import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  InsightfulStatement,
  Widgit,
  BarGraph,
  b300clearSkyBlue,
  b500bridgitBlue,
} from '@bridgit/foundation';
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';
import { stringToMoment } from '../../utils/dateUtils';
import {
  AVAILABILITY_SORT_COLUMN,
  AVAILABILITY_SORT_LABEL,
  IDLE_TIME_BY_TITLE_HEADER,
  IDLE_TIME_BY_TITLE_TOOLTIP,
  TITLE_FIELD_NAME,
  IDLE_TIME_STATEMENTS,
  IDLE_TIME_GRAPH_Y_TICKS,
  IDLE_TIME_BY_TITLE_EMPTY_FIRST,
  IDLE_TIME_BY_TITLE_EMPTY_SECOND,
  IDLE_TIME_BY_TITLE_EMPTY_LINK,
} from './constants';
import { getIdleTimeBreakdown } from './redux/getIdleTimeBreakdown';
import { IDLE_TIME_TITLE_CTA_CLICK, IDLE_TIME_TITLE_GRAPH_CLICK, IDLE_TIME_TITLE_CLICK } from '../../analytics/dashboard/constants';
import { getPeople } from '../people/redux/getPeople';
import { trackAnalytics } from '../common/redux/trackAnalytics';
import { MONTH_DAY_RANGE_FORMAT, PEOPLE_VIEW } from '../../common/constants';
import { FILTER_STORAGE_KEY, getStorageKey, SORT_STORAGE_KEY } from '../../common/localStorageKeys';
import { FILTERED_PEOPLE_QUERY_ID } from '../queries/redux/constants';
import { setFilterQuery } from '../queries/redux/setFilterQuery';
import { setSortQuery } from '../queries/redux/setSortQuery';
import emptyImage from '../../images/IdleTimeByTitle_EmptyState.svg?url';
import { getProjects } from '../projects/redux/getProjects';
import { generateXLabels } from './utils/dashboardUtils';

const IdleTimeByTitle = () => {
  const dispatch = useDispatch();
  const accountId = useSelector(({ common }) => common.accountId);
  const userId = useSelector(({ login: { userInfo: { sub } } }) => sub);
  const { personFields, getPersonFieldsPending } = useSelector(({ accountSettings }) => accountSettings);
  const {
    idleTimeBreakdown,
    getIdleTimeBreakdownPending,
    idleTimeSentiment,
    monthlyIdleTimeOverview,
  } = useSelector(({ dashboard }) => dashboard);
  const { entities: people, getPeoplePending } = useSelector(({ people }) => people);
  const { projects, getProjectsPending } = useSelector(({ projects }) => projects);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [hoverIndex, setHoverIndex] = useState(null);
  const isLoading = useMemo(() => (
    getIdleTimeBreakdownPending
    || getPersonFieldsPending
    || getPeoplePending
    || getProjectsPending
  ), [
    getIdleTimeBreakdownPending,
    getPersonFieldsPending,
    getPeoplePending,
    getProjectsPending,
  ]);
  const isEmpty = useMemo(() => (!people?.length || !projects?.length), [people, projects]);
  const filterStorageKey = useMemo(() => getStorageKey(PEOPLE_VIEW, accountId, FILTER_STORAGE_KEY, userId), [accountId, userId]);
  const sortStorageKey = useMemo(() => getStorageKey(PEOPLE_VIEW, accountId, SORT_STORAGE_KEY, userId), [accountId, userId]);
  const graphStartDate = stringToMoment(idleTimeBreakdown?.[0]?.barData?.[0].x);

  const titleId = useMemo(() => {
    if (getPersonFieldsPending) {
      return null;
    }
    return personFields.find(field => field.name === TITLE_FIELD_NAME)?.id ?? null;
  }, [getPersonFieldsPending, personFields]);

  useEffect(() => {
    dispatch(getPeople(accountId));
    dispatch(getProjects(accountId));
    if (titleId === null) {
      return;
    }
    dispatch(getIdleTimeBreakdown(accountId, moment(), moment().add(12, 'week'), titleId));
  }, [accountId, titleId, dispatch]);

  const onBarClick = useCallback((index, clickedBar) => {
    dispatch(trackAnalytics(IDLE_TIME_TITLE_GRAPH_CLICK), {
      'Time period': `${index + 1}`,
    });
    const rowClicked = idleTimeBreakdown[index];
    const selectedIndex = rowClicked.barData.indexOf(clickedBar);
    setSelectedIndex(selectedIndex ?? 0);
  }, [dispatch, idleTimeBreakdown]);

  const selectedDateRangeString = useMemo(() => {
    if (selectedIndex === null) {
      return 'This Month';
    }

    const breakdownRawDate = idleTimeBreakdown?.[0]?.barData[selectedIndex]?.x;
    if (!breakdownRawDate) {
      return '';
    }

    const startDate = moment(breakdownRawDate);
    const endDate = moment(startDate).add(6, 'day');

    const startFormat = startDate.format(MONTH_DAY_RANGE_FORMAT);
    const endFormat = endDate.format(MONTH_DAY_RANGE_FORMAT);
    return `${startFormat} - ${endFormat}`;
  }, [selectedIndex, idleTimeBreakdown]);

  const xLabels = useMemo(() => ((generateXLabels(monthlyIdleTimeOverview, graphStartDate))), [graphStartDate, monthlyIdleTimeOverview]);

  const sortByAvailability = useCallback(() => {
    const sortArgs = [{
      ascending: true,
      column: AVAILABILITY_SORT_COLUMN,
      label: AVAILABILITY_SORT_LABEL,
      type: null,
    }];
    dispatch(setSortQuery(FILTERED_PEOPLE_QUERY_ID, {
      name: 'sort',
      args: sortArgs,
    }, sortStorageKey));
  }, [dispatch, sortStorageKey]);

  const onTitleClick = useCallback((title) => {
    dispatch(trackAnalytics(IDLE_TIME_TITLE_CLICK), {
      Title: title,
    });

    const filterArgs = [{
      activeFilters: [{
        name: title,
        value: title,
      }],
      column: TITLE_FIELD_NAME.toLowerCase(),
      label: TITLE_FIELD_NAME,
      type: 'SingleSelect',
    }];

    sortByAvailability();

    dispatch(setFilterQuery(FILTERED_PEOPLE_QUERY_ID, {
      name: 'filter',
      args: filterArgs,
    }, filterStorageKey));
  }, [dispatch, filterStorageKey, sortByAvailability]);

  const onInsightfulStatementClick = useCallback(() => {
    dispatch(trackAnalytics(IDLE_TIME_TITLE_CTA_CLICK));

    sortByAvailability();

    // Reset filters if any exist
    dispatch(setFilterQuery(FILTERED_PEOPLE_QUERY_ID, {
      name: 'filter',
      args: [],
    }, filterStorageKey));
  }, [dispatch, filterStorageKey, sortByAvailability]);

  const onBarHover = useCallback(hoverBarIndex => setHoverIndex(hoverBarIndex !== undefined ? hoverBarIndex : null), []);
  const hoverBarLeftPosition = useMemo(() => (hoverIndex !== null ? `${18 + (22 * hoverIndex)}px` : ''), [hoverIndex]);

  const widgitContent = useMemo(() => (
    <div className="widgit-content">
      <div className="header-row">
        <div className="title-header-block" />
        <div className="stats-header-block">
          <div>{selectedDateRangeString}</div>
          <div className="sub-header">
            <span>People</span>
            <span>Idle Time</span>
          </div>
        </div>
        <div className="graph-header-block">
          <div>Trend - Idle Time</div>
          <div className="sub-header">
            {xLabels.map(scale => (<span key={scale}>{scale}</span>))}
          </div>
        </div>
      </div>
      {idleTimeBreakdown.map(({ title, barData, thisMonth }, index) => {
        const numPeople = selectedIndex !== null ? barData[selectedIndex].numPeople : thisMonth?.numPeople ?? 0;
        const idleTime = selectedIndex !== null ? barData[selectedIndex].y : thisMonth?.idleTime ?? 0;
        return (
          <div key={title} className="idle-time-row">
            <RouterLink className="title-block text-block" to={`/accounts/${accountId}/people/`} type="button" onClick={() => onTitleClick(title)}>
              <span>{title}</span>
            </RouterLink>
            <div className="people-block text-block">
              <span>{numPeople}</span>
            </div>
            <div className="idle-time-block text-block">
              <span>{`${parseFloat(idleTime).toFixed(1)}%`}</span>
            </div>
            <div className="graph-block">
              <div className="bar-graph">
                <BarGraph
                  hideXTickLine
                  hideYTickLine
                  hideXAxis
                  hideYAxis
                  barData={barData}
                  width={310}
                  height={45}
                  barWidth={20}
                  barSpacing={22}
                  barColor={b300clearSkyBlue}
                  barBorderColor={b500bridgitBlue}
                  selectedBarColor={b500bridgitBlue}
                  selectedBarBorderColor={b500bridgitBlue}
                  barBorderRadius={2}
                  onBarClick={clickedBar => onBarClick(index, clickedBar)}
                  yTicks={IDLE_TIME_GRAPH_Y_TICKS}
                  currentSelectedIndex={selectedIndex}
                  onHover={onBarHover}
                  useOpacity={false}
                />
              </div>
              {hoverIndex !== null && (<div className="hover-bar" style={{ left: hoverBarLeftPosition }} />)}
            </div>
          </div>
        );
      })}
    </div>
  ), [
    selectedDateRangeString,
    xLabels,
    idleTimeBreakdown,
    selectedIndex,
    accountId,
    onBarHover,
    hoverIndex,
    onTitleClick,
    onBarClick,
    hoverBarLeftPosition,
  ]);

  const widgitFooter = useMemo(() => {
    if (!idleTimeSentiment) {
      return null;
    }
    return (
      <RouterLink className="footer-wrapper" to={`/accounts/${accountId}/people/`} type="button">
        <InsightfulStatement onClick={onInsightfulStatementClick} insightfulStatement={IDLE_TIME_STATEMENTS[idleTimeSentiment]} />
      </RouterLink>
    );
  }, [accountId, idleTimeSentiment, onInsightfulStatementClick]);

  const widgitTooltip = useMemo(() => (
    <div className="widgit-tooltip">
      <span>
        <b>
          {IDLE_TIME_BY_TITLE_HEADER}
        </b>
        {IDLE_TIME_BY_TITLE_TOOLTIP}
      </span>
    </div>
  ), []);

  const emptyState = useMemo(() => (
    <div className="empty-state">
      <div className="empty-state-text">{IDLE_TIME_BY_TITLE_EMPTY_FIRST}</div>
      <div>
        <RouterLink className="title-block text-block add-projects-link" to={`/accounts/${accountId}/projects`} type="button">
          {IDLE_TIME_BY_TITLE_EMPTY_LINK}
        </RouterLink>
        {IDLE_TIME_BY_TITLE_EMPTY_SECOND}
      </div>
      <img className="empty-image" src={emptyImage} alt="emptyImg" />
    </div>
  ), [accountId]);

  return (
    <div className="dashboard-idle-time-by-title">
      <Widgit
        headerText={IDLE_TIME_BY_TITLE_HEADER}
        headerTooltipContent={widgitTooltip}
        mainContent={widgitContent}
        footerContent={widgitFooter}
        isLoading={isLoading}
        emptyState={emptyState}
        isEmpty={isEmpty}
      />
    </div>
  );
};

export default IdleTimeByTitle;
