import moment from 'moment';
import pluralize from 'pluralize';
import {
  HOME_ADDRESS_FIELD,
  DATE_DISPLAY_FORMAT,
  MIN_API_CONFIGURABLE_DATE,
  MIN_API_CONFIGURABLE_DATE_MESSAGE,
} from 'src/common/constants';
import { ASSIGNED, ASSIGNED_NOTIFIED, DEFAULT_WORK_DAYS_OPTIONS, REQUEST_REJECTED } from 'src/features/self-perform/constants';
import { END_DATE, REQUIRED_SKILLS, START_DATE } from 'src/filters/constants';
import {
  formatWorkingDays,
  getSelectedWorkDayValues,
  isNoWorkDaysWithinRange,
} from './requestUtils';


// Moment uses Monday - Sunday as 1 - 7
// where we use Sunday - Saturday 0 - 6
const getWorkDay = (day) => {
  if (day === 7) return 0;
  return day;
};

// Calculate duration based on how many workdays are in the date range
const calculateDuration = (startDate, endDate, workDays) => {
  const momentStart = moment(startDate);
  const momentEnd = moment(endDate);
  const totalDays = momentEnd.diff(startDate, 'days');
  const totalWeeks = momentEnd.diff(startDate, 'weeks');

  // first get the total full weeks
  let duration = totalWeeks * workDays.length;

  // calculate remaining days to check
  const remainder = totalDays % 7;

  // start on the start dates day
  let day = getWorkDay(momentStart.isoWeekday());

  // for the remainder number of days, check for matches in workDays
  for (let i = 0; i <= remainder; i += 1) {
    if (workDays.includes(`${day}`)) duration += 1;
    if (day === 6) {
      day = 0;
    } else {
      day += 1;
    }
  }

  return duration;
};

const getSelectedWorkDayCheckboxes = workDays => DEFAULT_WORK_DAYS_OPTIONS.map((day) => {
  const newDay = { ...day };

  newDay.checked = workDays.includes(newDay.value.toString());

  return newDay;
});

export const getMainItemsDesc = (request, skillSet) => {
  if (!request) return [];

  const isDisplayOnly = request.state === REQUEST_REJECTED;

  const { description, project, requiredSkills } = request;
  const { name: projectName } = project;

  return [
    {
      id: 'description',
      name: 'Description',
      type: 'Text',
      value: description,
      isRequired: true,
      fullWidth: true,
      maxLength: 100,
      displayOnly: isDisplayOnly,
      isHeader: true,
    },
    {
      id: 'project',
      name: 'Project',
      value: projectName,
      input: {},
      displayOnly: true,
    },
    {
      id: REQUIRED_SKILLS,
      name: 'Skill set',
      type: 'MultiSelect',
      multi: true,
      value: requiredSkills,
      definedValues: skillSet.map(skill => ({ definedValue: skill.name })),
      displayOnly: isDisplayOnly,
    },
  ];
};

export const getOtherItemsDesc = (request) => {
  if (!request) return [];

  const {
    startDate,
    endDate,
    workDays,
    requester,
    requestedOn,
    note,
    state,
  } = request;

  const isDisplayOnly = state === REQUEST_REJECTED;
  const duration = calculateDuration(startDate, endDate, workDays);

  return [
    {
      id: 'startDate',
      name: 'Start date',
      type: 'Date',
      value: startDate,
      isRequired: true,
      required: true,
      minDate: moment(MIN_API_CONFIGURABLE_DATE),
      minDateMessage: MIN_API_CONFIGURABLE_DATE_MESSAGE,
      displayOnly: isDisplayOnly,
    },
    {
      id: 'endDate',
      name: 'End date',
      type: 'Date',
      value: endDate,
      minDate: moment(MIN_API_CONFIGURABLE_DATE),
      minDateMessage: MIN_API_CONFIGURABLE_DATE_MESSAGE,
      isRequired: true,
      displayOnly: isDisplayOnly,
    },
    {
      id: 'duration',
      name: 'Duration',
      type: 'Text',
      multi: true,
      options: [],
      value: pluralize('day', duration, true),
      input: {},
      displayOnly: true,
    },
    {
      id: 'workDays',
      name: 'Working days',
      type: 'MultiCheckbox',
      value: formatWorkingDays(workDays),
      options: getSelectedWorkDayCheckboxes(workDays),
      className: 'working-days-container',
      displayOnly: isDisplayOnly,
    },
    {
      id: 'requester',
      name: 'Requester',
      type: 'Text',
      value: requester?.name || '',
      input: {},
      displayOnly: true,
    },
    {
      id: 'requestedOn',
      name: 'Requested',
      type: 'Date',
      value: requestedOn,
      input: {},
      displayOnly: true,
    },
    {
      id: 'note',
      name: 'Notes',
      type: 'LongText',
      value: note,
      displayOnly: isDisplayOnly,
    },
  ];
};

export const getHourlyPersonMainDesc = (hourlyPerson) => {
  if (!hourlyPerson) return [];
  const { skillSet, fields } = hourlyPerson;
  const homeAddress = fields.find(({ name }) => name === HOME_ADDRESS_FIELD)?.values[0];
  return [
    {
      id: 'Skill set',
      name: 'Skill set',
      type: 'MultiSelect',
      multi: true,
      options: [],
      value: skillSet,
    },
    {
      id: 'Home Address',
      name: 'Home Address',
      type: 'Text',
      value: homeAddress,
    },
  ];
};

export const getHourlyPersonOtherDesc = (hourlyPerson, hourlyPersonFields) => {
  if (!hourlyPerson || !hourlyPersonFields.length) return [];
  const { phoneNumber, fields } = hourlyPerson;

  const otherFields = fields.reduce((acc, field) => {
    const { id, name, values } = field;
    if (name !== HOME_ADDRESS_FIELD) {
      const relevantField = hourlyPersonFields.find(field => field.id === id);
      if (relevantField) {
        const { type } = relevantField;
        acc.push({
          id,
          name,
          type,
          value: values,
        });
      }
    }
    return acc;
  }, []);

  return [
    {
      id: 'Cell number',
      name: 'Cell number',
      type: 'PhoneNumber',
      value: phoneNumber,
    },
    ...otherFields,
  ];
};

export const getDeleteRequestModalText = (selectedRequest) => {
  const { state, assignee } = selectedRequest || {};
  const deleteAssignment = 'Are you sure you want to delete this assignment?';
  const deleteAssignmentTitle = 'Delete assignment';

  switch (state) {
    case ASSIGNED: {
      return {
        modalText: deleteAssignment,
        modalTitle: deleteAssignmentTitle,
      };
    }
    case ASSIGNED_NOTIFIED: {
      return {
        modalText: `${deleteAssignment} ${assignee.name} has already been notified of the assignment.`,
        modalTitle: deleteAssignmentTitle,
      };
    }
    default: {
      return {
        modalText: 'Are you sure you want to delete this request?',
        modalTitle: 'Delete request',
      };
    }
  }
};

export const getNormalizedUpdateRequestData = (fieldId, values) => {
  if (fieldId === REQUIRED_SKILLS) return { [fieldId]: values };

  if (fieldId === 'workDays') return { [fieldId]: getSelectedWorkDayValues(values) };

  return { [fieldId]: values[0] };
};

export const validateUpdateRequestData = (fieldToEdit, data, selectedRequest) => {
  const { startDate, endDate, workDays } = selectedRequest;

  if (fieldToEdit === START_DATE) {
    if (moment(data).isAfter(endDate)) return { startDate: 'Start date must be earlier than the end date' };

    if (isNoWorkDaysWithinRange(workDays, data, endDate)) return { startDate: 'There are no working days in the request date range' };
  }

  if (fieldToEdit === END_DATE) {
    if (moment(data).isBefore(startDate)) return { endDate: 'End date must be later than the start date' };

    if (isNoWorkDaysWithinRange(workDays, startDate, data)) return { endDate: 'There are no working days in the request date range' };
  }

  if (fieldToEdit === 'workDays') {
    if (isNoWorkDaysWithinRange(data, startDate, endDate)) return { workDays: 'There are no working days in the request date range' };
  }

  return {};
};

export const getStandardNotifyText = (person, request) => {
  if (!person || !request) return '';

  const { name } = person;
  const {
    project,
    workDays,
    startDate,
    endDate,
    requester,
  } = request;
  const { name: projectName } = project;

  const start = moment(startDate).format(DATE_DISPLAY_FORMAT);
  const end = moment(endDate).format(DATE_DISPLAY_FORMAT);
  let text = `${name} has been assigned to ${projectName} from ${start} - ${end}. Working days: ${formatWorkingDays(workDays)}.`;

  if (requester) {
    const { name: requesterName, phoneNumber } = requester;
    const phoneNumberText = phoneNumber ? ` ${phoneNumber}` : '';
    text += ` Requested by ${requesterName}${phoneNumberText}.`;
  }

  return text;
};

export const getStandardRemoveNotifyText = (request) => {
  if (!request) return '';
  const { assignee, project, startDate, endDate } = request;
  const { name: projectName } = project;
  const start = moment(startDate).format(DATE_DISPLAY_FORMAT);
  const end = moment(endDate).format(DATE_DISPLAY_FORMAT);
  return `Assignment Removed. ${assignee?.name || 'Assignee'} is no longer assigned to ${projectName} from ${start} - ${end}.`;
};

export const getRequestRecipients = (person, request) => {
  if (!person || !request) return [];

  const { id, name, skillSet } = person;
  const { requester } = request;
  const recipients = [
    {
      id,
      name,
      title: skillSet?.join(', ') || '',
    },
  ];

  if (requester) {
    recipients.push({
      id: `requester-${requester.id}`,
      name: requester.name,
      title: 'Requester',
    });
  }

  return recipients;
};
