import { gql } from 'apollo-boost';
import { momentToString } from '../../../utils/dateUtils';
import { WEEK } from '../constants';

const IDLE_TIME_OVERVIEW_QUERY = gql`
    query idleTimeOverviewQuery($accountId: Int!, $trueValue: Boolean!, $startDate: String!, $endDate: String!, $period: String!) {
      forecastingOverview(accountId: $accountId, trueValue: $trueValue, startDate: $startDate, endDate: $endDate, period: $period) {
        items {
          x
          y
        }
        count
      }
    }
  `;

const getIdleTimeOverviewQuery = (accountId, startDate, endDate, period = WEEK) => {
  const variables = {
    accountId,
    trueValue: true,
    startDate: momentToString(startDate),
    endDate: momentToString(endDate),
    period,
  };

  return {
    query: IDLE_TIME_OVERVIEW_QUERY,
    variables,
  };
};

export {
  getIdleTimeOverviewQuery,
};
