import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import {
  enable as enableDarkMode,
  disable as disableDarkMode,
  auto as followSystemColorScheme,
  setFetchMethod,
} from 'darkreader';
import { IconButton, Tooltip } from '@material-ui/core';
import { getStoredSystemThemeStatus, getStoredThemeSettings, getStoredThemeName } from './utils';
import { changeTheme, toggleSystemThemeSwitch, loadThemeSettings } from './redux/actions';
import DarkModeIcon from '../../images/moon-icon.svg';
import StandardModeIcon from '../../images/sun-icon.svg';
import { DARK_THEME, DIM_THEME, STANDARD_THEME, darkThemeFixes, TOOLTIP } from './constants';
import { updateMixpanelThemeProps } from '../../common/analyticsHelper';
import { THEME_SELECTED } from '../../analytics/themes/constants';

const ThemeToggle = ({ className }) => {
  const userId = useSelector(state => state.login.userInfo.sub || null);
  const themeName = useSelector(state => state.themes.theme);
  const themeSettings = useSelector(state => state.themes.settings);
  const isUsingSystemTheme = useSelector(state => state.themes.isUsingSystemTheme);

  const dispatch = useDispatch();

  useEffect(() => {
    setFetchMethod(window.fetch);
  }, []);

  const isDarkModeOn = useMemo(() => themeName === DARK_THEME, [themeName]);
  const isDimmedModeOn = useMemo(() => themeName === DIM_THEME, [themeName]);
  const isStandardModeOn = useMemo(() => themeName === STANDARD_THEME, [themeName]);

  const handleToggleDarkMode = useCallback(() => {
    const analyticsPayload = { eventType: THEME_SELECTED };

    if (isStandardModeOn) {
      dispatch(changeTheme(DARK_THEME, userId, analyticsPayload));
    }

    if (isDarkModeOn || isDimmedModeOn) {
      dispatch(changeTheme(STANDARD_THEME, userId, analyticsPayload));
    }
  }, [dispatch, isDarkModeOn, isDimmedModeOn, isStandardModeOn, userId]);

  useEffect(() => {
    // System theme takes priority
    if (isUsingSystemTheme) {
      followSystemColorScheme(themeSettings[DARK_THEME]);
    } else if (isDarkModeOn) {
      enableDarkMode(themeSettings[DARK_THEME], darkThemeFixes);
    } else if (isDimmedModeOn) {
      enableDarkMode({ mode: 0, ...themeSettings[DIM_THEME] });
    } else {
      disableDarkMode();
    }
  }, [isDarkModeOn, themeSettings, isUsingSystemTheme, isDimmedModeOn]);

  useEffect(() => {
    const storedSystemThemeStatus = getStoredSystemThemeStatus(userId);

    if (storedSystemThemeStatus) dispatch(toggleSystemThemeSwitch(storedSystemThemeStatus, userId));
  }, [dispatch, userId]);

  useEffect(() => {
    const storedSettings = getStoredThemeSettings(userId, themeName);

    if (storedSettings) dispatch(loadThemeSettings(themeName, storedSettings, userId));
  }, [dispatch, userId, themeName]);

  useEffect(() => {
    if (userId) {
      const storedThemeName = getStoredThemeName(userId);
      if (storedThemeName) {
        dispatch(changeTheme(storedThemeName, userId));
        updateMixpanelThemeProps(storedThemeName);
      } else {
        updateMixpanelThemeProps(themeName);
      }
    }
  }, [dispatch, userId, themeName]);

  const tooltipTitle = useMemo(() => {
    const { systemThemeText, standardText, darkOrDimText } = TOOLTIP;

    if (isUsingSystemTheme) return systemThemeText;

    if (isDarkModeOn || isDimmedModeOn) return darkOrDimText;

    return standardText;
  }, [isUsingSystemTheme, isDarkModeOn, isDimmedModeOn]);

  return (
    <div className={classNames(className, 'theme-toggle-wrapper', { 'toggle-disabled': isUsingSystemTheme })}>
      <Tooltip
        classes={{ popper: 'common-top-bar', tooltip: 'tooltip theme-toggle-tooltip' }}
        title={tooltipTitle}
        placement="bottom"
      >
        <span>
          <IconButton onClick={handleToggleDarkMode} disabled={isUsingSystemTheme}>
            {isDarkModeOn || isDimmedModeOn ? <StandardModeIcon /> : <DarkModeIcon />}
          </IconButton>
        </span>
      </Tooltip>
    </div>
  );
};

ThemeToggle.propTypes = {
  className: PropTypes.string,
};

ThemeToggle.defaultProps = {
  className: '',
};

export default ThemeToggle;
