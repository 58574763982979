import { call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';

import {
  GANTT_SET_PEOPLE_GANTT_SHARE_DATA_BEGIN,
  GANTT_SET_PEOPLE_GANTT_SHARE_DATA_SUCCESS,
  GANTT_SET_PEOPLE_GANTT_SHARE_DATA_FAILURE,
  GANTT_SET_PEOPLE_GANTT_SHARE_DATA_DISMISS_ERROR,
} from './constants';
import { SHARE_DATA_QUERY_PARAM } from '../constants';
import graphApiClient from '../../../common/GraphAPIClient';
import { setPeopleGanttShareQuery } from '../queries/setPeopleGanttShareQuery';

export function setPeopleGanttShareData(accountId, value) {
  const { query, variables } = setPeopleGanttShareQuery(accountId, value);
  return {
    type: GANTT_SET_PEOPLE_GANTT_SHARE_DATA_BEGIN,
    query,
    variables,
    accountId,
  };
}

export function dismissSetPeopleGanttShareDataError() {
  return {
    type: GANTT_SET_PEOPLE_GANTT_SHARE_DATA_DISMISS_ERROR,
  };
}

export function* doSetPeopleGanttShareData(action) {
  const { query, variables } = action;
  let res;
  try {
    res = yield call(graphApiClient.query, { query, variables });
  } catch (err) {
    yield put({
      type: GANTT_SET_PEOPLE_GANTT_SHARE_DATA_FAILURE,
      data: { error: err },
    });
    return;
  }

  const { key } = res.data?.setPeopleGanttConfig || {};

  // Append share query parameter to current URL & copy to clipboard
  const shareLink = new URL(window.location.href);
  shareLink.searchParams.set(SHARE_DATA_QUERY_PARAM, key);
  window.navigator.clipboard.writeText(shareLink);

  yield put({
    type: GANTT_SET_PEOPLE_GANTT_SHARE_DATA_SUCCESS,
    analyticsPayload: { key },
  });
}

export function* watchSetPeopleGanttShareData() {
  yield takeLatest(GANTT_SET_PEOPLE_GANTT_SHARE_DATA_BEGIN, doSetPeopleGanttShareData);
}

export function reducer(state, action) {
  switch (action.type) {
    case GANTT_SET_PEOPLE_GANTT_SHARE_DATA_BEGIN:
      return {
        ...state,
        setPeopleGanttShareDataPending: true,
        setPeopleGanttShareDataError: null,
      };

    case GANTT_SET_PEOPLE_GANTT_SHARE_DATA_SUCCESS:
      return {
        ...state,
        setPeopleGanttShareDataPending: false,
        setPeopleGanttShareDataError: null,
        sharePeopleGanttDataExpiryDate: moment().add(5, 'days'),
      };

    case GANTT_SET_PEOPLE_GANTT_SHARE_DATA_FAILURE:
      return {
        ...state,
        setPeopleGanttShareDataPending: false,
        setPeopleGanttShareDataError: action.data.error,
      };

    case GANTT_SET_PEOPLE_GANTT_SHARE_DATA_DISMISS_ERROR:
      return {
        ...state,
        setPeopleGanttShareDataError: null,
      };

    default:
      return state;
  }
}
