import {
  PEOPLE_GANTT_CONFIG_KEY,
  DEFAULT_GANTT_SCALE,
  PEOPLE_GANTT_CONFIG_KEY_V2,
  PROJECT_GANTT_CONFIG_KEY,
} from './constants';
import { PEOPLE_VIEW_SETTINGS_UTILIZATION, PEOPLE_VIEW_SETTINGS_FULL_COLOR } from '../people-gantt/constants';
import { FULL_COLOR, PROJECTS_AND_UTILIZATION } from '../people-gantt-v2/constants';
import { YEAR } from '../constants';
import { DEFAULT_ROLE_FILTER, DEFAULT_ROLE_SORT, DEFAULT_VIEW_ROLES } from '../project-gantt/constants';

const initialState = {
  instances: {
    [PEOPLE_GANTT_CONFIG_KEY]: {
      scaleValue: DEFAULT_GANTT_SCALE,
      columnHeaders: [],
      parsedPeople: [],
      todayPosition: null,
      scrollPosition: null,
      displayOptions: {
        title: true,
        photo: true,
      },
      ganttDisplay: PEOPLE_VIEW_SETTINGS_UTILIZATION,
      colorOption: PEOPLE_VIEW_SETTINGS_FULL_COLOR,
    },
    [PEOPLE_GANTT_CONFIG_KEY_V2]: {
      showAvatar: true,
      displayOption: PROJECTS_AND_UTILIZATION,
      timeInterval: YEAR,
      colorOption: FULL_COLOR,
    },
    [PROJECT_GANTT_CONFIG_KEY]: {
      timeInterval: YEAR,
      viewRoles: DEFAULT_VIEW_ROLES,
      roleSortOption: DEFAULT_ROLE_SORT,
      roleFilters: DEFAULT_ROLE_FILTER,
    },
  },
  getPeopleGanttShareDataPending: false,
  getPeopleGanttShareDataError: null,
  setPeopleGanttShareDataPending: false,
  setPeopleGanttShareDataError: null,
  sharePeopleGanttDataExpiryDate: null,
};

export default initialState;
