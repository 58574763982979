import { matchPath } from 'react-router-dom';
import { COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_ALLOCATE_PERSON } from '../features/common/redux/constants';
import {
  PAPM_VIEW,
  ROLE_SEGMENT_VIEW,
  DASHBOARD_VIEW,
  FORECAST_VIEW,
  VIEWS,
  CONTENT_VIEW_MAP,
  PAGE_NAMES,
} from '../common/constants';

const getActiveView = (state) => {
  const { modalManager, common } = state;
  const { activeModal } = modalManager;
  const { multiStateModal } = common;

  const allocationModalOpen = activeModal && multiStateModal?.activeView === COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_ALLOCATE_PERSON;

  return allocationModalOpen ? PAPM_VIEW : common.activeView;
};

const getActiveViewLabel = state => VIEWS[getActiveView(state)];

const getPageName = (activeView, contentView) => {
  const view = CONTENT_VIEW_MAP[contentView];
  const page = PAGE_NAMES[activeView];

  if ([ROLE_SEGMENT_VIEW, DASHBOARD_VIEW, FORECAST_VIEW].includes(activeView)) {
    return page;
  }

  return `${page} ${view}`;
};

const isListIdChange = pathname => matchPath(pathname, {
  path: '/accounts/:id/:type/list/:listId',
});

// shorten a URL by getting everything after the account ID
const getShortUrl = (pathname) => {
  const matchedPath = matchPath(pathname, {
    path: '/accounts/:id',
  });

  if (matchedPath?.params) {
    const accountId = Number(matchedPath.params.id);
    const splitPath = pathname.split(`/accounts/${accountId}`);
    return splitPath?.length > 1 ? splitPath[1] : splitPath[0];
  }

  return pathname;
};

export {
  getActiveViewLabel,
  isListIdChange,
  getShortUrl,
  getPageName,
};
