export const MAX_FILE_SIZE = 500000; // 500000 b is 500 KB
export const DEFAULT_ACCOUNT_SORT = { name: 'sort', args: [{ column: 'name', label: 'Account Name', ascending: true, type: null }] };
export const DEFAULT_ACTIVE_USER_SORT = { name: 'sort', args: [{ column: 'Name', label: 'Name', ascending: true, type: null }] };
export const LOGO_UPLOAD_CONFIG = { maxSize: MAX_FILE_SIZE, accept: 'image/*' };
export const LOGO_UPLOAD_ERROR_MESSAGES = {
  'too-many-files': 'Please upload one file at a time',
  'file-too-large': 'Your photo is too large to upload. It must be smaller than 500kb. Please resize or select a different image.',
  'file-invalid-type': 'This file type is not supported. Please select a different image.',
};

export const ACCOUNTS_CREATE_NEW_ACCOUNT_BEGIN = 'ACCOUNTS_CREATE_NEW_ACCOUNT_BEGIN';
export const ACCOUNTS_CREATE_NEW_ACCOUNT_SUCCESS = 'ACCOUNTS_CREATE_NEW_ACCOUNT_SUCCESS';
export const ACCOUNTS_CREATE_NEW_ACCOUNT_FAILURE = 'ACCOUNTS_CREATE_NEW_ACCOUNT_FAILURE';
export const ACCOUNTS_CREATE_NEW_ACCOUNT_DISMISS_ERROR = 'ACCOUNTS_CREATE_NEW_ACCOUNT_DISMISS_ERROR';
export const ACCOUNTS_GET_ACCOUNTS_BEGIN = 'ACCOUNTS_GET_ACCOUNTS_BEGIN';
export const ACCOUNTS_GET_ACCOUNTS_SUCCESS = 'ACCOUNTS_GET_ACCOUNTS_SUCCESS';
export const ACCOUNTS_GET_ACCOUNTS_FAILURE = 'ACCOUNTS_GET_ACCOUNTS_FAILURE';
export const ACCOUNTS_GET_ACCOUNTS_DISMISS_ERROR = 'ACCOUNTS_GET_ACCOUNTS_DISMISS_ERROR';
export const ACCOUNTS_UPDATE_ACCOUNT_BEGIN = 'ACCOUNTS_UPDATE_ACCOUNT_BEGIN';
export const ACCOUNTS_UPDATE_ACCOUNT_SUCCESS = 'ACCOUNTS_UPDATE_ACCOUNT_SUCCESS';
export const ACCOUNTS_UPDATE_ACCOUNT_FAILURE = 'ACCOUNTS_UPDATE_ACCOUNT_FAILURE';
export const ACCOUNTS_UPDATE_ACCOUNT_DISMISS_ERROR = 'ACCOUNTS_UPDATE_ACCOUNT_DISMISS_ERROR';
export const ACCOUNTS_INVITE_USER_BEGIN = 'ACCOUNTS_INVITE_USER_BEGIN';
export const ACCOUNTS_INVITE_USER_SUCCESS = 'ACCOUNTS_INVITE_USER_SUCCESS';
export const ACCOUNTS_INVITE_USER_FAILURE = 'ACCOUNTS_INVITE_USER_FAILURE';
export const ACCOUNTS_INVITE_USER_DISMISS_ERROR = 'ACCOUNTS_INVITE_USER_DISMISS_ERROR';
export const ACCOUNTS_REINVITE_USER_BEGIN = 'ACCOUNTS_REINVITE_USER_BEGIN';
export const ACCOUNTS_REINVITE_USER_SUCCESS = 'ACCOUNTS_REINVITE_USER_SUCCESS';
export const ACCOUNTS_REINVITE_USER_FAILURE = 'ACCOUNTS_REINVITE_USER_FAILURE';
export const ACCOUNTS_REINVITE_USER_DISMISS_ERROR = 'ACCOUNTS_REINVITE_USER_DISMISS_ERROR';
export const ACCOUNTS_GET_USERS_BEGIN = 'ACCOUNTS_GET_USERS_BEGIN';
export const ACCOUNTS_GET_USERS_SUCCESS = 'ACCOUNTS_GET_USERS_SUCCESS';
export const ACCOUNTS_GET_USERS_FAILURE = 'ACCOUNTS_GET_USERS_FAILURE';
export const ACCOUNTS_GET_USERS_DISMISS_ERROR = 'ACCOUNTS_GET_USERS_DISMISS_ERROR';
export const ACCOUNTS_REMOVE_USER_BEGIN = 'ACCOUNTS_REMOVE_USER_BEGIN';
export const ACCOUNTS_REMOVE_USER_SUCCESS = 'ACCOUNTS_REMOVE_USER_SUCCESS';
export const ACCOUNTS_REMOVE_USER_FAILURE = 'ACCOUNTS_REMOVE_USER_FAILURE';
export const ACCOUNTS_REMOVE_USER_DISMISS_ERROR = 'ACCOUNTS_REMOVE_USER_DISMISS_ERROR';
export const ACCOUNTS_REMOVE_INVITATION_BEGIN = 'ACCOUNTS_REMOVE_INVITATION_BEGIN';
export const ACCOUNTS_REMOVE_INVITATION_SUCCESS = 'ACCOUNTS_REMOVE_INVITATION_SUCCESS';
export const ACCOUNTS_REMOVE_INVITATION_FAILURE = 'ACCOUNTS_REMOVE_INVITATION_FAILURE';
export const ACCOUNTS_REMOVE_INVITATION_DISMISS_ERROR = 'ACCOUNTS_REMOVE_INVITATION_DISMISS_ERROR';
export const ACCOUNTS_GET_INVITATIONS_BEGIN = 'ACCOUNTS_GET_INVITATIONS_BEGIN';
export const ACCOUNTS_GET_INVITATIONS_SUCCESS = 'ACCOUNTS_GET_INVITATIONS_SUCCESS';
export const ACCOUNTS_GET_INVITATIONS_FAILURE = 'ACCOUNTS_GET_INVITATIONS_FAILURE';
export const ACCOUNTS_GET_INVITATIONS_DISMISS_ERROR = 'ACCOUNTS_GET_INVITATIONS_DISMISS_ERROR';
export const ACCOUNTS_UPDATE_USER_PERMISSIONS_BEGIN = 'ACCOUNTS_UPDATE_USER_PERMISSIONS_BEGIN';
export const ACCOUNTS_UPDATE_USER_PERMISSIONS_SUCCESS = 'ACCOUNTS_UPDATE_USER_PERMISSIONS_SUCCESS';
export const ACCOUNTS_UPDATE_USER_PERMISSIONS_FAILURE = 'ACCOUNTS_UPDATE_USER_PERMISSIONS_FAILURE';
export const ACCOUNTS_UPDATE_USER_PERMISSIONS_DISMISS_ERROR = 'ACCOUNTS_UPDATE_USER_PERMISSIONS_DISMISS_ERROR';
export const ACCOUNTS_SET_LOGO_BEGIN = 'ACCOUNTS_SET_LOGO_BEGIN';
export const ACCOUNTS_SET_LOGO_SUCCESS = 'ACCOUNTS_SET_LOGO_SUCCESS';
export const ACCOUNTS_SET_LOGO_FAILURE = 'ACCOUNTS_SET_LOGO_FAILURE';
export const ACCOUNTS_SET_LOGO_DISMISS_ERROR = 'ACCOUNTS_SET_LOGO_DISMISS_ERROR';
export const ACCOUNTS_DELETE_LOGO_BEGIN = 'ACCOUNTS_DELETE_LOGO_BEGIN';
export const ACCOUNTS_DELETE_LOGO_SUCCESS = 'ACCOUNTS_DELETE_LOGO_SUCCESS';
export const ACCOUNTS_DELETE_LOGO_FAILURE = 'ACCOUNTS_DELETE_LOGO_FAILURE';
export const ACCOUNTS_DELETE_LOGO_DISMISS_ERROR = 'ACCOUNTS_DELETE_LOGO_DISMISS_ERROR';
export const ACCOUNTS_DEACTIVATE_ACCOUNT_BEGIN = 'ACCOUNTS_DEACTIVATE_ACCOUNT_BEGIN';
export const ACCOUNTS_DEACTIVATE_ACCOUNT_SUCCESS = 'ACCOUNTS_DEACTIVATE_ACCOUNT_SUCCESS';
export const ACCOUNTS_DEACTIVATE_ACCOUNT_FAILURE = 'ACCOUNTS_DEACTIVATE_ACCOUNT_FAILURE';
export const ACCOUNTS_DEACTIVATE_ACCOUNT_DISMISS_ERROR = 'ACCOUNTS_DEACTIVATE_ACCOUNT_DISMISS_ERROR';
export const ACCOUNTS_REACTIVATE_ACCOUNT_BEGIN = 'ACCOUNTS_REACTIVATE_ACCOUNT_BEGIN';
export const ACCOUNTS_REACTIVATE_ACCOUNT_SUCCESS = 'ACCOUNTS_REACTIVATE_ACCOUNT_SUCCESS';
export const ACCOUNTS_REACTIVATE_ACCOUNT_FAILURE = 'ACCOUNTS_REACTIVATE_ACCOUNT_FAILURE';
export const ACCOUNTS_REACTIVATE_ACCOUNT_DISMISS_ERROR = 'ACCOUNTS_REACTIVATE_ACCOUNT_DISMISS_ERROR';
export const ACCOUNTS_GET_ACCOUNT_COLORS_BEGIN = 'ACCOUNTS_GET_ACCOUNT_COLORS_BEGIN';
export const ACCOUNTS_GET_ACCOUNT_COLORS_SUCCESS = 'ACCOUNTS_GET_ACCOUNT_COLORS_SUCCESS';
export const ACCOUNTS_GET_ACCOUNT_COLORS_FAILURE = 'ACCOUNTS_GET_ACCOUNT_COLORS_FAILURE';
export const ACCOUNTS_GET_ACCOUNT_COLORS_DISMISS_ERROR = 'ACCOUNTS_GET_ACCOUNT_COLORS_DISMISS_ERROR';
export const ACCOUNTS_GET_ROLE_TEMPLATES_BEGIN = 'ACCOUNTS_GET_ROLE_TEMPLATES_BEGIN';
export const ACCOUNTS_GET_ROLE_TEMPLATES_SUCCESS = 'ACCOUNTS_GET_ROLE_TEMPLATES_SUCCESS';
export const ACCOUNTS_GET_ROLE_TEMPLATES_FAILURE = 'ACCOUNTS_GET_ROLE_TEMPLATES_FAILURE';
export const ACCOUNTS_GET_ROLE_TEMPLATES_DISMISS_ERROR = 'ACCOUNTS_GET_ROLE_TEMPLATES_DISMISS_ERROR';
export const ACCOUNTS_DELETE_ROLE_TEMPLATE_BEGIN = 'ACCOUNTS_DELETE_ROLE_TEMPLATE_BEGIN';
export const ACCOUNTS_DELETE_ROLE_TEMPLATE_SUCCESS = 'ACCOUNTS_DELETE_ROLE_TEMPLATE_SUCCESS';
export const ACCOUNTS_DELETE_ROLE_TEMPLATE_FAILURE = 'ACCOUNTS_DELETE_ROLE_TEMPLATE_FAILURE';
export const ACCOUNTS_DELETE_ROLE_TEMPLATE_DISMISS_ERROR = 'ACCOUNTS_DELETE_ROLE_TEMPLATE_DISMISS_ERROR';
export const ACCOUNTS_ADD_ROLE_TEMPLATE_BEGIN = 'ACCOUNTS_ADD_ROLE_TEMPLATE_BEGIN';
export const ACCOUNTS_ADD_ROLE_TEMPLATE_SUCCESS = 'ACCOUNTS_ADD_ROLE_TEMPLATE_SUCCESS';
export const ACCOUNTS_ADD_ROLE_TEMPLATE_FAILURE = 'ACCOUNTS_ADD_ROLE_TEMPLATE_FAILURE';
export const ACCOUNTS_ADD_ROLE_TEMPLATE_DISMISS_ERROR = 'ACCOUNTS_ADD_ROLE_TEMPLATE_DISMISS_ERROR';
export const ACCOUNTS_UPDATE_ROLE_TEMPLATE_BEGIN = 'ACCOUNTS_UPDATE_ROLE_TEMPLATE_BEGIN';
export const ACCOUNTS_UPDATE_ROLE_TEMPLATE_SUCCESS = 'ACCOUNTS_UPDATE_ROLE_TEMPLATE_SUCCESS';
export const ACCOUNTS_UPDATE_ROLE_TEMPLATE_FAILURE = 'ACCOUNTS_UPDATE_ROLE_TEMPLATE_FAILURE';
export const ACCOUNTS_UPDATE_ROLE_TEMPLATE_DISMISS_ERROR = 'ACCOUNTS_UPDATE_ROLE_TEMPLATE_DISMISS_ERROR';
export const ACCOUNTS_GET_TITLE_COSTS_BEGIN = 'ACCOUNTS_GET_TITLE_COSTS_BEGIN';
export const ACCOUNTS_GET_TITLE_COSTS_SUCCESS = 'ACCOUNTS_GET_TITLE_COSTS_SUCCESS';
export const ACCOUNTS_GET_TITLE_COSTS_FAILURE = 'ACCOUNTS_GET_TITLE_COSTS_FAILURE';
export const ACCOUNTS_GET_TITLE_COSTS_DISMISS_ERROR = 'ACCOUNTS_GET_TITLE_COSTS_DISMISS_ERROR';
export const ACCOUNTS_SET_TITLE_COSTS_BEGIN = 'ACCOUNTS_SET_TITLE_COSTS_BEGIN';
export const ACCOUNTS_SET_TITLE_COSTS_SUCCESS = 'ACCOUNTS_SET_TITLE_COSTS_SUCCESS';
export const ACCOUNTS_SET_TITLE_COSTS_FAILURE = 'ACCOUNTS_SET_TITLE_COSTS_FAILURE';
export const ACCOUNTS_SET_TITLE_COSTS_DISMISS_ERROR = 'ACCOUNTS_SET_TITLE_COSTS_DISMISS_ERROR';
