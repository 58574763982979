import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from '@material-ui/core';

export class SelectionLists extends PureComponent {
  static propTypes = {
    lists: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectList: PropTypes.func.isRequired,
    selectedListName: PropTypes.string.isRequired,
    disableUnselected: PropTypes.bool.isRequired,
  };

  selectList = field => () => {
    const { selectList, selectedListName } = this.props;
    selectList(field.name === selectedListName ? '' : field);
  };

  render() {
    const { lists, selectedListName, disableUnselected } = this.props;
    const classes = { selected: 'list-item-selected' };

    return (
      <div className="account-settings-selection-lists">
        <p className="subtext">Select a list below to view and edit their content</p>

        <List component="nav">
          <ListSubheader classes={{ root: 'selection-lists__subheader' }} disableGutters>Lists</ListSubheader>
          <Divider />
          {lists.filter(list => list.name && (list.definedValues.length > 0 || list.name === selectedListName)).map(field => (
            <ListItem
              key={field.name}
              classes={classes}
              disabled={disableUnselected && selectedListName !== '' && selectedListName !== field.name}
              selected={selectedListName === field.Name}
              onClick={this.selectList(field)}
              button
              divider
              disableGutters
              disableRipple
            >
              <ListItemText
                classes={{ root: 'list-item__text' }}
                primary={field.name}
                secondary={`${field.definedValues.length} List Item${field.definedValues.length === 1 ? '' : 's'}`}
              />
            </ListItem>
          ))}
        </List>
      </div>
    );
  }
}
export default SelectionLists;
