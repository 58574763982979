import {
  WRAPPED_COMPONENTS_TOGGLE_ADDRESS_SUGGESTION_SELECTED,
} from './constants';

export function toggleAddressSuggestionSelected() {
  return {
    type: WRAPPED_COMPONENTS_TOGGLE_ADDRESS_SUGGESTION_SELECTED,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case WRAPPED_COMPONENTS_TOGGLE_ADDRESS_SUGGESTION_SELECTED:
      return {
        ...state,
        placeSuggestions: {
          ...state.placeSuggestions,
          isAddressSuggestionSelected: !state.placeSuggestions.isAddressSuggestionSelected,
        },
      };

    default:
      return state;
  }
}
