import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import classNames from 'classnames';
import deepEqual from 'react-fast-compare';
import {
  AllocationDetailRow,
  ConfirmAllocationChange,
  ConfirmDeleteRole,
} from 'src/features/allocations';
import { validateAllocationUpdate } from 'src/features/people/utils/unavailabilityUtils';
import { sortSegmentsByStartDate } from 'src/utils/dateSegmentUtils';
import { formatProjectRoles } from 'src/features/projects/utils/projectRolesUtils';
import {
  deleteProjectRole,
  sendRoleNotification,
  addProjectRoles,
} from './redux/actions';
import { trackAnalytics } from '../common/redux/actions';
import { RoleDetailRow, FloatingActionsProjectRole, CommunicateAssignmentRemovalOrReplacementModal } from '.';
import {
  DATE_INPUT_FORMAT,
  ACCOUNT_MODULE_ASSIGNMENT_COMMUNICATION,
  COMPONENT_ASSIGNMENT_COMMUNICATION_EMAIL,
} from '../../common/constants';
import { allocationUpdateMessage } from '../allocations/utils/allocationUtils';
import {
  ALLOCATION_REMOVED,
  INCLUDE_PROJECT_ADDRESS,
  INCLUDE_ROLE_NAME,
  ROLE_NOTIFY_EMAIL,
  ROLE_NOTIFY_TYPE_CLEAR,
} from './constants';
import { hasModuleEnabled } from '../permissions/utils/permissionUtils';
import { PROJECT_ROLE_DUPLICATED } from '../../analytics/projects/constants';

export class ProjectRole extends PureComponent {
  static propTypes = {
    accountId: PropTypes.number.isRequired,
    peopleEntities: PropTypes.arrayOf(PropTypes.object).isRequired,
    unavailabilities: PropTypes.arrayOf(PropTypes.object).isRequired,
    role: PropTypes.object.isRequired,
    selectedProject: PropTypes.object.isRequired,
    openPopper: PropTypes.func.isRequired,
    editingRole: PropTypes.bool.isRequired,
    onEditRoleToggle: PropTypes.func.isRequired,
    deleteProjectRole: PropTypes.func.isRequired,
    addProjectRoles: PropTypes.func.isRequired,
    onDeleteAllocation: PropTypes.func.isRequired,
    allocationChangePending: PropTypes.bool.isRequired,
    onUpdateAllocation: PropTypes.func.isRequired,
    runsInModal: PropTypes.bool,
    sendRoleNotification: PropTypes.func.isRequired,
    sendRoleNotificationPending: PropTypes.bool.isRequired,
    accountModules: PropTypes.arrayOf(PropTypes.object).isRequired,
    getExistingAllocations: PropTypes.func.isRequired,
    projectAllocations: PropTypes.object.isRequired,
    getProjectAllocationsPending: PropTypes.bool.isRequired,
    updateProjectRoleAllocationPending: PropTypes.bool.isRequired,
    trackAnalytics: PropTypes.func.isRequired,
  };

  static defaultProps = {
    runsInModal: false,
  };

  constructor(props) {
    super(props);

    const existingAllocations = props.getExistingAllocations(props.projectAllocations, props.role);

    this.state = {
      valid: false,
      confirmDeleteAllocation: false,
      confirmDeleteRole: false,
      confirmUpdateAllocation: null,
      allocationPersonName: '',
      onDeleteAllocationCallback: null,
      editingAllocation: null,
      allocationChange: null,
      confirmOverlapUpdate: false,
      overlapMessage: null,
      editMode: null,
      selectOpen: false,
      touched: false,
      existingAllocations,
      isNotifyModalShown: false,
    };
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { projectAllocations, getProjectAllocationsPending, allocationChangePending, sendRoleNotificationPending } = this.props;

    if ((allocationChangePending && !nextProps.allocationChangePending)
      || (getProjectAllocationsPending && !nextProps.getProjectAllocationsPending)) {
      this.onConfirmCancel();
    }

    if (!deepEqual(projectAllocations, nextProps.projectAllocations)) {
      this.setState({
        existingAllocations: nextProps.getExistingAllocations(nextProps.projectAllocations, nextProps.role),
      });
    }

    if (sendRoleNotificationPending && !nextProps.sendRoleNotificationPending) {
      this.hideNotifyModal();
    }
  }

  onEditToggle = field => () => {
    const { onEditRoleToggle } = this.props;
    this.onConfirmCancel();
    onEditRoleToggle(true);
    this.setState({
      editMode: field,
      valid: false,
    });
  }

  onEditNoteToggle = () => {
    const { onEditRoleToggle } = this.props;

    onEditRoleToggle(true);
    this.setState({
      editMode: 'note',
    });
  }

  onEditAllocation = (allocation) => {
    const { onEditRoleToggle } = this.props;
    this.onConfirmCancel();
    onEditRoleToggle(true);
    this.setState({
      editingAllocation: allocation,
    });
  }

  onEditAllocationCancel = () => {
    this.onConfirmCancel();
    this.setState({
      editingAllocation: null,
      allocationChange: null,
    });
  }

  onAllocationChange = (change) => {
    this.setState({
      allocationChange: change,
    });
  }

  onDeleteAllocationConfirm = (personName, onDeleteAllocationCallback) => {
    this.setState({
      confirmDeleteAllocation: true,
      allocationPersonName: personName,
      onDeleteAllocationCallback,
    });
  }

  hasAssignmentCommunicationAccess = () => {
    const { accountModules } = this.props;
    return hasModuleEnabled(
      accountModules,
      ACCOUNT_MODULE_ASSIGNMENT_COMMUNICATION,
      COMPONENT_ASSIGNMENT_COMMUNICATION_EMAIL,
    );
  }

  onDeleteRoleConfirm = () => {
    const { existingAllocations } = this.state;

    if (existingAllocations.some(alloc => !alloc.isUnfilled)) {
      this.setState({
        confirmDeleteRole: true,
      });
    } else {
      this.onDeleteRole();
    }
  }

  onConfirmCancel = () => {
    const { onEditRoleToggle } = this.props;

    onEditRoleToggle(false);
    this.setState({
      confirmDeleteAllocation: false,
      confirmDeleteRole: false,
      confirmUpdateAllocation: null,
      confirmOverlapUpdate: false,
      overlapMessage: null,
      editingAllocation: null,
      allocationChange: null,
      editMode: null,
      touched: false,
      valid: false,
    });
  }

  onDeleteRole = () => {
    const { accountId, selectedProject, role, deleteProjectRole, runsInModal } = this.props;
    const { existingAllocations } = this.state;
    const deletedFrom = runsInModal ? 'Project Details Modal' : 'Project List';
    const analyticsPayload = {
      projectName: selectedProject.name,
      projectId: selectedProject.id,
      projectStatus: selectedProject.state,
      projectType: selectedProject.type,
      hasAllocatedPeople: existingAllocations.length > 0,
      deletedFrom,
    };

    deleteProjectRole(accountId, selectedProject.id, role.id, analyticsPayload);
  }

  onDuplicateRole = () => {
    const { role, runsInModal, accountId, selectedProject, addProjectRoles, trackAnalytics } = this.props;

    const formattedRole = formatProjectRoles(role);
    const parentName = runsInModal ? 'Project Details Modal' : 'Project List';

    addProjectRoles(accountId, selectedProject.id, formattedRole, parentName);

    const analyticsPayload = {
      'Project name': selectedProject.name,
      'Project id': selectedProject.id,
      'Role name': role.name,
      'With note': !!role.note,
      'With custom allocation': role.requirements.length > 1,
    };

    trackAnalytics(PROJECT_ROLE_DUPLICATED, analyticsPayload);
  }

  onUpdateRoleName = () => {
    this.setState({
      valid: true,
      touched: true,
    });
  }

  onUpdateRoleNote = (note) => {
    const { role } = this.props;

    this.setState({
      valid: true,
      touched: role.note !== note,
    });
  }

  onNumberInputChange = (value, isValid) => {
    const valid = isValid && !Number.isNaN(parseInt(value, 10)) && value !== 0;

    this.setState({
      touched: true,
      valid,
    });
  }

  confirmUpdateAllocation = async () => {
    const { editingAllocation, allocationChange } = this.state;
    if (!allocationChange) {
      this.onEditAllocationCancel();
      return;
    }

    const { projectAllocations, role, selectedProject, peopleEntities, unavailabilities } = this.props;
    const { startDate, endDate } = allocationChange;
    const selectedPerson = peopleEntities.find(person => person.id === editingAllocation.personId);
    const pous = unavailabilities.find(person => person.personId === editingAllocation.personId);
    const data = {
      startDate: moment(editingAllocation.startDate).format(DATE_INPUT_FORMAT),
      endDate: moment(editingAllocation.endDate).format(DATE_INPUT_FORMAT),
    };
    const oldStartDate = moment(editingAllocation.startDate);
    const sortedRoleAllocations = sortSegmentsByStartDate(projectAllocations[selectedProject.id].find(a => a.roleId === role.id).allocations);
    const allocationIndex = sortedRoleAllocations.findIndex(a => a.startDate === oldStartDate.format(DATE_INPUT_FORMAT));

    let othersAffected = false;
    if (startDate) {
      const previousAllocation = sortedRoleAllocations[allocationIndex - 1];
      othersAffected = previousAllocation && (moment(previousAllocation.endDate) >= startDate);
      data.startDate = moment(startDate).format(DATE_INPUT_FORMAT);
    }

    if (endDate) {
      const nextAllocation = sortedRoleAllocations[allocationIndex + 1];
      othersAffected = nextAllocation && (moment(nextAllocation.startDate) <= endDate);
      data.endDate = moment(endDate).format(DATE_INPUT_FORMAT);
    }

    const overlap = await validateAllocationUpdate(selectedPerson && selectedPerson.employmentDates, pous && pous.unavailabilities, data);

    if (!othersAffected && !overlap) {
      this.updateAllocation();
    } else if (overlap && overlap.length) {
      this.setState({
        confirmOverlapUpdate: true,
        overlapMessage: overlap,
      });
    } else {
      this.setState({
        confirmUpdateAllocation: {
          othersAffected,
          changeType: startDate ? 'start date' : 'end date',
          allocationIndex,
        },
      });
    }
  }

  updateAllocation = () => {
    const { accountId, selectedProject, onUpdateAllocation, role } = this.props;
    const { allocationChange, editingAllocation } = this.state;
    onUpdateAllocation(accountId, selectedProject.id, role.id, editingAllocation, allocationChange);
  }

  showNotifyModal = () => this.setState({ isNotifyModalShown: true });

  hideNotifyModal = () => this.setState({ isNotifyModalShown: false });

  buildAllocationConfirmObj = () => {
    const { allocationChangePending, peopleEntities, role } = this.props;
    const {
      editingAllocation,
      confirmUpdateAllocation,
      confirmDeleteAllocation,
      allocationPersonName,
      confirmOverlapUpdate,
      overlapMessage,
      onDeleteAllocationCallback,
    } = this.state;

    if (!confirmDeleteAllocation && !confirmUpdateAllocation && !confirmOverlapUpdate) return { open: false };

    const { isCommunicated } = role;
    const { endDate } = editingAllocation;

    const allocationIsPast = moment(endDate).isBefore(moment(), 'date');

    let primaryAction;

    if (isCommunicated && !allocationIsPast && this.hasAssignmentCommunicationAccess()) {
      primaryAction = this.showNotifyModal;
    } else if (confirmDeleteAllocation) {
      primaryAction = onDeleteAllocationCallback;
    } else {
      primaryAction = this.updateAllocation;
    }

    let personName;
    if (confirmDeleteAllocation) {
      personName = allocationPersonName;
    } else {
      personName = editingAllocation.state === 'Active' ? peopleEntities.find(p => p.id === editingAllocation.personId).name : editingAllocation.name;
    }

    const warningMessage = allocationUpdateMessage(confirmOverlapUpdate, confirmDeleteAllocation, confirmUpdateAllocation, personName, overlapMessage);
    const { primaryActionText, message, subMessage } = warningMessage;

    return {
      open: !!confirmDeleteAllocation || !!confirmUpdateAllocation || !!confirmOverlapUpdate,
      loading: allocationChangePending,
      primaryActionText,
      primaryAction,
      secondaryAction: this.onConfirmCancel,
      message,
      subMessage,
    };
  }

  onDateChange = (date, field) => {
    this.setState({
      editMode: field,
      touched: true,
    });
  }

  onSelectOpen = () => {
    this.setState({
      selectOpen: true,
    });
  };

  onSelectClose = () => {
    this.setState({
      selectOpen: false,
    });
  };

  onClickAway = () => {
    const { selectOpen, touched } = this.state;
    if (!selectOpen && !touched) {
      this.onConfirmCancel();
    }
  };

  onDateError = (error = '') => {
    const { valid, touched } = this.state;
    const isValid = !error.length && touched;
    if (isValid !== valid) {
      this.setState({
        valid: isValid,
      });
    }
  }

  handleDeleteAllocationWithoutNotify = () => {
    const { onDeleteAllocationCallback } = this.state;

    onDeleteAllocationCallback();

    this.hideNotifyModal();
  }

  onDeleteAllocationWithoutConfirm = (deleteAllocation) => {
    const { role } = this.props;
    const { isCommunicated } = role;

    if (isCommunicated && this.hasAssignmentCommunicationAccess()) {
      this.setState({
        onDeleteAllocationCallback: deleteAllocation,
        isNotifyModalShown: true,
      });
    } else {
      deleteAllocation();
    }
  }

  onDeleteAllocationAndNotify = (message, standardMessage, options) => {
    const { selectedProject, role, sendRoleNotification, accountId } = this.props;
    const { existingAllocations, editingAllocation, onDeleteAllocationCallback } = this.state;

    const { id: projectId } = selectedProject;
    const { id: roleId } = role;
    const { personId } = editingAllocation;

    const data = [{
      personId,
      method: ROLE_NOTIFY_EMAIL,
      type: ROLE_NOTIFY_TYPE_CLEAR,
      ...(message ? { message } : {}),
      includeProjectAddress: options[INCLUDE_PROJECT_ADDRESS],
      includeRoleName: options[INCLUDE_ROLE_NAME],
    }];

    const analyticsPayload = {
      project: selectedProject,
      role,
      allocations: existingAllocations,
      allocation: editingAllocation,
      options,
      allocationChangeType: ALLOCATION_REMOVED,
    };

    sendRoleNotification(accountId, projectId, roleId, data, analyticsPayload, onDeleteAllocationCallback);

    this.setState({
      onDeleteAllocationCallback: null,
    });
  }

  render() {
    const {
      peopleEntities,
      role,
      selectedProject,
      openPopper,
      onDeleteAllocation,
      editingRole,
      allocationChangePending,
      runsInModal,
      getProjectAllocationsPending,
      updateProjectRoleAllocationPending,
    } = this.props;
    const {
      confirmDeleteRole,
      editingAllocation,
      editMode,
      valid,
      existingAllocations,
      isNotifyModalShown,
    } = this.state;

    const {
      open,
      loading,
      primaryActionText,
      primaryAction,
      secondaryAction,
      message,
      subMessage,
    } = this.buildAllocationConfirmObj();

    const editNoteMode = editMode === 'note';

    const roleClass = classNames(
      'projects-project-role',
      {
        'role-disabled': !editNoteMode && (editingRole || editMode || editingAllocation || allocationChangePending),
        confirming: confirmDeleteRole,
      },
      { 'editing-note': editNoteMode },
    );

    return (
      <div className={roleClass}>
        {isNotifyModalShown && (
          <CommunicateAssignmentRemovalOrReplacementModal
            role={role}
            selectedProject={selectedProject}
            primaryAction={this.onDeleteAllocationAndNotify}
            secondaryAction={this.handleDeleteAllocationWithoutNotify}
            allocation={editingAllocation}
            type={ALLOCATION_REMOVED}
          />
        )}

        <div className="project-role-assignment">
          <RoleDetailRow
            role={role}
            editMode={editMode}
            selectedProject={selectedProject}
            valid={valid}
            existingAllocations={existingAllocations}
            onUpdateRoleName={this.onUpdateRoleName}
            onUpdateRoleNote={this.onUpdateRoleNote}
            onEditNoteToggle={this.onEditNoteToggle}
            onSelectOpen={this.onSelectOpen}
            onSelectClose={this.onSelectClose}
            onConfirmCancel={this.onConfirmCancel}
            onEditToggle={this.onEditToggle}
            onClickAway={this.onClickAway}
            onDateChange={this.onDateChange}
            onDateError={this.onDateError}
            onNumberInputChange={this.onNumberInputChange}
            runsInModal={runsInModal}
          />

          <AllocationDetailRow
            people={peopleEntities}
            role={role}
            selectedProject={selectedProject}
            allocations={existingAllocations}
            openPopper={openPopper}
            onDeleteAllocationConfirm={this.onDeleteAllocationConfirm}
            onDeleteAllocation={onDeleteAllocation}
            onEditAllocation={this.onEditAllocation}
            editingAllocation={!!editingAllocation}
            onAllocationChange={this.onAllocationChange}
            onClickAway={this.onClickAway}
            updateProjectRoleAllocationPending={updateProjectRoleAllocationPending}
            getProjectAllocationsPending={getProjectAllocationsPending}
            confirmUpdateAllocation={this.confirmUpdateAllocation}
            onEditAllocationCancel={this.onEditAllocationCancel}
            runsInModal={runsInModal}
            onDeleteAllocationWithoutConfirm={this.onDeleteAllocationWithoutConfirm}
          />

          <ConfirmAllocationChange
            open={open}
            loading={loading}
            primaryActionText={primaryActionText}
            primaryAction={primaryAction}
            secondaryAction={secondaryAction}
            message={message}
            subMessage={subMessage}
          />
        </div>

        <FloatingActionsProjectRole
          onDeleteRole={this.onDeleteRoleConfirm}
          onDuplicateRole={this.onDuplicateRole}
          selectedProject={selectedProject}
          role={role}
          allocations={existingAllocations}
          runsInModal={runsInModal}
        />

        {confirmDeleteRole && (
          <ConfirmDeleteRole
            role={role}
            onDelete={this.onDeleteRole}
            onCancel={this.onConfirmCancel}
          />
        )}
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps({ common, projects, people, accountSettings }) {
  const { accountId } = common;
  const { accountModules } = accountSettings;
  const {
    deleteProjectAllocationPending,
    updateProjectRoleAllocationPending,
    addProjectRolesPending,
    deleteProjectRolePending,
    sendRoleNotificationPending,
    projectAllocations,
    getProjectAllocationsPending,
  } = projects;
  const { entities: peopleEntities, unavailabilities } = people;

  const allocationChangePending =
    deleteProjectAllocationPending ||
    updateProjectRoleAllocationPending ||
    addProjectRolesPending ||
    deleteProjectRolePending;

  return {
    accountId,
    allocationChangePending,
    sendRoleNotificationPending,
    accountModules,
    projectAllocations,
    getProjectAllocationsPending,
    updateProjectRoleAllocationPending,
    peopleEntities,
    unavailabilities,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    deleteProjectRole: bindActionCreators(deleteProjectRole, dispatch),
    sendRoleNotification: bindActionCreators(sendRoleNotification, dispatch),
    addProjectRoles: bindActionCreators(addProjectRoles, dispatch),
    trackAnalytics: bindActionCreators(trackAnalytics, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectRole);
