import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Snackbar, Button } from '@material-ui/core';
import { dismissError } from './redux/actions';
import { BENCH_SUPPORT_URL, CUSTOM_MESSAGES, DEFAULT } from './redux/constants';
import { errorBlacklist } from './blacklist';

const ErrorSnackbar = ({ error, dismissError }) => {
  const { messageId, message } = error || {};

  if (!error) return null;

  // If this message is blacklisted, don't show the snackbar
  if (errorBlacklist.includes(message)) return null;

  const content =
    messageId === DEFAULT ? (
      <>
        {`Sorry, there was a technical issue and we were unable to ${message}. Please try again or `}
        <a href={BENCH_SUPPORT_URL} target="_blank" rel="noopener noreferrer">
          contact support
        </a>
      </>
    ) : (
      CUSTOM_MESSAGES[messageId]
    );

  return (
    <Snackbar
      open={!!error}
      onClose={dismissError}
      ClickAwayListenerProps={{ mouseEvent: false }}
      className="errors-error-snackbar"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <div className="container">
        <span className="content">{content}</span>
        <Button disableRipple onClick={dismissError}>Close</Button>
      </div>
    </Snackbar>
  );
};

ErrorSnackbar.propTypes = {
  error: PropTypes.object,
  dismissError: PropTypes.func.isRequired,
};

ErrorSnackbar.defaultProps = {
  error: null,
};

/* istanbul ignore next */
function mapStateToProps({ errors }) {
  const { error } = errors;
  return {
    error,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    dismissError: bindActionCreators(dismissError, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ErrorSnackbar);
