import { gql } from 'apollo-boost';
import { generateRoleListRequest } from 'src/filters/roleListFilter';
import { MAX_SIZE } from '../../../common/constants';
import { generateRoleSort } from '../../../filters/generateRoleSort';

const ROLES_QUERY = gql`
  query rolesQuery(
    $from: Int,
    $size: Int,
    $filters: [ESQuery],
    $accountId: Int!,
    $structuredSort: [RoleSegmentSort]
  ) {
    roleSegments(
      from: $from,
      size: $size,
      filters: $filters,
      accountId: $accountId,
      structuredSort: $structuredSort,
    ) {
      count
      hasMore
      cursor
      items {
        roleId
        roleName
        note
        endDate
        startDate
        sortOrder
        project {
          id
          name
          state
          colour
        }
        requirements {
          endDate
          percent
          startDate
        }
      }
    }
  }
`;

export const getFilteredRolesQuery = (
  accountId,
  projectQueries,
  timeframeFilter,
  sortQuery,
  from,
  size = MAX_SIZE,
) => {
  const filters = generateRoleListRequest(projectQueries, timeframeFilter);

  const variables = {
    from,
    size,
    filters,
    accountId,
    structuredSort: generateRoleSort(sortQuery),
  };

  return {
    query: ROLES_QUERY,
    variables,
  };
};
