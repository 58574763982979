import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize } from '../../../utils/formatters';
import { trackAnalytics } from '../../common/redux/trackAnalytics';
import { getSavedFilters } from '../../queries/redux/actions';
import { deleteSavedFilter as deleteFilter } from '../../queries/redux/deleteSavedFilter';
import { getFiltersInfo } from './filterUtils';
import useRememberedFilters from './useRememberedFilters';

export default function useSavedFilters({ accountId, userId, viewId, filterType, isPursuitsEnabled, trackingContext = {} } = {}) {
  const dispatch = useDispatch();
  const queries = useSelector(({ queries }) => queries);

  const { applyFilter, updateCurrentFilter, onFilterDeleted } = useRememberedFilters({
    accountId,
    userId,
    viewId,
    isPursuitsEnabled,
    trackingContext,
  });

  const {
    savedFilters,
    deleteSavedFilterPending,
    deleteSavedFilterError,
    getSavedFiltersPending,
    getSavedFiltersError,
  } = queries;

  const isReady = !getSavedFiltersPending && !getSavedFiltersError && !deleteSavedFilterPending && !deleteSavedFilterError;
  const { viewType } = trackingContext;

  useEffect(() => {
    dispatch(getSavedFilters(accountId, filterType));
  }, [dispatch, filterType, accountId]);

  const [filterToDelete, setFilterToDelete] = useState(null);

  const deletePending = useRef(deleteSavedFilterPending);
  useEffect(() => {
    if (deletePending.current && !deleteSavedFilterPending && !deleteSavedFilterError) {
      onFilterDeleted?.(filterToDelete);
      setFilterToDelete(null);
    }
    deletePending.current = deleteSavedFilterPending;
  }, [deleteSavedFilterPending, deleteSavedFilterError, filterToDelete, onFilterDeleted]);

  const deleteSavedFilter = useCallback(filter => setFilterToDelete(filter), []);

  const confirmDeleteSavedFilter = useCallback(() => {
    const { filterId, filterName, filterType, args } = filterToDelete || {};
    if (!filterId) return;
    dispatch(deleteFilter({ accountId, filterId }));

    if (viewType) {
      const { properties, values, valueCount } = getFiltersInfo(args);

      dispatch(trackAnalytics('Saved Filter Deleted', {
        'Saved filter name': filterName,
        'Saved filter type': capitalize(filterType),
        'Filter property count': properties.length,
        'Filter properties': properties.join(', '),
        'Filter value count': valueCount,
        'Filter values': values,
        'View deleted from': viewType,
      }));
    }
  }, [dispatch, accountId, filterToDelete, viewType]);

  const cancelDeleteSavedFilter = useCallback(() => setFilterToDelete(null), []);

  return {
    applyFilter,
    updateCurrentFilter,
    savedFilters,
    isSavedFiltersReady: isReady,
    confirmDeleteSavedFilter,
    filterToDelete,
    cancelDeleteSavedFilter,
    deleteSavedFilter,
  };
}
