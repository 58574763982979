import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNT_SETTINGS_ADD_ACCOUNT_MODULE_BEGIN,
  ACCOUNT_SETTINGS_ADD_ACCOUNT_MODULE_SUCCESS,
  ACCOUNT_SETTINGS_ADD_PURSUIT_TRACKING_MODULE_SUCCESS,
  ACCOUNT_SETTINGS_ADD_ACCOUNT_MODULE_FAILURE,
  ACCOUNT_SETTINGS_ADD_ACCOUNT_MODULE_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';
import { ACCOUNT_MODULE_PURSUIT_TRACKING } from '../../../common/constants';

export function addAccountModule(accountId, data, moduleName) {
  return {
    type: ACCOUNT_SETTINGS_ADD_ACCOUNT_MODULE_BEGIN,
    accountId,
    data,
    moduleName,
  };
}

export function dismissAddAccountModuleError() {
  return {
    type: ACCOUNT_SETTINGS_ADD_ACCOUNT_MODULE_DISMISS_ERROR,
  };
}

export function* doAddAccountModule(action) {
  const { accountId, data, moduleName } = action;
  try {
    yield call(protectedJsonPost, `accounts/${accountId}/modules`, data);
  } catch (err) {
    yield put({
      type: ACCOUNT_SETTINGS_ADD_ACCOUNT_MODULE_FAILURE,
      data: { error: err },
    });
    return;
  }

  if (moduleName === ACCOUNT_MODULE_PURSUIT_TRACKING) {
    yield put({
      type: ACCOUNT_SETTINGS_ADD_PURSUIT_TRACKING_MODULE_SUCCESS,
      moduleName,
      accountId,
    });
  }

  yield put({
    type: ACCOUNT_SETTINGS_ADD_ACCOUNT_MODULE_SUCCESS,
    data,
    moduleName,
  });
}

export function* watchAddAccountModule() {
  yield takeLatest(ACCOUNT_SETTINGS_ADD_ACCOUNT_MODULE_BEGIN, doAddAccountModule);
}

export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNT_SETTINGS_ADD_ACCOUNT_MODULE_BEGIN:
      return {
        ...state,
        addAccountModulePending: true,
        addAccountModuleError: null,
      };

    case ACCOUNT_SETTINGS_ADD_ACCOUNT_MODULE_SUCCESS: {
      const { moduleName, data } = action;
      return {
        ...state,
        accountModules: [
          ...state.accountModules,
          {
            name: moduleName,
            ...data,
          },
        ],
        addAccountModulePending: false,
        addAccountModuleError: null,
      };
    }

    case ACCOUNT_SETTINGS_ADD_ACCOUNT_MODULE_FAILURE:
      return {
        ...state,
        addAccountModulePending: false,
        addAccountModuleError: action.data.error,
      };

    case ACCOUNT_SETTINGS_ADD_ACCOUNT_MODULE_DISMISS_ERROR:
      return {
        ...state,
        addAccountModuleError: null,
      };

    default:
      return state;
  }
}
