import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { STICKY_COLUMN_WIDTH } from './redux/constants';

const GanttReportGrid = ({
  columnCount,
  columnWidth,
}) => {
  const renderGridColumns = () => {
    const gridColumns = [];
    let left = STICKY_COLUMN_WIDTH - 2;
    for (let index = 0; index < columnCount; index += 1) {
      const style = {
        left,
        width: columnWidth,
      };
      const gridColumn = <div className="grid-column" key={index} style={style} />;
      gridColumns.push(gridColumn);
      left += columnWidth;
    }
    return gridColumns;
  };

  return (
    <div className="reports-gantt-report-grid">
      {renderGridColumns()}
    </div>
  );
};

GanttReportGrid.propTypes = {
  columnCount: PropTypes.number.isRequired,
  columnWidth: PropTypes.number.isRequired,
};

export default memo(GanttReportGrid);
