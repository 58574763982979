import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MenuItem } from '@material-ui/core';
import { AttachmentItem } from '@bridgit/foundation';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { MULTI_STATE_MODAL_ID } from '../common/redux/constants';
import { PEOPLE_LIST_SELECTION_ID } from './redux/constants';
import {
  ATTACHMENT_STATE_MAP,
  DEFAULT_REQUEST_DELAY,
  REQUEST_DELAY_MULTIPLIER,
  MAX_POLLING_TIME,
  PROFILE_DETAILS_MODAL_CONTEXT,
  PROFILE_DETAILS_PROFILE_CONTEXT,
  ATTACHMENT_COMPLETED_STATE,
  ATTACHMENT_PROCESSING_STATE,
  ATTACHMENT_POLLING_STATE,
  ATTACHMENT_FAILED_STATE,
  PERSON_CERTIFICATIONS_TAB_NAME,
  PERSON_ATTACHMENTS_TAB_NAME,
} from './constants';
import { Can } from '../wrapped-components';
import { PERM_PERSON, PERM_WRITE } from '../permissions/utils/constants';
import {
  getPersonAttachmentById,
  deletePersonAttachment,
  downloadPersonAttachment,
} from './redux/actions';
import { Confirm } from '../common';
import { getFileExtension } from '../../utils/miscUtils';
import { PRIVATE_MODE_TOOLTIP_TEXT } from '../../common/constants';

const ProfileAttachment = ({
  id,
  name,
  createdOn,
  binaryUrl,
  isPrivate,
  state,
  size,
  numOfLoadedAttachments,
  hasMore,
  certificationId,
  activeTab,
  caption,
}) => {
  const dispatch = useDispatch();

  const { accountId, privateModeEnabled } = useSelector(({ common }) => common);

  const isOpenInModal = useSelector(({ modalManager }) => modalManager.activeModal === MULTI_STATE_MODAL_ID);

  const parentCertification = useSelector(({ accountSettings }) => accountSettings.certifications.find(({ id }) => id === certificationId));

  const { personId, personName } = useSelector(({ people: { personSelections } }) => {
    const selectedPerson = isOpenInModal ? personSelections?.[MULTI_STATE_MODAL_ID] : personSelections?.[PEOPLE_LIST_SELECTION_ID];

    return {
      personId: selectedPerson?.id,
      personName: selectedPerson?.name,
    };
  });

  const [attachmentState, setAttachmentState] = useState(ATTACHMENT_STATE_MAP[state]);
  const [delay, setDelay] = useState(DEFAULT_REQUEST_DELAY);
  const [pollingTimeElapsed, setPollingTimeElapsed] = useState(0);
  const [isConfirmationDialogShown, setIsConfirmationDialogShown] = useState(false);

  const handleGetPersonAttachmentById = useCallback(() => dispatch(getPersonAttachmentById(accountId, personId, id)),
    [accountId, dispatch, id, personId]);

  useEffect(() => {
    if (attachmentState === ATTACHMENT_STATE_MAP.Failed) { // stop sending requests
      return;
    }

    if (!binaryUrl) {
      if (state === ATTACHMENT_FAILED_STATE) {
        setAttachmentState(ATTACHMENT_STATE_MAP[ATTACHMENT_FAILED_STATE]);
      } else if (
        state === ATTACHMENT_POLLING_STATE &&
        pollingTimeElapsed <= MAX_POLLING_TIME
      ) { // keep sending requests with incremented delay
        setDelay(() => {
          setTimeout(() => {
            handleGetPersonAttachmentById();
            setPollingTimeElapsed(currentTimeElapsed => currentTimeElapsed + delay);
          }, delay);

          return delay * REQUEST_DELAY_MULTIPLIER;
        });
      } else { // change the state from Polling to Processing, where manual attachment refresh would be required
        setAttachmentState(ATTACHMENT_STATE_MAP[ATTACHMENT_PROCESSING_STATE]);
      }
    } else if (binaryUrl && attachmentState !== ATTACHMENT_STATE_MAP[ATTACHMENT_COMPLETED_STATE]) {
      setAttachmentState(ATTACHMENT_STATE_MAP[ATTACHMENT_COMPLETED_STATE]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachmentState, binaryUrl, pollingTimeElapsed, handleGetPersonAttachmentById, state]); // removed "delay" from deps to prevent unnecessary re-renders

  const handleDownload = useCallback(() => {
    const analyticsPayload = {
      personName,
      personId,
      downloadedFrom: isOpenInModal ? PROFILE_DETAILS_MODAL_CONTEXT : PROFILE_DETAILS_PROFILE_CONTEXT,
      size,
      fileExtension: getFileExtension(name),
      activeTab,
      isPrivate,
      certificationName: parentCertification?.name,
      isExpiryDateRequired: parentCertification?.requireExpiration,
      warningRange: parentCertification?.daysWarnBeforeExpire,
    };

    dispatch(downloadPersonAttachment(binaryUrl, analyticsPayload));
  }, [activeTab, binaryUrl, parentCertification, dispatch, isOpenInModal, name, personId, personName, size, isPrivate]);

  const handleDeleteAttachment = useCallback(
    () => {
      const analyticsPayload = {
        personName,
        personId,
        deletedFrom: isOpenInModal ? PROFILE_DETAILS_MODAL_CONTEXT : PROFILE_DETAILS_PROFILE_CONTEXT,
        size,
        fileExtension: getFileExtension(name),
        accountId,
        activeTab,
        isPrivate,
        certificationName: parentCertification?.name,
        isExpiryDateRequired: parentCertification?.requireExpiration,
        warningRange: parentCertification?.daysWarnBeforeExpire,
      };

      const newOffset = numOfLoadedAttachments - 1;

      dispatch(deletePersonAttachment(accountId, personId, id, analyticsPayload, newOffset, hasMore, certificationId));

      setIsConfirmationDialogShown(false);
    },
    [
      personName,
      personId,
      isOpenInModal,
      size,
      name,
      accountId,
      activeTab,
      parentCertification,
      numOfLoadedAttachments,
      dispatch,
      id,
      hasMore,
      certificationId,
      isPrivate,
    ],
  );

  const handleRemoveCancel = () => setIsConfirmationDialogShown(false);

  const showConfirmationDialog = () => setIsConfirmationDialogShown(true);

  const attachmentItemOptions = useMemo(() => [
    <MenuItem className="people-profile-attachments-menu-option" onClick={handleDownload} key={`Download-${id}`}>
      Download
    </MenuItem>,
    <Can
      key={`Delete-${id}`}
      action={PERM_WRITE}
      subject={PERM_PERSON}
      yes={(
        <MenuItem
          className="people-profile-attachments-delete"
          onClick={showConfirmationDialog}
        >
          Delete attachment
        </MenuItem>
      )}
    />,
  ],
  [handleDownload, id]);

  return (
    <>
      <AttachmentItem
        key={id}
        fileName={name}
        uploadDate={createdOn}
        options={attachmentItemOptions}
        state={attachmentState}
        onRefresh={handleGetPersonAttachmentById}
        onDelete={handleDeleteAttachment}
        isPrivate={isPrivate}
        isDeleteIconDisabled={false}
        caption={caption}
        privateModeEnabled={privateModeEnabled}
        privacyLabel="Hidden attachment"
        privacyTooltip={PRIVATE_MODE_TOOLTIP_TEXT}
      />

      {isConfirmationDialogShown && (
        <Confirm
          headline="Delete Attachment"
          acceptButtonText="Yes"
          cancelButtonText="Cancel"
          onCancel={handleRemoveCancel}
          onAccept={handleDeleteAttachment}
        >
          <div>
            <p>Are you sure you want to delete this attachment?</p>
            <p>This cannot be undone.</p>
          </div>
        </Confirm>
      )}
    </>
  );
};

ProfileAttachment.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  createdOn: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  state: PropTypes.oneOf(Object.keys(ATTACHMENT_STATE_MAP)).isRequired,
  binaryUrl: PropTypes.string,
  isPrivate: PropTypes.bool.isRequired,
  size: PropTypes.number.isRequired,
  numOfLoadedAttachments: PropTypes.number,
  hasMore: PropTypes.bool,
  certificationId: PropTypes.number,
  activeTab: PropTypes.oneOf([PERSON_CERTIFICATIONS_TAB_NAME, PERSON_ATTACHMENTS_TAB_NAME]),
  caption: PropTypes.string,
};

ProfileAttachment.defaultProps = {
  binaryUrl: null,
  numOfLoadedAttachments: 0,
  hasMore: false,
  certificationId: null,
  activeTab: '',
  caption: null,
};

export default ProfileAttachment;
