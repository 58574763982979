import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Add } from '@material-ui/icons';
import { formatHourlyPersonData } from 'src/utils/formatters';
import { getHourlyPersonFieldsDesc } from 'src/features/self-perform/utils/hourlyPersonUtils';
import { PERM_HOURLY_PERSON, PERM_WRITE } from 'src/features/permissions/utils/constants';
import { FloatingActionButton, Can } from 'src/features/wrapped-components';
import { Modal, FieldInputForm } from 'src/features/common';
import { addHourlyPerson } from 'src/features/self-perform/redux/actions';
import { getHourlyPersonFields, getSkillSet } from 'src/features/account-settings/redux/actions';

const classes = { root: 'add-hourly-person-modal' };

export function AddHourlyPersonModal({
  accountId,
  hourlyPersonFields,
  skillSet,
  addHourlyPersonPending,
  addHourlyPerson,
  getHourlyPersonFields,
  getSkillSet,
}) {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getHourlyPersonFields(accountId);
    getSkillSet(accountId);
  }, [accountId, getHourlyPersonFields, getSkillSet]);

  useEffect(() => {
    if (loading && !addHourlyPersonPending) {
      setShowModal(false);
    }
  }, [loading, addHourlyPersonPending]);

  // Merge system fields with hourly person fields
  const fields = useMemo(() => getHourlyPersonFieldsDesc(hourlyPersonFields, skillSet),
    [hourlyPersonFields, skillSet]);

  const onClick = () => setShowModal(true);

  const onSave = (values) => {
    setLoading(true);
    addHourlyPerson(accountId, formatHourlyPersonData(hourlyPersonFields, values));
  };

  const onCancel = () => setShowModal(false);

  return (
    <div className="self-perform-add-hourly-person">
      <Can
        action={PERM_WRITE}
        subject={PERM_HOURLY_PERSON}
        yes={(
          <FloatingActionButton
            icon={<Add />}
            tooltipText="Add a Person"
            onClick={onClick}
          />
        )}
      />
      {showModal && (
        <Modal
          classes={classes}
          headline="New Self-perform Person"
          showClose={false}
        >
          <FieldInputForm
            fields={fields}
            onSaveText="Add Person"
            onSave={onSave}
            onCancel={onCancel}
            pending={addHourlyPersonPending}
          />
        </Modal>
      )}
    </div>
  );
}

AddHourlyPersonModal.propTypes = {
  accountId: PropTypes.number.isRequired,
  hourlyPersonFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  skillSet: PropTypes.arrayOf(PropTypes.object).isRequired,
  addHourlyPersonPending: PropTypes.bool,
  addHourlyPerson: PropTypes.func.isRequired,
  getHourlyPersonFields: PropTypes.func.isRequired,
  getSkillSet: PropTypes.func.isRequired,
};

AddHourlyPersonModal.defaultProps = {
  addHourlyPersonPending: false,
};

/* istanbul ignore next */
function mapStateToProps({ common, accountSettings, selfPerform }) {
  const { accountId } = common;
  const { hourlyPersonFields, skillSet } = accountSettings;
  const { addHourlyPersonPending } = selfPerform;
  return {
    accountId,
    hourlyPersonFields,
    skillSet,
    addHourlyPersonPending,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    addHourlyPerson: bindActionCreators(addHourlyPerson, dispatch),
    getHourlyPersonFields: bindActionCreators(getHourlyPersonFields, dispatch),
    getSkillSet: bindActionCreators(getSkillSet, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddHourlyPersonModal);
