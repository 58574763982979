import { call, put, takeLatest } from 'redux-saga/effects';
import { protectedJsonDelete } from '../../../utils/api';
import {
  PROJECTS_DELETE_PROJECT_BEGIN,
  PROJECTS_DELETE_PROJECT_SUCCESS,
  PROJECTS_DELETE_PROJECT_FAILURE,
  PROJECTS_DELETE_PROJECT_DISMISS_ERROR,
} from './constants';

export function deleteProject(accountId, projectId, parentName) {
  return {
    type: PROJECTS_DELETE_PROJECT_BEGIN,
    accountId,
    projectId,
    parentName,
  };
}

export function dismissDeleteProjectError() {
  return {
    type: PROJECTS_DELETE_PROJECT_DISMISS_ERROR,
  };
}

export function* doDeleteProject(action) {
  const { accountId, projectId, parentName } = action;
  try {
    yield call(protectedJsonDelete, `accounts/${accountId}/projects/${projectId}`);
  } catch (err) {
    yield put({
      type: PROJECTS_DELETE_PROJECT_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PROJECTS_DELETE_PROJECT_SUCCESS,
    projectId,
    parentName,
  });
}

export function* watchDeleteProject() {
  yield takeLatest(PROJECTS_DELETE_PROJECT_BEGIN, doDeleteProject);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PROJECTS_DELETE_PROJECT_BEGIN:
      return {
        ...state,
        deleteProjectPending: true,
        deleteProjectError: null,
      };

    case PROJECTS_DELETE_PROJECT_SUCCESS: {
      const { projects, filteredProjects, projectSelections } = state;
      const { projectId, parentName } = action;

      const newProjects = projects.filter(project => project.id !== projectId);
      const deletedProject = projects.find(project => project.id === projectId);
      const newFilteredProjects = filteredProjects.filter(project => project.id !== projectId);

      const newProjectSelections = { ...projectSelections };
      Object.keys(newProjectSelections).forEach((selectionId) => {
        const selectedProject = newProjectSelections[selectionId];
        if (selectedProject?.id === projectId) {
          delete newProjectSelections[selectionId];
        }
      });

      // Mixpanel - Track when a project is deleted
      window.mixpanel.track('Project Deleted', {
        'Project Name': deletedProject.name,
        'Project ID': deletedProject.id,
        'Project Status': deletedProject.state,
        'Project type': deletedProject.type,
        'Has Roles': deletedProject.totalRoles > 0,
        'Has Allocated People': deletedProject.totalRoles > deletedProject.unfilledRoles,
        'Project deleted from': parentName,
      });

      return {
        ...state,
        projects: newProjects,
        filteredProjects: newFilteredProjects,
        deleteProjectPending: false,
        deleteProjectError: null,
        projectSelections: newProjectSelections,
      };
    }

    case PROJECTS_DELETE_PROJECT_FAILURE:
      return {
        ...state,
        deleteProjectPending: false,
        deleteProjectError: action.data.error,
      };

    case PROJECTS_DELETE_PROJECT_DISMISS_ERROR:
      return {
        ...state,
        deleteProjectError: null,
      };

    default:
      return state;
  }
}
