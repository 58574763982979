import {
  QUERIES_SET_SORT_QUERY,
} from './constants';

export function setSortQuery(id, query, columnStorageKey = '') {
  if (columnStorageKey !== '') {
    localStorage.setItem(columnStorageKey, JSON.stringify(query));
  }

  return {
    type: QUERIES_SET_SORT_QUERY,
    id,
    query,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case QUERIES_SET_SORT_QUERY: {
      const { id, query } = action;
      const newState = { ...state };
      newState[id] = {
        ...newState[id],
        sort: query,
      };
      return newState;
    }

    default:
      return state;
  }
}
