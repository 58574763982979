import { gql } from 'apollo-boost';

const UPDATE_PROJECT_DATES_QUERY = gql`
  mutation updateProjectDuration(
    $accountId: Int!
    $projectId: Int!
    $startDate: Date
    $endDate: Date
    $phases: [UpdatePhaseInput]
    $roles: [UpdateRoleInput]
  )  {
    updateProjectDuration(
      accountId: $accountId
      projectId: $projectId
      startDate: $startDate
      endDate: $endDate
      phases: $phases
      roles: $roles
    ) {
      id
      name
      colour
      startDate
      endDate
      state
      unfilledRoles
      totalRoles
      issuesCount
      lastModifiedOn
      phases {
        id
        name
        startDate
        endDate
        subPhases {
          id
          name
          startDate
          endDate
        }
      }
      currentPhases {
        id
        name
        startDate
        endDate
        subPhases {
          id
          name
          startDate
          endDate
        }
      }
      roles {
        id
        name
        startDate
        endDate
        isFilled
        requirements {
          startDate
          endDate
          allocatedPercent
        }
        allocations {
          allocatedPercent
          startDate
          endDate
          personId
          name
        }
        unfilledRanges {
          startDate
          endDate
        }
        note
      }
    }
  }
`;

export const getUpdateProjectDurationQuery = (accountId, projectId, startDate, endDate, phases, roles) => {
  const variables = { accountId, projectId, startDate, endDate, phases, roles };

  return {
    query: UPDATE_PROJECT_DATES_QUERY,
    variables,
  };
};
