import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ErrorBoundary } from '../errors';
import { RequestList } from '.';
import { HeaderControls, TabbedPageHeader } from '../common';
import { Can, PermissionTabs } from '../wrapped-components';
import { AccessDenied } from '../permissions';
import {
  PERM_READ,
  PERM_REQUEST,
  PERM_ASSIGNMENT,
} from '../permissions/utils/constants';
import {
  REQUESTS_TAB,
  SELF_PERFORM_TAB_MAP,
  SELF_PERFORM_REQUESTS_VIEW,
  SELF_PERFORM_ASSIGNMENTS_VIEW,
  SELF_PERFORM_TABS,
} from '../../common/constants';
import { getStoredContentView } from '../common/listUtils';
import { LocalStorageKeys } from '../../common/localStorageKeys';
import { setActiveView, setContentView } from '../common/redux/actions';
import { getRequestById, clearSelectedRequest } from './redux/actions';

const SelfPerform = ({
  contentView,
  history,
  accountId,
  match,
  setContentView,
  setActiveView,
  selectedRequestId,
  getRequestById,
  clearSelectedRequest,
}) => {
  // Get initial currentView from URL or local storage
  const [currentView, setCurrentView] = useState(match?.params?.contentView
    ? SELF_PERFORM_TAB_MAP[match.params.contentView]
    : getStoredContentView(LocalStorageKeys.selfPerformView));

  const isFirstRender = useRef(true);

  useEffect(() => {
    // If no content view in the URL update with value from local storage in currentView
    if (!match?.params?.contentView) {
      history.replace(`/accounts/${accountId}/self-perform/${SELF_PERFORM_TAB_MAP[currentView]}`);
    }
  }, [match, currentView, accountId, history]);

  useEffect(() => {
    const urlRequestId = match?.params?.requestId;

    // If inital page load, fetch request by id from URL
    if (isFirstRender.current) {
      if (urlRequestId) getRequestById(accountId, parseInt(urlRequestId, 10));
      isFirstRender.current = false;
      return;
    }

    if (urlRequestId !== selectedRequestId) {
      history.replace(`/accounts/${accountId}/self-perform/${SELF_PERFORM_TAB_MAP[currentView]}${selectedRequestId ? `/${selectedRequestId}` : ''}`);
    }
  }, [accountId, currentView, getRequestById, history, match?.params?.requestId, selectedRequestId]);

  useEffect(() => {
    clearSelectedRequest();

    return () => {
      // clear the selected request when unmounting the component otherwise it makes
      // a getBestMatches with old request info if you switch accounts
      clearSelectedRequest();
    };
  }, [accountId, clearSelectedRequest]);

  useEffect(() => {
    const activeView = contentView === REQUESTS_TAB ? SELF_PERFORM_REQUESTS_VIEW : SELF_PERFORM_ASSIGNMENTS_VIEW;

    setActiveView(activeView);
  }, [setActiveView, contentView]);

  useEffect(() => {
    setContentView(currentView);
  }, [setContentView, currentView]);

  const handleTabsChange = useCallback((event, value) => {
    if (value === contentView) return;

    if (value === REQUESTS_TAB) {
      window.mixpanel.track('Navigate to Requests');
    } else {
      window.mixpanel.track('Navigate to Assignments');
    }

    setCurrentView(value);
    clearSelectedRequest();
    history.replace(`/accounts/${accountId}/self-perform/${SELF_PERFORM_TAB_MAP[value]}`);
  }, [contentView, clearSelectedRequest, history, accountId]);

  const onSearch = useCallback(() => {}, []);

  const tabs = useMemo(() => (
    <PermissionTabs
      className="filter-tabs"
      tabs={SELF_PERFORM_TABS}
      value={currentView}
      onChange={handleTabsChange}
    />
  ), [currentView, handleTabsChange]);


  const headerControlOptions = useMemo(() => ({
    showSearch: false,
    showExport: false,
    exportLoading: false,
    showSort: false,
    viewColumns: false,
  }), []);

  const headerControls = useMemo(() => (
    <HeaderControls
      onSearch={onSearch}
      options={headerControlOptions}
    />
  ), [headerControlOptions, onSearch]);

  const accessDenied = useMemo(() => <AccessDenied className="app-page-main" />, []);
  const requestList = useMemo(() => <RequestList className="app-page-main content-container" currentView={currentView} />, [currentView]);

  return (
    <ErrorBoundary>
      <div className="self-perform-main-page">
        <TabbedPageHeader
          pageTitle="Self-Perform"
          tabs={tabs}
          controls={headerControls}
        />
        <Can
          action={PERM_READ}
          subject={currentView === REQUESTS_TAB ? PERM_REQUEST : PERM_ASSIGNMENT}
          yes={requestList}
          no={accessDenied}
        />
      </div>
    </ErrorBoundary>
  );
};

SelfPerform.propTypes = {
  match: PropTypes.object.isRequired,
  accountId: PropTypes.number.isRequired,
  setContentView: PropTypes.func.isRequired,
  setActiveView: PropTypes.func.isRequired,
  getRequestById: PropTypes.func.isRequired,
  clearSelectedRequest: PropTypes.func.isRequired,
  contentView: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  selectedRequestId: PropTypes.number,
};

SelfPerform.defaultProps = {
  selectedRequestId: null,
};

/* istanbul ignore next */
const mapStateToProps = ({
  common,
  selfPerform,
}) => {
  const { accountId, contentView } = common;
  const { selectedRequest } = selfPerform;

  return {
    accountId,
    contentView,
    selectedRequestId: selectedRequest?.id,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  setActiveView: bindActionCreators(setActiveView, dispatch),
  setContentView: bindActionCreators(setContentView, dispatch),
  getRequestById: bindActionCreators(getRequestById, dispatch),
  clearSelectedRequest: bindActionCreators(clearSelectedRequest, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelfPerform);
