import { getTableConfigFromSchema } from 'src/features/table/filterUtils';
import {
  ASSIGNEE,
  START_DATE,
  END_DATE,
  PROJECT_NAME,
  REQUIRED_SKILLS,
  LAST_NOTIFIED_ON,
  STATUS,
} from 'src/filters/constants';
import { ACTIVE, COMPLETE, UPCOMING } from '../../features/self-perform/constants';

export const ASSIGNMENT_FIELDS = [
  {
    schemaName: 'id',
    displayName: 'Request ID',
    visible: false,
  },
  {
    schemaName: 'projectId',
    displayName: 'Project ID',
    visible: false,
  },
  {
    schemaName: 'description',
    displayName: 'Description',
    defaultVisible: true,
    sort: false,
  },
  {
    schemaName: PROJECT_NAME,
    displayName: 'Project',
    defaultVisible: true,
  },
  {
    schemaName: REQUIRED_SKILLS,
    displayName: 'Skill set',
    defaultVisible: true,
  },
  {
    schemaName: START_DATE,
    displayName: 'Start Date',
    defaultVisible: true,
  },
  {
    schemaName: END_DATE,
    displayName: 'End Date',
    defaultVisible: true,
  },
  {
    schemaName: ASSIGNEE,
    displayName: 'Assignee',
    defaultVisible: true,
  },
  {
    schemaName: LAST_NOTIFIED_ON,
    displayName: 'Communication Status',
    defaultVisible: true,
  },
  {
    schemaName: STATUS,
    displayName: 'Status',
    defaultVisible: true,
    filter: true,
    filterOptions: [
      { name: ACTIVE, value: ACTIVE },
      { name: UPCOMING, value: UPCOMING },
      { name: COMPLETE, value: COMPLETE },
    ],
  },
  {
    schemaName: 'state',
    visible: false,
  },
];

export const ASSIGNMENT_FIELDS_DEFAULTS = {
  displayName: '',
  visible: true,
  defaultVisible: false,
  alwaysVisible: false,
  filter: false,
  sort: true,
};

export const ASSIGNMENT_DESCRIPTOR = ASSIGNMENT_FIELDS.map(field => ({
  ...ASSIGNMENT_FIELDS_DEFAULTS,
  ...field,
}));

export const ASSIGNMENT_TABLE_CONFIG = getTableConfigFromSchema(ASSIGNMENT_DESCRIPTOR);

export const ASSIGNMENT_SCHEMAS = ASSIGNMENT_FIELDS.map(field => field.schemaName);
