import { ROLE_SCHEMA } from '../../common/descriptors/role';

const FEEDBACK_URL = 'https://docs.google.com/forms/d/e/1FAIpQLScIR14FNH6N_xyvp4c2ZR_dtDxYdHYXxC7rvz8PT8y2lwZByg/viewform?usp=sf_link';

const ALL_MONTHS = -1;
const ONE_MONTH = 1;
const THREE_MONTHS = 3;
const SIX_MONTHS = 6;
const NINE_MONTHS = 9;
const TWELVE_MONTHS = 12;
const DEFAULT_TIMEFRAME = ONE_MONTH;

const TIMEFRAME_MENU_ITEMS = [
  { value: ONE_MONTH, label: 'Next 1 month' },
  { value: THREE_MONTHS, label: 'Next 3 months' },
  { value: SIX_MONTHS, label: 'Next 6 months' },
  { value: NINE_MONTHS, label: 'Next 9 months' },
  { value: TWELVE_MONTHS, label: 'Next 12 months' },
  { value: ALL_MONTHS, label: 'All unfilled roles' },
];

const EMPTY_STATE_ALL_FILLED = 'All unfilled roles matching the applied filters and timeframe selected have been filled!';

const DEFAULT_ROLE_SEGMENT_SORT = { args: [{ label: 'Start Date', column: ROLE_SCHEMA.startDate, ascending: true }] };

const IDLE_TIME_TOOLTIP_TEXT = {
  title: 'Idle Time',
  line1: 'measures the average availability for all people within a given time period.',
  line2: 'Idle Time is the exact inverse of True Utilization Rate.',
  greenDot: 'Green represents idle time',
};

export {
  FEEDBACK_URL,
  ALL_MONTHS,
  ONE_MONTH,
  THREE_MONTHS,
  SIX_MONTHS,
  NINE_MONTHS,
  TWELVE_MONTHS,
  TIMEFRAME_MENU_ITEMS,
  EMPTY_STATE_ALL_FILLED,
  DEFAULT_TIMEFRAME,
  DEFAULT_ROLE_SEGMENT_SORT,
  IDLE_TIME_TOOLTIP_TEXT,
};
