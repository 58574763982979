import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import tinycolor from 'tinycolor2';
import { PURSUIT, LOST } from 'src/features/projects/constants';
import {
  utils,
  w000pureWhite,
  g600stoneGrey,
  g100cinderBlockGrey,
} from '@bridgit/foundation';
import { getPhaseStartPx, getBarWidth } from '../gantt/utils/ganttUtils';

const { convertHexToRGB } = utils;

export const PhaseBar = React.memo(({
  selectedProject,
  highlightedPhases,
  onMouseMove,
  onMouseLeave,
  parentName,
  zoomConfig,
}) => {
  const { startDate, endDate, colour, state } = selectedProject;
  const projectDays = moment(endDate).diff(startDate, 'days');
  const isProjectPursuitOrLost = [PURSUIT, LOST].includes(state);

  const generateOutlineColour = useMemo(() => (
    tinycolor(colour).isLight() || isProjectPursuitOrLost ? g600stoneGrey : g100cinderBlockGrey
  ), [colour, isProjectPursuitOrLost]);

  const generateBackgroundColour = useMemo(() => (
    tinycolor(colour).isLight() || isProjectPursuitOrLost ? g600stoneGrey : w000pureWhite
  ), [colour, isProjectPursuitOrLost]);

  const outlineColour = parentName === 'Project Gantt'
    ? generateOutlineColour
    : colour;

  const backgroundColour = parentName === 'Project Gantt'
    ? generateBackgroundColour
    : colour;

  return selectedProject.phases.map((phase) => {
    const phaseStart = moment(phase.startDate);
    const phaseEnd = moment(phase.endDate);
    let phaseLength;
    let startPx;

    // Pursuit/Lost projects have an extra border (Dashed/Solid, respectively)
    // Ignoring Pursuit/Lost if !!zoomConfig allows the phase bars to appear correctly
    if (zoomConfig && !isProjectPursuitOrLost) {
      phaseLength = getBarWidth(phaseStart, phaseEnd, zoomConfig);
      startPx = getPhaseStartPx(moment(startDate), phaseStart, zoomConfig);
    } else {
      phaseLength = `${(phaseEnd.diff(phase.startDate, 'days') / projectDays) * 100}%`;
      startPx = `${(phaseStart.diff(startDate, 'days') / projectDays) * 100}%`;
    }
    const isHighlighted = highlightedPhases.find(p => p.id === phase.id);

    const phaseBarStyle = {
      left: startPx,
      width: phaseLength,
      border: `1px solid ${outlineColour}`,
      backgroundColor: convertHexToRGB(backgroundColour, isHighlighted ? 0.4 : 0),
    };

    if (parentName === 'Project Gantt') {
      if (phaseStart.isSame(selectedProject.startDate)) {
        phaseBarStyle.borderTopLeftRadius = '20px';
        phaseBarStyle.borderBottomLeftRadius = '20px';
      }

      if (phaseEnd.isSame(selectedProject.endDate)) {
        phaseBarStyle.borderTopRightRadius = '20px';
        phaseBarStyle.borderBottomRightRadius = '20px';
      }
    }

    return (
      <button
        key={phase.id}
        type="button"
        className={classNames('phase-bar', { highlight: isHighlighted })}
        data-id={phase.id}
        data-start={phase.startDate}
        data-end={phase.endDate}
        style={phaseBarStyle}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
      />
    );
  });
});

PhaseBar.propTypes = {
  selectedProject: PropTypes.object.isRequired,
  highlightedPhases: PropTypes.array.isRequired,
  onMouseMove: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  parentName: PropTypes.string,
  zoomConfig: PropTypes.object,
};

PhaseBar.defaultProps = {
  parentName: 'Project List',
  zoomConfig: null,
};

export default PhaseBar;
