import { gql } from 'apollo-boost';

const GET_HOURLY_PERSON_BY_ID_QUERY = gql`
  query hourlyPerson($accountId: Int!, $hourlyPersonId: Int!) {
    hourlyPerson(accountId: $accountId, hourlyPersonId: $hourlyPersonId) {
      id
      accountId
      name
      phoneNumber
      skillSet
      currentAssignments {
        project {
          name
          colour
        }
        startDate
        endDate
        description
      }
      upcomingAssignments {
        project {
          name
          colour
        }
        startDate
        endDate
        description
      }
      pastAssignments {
        project {
          name
          colour
        }
        startDate
        endDate
        description
      }
      fields {
        id
        isPrivate
        name
        type: dataType
        values
      }
    }
  }
`;

export const getHourlyPersonByIdQuery = (accountId, hourlyPersonId) => {
  const variables = { accountId, hourlyPersonId };

  return {
    query: GET_HOURLY_PERSON_BY_ID_QUERY,
    variables,
  };
};
