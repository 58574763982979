import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  GANTT_GET_PEOPLE_GANTT_SHARE_DATA_BEGIN,
  GANTT_GET_PEOPLE_GANTT_SHARE_DATA_SUCCESS,
  GANTT_GET_PEOPLE_GANTT_SHARE_DATA_FAILURE,
  GANTT_GET_PEOPLE_GANTT_SHARE_DATA_DISMISS_ERROR,
} from './constants';
import {
  FILTERED_PEOPLE_QUERY_ID,
} from '../../queries/redux/constants';
import graphApiClient from '../../../common/GraphAPIClient';
import { getPeopleGanttShareQuery } from '../queries/getPeopleGanttShareQuery';
import { setPeopleGanttConfigSettings } from './setPeopleGanttConfigSettings';
import { setSortQuery } from '../../queries/redux/setSortQuery';
import { setFilterQuery } from '../../queries/redux/setFilterQuery';
import {
  getStorageKey,
  SORT_STORAGE_KEY,
  FILTER_STORAGE_KEY,
} from '../../../common/localStorageKeys';

export function getPeopleGanttShareData(accountId, userId, key, activeView) {
  const { query, variables } = getPeopleGanttShareQuery(accountId, key);
  const sortStorageKey = getStorageKey(activeView, accountId, SORT_STORAGE_KEY, userId);
  const filterStorageKey = getStorageKey(activeView, accountId, FILTER_STORAGE_KEY, userId);
  return {
    type: GANTT_GET_PEOPLE_GANTT_SHARE_DATA_BEGIN,
    key,
    query,
    variables,
    accountId,
    userId,
    activeView,
    sortStorageKey,
    filterStorageKey,
  };
}

export function dismissGetPeopleGanttShareDataError() {
  return {
    type: GANTT_GET_PEOPLE_GANTT_SHARE_DATA_DISMISS_ERROR,
  };
}

export function* doGetPeopleGanttShareData(action) {
  const { key, query, variables, sortStorageKey, filterStorageKey, accountId, userId } = action;
  let data;

  try {
    const res = yield call(graphApiClient.query, { query, variables });
    const val = res.data?.peopleGanttConfig?.value;

    // If query response is empty, trigger error action
    // and track analytics event
    if (!val) {
      yield all([
        put({
          type: GANTT_GET_PEOPLE_GANTT_SHARE_DATA_FAILURE,
          data: { error: new Error('data not found.') },
          analyticsPayload: { key },
        }),
      ]);
      return;
    }
    data = JSON.parse(val);
  } catch (err) {
    yield put({
      type: GANTT_GET_PEOPLE_GANTT_SHARE_DATA_FAILURE,
      data: { error: err },
    });
    return;
  }

  const { config } = data || {};
  const { sort, filter } = data.filter || {};
  const actions = [];

  // Update sort action
  if (sort) {
    actions.push(put(setSortQuery(
      FILTERED_PEOPLE_QUERY_ID,
      sort,
      sortStorageKey,
    )));
  }

  // Update filters action
  if (filter) {
    actions.push(put(setFilterQuery(
      FILTERED_PEOPLE_QUERY_ID,
      filter,
      filterStorageKey,
    )));
  }

  // Bulk update gantt configuration settings action
  if (config) {
    actions.push(put(setPeopleGanttConfigSettings(
      accountId,
      userId,
      config,
    )));
  }

  // Update all shared data then trigger success action
  yield all(actions);
  yield put({
    type: GANTT_GET_PEOPLE_GANTT_SHARE_DATA_SUCCESS,
    data,
    key,
  });
}

export function* watchGetPeopleGanttShareData() {
  yield takeLatest(GANTT_GET_PEOPLE_GANTT_SHARE_DATA_BEGIN, doGetPeopleGanttShareData);
}

export function reducer(state, action) {
  switch (action.type) {
    case GANTT_GET_PEOPLE_GANTT_SHARE_DATA_BEGIN:
      return {
        ...state,
        getPeopleGanttShareDataPending: true,
        getPeopleGanttShareDataError: null,
      };

    case GANTT_GET_PEOPLE_GANTT_SHARE_DATA_SUCCESS:
      return {
        ...state,
        getPeopleGanttShareDataPending: false,
        getPeopleGanttShareDataError: null,
      };

    case GANTT_GET_PEOPLE_GANTT_SHARE_DATA_FAILURE:
      return {
        ...state,
        getPeopleGanttShareDataPending: false,
        getPeopleGanttShareDataError: action.data.error,
      };

    case GANTT_GET_PEOPLE_GANTT_SHARE_DATA_DISMISS_ERROR:
      return {
        ...state,
        getPeopleGanttShareDataError: null,
      };

    default:
      return state;
  }
}
