import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Field, FormSpy } from 'react-final-form';
import { EditControls, Modal } from '@bridgit/foundation';
import pluralize from 'pluralize';
import { FormHelperText, Button } from '@material-ui/core';
import classnames from 'classnames';

import { composeFormValidators, isRequired, isUnique } from '../../utils/validators';
import { isEmptyObject } from '../../utils/miscUtils';
import { FormField } from '../wrapped-components';
import { MULTI_STATE_MODAL_ID } from '../common/redux/constants';
import { PEOPLE_LIST_SELECTION_ID } from './redux/constants';

const PersonCertificationForm = ({ editCertification, onClose, onSubmit, onDelete }) => {
  const { certifications } = useSelector(({ accountSettings }) => accountSettings);
  const isOpenInModal = useSelector(({ modalManager }) => modalManager.activeModal === MULTI_STATE_MODAL_ID);
  const selectedPerson = useSelector(({ people: { personSelections } }) => (
    isOpenInModal ? personSelections?.[MULTI_STATE_MODAL_ID] : personSelections?.[PEOPLE_LIST_SELECTION_ID]
  ));

  const isEditing = useMemo(() => !!editCertification, [editCertification]);

  const [activeAccountCertification, setActiveAccountCertification] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const findCertification = useCallback(
    certificationId => certifications.find(({ id }) => id === certificationId),
    [certifications],
  );

  const certificationOptions = useMemo(
    () => certifications.map(({ name, id }) => ({ label: name, value: id })),
    [certifications],
  );

  const pluralExpiryDays = useMemo(() => {
    const { daysWarnBeforeExpire } = isEditing ? editCertification : activeAccountCertification || {};

    return daysWarnBeforeExpire && pluralize('day', daysWarnBeforeExpire, true);
  }, [activeAccountCertification, editCertification, isEditing]);

  const handleCertificationChange = useCallback(onChange => (event) => {
    const certificationId = event.target.value;
    const certification = findCertification(certificationId);

    setActiveAccountCertification(certification);
    onChange(event);
  }, [findCertification]);

  const isCertificationInUse = useCallback((certificationId) => {
    const usersWithSelectedCert = findCertification(certificationId)?.inUse;

    return isUnique(usersWithSelectedCert, selectedPerson?.id, 'This certification already exists on this profile');
  }, [findCertification, selectedPerson]);

  const getExpiryDateValidator = useCallback((val, { certificationId }) => {
    const isDateRequired = findCertification(certificationId)?.requireExpiration;

    return isDateRequired ? isRequired(val) : null;
  }, [findCertification]);

  const renderCertificationSelect = useCallback(({ input, meta, disabled }) => (
    <FormField
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...input}
      onChange={handleCertificationChange(input.onChange)}
      type="select"
      width="full"
      label="Certification name"
      error={meta.error}
      alwaysShowErrors
      placeholder="Select a certification"
      options={certificationOptions}
      disabled={disabled}
    />
  ), [certificationOptions, handleCertificationChange]);

  const renderExpiryDateField = useCallback(({ input, disabled }) => (
    <FormField
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...input}
      type="date"
      width="full"
      label="Expiration date"
      disabled={disabled}
    />
  ), []);

  const toggleDeleteModal = useCallback(open => () => setIsDeleteModalOpen(open), []);

  const deleteModal = useMemo(() => (
    <Modal
      headline="Delete Certification"
      open={isDeleteModalOpen}
      onClose={toggleDeleteModal(false)}
    >
      <div className="delete-people-person-certification-modal">
        Are you sure you would like to delete this certification? Any linked attachments will also be deleted.
        <EditControls
          primaryText="Delete"
          primaryAction={onDelete}
          secondaryAction={toggleDeleteModal(false)}
        />
      </div>
    </Modal>
  ), [isDeleteModalOpen, toggleDeleteModal, onDelete]);

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, values }) => (
        <div className="people-profile-certification-form">
          <Field
            name="certificationId"
            validate={!isEditing && composeFormValidators(isRequired, isCertificationInUse)}
            render={renderCertificationSelect}
            defaultValue={editCertification?.id}
            disabled={isEditing}
          />

          <Field
            name="expiryDate"
            validate={getExpiryDateValidator}
            render={renderExpiryDateField}
            disabled={!values.certificationId}
            defaultValue={editCertification?.expiryDate}
          />

          <FormSpy
            subscription={{ errors: true }}
            render={({ errors: { expiryDate } }) => (
              <FormHelperText error={!!expiryDate}>{expiryDate}</FormHelperText>
            )}
          />

          {pluralExpiryDays && (
            <div className="expiry-message">
              {`This certification will be flagged as "expiring soon" ${pluralExpiryDays} before the expiry date.`}
            </div>
          )}

          <div
            className={classnames(
              'people-profile-certification-form-controls',
              { 'people-profile-certification-form-controls-in-modal': isOpenInModal },
            )}
          >
            <FormSpy
              subscription={{ errors: true }}
              render={({ errors }) => (
                <EditControls
                  primaryAction={handleSubmit}
                  primaryVariant="contained"
                  secondaryAction={onClose}
                  disabled={!isEmptyObject(errors)}
                />
              )}
            />
            {isEditing && (
              <Button className="form-control-delete" onClick={toggleDeleteModal(true)} color="secondary">
                Delete
              </Button>
            )}
            {deleteModal}
          </div>
        </div>
      )}
    />
  );
};

PersonCertificationForm.propTypes = {
  editCertification: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

PersonCertificationForm.defaultProps = {
  editCertification: null,
  onDelete: () => null,
};

export default PersonCertificationForm;
