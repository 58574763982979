import { call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import {
  FORECAST_GET_WORKFORCE_PLAN_BEGIN,
  FORECAST_GET_WORKFORCE_PLAN_SUCCESS,
  FORECAST_GET_WORKFORCE_PLAN_FAILURE,
  FORECAST_GET_WORKFORCE_PLAN_DISMISS_ERROR,
} from './constants';
import { getWorkforcePlanQuery } from '../queries/getWorkforcePlanQuery';
import graphApiClient from '../../../common/GraphAPIClient';
import { MONTH_YEAR_FORMAT } from '../../../common/constants';

export function getWorkforcePlan(
  accountId,
  peopleQueries = {},
  projectQueries = {},
  startDate,
  endDate,
  filteredPursuits,
  isPursuitsEnabled,
) {
  const filteredPursuitIds = filteredPursuits?.map(pursuit => pursuit.id);

  const { query, variables } = getWorkforcePlanQuery(accountId, peopleQueries, projectQueries, startDate, endDate, filteredPursuitIds, isPursuitsEnabled);
  return {
    type: FORECAST_GET_WORKFORCE_PLAN_BEGIN,
    query,
    variables,
  };
}

export function dismissGetWorkforcePlanError() {
  return {
    type: FORECAST_GET_WORKFORCE_PLAN_DISMISS_ERROR,
  };
}

export function* doGetWorkforcePlan(action) {
  const { query, variables, analyticsPayload } = action;
  let res;
  try {
    res = yield call(graphApiClient.query, { query, variables });
  } catch (err) {
    yield put({
      type: FORECAST_GET_WORKFORCE_PLAN_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: FORECAST_GET_WORKFORCE_PLAN_SUCCESS,
    data: res.data,
    analyticsPayload,
  });
}

export function* watchGetWorkforcePlan() {
  yield takeLatest(FORECAST_GET_WORKFORCE_PLAN_BEGIN, doGetWorkforcePlan);
}

export function reducer(state, action) {
  switch (action.type) {
    case FORECAST_GET_WORKFORCE_PLAN_BEGIN:
      return {
        ...state,
        getWorkforcePlanPending: true,
        getWorkforcePlanError: null,
      };

    case FORECAST_GET_WORKFORCE_PLAN_SUCCESS:
      return {
        ...state,
        workforceDemand: action.data?.projectDemand?.items.map(item => ({ ...item, x: moment(item.x, MONTH_YEAR_FORMAT) })),
        workforceSupply: action.data?.workforceSupply?.items.map(item => ({ ...item, x: moment(item.x, MONTH_YEAR_FORMAT) })),
        workforcePursuits: action.data?.pursuits?.items,
        getWorkforcePlanPending: false,
        getWorkforcePlanError: null,
      };

    case FORECAST_GET_WORKFORCE_PLAN_FAILURE:
      return {
        ...state,
        getWorkforcePlanPending: false,
        getWorkforcePlanError: action.data.error,
      };

    case FORECAST_GET_WORKFORCE_PLAN_DISMISS_ERROR:
      return {
        ...state,
        getWorkforcePlanError: null,
      };

    default:
      return state;
  }
}
