import {
  ERRORS_CLEAR_APP_DATA,
} from './constants';

export function clearAppData() {
  return {
    type: ERRORS_CLEAR_APP_DATA,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case ERRORS_CLEAR_APP_DATA:
      return {
        ...state,
      };

    default:
      return state;
  }
}
