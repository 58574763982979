import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { clearPlaceSuggestions, getPlaceSuggestions, toggleAddressSuggestionSelected } from './redux/actions';
import { DEBOUNCE_DURATION } from '../filters/redux/constants';

const AddressAutocomplete = ({ name, onChange, className, onOpen, id, value, renderInput, classes }) => {
  const dispatch = useDispatch();

  useEffect(() => () => dispatch(clearPlaceSuggestions()), [dispatch]);

  const [timeoutToken, setTimeoutToken] = useState(null);

  const { accountId } = useSelector(({ common }) => common);
  const { suggestions, isAddressSuggestionSelected } = useSelector(({ wrappedComponents: { placeSuggestions } }) => placeSuggestions);

  const getPlaceSuggestionsHandler = useCallback(
    value => dispatch(getPlaceSuggestions(accountId, value)),
    [accountId, dispatch],
  );

  const debouncedGetPlaceSuggestions = useCallback((input) => {
    const token = setTimeout(() => {
      getPlaceSuggestionsHandler(input);
    }, DEBOUNCE_DURATION);

    setTimeoutToken(token);
  }, [getPlaceSuggestionsHandler]);

  const inputChangeHandler = useCallback((e, inputValue, reason) => {
    if (['reset', 'clear'].includes(reason)) return;

    // set the input value
    onChange(e, inputValue, name);

    if (timeoutToken) {
      clearTimeout(timeoutToken);
    }

    if (inputValue) {
      debouncedGetPlaceSuggestions(inputValue);
    }

    if (isAddressSuggestionSelected) {
      dispatch(toggleAddressSuggestionSelected());
    }
  }, [debouncedGetPlaceSuggestions, dispatch, isAddressSuggestionSelected, name, onChange, timeoutToken]);

  const suggestionSelectHandler = useCallback((e, selectedValue) => {
    // set the input value
    onChange(e, selectedValue, name);

    if (!isAddressSuggestionSelected) {
      dispatch(toggleAddressSuggestionSelected());
    }
  }, [dispatch, isAddressSuggestionSelected, name, onChange]);

  return (
    <Autocomplete
      options={suggestions}
      onInputChange={inputChangeHandler}
      onChange={suggestionSelectHandler}
      renderInput={renderInput}
      className={className}
      onOpen={onOpen}
      id={id}
      value={value}
      disableClearable
      freeSolo
      classes={classes}
      filterOptions={options => options} // to display suggested options even if there are typos in the input
    />
  );
};

AddressAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  renderInput: PropTypes.func.isRequired,
  className: PropTypes.string,
  onOpen: PropTypes.func.isRequired,
  id: PropTypes.string,
  value: PropTypes.string,
  classes: PropTypes.object,
};

AddressAutocomplete.defaultProps = {
  className: '',
  value: '',
  id: '',
  classes: {},
};

export default AddressAutocomplete;
