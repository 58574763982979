import { call, put, takeLatest } from 'redux-saga/effects';
import {
  LOGIN_UPDATE_USER_INFO_BEGIN,
  LOGIN_UPDATE_USER_INFO_SUCCESS,
  LOGIN_UPDATE_USER_INFO_FAILURE,
  LOGIN_UPDATE_USER_INFO_DISMISS_ERROR,
} from './constants';
import { protectedJsonPatch } from '../../../utils/api';

export function updateUserInfo(userId, accountId, data, analyticsPayload) {
  return {
    type: LOGIN_UPDATE_USER_INFO_BEGIN,
    userId,
    accountId,
    data,
    analyticsPayload,
  };
}

export function dismissUpdateUserInfoError() {
  return {
    type: LOGIN_UPDATE_USER_INFO_DISMISS_ERROR,
  };
}

export function* doUpdateUserInfo(action) {
  const { userId, accountId, data, analyticsPayload } = action;
  try {
    if (accountId && accountId !== '*') {
      yield call(protectedJsonPatch, `accounts/${accountId}/users/${userId}`, data);
    } else {
      yield call(protectedJsonPatch, `users/${userId}`, data);
    }
  } catch (err) {
    yield put({
      type: LOGIN_UPDATE_USER_INFO_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: LOGIN_UPDATE_USER_INFO_SUCCESS,
    data,
    analyticsPayload,
  });
}

export function* watchUpdateUserInfo() {
  yield takeLatest(LOGIN_UPDATE_USER_INFO_BEGIN, doUpdateUserInfo);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case LOGIN_UPDATE_USER_INFO_BEGIN:
      return {
        ...state,
        updateUserInfoPending: true,
        updateUserInfoError: null,
      };

    case LOGIN_UPDATE_USER_INFO_SUCCESS:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...action.data,
        },
        updateUserInfoPending: false,
        updateUserInfoError: null,
      };

    case LOGIN_UPDATE_USER_INFO_FAILURE:
      return {
        ...state,
        updateUserInfoPending: false,
        updateUserInfoError: action.data.error,
      };

    case LOGIN_UPDATE_USER_INFO_DISMISS_ERROR:
      return {
        ...state,
        updateUserInfoError: null,
      };

    default:
      return state;
  }
}
