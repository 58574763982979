import { call, put, takeLatest } from 'redux-saga/effects';
import {
  SELF_PERFORM_ADD_HOURLY_REQUEST_BEGIN,
  SELF_PERFORM_ADD_HOURLY_REQUEST_SUCCESS,
  SELF_PERFORM_ADD_HOURLY_REQUEST_FAILURE,
  SELF_PERFORM_ADD_HOURLY_REQUEST_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';

export function addHourlyRequest(accountId, projectId, data, project, requester, viewAddedFrom) {
  return {
    type: SELF_PERFORM_ADD_HOURLY_REQUEST_BEGIN,
    accountId,
    projectId,
    data,
    project,
    requester,
    viewAddedFrom,
  };
}

export function dismissAddHourlyRequestError() {
  return {
    type: SELF_PERFORM_ADD_HOURLY_REQUEST_DISMISS_ERROR,
  };
}

export function* doAddHourlyRequest(action) {
  const { accountId, projectId, data, project, requester, viewAddedFrom } = action;

  let res;
  try {
    res = yield call(protectedJsonPost, `accounts/${accountId}/projects/${projectId}/hourly-requests`, data);
  } catch (err) {
    yield put({
      type: SELF_PERFORM_ADD_HOURLY_REQUEST_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: SELF_PERFORM_ADD_HOURLY_REQUEST_SUCCESS,
    data: res,
    projectId,
    project,
    requester,
    viewAddedFrom,
  });
}

export function* watchAddHourlyRequest() {
  yield takeLatest(SELF_PERFORM_ADD_HOURLY_REQUEST_BEGIN, doAddHourlyRequest);
}

export function reducer(state, action) {
  switch (action.type) {
    case SELF_PERFORM_ADD_HOURLY_REQUEST_BEGIN:
      return {
        ...state,
        addHourlyRequestPending: true,
        addHourlyRequestError: null,
      };

    case SELF_PERFORM_ADD_HOURLY_REQUEST_SUCCESS: {
      const { data, projectId, project, requester } = action;

      const newRequest = {
        ...data[0],
        project: {
          id: projectId,
          name: project.name,
          colour: project.colour,
        },
        requester,
      };

      const updatedFilteredRequests = [...state.filteredRequests, newRequest];

      return {
        ...state,
        filteredRequests: updatedFilteredRequests,
        addHourlyRequestPending: false,
        addHourlyRequestError: null,
      };
    }

    case SELF_PERFORM_ADD_HOURLY_REQUEST_FAILURE:
      return {
        ...state,
        addHourlyRequestPending: false,
        addHourlyRequestError: action.data.error,
      };

    case SELF_PERFORM_ADD_HOURLY_REQUEST_DISMISS_ERROR:
      return {
        ...state,
        addHourlyRequestError: null,
      };

    default:
      return state;
  }
}
