import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Event } from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { PERM_WRITE, PERM_ALLOCATION } from 'src/features/permissions/utils/constants';
import { DATE_INPUT_FORMAT, DATE_DISPLAY_FORMAT } from '../../common/constants';
import { Can } from '../wrapped-components';

function AllocationDateEditor({
  allocation,
  setDatePicker,
  confirmUpdate,
}) {
  const [tempStartDate, setTempStartDate] = useState(moment(allocation.startDate.format(DATE_INPUT_FORMAT)));
  const [tempEndDate, setTempEndDate] = useState(moment(allocation.endDate.format(DATE_INPUT_FORMAT)));

  const { startDate, endDate } = allocation;

  const onOpen = () => setDatePicker(true);
  const onClose = () => {
    setDatePicker(false);
    setTempStartDate(moment(allocation.startDate.format(DATE_INPUT_FORMAT)));
    setTempEndDate(moment(allocation.endDate.format(DATE_INPUT_FORMAT)));
  };

  const onChangeStartDate = date => setTempStartDate(moment(date));
  const onChangeEndDate = date => setTempEndDate(moment(date));

  const onAcceptStart = date => confirmUpdate(moment(date), null, 'start date');
  const onAcceptEnd = date => confirmUpdate(null, moment(date), 'end date');

  const outerTheme = {
    overrides: {
      MuiInput: {
        root: {
          opacity: 0,
          height: '100%',
          width: '100%',
          cursor: 'pointer',
          '&::after': {
            borderBottom: 'none',
          },
        },
        input: {
          cursor: 'pointer',
        },
      },
    },
  };

  return (
    <div className="allocations-allocation-date-editor">
      <div className="date">
        <span>Start Date</span>
        <span>End Date</span>
      </div>
      <div className="progress-wrap">
        <div className="progress" style={{ width: allocation.roleProgress }} />
      </div>
      <div className="date">
        <span>{startDate.format(DATE_DISPLAY_FORMAT)}</span>
        <span>{endDate.format(DATE_DISPLAY_FORMAT)}</span>
      </div>
      <Can
        action={PERM_WRITE}
        subject={PERM_ALLOCATION}
        yes={(
          <>
            <div className="edit-start-date">
              <MuiThemeProvider theme={outerTheme}>
                <DatePicker
                  className="allocation-date-picker"
                  initialFocusedDate={startDate}
                  minDate={moment(allocation.roleStart, DATE_INPUT_FORMAT)}
                  maxDate={endDate}
                  value={tempStartDate}
                  onOpen={onOpen}
                  onClose={onClose}
                  onChange={onChangeStartDate}
                  onAccept={onAcceptStart}
                />
              </MuiThemeProvider>
              <Event className="date-picker-icon" />
            </div>
            <div className="edit-end-date">
              <MuiThemeProvider theme={outerTheme}>
                <DatePicker
                  className="allocation-date-picker"
                  initialFocusedDate={endDate}
                  minDate={startDate}
                  maxDate={moment(allocation.roleEnd, DATE_INPUT_FORMAT)}
                  value={tempEndDate}
                  onOpen={onOpen}
                  onClose={onClose}
                  onChange={onChangeEndDate}
                  onAccept={onAcceptEnd}
                />
              </MuiThemeProvider>
              <Event className="date-picker-icon" />
            </div>
          </>
        )}
      />
    </div>
  );
}

AllocationDateEditor.propTypes = {
  allocation: PropTypes.object.isRequired,
  setDatePicker: PropTypes.func,
  confirmUpdate: PropTypes.func,
};

AllocationDateEditor.defaultProps = {
  setDatePicker: () => {},
  confirmUpdate: () => {},
};

export default AllocationDateEditor;
