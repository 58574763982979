import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PEOPLE_REMOVE_PROFILE_PICTURE_BEGIN,
  PEOPLE_REMOVE_PROFILE_PICTURE_SUCCESS,
  PEOPLE_REMOVE_PROFILE_PICTURE_FAILURE,
  PEOPLE_REMOVE_PROFILE_PICTURE_DISMISS_ERROR,
} from './constants';
import { protectedJsonDelete } from '../../../utils/api';

export function removeProfilePicture(accountId, personId, personName, parentName) {
  return {
    type: PEOPLE_REMOVE_PROFILE_PICTURE_BEGIN,
    accountId,
    personId,
    personName,
    parentName,
  };
}

export function dismissRemoveProfilePictureError() {
  return {
    type: PEOPLE_REMOVE_PROFILE_PICTURE_DISMISS_ERROR,
  };
}

export function* doRemoveProfilePicture(action) {
  const { accountId, personId, parentName, personName } = action;
  let res;
  try {
    res = yield call(protectedJsonDelete, `accounts/${accountId}/persons/${personId}/profile-picture`);
  } catch (err) {
    yield put({
      type: PEOPLE_REMOVE_PROFILE_PICTURE_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PEOPLE_REMOVE_PROFILE_PICTURE_SUCCESS,
    data: res,
    personId,
    parentName,
    personName,
  });
}

export function* watchRemoveProfilePicture() {
  yield takeLatest(PEOPLE_REMOVE_PROFILE_PICTURE_BEGIN, doRemoveProfilePicture);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_REMOVE_PROFILE_PICTURE_BEGIN:
      return {
        ...state,
        removeProfilePicturePending: true,
        removeProfilePictureError: null,
      };

    case PEOPLE_REMOVE_PROFILE_PICTURE_SUCCESS: {
      const updatedPerson = { ...state.entities.find(p => p.id === action.personId) };
      updatedPerson.photoUrl = '';

      return {
        ...state,
        entities: [
          ...state.entities.filter(person => person.id !== action.personId),
          updatedPerson,
        ],
        removeProfilePicturePending: false,
        removeProfilePictureError: null,
      };
    }

    case PEOPLE_REMOVE_PROFILE_PICTURE_FAILURE:
      return {
        ...state,
        removeProfilePicturePending: false,
        removeProfilePictureError: action.data.error,
      };

    case PEOPLE_REMOVE_PROFILE_PICTURE_DISMISS_ERROR:
      return {
        ...state,
        removeProfilePictureError: null,
      };

    default:
      return state;
  }
}
