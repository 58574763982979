import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNT_SETTINGS_GET_MODULES_BEGIN,
  ACCOUNT_SETTINGS_GET_MODULES_SUCCESS,
  ACCOUNT_SETTINGS_GET_MODULES_FAILURE,
  ACCOUNT_SETTINGS_GET_MODULES_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';

export function getModules() {
  return {
    type: ACCOUNT_SETTINGS_GET_MODULES_BEGIN,
  };
}

export function dismissGetModulesError() {
  return {
    type: ACCOUNT_SETTINGS_GET_MODULES_DISMISS_ERROR,
  };
}

export function* doGetModules() {
  let modules;
  try {
    modules = yield call(protectedJsonGet, 'modules');
  } catch (err) {
    yield put({
      type: ACCOUNT_SETTINGS_GET_MODULES_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: ACCOUNT_SETTINGS_GET_MODULES_SUCCESS,
    modules,
  });
}

export function* watchGetModules() {
  yield takeLatest(ACCOUNT_SETTINGS_GET_MODULES_BEGIN, doGetModules);
}

export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNT_SETTINGS_GET_MODULES_BEGIN:
      return {
        ...state,
        getModulesPending: true,
        getModulesError: null,
      };

    case ACCOUNT_SETTINGS_GET_MODULES_SUCCESS: {
      const { modules } = action;
      return {
        ...state,
        modules,
        getModulesPending: false,
        getModulesError: null,
      };
    }
    case ACCOUNT_SETTINGS_GET_MODULES_FAILURE:
      return {
        ...state,
        getModulesPending: false,
        getModulesError: action.data.error,
      };

    case ACCOUNT_SETTINGS_GET_MODULES_DISMISS_ERROR:
      return {
        ...state,
        getModulesError: null,
      };

    default:
      return state;
  }
}
