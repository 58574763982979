import moment from 'moment';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PROJECTS_UPDATE_PROJECT_DURATION_BEGIN,
  PROJECTS_UPDATE_PROJECT_DURATION_SUCCESS,
  PROJECTS_UPDATE_PROJECT_DURATION_FAILURE,
  PROJECTS_UPDATE_PROJECT_DURATION_DISMISS_ERROR,
} from './constants';
import graphApiClient from '../../../common/GraphAPIClient';
import { getUpdatedEntity } from '../../../filters/graphqlUtils';
import { getUpdateProjectDurationQuery } from '../queries/updateProjectDurationQuery';

export function updateProjectDuration(accountId, projectId, newStartDate, newEndDate, phases, roles) {
  const { query, variables } = getUpdateProjectDurationQuery(accountId, projectId, newStartDate, newEndDate, phases, roles);
  return {
    type: PROJECTS_UPDATE_PROJECT_DURATION_BEGIN,
    query,
    variables,
    projectId,
  };
}

export function dismissUpdateProjectDurationError() {
  return {
    type: PROJECTS_UPDATE_PROJECT_DURATION_DISMISS_ERROR,
  };
}

export function* doUpdateProjectDuration(action) {
  const { query, variables, projectId } = action;
  let res;
  try {
    res = yield call(graphApiClient.query, { query, variables });
  } catch (err) {
    yield put({
      type: PROJECTS_UPDATE_PROJECT_DURATION_FAILURE,
      data: { error: err },
    });
    return;
  }

  const { updateProjectDuration: response } = res.data;

  yield put({
    type: PROJECTS_UPDATE_PROJECT_DURATION_SUCCESS,
    data: {
      ...response,
      __typename: 'Project',
    },
    projectId,
  });
}

export function* watchUpdateProjectDuration() {
  yield takeLatest(PROJECTS_UPDATE_PROJECT_DURATION_BEGIN, doUpdateProjectDuration);
}

export function reducer(state, action) {
  switch (action.type) {
    case PROJECTS_UPDATE_PROJECT_DURATION_BEGIN:
      return {
        ...state,
        updateProjectDurationPending: true,
        updateProjectDurationError: null,
      };

    case PROJECTS_UPDATE_PROJECT_DURATION_SUCCESS: {
      const { projects, filteredProjects, projectSelections } = state;
      const { data, projectId } = action;

      // Update projects
      const newProjects = projects.map((project) => {
        if (project.id === data.id) {
          return {
            ...project,
            ...data,
          };
        }
        return project;
      });

      // Update filteredProjects
      const { phases, currentPhases, ...rest } = data;

      const newCurrentPhases = phases
        .filter(({ startDate, endDate }) => moment().isBetween(startDate, endDate, null, '[]'))
        .map(({ subPhases, ...rest }) => ({
          ...rest,
          subPhases: subPhases
            .filter(({ startDate, endDate }) => moment().isBetween(startDate, endDate, null, '[]'))
            .map(({ name }) => name),
        }));

      const filteredProjectsWithUpdatedPhases = filteredProjects.map((project) => {
        if (project.id !== projectId) return project;

        return {
          ...project,
          phases,
          currentPhases: newCurrentPhases,
        };
      });

      const newFilteredProjects = getUpdatedEntity('Project', filteredProjectsWithUpdatedPhases, rest);

      const newProjectSelections = { ...projectSelections };
      Object.keys(newProjectSelections).forEach((selectionId) => {
        const selectedProject = newProjectSelections[selectionId];
        if (selectedProject?.id === projectId) {
          newProjectSelections[selectionId] = {
            ...selectedProject,
            ...data,
            currentPhases: newCurrentPhases,
          };
        }
      });

      return {
        ...state,
        projects: newProjects,
        filteredProjects: newFilteredProjects,
        projectSelections: newProjectSelections,
        updateProjectDurationPending: false,
        updateProjectDurationError: null,
      };
    }

    case PROJECTS_UPDATE_PROJECT_DURATION_FAILURE:
      return {
        ...state,
        updateProjectDurationPending: false,
        updateProjectDurationError: action.data.error,
      };

    case PROJECTS_UPDATE_PROJECT_DURATION_DISMISS_ERROR:
      return {
        ...state,
        updateProjectDurationError: null,
      };

    default:
      return state;
  }
}
