import { call, put, takeLatest } from 'redux-saga/effects';
import { protectedJsonPut } from 'src/utils/api';
import {
  ACCOUNT_SETTINGS_UPDATE_PHASE_BEGIN,
  ACCOUNT_SETTINGS_UPDATE_PHASE_SUCCESS,
  ACCOUNT_SETTINGS_UPDATE_PHASE_FAILURE,
  ACCOUNT_SETTINGS_UPDATE_PHASE_DISMISS_ERROR,
} from './constants';

export function updatePhase(accountId, phaseId, data) {
  return {
    type: ACCOUNT_SETTINGS_UPDATE_PHASE_BEGIN,
    accountId,
    phaseId,
    data,
  };
}

export function dismissUpdatePhaseError() {
  return {
    type: ACCOUNT_SETTINGS_UPDATE_PHASE_DISMISS_ERROR,
  };
}

export function* doUpdatePhase(action) {
  const { accountId, phaseId, data } = action;
  try {
    yield call(protectedJsonPut, `accounts/${accountId}/phases/${phaseId}`, data);
  } catch (err) {
    yield put({
      type: ACCOUNT_SETTINGS_UPDATE_PHASE_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: ACCOUNT_SETTINGS_UPDATE_PHASE_SUCCESS,
    accountId,
    phaseId,
    data,
  });
}

export function* watchUpdatePhase() {
  yield takeLatest(ACCOUNT_SETTINGS_UPDATE_PHASE_BEGIN, doUpdatePhase);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNT_SETTINGS_UPDATE_PHASE_BEGIN:
      return {
        ...state,
        updatePhasePending: true,
        updatePhaseError: null,
      };

    case ACCOUNT_SETTINGS_UPDATE_PHASE_SUCCESS: {
      const newPhases = state.phases.map((phase) => {
        if (action.phaseId === phase.id) {
          return {
            ...phase,
            ...action.data,
          };
        }
        return phase;
      });

      return {
        ...state,
        phases: newPhases,
        updatePhasePending: false,
        updatePhaseError: null,
      };
    }

    case ACCOUNT_SETTINGS_UPDATE_PHASE_FAILURE:
      return {
        ...state,
        updatePhasePending: false,
        updatePhaseError: action.data.error,
      };

    case ACCOUNT_SETTINGS_UPDATE_PHASE_DISMISS_ERROR:
      return {
        ...state,
        updatePhaseError: null,
      };

    default:
      return state;
  }
}
