const initialState = {
  entities: [],
  users: {},
  invitations: [],
  roleTemplates: [],
  titleCosts: [],
  createNewPending: false,
  createNewError: null,
  createNewAccountPending: false,
  createNewAccountError: null,
  getAccountsPending: false,
  getAccountsError: null,
  updateAccountPending: false,
  updateAccountError: null,
  inviteUserPending: false,
  inviteUserError: null,
  getUsersPending: false,
  getUsersError: null,
  removeUserPending: false,
  removeUserError: null,
  removeInvitationPending: false,
  removeInvitationError: null,
  getInvitationsPending: false,
  getInvitationsError: null,
  updateUserPermissionsPending: false,
  updateUserPermissionsError: null,
  setLogoPending: false,
  setLogoError: null,
  deleteLogoPending: false,
  deleteLogoError: null,
  deactivateAccountPending: false,
  deactivateAccountError: null,
  reactivateAccountPending: false,
  reactivateAccountError: null,
  getAccountColorsPending: false,
  getAccountColorsError: null,
  getRoleTemplatesPending: false,
  getRoleTemplatesError: null,
  deleteRoleTemplatePending: false,
  deleteRoleTemplateError: null,
  addRoleTemplatePending: false,
  addRoleTemplateError: null,
  updateRoleTemplatePending: false,
  updateRoleTemplateError: null,
  getTitleCostsPending: false,
  getTitleCostsError: null,
  setTitleCostsPending: false,
  setTitleCostsError: null,
};

export default initialState;
