export default function generatePropertyFilter(fieldValues, fieldName) {
  const should = [];

  fieldValues.forEach((value) => {
    should.push({
      match: {
        [fieldName]: value.name,
      },
    });
  });

  return {
    bool: {
      should,
    },
  };
}
