import { gql } from 'apollo-boost';
import { MAX_SIZE, MIN_FROM } from 'src/common/constants';
import { generateRequestFilter } from '../../../filters/graphqlUtils';
import { HOURLY_PERSONS_SCHEMAS } from '../../../common/descriptors/hourlyPerson';

const HOURLY_PERSONS_LIST_QUERY = gql`
    query getHourlyPersonsBestMatches($accountId: Int!, $size: Int, $from: Int, $filters: [ESQuery], $structuredSort: [HourlyPersonSort], $isActive: Boolean) {
      hourlyPersons(accountId: $accountId, size: $size, from: $from, filters: $filters, structuredSort: $structuredSort, isActive: $isActive) {
        count
        hasMore
        cursor
        items {
          id
          name
          skillSet
          fields {
            name
            values
          }
        }
      }
    }
  `;

export const getHourlyPersonsBestMatchesQuery = (accountId, personIds = [], searchQuery = '', isActive = true) => {
  const defaultFilter = {
    label: 'id',
    column: 'id',
    activeFilters: personIds.map(({ personId }) => ({ name: personId, value: personId })),
  };

  const filters = [
    ...generateRequestFilter(defaultFilter, HOURLY_PERSONS_SCHEMAS),
    ...(searchQuery ? [generateRequestFilter(searchQuery, ['name'])[0]] : []),
  ];

  const variables = {
    isActive,
    accountId,
    filters,
    from: MIN_FROM,
    size: MAX_SIZE,
    structuredSort: [{ name: { order: 'asc' } }],
  };

  return {
    query: HOURLY_PERSONS_LIST_QUERY,
    variables,
  };
};
