import { call, put, takeLatest } from 'redux-saga/effects';
import { collateFieldValues } from 'src/filters/graphqlUtils';
import {
  PEOPLE_GET_FILTERED_PEOPLE_BEGIN,
  PEOPLE_GET_FILTERED_PEOPLE_SUCCESS,
  PEOPLE_GET_FILTERED_PEOPLE_FAILURE,
  PEOPLE_GET_FILTERED_PEOPLE_DISMISS_ERROR,
} from './constants';
import { getPeopleListQuery } from '../queries/getPeopleListQuery';
import graphApiClient from '../../../common/GraphAPIClient';
import initialState from './initialState';
import { PAGE_SIZE } from '../../../common/constants';

export function getFilteredPeople(accountId, queries, pageFrom, pageSize = PAGE_SIZE) {
  const { query, variables } = getPeopleListQuery(accountId, queries, pageFrom, pageSize);

  return {
    type: PEOPLE_GET_FILTERED_PEOPLE_BEGIN,
    query,
    variables,
  };
}

export function dismissGetFilteredPeopleError() {
  return {
    type: PEOPLE_GET_FILTERED_PEOPLE_DISMISS_ERROR,
  };
}

export function* doGetFilteredPeople(action) {
  const { query, variables } = action;
  let res;
  try {
    res = yield call(graphApiClient.query, { query, variables });
    const { errors } = res;
    if (errors) {
      throw errors;
    }
  } catch (err) {
    yield put({
      type: PEOPLE_GET_FILTERED_PEOPLE_FAILURE,
      data: { error: err },
      isPaging: variables.from !== 0,
    });
    return;
  }
  yield put({
    type: PEOPLE_GET_FILTERED_PEOPLE_SUCCESS,
    data: res.data,
    clearData: variables.from === 0,
  });
}

export function* watchGetFilteredPeople() {
  yield takeLatest(PEOPLE_GET_FILTERED_PEOPLE_BEGIN, doGetFilteredPeople);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_GET_FILTERED_PEOPLE_BEGIN: {
      const { variables } = action;
      const isPaging = variables.from > 0;

      return {
        ...state,
        getFilteredPeoplePagePending: isPaging,
        getFilteredPeoplePending: !isPaging,
        getFilteredPeopleError: null,
      };
    }

    case PEOPLE_GET_FILTERED_PEOPLE_SUCCESS: {
      const { data, clearData } = action;
      const collatedData = collateFieldValues(data.people.items);

      return {
        ...state,
        filteredPeople: clearData ? collatedData : [...state.filteredPeople, ...collatedData],
        peopleCount: data.people.count,
        pageFrom: data.people.cursor,
        hasMore: data.people.hasMore,
        getFilteredPeoplePagePending: false,
        getFilteredPeoplePending: false,
        getFilteredPeopleError: null,
        getFilteredPeoplePageError: null,
      };
    }

    case PEOPLE_GET_FILTERED_PEOPLE_FAILURE: {
      const { filteredPeople } = initialState;
      return {
        ...state,
        filteredPeople,
        getFilteredPeoplePending: false,
        getFilteredPeoplePagePending: false,
        getFilteredPeopleError: action.isPaging ? false : action.data.error,
        getFilteredPeoplePageError: action.isPaging ? action.data.error : false,
      };
    }
    case PEOPLE_GET_FILTERED_PEOPLE_DISMISS_ERROR:
      return {
        ...state,
        getFilteredPeopleError: null,
        getFilteredPeoplePageError: null,
      };

    default:
      return state;
  }
}
