import initialState from './initialState';
import { reducer as hideSuccessToastReducer } from './hideSuccessToast';
import { reducer as showSuccessToastReducer } from './showSuccessToast';

const reducers = [
  hideSuccessToastReducer,
  showSuccessToastReducer,
];

export default function reducer(state = initialState, action) {
  return reducers.reduce((s, r) => r(s, action), state);
}
