import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Loader } from '@bridgit/foundation';
import { getServiceAccounts, resetServiceAccount } from './redux/actions';
import { SERVICE_ACCOUNTS_STEPS } from './constants';
import { ServiceAccountsList, ServiceAccountDetails, AddServiceAccountForm } from '.';

const { ACCOUNTS_LIST, ACCOUNT_DETAILS, ADD_ACCOUNT_FORM } = SERVICE_ACCOUNTS_STEPS;

export const ServiceAccountsTab = ({
  accountId,
  getServiceAccounts,
  loading,
  resetServiceAccount,
  account,
}) => {
  const [step, setStep] = useState(ACCOUNTS_LIST);

  useEffect(() => {
    if (step === ACCOUNTS_LIST) getServiceAccounts(accountId);

    if (account) setStep(ACCOUNT_DETAILS);
  }, [getServiceAccounts, accountId, account, step]);

  // reset service account data on component unmount
  useEffect(() => () => {
    resetServiceAccount();
  }, [resetServiceAccount]);

  const goToNextStep = useCallback(() => {
    if ([ACCOUNT_DETAILS, ADD_ACCOUNT_FORM].includes(step)) {
      resetServiceAccount();

      return setStep(ACCOUNTS_LIST);
    }

    return setStep(ADD_ACCOUNT_FORM);
  }, [resetServiceAccount, step]);

  const goToPreviousStep = useCallback(() => {
    setStep(ACCOUNTS_LIST);
  }, []);

  if (loading) {
    return (
      <div className="service-accounts-account-list">
        <Loader />
      </div>
    );
  }

  const renderChildren = () => {
    switch (step) {
      case ACCOUNTS_LIST:
        return <ServiceAccountsList onNextClick={goToNextStep} />;
      case ACCOUNT_DETAILS:
        return <ServiceAccountDetails onNextClick={goToNextStep} />;
      case ADD_ACCOUNT_FORM:
        return <AddServiceAccountForm onCancelClick={goToPreviousStep} />;
      default:
        return null;
    }
  };

  return (
    <div className="service-accounts-account-list">
      <div className="accounts">
        <div className="header">Service Accounts</div>

        {renderChildren()}
      </div>
    </div>
  );
};

ServiceAccountsTab.propTypes = {
  accountId: PropTypes.number.isRequired,
  getServiceAccounts: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  resetServiceAccount: PropTypes.func.isRequired,
  account: PropTypes.object,
};

ServiceAccountsTab.defaultProps = {
  loading: false,
  account: null,
};

const mapStateToProps = ({
  serviceAccounts: {
    getServiceAccountsPending,
    addServiceAccountPending,
    resetServiceAccountPasswordPending,
    deleteServiceAccountPending,
    account,
  },
  common: { accountId },
}) => ({
  accountId,
  loading:
    getServiceAccountsPending
    || addServiceAccountPending
    || resetServiceAccountPasswordPending
    || deleteServiceAccountPending,
  account,
});

const mapDispatchToProps = dispatch => ({
  getServiceAccounts: bindActionCreators(getServiceAccounts, dispatch),
  resetServiceAccount: bindActionCreators(resetServiceAccount, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ServiceAccountsTab);
