import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Can } from 'src/features/wrapped-components';
import { LIGHT_GREY } from '@bridgit/foundation';

export default function ChipDropdownToggle({
  options,
  selectedId,
  onSelect,
  permissions,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const selected = useMemo(() => options.find(option => option.id === selectedId), [options, selectedId]);

  const handleClick = useCallback((event => setAnchorEl(event.currentTarget)), []);
  const handleClose = useCallback(() => setAnchorEl(null), []);

  const handleSelect = useCallback(label => () => {
    onSelect(label);
    handleClose();
  }, [handleClose, onSelect]);

  const selectList = useMemo(() => options.reduce((list, { id, menuLabel, label }) => {
    if (id !== selectedId) {
      list.push((
        <MenuItem style={selected?.text} className="common-chip-dropdown-toggle-items" key={id} onClick={handleSelect(label)}>{menuLabel}</MenuItem>
      ));
    }

    return list;
  }, []), [handleSelect, options, selected, selectedId]);

  const backgroundColor = useMemo(() => {
    if (selected?.background) {
      const { focused, unfocused } = selected.background;
      return anchorEl ? focused : unfocused;
    }
    // Default colour
    return { backgroundColor: LIGHT_GREY };
  }, [anchorEl, selected?.background]);

  if (!selected) return null;

  const { label } = selected;

  return (
    <div className="common-chip-dropdown-toggle">
      <Can
        action={permissions?.action}
        subject={permissions?.subject}
        yes={(
          <div>
            <Button
              className="common-chip-dropdown-toggle-button"
              style={backgroundColor}
              aria-haspopup="true"
              onClick={handleClick}
              endIcon={anchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              disableRipple
            >
              {label}
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={!!anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              getContentAnchorEl={null}
              MenuListProps={{ disablePadding: true }}
            >
              {selectList}
            </Menu>
          </div>
        )}
        no={(
          <div>
            <Button
              disabled
              className="common-chip-dropdown-toggle-button"
              style={backgroundColor}
            >
              {label}
            </Button>
          </div>
        )}
      />
    </div>

  );
}

ChipDropdownToggle.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  selectedId: PropTypes.string,
  onSelect: PropTypes.func,
  permissions: PropTypes.object,
};

ChipDropdownToggle.defaultProps = {
  options: [],
  selectedId: '',
  onSelect: () => {},
  permissions: null,
};
