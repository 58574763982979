import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PROJECTS_UPDATE_PROJECT_NOTE_BEGIN,
  PROJECTS_UPDATE_PROJECT_NOTE_SUCCESS,
  PROJECTS_UPDATE_PROJECT_NOTE_FAILURE,
  PROJECTS_UPDATE_PROJECT_NOTE_DISMISS_ERROR,
} from './constants';
import { protectedJsonPatch } from '../../../utils/api';
import { updateProjectNoteById } from '../utils/projectUtils';

export function updateProjectNote(accountId, projectId, noteId, analyticsPayload, data) {
  return {
    type: PROJECTS_UPDATE_PROJECT_NOTE_BEGIN,
    accountId,
    projectId,
    noteId,
    analyticsPayload,
    data,
  };
}

export function dismissUpdateProjectNoteError() {
  return {
    type: PROJECTS_UPDATE_PROJECT_NOTE_DISMISS_ERROR,
  };
}

export function* doUpdateProjectNote(action) {
  const { accountId, projectId, noteId, analyticsPayload, data } = action;
  let res;
  try {
    res = yield call(protectedJsonPatch, `accounts/${accountId}/projects/${projectId}/notes/${noteId}`, data);
  } catch (err) {
    yield put({
      type: PROJECTS_UPDATE_PROJECT_NOTE_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PROJECTS_UPDATE_PROJECT_NOTE_SUCCESS,
    accountId,
    projectId,
    noteId,
    analyticsPayload,
    data: res,
  });
}

export function* watchUpdateProjectNote() {
  yield takeLatest(PROJECTS_UPDATE_PROJECT_NOTE_BEGIN, doUpdateProjectNote);
}

export function reducer(state, action) {
  switch (action.type) {
    case PROJECTS_UPDATE_PROJECT_NOTE_BEGIN:
      return {
        ...state,
        updateProjectNotePending: true,
        updateProjectNoteError: null,
      };

    case PROJECTS_UPDATE_PROJECT_NOTE_SUCCESS: {
      const { projectId, noteId, data } = action;
      const { projectSelections } = state;

      const newProjectSelections = { ...projectSelections };
      Object.keys(newProjectSelections).forEach((selectionId) => {
        const selectedProject = newProjectSelections[selectionId];
        if (selectedProject && selectedProject.id === projectId) {
          const updatedProject = updateProjectNoteById(selectedProject, noteId, data);
          newProjectSelections[selectionId] = {
            ...updatedProject,
          };
        }
      });

      return {
        ...state,
        updateProjectNotePending: false,
        updateProjectNoteError: null,
        projectSelections: newProjectSelections,
      };
    }

    case PROJECTS_UPDATE_PROJECT_NOTE_FAILURE:
      return {
        ...state,
        updateProjectNotePending: false,
        updateProjectNoteError: action.data.error,
      };

    case PROJECTS_UPDATE_PROJECT_NOTE_DISMISS_ERROR:
      return {
        ...state,
        updateProjectNoteError: null,
      };

    default:
      return state;
  }
}
