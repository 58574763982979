import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { BLANK_FILTER, CONTROL_OPTIONS_FILTER_NAMES } from 'src/filters/constants';
import classNames from 'classnames';
import { FilterChip } from '.';

export default class FilterChipGroup extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    column: PropTypes.object.isRequired,
    filters: PropTypes.array.isRequired,
    updateFilters: PropTypes.func.isRequired,
    verb: PropTypes.string,
    id: PropTypes.number,
  };

  static defaultProps = {
    className: '',
    classes: {},
    verb: 'includes',
    id: -1,
  }

  maxChipDisplay = 5;

  removeFilter = (filterLabel) => {
    const { updateFilters, filters, id, column } = this.props;
    const columnData = {
      schemaName: column.column,
      name: column.label,
      type: column.type,
      filterType: column.filterType,
    };

    updateFilters(columnData, filters.filter(f => f.name !== filterLabel), id, filterLabel);
  }

  getFilterChips = () => {
    const { filters, classes, column: columnObj } = this.props;
    const { chip, operator } = classes;
    const { column } = columnObj;

    // Truncate filters & bring "special" filters (eg. Filled/Unfilled chips) to the front of the list to prevent cut-off
    const truncatedFilters = filters.reduce((accumulator, filter, index) => {
      const { name } = filter;
      if (CONTROL_OPTIONS_FILTER_NAMES[column]?.includes(name)) {
        accumulator.unshift(filter);
      } else {
        accumulator.push(filter);
      }

      if (index === filters.length - 1) {
        return accumulator.slice(0, this.maxChipDisplay);
      }
      return accumulator;
    }, []);

    const filterChipList = truncatedFilters.reduce((accumulator, { value, name }, index) => {
      accumulator.push(
        <FilterChip
          className={classNames(chip, { 'filter-joined-chips': CONTROL_OPTIONS_FILTER_NAMES[column]?.includes(name) })}
          key={value ?? name}
          removeFilter={this.removeFilter}
          label={name}
        />,
      );

      // Only inject "or" filler if chip is not a control option filter (ex Unfilled in column roles)
      if (index !== truncatedFilters.length - 1 && !CONTROL_OPTIONS_FILTER_NAMES[column]?.includes(name)) {
        accumulator.push(<span key={`${name}-operator`} className={classNames('filter-operator', operator)}>or</span>);
      }

      return accumulator;
    }, []);

    return [...filterChipList];
  }

  render() {
    const { column, filters, verb, className, classes } = this.props;
    const showMore = filters.length > this.maxChipDisplay;
    const { label } = column;
    const verbText = [BLANK_FILTER, ''].includes(verb) ? '' : ` ${verb.toLowerCase()}`;

    return (
      <div className={classNames('table-filter-chip-group', className)}>
        <span className={classNames('filter-label', classes.groupLabel)}>{`${label}${verbText}:`}</span>
        {this.getFilterChips()}

        {showMore && (
          <span className="filter-more">{`+${filters.length - this.maxChipDisplay} more`}</span>
        )}
      </div>
    );
  }
}
