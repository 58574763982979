import React from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener, Tooltip } from '@material-ui/core';

export const CustomTooltip = ({ onClickAway, children, title, placement, ...rest }) => (
  <ClickAwayListener onClickAway={onClickAway}>
    <Tooltip
      title={title}
      placement={placement}
        // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {children}
    </Tooltip>
  </ClickAwayListener>
);

CustomTooltip.propTypes = {
  onClickAway: PropTypes.func,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  placement: PropTypes.string,
};

CustomTooltip.defaultProps = {
  onClickAway: () => {},
  placement: 'top',
};

export default CustomTooltip;
