import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import pluralize from 'pluralize';
import { Link } from 'react-router-dom';
import { Avatar, Tooltip } from '@material-ui/core';
import classNames from 'classnames';

import { stringToMoment } from 'src/utils/dateUtils';
import {
  ACCOUNT_MODULE_LOCATION,
  FIELD_TYPE_ADDRESS,
  HOME_ADDRESS_FIELD,
  UNAVAILABILITY,
} from '../../common/constants';
import { AvailabilityIndicator } from '.';
import emptyAvatar from '../../images/empty_avatar_blue.svg?url';
import { setSelectedPersonId } from '../common/redux/actions';
import { MULTI_STATE_MODAL_ID } from '../common/redux/constants';
import { openModal } from '../modal-manager/redux/actions';
import { PROFILE_DETAILS_MODAL_CONTEXT } from '../people/constants';
import { Can } from '../wrapped-components';
import { PERM_PRIVATE, PERM_READ } from '../permissions/utils/constants';
import WrongLocationIcon from '../../images/wrong-location.svg';
import { convertMetersToDistance } from '../../utils/formatters';
import { DISTANCE_UNITS } from '../accounts/common/constants';

const { KM, MI } = DISTANCE_UNITS;

/* We have layered click actions here so we could not use a button */
/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

export class RoleCandidate extends PureComponent {
  static propTypes = {
    selected: PropTypes.bool,
    roleStart: PropTypes.instanceOf(moment).isRequired,
    roleEnd: PropTypes.instanceOf(moment).isRequired,
    person: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    onAvailabilityClick: PropTypes.func.isRequired,
    accountId: PropTypes.number.isRequired,
    roleRequirements: PropTypes.array,
    setSelectedPersonId: PropTypes.func.isRequired,
    activeModal: PropTypes.string,
    openModal: PropTypes.func.isRequired,
    onPursuitsClick: PropTypes.func.isRequired,
    roleName: PropTypes.string.isRequired,
    roleId: PropTypes.number.isRequired,
    project: PropTypes.object.isRequired,
    isAddressPrivate: PropTypes.bool.isRequired,
    conversionFactorMetric: PropTypes.number.isRequired,
  };

  static defaultProps = {
    selected: false,
    roleRequirements: [],
    activeModal: null,
  }

  onVisitProfileClick = (event) => {
    const {
      person,
      setSelectedPersonId,
      activeModal,
      openModal,
    } = this.props;

    event.preventDefault();

    const analyticsPayload = {
      runsInModal: true,
      data: {
        personName: person.name,
        personId: person.id,
        to: PROFILE_DETAILS_MODAL_CONTEXT,
        from: 'Place a person modal',
      },
    };
    setSelectedPersonId(person.id, true, analyticsPayload);

    if (!activeModal || activeModal !== MULTI_STATE_MODAL_ID) {
      openModal(MULTI_STATE_MODAL_ID);
      window.mixpanel.track('Profile Details Modal Displayed', {
        'Person Name': person.name,
        'Person ID': person.id,
        'Launched from': 'Place a person modal',
      });
    }
  }

  onSelectPerson = () => {
    const { onClick, person } = this.props;

    if (this.candidateUnavailable()) return;

    onClick(person);
  }

  candidateUnavailable = () => {
    const { person, roleStart, roleEnd } = this.props;

    // See if the candidate has unavailability spanning the entire role
    const { startDate, endDate, rangeType } = person?.unavailabilities?.[0] || {};
    const hasBackfillRequired = rangeType === UNAVAILABILITY;

    return startDate && endDate && hasBackfillRequired
      && stringToMoment(startDate).isSame(roleStart)
      && stringToMoment(endDate).isSame(roleEnd);
  }

  handlePursuitClick = (event) => {
    const { onPursuitsClick, person, roleName, roleId, project } = this.props;
    event.stopPropagation();
    onPursuitsClick(event.currentTarget, person.id);

    window.mixpanel.track('Place a Person - Pursuits indicator selected', {
      'Project name': project.name,
      'Project id': project.id,
      'Project status': project.state,
      'Project type': project.type,
      'Role Name': roleName,
      'Role id': roleId,
      'Person name': person.name,
      'Person id': person.id,
    });
  }

  render() {
    const {
      person,
      roleStart,
      roleEnd,
      selected,
      onAvailabilityClick,
      accountId,
      roleRequirements,
      isAddressPrivate,
      conversionFactorMetric,
      project,
    } = this.props;
    const { distanceFromProject } = person;
    const unavailable = this.candidateUnavailable();
    const hasDistanceResult = distanceFromProject != null; // non-strict equality; check for undefined as well
    // Round displayed distance up
    const displayedDistance = convertMetersToDistance(distanceFromProject, conversionFactorMetric);
    const projectAddress = project?.fields?.find(({ name }) => name === FIELD_TYPE_ADDRESS)?.values?.[0];

    return (
      <div
        role="button"
        onKeyDown={() => {}}
        tabIndex={-1}
        onClick={this.onSelectPerson}
        className={classNames('allocations-role-candidate', { selected, unavailable })}
      >
        <Tooltip title="Visit Profile" placement="top">
          <Link to={`/accounts/${accountId}/people/${person.id}`} onClick={this.onVisitProfileClick}>
            {!person.photoUrl
              ? <Avatar className="person-portrait" src={emptyAvatar} />
              : <Avatar className="person-portrait" src={person.photoUrl} />}
          </Link>
        </Tooltip>
        <Tooltip
          open={unavailable ? undefined : false}
          title="No availability"
          placement="top"
        >
          <div className="person-identity">
            <span className="person-name" title={person.name}>{person.name}</span>
            <span className="person-title" title={person.title}>{person.title}</span>
            { person.pursuitProjectsCount > 0 && (
              <div
                className="pursuits-badge"
                role="presentation"
                onClick={this.handlePursuitClick}
              >
                <span>
                  On
                  {' '}
                  <strong>{person.pursuitProjectsCount}</strong>
                  {' '}
                  {pluralize('pursuit', person.pursuitProjectsCount)}
                </span>
              </div>
            )}
          </div>
        </Tooltip>
        <div className="person-availability">
          <AvailabilityIndicator
            personId={person.id}
            fullCoverage={!!person.canFillAllocationRange}
            availabilities={person.availabilities}
            unavailabilities={person.unavailabilities}
            roleStart={roleStart.format('YYYY-MM-DD')}
            roleEnd={roleEnd.format('YYYY-MM-DD')}
            onSegmentClick={onAvailabilityClick}
            roleRequirements={roleRequirements}
            placeholder="Click to view allocation"
          />
        </div>
        <Can
          module={ACCOUNT_MODULE_LOCATION}
          action={PERM_READ}
          subject={isAddressPrivate ? PERM_PRIVATE : null}
          yes={(
            <div className="person-distance-from-project">
              {hasDistanceResult
                ? (
                  <span className="distance-from-project-result">{displayedDistance}</span>
                )
                : (
                  <Tooltip title={projectAddress ? '' : 'No project address has been added'}>
                    <div className="distance-from-project-no-result">
                      <div className="no-result-icon">
                        <WrongLocationIcon />
                      </div>
                      <span className="no-result-text">No result</span>
                    </div>
                  </Tooltip>
                )}
            </div>
          )}
        />
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps({ common, modalManager, accountSettings, accounts }) {
  const { entities } = accounts;
  const { accountId } = common;
  const { activeModal } = modalManager;
  const { personFields } = accountSettings;

  const isAddressPrivate = personFields?.find(({ name }) => name === HOME_ADDRESS_FIELD)?.isPrivate ?? true;
  const { useMetricUnits } = entities.find(({ id }) => id === accountId) || {};
  const { conversionFactorMetric } = useMetricUnits ? KM : MI;

  return {
    accountId,
    activeModal,
    isAddressPrivate,
    conversionFactorMetric,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSelectedPersonId: bindActionCreators(setSelectedPersonId, dispatch),
    openModal: bindActionCreators(openModal, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RoleCandidate);
