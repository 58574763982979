import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { isAuthorized, hasModuleEnabled } from 'src/features/permissions/utils/permissionUtils';

export function renderRouteConfigV3(routes, contextPath, accountId, permissions, accountModules) {
  // Resolve route config object in React Router v3.
  const children = []; // children component list

  const renderRoute = (item, routeContextPath) => {
    const { action, subject, module, component, path, childRoutes } = item;

    // Check permissions
    if (accountId && permissions && action && subject) {
      const authorized = isAuthorized(accountId, permissions, action, subject);
      if (!authorized) return;
    }

    // Check if required module is enabled
    if (accountId && accountModules && module) {
      const hasModule = hasModuleEnabled(accountModules, module);
      if (!hasModule) return;
    }

    let newContextPath;
    if (/^\//.test(path)) {
      newContextPath = path;
    } else {
      newContextPath = `${routeContextPath}/${path}`;
    }
    newContextPath = newContextPath.replace(/\/+/g, '/');

    if (component && childRoutes) {
      const renderedChildRoutes = renderRouteConfigV3(childRoutes, newContextPath, accountId, permissions, accountModules);
      /* eslint-disable react/jsx-props-no-spreading */
      children.push(
        <Route
          key={newContextPath}
          render={props => <item.component {...props}>{renderedChildRoutes}</item.component>}
          path={newContextPath}
        />,
      );
      /* eslint-enable react/jsx-props-no-spreading */
    } else if (component) {
      children.push(
        <Route key={newContextPath} component={component} path={newContextPath} exact />,
      );
    } else if (childRoutes) {
      childRoutes.forEach(r => renderRoute(r, newContextPath));
    }
  };

  routes.forEach(item => renderRoute(item, contextPath));

  // Use Switch so that only the first matched route is rendered.
  return <Switch>{children}</Switch>;
}
