import React from 'react';
import PropTypes from 'prop-types';
import { EditControls } from '../wrapped-components';

export default function DateModalControls({
  pending,
  primaryAction,
  secondaryAction,
}) {
  return (
    <EditControls
      pending={pending}
      className="edit-dates-date-modal-controls"
      primaryText="Confirm changes"
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
      pendingText="Processing changes - Please leave this window open"
    />
  );
}

DateModalControls.propTypes = {
  pending: PropTypes.bool,
  primaryAction: PropTypes.func.isRequired,
  secondaryAction: PropTypes.func.isRequired,
};

DateModalControls.defaultProps = {
  pending: false,
};
