const PERSON_NOTE_ADDED = 'Person Note Added';
const PERSON_NOTE_DELETED = 'Person Note Deleted';
const PERSON_NOTE_EDITED = 'Person Note Edited';

const UPLOAD_PROFILE_ATTACHMENT = 'Upload a Profile Attachment';
const DOWNLOAD_PROFILE_ATTACHMENT = 'Download a Profile Attachment';
const DELETE_PROFILE_ATTACHMENT = 'Delete a Profile Attachment';

const TIME_OFF_ADDED = 'Time Off Added';
const TIME_OFF_UPDATED = 'Time Off Updated';
const TIME_OFF_DELETED = 'Time Off Deleted';

const PERSON_ADDED = 'Person Added';

const PERSON_UPDATED = 'Person Updated';
const DEACTIVATED_PERSON_UPDATED = 'Deactivated Person Updated';

const PERSON_DEACTIVATED = 'Person Deactivated';
const PERSON_REACTIVATED = 'Person is Reactivated';

const PERSON_DELETE_PROFILE_PICTURE = 'Profile Picture - Delete';
const PERSON_UPLOAD_PROFILE_PICTURE = 'Profile Picture - Upload';

const PERSON_ADD_CERTIFICATION = 'Certification Added to a Profile';
const PERSON_EDIT_CERTIFICATION = 'Certification Edited on a Profile';
const PERSON_DELETE_CERTIFICATION = 'Certification Deleted from Profile';
const MANAGE_CERTIFICATION_ATTACHMENTS = 'Manage attachments is selected';
const HIDE_CERTIFICATION_ATTACHMENTS = 'Hide attachments is selected';

const PERSON_ADDRESS_UPDATED = 'Person Home Address Updated';

export {
  PERSON_NOTE_ADDED,
  PERSON_NOTE_DELETED,
  PERSON_NOTE_EDITED,
  UPLOAD_PROFILE_ATTACHMENT,
  DOWNLOAD_PROFILE_ATTACHMENT,
  TIME_OFF_ADDED,
  TIME_OFF_UPDATED,
  TIME_OFF_DELETED,
  PERSON_ADDED,
  PERSON_UPDATED,
  DEACTIVATED_PERSON_UPDATED,
  DELETE_PROFILE_ATTACHMENT,
  PERSON_DEACTIVATED,
  PERSON_REACTIVATED,
  PERSON_DELETE_PROFILE_PICTURE,
  PERSON_UPLOAD_PROFILE_PICTURE,
  PERSON_ADD_CERTIFICATION,
  PERSON_EDIT_CERTIFICATION,
  PERSON_DELETE_CERTIFICATION,
  MANAGE_CERTIFICATION_ATTACHMENTS,
  HIDE_CERTIFICATION_ATTACHMENTS,
  PERSON_ADDRESS_UPDATED,
};
