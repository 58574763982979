import initialState from './initialState';
import { reducer as getUtilizationDataReducer } from './getUtilizationData';
import { reducer as getBreakdownReducer } from './getBreakdown';
import { reducer as updateForecastAnalyticsPayloadReducer } from './updateForecastAnalyticsPayload';
import { reducer as getWorkforcePlanReducer } from './getWorkforcePlan';
import { reducer as getPursuitProjectsReducer } from './getPursuitProjects';
import { reducer as getBenchCostOverviewReducer } from './getBenchCostOverview';
import { reducer as getBenchCostIndividualsReducer } from './getBenchCostIndividuals';

const reducers = [
  getUtilizationDataReducer,
  getBreakdownReducer,
  updateForecastAnalyticsPayloadReducer,
  getWorkforcePlanReducer,
  getPursuitProjectsReducer,
  getBenchCostOverviewReducer,
  getBenchCostIndividualsReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
