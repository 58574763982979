import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ReportPeopleList, CoverPage, ReportTemplate } from '.';
import { PEOPLE_TITLE } from './redux/constants';
import { getAccountById } from '../../utils/miscUtils';
import { FILTERED_PEOPLE_QUERY_ID } from '../queries/redux/constants';
import { trackAnalytics } from '../common/redux/actions';
import { REPORTS_EXPORT_PEOPLE_LIST } from '../../analytics/reports/constants';

const PeoplePDFReport = () => {
  const dispatch = useDispatch();

  const { settings, people } = useSelector(({ reports }) => reports);
  const { accountId } = useSelector(({ common }) => common);
  const { entities: accounts } = useSelector(({ accounts }) => accounts);

  useEffect(() => {
    const { includeAllocations } = settings;

    const analyticsPayload = {
      'Report type': 'PDF',
      'Allocations included': includeAllocations,
    };

    dispatch(trackAnalytics(REPORTS_EXPORT_PEOPLE_LIST, analyticsPayload));
  }, [dispatch, settings]);

  const peopleCount = people.length;
  const account = getAccountById(accounts, accountId);

  const coverPage = (
    <CoverPage
      title={PEOPLE_TITLE}
      subTitle={`${peopleCount} ${peopleCount === 1 ? 'Person' : 'People'} included`}
      queryId={FILTERED_PEOPLE_QUERY_ID}
    />
  );

  return (
    <div className="reports-people-report">
      <ReportTemplate
        coverPage={coverPage}
        report={<ReportPeopleList />}
        header={account ? account.name : ''}
        title={PEOPLE_TITLE}
      />
    </div>
  );
};

export default PeoplePDFReport;
