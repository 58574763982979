import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { Search, Clear } from '@material-ui/icons';
import { BLANK_FILTER } from 'src/filters/constants';
import { CustomPopper } from '../wrapped-components';
import { setPopperIsOpen } from '../filters/redux/actions';

class TextFilterPopper extends PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    anchorEl: PropTypes.object,
    title: PropTypes.string.isRequired,
    onApply: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    selected: PropTypes.object,
    setPopperIsOpen: PropTypes.func.isRequired,
  };

  static defaultProps = {
    anchorEl: null,
    placeholder: '',
    selected: null,
  }

  constructor(props) {
    super(props);

    const { selected } = props;

    this.state = {
      applyDisabled: true,
      searchString: selected ? selected.name : '',
      blankFilter: selected ? selected.blankFilter : false,
    };
  }

  onSearch = (searchString = '') => {
    const { selected } = this.props;
    const trimmedString = searchString.trim();
    let applyDisabled;

    if (selected) {
      if (searchString === '') {
        applyDisabled = false;
      } else {
        applyDisabled = selected.name === trimmedString;
      }
    } else {
      applyDisabled = searchString === '';
    }

    this.setState({
      searchString,
      applyDisabled,
    });
  }

  onSearchChange = (e) => {
    this.onSearch(e.currentTarget.value);
  }

  onClearSearch = () => {
    this.onSearch();
  }

  onCancel = () => {
    const { onCancel, selected, setPopperIsOpen } = this.props;

    onCancel();
    setPopperIsOpen(false);
    this.setState({
      applyDisabled: true,
      searchString: selected ? selected.name : '',
      blankFilter: selected ? selected.blankFilter : false,
    });
  }

  onApply = () => {
    const { onApply } = this.props;
    const { blankFilter, searchString } = this.state;
    const activeFilter = [];

    if (!blankFilter) {
      const trimmedString = searchString.trim();

      if (trimmedString.length > 0) {
        activeFilter.push({
          name: trimmedString,
          value: trimmedString,
          blankFilter,
        });
      }
    } else {
      activeFilter.push({
        name: BLANK_FILTER,
        blankFilter,
      });
    }

    onApply(activeFilter);
    this.setState({
      applyDisabled: true,
    });
  }

  toggleBlankFilter = () => {
    const { selected } = this.props;
    const { blankFilter } = this.state;

    let applyDisabled;
    if (selected) {
      applyDisabled = blankFilter ? false : blankFilter !== selected.blankFilter;
    } else {
      applyDisabled = blankFilter;
    }

    this.setState({
      blankFilter: !blankFilter,
      searchString: '',
      applyDisabled,
    });
  }

  render() {
    const {
      anchorEl,
      open,
      title,
      placeholder,
    } = this.props;
    const { applyDisabled, searchString, blankFilter } = this.state;

    return (
      <CustomPopper
        popperOpen={open}
        anchorEl={anchorEl}
        classes="common-text-filter-popper"
        placement="top-start"
        hideArrow
        preventBubbling
      >
        <ClickAwayListener onClickAway={this.onCancel}>
          <div className="content-wrap">
            <div role="presentation" onClick={this.onCancel} className="click-listener" />
            <div className="header">
              <div className="title">{title}</div>
              {!blankFilter && (
                <div className="search-wrap">
                  <TextField
                    placeholder={placeholder}
                    value={searchString}
                    fullWidth
                    onChange={this.onSearchChange}
                    autoFocus
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          {searchString.length > 0 && (
                            <Clear className="filter-clear" onClick={this.onClearSearch} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              )}
              <div className="select-wrap">
                <FormControlLabel
                  classes={{ root: 'select-label' }}
                  label="Filter on (Blank)"
                  onChange={this.toggleBlankFilter}
                  control={(
                    <Checkbox
                      classes={{ root: 'checkbox' }}
                      checked={blankFilter}
                      color="primary"
                    />
                  )}
                />
              </div>
            </div>

            <div className="footer">
              <Button
                disableRipple
                size="medium"
                onClick={this.onCancel}
              >
                Cancel
              </Button>
              <Button
                disableRipple
                size="medium"
                onClick={this.onApply}
                color="primary"
                disabled={applyDisabled}
              >
                Apply
              </Button>
            </div>
          </div>
        </ClickAwayListener>
      </CustomPopper>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setPopperIsOpen: bindActionCreators(setPopperIsOpen, dispatch),
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(TextFilterPopper);
