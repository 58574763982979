import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { generate } from 'shortid';

function TimelineHeader({
  className,
  monthWidth,
  todayPosition,
  columnHeaders,
  showToday,
}) {
  const displayToday = showToday && todayPosition >= 0 && todayPosition < monthWidth;
  return (
    <div className={classNames('gantt-timeline-header', className)} style={{ width: `${monthWidth}px` }}>
      {columnHeaders.map(month => (
        <div key={generate()} className="month-header">
          <div className="month">
            <span className="sticky-month">{month}</span>
          </div>
        </div>
      ))}
      {displayToday && <div className="today-text" style={{ left: `${todayPosition}px` }}>Today</div>}
    </div>
  );
}

TimelineHeader.propTypes = {
  className: PropTypes.string,
  monthWidth: PropTypes.number.isRequired,
  todayPosition: PropTypes.number,
  columnHeaders: PropTypes.array.isRequired,
  showToday: PropTypes.bool,
};

TimelineHeader.defaultProps = {
  className: '',
  showToday: true,
  todayPosition: 0,
};

export default React.memo(TimelineHeader);
