import { call, put, takeLatest } from 'redux-saga/effects';
import { collateFieldValues } from 'src/filters/graphqlUtils';
import {
  PROJECTS_GET_FILTERED_PROJECTS_BEGIN,
  PROJECTS_GET_FILTERED_PROJECTS_SUCCESS,
  PROJECTS_GET_FILTERED_PROJECTS_FAILURE,
  PROJECTS_GET_FILTERED_PROJECTS_DISMISS_ERROR,
} from './constants';
import { getProjectListQuery } from '../queries/getProjectListQuery';
import graphApiClient from '../../../common/GraphAPIClient';
import initialState from './initialState';

export function getFilteredProjects(accountId, queries, pageFrom, data = {}, pageSize) {
  const { roleSortOption, roleFilters, permissions } = data;
  const { query, variables } = getProjectListQuery(accountId, queries, pageFrom, pageSize, roleSortOption, roleFilters, permissions);

  return {
    type: PROJECTS_GET_FILTERED_PROJECTS_BEGIN,
    query,
    variables,
  };
}

export function dismissGetFilteredProjectsError() {
  return {
    type: PROJECTS_GET_FILTERED_PROJECTS_DISMISS_ERROR,
  };
}

export function* doGetFilteredProjects(action) {
  const { query, variables } = action;
  let res;
  try {
    res = yield call(graphApiClient.query, { query, variables });
    const { errors } = res;
    if (errors) {
      throw errors;
    }
  } catch (err) {
    yield put({
      type: PROJECTS_GET_FILTERED_PROJECTS_FAILURE,
      data: { error: err },
      isPaging: variables.from !== 0,
    });
    return;
  }
  yield put({
    type: PROJECTS_GET_FILTERED_PROJECTS_SUCCESS,
    data: res.data,
    clearData: variables.from === 0,
  });
}

export function* watchGetFilteredProjects() {
  yield takeLatest(PROJECTS_GET_FILTERED_PROJECTS_BEGIN, doGetFilteredProjects);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PROJECTS_GET_FILTERED_PROJECTS_BEGIN: {
      const { variables } = action;
      const isPaging = variables.from > 0;

      return {
        ...state,
        getFilteredProjectsPagePending: isPaging,
        getFilteredProjectsPending: !isPaging,
        getFilteredProjectsError: null,
      };
    }

    case PROJECTS_GET_FILTERED_PROJECTS_SUCCESS: {
      const { data, clearData } = action;
      const collatedData = collateFieldValues(data.projects.items);

      return {
        ...state,
        filteredProjects: clearData ? collatedData : [...state.filteredProjects, ...collatedData],
        projectCount: data.projects.count,
        pageFrom: data.projects.cursor,
        hasMore: data.projects.hasMore,
        getFilteredProjectsPagePending: false,
        getFilteredProjectsPending: false,
        getFilteredProjectsError: null,
        getFilteredProjectsPageError: null,
      };
    }

    case PROJECTS_GET_FILTERED_PROJECTS_FAILURE: {
      const { filteredProjects } = initialState;
      return {
        ...state,
        filteredProjects,
        getFilteredProjectsPending: false,
        getFilteredProjectsPagePending: false,
        getFilteredProjectsError: action.isPaging ? null : action.data.error,
        getFilteredProjectsPageError: action.isPaging ? action.data.error : null,
      };
    }
    case PROJECTS_GET_FILTERED_PROJECTS_DISMISS_ERROR:
      return {
        ...state,
        getFilteredProjectsError: null,
        getFilteredProjectsPageError: null,
      };

    default:
      return state;
  }
}
