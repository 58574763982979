const initialState = {
  editAction: null,
  dateUnderEdit: null,
  typeUnderEdit: null,
  offset: 0,
  newStartDate: null,
  newEndDate: null,
  boundingStartDate: null,
  boundingEndDate: null,
  segments: {},
  parentName: '',
  primaryId: null,
};

export default initialState;
