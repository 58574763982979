import React, { useState, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  PROJECT_LIST_DEFAULT_QUERY,
  PROJECT_LIST_DEFAULT_QUERY_PURSUITS_ON,
  ADD_REQUEST_MODAL,
} from './constants';
import { ManagedModal } from '../modal-manager';
import { ValidatedForm } from '../wrapped-components';
import { closeModal } from '../modal-manager/redux/closeModal';

import { addHourlyRequest } from './redux/addHourlyRequest';
import { getSkillSet } from '../account-settings/redux/getSkillSet';
import { getRequesters } from './redux/actions';
import { getUsers } from '../accounts/redux/getUsers';
import { getAddRequestInputs, normalizeAddRequestData, validateAddRequestInputs } from './utils/addRequestUtils';
import { getProjectNames } from '../projects/redux/getProjectNames';
import { PROJECT_LIST_SELECTION_ID } from '../projects/redux/constants';
import { hasModuleEnabled } from '../permissions/utils/permissionUtils';
import { ACCOUNT_MODULE_PURSUIT_TRACKING } from '../../common/constants';

const AddRequestModal = ({
  closeModal,
  loading,
  addHourlyRequestPending,
  addHourlyRequest,
  accountId,
  getSkillSet,
  skillSet,
  getProjectNames,
  projectNames,
  getRequesters,
  users,
  getUsers,
  projectRequesters,
  activeView,
  projectListSelectedProject,
  isPursuitModuleOn,
}) => {
  const [disableSend, setDisableSend] = useState(false);
  const [addRequestData, setAddRequestData] = useState(null);
  const [selectedProjectId, setSelectedProjectId] = useState(null);

  useEffect(() => {
    const defaultProjectQuery = isPursuitModuleOn ? PROJECT_LIST_DEFAULT_QUERY_PURSUITS_ON : PROJECT_LIST_DEFAULT_QUERY;

    getUsers(accountId);
    getSkillSet(accountId);
    getProjectNames(accountId, defaultProjectQuery);
  }, [getSkillSet, accountId, getProjectNames, getUsers, isPursuitModuleOn]);

  useEffect(() => {
    if (projectListSelectedProject) {
      setSelectedProjectId(projectListSelectedProject.id);
    }
  }, [projectListSelectedProject]);

  useEffect(() => {
    if (selectedProjectId) {
      getRequesters(accountId, selectedProjectId, users);
    }
  }, [accountId, selectedProjectId, users, getRequesters]);

  const inputs = useMemo(
    () => getAddRequestInputs(skillSet, projectNames, selectedProjectId, projectRequesters, addRequestData?.startDate),
    [skillSet, projectNames, selectedProjectId, projectRequesters, addRequestData?.startDate],
  );

  const validate = (values) => {
    const errors = validateAddRequestInputs(inputs, values);

    setDisableSend(!!Object.values(errors).length);

    return errors;
  };

  const onValueChanged = useCallback((inputName, values) => {
    if (inputName === 'project') {
      const projectId = values?.project?.value;

      setSelectedProjectId(projectId);
    }

    setAddRequestData(values);
  }, []);

  const handleCloseModal = () => closeModal(ADD_REQUEST_MODAL);

  const onSaveRequestClick = () => {
    const {
      newRequestData,
      requester,
      project,
      addedFrom,
    } = normalizeAddRequestData(projectRequesters, addRequestData, activeView);

    addHourlyRequest(accountId, selectedProjectId, newRequestData, project, requester, addedFrom);
  };

  const defaultValues = useMemo(() => (
    { project: projectNames.find(({ value }) => value === selectedProjectId) || '' }
  ), [projectNames, selectedProjectId]);

  return (
    <>
      <ManagedModal
        className="self-perform-add-request-modal"
        modalId={ADD_REQUEST_MODAL}
        headline="Add Request"
        pending={addHourlyRequestPending}
        showClose={false}
      >
        <div className="add-request-container">
          <ValidatedForm
            inputs={inputs}
            validate={validate}
            onValueChanged={onValueChanged}
            loading={loading}
            defaultValues={defaultValues}
          />
          <Button
            className="button__default save-request-button"
            onClick={onSaveRequestClick}
            disabled={disableSend}
            disableRipple
          >
            Save request
          </Button>
          <Button
            className="button__default cancel-request-button"
            onClick={handleCloseModal}
            disableRipple
          >
            Cancel
          </Button>
        </div>
      </ManagedModal>
    </>
  );
};

AddRequestModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  addHourlyRequestPending: PropTypes.bool,
  addHourlyRequest: PropTypes.func.isRequired,
  getSkillSet: PropTypes.func.isRequired,
  accountId: PropTypes.number.isRequired,
  skillSet: PropTypes.array.isRequired,
  getProjectNames: PropTypes.func.isRequired,
  projectNames: PropTypes.array.isRequired,
  getRequesters: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
  getUsers: PropTypes.func.isRequired,
  projectRequesters: PropTypes.array,
  activeView: PropTypes.number.isRequired,
  projectListSelectedProject: PropTypes.object,
  isPursuitModuleOn: PropTypes.bool.isRequired,
};

AddRequestModal.defaultProps = {
  loading: false,
  addHourlyRequestPending: false,
  projectRequesters: [],
  projectListSelectedProject: null,
};

const mapStateToProps = ({
  selfPerform: { addHourlyRequestPending, projectRequesters, getProjectRequestersPending },
  common: { accountId, activeView },
  accountSettings: { skillSet, getSkillSetPending, accountModules },
  projects: { projectNames, getProjectNamesPending, projectSelections },
  accounts: { users, getUsersPending },
}) => ({
  loading: addHourlyRequestPending || getProjectNamesPending || getUsersPending || getSkillSetPending || getProjectRequestersPending,
  accountId,
  skillSet,
  projectNames,
  users,
  projectRequesters,
  addHourlyRequestPending,
  activeView,
  projectListSelectedProject: projectSelections[PROJECT_LIST_SELECTION_ID],
  isPursuitModuleOn: hasModuleEnabled(accountModules, ACCOUNT_MODULE_PURSUIT_TRACKING),
});

const mapDispatchToProps = dispatch => ({
  closeModal: bindActionCreators(closeModal, dispatch),
  addHourlyRequest: bindActionCreators(addHourlyRequest, dispatch),
  getSkillSet: bindActionCreators(getSkillSet, dispatch),
  getProjectNames: bindActionCreators(getProjectNames, dispatch),
  getRequesters: bindActionCreators(getRequesters, dispatch),
  getUsers: bindActionCreators(getUsers, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddRequestModal);
