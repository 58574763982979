import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FilterList } from '@material-ui/icons';
import { MAPPED_PEOPLE_FILTER_NAMES } from '../../filters/constants';
import FilterPopperFactory from './FilterPopperFactory';

const useStyles = makeStyles({
  iconButton: {
    marginRight: '-8px',
  },
});

const SingleFilter = ({ queries, queryId, column, onFilterApplied }) => {
  const queryObj = queries[queryId];
  const filterQueries = queryObj && queryObj.filter ? queryObj.filter : {};

  const {
    name,
    options: { filter = false, hideColumnFilter = false } = {},
  } = column;

  const filterName = MAPPED_PEOPLE_FILTER_NAMES[name] || name;
  const columnFilter = filterQueries?.args?.find(query => query.label === filterName);

  const classes = useStyles();

  const [filterRef, setFilterRef] = useState(null);

  const openFilter = useCallback((event) => {
    event.stopPropagation();
    setFilterRef(event.target);
  }, []);

  const closeFilter = useCallback(() => {
    setFilterRef(null);
  }, []);

  const applyFilter = useCallback((updatedFilter) => {
    onFilterApplied?.(column, updatedFilter);
  }, [column, onFilterApplied]);

  return filter && !hideColumnFilter && (
    <>
      <IconButton
        className={classes.iconButton}
        size="small"
        disableRipple
        variant="contained"
        color={columnFilter ? 'primary' : 'default'}
        onClick={openFilter}
        disabled={!!filterRef}
      >
        <FilterList className="icon-edit" aria-label="Required" />
      </IconButton>
      <FilterPopperFactory
        filterQueries={filterQueries}
        selectedFilter={column}
        anchorEl={filterRef}
        onCancel={closeFilter}
        onApply={applyFilter}
        open={!!filterRef}
      />
    </>
  );
};

SingleFilter.propTypes = {
  queries: PropTypes.object.isRequired,
  queryId: PropTypes.string.isRequired,
  column: PropTypes.object.isRequired,
  onFilterApplied: PropTypes.func,
};

SingleFilter.defaultProps = {
  onFilterApplied: undefined,
};

export default SingleFilter;
