import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNT_SETTINGS_UPDATE_HOURLY_PERSON_FIELDS_BEGIN,
  ACCOUNT_SETTINGS_UPDATE_HOURLY_PERSON_FIELDS_SUCCESS,
  ACCOUNT_SETTINGS_UPDATE_HOURLY_PERSON_FIELDS_FAILURE,
  ACCOUNT_SETTINGS_UPDATE_HOURLY_PERSON_FIELDS_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';

export function updateHourlyPersonFields(accountId, data) {
  return {
    type: ACCOUNT_SETTINGS_UPDATE_HOURLY_PERSON_FIELDS_BEGIN,
    accountId,
    data,
  };
}

export function dismissUpdateHourlyPersonFieldsError() {
  return {
    type: ACCOUNT_SETTINGS_UPDATE_HOURLY_PERSON_FIELDS_DISMISS_ERROR,
  };
}

export function* doUpdateHourlyPersonFields(action) {
  const { accountId, data } = action;
  let res;
  try {
    res = yield call(protectedJsonPost, `accounts/${accountId}/hourly-person-fields`, data);
  } catch (err) {
    yield put({
      type: ACCOUNT_SETTINGS_UPDATE_HOURLY_PERSON_FIELDS_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: ACCOUNT_SETTINGS_UPDATE_HOURLY_PERSON_FIELDS_SUCCESS,
    data: res,
  });
}

export function* watchUpdateHourlyPersonFields() {
  yield takeLatest(ACCOUNT_SETTINGS_UPDATE_HOURLY_PERSON_FIELDS_BEGIN, doUpdateHourlyPersonFields);
}

export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNT_SETTINGS_UPDATE_HOURLY_PERSON_FIELDS_BEGIN:
      return {
        ...state,
        updateHourlyPersonFieldsPending: true,
        updateHourlyPersonFieldsError: null,
      };

    case ACCOUNT_SETTINGS_UPDATE_HOURLY_PERSON_FIELDS_SUCCESS: {
      const hourlyPersonFields = state.hourlyPersonFields?.map((field) => {
        const newField = action.data.find(data => data.id === field.id);
        if (newField) {
          return {
            ...field,
            ...newField,
          };
        }
        return field;
      });
      return {
        ...state,
        hourlyPersonFields,
        updateHourlyPersonFieldsPending: false,
        updateHourlyPersonFieldsError: null,
      };
    }

    case ACCOUNT_SETTINGS_UPDATE_HOURLY_PERSON_FIELDS_FAILURE:
      return {
        ...state,
        updateHourlyPersonFieldsPending: false,
        updateHourlyPersonFieldsError: action.data.error,
      };

    case ACCOUNT_SETTINGS_UPDATE_HOURLY_PERSON_FIELDS_DISMISS_ERROR:
      return {
        ...state,
        updateHourlyPersonFieldsError: null,
      };

    default:
      return state;
  }
}
