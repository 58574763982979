import initialState from './initialState';
import { reducer as getUnfilledRolesReducer } from './getUnfilledRoles';
import { reducer as clearUnfilledRolesReducer } from './clearUnfilledRoles';
import { reducer as getProjectBreakdownReducer } from './getProjectBreakdown';
import { reducer as getProjectBreakdownAndRolesReducer } from './getProjectBreakdownAndRoles';
import { reducer as getIdleTimeBreakdownReducer } from './getIdleTimeBreakdown';
import { reducer as getIdleTimeOverviewReducer } from './getIdleTimeOverview';
import { reducer as getMonthlyIdleTimeOverviewReducer } from './getMonthlyIdleTimeOverview';

const reducers = [
  getUnfilledRolesReducer,
  clearUnfilledRolesReducer,
  getProjectBreakdownReducer,
  getProjectBreakdownAndRolesReducer,
  getIdleTimeBreakdownReducer,
  getIdleTimeOverviewReducer,
  getMonthlyIdleTimeOverviewReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
