import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import {
  EDITABLE_SEGMENT_TYPES,
  SEGMENT_TYPE_PHASE,
  SEGMENT_TYPE_PRIMARY_PHASE,
  SEGMENT_TYPE_PROJECT,
  SEGMENT_TYPE_ROLE,
} from './common/constants';

function GroupHeader({
  groupType,
  totalImpacted,
  typeUnderEdit,
}) {
  const formattedTypes = {
    [SEGMENT_TYPE_PRIMARY_PHASE]: 'phase',
    [SEGMENT_TYPE_ROLE]: 'role',
    [SEGMENT_TYPE_PHASE]: 'phase',
    [SEGMENT_TYPE_PROJECT]: 'project',
  };

  const groupLabel = () => {
    if (typeUnderEdit === SEGMENT_TYPE_PROJECT && groupType === SEGMENT_TYPE_PROJECT) {
      return 'Project date edited';
    }

    if (typeUnderEdit === SEGMENT_TYPE_PHASE && groupType === SEGMENT_TYPE_PRIMARY_PHASE) {
      return 'Phase date edited';
    }

    if (typeUnderEdit === SEGMENT_TYPE_PHASE && groupType === SEGMENT_TYPE_PROJECT) {
      return 'Project';
    }

    return `${pluralize(formattedTypes[groupType], totalImpacted, true)} impacted`;
  };

  return (
    <div className="edit-dates-group-header">
      <div className="segment-group-label">{groupLabel()}</div>
    </div>
  );
}

GroupHeader.propTypes = {
  groupType: PropTypes.oneOf(EDITABLE_SEGMENT_TYPES).isRequired,
  totalImpacted: PropTypes.number.isRequired,
  typeUnderEdit: PropTypes.oneOf(EDITABLE_SEGMENT_TYPES).isRequired,
};

/* istanbul ignore next */
function mapStateToProps({ editDates }) {
  const { typeUnderEdit } = editDates;

  return { typeUnderEdit };
}

export default connect(
  mapStateToProps,
)(GroupHeader);
