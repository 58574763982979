import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Button, Typography, Divider, Tooltip, Select, MenuItem } from '@material-ui/core';
import { Close, Person, Replay, Star } from '@material-ui/icons';
import moment from 'moment';
import * as actions from './redux/actions';
import { Modal } from '.';
import Confirm from './Confirm';
import { DATE_DISPLAY_FORMAT, PERM_SUPER_ADMIN, PERM_BRIDGIT_ADMIN_VIEW_ONLY, PERM_BRIDGIT_ADMIN } from '../../common/constants';

export class UserDetail extends PureComponent {
  static propTypes = {
    common: PropTypes.object.isRequired,
    userInfo: PropTypes.object.isRequired,
    boundActions: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    buttonText: PropTypes.string.isRequired,
    removeUser: PropTypes.func.isRequired,
    removeInvitation: PropTypes.func.isRequired,
    resendInvite: PropTypes.func.isRequired,
    canUpdateAdminPermission: PropTypes.bool.isRequired,
    updateAdminPermission: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      resendText: 'Resend',
      showRemoveConfirm: false,
    };
  }

  toggleModal = () => {
    const { boundActions, data } = this.props;
    boundActions.toggleModal(data.id);
  };

  resendInvitation = () => {
    const { resendInvite, data } = this.props;
    resendInvite(data.email);

    this.setState({
      resendText: 'Sent!',
    });

    setTimeout(() => {
      this.setState({
        resendText: 'Resend',
      });
    }, 2000);
  };

  removeFromList = () => {
    const { data, removeUser, removeInvitation } = this.props;

    if (data.status) {
      removeInvitation(data.id);
    } else {
      removeUser(data.id);
    }
  };

  onClickRemove = () => {
    this.setState({ showRemoveConfirm: true });
  };

  onRemoveCanceled = () => {
    this.setState({ showRemoveConfirm: false });
    this.toggleModal();
  };

  onRemoveConfirmed = () => {
    const { data, removeUser } = this.props;
    this.setState({ showRemoveConfirm: false });
    this.toggleModal();
    removeUser(data.id);
  };

  getButtons = (data, buttonText) => {
    const { userInfo } = this.props;
    // can not remove super admin, can not remove himself
    if (data.group === PERM_SUPER_ADMIN || data.id === parseInt(userInfo.sub, 10)) {
      return [];
    }
    return [
      <Button
        key="remove"
        className="button__default"
        disableRipple
        variant="contained"
        size="medium"
        color="default"
        onClick={this.onClickRemove}
      >
        {buttonText}
      </Button>,
    ];
  };

  renderModalContent = () => {
    const { data } = this.props;
    return (
      <span className="user-detail-modal">
        <Typography gutterBottom variant="h4" color="textPrimary" className="title">{data.name}</Typography>
        <Divider classes={{ root: 'divider' }} />

        <Grid item xs={12}>
          <Typography component="div" gutterBottom variant="caption">Email</Typography>
          <Typography variant="body1" classes={{ root: 'wrap-text' }}>{data.email}</Typography>
        </Grid>

        <Grid item xs={12} className="form__textfield">
          <div className="permissions-container">
            <Typography component="span" gutterBottom variant="caption">Permissions</Typography>
          </div>
          <Typography variant="body1">{data.group}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography component="span" gutterBottom variant="caption">Account Created</Typography>
          <Typography variant="body1">{moment(data.createdOn).format(DATE_DISPLAY_FORMAT)}</Typography>
        </Grid>
      </span>
    );
  }

  onAdminPermChange = ({ target }) => {
    const { data, updateAdminPermission } = this.props;
    updateAdminPermission(data.id, target.value);
  }

  render() {
    const { data, common, buttonText, userInfo, canUpdateAdminPermission } = this.props;
    const { resendText, showRemoveConfirm } = this.state;
    const invitationStatuses = ['Pending', 'Expired'];

    return (
      <Grid container item xs={12} classes={{ container: 'common-user-detail' }}>
        {data.group && common.editingUser === data.id && common.modalOpen && !showRemoveConfirm && (
          <Modal headline="Profile" data={data} closeModal={this.toggleModal} buttons={this.getButtons(data, buttonText)}>
            {this.renderModalContent()}
          </Modal>
        )}
        {common.editingUser === data.id && common.modalOpen && showRemoveConfirm && (
          <Confirm
            headline="Delete Admin"
            acceptButtonText="Delete Admin"
            cancelButtonText="Cancel"
            onCancel={this.onRemoveCanceled}
            onAccept={this.onRemoveConfirmed}
          >
            <div>
              <p>This admin will no longer be able to log in.</p>
              <p>Are you sure you want to delete them?</p>
            </div>
          </Confirm>
        )}
        <Grid container item xs={7} direction="column" justify="center">
          <Typography color="textPrimary" variant="body1" component="span" noWrap>{data.email}</Typography>
          <Typography color="textSecondary" variant="caption" component="span">
            {data.name}
            {data.id === parseInt(userInfo.sub, 10) ? ' (You)' : ''}
          </Typography>
          {invitationStatuses.includes(data.status) && (
            <>
              <Typography color="textSecondary" classes={{ root: 'italic' }} variant="caption">
                {data.status === 'Pending' ? 'Pending...' : data.status}
                {' '}
                {`invite sent ${moment(data.createdOn).format(DATE_DISPLAY_FORMAT)}`}
              </Typography>
              <Button type="submit" className="resend-button" onClick={this.resendInvitation}>
                {resendText === 'Resend' && (
                  <Replay classes={{ root: 'icon--replay' }} fontSize="small" color="primary" />
                )}
                <Typography color="primary" variant="caption">{resendText}</Typography>
              </Button>
            </>
          )}
        </Grid>
        <Grid container item xs={3} direction="column" justify="center" alignItems="center">
          {data.group === PERM_SUPER_ADMIN && (<Star color="primary" />)}
          {canUpdateAdminPermission && data.group !== PERM_SUPER_ADMIN ? (
            <Select
              value={data.group}
              onChange={this.onAdminPermChange}
            >
              {
                [PERM_BRIDGIT_ADMIN, PERM_BRIDGIT_ADMIN_VIEW_ONLY].map(permission => (
                  <MenuItem key={permission} value={permission}>
                    {permission}
                  </MenuItem>
                ))
              }
            </Select>
          ) : (
            <Typography color="primary" variant="caption">
              {data.group}
            </Typography>
          )}
        </Grid>
        <Grid container item xs={2} direction="column" justify="center" alignItems="flex-end">
          {!invitationStatuses.includes(data.status) ? (
            <Tooltip title="View profile" placement="top">
              <Button disableRipple onClick={this.toggleModal}>
                <Person color="action" />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title="Delete user" placement="top">
              <Button disableRipple onClick={this.removeFromList}>
                <Close color="action" data-id={data.id} />
              </Button>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    common: state.common,
    users: state.accounts.users,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    boundActions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserDetail);
