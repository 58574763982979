import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNTS_DEACTIVATE_ACCOUNT_BEGIN,
  ACCOUNTS_DEACTIVATE_ACCOUNT_SUCCESS,
  ACCOUNTS_DEACTIVATE_ACCOUNT_FAILURE,
  ACCOUNTS_DEACTIVATE_ACCOUNT_DISMISS_ERROR,
} from './constants';
import { protectedJsonDelete } from '../../../utils/api';

export function deactivateAccount(id) {
  return {
    type: ACCOUNTS_DEACTIVATE_ACCOUNT_BEGIN,
    id,
  };
}

export function dismissDeactivateAccountError() {
  return {
    type: ACCOUNTS_DEACTIVATE_ACCOUNT_DISMISS_ERROR,
  };
}

export function* doDeactivateAccount(action) {
  try {
    yield call(protectedJsonDelete, `accounts/${action.id}`);
  } catch (err) {
    yield put({
      type: ACCOUNTS_DEACTIVATE_ACCOUNT_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: ACCOUNTS_DEACTIVATE_ACCOUNT_SUCCESS,
    data: action,
  });
}

export function* watchDeactivateAccount() {
  yield takeLatest(ACCOUNTS_DEACTIVATE_ACCOUNT_BEGIN, doDeactivateAccount);
}

export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNTS_DEACTIVATE_ACCOUNT_BEGIN:
      return {
        ...state,
        deactivateAccountPending: true,
        deactivateAccountError: null,
      };

    case ACCOUNTS_DEACTIVATE_ACCOUNT_SUCCESS: {
      const entities = state.entities.map((account) => {
        if (account.id === action.data.id) {
          return {
            ...account,
            status: 'Deactivated',
          };
        }
        return account;
      });
      return {
        ...state,
        entities,
        deactivateAccountPending: false,
        deactivateAccountError: null,
      };
    }

    case ACCOUNTS_DEACTIVATE_ACCOUNT_FAILURE:
      return {
        ...state,
        deactivateAccountPending: false,
        deactivateAccountError: action.data.error,
      };

    case ACCOUNTS_DEACTIVATE_ACCOUNT_DISMISS_ERROR:
      return {
        ...state,
        deactivateAccountError: null,
      };

    default:
      return state;
  }
}
