import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  MenuItem,
  Select,
} from '@material-ui/core';
import { RequiredToggle, PrivateToggle } from '.';
import { OutlinedInput } from '../wrapped-components';
import { COMMON_FIELD_TYPES } from '../../common/constants';
import CancelButton from '../common/CancelButton';

export function CustomFieldAdd({
  field,
  onBlur,
  onFieldRemove,
  onChange,
  onTypeChange,
  onRequiredChange,
  onPrivateChange,
  onCreateList,
  disabled,
  id,
  disabledFieldTypes,
}) {
  const isList = field.type === 'SingleSelect' || field.type === 'MultiSelect';

  return (
    <div className="account-settings-custom-field-edit row" id={id}>
      <OutlinedInput
        disabled={disabled}
        value={field.name.toString()}
        placeholder="Enter a field title"
        className={`no-wrap visible${field.nameError ? ` error ${field.nameError}` : ''}`}
        onChange={onChange}
        onBlur={onBlur}
        inputProps={{ maxLength: 50 }}
      />

      <Select
        value={field.type}
        displayEmpty
        className={`form__select visible${field.typeError ? ` error ${field.typeError}` : ''}`}
        input={<OutlinedInput />}
        onChange={onTypeChange}
        disabled={disabled}
      >
        <MenuItem disabled value="">Select a field type</MenuItem>
        {COMMON_FIELD_TYPES.filter(t => !disabledFieldTypes.includes(t.value)).map(option => (
          <MenuItem
            name="fieldType"
            key={option.value}
            value={option.value}
          >
            {option.display}
          </MenuItem>
        ))}
      </Select>

      {isList && (!field.definedValues?.length) && (
        <Button
          disabled={!!field.nameError || disabled || !field.name}
          variant="outlined"
          color="primary"
          classes={{ label: 'create-btn' }}
          className={`form__select visible${field.listError ? ` error ${field.listError}` : ''}`}
          onClick={onCreateList}
        >
          Create a list
        </Button>
      )}

      {isList && field.definedValues?.length > 0 && (
        <OutlinedInput disabled value={`"${field.name}"`} className="form__select visible" />
      )}

      {field.type !== 'Boolean' && (
        <RequiredToggle
          onClick={onRequiredChange}
          disabled={disabled}
          required={field.isRequired}
          type="add"
        />
      )}
      <PrivateToggle
        onClick={onPrivateChange}
        isPrivate={field.isPrivate}
        type="add"
      />
      <CancelButton className={`button-delete${disabled ? ' delete-disabled' : ''}`} onClick={onFieldRemove} tooltipText="Delete field" />
    </div>
  );
}

CustomFieldAdd.propTypes = {
  field: PropTypes.object.isRequired,
  onBlur: PropTypes.func.isRequired,
  onFieldRemove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onTypeChange: PropTypes.func.isRequired,
  onRequiredChange: PropTypes.func.isRequired,
  onPrivateChange: PropTypes.func.isRequired,
  onCreateList: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  disabledFieldTypes: PropTypes.array.isRequired,
};

CustomFieldAdd.defaultProps = {
  disabled: false,
};

export default CustomFieldAdd;
