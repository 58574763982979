import momentBase from 'moment';
import { extendMoment } from 'moment-range';
import { DATE_DISPLAY_FORMAT, DATE_INPUT_FORMAT, TIME_DISPLAY_FORMAT } from 'src/common/constants';

import { isRangeOrSegment } from 'src/utils/dateSegmentUtils';

const moment = extendMoment(momentBase);

const stringToMoment = inputString => moment(inputString, DATE_INPUT_FORMAT);

const momentToString = inputMoment => (moment.isMoment(inputMoment) ? inputMoment.format(DATE_INPUT_FORMAT) : inputMoment);

const isStringOrMoment = value => typeof value === 'string' || moment.isMoment(value);

const dayBefore = date => moment(date).subtract(1, 'days').format(DATE_INPUT_FORMAT);

const dayAfter = date => moment(date).add(1, 'days').format(DATE_INPUT_FORMAT);

const dateStringAdd = (date, amount = 0, unit = 'days') => (
  momentToString(stringToMoment(date).add(amount, unit))
);

const dateStringSubtract = (date, amount = 0, unit = 'days') => (
  momentToString(stringToMoment(date).subtract(amount, unit))
);

/*
  Returns the duration indicated by the start and end date, inclusive.
  If the two dates are the same, the duration returned will be 1.
*/
const getDurationDays = (startDate, endDate) => {
  const startParsed = stringToMoment(startDate).startOf('day');
  const endParsed = stringToMoment(endDate).endOf('day');
  return Math.round(endParsed.diff(startParsed, 'days', true));
};

/*
  Returns the absolute difference between two dates in days.
  Passing the same date for start and end will return 0.
  Passing two consecutive days will return 1.
 */
const getGapDays = (startDate, endDate) => Math.abs(moment(endDate).diff(moment(startDate), 'days'));

// Takes a start and end date string and returns a moment-range object: https://github.com/rotaready/moment-range
const toRange = (start, end) => moment.range(
  moment(start, DATE_INPUT_FORMAT),
  moment(end, DATE_INPUT_FORMAT),
);

const fromRange = (range) => {
  const [startDate, endDate] = range.toDate();
  return {
    startDate: moment(startDate).format(DATE_INPUT_FORMAT),
    endDate: moment(endDate).format(DATE_INPUT_FORMAT),
  };
};

function validateDate(date, min, max) {
  const parsed = moment(date, DATE_INPUT_FORMAT);
  const minParsed = moment(min, DATE_INPUT_FORMAT);
  const maxParsed = moment(max, DATE_INPUT_FORMAT);

  /*
    Confirm that the date can be parsed or was a valid moment/Date object

    This is more restrictive than the moment() constructor which will accept
    things like numbers or no params at all. In this case only parsable strings,
    JS Date objects, or already-valid moment objects are accepted.

    It's worth noting that '123' will still be considered valid, so this function
    won't protect against all garbage inputs.
  */

  if (!(
    ((typeof date === 'string' || date instanceof Date) && parsed.isValid()) ||
    (moment.isMoment(date) && date.isValid())
  )) return false;

  // If a min date was provided, validate accordingly
  if (min && minParsed.isValid()) {
    if (!parsed.isSameOrAfter(minParsed)) return false;
  }

  // If a max date was provided, validate accordingly
  if (max && maxParsed.isValid()) {
    if (!parsed.isSameOrBefore(maxParsed)) return false;
  }

  return true;
}

function subtractMomentRanges(minuend, subtrahend) {
  let updatedRange = [];

  // Validate that given params can be used as moment ranges: https://github.com/rotaready/moment-range
  if (!(moment.isRange(minuend) && moment.isRange(subtrahend))) return null;

  // Return empty array if minuend is entirely contained within subtrahend
  if (subtrahend.contains(minuend)) return [];

  if (!minuend.isSame(subtrahend)) {
    updatedRange = minuend.subtract(subtrahend);
  }

  // Range subtraction considers identical dates to be adjacent, rather than overlapping
  // See discussion here: https://github.com/rotaready/moment-range/issues/45
  if (updatedRange.length) {
    if (updatedRange[0].end.isSame(subtrahend.start)) {
      updatedRange[0].end = moment(updatedRange[0].end).subtract(1, 'day');
    }

    if (updatedRange[0].start.isSame(subtrahend.end)) {
      updatedRange[0].start = moment(updatedRange[0].start).add(1, 'day');
    }

    // Segment was split by this allocation
    if (updatedRange.length === 2 && updatedRange[1].start.isSame(subtrahend.end)) {
      updatedRange[1].start = moment(updatedRange[1].start).add(1, 'day');
    }
  }

  return updatedRange;
}

/*
  Given two ranges, return a new range made of the overlapping
  period of time between the two. Returns null if params are invalid,
  or if there is no overlap between the given ranges.
*/
function getIntersectingMomentRanges(first, second) {
  // Validate that given params can be used as moment ranges: https://github.com/rotaready/moment-range
  if (!(moment.isRange(first) && moment.isRange(second))) return null;

  // If the two don't overlap, return null
  if (!(first.overlaps(second, { adjacent: true }))) return null;

  return moment.range(
    Math.max(first.start, second.start),
    Math.min(first.end, second.end),
  );
}

/*
  Given two moment-range or segment objects, returns a number representing the percentage
  of the second that can be contained within the first. The ranges are compared by length,
  meaning there is no requirement that the dates actually overlap.
*/
function getRelativeRangeLength(first, second) {
  if (!(
    isRangeOrSegment(first) &&
    isRangeOrSegment(second))
  ) return null;

  const getDates = obj => Object.values(moment.isRange(obj) ? fromRange(obj) : obj);

  const [firstStart, firstEnd] = getDates(first);
  const [secondStart, secondEnd] = getDates(second);

  const firstDuration = getDurationDays(firstStart, firstEnd);
  const secondDuration = getDurationDays(secondStart, secondEnd);

  return (Math.abs(firstDuration) / Math.abs(secondDuration)) * 100;
}

/*
  Given two moment-range or segment objects, return true if the time periods overlap
  and false if they do not.
*/
function rangesOverlap(first, second, adjacent = true) {
  if (!(
    isRangeOrSegment(first) &&
    isRangeOrSegment(second))
  ) return null;

  const firstRange = moment.isRange(first) ? first : toRange(first.startDate, first.endDate);
  const secondRange = moment.isRange(second) ? second : toRange(second.startDate, second.endDate);

  return firstRange.overlaps(secondRange, { adjacent: !!adjacent });
}

const calculateSpacerDimensions = (first, second) => {
  if (!(
    isRangeOrSegment(first) &&
    isRangeOrSegment(second))
  ) return { startPadPercentage: 0, endPadPercentage: 0 };

  const { startDate, endDate } = moment.isRange(first) ? fromRange(first) : first;
  const { startDate: boundingStartDate, endDate: boundingEndDate } =
    moment.isRange(second) ? fromRange(second) : second;

  const startGapDays = getGapDays(boundingStartDate, startDate);
  const endGapDays = getGapDays(endDate, boundingEndDate);
  const projectDays = getDurationDays(boundingStartDate, boundingEndDate);

  const startPadPercentage = startGapDays !== 0 ? (startGapDays / projectDays) * 100 : 0;
  const endPadPercentage = endGapDays !== 0 ? (endGapDays / projectDays) * 100 : 0;

  return {
    startPadPercentage,
    endPadPercentage,
  };
};

const getEntityState = (startDate, endDate) => {
  const now = moment.utc().startOf('day');

  if ((now - endDate) > 0) return 'Past';
  if ((now - startDate) < 0) return 'Upcoming';

  return 'Current';
};

/*
  Given the date string, format it to the standard form: MMM D, YYYY (or Jan 1, 1970).
*/
const getFormattedDate = date => moment(date).format(DATE_DISPLAY_FORMAT);

/*
  Given the date string, format it to display date and time: MMM D, YYYY at LT (or Jan 1, 1970 at 12:00PM).
*/
const getFormattedDateAndTime = date => `${moment(date).format(DATE_DISPLAY_FORMAT)} at ${moment(date).format(TIME_DISPLAY_FORMAT)}`;

// Pass the endDate of a resource
const isPast = endDate => moment().isAfter(stringToMoment(endDate));

// Needs both startDate and endDate
const isCurrent = (startDate, endDate) => moment().isBetween(stringToMoment(startDate), stringToMoment(endDate), undefined, '[]');

// Pass the startDate of a resource
const isUpcoming = startDate => moment().isBefore(stringToMoment(startDate));

const formatDateFullMonthYear = date => moment(date).format('MMMM YYYY');

export {
  stringToMoment,
  momentToString,
  isStringOrMoment,
  dayBefore,
  dayAfter,
  dateStringAdd,
  dateStringSubtract,
  getDurationDays,
  getGapDays,
  toRange,
  fromRange,
  validateDate,
  subtractMomentRanges,
  getIntersectingMomentRanges,
  getRelativeRangeLength,
  rangesOverlap,
  calculateSpacerDimensions,
  getEntityState,
  getFormattedDate,
  getFormattedDateAndTime,
  isPast,
  isCurrent,
  isUpcoming,
  formatDateFullMonthYear,
};
