import React from 'react';
import { useSelector } from 'react-redux';
import { HelpTooltip } from '@bridgit/foundation';
import { makeStyles } from '@material-ui/core';

import { ACCOUNT_MODULE_LOCATION, HOME_ADDRESS_FIELD } from '../../common/constants';
import { Can } from '../wrapped-components';
import { PERM_READ, PERM_PRIVATE } from '../permissions/utils/constants';
import { DISTANCE_UNITS } from '../accounts/common/constants';

const { KM, MI } = DISTANCE_UNITS;
const useTooltipStyles = makeStyles({
  tooltip: {
    maxWidth: 200,
  },
});

const CandidateListDistanceHeading = () => {
  const tooltipClasses = useTooltipStyles();

  const { accountId } = useSelector(({ common }) => common);
  const isAddressPrivate = useSelector(({ accountSettings: { personFields } }) => (
    personFields?.find(({ name }) => name === HOME_ADDRESS_FIELD)?.isPrivate ?? true
  ));
  const { value: distanceUnit } = useSelector(({ accounts: { entities } }) => {
    const { useMetricUnits } = entities.find(({ id }) => id === accountId) || {};

    return useMetricUnits ? KM : MI;
  });

  return (
    <Can
      module={ACCOUNT_MODULE_LOCATION}
      action={PERM_READ}
      subject={isAddressPrivate ? PERM_PRIVATE : null}
      yes={(
        <div className="distance-unit">
          {distanceUnit}
          <HelpTooltip
            text="This is the distance between the project address and a person's home address."
            classes={tooltipClasses}
          />
        </div>
      )}
    />
  );
};

export default CandidateListDistanceHeading;
