import { call, put, takeLatest } from 'redux-saga/effects';
import {
  SELF_PERFORM_GET_HOURLY_PERSON_BY_ID_BEGIN,
  SELF_PERFORM_GET_HOURLY_PERSON_BY_ID_SUCCESS,
  SELF_PERFORM_GET_HOURLY_PERSON_BY_ID_FAILURE,
  SELF_PERFORM_GET_HOURLY_PERSON_BY_ID_DISMISS_ERROR,
} from './constants';
import { getHourlyPersonByIdQuery } from '../queries/getHourlyPersonByIdQuery';
import graphApiClient from '../../../common/GraphAPIClient';

export function getHourlyPersonById(accountId, selectedHourlyPersonId) {
  const { query, variables } = getHourlyPersonByIdQuery(accountId, selectedHourlyPersonId);

  return {
    type: SELF_PERFORM_GET_HOURLY_PERSON_BY_ID_BEGIN,
    accountId,
    selectedHourlyPersonId,
    query,
    variables,
  };
}

export function dismissGetHourlyPersonByIdError() {
  return {
    type: SELF_PERFORM_GET_HOURLY_PERSON_BY_ID_DISMISS_ERROR,
  };
}

export function* doGetHourlyPersonById(action) {
  const { query, variables } = action;

  let res;
  let selectedHourlyPerson;
  try {
    res = yield call(graphApiClient.query, {
      query,
      variables,
    });

    const { errors } = res;

    if (errors) {
      throw errors;
    }

    selectedHourlyPerson = res?.data?.hourlyPerson || null;
  } catch (err) {
    yield put({
      type: SELF_PERFORM_GET_HOURLY_PERSON_BY_ID_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: SELF_PERFORM_GET_HOURLY_PERSON_BY_ID_SUCCESS,
    data: selectedHourlyPerson,
  });
}

export function* watchGetHourlyPersonById() {
  yield takeLatest(SELF_PERFORM_GET_HOURLY_PERSON_BY_ID_BEGIN, doGetHourlyPersonById);
}

export function reducer(state, action) {
  switch (action.type) {
    case SELF_PERFORM_GET_HOURLY_PERSON_BY_ID_BEGIN:
      return {
        ...state,
        getHourlyPersonByIdPending: true,
        getHourlyPersonByIdError: null,
      };

    case SELF_PERFORM_GET_HOURLY_PERSON_BY_ID_SUCCESS: {
      const { data: selectedHourlyPerson } = action;

      return {
        ...state,
        selectedHourlyPerson,
        getHourlyPersonByIdPending: false,
        getHourlyPersonByIdError: null,
      };
    }

    case SELF_PERFORM_GET_HOURLY_PERSON_BY_ID_FAILURE:
      return {
        ...state,
        selectedHourlyPerson: null,
        getHourlyPersonByIdPending: false,
        getHourlyPersonByIdError: action.data.error,
      };

    case SELF_PERFORM_GET_HOURLY_PERSON_BY_ID_DISMISS_ERROR:
      return {
        ...state,
        getHourlyPersonByIdError: null,
      };

    default:
      return state;
  }
}
