import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Tooltip,
} from '@material-ui/core';
import {
  Edit,
} from '@material-ui/icons';
import moment from 'moment';
import { DatePicker } from '.';
import { DATE_INPUT_FORMAT, DATE_DISPLAY_FORMAT } from '../../common/constants';
import { EditControls } from '../wrapped-components';

export default class DateEditor extends Component {
  static propTypes = {
    date: PropTypes.object.isRequired,
    shouldDisableDate: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    minDateMessage: PropTypes.string,
    maxDateMessage: PropTypes.string,
    editMode: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
    onEditToggle: PropTypes.func,
    outerTheme: PropTypes.object,
    onSave: PropTypes.func,
    editIconTooltip: PropTypes.string,
    disableSaveButton: PropTypes.bool,
    onError: PropTypes.func,
    pending: PropTypes.bool,
  };

  static defaultProps = {
    editMode: false,
    onEditToggle: null,
    minDate: undefined,
    maxDate: undefined,
    minDateMessage: '',
    maxDateMessage: '',
    shouldDisableDate: () => {},
    outerTheme: {},
    onSave: null,
    editIconTooltip: '',
    disableSaveButton: false,
    onError: () => {},
    pending: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      disableSave: props.disableSaveButton,
    };
  }

  componentDidUpdate(prevProps) {
    const { disableSaveButton, editMode } = this.props;

    if (!editMode && editMode !== prevProps.editMode) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ editMode });
    }

    if (disableSaveButton !== prevProps.disableSaveButton) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        disableSave: disableSaveButton,
      });
    }

    return {};
  }

  onEditToggle = () => {
    const { onEditToggle } = this.props;
    const { editMode } = this.state;
    if (onEditToggle) {
      onEditToggle();
    }
    this.setState({
      editMode: !editMode,
    });
  }

  onError = (error = '') => {
    const { onError } = this.props;
    const { disableSave: currDisableSave } = this.state;
    const disableSave = !!error.length;

    if (currDisableSave !== disableSave) {
      this.setState({ disableSave });
    }
    onError(error);
  }

  render() {
    const { editMode, disableSave } = this.state;
    const {
      date,
      minDate,
      maxDate,
      shouldDisableDate,
      minDateMessage,
      maxDateMessage,
      outerTheme,
      onSave,
      editIconTooltip,
      onChange,
      pending,
    } = this.props;
    const formattedDate = moment(date, DATE_INPUT_FORMAT).format(DATE_DISPLAY_FORMAT);

    return (
      <>
        <div className={`common-date-editor${editMode ? ' date-enabled' : ''}`}>
          {!editMode && (
            <>
              <span>{formattedDate}</span>
              <Tooltip title={editIconTooltip} placement="top">
                <IconButton className="edit-date" onClick={this.onEditToggle}>
                  <Edit fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </>
          )}
          {editMode && (
            <div className="date-wrapper">
              <DatePicker
                minDate={minDate}
                minDateMessage={minDateMessage}
                maxDate={maxDate}
                maxDateMessage={maxDateMessage}
                shouldDisableDate={shouldDisableDate}
                date={date}
                onChange={onChange}
                onError={this.onError}
                outerTheme={outerTheme}
                editing={editMode}
                variant="inline"
              />
            </div>
          )}
        </div>
        {editMode && onSave && (
          <EditControls
            className="date-editor-action-buttons"
            primaryAction={onSave}
            secondaryAction={this.onEditToggle}
            disabled={disableSave}
            pending={pending}
          />
        )}
      </>
    );
  }
}
