import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TextField, InputAdornment } from '@material-ui/core';
import { RemoveButton } from '../common';
import { AUTOMATION_PROJECTS_ROLE_NOTE_FIELD } from './ids';

export const RoleNoteField = ({
  className,
  onNoteChange,
  note,
  clearNote,
  onBlur,
  showDeleteWhenEmpty,
  fullWidth,
  autoFocus,
  onKeyUp,
}) => (
  <TextField
    className={classNames('projects-role-note-field', className)}
    placeholder="Enter a note about this role"
    id={AUTOMATION_PROJECTS_ROLE_NOTE_FIELD}
    onChange={onNoteChange}
    value={note || ''}
    inputProps={{ maxLength: 50 }}
    onBlur={onBlur}
    fullWidth={fullWidth}
    autoFocus={autoFocus}
    onKeyUp={onKeyUp}
    // eslint-disable-next-line react/jsx-no-duplicate-props
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          {(showDeleteWhenEmpty || note?.length > 0) && (
            <RemoveButton
              className="delete-note-button"
              tooltipValue="Delete note"
              onClick={clearNote}
            />
          )}
        </InputAdornment>
      ),
    }}
  />
);

RoleNoteField.propTypes = {
  className: PropTypes.string,
  onNoteChange: PropTypes.func.isRequired,
  note: PropTypes.string,
  clearNote: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  showDeleteWhenEmpty: PropTypes.bool,
  fullWidth: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onKeyUp: PropTypes.func,
};

RoleNoteField.defaultProps = {
  className: '',
  note: '',
  onBlur: () => {},
  showDeleteWhenEmpty: false,
  fullWidth: false,
  autoFocus: false,
  onKeyUp: () => {},
};

export default RoleNoteField;
