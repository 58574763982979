import { AppBar, Toolbar, Typography, IconButton, Tooltip, MenuItem, Select } from '@material-ui/core';
import { Help, Menu, OpenInNew, Visibility, VisibilityOff } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { matchPath } from 'react-router-dom';
import { I18nSelector, Button } from '@bridgit/foundation';
import history from 'src/common/history';
import { getPermissionsForAccount } from 'src/features/permissions/utils/permissionUtils';
import { COMMON_SUPPORT_CLICKED } from 'src/analytics/common/constants';
import { setAccountInfo } from '../../common/analyticsHelper';
import { setActiveAccountId, toggleNavigation, setPrivateMode } from './redux/actions';
import { LAST_VISITED_ACCOUNT_ID_KEY } from '../../common/localStorageKeys';
import { clearProjectSelection } from '../projects/redux/clearProjectSelection';
import { PROJECT_GANTT_SELECTION_ID, PROJECT_LIST_SELECTION_ID } from '../projects/redux/constants';
import { PEOPLE_LIST_SELECTION_ID } from '../people/redux/constants';
import { clearPersonSelection } from '../people/redux/clearPersonSelection';
import { LIST_TAB, PERM_SUPER_ADMIN } from '../../common/constants';
import { clearSelectedHourlyPerson } from '../self-perform/redux/clearSelectedHourlyPerson';
import { ThemeToggle } from '../themes';
import { changeTheme } from '../themes/redux/changeTheme';
import { AUTOMATION_TOP_BAR_MENU_ICON } from './ids';
import { Can } from '../wrapped-components';
import { PERM_READ, PERM_PRIVATE, PERM_FINANCIALS } from '../permissions/utils/constants';
import { setLocale } from '../locale/redux/setLocale';
import { EN } from '../locale/redux/constants';
import { LIGHT } from './constants';

export class TopBar extends PureComponent {
  static propTypes = {
    collapsedNav: PropTypes.bool.isRequired,
    toggleNavigation: PropTypes.func.isRequired,
    setActiveAccountId: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    getAccountsPending: PropTypes.bool.isRequired,
    entities: PropTypes.array,
    isBridgitAdmin: PropTypes.bool.isRequired,
    isBridgitSuperAdmin: PropTypes.bool.isRequired,
    accountId: PropTypes.number,
    userId: PropTypes.string,
    clearProjectSelection: PropTypes.func.isRequired,
    clearPersonSelection: PropTypes.func.isRequired,
    clearSelectedHourlyPerson: PropTypes.func.isRequired,
    contentView: PropTypes.number,
    setLocale: PropTypes.func.isRequired,
    locale: PropTypes.string,
    privateModeEnabled: PropTypes.bool,
    setPrivateMode: PropTypes.func.isRequired,
    i18nFlag: PropTypes.bool,
  };

  static defaultProps = {
    entities: [],
    userId: '',
    accountId: null,
    contentView: 0,
    locale: EN,
    privateModeEnabled: true,
    i18nFlag: false,
  }

  constructor(props) {
    super(props);

    const { isBridgitAdmin, entities } = props;
    const title = this.tryToGetAccountName(props);

    if (window.Appcues) window.Appcues.page();

    this.state = {
      title,
      isMultiAccountSelectAvailable: !isBridgitAdmin && entities.length > 1 && title !== 'User profile',
    };
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { pathname, getAccountsPending, isBridgitAdmin, isBridgitSuperAdmin } = this.props;
    const accountsChanged = getAccountsPending && !nextProps.getAccountsPending;
    const pathChanged = pathname !== nextProps.pathname;

    let title = '';

    if (pathChanged || accountsChanged) {
      if (nextProps.pathname === '/') {
        title = 'Account Manager';
      } else if (nextProps.pathname === '/settings' && isBridgitSuperAdmin) {
        title = 'Admin Manager';
      } else if (nextProps.pathname === '/profile') {
        title = 'User profile';
      } else {
        title = this.tryToGetAccountName(nextProps);
      }

      const isMultiAccountSelectAvailable = nextProps.entities.length > 1 && !isBridgitAdmin && title !== 'User profile';

      this.setState({
        title,
        isMultiAccountSelectAvailable,
      });
    }

    if (pathChanged && window.Appcues) {
      window.Appcues.page();
    }
  }

  tryToGetAccountName = ({ pathname, entities, accountId }) => {
    if (accountId) {
      const retrievedAccount = entities.find(({ id }) => id === accountId);
      if (retrievedAccount) {
        // Mixpanel - Set account info as a super property
        setAccountInfo(retrievedAccount);
        return retrievedAccount.name;
      }
    }

    if (pathname.indexOf('/accounts') !== -1) {
      const mp = matchPath(pathname, {
        path: '/accounts/:id',
      });

      if (mp && mp.params) {
        const accountId = Number(mp.params.id);

        const retrievedAccount = entities.find(({ id }) => id === accountId);
        if (retrievedAccount) {
          // Mixpanel - Set account info as a super property
          setAccountInfo(retrievedAccount);
          return retrievedAccount.name;
        }
      }
    }
    return '';
  }

  trackHelp = () => {
    window.mixpanel.track(COMMON_SUPPORT_CLICKED);
  }

  handleSwitchAccount = ({ target: { value } }) => {
    const {
      setActiveAccountId,
      pathname,
      userId,
      clearProjectSelection,
      clearPersonSelection,
      clearSelectedHourlyPerson,
      contentView,
    } = this.props;

    const isSettingsPage = pathname.includes('settings');
    const pathToMatch = `/accounts/:id/:resource/${isSettingsPage ? ':remainingParam/' : ''}`;
    const { params: { resource, remainingParam } } = matchPath(pathname, {
      path: pathToMatch,
    });

    if (resource === 'people') {
      clearPersonSelection(PEOPLE_LIST_SELECTION_ID);
      clearSelectedHourlyPerson();
    }

    if (resource === 'projects') {
      if (contentView === LIST_TAB) {
        clearProjectSelection(PROJECT_LIST_SELECTION_ID);
      } else {
        clearProjectSelection(PROJECT_GANTT_SELECTION_ID);
      }
    }

    localStorage.setItem(`${LAST_VISITED_ACCOUNT_ID_KEY}-${userId}`, value);
    setActiveAccountId(value, { isNavigatingBetweenAccounts: true });

    let newURL = `/accounts/${value}/${resource}`;
    if (isSettingsPage) newURL += `/${remainingParam}`;

    history.push(newURL);
  }

  trackLaunchpad = () => {
    window.mixpanel.track('Launchpad Clicked');
  }

  onPrivateModeToggle = () => {
    const { setPrivateMode, privateModeEnabled } = this.props;
    setPrivateMode(!privateModeEnabled);
  }

  handleLocaleChange = (event) => {
    const { setLocale } = this.props;
    const { value } = event?.target;
    setLocale(value);
  }

  render() {
    const {
      collapsedNav,
      toggleNavigation,
      entities,
      accountId,
      locale,
      i18nFlag,
      privateModeEnabled,
    } = this.props;
    const { title, isMultiAccountSelectAvailable } = this.state;

    const localeItems = [
      {
        locale: 'en',
        label: 'en',
        icon: '',
      },
    ];

    return (
      <AppBar
        className={`common-top-bar app-bar${collapsedNav ? '--expanded' : ''}`}
        elevation={0}
        position="fixed"
        color="primary"
      >
        <Toolbar variant="dense" classes={{ root: 'app-header' }}>
          <IconButton
            id={AUTOMATION_TOP_BAR_MENU_ICON}
            classes={{ root: 'icon-button' }}
            onClick={toggleNavigation}
            color="inherit"
            aria-label="Menu"
            size="small"
          >
            <Menu />
          </IconButton>
          <div className="account-name">
            <Typography className="bar-title" variant="h6" color="inherit">{title}</Typography>
            {isMultiAccountSelectAvailable && (
              <Select
                className="accounts-dropdown"
                onChange={this.handleSwitchAccount}
                MenuProps={{
                  className: `app-page-offset multi-account-dropdown-popover ${collapsedNav ? 'expanded' : ''}`,
                  classes: { paper: 'dropdown-shell' },
                }}
                value=""
                classes={{ selectMenu: 'select-menu' }}
              >
                {entities.map(({ name, id }) => (
                  <MenuItem
                    key={id}
                    value={id}
                    className={accountId === id ? 'active-account' : ''}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </div>

          <ThemeToggle className="tool-bar-margin-left" />

          <Can
            action={PERM_READ}
            subject={[PERM_PRIVATE, PERM_FINANCIALS]}
            inclusive={false}
            yes={(
              <Button
                className="private-mode-button"
                startIcon={privateModeEnabled ? <Visibility /> : <VisibilityOff />}
                onClick={this.onPrivateModeToggle}
                color="primary"
                variant="contained"
              >
                { `${privateModeEnabled ? 'View' : 'Hide'} confidential fields` }
              </Button>
            )}
          />

          <div
            id="appcues-launchpad"
            className="appcues-launchpad"
            onClick={this.trackLaunchpad}
            role="presentation"
          />

          <a
            href="https://support.bridgitsolutions.com/hc/en-us/categories/360001651432-Bridgit-Bench"
            rel="noopener noreferrer"
            target="_blank"
            onClick={this.trackHelp}
          >
            <Tooltip
              classes={{ tooltip: 'topbar-tooltip' }}
              title={(
                <>
                  <span>Bench Support</span>
                  <OpenInNew className="open-icon" />
                </>
              )}
              placement="bottom-end"
            >
              <IconButton
                classes={{ root: 'icon-button' }}
                aria-label="Help"
                size="small"
              >
                <Help />
              </IconButton>
            </Tooltip>
          </a>

          {
            i18nFlag && (
              <div className="locale">
                <I18nSelector
                  onChange={this.handleLocaleChange}
                  locales={localeItems}
                  selectedLocale={locale}
                  variant={LIGHT}
                />
              </div>
            )
          }
        </Toolbar>
      </AppBar>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps({
  common: { collapsedNav, accountId, contentView, privateModeEnabled },
  accounts: {
    getAccountsPending,
    entities,
  },
  login: { userInfo: { permissions, sub } },
  locale: { locale },
  launchDarkly: { i18N },
}) {
  return {
    collapsedNav,
    getAccountsPending,
    entities,
    isBridgitAdmin: !!permissions?.['*'],
    isBridgitSuperAdmin: getPermissionsForAccount('*', permissions)?.group === PERM_SUPER_ADMIN,
    accountId,
    userId: sub,
    contentView,
    locale,
    privateModeEnabled,
    i18nFlag: i18N,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    toggleNavigation: bindActionCreators(toggleNavigation, dispatch),
    setActiveAccountId: bindActionCreators(setActiveAccountId, dispatch),
    clearProjectSelection: bindActionCreators(clearProjectSelection, dispatch),
    clearPersonSelection: bindActionCreators(clearPersonSelection, dispatch),
    clearSelectedHourlyPerson: bindActionCreators(clearSelectedHourlyPerson, dispatch),
    setLocale: bindActionCreators(setLocale, dispatch),
    changeTheme: bindActionCreators(changeTheme, dispatch),
    setPrivateMode: bindActionCreators(setPrivateMode, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopBar);
