import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNT_SETTINGS_DELETE_PHASE_BEGIN,
  ACCOUNT_SETTINGS_DELETE_PHASE_SUCCESS,
  ACCOUNT_SETTINGS_DELETE_PHASE_FAILURE,
  ACCOUNT_SETTINGS_DELETE_PHASE_DISMISS_ERROR,
} from './constants';
import { protectedJsonDelete } from '../../../utils/api';

export function deletePhase(accountId, phaseIds) {
  return {
    type: ACCOUNT_SETTINGS_DELETE_PHASE_BEGIN,
    accountId,
    phaseIds,
  };
}

export function dismissDeletePhaseError() {
  return {
    type: ACCOUNT_SETTINGS_DELETE_PHASE_DISMISS_ERROR,
  };
}

export function* doDeletePhase(action) {
  const { accountId, phaseIds } = action;

  try {
    yield call(protectedJsonDelete, `accounts/${accountId}/phases`, phaseIds);
  } catch (err) {
    yield put({
      type: ACCOUNT_SETTINGS_DELETE_PHASE_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: ACCOUNT_SETTINGS_DELETE_PHASE_SUCCESS,
    data: phaseIds,
  });
}

export function* watchDeletePhase() {
  yield takeLatest(ACCOUNT_SETTINGS_DELETE_PHASE_BEGIN, doDeletePhase);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNT_SETTINGS_DELETE_PHASE_BEGIN:
      return {
        ...state,
        deletePhasePending: true,
        deletePhaseError: null,
      };

    case ACCOUNT_SETTINGS_DELETE_PHASE_SUCCESS: {
      const { data } = action;
      const { phases } = state;
      const newPhases = phases.filter(phase => !data.includes(phase.id));

      return {
        ...state,
        phases: newPhases,
        deletePhasePending: false,
        deletePhaseError: null,
      };
    }

    case ACCOUNT_SETTINGS_DELETE_PHASE_FAILURE:
      return {
        ...state,
        deletePhasePending: false,
        deletePhaseError: action.data.error,
      };

    case ACCOUNT_SETTINGS_DELETE_PHASE_DISMISS_ERROR:
      return {
        ...state,
        deletePhaseError: null,
      };

    default:
      return state;
  }
}
