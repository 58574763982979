import {
  MODAL_MANAGER_OPEN_MODAL,
} from './constants';

export function openModal(modalId) {
  return {
    type: MODAL_MANAGER_OPEN_MODAL,
    modalId,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case MODAL_MANAGER_OPEN_MODAL: {
      const { modalId } = action;
      return {
        ...state,
        activeModal: modalId,
      };
    }

    default:
      return state;
  }
}
