import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button } from '@bridgit/foundation';
import { AccountDetails, AccountUserList } from 'src/features/accounts';
import { Can } from 'src/features/wrapped-components';
import { Confirm } from 'src/features/common';
import { PERM_ACCOUNT, PERM_WRITE } from 'src/features/permissions/utils/constants';
import { BRIDGIT_SUPPORT_EMAIL } from 'src/common/constants';
import { PermissionGroups } from 'src/features/permissions';
import { ServiceAccountsTab } from 'src/features/service-accounts';
import {
  deactivateAccount,
  reactivateAccount,
  dismissInviteUserError,
} from 'src/features/accounts/redux/actions';
import { BridgitAccountDetails, AccountModule } from '.';
import { SETTINGS_SIDE_TABS } from './common/constants';

function AccountSettings({
  accounts,
  accountId,
  deactivateAccount,
  reactivateAccount,
  dismissInviteUserError,
  activeSideTab,
}) {
  const [confirmDeactivate, setConfirmDeactivate] = useState(false);

  const account = useMemo(
    () => accounts.entities.find(account => account.id === accountId),
    [accounts.entities, accountId],
  );

  const isActive = useMemo(() => account && account.status === 'Active', [account]);

  const action = isActive ? 'Deactivate' : 'Reactivate';
  const actionPending = accounts.deactivateAccountPending || accounts.reactivateAccountPending;
  const message = {
    Deactivate: () => (
      <div>
        <p>Are you sure you want to deactivate this account?</p>
        <p>All users in this account will be deactivated and will no longer be able to log into Bench.</p>
      </div>
    ),
    Reactivate: () => (
      <div>
        <p>Are you sure you want to reactivate this account?</p>
        <p>This will not reactivate any users in this account.</p>
      </div>
    ),
  };

  const toggleConfirm = () => setConfirmDeactivate(!confirmDeactivate);

  const changeAccountStatus = () => {
    if (isActive) {
      deactivateAccount(accountId);
    } else {
      reactivateAccount(accountId);
    }

    toggleConfirm();
  };

  const renderContent = () => {
    const {
      USERS,
      SERVICE_ACCOUNTS,
      PERMISSION_GROUPS,
      ACCOUNT_MANAGEMENT,
      BRIDGIT_ACCOUNT_MANAGEMENT,
    } = SETTINGS_SIDE_TABS;

    switch (activeSideTab) {
      case USERS:
        return <AccountUserList />;
      case SERVICE_ACCOUNTS:
        return <ServiceAccountsTab />;
      case PERMISSION_GROUPS:
        return <PermissionGroups />;
      case ACCOUNT_MANAGEMENT:
        return <AccountDetails />;
      case BRIDGIT_ACCOUNT_MANAGEMENT:
        return (
          <>
            <BridgitAccountDetails />
            <AccountModule />
            <Can
              action={PERM_WRITE}
              subject={PERM_ACCOUNT}
              yes={(
                <div className="deactivate-account">
                  <Button
                    color="warning"
                    variant="outlined"
                    onClick={toggleConfirm}
                  >
                    {`${action} Account`}
                  </Button>
                </div>
              )}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="account-settings">
      {renderContent()}

      {(confirmDeactivate || actionPending) && (
        <Confirm
          headline={`${action} Account`}
          cancelButtonText="Cancel"
          acceptButtonText={action}
          onAccept={changeAccountStatus}
          onCancel={toggleConfirm}
          loading={actionPending}
        >
          {message[action]()}
        </Confirm>
      )}

      {accounts.inviteUserError &&
        (
          <Confirm
            className="common-invite-error"
            headline="Invitation Error"
            cancelButtonText="Cancel"
            acceptButtonText="Ok"
            onAccept={dismissInviteUserError}
            onCancel={dismissInviteUserError}
          >
            <div>
              <p>{`Unable to add ${accounts.inviteUserError.email} to this account.`}</p>
              <p>
                Please contact
                {' '}
                <a href={`mailto:${BRIDGIT_SUPPORT_EMAIL}`}>
                  {BRIDGIT_SUPPORT_EMAIL}
                </a>
              </p>
            </div>
          </Confirm>
        )}
    </div>
  );
}

AccountSettings.propTypes = {
  accounts: PropTypes.object.isRequired,
  accountId: PropTypes.number.isRequired,
  deactivateAccount: PropTypes.func.isRequired,
  reactivateAccount: PropTypes.func.isRequired,
  dismissInviteUserError: PropTypes.func.isRequired,
  activeSideTab: PropTypes.string.isRequired,
};

/* istanbul ignore next */
function mapStateToProps({ accounts, common }) {
  const { accountId } = common;

  return {
    accounts,
    accountId,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    deactivateAccount: bindActionCreators(deactivateAccount, dispatch),
    reactivateAccount: bindActionCreators(reactivateAccount, dispatch),
    dismissInviteUserError: bindActionCreators(dismissInviteUserError, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountSettings);
