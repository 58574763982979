import {
  ALLOCATIONS_SET_RADIUS,
} from './constants';

export function setRadius(radius) {
  return {
    type: ALLOCATIONS_SET_RADIUS,
    radius,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case ALLOCATIONS_SET_RADIUS: {
      const { radius } = action;

      return {
        ...state,
        radius,
      };
    }

    default:
      return state;
  }
}
