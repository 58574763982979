import {
  SERVICE_ACCOUNTS_SELECT_SERVICE_ACCOUNT,
} from './constants';

export function selectServiceAccount(selectedAccountId, analyticsPayload) {
  return {
    type: SERVICE_ACCOUNTS_SELECT_SERVICE_ACCOUNT,
    selectedAccountId,
    analyticsPayload,
  };
}

export function reducer(state, action) {
  const { selectedAccountId } = action;
  switch (action.type) {
    case SERVICE_ACCOUNTS_SELECT_SERVICE_ACCOUNT:
      return {
        ...state,
        account: state.accounts.find(({ id }) => id === selectedAccountId),
      };

    default:
      return state;
  }
}
