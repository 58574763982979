import {
  FILTERS_SET_FILTER_ANCHOR_EL_ID,
} from './constants';

export function setFilterAnchorElId(filterAnchorElId) {
  return {
    type: FILTERS_SET_FILTER_ANCHOR_EL_ID,
    filterAnchorElId,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FILTERS_SET_FILTER_ANCHOR_EL_ID: {
      const { filterAnchorElId } = action;
      const { filterMenu } = state;
      return {
        ...state,
        filterMenu: {
          ...filterMenu,
          filterAnchorElId,
        },
      };
    }

    default:
      return state;
  }
}
