import { call, put, takeLatest } from 'redux-saga/effects';
import {
  SERVICE_ACCOUNTS_DELETE_SERVICE_ACCOUNT_BEGIN,
  SERVICE_ACCOUNTS_DELETE_SERVICE_ACCOUNT_SUCCESS,
  SERVICE_ACCOUNTS_DELETE_SERVICE_ACCOUNT_FAILURE,
  SERVICE_ACCOUNTS_DELETE_SERVICE_ACCOUNT_DISMISS_ERROR,
} from './constants';
import { protectedJsonDelete } from '../../../utils/api';

export function deleteServiceAccount(accountId, serviceAccountId, analyticsPayload) {
  return {
    type: SERVICE_ACCOUNTS_DELETE_SERVICE_ACCOUNT_BEGIN,
    accountId,
    serviceAccountId,
    analyticsPayload,
  };
}

export function dismissDeleteServiceAccountError() {
  return {
    type: SERVICE_ACCOUNTS_DELETE_SERVICE_ACCOUNT_DISMISS_ERROR,
  };
}

export function* doDeleteServiceAccount(action) {
  const { accountId, serviceAccountId, analyticsPayload } = action;
  try {
    yield call(
      protectedJsonDelete,
      `accounts/${accountId}/service-accounts/${serviceAccountId}`,
    );
  } catch (err) {
    yield put({
      type: SERVICE_ACCOUNTS_DELETE_SERVICE_ACCOUNT_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: SERVICE_ACCOUNTS_DELETE_SERVICE_ACCOUNT_SUCCESS,
    serviceAccountId,
    analyticsPayload,
  });
}

export function* watchDeleteServiceAccount() {
  yield takeLatest(SERVICE_ACCOUNTS_DELETE_SERVICE_ACCOUNT_BEGIN, doDeleteServiceAccount);
}

export function reducer(state, action) {
  switch (action.type) {
    case SERVICE_ACCOUNTS_DELETE_SERVICE_ACCOUNT_BEGIN:
      return {
        ...state,
        deleteServiceAccountPending: true,
        deleteServiceAccountError: null,
      };

    case SERVICE_ACCOUNTS_DELETE_SERVICE_ACCOUNT_SUCCESS: {
      const { serviceAccountId } = action;

      return {
        ...state,
        accounts: state.accounts.filter(({ id }) => id !== serviceAccountId),
        account: null,
        deleteServiceAccountPending: false,
        deleteServiceAccountError: null,
      };
    }
    case SERVICE_ACCOUNTS_DELETE_SERVICE_ACCOUNT_FAILURE:
      return {
        ...state,
        deleteServiceAccountPending: false,
        deleteServiceAccountError: action.data.error,
      };

    case SERVICE_ACCOUNTS_DELETE_SERVICE_ACCOUNT_DISMISS_ERROR:
      return {
        ...state,
        deleteServiceAccountError: null,
      };

    default:
      return state;
  }
}
