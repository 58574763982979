import { call, put, takeLatest } from 'redux-saga/effects';
import {
  SETTINGS_REMOVE_ADMIN_INVITATION_BEGIN,
  SETTINGS_REMOVE_ADMIN_INVITATION_SUCCESS,
  SETTINGS_REMOVE_ADMIN_INVITATION_FAILURE,
  SETTINGS_REMOVE_ADMIN_INVITATION_DISMISS_ERROR,
} from './constants';
import { protectedJsonDelete } from '../../../utils/api';

export function removeAdminInvitation(invitationId) {
  // If need to pass args to saga, pass it with the begin action.
  return {
    type: SETTINGS_REMOVE_ADMIN_INVITATION_BEGIN,
    invitationId,
  };
}

export function dismissRemoveAdminInvitationError() {
  return {
    type: SETTINGS_REMOVE_ADMIN_INVITATION_DISMISS_ERROR,
  };
}

// worker Saga: will be fired on SETTINGS_REMOVE_ADMIN_INVITATION_BEGIN actions
export function* doRemoveAdminInvitation(action) {
  const { invitationId } = action;
  try {
    yield call(protectedJsonDelete, `invitations/${invitationId}`);
  } catch (err) {
    yield put({
      type: SETTINGS_REMOVE_ADMIN_INVITATION_FAILURE,
      data: { error: err },
    });
    return;
  }
  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: SETTINGS_REMOVE_ADMIN_INVITATION_SUCCESS,
    invitationId,
  });
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchRemoveAdminInvitation() {
  yield takeLatest(SETTINGS_REMOVE_ADMIN_INVITATION_BEGIN, doRemoveAdminInvitation);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case SETTINGS_REMOVE_ADMIN_INVITATION_BEGIN:
      return {
        ...state,
        removeAdminInvitationPending: true,
        removeAdminInvitationError: null,
      };

    case SETTINGS_REMOVE_ADMIN_INVITATION_SUCCESS: {
      const updatedInvitations = state.invitations.filter(i => i.id !== action.invitationId);
      return {
        ...state,
        invitations: updatedInvitations,
        removeAdminInvitationPending: false,
        removeAdminInvitationError: null,
      };
    }

    case SETTINGS_REMOVE_ADMIN_INVITATION_FAILURE:
      return {
        ...state,
        removeAdminInvitationPending: false,
        removeAdminInvitationError: action.data.error,
      };

    case SETTINGS_REMOVE_ADMIN_INVITATION_DISMISS_ERROR:
      return {
        ...state,
        removeAdminInvitationError: null,
      };

    default:
      return state;
  }
}
