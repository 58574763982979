import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PEOPLE_REMOVE_PERSON_BEGIN,
  PEOPLE_REMOVE_PERSON_SUCCESS,
  PEOPLE_REMOVE_PERSON_FAILURE,
  PEOPLE_REMOVE_PERSON_DISMISS_ERROR,
} from './constants';
import { protectedJsonDelete } from '../../../utils/api';

export function removePerson(accountId, personId, personName, parentName) {
  return {
    type: PEOPLE_REMOVE_PERSON_BEGIN,
    accountId,
    personId,
    personName,
    parentName,
  };
}

export function dismissRemovePersonError() {
  return {
    type: PEOPLE_REMOVE_PERSON_DISMISS_ERROR,
  };
}

export function* doRemovePerson(action) {
  const { accountId, personId, personName, parentName } = action;
  let res;
  try {
    res = yield call(protectedJsonDelete, `accounts/${accountId}/persons/${personId}`);
  } catch (err) {
    yield put({
      type: PEOPLE_REMOVE_PERSON_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PEOPLE_REMOVE_PERSON_SUCCESS,
    data: res,
    personId,
    personName,
    parentName,
  });
}

export function* watchRemovePerson() {
  yield takeLatest(PEOPLE_REMOVE_PERSON_BEGIN, doRemovePerson);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_REMOVE_PERSON_BEGIN:
      return {
        ...state,
        removePersonPending: true,
        removePersonError: null,
      };

    case PEOPLE_REMOVE_PERSON_SUCCESS: {
      const { personId } = action;
      return {
        ...state,
        entities: state.entities.filter(person => person.id !== personId),
        allocations: state.allocations.filter(a => a.personId !== personId),
        removePersonPending: false,
        removePersonError: null,
        filteredPeople: state.filteredPeople.filter(person => person.id !== personId),
      };
    }
    case PEOPLE_REMOVE_PERSON_FAILURE:
      return {
        ...state,
        removePersonPending: false,
        removePersonError: action.data.error,
      };

    case PEOPLE_REMOVE_PERSON_DISMISS_ERROR:
      return {
        ...state,
        removePersonError: null,
      };

    default:
      return state;
  }
}
