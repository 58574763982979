import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Field } from 'react-final-form';

import { OTHER } from './constants';
import { isRequired } from '../../utils/validators';
import { FormField } from '../wrapped-components';

const ProfileTeamInput = ({ disabled, values, fieldClassName }) => {
  const teamOptions = useSelector(({ login: { defaultTeams } }) => (
    [...defaultTeams, OTHER].map(team => ({ label: team, value: team }))
  ));

  const renderTeamSelect = useCallback(({ input, meta, disabled }) => (
    <FormField
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...input}
      type="select"
      width="full"
      label="Team"
      error={meta.error}
      alwaysShowErrors
      placeholder="Select a team"
      options={teamOptions}
      disabled={disabled}
      className={fieldClassName}
      isNoneOptionHidden
    />
  ), [teamOptions, fieldClassName]);

  const renderTeamNameInput = useCallback(({ input, meta, disabled }) => (
    <FormField
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...input}
      type="text"
      width="full"
      label="Team Name"
      error={meta.error}
      alwaysShowErrors
      placeholder="Enter team name"
      disabled={disabled}
      maxLength={50}
      className={fieldClassName}
    />
  ), [fieldClassName]);

  return (
    <>
      <Field
        name="team"
        render={renderTeamSelect}
        defaultValue={values.team}
        validate={isRequired}
        disabled={disabled}
      />

      {values.team === OTHER && (
        <Field
          name="teamName"
          render={renderTeamNameInput}
          defaultValue={values.teamName}
          validate={isRequired}
          disabled={disabled}
        />
      )}
    </>
  );
};

ProfileTeamInput.propTypes = {
  disabled: PropTypes.bool,
  values: PropTypes.shape({
    team: PropTypes.string,
    teamName: PropTypes.string,
  }).isRequired,
  fieldClassName: PropTypes.string,
};

ProfileTeamInput.defaultProps = {
  disabled: false,
  fieldClassName: '',
};

export default ProfileTeamInput;
