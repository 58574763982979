import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, CircularProgress } from '@material-ui/core';

export default function ConfirmOverlay({
  className,
  message,
  subtext,
  pending,
  onCancel,
  onAccept,
  actionButtons,
  displayVertical,
  displayCompact,
}) {
  return (
    <div className={classNames('common-confirm-overlay', className, { vertical: displayVertical }, { compact: displayCompact })}>
      <div className="confirm-message">
        {pending
          ? <CircularProgress className="spinner" size={35} />
          : (
            <>
              <div>{message}</div>
              <div className="confirm-sub-message">{subtext}</div>
              <div className="action-buttons">
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  onClick={onCancel}
                >
                  Cancel
                </Button>

                { !actionButtons
                  ? (
                    <Button
                      className="button__default compact"
                      variant="contained"
                      size="medium"
                      onClick={onAccept}
                    >
                      OK
                    </Button>
                  )
                  : (actionButtons)}
              </div>
            </>
          )}
      </div>
    </div>
  );
}

ConfirmOverlay.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string.isRequired,
  subtext: PropTypes.string,
  pending: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onAccept: PropTypes.func,
  actionButtons: PropTypes.node,
  displayVertical: PropTypes.bool,
  displayCompact: PropTypes.bool,
};

ConfirmOverlay.defaultProps = {
  className: '',
  subtext: '',
  pending: false,
  onAccept: () => {},
  actionButtons: null,
  displayVertical: false,
  displayCompact: false,
};
