import {
  DEACTIVATED_PERSON_UPDATED,
  PERSON_UPDATED,
} from './constants';

export const getUpdatePersonEvent = (state, { analyticsPayload }) => (analyticsPayload.isActive ? PERSON_UPDATED : DEACTIVATED_PERSON_UPDATED);

export const getUpdatePersonPayload = (state, { analyticsPayload }) => {
  const { personName, personId, updatedFrom, field } = analyticsPayload;

  return {
    'Field Updated': field,
    'Person name': personName,
    'Person id': personId,
    'Updated from': updatedFrom,
  };
};
