import {
  COMMON_SET_BACK_BUTTON_STATE,
} from './constants';

export function setBackButtonState(backButtonState) {
  return {
    type: COMMON_SET_BACK_BUTTON_STATE,
    backButtonState,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_SET_BACK_BUTTON_STATE: {
      const { backButtonState } = action;
      const { multiStateModal } = state;
      return {
        ...state,
        multiStateModal: {
          ...multiStateModal,
          showBackButton: backButtonState,
        },
      };
    }

    default:
      return state;
  }
}
