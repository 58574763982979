const PROJECT_GANTT_SETTINGS_PANEL_SELECTED = 'Project Gantt - Gantt settings panel selected';
const PROJECT_GANTT_TIME_SEGMENT_CHANGED = 'Project Gantt - Time Segment Changed';
const PROJECT_DETAILS_MODAL_DISPLAYED = 'Project Details Modal Displayed';
const VIEW_SELECTED = 'Project Gantt - View Selected';
const ROLE_SORT_APPLIED = 'Project Gantt - Role sort applied';
const FILTER_FILLED_UNFILLED_ROLES = 'Project Gantt - Filter by unfilled/filled roles';
const FILER_BY_ROLE_NAME = 'Project Gantt - Filter by role name';

const PEOPLE_TIME_SEGMENT_CHANGED = 'People Gantt - Time Segment Changed';
const PEOPLE_GANTT_SETTINGS = 'People Gantt - Gantt options';
const PEOPLE_PROJECT_BAR_COLOR = 'People Gantt - Project bar color';
const PEOPLE_GANTT_DISPLAY = 'People Gantt - Display';
const PEOPLE_GANTT_SETTINGS_PANEL_SELECTED = 'People Gantt - Gantt settings panel selected';

const PEOPLE_GANTT_SHARE_DATA_LOADED = 'People Gantt - Shared link selected';
const PEOPLE_GANTT_SHARE_LINK_GENERATED = 'People Gantt - Share link generated';

export {
  PROJECT_GANTT_SETTINGS_PANEL_SELECTED,
  PROJECT_GANTT_TIME_SEGMENT_CHANGED,
  PEOPLE_TIME_SEGMENT_CHANGED,
  PEOPLE_GANTT_SETTINGS,
  PEOPLE_PROJECT_BAR_COLOR,
  PEOPLE_GANTT_DISPLAY,
  PROJECT_DETAILS_MODAL_DISPLAYED,
  PEOPLE_GANTT_SETTINGS_PANEL_SELECTED,
  VIEW_SELECTED,
  ROLE_SORT_APPLIED,
  FILTER_FILLED_UNFILLED_ROLES,
  PEOPLE_GANTT_SHARE_DATA_LOADED,
  PEOPLE_GANTT_SHARE_LINK_GENERATED,
  FILER_BY_ROLE_NAME,
};
