import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  TextField,
  FormControl,
  ClickAwayListener,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
  Button,
  Typography,
} from '@material-ui/core';
import { momentToString } from 'src/utils/dateUtils';
import {
  TABLOID_PAPER,
  LETTER_PAPER,
  REPORT_TITLE_MAX_LENGTH,
  PROJECT_GANTT_PDF_REPORT,
  PEOPLE_GANTT_PDF_REPORT,
  LANDSCAPE,
  PORTRAIT,
  LANDSCAPE_TABLOID_PAPER_WIDTH,
  LANDSCAPE_LETTER_PAPER_WIDTH,
  PORTRAIT_TABLOID_PAPER_WIDTH,
  PORTRAIT_LETTER_PAPER_WIDTH,
  STICKY_COLUMN_WIDTH,
  REPORT_DEFAULT_PROJECT_TITLE,
  REPORT_DEFAULT_PEOPLE_TITLE,
} from './redux/constants';
import { collectProjectData, collectPeopleData } from './redux/actions';
import {
  SIMPLE_PROJECT_GANTT,
  MIN_API_CONFIGURABLE_DATE,
  MIN_API_CONFIGURABLE_DATE_MESSAGE,
  PROJECT_VIEW,
  DATE_INPUT_FORMAT,
} from '../../common/constants';
import { DatePicker } from '../common';
import { timelineIntervals } from '../gantt/utils/ganttUtils';
import { MONTH_PICKER_VIEWS } from '../common/redux/constants';
import { PROJECT_GANTT_CONFIG_KEY } from '../gantt/redux/constants';

const GanttFileExportMenu = ({
  menuTitle,
  handleClose,
  collectProjectData,
  collectPeopleData,
  accountId,
  queries,
  disableToggle,
  isProjectView,
  preloadPhotos,
  roleSortOption,
  roleFilters,
  permissions,
}) => {
  const today = new Date();
  const formattedToday = moment(today).startOf('month').format(DATE_INPUT_FORMAT);

  const [title, setTitle] = useState(isProjectView ? REPORT_DEFAULT_PROJECT_TITLE : REPORT_DEFAULT_PEOPLE_TITLE);
  const [earliestDate, setEarliestDate] = useState(formattedToday);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isTimelineLengthOpen, setIsTimelineLengthOpen] = useState(false);
  const [isPageOrientationOpen, setIsPageOrientationOpen] = useState(false);
  const [timelineLength, setTimelineLength] = useState(2);
  const [pageOrientation, setPageOrientation] = useState(LANDSCAPE);
  const [paperSize, setPaperSize] = useState(TABLOID_PAPER);
  const [showAllocationPercentages, setShowAllocationPercentages] = useState(false);
  const [showProfilePictures, setShowProfilePictures] = useState(false);

  const handleDatePickerOpen = () => {
    setIsDatePickerOpen(true);
  };

  const handleDatePickerClose = () => {
    setIsDatePickerOpen(false);
  };

  const handleTimelineLengthOpen = () => {
    setIsTimelineLengthOpen(true);
  };

  const handleTimelineLengthClose = () => {
    setIsTimelineLengthOpen(false);
  };

  const handlePageOrientationOpen = () => {
    setIsPageOrientationOpen(true);
  };

  const handlePageOrientationClose = () => {
    setIsPageOrientationOpen(false);
  };

  const handleClickAway = () => {
    if (isDatePickerOpen || isTimelineLengthOpen || isPageOrientationOpen) return;
    handleClose();
  };

  const handleReportTitleChange = (event) => {
    const { target: { value } } = event;
    setTitle(value);
  };

  const handleDateChange = (date) => {
    setEarliestDate(momentToString(date));
  };

  const handleTimelineLengthChange = (event) => {
    const { target: { value } } = event;
    setTimelineLength(value);
  };

  const handlePageOrientationChange = (event) => {
    const { target: { value } } = event;
    setPageOrientation(value);
  };

  const handlePaperSizeChange = (event) => {
    const { target: { value } } = event;
    setPaperSize(value);
  };

  const handleShowAllocationPercentagesChange = (event) => {
    setShowAllocationPercentages(event.target.checked);
  };

  const handleShowProfilePicturesChange = (event) => {
    setShowProfilePictures(event.target.checked);
  };

  const calculateReportDimensions = () => {
    let paperWidth;
    if (pageOrientation === LANDSCAPE) {
      if (paperSize === TABLOID_PAPER) {
        paperWidth = LANDSCAPE_TABLOID_PAPER_WIDTH;
      } else {
        paperWidth = LANDSCAPE_LETTER_PAPER_WIDTH;
      }
    } else if (paperSize === TABLOID_PAPER) {
      paperWidth = PORTRAIT_TABLOID_PAPER_WIDTH;
    } else {
      paperWidth = PORTRAIT_LETTER_PAPER_WIDTH;
    }

    const { columns } = timelineIntervals[timelineLength];

    const columnCount = paperSize === TABLOID_PAPER ? columns.tabloid : columns.letter;
    const columnWidth = (paperWidth - STICKY_COLUMN_WIDTH) / columnCount;

    return {
      columnWidth,
      columnCount,
      paperWidth,
    };
  };

  const printReport = () => {
    const { columnWidth, columnCount, paperWidth } = calculateReportDimensions();
    const type = isProjectView ? PROJECT_GANTT_PDF_REPORT : PEOPLE_GANTT_PDF_REPORT;

    const settings = {
      title,
      earliestDate,
      timelineLength,
      pageOrientation,
      paperSize,
      type,
      columnWidth,
      columnCount,
      paperWidth,
    };
    handleClose();
    if (isProjectView) {
      settings.showAllocationPercentages = showAllocationPercentages;
      settings.showProfilePictures = showProfilePictures;
      settings.roleSortOption = roleSortOption;
      settings.roleFilters = roleFilters;
      settings.permissions = permissions;
      collectProjectData(accountId, queries, settings);
    } else {
      settings.preloadPhotos = preloadPhotos;
      settings.includeGanttAllocations = true;
      collectPeopleData(accountId, queries, settings);
    }
  };

  const renderReportTitleInput = () => (
    <FormControl component="fieldset">
      <TextField
        label="Title"
        value={title}
        onChange={handleReportTitleChange}
        margin="normal"
        inputProps={{ maxLength: REPORT_TITLE_MAX_LENGTH }}
      />
    </FormControl>
  );

  const renderDatePicker = () => (
    <FormControl component="fieldset">
      <span className="date-label">Earliest month displayed</span>
      <div className="date-wrapper">
        <DatePicker
          onClose={handleDatePickerClose}
          onOpen={handleDatePickerOpen}
          onChange={handleDateChange}
          date={earliestDate}
          className="date-picker"
          minDate={moment(MIN_API_CONFIGURABLE_DATE)}
          minDateMessage={MIN_API_CONFIGURABLE_DATE_MESSAGE}
          views={MONTH_PICKER_VIEWS}
          keyboard={false}
          format="MMM YYYY"
          variant="inline"
          openTo="month"
        />
      </div>
    </FormControl>
  );

  const renderTimelineLengthSelect = () => (
    <FormControl component="fieldset">
      <InputLabel htmlFor="gantt-file-export_timeline-length">Length of timeline</InputLabel>
      <Select
        value={timelineLength}
        onChange={handleTimelineLengthChange}
        onOpen={handleTimelineLengthOpen}
        onClose={handleTimelineLengthClose}
        open={isTimelineLengthOpen}
        inputProps={{
          name: 'timelineLength',
          id: 'gantt-file-export_timeline-length',
        }}
      >
        {
        /*
        Hiding these options for now because the number of months actually displayed
        does not match what's expected due to the fact that we are assuming in our
        implementation that all months are equal. They might be enabled in the future.
        The rest of the code handling these options is still in place.
        <MenuItem value={0}>3 Months</MenuItem>
        <MenuItem value={1}>6 Months</MenuItem>
        */
        }
        <MenuItem value={2}>1 Year</MenuItem>
        <MenuItem value={3}>2 Years</MenuItem>
        <MenuItem value={4}>3 Years</MenuItem>
        <MenuItem value={5}>4 Years</MenuItem>
        <MenuItem value={6}>5 Years</MenuItem>
      </Select>
    </FormControl>
  );

  const renderPageOrientationSelect = () => (
    <FormControl component="fieldset">
      <InputLabel htmlFor="gantt-file-export_page-orientation">Optimize for page orientation</InputLabel>
      <Select
        value={pageOrientation}
        onChange={handlePageOrientationChange}
        onOpen={handlePageOrientationOpen}
        onClose={handlePageOrientationClose}
        open={isPageOrientationOpen}
        inputProps={{
          name: 'pageOrientation',
          id: 'gantt-file-export_page-orientation',
        }}
      >
        <MenuItem value={LANDSCAPE}>Landscape</MenuItem>
        <MenuItem value={PORTRAIT}>Portrait</MenuItem>
      </Select>
    </FormControl>
  );

  const renderPaperSizeOptimizationRadio = () => {
    const interval = timelineIntervals[timelineLength];
    const { columns } = interval;
    const { tabloid, letter } = columns;
    const optionDescriptionLabel = (dimensions, paperSize) => `${dimensions} - Displays ${paperSize} columns wide`;

    const tabloidLabel = (
      <div className="radio-label">
        <div>Optimize for Tabloid size paper</div>
        <Typography color="textSecondary">{optionDescriptionLabel('11”x17”', tabloid)}</Typography>
      </div>
    );
    const letterLabel = (
      <div className="radio-label">
        <div>Optimize for Letter size paper</div>
        <Typography color="textSecondary">{optionDescriptionLabel('8.5”x11”', letter)}</Typography>
      </div>
    );
    return (
      <FormControl component="fieldset">
        <RadioGroup aria-label="Paper size optimization" name="paperSizeOptimizationRadio" value={paperSize} onChange={handlePaperSizeChange}>
          <FormControlLabel
            value={TABLOID_PAPER}
            control={<Radio color="primary" />}
            label={tabloidLabel}
            labelPlacement="end"
          />
          <FormControlLabel
            value={LETTER_PAPER}
            control={<Radio color="primary" />}
            label={letterLabel}
            labelPlacement="end"
          />
        </RadioGroup>
      </FormControl>
    );
  };

  const renderToggleSwitches = () => (
    <FormControl component="fieldset">
      <FormControlLabel
        control={(
          <Switch
            checked={showAllocationPercentages}
            onChange={handleShowAllocationPercentagesChange}
            value={showAllocationPercentages}
            color="primary"
            disabled={disableToggle}
          />
        )}
        label="Include allocation percentages"
      />
      <FormControlLabel
        control={(
          <Switch
            checked={showProfilePictures}
            onChange={handleShowProfilePicturesChange}
            value={showProfilePictures}
            color="primary"
            disabled={disableToggle}
          />
        )}
        label="Include profile pictures"
      />
    </FormControl>
  );

  const renderExportButton = () => (
    <FormControl component="fieldset">
      <Button
        onClick={printReport}
        color="primary"
        disabled={!earliestDate}
        className="print-button"
      >
        Export
      </Button>
    </FormControl>
  );

  return (
    <div className="reports-gantt-file-export-menu">
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className="content-wrap">
          <div role="presentation" onClick={handleClickAway} className="click-listener" />
          <div className="title">{menuTitle}</div>
          <form className="form">
            {renderReportTitleInput()}
            {renderDatePicker()}
            {renderTimelineLengthSelect()}
            {renderPageOrientationSelect()}
            {renderPaperSizeOptimizationRadio()}
            {isProjectView && renderToggleSwitches()}
            {renderExportButton()}
          </form>
        </div>
      </ClickAwayListener>
    </div>
  );
};

GanttFileExportMenu.propTypes = {
  menuTitle: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  collectProjectData: PropTypes.func.isRequired,
  collectPeopleData: PropTypes.func.isRequired,
  accountId: PropTypes.number.isRequired,
  disableToggle: PropTypes.bool,
  preloadPhotos: PropTypes.bool,
  isProjectView: PropTypes.bool.isRequired,
  queries: PropTypes.object,
  roleSortOption: PropTypes.string,
  roleFilters: PropTypes.array,
  permissions: PropTypes.object.isRequired,
};

GanttFileExportMenu.defaultProps = {
  queries: {},
  disableToggle: false,
  preloadPhotos: false,
  roleSortOption: null,
  roleFilters: [],
};

function mapStateToProps({ common, gantt, queries, login }) {
  const { userInfo: { permissions } } = login;
  const { accountId, activeView } = common;
  const { filteredProjects: filteredProjectsQueries, filteredPeople: filteredPeopleQueries } = queries;
  const isProjectView = activeView === PROJECT_VIEW;
  const { roleSortOption, roleFilters, view } = gantt?.instances?.[PROJECT_GANTT_CONFIG_KEY] || {};

  return {
    accountId,
    queries: isProjectView ? filteredProjectsQueries : filteredPeopleQueries,
    disableToggle: view === SIMPLE_PROJECT_GANTT,
    preloadPhotos: !!gantt?.instances?.people?.displayOptions?.photo,
    isProjectView,
    roleSortOption,
    roleFilters,
    permissions,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    collectProjectData: bindActionCreators(collectProjectData, dispatch),
    collectPeopleData: bindActionCreators(collectPeopleData, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GanttFileExportMenu);
