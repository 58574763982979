import {
  FILTERS_SET_FILTER_MENU_VISIBILITY,
} from './constants';

export function setFilterMenuVisibility(open) {
  return {
    type: FILTERS_SET_FILTER_MENU_VISIBILITY,
    open,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FILTERS_SET_FILTER_MENU_VISIBILITY: {
      const { open } = action;
      const { filterMenu } = state;
      return {
        ...state,
        filterMenu: {
          ...filterMenu,
          open,
        },
      };
    }

    default:
      return state;
  }
}
