import React, { useEffect } from 'react';
import pluralize from 'pluralize';
import { useSelector, useDispatch } from 'react-redux';
import { ReportProjectList, CoverPage, ReportTemplate } from '.';
import { PROJECT_TITLE } from './redux/constants';
import { getAccountById } from '../../utils/miscUtils';
import { FILTERED_PROJECTS_QUERY_ID } from '../queries/redux/constants';
import { trackAnalytics } from '../common/redux/actions';
import { REPORTS_EXPORT_PROJECT_LIST } from '../../analytics/reports/constants';

const ProjectPDFReport = () => {
  const dispatch = useDispatch();

  const { settings, projects } = useSelector(({ reports }) => reports);
  const { accountId } = useSelector(({ common }) => common);
  const { entities: accounts } = useSelector(({ accounts }) => accounts);

  useEffect(() => {
    const { includeAllocations } = settings;

    const analyticsPayload = {
      'Report type': 'PDF',
      'Allocations included': includeAllocations,
    };

    dispatch(trackAnalytics(REPORTS_EXPORT_PROJECT_LIST, analyticsPayload));
  }, [dispatch, settings]);

  const projectCount = projects.length;
  const account = getAccountById(accounts, accountId);

  const coverPage = (
    <CoverPage
      title={PROJECT_TITLE}
      subTitle={`${pluralize('Projects', projectCount, true)} included`}
      queryId={FILTERED_PROJECTS_QUERY_ID}
    />
  );

  return (
    <div className="reports-project-pdf-report">
      <ReportTemplate
        coverPage={coverPage}
        report={<ReportProjectList />}
        header={account ? account.name : ''}
        title={PROJECT_TITLE}
      />
    </div>
  );
};

export default ProjectPDFReport;
