import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNT_SETTINGS_ADD_SUB_PHASE_BEGIN,
  ACCOUNT_SETTINGS_ADD_SUB_PHASE_SUCCESS,
  ACCOUNT_SETTINGS_ADD_SUB_PHASE_FAILURE,
  ACCOUNT_SETTINGS_ADD_SUB_PHASE_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';

export function addSubPhase(accountId, phaseId, data) {
  return {
    type: ACCOUNT_SETTINGS_ADD_SUB_PHASE_BEGIN,
    accountId,
    phaseId,
    data,
  };
}

export function dismissAddSubPhaseError() {
  return {
    type: ACCOUNT_SETTINGS_ADD_SUB_PHASE_DISMISS_ERROR,
  };
}

export function* doAddSubPhase(action) {
  const { accountId, phaseId, data } = action;
  let res;
  try {
    res = yield call(protectedJsonPost, `accounts/${accountId}/phases/${phaseId}/subphases`, data);
  } catch (err) {
    yield put({
      type: ACCOUNT_SETTINGS_ADD_SUB_PHASE_FAILURE,
      data: { error: err },
    });
    return;
  }
  yield put({
    type: ACCOUNT_SETTINGS_ADD_SUB_PHASE_SUCCESS,
    phaseId,
    data: res,
  });
}

export function* watchAddSubPhase() {
  yield takeLatest(ACCOUNT_SETTINGS_ADD_SUB_PHASE_BEGIN, doAddSubPhase);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNT_SETTINGS_ADD_SUB_PHASE_BEGIN:
      return {
        ...state,
        addSubPhasePending: true,
        addSubPhaseError: null,
      };

    case ACCOUNT_SETTINGS_ADD_SUB_PHASE_SUCCESS: {
      const newPhases = state.phases.map((phase) => {
        if (action.phaseId === phase.id) {
          return {
            ...phase,
            subPhases: [
              ...phase.subPhases,
              {
                ...action.data,
                parentId: action.phaseId,
              },
            ],
          };
        }
        return phase;
      });

      return {
        ...state,
        phases: newPhases,
        addSubPhasePending: false,
        addSubPhaseError: null,
      };
    }

    case ACCOUNT_SETTINGS_ADD_SUB_PHASE_FAILURE:
      return {
        ...state,
        addSubPhasePending: false,
        addSubPhaseError: action.data.error,
      };

    case ACCOUNT_SETTINGS_ADD_SUB_PHASE_DISMISS_ERROR:
      return {
        ...state,
        addSubPhaseError: null,
      };

    default:
      return state;
  }
}
