import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ALLOCATIONS_GET_FILTERED_ROLE_AVAILABILITIES_BEGIN,
  ALLOCATIONS_GET_FILTERED_ROLE_AVAILABILITIES_SUCCESS,
  ALLOCATIONS_GET_FILTERED_ROLE_AVAILABILITIES_FAILURE,
  ALLOCATIONS_GET_FILTERED_ROLE_AVAILABILITIES_DISMISS_ERROR,
} from './constants';
import { getRoleAvailabilityQuery } from '../queries/getRoleAvailabilitiesQuery';
import graphApiClient from '../../../common/GraphAPIClient';

export function getFilteredRoleAvailabilities(queryParams) {
  const { query, variables } = getRoleAvailabilityQuery(queryParams);

  return {
    type: ALLOCATIONS_GET_FILTERED_ROLE_AVAILABILITIES_BEGIN,
    query,
    variables,
  };
}

export function dismissGetFilteredRoleAvailabilitiesError() {
  return {
    type: ALLOCATIONS_GET_FILTERED_ROLE_AVAILABILITIES_DISMISS_ERROR,
  };
}

export function* doGetFilteredRoleAvailabilities(action) {
  const { query, variables } = action;

  let res;
  try {
    res = yield call(graphApiClient.query, { query, variables });
    const { errors } = res;
    if (errors) {
      throw errors;
    }
  } catch (err) {
    yield put({
      type: ALLOCATIONS_GET_FILTERED_ROLE_AVAILABILITIES_FAILURE,
      data: { error: err },
      isPaging: variables.from !== 0,
    });
    return;
  }

  yield put({
    type: ALLOCATIONS_GET_FILTERED_ROLE_AVAILABILITIES_SUCCESS,
    data: res.data,
    clearData: variables.from === 0,
  });
}

export function* watchGetFilteredRoleAvailabilities() {
  yield takeLatest(ALLOCATIONS_GET_FILTERED_ROLE_AVAILABILITIES_BEGIN, doGetFilteredRoleAvailabilities);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case ALLOCATIONS_GET_FILTERED_ROLE_AVAILABILITIES_BEGIN: {
      const { variables } = action;
      const isPaging = variables.from > 0;

      return {
        ...state,
        getFilteredRoleAvailabilitiesPagePending: isPaging,
        getFilteredRoleAvailabilitiesPending: !isPaging,
        getFilteredRoleAvailabilitiesError: null,
      };
    }
    case ALLOCATIONS_GET_FILTERED_ROLE_AVAILABILITIES_SUCCESS: {
      const { data, clearData } = action;
      const roleAvailabilities = data && 'roleAvailabilities' in data ? data.roleAvailabilities : {};

      const recommendedItems = Array.isArray(roleAvailabilities.items) ? roleAvailabilities.items : [];
      const originalItems = Array.isArray(roleAvailabilities.originalItems) ? roleAvailabilities.originalItems : [];
      const pageFrom = 'cursor' in roleAvailabilities ? roleAvailabilities.cursor : 0;
      const hasMore = !!roleAvailabilities.hasMore;

      return {
        ...state,
        roleAvailabilities: clearData ? recommendedItems : [...state.roleAvailabilities, ...recommendedItems],
        originalRoleAvailabilities: originalItems,
        pageFrom,
        hasMore,
        getFilteredRoleAvailabilitiesPending: false,
        getFilteredRoleAvailabilitiesPagePending: false,
        getFilteredRoleAvailabilitiesError: null,
        getFilteredRoleAvailabilitiesPageError: null,
      };
    }
    case ALLOCATIONS_GET_FILTERED_ROLE_AVAILABILITIES_FAILURE:
      return {
        ...state,
        getFilteredRoleAvailabilitiesPending: false,
        getFilteredRoleAvailabilitiesPagePending: false,
        getFilteredRoleAvailabilitiesError: action.isPaging ? false : action.data.error,
        getFilteredRoleAvailabilitiesPageError: action.isPaging ? action.data.error : false,
      };

    case ALLOCATIONS_GET_FILTERED_ROLE_AVAILABILITIES_DISMISS_ERROR:
      return {
        ...state,
        getFilteredRoleAvailabilitiesError: null,
        getFilteredRoleAvailabilitiesPageError: null,
      };

    default:
      return state;
  }
}
