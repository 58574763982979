import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { MessageOutlined, Restore } from '@material-ui/icons';
import { ModalSidePanel } from 'src/features/common';
import { ProjectNotesContainer, ProjectAudit } from '.';
import { isAuthorized, hasModuleEnabled } from '../permissions/utils/permissionUtils';
import { PERM_READ, PERM_ROLE } from '../permissions/utils/constants';
import { ACCOUNT_MODULE_STANDARD } from '../../common/constants';
import { MODAL_NOTES_TAB, MODAL_PROJECT_AUDIT_TAB } from './constants';
import { trackAnalytics } from '../common/redux/actions';
import {
  PROJECT_VIEW_PROJECT_NOTES,
  PROJECT_VIEW_PROJECT_ACTIVITY,
} from '../../analytics/projects/constants';

const ProjectModalSidePanel = ({
  selectedProject,
}) => {
  const dispatch = useDispatch();

  const { accountId } = useSelector(({ common }) => common);
  const { accountModules } = useSelector(({ accountSettings }) => accountSettings);
  const { permissions } = useSelector(({ login: { userInfo } }) => userInfo);

  const [activePanel, setActivePanel] = useState(null);

  const options = useMemo(() => {
    const hasRolePerms = isAuthorized(accountId, permissions, PERM_READ, PERM_ROLE);
    const hasStandardModule = hasModuleEnabled(accountModules, ACCOUNT_MODULE_STANDARD);

    return [
      {
        value: MODAL_NOTES_TAB,
        display: 'Notes',
        eventName: PROJECT_VIEW_PROJECT_NOTES,
        icon: <MessageOutlined color="primary" />,
      },
      ...(hasRolePerms && hasStandardModule ? [{
        value: MODAL_PROJECT_AUDIT_TAB,
        display: 'Project Activity',
        eventName: PROJECT_VIEW_PROJECT_ACTIVITY,
        icon: <Restore color="primary" />,
      }] : []),
    ];
  }, [accountId, accountModules, permissions]);

  useEffect(() => {
    if (options.length) {
      setActivePanel(options[0]);
    }
  }, [options]);

  const onChange = useCallback((evt) => {
    const { value } = evt.target;
    const { eventName } = value;
    const { id, name, type, state } = selectedProject;

    const analyticsPayload = {
      'Project name': name,
      'Project ID': id,
      'Project type': type,
      'Project status': state,
      'Viewed from': 'Project details modal',
    };

    dispatch(trackAnalytics(eventName, analyticsPayload));

    setActivePanel(value);
  }, [selectedProject, dispatch]);

  const renderContent = () => {
    const { value } = activePanel;
    const { id, name, type, state, notes } = selectedProject;

    switch (value) {
      case MODAL_NOTES_TAB:
        return (
          <ProjectNotesContainer
            projectId={id}
            projectName={name}
            projectType={type}
            projectStatus={state}
            projectNotes={notes}
          />
        );
      case MODAL_PROJECT_AUDIT_TAB: return <ProjectAudit />;
      default: return null;
    }
  };

  if (!selectedProject || !options.length || !activePanel) return null;

  return (
    <ModalSidePanel
      className="projects-project-modal-side-panel"
      options={options}
      activePanel={activePanel}
      onChange={onChange}
    >
      {renderContent()}
    </ModalSidePanel>
  );
};

ProjectModalSidePanel.propTypes = {
  selectedProject: PropTypes.object,
};

ProjectModalSidePanel.defaultProps = {
  selectedProject: null,
};

export default ProjectModalSidePanel;
