export const SERVICE_ACCOUNTS_GET_SERVICE_ACCOUNTS_BEGIN = 'SERVICE_ACCOUNTS_GET_SERVICE_ACCOUNTS_BEGIN';
export const SERVICE_ACCOUNTS_GET_SERVICE_ACCOUNTS_SUCCESS = 'SERVICE_ACCOUNTS_GET_SERVICE_ACCOUNTS_SUCCESS';
export const SERVICE_ACCOUNTS_GET_SERVICE_ACCOUNTS_FAILURE = 'SERVICE_ACCOUNTS_GET_SERVICE_ACCOUNTS_FAILURE';
export const SERVICE_ACCOUNTS_GET_SERVICE_ACCOUNTS_DISMISS_ERROR = 'SERVICE_ACCOUNTS_GET_SERVICE_ACCOUNTS_DISMISS_ERROR';
export const SERVICE_ACCOUNTS_ADD_SERVICE_ACCOUNT_BEGIN = 'SERVICE_ACCOUNTS_ADD_SERVICE_ACCOUNT_BEGIN';
export const SERVICE_ACCOUNTS_ADD_SERVICE_ACCOUNT_SUCCESS = 'SERVICE_ACCOUNTS_ADD_SERVICE_ACCOUNT_SUCCESS';
export const SERVICE_ACCOUNTS_ADD_SERVICE_ACCOUNT_FAILURE = 'SERVICE_ACCOUNTS_ADD_SERVICE_ACCOUNT_FAILURE';
export const SERVICE_ACCOUNTS_ADD_SERVICE_ACCOUNT_DISMISS_ERROR = 'SERVICE_ACCOUNTS_ADD_SERVICE_ACCOUNT_DISMISS_ERROR';
export const SERVICE_ACCOUNTS_RESET_SERVICE_ACCOUNT = 'SERVICE_ACCOUNTS_RESET_SERVICE_ACCOUNT';
export const SERVICE_ACCOUNTS_RESET_SERVICE_ACCOUNT_PASSWORD_BEGIN = 'SERVICE_ACCOUNTS_RESET_SERVICE_ACCOUNT_PASSWORD_BEGIN';
export const SERVICE_ACCOUNTS_RESET_SERVICE_ACCOUNT_PASSWORD_SUCCESS = 'SERVICE_ACCOUNTS_RESET_SERVICE_ACCOUNT_PASSWORD_SUCCESS';
export const SERVICE_ACCOUNTS_RESET_SERVICE_ACCOUNT_PASSWORD_FAILURE = 'SERVICE_ACCOUNTS_RESET_SERVICE_ACCOUNT_PASSWORD_FAILURE';
export const SERVICE_ACCOUNTS_RESET_SERVICE_ACCOUNT_PASSWORD_DISMISS_ERROR = 'SERVICE_ACCOUNTS_RESET_SERVICE_ACCOUNT_PASSWORD_DISMISS_ERROR';
export const SERVICE_ACCOUNTS_SELECT_SERVICE_ACCOUNT = 'SERVICE_ACCOUNTS_SELECT_SERVICE_ACCOUNT';
export const SERVICE_ACCOUNTS_DELETE_SERVICE_ACCOUNT_BEGIN = 'SERVICE_ACCOUNTS_DELETE_SERVICE_ACCOUNT_BEGIN';
export const SERVICE_ACCOUNTS_DELETE_SERVICE_ACCOUNT_SUCCESS = 'SERVICE_ACCOUNTS_DELETE_SERVICE_ACCOUNT_SUCCESS';
export const SERVICE_ACCOUNTS_DELETE_SERVICE_ACCOUNT_FAILURE = 'SERVICE_ACCOUNTS_DELETE_SERVICE_ACCOUNT_FAILURE';
export const SERVICE_ACCOUNTS_DELETE_SERVICE_ACCOUNT_DISMISS_ERROR = 'SERVICE_ACCOUNTS_DELETE_SERVICE_ACCOUNT_DISMISS_ERROR';
export const SERVICE_ACCOUNTS_COPY_SERVICE_ACCOUNT_ID = 'SERVICE_ACCOUNTS_COPY_SERVICE_ACCOUNT_ID';
export const SERVICE_ACCOUNTS_COPY_SERVICE_ACCOUNT_PASSWORD = 'SERVICE_ACCOUNTS_COPY_SERVICE_ACCOUNT_PASSWORD';
