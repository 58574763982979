import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener, Paper } from '@material-ui/core';
import { CustomPopper, EditControls } from 'src/features/wrapped-components';
import { RoleNoteField } from '.';

export const RoleNotePopper = ({
  targetRef,
  onClickAway,
  initialNote,
  onUpdate,
  onCancel,
  onSave,
  pending,
}) => {
  const [note, setNote] = useState(initialNote);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    let validated = true;

    if (initialNote === note || (!initialNote && !note)) {
      validated = false;
    }

    setIsValid(validated);
  }, [isValid, initialNote, note]);

  const onNoteChange = (evt) => {
    setNote(evt.target.value);
  };

  const clearNote = () => {
    setNote('');
  };

  const handleSave = async () => {
    await onUpdate(note);
    onSave();
  };

  const checkEnter = (evt) => {
    if (evt.keyCode === 13 && isValid) {
      handleSave();
    }
  };

  const handleClickAway = () => {
    if (initialNote === note) {
      onClickAway();
    }
  };

  return (
    <CustomPopper
      popperOpen
      anchorEl={targetRef}
      classes="projects-role-note-popper"
      placement="bottom-start"
      hideArrow
    >
      <ClickAwayListener onClickAway={handleClickAway}>
        <Paper className="content-wrapper">
          <p>Note:</p>
          <div className="note-container">
            <RoleNoteField
              onNoteChange={onNoteChange}
              note={note}
              clearNote={clearNote}
              fullWidth
              autoFocus
              onKeyUp={checkEnter}
            />
          </div>
          <EditControls
            primaryAction={handleSave}
            secondaryAction={onCancel}
            disabled={!isValid}
            primaryText="Save"
            pending={pending}
          />
        </Paper>
      </ClickAwayListener>
    </CustomPopper>
  );
};

RoleNotePopper.propTypes = {
  targetRef: PropTypes.object.isRequired,
  onClickAway: PropTypes.func.isRequired,
  initialNote: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  pending: PropTypes.bool.isRequired,
};

RoleNotePopper.defaultProps = {
  initialNote: '',
};

export default RoleNotePopper;
