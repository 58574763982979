import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  Input,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { Button, NavigateBack } from '@bridgit/foundation';
import { SUB_PHASES_LIMIT } from 'src/common/constants';
import { Confirm } from '../common';
import { EditControls, EditButton } from '../wrapped-components';
import { AddSubPhaseForm } from '.';

export function EditPhase({
  accountId,
  activePhase,
  deletePhase,
  updatePhase,
  updatePhasePending,
  closeEditor,
  phases,
  addSubPhase,
  addSubPhasePending,
  setEditingSubPhase,
  editingSubPhase,
  activeSubPhase,
  deleteSubPhase,
  updateSubPhase,
  updateSubPhasePending,
}) {
  const [confirming, setConfirming] = useState(false);
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState(activePhase.name);
  const [subName, setSubName] = useState(activeSubPhase ? activeSubPhase.name : '');
  const [error, setError] = useState(null);
  const [includeOnNewProjects, setIncludeOnNewProjects] = useState(activePhase.includeOnNewProjects);

  const cancelConfirm = () => setConfirming(false);

  const handleEditClick = () => {
    if (activeSubPhase) setSubName(activeSubPhase.name);
    setEditing(true);
  };

  const handleAddSubPhaseClick = () => setEditingSubPhase(true);

  const closeAdding = () => setEditingSubPhase(false);

  const cancelEdit = () => {
    setEditing(false);
    setName(activePhase.name);
    setIncludeOnNewProjects(activePhase.includeOnNewProjects);
    setError(null);
    if (activeSubPhase) setSubName(activeSubPhase.name);
  };

  const handleDeletePhase = () => {
    if (activeSubPhase) {
      deleteSubPhase(parseInt(activeSubPhase.id, 10));
    } else {
      deletePhase(parseInt(activePhase.id, 10));
    }
    setConfirming(false);
  };

  const handleDeleteClick = () => {
    const projectsWithPhase = activeSubPhase ? activeSubPhase.projects : activePhase.projects;
    if (projectsWithPhase && projectsWithPhase.length) {
      setConfirming(true);
    } else {
      handleDeletePhase();
    }
  };

  const handleSave = () => {
    if (activeSubPhase) {
      const data = { name: subName };
      updateSubPhase(accountId, parseInt(activePhase.id, 10), parseInt(activeSubPhase.id, 10), data);
    } else {
      const data = { name, includeOnNewProjects };
      updatePhase(accountId, parseInt(activePhase.id, 10), data);
    }
    setEditing(false);
  };

  const validate = (name) => {
    let isNotUnique = phases.some((phase) => {
      const phaseMatch = phase.name.toLowerCase() === name;
      const subMatch = phase.subPhases ? phase.subPhases.find(sp => sp.name.toLowerCase() === name) : false;
      return phaseMatch || subMatch;
    });

    if (activeSubPhase) {
      isNotUnique = isNotUnique && name !== activeSubPhase.name.toLowerCase();
    } else {
      isNotUnique = isNotUnique && name !== activePhase.name.toLowerCase();
    }
    const isEmpty = !name.length;
    let error = null;

    if (isEmpty) {
      error = 'Please enter a name';
    } else if (isNotUnique) {
      error = `${activeSubPhase ? 'Sub-phase' : 'Phase'} name must be unique`;
    }
    setError(error);
  };

  const onNameChange = (e) => {
    const newName = e.target.value;
    validate(newName.trim().toLowerCase());
    if (activeSubPhase) {
      setSubName(newName);
    } else {
      setName(newName);
    }
  };

  const toggleIncludeOnNewProjects = () => {
    setIncludeOnNewProjects(!includeOnNewProjects);
  };

  const isDisabled = () => error !== null || !name.length;

  const checkEnter = (e) => {
    const disabled = isDisabled();
    if (e.keyCode === 13 && !disabled) {
      handleSave();
    }
  };

  useEffect(() => {
    if (activeSubPhase) {
      setSubName(activeSubPhase.name);
    } else {
      setName(activePhase.name);
      setIncludeOnNewProjects(activePhase.includeOnNewProjects);
    }
    setEditing(false);
    setError(null);
  }, [activeSubPhase, activePhase]);

  const renderAddSub = () => {
    if (activeSubPhase) return null;
    return activePhase.subPhases && activePhase.subPhases.length < SUB_PHASES_LIMIT
      ? (
        <Button
          color="primary"
          variant="plain"
          onClick={handleAddSubPhaseClick}
          startIcon={<AddCircle />}
        >
          Add sub-phase
        </Button>
      )
      : <p className="limit-warning">Limit for sub-phases reached</p>;
  };

  return (
    <div className="account-settings-edit-phase">
      {editingSubPhase ? (
        <AddSubPhaseForm
          addSubPhase={addSubPhase}
          addSubPhasePending={addSubPhasePending}
          close={closeAdding}
          phase={activePhase}
          setEditingSubPhase={setEditingSubPhase}
          phases={phases}
        />
      ) : (
        <>
          <div className="nav-wrapper">
            <NavigateBack onClick={closeEditor} edge="start" label={`Back to ${activeSubPhase ? name : 'phase list'}`} />
            {!editing && <EditButton tooltipTitle={`Edit ${activeSubPhase ? 'sub-' : ''}phase`} onClick={handleEditClick} />}
          </div>
          <div className="view-edit-phase">
            {editing
              ? (
                <>
                  <Input
                    fullWidth
                    autoFocus
                    className="input--headline"
                    value={activeSubPhase ? subName : name}
                    inputProps={{ maxLength: 100 }}
                    onChange={onNameChange}
                    onKeyUp={checkEnter}
                  />
                  {error && (
                    <FormHelperText className="form__warning">{error}</FormHelperText>
                  )}
                </>
              ) : (
                <div className="top-container">
                  <Typography className="input--headline" variant="h5">{activeSubPhase ? subName : name}</Typography>
                  {renderAddSub()}
                </div>
              )}
            {!activeSubPhase && (
              <div className={classNames({ options: !editing })}>
                <FormControlLabel
                  label="Automatically add phase to new projects"
                  control={(
                    <Checkbox
                      classes={{ root: 'checkbox' }}
                      checked={includeOnNewProjects}
                      onChange={toggleIncludeOnNewProjects}
                      disabled={!editing}
                      color="primary"
                    />
                  )}
                />
              </div>
            )}
          </div>
          <div className="actions-container">
            <Button
              color="warning"
              variant="plain"
              className="delete"
              onClick={handleDeleteClick}
            >
              {`Delete ${activeSubPhase ? 'Sub-' : ''}Phase`}
            </Button>
            {editing && (
              <EditControls
                primaryText={activeSubPhase ? 'Save Sub-Phase' : 'Save Phase'}
                primaryAction={handleSave}
                secondaryAction={cancelEdit}
                disabled={isDisabled()}
                pending={activeSubPhase ? updateSubPhasePending : updatePhasePending}
              />
            )}
          </div>
          {confirming && (
            <Confirm
              headline={activeSubPhase ? 'Delete Sub-Phase' : 'Delete Phase'}
              acceptButtonText={activeSubPhase ? 'Delete Sub-Phase' : 'Delete Phase'}
              cancelButtonText="Cancel"
              onCancel={cancelConfirm}
              onAccept={handleDeletePhase}
            >
              <div>
                <p>{`Are you sure you want to delete the ${activeSubPhase ? `sub-phase "${activeSubPhase.name}"` : `phase "${name}" and any sub-phases associated with it`}?`}</p>
                <p>{`It will be removed from the ${activeSubPhase ? activeSubPhase.projects.length : activePhase.projects.length} projects it was added to.`}</p>
              </div>
            </Confirm>
          )}
        </>
      )}
    </div>
  );
}

EditPhase.propTypes = {
  accountId: PropTypes.number.isRequired,
  activePhase: PropTypes.object.isRequired,
  deletePhase: PropTypes.func.isRequired,
  updatePhase: PropTypes.func.isRequired,
  updatePhasePending: PropTypes.bool.isRequired,
  closeEditor: PropTypes.func.isRequired,
  phases: PropTypes.array.isRequired,
  addSubPhase: PropTypes.func.isRequired,
  addSubPhasePending: PropTypes.bool.isRequired,
  setEditingSubPhase: PropTypes.func.isRequired,
  editingSubPhase: PropTypes.bool.isRequired,
  activeSubPhase: PropTypes.object,
  deleteSubPhase: PropTypes.func.isRequired,
  updateSubPhase: PropTypes.func.isRequired,
  updateSubPhasePending: PropTypes.bool.isRequired,
};

EditPhase.defaultProps = {
  activeSubPhase: null,
};

/* istanbul ignore next */
function mapStateToProps({ common }) {
  return {
    accountId: common.accountId,
  };
}

export default connect(
  mapStateToProps,
)(EditPhase);
