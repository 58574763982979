import { gql } from 'apollo-boost';
import { generateRequestFilter } from 'src/filters/graphqlUtils';
import { PEOPLE_SCHEMAS } from 'src/common/descriptors/person';
import { momentToString } from '../../../utils/dateUtils';

const BENCH_COST_INDIVIDUAL_QUERY = gql`
    query benchCostIndividuals($accountId: Int!, $filters: [ESQuery], $startDate: String!, $endDate: String!, $includePursuitIds: [Int]) {
      benchCostIndividuals: benchCostIndividuals(accountId: $accountId, filters: $filters, startDate: $startDate, endDate: $endDate, includePursuitIds: $includePursuitIds) {
        items {
          personId
          costAvailable
          name
          photoUrl
          title
        }
        count
      }
    }
  `;

const getBenchCostIndividualQuery = (accountId, queries, startDate, endDate, includePursuitIds = []) => {
  const { filter } = queries;
  const filters = generateRequestFilter(filter, PEOPLE_SCHEMAS);

  const variables = {
    accountId,
    filters,
    startDate: momentToString(startDate),
    endDate: momentToString(endDate),
    includePursuitIds,
  };

  return {
    query: BENCH_COST_INDIVIDUAL_QUERY,
    variables,
  };
};

export {
  getBenchCostIndividualQuery,
};
