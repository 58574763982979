import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PROJECTS_REPLACE_PROJECT_ROLE_ALLOCATION_BEGIN,
  PROJECTS_REPLACE_PROJECT_ROLE_ALLOCATION_SUCCESS,
  PROJECTS_REPLACE_PROJECT_ROLE_ALLOCATION_FAILURE,
  PROJECTS_REPLACE_PROJECT_ROLE_ALLOCATION_DISMISS_ERROR,
} from './constants';
import { protectedJsonDelete, protectedJsonPost } from '../../../utils/api';

export function replaceProjectRoleAllocation(accountId, projectId, oldData, newAllocation, analytics) {
  return {
    type: PROJECTS_REPLACE_PROJECT_ROLE_ALLOCATION_BEGIN,
    accountId,
    projectId,
    oldData,
    newAllocation,
    analytics,
  };
}

export function dismissReplaceProjectRoleAllocationError() {
  return {
    type: PROJECTS_REPLACE_PROJECT_ROLE_ALLOCATION_DISMISS_ERROR,
  };
}

export function* doReplaceProjectRoleAllocation(action) {
  const { accountId, projectId, oldData, newAllocation, analytics } = action;
  try {
    yield call(protectedJsonDelete, `accounts/${accountId}/projects/${projectId}/allocations`, oldData);

    yield call(protectedJsonPost, `accounts/${accountId}/projects/${projectId}/allocations`, newAllocation);
  } catch (err) {
    yield put({
      type: PROJECTS_REPLACE_PROJECT_ROLE_ALLOCATION_FAILURE,
      data: { error: err, data: newAllocation },
    });
    return;
  }
  yield put({
    type: PROJECTS_REPLACE_PROJECT_ROLE_ALLOCATION_SUCCESS,
    data: newAllocation,
    oldData,
    projectId,
    analytics,
  });
}

export function* watchReplaceProjectRoleAllocation() {
  yield takeLatest(PROJECTS_REPLACE_PROJECT_ROLE_ALLOCATION_BEGIN, doReplaceProjectRoleAllocation);
}

export function reducer(state, action) {
  switch (action.type) {
    case PROJECTS_REPLACE_PROJECT_ROLE_ALLOCATION_BEGIN:
      return {
        ...state,
        replaceProjectRoleAllocationPending: true,
        replaceProjectRoleAllocationError: null,
      };

    case PROJECTS_REPLACE_PROJECT_ROLE_ALLOCATION_SUCCESS: {
      const { projectId, oldData, data } = action;
      const { projectSelections } = state;

      let existingAllocations = [];

      if (projectId.toString() in state.projectAllocations) {
        const role = state.projectAllocations[projectId].find(a => a.roleId === oldData.roleId);
        if (role) {
          existingAllocations = role.allocations;
        }
      }

      const updatedRoleAllocations = {
        roleId: oldData.roleId,
        allocations: [
          ...existingAllocations.filter(a => !((a.startDate === oldData.startDate) && (a.endDate === oldData.endDate))),
          data,
        ],
      };

      // Update role allocations in project selections.
      const newProjectSelections = { ...projectSelections };
      Object.entries(newProjectSelections).forEach(([selectionId, selectedProject]) => {
        if (selectedProject.id === projectId) {
          newProjectSelections[selectionId] = {
            ...selectedProject,
            roles: selectedProject.roles.map((role) => {
              const newRole = { ...role };
              if (role.id === oldData.roleId) {
                newRole.allocations = updatedRoleAllocations.allocations;

                // If the singular person on a communicated role is replaced with another, reset the communication/notification status
                if (newRole.isCommunicated && newRole.allocations.length === 1) {
                  newRole.isCommunicated = false;
                  newRole.notification = null;
                }
              }
              return newRole;
            }),
          };
        }
      });

      return {
        ...state,
        projectSelections: newProjectSelections,
        projectAllocations: {
          ...state.projectAllocations,
          [projectId]: [
            ...state.projectAllocations[projectId].filter(a => a.roleId !== oldData.roleId),
            updatedRoleAllocations,
          ],
        },
        replaceProjectRoleAllocationPending: false,
        replaceProjectRoleAllocationError: null,
        triggerRefreshAllocationMeta: true,
      };
    }

    case PROJECTS_REPLACE_PROJECT_ROLE_ALLOCATION_FAILURE:
      return {
        ...state,
        replaceProjectRoleAllocationPending: false,
        replaceProjectRoleAllocationError: action.data.error,
      };

    case PROJECTS_REPLACE_PROJECT_ROLE_ALLOCATION_DISMISS_ERROR:
      return {
        ...state,
        replaceProjectRoleAllocationError: null,
      };

    default:
      return state;
  }
}
