import { call, put, takeLatest } from 'redux-saga/effects';
import {
  SELF_PERFORM_GET_REQUEST_BY_ID_BEGIN,
  SELF_PERFORM_GET_REQUEST_BY_ID_SUCCESS,
  SELF_PERFORM_GET_REQUEST_BY_ID_FAILURE,
  SELF_PERFORM_GET_REQUEST_BY_ID_DISMISS_ERROR,
} from './constants';
import { getRequestByIdQuery } from '../queries/getRequestByIdQuery';
import graphApiClient from '../../../common/GraphAPIClient';

export function getRequestById(accountId, selectedRequestId) {
  const { query, variables } = getRequestByIdQuery(accountId, selectedRequestId);

  return {
    type: SELF_PERFORM_GET_REQUEST_BY_ID_BEGIN,
    selectedRequestId,
    query,
    variables,
  };
}

export function dismissGetRequestByIdError() {
  return {
    type: SELF_PERFORM_GET_REQUEST_BY_ID_DISMISS_ERROR,
  };
}

export function* doGetRequestById(action) {
  const { query, variables } = action;
  let selectedRequest;
  let res;
  try {
    res = yield call(graphApiClient.query, {
      query,
      variables,
    });

    const { errors } = res;

    if (errors) {
      throw errors;
    }

    selectedRequest = res?.data?.request || null;
  } catch (err) {
    yield put({
      type: SELF_PERFORM_GET_REQUEST_BY_ID_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: SELF_PERFORM_GET_REQUEST_BY_ID_SUCCESS,
    selectedRequest,
  });
}

export function* watchGetRequestById() {
  yield takeLatest(SELF_PERFORM_GET_REQUEST_BY_ID_BEGIN, doGetRequestById);
}

export function reducer(state, action) {
  switch (action.type) {
    case SELF_PERFORM_GET_REQUEST_BY_ID_BEGIN:
      return {
        ...state,
        getRequestByIdPending: true,
        getRequestByIdError: null,
      };

    case SELF_PERFORM_GET_REQUEST_BY_ID_SUCCESS: {
      const { selectedRequest } = action;
      return {
        ...state,
        selectedRequest,
        getRequestByIdPending: false,
        getRequestByIdError: null,
      };
    }

    case SELF_PERFORM_GET_REQUEST_BY_ID_FAILURE:
      return {
        ...state,
        selectedRequest: null,
        getRequestByIdPending: false,
        getRequestByIdError: action.data.error,
      };

    case SELF_PERFORM_GET_REQUEST_BY_ID_DISMISS_ERROR:
      return {
        ...state,
        getRequestByIdError: null,
      };

    default:
      return state;
  }
}
