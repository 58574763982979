import React, { useMemo, useState } from 'react';
import { Switch, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { changeTheme } from './redux/changeTheme';
import { DIM_THEME, STANDARD_THEME } from './constants';
import { THEME_ENABLE_DIMMED_MODE } from '../../analytics/themes/constants';

const DimmedModeSwitch = () => {
  const dispatch = useDispatch();

  const userId = useSelector(state => state.login.userInfo.sub || null);
  const themeName = useSelector(state => state.themes.theme);
  const isUsingSystemTheme = useSelector(state => state.themes.isUsingSystemTheme || false);

  const isDimModeOn = useMemo(() => themeName === DIM_THEME, [themeName]);

  const [themeBeforeDimmed, setThemeBeforeDimmed] = useState(themeName === DIM_THEME ? STANDARD_THEME : themeName);

  const handleChange = () => {
    const analyticsPayload = {
      eventType: THEME_ENABLE_DIMMED_MODE,
      isDimModeOn: !isDimModeOn,
    };

    if (!isDimModeOn) {
      setThemeBeforeDimmed(themeName);
      dispatch(changeTheme(DIM_THEME, userId, analyticsPayload));
    } else {
      dispatch(changeTheme(themeBeforeDimmed, userId, analyticsPayload));
    }
  };

  return (
    <div className="theme-settings-dimmed-mode-container">
      <Typography gutterBottom className="theme-settings-dimmed-mode-switch-title">
        Enable Dimmed Mode
      </Typography>
      <Switch
        checked={isDimModeOn}
        onChange={handleChange}
        value={isDimModeOn}
        color="primary"
        disabled={isUsingSystemTheme}
      />
    </div>
  );
};

export default DimmedModeSwitch;
