import { put, takeLatest, all, select } from 'redux-saga/effects';
import { getProjectListQuery } from 'src/features/projects/queries/getProjectListQuery';
import deactivatedAvatar from 'src/images/deactivated_avatar.svg?url';
import emptyAvatar from 'src/images/empty_avatar.svg?url';
import {
  REPORTS_COLLECT_PROJECT_DATA_BEGIN,
  REPORTS_COLLECT_PROJECT_DATA_SUCCESS,
  REPORTS_COLLECT_PROJECT_DATA_FAILURE,
  REPORTS_COLLECT_PROJECT_DATA_DISMISS_ERROR,
} from './constants';
import { MIN_FROM, MAX_SIZE } from '../../../common/constants';
import { resolveImage } from '../utils/reportUtils';
import { peoplePhotos } from '../../people/redux/selectors';
import { doGetReportProjects } from './getReportProjects';
import { doGetProjectAllocations } from './getProjectAllocations';

export function collectProjectData(accountId, queries, settings) {
  return {
    type: REPORTS_COLLECT_PROJECT_DATA_BEGIN,
    accountId,
    queries,
    settings,
  };
}

export function dismissCollectProjectDataError() {
  return {
    type: REPORTS_COLLECT_PROJECT_DATA_DISMISS_ERROR,
  };
}

export function* doCollectProjectData(action) {
  const { queries, accountId, settings } = action;
  const { includeAllocations, preloadPhotos, roleSortOption, roleFilters, permissions } = settings;
  const { query, variables } = getProjectListQuery(accountId, queries, MIN_FROM, MAX_SIZE, roleSortOption, roleFilters, permissions);

  try {
    const requests = [doGetReportProjects({ query, variables, settings, accountId })];
    if (includeAllocations) {
      requests.push(doGetProjectAllocations({ accountId }));

      if (preloadPhotos) {
        const peoplePhotoUrls = yield select(peoplePhotos);
        peoplePhotoUrls.forEach(url => requests.push(resolveImage(url)));
        requests.push(resolveImage(deactivatedAvatar));
        requests.push(resolveImage(emptyAvatar));
      }
    }

    yield all(requests);
  } catch (err) {
    yield put({
      type: REPORTS_COLLECT_PROJECT_DATA_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: REPORTS_COLLECT_PROJECT_DATA_SUCCESS,
    settings,
  });
}

export function* watchCollectProjectData() {
  yield takeLatest(REPORTS_COLLECT_PROJECT_DATA_BEGIN, doCollectProjectData);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case REPORTS_COLLECT_PROJECT_DATA_BEGIN:
      return {
        ...state,
        collectProjectDataPending: true,
        collectProjectDataError: null,
      };

    case REPORTS_COLLECT_PROJECT_DATA_SUCCESS: {
      const { settings } = action;
      const { settings: previousSettings } = state;
      const { type } = settings;

      return {
        ...state,
        showReport: true,
        type,
        settings: {
          ...previousSettings,
          ...settings,
        },
        collectProjectDataPending: false,
        collectProjectDataError: null,
      };
    }

    case REPORTS_COLLECT_PROJECT_DATA_FAILURE:
      return {
        ...state,
        collectProjectDataPending: false,
        collectProjectDataError: action.data.error,
      };

    case REPORTS_COLLECT_PROJECT_DATA_DISMISS_ERROR:
      return {
        ...state,
        collectProjectDataError: null,
      };

    default:
      return state;
  }
}
