import { getDateRangeQuery } from './queryUtils';
import {
  BLANK_FILTER,
  FIELDS,
  FIELDS_NAME,
  FIELDS_VALUE_DATE,
} from './constants';

const blankFilter = fieldName => (
  {
    bool: {
      must_not: [
        {
          nested: {
            path: FIELDS,
            query: { match: { [FIELDS_NAME]: fieldName } },
          },
        },
      ],
    },
  }
);

const queryFilter = (field, fieldName) => {
  const selected = field.selected[0];
  const query = getDateRangeQuery(selected, field.verb);

  return {
    nested: {
      path: FIELDS,
      query: {
        bool: {
          must: [
            {
              match: {
                [FIELDS_NAME]: fieldName,
              },
            },
            {
              range: {
                [FIELDS_VALUE_DATE]: query,
              },
            },
          ],
        },
      },
    },
  };
};

export default function generateDateFilter(field, fieldName) {
  if (field.verb === BLANK_FILTER) {
    return blankFilter(fieldName);
  }

  return queryFilter(field, fieldName);
}
