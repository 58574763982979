import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { List } from '@material-ui/core';
import {
  VerbSelector,
  RenderItem,
  MultiSearch,
  SelectAll,
  RadioChipForm,
} from '..';

export default function MultiSelect({
  expanded,
  selectedItems,
  selectedVerb,
  onVerbChange,
  verbs,
  verbText,
  disableDelete,
  onDelete,
  selectedAll,
  selectedSome,
  onSelectAll,
  visibleItems,
  noResultsFound,
  searchString,
  filterKey,
  onSelect,
  placeholder,
  onSearch,
  onExpand,
  onClearSearch,
  expandedSubs,
  subText,
  onExpandSub,
  controlOptions,
  hideVerbSelector,
  showSelectedCount,
  primaryContent,
}) {
  const { id, selected } = selectedItems;
  const isSelectListSearch = noResultsFound !== null;
  const hasSelectedItems = selected.length > 0;

  // If parent is `SelectListSearch`, show "no results found" based on passed prop rather than visibleItems length
  const noResults = isSelectListSearch ? noResultsFound : visibleItems.length === 0;

  const defaultControlOption = useMemo(() => (
    controlOptions
      ? selected.map(chip => chip.name)
      : null
  ), [controlOptions, selected]);

  const listCount = useMemo(() => {
    if (!showSelectedCount || !hasSelectedItems) return 0;

    // assume if it doesn't have an id it's being used for the controlOptions
    // and we don't want to include controlOptions in the count
    const selectedItems = selected.filter(item => !!item.id);
    return selectedItems.length;
  }, [showSelectedCount, hasSelectedItems, selected]);

  return (
    <div className="multiselect-select-list">
      {!hideVerbSelector && (
        <VerbSelector
          onExpand={onExpand}
          expanded={expanded}
          selectedItems={selectedItems}
          selectedVerb={selectedVerb}
          onVerbChange={onVerbChange}
          verbs={verbs}
          verbText={verbText}
          disableDelete={disableDelete}
          onDelete={onDelete}
        />
      )}
      <div className={classnames('select-list-wrap', { expanded: expanded === id || hideVerbSelector })}>
        {controlOptions && (
          <div className="radio-chip-form">
            <RadioChipForm
              controls={controlOptions}
              onChange={onSelect}
              defaultValue={defaultControlOption}
              isMultiSelect
            />
          </div>
        )}
        <div className="search-select-wrap">
          <MultiSearch
            placeholder={placeholder}
            searchString={searchString}
            onSearch={onSearch}
            onClearSearch={onClearSearch}
            isSelectListSearch={isSelectListSearch}
          />
          {!isSelectListSearch && (
            <SelectAll
              selectedAll={selectedAll}
              selectedSome={selectedSome}
              onSelectAll={onSelectAll}
            />
          )}
        </div>
        {isSelectListSearch && !searchString && !hasSelectedItems && (
          <div className="item-wrap empty">Use search to narrow down people results</div>
        )}
        {noResults && <div className="item-wrap empty">No results found</div>}
        {showSelectedCount && listCount > 0 && (
          <div className="selected-count">{`${listCount} selected`}</div>
        )}
        <List className="item-wrap">
          {visibleItems.map(item => (
            <RenderItem
              item={item}
              key={item[filterKey]}
              searchString={searchString}
              selectedItems={selectedItems}
              filterKey={filterKey}
              onSelect={onSelect}
              expandedSubs={expandedSubs}
              subText={subText}
              onExpandSub={onExpandSub}
              primaryContent={primaryContent}
            />
          ))}
        </List>
      </div>
    </div>
  );
}

MultiSelect.propTypes = {
  selectedAll: PropTypes.bool,
  selectedSome: PropTypes.bool,
  onSelectAll: PropTypes.func,
  visibleItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  expanded: PropTypes.string.isRequired,
  filterKey: PropTypes.string.isRequired,
  noResultsFound: PropTypes.bool,
  searchString: PropTypes.string,
  selectedItems: PropTypes.object.isRequired,
  selectedVerb: PropTypes.string,
  onVerbChange: PropTypes.func.isRequired,
  verbs: PropTypes.arrayOf(PropTypes.object),
  verbText: PropTypes.string.isRequired,
  disableDelete: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired,
  onClearSearch: PropTypes.func.isRequired,
  expandedSubs: PropTypes.array,
  subText: PropTypes.string,
  onExpandSub: PropTypes.func,
  controlOptions: PropTypes.array,
  hideVerbSelector: PropTypes.bool,
  showSelectedCount: PropTypes.bool,
  primaryContent: PropTypes.string,
};

MultiSelect.defaultProps = {
  onSelectAll: () => {},
  onExpandSub: () => {},
  noResultsFound: null,
  searchString: '',
  selectedSome: false,
  selectedAll: false,
  placeholder: '',
  expandedSubs: [],
  subText: '',
  controlOptions: null,
  hideVerbSelector: false,
  showSelectedCount: false,
  verbs: null,
  selectedVerb: null,
  primaryContent: undefined,
};
