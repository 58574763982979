import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { CircularProgress } from '@material-ui/core';
import { Modal } from '../common';
import { closeModal } from './redux/closeModal';
import { COMMON_MODAL_POSITION_MIDDLE } from '../common/redux/constants';

const ManagedModal = ({
  activeModal,
  closeModal,
  modalId,
  children,
  pending,
  headline,
  onOpen,
  onClose,
  className,
  showAppBar,
  showClose,
  position,
  fullHeight,
  maxWidth,
}) => {
  const [open, setOpen] = useState(activeModal === modalId);

  useEffect(() => {
    if (!open && activeModal === modalId) {
      onOpen();
      setOpen(true);
    }
    if (open && activeModal !== modalId) {
      setOpen(false);
    }
  }, [activeModal, modalId, onOpen, open]);

  const renderContent = () => (
    pending
      ? <CircularProgress className="spinner" size={35} />
      : <>{children}</>
  );

  const handleClose = () => {
    onClose();
    closeModal();
  };

  return open
    ? (
      <Modal
        open={open}
        headline={headline}
        closeModal={handleClose}
        className={classNames('modal-manager-managed-modal', className)}
        showAppBar={showAppBar}
        disableEnforceFocus
        showClose={showClose}
        position={position}
        fullHeight={fullHeight}
        maxWidth={maxWidth}
        closeTooltipTitle="Close Modal"
        closeTooltipDelay={2000}
      >
        { renderContent() }
      </Modal>
    )
    : null;
};

ManagedModal.propTypes = {
  className: PropTypes.string,
  activeModal: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  modalId: PropTypes.string.isRequired,
  pending: PropTypes.bool,
  children: PropTypes.node,
  headline: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  showAppBar: PropTypes.bool,
  showClose: PropTypes.bool,
  position: PropTypes.string,
  fullHeight: PropTypes.bool,
  maxWidth: PropTypes.string,
};

ManagedModal.defaultProps = {
  className: '',
  activeModal: null,
  pending: false,
  headline: '',
  children: null,
  onOpen: () => {},
  onClose: () => {},
  showAppBar: true,
  showClose: true,
  position: COMMON_MODAL_POSITION_MIDDLE,
  fullHeight: false,
  maxWidth: 'sm',
};

const mapStateToProps = ({ modalManager }) => {
  const { activeModal } = modalManager;
  return {
    activeModal,
  };
};

const mapDispatchToProps = dispatch => ({
  closeModal: bindActionCreators(closeModal, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManagedModal);
