import {
  PROJECTS_BACKUP_PROJECT_SELECTION, PROJECTS_PROJECT_SELECTION_BACKUP,
} from './constants';

export function backupProjectSelection(selectionId) {
  return {
    type: PROJECTS_BACKUP_PROJECT_SELECTION,
    selectionId,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case PROJECTS_BACKUP_PROJECT_SELECTION: {
      const { projectSelections } = state;
      const { selectionId } = action;
      const currentSelection = projectSelections[selectionId];
      return currentSelection
        ? {
          ...state,
          projectSelections: {
            ...projectSelections,
            [PROJECTS_PROJECT_SELECTION_BACKUP]: {
              ...currentSelection,
            },
          },
        }
        : {
          ...state,
        };
    }

    default:
      return state;
  }
}
