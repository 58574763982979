import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { MoreHoriz, Close, NoteAddOutlined } from '@material-ui/icons';
import {
  IconButton,
  Input,
  TableCell,
  TableRow,
  Tooltip,
  Collapse,
  SvgIcon,
} from '@material-ui/core';
import { trackAnalytics } from 'src/features/common/redux/actions';
import { COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PROJECT } from 'src/features/common/redux/constants';
import { PROJECT_DUPLICATE_ROLE_SETUP } from 'src/analytics/projects/constants';
import { CancelButton, DatePicker } from '../common';
import { NumberInput } from '../wrapped-components';
import { ProjectRoleEditor, RoleNoteField } from '.';
import { RolePhaseSelector } from '../phases';
import { DATE_INPUT_FORMAT, BASE_COLUMN_COUNT, PROJECT_VIEW } from '../../common/constants';
import { AUTOMATION_ROLE_START_DATE_PREFIX, AUTOMATION_ROLE_END_DATE_PREFIX } from './ids';
import ContentCopyIcon from '../../images/content_copy.svg';

export const AddProjectRoleRow = ({
  selectedRole,
  selectedProject,
  onRoleTypeUpdate,
  updateRole,
  clearRequirements,
  onShowModal,
  removeResource,
  startDate,
  endDate,
  parentName,
  copyRole,
  roleNote,
  selectedPhases,
}) => {
  const dispatch = useDispatch();

  const { activeView, multiStateModal: { activeView: modalActiveView } } = useSelector(({ common }) => common);
  const [showNote, setShowNote] = useState(!!roleNote);
  const [note, setNote] = useState(roleNote || '');
  const [manualChange, setManualChange] = useState(false);

  const displayNote = () => setShowNote(true);

  const clearNote = useCallback(() => {
    setNote('');
    setShowNote(false);
  }, [setNote]);

  const onNoteChange = useCallback((evt) => {
    setNote(evt.target.value);
  }, [setNote]);

  const onPhaseSelect = useCallback(() => {
    setManualChange(false);
  }, []);

  const hasPhases = selectedProject?.phases?.length > 0;

  const onBlur = useCallback(() => {
    updateRole(selectedRole.rowId, { note });
  }, [updateRole, selectedRole.rowId, note]);

  const onNameChange = useCallback((roleId) => {
    onRoleTypeUpdate(selectedRole.rowId, roleId);
  }, [onRoleTypeUpdate, selectedRole.rowId]);

  const onStartChange = useCallback((date) => {
    updateRole(selectedRole.rowId, { startDate: date });
    setManualChange(true);
  }, [updateRole, selectedRole.rowId]);

  const onEndChange = useCallback((date) => {
    updateRole(selectedRole.rowId, { endDate: date });
    setManualChange(true);
  }, [updateRole, selectedRole.rowId]);

  const onClearRequirements = useCallback(() => {
    clearRequirements(selectedRole.rowId);
    setManualChange(true);
  }, [clearRequirements, selectedRole.rowId]);

  const onPercentageChange = useCallback((newValue) => {
    const { requirements } = selectedRole;
    updateRole(selectedRole.rowId, {
      requirements: [{
        startDate: requirements[0].startDate,
        endDate: requirements[0].endDate,
        allocatedPercent: newValue,
      }],
    });
  }, [updateRole, selectedRole]);

  const onCopyRole = useCallback(() => {
    copyRole(selectedRole.rowId);

    const viewAddedFrom = () => {
      if (activeView === PROJECT_VIEW) {
        return 'Project Details';
      }

      if (modalActiveView === COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PROJECT) {
        return 'Project Details Modal';
      }

      return null;
    };

    const analyticsPayload = {
      'Project name': selectedProject.name,
      'Project id': selectedProject.id,
      'Role name': selectedRole.name,
      'With note': !!selectedRole.note,
      'With custom allocation': selectedRole.requirements.length > 1,
      'Added from': viewAddedFrom(),
    };

    dispatch(trackAnalytics(PROJECT_DUPLICATE_ROLE_SETUP, analyticsPayload));
  }, [
    copyRole,
    selectedRole,
    selectedProject,
    activeView,
    dispatch,
    modalActiveView,
  ]);

  const onRemoveRole = useCallback(() => {
    removeResource(selectedRole.rowId);
  }, [removeResource, selectedRole.rowId]);

  const requirementsDisabled = moment(selectedRole.startDate).isAfter(selectedRole.endDate)
    || moment(selectedRole.endDate).isBefore(selectedRole.startDate);

  const colSpan = hasPhases ? BASE_COLUMN_COUNT + 1 : BASE_COLUMN_COUNT;

  const renderProjectRoleEditor = useMemo(() => (
    <TableCell scope="row" className="roles-column">
      <ProjectRoleEditor
        selectedRole={selectedRole}
        onChange={onNameChange}
      />
    </TableCell>
  ), [onNameChange, selectedRole]);

  const renderNoteSection = useMemo(() => (
    <TableCell className="note-column">
      <IconButton className={classNames('note-add', { active: !!note })} onClick={displayNote} disabled={showNote}>
        <Tooltip title="Add a note" placement="top">
          <NoteAddOutlined />
        </Tooltip>
      </IconButton>
    </TableCell>
  ), [note, showNote]);

  const renderRolePhaseSelector = useMemo(() => {
    if (hasPhases) {
      return (
        <TableCell>
          <RolePhaseSelector
            selectedProject={selectedProject}
            defaultPhases={selectedPhases}
            updateRole={updateRole}
            selectedRole={selectedRole}
            manualChange={manualChange}
            onPhaseSelect={onPhaseSelect}
            allocatedPercent={selectedRole.requirements?.[0]?.allocatedPercent}
            parentName={parentName}
          />
        </TableCell>
      );
    }
    return null;
  }, [hasPhases, selectedProject, selectedPhases, updateRole, selectedRole, manualChange, onPhaseSelect, parentName]);

  const renderStartDateSelector = useMemo(() => (
    <TableCell>
      <div className="date-wrapper">
        <DatePicker
          minDate={moment(startDate, DATE_INPUT_FORMAT)}
          minDateMessage="Start date cannot be earlier than the project start date"
          maxDate={moment(selectedRole.endDate, DATE_INPUT_FORMAT)}
          maxDateMessage="Start date must be earlier than the role end date"
          invalidDateMessage="Invalid Date Format"
          date={selectedRole.startDate}
          onChange={onStartChange}
          variant="inline"
          id={`${AUTOMATION_ROLE_START_DATE_PREFIX}${selectedRole.rowId}`}
        />
      </div>
    </TableCell>
  ), [onStartChange, startDate, selectedRole.startDate, selectedRole.endDate, selectedRole.rowId]);

  const renderEndDateSelector = useMemo(() => (
    <TableCell>
      <div className="date-wrapper">
        <DatePicker
          minDate={moment(selectedRole.startDate, DATE_INPUT_FORMAT)}
          minDateMessage="End date must be later than the role start date"
          maxDate={moment(endDate, DATE_INPUT_FORMAT)}
          maxDateMessage="End date cannot be later than the project end date"
          invalidDateMessage="Invalid Date Format"
          date={selectedRole.endDate.format()}
          onChange={onEndChange}
          variant="inline"
          id={`${AUTOMATION_ROLE_END_DATE_PREFIX}${selectedRole.rowId}`}
        />
      </div>
    </TableCell>
  ), [endDate, selectedRole.startDate, selectedRole.endDate, onEndChange, selectedRole.rowId]);

  const renderRoleAllocations = useMemo(() => (
    <>
      <TableCell>
        <div className="resource-role-allocation">
          {selectedRole.requirements.length > 1 && (
          <div className="custom-wrap">
            <Input
              className="allocation-picker"
              type="text"
              value="Custom"
              disabled
            />
            <CancelButton
              className="clear"
              onClick={onClearRequirements}
            />
          </div>
          )}
          {selectedRole?.requirements?.length === 1 && (
          <NumberInput
            className="allocation-picker"
            value={selectedRole.requirements[0].allocatedPercent}
            onValueChanged={onPercentageChange}
          />
          )}
          <IconButton data-rowid={selectedRole.rowId} onClick={onShowModal} disabled={requirementsDisabled}>
            <Tooltip title="Customize" placement="top">
              <MoreHoriz />
            </Tooltip>
          </IconButton>
        </div>
      </TableCell>
      <TableCell>
        <div className="project-role-row-controls">
          <Tooltip title="Copy Role" placement="top">
            <IconButton className="project-role-copy" aria-label="copy role" onClick={onCopyRole}>
              <SvgIcon><ContentCopyIcon /></SvgIcon>
            </IconButton>
          </Tooltip>
          <Tooltip title="Remove role" placement="top">
            <IconButton className="project-role-remove" onClick={onRemoveRole}>
              <Close fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </div>
      </TableCell>
    </>
  ), [onClearRequirements, onPercentageChange, onShowModal, requirementsDisabled, selectedRole.requirements, selectedRole.rowId, onRemoveRole, onCopyRole]);

  const renderNoteEditor = useMemo(() => (
    <TableRow className="projects-add-project-role-row">
      <TableCell colSpan={colSpan}>
        <Collapse in={showNote} timeout="auto" unmountOnExit>
          <div className="note-container">
            <p>Note:</p>
            <RoleNoteField
              onNoteChange={onNoteChange}
              note={note}
              clearNote={clearNote}
              onBlur={onBlur}
              showDeleteWhenEmpty
            />
          </div>
        </Collapse>
      </TableCell>
    </TableRow>
  ), [colSpan, showNote, onNoteChange, note, clearNote, onBlur]);

  return (
    <>
      <TableRow className="projects-add-project-role-row attention-fade">
        {renderProjectRoleEditor}
        {renderNoteSection}
        {renderRolePhaseSelector}
        {renderStartDateSelector}
        {renderEndDateSelector}
        {renderRoleAllocations}
      </TableRow>
      {renderNoteEditor}
    </>
  );
};

AddProjectRoleRow.propTypes = {
  selectedRole: PropTypes.object.isRequired,
  selectedProject: PropTypes.object.isRequired,
  onRoleTypeUpdate: PropTypes.func.isRequired,
  updateRole: PropTypes.func.isRequired,
  clearRequirements: PropTypes.func.isRequired,
  onShowModal: PropTypes.func.isRequired,
  removeResource: PropTypes.func.isRequired,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  parentName: PropTypes.string,
  copyRole: PropTypes.func.isRequired,
  roleNote: PropTypes.string,
  selectedPhases: PropTypes.array,
};

AddProjectRoleRow.defaultProps = {
  startDate: '',
  endDate: '',
  parentName: 'Project List',
  roleNote: '',
  selectedPhases: [],
};

export default AddProjectRoleRow;
