import { gql } from 'apollo-boost';

const REQUEST_BY_ID_QUERY = gql`
  query getRequestById($accountId: Int!, $requestId: Int!) {
    request(accountId: $accountId, requestId: $requestId) {
      id
      description
      accountId
      project {
        id
        name
        colour
      }
      startDate
      endDate
      assignee {
        id
        name
        phoneNumber
        skillSet
        fields {
          id
          name
          values
        }
      }
      requester {
        id
        name
        phoneNumber
      }
      requestedOn
      workDays
      requiredSkills
      startAt
      endAt
      state
      lastNotifiedOn
      note
    }
  }
`;

const getRequestByIdQuery = (accountId, requestId) => {
  const variables = { accountId, requestId };

  return {
    query: REQUEST_BY_ID_QUERY,
    variables,
  };
};

export {
  getRequestByIdQuery,
};
