import { call, put, takeLatest } from 'redux-saga/effects';
import { ACTIVE } from 'src/features/projects/constants';
import {
  PEOPLE_GET_PERSON_CURRENT_ALLOCATIONS_BEGIN,
  PEOPLE_GET_PERSON_CURRENT_ALLOCATIONS_SUCCESS,
  PEOPLE_GET_PERSON_CURRENT_ALLOCATIONS_FAILURE,
  PEOPLE_GET_PERSON_CURRENT_ALLOCATIONS_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';

export function getPersonCurrentAllocations(accountId, personId, offset) {
  // If need to pass args to saga, pass it with the begin action.
  return {
    type: PEOPLE_GET_PERSON_CURRENT_ALLOCATIONS_BEGIN,
    accountId,
    personId,
    offset,
  };
}

export function dismissGetPersonCurrentAllocationsError() {
  return {
    type: PEOPLE_GET_PERSON_CURRENT_ALLOCATIONS_DISMISS_ERROR,
  };
}

// worker Saga: will be fired on PEOPLE_GET_PERSON_CURRENT_ALLOCATIONS_BEGIN actions
export function* doGetPersonCurrentAllocations(action) {
  const { accountId, personId, offset } = action;
  let res;
  try {
    res = yield call(protectedJsonGet, `accounts/${accountId}/persons/${personId}/current-allocations`, { offset, projectState: ACTIVE, limit: 5000 }, true);
  } catch (err) {
    yield put({
      type: PEOPLE_GET_PERSON_CURRENT_ALLOCATIONS_FAILURE,
      data: { error: err },
    });
    return;
  }
  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: PEOPLE_GET_PERSON_CURRENT_ALLOCATIONS_SUCCESS,
    data: res.data,
    headers: res.headers,
    personId,
    offset,
  });
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchGetPersonCurrentAllocations() {
  yield takeLatest(PEOPLE_GET_PERSON_CURRENT_ALLOCATIONS_BEGIN, doGetPersonCurrentAllocations);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_GET_PERSON_CURRENT_ALLOCATIONS_BEGIN:
      return {
        ...state,
        getPersonCurrentAllocationsPending: true,
        getPersonCurrentAllocationsError: null,
      };

    case PEOPLE_GET_PERSON_CURRENT_ALLOCATIONS_SUCCESS:
      return {
        ...state,
        currentAllocations: {
          ...state.currentAllocations,
          [action.personId]: action.offset > 0 ? state.currentAllocations[action.personId].concat(action.data) : action.data,
        },
        moreCurrentAllocations: action.headers['query-has-more'] === 'True',
        getPersonCurrentAllocationsPending: false,
        getPersonCurrentAllocationsError: null,
      };

    case PEOPLE_GET_PERSON_CURRENT_ALLOCATIONS_FAILURE:
      return {
        ...state,
        getPersonCurrentAllocationsPending: false,
        getPersonCurrentAllocationsError: action.data.error,
      };

    case PEOPLE_GET_PERSON_CURRENT_ALLOCATIONS_DISMISS_ERROR:
      return {
        ...state,
        getPersonCurrentAllocationsError: null,
      };

    default:
      return state;
  }
}
