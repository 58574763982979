import { call, put, takeLatest } from 'redux-saga/effects';
import {
  WRAPPED_COMPONENTS_GET_PLACE_SUGGESTIONS_BEGIN,
  WRAPPED_COMPONENTS_GET_PLACE_SUGGESTIONS_SUCCESS,
  WRAPPED_COMPONENTS_GET_PLACE_SUGGESTIONS_FAILURE,
  WRAPPED_COMPONENTS_GET_PLACE_SUGGESTIONS_DISMISS_ERROR,
} from './constants';
import graphApiClient from '../../../common/GraphAPIClient';
import { getPlaceSuggestionsQuery } from '../queries/getPlaceSuggestionsQuery';

export function getPlaceSuggestions(accountId, input = '') {
  const { query, variables } = getPlaceSuggestionsQuery(accountId, input);

  return {
    type: WRAPPED_COMPONENTS_GET_PLACE_SUGGESTIONS_BEGIN,
    accountId,
    input,
    query,
    variables,
  };
}

export function dismissGetPlaceSuggestionsError() {
  return {
    type: WRAPPED_COMPONENTS_GET_PLACE_SUGGESTIONS_DISMISS_ERROR,
  };
}

export function* doGetPlaceSuggestions(action) {
  const { query, variables } = action;

  let res;
  try {
    res = yield call(graphApiClient.query, {
      query,
      variables,
    });
  } catch (err) {
    yield put({
      type: WRAPPED_COMPONENTS_GET_PLACE_SUGGESTIONS_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: WRAPPED_COMPONENTS_GET_PLACE_SUGGESTIONS_SUCCESS,
    data: res.data,
  });
}

export function* watchGetPlaceSuggestions() {
  yield takeLatest(WRAPPED_COMPONENTS_GET_PLACE_SUGGESTIONS_BEGIN, doGetPlaceSuggestions);
}

export function reducer(state, action) {
  switch (action.type) {
    case WRAPPED_COMPONENTS_GET_PLACE_SUGGESTIONS_BEGIN:
      return {
        ...state,
        getPlaceSuggestionsPending: true,
        getPlaceSuggestionsError: null,
      };

    case WRAPPED_COMPONENTS_GET_PLACE_SUGGESTIONS_SUCCESS: {
      const { data } = action;
      const { places } = data;

      return {
        ...state,
        placeSuggestions: {
          ...state.placeSuggestions,
          ...places,
          isAddressSuggestionSelected: false,
        },
        getPlaceSuggestionsPending: false,
        getPlaceSuggestionsError: null,
      };
    }

    case WRAPPED_COMPONENTS_GET_PLACE_SUGGESTIONS_FAILURE:
      return {
        ...state,
        getPlaceSuggestionsPending: false,
        getPlaceSuggestionsError: action.data.error,
      };

    case WRAPPED_COMPONENTS_GET_PLACE_SUGGESTIONS_DISMISS_ERROR:
      return {
        ...state,
        getPlaceSuggestionsError: null,
      };

    default:
      return state;
  }
}
