import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
} from '@material-ui/core';

function ConfirmAllocationChange({
  open,
  loading,
  primaryActionText,
  primaryAction,
  secondaryAction,
  message,
  subMessage,
}) {
  if (!open) return null;

  return (
    <div className="allocations-confirm-allocation-change">
      {loading
        ? <div className="confirm-message"><CircularProgress className="spinner" size={35} /></div>
        : (
          <div className="confirm-message">
            <div>{message}</div>
            <div className="confirm-sub-message">
              {Array.isArray(subMessage) ? subMessage.join(', ') : subMessage }
            </div>
            <div className="action-buttons">
              <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={secondaryAction}
              >
                Cancel
              </Button>
              <Button
                className="button__default"
                variant="contained"
                size="medium"
                onClick={primaryAction}
              >
                {primaryActionText}
              </Button>
            </div>
          </div>
        )}
    </div>
  );
}

ConfirmAllocationChange.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  primaryActionText: PropTypes.string,
  primaryAction: PropTypes.func,
  secondaryAction: PropTypes.func,
  message: PropTypes.string,
  subMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

ConfirmAllocationChange.defaultProps = {
  open: true,
  loading: false,
  primaryActionText: '',
  primaryAction: () => {},
  secondaryAction: () => {},
  message: '',
  subMessage: '',
};

export default ConfirmAllocationChange;
