const TOGGLE_STATE = 'Toggle State';
const THEME_SELECTED = 'Theme selected';
const SETTING_UPDATED = 'Setting updated';
const THEME_ENABLE_DIMMED_MODE = 'Theme - Enable Dimmed Mode';
const DIM_MODE_SETTING_UPDATED = 'Dim Mode setting updated';
const DARK_MODE_SETTING_UPDATED = 'Dark Mode setting updated';
const THEME_OS_SYSTEM_THEME_SELECTED = 'Theme - OS system theme selected';

export {
  TOGGLE_STATE,
  THEME_SELECTED,
  SETTING_UPDATED,
  THEME_ENABLE_DIMMED_MODE,
  DIM_MODE_SETTING_UPDATED,
  DARK_MODE_SETTING_UPDATED,
  THEME_OS_SYSTEM_THEME_SELECTED,
};
