import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PROJECTS_GET_PROJECT_DEFAULTS_BEGIN,
  PROJECTS_GET_PROJECT_DEFAULTS_SUCCESS,
  PROJECTS_GET_PROJECT_DEFAULTS_FAILURE,
  PROJECTS_GET_PROJECT_DEFAULTS_DISMISS_ERROR,
} from './constants';
import { getBaseRequest } from '../../../utils/api';

export function getProjectDefaults() {
  return {
    type: PROJECTS_GET_PROJECT_DEFAULTS_BEGIN,
  };
}

export function dismissGetProjectDefaultsError() {
  return {
    type: PROJECTS_GET_PROJECT_DEFAULTS_DISMISS_ERROR,
  };
}

export function* doGetProjectDefaults() {
  let res;
  try {
    res = yield call(getBaseRequest, 'bench-defaults/project');
  } catch (err) {
    yield put({
      type: PROJECTS_GET_PROJECT_DEFAULTS_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PROJECTS_GET_PROJECT_DEFAULTS_SUCCESS,
    data: res,
  });
}

export function* watchGetProjectDefaults() {
  yield takeLatest(PROJECTS_GET_PROJECT_DEFAULTS_BEGIN, doGetProjectDefaults);
}

export function reducer(state, action) {
  switch (action.type) {
    case PROJECTS_GET_PROJECT_DEFAULTS_BEGIN:
      return {
        ...state,
        getProjectDefaultsPending: true,
        getProjectDefaultsError: null,
      };

    case PROJECTS_GET_PROJECT_DEFAULTS_SUCCESS:
      return {
        ...state,
        projectDefaults: action.data,
        getProjectDefaultsPending: false,
        getProjectDefaultsError: null,
      };

    case PROJECTS_GET_PROJECT_DEFAULTS_FAILURE:
      return {
        ...state,
        getProjectDefaultsPending: false,
        getProjectDefaultsError: action.data.error,
      };

    case PROJECTS_GET_PROJECT_DEFAULTS_DISMISS_ERROR:
      return {
        ...state,
        getProjectDefaultsError: null,
      };

    default:
      return state;
  }
}
