import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import deepEqual from 'react-fast-compare';
import {
  LIST_TAB,
  PROJECT_LIST_STATIC_COLUMNS,
  LIST,
} from 'src/common/constants';
import { LocalStorageKeys } from 'src/common/localStorageKeys';
import { FilteredTable } from '../table';
import { filterTable } from '../table/filterUtils';
import { AddProjectModal, ProjectDetails } from '.';
import {
  refreshProjectAllocations,
  getProjectById,
  clearProjectSelection,
} from './redux/actions';
import { trackAnalytics } from '../common/redux/actions';
import { generateInputs } from './utils/addProjectModalUtils';
import { FILTERED_PROJECTS_QUERY_ID } from '../queries/redux/constants';
import { PROJECT_LIST_SELECTION_ID } from './redux/constants';
import { LOST, CANCELED, WIN_PERCENT_LABEL } from './constants';
import { PROJECT_VIEW_PROJECT_DETAILS } from '../../analytics/projects/constants';

export class ProjectList extends PureComponent {
  static propTypes = {
    accountId: PropTypes.number.isRequired,
    projects: PropTypes.object.isRequired,
    visibleColumns: PropTypes.array.isRequired,
    accountSettings: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    loadMore: PropTypes.func,
    queries: PropTypes.object,
    selectedProject: PropTypes.object,
    getProjectById: PropTypes.func.isRequired,
    clearProjectSelection: PropTypes.func.isRequired,
    refreshProjectAllocations: PropTypes.func.isRequired,
    projectCount: PropTypes.number,
    permissions: PropTypes.object.isRequired,
    defaultProjectFilters: PropTypes.arrayOf(Object).isRequired,
    projectSidePanelOpen: PropTypes.bool.isRequired,
    trackAnalytics: PropTypes.func.isRequired,
  };

  static defaultProps = {
    loadMore: () => {},
    selectedProject: null,
    queries: {},
    projectCount: 0,
  }

  constructor(props) {
    super(props);
    const {
      projects,
      visibleColumns,
      queries,
    } = props;

    window.localStorage.setItem(LocalStorageKeys.projectView, LIST_TAB);

    this.state = {
      projectRows: this.mapProjectsToRows(projects.filteredProjects, visibleColumns, queries),
    };
  }

  componentDidMount() {
    const {
      match,
      accountId,
      getProjectById,
      permissions,
    } = this.props;

    const selectedProjectId = Number(match.params.projectId) || null;

    if (selectedProjectId) {
      getProjectById(accountId, selectedProjectId, PROJECT_LIST_SELECTION_ID, false, permissions);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      refreshProjectAllocations,
      accountId,
      queries,
      visibleColumns,
      projects: {
        filteredProjects,
        addProjectPending,
        deleteProjectAllocationPending,
        addProjectRoleAllocationPending,
        updateProjectRoleAllocationPending,
        replaceProjectRoleAllocationPending,
      },
    } = this.props;

    // If something changes with the allocation or phases, refresh all projects
    const doneAddingAllocation = prevProps.projects.addProjectRoleAllocationPending && !addProjectRoleAllocationPending;
    const doneDeletingAllocation = prevProps.projects.deleteProjectAllocationPending && !deleteProjectAllocationPending;
    const doneReplacingAllocation = prevProps.projects.replaceProjectRoleAllocationPending && !replaceProjectRoleAllocationPending;
    const doneUpdatingAllocation = prevProps.projects.updateProjectRoleAllocationPending && !updateProjectRoleAllocationPending;
    const doneAddingProject = prevProps.projects.addProjectPending && !addProjectPending;

    if (doneAddingAllocation || doneDeletingAllocation || doneReplacingAllocation || doneUpdatingAllocation || doneAddingProject) {
      refreshProjectAllocations(accountId);
    }

    if (
      !deepEqual(filteredProjects, prevProps.projects.filteredProjects)
      || !deepEqual(queries, prevProps.queries)
      || !deepEqual(visibleColumns, prevProps.visibleColumns)
    ) {
      this.updateState({
        projectRows: this.mapProjectsToRows(filteredProjects, visibleColumns, queries),
      });
    }
  }

  updateState = newState => this.setState(newState);

  mapProjectsToRows = (filteredProjects, visibleColumns, queries) => {
    const data = filteredProjects.map((project) => {
      const { currentPhases, fields } = project;

      const projectObj = {
        'Project Name': project.name,
        'Start Date': project.startDate,
        'End Date': project.endDate,
        Roles: project.totalRoles === 0 ? '' : `${project.unfilledRoles} Role${project.unfilledRoles === 1 ? '' : 's'} unfilled`,
        Status: project.state,
        Issues: project.hasIssues,
        Type: project.type,
        [WIN_PERCENT_LABEL]: project.winPercent,
      };

      if (currentPhases && ![LOST, CANCELED].includes(project.state)) {
        projectObj['Active Phase'] = currentPhases.map((phase) => {
          const { name, subPhases } = phase;
          return subPhases.length
            ? subPhases.map(subPhase => `(${name} / ${subPhase})`)
            : `(${name})`;
        }).flat().join(', ');
      }

      if (fields) {
        fields.forEach((field) => {
          if (field.values.length > 1) {
            projectObj[field.name] = field.values.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).join(', ');
          } else if (field.type === 'Boolean') {
            projectObj[field.name] = field.values.length ? field.values[0].toString().toLowerCase() : 'false';
          } else if (field.values.length === 1) {
            projectObj[field.name] = field.values[0].toString();
          }
        });
      }

      const rowData = !visibleColumns ? [] : visibleColumns.map((column) => {
        if (typeof projectObj[column.name] === 'undefined') {
          projectObj[column.name] = '';
        }
        return projectObj[column.name];
      });

      return {
        rowMeta: {
          id: project.id,
          name: project.name,
          colour: project.colour,
          rawData: projectObj,
        },
        rowData,
        rowId: project.id,
      };
    });

    return filterTable(queries.search, data);
  }

  onCloseDetail = () => {
    const { history, accountId, clearProjectSelection } = this.props;
    clearProjectSelection(PROJECT_LIST_SELECTION_ID);
    history.replace(`/accounts/${accountId}/projects/list`);
  }

  onTableRowSelected = (selectedRowId, rowMeta) => {
    const { history, accountId, getProjectById, permissions, trackAnalytics } = this.props;
    const analyticsPayload = {
      'Project Name': rowMeta?.name,
      'Project ID': rowMeta?.id,
      'Project status': rowMeta?.status || rowMeta?.rawData?.Status,
      'Project type': rowMeta?.type || rowMeta?.rawData?.Type,
    };

    trackAnalytics(PROJECT_VIEW_PROJECT_DETAILS, analyticsPayload);
    getProjectById(accountId, rowMeta.id, PROJECT_LIST_SELECTION_ID, false, permissions);
    history.replace(`/accounts/${accountId}/projects/list/${rowMeta.id}`);
  }

  render() {
    const {
      projects,
      accountSettings,
      loading,
      visibleColumns,
      loadMore,
      selectedProject,
      projectCount,
      defaultProjectFilters,
      projectSidePanelOpen,
    } = this.props;
    const { projectRows } = this.state;
    const showTableLoader = loading && !selectedProject;
    const columns = selectedProject ? visibleColumns.slice(0, 1) : visibleColumns;
    const selectedRowId = selectedProject ? selectedProject.id : '';
    const noMatch = projects.filteredProjects.length === 0
      ? 'No projects have been added to your account, click the + button below to add a project.'
      : 'Sorry, no matching records found';

    const { projectFields, phases } = accountSettings;
    const { requiredInputs, optionalInputs, dateInputs } = generateInputs(projectFields, phases);
    const defaultPhases = phases && phases.length ? phases.filter(phase => phase.includeOnNewProjects) : [];

    return (
      <div className="projects-project-list">
        <div className={classNames('listWrapper', { 'listWrapper--left': !!selectedProject, 'project-panel-open': projectSidePanelOpen })}>
          <FilteredTable
            showFilters={!selectedProject}
            data={projectRows}
            columns={columns}
            onRowClick={this.onTableRowSelected}
            loadMore={loadMore}
            noMatch={noMatch}
            loading={showTableLoader}
            loadingPage={projects.getFilteredProjectsPagePending}
            pagingError={!!projects.getFilteredProjectsPageError}
            selectedRowId={selectedRowId}
            defaultFilters={defaultProjectFilters}
            staticColumns={PROJECT_LIST_STATIC_COLUMNS}
            allowColumnReordering
            queryId={FILTERED_PROJECTS_QUERY_ID}
            resultCount={projectCount}
          />
        </div>

        {selectedProject && (
          <ProjectDetails
            className="detail-compact"
            onBackClick={this.onCloseDetail}
            selectedProject={selectedProject}
          />
        )}

        {!selectedProject && (
          <AddProjectModal
            requiredInputs={requiredInputs}
            optionalInputs={optionalInputs}
            dateInputs={dateInputs}
            defaultPhases={defaultPhases}
            modalOrigin={LIST}
            redirectToProject={this.onTableRowSelected}
          />
        )}
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps({ common, projects, table, accountSettings, queries, login }) {
  const { accountId, projectSidePanelOpen } = common;
  const { filteredProjects: filteredProjectsQueries } = queries;
  const { projectSelections, projectCount } = projects;
  const { visibleColumns } = table;
  const { permissions } = login.userInfo;

  return {
    accountId,
    projects,
    visibleColumns,
    accountSettings,
    queries: filteredProjectsQueries,
    selectedProject: projectSelections[PROJECT_LIST_SELECTION_ID],
    projectCount,
    permissions,
    projectSidePanelOpen,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    refreshProjectAllocations: bindActionCreators(refreshProjectAllocations, dispatch),
    getProjectById: bindActionCreators(getProjectById, dispatch),
    clearProjectSelection: bindActionCreators(clearProjectSelection, dispatch),
    trackAnalytics: bindActionCreators(trackAnalytics, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ProjectList));
