import { gql } from 'apollo-boost';
import { PAGE_SIZE } from 'src/common/constants';
import { generateRequestFilter, getQueryParamsFromFilters } from 'src/filters/graphqlUtils';
import { getSearchStringFromQuery } from 'src/features/filters/utils/filterUtils';
import { PEOPLE_SCHEMAS } from 'src/common/descriptors/person';

const ROLE_AVAILABILITY_FILTER_QUERY = gql`
  query availabilityQuery(
    $accountId: Int!
    $projectId: Int!
    $roleId: Int!
    $endDate: Date!,
    $startDate: Date!,
    $requireAvailability: Boolean,
    $size: Int
    $from: Int
    $filters: [ESQuery]
    $structuredSort: [RoleAvailabilitySort]
    $radius: Int
  ) {
    roleAvailabilities(
      accountId: $accountId
      projectId: $projectId
      roleId: $roleId
      startDate: $startDate
      endDate: $endDate
      requireAvailability: $requireAvailability
      size: $size
      from: $from
      filters: $filters
      structuredSort: $structuredSort
      radius: $radius
    ) {
      count
      hasMore
      cursor
      originalItems {
        id: personId
      },
      items {
        roleId
        id: personId
        name: personName
        title: personTitle
        canFillAllocationRange
        availabilities {
          availablePercent: percent
          startDate
          endDate
        }
        unavailabilities {
          id
          startDate
          endDate
          actualStart
          actualEnd
          unavailable
          description
          rangeType
          isPrivate
        }
        pursuitProjectsCount
        distanceFromProject
      }
      }
    }
`;

/*
  This query has a fair bit of logic in place that is set up for the specific
  requirements in the AllocatePersonModal (ie, a search filter means all other
  filters are skipped.) For anything that requires this data in a more streamlined
  way, a query with more generic sort/filter logic should be created.
*/
const getRoleAvailabilityQuery = ({
  accountId,
  projectId,
  roleId,
  startDate,
  endDate,
  requireAvailability,
  queries = {},
  pageFrom,
  pageSize,
  radius,
}) => {
  const defaults = {
    size: pageSize || PAGE_SIZE,
    requireAvailability: typeof requireAvailability === 'undefined' ? true : requireAvailability,
  };

  const availabilitySortClause = {
    canFillAllocationRange: {
      order: 'desc',
    },
  };

  const structuredSort = [
    {
      personName: {
        order: 'asc',
      },
    },
  ];

  const { search, filter } = queries;

  const filters = [];
  let hasSearch = false;

  const searchString = getSearchStringFromQuery(queries);

  if (searchString.length && search && search.args && search.args.length) {
    const searchFilter = generateRequestFilter(search, ['name']);
    if (searchFilter) {
      hasSearch = true;
      filters.push(searchFilter[0]);
    }
  } else {
    // If not searching, results should be sorted by availability first
    structuredSort.unshift(availabilitySortClause);
  }

  // If a search filter is applied, it should override any other filters
  if (!hasSearch) {
    const activeFilter = { bool: { must: [{ match: { isActive: true } }] } };
    filters.push(activeFilter);

    if (filter && filter.args && filter.args.length) {
      const queryParams = getQueryParamsFromFilters(filter);

      queryParams.forEach((param) => {
        Object.assign(defaults, param);
      });

      const propFilters = generateRequestFilter(filter, PEOPLE_SCHEMAS);
      if (propFilters && propFilters.length) filters.push(...propFilters);
    }
  }

  const variables = {
    accountId,
    projectId,
    roleId,
    startDate,
    endDate,
    filters,
    structuredSort,
    from: pageFrom,
    radius,
    ...defaults,
  };

  return {
    query: ROLE_AVAILABILITY_FILTER_QUERY,
    variables,
  };
};

export {
  getRoleAvailabilityQuery,
};
