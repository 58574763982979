import moment from 'moment';
import {
  LABEL_TIME_OFF,
  LABEL_FULL_AVAILABLE,
  TIME_OFF,
} from 'src/common/constants';
import { NEXT_AVAILABILITY, FUTURE_AVAILABILITY } from 'src/filters/constants';
import {
  BLUE,
  GREEN,
  BACKGROUND_GREEN,
  ORANGE,
  BACKGROUND_ORANGE,
  BLACK,
  WHITE,
  LIGHT_GREY,
} from '@bridgit/foundation';
import { momentToString } from 'src/utils/dateUtils';
import { sortSegmentsByStartDate, stitchSegmentsByRange } from 'src/utils/dateSegmentUtils';

export function quickFilterValues(filters) {
  const availabilityFilters = filters && filters.args && filters.args.length ? filters.args.find(filter => filter.column === NEXT_AVAILABILITY) : null;
  const futureAvailability = availabilityFilters
    ? availabilityFilters.activeFilters.find(filter => filter.value === FUTURE_AVAILABILITY)
    : false;
  const issuesFilters = filters && filters.args && filters.args.length ? filters.args.find(filter => filter.column === 'hasConflict') : null;
  const hasIssue = issuesFilters
    ? issuesFilters.activeFilters.find(filter => filter.value)
    : false;
  return {
    availableFilter: !!futureAvailability,
    issuesFilter: !!hasIssue,
  };
}

function pushAvailableBar(group, key, barEnd, barStart, maxDate) {
  const startDate = moment(barEnd).add(1, 'days');
  const endDate = moment(barStart).subtract(1, 'days');

  if (moment(startDate).isAfter(maxDate)) return;

  group.push({
    id: key,
    shortLabel: '100%',
    label: LABEL_FULL_AVAILABLE,
    startDate: momentToString(startDate),
    endDate: momentToString(endDate),
    barColor: BACKGROUND_GREEN,
    borderColor: GREEN,
    textColor: GREEN,
    isAvailable: true,
  });
}

function pushUnavailableBar(pou, personAllocations, defaultStart, index, maxDate) {
  const startDate = moment.utc(pou.startDate).isAfter(defaultStart) ? pou.startDate : defaultStart;
  const endDate = moment.utc(pou.endDate).isAfter(maxDate) ? maxDate : pou.endDate;

  if (moment(startDate).isAfter(maxDate)) return;

  const timeOffBarColor = pou?.rangeType === TIME_OFF ? LIGHT_GREY : BLACK;

  const barInfo = {
    id: `${pou.id}-${index}-unavailable`,
    label: LABEL_TIME_OFF,
    startDate: momentToString(moment(startDate)),
    endDate: momentToString(moment(endDate)),
    barColor: timeOffBarColor,
    borderColor: timeOffBarColor,
    details: pou,
    isUnavailable: true,
  };
  personAllocations.push(barInfo);
}

function getTitleText(percent) {
  if (percent < 100) {
    return ' Available';
  }
  return ' Allocated';
}

export function parsePeople(defaultStart, maxDate, allocations, people) {
  const group = {};
  const parsedPeople = [];

  if (!people || people.length === 0) {
    return parsedPeople;
  }

  people.forEach((person) => {
    const { id, name, title, unavailabilities, projectAllocations } = person;
    const personAllocations = allocations.filter(({ personId }) => personId === id);

    group[id] = {
      id,
      name,
      title,
      photoUrl: person.photoUrl ? person.photoUrl : '',
      allocationGroups: [],
      projectAllocations,
    };

    if (unavailabilities) {
      unavailabilities.forEach((pou, index) => {
        if (moment(pou.endDate).isAfter(defaultStart)) {
          pushUnavailableBar(pou, personAllocations, defaultStart, index, maxDate);
        }
      });
    }

    if (!personAllocations.length) {
      const barEnd = moment(defaultStart).subtract(1, 'day');
      pushAvailableBar(group[id].allocationGroups, `${id}-full-available`, barEnd, maxDate, maxDate);
    }

    const sortedSegments = sortSegmentsByStartDate(personAllocations);
    const parsedAllocations = stitchSegmentsByRange(sortedSegments);

    parsedAllocations.forEach((allocation, index) => {
      const { allocatedPercent, isUnavailable } = allocation;
      const titleText = getTitleText(allocatedPercent);
      const isAvailable = allocatedPercent < 100;
      const percent = isAvailable ? (100 - allocatedPercent) : allocatedPercent;
      const startDate = moment.utc(allocation.startDate);
      const endDate = moment.utc(allocation.endDate);

      let barInfo = isUnavailable ? allocation : {
        id: `${id}-${index}`,
        shortLabel: `${percent}%`,
        label: `${percent}%${titleText}`,
        startDate: allocation.startDate,
        endDate: allocation.endDate,
        details: allocation,
        isAvailable,
      };

      if (!isUnavailable) {
        if (allocatedPercent < 100) {
          barInfo = {
            ...barInfo,
            barColor: WHITE,
            borderColor: GREEN,
            textColor: GREEN,
          };
        } else if (allocatedPercent > 100) {
          barInfo = {
            ...barInfo,
            barColor: BACKGROUND_ORANGE,
            borderColor: ORANGE,
            isOverAllocated: true,
          };
        } else {
          barInfo = {
            ...barInfo,
            barColor: BLUE,
            borderColor: BLUE,
          };
        }
      }

      if (index === 0) {
        const barEnd = moment(defaultStart).subtract(1, 'day');
        const barStart = startDate;
        const dayDiff = barStart.diff(barEnd, 'days');
        if (dayDiff > 1) {
          pushAvailableBar(group[id].allocationGroups, `${index}-first-available`, barEnd, barStart, maxDate);
        }
      }

      if (index < parsedAllocations.length - 1) {
        const nextBar = parsedAllocations[index + 1];
        const barEnd = endDate;
        const barStart = moment.utc(nextBar.startDate);
        const dayDiff = barStart.diff(barEnd, 'days');

        if (dayDiff > 1) {
          pushAvailableBar(group[id].allocationGroups, `${index}-100-available`, barEnd, barStart, maxDate);
        }
      } else if (index === parsedAllocations.length - 1) {
        const barEnd = endDate;
        const barStart = maxDate;
        const dayDiff = barStart.diff(barEnd, 'days');

        if (dayDiff > 1) {
          pushAvailableBar(group[id].allocationGroups, `${index}-last-available`, barEnd, barStart, maxDate);
        }
      }

      group[id].allocationGroups.push(barInfo);
    });

    parsedPeople.push(group[id]);
  });

  return parsedPeople;
}

export function getTitles(personFields) {
  const titleField = personFields.find(field => field.name === 'Title');

  if (titleField) {
    return titleField.definedValues.map(value => ({ name: value.definedValue, value: value.definedValue }));
  }

  return [];
}
