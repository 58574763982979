import React from 'react';
import { Button, Tooltip } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const CloseButton = ({ onClick, closeTooltipTitle, closeTooltipDelay, color, className }) => {
  const enableTooltip = closeTooltipTitle?.length ? undefined : false;

  return (
    <Tooltip open={enableTooltip} title={closeTooltipTitle} enterDelay={closeTooltipDelay} placement="bottom-start">
      <Button
        aria-label="Close"
        className={classNames(
          'common-close-button',
          className,
        )}
        disableRipple
        color={color}
        onClick={onClick}
      >
        <Close />
      </Button>
    </Tooltip>
  );
};

CloseButton.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.string,
  closeTooltipTitle: PropTypes.string,
  closeTooltipDelay: PropTypes.number,
  className: PropTypes.string,
};

CloseButton.defaultProps = {
  onClick: () => {},
  color: 'default',
  closeTooltipTitle: '',
  closeTooltipDelay: 100,
  className: null,
};

export default CloseButton;
