import { call, put, takeLatest } from 'redux-saga/effects';
import {
  SELF_PERFORM_GET_HOURLY_PERSON_FIELD_VALUES_BEGIN,
  SELF_PERFORM_GET_HOURLY_PERSON_FIELD_VALUES_SUCCESS,
  SELF_PERFORM_GET_HOURLY_PERSON_FIELD_VALUES_FAILURE,
  SELF_PERFORM_GET_HOURLY_PERSON_FIELD_VALUES_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';

export function getHourlyPersonFieldValues(accountId, hourlyPersonId) {
  return {
    type: SELF_PERFORM_GET_HOURLY_PERSON_FIELD_VALUES_BEGIN,
    accountId,
    hourlyPersonId,
  };
}

export function dismissGetHourlyPersonFieldValuesError() {
  return {
    type: SELF_PERFORM_GET_HOURLY_PERSON_FIELD_VALUES_DISMISS_ERROR,
  };
}

export function* doGetHourlyPersonFieldValues(action) {
  const { accountId, hourlyPersonId } = action;
  let res;
  try {
    res = yield call(protectedJsonGet, `accounts/${accountId}/hourly-persons/${hourlyPersonId}/person-field-values`);
  } catch (err) {
    yield put({
      type: SELF_PERFORM_GET_HOURLY_PERSON_FIELD_VALUES_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: SELF_PERFORM_GET_HOURLY_PERSON_FIELD_VALUES_SUCCESS,
    data: res,
  });
}

export function* watchGetHourlyPersonFieldValues() {
  yield takeLatest(SELF_PERFORM_GET_HOURLY_PERSON_FIELD_VALUES_BEGIN, doGetHourlyPersonFieldValues);
}

export function reducer(state, action) {
  switch (action.type) {
    case SELF_PERFORM_GET_HOURLY_PERSON_FIELD_VALUES_BEGIN:
      return {
        ...state,
        getHourlyPersonFieldValuesPending: true,
        getHourlyPersonFieldValuesError: null,
      };

    case SELF_PERFORM_GET_HOURLY_PERSON_FIELD_VALUES_SUCCESS:
      return {
        ...state,
        hourlyPersonFieldValues: action.data || [],
        getHourlyPersonFieldValuesPending: false,
        getHourlyPersonFieldValuesError: null,
      };

    case SELF_PERFORM_GET_HOURLY_PERSON_FIELD_VALUES_FAILURE:
      return {
        ...state,
        getHourlyPersonFieldValuesPending: false,
        getHourlyPersonFieldValuesError: action.data.error,
      };

    case SELF_PERFORM_GET_HOURLY_PERSON_FIELD_VALUES_DISMISS_ERROR:
      return {
        ...state,
        getHourlyPersonFieldValuesError: null,
      };

    default:
      return state;
  }
}
