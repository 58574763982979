import {
  COMMON_SET_ACTIVE_VIEW,
} from './constants';

export function setActiveView(activeView = null) {
  return {
    activeView,
    type: COMMON_SET_ACTIVE_VIEW,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_SET_ACTIVE_VIEW: {
      const { activeView = null } = action;
      return {
        ...state,
        activeView,
      };
    }
    default:
      return state;
  }
}
