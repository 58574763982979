const initialState = {
  getFilteredRoleAvailabilitiesPending: false,
  getFilteredRoleAvailabilitiesPagePending: false,
  getFilteredRoleAvailabilitiesError: null,
  getFilteredRoleAvailabilitiesPageError: null,
  pageFrom: 0,
  hasMore: true,
  roleAvailabilities: [],
  originalRoleAvailabilities: [],
  radius: 0,
};

export default initialState;
