import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { SideSlideMenu, SideSlideMenuButton } from '@bridgit/foundation';
import { MessageOutlined, Restore, AttachFile, Beenhere } from '@material-ui/icons';
import { togglePersonSidePanel } from 'src/features/common/redux/actions';
import { Can } from '../wrapped-components';
import {
  SIDE_PANEL_PERSON_NOTES,
  SIDE_PANEL_PERSON_HISTORY,
  SIDE_PANEL_PERSON_ATTACHMENTS,
  SIDE_PANEL_PERSON_CERTIFICATIONS,
} from './constants';
import {
  ACCOUNT_MODULE_ATTACHMENTS,
  ACCOUNT_MODULE_CERTIFICATIONS,
  PEOPLE_ACCOUNT_MODULE_COMPONENT,
} from '../../common/constants';
import { PersonNotesContainer, PersonAudit, ProfileAttachments, ProfileCertifications } from '.';
import { getStorageKey, PERSON_DETAILS_SIDE_PANEL_OPEN_KEY } from '../../common/localStorageKeys';
import { getValidatedLocalStorage } from '../../utils/validators';

const PersonDetailsSideMenu = ({ selectedPerson }) => {
  const dispatch = useDispatch();

  const { activeView, accountId } = useSelector(({ common }) => common);
  const { sub: userId } = useSelector(({ login }) => login.userInfo);
  const filtersApplied = useSelector(({ queries: { filteredPeople } }) => !!filteredPeople?.filter?.args?.length);

  const sidePanelOpenKey = useMemo(() => (
    getStorageKey(activeView, accountId, PERSON_DETAILS_SIDE_PANEL_OPEN_KEY, userId)
  ), [activeView, accountId, userId]);

  const defaultSidePanelOpen = useMemo(() => {
    const isOpened = getValidatedLocalStorage(sidePanelOpenKey);
    return isOpened ?? true;
  }, [sidePanelOpenKey]);

  useEffect(() => {
    dispatch(togglePersonSidePanel(defaultSidePanelOpen));
  }, [defaultSidePanelOpen, dispatch]);

  const defaultActivePanel = SIDE_PANEL_PERSON_NOTES;

  const [open, setOpen] = useState(defaultSidePanelOpen);
  const [activePanel, setActivePanel] = useState(defaultActivePanel);

  const panelTop = useMemo(() => {
    if (!selectedPerson?.isActive && selectedPerson?.state !== 'Active') return 161;
    return filtersApplied ? 133 : 102;
  }, [filtersApplied, selectedPerson?.isActive, selectedPerson?.state]);

  const trackSlideMenuState = (activePanel, open) => {
    const { name, id } = selectedPerson;

    const eventName = {
      [SIDE_PANEL_PERSON_NOTES]: 'View Person Notes',
      [SIDE_PANEL_PERSON_HISTORY]: 'View Profile Activity Log',
      [SIDE_PANEL_PERSON_ATTACHMENTS]: 'View Profile Attachments',
      [SIDE_PANEL_PERSON_CERTIFICATIONS]: 'View Certifications',
    };

    window.mixpanel.track(eventName[activePanel] || activePanel, {
      'Person name': name,
      'Person ID': id,
      'Viewed from': 'Person profile',
    });

    localStorage.setItem(sidePanelOpenKey, open);
  };

  const openSidePanel = newActivePanel => () => {
    if (newActivePanel === activePanel && open) {
      trackSlideMenuState('Close Person Panel', false);
      dispatch(togglePersonSidePanel(false));
      setOpen(false);
    } else {
      trackSlideMenuState(newActivePanel, true);
      setActivePanel(newActivePanel);
      dispatch(togglePersonSidePanel(true));
      setOpen(true);
    }
  };

  const onSlideMenuToggle = () => {
    if (open) {
      trackSlideMenuState('Close Person Panel', false);
    } else {
      trackSlideMenuState(defaultActivePanel, true);
    }
    // Reset active panel to default on toggle
    setActivePanel(defaultActivePanel);
    dispatch(togglePersonSidePanel(!open));
    setOpen(!open);
  };

  const renderSideButtons = () => (
    <>
      <SideSlideMenuButton
        tooltip="Person notes"
        icon={<MessageOutlined />}
        isActive={open && activePanel === SIDE_PANEL_PERSON_NOTES}
        onClick={openSidePanel(SIDE_PANEL_PERSON_NOTES)}
      />
      {selectedPerson?.isActive && (
        <Can
          module={ACCOUNT_MODULE_CERTIFICATIONS}
          component={PEOPLE_ACCOUNT_MODULE_COMPONENT}
          yes={(
            <SideSlideMenuButton
              tooltip="Certifications"
              icon={<Beenhere />}
              isActive={open && activePanel === SIDE_PANEL_PERSON_CERTIFICATIONS}
              onClick={openSidePanel(SIDE_PANEL_PERSON_CERTIFICATIONS)}
            />
          )}
        />
      )}
      {selectedPerson?.isActive && (
        <Can
          module={ACCOUNT_MODULE_ATTACHMENTS}
          component={PEOPLE_ACCOUNT_MODULE_COMPONENT}
          yes={(
            <SideSlideMenuButton
              tooltip="Attachments"
              icon={<AttachFile />}
              isActive={open && activePanel === SIDE_PANEL_PERSON_ATTACHMENTS}
              onClick={openSidePanel(SIDE_PANEL_PERSON_ATTACHMENTS)}
            />
          )}
        />
      )}
      {selectedPerson?.isActive && (
        <SideSlideMenuButton
          tooltip="Profile activity"
          icon={<Restore />}
          isActive={open && activePanel === SIDE_PANEL_PERSON_HISTORY}
          onClick={openSidePanel(SIDE_PANEL_PERSON_HISTORY)}
        />
      )}
    </>
  );

  const personNotesPanel = useMemo(() => (
    <PersonNotesContainer
      headerLabel="Notes"
      personId={selectedPerson.id}
      personName={selectedPerson?.name}
      personNotes={selectedPerson?.notes}
    />
  ), [selectedPerson.id, selectedPerson?.name, selectedPerson?.notes]);

  const personAudit = useMemo(() => <PersonAudit headerLabel="Profile Activity" />, []);

  const profileAttachments = useMemo(() => <ProfileAttachments />, []);

  const profileCertifications = useMemo(() => <ProfileCertifications />, []);

  const renderActivePanel = useMemo(() => {
    const renderPanel = {
      [SIDE_PANEL_PERSON_NOTES]: personNotesPanel,
      [SIDE_PANEL_PERSON_HISTORY]: personAudit,
      [SIDE_PANEL_PERSON_ATTACHMENTS]: profileAttachments,
      [SIDE_PANEL_PERSON_CERTIFICATIONS]: profileCertifications,
    };
    return renderPanel[activePanel] || null;
  }, [activePanel, personAudit, personNotesPanel, profileAttachments, profileCertifications]);

  if (!activePanel) return null;

  return (
    <SideSlideMenu
      open={open}
      hide={false}
      top={panelTop}
      renderSideButtons={renderSideButtons}
      onSlideMenuToggle={onSlideMenuToggle}
    >
      {renderActivePanel}
    </SideSlideMenu>
  );
};

PersonDetailsSideMenu.propTypes = {
  selectedPerson: PropTypes.object.isRequired,
};

export default PersonDetailsSideMenu;
