import React from 'react';
import {
  AUTOMATION_REQUESTER_NAME_PREFIX,
  AUTOMATION_REQUESTER_EMAIL_PREFIX,
  AUTOMATION_PROJECT_REQUESTERS_INPUT_ID,
} from '../ids';

const getRequesterOption = ({ name, email, id }) => ({
  label: (
    <div className="requester-option" key={id}>
      <p id={`${AUTOMATION_REQUESTER_NAME_PREFIX}${name}`} className="requester-name">{name}</p>
      <p id={`${AUTOMATION_REQUESTER_EMAIL_PREFIX}${name}`} className="requester-email">{email}</p>
    </div>
  ),
  value: id,
  name,
});

export const getProjectRequestersInput = (accountRequesterUsers, projectRequesters) => ({
  id: AUTOMATION_PROJECT_REQUESTERS_INPUT_ID,
  name: 'Requesters on this project',
  label: 'Requesters on this project',
  type: 'select',
  multi: true,
  className: 'project-requesters-multiselect-container',
  options: accountRequesterUsers
    ?.filter(user => user.state === 'Enabled')
    ?.map(getRequesterOption) || [],
  value: projectRequesters.map(({ id }) => id),
  placeholder: 'Select requester',
  maxLength: 32,
});

export const getProjectRequestersItem = requesterInput => ({
  ...requesterInput,
  type: 'MultiSelect',
});

export const getSortedSelectedRequesterNames = (allRequesterOptions, selectedRequesters) => allRequesterOptions
  .filter(({ value }) => selectedRequesters.includes(value))
  .map(({ name }) => name)
  .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
  .join(', ');
