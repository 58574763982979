import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getFieldInput, unique } from 'src/utils/miscUtils';
import { PERM_WRITE, PERM_PERSON } from 'src/features/permissions/utils/constants';
import { naturalSort } from 'src/utils/sortUtils';
import { updatePerson, getPersonAllocationsByDate } from './redux/actions';
import { validateEmploymentPouOverlap } from './utils/unavailabilityUtils';
import { FieldEditor, Confirm } from '../common';
import { DATE_INPUT_FORMAT, MIN_CONFIGURABLE_DATE, MAX_CONFIGURABLE_DATE } from '../../common/constants';
import { PROFILE_DETAILS_MODAL_CONTEXT } from './constants';

export const ProfileEmployment = ({
  person,
  updatePerson,
  updatePersonPending,
  accountId,
  getPersonAllocationsByDate,
  personAllocationsByDate,
  runsInModal,
}) => {
  const initialStartDate = person.employmentDates?.startDate ?? undefined;
  const initialEndDate = person.employmentDates?.endDate ?? undefined;

  const [fieldToEdit, setFieldToEdit] = useState(null);
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [showUpdateConfirm, setShowUpdateConfirm] = useState(false);
  const [formData, setFormData] = useState(null);
  const [checkAllocations, setCheckAllocations] = useState(false);
  const [affectedProjects, setAffectedProjects] = useState([]);

  const fields = [
    {
      id: 1,
      name: 'Hire Date',
      type: 'Date',
      isRequired: false,
      isPrivate: false,
      value: initialStartDate,
      maxDate: endDate,
      minDate: MIN_CONFIGURABLE_DATE,
    },
    {
      id: 2,
      name: 'Termination Date',
      type: 'Date',
      isRequired: false,
      isPrivate: false,
      value: initialEndDate,
      minDate: startDate || MIN_CONFIGURABLE_DATE,
    },
  ];

  const onCancel = useCallback(() => {
    setShowUpdateConfirm(false);
    setStartDate(initialStartDate);
    setEndDate(initialEndDate);
    setFieldToEdit(null);
    setFormData(null);
    setCheckAllocations(false);
    setAffectedProjects([]);
  }, [initialStartDate, initialEndDate]);

  useEffect(() => {
    if (!updatePersonPending) {
      onCancel();
    }
  }, [person, updatePersonPending, onCancel]);

  useEffect(() => {
    let newAffectedProjects = [];
    if (personAllocationsByDate[person.id]) {
      const distinctProjects = unique(personAllocationsByDate[person.id], 'projectName');
      newAffectedProjects = naturalSort(distinctProjects, 'projectName');
    }
    setAffectedProjects(newAffectedProjects);
  }, [personAllocationsByDate, person.id]);

  const onSave = () => {
    if (formData) {
      const analyticsPayload = {
        personName: person.name,
        personId: person.id,
        field: `${fieldToEdit === 1 ? 'Hire' : 'Termination'} date`,
        updatedFrom: runsInModal ? PROFILE_DETAILS_MODAL_CONTEXT : 'People List',
        isActive: person.isActive,
      };
      updatePerson(accountId, person.id, formData, analyticsPayload);

      // Mixpanel - Track updating employment dates
      window.mixpanel.track('Person Hire/Termination Date Added or Updated', {
        'Date updated': fieldToEdit === 1 ? 'Hire' : 'Termination',
        Location: 'Employment tab',
      });
    } else {
      onCancel();
    }
  };

  const confirmUpdatePerson = () => {
    const valueIsNull = (fieldToEdit === 1 && !startDate) || (fieldToEdit === 2 && !endDate);
    const valueChanged = !moment(startDate).isSame(initialStartDate) || !moment(endDate).isSame(initialEndDate);
    if (personAllocationsByDate[person.id]
      && personAllocationsByDate[person.id].length
      && !valueIsNull
      && valueChanged
      && checkAllocations) {
      setShowUpdateConfirm(true);
    } else {
      onSave();
    }
  };

  const onValidate = (val) => {
    const { unavailabilities, employmentDates } = person;
    const value = val ? moment(val).format(DATE_INPUT_FORMAT) : null;
    const saveData = {
      employmentDates: {},
    };

    const overlap = !validateEmploymentPouOverlap(fieldToEdit === 1 ? 'start' : 'end', value, employmentDates, unavailabilities);

    if (overlap) {
      const dateType = fieldToEdit === 1 ? 'hire' : 'termination';
      const tense = fieldToEdit === 1 ? 'after' : 'before';
      const message = `A ${dateType} date cannot be added ${tense} or during time off.`;
      return { [fieldToEdit]: message };
    }

    let initialValue;
    let queryValue;
    if (fieldToEdit === 1) {
      saveData.employmentDates.startDate = value;
      initialValue = initialStartDate || MIN_CONFIGURABLE_DATE;
      queryValue = value && moment(value).subtract(1, 'day').format(DATE_INPUT_FORMAT);
      if (moment(initialValue).isBefore(value)) {
        setCheckAllocations(true);
      }
      setStartDate(value);
    } else {
      saveData.employmentDates.endDate = value;
      initialValue = initialEndDate || MAX_CONFIGURABLE_DATE;
      queryValue = value && moment(value).add(1, 'day').format(DATE_INPUT_FORMAT);
      if (moment(initialValue).isAfter(value)) {
        setCheckAllocations(true);
      }
      setEndDate(value);
    }
    setFormData(saveData);
    if (queryValue && initialValue) {
      const sortedValues = [initialValue, queryValue].sort((initialValue, queryValue) => moment(initialValue).diff(queryValue));
      getPersonAllocationsByDate(accountId, person.id, sortedValues[0], sortedValues[1]);
    }
    return {};
  };

  const onToggleEdit = id => setFieldToEdit(id);

  return (
    <div className="people-profile-employment">
      <div className="employment-dates">
        {fields.map((field) => {
          const input = getFieldInput(field);

          return (
            <div className="item-wrap" key={field.id}>
              <FieldEditor
                item={field}
                input={input}
                onSave={confirmUpdatePerson}
                onValidate={onValidate}
                disabled={fieldToEdit !== null && fieldToEdit !== field.id}
                permission={{ action: PERM_WRITE, subject: PERM_PERSON }}
                pending={updatePersonPending}
                fieldToEdit={onToggleEdit}
                editing={fieldToEdit === field.id}
                onCancel={onCancel}
              />
            </div>
          );
        })}
        {showUpdateConfirm && (
          <Confirm
            headline="Allocation Conflict"
            acceptButtonText="OK"
            cancelButtonText="Cancel"
            onCancel={onCancel}
            onAccept={onSave}
            loading={updatePersonPending}
          >
            <div className="employment-dates-confirm-message">
              <div className="update-person-message">
                <p>{`This ${fieldToEdit === 1 ? 'hire' : 'termination'} date impacts ${person.name}'s allocations on the following projects:`}</p>
                {affectedProjects.map(project => (
                  <p key={project.roleId} className="project-name">{project.projectName}</p>
                ))}
                <p>{`These allocations will be edited to accommodate for the new ${fieldToEdit === 1 ? 'hire' : 'termination'} date.`}</p>
              </div>
            </div>
          </Confirm>
        )}
      </div>
    </div>
  );
};

ProfileEmployment.propTypes = {
  person: PropTypes.object.isRequired,
  updatePerson: PropTypes.func.isRequired,
  updatePersonPending: PropTypes.bool.isRequired,
  accountId: PropTypes.number.isRequired,
  personAllocationsByDate: PropTypes.object.isRequired,
  getPersonAllocationsByDate: PropTypes.func.isRequired,
  runsInModal: PropTypes.bool,
};

ProfileEmployment.defaultProps = {
  runsInModal: false,
};

/* istanbul ignore next */
function mapStateToProps({ people, common }) {
  const { updatePersonPending, personAllocationsByDate } = people;
  const { accountId } = common;
  return {
    updatePersonPending,
    accountId,
    personAllocationsByDate,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    updatePerson: bindActionCreators(updatePerson, dispatch),
    getPersonAllocationsByDate: bindActionCreators(getPersonAllocationsByDate, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileEmployment);
