import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { HelpTooltip } from '@bridgit/foundation';
import { Button } from '@material-ui/core';
import {
  PERM_PROJECT_USER_ASSOCIATION,
  PERM_REQUEST,
  PERM_WRITE,
  SELF_PERFORM_REQUESTER,
} from '../permissions/utils/constants';
import { getUsers } from '../accounts/redux/getUsers';
import { FieldEditor, SimpleTable } from '../common';
import { getRequesters } from '../self-perform/redux/getRequesters';
import { assignRequester } from '../self-perform/redux/assignRequester';
import { deleteRequester } from '../self-perform/redux/deleteRequester';
import { getProjectRequestersInput, getProjectRequestersItem } from './utils/projectRequestersUtils';
import { AddRequestModal } from '../self-perform';
import { ADD_REQUEST_MODAL, REQUESTS_COLUMNS } from '../self-perform/constants';
import { Can } from '../wrapped-components';
import { openModal, closeModal } from '../modal-manager/redux/actions';
import { MULTI_STATE_MODAL_ID } from '../common/redux/constants';
import { generateProjectRequestsData } from '../self-perform/utils/hourlyPersonUtils';

const ProjectSelfPerformTab = ({
  accountId,
  getUsers,
  loading,
  getRequesters,
  users,
  projectRequesters,
  assignRequester,
  deleteRequester,
  selectedProject,
  openModal,
  closeModal,
  isModalOpen,
  addHourlyRequestPending,
  isModalAllowed,
}) => {
  const { id: selectedProjectId, name: selectedProjectName } = selectedProject;

  const [fieldToEdit, setFieldToEdit] = useState(null);

  useEffect(() => {
    getUsers(accountId, { group: SELF_PERFORM_REQUESTER });
  }, [getUsers, accountId]);

  useEffect(() => {
    if (users[accountId]) {
      getRequesters(accountId, selectedProjectId, users);
    }
  }, [getRequesters, accountId, selectedProjectId, users]);

  useEffect(() => {
    if (isModalAllowed && !addHourlyRequestPending) closeModal(ADD_REQUEST_MODAL);
  }, [closeModal, addHourlyRequestPending, isModalAllowed]);

  const onSave = ({ values }) => {
    const assignedProjectRequesterIds = projectRequesters.map(({ id }) => id);

    const addedRequesterIds = values.filter(val => !assignedProjectRequesterIds.includes(val));
    const deletedRequesterIds = assignedProjectRequesterIds.filter(id => !values.includes(id));

    if (addedRequesterIds.length) {
      assignRequester(accountId, selectedProjectId, addedRequesterIds, users, selectedProjectName);
    }

    if (deletedRequesterIds.length) {
      deleteRequester(accountId, selectedProjectId, deletedRequesterIds, selectedProjectName);
    }

    setFieldToEdit(null);
  };

  const handleCancelEditing = () => setFieldToEdit(null);

  const requesterInput = useMemo(() => getProjectRequestersInput(users[accountId], projectRequesters),
    [users, accountId, projectRequesters]);

  const requesterItem = useMemo(() => getProjectRequestersItem(requesterInput),
    [requesterInput]);

  const openAddRequestModal = useCallback(() => openModal(ADD_REQUEST_MODAL), [openModal]);

  const addRequestButton = useMemo(() => {
    if (!isModalAllowed) return null;

    return (
      <Button
        className="add-request-button"
        onClick={openAddRequestModal}
        disableRipple
      >
        Add Request
      </Button>
    );
  }, [openAddRequestModal, isModalAllowed]);

  const isEmptyList = !requesterInput.options.length;

  const tableData = useMemo(
    () => generateProjectRequestsData(selectedProject.requests),
    [selectedProject.requests],
  );

  return (
    <div className="projects-project-self-perform-tab">
      <div className="top">
        <Can
          action={PERM_WRITE}
          subject={PERM_REQUEST}
          yes={addRequestButton}
        />

        {isModalOpen && <AddRequestModal />}

        <div className="requesters-multiselect">
          <FieldEditor
            item={requesterItem}
            input={requesterInput}
            onSave={onSave}
            onCancel={handleCancelEditing}
            disabled={loading || isEmptyList}
            permission={{ action: PERM_WRITE, subject: PERM_PROJECT_USER_ASSOCIATION }}
            pending={loading}
            fieldToEdit={setFieldToEdit}
            editing={!!fieldToEdit}
            emptySelectionText={isEmptyList ? 'There are no users with the requester permission.' : 'None'}
          />
        </div>
        <div className="tooltip-wrap">
          <HelpTooltip
            text="A Requester will have the ability to create a request for this project."
            color="inherit"
          />
        </div>
      </div>
      {selectedProject.requests?.length === 0
        ? (
          <div className="empty-requests">
            There are no self-perform requests or assignments on this project. Visit the
            self-perform area to create a new request
          </div>
        )
        : (
          <SimpleTable
            className="table-wrap"
            tableClass="request-table"
            rowClass={{ root: 'self-perform-request-row' }}
            cols={REQUESTS_COLUMNS}
            rows={tableData}
            withShadow
          />
        )}
    </div>
  );
};

ProjectSelfPerformTab.propTypes = {
  accountId: PropTypes.number.isRequired,
  getUsers: PropTypes.func.isRequired,
  getRequesters: PropTypes.func.isRequired,
  assignRequester: PropTypes.func.isRequired,
  deleteRequester: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  users: PropTypes.object.isRequired,
  selectedProject: PropTypes.object.isRequired,
  projectRequesters: PropTypes.array.isRequired,
  addHourlyRequestPending: PropTypes.bool,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  isModalAllowed: PropTypes.bool.isRequired,
};

ProjectSelfPerformTab.defaultProps = {
  addHourlyRequestPending: false,
};

const mapStateToProps = ({
  common: { accountId },
  selfPerform: { assignRequesterPending, getProjectRequestersPending, projectRequesters, addHourlyRequestPending },
  accounts: { users },
  modalManager: { activeModal },
}) => ({
  accountId,
  loading: assignRequesterPending || getProjectRequestersPending,
  projectRequesters,
  users,
  isModalOpen: activeModal === ADD_REQUEST_MODAL,
  isModalAllowed: activeModal !== MULTI_STATE_MODAL_ID,
  addHourlyRequestPending,
});

const mapDispatchToProps = dispatch => ({
  getUsers: bindActionCreators(getUsers, dispatch),
  getRequesters: bindActionCreators(getRequesters, dispatch),
  assignRequester: bindActionCreators(assignRequester, dispatch),
  deleteRequester: bindActionCreators(deleteRequester, dispatch),
  openModal: bindActionCreators(openModal, dispatch),
  closeModal: bindActionCreators(closeModal, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectSelfPerformTab);
