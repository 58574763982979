import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PEOPLE_SET_PROFILE_PICTURE_BEGIN,
  PEOPLE_SET_PROFILE_PICTURE_SUCCESS,
  PEOPLE_SET_PROFILE_PICTURE_FAILURE,
  PEOPLE_SET_PROFILE_PICTURE_DISMISS_ERROR,
} from './constants';
import { uploadImage } from '../../../utils/api';

export function setProfilePicture(url, image, personId, blobUrl, analyticsPayload = {}) {
  return {
    type: PEOPLE_SET_PROFILE_PICTURE_BEGIN,
    url,
    image,
    personId,
    blobUrl,
    analyticsPayload,
  };
}

export function dismissSetProfilePictureError() {
  return {
    type: PEOPLE_SET_PROFILE_PICTURE_DISMISS_ERROR,
  };
}

export function* doSetProfilePicture(action) {
  const { url, image, personId, blobUrl, analyticsPayload } = action;
  let res;
  try {
    res = yield call(uploadImage, url, image);
  } catch (err) {
    yield put({
      type: PEOPLE_SET_PROFILE_PICTURE_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PEOPLE_SET_PROFILE_PICTURE_SUCCESS,
    data: res,
    personId,
    blobUrl,
    analyticsPayload,
  });
}

export function* watchSetProfilePicture() {
  yield takeLatest(PEOPLE_SET_PROFILE_PICTURE_BEGIN, doSetProfilePicture);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_SET_PROFILE_PICTURE_BEGIN:
      return {
        ...state,
        setProfilePicturePending: true,
        setProfilePictureError: null,
      };

    case PEOPLE_SET_PROFILE_PICTURE_SUCCESS: {
      const updatedPerson = {
        ...state.entities.find(p => p.id === action.personId),
        photoUrl: action.blobUrl,
      };

      return {
        ...state,
        entities: [
          ...state.entities.filter(p => p.id !== action.personId),
          updatedPerson,
        ],
        setProfilePicturePending: false,
        setProfilePictureError: null,
      };
    }

    case PEOPLE_SET_PROFILE_PICTURE_FAILURE:
      return {
        ...state,
        setProfilePicturePending: false,
        setProfilePictureError: action.data.error,
      };

    case PEOPLE_SET_PROFILE_PICTURE_DISMISS_ERROR:
      return {
        ...state,
        setProfilePictureError: null,
      };

    default:
      return state;
  }
}
