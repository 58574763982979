export const getAttachmentUploadState = (state, action, attachmentState) => {
  const { attachment, certificationId } = action;

  // certificationId is only passed if attachment is uploaded from certification view/tab
  return certificationId ? {
    personCertifications: {
      ...state.personCertifications,
      items: state.personCertifications.items.map((cert) => {
        const { id, attachments } = cert;
        return (id === certificationId)
          ? {
            ...cert,
            hasAttachments: true,
            attachments: [
              {
                ...attachment,
                state: attachmentState,
              },
              ...(attachments || []),
            ],
          } : cert;
      }),
    },
  } : {
    personAttachments: {
      ...state.personAttachments,
      items: [
        {
          ...attachment,
          state: attachmentState,
        },
        ...state.personAttachments.items,
      ],
    },
  };
};
