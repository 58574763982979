import {
  SERVICE_ACCOUNTS_COPY_SERVICE_ACCOUNT_ID,
  SERVICE_ACCOUNTS_COPY_SERVICE_ACCOUNT_PASSWORD,
} from './constants';
import { SERVICE_ACCOUNT_FIELDS } from '../constants';

const { ID } = SERVICE_ACCOUNT_FIELDS;

// This action does not update state, but triggers analytic events to fire
export function copyServiceAccountInfo(field, analyticsPayload) {
  return {
    type: field === ID ? SERVICE_ACCOUNTS_COPY_SERVICE_ACCOUNT_ID : SERVICE_ACCOUNTS_COPY_SERVICE_ACCOUNT_PASSWORD,
    analyticsPayload,
  };
}

export function reducer(state) {
  return state;
}
