export const DEFAULT_HOURLY_PERSONS_SORT = { name: 'sort', args: [{ column: 'name', label: 'Name', ascending: true, type: null }] };
export const DEFAULT_REQUEST_SORT = { name: 'sort', args: [{ column: 'startDate', label: 'Start Date', ascending: true, type: null }] };
export const DEFAULT_ASSIGNMENT_SORT = { name: 'sort', args: [{ column: 'startDate', label: 'Start Date', ascending: false, type: null }] };
export const DEFAULT_REQUEST_SECONDARY_SORT = [
  { startDate: { order: 'asc' } },
  { endDate: { order: 'asc' } },
];

export const MAX_REQUEST_SIZE = 9999;
export const DEFAULT_REQUEST_STATE_FILTER = {
  label: 'Status',
  column: 'state',
  activeFilters: [
    { name: 'Open', value: 'Open' },
    { name: 'Assigned', value: 'Assigned' },
    { name: 'AssignedNotified', value: 'AssignedNotified' },
    { name: 'Pending', value: 'Pending' },
  ],
};

export const DEFAULT_PROJECT_REQUEST_SORT = [
  { startDate: { order: 'asc' } },
  { endDate: { order: 'asc' } },
  { requiredSkills: { order: 'asc' } },
];

export const HOURLY_PERSONS_GET_FILTERED_HOURLY_PERSONS_BEGIN = 'HOURLY_PERSONS_GET_FILTERED_HOURLY_PERSONS_BEGIN';
export const HOURLY_PERSONS_GET_FILTERED_HOURLY_PERSONS_SUCCESS = 'HOURLY_PERSONS_GET_FILTERED_HOURLY_PERSONS_SUCCESS';
export const HOURLY_PERSONS_GET_FILTERED_HOURLY_PERSONS_FAILURE = 'HOURLY_PERSONS_GET_FILTERED_HOURLY_PERSONS_FAILURE';
export const HOURLY_PERSONS_GET_FILTERED_HOURLY_PERSONS_DISMISS_ERROR = 'HOURLY_PERSONS_GET_FILTERED_HOURLY_PERSONS_DISMISS_ERROR';
export const HOURLY_PERSONS_LIST_SELECTION_ID = 'HOURLY_PERSONS_LIST_SELECTION_ID';
export const SELF_PERFORM_ADD_HOURLY_PERSON_BEGIN = 'SELF_PERFORM_ADD_HOURLY_PERSON_BEGIN';
export const SELF_PERFORM_ADD_HOURLY_PERSON_SUCCESS = 'SELF_PERFORM_ADD_HOURLY_PERSON_SUCCESS';
export const SELF_PERFORM_ADD_HOURLY_PERSON_FAILURE = 'SELF_PERFORM_ADD_HOURLY_PERSON_FAILURE';
export const SELF_PERFORM_ADD_HOURLY_PERSON_DISMISS_ERROR = 'SELF_PERFORM_ADD_HOURLY_PERSON_DISMISS_ERROR';
export const SELF_PERFORM_GET_REQUESTERS_BEGIN = 'SELF_PERFORM_GET_REQUESTERS_BEGIN';
export const SELF_PERFORM_GET_REQUESTERS_SUCCESS = 'SELF_PERFORM_GET_REQUESTERS_SUCCESS';
export const SELF_PERFORM_GET_REQUESTERS_FAILURE = 'SELF_PERFORM_GET_REQUESTERS_FAILURE';
export const SELF_PERFORM_GET_REQUESTERS_DISMISS_ERROR = 'SELF_PERFORM_GET_REQUESTERS_DISMISS_ERROR';
export const SELF_PERFORM_ASSIGN_REQUESTER_BEGIN = 'SELF_PERFORM_ASSIGN_REQUESTER_BEGIN';
export const SELF_PERFORM_ASSIGN_REQUESTER_SUCCESS = 'SELF_PERFORM_ASSIGN_REQUESTER_SUCCESS';
export const SELF_PERFORM_ASSIGN_REQUESTER_FAILURE = 'SELF_PERFORM_ASSIGN_REQUESTER_FAILURE';
export const SELF_PERFORM_ASSIGN_REQUESTER_DISMISS_ERROR = 'SELF_PERFORM_ASSIGN_REQUESTER_DISMISS_ERROR';
export const SELF_PERFORM_DELETE_REQUESTER_BEGIN = 'SELF_PERFORM_DELETE_REQUESTER_BEGIN';
export const SELF_PERFORM_DELETE_REQUESTER_SUCCESS = 'SELF_PERFORM_DELETE_REQUESTER_SUCCESS';
export const SELF_PERFORM_DELETE_REQUESTER_FAILURE = 'SELF_PERFORM_DELETE_REQUESTER_FAILURE';
export const SELF_PERFORM_DELETE_REQUESTER_DISMISS_ERROR = 'SELF_PERFORM_DELETE_REQUESTER_DISMISS_ERROR';
export const SELF_PERFORM_GET_HOURLY_PERSON_BY_ID_BEGIN = 'SELF_PERFORM_GET_HOURLY_PERSON_BY_ID_BEGIN';
export const SELF_PERFORM_GET_HOURLY_PERSON_BY_ID_SUCCESS = 'SELF_PERFORM_GET_HOURLY_PERSON_BY_ID_SUCCESS';
export const SELF_PERFORM_GET_HOURLY_PERSON_BY_ID_FAILURE = 'SELF_PERFORM_GET_HOURLY_PERSON_BY_ID_FAILURE';
export const SELF_PERFORM_GET_HOURLY_PERSON_BY_ID_DISMISS_ERROR = 'SELF_PERFORM_GET_HOURLY_PERSON_BY_ID_DISMISS_ERROR';
export const SELF_PERFORM_CLEAR_SELECTED_HOURLY_PERSON = 'SELF_PERFORM_CLEAR_SELECTED_HOURLY_PERSON';
export const SELF_PERFORM_GET_FILTERED_REQUESTS_BEGIN = 'SELF_PERFORM_GET_FILTERED_REQUESTS_BEGIN';
export const SELF_PERFORM_GET_FILTERED_REQUESTS_SUCCESS = 'SELF_PERFORM_GET_FILTERED_REQUESTS_SUCCESS';
export const SELF_PERFORM_GET_FILTERED_REQUESTS_FAILURE = 'SELF_PERFORM_GET_FILTERED_REQUESTS_FAILURE';
export const SELF_PERFORM_GET_FILTERED_REQUESTS_DISMISS_ERROR = 'SELF_PERFORM_GET_FILTERED_REQUESTS_DISMISS_ERROR';
export const SELF_PERFORM_UPDATE_HOURLY_PERSON_BEGIN = 'SELF_PERFORM_UPDATE_HOURLY_PERSON_BEGIN';
export const SELF_PERFORM_UPDATE_HOURLY_PERSON_SUCCESS = 'SELF_PERFORM_UPDATE_HOURLY_PERSON_SUCCESS';
export const SELF_PERFORM_UPDATE_HOURLY_PERSON_FAILURE = 'SELF_PERFORM_UPDATE_HOURLY_PERSON_FAILURE';
export const SELF_PERFORM_UPDATE_HOURLY_PERSON_DISMISS_ERROR = 'SELF_PERFORM_UPDATE_HOURLY_PERSON_DISMISS_ERROR';
export const SELF_PERFORM_UPDATE_HOURLY_PERSON_FIELD_VALUE_BEGIN = 'SELF_PERFORM_UPDATE_HOURLY_PERSON_FIELD_VALUE_BEGIN';
export const SELF_PERFORM_UPDATE_HOURLY_PERSON_FIELD_VALUE_SUCCESS = 'SELF_PERFORM_UPDATE_HOURLY_PERSON_FIELD_VALUE_SUCCESS';
export const SELF_PERFORM_UPDATE_HOURLY_PERSON_FIELD_VALUE_FAILURE = 'SELF_PERFORM_UPDATE_HOURLY_PERSON_FIELD_VALUE_FAILURE';
export const SELF_PERFORM_UPDATE_HOURLY_PERSON_FIELD_VALUE_DISMISS_ERROR = 'SELF_PERFORM_UPDATE_HOURLY_PERSON_FIELD_VALUE_DISMISS_ERROR';
export const SELF_PERFORM_GET_HOURLY_PERSON_FIELD_VALUES_BEGIN = 'SELF_PERFORM_GET_HOURLY_PERSON_FIELD_VALUES_BEGIN';
export const SELF_PERFORM_GET_HOURLY_PERSON_FIELD_VALUES_SUCCESS = 'SELF_PERFORM_GET_HOURLY_PERSON_FIELD_VALUES_SUCCESS';
export const SELF_PERFORM_GET_HOURLY_PERSON_FIELD_VALUES_FAILURE = 'SELF_PERFORM_GET_HOURLY_PERSON_FIELD_VALUES_FAILURE';
export const SELF_PERFORM_GET_HOURLY_PERSON_FIELD_VALUES_DISMISS_ERROR = 'SELF_PERFORM_GET_HOURLY_PERSON_FIELD_VALUES_DISMISS_ERROR';
export const SELF_PERFORM_GET_HOURLY_PEOPLE_FIELD_VALUES_BEGIN = 'SELF_PERFORM_GET_HOURLY_PEOPLE_FIELD_VALUES_BEGIN';
export const SELF_PERFORM_GET_HOURLY_PEOPLE_FIELD_VALUES_SUCCESS = 'SELF_PERFORM_GET_HOURLY_PEOPLE_FIELD_VALUES_SUCCESS';
export const SELF_PERFORM_GET_HOURLY_PEOPLE_FIELD_VALUES_FAILURE = 'SELF_PERFORM_GET_HOURLY_PEOPLE_FIELD_VALUES_FAILURE';
export const SELF_PERFORM_GET_HOURLY_PEOPLE_FIELD_VALUES_DISMISS_ERROR = 'SELF_PERFORM_GET_HOURLY_PEOPLE_FIELD_VALUES_DISMISS_ERROR';
export const SELF_PERFORM_GET_REQUEST_BY_ID_BEGIN = 'SELF_PERFORM_GET_REQUEST_BY_ID_BEGIN';
export const SELF_PERFORM_GET_REQUEST_BY_ID_SUCCESS = 'SELF_PERFORM_GET_REQUEST_BY_ID_SUCCESS';
export const SELF_PERFORM_GET_REQUEST_BY_ID_FAILURE = 'SELF_PERFORM_GET_REQUEST_BY_ID_FAILURE';
export const SELF_PERFORM_GET_REQUEST_BY_ID_DISMISS_ERROR = 'SELF_PERFORM_GET_REQUEST_BY_ID_DISMISS_ERROR';
export const SELF_PERFORM_GET_BEST_MATCHES_BEGIN = 'SELF_PERFORM_GET_BEST_MATCHES_BEGIN';
export const SELF_PERFORM_GET_BEST_MATCHES_SUCCESS = 'SELF_PERFORM_GET_BEST_MATCHES_SUCCESS';
export const SELF_PERFORM_GET_BEST_MATCHES_FAILURE = 'SELF_PERFORM_GET_BEST_MATCHES_FAILURE';
export const SELF_PERFORM_GET_BEST_MATCHES_DISMISS_ERROR = 'SELF_PERFORM_GET_BEST_MATCHES_DISMISS_ERROR';
export const SELF_PERFORM_CLEAR_SELECTED_REQUEST = 'SELF_PERFORM_CLEAR_SELECTED_REQUEST';
export const SELF_PERFORM_DEACTIVATE_HOURLY_PERSON_BEGIN = 'SELF_PERFORM_DEACTIVATE_HOURLY_PERSON_BEGIN';
export const SELF_PERFORM_DEACTIVATE_HOURLY_PERSON_SUCCESS = 'SELF_PERFORM_DEACTIVATE_HOURLY_PERSON_SUCCESS';
export const SELF_PERFORM_DEACTIVATE_HOURLY_PERSON_FAILURE = 'SELF_PERFORM_DEACTIVATE_HOURLY_PERSON_FAILURE';
export const SELF_PERFORM_DEACTIVATE_HOURLY_PERSON_DISMISS_ERROR = 'SELF_PERFORM_DEACTIVATE_HOURLY_PERSON_DISMISS_ERROR';
export const SELF_PERFORM_ASSIGN_REQUEST_BEGIN = 'SELF_PERFORM_ASSIGN_REQUEST_BEGIN';
export const SELF_PERFORM_ASSIGN_REQUEST_SUCCESS = 'SELF_PERFORM_ASSIGN_REQUEST_SUCCESS';
export const SELF_PERFORM_ASSIGN_REQUEST_FAILURE = 'SELF_PERFORM_ASSIGN_REQUEST_FAILURE';
export const SELF_PERFORM_ASSIGN_REQUEST_DISMISS_ERROR = 'SELF_PERFORM_ASSIGN_REQUEST_DISMISS_ERROR';
export const SELF_PERFORM_ADD_HOURLY_REQUEST_BEGIN = 'SELF_PERFORM_ADD_HOURLY_REQUEST_BEGIN';
export const SELF_PERFORM_ADD_HOURLY_REQUEST_SUCCESS = 'SELF_PERFORM_ADD_HOURLY_REQUEST_SUCCESS';
export const SELF_PERFORM_ADD_HOURLY_REQUEST_FAILURE = 'SELF_PERFORM_ADD_HOURLY_REQUEST_FAILURE';
export const SELF_PERFORM_ADD_HOURLY_REQUEST_DISMISS_ERROR = 'SELF_PERFORM_ADD_HOURLY_REQUEST_DISMISS_ERROR';
export const SELF_PERFORM_DELETE_REQUEST_BEGIN = 'SELF_PERFORM_DELETE_REQUEST_BEGIN';
export const SELF_PERFORM_DELETE_REQUEST_SUCCESS = 'SELF_PERFORM_DELETE_REQUEST_SUCCESS';
export const SELF_PERFORM_DELETE_REQUEST_FAILURE = 'SELF_PERFORM_DELETE_REQUEST_FAILURE';
export const SELF_PERFORM_DELETE_REQUEST_DISMISS_ERROR = 'SELF_PERFORM_DELETE_REQUEST_DISMISS_ERROR';
export const SELF_PERFORM_UPDATE_HOURLY_REQUEST_BEGIN = 'SELF_PERFORM_UPDATE_HOURLY_REQUEST_BEGIN';
export const SELF_PERFORM_UPDATE_HOURLY_REQUEST_SUCCESS = 'SELF_PERFORM_UPDATE_HOURLY_REQUEST_SUCCESS';
export const SELF_PERFORM_UPDATE_HOURLY_REQUEST_FAILURE = 'SELF_PERFORM_UPDATE_HOURLY_REQUEST_FAILURE';
export const SELF_PERFORM_UPDATE_HOURLY_REQUEST_DISMISS_ERROR = 'SELF_PERFORM_UPDATE_HOURLY_REQUEST_DISMISS_ERROR';
export const SELF_PERFORM_NOTIFICATION_SENT_SUCCESS = 'SELF_PERFORM_NOTIFICATION_SENT_SUCCESS';
export const SELF_PERFORM_SEND_NOTIFICATION_BEGIN = 'SELF_PERFORM_SEND_NOTIFICATION_BEGIN';
export const SELF_PERFORM_SEND_NOTIFICATION_SUCCESS = 'SELF_PERFORM_SEND_NOTIFICATION_SUCCESS';
export const SELF_PERFORM_SEND_NOTIFICATION_FAILURE = 'SELF_PERFORM_SEND_NOTIFICATION_FAILURE';
export const SELF_PERFORM_SEND_NOTIFICATION_DISMISS_ERROR = 'SELF_PERFORM_SEND_NOTIFICATION_DISMISS_ERROR';
export const SELF_PERFORM_REJECT_REQUEST_SUCCESS = 'SELF_PERFORM_REJECT_REQUEST_SUCCESS';
export const SELF_PERFORM_REOPEN_REQUEST_SUCCESS = 'SELF_PERFORM_REOPEN_REQUEST_SUCCESS';
export const SELF_PERFORM_REMOVE_ASSIGNEE_BEGIN = 'SELF_PERFORM_REMOVE_ASSIGNEE_BEGIN';
export const SELF_PERFORM_REMOVE_ASSIGNEE_SUCCESS = 'SELF_PERFORM_REMOVE_ASSIGNEE_SUCCESS';
export const SELF_PERFORM_REMOVE_ASSIGNEE_FAILURE = 'SELF_PERFORM_REMOVE_ASSIGNEE_FAILURE';
export const SELF_PERFORM_REMOVE_ASSIGNEE_DISMISS_ERROR = 'SELF_PERFORM_REMOVE_ASSIGNEE_DISMISS_ERROR';
export const SELF_PERFORM_SEARCH_BEST_MATCHES_SUCCESS = 'SELF_PERFORM_SEARCH_BEST_MATCHES_SUCCESS';
