import { Button, Grid } from '@material-ui/core';
import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import deepEqual from 'react-fast-compare';
import moment from 'moment';
import { getFieldInput } from 'src/utils/miscUtils';
import {
  PERM_WRITE,
  PERM_PERSON,
  PERM_ACCOUNT,
} from 'src/features/permissions/utils/constants';
import { hasModuleEnabled } from 'src/features/permissions/utils/permissionUtils';
import {
  ACCOUNT_MODULE_FINANCIAL_TRACKING,
  DATE_DISPLAY_FORMAT,
  FIELD_TYPE_ADDRESS,
  FIELD_TYPE_BOOLEAN,
  FIELD_TYPE_DATE,
  FIELD_TYPE_LONG_TEXT,
  FIELD_TYPE_MULTI_SELECT,
  FIELD_TYPE_PHONE,
  FIELD_TYPE_PHONE_E164,
  HOME_ADDRESS_FIELD,
} from 'src/common/constants';
import { updatePerson, updatePersonFieldValue } from './redux/actions';
import { Can } from '../wrapped-components';
import { Modal, FieldEditor } from '../common';
import {
  PROFILE_DETAILS_MODAL_CONTEXT,
  PEOPLE_DISPLAY_COST_RATE,
  PEOPLE_DISPLAY_TITLE,
  PEOPLE_DISPLAY_OTHER,
  PEOPLE_DISPLAY_EMPLOYEE_ID,
} from './constants';
import { trackAnalytics } from '../common/redux/actions';
import { PERSON_ADDRESS_UPDATED } from '../../analytics/people/constants';

export class ProfileDetail extends PureComponent {
  static propTypes = {
    person: PropTypes.object.isRequired,
    selectedPersonFields: PropTypes.array.isRequired,
    selectedPersonProjects: PropTypes.array,
    personFields: PropTypes.arrayOf(PropTypes.object).isRequired,
    getFilteredPeoplePending: PropTypes.bool.isRequired,
    allocations: PropTypes.array,
    hasMoreAllocations: PropTypes.bool.isRequired,
    toggleActivation: PropTypes.func.isRequired,
    updatePerson: PropTypes.func.isRequired,
    updatePersonFieldValue: PropTypes.func.isRequired,
    accountId: PropTypes.number.isRequired,
    updatePersonFieldValuePending: PropTypes.bool.isRequired,
    updatePersonPending: PropTypes.bool.isRequired,
    runsInModal: PropTypes.bool,
    trackAnalytics: PropTypes.func.isRequired,
    isAddressSuggestionSelected: PropTypes.bool,
    titleCosts: PropTypes.array.isRequired,
    defaultCostRateFlag: PropTypes.bool.isRequired,
    hasFinancialModuleEnabled: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    selectedPersonProjects: [],
    allocations: [],
    runsInModal: false,
    isAddressSuggestionSelected: false,
  }

  constructor(props) {
    super(props);
    const dataset = this.generateDataset(props.selectedPersonFields, props.personFields, props.person);
    const inputs = this.generateInputs(dataset);
    this.state = {
      dataset,
      inputs,
      showModal: false,
      fieldToEdit: null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selectedPersonFields, person, updatePersonFieldValuePending } = this.props;
    const doneSettingFieldValues = updatePersonFieldValuePending && !nextProps.updatePersonFieldValuePending;
    const personFieldsUpdated = !deepEqual(selectedPersonFields, nextProps.selectedPersonFields);
    const personUpdated = !deepEqual(person, nextProps.person);

    if (doneSettingFieldValues || personFieldsUpdated || personUpdated) {
      const dataset = this.generateDataset(nextProps.selectedPersonFields, nextProps.personFields, nextProps.person);
      const inputs = this.generateInputs(dataset);
      this.setState({
        dataset,
        inputs,
        fieldToEdit: null,
      });
    }
  }

  onSave = (data) => {
    const {
      updatePerson,
      updatePersonFieldValue,
      accountId,
      person,
      runsInModal,
      trackAnalytics,
      isAddressSuggestionSelected,
    } = this.props;

    const { name: personName, id: personId, isActive } = person;
    const { name: fieldName, values, type, value: origValue } = data;
    const fieldValue = values.length > 1 ? values.join(', ') : values[0];
    const originalValue = Array.isArray(origValue) ? origValue?.join(', ') : origValue;

    // If the value wasn't changed, cancel editing
    if (fieldValue === originalValue) {
      this.setState({
        fieldToEdit: null,
      });
      return;
    }

    const baseAnalyticsPayload = {
      personName,
      personId,
      updatedFrom: runsInModal ? PROFILE_DETAILS_MODAL_CONTEXT : 'People List',
    };

    const analyticsPayload = {
      ...baseAnalyticsPayload,
      field: fieldName,
      isActive,
    };

    if (fieldName === 'Title') {
      updatePerson(accountId, personId, { title: values[0] }, analyticsPayload);
    } else {
      if (type === FIELD_TYPE_ADDRESS) {
        const updateAddressPayload = {
          ...baseAnalyticsPayload,
          'Autofill address selected': isAddressSuggestionSelected,
        };

        trackAnalytics(PERSON_ADDRESS_UPDATED, updateAddressPayload);
      }

      updatePersonFieldValue(accountId, personId, data, analyticsPayload);
    }
  }

  generateDataset = (selectedPersonFields, personFields, person) => {
    const preScreenDataName = ['Other'];

    const filteredFields = personFields.filter(
      data => !!data.type && ![FIELD_TYPE_PHONE, FIELD_TYPE_PHONE_E164].includes(data.type) && !preScreenDataName.includes(data.name),
    );

    const customFields = [];
    const systemFields = filteredFields.reduce((collectedSystemFields, field) => {
      if (field.isSystem) {
        collectedSystemFields.push(field);
      } else {
        customFields.push(field);
      }
      return collectedSystemFields;
    }, []);

    const detailFields = [...systemFields, ...customFields];

    const tempOtherDataSets = personFields.filter(({ name }) => name === 'Other');
    const otherData = tempOtherDataSets?.length > 0 ? tempOtherDataSets[0] : null;

    const dataset = detailFields.map((defaultField) => {
      const populatedField = selectedPersonFields && selectedPersonFields.length
        ? selectedPersonFields.find(f => f.fieldId === defaultField.id)
        : {};
      let value;

      if (defaultField.name === 'Title') {
        value = person.title;
      } else if (populatedField && populatedField.values) {
        if (defaultField.type === FIELD_TYPE_MULTI_SELECT) {
          value = populatedField.values;
        } else {
          [value] = populatedField.values;
        }
      }

      return {
        ...defaultField,
        ...populatedField,
        value,
      };
    });

    if (otherData) {
      const populatedOther = selectedPersonFields?.length
        ? selectedPersonFields.find(f => f.name === otherData.name)
        : {};
      dataset.push({
        ...otherData,
        ...populatedOther,
        value: populatedOther?.values?.length
          ? populatedOther.values[0]
          : undefined,
      });
    }

    return dataset;
  }

  showModal = () => {
    this.setState({
      showModal: true,
    });
  }

  hideModal = () => {
    this.setState({
      showModal: false,
    });
  }

  deactivateUser = () => {
    const { toggleActivation, runsInModal } = this.props;
    const parentName = runsInModal ? PROFILE_DETAILS_MODAL_CONTEXT : 'People List';
    toggleActivation(parentName);
    this.hideModal();
  }

  generateInputs = (inputFields) => {
    const inputs = [];
    const exceptedFields = [
      PEOPLE_DISPLAY_TITLE,
      HOME_ADDRESS_FIELD,
      PEOPLE_DISPLAY_OTHER,
      PEOPLE_DISPLAY_COST_RATE,
      PEOPLE_DISPLAY_EMPLOYEE_ID,
    ];

    if (!inputFields || inputFields.length === 0) return [];
    const fields = Array.isArray(inputFields) ? inputFields : [inputFields];
    fields.filter(f => exceptedFields.includes(f.name) || !f.isSystem).forEach(field => inputs.push(getFieldInput(field)));

    return inputs;
  }

  onAddressView = (evt) => {
    evt.stopPropagation();

    const { runsInModal } = this.props;
    const parentName = runsInModal ? PROFILE_DETAILS_MODAL_CONTEXT : 'People List';

    // Mixpanel - Track viewing address on map
    window.mixpanel.track('View Address On A Map', {
      'Address Type': 'Person Address',
      Location: parentName,
    });
  }

  fieldToEdit = (id) => {
    this.setState({ fieldToEdit: id });
  }

  render() {
    const {
      selectedPersonProjects,
      hasMoreAllocations,
      allocations,
      person,
      updatePersonFieldValuePending,
      updatePersonPending,
      getFilteredPeoplePending,
      titleCosts,
      defaultCostRateFlag,
      hasFinancialModuleEnabled,
    } = this.props;
    const { dataset, inputs, showModal, fieldToEdit } = this.state;
    const showUnfilledRoles = selectedPersonProjects?.length > 0 && allocations?.length > 0;
    const isDeactivated = person && !person.isActive && person.state !== 'Active';
    const updatePending = updatePersonPending || getFilteredPeoplePending;
    const defaultTitleCost = titleCosts?.find(({ name }) => name === person.title)?.hourlyCost;

    return (
      <div className="people-profile-detail">
        {showModal && (
          <Modal
            className="deactivate-person-modal"
            headline="Deactivate Person"
            showClose={false}
            buttons={[(
              <Button
                key="cancel"
                disableRipple
                variant="contained"
                size="medium"
                onClick={this.hideModal}
              >
                Cancel
              </Button>
            ), (
              <Button
                key="deactivate"
                className="button-spacing"
                disableRipple
                variant="contained"
                size="medium"
                color="primary"
                onClick={this.deactivateUser}
              >
                Deactivate
              </Button>
            )]}
          >
            <div>
              <div>Are you sure you want to deactivate this person?</div>
              {showUnfilledRoles && (
                <div>
                  {hasMoreAllocations ? 'More than ' : ''}
                  {`${allocations.length} Role${allocations.length > 1 ? 's' : ''} on the following active and upcoming projects will become unfilled:`}
                </div>
              )}
              {showUnfilledRoles && selectedPersonProjects.map(project => (
                <li key={project}>{project}</li>
              ))}
              {hasMoreAllocations && <div>For a detailed list of all impacted projects, please refer to this persons profile before deactivating.</div>}
              <Can
                action={PERM_WRITE}
                subject={PERM_ACCOUNT}
                yes={<div className="form__warning">You can find your deactivated people under Settings &gt; People</div>}
              />
            </div>
          </Modal>
        )}

        <Grid
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          container
          spacing={1}
        >
          {dataset.map((dataItem) => {
            const field = { ...dataItem };
            const checked = field.type === FIELD_TYPE_BOOLEAN && field.value && field.value.toString().toLowerCase() === 'true';
            let showTooltip = false;

            if (field.type === FIELD_TYPE_DATE && field.value && moment(field.value).isValid()) {
              field.value = moment(field.value).format(DATE_DISPLAY_FORMAT);
            }

            if (field.name === PEOPLE_DISPLAY_COST_RATE) {
              if (defaultCostRateFlag) {
                showTooltip = true;

                if (!field.value && hasFinancialModuleEnabled && defaultTitleCost) {
                  field.value = defaultTitleCost;
                  field.hasValueDescription = true;
                }
              }
            }

            const input = inputs.find(i => parseInt(i.name, 10) === field.id);

            return (
              <div key={field.id} className={field.type === FIELD_TYPE_LONG_TEXT && field.name === 'Other' ? 'other-wrapper' : 'item-wrap'}>
                <FieldEditor
                  item={field}
                  checked={checked}
                  input={input}
                  onSave={this.onSave}
                  disabled={fieldToEdit !== null && fieldToEdit !== field.id}
                  permission={{ action: PERM_WRITE, subject: PERM_PERSON }}
                  pending={field.name === 'Title' ? updatePending : updatePersonFieldValuePending}
                  fieldToEdit={this.fieldToEdit}
                  editing={fieldToEdit === field.id}
                  onAddressView={this.onAddressView}
                  showTooltip={showTooltip}
                />
              </div>
            );
          })}
        </Grid>

        {!isDeactivated && !fieldToEdit && (
          <Can
            action={PERM_WRITE}
            subject={PERM_PERSON}
            yes={(
              <div className="deactivate">
                <Button variant="outlined" onClick={this.showModal}>Deactivate Person</Button>
              </div>
            )}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ people, common, wrappedComponents, accounts, accountSettings, launchDarkly }) => {
  const { getFilteredPeoplePending, updatePersonFieldValuePending, updatePersonPending } = people;
  const { accountId } = common;
  const { placeSuggestions: { isAddressSuggestionSelected } } = wrappedComponents;
  const { titleCosts } = accounts;
  const { defaultCostRate } = launchDarkly;

  const hasFinancialModuleEnabled = hasModuleEnabled(accountSettings.accountModules, ACCOUNT_MODULE_FINANCIAL_TRACKING);

  return {
    getFilteredPeoplePending,
    updatePersonFieldValuePending,
    updatePersonPending,
    accountId,
    isAddressSuggestionSelected,
    titleCosts,
    defaultCostRateFlag: defaultCostRate,
    hasFinancialModuleEnabled,
  };
};

const mapDispatchToProps = dispatch => ({
  updatePerson: bindActionCreators(updatePerson, dispatch),
  updatePersonFieldValue: bindActionCreators(updatePersonFieldValue, dispatch),
  trackAnalytics: bindActionCreators(trackAnalytics, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileDetail);
