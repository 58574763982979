import { gql } from 'apollo-boost';
import { generateRequestFilter, generateRequestSort } from 'src/filters/graphqlUtils';
import { PROJECT_SCHEMAS } from 'src/common/descriptors/project';
import { MAX_SIZE } from 'src/common/constants';
import { FILTER_UNPLANNED_PROJECTS } from '../redux/constants';
import { getFilterIssues } from '../../filters/utils/filterUtils';
import { ALL_ISSUES, FILTER_ISSUES } from '../../../filters/constants';

const PROJECT_BREAKDOWN_QUERY = gql`
  query projectBreakdownQuery($accountId: Int!, $structuredSort: [ProjectSort], $from: Int, $size: Int, $filters: [ESQuery], $filterIssues: Int){
    projects(accountId: $accountId, structuredSort: $structuredSort, from: $from, size: $size, filters: $filters, filterIssues: $filterIssues ) {
      hasMore
      cursor
      count
      items {
        id
        state
        name
        startDate
        endDate
        totalRoles
        unfilledRoles
        hasIssues
        colour
        currentPhases {
          name
          subPhases
        }
        roles {
          id
          name
          startDate
          endDate
          isFilled
          sortOrder
          note
          requirements {
            startDate
            endDate
            allocatedPercent: percent
          }
          allocations {
            startDate
            endDate
            personId
            allocatedPercent: percent
          }
          unfilledRanges {
            startDate
            endDate
          }
        }
      }
    }
  }
`;

export const getProjectBreakdownQuery = (accountId, queries = {}, pageFrom, pageSize) => {
  const defaults = {
    size: pageSize || MAX_SIZE,
  };

  const defaultSort = [
    { name: { order: 'asc' } },
    {
      roles: {
        nested: {
          path: 'roles',
          structuredSort: [
            { startDate: { order: 'asc' } },
            { endDate: { order: 'asc' } },
            { sortOrder: { order: 'asc' } },
          ],
        },
      },
    },
  ];

  const { filter, sort } = queries;
  const hasFilterQuery = filter && filter.args;

  const filterIssues = hasFilterQuery ? getFilterIssues(filter.args) : ALL_ISSUES;
  const filteredQuery = hasFilterQuery ? { ...filter, args: filter.args.filter(arg => arg.filterType !== FILTER_ISSUES) } : {};

  const filters = generateRequestFilter(filteredQuery, PROJECT_SCHEMAS).concat(FILTER_UNPLANNED_PROJECTS);
  const structuredSort = generateRequestSort(sort, PROJECT_SCHEMAS, defaultSort);

  const variables = { accountId, filters, filterIssues, structuredSort, from: pageFrom, ...defaults };

  return {
    query: PROJECT_BREAKDOWN_QUERY,
    variables,
  };
};
