import {
  EDIT_DATES_SET_SEGMENT_SELECTED,
} from './constants';
import { EDITABLE_SEGMENT_TYPES } from '../common/constants';
import { getUpdatedSegments } from '../utils/dateEditorUtils';

export function setSegmentSelected(segmentType, segmentId) {
  if (!EDITABLE_SEGMENT_TYPES.includes(segmentType)) return null;

  return {
    type: EDIT_DATES_SET_SEGMENT_SELECTED,
    segmentType,
    segmentId,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case EDIT_DATES_SET_SEGMENT_SELECTED: {
      const { segmentType, segmentId } = action;
      const { segments, typeUnderEdit, offset } = state;

      const updatedSegments = getUpdatedSegments(segments, segmentType, segmentId, typeUnderEdit, offset);

      return {
        ...state,
        segments: {
          ...segments,
          ...updatedSegments,
        },
      };
    }
    default:
      return state;
  }
}
