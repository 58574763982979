import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Delete } from '@material-ui/icons';
import { Button, CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import { PERM_WRITE, PERM_PERSON } from 'src/features/permissions/utils/constants';
import { Modal, ImageCrop } from '../common';
import { MAX_FILE_SIZE, MIN_IMAGE_DIMENSION, MAX_IMAGE_DIMENSION } from './redux/constants';
import { Can } from '../wrapped-components';

export default class AddAvatar extends Component {
  static propTypes = {
    avatar: PropTypes.string,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    showModal: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    compact: PropTypes.bool,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    avatar: '',
    compact: false,
    loading: false,
  }

  constructor(props) {
    super(props);

    this.state = {
      image: null,
      error: null,
    };
  }

  handleDropSuccess = (dropped) => {
    const { showModal } = this.props;
    const file = dropped[0];
    const fr = new FileReader();

    fr.onload = () => {
      const img = new Image();

      img.onload = () => {
        if (img.width < MIN_IMAGE_DIMENSION || img.height < MIN_IMAGE_DIMENSION) {
          this.setState({
            error: 'Your photo is too small to upload. Please select a different image.',
          });
        } else {
          this.setState({
            image: file,
          });
          showModal();
        }
      };

      img.src = fr.result;
    };

    fr.readAsDataURL(file);
  }

  handleDropFail = (file) => {
    if (file && file.length === 1) {
      if (file[0].size > MAX_FILE_SIZE) {
        this.setState({
          error: 'Your photo is too large to upload. It must be smaller than 5MB. Please resize or select a different image.',
        });
      } else {
        this.setState({
          error: 'This file type is not supported. Please select a different image.',
        });
      }
    }
  }

  hideError = () => {
    this.setState({
      error: null,
    });
  }

  onClose = () => {
    const { hideModal } = this.props;
    hideModal();
    this.resetDefaults();
  }

  saveBlob = (blob) => {
    const { onSave } = this.props;
    const img = new Image();

    img.onload = () => {
      if (img.width < MIN_IMAGE_DIMENSION || img.height < MIN_IMAGE_DIMENSION) {
        this.setState({
          error: 'Your photo is too small to upload. Please zoom out or select another image.',
        });
      } else if (img.width > MAX_IMAGE_DIMENSION || img.height > MAX_IMAGE_DIMENSION) {
        this.setState({
          error: 'Your photo is too large to upload. Please zoom in or select another image.',
        });
      } else {
        onSave(blob);
        this.resetDefaults();
      }
    };

    img.src = window.URL.createObjectURL(blob);
  }

  resetDefaults = () => {
    this.setState({
      image: null,
      error: null,
    });
  }

  render() {
    const { avatar, open, onDelete, compact, loading } = this.props;
    const { image, error } = this.state;

    if (loading) {
      return (
        <div className="people-add-avatar">
          <div className="loading">
            <CircularProgress size={25} color="primary" />
          </div>
        </div>
      );
    }

    return (
      <Can
        action={PERM_WRITE}
        subject={PERM_PERSON}
        yes={(
          <div className="people-add-avatar">
            {!!error && (
              <Modal
                headline="Error Uploading"
                closeModal={this.hideError}
                buttons={[(
                  <Button
                    key="ok"
                    color="primary"
                    disableRipple
                    variant="contained"
                    size="medium"
                    onClick={this.hideError}
                  >
                    Okay
                  </Button>
                )]}
              >
                {error}
              </Modal>
            )}

            {!avatar
              ? (
                <Dropzone onDropAccepted={this.handleDropSuccess} onDropRejected={this.handleDropFail} maxSize={MAX_FILE_SIZE} accept="image/*">
                  {({ getRootProps, getInputProps, isDragActive }) => {
                    if (compact) {
                      return (
                        <Tooltip title="Click or drop a photo to upload" placement="bottom">
                          <div {...getRootProps()} className={`drop-container${compact ? ' compact' : ''}${isDragActive ? ' active-drag' : ''}`}>
                            <input {...getInputProps()} />
                            <div className="add-icon" />
                          </div>
                        </Tooltip>
                      );
                    }
                    return (
                      <div {...getRootProps()} className={`drop-container${compact ? ' compact' : ''}${isDragActive ? ' active-drag' : ''}`}>
                        <input {...getInputProps()} />
                        <div className="add-icon" />
                        <span>{isDragActive ? 'Drop a photo to upload' : 'Click or drop a photo to upload'}</span>
                      </div>
                    );
                  }}
                </Dropzone>
              )
              : (
                <div className="avatar-wrap editable">
                  <img src={avatar} className="avatar" alt="avatar" />
                  <div className="hover-gradient">
                    <IconButton className="delete-icon" variant="contained" onClick={onDelete}>
                      <Delete aria-label="Required" />
                    </IconButton>
                  </div>
                </div>
              )}
            {open && !!image && (
              <ImageCrop
                image={image}
                saveBlob={this.saveBlob}
                onClose={this.onClose}
              />
            )}
          </div>
        )}
        no={avatar && (
          <div className="people-add-avatar">
            <div className="avatar-wrap">
              <img src={avatar} className="avatar" alt="avatar" />
            </div>
          </div>
        )}
      />
    );
  }
}
