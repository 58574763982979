import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function AllocationModalContentStructure({
  leftColumnClass,
  rightColumnClass,
  leftContent,
  rightContent,
}) {
  return (
    <div className="allocations-allocation-modal-content-structure">
      <div className={classNames('content', 'left', leftColumnClass)}>
        {leftContent}
      </div>
      <div className={classNames('content', 'right', rightColumnClass)}>
        {rightContent}
      </div>
    </div>
  );
}

AllocationModalContentStructure.propTypes = {
  leftColumnClass: PropTypes.string,
  rightColumnClass: PropTypes.string,
  leftContent: PropTypes.node,
  rightContent: PropTypes.node,
};

AllocationModalContentStructure.defaultProps = {
  leftColumnClass: 'width-100',
  rightColumnClass: 'width-100',
  leftContent: '',
  rightContent: '',
};
