import moment from 'moment';
import deepEqual from 'react-fast-compare';
import Rollbar from 'rollbar';
import { getUrlParam } from 'src/utils/miscUtils';
import { getPermissionsForAccount } from 'src/features/permissions/utils/permissionUtils';
import { expireCookie } from 'src/utils/cookieUtils';
import { RP_AUTH_COOKIE_ID } from 'src/utils/constants';
import {
  PEOPLE_VIEW_SETTINGS_GANTT_DISPLAY_MAP,
  PEOPLE_VIEW_SETTINGS_COLOR_OPTIONS_MAP,
} from 'src/features/gantt/people-gantt/constants';
import { naturalSort } from 'src/utils/sortUtils';
import config from './envConfig';
import flagIcon from '../images/flag-white.svg';
import { DARK_THEME } from '../features/themes/constants';
import { INTERVALS_MAP } from '../features/gantt/constants';
import { SORT_ROLES_BY_DISPLAY_MAP } from '../features/gantt/project-gantt/constants';
import { parseRoleFilters } from '../features/reports/utils/reportUtils';
import { FILLED_ROLES, UNFILLED_ROLES } from '../filters/constants';

export function logRollbar(message, err) {
  if (window.rollbarInstance) {
    window.rollbarInstance.error(message, err);
  } else {
    console.error(message, err); // eslint-disable-line no-console
  }
}

export function initMixpanel() {
  try {
    const source = getUrlParam('source');
    if (config.metricsDomain) {
      window.mixpanel.init(config.mixpanelToken, { api_host: `https://${config.metricsDomain}` }); // eslint-disable-line camelcase
    } else {
      window.mixpanel.init(config.mixpanelToken);
    }

    window.mixpanel.register({
      'Account Name': null,
      'Account ID': null,
      Role: null,
      Source: source,
      'Web Version': config.version,
    });
  } catch (err) {
    window.mixpanel = {
      init: () => {},
      track: () => {},
      people: {
        set: () => {},
        delete_user: () => {}, // eslint-disable-line camelcase
      },
      identify: () => {},
      register: () => {},
    };
  }
}

export function initRollbar() {
  if (config.rollbarKey) {
    const rollbarConfig = {
      accessToken: config.rollbarKey,
      captureUncaught: true,
      captureUnhandledRejections: true,
      environment: config.environment,
      payload: {
        client: {
          javascript: {
            source_map_enabled: false, // eslint-disable-line camelcase
            guess_uncaught_frames: true, // eslint-disable-line camelcase
            code_version: config.version, // eslint-disable-line camelcase
          },
        },
      },
    };

    window.rollbarInstance = new Rollbar(rollbarConfig);
  }
}

export function setAccountInfo({ name, id, expiryDate = null }) {
  // Set account values for mixpanel
  window.mixpanel.register({
    'Account Name': name,
    'Account ID': id,
  });

  // Link account to user
  window.mixpanel.people.set({
    'Account Name': name,
  });

  if (window.rollbarInstance) {
    window.rollbarInstance.configure({
      'Account Name': name,
      'Account ID': id,
    });
  }

  if (window.Appcues && !!expiryDate) {
    window.Appcues.track('Trial Account');
  }
}

export function setUserInfo(userInfo, accountId = null, accountModules = null) {
  if (userInfo?.sub) {
    window.mixpanel.identify(userInfo.email);

    let group;
    if (userInfo?.permissions) {
      if (accountId) {
        const permissions = getPermissionsForAccount(accountId, userInfo.permissions);
        group = permissions?.group ? permissions.group : null;
      } else {
        const values = Object.values(userInfo.permissions);
        group = (values.length && values[0].group) ? values[0].group : null;
      }
    }

    // Set user values for mixpanel
    window.mixpanel.people.set({
      $name: userInfo.name,
      $email: userInfo.email,
      $created: userInfo.createdOn,
      Title: userInfo.title,
      Team: userInfo.team,
      'Current Role': group,
    });

    // Set user role value for mixpanel
    window.mixpanel.register({
      Role: group,
    });

    if (window.Appcues) {
      // strip out permissions object for Appcues
      const { permissions, ...appcuesUserInfo } = userInfo;

      window.Appcues.identify(userInfo.email, {
        ...appcuesUserInfo,
        'Current Role': group,
        AccountID: accountId,
        'Account Modules': accountModules,
      });

      if (config.environment?.toLowerCase() === 'development') {
        window.Appcues.loadLaunchpad('#appcues-launchpad', {
          icon: flagIcon,
          position: 'left',
          header: '<div class="appcues-custom-header">Bench Announcements</div>',
        });
      }
    }

    if (window.rollbarInstance) {
      window.rollbarInstance.configure({
        payload: {
          person: {
            id: userInfo.sub,
            username: userInfo.name,
            email: userInfo.email,
          },
        },
      });
    }
  }
}

export function clearUserInfo() {
  window.mixpanel.track('Logout');

  // Clear people profile
  window.mixpanel.people.delete_user();

  // Clear the super props
  window.mixpanel.register({
    'Account Name': null,
    'Account ID': null,
    Role: null,
  });

  // If using session auth, we will have an auth cookie accessible to js; expire it.
  expireCookie(RP_AUTH_COOKIE_ID);

  if (window.rollbarInstance) {
    window.rollbarInstance.configure({
      payload: {
        person: {},
      },
    });
  }
}

function mapRoles(roles) {
  return naturalSort(roles.map(alloc => (
    {
      allocatedPercent: alloc.allocatedPercent,
    }
  )), 'allocatedPercent');
}

export function trackProjectRoleUpdate(role, updatedRole, project, parentName) {
  const percentages = role && role.requirements ? mapRoles(role.requirements) : [];
  const updatedPercentages = updatedRole && updatedRole.allocations ? mapRoles(updatedRole.allocations) : [];
  const roleNoteAdded = !role.note && !!updatedRole.note;
  const roleNoteDeleted = !!role.note && !updatedRole.note;
  const roleNoteUpdated = !roleNoteDeleted && !!role.note && role.note !== updatedRole.note;

  if (roleNoteDeleted) {
    window.mixpanel.track('Role Note Deleted', {
      'Project Name': project.name,
      'Project ID': project.id,
      'Project status': project.state,
      'Project type': project.type,
      'Role name': updatedRole.name,
      'Role ID': role.id,
      'Role note': role.note,
      'Role note deleted from': parentName,
    });
  } else {
    window.mixpanel.track('Role Updated on Project', {
      'Project Name': project.name,
      'Project ID': project.id,
      'Project status': project.state,
      'Project type': project.type,
      'Role name': updatedRole.name,
      'Role ID': role.id,
      'Role Type Updated': updatedRole.name !== role.name,
      'Role Start Date Updated': !moment(updatedRole.startDate).isSame(role.startDate),
      'Role End Date Updated': !moment(updatedRole.endDate).isSame(role.endDate),
      'Role Allocation % Updated': !deepEqual(percentages, updatedPercentages),
      'Role is updated from': parentName,
      'Role note added': roleNoteAdded,
      'Role note updated': roleNoteUpdated,
      'Role note': updatedRole.note,
    });
  }
}

export function updateMixpanelPeopleGanttProps(displayOptions = {}, ganttDisplay, colorOption) {
  window.mixpanel.people.set({
    'People Gantt display set': PEOPLE_VIEW_SETTINGS_GANTT_DISPLAY_MAP[ganttDisplay],
    'People Gantt Project color set': PEOPLE_VIEW_SETTINGS_COLOR_OPTIONS_MAP[colorOption],
    'People Gantt Title displayed': !!displayOptions.title,
    'People Gantt Photo displayed': !!displayOptions.photo,
  });
}

export function setProjectGanttAnalyticProps(timeInterval, viewRoles, roleSortOption, roleFilters, rolesLength) {
  const { nameFilters, hasFilledFilter, hasUnfilledFilter } = parseRoleFilters(roleFilters);
  const selectedCount = nameFilters?.length;
  const isSingleFilter = (hasFilledFilter && !hasUnfilledFilter) || (!hasFilledFilter && hasUnfilledFilter);
  const roleFilterName = hasFilledFilter ? FILLED_ROLES : UNFILLED_ROLES;

  window.mixpanel.people.set({
    'Project Gantt Time Interval': INTERVALS_MAP[timeInterval],
    'Project Gantt View Roles': viewRoles,
    'Project Gantt Role Sort': SORT_ROLES_BY_DISPLAY_MAP[roleSortOption],
    'Project Gantt Role Filters': isSingleFilter ? roleFilterName : null,
    'Project Gantt Role Name Filters': selectedCount > 0 && selectedCount !== rolesLength ? nameFilters.join(', ') : null,
  });
}

export function updateMixpanelThemeProps(themeName) {
  window.mixpanel.people.set({
    Theme: themeName === DARK_THEME ? 'Dark Mode' : 'Light Mode',
  });
}

export function updateMixpanelSWPViewPreferenceProps(view) {
  window.mixpanel.people.set('SWP view preference', view);
}

export function trackTimeOffClick(personName, personId, clickedFrom, timeOffLength = null, overlap = null) {
  window.mixpanel.track('Time off with no backfill clicked', {
    'Person name': personName,
    'Person id': personId,
    'Clicked from': clickedFrom,
    ...(!!timeOffLength && { 'Time off length (days)': timeOffLength }),
    ...(!!overlap && { 'Overlap with allocations': overlap }),
  });
}
