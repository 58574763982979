import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'react-fast-compare';
import { Grid, Typography, Input, FormHelperText, ClickAwayListener, CircularProgress } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { validateEmail } from 'src/utils/validators';
import { EditControls } from '../wrapped-components';

export default class PersonEmailEditor extends PureComponent {
  static propTypes = {
    originalEmail: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    updatePersonError: PropTypes.object,
    updatePersonPending: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    updatePersonError: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      email: props.originalEmail,
      error: null,
      valid: validateEmail(props.originalEmail),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { updatePersonError, updatePersonPending, originalEmail } = this.props;

    if (updatePersonPending && !nextProps.updatePersonPending) {
      if (!deepEqual(updatePersonError, nextProps.updatePersonError)) {
        if (nextProps.updatePersonError) {
          this.handleError(nextProps.updatePersonError.status);
        }
      } else {
        this.closeEditor();
      }
    }

    if (!deepEqual(originalEmail, nextProps.originalEmail)) {
      this.setState({ email: nextProps.originalEmail });
    }
  }

  handleError = (errorCode) => {
    const message = {
      400: 'Enter a valid email',
      409: 'Email address must be unique',
    };

    this.setState({ error: message[errorCode] || 'Something went wrong' });
  }

  onEditEmail = () => {
    this.setState({ editing: true });
  }

  cancelEditing = () => {
    const { originalEmail } = this.props;
    this.setState({
      editing: false,
      email: originalEmail,
      valid: validateEmail(originalEmail),
      error: null,
    });
  }

  closeEditor = () => {
    this.setState({
      editing: false,
    });
  }

  checkEnter = (e) => {
    const { email } = this.state;
    if (e.keyCode === 13 && email) {
      this.save();
    }
  }

  onChange = (e) => {
    const { value } = e.currentTarget;
    const email = value.trim();

    this.setState({
      email,
      valid: validateEmail(email),
      error: !email ? 'Please enter an email' : null,
    });
  }

  save = () => {
    const { onSave, originalEmail } = this.props;
    const { email, valid } = this.state;

    if (email === originalEmail) {
      this.cancelEditing();
    } else if (valid) {
      onSave(email);
    } else {
      this.handleError(400);
    }
  }

  render() {
    const { updatePersonPending } = this.props;
    const { email, editing, error } = this.state;

    return (
      <>
        {editing
          ? (
            <ClickAwayListener onClickAway={this.cancelEditing}>
              <div className="people-person-email-editor email-editor">
                { updatePersonPending
                  ? (
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                    >
                      <CircularProgress size={25} color="primary" />
                    </Grid>
                  )
                  : (
                    <Grid item>
                      <Typography className="content-item content-title" component="span" gutterBottom variant="caption">Email</Typography>
                      <Input
                        fullWidth
                        autoFocus
                        className="input--underline"
                        value={email}
                        inputProps={{ maxLength: 60 }}
                        onChange={this.onChange}
                        onKeyUp={this.checkEnter}
                      />
                      <div className="controls">
                        {error && (
                          <FormHelperText className="form__warning">{error}</FormHelperText>
                        )}
                        <EditControls
                          disabled={!!error}
                          primaryAction={this.save}
                          secondaryAction={this.cancelEditing}
                        />
                      </div>
                    </Grid>
                  )}
              </div>
            </ClickAwayListener>
          )
          : (
            <button
              type="button"
              className="people-person-email-editor email-editor"
              onClick={this.onEditEmail}
            >
              <Grid item>
                <Typography className="content-item content-title" component="span" gutterBottom variant="caption">Email</Typography>
                <Typography className="content-item" component="span" gutterBottom>{email}</Typography>
              </Grid>
              <div className="edit-icon">
                <Edit />
              </div>
            </button>
          )}
      </>
    );
  }
}
