const initialState = {
  permissionGroups: [],
  getPermissionGroupsPending: false,
  getPermissionGroupsError: null,
  createPermissionGroupPending: false,
  createPermissionGroupError: null,
  updatePermissionGroupPending: false,
  updatePermissionGroupError: null,
  deletePermissionGroupPending: false,
  deletePermissionGroupError: null,
};

export default initialState;
