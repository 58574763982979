import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, FormSpy } from 'react-final-form';
import { EditControls } from '@bridgit/foundation';
import { ClickAwayListener } from '@material-ui/core';
import classnames from 'classnames';

import { OTHER } from './constants';
import { isEmptyObject } from '../../utils/miscUtils';
import { ProfileTeamInput } from '.';

const EditProfileTeamForm = ({ disabled, setFieldEditing, onSave }) => {
  const { defaultTeams, userTeam } = useSelector(({ login }) => {
    const { defaultTeams, userInfo: { team } } = login;

    return {
      defaultTeams,
      userTeam: team,
    };
  });

  const isTeamDefault = useMemo(() => defaultTeams.includes(userTeam), [defaultTeams, userTeam]);

  const submitHandler = useCallback(({ team, teamName }) => {
    const teamPayload = team === OTHER ? teamName : team;

    onSave({ id: 'team', name: 'team', values: [teamPayload] });
  }, [onSave]);

  const dirtyChangeHandler = useCallback(({ dirty }) => {
    const fieldEditing = dirty ? 'team' : null;

    setFieldEditing(fieldEditing);
  }, [setFieldEditing]);

  return (
    <Form
      onSubmit={submitHandler}
      initialValues={{
        team: isTeamDefault ? userTeam : OTHER,
        teamName: isTeamDefault ? null : userTeam,
      }}
      render={({ values, dirty, handleSubmit, form, errors }) => (
        <ClickAwayListener mouseEvent="onMouseUp" onClickAway={form.reset}>
          <div className="edit-profile-team-form">
            <div className="team-form-fields">
              <ProfileTeamInput values={values} disabled={disabled} fieldClassName="team-form-field" />
            </div>

            {dirty && (
              <EditControls
                primaryAction={handleSubmit}
                secondaryAction={form.reset}
                className={classnames('team-form-controls', { 'is-team-name-visible': values.team === OTHER })}
                disabled={!isEmptyObject(errors)}
              />
            )}

            <FormSpy
              subscription={{ dirty: true }}
              onChange={dirtyChangeHandler}
            />
          </div>
        </ClickAwayListener>
      )}
    />
  );
};

EditProfileTeamForm.propTypes = {
  disabled: PropTypes.bool,
  setFieldEditing: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

EditProfileTeamForm.defaultProps = {
  disabled: false,
};

export default EditProfileTeamForm;
