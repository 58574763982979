import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function CapsuleBar({
  className,
  primaryColorCode,
  style,
  children,
}) {
  const barStyles = {
    ...(primaryColorCode && { backgroundColor: primaryColorCode }),
    ...style,
  };

  return (
    <div
      className={classNames('common-capsule-bar', className)}
      style={barStyles}
    >
      {children}
    </div>
  );
}

CapsuleBar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  primaryColorCode: PropTypes.string,
  style: PropTypes.object,
};

CapsuleBar.defaultProps = {
  children: null,
  className: '',
  primaryColorCode: '',
  style: null,
};
