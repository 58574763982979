import { call, put, takeLatest } from 'redux-saga/effects';
import {
  SELF_PERFORM_UPDATE_HOURLY_PERSON_FIELD_VALUE_BEGIN,
  SELF_PERFORM_UPDATE_HOURLY_PERSON_FIELD_VALUE_SUCCESS,
  SELF_PERFORM_UPDATE_HOURLY_PERSON_FIELD_VALUE_FAILURE,
  SELF_PERFORM_UPDATE_HOURLY_PERSON_FIELD_VALUE_DISMISS_ERROR,
} from './constants';
import { protectedJsonPatch } from '../../../utils/api';
import { updateHourlyPersonField } from '../utils/hourlyPersonUtils';

export function updateHourlyPersonFieldValue(accountId, hourlyPersonId, fieldId, data, hourlyPersonName, updatedFrom) {
  return {
    type: SELF_PERFORM_UPDATE_HOURLY_PERSON_FIELD_VALUE_BEGIN,
    accountId,
    hourlyPersonId,
    fieldId,
    data,
    updatedFrom,
    hourlyPersonName,
  };
}

export function dismissUpdateHourlyPersonFieldValueError() {
  return {
    type: SELF_PERFORM_UPDATE_HOURLY_PERSON_FIELD_VALUE_DISMISS_ERROR,
  };
}

export function* doUpdateHourlyPersonFieldValue(action) {
  const { accountId, hourlyPersonId, fieldId, data, updatedFrom, hourlyPersonName } = action;
  try {
    yield call(protectedJsonPatch, `accounts/${accountId}/hourly-persons/${hourlyPersonId}/person-field-values/${fieldId}`, data);
  } catch (err) {
    yield put({
      type: SELF_PERFORM_UPDATE_HOURLY_PERSON_FIELD_VALUE_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: SELF_PERFORM_UPDATE_HOURLY_PERSON_FIELD_VALUE_SUCCESS,
    data,
    fieldId,
    hourlyPersonId,
    updatedFrom,
    hourlyPersonName,
  });
}

export function* watchUpdateHourlyPersonFieldValue() {
  yield takeLatest(SELF_PERFORM_UPDATE_HOURLY_PERSON_FIELD_VALUE_BEGIN, doUpdateHourlyPersonFieldValue);
}

export function reducer(state, action) {
  switch (action.type) {
    case SELF_PERFORM_UPDATE_HOURLY_PERSON_FIELD_VALUE_BEGIN:
      return {
        ...state,
        updateHourlyPersonFieldValuePending: true,
        updateHourlyPersonFieldValueError: null,
      };

    case SELF_PERFORM_UPDATE_HOURLY_PERSON_FIELD_VALUE_SUCCESS: {
      const { data, fieldId, hourlyPersonId } = action;
      const updatedHourlyPersonFieldValues = state.hourlyPersonFieldValues.map(
        field => (field.fieldId === fieldId
          ? { ...field, values: data.values }
          : field),
      );

      const updatedSelectedHourlyPerson = updateHourlyPersonField(state.selectedHourlyPerson, data);

      const updateFilteredHourlyPersons = state.filteredHourlyPersons.map((person) => {
        if (person.id === hourlyPersonId) {
          return { ...person, ...updatedSelectedHourlyPerson };
        }

        return person;
      });

      return {
        ...state,
        hourlyPersonFieldValues: updatedHourlyPersonFieldValues,
        selectedHourlyPerson: updatedSelectedHourlyPerson,
        filteredHourlyPersons: updateFilteredHourlyPersons,
        updateHourlyPersonFieldValuePending: false,
        updateHourlyPersonFieldValueError: null,
      };
    }

    case SELF_PERFORM_UPDATE_HOURLY_PERSON_FIELD_VALUE_FAILURE:
      return {
        ...state,
        updateHourlyPersonFieldValuePending: false,
        updateHourlyPersonFieldValueError: action.data.error,
      };

    case SELF_PERFORM_UPDATE_HOURLY_PERSON_FIELD_VALUE_DISMISS_ERROR:
      return {
        ...state,
        updateHourlyPersonFieldValueError: null,
      };

    default:
      return state;
  }
}
