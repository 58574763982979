import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ClickAwayListener, LinearProgress } from '@material-ui/core';
import moment from 'moment';
import classnames from 'classnames';

import { DATE_INPUT_FORMAT, TIME_OFF } from '../../common/constants';
import { trackTimeOffClick } from '../../common/analyticsHelper';
import { PURSUIT } from '../projects/constants';
import { backoffRetry } from '../../utils/api';
import { momentToString, getDurationDays } from '../../utils/dateUtils';
import { infiniteScrollWatcher } from '../../utils/uiUtils';
import { getPersonAllocationsByDate } from '../people/redux/actions';
import {
  InfoPopper,
  BlackoutInfo,
  RoleCandidate,
  AllocationInfo,
  CandidateListDistanceHeading,
} from '.';

export class CandidateList extends PureComponent {
  static propTypes = {
    accountId: PropTypes.number.isRequired,
    filteredPeople: PropTypes.array.isRequired,
    selectedPerson: PropTypes.object,
    getPersonAllocationsByDate: PropTypes.func.isRequired,
    onHideModal: PropTypes.func.isRequired,
    onSelectPerson: PropTypes.func.isRequired,
    roleStart: PropTypes.instanceOf(moment).isRequired,
    roleEnd: PropTypes.instanceOf(moment).isRequired,
    getFilteredRoleAvailabilitiesPending: PropTypes.bool.isRequired,
    loadMore: PropTypes.func.isRequired,
    loadingPage: PropTypes.bool,
    pagingError: PropTypes.bool,
    pageFrom: PropTypes.number,
    hasMore: PropTypes.bool,
    roleRequirements: PropTypes.arrayOf(PropTypes.object).isRequired,
    runsInModal: PropTypes.bool,
    roleName: PropTypes.string.isRequired,
    roleId: PropTypes.number.isRequired,
    project: PropTypes.object.isRequired,
    roleAvailabilities: PropTypes.array,
  };

  static defaultProps = {
    selectedPerson: null,
    loadingPage: false,
    pagingError: false,
    pageFrom: 0,
    hasMore: false,
    runsInModal: false,
    roleAvailabilities: [],
  };

  constructor(props) {
    super(props);
    const { selectedPerson } = props;

    this.peopleList = null;

    this.setPeopleListRef = (element) => {
      this.peopleList = element;
    };

    this.retryHandler = backoffRetry(this.loadAvailabilities);

    this.state = {
      activePersonId: selectedPerson ? selectedPerson.id : null,
      selectedBlackoutId: null,
      allocationPopperOpen: false,
      blackoutPopperOpen: false,
      isListScrollable: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { pagingError, loadingPage, getFilteredRoleAvailabilitiesPending } = this.props;
    const { allocationPopperOpen, blackoutPopperOpen } = this.state;

    this.getIsListScrollable();

    // If loading begins and either popper is open, clean poppers up
    if (!prevProps.getFilteredRoleAvailabilitiesPending
      && getFilteredRoleAvailabilitiesPending
      && (allocationPopperOpen || blackoutPopperOpen)) {
      this.onPopperClose();
    }


    if ((!prevProps.pagingError && pagingError) || (pagingError && prevProps.loadingPage && !loadingPage)) {
      this.retryHandler.retry();
    } else if (prevProps.pagingError && !pagingError) {
      this.retryHandler.resetTries();
    }
  }

  componentWillUnmount() {
    this.retryHandler.stopTrying();
  }

  getIsListScrollable() {
    if (!this.peopleList) return;

    const isListScrollable = this.peopleList?.scrollHeight > this.peopleList?.clientHeight;
    this.setState({
      isListScrollable,
    });
  }

  indicatorSegmentRef = null;

  retryHandler = null;

  loadAvailabilities = () => {
    const { hasMore, loadingPage, pageFrom, loadMore } = this.props;

    if (hasMore && !loadingPage) {
      loadMore(pageFrom);
    }
  };

  onScroll = (element) => {
    const { loadingPage, pagingError } = this.props;
    infiniteScrollWatcher(element, this.loadAvailabilities, loadingPage, pagingError);
  };

  onPopperClose = () => {
    this.indicatorSegmentRef = null;

    this.setState({
      allocationPopperOpen: false,
      blackoutPopperOpen: false,
      activePersonId: null,
      selectedBlackoutId: null,
    });
  };

  onAvailabilityClick = (segmentData, personId) => {
    const {
      accountId,
      getPersonAllocationsByDate,
      project,
      roleName,
      roleId,
      filteredPeople,
    } = this.props;
    const { segmentRef, startDate, endDate, unavailable, periodId, rangeType } = segmentData;
    const stateUpdates = { activePersonId: personId };
    const person = filteredPeople.find(person => person.id === personId);

    if (unavailable) {
      stateUpdates.selectedBlackoutId = periodId;
      stateUpdates.blackoutPopperOpen = true;

      if (rangeType === TIME_OFF) {
        const timeOff = person?.unavailabilities?.find(unavailability => unavailability.id === periodId);

        trackTimeOffClick(
          person?.name,
          personId,
          'PAPM',
          getDurationDays(timeOff?.actualStart, timeOff?.actualEnd),
        );
      }
    } else {
      // Load the availability for this specific user and timeframe
      getPersonAllocationsByDate(accountId, personId, startDate, endDate);
      stateUpdates.allocationPopperOpen = true;

      window.mixpanel.track('Place a Person - Allocation bar selected', {
        'Project name': project.name,
        'Project id': project.id,
        'Project status': project.state,
        'Project type': project.type,
        'Role name': roleName,
        'Role id': roleId,
        'Person name': person?.name,
        'Person id': personId,
      });
    }

    this.indicatorSegmentRef = segmentRef;

    this.setState(stateUpdates);
  };

  onPursuitsClick = (anchorEl, personId) => {
    const { accountId, roleStart, roleEnd, getPersonAllocationsByDate } = this.props;
    this.indicatorSegmentRef = anchorEl;
    getPersonAllocationsByDate(accountId, personId, momentToString(roleStart), momentToString(roleEnd), PURSUIT);
    this.setState({
      activePersonId: personId,
      allocationPopperOpen: true,
    });
  };

  render() {
    const {
      filteredPeople,
      selectedPerson,
      roleStart,
      roleEnd,
      loadingPage,
      pagingError,
      getFilteredRoleAvailabilitiesPending,
      onHideModal,
      onSelectPerson,
      roleRequirements,
      runsInModal,
      roleName,
      roleId,
      project,
      roleAvailabilities,
    } = this.props;

    const {
      activePersonId,
      selectedBlackoutId,
      allocationPopperOpen,
      blackoutPopperOpen,
      isListScrollable,
    } = this.state;

    const popperOpen = allocationPopperOpen || blackoutPopperOpen;

    if (!getFilteredRoleAvailabilitiesPending && (!filteredPeople || filteredPeople.length === 0)) {
      return (
        <div className="allocations-candidate-list people-empty">
          <div className="people-empty-text-wrapper">
            <p>There are no people matching your role criteria.</p>
            <p>Try updating your filters or searching for a person by name.</p>
          </div>
        </div>
      );
    }

    if (getFilteredRoleAvailabilitiesPending) {
      return (
        <LinearProgress className="loading-progress-bar" variant="query" color="primary" />
      );
    }

    const person = roleAvailabilities?.find(avail => avail.id === activePersonId);
    const blackout = person?.unavailabilities?.find(unavail => unavail.id === selectedBlackoutId);

    return (
      <div className="allocations-candidate-list">
        <div className={classnames('people-heading', { 'people-heading-for-overflow-list': isListScrollable })}>
          <span className="section-heading">Name</span>
          <span className="section-heading">Time Availability</span>
          <div className="section-heading distance-from-project">
            <CandidateListDistanceHeading />
          </div>
        </div>

        <div className="people-list-container" onScroll={this.onScroll} ref={this.setPeopleListRef}>
          {filteredPeople.map((p) => {
            const selected = selectedPerson && selectedPerson.id === p.id;
            return (
              <RoleCandidate
                key={p.id}
                selected={selected}
                roleStart={moment(roleStart, DATE_INPUT_FORMAT)}
                roleEnd={moment(roleEnd, DATE_INPUT_FORMAT)}
                person={p}
                onClick={onSelectPerson}
                onAvailabilityClick={this.onAvailabilityClick}
                roleRequirements={roleRequirements}
                onPursuitsClick={this.onPursuitsClick}
                roleName={roleName}
                roleId={roleId}
                project={project}
              />
            );
          })}

          { (loadingPage || pagingError) && (
            <div className="page-progress-container">
              <LinearProgress variant="query" className="progress" />
            </div>
          )}

          <ClickAwayListener onClickAway={this.onPopperClose} mouseEvent={popperOpen ? 'onClick' : false}>
            <div>
              <InfoPopper
                popperTargetRef={this.indicatorSegmentRef}
                popperOpen={popperOpen}
                onPopperClose={this.onPopperClose}
                onHideModal={onHideModal}
              >
                { allocationPopperOpen && (
                <AllocationInfo
                  personId={activePersonId}
                  runsInModal={runsInModal}
                />
                )}

                { blackoutPopperOpen && (
                <BlackoutInfo
                  startDate={blackout?.actualStart}
                  endDate={blackout?.actualEnd}
                  description={blackout?.description}
                  rangeType={blackout?.rangeType}
                  isPrivate={blackout?.isPrivate}
                />
                )}

              </InfoPopper>
            </div>
          </ClickAwayListener>
        </div>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps({ common, allocations }) {
  const { accountId } = common;
  const {
    getFilteredRoleAvailabilitiesPending,
    getFilteredRoleAvailabilitiesPagePending,
    getFilteredRoleAvailabilitiesPageError,
    hasMore,
    pageFrom,
    roleAvailabilities,
  } = allocations;

  const loadingPage = getFilteredRoleAvailabilitiesPagePending;
  const pagingError = !!getFilteredRoleAvailabilitiesPageError;

  return {
    accountId,
    getFilteredRoleAvailabilitiesPending,
    loadingPage,
    pagingError,
    hasMore,
    pageFrom,
    roleAvailabilities,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    getPersonAllocationsByDate: bindActionCreators(getPersonAllocationsByDate, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CandidateList);
