import initialState from './initialState';
import { reducer as inviteAdminReducer } from './inviteAdmin';
import { reducer as removeAdminReducer } from './removeAdmin';
import { reducer as getAdminsReducer } from './getAdmins';
import { reducer as updateAdminReducer } from './updateAdmin';
import { reducer as removeAdminInvitationReducer } from './removeAdminInvitation';
import { reducer as getAdminInvitationsReducer } from './getAdminInvitations';
import { reducer as updateUserMembershipReducer } from './updateUserMembership';

const reducers = [inviteAdminReducer, removeAdminReducer, getAdminsReducer, updateAdminReducer, removeAdminInvitationReducer, getAdminInvitationsReducer, updateUserMembershipReducer];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
