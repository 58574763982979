import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { AuditLogItem, ListWithLoader } from '@bridgit/foundation';
import classNames from 'classnames';

import { getProjectAudit } from './redux/actions';
import {
  getProjectLogEventName,
  getProjectAuditItemExpandedAnalytics,
  renderEventDetails,
} from './utils/projectAuditUtils';
import { trackAnalytics } from '../common/redux/actions';
import { MULTI_STATE_MODAL_ID } from '../common/redux/constants';
import { PROJECT_LIST_SELECTION_ID } from './redux/constants';
import { splitByUpperCase } from '../../utils/miscUtils';
import { PROJECT_ACTIVITY_ENTRY_EXPANDED } from '../../analytics/projects/constants';

export const ProjectAudit = ({
  headerLabel,
}) => {
  const dispatch = useDispatch();

  const isOpenInModal = useSelector(({ modalManager: { activeModal } }) => activeModal === MULTI_STATE_MODAL_ID);
  const selectedProject = useSelector(({ projects: { projectSelections } }) => (
    isOpenInModal
      ? projectSelections?.[MULTI_STATE_MODAL_ID]
      : projectSelections?.[PROJECT_LIST_SELECTION_ID]
  ));
  const { accountId } = useSelector(({ common }) => common);
  const { getProjectAuditPending } = useSelector(({ projects }) => projects);
  const {
    hasMore: hasMoreProjectLogItems,
    cursor: pageFrom = 0,
    items: projectLogItems = [],
  } = useSelector(({ projects: { projectAudit } }) => projectAudit || {});

  useEffect(() => {
    if (selectedProject?.id) dispatch(getProjectAudit(accountId, selectedProject.id));
  }, [accountId, selectedProject?.id, dispatch]);

  const loadMoreItems = useCallback(
    () => hasMoreProjectLogItems && selectedProject?.id && dispatch(getProjectAudit(accountId, selectedProject?.id, pageFrom)),
    [accountId, hasMoreProjectLogItems, pageFrom, selectedProject?.id, dispatch],
  );

  const handleProjectAuditItemExpanded = (isExpanded, eventData) => {
    const analyticsPayload = getProjectAuditItemExpandedAnalytics(isExpanded, eventData, selectedProject, isOpenInModal);

    dispatch(trackAnalytics(PROJECT_ACTIVITY_ENTRY_EXPANDED, analyticsPayload));
  };

  const renderProjectLogItems = ({ userName, eventOn, actionType, resourceType, eventData }) => {
    const eventName = getProjectLogEventName(resourceType, actionType);

    return (
      <AuditLogItem
        key={`${userName || 'Unknown'} ${eventOn}`}
        userName={userName || 'Unknown'}
        actionType={eventName || splitByUpperCase(actionType)}
        eventOn={eventOn}
        details={renderEventDetails(eventName, eventData)}
        onClick={handleProjectAuditItemExpanded}
        resourceType={resourceType}
      />
    );
  };

  return (
    <div className={classNames('projects-project-audit', { 'projects-project-audit-in-modal': isOpenInModal })}>
      {headerLabel && <div className="projects-project-audit-header">{headerLabel}</div>}

      <ListWithLoader
        items={projectLogItems}
        renderItems={renderProjectLogItems}
        loadMore={loadMoreItems}
        isLoading={getProjectAuditPending}
        isLoadingPage={getProjectAuditPending}
        className={getProjectAuditPending ? 'projects-project-audit-list-loading' : 'projects-project-audit-list'}
      />
    </div>
  );
};

ProjectAudit.propTypes = {
  headerLabel: PropTypes.string,
};

ProjectAudit.defaultProps = {
  headerLabel: '',
};

export default ProjectAudit;
