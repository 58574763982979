import { call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { protectedJsonPatch } from '../../../utils/api';
import {
  PROJECTS_UPDATE_PROJECT_BEGIN,
  PROJECTS_UPDATE_PROJECT_SUCCESS,
  PROJECTS_UPDATE_PROJECT_FAILURE,
  PROJECTS_UPDATE_PROJECT_DISMISS_ERROR,
} from './constants';
import { getUpdatedEntity } from '../../../filters/graphqlUtils';
import { updatePhases, updateRoles, getProjectUpdateType } from '../utils/projectUtils';

export function updateProject(accountId, projectId, data, initialProject = {}, parentName = '', updateType = null) {
  return {
    type: PROJECTS_UPDATE_PROJECT_BEGIN,
    accountId,
    projectId,
    data,
    initialProject,
    parentName,
    updateType,
  };
}

export function dismissUpdateProjectError() {
  return {
    type: PROJECTS_UPDATE_PROJECT_DISMISS_ERROR,
  };
}

export function* doUpdateProject(action) {
  const { accountId, projectId, data, initialProject, parentName, updateType } = action;

  let res;
  try {
    res = yield call(protectedJsonPatch, `accounts/${accountId}/projects/${projectId}`, data);
  } catch (err) {
    yield put({
      type: PROJECTS_UPDATE_PROJECT_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PROJECTS_UPDATE_PROJECT_SUCCESS,
    data: res,
    expandPhases: data.shiftDates || data.expandPhases,
    expandRole: data.shiftDates || data.expandRole,
    projectId,
    mixpanelEvent: updateType ? `TRACK_PROJECT_UPDATE_${updateType.toUpperCase()}` : null,
    analyticsPayload: { initialProject, parentName },
  });
}

export function* watchUpdateProject() {
  yield takeLatest(PROJECTS_UPDATE_PROJECT_BEGIN, doUpdateProject);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PROJECTS_UPDATE_PROJECT_BEGIN:
      return {
        ...state,
        updateProjectPending: true,
        updateProjectError: null,
      };

    case PROJECTS_UPDATE_PROJECT_SUCCESS: {
      const { data, expandPhases, expandRole, projectId } = action;
      const { projects, filteredProjects, projectSelections } = state;

      // Update projects
      const newProjects = projects.map((project) => {
        if (project.id === data.id) {
          return {
            ...project,
            ...data,
          };
        }
        return project;
      });

      // Update filteredProjects
      const newFilteredProjects = getUpdatedEntity('Project', filteredProjects, data);

      const oldProject = projects.find(project => project.id === data.id);

      if (oldProject) {
        const oldStartDate = moment(oldProject.startDate);
        const oldEndDate = moment(oldProject.endDate);
        const sameStartDate = oldStartDate.isSame(data.startDate);
        const sameEndDate = oldEndDate.isSame(data.endDate);

        const updateType = getProjectUpdateType(oldStartDate, oldEndDate, data);
        const projectDates = { endDate: oldEndDate, startDate: oldStartDate };

        if (!sameStartDate || !sameEndDate) {
          // phases need to be updated
          const currentProject = newFilteredProjects.find(project => project.id === data.id);
          if (currentProject) {
            if (currentProject.phases && currentProject.phases.length > 0) {
              currentProject.phases = updatePhases(projectDates, expandPhases, currentProject.phases, updateType, data);
            }

            if (currentProject.roles && currentProject.roles.length > 0) {
              currentProject.roles = updateRoles(projectDates, expandRole, currentProject.roles, updateType, data);
            }
          }
        }
      }

      const newProjectSelections = { ...projectSelections };
      Object.keys(newProjectSelections).forEach((selectionId) => {
        const selectedProject = newProjectSelections[selectionId];
        if (selectedProject && selectedProject.id === projectId) {
          const { startDate, endDate } = selectedProject;
          const selectedProjectDates = {
            startDate: moment(startDate),
            endDate: moment(endDate),
          };
          const updateType = getProjectUpdateType(startDate, endDate, data);
          if (selectedProject.phases && selectedProject.phases.length > 0) {
            selectedProject.phases = updatePhases(selectedProjectDates, expandPhases, selectedProject.phases, updateType, data);
          }

          if (selectedProject.roles && selectedProject.roles.length > 0) {
            selectedProject.roles = updateRoles(selectedProjectDates, expandRole, selectedProject.roles, updateType, data);
          }
          newProjectSelections[selectionId] = {
            ...selectedProject,
            ...data,
          };
        }
      });

      return {
        ...state,
        projects: newProjects,
        filteredProjects: newFilteredProjects,
        updateProjectPending: false,
        updateProjectError: null,
        projectSelections: newProjectSelections,
      };
    }

    case PROJECTS_UPDATE_PROJECT_FAILURE:
      return {
        ...state,
        updateProjectPending: false,
        updateProjectError: action.data.error,
      };

    case PROJECTS_UPDATE_PROJECT_DISMISS_ERROR:
      return {
        ...state,
        updateProjectError: null,
      };

    default:
      return state;
  }
}
