const getChangeColumnOrderEvent = view => `${view} List - Change Column`;

function getChangeColumnPayload(newColumnNames = [], oldColumns = []) {
  const oldColumnNames = oldColumns.filter(col => !oldColumns.includes(col.name)).map(col => col.name);

  const columnsAdded = newColumnNames.filter((newName) => {
    const wasVisible = oldColumnNames.find(oldName => oldName === newName);
    return !wasVisible;
  });

  const columnsRemoved = oldColumnNames.filter((oldName) => {
    const isStillVisible = newColumnNames.find(newName => newName === oldName);
    return !isStillVisible;
  });

  const details = {};

  if (columnsAdded.length) {
    details['Column added'] = columnsAdded.join(', ');
  }

  if (columnsRemoved.length) {
    details['Column removed'] = columnsRemoved.join(', ');
  }

  return details;
}

export {
  getChangeColumnOrderEvent,
  getChangeColumnPayload,
};
