import {
  COMMON_SET_SELECTED_PERSON_ID,
  COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PERSON,
} from './constants';

export function setSelectedPersonId(selectedPersonId, showBackButton = false, analyticsPayload = null) {
  return {
    type: COMMON_SET_SELECTED_PERSON_ID,
    selectedPersonId,
    showBackButton,
    analyticsPayload,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_SET_SELECTED_PERSON_ID: {
      const { selectedPersonId, showBackButton } = action;
      const { multiStateModal } = state;
      return {
        ...state,
        multiStateModal: {
          ...multiStateModal,
          selectedPersonId,
          activeView: COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PERSON,
          showBackButton,
        },
      };
    }

    default:
      return state;
  }
}
