import React, { useState, useMemo, useCallback, useEffect } from 'react';
import {
  NavigateBack,
  PopperButton,
  RoleTemplateListItem,
  EditControls,
  b500bridgitBlue,
} from '@bridgit/foundation';
import PropTypes from 'prop-types';
import { TextField, Divider } from '@material-ui/core';
import deepEqual from 'react-fast-compare';
import { useDispatch } from 'react-redux';
import FilterList from '../filters/FilterList';
import { addRoleTemplate } from '../accounts/redux/addRoleTemplate';
import { updateRoleTemplate } from '../accounts/redux/updateRoleTemplate';
import { ROLE_TEMPLATE_NAME_MAX_LENGTH } from './common/constants';

export const EditRoleTemplate = ({
  accountId,
  onBackClick,
  roles,
  editedTemplate,
  roleTemplates,
}) => {
  const [templateRoles, setTemplateRoles] = useState(editedTemplate?.roles || []);
  const [templateName, setTemplateName] = useState(editedTemplate?.name || '');
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!editedTemplate) {
      setTemplateRoles([]);
      setTemplateName('');
    }
  }, [editedTemplate]);

  const isDuplicateTemplateName = !!roleTemplates.filter(({ name }) => {
    if (name === editedTemplate?.name) return false;
    return name === templateName;
  }).length;

  const onChangeHandler = useCallback((changes, index) => {
    const formattedChanges = { ...changes, role: { name: changes.role.name } };

    if (!deepEqual(formattedChanges, templateRoles[index])) {
      const updatedTemplateRoles = [...templateRoles];

      updatedTemplateRoles.splice(index, 1, formattedChanges);

      setTemplateRoles(updatedTemplateRoles);
    }
  }, [templateRoles]);

  const onTemplateNameChangeHandler = event => setTemplateName(event.target.value);

  const onDeleteRoleHandler = useCallback((index) => {
    const templateRolesCopy = [...templateRoles];
    templateRolesCopy.splice(index, 1);

    setTemplateRoles(templateRolesCopy);
  }, [templateRoles]);

  const onClickHandler = useCallback(isOpen => setIsOpen(isOpen), []);

  const onSelectHandler = useCallback((role) => {
    const roleWithValues = { quantity: 1, role, allocationPercentage: 100 };

    setTemplateRoles([...templateRoles, roleWithValues]);
    setIsOpen(false);
  }, [templateRoles]);

  const onSaveHandler = () => {
    const formattedTemplateRoles = templateRoles.map(({ allocationPercentage, quantity, role: { name } }) => (
      {
        roleName: name,
        percent: allocationPercentage,
        quantity,
      }
    ));

    const newRoleTemplate = {
      name: templateName,
      data: formattedTemplateRoles,
    };

    if (editedTemplate?.id) {
      dispatch(updateRoleTemplate(accountId, newRoleTemplate, editedTemplate.id));
    } else {
      dispatch(addRoleTemplate(accountId, newRoleTemplate));
    }

    onBackClick();
  };

  const renderAddRoleButton = useMemo(() => (
    <div className="add-role-button">
      <PopperButton
        classes="add-role-popper"
        buttonText="Add Role"
        buttonVariant="contained"
        isOpen={isOpen}
        onClick={onClickHandler}
      >
        <FilterList
          columns={roles}
          onFilterSelect={onSelectHandler}
          alphabetize={false}
          placeholder="Search roles"
        />
      </PopperButton>
    </div>
  ), [isOpen, onClickHandler, onSelectHandler, roles]);

  const renderEditingRoles = useMemo(() => templateRoles.map(({ role, quantity, allocationPercentage }, index) => (
    /* eslint-disable react/no-array-index-key */
    <RoleTemplateListItem
      key={index}
      roleValue={role}
      quantityValue={quantity}
      allocationValue={allocationPercentage}
      roles={roles}
      onChange={changes => onChangeHandler(changes, index)}
      onRemoveClick={() => onDeleteRoleHandler(index)}
      maxQuantity={100}
    />
  )), [onChangeHandler, onDeleteRoleHandler, roles, templateRoles]);

  const renderRolesTable = useMemo(() => {
    if (!templateRoles.length) {
      return (
        <div className="no-roles-message">
          <p>No roles have been added to this template</p>
        </div>
      );
    }
    return (
      <div className="template-body">
        <div className="table-header">
          <p className="quantity-column">Quantity</p>
          <p className="role-column">Role</p>
          <p className="allocation-column">Allocation %</p>
        </div>
        <div className="role-list">
          {renderEditingRoles}
        </div>
      </div>
    );
  }, [renderEditingRoles, templateRoles.length]);

  const errorHelperText = (!templateName && '* Required') || (isDuplicateTemplateName && '* Template name must be unique');

  const hasEditedTemplateChanged = !(editedTemplate?.name === templateName && deepEqual(templateRoles, editedTemplate?.roles));

  const isSaveDisabled = !templateRoles.length || !templateName || isDuplicateTemplateName || (editedTemplate?.id && !hasEditedTemplateChanged);

  return (
    <div className="account-settings-edit-role-template">
      <div className="edit-template">
        <NavigateBack onClick={onBackClick} label="Back to role templates list" />
        <TextField
          className="name-input"
          variant="standard"
          placeholder="Template Name"
          value={templateName}
          onChange={onTemplateNameChangeHandler}
          helperText={errorHelperText}
          FormHelperTextProps={{ style: { color: !templateName && b500bridgitBlue } }}
          inputProps={{ maxLength: ROLE_TEMPLATE_NAME_MAX_LENGTH }}
          error={isDuplicateTemplateName}
        />
        <Divider className="divider" />
        {renderAddRoleButton}
        {renderRolesTable}
      </div>
      <div className="footer">
        <Divider className="divider" />
        <EditControls
          className="controls"
          primaryAction={onSaveHandler}
          secondaryAction={() => onBackClick(false)}
          disabled={isSaveDisabled}
        />
      </div>
    </div>
  );
};

EditRoleTemplate.propTypes = {
  accountId: PropTypes.number.isRequired,
  onBackClick: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  editedTemplate: PropTypes.shape({
    name: PropTypes.string,
    roles: PropTypes.array,
    id: PropTypes.number,
  }),
  roleTemplates: PropTypes.array,
};

EditRoleTemplate.defaultProps = {
  editedTemplate: null,
  roleTemplates: [],
};

export default EditRoleTemplate;

