import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { collectAppPrerequisites } from './redux/actions';
import { setUserInfo } from '../../common/analyticsHelper';
import { hasWebPerms } from '../permissions/utils/permissionUtils';
import { AccessDenied } from '../permissions';

const MainContent = ({
  children,
}) => {
  const dispatch = useDispatch();

  const { accountId, collectAppPrerequisitesPending } = useSelector(({ common }) => common);
  const { userInfo } = useSelector(({ login }) => login);
  const { accountModules } = useSelector(({ accountSettings }) => accountSettings);
  const { isLDReady } = useSelector(({ launchDarkly }) => launchDarkly);

  const hasWebPermissions = useMemo(
    () => hasWebPerms(accountId, userInfo?.permissions),
    [accountId, userInfo?.permissions],
  );

  useEffect(() => {
    if (accountId) {
      dispatch(collectAppPrerequisites(accountId));
    }
  }, [accountId, dispatch]);

  useEffect(() => {
    setUserInfo(userInfo, accountId, accountModules);
  }, [accountId, accountModules, userInfo]);

  // If critical data is still loading or Launch Darkly isn't initialized, do not attempt to render the app
  if (collectAppPrerequisitesPending || !isLDReady) return null;

  // If user does not have permissions for web (ie. Self Perform Requester ) return access denied
  if (!hasWebPermissions) {
    return (
      <div className="common-main-content-web-access-denied">
        <AccessDenied
          message="You do not have permission to access this Bench account on the web. Please log in to the Bench app on your mobile device."
        />
      </div>
    );
  }

  return (
    <div className="common-main-content">
      { children }
    </div>
  );
};

MainContent.propTypes = {
  children: PropTypes.node,
};

MainContent.defaultProps = {
  children: null,
};

export default MainContent;
