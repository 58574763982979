import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Add } from '@material-ui/icons';
import { withRouter } from 'react-router';
import deepEqual from 'react-fast-compare';
import { apiFormatPhoneNumber } from 'src/utils/miscUtils';
import { validateBasicInputs, validateEmail } from 'src/utils/validators';
import { PERM_PERSON, PERM_WRITE } from 'src/features/permissions/utils/constants';
import * as actions from './redux/actions';
import { AddAvatar } from '.';
import { FloatingActionButton, ValidatedForm, Can, EditControls } from '../wrapped-components';
import { Modal, Confirm } from '../common';
import { generateInputs } from './utils/addPersonModalUtils';
import { FIELD_TYPE_PHONE_E164, FIELD_TYPE_PHONE } from '../../common/constants';
import { formatPhoneNumberInput } from '../../utils/phoneNumberUtils';

export class AddPersonModal extends Component {
  static propTypes = {
    accountId: PropTypes.number.isRequired,
    actions: PropTypes.object.isRequired,
    people: PropTypes.object.isRequired,
    personFields: PropTypes.array.isRequired,
    modalOrigin: PropTypes.string,
    redirectToPerson: PropTypes.func,
  };

  static defaultProps = {
    modalOrigin: '',
    redirectToPerson: () => {},
  }

  constructor(props) {
    super(props);

    const { personFields } = props;
    const { requiredInputs, optionalInputs } = generateInputs(personFields);

    this.state = {
      open: false,
      requiredInputs,
      optionalInputs,
      isValid: false,
      values: {},
      localAvatar: null,
      avatarBlob: null,
      avatarOpen: false,
      uploading: false,
      errors: {},
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { personFields, people } = this.props;
    const { localAvatar } = this.state;
    let stateUpdates = {};

    if (!deepEqual(personFields, nextProps.personFields)) {
      const { requiredInputs, optionalInputs } = generateInputs(nextProps.personFields);
      stateUpdates = {
        requiredInputs,
        optionalInputs,
      };
    }

    const updateError = nextProps.people.addPersonError;
    const wasLoading = people.addPersonPending && !nextProps.people.addPersonPending;
    const uploadFinished = people.setProfilePicturePending && !nextProps.people.setProfilePicturePending;

    if (updateError && updateError.status === 409) {
      stateUpdates.uploading = false;
      stateUpdates.errors = { email: 'This email is in use by a deactivated person' };
    } else if (uploadFinished) {
      stateUpdates.open = false;
      stateUpdates.uploading = false;
      stateUpdates.localAvatar = null;
      stateUpdates.avatarBlob = null;
      stateUpdates.errors = {};
      stateUpdates.values = {};
    } else if (wasLoading && !localAvatar) {
      stateUpdates.open = false;
      stateUpdates.uploading = false;
      stateUpdates.errors = {};
      stateUpdates.values = {};
    }

    this.setState(stateUpdates);
  }

  showModal = () => {
    this.setState({
      open: true,
    });
  }

  hideModal = () => {
    this.setState({
      open: false,
      isValid: false,
      localAvatar: null,
      avatarBlob: null,
      errors: {},
    });
  }

  addPerson = () => {
    const { accountId, personFields, actions, modalOrigin, redirectToPerson } = this.props;
    const { values, uploading, requiredInputs } = this.state;
    const fields = [];
    const titleId = requiredInputs.find(i => i.label === 'Title').name;
    const excludedFields = ['email', 'name', titleId, 'startDate', 'endDate'];

    if (uploading) {
      return;
    }

    this.setState({
      uploading: true,
    }, () => {
      Object.keys(values).filter(v => !excludedFields.includes(v)).forEach((key) => {
        if (values[key]) {
          const fieldId = Number(key);
          const { name, type, isPrivate } = personFields.find(f => f.id === fieldId);
          const field = { fieldId, name, type, isPrivate };
          if (Array.isArray(values[key])) {
            field.values = values[key];
          } else if (type === 'Date') {
            const dateString = values[key].toJSON().split('T')[0];
            field.values = [dateString];
          } else if (type === FIELD_TYPE_PHONE) {
            field.values = [apiFormatPhoneNumber(values[key])];
          } else if (type === FIELD_TYPE_PHONE_E164) {
            field.values = [formatPhoneNumberInput(values[key])];
          } else {
            field.values = [values[key]];
          }

          fields.push(field);
        }
      });

      const { name, email, startDate, endDate } = values;
      const data = {
        name,
        email,
        title: values[titleId],
        fields: fields.length ? fields : [],
        employmentDates: {},
      };
      const dateUpdated = [];

      if (startDate) {
        data.employmentDates.startDate = startDate;
        dateUpdated.push('Hire');
      }
      if (endDate) {
        data.employmentDates.endDate = endDate;
        dateUpdated.push('Termination');
      }

      if (dateUpdated.length) {
        // Mixpanel - Track updating employment dates
        window.mixpanel.track('Person Hire/Termination Date Added or Updated', {
          'Date updated': dateUpdated.join(' and '),
          Location: 'New person modal',
        });
      }

      actions.addPerson(accountId, data, modalOrigin, (person) => {
        const { localAvatar, avatarBlob } = this.state;
        const { id, name } = person;

        if (localAvatar) {
          actions.getUploadUrl(accountId, id, (url) => {
            const analyticsPayload = {
              personName: name,
              personId: id,
              uploadedFrom: 'Create a Person modal',
            };
            this.uploadImage(url, avatarBlob, id, localAvatar, analyticsPayload);
          });
        }

        redirectToPerson(null, { name, id });
      });
    });
  }

  uploadImage = (uploadUrl, imageBlob, selectedPersonId, blobUrl, analyticsPayload) => {
    const { actions } = this.props;
    actions.setProfilePicture(uploadUrl, imageBlob, selectedPersonId, blobUrl, analyticsPayload);
  }

  onAddAvatar = (image) => {
    this.setState({
      localAvatar: window.URL.createObjectURL(image),
      avatarBlob: image,
      avatarOpen: false,
    });
  }

  onDeleteAvatar = () => {
    this.setState({
      localAvatar: null,
    });
  }

  showAvatar = () => {
    this.setState({
      avatarOpen: true,
    });
  }

  hideAvatar = () => {
    this.setState({
      avatarOpen: false,
    });
  }

  validate = (changedValues) => {
    const { people } = this.props;
    const { requiredInputs, optionalInputs, values } = this.state;
    const newValues = { ...values, ...changedValues };
    const inputsToValidate = [
      ...requiredInputs,
      ...optionalInputs,
    ];
    const errors = validateBasicInputs(inputsToValidate, newValues);

    if (!validateEmail(newValues.email)) {
      errors.email = 'Please enter a valid email';
    }

    if (people.entities.map(p => p.email).includes(newValues.email)) {
      errors.email = 'This email has already been added to this account';
    }

    const { startDate, endDate } = newValues;
    if (startDate && endDate && startDate >= endDate) {
      errors.endDate = 'Must be later than the Hire Date';
    }

    this.setState({
      isValid: !Object.keys(errors).length,
      values: newValues,
    });

    return errors;
  }

  render() {
    const { actions, people } = this.props;
    const {
      open,
      requiredInputs,
      optionalInputs,
      isValid,
      localAvatar,
      avatarOpen,
      uploading,
      errors,
    } = this.state;

    const optionalNoAvatar = optionalInputs.filter(ele => ele.name !== 'avatar');
    const error = people.addPersonError;

    return (
      <div className="people-add-person-modal">
        <Can
          action={PERM_WRITE}
          subject={PERM_PERSON}
          yes={(
            <FloatingActionButton
              icon={<Add />}
              tooltipText="Add a Person"
              onClick={this.showModal}
            />
          )}
        />

        {open && (
          <Modal
            headline="New Person"
            showClose={false}
            classes={{ root: `common-modal add-person-modal${avatarOpen ? ' hide' : ''}` }}
            buttons={[
              <div key="buttons" className="people-add-person-modal-buttons">
                <EditControls
                  primaryText="Add Person"
                  primaryAction={this.addPerson}
                  secondaryAction={this.hideModal}
                  disabled={!isValid}
                  pending={uploading}
                />
              </div>,
            ]}
          >
            <>
              <AddAvatar
                avatar={localAvatar}
                onSave={this.onAddAvatar}
                onDelete={this.onDeleteAvatar}
                open={avatarOpen}
                showModal={this.showAvatar}
                hideModal={this.hideAvatar}
              />
              <div className="input-container required">
                <div className="input-header required">Required Fields</div>
                <ValidatedForm
                  inputs={requiredInputs}
                  validate={this.validate}
                  errors={errors}
                />
              </div>
              <div className="input-container">
                <div className="input-header">Other Fields</div>
                <ValidatedForm
                  inputs={optionalNoAvatar}
                  validate={this.validate}
                  errors={errors}
                />
              </div>
            </>
          </Modal>
        )}

        {
          error?.data?.errors?.[0]?.errorType === 'MissingRequiredField' &&
          (
            <Confirm
              headline="Unable to add person"
              acceptButtonText="Okay"
              onAccept={actions.dismissAddPersonError}
              showSecondary={false}
            >
              <p>You are unable to add this person to your account due to a required, private field that you do not have access to.</p>
            </Confirm>
          )
        }
      </div>
    );
  }
}


/* istanbul ignore next */
function mapStateToProps({ people, accountSettings, common }) {
  return {
    people,
    personFields: accountSettings.personFields,
    accountId: common.accountId,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AddPersonModal));
