import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CheckboxForm, RadioChipForm } from 'src/features/common';
import { setFilterQuery } from 'src/features/queries/redux/actions';
import { setGanttConfig } from 'src/features/gantt/redux/actions';
import { PeopleZoomConfig } from 'src/features/gantt/utils/ganttUtils';
import {
  FILTER_STORAGE_KEY,
  SLIDER_STORAGE_KEY,
  DISPLAY_OPTIONS_KEY,
  GANTT_DISPLAY_KEY,
  COLOR_OPTION_KEY,
  getStorageKey,
} from 'src/common/localStorageKeys';
import { quickFilterValues } from 'src/features/gantt/utils/peopleGanttUtils';
import { capitalize } from 'src/utils/formatters';
import { PEOPLE_VIEW } from 'src/common/constants';
import {
  FILTER_AVAILABILITIES,
  FILTER_ISSUES,
  NEXT_AVAILABILITY,
  FUTURE_AVAILABILITY,
} from 'src/filters/constants';
import { FILTERED_PEOPLE_QUERY_ID } from 'src/features/queries/redux/constants';
import { PEOPLE_GANTT_CONFIG_KEY } from '../redux/constants';
import {
  PEOPLE_VIEW_SETTINGS_TIME_INTERVAL,
  PEOPLE_VIEW_SETTINGS_GANTT_DISPLAY,
  PEOPLE_VIEW_SETTINGS_DISPLAY_OPTIONS,
  PEOPLE_VIEW_SETTINGS_FILTER_OPTIONS,
  PEOPLE_VIEW_SETTINGS_GANTT_DISPLAY_MAP,
  PEOPLE_VIEW_SETTINGS_UTILIZATION,
  PEOPLE_VIEW_SETTINGS_COLOR_OPTIONS,
  PEOPLE_VIEW_SETTINGS_COLOR_OPTIONS_MAP,
} from './constants';
import { MIXPANEL_EVENTS } from '../mixpanelEvents';
import { getPeopleFilterSummary } from '../../filters/utils/filterUtils';

const {
  TIME_SEGMENT_CHANGED,
  GANTT_SETTINGS,
  PROJECT_BAR_COLOR,
} = MIXPANEL_EVENTS;

function PeopleGanttViewSettings({
  accountId,
  userId,
  scaleValue,
  displayOptions,
  ganttDisplay,
  colorOption,
  queries,
  setGanttConfig,
  setFilterQuery,
}) {
  const filterOptions = useMemo(() => quickFilterValues(queries), [queries]);

  const onTimeIntervalChange = useCallback((scaleValue) => {
    const zoomConfig = PeopleZoomConfig[scaleValue];
    const sliderStorageKey = getStorageKey(PEOPLE_VIEW, accountId, SLIDER_STORAGE_KEY, userId);
    setGanttConfig(PEOPLE_GANTT_CONFIG_KEY, { scaleValue }, sliderStorageKey);
    window.mixpanel.track(TIME_SEGMENT_CHANGED, {
      'Time Segment Selected': zoomConfig.label.toLowerCase(),
    });
  }, [accountId, setGanttConfig, userId]);

  const onGanttDisplayChange = useCallback((ganttDisplay) => {
    const ganttDisplayKey = getStorageKey(PEOPLE_VIEW, accountId, GANTT_DISPLAY_KEY, userId);
    setGanttConfig(PEOPLE_GANTT_CONFIG_KEY, { ganttDisplay }, ganttDisplayKey);
    window.mixpanel.track(GANTT_SETTINGS, {
      'Display Option selected': PEOPLE_VIEW_SETTINGS_GANTT_DISPLAY_MAP[ganttDisplay],
    });
  }, [accountId, setGanttConfig, userId]);

  const onFilterOptionChange = useCallback((name) => {
    const onAvailableFilter = () => {
      const { availableFilter } = filterOptions;
      const filterStorageKey = getStorageKey(PEOPLE_VIEW, accountId, FILTER_STORAGE_KEY, userId);
      const newArgs = queries && queries.args && queries.args.length
        ? queries.args.filter(arg => arg.column !== NEXT_AVAILABILITY)
        : [];
      const newQuery = {
        name: 'filter',
        args: [...newArgs],
      };

      if (!availableFilter) {
        newQuery.args.push({
          activeFilters: [{
            name: 'Has availability',
            verb: '',
            value: FUTURE_AVAILABILITY,
          }],
          column: NEXT_AVAILABILITY,
          label: 'Availability',
          filterType: FILTER_AVAILABILITIES,
        });

        window.mixpanel.track('Filter on People', {
          'View filtered on': 'People Gantt',
          'Property filtered by': 'Availability',
          'Filter type used': 'Gantt quick option',
          'Value filtered by': 'has availability',
          ...getPeopleFilterSummary(newQuery.args),
        });
      }

      setFilterQuery(FILTERED_PEOPLE_QUERY_ID, newQuery, filterStorageKey);
    };

    const onOverAllocationFilter = () => {
      const { issuesFilter } = filterOptions;
      const filterStorageKey = getStorageKey(PEOPLE_VIEW, accountId, FILTER_STORAGE_KEY, userId);
      const conflictQuery = queries && queries.args && queries.args.length
        ? queries.args.find(arg => arg.column === 'hasConflict')
        : null;
      const newArgs = queries && queries.args && queries.args.length
        ? queries.args.filter(arg => arg.column !== 'hasConflict')
        : [];
      const newQuery = {
        name: 'filter',
        args: [...newArgs],
      };
      let activeFilters = conflictQuery ? [...conflictQuery.activeFilters] : [];

      if (issuesFilter) {
        activeFilters = activeFilters.filter(fil => !fil.value);
      } else {
        activeFilters.push({
          name: 'Has allocation issue',
          value: true,
        });
      }

      if (activeFilters.length) {
        newQuery.args.push({
          activeFilters,
          column: 'hasConflict',
          label: 'Issues',
          filterType: FILTER_ISSUES,
        });
      }

      if (!issuesFilter) {
        window.mixpanel.track('Filter on People', {
          'View filtered on': 'People Gantt',
          'Property filtered by': 'Issues',
          'Filter type used': 'Gantt quick option',
          ...getPeopleFilterSummary(newQuery.args),
        });
      }

      setFilterQuery(FILTERED_PEOPLE_QUERY_ID, newQuery, filterStorageKey);
    };

    if (name === 'availableFilter') {
      onAvailableFilter();
    }
    if (name === 'issuesFilter') {
      onOverAllocationFilter();
    }
  }, [accountId, filterOptions, queries, setFilterQuery, userId]);

  const onDisplayOptionChange = useCallback((name, checked) => {
    const newDisplayOptions = {
      ...displayOptions,
      [name]: checked,
    };
    const displayOptionsKey = getStorageKey(PEOPLE_VIEW, accountId, DISPLAY_OPTIONS_KEY, userId);
    setGanttConfig(PEOPLE_GANTT_CONFIG_KEY, { displayOptions: newDisplayOptions }, displayOptionsKey);

    const displayType = `${capitalize(name)}s`;
    window.mixpanel.track(`People Gantt - Display ${displayType}`, {
      [`${displayType} displayed`]: checked,
    });
  }, [accountId, setGanttConfig, userId, displayOptions]);

  const onColorOptionChange = useCallback((colorOption) => {
    const colorOptionKey = getStorageKey(PEOPLE_VIEW, accountId, COLOR_OPTION_KEY, userId);
    setGanttConfig(PEOPLE_GANTT_CONFIG_KEY, { colorOption }, colorOptionKey);
    window.mixpanel.track(PROJECT_BAR_COLOR, {
      'Project colours selected': PEOPLE_VIEW_SETTINGS_COLOR_OPTIONS_MAP[colorOption],
    });
  }, [accountId, setGanttConfig, userId]);

  const isColorOptionsVisible = ganttDisplay !== PEOPLE_VIEW_SETTINGS_UTILIZATION;

  return (
    <div className="people-gantt-view-settings">
      <div className="app-side-slide-header">Gantt Settings</div>
      <div className="view-settings-option">
        <RadioChipForm
          title="Time interval"
          controls={PEOPLE_VIEW_SETTINGS_TIME_INTERVAL}
          onChange={onTimeIntervalChange}
          defaultValue={scaleValue}
        />
      </div>
      <div className="view-settings-option">
        <RadioChipForm
          title="Gantt display options"
          controls={PEOPLE_VIEW_SETTINGS_GANTT_DISPLAY}
          onChange={onGanttDisplayChange}
          defaultValue={ganttDisplay}
        />
      </div>
      { isColorOptionsVisible && (
        <div className="view-settings-option">
          <RadioChipForm
            title="Project color options"
            controls={PEOPLE_VIEW_SETTINGS_COLOR_OPTIONS}
            onChange={onColorOptionChange}
            defaultValue={colorOption}
          />
        </div>
      )}
      <div className="view-settings-option">
        <CheckboxForm
          title="People display options"
          controls={PEOPLE_VIEW_SETTINGS_DISPLAY_OPTIONS}
          onChange={onDisplayOptionChange}
          checkedList={displayOptions}
        />
      </div>
      <div className="view-settings-option">
        <CheckboxForm
          title="Quick filters"
          controls={PEOPLE_VIEW_SETTINGS_FILTER_OPTIONS}
          onChange={onFilterOptionChange}
          checkedList={filterOptions}
        />
      </div>
    </div>
  );
}

PeopleGanttViewSettings.propTypes = {
  accountId: PropTypes.number.isRequired,
  userId: PropTypes.string.isRequired,
  scaleValue: PropTypes.number.isRequired,
  displayOptions: PropTypes.object.isRequired,
  ganttDisplay: PropTypes.string.isRequired,
  colorOption: PropTypes.string.isRequired,
  queries: PropTypes.object,
  setGanttConfig: PropTypes.func.isRequired,
  setFilterQuery: PropTypes.func.isRequired,
};

PeopleGanttViewSettings.defaultProps = {
  queries: {},
};

/* istanbul ignore next */
function mapStateToProps({ common, login, gantt, queries }) {
  const { accountId } = common;
  const { sub: userId } = login.userInfo;
  const {
    scaleValue,
    displayOptions,
    ganttDisplay,
    colorOption,
  } = gantt.instances[PEOPLE_GANTT_CONFIG_KEY];
  const { filteredPeople } = queries;
  return {
    accountId,
    userId,
    scaleValue,
    displayOptions,
    ganttDisplay,
    colorOption,
    queries: filteredPeople?.filter,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    setGanttConfig: bindActionCreators(setGanttConfig, dispatch),
    setFilterQuery: bindActionCreators(setFilterQuery, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PeopleGanttViewSettings);
