import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, CircularProgress } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import { unique } from 'src/utils/miscUtils';
import { isAuthorized } from 'src/features/permissions/utils/permissionUtils';
import { PERM_WRITE, PERM_PRIVATE } from 'src/features/permissions/utils/constants';
import { naturalSort } from 'src/utils/sortUtils';
import { dateStringAdd, momentToString, stringToMoment } from 'src/utils/dateUtils';
import { RadioChipForm, LongTooltip } from '@bridgit/foundation';
import classNames from 'classnames';
import {
  addUnavailability,
  updateUnavailability,
  deleteUnavailability,
  getPersonAllocationsByDate,
  getAccountAllocations,
} from './redux/actions';
import { Modal, DatePicker, CancelButton, Confirm } from '../common';
import { FormField, Can } from '../wrapped-components';
import { PrivateToggle } from '../account-settings';
import { validateUnavailability } from './utils/unavailabilityUtils';
import {
  DEFAULT_POU_START,
  DEFAULT_POU_END,
  MIN_DATA_RANGE_IN_MONTHS,
  MAX_DATA_RANGE_IN_YEARS,
  MAX_CONFIGURABLE_DATE,
  MIN_API_CONFIGURABLE_DATE,
  MIN_API_CONFIGURABLE_DATE_MESSAGE,
  TIME_OFF,
  UNAVAILABILITY,
} from '../../common/constants';
import {
  TIME_OFF_BACKFILL_CONTROLS,
  TIME_OFF_BACKFILL_LABEL,
  TIME_OFF_BACKFILL_CONTROL_VALUES,
  TIME_OFF_BACKFILL_TOOLTIP,
} from './constants';

export const ManageUnavailability = ({
  person,
  onCloseModal,
  addUnavailability,
  updateUnavailability,
  deleteUnavailability,
  accountId,
  userInfo,
  activePou,
  pous,
  addUnavailabilityPending,
  updateUnavailabilityPending,
  deleteUnavailabilityPending,
  getPersonAllocationsByDate,
  personAllocationsByDate,
  getAccountAllocations,
  runsInModal,
}) => {
  const initialStartDate = (activePou && activePou.startDate && activePou.startDate !== DEFAULT_POU_START) ? activePou.startDate : null;
  const initialEndDate = (activePou && activePou.endDate && activePou.endDate !== DEFAULT_POU_END) ? activePou.endDate : null;
  const initialBackfillRequirement = useMemo(() => {
    const { YES, NO } = TIME_OFF_BACKFILL_CONTROL_VALUES;
    if (activePou?.rangeType) {
      return activePou.rangeType === TIME_OFF ? NO : YES;
    }
    return null;
  }, [activePou]);

  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [workingStartDate, setWorkingStartDate] = useState(initialStartDate);
  const [workingEndDate, setWorkingEndDate] = useState(initialEndDate);

  const [description, setDescription] = useState((activePou && activePou.description) || '');
  const [isPrivate, setIsPrivate] = useState((activePou && activePou.isPrivate) || false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [error, setError] = useState(null);
  const [affectedProjects, setAffectedProjects] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [hasBackfill, setHasBackfill] = useState(
    initialBackfillRequirement === null
      ? null
      : initialBackfillRequirement === TIME_OFF_BACKFILL_CONTROL_VALUES.YES,
  );
  const backfillIsNull = useMemo(() => hasBackfill === null, [hasBackfill]);

  const { employmentDates } = person;
  const hidePrivate = isPrivate && !isAuthorized(accountId, userInfo.permissions, PERM_WRITE, PERM_PRIVATE);

  const minDate =
    employmentDates?.startDate &&
      stringToMoment(employmentDates.startDate).isAfter(MIN_API_CONFIGURABLE_DATE)
      ? stringToMoment(employmentDates.startDate)
      : stringToMoment(MIN_API_CONFIGURABLE_DATE);

  const maxDate =
    employmentDates?.endDate &&
      stringToMoment(employmentDates.endDate).isAfter(MIN_API_CONFIGURABLE_DATE)
      ? stringToMoment(employmentDates.endDate)
      : stringToMoment(MAX_CONFIGURABLE_DATE);

  const minDateMessage =
    minDate.isAfter(MIN_API_CONFIGURABLE_DATE)
      ? ''
      : MIN_API_CONFIGURABLE_DATE_MESSAGE;

  const updateWorkingStartDate = (date) => {
    setWorkingStartDate(date);
    const newStart = stringToMoment(date);

    if (date === null) {
      setStartDate(null);
    } else if (newStart.isValid()) {
      setStartDate(momentToString(newStart));
    }
  };

  const updateWorkingEndDate = (date) => {
    setWorkingEndDate(date);
    const newEnd = stringToMoment(date);

    if (date === null) {
      setEndDate(null);
    } else if (newEnd === null || newEnd.isValid()) {
      setEndDate(momentToString(newEnd));
    }
  };

  const updateDescription = evt => setDescription(evt.currentTarget.value);

  const updatePrivate = () => setIsPrivate(!isPrivate);

  const clearStart = () => updateWorkingStartDate(null);

  const clearEnd = () => updateWorkingEndDate(null);

  const onChangeBackfill = selectedValue => setHasBackfill(selectedValue === TIME_OFF_BACKFILL_CONTROL_VALUES.YES);

  useEffect(() => {
    const startDate = moment.utc().subtract(MIN_DATA_RANGE_IN_MONTHS, 'months');
    const endDate = moment.utc().add(MAX_DATA_RANGE_IN_YEARS, 'years');

    getAccountAllocations(accountId, momentToString(startDate), momentToString(endDate));
  }, [accountId, getAccountAllocations, pous]);

  useEffect(() => {
    let newAffectedProjects = [];
    if (personAllocationsByDate[person.id]) {
      const distinctProjects = unique(personAllocationsByDate[person.id], 'projectName');
      newAffectedProjects = naturalSort(distinctProjects, 'projectName');
    }
    setAffectedProjects(newAffectedProjects);
  }, [personAllocationsByDate, person.id]);

  const onValidate = useCallback(() => {
    const data = {
      id: activePou ? activePou.id : null,
      startDate,
      endDate,
    };

    const newError = validateUnavailability(employmentDates, pous, data);
    setError(newError);
    if (startDate) {
      getPersonAllocationsByDate(accountId, person.id, startDate, endDate || dateStringAdd(startDate, MAX_DATA_RANGE_IN_YEARS, 'years'));
    }
  }, [activePou, startDate, endDate, employmentDates, pous, getPersonAllocationsByDate, accountId, person.id]);

  useEffect(() => {
    if (startDate) {
      onValidate();
    }
  }, [startDate, endDate, onValidate]);

  const onSave = () => {
    const data = [{
      startDate,
      endDate,
      rangeType: hasBackfill ? UNAVAILABILITY : TIME_OFF,
    }];

    if (!hidePrivate) {
      data[0] = {
        ...data[0],
        description,
        isPrivate,
      };
    }

    const analyticsPayload = {
      endDate,
      startDate,
      description,
      isPrivate,
      hasBackfill,
      personName: person.name,
      personId: person.id,
      affectedProjects,
      runsInModal,
      ...(endDate && { lengthOfTimeOff: stringToMoment(endDate).diff(startDate, 'days') + 1 }),
    };

    if (!activePou) {
      addUnavailability(accountId, person.id, data, analyticsPayload);
    } else {
      // data did not change
      if (activePou.startDate === startDate && activePou.endDate === endDate
        && activePou.description === description && activePou.isPrivate === isPrivate) {
        onCloseModal();
        return;
      }
      data[0].id = activePou.id;

      const fieldsUpdated = [];

      if (activePou.startDate !== startDate) fieldsUpdated.push('Start date');
      if (activePou.endDate !== endDate) fieldsUpdated.push('End date');
      if (activePou.description !== description) fieldsUpdated.push('Description');

      analyticsPayload.fieldsUpdated = fieldsUpdated;

      updateUnavailability(accountId, person.id, data, analyticsPayload);
    }
  };

  const onDelete = () => {
    const analyticsPayload = {
      personName: person.name,
      personId: person.id,
      runsInModal,
    };

    deleteUnavailability(accountId, person.id, [activePou.id], analyticsPayload);
  };

  const handleDelete = () => {
    setShowDeleteConfirm(true);
  };

  const cancelConfirm = () => {
    setShowDeleteConfirm(false);
  };

  const onFocus = () => setIsEditing(true);

  const onBlur = () => setIsEditing(false);

  const requiredInputs = useMemo(() => {
    if (!backfillIsNull) {
      return hasBackfill ? startDate : startDate && endDate;
    }
    return false;
  }, [backfillIsNull, hasBackfill, startDate, endDate]);

  const renderProjectsImpacted = useMemo(() => {
    let message;

    if (!hasBackfill) {
      message = `This time off will impact ${person.name}`;

      if (affectedProjects.length) {
        message += '\'s time on the following projects:';
      } else {
        message += '\'s assignments on any future projects.';
      }
    } else {
      message = `${person.name} `;

      if (affectedProjects.length) {
        message += 'will be removed from the following projects during this time off. Make sure to allocate another person for this time off.';
      } else {
        message += 'cannot be assigned to projects during this time off.';
      }
    }

    return (
      <div className="update-person-message">
        {message}
        {affectedProjects.map(({ roleId, projectName }) => (
          <p key={roleId} className="project-name">{projectName}</p>
        ))}
      </div>
    );
  }, [affectedProjects, hasBackfill, person.name]);

  return (
    <div className="people-manage-unavailability">
      <Modal
        className="manage-unavailability"
        maxWidth="sm"
        headline="Time Off"
        closeModal={onCloseModal}
        buttons={[(
          <div key="footer-controls" className="footer-controls" role="presentation">
            <p className="errors">{error}</p>
            <>
              {(addUnavailabilityPending || updateUnavailabilityPending || deleteUnavailabilityPending)
                ? (
                  <div className="loading save-button">
                    <CircularProgress size={25} color="primary" />
                  </div>
                )
                : (
                  <Button
                    className="save-button"
                    color="primary"
                    onClick={onSave}
                    disabled={!!error || !requiredInputs || isEditing}
                  >
                    Save
                  </Button>
                )}
            </>
            {activePou && (
              <Button
                className="delete-button"
                onClick={handleDelete}
                disabled={deleteUnavailabilityPending}
              >
                Delete
              </Button>
            )}
          </div>
        )]}
      >
        <div className="manage-unavailability-content">
          <div className="dates">
            <div className="date-field">
              <p>Start date</p>
              <div className="date-wrapper">
                <DatePicker
                  minDate={minDate}
                  minDateMessage={minDateMessage}
                  maxDate={endDate ? stringToMoment(endDate) : maxDate}
                  date={workingStartDate}
                  onChange={updateWorkingStartDate}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  variant="inline"
                />
                {startDate && <CancelButton className="date-cancel" onClick={clearStart} />}
                <p className="required-icon">Required *</p>
              </div>
            </div>
            <div className="to-text">to</div>
            <div className="date-field">
              <p>End date</p>
              <div className="date-wrapper">
                <DatePicker
                  minDate={startDate ? stringToMoment(startDate) : minDate}
                  minDateMessage={minDateMessage}
                  maxDate={maxDate}
                  date={workingEndDate}
                  onChange={updateWorkingEndDate}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  variant="inline"
                />
                {endDate && <CancelButton className="date-cancel" onClick={clearEnd} />}
                {!backfillIsNull && !hasBackfill && <p className="required-icon">Required *</p>}
              </div>
            </div>
          </div>
          <div className="description-container">
            <FormField
              type="text"
              label="Description"
              name="description"
              value={description}
              placeholder={hidePrivate ? 'Private description' : 'Enter a short description (Example: Vacation or PTO)'}
              onChange={updateDescription}
              maxLength={50}
              width="large"
              disabled={hidePrivate}
            />
            <Can
              action={PERM_WRITE}
              subject={PERM_PRIVATE}
              yes={(
                <PrivateToggle
                  onClick={updatePrivate}
                  isPrivate={isPrivate}
                  type="add"
                  tooltipMessage={`Click to make description ${isPrivate ? 'non-' : ''}private`}
                />
              )}
              no={isPrivate && <Lock className="private-pou-lock" />}
            />
          </div>

          <div className={classNames('backfill-container')}>
            <div className="backfill-title">
              <p>{TIME_OFF_BACKFILL_LABEL}</p>
              <LongTooltip>
                <p>{TIME_OFF_BACKFILL_TOOLTIP}</p>
              </LongTooltip>
            </div>
            <RadioChipForm
              isDisabled={!!activePou}
              onChange={onChangeBackfill}
              defaultValue={initialBackfillRequirement}
              controls={TIME_OFF_BACKFILL_CONTROLS}
            />
            <p className="required-icon">Required *</p>
          </div>
          {!error && startDate && renderProjectsImpacted}
        </div>

        {showDeleteConfirm && (
          <Confirm
            headline="Delete time off"
            acceptButtonText="Ok"
            cancelButtonText="Cancel"
            onCancel={cancelConfirm}
            onAccept={onDelete}
          >
            <div>
              <p>Are you sure you want to delete this time off?</p>
              <p>All record of this entry will be deleted.</p>
            </div>
          </Confirm>
        )}
      </Modal>
    </div>
  );
};

ManageUnavailability.propTypes = {
  person: PropTypes.object.isRequired,
  onCloseModal: PropTypes.func,
  addUnavailability: PropTypes.func.isRequired,
  updateUnavailability: PropTypes.func.isRequired,
  deleteUnavailability: PropTypes.func.isRequired,
  accountId: PropTypes.number.isRequired,
  userInfo: PropTypes.object.isRequired,
  activePou: PropTypes.object,
  pous: PropTypes.array.isRequired,
  addUnavailabilityPending: PropTypes.bool.isRequired,
  updateUnavailabilityPending: PropTypes.bool.isRequired,
  deleteUnavailabilityPending: PropTypes.bool.isRequired,
  getPersonAllocationsByDate: PropTypes.func.isRequired,
  personAllocationsByDate: PropTypes.object.isRequired,
  getAccountAllocations: PropTypes.func.isRequired,
  runsInModal: PropTypes.bool,
};

ManageUnavailability.defaultProps = {
  onCloseModal: () => {},
  activePou: null,
  runsInModal: false,
};

/* istanbul ignore next */
function mapStateToProps({ common, people, login }) {
  const { accountId } = common;
  const { userInfo } = login;
  const {
    addUnavailabilityPending,
    updateUnavailabilityPending,
    deleteUnavailabilityPending,
    personAllocationsByDate,
  } = people;
  return {
    accountId,
    userInfo,
    addUnavailabilityPending,
    updateUnavailabilityPending,
    deleteUnavailabilityPending,
    personAllocationsByDate,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    addUnavailability: bindActionCreators(addUnavailability, dispatch),
    updateUnavailability: bindActionCreators(updateUnavailability, dispatch),
    deleteUnavailability: bindActionCreators(deleteUnavailability, dispatch),
    getPersonAllocationsByDate: bindActionCreators(getPersonAllocationsByDate, dispatch),
    getAccountAllocations: bindActionCreators(getAccountAllocations, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManageUnavailability);
