import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PROJECTS_GET_PROJECT_BEGIN,
  PROJECTS_GET_PROJECT_SUCCESS,
  PROJECTS_GET_PROJECT_FAILURE,
  PROJECTS_GET_PROJECT_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';
import { getUpdatedEntity } from '../../../filters/graphqlUtils';

export function getProject(accountId, projectId) {
  return {
    type: PROJECTS_GET_PROJECT_BEGIN,
    accountId,
    projectId,
  };
}

export function dismissGetProjectError() {
  return {
    type: PROJECTS_GET_PROJECT_DISMISS_ERROR,
  };
}

export function* doGetProject(action) {
  const { accountId, projectId } = action;
  let res;
  try {
    res = yield call(protectedJsonGet, `accounts/${accountId}/projects/${projectId}`);
  } catch (err) {
    yield put({
      type: PROJECTS_GET_PROJECT_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PROJECTS_GET_PROJECT_SUCCESS,
    data: res,
    projectId,
  });
}

export function* watchGetProject() {
  yield takeLatest(PROJECTS_GET_PROJECT_BEGIN, doGetProject);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PROJECTS_GET_PROJECT_BEGIN:
      return {
        ...state,
        getProjectPending: true,
        getProjectError: null,
      };

    case PROJECTS_GET_PROJECT_SUCCESS: {
      const { data, projectId } = action;
      const filteredProjects = getUpdatedEntity('Project', state.filteredProjects, data);

      return {
        ...state,
        projects: [
          ...state.projects.filter(p => p.id !== projectId),
          action.data,
        ],
        filteredProjects,
        getProjectPending: false,
        getProjectError: null,
      };
    }

    case PROJECTS_GET_PROJECT_FAILURE:
      return {
        ...state,
        getProjectPending: false,
        getProjectError: action.data.error,
      };

    case PROJECTS_GET_PROJECT_DISMISS_ERROR:
      return {
        ...state,
        getProjectError: null,
      };

    default:
      return state;
  }
}
