import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { hasModuleEnabled } from 'src/features/permissions/utils/permissionUtils';
import { AllocationsTable } from '@bridgit/foundation';
import { ACCOUNT_MODULE_PURSUIT_TRACKING } from 'src/common/constants';
import { PURSUIT } from 'src/features/projects/constants';
import { getPersonPastAllocations } from './redux/getPersonPastAllocations';
import { getPersonAllocations } from './redux/getPersonAllocations';
import { setSelectedProjectId } from '../common/redux/actions';
import {
  COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PROJECT,
  MULTI_STATE_MODAL_ID,
} from '../common/redux/constants';
import { openModal } from '../modal-manager/redux/actions';
import {
  AUTOMATION_CURRENT_ALLOCATION_TABLE_ID,
  AUTOMATION_PAST_ALLOCATION_TABLE_ID,
  AUTOMATION_PURSUIT_ALLOCATION_TABLE_ID,
  AUTOMATION_UPCOMING_ALLOCATION_TABLE_ID } from './ids';
import { PROFILE_DETAILS_MODAL_CONTEXT, PROFILE_DETAILS_PROFILE_CONTEXT } from './constants';

export const ProfileProjects = ({
  personId,
  isDeactivated,
  runsInModal,
}) => {
  const dispatch = useDispatch();

  const {
    isPursuitModuleOn,
    currentAllocations,
    pastAllocations,
    pursuitAllocations,
    upcomingAllocations,
    morePastAllocations,
    morePursuitAllocations,
    accountId,
    currentLoading,
    pastLoading,
    pursuitLoading,
    upcomingLoading,
    morePastLoading,
    morePursuitLoading,
  } = useSelector(({ people, common, accountSettings }) => {
    const { accountId } = common;
    const { accountModules } = accountSettings;
    const {
      currentAllocations: allCurrentAllocations,
      pastAllocations: allPastAllocations,
      personAllocations: allPursuitAllocations,
      upcomingAllocations: allUpcomingAllocations,
      morePastAllocations,
      moreAnyAllocations: morePursuitAllocations,
      getPersonCurrentAllocationsPending,
      getPersonPastAllocationsPending,
      getPersonAllocationsPending,
      getPersonUpcomingAllocationsPending,
    } = people;

    const isPursuitModuleOn = hasModuleEnabled(accountModules, ACCOUNT_MODULE_PURSUIT_TRACKING);

    // This filtering/sorting is temporary until we refactor this area with graph instead of the outdated API
    const currentAllocations =
      allCurrentAllocations[personId]
        ?.filter(({ startDate, endDate }) => moment(moment()).isBetween(startDate, endDate, 'day', []))
        .sort((a, b) => new Date(a.endDate) - new Date(b.endDate))
        || [];
    const upcomingAllocations =
      allUpcomingAllocations[personId]
        ?.filter(({ startDate }) => moment(startDate).isAfter(moment()))
        || [];
    const pastAllocations = allPastAllocations[personId]?.sort((a, b) => new Date(b.endDate) - new Date(a.endDate)) || [];
    const pursuitAllocations = allPursuitAllocations[personId] || [];

    const currentLoading = getPersonCurrentAllocationsPending && currentAllocations.length === 0;
    const pursuitLoading = getPersonAllocationsPending && pursuitAllocations.length === 0;
    const pastLoading = getPersonPastAllocationsPending && pastAllocations.length === 0;
    const upcomingLoading = getPersonUpcomingAllocationsPending && upcomingAllocations.length === 0;
    const morePastLoading = getPersonPastAllocationsPending && pastAllocations.length > 0;
    const morePursuitLoading = getPersonAllocationsPending && pursuitAllocations.length > 0;

    return {
      isPursuitModuleOn,
      currentAllocations,
      pastAllocations,
      pursuitAllocations,
      upcomingAllocations,
      morePastAllocations,
      morePursuitAllocations,
      accountId,
      currentLoading,
      pastLoading,
      pursuitLoading,
      upcomingLoading,
      morePastLoading,
      morePursuitLoading,
    };
  }, shallowEqual);

  const getMuiTheme = outerTheme => ({
    ...outerTheme,
    overrides: {
      ...outerTheme.overrides,
      MuiTableCell: {
        root: {
          borderRight: '1px solid #F2F4F7',
          '&:nth-child(1)': {
            maxWidth: '155px',
            boxSizing: 'border-box',
          },
          '&:nth-child(3)': {
            minWidth: '75px',
          },
          '&:last-child': {
            borderRight: 'none',
            paddingRight: '15px',
            minWidth: '90px',
          },
          padding: '4px 15px',
        },
        head: {
          color: '#4D4D4E',
          borderRight: 'none',
        },
      },
      MuiTableRow: {
        head: {
          height: '35px',
        },
      },
    },
  });

  const getMorePastAllocations = () => {
    const offset = pastAllocations.length;
    dispatch(getPersonPastAllocations(accountId, personId, offset));
  };

  const getMorePursuitAllocations = () => {
    const offset = pursuitAllocations.length;
    dispatch(getPersonAllocations(accountId, personId, offset, PURSUIT));
  };

  let launchedFrom = runsInModal ? PROFILE_DETAILS_MODAL_CONTEXT : 'Profile History';
  if (isDeactivated) {
    launchedFrom = `Deactivated People - ${runsInModal ? PROFILE_DETAILS_MODAL_CONTEXT : PROFILE_DETAILS_PROFILE_CONTEXT}`;
  }

  const onProjectClick = ({ projectId, projectName }) => {
    const analyticsPayload = {
      runsInModal,
      data: {
        projectName,
        projectId,
        from: PROFILE_DETAILS_MODAL_CONTEXT,
        to: 'Project Details Modal',
      },
    };
    dispatch(setSelectedProjectId(projectId, COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PROJECT, null, false, false, null, analyticsPayload));
    dispatch(openModal(MULTI_STATE_MODAL_ID));

    window.mixpanel.track('Project Details Modal Displayed', {
      'Project Name': projectName,
      'Project ID': projectId,
      'Launched from': launchedFrom,
    });
  };

  return (
    <div className="people-profile-projects">
      <MuiThemeProvider theme={getMuiTheme}>
        {!isDeactivated && (
          <>
            <AllocationsTable
              automationId={AUTOMATION_CURRENT_ALLOCATION_TABLE_ID}
              allocations={currentAllocations}
              loading={currentLoading}
              headerText={<div>Current project allocations</div>}
              emptyListText="No current projects"
              onProjectClick={onProjectClick}
            />
            <AllocationsTable
              automationId={AUTOMATION_UPCOMING_ALLOCATION_TABLE_ID}
              allocations={upcomingAllocations}
              loading={upcomingLoading}
              headerText={<div>Upcoming project allocations</div>}
              emptyListText="No upcoming projects"
              onProjectClick={onProjectClick}
            />
            {isPursuitModuleOn && (
              <AllocationsTable
                automationId={AUTOMATION_PURSUIT_ALLOCATION_TABLE_ID}
                allocations={pursuitAllocations}
                loading={pursuitLoading}
                moreLoading={morePursuitLoading}
                headerText={<div>Pursuit project allocations</div>}
                emptyListText="No pursuit projects"
                onProjectClick={onProjectClick}
                hasMoreAllocations={morePursuitAllocations}
                getMoreAllocations={getMorePursuitAllocations}
              />
            )}
          </>
        )}
        <AllocationsTable
          automationId={AUTOMATION_PAST_ALLOCATION_TABLE_ID}
          allocations={pastAllocations}
          loading={pastLoading}
          moreLoading={morePastLoading}
          headerText={<div>Past projects</div>}
          emptyListText="No past projects"
          onProjectClick={onProjectClick}
          hasMoreAllocations={morePastAllocations}
          getMoreAllocations={getMorePastAllocations}
        />
      </MuiThemeProvider>
    </div>
  );
};

ProfileProjects.propTypes = {
  personId: PropTypes.number.isRequired,
  isDeactivated: PropTypes.bool,
  runsInModal: PropTypes.bool,
};

ProfileProjects.defaultProps = {
  isDeactivated: false,
  runsInModal: false,
};

export default ProfileProjects;
