const initialState = {
  defaultVisible: [],
  alwaysVisible: [],
  neverVisible: [],
  visibleColumns: [],
  visibleColumnNames: [],
  columns: [],
};

export default initialState;
