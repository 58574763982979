import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PROJECTS_GET_PROJECT_BY_ID_BEGIN,
  PROJECTS_GET_PROJECT_BY_ID_SUCCESS,
  PROJECTS_GET_PROJECT_BY_ID_FAILURE,
  PROJECTS_GET_PROJECT_BY_ID_DISMISS_ERROR,
} from './constants';
import { getProjectByIdQuery } from '../queries/getProjectByIdQuery';
import graphApiClient from '../../../common/GraphAPIClient';

export function getProjectById(accountId, selectedProjectId, selectionId, refreshing, permissions, mixpanelEvent = null, analyticsPayload) {
  const { query, variables } = getProjectByIdQuery(accountId, selectedProjectId, permissions);
  return {
    type: PROJECTS_GET_PROJECT_BY_ID_BEGIN,
    selectedProjectId,
    query,
    variables,
    selectionId,
    refreshing,
    mixpanelEvent,
    analyticsPayload,
  };
}

export function dismissGetProjectByIdError() {
  return {
    type: PROJECTS_GET_PROJECT_BY_ID_DISMISS_ERROR,
  };
}

export function* doGetProjectById(action) {
  const { query, variables, selectionId, mixpanelEvent, analyticsPayload } = action;
  let res;
  let selectedProject;
  let requests;

  try {
    res = yield call(graphApiClient.query, { query, variables });
    const { errors } = res;
    if (errors) {
      throw errors;
    }
    selectedProject = res?.data?.project;
    requests = res?.data?.requests;
  } catch (err) {
    yield put({
      type: PROJECTS_GET_PROJECT_BY_ID_FAILURE,
      data: { error: err },
    });
    return;
  }
  yield put({
    type: PROJECTS_GET_PROJECT_BY_ID_SUCCESS,
    data: {
      selectedProject,
      selectionId,
      requests,
    },
    mixpanelEvent,
    analyticsPayload,
  });
}

export function* watchGetProjectById() {
  yield takeLatest(PROJECTS_GET_PROJECT_BY_ID_BEGIN, doGetProjectById);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PROJECTS_GET_PROJECT_BY_ID_BEGIN:
      return {
        ...state,
        getProjectByIdPending: !action.refreshing,
        getProjectByIdError: null,
      };

    case PROJECTS_GET_PROJECT_BY_ID_SUCCESS: {
      const { projectSelections } = state;
      const { data: { selectedProject, selectionId, requests } } = action;
      return {
        ...state,
        projectSelections: {
          ...projectSelections,
          [selectionId]: {
            ...selectedProject,
            requests: requests?.items || [],
          },
        },
        getProjectByIdPending: false,
        getProjectByIdError: null,
      };
    }

    case PROJECTS_GET_PROJECT_BY_ID_FAILURE:
      return {
        ...state,
        getProjectByIdPending: false,
        getProjectByIdError: action.data.error,
      };

    case PROJECTS_GET_PROJECT_BY_ID_DISMISS_ERROR:
      return {
        ...state,
        getProjectByIdError: null,
      };

    default:
      return state;
  }
}
