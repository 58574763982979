import {
  THEMES_CHANGE_THEME,
  THEMES_ADJUST_THEME_SETTINGS,
  THEMES_TOGGLE_SYSTEM_THEME_SWITCH,
} from 'src/features/themes/redux/constants';

import {
  DIM_THEME,
  DARK_THEME,
  STANDARD_THEME,
} from '../../features/themes/constants';

import {
  TOGGLE_STATE,
  THEME_SELECTED,
  SETTING_UPDATED,
  THEME_ENABLE_DIMMED_MODE,
  DIM_MODE_SETTING_UPDATED,
  DARK_MODE_SETTING_UPDATED,
  THEME_OS_SYSTEM_THEME_SELECTED,
} from './constants';

const events = {
  [THEMES_CHANGE_THEME]: {
    getEvent: (state, { themeName, analyticsPayload = {} }) => {
      const { eventType } = analyticsPayload;

      if (eventType === THEME_SELECTED) {
        if (themeName !== DIM_THEME) {
          return THEME_SELECTED;
        }
      }

      if (eventType === THEME_ENABLE_DIMMED_MODE) {
        return THEME_ENABLE_DIMMED_MODE;
      }

      return null;
    },
    getPayload: (state, { themeName, analyticsPayload = {} }) => {
      const { eventType, isDimModeOn } = analyticsPayload;

      if (eventType === THEME_ENABLE_DIMMED_MODE) {
        return {
          [TOGGLE_STATE]: isDimModeOn,
        };
      }

      if (themeName === DARK_THEME) {
        return {
          [THEME_SELECTED]: 'Dark Mode',
        };
      }

      if (themeName === STANDARD_THEME) {
        return {
          [THEME_SELECTED]: 'Light Mode',
        };
      }

      return null;
    },
  },
  [THEMES_ADJUST_THEME_SETTINGS]: {
    getEvent: (state, { themeName, analyticsPayload }) => {
      const { updatedThemeSetting } = analyticsPayload;

      if (updatedThemeSetting) {
        if (themeName === DIM_THEME) {
          return DIM_MODE_SETTING_UPDATED;
        }

        if (themeName === DARK_THEME) {
          return DARK_MODE_SETTING_UPDATED;
        }
      }

      return null;
    },
    getPayload: (state, { analyticsPayload }) => {
      const { updatedThemeSetting } = analyticsPayload;

      return {
        [SETTING_UPDATED]: updatedThemeSetting,
      };
    },
  },
  [THEMES_TOGGLE_SYSTEM_THEME_SWITCH]: {
    getEvent: (state, { analyticsPayload = {} }) => {
      const { eventType } = analyticsPayload;

      if (eventType === THEME_OS_SYSTEM_THEME_SELECTED) {
        return THEME_OS_SYSTEM_THEME_SELECTED;
      }

      return null;
    },
    getPayload: (state, { newToggleState }) => ({ [TOGGLE_STATE]: newToggleState }),
  },
};

export default events;
