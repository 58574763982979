import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ConfirmationModal, Modal, Button } from '@bridgit/foundation';
import { ClickAwayListener, ListItemIcon, MenuItem, MenuList, Typography } from '@material-ui/core';
import { Cancel, Domain, Person, Star } from '@material-ui/icons';
import { CustomPopper } from '../wrapped-components';
import { PEOPLE_FILTER_TYPE, PROJECT_FILTER_TYPE } from './common/constants';
import useAnchorToggle from './utils/useAnchorToggle';

function SavedFiltersDropdown({ savedFilters, disabled, onApply, onDelete, onConfirmDelete, onCancelDelete, isApplied, filterToDelete, showSubheaders }) {
  const { anchorEl, handleClose: closeDropdown, handleToggle: toggleDropdown, isOpen: isDropdownOpen } = useAnchorToggle();
  const [isManageModalOpen, setIsManageModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const [peopleFilterList, projectFilterList] = useMemo(() => (
    savedFilters?.reduce(([peopleFilterList, projectFilterList], filter) => {
      if (filter.filterType === PEOPLE_FILTER_TYPE) {
        peopleFilterList.push(filter);
      }

      if (filter.filterType === PROJECT_FILTER_TYPE) {
        projectFilterList.push(filter);
      }

      return [peopleFilterList, projectFilterList];
    }, [[], []])
  ), [savedFilters]);

  const peopleFilterSubheader = useMemo(() => {
    if (showSubheaders) {
      if (peopleFilterList?.length === 0 && !!projectFilterList?.length) {
        return (
          <div className="people-filters">
            <div className="saved-filters-subheading">People saved filters</div>
            <div className="no-saved-filters-msg">There are no saved People filters</div>
          </div>
        );
      }

      return <div className="saved-filters-subheading">People saved filters</div>;
    }

    return null;
  }, [peopleFilterList?.length, projectFilterList?.length, showSubheaders]);

  const projectFilterSubheader = useMemo(() => {
    if (showSubheaders) {
      if (projectFilterList?.length === 0 && !!peopleFilterList?.length) {
        return (
          <div className="project-filters">
            <div className="saved-filters-subheading">Project saved filters</div>
            <div className="no-saved-filters-msg">There are no saved Project filters</div>
          </div>
        );
      }

      return <div className="saved-filters-subheading">Project saved filters</div>;
    }

    return null;
  }, [peopleFilterList?.length, projectFilterList?.length, showSubheaders]);

  const renderFiltersList = useCallback(({ applyFilter, deleteFilter } = {}) => (
    <MenuList classes={{ root: 'saved-filters-list' }}>
      {peopleFilterSubheader}
      {peopleFilterList?.map(filter => (
        <MenuItem
          key={filter.filterId}
          classes={{ root: 'menu-item' }}
          disableRipple
          onClick={applyFilter ? applyFilter(filter) : undefined}
        >
          <ListItemIcon classes={{ root: 'type-icon' }}>
            <Person fontSize="small" />
          </ListItemIcon>
          <Typography className="filter-name">
            {filter.filterName}
          </Typography>
          {!!deleteFilter && (
            <ListItemIcon classes={{ root: 'delete-icon' }} onClick={deleteFilter(filter)}>
              <Cancel />
            </ListItemIcon>
          )}
        </MenuItem>
      ))}

      {projectFilterSubheader}
      {projectFilterList?.map(filter => (
        <MenuItem
          key={filter.filterId}
          classes={{ root: 'menu-item' }}
          disableRipple
          onClick={applyFilter ? applyFilter(filter) : undefined}
        >
          <ListItemIcon classes={{ root: 'type-icon' }}>
            <Domain fontSize="small" />
          </ListItemIcon>
          <Typography className="filter-name">
            {filter.filterName}
          </Typography>
          {!!deleteFilter && (
            <ListItemIcon classes={{ root: 'delete-icon' }} onClick={deleteFilter(filter)}>
              <Cancel />
            </ListItemIcon>
          )}
        </MenuItem>
      ))}
    </MenuList>
  ), [peopleFilterList, projectFilterList, peopleFilterSubheader, projectFilterSubheader]);

  const openManageModal = () => {
    closeDropdown();
    setIsManageModalOpen(true);
  };

  const closeManageModal = useCallback(() => setIsManageModalOpen(false), []);

  const openConfirmModal = filter => () => {
    onDelete(filter);
    closeManageModal();
    setIsConfirmModalOpen(true);
  };

  const cancelDeleteSavedFilter = () => {
    setIsConfirmModalOpen(false);
    setTimeout(() => onCancelDelete(), 1000);
  };

  const applySavedFilter = filter => () => {
    if (onApply) onApply(filter);
    closeDropdown();
  };

  const deleteSavedFilter = () => {
    setIsConfirmModalOpen(false);
    onConfirmDelete();
  };

  return (
    <>
      <div className="saved-filters-dropdown">
        <Button
          color="secondary"
          variant="outlined"
          onClick={toggleDropdown}
          disabled={disabled}
          endIcon={<Star className={classNames({ 'button-active': isApplied })} />}
        >
          Saved filters
        </Button>
        <CustomPopper
          classes="common-filter-popper"
          popperOpen={isDropdownOpen}
          anchorEl={anchorEl}
          placement="bottom-start"
          disablePortal
          hideArrow
        >
          <ClickAwayListener onClickAway={closeDropdown}>
            <div className="saved-filters-dropdown">
              {!savedFilters.length && (
                <Typography className="empty-dropdown">
                  No saved filters. Try applying filters and saving them for quick access in the future.
                </Typography>
              )}
              {!!savedFilters.length && (
                <>
                  {renderFiltersList({ applyFilter: applySavedFilter })}
                  <div className="manage-button-container">
                    <Button
                      size="small"
                      color="primary"
                      variant="outlined"
                      onClick={openManageModal}
                    >
                      Manage saved filters
                    </Button>
                  </div>
                </>
              )}
            </div>
          </ClickAwayListener>
        </CustomPopper>
      </div>
      <Modal className="saved-filters-editor" open={isManageModalOpen} headline="Manage saved filters" onClose={closeManageModal}>
        {renderFiltersList({ deleteFilter: openConfirmModal })}
      </Modal>
      <ConfirmationModal
        className="confirm-delete-filter"
        open={isConfirmModalOpen}
        headline="Delete saved filter"
        cancelText="Cancel"
        confirmText="Delete"
        onConfirm={deleteSavedFilter}
        onCancel={cancelDeleteSavedFilter}
        primaryButtonClassName="delete-filter"
      >
        <Typography className="prompt-message" variant="subtitle1">
          {`Are you sure you want to delete the saved filter "${filterToDelete?.filterName}"?`}
        </Typography>
      </ConfirmationModal>
    </>
  );
}

SavedFiltersDropdown.propTypes = {
  savedFilters: PropTypes.array,
  disabled: PropTypes.bool,
  onApply: PropTypes.func,
  onDelete: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onCancelDelete: PropTypes.func,
  isApplied: PropTypes.bool,
  filterToDelete: PropTypes.object,
  showSubheaders: PropTypes.bool,
};

SavedFiltersDropdown.defaultProps = {
  savedFilters: undefined,
  disabled: false,
  onApply: undefined,
  onDelete: () => {},
  onConfirmDelete: () => {},
  onCancelDelete: () => {},
  isApplied: false,
  filterToDelete: null,
  showSubheaders: false,
};

export default SavedFiltersDropdown;
