import { getTableConfigFromSchema } from 'src/features/table/filterUtils';
import { END_DATE, PROJECT_NAME, START_DATE, REQUIRED_SKILLS, REQUESTER_NAME } from 'src/filters/constants';
import { SKILL_SET_FIELD } from 'src/features/self-perform/constants';

export const REQUEST_FIELDS = [
  {
    schemaName: 'id',
    displayName: 'Request ID',
    visible: false,
  },
  {
    schemaName: 'projectId',
    displayName: 'Project ID',
    visible: false,
  },
  {
    schemaName: 'description',
    displayName: 'Description',
    defaultVisible: true,
    sort: false,
  },
  {
    schemaName: PROJECT_NAME,
    displayName: 'Project',
    defaultVisible: true,
  },
  {
    schemaName: REQUIRED_SKILLS,
    displayName: SKILL_SET_FIELD,
    defaultVisible: true,
  },
  {
    schemaName: START_DATE,
    displayName: 'Start Date',
    defaultVisible: true,
  },
  {
    schemaName: END_DATE,
    displayName: 'End Date',
    defaultVisible: true,
  },
  {
    schemaName: REQUESTER_NAME,
    displayName: 'Requester',
    defaultVisible: true,
  },
  {
    schemaName: 'requestedOn',
    displayName: 'Date Requested',
    defaultVisible: true,
  },
  {
    schemaName: 'state',
    displayName: 'Status',
    defaultVisible: true,
  },
];

export const REQUEST_FIELDS_DEFAULTS = {
  displayName: '',
  visible: true,
  defaultVisible: false,
  alwaysVisible: false,
  filter: false,
  sort: true,
};

export const REQUEST_DESCRIPTOR = REQUEST_FIELDS.map(field => ({
  ...REQUEST_FIELDS_DEFAULTS,
  ...field,
}));

export const REQUEST_TABLE_CONFIG = getTableConfigFromSchema(REQUEST_DESCRIPTOR);

export const REQUEST_SCHEMAS = REQUEST_FIELDS.map(field => field.schemaName);
