import { gql } from 'apollo-boost';

const SET_PEOPLE_GANTT_SHARE_QUERY = gql`
  mutation setPeopleGanttConfig($accountId: Int!, $value: String!) {
    setPeopleGanttConfig(accountId: $accountId, value: $value) {
      key
    }
  }
 `;

export const setPeopleGanttShareQuery = (accountId, value) => {
  const variables = { accountId, value };

  return {
    query: SET_PEOPLE_GANTT_SHARE_QUERY,
    variables,
  };
};
