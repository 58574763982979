import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNTS_SET_TITLE_COSTS_BEGIN,
  ACCOUNTS_SET_TITLE_COSTS_SUCCESS,
  ACCOUNTS_SET_TITLE_COSTS_FAILURE,
  ACCOUNTS_SET_TITLE_COSTS_DISMISS_ERROR,
} from './constants';
import { protectedJsonPut } from '../../../utils/api';

export function setTitleCosts(accountId, titleId, hourlyCost, analyticsPayload = {}) {
  return {
    type: ACCOUNTS_SET_TITLE_COSTS_BEGIN,
    accountId,
    titleCosts: [{
      id: titleId,
      hourlyCost,
    }],
    analyticsPayload,
  };
}

export function dismissSetTitleCostsError() {
  return {
    type: ACCOUNTS_SET_TITLE_COSTS_DISMISS_ERROR,
  };
}

export function* doSetTitleCosts(action) {
  const { accountId, titleCosts } = action;
  let res;
  try {
    res = yield call(protectedJsonPut, `/accounts/${accountId}/title-costs`, titleCosts);
  } catch (err) {
    yield put({
      type: ACCOUNTS_SET_TITLE_COSTS_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: ACCOUNTS_SET_TITLE_COSTS_SUCCESS,
    data: res,
  });
}

export function* watchSetTitleCosts() {
  yield takeLatest(ACCOUNTS_SET_TITLE_COSTS_BEGIN, doSetTitleCosts);
}

export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNTS_SET_TITLE_COSTS_BEGIN:
      return {
        ...state,
        setTitleCostsPending: true,
        setTitleCostsError: null,
      };

    case ACCOUNTS_SET_TITLE_COSTS_SUCCESS: {
      const newTitleCosts = [];
      const updatedTitleCosts = action.data;
      state.titleCosts?.forEach((existingTitleCost) => {
        const updatedTitleCost = updatedTitleCosts.find(updated => updated.id === existingTitleCost.id);
        if (updatedTitleCost) {
          newTitleCosts.push(updatedTitleCost);
        } else {
          newTitleCosts.push(existingTitleCost);
        }
      });
      return {
        ...state,
        setTitleCostsPending: false,
        setTitleCostsError: null,
        titleCosts: newTitleCosts,
      };
    }
    case ACCOUNTS_SET_TITLE_COSTS_FAILURE:
      return {
        ...state,
        setTitleCostsPending: false,
        setTitleCostsError: action.data.error,
      };

    case ACCOUNTS_SET_TITLE_COSTS_DISMISS_ERROR:
      return {
        ...state,
        setTitleCostsError: null,
      };

    default:
      return state;
  }
}
