import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import deepEqual from 'react-fast-compare';
import { Checkbox, Button } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import {
  PERMISSION_DEFAULT_VALUES,
  MAX_GROUP_NAME_LENGTH,
} from 'src/features/permissions/utils/constants';
import { EditControls, ValidatedForm } from 'src/features/wrapped-components';

function PermissionGroupSave({
  className,
  permissionGroups,
  originalValues,
  onSave,
  onCancel,
  onDelete,
  pending,
  show,
  permissionTypes,
}) {
  const [disableSave, setDisableSave] = useState(false);
  const [noChange, setNoChange] = useState(true);
  const [values, setValues] = useState({
    ...PERMISSION_DEFAULT_VALUES,
    ...originalValues,
  });

  const hasOriginalValues = useMemo(() => !!(originalValues?.name && originalValues?.permissions), [originalValues]);

  useEffect(() => {
    setValues({
      ...PERMISSION_DEFAULT_VALUES,
      ...originalValues,
    });
  }, [originalValues, show]);

  useEffect(() => {
    setNoChange(!!(
      hasOriginalValues &&
      values.name.trim() === originalValues.name &&
      deepEqual(values.permissions, originalValues.permissions)
    ));
  }, [originalValues, values, hasOriginalValues]);

  const onNameChange = (key, name) => {
    setValues({
      ...values,
      ...name,
    });
  };

  const onPermChange = key => (evt) => {
    setValues({
      ...values,
      permissions: {
        ...values.permissions,
        [key]: evt.target.checked,
      },
    });
  };

  const validate = () => {
    const errors = {};
    const name = values.name.trim();

    if (name === '') {
      errors.name = 'This field is required';
    }

    const lowerCaseName = name.toLowerCase();
    if (permissionGroups.some(group => group.name.toLowerCase() === lowerCaseName) && lowerCaseName !== originalValues?.name?.toLowerCase()) {
      errors.name = 'Permission group name must be unique';
    }

    setDisableSave(Object.keys(errors).length !== 0);
    return errors;
  };

  const primaryAction = () => onSave(values);

  if (!show) return null;

  return (
    <div className={className}>
      <div className="permissions-permission-group-create">
        <ValidatedForm
          className="perm-name"
          inputs={[{
            type: 'text',
            name: 'name',
            value: values.name,
            label: 'Name',
            maxLength: MAX_GROUP_NAME_LENGTH,
            placeholder: 'Enter a name for this permission group',
          }]}
          validate={validate}
          onValueChanged={onNameChange}
          alwaysShowErrors
          forceUpdate
        />
        {permissionTypes.map(type => (
          <div className="perm-checkbox-option" key={type.value}>
            <Checkbox
              className={classNames('perm-checkbox', { disabled: type.disabled })}
              checked={values.permissions[type.value]}
              onChange={onPermChange(type.value)}
              disabled={type.disabled}
              color="primary"
              disableRipple
            />
            <div className="option-details">
              <h2>{type.label}</h2>
              <p>{type.description()}</p>
            </div>
          </div>
        ))}
        <div className="perm-checkbox-option">
          <Lock className="perm-lock" />
          <div className="option-details">
            <h2>Manage Settings</h2>
            <p>View and update account settings, custom fields, users, and permissions. Only Administrators will have this permission.</p>
          </div>
        </div>
        <div className="actions-container">
          {hasOriginalValues && (
            <Button
              className="delete-button"
              disableRipple
              onClick={onDelete}
            >
              Delete Permission Group
            </Button>
          )}
          <EditControls
            primaryText="Save Permission Group"
            primaryAction={primaryAction}
            secondaryAction={onCancel}
            pending={pending}
            disabled={disableSave || noChange}
          />
        </div>
      </div>
    </div>
  );
}

PermissionGroupSave.propTypes = {
  className: PropTypes.string,
  permissionGroups: PropTypes.array.isRequired,
  originalValues: PropTypes.object,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  pending: PropTypes.bool,
  show: PropTypes.bool,
  permissionTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

PermissionGroupSave.defaultProps = {
  className: '',
  originalValues: {},
  onSave: () => {},
  onCancel: () => {},
  onDelete: null,
  pending: false,
  show: true,
};

/* istanbul ignore next */
function mapStateToProps({ permissions }) {
  const { permissionGroups } = permissions;
  return {
    permissionGroups,
  };
}

export default connect(
  mapStateToProps,
  null,
)(PermissionGroupSave);
