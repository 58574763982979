import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_FILTER_BY_VIEW, QUERY_ID_BY_VIEW, RELATED_VIEWS, STORAGE_KEYS_BY_VIEW } from '../../../common/constants';
import { perAccountUserKey } from '../../../common/localStorageKeys';
import { saveToLocalStorage } from '../../../utils/browser-utils';
import { capitalize } from '../../../utils/formatters';
import { getValidatedLocalStorage, validateStoredFilter } from '../../../utils/validators';
import { trackAnalytics } from '../../common/redux/trackAnalytics';
import { setFilterQuery } from '../../queries/redux/actions';
import { updateColumnFilter } from '../../table/filterUtils';
import { APPLY_FILTER_ON_INIT } from '../common/constants';
import {
  createFilterGroup,
  getFilterEventProperties,
  getFilterSummary,
  isDefaultFilter,
  isFilterGroupNonEmpty,
  isSavedFilterGroup,
  toUnsavedFilterGroup,
} from './filterUtils';

export default function useRememberedFilters({ accountId, userId, viewId, isPursuitsEnabled, trackingContext = {} }) {
  const dispatch = useDispatch();
  const queries = useSelector(({ queries }) => queries);

  const queryIds = useMemo(() => QUERY_ID_BY_VIEW[viewId] || {}, [viewId]);
  const storageIds = useMemo(() => STORAGE_KEYS_BY_VIEW[viewId], [viewId]);
  const defaultFilterGroups = useMemo(() => DEFAULT_FILTER_BY_VIEW[viewId], [viewId]);
  const { page, view } = trackingContext;

  useEffect(() => {
    if (!storageIds) return;

    const rememberedFilters = Object.entries(storageIds).map(([filterType, prefix]) => {
      const storedFilter = getValidatedLocalStorage(perAccountUserKey(prefix, accountId, userId));
      const defaultFilter = createFilterGroup(filterType, defaultFilterGroups?.[filterType], isPursuitsEnabled);
      const validatedFilter = validateStoredFilter(storedFilter) ? storedFilter : defaultFilter;
      return [
        { ...validatedFilter, filterType }, // ensures all filters have the proper type
        defaultFilter,
      ];
    });

    rememberedFilters.forEach(([group]) => {
      const queryId = queryIds[group.filterType];
      if (queryId) dispatch(setFilterQuery(queryId, group));
    });

    const customFilters = rememberedFilters.reduce((groups, [group, groupDefault]) => {
      if (isFilterGroupNonEmpty(group) && !isDefaultFilter(groupDefault.args, group.args)) groups.push(group);
      return groups;
    }, []);

    if (customFilters.length && page) {
      const labels = customFilters.map(({ filterType }) => capitalize(filterType)).join(' and ');
      const saved = customFilters.filter(isSavedFilterGroup);
      const payload = {
        'View filtered on': page,
        'People or Project filters applied': labels,
        'Saved filter applied': !!saved.length,
      };
      if (saved.length) payload['Saved filter name'] = saved.map(group => group.filterName).join(', ');
      dispatch(trackAnalytics(APPLY_FILTER_ON_INIT, Object.assign(payload, ...customFilters.map(getFilterSummary))));
    }
  }, [storageIds, accountId, userId, queryIds, defaultFilterGroups, isPursuitsEnabled, dispatch, page]);

  const applyFilter = useCallback((filter, extraPayload) => {
    const { filterType, filterName } = filter;
    const { [filterType]: queryId, ...relatedQueryIds } = queryIds;
    if (queryId) dispatch(setFilterQuery(queryId, filter));

    saveToLocalStorage({
      key: perAccountUserKey(storageIds?.[filterType], accountId, userId),
      value: filter,
    });

    const concurrentFilterSummaries = Object.values(relatedQueryIds).reduce((summaries, id) => {
      const group = queries[id]?.filter;
      if (isFilterGroupNonEmpty(group)) summaries.push(getFilterSummary(group));
      return summaries;
    }, []);

    const type = capitalize(filterType);
    const eventName = type === 'Project' ? 'Filter on Projects' : `Filter on ${type}`;
    const isSaved = isSavedFilterGroup(filter);
    const payload = {
      'View filtered on': view,
      'Filter type used': 'Header',
      'Saved filter applied': isSaved,
      ...getFilterSummary(filter),
      ...extraPayload,
    };
    if (isSaved) payload['Saved filter name'] = filterName;

    dispatch(trackAnalytics(eventName, Object.assign(payload, ...concurrentFilterSummaries)));
  }, [dispatch, accountId, userId, view, queries, queryIds, storageIds]);

  const updateCurrentFilter = useCallback(filterType => (selectedFilter, updatedFilter) => {
    const queryId = queryIds[filterType];
    const currentFilter = queries[queryId]?.filter;
    const nextFilter = {
      filterType, // ensures all filters have the proper type
      ...updateColumnFilter(selectedFilter, updatedFilter, currentFilter),
    };

    applyFilter(nextFilter, getFilterEventProperties(selectedFilter, updatedFilter));
  }, [queryIds, queries, applyFilter]);

  const onFilterDeleted = useCallback(({ filterId, filterType } = {}) => {
    if (!filterId || !filterType) return;

    RELATED_VIEWS[filterType].forEach((relatedViewId) => {
      const key = perAccountUserKey(STORAGE_KEYS_BY_VIEW[relatedViewId][filterType], accountId, userId);
      const storedFilter = getValidatedLocalStorage(key);
      if (storedFilter?.filterId === filterId) saveToLocalStorage({ key, value: toUnsavedFilterGroup(storedFilter) });
    });

    const queryId = queryIds[filterType];
    const currentFilter = queries[queryId]?.filter;
    if (queryId && currentFilter?.filterId === filterId) dispatch(setFilterQuery(queryId, toUnsavedFilterGroup(currentFilter)));
  }, [accountId, userId, dispatch, queries, queryIds]);

  return { applyFilter, updateCurrentFilter, onFilterDeleted };
}
