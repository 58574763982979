import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Popper, LinearProgress } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import moment from 'moment';
import { ProjectLinkToModalContainer } from '../common';
import { DATE_INPUT_FORMAT, DATE_DISPLAY_FORMAT } from '../../common/constants';

class AllocationIssuePopper extends PureComponent {
  static propTypes = {
    allocations: PropTypes.array,
    popperTargetRef: PropTypes.object,
    popperOpen: PropTypes.bool.isRequired,
    onPopperClose: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    allocations: [],
    popperTargetRef: null,
  }

  render() {
    const { allocations, popperOpen, popperTargetRef, onPopperClose, loading } = this.props;

    const modifiers = {
      offset: {
        offset: 32,
      },
      flip: {
        enabled: false,
      },
    };

    return (
      <div className="projects-issue-popper">
        <Popper
          className="issue-popper"
          open={popperOpen}
          anchorEl={popperTargetRef}
          placement="top-end"
          disablePortal
          modifiers={modifiers}
        >
          <span className="popper-arrow" />
          <div className="issue-popper-content-wrapper">
            <Button
              size="small"
              aria-label="Close"
              classes={{ root: 'button-close' }}
              color="inherit"
              disableRipple
              onClick={onPopperClose}
            >
              <Close classes={{ root: 'icon-close' }} />
            </Button>

            <div className="popper-header">Issues</div>

            <div className="content-header">
              <span className="project-name">Project Name</span>
              <span className="allocation-date">Allocation Dates</span>
              <span className="allocation-percent">%</span>
            </div>

            { loading && (
              <div className="progress-container">
                <LinearProgress variant="query" color="primary" />
              </div>
            )}

            <div className="popper-content-container">
              { !loading && allocations.map((a) => {
                const startDateFormatted = moment(a.startDate, DATE_INPUT_FORMAT).format(DATE_DISPLAY_FORMAT);
                const endDateFormatted = moment(a.endDate, DATE_INPUT_FORMAT).format(DATE_DISPLAY_FORMAT);

                return (
                  <div key={a.roleId} className="content-row">
                    <div className="role-name">
                      <ProjectLinkToModalContainer
                        backgroundColor={a.projectColour}
                        projectName={a.projectName}
                        projectId={a.projectId}
                        onClick={onPopperClose}
                        launchedFrom="Conflict Popper"
                      />
                    </div>
                    <span className="role-timeframe">{`${startDateFormatted} - ${endDateFormatted}`}</span>
                    <span className="role-percentage">{a.allocatedPercent}</span>
                  </div>
                );
              })}
            </div>

          </div>
        </Popper>
      </div>
    );
  }
}

export default AllocationIssuePopper;
