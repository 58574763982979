export const DEFAULT_PROJECT_SORT = { name: 'sort', args: [{ column: 'name', label: 'Project Name', ascending: true, type: null }] };
export const DEFAULT_PROJECT_BY_ID_ROLE_SORT = [
  { sortOrder: { order: 'asc' } },
  { startDate: { order: 'asc' } },
  { endDate: { order: 'asc' } },
];
export const DEFAULT_PROJECT_ROLE_SORT = {
  roles: {
    nested: {
      path: 'roles',
      structuredSort: DEFAULT_PROJECT_BY_ID_ROLE_SORT,
    },
  },
};

export const MAX_PROJECTS = 999999;

export const PROJECTS_PROJECT_SELECTION_BACKUP = 'PROJECTS_PROJECT_SELECTION_BACKUP';

export const PROJECTS_ADD_PROJECT_BEGIN = 'PROJECTS_ADD_PROJECT_BEGIN';
export const PROJECTS_ADD_PROJECT_SUCCESS = 'PROJECTS_ADD_PROJECT_SUCCESS';
export const PROJECTS_ADD_PROJECT_FAILURE = 'PROJECTS_ADD_PROJECT_FAILURE';
export const PROJECTS_ADD_PROJECT_DISMISS_ERROR = 'PROJECTS_ADD_PROJECT_DISMISS_ERROR';
export const PROJECTS_GET_PROJECTS_BEGIN = 'PROJECTS_GET_PROJECTS_BEGIN';
export const PROJECTS_GET_PROJECTS_SUCCESS = 'PROJECTS_GET_PROJECTS_SUCCESS';
export const PROJECTS_GET_PROJECTS_FAILURE = 'PROJECTS_GET_PROJECTS_FAILURE';
export const PROJECTS_GET_PROJECTS_DISMISS_ERROR = 'PROJECTS_GET_PROJECTS_DISMISS_ERROR';
export const PROJECT_GET_PROJECT_FIELD_VALUES_BEGIN = 'PROJECT_GET_PROJECT_FIELD_VALUES_BEGIN';
export const PROJECT_GET_PROJECT_FIELD_VALUES_SUCCESS = 'PROJECT_GET_PROJECT_FIELD_VALUES_SUCCESS';
export const PROJECT_GET_PROJECT_FIELD_VALUES_FAILURE = 'PROJECT_GET_PROJECT_FIELD_VALUES_FAILURE';
export const PROJECT_GET_PROJECT_FIELD_VALUES_DISMISS_ERROR = 'PROJECT_GET_PROJECT_FIELD_VALUES_DISMISS_ERROR';
export const PROJECTS_UPDATE_PROJECT_BEGIN = 'PROJECTS_UPDATE_PROJECT_BEGIN';
export const PROJECTS_UPDATE_PROJECT_SUCCESS = 'PROJECTS_UPDATE_PROJECT_SUCCESS';
export const PROJECTS_UPDATE_PROJECT_FAILURE = 'PROJECTS_UPDATE_PROJECT_FAILURE';
export const PROJECTS_UPDATE_PROJECT_DISMISS_ERROR = 'PROJECTS_UPDATE_PROJECT_DISMISS_ERROR';
export const PROJECTS_ADD_PROJECT_ROLES_BEGIN = 'PROJECTS_ADD_PROJECT_ROLES_BEGIN';
export const PROJECTS_ADD_PROJECT_ROLES_SUCCESS = 'PROJECTS_ADD_PROJECT_ROLES_SUCCESS';
export const PROJECTS_ADD_PROJECT_ROLES_FAILURE = 'PROJECTS_ADD_PROJECT_ROLES_FAILURE';
export const PROJECTS_ADD_PROJECT_ROLES_DISMISS_ERROR = 'PROJECTS_ADD_PROJECT_ROLES_DISMISS_ERROR';
export const PROJECTS_GET_PROJECT_ROLE_AVAILABILITIES_BEGIN = 'PROJECTS_GET_PROJECT_ROLE_AVAILABILITIES_BEGIN';
export const PROJECTS_GET_PROJECT_ROLE_AVAILABILITIES_SUCCESS = 'PROJECTS_GET_PROJECT_ROLE_AVAILABILITIES_SUCCESS';
export const PROJECTS_GET_PROJECT_ROLE_AVAILABILITIES_FAILURE = 'PROJECTS_GET_PROJECT_ROLE_AVAILABILITIES_FAILURE';
export const PROJECTS_GET_PROJECT_ROLE_AVAILABILITIES_DISMISS_ERROR = 'PROJECTS_GET_PROJECT_ROLE_AVAILABILITIES_DISMISS_ERROR';
export const PROJECTS_ADD_PROJECT_ROLE_ALLOCATION_BEGIN = 'PROJECTS_ADD_PROJECT_ROLE_ALLOCATION_BEGIN';
export const PROJECTS_ADD_PROJECT_ROLE_ALLOCATION_SUCCESS = 'PROJECTS_ADD_PROJECT_ROLE_ALLOCATION_SUCCESS';
export const PROJECTS_ADD_PROJECT_ROLE_ALLOCATION_FAILURE = 'PROJECTS_ADD_PROJECT_ROLE_ALLOCATION_FAILURE';
export const PROJECTS_ADD_PROJECT_ROLE_ALLOCATION_DISMISS_ERROR = 'PROJECTS_ADD_PROJECT_ROLE_ALLOCATION_DISMISS_ERROR';
export const PROJECTS_GET_PROJECT_ALLOCATIONS_BEGIN = 'PROJECTS_GET_PROJECT_ALLOCATIONS_BEGIN';
export const PROJECTS_GET_PROJECT_ALLOCATIONS_SUCCESS = 'PROJECTS_GET_PROJECT_ALLOCATIONS_SUCCESS';
export const PROJECTS_GET_PROJECT_ALLOCATIONS_FAILURE = 'PROJECTS_GET_PROJECT_ALLOCATIONS_FAILURE';
export const PROJECTS_GET_PROJECT_ALLOCATIONS_DISMISS_ERROR = 'PROJECTS_GET_PROJECT_ALLOCATIONS_DISMISS_ERROR';
export const PROJECTS_GET_PROJECT_BEGIN = 'PROJECTS_GET_PROJECT_BEGIN';
export const PROJECTS_GET_PROJECT_SUCCESS = 'PROJECTS_GET_PROJECT_SUCCESS';
export const PROJECTS_GET_PROJECT_FAILURE = 'PROJECTS_GET_PROJECT_FAILURE';
export const PROJECTS_GET_PROJECT_DISMISS_ERROR = 'PROJECTS_GET_PROJECT_DISMISS_ERROR';
export const PROJECTS_REPLACE_PROJECT_ROLE_ALLOCATION_BEGIN = 'PROJECTS_REPLACE_PROJECT_ROLE_ALLOCATION_BEGIN';
export const PROJECTS_REPLACE_PROJECT_ROLE_ALLOCATION_SUCCESS = 'PROJECTS_REPLACE_PROJECT_ROLE_ALLOCATION_SUCCESS';
export const PROJECTS_REPLACE_PROJECT_ROLE_ALLOCATION_FAILURE = 'PROJECTS_REPLACE_PROJECT_ROLE_ALLOCATION_FAILURE';
export const PROJECTS_REPLACE_PROJECT_ROLE_ALLOCATION_DISMISS_ERROR = 'PROJECTS_REPLACE_PROJECT_ROLE_ALLOCATION_DISMISS_ERROR';
export const PROJECTS_DELETE_PROJECT_ROLE_BEGIN = 'PROJECTS_DELETE_PROJECT_ROLE_BEGIN';
export const PROJECTS_DELETE_PROJECT_ROLE_SUCCESS = 'PROJECTS_DELETE_PROJECT_ROLE_SUCCESS';
export const PROJECTS_DELETE_PROJECT_ROLE_FAILURE = 'PROJECTS_DELETE_PROJECT_ROLE_FAILURE';
export const PROJECTS_DELETE_PROJECT_ROLE_DISMISS_ERROR = 'PROJECTS_DELETE_PROJECT_ROLE_DISMISS_ERROR';
export const PROJECTS_DELETE_PROJECT_BEGIN = 'PROJECTS_DELETE_PROJECT_BEGIN';
export const PROJECTS_DELETE_PROJECT_SUCCESS = 'PROJECTS_DELETE_PROJECT_SUCCESS';
export const PROJECTS_DELETE_PROJECT_FAILURE = 'PROJECTS_DELETE_PROJECT_FAILURE';
export const PROJECTS_DELETE_PROJECT_DISMISS_ERROR = 'PROJECTS_DELETE_PROJECT_DISMISS_ERROR';
export const PROJECTS_DELETE_PROJECT_ALLOCATION_BEGIN = 'PROJECTS_DELETE_PROJECT_ALLOCATION_BEGIN';
export const PROJECTS_DELETE_PROJECT_ALLOCATION_SUCCESS = 'PROJECTS_DELETE_PROJECT_ALLOCATION_SUCCESS';
export const PROJECTS_DELETE_PROJECT_ALLOCATION_FAILURE = 'PROJECTS_DELETE_PROJECT_ALLOCATION_FAILURE';
export const PROJECTS_DELETE_PROJECT_ALLOCATION_DISMISS_ERROR = 'PROJECTS_DELETE_PROJECT_ALLOCATION_DISMISS_ERROR';
export const PROJECTS_UPDATE_PROJECT_ROLE_BEGIN = 'PROJECTS_UPDATE_PROJECT_ROLE_BEGIN';
export const PROJECTS_UPDATE_PROJECT_ROLE_SUCCESS = 'PROJECTS_UPDATE_PROJECT_ROLE_SUCCESS';
export const PROJECTS_UPDATE_PROJECT_ROLE_FAILURE = 'PROJECTS_UPDATE_PROJECT_ROLE_FAILURE';
export const PROJECTS_UPDATE_PROJECT_ROLE_DISMISS_ERROR = 'PROJECTS_UPDATE_PROJECT_ROLE_DISMISS_ERROR';
export const PROJECTS_UPDATE_PROJECT_ROLE_ALLOCATION_BEGIN = 'PROJECTS_UPDATE_PROJECT_ROLE_ALLOCATION_BEGIN';
export const PROJECTS_UPDATE_PROJECT_ROLE_ALLOCATION_SUCCESS = 'PROJECTS_UPDATE_PROJECT_ROLE_ALLOCATION_SUCCESS';
export const PROJECTS_UPDATE_PROJECT_ROLE_ALLOCATION_FAILURE = 'PROJECTS_UPDATE_PROJECT_ROLE_ALLOCATION_FAILURE';
export const PROJECTS_UPDATE_PROJECT_ROLE_ALLOCATION_DISMISS_ERROR = 'PROJECTS_UPDATE_PROJECT_ROLE_ALLOCATION_DISMISS_ERROR';
export const PROJECTS_GET_EXPANDED_ALLOCATIONS_BEGIN = 'PROJECTS_GET_EXPANDED_ALLOCATIONS_BEGIN';
export const PROJECTS_GET_EXPANDED_ALLOCATIONS_SUCCESS = 'PROJECTS_GET_EXPANDED_ALLOCATIONS_SUCCESS';
export const PROJECTS_GET_EXPANDED_ALLOCATIONS_FAILURE = 'PROJECTS_GET_EXPANDED_ALLOCATIONS_FAILURE';
export const PROJECTS_GET_EXPANDED_ALLOCATIONS_DISMISS_ERROR = 'PROJECTS_GET_EXPANDED_ALLOCATIONS_DISMISS_ERROR';
export const PROJECTS_GET_FILTERED_PROJECTS_BEGIN = 'PROJECTS_GET_FILTERED_PROJECTS_BEGIN';
export const PROJECTS_GET_FILTERED_PROJECTS_SUCCESS = 'PROJECTS_GET_FILTERED_PROJECTS_SUCCESS';
export const PROJECTS_GET_FILTERED_PROJECTS_FAILURE = 'PROJECTS_GET_FILTERED_PROJECTS_FAILURE';
export const PROJECTS_GET_FILTERED_PROJECTS_DISMISS_ERROR = 'PROJECTS_GET_FILTERED_PROJECTS_DISMISS_ERROR';
export const PROJECTS_DELETE_PROJECT_PHASE_BEGIN = 'PROJECTS_DELETE_PROJECT_PHASE_BEGIN';
export const PROJECTS_DELETE_PROJECT_PHASE_SUCCESS = 'PROJECTS_DELETE_PROJECT_PHASE_SUCCESS';
export const PROJECTS_DELETE_PROJECT_PHASE_FAILURE = 'PROJECTS_DELETE_PROJECT_PHASE_FAILURE';
export const PROJECTS_DELETE_PROJECT_PHASE_DISMISS_ERROR = 'PROJECTS_DELETE_PROJECT_PHASE_DISMISS_ERROR';
export const PROJECTS_DELETE_PROJECT_SUB_PHASE_BEGIN = 'PROJECTS_DELETE_PROJECT_SUB_PHASE_BEGIN';
export const PROJECTS_DELETE_PROJECT_SUB_PHASE_SUCCESS = 'PROJECTS_DELETE_PROJECT_SUB_PHASE_SUCCESS';
export const PROJECTS_DELETE_PROJECT_SUB_PHASE_FAILURE = 'PROJECTS_DELETE_PROJECT_SUB_PHASE_FAILURE';
export const PROJECTS_DELETE_PROJECT_SUB_PHASE_DISMISS_ERROR = 'PROJECTS_DELETE_PROJECT_SUB_PHASE_DISMISS_ERROR';
export const PROJECTS_ADD_PROJECT_PHASES_BEGIN = 'PROJECTS_ADD_PROJECT_PHASES_BEGIN';
export const PROJECTS_ADD_PROJECT_PHASES_SUCCESS = 'PROJECTS_ADD_PROJECT_PHASES_SUCCESS';
export const PROJECTS_ADD_PROJECT_PHASES_FAILURE = 'PROJECTS_ADD_PROJECT_PHASES_FAILURE';
export const PROJECTS_ADD_PROJECT_PHASES_DISMISS_ERROR = 'PROJECTS_ADD_PROJECT_PHASES_DISMISS_ERROR';
export const PROJECTS_ADD_PROJECT_SUB_PHASE_BEGIN = 'PROJECTS_ADD_PROJECT_SUB_PHASE_BEGIN';
export const PROJECTS_ADD_PROJECT_SUB_PHASE_SUCCESS = 'PROJECTS_ADD_PROJECT_SUB_PHASE_SUCCESS';
export const PROJECTS_ADD_PROJECT_SUB_PHASE_FAILURE = 'PROJECTS_ADD_PROJECT_SUB_PHASE_FAILURE';
export const PROJECTS_ADD_PROJECT_SUB_PHASE_DISMISS_ERROR = 'PROJECTS_ADD_PROJECT_SUB_PHASE_DISMISS_ERROR';
export const PROJECTS_GET_PROJECT_NAMES_BEGIN = 'PROJECTS_GET_PROJECT_NAMES_BEGIN';
export const PROJECTS_GET_PROJECT_NAMES_SUCCESS = 'PROJECTS_GET_PROJECT_NAMES_SUCCESS';
export const PROJECTS_GET_PROJECT_NAMES_FAILURE = 'PROJECTS_GET_PROJECT_NAMES_FAILURE';
export const PROJECTS_GET_PROJECT_NAMES_DISMISS_ERROR = 'PROJECTS_GET_PROJECT_NAMES_DISMISS_ERROR';
export const PROJECTS_COLLECT_PROJECT_TABLE_DATA_BEGIN = 'PROJECTS_COLLECT_PROJECT_TABLE_DATA_BEGIN';
export const PROJECTS_COLLECT_PROJECT_TABLE_DATA_SUCCESS = 'PROJECTS_COLLECT_PROJECT_TABLE_DATA_SUCCESS';
export const PROJECTS_COLLECT_PROJECT_TABLE_DATA_FAILURE = 'PROJECTS_COLLECT_PROJECT_TABLE_DATA_FAILURE';
export const PROJECTS_COLLECT_PROJECT_TABLE_DATA_DISMISS_ERROR = 'PROJECTS_COLLECT_PROJECT_TABLE_DATA_DISMISS_ERROR';
export const PROJECTS_UPDATE_PROJECT_PHASE_BEGIN = 'PROJECTS_UPDATE_PROJECT_PHASE_BEGIN';
export const PROJECTS_UPDATE_PROJECT_PHASE_SUCCESS = 'PROJECTS_UPDATE_PROJECT_PHASE_SUCCESS';
export const PROJECTS_UPDATE_PROJECT_PHASE_FAILURE = 'PROJECTS_UPDATE_PROJECT_PHASE_FAILURE';
export const PROJECTS_UPDATE_PROJECT_PHASE_DISMISS_ERROR = 'PROJECTS_UPDATE_PROJECT_PHASE_DISMISS_ERROR';
export const PROJECTS_UPDATE_PROJECT_SUB_PHASE_BEGIN = 'PROJECTS_UPDATE_PROJECT_SUB_PHASE_BEGIN';
export const PROJECTS_UPDATE_PROJECT_SUB_PHASE_SUCCESS = 'PROJECTS_UPDATE_PROJECT_SUB_PHASE_SUCCESS';
export const PROJECTS_UPDATE_PROJECT_SUB_PHASE_FAILURE = 'PROJECTS_UPDATE_PROJECT_SUB_PHASE_FAILURE';
export const PROJECTS_UPDATE_PROJECT_SUB_PHASE_DISMISS_ERROR = 'PROJECTS_UPDATE_PROJECT_SUB_PHASE_DISMISS_ERROR';
export const PROJECTS_UPDATE_PROJECT_FIELD_VALUE_BEGIN = 'PROJECTS_UPDATE_PROJECT_FIELD_VALUE_BEGIN';
export const PROJECTS_UPDATE_PROJECT_FIELD_VALUE_SUCCESS = 'PROJECTS_UPDATE_PROJECT_FIELD_VALUE_SUCCESS';
export const PROJECTS_UPDATE_PROJECT_FIELD_VALUE_FAILURE = 'PROJECTS_UPDATE_PROJECT_FIELD_VALUE_FAILURE';
export const PROJECTS_UPDATE_PROJECT_FIELD_VALUE_DISMISS_ERROR = 'PROJECTS_UPDATE_PROJECT_FIELD_VALUE_DISMISS_ERROR';
export const PROJECTS_REFRESH_ALLOCATION_META_BEGIN = 'PROJECTS_REFRESH_ALLOCATION_META_BEGIN';
export const PROJECTS_REFRESH_ALLOCATION_META_SUCCESS = 'PROJECTS_REFRESH_ALLOCATION_META_SUCCESS';
export const PROJECTS_REFRESH_ALLOCATION_META_FAILURE = 'PROJECTS_REFRESH_ALLOCATION_META_FAILURE';
export const PROJECTS_REFRESH_ALLOCATION_META_DISMISS_ERROR = 'PROJECTS_REFRESH_ALLOCATION_META_DISMISS_ERROR';
export const PROJECTS_GET_PROJECT_BY_ID_BEGIN = 'PROJECTS_GET_PROJECT_BY_ID_BEGIN';
export const PROJECTS_GET_PROJECT_BY_ID_SUCCESS = 'PROJECTS_GET_PROJECT_BY_ID_SUCCESS';
export const PROJECTS_GET_PROJECT_BY_ID_FAILURE = 'PROJECTS_GET_PROJECT_BY_ID_FAILURE';
export const PROJECTS_GET_PROJECT_BY_ID_DISMISS_ERROR = 'PROJECTS_GET_PROJECT_BY_ID_DISMISS_ERROR';
export const PROJECTS_CLEAR_PROJECT_SELECTION = 'PROJECTS_CLEAR_PROJECT_SELECTION';
export const PROJECT_LIST_SELECTION_ID = 'PROJECT_LIST_SELECTION_ID';
export const PROJECT_GANTT_SELECTION_ID = 'PROJECT_GANTT_SELECTION_ID';
export const PROJECTS_CLEAR_ALL_PROJECT_SELECTIONS = 'PROJECTS_CLEAR_ALL_PROJECT_SELECTIONS';
export const PROJECTS_REFRESH_PROJECT_ALLOCATIONS_BEGIN = 'PROJECTS_REFRESH_PROJECT_ALLOCATIONS_BEGIN';
export const PROJECTS_REFRESH_PROJECT_ALLOCATIONS_SUCCESS = 'PROJECTS_REFRESH_PROJECT_ALLOCATIONS_SUCCESS';
export const PROJECTS_REFRESH_PROJECT_ALLOCATIONS_FAILURE = 'PROJECTS_REFRESH_PROJECT_ALLOCATIONS_FAILURE';
export const PROJECTS_REFRESH_PROJECT_ALLOCATIONS_DISMISS_ERROR = 'PROJECTS_REFRESH_PROJECT_ALLOCATIONS_DISMISS_ERROR';
export const PROJECTS_BACKUP_PROJECT_SELECTION = 'PROJECTS_BACKUP_PROJECT_SELECTION';
export const PROJECTS_UPDATE_PROJECT_DURATION_BEGIN = 'PROJECTS_UPDATE_PROJECT_DURATION_BEGIN';
export const PROJECTS_UPDATE_PROJECT_DURATION_SUCCESS = 'PROJECTS_UPDATE_PROJECT_DURATION_SUCCESS';
export const PROJECTS_UPDATE_PROJECT_DURATION_FAILURE = 'PROJECTS_UPDATE_PROJECT_DURATION_FAILURE';
export const PROJECTS_UPDATE_PROJECT_DURATION_DISMISS_ERROR = 'PROJECTS_UPDATE_PROJECT_DURATION_DISMISS_ERROR';
export const PROJECTS_GET_PROJECT_DEFAULTS_BEGIN = 'PROJECTS_GET_PROJECT_DEFAULTS_BEGIN';
export const PROJECTS_GET_PROJECT_DEFAULTS_SUCCESS = 'PROJECTS_GET_PROJECT_DEFAULTS_SUCCESS';
export const PROJECTS_GET_PROJECT_DEFAULTS_FAILURE = 'PROJECTS_GET_PROJECT_DEFAULTS_FAILURE';
export const PROJECTS_GET_PROJECT_DEFAULTS_DISMISS_ERROR = 'PROJECTS_GET_PROJECT_DEFAULTS_DISMISS_ERROR';
export const TRACK_PROJECT_UPDATE_CANCELED = 'TRACK_PROJECT_UPDATE_CANCELED';
export const TRACK_PROJECT_UPDATE_REACTIVATED = 'TRACK_PROJECT_UPDATE_REACTIVATED';
export const TRACK_PROJECT_UPDATE_LOST = 'TRACK_PROJECT_UPDATE_LOST';
export const TRACK_PROJECT_UPDATE_TYPE = 'TRACK_PROJECT_UPDATE_TYPE';
export const PROJECTS_SEND_ROLE_NOTIFICATION_BEGIN = 'PROJECTS_SEND_ROLE_NOTIFICATION_BEGIN';
export const PROJECTS_SEND_ROLE_NOTIFICATION_SUCCESS = 'PROJECTS_SEND_ROLE_NOTIFICATION_SUCCESS';
export const PROJECTS_SEND_ROLE_NOTIFICATION_FAILURE = 'PROJECTS_SEND_ROLE_NOTIFICATION_FAILURE';
export const PROJECTS_SEND_ROLE_NOTIFICATION_DISMISS_ERROR = 'PROJECTS_SEND_ROLE_NOTIFICATION_DISMISS_ERROR';
export const PROJECTS_ADD_PROJECT_NOTE_BEGIN = 'PROJECTS_ADD_PROJECT_NOTE_BEGIN';
export const PROJECTS_ADD_PROJECT_NOTE_SUCCESS = 'PROJECTS_ADD_PROJECT_NOTE_SUCCESS';
export const PROJECTS_ADD_PROJECT_NOTE_FAILURE = 'PROJECTS_ADD_PROJECT_NOTE_FAILURE';
export const PROJECTS_ADD_PROJECT_NOTE_DISMISS_ERROR = 'PROJECTS_ADD_PROJECT_NOTE_DISMISS_ERROR';
export const PROJECTS_DELETE_PROJECT_NOTE_BEGIN = 'PROJECTS_DELETE_PROJECT_NOTE_BEGIN';
export const PROJECTS_DELETE_PROJECT_NOTE_SUCCESS = 'PROJECTS_DELETE_PROJECT_NOTE_SUCCESS';
export const PROJECTS_DELETE_PROJECT_NOTE_FAILURE = 'PROJECTS_DELETE_PROJECT_NOTE_FAILURE';
export const PROJECTS_DELETE_PROJECT_NOTE_DISMISS_ERROR = 'PROJECTS_DELETE_PROJECT_NOTE_DISMISS_ERROR';
export const PROJECTS_UPDATE_PROJECT_NOTE_BEGIN = 'PROJECTS_UPDATE_PROJECT_NOTE_BEGIN';
export const PROJECTS_UPDATE_PROJECT_NOTE_SUCCESS = 'PROJECTS_UPDATE_PROJECT_NOTE_SUCCESS';
export const PROJECTS_UPDATE_PROJECT_NOTE_FAILURE = 'PROJECTS_UPDATE_PROJECT_NOTE_FAILURE';
export const PROJECTS_UPDATE_PROJECT_NOTE_DISMISS_ERROR = 'PROJECTS_UPDATE_PROJECT_NOTE_DISMISS_ERROR';
export const PROJECTS_GET_PROJECT_AUDIT_BEGIN = 'PROJECTS_GET_PROJECT_AUDIT_BEGIN';
export const PROJECTS_GET_PROJECT_AUDIT_SUCCESS = 'PROJECTS_GET_PROJECT_AUDIT_SUCCESS';
export const PROJECTS_GET_PROJECT_AUDIT_FAILURE = 'PROJECTS_GET_PROJECT_AUDIT_FAILURE';
export const PROJECTS_GET_PROJECT_AUDIT_DISMISS_ERROR = 'PROJECTS_GET_PROJECT_AUDIT_DISMISS_ERROR';
export const PROJECTS_GET_PROJECT_ROLES_BEGIN = 'PROJECTS_GET_PROJECT_ROLES_BEGIN';
export const PROJECTS_GET_PROJECT_ROLES_SUCCESS = 'PROJECTS_GET_PROJECT_ROLES_SUCCESS';
export const PROJECTS_GET_PROJECT_ROLES_FAILURE = 'PROJECTS_GET_PROJECT_ROLES_FAILURE';
export const PROJECTS_GET_PROJECT_ROLES_DISMISS_ERROR = 'PROJECTS_GET_PROJECT_ROLES_DISMISS_ERROR';
export const PROJECTS_GET_PROJECT_ROLE_BEGIN = 'PROJECTS_GET_PROJECT_ROLE_BEGIN';
export const PROJECTS_GET_PROJECT_ROLE_SUCCESS = 'PROJECTS_GET_PROJECT_ROLE_SUCCESS';
export const PROJECTS_GET_PROJECT_ROLE_FAILURE = 'PROJECTS_GET_PROJECT_ROLE_FAILURE';
export const PROJECTS_GET_PROJECT_ROLE_DISMISS_ERROR = 'PROJECTS_GET_PROJECT_ROLE_DISMISS_ERROR';
