import React, { useState, useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Grid, Paper, Button } from '@material-ui/core';
import { PersonPropDisplayNames } from 'src/common/descriptors/person';
import { PROJECT_DISPLAY_NAMES } from 'src/common/descriptors/project';
import { HOURLY_PERSON_DISPLAY_NAMES } from 'src/common/descriptors/hourlyPerson';
import { SKILL_SET_FIELD } from 'src/features/self-perform/constants';
import { PURSUIT_TRACKING_FIELD, ACCOUNT_MODULE_PURSUIT_TRACKING } from 'src/common/constants';
import { MAX_ROLE_NAME_LENGTH, MAX_LIST_ITEM_LENGTH } from './redux/constants';
import {
  removeRoleNames,
  updateRoleNames,
  updatePersonFields,
  updateHourlyPersonFields,
  updateProjectFields,
  updateSkillSet,
  addPersonFields,
  addHourlyPersonFields,
  addProjectFields,
  removePersonFields,
  removeHourlyPersonFields,
  removeProjectFields,
  removeSkill,
  dismissUpdateProjectFieldsError,
  dismissUpdatePersonFieldsError,
  dismissUpdateHourlyPersonFieldsError,
} from './redux/actions';
import {
  ProfileFields,
  SelectionLists,
  CustomLists,
  SortList,
  Phases,
  DeactivatedPeople,
  Certifications,
  SystemDefaults,
} from '.';
import { Modal, PageHeader } from '../common';
import { cloneDeep } from '../../utils/miscUtils';
import {
  SETTINGS_TABS,
  SETTINGS_SIDE_TABS,
  SETTINGS_SIDE_TABS_LIST_HEADLINES,
  SETTINGS_SIDE_TABS_FIELD_HEADLINES,
  LIST_ITEM_IN_USE_MODAL_TEXT,
  ADDITIONAL_DEFAULT_FIELDS,
} from './common/constants';
import { hasModuleEnabled } from '../permissions/utils/permissionUtils';
import RoleTemplatesContainer from './RoleTemplatesContainer';

const { PEOPLE, PROJECTS, SELF_PERFORM } = SETTINGS_TABS;
const { ROLES, CUSTOM, SYSTEM, PHASES, DEACTIVATED_PEOPLE, CERTIFICATIONS } = SETTINGS_SIDE_TABS;

function ContentSettings({
  activeTab,
  activeSideTab,
}) {
  const dispatch = useDispatch();
  const accountSettings = useSelector(({ accountSettings }) => accountSettings);
  const accountId = useSelector(({ common: { accountId } }) => accountId);
  const hourlyPeopleFieldValues = useSelector(({ selfPerform: { hourlyPeopleFieldValues } }) => hourlyPeopleFieldValues);
  const personFieldValues = useSelector(({ people: { personFieldValues } }) => personFieldValues);
  const projectFieldValues = useSelector(({ projects: { projectFieldValues } }) => projectFieldValues);

  const [newFields, setNewFields] = useState([]);
  const [selectedListName, setSelectedListName] = useState('');
  const [selectionLists, setSelectionLists] = useState([]);
  const [addingNewList, setAddingNewList] = useState(false);

  const defaultValuesSectionRef = useRef(null);

  const fieldSet = useMemo(() => {
    switch (activeTab) {
      case PEOPLE:
        return accountSettings.personFields;
      case PROJECTS:
        return accountSettings.projectFields;
      case SELF_PERFORM:
        return accountSettings.hourlyPersonFields;
      default:
        return [];
    }
  }, [activeTab, accountSettings.hourlyPersonFields, accountSettings.personFields, accountSettings.projectFields]);

  useMemo(() => {
    let activeSelectionList = [];
    if (activeTab === PEOPLE) {
      activeSelectionList = accountSettings.personFields.filter(field => field.type === 'SingleSelect' || field.type === 'MultiSelect');
    } else if (activeTab === PROJECTS) {
      if (activeSideTab === ROLES) {
        activeSelectionList = [{
          id: 'roles',
          name: 'Roles',
          type: 'SingleSelect',
          isRequired: true,
          definedValues: accountSettings.roles,
        }];
      } else {
        activeSelectionList = accountSettings.projectFields.filter(field => field.type === 'SingleSelect' || field.type === 'MultiSelect');
      }
    } else if (activeTab === SELF_PERFORM) {
      activeSelectionList = [{
        id: 'skill-set',
        name: SKILL_SET_FIELD,
        type: 'MultiSelect',
        isRequired: true,
        isSystem: true,
        definedValues: accountSettings.skillSet,
      }];
      activeSelectionList.push(...accountSettings.hourlyPersonFields.filter(field => field.type === 'SingleSelect' || field.type === 'MultiSelect'));
    }
    setSelectionLists(activeSelectionList);
  }, [
    activeTab,
    activeSideTab,
    accountSettings.personFields,
    accountSettings.hourlyPersonFields,
    accountSettings.roles,
    accountSettings.projectFields,
    accountSettings.skillSet,
  ]);

  useEffect(() => {
    setNewFields([]);
  }, [
    activeTab,
    activeSideTab,
    accountSettings.personFields,
    accountSettings.hourlyPersonFields,
    accountSettings.projectFields,
  ]);

  useEffect(() => {
    setSelectedListName('');
    setAddingNewList(false);
  }, [
    activeTab,
    activeSideTab,
  ]);

  const deleteListItem = cb => (id) => {
    dispatch(cb(accountId, [id]));
  };

  const updateList = cb => (listItems, alphabetize, hasUpdateEvent = true) => {
    const mappedItems = listItems.map(({ id, name }) => ({ id, name }));

    dispatch(cb(accountId, mappedItems, alphabetize, hasUpdateEvent));
  };

  const updateFields = (newFields, type, cb) => {
    if (type === 'list') {
      const selectionLists = fieldSet.filter(field => field.type === 'SingleSelect' || field.type === 'MultiSelect');
      const filteredNewFields = newFields.filter(field => field.type === 'SingleSelect' || field.type === 'MultiSelect');
      setSelectionLists([...selectionLists, ...filteredNewFields]);
    }

    setNewFields(newFields);
    if (cb) cb();
  };

  const onEditFields = (accountId, fields) => {
    if (activeTab === PEOPLE) {
      dispatch(updatePersonFields(accountId, fields));
    } else if (activeTab === PROJECTS) {
      dispatch(updateProjectFields(accountId, fields));
    } else if (activeTab === SELF_PERFORM) {
      dispatch(updateHourlyPersonFields(accountId, fields));
    }
  };

  const updateSelectionList = (listName, values, existingListId, preventClose) => {
    if (existingListId) {
      const field = fieldSet.find(field => field.id === existingListId);
      const data = [{
        ...field,
        definedValues: values,
      }];
      onEditFields(accountId, data);
      updateFields([], 'list');
    } else {
      const clonedFields = cloneDeep(newFields);
      const field = clonedFields.find(field => field.name === listName);

      if (field) {
        field.definedValues = values;
      }

      updateFields(clonedFields, 'list');
    }

    if (!preventClose) {
      setSelectedListName('');
      setAddingNewList(false);
    }
  };

  const removeField = (index, type) => {
    if (type === 'list') {
      const remainingFields = newFields.slice(0, index).concat(newFields.slice(index + 1, newFields.length));
      const selectionLists = fieldSet.filter(field => field.type === 'SingleSelect' || field.type === 'MultiSelect');
      const filteredNewFields = remainingFields.filter(field => field.type === 'SingleSelect' || field.type === 'MultiSelect');
      setNewFields(remainingFields);
      setSelectionLists([...selectionLists, ...filteredNewFields]);
    } else {
      setNewFields(newFields.slice(0, index).concat(newFields.slice(index + 1, newFields.length)));
    }
  };

  const onAddFields = (accountId, fields) => {
    if (activeTab === PEOPLE) {
      dispatch(addPersonFields(accountId, fields));
    } else if (activeTab === PROJECTS) {
      dispatch(addProjectFields(accountId, fields));
    } else if (activeTab === SELF_PERFORM) {
      dispatch(addHourlyPersonFields(accountId, fields));
    }
  };

  const onDeleteFields = (accountId, fields) => {
    const selectedList = selectionLists.find(list => list.id === fields[0]);

    if (selectedList) {
      setSelectedListName('');
    }

    if (activeTab === PEOPLE) {
      dispatch(removePersonFields(accountId, fields));
    } else if (activeTab === PROJECTS) {
      dispatch(removeProjectFields(accountId, fields));
    } else if (activeTab === SELF_PERFORM) {
      dispatch(removeHourlyPersonFields(accountId, fields));
    }
  };

  const hideModal = () => {
    if (accountSettings.updateProjectFieldsError) {
      dispatch(dismissUpdateProjectFieldsError());
    }
    if (accountSettings.updatePersonFieldsError) {
      dispatch(dismissUpdatePersonFieldsError());
    }
    if (accountSettings.updateHourlyPersonFieldsError) {
      dispatch(dismissUpdateHourlyPersonFieldsError());
    }
  };

  const selectList = (list) => {
    setSelectedListName((list && list.name) || '');
    setAddingNewList(!!list && !list.id);
  };

  const getAdditionalFields = (type) => {
    const mapDescriptor = (additionalFields, descriptorNames) => {
      const systemFieldNames = additionalFields.map(f => f.name);

      return descriptorNames.reduce((acc, name) => {
        if (!systemFieldNames.includes(name) && name.length) {
          acc.push({
            name,
            hidden: true,
          });
        }
        return acc;
      }, additionalFields);
    };

    // Some fields are columns and not actually editable fields, we need them here to prevent creating a field with the same title as them.
    // They are defined as hidden.
    let additionalFields = ADDITIONAL_DEFAULT_FIELDS[type];
    switch (type) {
      case PEOPLE:
        return mapDescriptor(additionalFields, PersonPropDisplayNames);
      case PROJECTS:
        if (!hasModuleEnabled(accountSettings.accountModules, ACCOUNT_MODULE_PURSUIT_TRACKING)) {
          additionalFields = additionalFields.filter(field => field.name !== PURSUIT_TRACKING_FIELD);
        }
        return mapDescriptor(additionalFields, PROJECT_DISPLAY_NAMES);
      case SELF_PERFORM:
        return mapDescriptor(additionalFields, HOURLY_PERSON_DISPLAY_NAMES);
      default:
        return [];
    }
  };

  const compareListName = list => list.name === selectedListName;

  const renderFullList = (lists) => {
    if (selectedListName) {
      const customList = lists.find(compareListName);
      if (customList) {
        if (activeSideTab === ROLES) {
          return (
            <SortList
              updateList={updateList(updateRoleNames)}
              removeItem={deleteListItem(removeRoleNames)}
              list={customList}
              inUseModalText={LIST_ITEM_IN_USE_MODAL_TEXT.ROLES}
              pendingUpdate={accountSettings.updateRoleNamesPending}
              maxItemLength={MAX_ROLE_NAME_LENGTH}
            />
          );
        }

        if (activeTab === SELF_PERFORM && activeSideTab === SYSTEM) {
          return (
            <SortList
              updateList={updateList(updateSkillSet)}
              removeItem={deleteListItem(removeSkill)}
              list={customList}
              inUseModalText={LIST_ITEM_IN_USE_MODAL_TEXT.REQUIRED}
              pendingUpdate={accountSettings.updateSkillSetPending}
              maxItemLength={MAX_LIST_ITEM_LENGTH}
            />
          );
        }

        return (
          <CustomLists
            onCancel={selectList}
            updateSelectionList={updateSelectionList}
            list={customList}
            fieldValues={activeTab === PEOPLE ? personFieldValues : projectFieldValues}
          />
        );
      }
    }

    return null;
  };

  const handleClickManageDefaultsButton = () => {
    defaultValuesSectionRef?.current.scrollIntoView({ behavior: 'smooth' });
  };

  const renderLists = () => {
    if (activeTab === PROJECTS && activeSideTab === SYSTEM) {
      return null;
    }

    let lists;
    if (activeSideTab === SYSTEM) {
      lists = selectionLists.filter(list => list.isSystem);
    } else if (activeSideTab === CUSTOM) {
      lists = selectionLists.filter(list => !list.isSystem);
    } else {
      lists = selectionLists;
    }

    const headline = SETTINGS_SIDE_TABS_LIST_HEADLINES[activeSideTab][activeTab];

    return (
      <Grid item xs={12}>
        <Paper className={classNames('paper__container', 'lists', { dashed: addingNewList })}>
          <Grid container spacing={5}>
            <Grid item xs={6} className="right-column">
              <PageHeader headline={headline} />
              <SelectionLists
                selectList={selectList}
                lists={lists}
                selectedListName={selectedListName}
                disableUnselected={addingNewList}
              />
            </Grid>
            {renderFullList(lists)}
          </Grid>
        </Paper>
      </Grid>
    );
  };

  const profiles = useMemo(() => {
    switch (activeTab) {
      case PEOPLE:
        return personFieldValues;
      case PROJECTS:
        return projectFieldValues;
      case SELF_PERFORM:
        return hourlyPeopleFieldValues;
      default:
        return [];
    }
  }, [
    activeTab,
    personFieldValues,
    projectFieldValues,
    hourlyPeopleFieldValues,
  ]);

  const renderMain = () => {
    switch (activeSideTab) {
      case PHASES:
        return <Phases />;
      case DEACTIVATED_PEOPLE:
        return <DeactivatedPeople />;
      case CERTIFICATIONS:
        return <Certifications />;
      default: {
        const additionalFields = getAdditionalFields(activeTab);
        const headline = activeSideTab !== ROLES ? SETTINGS_SIDE_TABS_FIELD_HEADLINES[activeSideTab][activeTab] : null;

        return (
          <Grid container spacing={3} className="main-wrap">
            {activeSideTab !== ROLES && (
              <Grid item xs={12}>
                <Paper className={`paper__container${newFields.length > 0 ? ' dashed' : ''}`}>
                  <div className="settings-wrap">
                    <PageHeader headline={headline} />
                    <span className="people-field-column-label">Field title</span>
                    <span className="people-field-column-label">Field type</span>
                    <ProfileFields
                      fieldSet={fieldSet}
                      profiles={profiles}
                      additionalFields={additionalFields}
                      newFields={newFields}
                      updateFields={updateFields}
                      removeField={removeField}
                      selectList={selectList}
                      onAddFields={onAddFields}
                      onEditFields={onEditFields}
                      onDeleteFields={onDeleteFields}
                      disabled={addingNewList}
                      activeTab={activeTab}
                      activeSideTab={activeSideTab}
                      handleClickManageDefaultsButton={handleClickManageDefaultsButton}
                    />
                  </div>
                </Paper>
              </Grid>
            )}
            {renderLists()}
            {activeSideTab === ROLES && (
              <RoleTemplatesContainer accountId={accountId} />
            )}
            {activeTab === PEOPLE && activeSideTab === SYSTEM && (
              <SystemDefaults refProp={defaultValuesSectionRef} accountId={accountId} />
            )}
          </Grid>
        );
      }
    }
  };

  return (
    <div className="account-settings-content-settings">
      {(!!accountSettings.updatePersonFieldsError || !!accountSettings.updateProjectFieldsError) && (
        <Modal
          headline="List item"
          showClose={false}
          buttons={[(
            <Button
              key="ok"
              className="button__default"
              disableRipple
              variant="contained"
              size="medium"
              onClick={hideModal}
            >
              Ok
            </Button>
          )]}
        >
          <div>There was a problem updating the list item</div>
        </Modal>
      )}
      {renderMain()}
    </div>
  );
}

ContentSettings.propTypes = {
  activeTab: PropTypes.string.isRequired,
  activeSideTab: PropTypes.string.isRequired,
};

export default ContentSettings;
