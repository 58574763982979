import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PROJECTS_GET_PROJECT_NAMES_BEGIN,
  PROJECTS_GET_PROJECT_NAMES_SUCCESS,
  PROJECTS_GET_PROJECT_NAMES_FAILURE,
  PROJECTS_GET_PROJECT_NAMES_DISMISS_ERROR,
} from './constants';
import { getProjectNamesQuery } from '../queries/getProjectNamesQuery';
import graphApiClient from '../../../common/GraphAPIClient';

export function getProjectNames(accountId, queries = {}) {
  const { query, variables } = getProjectNamesQuery(accountId, queries);
  return {
    type: PROJECTS_GET_PROJECT_NAMES_BEGIN,
    query,
    variables,
  };
}

export function dismissGetProjectNamesError() {
  return {
    type: PROJECTS_GET_PROJECT_NAMES_DISMISS_ERROR,
  };
}

export function* doGetProjectNames(action) {
  const { query, variables } = action;
  let res;
  try {
    res = yield call(graphApiClient.query, { query, variables });
    const { errors } = res;
    if (errors) {
      throw errors;
    }
  } catch (err) {
    yield put({
      type: PROJECTS_GET_PROJECT_NAMES_FAILURE,
      data: { error: err },
    });
    return;
  }


  yield put({
    type: PROJECTS_GET_PROJECT_NAMES_SUCCESS,
    data: res.data,
  });
}

export function* watchGetProjectNames() {
  yield takeLatest(PROJECTS_GET_PROJECT_NAMES_BEGIN, doGetProjectNames);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PROJECTS_GET_PROJECT_NAMES_BEGIN:
      return {
        ...state,
        getProjectNamesPending: true,
        getProjectNamesError: null,
      };

    case PROJECTS_GET_PROJECT_NAMES_SUCCESS:
      return {
        ...state,
        projectNames: action.data.projects.items.map(project => ({ name: project.name, value: project.id, colour: project.colour, state: project.state })),
        getProjectNamesPending: false,
        getProjectNamesError: null,
      };

    case PROJECTS_GET_PROJECT_NAMES_FAILURE:
      return {
        ...state,
        getProjectNamesPending: false,
        getProjectNamesError: action.data.error,
      };

    case PROJECTS_GET_PROJECT_NAMES_DISMISS_ERROR:
      return {
        ...state,
        getProjectNamesError: null,
      };

    default:
      return state;
  }
}
