export function parsePhases(phases, selectedPhases) {
  return phases.map((phase) => {
    const selected = selectedPhases.find(p => p.id === phase.id);
    return {
      ...phase,
      selected: !!selected,
      subPhases: phase.subPhases.map(sub => ({
        ...sub,
        selected: selected ? !!selected.subPhases.find(s => s.id === sub.id) : false,
      })),
    };
  });
}

export function reorderPhases(phases, unorderedPhases) {
  const reorderedPhases = [];

  phases.forEach((phase) => {
    const phaseSelected = unorderedPhases.find(p => p.id === phase.id);
    if (phaseSelected) {
      const reorderedSubs = [];
      phase.subPhases.forEach((sub) => {
        const subSelected = phaseSelected.subPhases.find(s => s.id === sub.id);
        if (subSelected) {
          reorderedSubs.push(sub);
        }
      });
      phaseSelected.subPhases = reorderedSubs;
      reorderedPhases.push(phaseSelected);
    }
  });

  return reorderedPhases;
}

export function affectedPhases(phaseElements, mouseLocation) {
  const affected = [];
  phaseElements.forEach((phaseEl) => {
    const phasePos = phaseEl.getBoundingClientRect();
    const { id, start, end } = phaseEl.dataset;
    if (phasePos.left <= mouseLocation && mouseLocation <= phasePos.right) {
      affected.push({
        id: parseInt(id, 10),
        startDate: start,
        endDate: end,
        elem: phaseEl,
      });
    }
  });
  return affected;
}

export const getQuickOptionLabel = (phases, isSameDates) => {
  if (phases?.length) {
    if (phases.length === 1) {
      return phases[0]?.name;
    }
    return `${phases.length} selected`;
  }

  if (isSameDates) {
    return 'Entire Project';
  }

  return 'Custom';
};
