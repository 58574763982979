export function getConfigValue(key, fallback) {
  const isNullOrUndefined = val => val === null || typeof val === 'undefined';

  const process = global && global.process ? global.process : { env: {} };

  let result;
  if (window.config) {
    result = !isNullOrUndefined(window.config[key]) ? window.config[key] : process.env[key];
  } else if (!isNullOrUndefined(process.env[key])) {
    result = process.env[key];
  }

  if (!isNullOrUndefined(result)) {
    return result;
  }
  return fallback;
}

export default {
  apiDomain: getConfigValue('apiDomain', 'dev-bench.gobridgit.com'),
  graphapiDomain: getConfigValue('graphapiDomain', 'dev-bench.gobridgit.com/graphapi'),
  authDomain: getConfigValue('authDomain', 'dev-bench.gobridgit.com/auth'),
  baseRequestOverride: getConfigValue('baseRequestOverride', null),
  metricsDomain: getConfigValue('metricsDomain', null),
  mapsQueryPath: getConfigValue('mapsQueryPath', 'https://www.google.com/maps/search/'),
  useTokenAuth: getConfigValue('useTokenAuth', true),
  launchDarklyKey: getConfigValue('launchDarklyKey', '5ca23b70761af0081c82a179'),
  mixpanelToken: getConfigValue('mixpanelToken', 'a21df44ca68ec1010e0187a738eeeb02'),
  version: getConfigValue('version', null),
  rollbarKey: getConfigValue('rollbarKey', null),
  environment: getConfigValue('environment', 'development'),
  googleMapsKey: getConfigValue('googleMapsKey', null),
};
