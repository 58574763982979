import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { validateServiceAccountName } from 'src/utils/validators';
import { naturalSort } from 'src/utils/sortUtils';
import { EditControls, ValidatedForm } from '../wrapped-components';
import { addServiceAccountInputs, VALIDATION_ERRORS } from './constants';
import { addServiceAccount } from './redux/actions';

const AddServiceAccountForm = ({ onCancelClick }) => {
  const dispatch = useDispatch();

  const { permissionGroups } = useSelector(({ permissions }) => permissions);
  const { accountId } = useSelector(({ common }) => common);
  const { accounts, addServiceAccountPending } = useSelector(({ serviceAccounts }) => serviceAccounts);

  const addServiceAccountData = useRef(null);
  const [disableSend, setDisableSend] = useState(false);
  const sortedPermissions = useMemo(() => naturalSort(permissionGroups, 'name'), [permissionGroups]);

  const onValueChanged = useCallback((input, values) => {
    addServiceAccountData.current = values;
  }, []);

  const onAddServiceAccount = useCallback(() => {
    const { name, permissionGroup } = addServiceAccountData.current;

    const accountData = {
      accountId,
      name,
      group: permissionGroup,
    };
    const analyticsPayload = { name };

    dispatch(addServiceAccount(accountData, analyticsPayload));
  }, [accountId, dispatch]);

  const validate = useCallback((values) => {
    const { name, permissionGroup } = values;
    const errors = {};

    if (!validateServiceAccountName(name)) {
      errors.name = VALIDATION_ERRORS.INVALID_NAME;
    }

    const existingAccount = accounts.find(account => account.name === name);

    if (existingAccount) {
      errors.name = VALIDATION_ERRORS.DUPLICATE_NAME;
    }

    if (!permissionGroup) {
      errors.permissionGroup = VALIDATION_ERRORS.REQUIRED;
    }

    setDisableSend(!!(errors.name || errors.permissionGroup));

    return errors;
  }, [accounts]);

  return (
    <div className="add-account-container">
      <ValidatedForm
        inputs={addServiceAccountInputs(sortedPermissions)}
        validate={validate}
        onValueChanged={onValueChanged}
        className="form-wrapper"
      />

      <EditControls
        primaryText="Save"
        secondaryText="Cancel"
        primaryAction={onAddServiceAccount}
        secondaryAction={onCancelClick}
        disabled={disableSend}
        pending={addServiceAccountPending}
        className="service-accounts-form-controls"
      />

    </div>
  );
};

AddServiceAccountForm.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
};

export default AddServiceAccountForm;
