import { all } from 'redux-saga/effects';
import * as accountsSagas from '../features/accounts/redux/sagas';
import * as commonSagas from '../features/common/redux/sagas';
import * as settingsSaga from '../features/settings/redux/sagas';
import * as loginSagas from '../features/login/redux/sagas';
import * as peopleSagas from '../features/people/redux/sagas';
import * as projectsSagas from '../features/projects/redux/sagas';
import * as accountSettingsSagas from '../features/account-settings/redux/sagas';
import * as dashboardSagas from '../features/dashboard/redux/sagas';
import * as wrappedComponentsSagas from '../features/wrapped-components/redux/sagas';
import * as tableSagas from '../features/table/redux/sagas';
import * as phasesSagas from '../features/phases/redux/sagas';
import * as ganttSagas from '../features/gantt/redux/sagas';
import * as allocationsSagas from '../features/allocations/redux/sagas';
import * as filtersSagas from '../features/filters/redux/sagas';
import * as reportsSagas from '../features/reports/redux/sagas';
import * as queriesSagas from '../features/queries/redux/sagas';
import * as modalManagerSagas from '../features/modal-manager/redux/sagas';
import * as permissionsSagas from '../features/permissions/redux/sagas';
import * as forecastSagas from '../features/forecast/redux/sagas';
import * as errorsSagas from '../features/errors/redux/sagas';
import * as editDatesSagas from '../features/edit-dates/redux/sagas';
import * as serviceAccountsSagas from '../features/service-accounts/redux/sagas';
import * as selfPerformSagas from '../features/self-perform/redux/sagas';
import * as toastsSagas from '../features/toasts/redux/sagas';
import * as localeSagas from '../features/locale/redux/sagas';
import * as roleSagas from '../features/roles/redux/sagas';

const featureSagas = [
  accountsSagas,
  commonSagas,
  settingsSaga,
  loginSagas,
  peopleSagas,
  projectsSagas,
  accountSettingsSagas,
  dashboardSagas,
  wrappedComponentsSagas,
  tableSagas,
  phasesSagas,
  ganttSagas,
  allocationsSagas,
  filtersSagas,
  reportsSagas,
  queriesSagas,
  modalManagerSagas,
  permissionsSagas,
  forecastSagas,
  errorsSagas,
  editDatesSagas,
  serviceAccountsSagas,
  selfPerformSagas,
  toastsSagas,
  localeSagas,
  roleSagas,
];

const sagas = featureSagas
  .reduce((prev, curr) => [...prev, ...Object.keys(curr).map(k => curr[k])], [])
  // a saga should be function, below filter avoids error if redux/sagas.js is empty;
  .filter(s => typeof s === 'function');

function* rootSaga() {
  yield all(sagas.map(saga => saga()));
}

export default rootSaga;
