import {
  DASHBOARD_CLEAR_UNFILLED_ROLES,
} from './constants';

export function clearUnfilledRoles() {
  return {
    type: DASHBOARD_CLEAR_UNFILLED_ROLES,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case DASHBOARD_CLEAR_UNFILLED_ROLES:
      return {
        ...state,
        unfilledRoles: [],
      };

    default:
      return state;
  }
}
