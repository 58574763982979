import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { CircularProgress, Select, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { People, VisibilityOff } from '@material-ui/icons';
import { FileUploader } from '@bridgit/foundation';
import { getAccountLimits } from '../account-settings/redux/actions';
import { Can, OutlinedInput } from '../wrapped-components';
import { PERM_WRITE, PERM_PERSON, PERM_PRIVATE } from '../permissions/utils/constants';
import { MULTI_STATE_MODAL_ID } from '../common/redux/constants';
import {
  UPLOAD_ATTACHMENT_PUBLIC,
  UPLOAD_ATTACHMENT_PRIVATE,
  PROFILE_UPLOAD_ERRORS,
  PROFILE_UPLOAD_PROPS,
} from './constants';

const ProfileUploadAttachment = ({
  header,
  onUpload,
  pending,
}) => {
  const dispatch = useDispatch();

  const { accountId, totalPeopleAttachmentBytes } = useSelector(({ common, accounts: { entities } }) => {
    const { accountId } = common;
    const account = entities.find(account => account.id === accountId);
    return {
      accountId,
      totalPeopleAttachmentBytes: account?.totalPeopleAttachmentBytes,
    };
  });

  const isOpenInModal = useSelector(({ modalManager }) => modalManager.activeModal === MULTI_STATE_MODAL_ID);

  const {
    accountLimits: { maxPeopleAttachmentBytes },
    getAccountLimitsPending,
  } = useSelector(({ accountSettings }) => accountSettings);

  const [loading, setLoading] = useState(true);
  const [isPrivate, setIsPrivate] = useState(UPLOAD_ATTACHMENT_PUBLIC);

  useEffect(() => {
    dispatch(getAccountLimits(accountId));
  }, [accountId, dispatch]);

  useEffect(() => {
    setLoading(getAccountLimitsPending);
  }, [getAccountLimitsPending]);

  const {
    title,
    subTitle,
    disabled,
  } = useMemo(() => {
    const disabled = totalPeopleAttachmentBytes >= maxPeopleAttachmentBytes;
    return {
      title: disabled ? 'Account file limit reached' : 'Click or drag and drop to upload an attachment',
      subTitle: disabled ? 'Remove files to add more' : 'Maximum file size: 1 GB',
      disabled,
    };
  }, [maxPeopleAttachmentBytes, totalPeopleAttachmentBytes]);

  const onChange = useCallback((evt) => {
    const { value } = evt.target;
    setIsPrivate(value);
  }, []);

  const handleOnUpload = useCallback((file, binary) => {
    onUpload(file, binary, isPrivate === UPLOAD_ATTACHMENT_PRIVATE);
  }, [isPrivate, onUpload]);

  const renderValue = value => `Upload ${value ? 'private' : 'public'} attachment`;

  const renderUploader = () => {
    if (loading || pending) {
      return (
        <div className="attachments-loading">
          <CircularProgress color="primary" size={35} />
          {pending && (
            <div className="attachments-loading-caption">
              <p>Uploading attachment.</p>
              <p>Do not leave or refresh Bench.</p>
            </div>
          )}
        </div>
      );
    }

    return (
      <>
        <FileUploader
          title={title}
          subTitle={subTitle}
          disabled={disabled}
          dropzoneProps={PROFILE_UPLOAD_PROPS}
          errorMessages={PROFILE_UPLOAD_ERRORS}
          onUpload={handleOnUpload}
        />
        <Can
          action={PERM_WRITE}
          subject={PERM_PRIVATE}
          yes={(
            <Select
              classes={{ root: 'private-select' }}
              value={isPrivate}
              onChange={onChange}
              renderValue={renderValue}
              input={<OutlinedInput />}
            >
              <MenuItem value={UPLOAD_ATTACHMENT_PUBLIC}>
                <ListItemIcon>
                  <People color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Everyone" secondary="Visible to everyone with access to Bench" />
              </MenuItem>
              <MenuItem value={UPLOAD_ATTACHMENT_PRIVATE}>
                <ListItemIcon>
                  <VisibilityOff color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Private" secondary="Only visible to those who can see private fields" />
              </MenuItem>
            </Select>
          )}
        />
      </>
    );
  };

  return (
    <Can
      action={PERM_WRITE}
      subject={PERM_PERSON}
      yes={(
        <div className="people-profile-upload-attachment">
          {!isOpenInModal && header && <div className="header">{header}</div>}
          {renderUploader()}
        </div>
      )}
    />
  );
};

ProfileUploadAttachment.propTypes = {
  header: PropTypes.string,
  onUpload: PropTypes.func,
  pending: PropTypes.bool,
};

ProfileUploadAttachment.defaultProps = {
  header: null,
  onUpload: () => {},
  pending: false,
};

export default ProfileUploadAttachment;
