import React from 'react';
import moment from 'moment';
import {
  ProjectBar,
  PhaseBar,
  LegendRowProject,
  AllocationBar,
  LegendRowRole,
  g600stoneGrey,
} from '@bridgit/foundation';
import { PROJECT_VIEW, DATE_DISPLAY_FORMAT } from '../../../common/constants';
import {
  getStorageKey,
  SLIDER_STORAGE_KEY,
  FILTER_STORAGE_KEY,
  SIDE_PANEL_OPEN_KEY,
  PROJECT_GANTT_VIEW_ROLES_KEY,
  PROJECT_GANTT_SORT_ROLES_KEY,
  PROJECT_GANTT_FILTER_ROLES_KEY,
} from '../../../common/localStorageKeys';
import {
  DEACTIVATED,
  PROJECT_STATE_COLOR_MAP,
  DEFAULT_VIEW_ROLES,
  DEFAULT_ROLE_SORT,
  DEFAULT_ROLE_FILTER,
  QUICK_ROLE_FILTERS,
} from './constants';
import {
  ACTIVE,
  LOST,
  PURSUIT,
  CANCELED,
} from '../../projects/constants';
import {
  stitchSegmentsByRange,
  getSegmentsWithDayInRange,
} from '../../../utils/dateSegmentUtils';
import deactivatedAvatar from '../../../images/deactivated_avatar.svg?url';
import {
  PROJECT_BAR,
  PHASE_BAR,
  FILLED_ROLE_BAR,
  UNFILLED_ROLE_BAR,
  PROJECT_LEGEND,
  ROLE_LEGEND,
  VALID_TIME_INTERVALS,
  YEAR,
} from '../constants';
import { getPercentageLabel, getRoleProgress, trimTimeBar } from '../utils/ganttUtils';

const getLocalStorageValues = (accountId, userId) => {
  const timeIntervalStorageKey = getStorageKey(PROJECT_VIEW, accountId, SLIDER_STORAGE_KEY, userId);
  const filterStorageKey = getStorageKey(PROJECT_VIEW, accountId, FILTER_STORAGE_KEY, userId);
  const sidePanelOpenKey = getStorageKey(PROJECT_VIEW, accountId, SIDE_PANEL_OPEN_KEY, userId);
  const viewRolesKey = getStorageKey(PROJECT_VIEW, accountId, PROJECT_GANTT_VIEW_ROLES_KEY, userId);
  const roleSortByKey = getStorageKey(PROJECT_VIEW, accountId, PROJECT_GANTT_SORT_ROLES_KEY, userId);
  const roleFilterKey = getStorageKey(PROJECT_VIEW, accountId, PROJECT_GANTT_FILTER_ROLES_KEY, userId);

  const viewRoles = localStorage.getItem(viewRolesKey);
  const roleFilter = localStorage.getItem(roleFilterKey);

  const defaultViewRoles = viewRoles ? viewRoles === 'true' : DEFAULT_VIEW_ROLES;
  const defaultRoleSortBy = localStorage.getItem(roleSortByKey) ?? DEFAULT_ROLE_SORT;
  const defaultRoleFilter = roleFilter ? JSON.parse(roleFilter) : DEFAULT_ROLE_FILTER;

  const defaultSelectedRoles = [];
  const defaultQuickOptions = [];

  defaultRoleFilter.forEach(({ name }) => {
    if (QUICK_ROLE_FILTERS.includes(name)) {
      defaultQuickOptions.push(name);
    } else {
      defaultSelectedRoles.push(name);
    }
  });

  let defaultTimeInterval = localStorage.getItem(timeIntervalStorageKey) || YEAR;

  // Undefined check here is a temporary solution which will be corrected in RP-6893
  const sidePanelOpen = localStorage.getItem(sidePanelOpenKey);
  const defaultSidePanelOpen = (sidePanelOpen && sidePanelOpen !== 'undefined') ? JSON.parse(sidePanelOpen) : false;

  // Set valid values for these options in case there are storage keys in place
  // with values intended to be used with previous versions of the gantt.
  if (!VALID_TIME_INTERVALS.includes(defaultTimeInterval)) defaultTimeInterval = YEAR;

  return {
    keys: {
      timeIntervalStorageKey,
      filterStorageKey,
      sidePanelOpenKey,
      viewRolesKey,
      roleSortByKey,
      roleFilterKey,
    },
    defaultValues: {
      defaultSidePanelOpen,
      defaultTimeInterval,
      defaultViewRoles,
      defaultRoleSortBy,
      defaultRoleFilter,
      defaultQuickOptions,
      defaultSelectedRoles,
    },
  };
};

/*
  To support legacy component from old gantt (ExpandedGanttPopper)
  it requires a lot of data stitched together to function correctly passed as bar data
*/
const parseAllocationData = (role, allocation, project, person) => {
  const {
    startDate,
    endDate,
    name: allocationName,
    state: allocationState,
    personId,
  } = allocation;
  const {
    id: projectId,
    name: projectName,
    state: projectState,
    type: projectType,
    colour: projectColor,
  } = project;
  const {
    id: roleId,
    name: roleName,
    startDate: roleStart,
    endDate: roleEnd,
    note: roleNote,
    requirements,
  } = role;

  const { title, photoUrl, name } = person || {};
  const personData = person
    ? { personTitle: title, photoUrl, text: name }
    : { personTitle: DEACTIVATED, photoUrl: deactivatedAvatar, text: allocationName };

  const active = allocationState !== DEACTIVATED;
  const allocationPercent = getPercentageLabel(requirements);
  const now = moment.utc();
  const roleProgress = getRoleProgress(now, startDate, endDate);

  return {
    startDate: moment(startDate),
    endDate: moment(endDate),
    projectId,
    projectName,
    projectState,
    projectType,
    projectColor,
    roleId,
    roleName,
    roleStart,
    roleEnd,
    roleNote,
    roleProgress,
    personId,
    active,
    allocationPercent,
    requirements,
    ...personData,
  };
};

const formatRoleBars = (
  startingRank,
  project,
  people,
  expandedAllocations,
  viewRoles,
  hasRoleWrite,
  hasAllocationWrite,
  minStartDate = null,
  maxEndDate = null,
  includeAllocPerc = false,
) => {
  let rank = startingRank + 1;
  const bars = [];
  const legend = [];

  const {
    id: projectId,
    state: projectState,
    roles: projectRoles,
  } = project;

  // If no role data return empty array
  if (!projectRoles?.length || !viewRoles) return { bars: [], legend: [], rank };

  // Get the filled allocation data
  const { roles } = expandedAllocations.find(({ projectId: id }) => id === projectId) || {};

  projectRoles.forEach((projectRole) => {
    const {
      id: roleId,
      name: roleName,
      note,
      requirements,
      startDate,
      endDate,
    } = projectRole;

    const allocatedPercent = requirements.length ? getPercentageLabel(requirements) : '';

    const allocations = roles?.find(({ roleId: id }) => id === roleId)?.allocations || [];

    // Get date ranges where allocatedPercent is zero so that we don't create bars for those time periods
    const zeroPercent = requirements?.filter(req => req?.allocatedPercent === 0) || [];

    // Creates startDate and endDate objects for unfilled portions of the role
    const allocationBarData = stitchSegmentsByRange([
      { startDate, endDate },
      ...allocations,
      ...zeroPercent,
    ]).filter(({ allocatedPercent }) => allocatedPercent !== 0); // but also filter out zero percent segments

    allocationBarData.forEach((allocation) => {
      const { personId, name, startDate, endDate, state, hasConflict } = allocation;

      const person = people.find(({ id }) => id === personId);
      const photoUrl = person?.photoUrl;

      const isFilled = !!name;
      const disabled = !hasAllocationWrite && !isFilled;
      const isConflict = !!hasConflict && ![PURSUIT, LOST, CANCELED].includes(projectState);

      const allocationData = isFilled
        ? parseAllocationData(projectRole, allocation, project, person)
        : null;

      const barData = {
        roleId,
        projectId,
        startDate,
        endDate,
        allocationData,
        barType: isFilled ? FILLED_ROLE_BAR : UNFILLED_ROLE_BAR,
      };

      let trimmedBarData = barData;

      if (minStartDate || maxEndDate) {
        trimmedBarData = trimTimeBar(barData, minStartDate, maxEndDate);
        if (!trimmedBarData) return;
      }

      bars.push((
        <AllocationBar
          rank={rank}
          key={`${roleId}-${startDate}`}
          name={name}
          startDate={trimmedBarData.startDate}
          endDate={trimmedBarData.endDate}
          photoUrl={state === DEACTIVATED ? deactivatedAvatar : photoUrl}
          isFilled={isFilled}
          isPursuit={projectState === PURSUIT}
          isConflict={isConflict}
          disabled={disabled}
          data={barData}
        />
      ));
    });

    const legendData = {
      legendType: ROLE_LEGEND,
      role: projectRole,
      project,
    };

    legend.push((
      <LegendRowRole
        key={`${projectId}-${roleId}`}
        title={roleName}
        subTitle={note}
        percent={includeAllocPerc ? allocatedPercent : ''}
        isEditDisabled={!hasRoleWrite}
        data={legendData}
      />
    ));

    rank += 1;
  });

  return {
    bars,
    legend,
    rank,
  };
};

const formatPhaseBars = (rank, project) => {
  const { phases, colour, state } = project;
  const phaseBars = [];

  if (!phases?.length) return phaseBars;

  phases.forEach((phase) => {
    const { id, startDate, endDate } = phase;

    const barData = {
      barType: PHASE_BAR,
      ...project,
      phase,
    };

    phaseBars.push((
      <PhaseBar
        key={id}
        rank={rank}
        startDate={startDate}
        endDate={endDate}
        data={barData}
        color={colour}
        isPursuit={state === PURSUIT}
      />
    ));
  });

  return phaseBars;
};

const formatProjectGanttData = (
  projects,
  people,
  expandedAllocations,
  viewRoles,
  hasRoleWrite,
  hasAllocationWrite,
  minStartDate = null,
  maxEndDate = null,
  includeAllocPerc = false,
  isReport = false,
) => {
  if (!projects?.length) return { bars: [], legend: [], rank: 0 };

  let rank = 0;
  let bars = [];
  let legend = [];

  projects.forEach((project) => {
    const {
      id,
      name,
      colour,
      state,
      currentPhases,
      startDate,
      endDate,
    } = project;

    // In case dates are trimmed, set date label to always be full project dates
    const customDateDisplay = `${moment(startDate).format(DATE_DISPLAY_FORMAT)} - ${moment(endDate).format(DATE_DISPLAY_FORMAT)}`;

    const barData = {
      barType: PROJECT_BAR,
      ...project,
    };

    let trimmedBarData = barData;

    if (minStartDate || maxEndDate) {
      trimmedBarData = trimTimeBar(barData, minStartDate, maxEndDate);
      if (!trimmedBarData) return;
    }

    bars.push((
      <ProjectBar
        startDate={trimmedBarData.startDate}
        endDate={trimmedBarData.endDate}
        color={colour}
        rank={rank}
        key={id}
        isPursuit={state === PURSUIT}
        isLost={state === LOST}
        data={barData}
        customDateDisplay={customDateDisplay}
      />
    ));

    let stateText = state;

    // For active projects display current phase(s) if one exists
    if (state === ACTIVE && currentPhases?.length) {
      stateText = project.currentPhases.map(phase => (
        phase?.subPhases?.length
          ? phase.subPhases.map(sp => `(${phase.name} / ${sp})`)
          : `(${phase.name})`
      )).join('');
    }

    const stateColor = PROJECT_STATE_COLOR_MAP[state] || g600stoneGrey;

    const legendData = {
      legendType: PROJECT_LEGEND,
      project,
    };

    legend.push((
      <LegendRowProject
        key={`${name}-${stateText}-${id}`}
        title={name}
        subTitle={stateText}
        subTitleColor={stateColor}
        data={legendData}
        hasAddRoleButton={hasRoleWrite && viewRoles}
      />
    ));

    const phaseBars = isReport ? [] : formatPhaseBars(rank, project);

    const {
      bars: roleBars,
      legend: roleLegend,
      rank: newRank,
    } = formatRoleBars(
      rank,
      project,
      people,
      expandedAllocations,
      viewRoles,
      hasRoleWrite,
      hasAllocationWrite,
      minStartDate,
      maxEndDate,
      includeAllocPerc,
    );

    bars = [
      ...bars,
      ...phaseBars,
      ...roleBars,
    ];

    legend = [
      ...legend,
      ...roleLegend,
    ];

    rank = newRank;
  });

  return {
    bars,
    legend,
    rank,
  };
};

const buildRoleFilters = (roleFilters, newRoles, selectedQuickOption = null) => {
  let newRoleFilters = [];
  let newQuickOptions = [];

  roleFilters.forEach((filter) => {
    const { name } = filter;
    if (QUICK_ROLE_FILTERS.includes(name)) {
      newQuickOptions.push(filter);
    } else {
      newRoleFilters.push(filter);
    }
  });

  if (newRoles !== null) {
    newRoleFilters = [...newRoles];
  }

  if (selectedQuickOption !== null) {
    const { name } = selectedQuickOption;
    const hasOption = !!newQuickOptions.find(filter => filter.name === name);

    if (hasOption) {
      newQuickOptions = newQuickOptions.filter(filter => filter.name !== name);
    } else {
      newQuickOptions.push(selectedQuickOption);
    }
  }

  return {
    newRoleFilters,
    newQuickOptions,
    newFilters: [...newQuickOptions, ...newRoleFilters],
  };
};

const getHoveredPhases = (event, startDate, endDate, phases) => {
  const mousePosition = event?.pageX;
  const barEl = event?.target?.getBoundingClientRect();

  if (mousePosition === undefined || barEl === undefined) return [];

  const { right, left } = barEl;

  // Calculate what day the mouse is on using mousePosition, barEl, and dates
  const barSize = right - left;
  const relativeMousePosition = mousePosition - left;
  const barLengthInDays = moment(endDate).diff(startDate, 'days');
  const daysAfterStartPos = (relativeMousePosition / barSize) * barLengthInDays;
  const hoverDate = moment(startDate).add(daysAfterStartPos, 'days');

  // Using the calculated day, reduce phases that include that day
  const hoveredPhases = getSegmentsWithDayInRange(phases, hoverDate);

  return hoveredPhases;
};

export {
  getLocalStorageValues,
  formatProjectGanttData,
  buildRoleFilters,
  getHoveredPhases,
};
