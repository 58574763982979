import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { parsePeople } from 'src/features/gantt/utils/peopleGanttUtils';
import { PeopleGanttRow } from '../gantt';
import { GanttReportGrid } from '.';

const PeopleGanttReportContent = ({
  people,
  allocations,
  settings,
  zoomConfig,
}) => {
  const { columnWidth, paperWidth, columnCount, earliestDate, timelineLength } = settings;
  const endDate = moment(earliestDate).add(timelineLength - 1, 'years');
  const dates = {
    min: moment(earliestDate),
    max: endDate,
  };

  const parsedPeople = parsePeople(earliestDate, endDate, allocations, people);
  const style = {
    width: `${paperWidth}px`,
  };

  return (
    <div className="reports-people-gantt-report-content">
      <div className="content" style={style}>
        <GanttReportGrid columnWidth={columnWidth} columnCount={columnCount} />
        {parsedPeople.map(person => (
          <PeopleGanttRow
            key={person.id}
            person={person}
            dates={dates}
            columnWidth={columnWidth}
            zoomConfig={zoomConfig}
          />
        ))}
      </div>
    </div>
  );
};

PeopleGanttReportContent.propTypes = {
  people: PropTypes.arrayOf(PropTypes.object),
  allocations: PropTypes.arrayOf(PropTypes.object),
  settings: PropTypes.object,
  zoomConfig: PropTypes.object.isRequired,
};

PeopleGanttReportContent.defaultProps = {
  people: [],
  allocations: [],
  settings: {
    earliestDate: moment(),
    timelineLength: 2,
  },
};

function mapStateToProps({ reports }) {
  const { people, peopleGanttAllocations: allocations, settings } = reports;

  return {
    people,
    allocations,
    settings,
  };
}

export default connect(mapStateToProps)(PeopleGanttReportContent);
