import { call, put, takeLatest } from 'redux-saga/effects';
import {
  QUERIES_DELETE_SAVED_FILTER_BEGIN,
  QUERIES_DELETE_SAVED_FILTER_SUCCESS,
  QUERIES_DELETE_SAVED_FILTER_FAILURE,
  QUERIES_DELETE_SAVED_FILTER_DISMISS_ERROR,
} from './constants';
import { protectedJsonDelete } from '../../../utils/api';

export function deleteSavedFilter({ accountId, filterId } = {}) {
  return {
    type: QUERIES_DELETE_SAVED_FILTER_BEGIN,
    accountId,
    filterId,
  };
}

export function dismissDeleteSavedFilterError() {
  return {
    type: QUERIES_DELETE_SAVED_FILTER_DISMISS_ERROR,
  };
}

export function* doDeleteSavedFilter(action) {
  const { accountId, filterId } = action;
  try {
    yield call(protectedJsonDelete, `accounts/${accountId}/my-filters/${filterId}`);
  } catch (err) {
    yield put({
      type: QUERIES_DELETE_SAVED_FILTER_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: QUERIES_DELETE_SAVED_FILTER_SUCCESS,
    filterId,
  });
}

export function* watchDeleteSavedFilter() {
  yield takeLatest(QUERIES_DELETE_SAVED_FILTER_BEGIN, doDeleteSavedFilter);
}

export function reducer(state, action) {
  switch (action.type) {
    case QUERIES_DELETE_SAVED_FILTER_BEGIN:
      return {
        ...state,
        deleteSavedFilterPending: true,
        deleteSavedFilterError: null,
      };

    case QUERIES_DELETE_SAVED_FILTER_SUCCESS: {
      const { filterId } = action;
      return {
        ...state,
        savedFilters: state.savedFilters?.filter(filter => filter.filterId !== filterId),
        deleteSavedFilterPending: false,
        deleteSavedFilterError: null,
      };
    }

    case QUERIES_DELETE_SAVED_FILTER_FAILURE:
      return {
        ...state,
        deleteSavedFilterPending: false,
        deleteSavedFilterError: action.data.error,
      };

    case QUERIES_DELETE_SAVED_FILTER_DISMISS_ERROR:
      return {
        ...state,
        deleteSavedFilterError: null,
      };

    default:
      return state;
  }
}
