import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PROJECTS_GET_PROJECT_ROLE_AVAILABILITIES_BEGIN,
  PROJECTS_GET_PROJECT_ROLE_AVAILABILITIES_SUCCESS,
  PROJECTS_GET_PROJECT_ROLE_AVAILABILITIES_FAILURE,
  PROJECTS_GET_PROJECT_ROLE_AVAILABILITIES_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';

export function getProjectRoleAvailabilities(accountId, projectId, roleId, startDate, endDate) {
  return {
    type: PROJECTS_GET_PROJECT_ROLE_AVAILABILITIES_BEGIN,
    accountId,
    projectId,
    roleId,
    startDate,
    endDate,
  };
}

export function dismissGetProjectRoleAvailabilitiesError() {
  return {
    type: PROJECTS_GET_PROJECT_ROLE_AVAILABILITIES_DISMISS_ERROR,
  };
}

export function* doGetProjectRoleAvailabilities(action) {
  const { accountId, projectId, roleId, startDate, endDate } = action;
  const dateFilter = startDate && endDate ? `?start=${startDate}&end=${endDate}` : '';

  let res;
  try {
    res = yield call(protectedJsonGet, `accounts/${accountId}/projects/${projectId}/roles/${roleId}/availabilities${dateFilter}`);
  } catch (err) {
    yield put({
      type: PROJECTS_GET_PROJECT_ROLE_AVAILABILITIES_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PROJECTS_GET_PROJECT_ROLE_AVAILABILITIES_SUCCESS,
    projectId,
    roleId,
    data: res,
  });
}

export function* watchGetProjectRoleAvailabilities() {
  yield takeLatest(PROJECTS_GET_PROJECT_ROLE_AVAILABILITIES_BEGIN, doGetProjectRoleAvailabilities);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PROJECTS_GET_PROJECT_ROLE_AVAILABILITIES_BEGIN:
      return {
        ...state,
        getProjectRoleAvailabilitiesPending: true,
        getProjectRoleAvailabilitiesError: null,
      };

    case PROJECTS_GET_PROJECT_ROLE_AVAILABILITIES_SUCCESS: {
      const { projectId, roleId } = action;

      return {
        ...state,
        projectRoleAvailabilities: {
          ...state.projectRoleAvailabilities,
          [projectId]: {
            ...state.projectRoleAvailabilities[projectId],
            [roleId]: action.data,
          },
        },
        getProjectRoleAvailabilitiesPending: false,
        getProjectRoleAvailabilitiesError: null,
      };
    }
    case PROJECTS_GET_PROJECT_ROLE_AVAILABILITIES_FAILURE:
      return {
        ...state,
        getProjectRoleAvailabilitiesPending: false,
        getProjectRoleAvailabilitiesError: action.data.error,
      };

    case PROJECTS_GET_PROJECT_ROLE_AVAILABILITIES_DISMISS_ERROR:
      return {
        ...state,
        getProjectRoleAvailabilitiesError: null,
      };

    default:
      return state;
  }
}
