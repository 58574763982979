import {
  TABLE_SET_COLUMN_ORDER,
} from './constants';

export function setColumnOrder(visibleColumns, updatedColumn) {
  return {
    type: TABLE_SET_COLUMN_ORDER,
    visibleColumns,
    updatedColumn,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case TABLE_SET_COLUMN_ORDER: {
      const { visibleColumns } = action;

      return {
        ...state,
        visibleColumns,
      };
    }

    default:
      return state;
  }
}
