import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Switch,
} from '@material-ui/core';
import {
  CHECKBOX_TYPE,
  SWITCH_TYPE,
} from 'src/common/constants';

export default function CheckboxForm({
  title,
  controls,
  onChange,
  checkedList,
  type,
  pending,
}) {
  const onChangeHandler = (evt) => {
    const { name, checked } = evt.target;
    onChange(name, checked);
  };

  const renderControl = (control) => {
    if (type === SWITCH_TYPE) {
      return (
        <Switch
          classes={{
            switchBase: 'switch-base',
            checked: 'switch-checked',
            thumb: 'switch-thumb',
            track: 'switch-track',
          }}
          className="checkbox-form-button switch"
          checked={!!checkedList[control.name]}
          onChange={onChangeHandler}
          name={control.name}
          color="primary"
          disabled={pending}
        />
      );
    }
    return (
      <Checkbox
        className="checkbox-form-button"
        checked={!!checkedList[control.name]}
        onChange={onChangeHandler}
        name={control.name}
        color="primary"
        disabled={pending}
      />
    );
  };

  return (
    <FormControl className={`common-checkbox-form ${type}`}>
      <FormLabel className="checkbox-form-title">{title}</FormLabel>
      <FormGroup className="checkbox-form-group">
        {controls.map(control => (
          <FormControlLabel
            key={control.name}
            className="checkbox-form-label"
            control={renderControl(control)}
            label={control.label}
            labelPlacement={type === SWITCH_TYPE ? 'start' : 'end'}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}

CheckboxForm.propTypes = {
  title: PropTypes.string,
  controls: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  checkedList: PropTypes.object,
  type: PropTypes.string,
  pending: PropTypes.bool,
};

CheckboxForm.defaultProps = {
  title: '',
  controls: [],
  onChange: () => {},
  checkedList: {},
  type: CHECKBOX_TYPE,
  pending: false,
};
