import initialState from './initialState';
import { reducer as getPersonFieldsReducer } from './getPersonFields';
import { reducer as removePersonFieldsReducer } from './removePersonFields';
import { reducer as addPersonFieldsReducer } from './addPersonFields';
import { reducer as updatePersonFieldsReducer } from './updatePersonFields';
import { reducer as getProjectFieldsReducer } from './getProjectFields';
import { reducer as updateProjectFieldsReducer } from './updateProjectFields';
import { reducer as addProjectFieldsReducer } from './addProjectFields';
import { reducer as removeProjectFieldsReducer } from './removeProjectFields';
import { reducer as getRoleNamesReducer } from './getRoleNames';
import { reducer as updateRoleNamesReducer } from './updateRoleNames';
import { reducer as removeRoleNamesReducer } from './removeRoleNames';
import { reducer as getPhasesReducer } from './getPhases';
import { reducer as addPhaseReducer } from './addPhase';
import { reducer as deletePhaseReducer } from './deletePhase';
import { reducer as updatePhaseReducer } from './updatePhase';
import { reducer as addSubPhaseReducer } from './addSubPhase';
import { reducer as deleteSubPhaseReducer } from './deleteSubPhase';
import { reducer as updateSubPhaseReducer } from './updateSubPhase';
import { reducer as updatePhasesReducer } from './updatePhases';
import { reducer as updateSubPhasesReducer } from './updateSubPhases';
import { reducer as collectFieldsReducer } from './collectFields';
import { reducer as getAccountPropertiesReducer } from './getAccountProperties';
import { reducer as updateAccountPropertiesReducer } from './updateAccountProperties';
import { reducer as addAccountModuleReducer } from './addAccountModule';
import { reducer as getAccountModulesReducer } from './getAccountModules';
import { reducer as removeAccountModuleReducer } from './removeAccountModule';
import { reducer as getModulesReducer } from './getModules';
import { reducer as getHourlyPersonFieldsReducer } from './getHourlyPersonFields';
import { reducer as updateHourlyPersonFieldsReducer } from './updateHourlyPersonFields';
import { reducer as getSkillSetReducer } from './getSkillSet';
import { reducer as updateSkillSetReducer } from './updateSkillSet';
import { reducer as removeSkillReducer } from './removeSkill';
import { reducer as addHourlyPersonFieldsReducer } from './addHourlyPersonFields';
import { reducer as removeHourlyPersonFieldsReducer } from './removeHourlyPersonFields';
import { reducer as updateAccountModuleReducer } from './updateAccountModule';
import { reducer as getAccountLimitsReducer } from './getAccountLimits';
import { reducer as getAccountCertificationsReducer } from './getAccountCertifications';
import { reducer as addAccountCertificationReducer } from './addAccountCertification';
import { reducer as updateAccountCertificationReducer } from './updateAccountCertification';
import { reducer as deleteAccountCertificationReducer } from './deleteAccountCertification';
import { reducer as getAccountCertificationByIdReducer } from './getAccountCertificationById';
import { reducer as clearSelectedAccountCertificationReducer } from './clearSelectedAccountCertification';

const reducers = [
  getPersonFieldsReducer,
  removePersonFieldsReducer,
  addPersonFieldsReducer,
  getProjectFieldsReducer,
  updatePersonFieldsReducer,
  updateProjectFieldsReducer,
  addProjectFieldsReducer,
  removeProjectFieldsReducer,
  getRoleNamesReducer,
  updateRoleNamesReducer,
  removeRoleNamesReducer,
  getPhasesReducer,
  addPhaseReducer,
  deletePhaseReducer,
  updatePhaseReducer,
  addSubPhaseReducer,
  deleteSubPhaseReducer,
  updateSubPhaseReducer,
  updatePhasesReducer,
  updateSubPhasesReducer,
  collectFieldsReducer,
  getAccountPropertiesReducer,
  updateAccountPropertiesReducer,
  addAccountModuleReducer,
  getAccountModulesReducer,
  removeAccountModuleReducer,
  getModulesReducer,
  getHourlyPersonFieldsReducer,
  updateHourlyPersonFieldsReducer,
  getSkillSetReducer,
  updateSkillSetReducer,
  removeSkillReducer,
  addHourlyPersonFieldsReducer,
  removeHourlyPersonFieldsReducer,
  updateAccountModuleReducer,
  getAccountLimitsReducer,
  getAccountCertificationsReducer,
  addAccountCertificationReducer,
  updateAccountCertificationReducer,
  deleteAccountCertificationReducer,
  getAccountCertificationByIdReducer,
  clearSelectedAccountCertificationReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
