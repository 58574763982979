import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, LinearProgress, Paper } from '@material-ui/core';
import deepEqual from 'react-fast-compare';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { getDashboardEmptyResultText } from 'src/features/filters/utils/filterUtils';
import { getProjectAllocations } from '../projects/redux/actions';
import { ProjectLinkToModalContainer } from '../common';
import emptyImage from '../../images/empty-dashboard.svg?url';
import { ProjectBreakdownBar } from '.';
import {
  DASHBOARD_NO_ROLES_PLACEHOLDER,
  DATE_DISPLAY_FORMAT,
  DOT,
  INVALID_STATUS_FILTER,
  ACCOUNT_MODULE_PURSUIT_TRACKING,
} from '../../common/constants';
import { ACTIVE, UPCOMING, PURSUIT } from '../projects/constants';
import { hasModuleEnabled } from '../permissions/utils/permissionUtils';
import {
  AUTOMATION_PROJECT_BREAKDOWN_TOTAL_ROLES,
  AUTOMATION_PROJECT_BREAKDOWN_FILLED_ROLES,
  AUTOMATION_PROJECT_BREAKDOWN_UNFILLED_ROLES,
} from './ids';

export class RoleBreakdown extends Component {
  static propTypes = {
    projects: PropTypes.array.isRequired,
    people: PropTypes.array,
    projectAllocations: PropTypes.object.isRequired,
    accountId: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingAllocations: PropTypes.bool.isRequired,
    projectActions: PropTypes.object.isRequired,
    filterGroupApplied: PropTypes.string,
    isPursuitModuleOn: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    people: [],
    filterGroupApplied: 'default',
  };

  constructor(props) {
    super(props);

    this.emptyArray = [];

    this.state = {
      ...this.parseProjects(props.projects),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { projects } = this.props;

    if (!deepEqual(projects, nextProps.projects)) {
      this.setState({
        ...this.parseProjects(nextProps.projects),
      });
    }
  }

  renderRoleCount = (totalRoles, roleCount, filled) => (
    <div className="role-count">
      <p>{ totalRoles === 0 ? 'No resources defined' : `${filled ? 'Filled' : 'Unfilled'} Roles`}</p>
      {totalRoles !== 0 && <p>{roleCount}</p>}
    </div>
  );

  parseProjects = (projects) => {
    const upperBound = Math.max(...projects.map(project => project.totalRoles), 0);
    let totalUnfilledRoles = 0;
    let totalRoles = 0;
    let totalFilledRoles = 0;
    const activeProjects = [];
    const upcomingProjects = [];
    const pursuitProjects = [];

    projects.forEach((project) => {
      const bars = [];
      const filledRoles = project.totalRoles - project.unfilledRoles;
      const unfilledWidth = (project.unfilledRoles / upperBound) * 100;
      const filledWidth = (filledRoles / upperBound) * 100;
      const startDate = moment.utc(project.startDate).format(DATE_DISPLAY_FORMAT);
      const endDate = moment.utc(project.endDate).format(DATE_DISPLAY_FORMAT);

      if (filledWidth > 0) {
        bars.push({
          id: 2,
          class: `filled-bar${unfilledWidth > 0 ? ' no-border-radius-right' : ''}`,
          width: filledWidth,
          filled: true,
          roleCount: this.renderRoleCount(project.totalRoles, filledRoles, true),
        });
      }
      if (unfilledWidth > 0) {
        bars.push({
          id: 1,
          class: `unfilled-bar${filledWidth > 0 ? ' no-border-left' : ''}`,
          width: unfilledWidth,
          filled: false,
          roleCount: this.renderRoleCount(project.totalRoles, project.unfilledRoles, false),
        });
      }

      totalRoles += project.totalRoles;
      totalUnfilledRoles += project.unfilledRoles;
      totalFilledRoles += filledRoles;

      const filteredProject = {
        id: project.id,
        name: project.name,
        color: project.colour,
        countPosition: unfilledWidth + filledWidth,
        totalRoles: project.totalRoles,
        unfilledRoles: project.unfilledRoles,
        state: project.state,
        statusClass: project.state.toLowerCase(),
        dateRange: `${startDate} - ${endDate}`,
        filledRoles,
        roles: project.roles || [],
        bars,
        currentPhases: project.currentPhases,
      };

      if (project.state === ACTIVE) {
        activeProjects.push(filteredProject);
      } else if (project.state === UPCOMING) {
        upcomingProjects.push(filteredProject);
      } else if (project.state === PURSUIT) {
        pursuitProjects.push(filteredProject);
      }
    });

    return { activeProjects, upcomingProjects, pursuitProjects, totalRoles, totalUnfilledRoles, totalFilledRoles };
  }

  getProjectAllocations = (accountId, projectId) => {
    const { projectActions } = this.props;
    projectActions.getProjectAllocations(accountId, projectId);
  }

  renderSection = (title, filteredProjects) => {
    if (!filteredProjects.length) return null;

    const { projectAllocations, people, loadingAllocations } = this.props;

    return (
      <>
        <div className="project-section-title">{`${title} (${filteredProjects.length})`}</div>
        <div className="project-section">
          {filteredProjects.map(project => (
            <div key={project.id} className="project-container">
              <div className="name-wrap">
                <ProjectLinkToModalContainer
                  backgroundColor={project.color}
                  projectName={project.name}
                  launchedFrom="Dashboard - Project Breakdown"
                  projectId={project.id}
                />
              </div>
              <div className="bar-wrap">
                {project.bars.map(bar => (
                  <ProjectBreakdownBar
                    key={bar.id}
                    width={bar.width}
                    classes={bar.class}
                    project={project}
                    people={people}
                    projectAllocations={projectAllocations[project.id] || this.emptyArray}
                    bar={bar}
                    getProjectAllocations={this.getProjectAllocations}
                    loadingAllocations={loadingAllocations}
                  />
                ))}
                <span className="role-count" style={{ left: `${project.countPosition}%` }}>
                  {project.totalRoles ? project.totalRoles : DASHBOARD_NO_ROLES_PLACEHOLDER}
                </span>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }

  renderEmpty = () => {
    const { accountId, filterGroupApplied, isPursuitModuleOn } = this.props;
    const accountPath = `/accounts/${accountId}`;

    let noMatch;
    if (filterGroupApplied === INVALID_STATUS_FILTER) {
      noMatch = getDashboardEmptyResultText(isPursuitModuleOn);
    } else if (filterGroupApplied === 'filtered') {
      noMatch = 'No projects match the filters applied';
    } else {
      noMatch = 'There are currently no active or upcoming projects with roles assigned!';
    }

    return (
      <div className="empty-wrap">
        <div className="no-projects">{noMatch}</div>
        {filterGroupApplied !== 'filtered' && filterGroupApplied !== INVALID_STATUS_FILTER && (
          <>
            <div className="image-wrap">
              <img src={emptyImage} alt="Empty" />
            </div>
            <div className="button-wrap">
              <Button variant="contained" color="primary" component={Link} to={`${accountPath}/projects`}>Add Projects</Button>
            </div>
          </>
        )}
      </div>
    );
  }

  render() {
    const { activeProjects, upcomingProjects, pursuitProjects, totalRoles, totalFilledRoles, totalUnfilledRoles } = this.state;
    const { loading, filterGroupApplied } = this.props;
    const isEmpty = activeProjects.length === 0 && upcomingProjects.length === 0 && pursuitProjects.length === 0;

    const isNoProjectsWithRoles = isEmpty && !['filtered', INVALID_STATUS_FILTER].includes(filterGroupApplied);

    if (loading) {
      return (
        <Paper className="dashboard-role-breakdown empty">
          <LinearProgress color="primary" />
        </Paper>
      );
    }

    return (
      <Paper className={classNames('dashboard-role-breakdown', { 'empty-list': isNoProjectsWithRoles })}>
        <div className={classNames('dash-header', { 'empty-header': isEmpty })}>
          <div className="title">Project Breakdown</div>
          <div className="details">
            <div className="header-count" id={AUTOMATION_PROJECT_BREAKDOWN_TOTAL_ROLES}>{`Total roles: ${totalRoles}`}</div>
            <div className="header-count" id={AUTOMATION_PROJECT_BREAKDOWN_FILLED_ROLES}>
              <span className="dot filled">{DOT}</span>
              {`Filled roles: ${totalFilledRoles}`}
            </div>
            <div className="header-count" id={AUTOMATION_PROJECT_BREAKDOWN_UNFILLED_ROLES}>
              <span className="dot unfilled">{DOT}</span>
              {`Unfilled roles: ${totalUnfilledRoles}`}
            </div>
          </div>
        </div>
        {isEmpty
          ? this.renderEmpty()
          : (
            <div className="list-wrap">
              {this.renderSection(`${ACTIVE} Projects`, activeProjects)}
              {this.renderSection(`${UPCOMING} Projects`, upcomingProjects)}
              {this.renderSection(`${PURSUIT} Projects`, pursuitProjects)}
            </div>
          )}
      </Paper>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps({ common, projects, people, dashboard, accountSettings }) {
  const { accountModules } = accountSettings;
  const isPursuitModuleOn = hasModuleEnabled(accountModules, ACCOUNT_MODULE_PURSUIT_TRACKING);

  return {
    accountId: common.accountId,
    projects: dashboard.projectBreakdown,
    people: people.entities,
    projectAllocations: projects.projectAllocations,
    loading: dashboard.getProjectBreakdownPending,
    loadingAllocations: projects.getProjectAllocationsPending,
    isPursuitModuleOn,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    projectActions: bindActionCreators({ getProjectAllocations }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RoleBreakdown);
