import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';

function AllocationValue({
  allocatedPercent,
  showCustom,
  onCustomClick,
}) {
  return (
    <div className="allocations-allocation-value">
      {!showCustom && `${allocatedPercent}%`}
      {showCustom && (
        <button
          className="custom-allocation-info"
          type="button"
          onClick={onCustomClick}
        >
          <div className="custom-allocation-label">Custom</div>
          <Tooltip title="View breakdown" placement="top">
            <div><MoreHoriz /></div>
          </Tooltip>
        </button>
      )}
    </div>
  );
}

AllocationValue.propTypes = {
  allocatedPercent: PropTypes.number.isRequired,
  showCustom: PropTypes.bool,
  onCustomClick: PropTypes.func,
};

AllocationValue.defaultProps = {
  showCustom: false,
  onCustomClick: () => {},
};

export default AllocationValue;
