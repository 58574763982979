import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PEOPLE_GET_PERSON_ALLOCATIONS_BEGIN,
  PEOPLE_GET_PERSON_ALLOCATIONS_SUCCESS,
  PEOPLE_GET_PERSON_ALLOCATIONS_FAILURE,
  PEOPLE_GET_PERSON_ALLOCATIONS_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';

export function getPersonAllocations(accountId, personId, offset, projectState = 'All', limit = 10, state = 'All') {
  return {
    type: PEOPLE_GET_PERSON_ALLOCATIONS_BEGIN,
    accountId,
    personId,
    offset,
    limit,
    state,
    projectState,
  };
}

export function dismissGetPersonAllocationsError() {
  return {
    type: PEOPLE_GET_PERSON_ALLOCATIONS_DISMISS_ERROR,
  };
}

export function* doGetPersonAllocations(action) {
  const { accountId, personId, offset, limit = 5000, state, projectState } = action;
  let res;
  try {
    res = yield call(protectedJsonGet, `accounts/${accountId}/persons/${personId}/project-allocations`, { limit, offset, projectState, state }, true);
  } catch (err) {
    yield put({
      type: PEOPLE_GET_PERSON_ALLOCATIONS_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PEOPLE_GET_PERSON_ALLOCATIONS_SUCCESS,
    data: res.data,
    headers: res.headers,
    personId,
    offset,
  });
}

export function* watchGetPersonAllocations() {
  yield takeLatest(PEOPLE_GET_PERSON_ALLOCATIONS_BEGIN, doGetPersonAllocations);
}

export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_GET_PERSON_ALLOCATIONS_BEGIN:
      return {
        ...state,
        getPersonAllocationsPending: true,
        getPersonAllocationsError: null,
      };

    case PEOPLE_GET_PERSON_ALLOCATIONS_SUCCESS:
      return {
        ...state,
        personAllocations: {
          ...state.personAllocations,
          [action.personId]: action.offset > 0 ? state.personAllocations[action.personId].concat(action.data) : action.data,
        },
        moreAnyAllocations: action.headers['query-has-more'] === 'True',
        getPersonAllocationsPending: false,
        getPersonAllocationsError: null,
      };

    case PEOPLE_GET_PERSON_ALLOCATIONS_FAILURE:
      return {
        ...state,
        getPersonAllocationsPending: false,
        getPersonAllocationsError: action.data.error,
      };

    case PEOPLE_GET_PERSON_ALLOCATIONS_DISMISS_ERROR:
      return {
        ...state,
        getPersonAllocationsError: null,
      };

    default:
      return state;
  }
}


/*  */
