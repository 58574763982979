import { call, put, takeLatest } from 'redux-saga/effects';
import {
  SELF_PERFORM_UPDATE_HOURLY_REQUEST_BEGIN,
  SELF_PERFORM_UPDATE_HOURLY_REQUEST_SUCCESS,
  SELF_PERFORM_UPDATE_HOURLY_REQUEST_FAILURE,
  SELF_PERFORM_UPDATE_HOURLY_REQUEST_DISMISS_ERROR,
  SELF_PERFORM_REOPEN_REQUEST_SUCCESS,
  SELF_PERFORM_REJECT_REQUEST_SUCCESS,
} from './constants';
import { protectedJsonPatch } from '../../../utils/api';
import { REQUEST_REJECTED } from '../constants';

export function updateHourlyRequest(accountId, projectId, requestId, updatedField, projectName, state) {
  return {
    type: SELF_PERFORM_UPDATE_HOURLY_REQUEST_BEGIN,
    accountId,
    projectId,
    requestId,
    updatedField,
    projectName,
    state,
  };
}

export function dismissUpdateHourlyRequestError() {
  return {
    type: SELF_PERFORM_UPDATE_HOURLY_REQUEST_DISMISS_ERROR,
  };
}

export function* doUpdateHourlyRequest(action) {
  const { accountId, projectId, requestId, updatedField, projectName, state } = action;
  try {
    yield call(protectedJsonPatch, `accounts/${accountId}/projects/${projectId}/hourly-requests/${requestId}`, updatedField);
  } catch (err) {
    yield put({
      type: SELF_PERFORM_UPDATE_HOURLY_REQUEST_FAILURE,
      data: { error: err },
    });
    return;
  }

  const updatedFieldsKeys = Object.keys(updatedField);

  // Mixpanel dispatch for toggling request state
  if (updatedFieldsKeys[0] === 'state') {
    const type = updatedField.state === REQUEST_REJECTED ? SELF_PERFORM_REJECT_REQUEST_SUCCESS : SELF_PERFORM_REOPEN_REQUEST_SUCCESS;

    yield put({
      type,
      data: updatedField,
      projectId,
      requestId,
      projectName,
      state,
    });
  } else {
    yield put({
      type: SELF_PERFORM_UPDATE_HOURLY_REQUEST_SUCCESS,
      data: updatedField,
      projectId,
      requestId,
      projectName,
      state,
    });
  }
}

export function* watchUpdateHourlyRequest() {
  yield takeLatest(SELF_PERFORM_UPDATE_HOURLY_REQUEST_BEGIN, doUpdateHourlyRequest);
}

export function reducer(state, action) {
  switch (action.type) {
    case SELF_PERFORM_UPDATE_HOURLY_REQUEST_BEGIN:
      return {
        ...state,
        updateHourlyRequestPending: true,
        updateHourlyRequestError: null,
      };

    case SELF_PERFORM_UPDATE_HOURLY_REQUEST_SUCCESS:
    case SELF_PERFORM_REJECT_REQUEST_SUCCESS:
    case SELF_PERFORM_REOPEN_REQUEST_SUCCESS: {
      const { data } = action;

      const selectedRequest = { ...state.selectedRequest, ...data };
      const filteredRequests = state.filteredRequests.map(request => (request.id === selectedRequest.id ? selectedRequest : request));

      return {
        ...state,
        selectedRequest,
        filteredRequests,
        updateHourlyRequestPending: false,
        updateHourlyRequestError: null,
      };
    }

    case SELF_PERFORM_UPDATE_HOURLY_REQUEST_FAILURE:
      return {
        ...state,
        updateHourlyRequestPending: false,
        updateHourlyRequestError: action.data.error,
      };

    case SELF_PERFORM_UPDATE_HOURLY_REQUEST_DISMISS_ERROR:
      return {
        ...state,
        updateHourlyRequestError: null,
      };

    default:
      return state;
  }
}
