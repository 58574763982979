import React from 'react';
import shortId from 'shortid';
import { LegendRowPeople, UtilizationBar, ganttBarTypes, LegendRowBlank, ProjectBar } from '@bridgit/foundation';
import { getNonOverlapping, sortSegmentsByStartDate, stitchSegmentsByRange } from '../../../utils/dateSegmentUtils';
import { rangesOverlap } from '../../../utils/dateUtils';
import {
  FULL_COLOR,
  VALID_GANTT_DISPLAYOPTIONS,
  VALID_COLOR_OPTIONS,
  PROJECT,
  PROJECTS,
  PROJECTS_AND_UTILIZATION,
  UTILIZATION,
  PEOPLE,
  BLANK,
  PURSUIT,
  CANCELED,
  LOST,
  AVAILABILITY,
  UNAVAILABILITY,
  TIME_OFF_RANGE_TYPE,
} from './constants';
import {
  YEAR,
  VALID_TIME_INTERVALS,
} from '../constants';
import {
  getStorageKey,
  FILTER_STORAGE_KEY,
  SLIDER_STORAGE_KEY,
  GANTT_DISPLAY_KEY,
  DISPLAY_OPTIONS_KEY,
  COLOR_OPTION_KEY,
  SIDE_PANEL_OPEN_KEY,
} from '../../../common/localStorageKeys';
import { PEOPLE_VIEW } from '../../../common/constants';
import { ACTIVE, COMPLETE, UPCOMING } from '../../projects/constants';
import { trimTimeBar } from '../utils/ganttUtils';

const { UNAVAILABLE, AVAILABLE, ALLOCATED, OVERALLOCATED, PARTIALLY_AVAILABLE, TIME_OFF } = ganttBarTypes;

const getProjectBarRows = (person) => {
  const { projectAllocations } = person;
  const parsedProjectBarRows = [];

  let filteredProjectAllocations = projectAllocations.filter((projectAllocation) => {
    const { projectState } = projectAllocation;
    return ![CANCELED, LOST].includes(projectState);
  });

  while (filteredProjectAllocations.length) {
    const { nonOverlapping, leftovers } = getNonOverlapping(filteredProjectAllocations);
    parsedProjectBarRows.push(nonOverlapping);
    filteredProjectAllocations = leftovers;
  }
  return parsedProjectBarRows;
};

const getParsedAllocations = (allocations, unavailabilities) => {
  const allocationsAndUnavailabilities = [...allocations, ...unavailabilities];
  return stitchSegmentsByRange(allocationsAndUnavailabilities)?.filter(seg => seg?.__typename !== UNAVAILABILITY);
};

const getParsedAvailabilities = (availabilities, unavailabilities) => {
  const availabilitiesAndUnavailabilities = [...availabilities, ...unavailabilities];
  return stitchSegmentsByRange(availabilitiesAndUnavailabilities)?.filter(seg => seg?.__typename === AVAILABILITY);
};

const getUnavailabilityBars = (unavailabilities, minStartDate, maxEndDate, person, barRank) => {
  const bars = [];
  unavailabilities.forEach((unavailability) => {
    const trimmedUnavailability = trimTimeBar(unavailability, minStartDate, maxEndDate);

    if (trimmedUnavailability === null) return;

    const { startDate, endDate, id, rangeType } = trimmedUnavailability;

    const unavailabilityBarType = rangeType === TIME_OFF_RANGE_TYPE ? TIME_OFF : UNAVAILABLE;

    const data = {
      type: unavailabilityBarType,
      allocation: trimmedUnavailability,
      person,
      isUnavailable: true,
    };

    bars.push(
      <UtilizationBar
        percentage={0}
        startDate={startDate}
        endDate={endDate}
        type={unavailabilityBarType}
        rank={barRank}
        key={id}
        data={data}
      />,
    );
  });
  return bars;
};

const getAvailabilityBars = (availabilities, minStartDate, maxEndDate, barRank) => {
  const bars = [];
  availabilities.forEach((availability) => {
    const trimmedAvailability = trimTimeBar(availability, minStartDate, maxEndDate);

    if (trimmedAvailability === null) return;

    const { percent, startDate, endDate } = trimmedAvailability;

    const type = percent < 100 ? PARTIALLY_AVAILABLE : AVAILABLE;

    bars.push(
      <UtilizationBar
        percentage={percent}
        startDate={startDate}
        endDate={endDate}
        type={type}
        rank={barRank}
        key={shortId.generate()}
      />,
    );
  });
  return bars;
};

const getAllocationBars = (allocations, minStartDate, maxEndDate, person, barRank, overallocationOnly) => {
  const bars = [];
  allocations.forEach((allocation) => {
    const trimmedAllocation = trimTimeBar(allocation, minStartDate, maxEndDate);

    if (trimmedAllocation === null) return;

    const { allocatedPercent, startDate, endDate } = trimmedAllocation;

    let type = ALLOCATED;

    if (allocatedPercent > 100) {
      type = OVERALLOCATED;
    } else if (allocatedPercent < 100) {
      return;
    }

    if (overallocationOnly && type !== OVERALLOCATED) return;

    const data = {
      type: ALLOCATED,
      allocation,
      person,
    };

    bars.push(
      <UtilizationBar
        percentage={allocatedPercent}
        startDate={startDate}
        endDate={endDate}
        type={type}
        rank={barRank}
        key={shortId.generate()}
        data={data}
      />,
    );
  });
  return bars;
};

const getProjectRowBars = (projectRow, minStartDate, maxEndDate, name, barRank, showLightBackground) => {
  const bars = [];
  projectRow.forEach((projectRowItem) => {
    const trimmedProjectBarRowItem = trimTimeBar(projectRowItem, minStartDate, maxEndDate);

    if (trimmedProjectBarRowItem === null) return;

    const {
      startDate,
      endDate,
      projectColour,
      projectName,
      projectId,
      projectState,
      allocatedPercent,
    } = trimmedProjectBarRowItem;

    if (projectState === CANCELED || projectState === LOST) return;

    const data = {
      type: PROJECT,
      projectId,
      name,
      projectState,
    };

    const isPursuit = projectState === PURSUIT;
    bars.push(
      <ProjectBar
        startDate={startDate}
        endDate={endDate}
        color={projectColour}
        name={projectName}
        allocatedPercent={allocatedPercent}
        rank={barRank}
        key={projectId}
        data={data}
        showLightBackground={showLightBackground}
        isPursuit={isPursuit}
      />,
    );
  });
  return bars;
};

const getProjectAndUnavailabilityBars = (
  segments,
  minStartDate,
  maxEndDate,
  person,
  barRank,
  showLightBackground,
) => {
  const bars = [];
  segments.forEach((segment) => {
    const trimmedSegment = trimTimeBar(segment, minStartDate, maxEndDate);

    if (trimmedSegment === null) return;

    const { __typename } = trimmedSegment;

    if (__typename === UNAVAILABILITY) {
      const { startDate, endDate, id, rangeType } = trimmedSegment;

      const unavailabilityBarType = rangeType === TIME_OFF_RANGE_TYPE ? TIME_OFF : UNAVAILABLE;

      const data = {
        type: unavailabilityBarType,
        allocation: trimmedSegment,
        person,
        isUnavailable: true,
      };

      bars.push(
        <UtilizationBar
          percentage={0}
          startDate={startDate}
          endDate={endDate}
          type={unavailabilityBarType}
          rank={barRank}
          key={id}
          data={data}
        />,
      );
    } else {
      const {
        startDate,
        endDate,
        projectColour,
        projectName,
        projectId,
        projectState,
        allocatedPercent,
        name,
      } = trimmedSegment;

      if (projectState === CANCELED || projectState === LOST) return;

      const data = {
        type: PROJECT,
        projectId,
        name,
        projectState,
      };

      const isPursuit = projectState === PURSUIT;
      bars.push(
        <ProjectBar
          startDate={startDate}
          endDate={endDate}
          color={projectColour}
          name={projectName}
          allocatedPercent={allocatedPercent}
          rank={barRank}
          key={projectId}
          data={data}
          showLightBackground={showLightBackground}
          isPursuit={isPursuit}
        />,
      );
    }
  });

  return bars;
};

const formatPeopleGanttDataForUtilization = (
  people,
  allocations,
  minStartDate,
  maxEndDate,
  showAvatar,
  availableFilter,
  issuesFilter,
) => {
  const bars = [];
  const legend = [];
  let barRank = 0;
  people.forEach((person) => {
    const {
      id,
      unavailabilities,
      availabilities,
      name,
      title,
      photoUrl,
    } = person;

    const data = {
      type: PEOPLE,
      person,
    };

    legend.push(
      <LegendRowPeople
        photoUrl={photoUrl}
        name={name}
        title={title}
        key={`${name}-${title}-${id}`}
        showAvatar={showAvatar}
        showTitle
        data={data}
      />,
    );

    if (!availableFilter && !issuesFilter) {
      const unavailabilityBars = getUnavailabilityBars(unavailabilities, minStartDate, maxEndDate, person, barRank);
      if (unavailabilityBars?.length) bars.push(...unavailabilityBars);
    }

    if (!issuesFilter || availableFilter) {
      const parsedAvailabilities = getParsedAvailabilities(availabilities, unavailabilities);
      const parsedAvailabilityBars = getAvailabilityBars(parsedAvailabilities, minStartDate, maxEndDate, barRank);
      if (parsedAvailabilityBars?.length) bars.push(...parsedAvailabilityBars);
    }

    if (!availableFilter || issuesFilter) {
      const filteredAllocations = allocations.filter(alloc => alloc.personId === person.id);
      const parsedAllocations = getParsedAllocations(filteredAllocations, unavailabilities);
      const allocationBars = getAllocationBars(parsedAllocations, minStartDate, maxEndDate, person, barRank, issuesFilter);
      if (allocationBars?.length) bars.push(...allocationBars);
    }

    barRank += 1;
  });

  return { bars, legend };
};

const formatPeopleGanttDataForProjects = (
  people,
  minStartDate,
  maxEndDate,
  showAvatar,
  showLightBackground,
) => {
  const bars = [];
  const legend = [];
  let barRank = 0;
  people.forEach((person) => {
    const {
      id,
      name,
      photoUrl,
      title,
      unavailabilities,
    } = person;
    const projectBarRows = getProjectBarRows(person);

    let personRowAdded = false;

    if (!projectBarRows.length) {
      const data = {
        type: PEOPLE,
        person,
      };

      legend.push(
        <LegendRowPeople
          photoUrl={photoUrl}
          name={name}
          title={title}
          key={`${name}-${title}-${id}`}
          showAvatar={showAvatar}
          showTitle
          data={data}
        />,
      );

      barRank += 1;
    }

    projectBarRows.forEach((projectRow, idx) => {
      let segments = projectRow;
      if (idx === 0) {
        const sortedSegments = sortSegmentsByStartDate([...segments, ...unavailabilities]);
        segments = stitchSegmentsByRange(sortedSegments);
      }
      const projectRowBars = getProjectAndUnavailabilityBars(segments, minStartDate, maxEndDate, name, barRank, showLightBackground);
      if (projectRowBars?.length) bars.push(...projectRowBars);

      if (projectRowBars?.length) {
        if (!personRowAdded) {
          const data = {
            type: PEOPLE,
            person,
          };

          legend.push(
            <LegendRowPeople
              photoUrl={photoUrl}
              name={name}
              title={title}
              key={`${name}-${title}-${id}`}
              showAvatar={showAvatar}
              showTitle
              data={data}
            />,
          );
          personRowAdded = true;
        } else {
          const data = { type: BLANK };
          legend.push(
            <LegendRowBlank key={shortId.generate()} data={data} />,
          );
        }

        barRank += 1;
      }
    });
  });

  return { bars, legend, barRank };
};

const formatPeopleGanttDataForProjectsAndUtilization = (
  people,
  allocations,
  minStartDate,
  maxEndDate,
  showAvatar,
  showLightBackground,
  availableFilter,
  issuesFilter,
) => {
  const bars = [];
  const legend = [];
  let barRank = 0;
  people.forEach((person) => {
    const {
      id,
      unavailabilities,
      availabilities,
      name,
      title,
      photoUrl,
    } = person;

    const data = {
      type: PEOPLE,
      person,
    };

    legend.push(
      <LegendRowPeople
        photoUrl={photoUrl}
        name={name}
        title={title}
        key={`${name}-${title}-${id}`}
        showAvatar={showAvatar}
        showTitle
        data={data}
      />,
    );

    if (!availableFilter && !issuesFilter) {
      const unavailabilityBars = getUnavailabilityBars(unavailabilities, minStartDate, maxEndDate, person, barRank);
      if (unavailabilityBars?.length) bars.push(...unavailabilityBars);
    }

    if (!issuesFilter || availableFilter) {
      const parsedAvailabilities = getParsedAvailabilities(availabilities, unavailabilities);
      const parsedAvailabilityBars = getAvailabilityBars(parsedAvailabilities, minStartDate, maxEndDate, barRank);
      if (parsedAvailabilityBars?.length) bars.push(...parsedAvailabilityBars);
    }

    if (!availableFilter || issuesFilter) {
      const personAllocations = allocations.filter(allocation => allocation.personId === person.id);
      const parsedAllocations = getParsedAllocations(personAllocations, unavailabilities);
      const allocationBars = getAllocationBars(parsedAllocations, minStartDate, maxEndDate, person, barRank, issuesFilter);
      if (allocationBars?.length) bars.push(...allocationBars);
    }

    barRank += 1;

    const projectBarRows = getProjectBarRows(person);

    projectBarRows.forEach((projectRow) => {
      const projectRowBars = getProjectRowBars(projectRow, minStartDate, maxEndDate, name, barRank, showLightBackground);
      if (projectRowBars?.length) bars.push(...projectRowBars);

      if (projectRowBars?.length) {
        const data = { type: BLANK };
        legend.push(
          <LegendRowBlank key={shortId.generate()} data={data} />,
        );

        barRank += 1;
      }
    });
  });

  return { bars, legend };
};

const formatPeopleGanttData = (
  people,
  allocations,
  minStartDate,
  maxEndDate,
  showAvatar,
  showLightBackground,
  ganttDisplayOption,
  availableFilter,
  issuesFilter,
) => {
  if (ganttDisplayOption === PROJECTS_AND_UTILIZATION) {
    return formatPeopleGanttDataForProjectsAndUtilization(
      people,
      allocations,
      minStartDate,
      maxEndDate,
      showAvatar,
      showLightBackground,
      availableFilter,
      issuesFilter,
    );
  }

  if (ganttDisplayOption === PROJECTS) {
    return formatPeopleGanttDataForProjects(
      people,
      minStartDate,
      maxEndDate,
      showAvatar,
      showLightBackground,
    );
  }

  if (ganttDisplayOption === UTILIZATION) {
    return formatPeopleGanttDataForUtilization(
      people,
      allocations,
      minStartDate,
      maxEndDate,
      showAvatar,
      availableFilter,
      issuesFilter,
    );
  }
  return { bars: [], legend: [] };
};

const parseProjectAllocations = (projectAllocations, startDate, endDate) => {
  const parsedProjectAllocations = [];
  const range1 = { startDate, endDate };
  projectAllocations?.forEach((projectAllocation) => {
    const { startDate: projectAllocationStartDate, endDate: projectAllocationEndDate, projectState } = projectAllocation;
    const range2 = { startDate: projectAllocationStartDate, endDate: projectAllocationEndDate };
    if (rangesOverlap(range1, range2) && (projectState === ACTIVE || projectState === UPCOMING || projectState === COMPLETE)) {
      parsedProjectAllocations.push(projectAllocation);
    }
  });
  return parsedProjectAllocations;
};

const getLocalStorageValues = (accountId, userId) => {
  const timeIntervalStorageKey = getStorageKey(PEOPLE_VIEW, accountId, SLIDER_STORAGE_KEY, userId);
  const ganttDisplayOptionKey = getStorageKey(PEOPLE_VIEW, accountId, GANTT_DISPLAY_KEY, userId);
  const displayOptionsKey = getStorageKey(PEOPLE_VIEW, accountId, DISPLAY_OPTIONS_KEY, userId);
  const colorOptionKey = getStorageKey(PEOPLE_VIEW, accountId, COLOR_OPTION_KEY, userId);
  const sidePanelOpenKey = getStorageKey(PEOPLE_VIEW, accountId, SIDE_PANEL_OPEN_KEY, userId);
  const filterStorageKey = getStorageKey(PEOPLE_VIEW, accountId, FILTER_STORAGE_KEY, userId);

  let defaultTimeInterval = localStorage.getItem(timeIntervalStorageKey) || YEAR;
  let defaultGanttDisplayOption = localStorage.getItem(ganttDisplayOptionKey) || PROJECTS_AND_UTILIZATION;
  let defaultColorOption = localStorage.getItem(colorOptionKey) || FULL_COLOR;

  const sidePanelOpen = localStorage.getItem(sidePanelOpenKey);
  // Undefined check here is a temporary solution which will be corrected in RP-6893
  const defaultSidePanelOpen = (sidePanelOpen && sidePanelOpen !== 'undefined') ? JSON.parse(sidePanelOpen) : false;

  const displayOptions = localStorage.getItem(displayOptionsKey);
  let defaultShowAvatar;

  if (displayOptions === null || displayOptions === undefined) {
    defaultShowAvatar = true;
  } else {
    const parsedOptions = JSON.parse(displayOptions);

    // The previous gantt component used an object to store the photo
    // and title settings. The title setting has been removed in the
    // new version.
    if (typeof parsedOptions === 'object') {
      defaultShowAvatar = !!parsedOptions.photo;
    } else {
      defaultShowAvatar = parsedOptions;
    }
  }

  // Set valid values for these options in case there are storage keys in place
  // with values intended to be used with previous versions of the gantt.
  if (VALID_TIME_INTERVALS.indexOf(defaultTimeInterval) === -1) defaultTimeInterval = YEAR;
  if (VALID_GANTT_DISPLAYOPTIONS.indexOf(defaultGanttDisplayOption) === -1) defaultGanttDisplayOption = PROJECTS_AND_UTILIZATION;
  if (VALID_COLOR_OPTIONS.indexOf(defaultColorOption) === -1) defaultColorOption = FULL_COLOR;

  return {
    keys: {
      timeIntervalStorageKey,
      ganttDisplayOptionKey,
      colorOptionKey,
      displayOptionsKey,
      sidePanelOpenKey,
      filterStorageKey,
    },
    defaultValues: {
      defaultTimeInterval,
      defaultGanttDisplayOption,
      defaultColorOption,
      defaultSidePanelOpen,
      defaultShowAvatar,
    },
  };
};

/**
 * Sets the localStorage values for People Gantt(v2) configuration settings
 * @param {string} accountId Active account ID
 * @param {string} userId Current users ID
 * @param {Object} config Gantt configuration settings
 */
const setPeopleGanttConfigLocalStorageValues = (accountId, userId, config = {}) => {
  const storageKeys = {
    showAvatar: getStorageKey(PEOPLE_VIEW, accountId, DISPLAY_OPTIONS_KEY, userId),
    colorOption: getStorageKey(PEOPLE_VIEW, accountId, COLOR_OPTION_KEY, userId),
    timeInterval: getStorageKey(PEOPLE_VIEW, accountId, SLIDER_STORAGE_KEY, userId),
    displayOption: getStorageKey(PEOPLE_VIEW, accountId, GANTT_DISPLAY_KEY, userId),
  };

  Object.keys(storageKeys).forEach((key) => {
    localStorage.setItem(storageKeys[key], config[key]);
  });
};

const getQuickFiltersInitialValue = (defaultQuickFilters) => {
  const quickFilters = {};
  defaultQuickFilters?.args?.forEach(arg => (
    arg.activeFilters.forEach((activeFilter) => {
      const { name, value } = activeFilter;
      if (name === 'Has allocation issue') {
        quickFilters.issuesFilter = !!value;
      }
      if (name === 'Has availability') {
        quickFilters.availableFilter = !!value;
      }
    })
  ));
  return quickFilters;
};

export {
  formatPeopleGanttData,
  getProjectBarRows,
  parseProjectAllocations,
  trimTimeBar,
  getLocalStorageValues,
  getQuickFiltersInitialValue,
  setPeopleGanttConfigLocalStorageValues,
};
