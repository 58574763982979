import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { g100cinderBlockGrey } from '@bridgit/foundation';
import { isColumnStatic } from '../../utils/tableUtils';
import { setColumnOrder } from './redux/actions';

const useStyles = makeStyles({
  root: {
    '&.draggable': {
      cursor: 'grab',
    },
    '&.dragging, &.draggingOver': {
      backgroundColor: g100cinderBlockGrey,
    },
  },
});

export default function withDrag(WrappedComponent) {
  const DraggableComponent = ({
    className,
    column,
    staticColumns,
    visibleColumns,
    allowColumnReordering,
    columnOrderStorageKey,
    ...otherProps
  }) => {
    const dispatch = useDispatch();
    const [isDraggingOver, setIsDraggingOver] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const customClasses = useStyles();

    const columnName = column?.name;
    const draggable = allowColumnReordering && !isColumnStatic(columnName, staticColumns);

    const onDragOver = useCallback((event) => {
      event.stopPropagation();
      event.preventDefault();
      if (!isDraggingOver && !isColumnStatic(columnName, staticColumns)) {
        setIsDraggingOver(true);
      }
    }, [columnName, isDraggingOver, staticColumns]);

    const onDragLeave = useCallback((event) => {
      event.stopPropagation();
      event.preventDefault();
      setIsDraggingOver(false);
    }, []);

    const onDragStart = useCallback((event) => {
      if (columnName) event.dataTransfer.setData('sourceColumn', columnName);
      setIsDragging(true);
    }, [columnName]);

    const onDragEnd = useCallback(() => {
      setIsDragging(false);
      setIsDraggingOver(false);
    }, []);

    const onDrop = useCallback((event) => {
      setIsDragging(false);
      setIsDraggingOver(false);
      if (isColumnStatic(columnName, staticColumns)) return false;

      const sourceColumn = event.dataTransfer.getData('sourceColumn');
      const sourceColumnIdx = visibleColumns.findIndex(item => item.name === sourceColumn);
      const targetColumnIdx = visibleColumns.findIndex(item => item.name === columnName);

      if (sourceColumnIdx === targetColumnIdx) return false;

      const newVisibleColumns = [...visibleColumns];
      newVisibleColumns.splice(targetColumnIdx, 0, newVisibleColumns.splice(sourceColumnIdx, 1)[0]);

      if (columnOrderStorageKey) localStorage.setItem(columnOrderStorageKey, JSON.stringify(newVisibleColumns));
      dispatch(setColumnOrder(newVisibleColumns, sourceColumn));

      return true;
    }, [columnName, staticColumns, visibleColumns, columnOrderStorageKey, dispatch]);

    const customClassName = useMemo(() => classNames(className, customClasses.root, {
      draggable,
      dragging: isDragging,
      draggingOver: isDraggingOver,
    }), [className, draggable, isDragging, isDraggingOver, customClasses.root]);

    return (
      <WrappedComponent
        className={customClassName}
        column={column}
        draggable={draggable}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDrop={onDrop}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
      />
    );
  };

  DraggableComponent.propTypes = {
    column: PropTypes.object.isRequired,
    visibleColumns: PropTypes.array.isRequired,
    staticColumns: PropTypes.arrayOf(PropTypes.string),
    allowColumnReordering: PropTypes.bool,
    columnOrderStorageKey: PropTypes.string,
    className: PropTypes.string,
  };

  DraggableComponent.defaultProps = {
    staticColumns: [],
    allowColumnReordering: false,
    columnOrderStorageKey: undefined,
    className: undefined,
  };

  return DraggableComponent;
}
