import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Avatar } from '@material-ui/core';
import { PersonAvailability } from 'src/features/people';
import { EXCLUDE_PEOPLE_COLUMNS } from './redux/constants';
import { getPersonValue, getFieldValue } from './utils/reportUtils';
import emptyAvatar from '../../images/empty_avatar.svg?url';
import { PersonAllocations } from '.';
import { PEOPLE_DISPLAY_AVAILABILITY_UNTIL } from '../people/constants';

const ReportPeopleList = ({
  people,
  allocations,
  includeAllocations,
  visibleColumns,
}) => {
  const displayColumns = useMemo(() => (
    visibleColumns.filter(column => !EXCLUDE_PEOPLE_COLUMNS.includes(column.name))
  ), [visibleColumns]);

  const showTitle = useMemo(() => (
    !!visibleColumns.find(column => column.name === 'Title')
  ), [visibleColumns]);

  const showAvailability = useMemo(() => (
    !!visibleColumns.find(column => column.name === PEOPLE_DISPLAY_AVAILABILITY_UNTIL)
  ), [visibleColumns]);

  const renderFields = (person) => {
    const fields = [];
    let other;

    displayColumns.forEach((column) => {
      let fieldValue = getPersonValue(column, person);

      // check for null in case the value is empty or false
      if (fieldValue === null) {
        fieldValue = getFieldValue(column, person);
      }

      if (column.name === 'Other') {
        other = {
          value: fieldValue,
          columnName: column.name,
        };
      } else {
        fields.push(
          <div key={column.name} className="field">
            <div className="field-name"><span>{column.name}</span></div>
            <div className="field-value">{fieldValue}</div>
          </div>,
        );
      }
    });

    if (other) {
      // make sure other is always the last field
      fields.push(
        <div key="Other" className="field other">
          <div className="field-name">{other.columnName}</div>
          <div className="field-value">{other.value}</div>
        </div>,
      );
    }

    return (
      <div className="field-wrap">
        {fields}
      </div>
    );
  };

  return (
    <div className="reports-report-people-list">
      {people.map(person => (
        <div key={person.id} className="person-row">
          <div className="person-info">
            <div className="avatar">
              <Avatar src={person.photoUrl ? person.photoUrl : emptyAvatar} />
            </div>
            <div className="name-info">
              <div className="name">{person.name}</div>
              {showTitle && <div className="person-title">{person.title}</div>}
              {showAvailability && (
                <PersonAvailability
                  className="available-in"
                  availableInDays={person.availableInDays}
                />
              )}
            </div>
            {renderFields(person)}
          </div>
          {includeAllocations && (
            <PersonAllocations
              person={person}
              personAllocations={allocations.find(allocation => allocation.personId === person.id)}
            />
          )}
        </div>
      ))}
    </div>
  );
};


ReportPeopleList.propTypes = {
  people: PropTypes.array.isRequired,
  allocations: PropTypes.array.isRequired,
  includeAllocations: PropTypes.bool,
  visibleColumns: PropTypes.array.isRequired,
};

ReportPeopleList.defaultProps = {
  includeAllocations: false,
};

/* istanbul ignore next */
function mapStateToProps({ reports, table }) {
  const { settings: { includeAllocations } } = reports;

  return {
    people: reports.people,
    allocations: reports.peopleAllocations,
    visibleColumns: table.visibleColumns,
    includeAllocations,
  };
}

export default connect(
  mapStateToProps,
)(ReportPeopleList);
