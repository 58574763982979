export const FORECAST_GET_UTILIZATION_DATA_BEGIN = 'FORECAST_GET_UTILIZATION_DATA_BEGIN';
export const FORECAST_GET_UTILIZATION_DATA_SUCCESS = 'FORECAST_GET_UTILIZATION_DATA_SUCCESS';
export const FORECAST_GET_UTILIZATION_DATA_FAILURE = 'FORECAST_GET_UTILIZATION_DATA_FAILURE';
export const FORECAST_GET_UTILIZATION_DATA_DISMISS_ERROR = 'FORECAST_GET_UTILIZATION_DATA_DISMISS_ERROR';
export const FORECAST_GET_BREAKDOWN_BEGIN = 'FORECAST_GET_BREAKDOWN_BEGIN';
export const FORECAST_GET_BREAKDOWN_SUCCESS = 'FORECAST_GET_BREAKDOWN_SUCCESS';
export const FORECAST_GET_BREAKDOWN_FAILURE = 'FORECAST_GET_BREAKDOWN_FAILURE';
export const FORECAST_GET_BREAKDOWN_DISMISS_ERROR = 'FORECAST_GET_BREAKDOWN_DISMISS_ERROR';
export const FORECAST_UPDATE_ANALYTICS_PAYLOAD = 'FORECAST_UPDATE_ANALYTICS_PAYLOAD';
export const FORECAST_GET_WORKFORCE_PLAN_BEGIN = 'FORECAST_GET_WORKFORCE_PLAN_BEGIN';
export const FORECAST_GET_WORKFORCE_PLAN_SUCCESS = 'FORECAST_GET_WORKFORCE_PLAN_SUCCESS';
export const FORECAST_GET_WORKFORCE_PLAN_FAILURE = 'FORECAST_GET_WORKFORCE_PLAN_FAILURE';
export const FORECAST_GET_WORKFORCE_PLAN_DISMISS_ERROR = 'FORECAST_GET_WORKFORCE_PLAN_DISMISS_ERROR';
export const FORECAST_GET_PURSUIT_PROJECTS_BEGIN = 'FORECAST_GET_PURSUIT_PROJECTS_BEGIN';
export const FORECAST_GET_PURSUIT_PROJECTS_SUCCESS = 'FORECAST_GET_PURSUIT_PROJECTS_SUCCESS';
export const FORECAST_GET_PURSUIT_PROJECTS_FAILURE = 'FORECAST_GET_PURSUIT_PROJECTS_FAILURE';
export const FORECAST_GET_PURSUIT_PROJECTS_DISMISS_ERROR = 'FORECAST_GET_PURSUIT_PROJECTS_DISMISS_ERROR';
export const FORECAST_GET_BENCH_COST_OVERVIEW_BEGIN = 'FORECAST_GET_BENCH_COST_OVERVIEW_BEGIN';
export const FORECAST_GET_BENCH_COST_OVERVIEW_SUCCESS = 'FORECAST_GET_BENCH_COST_OVERVIEW_SUCCESS';
export const FORECAST_GET_BENCH_COST_OVERVIEW_FAILURE = 'FORECAST_GET_BENCH_COST_OVERVIEW_FAILURE';
export const FORECAST_GET_BENCH_COST_OVERVIEW_DISMISS_ERROR = 'FORECAST_GET_BENCH_COST_OVERVIEW_DISMISS_ERROR';
export const FORECAST_GET_BENCH_COST_INDIVIDUALS_BEGIN = 'FORECAST_GET_BENCH_COST_INDIVIDUALS_BEGIN';
export const FORECAST_GET_BENCH_COST_INDIVIDUALS_SUCCESS = 'FORECAST_GET_BENCH_COST_INDIVIDUALS_SUCCESS';
export const FORECAST_GET_BENCH_COST_INDIVIDUALS_FAILURE = 'FORECAST_GET_BENCH_COST_INDIVIDUALS_FAILURE';
export const FORECAST_GET_BENCH_COST_INDIVIDUALS_DISMISS_ERROR = 'FORECAST_GET_BENCH_COST_INDIVIDUALS_DISMISS_ERROR';
