import { gql } from 'apollo-boost';
import { MIN_FROM, AUDIT_PAGE_SIZE } from '../../../common/constants';

const PERSON_ENTRY = gql`
  fragment PersonEntry on PersonEntry {
    email
    name
    title
    photoUrl
  }
`;

const DETAILED_PERSON_ENTRY = gql`
  fragment DetailedPersonEntry on DetailedPersonEntry {
    id
    email
    name
    title
    photoUrl
    state
    hasConflict
    lastModifiedOn
    employmentDates {
      startDate
      endDate
    }
  }
`;

const FIELD_ENTRY = gql`
  fragment PersonFieldEntry on FieldEntry {
    data {
      fieldId
      fieldName
      fieldType
      values
    }
  }
`;

const UNAVAILABILITY_ENTRY = gql`
  fragment UnavailabilityEntry on UnavailabilityEntry {
    data {
      id
      rangeType
      startDate
      endDate
      isPrivate
      description
    }
  }
`;

const PERSON_NOTE_ENTRY = gql`
  fragment PersonNoteEntry on PersonNoteEntry {
    id
    message
    isPrivate
    creatorId
    creatorName
    createdOn
    lastModifiedOn
  }
`;

const ATTACHMENT_ENTRY = gql`
  fragment AttachmentEntry on AttachmentEntry {
    id
    name
    size
    mimeType
    isPrivate
    binaryUrl
    creatorId
    createdOn
    state
  }
`;

const CERTIFICATION_ENTRY = gql`
  fragment CertificationEntry on CertificationEntry {
    id
    name
    abbreviation
    expiryDate
    daysWarnBeforeExpire
    hasAttachment
    state
  }
`;

const CERTIFICATION_ATTACHMENT_ENTRY = gql`
  fragment CertificationAttachmentEntry on CertificationAttachmentEntry {
    id
    name
    size
    mimeType
    isPrivate
    binaryUrl
    creatorId
    createdOn
    state
    certificationName
  }
`;

const PERSON_AUDIT_QUERY = gql`
  ${PERSON_ENTRY}
  ${DETAILED_PERSON_ENTRY}
  ${FIELD_ENTRY}
  ${UNAVAILABILITY_ENTRY}
  ${PERSON_NOTE_ENTRY}
  ${ATTACHMENT_ENTRY}
  ${CERTIFICATION_ENTRY}
  ${CERTIFICATION_ATTACHMENT_ENTRY}
  query personAuditQuery($accountId: Int!, $personId: Int!, $from: Int, $size: Int) {
    personAudit(
      accountId: $accountId
      personId: $personId
      from: $from
      size: $size
    ) {
      hasMore
      cursor
      items {
        eventOn
        userId
        userName
        resourceId
        resourceType
        actionType
        eventData {
          ... on PersonEntry { ...PersonEntry }
          ... on DetailedPersonEntry { ...DetailedPersonEntry }
          ... on FieldEntry { ...PersonFieldEntry }
          ... on UnavailabilityEntry { ...UnavailabilityEntry }
          ... on PersonNoteEntry { ...PersonNoteEntry }
          ... on AttachmentEntry { ...AttachmentEntry }
          ... on CertificationEntry { ...CertificationEntry }
          ... on CertificationAttachmentEntry { ...CertificationAttachmentEntry }
        }
      }
    }
  }
`;

export const getPersonAuditQuery = (accountId, personId, from = MIN_FROM) => {
  const variables = {
    accountId,
    personId,
    size: AUDIT_PAGE_SIZE,
    from,
  };

  return {
    query: PERSON_AUDIT_QUERY,
    variables,
  };
};
