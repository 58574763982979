import deepEqual from 'react-fast-compare';
import { defaultFiltersWithModule } from '../../../utils/tableUtils';
import { sortAlphabetically } from '../../../utils/sortUtils';
import { capitalize } from '../../../utils/formatters';
import { getDurationDays, stringToMoment } from '../../../utils/dateUtils';
import { PURSUIT_MODULE_STATUS_TO_REMOVE } from '../../projects/constants';
import {
  FILTERED_PROJECTS_QUERY_ID,
  FILTERED_PEOPLE_QUERY_ID,
  FILTERED_PROJECT_FORECAST_QUERY_ID,
  FILTERED_PROJECT_DASHBOARD_QUERY_ID,
  FILTERED_UNFILLED_ROLES_QUERY_ID } from '../../queries/redux/constants';
import { LIST_TAB, VIEWS, DATE_DISPLAY_FORMAT, FIELD_TYPE_MULTI_SELECT } from '../../../common/constants';
import {
  BLANK_FILTER,
  FILTER_ISSUES,
  ALL_ISSUES,
  NO_ISSUES,
  HAS_ISSUES,
  FILTER_ALLOCATION,
  FILTER_ALLOCATED_PEOPLE,
  FILTER_PHASE,
  FILTER_ROLES,
  MAPPED_PEOPLE_FILTER_NAMES,
  FILTER_AVAILABILITIES,
  NO_AVAILABILITY_FILTER,
  AVAILABLE_NOW_FILTER,
  BETWEEN_FILTER,
  PERCENT_FILTER_TYPE,
  DATE_FILTER_TYPE,
} from '../../../filters/constants';
import { defaultFilters, PROJECT_FILTER_TYPE } from '../common/constants';
import { PROPERTY_SORTED_BY, VIEW_SORTED_ON, SORT_ON } from '../../../analytics/filters/constants';

const mixpanelSortEvent = (columnName, activeView, contentView) => {
  if (typeof activeView === 'undefined' || typeof contentView === 'undefined') {
    return {
      event: null,
      payload: null,
    };
  }

  const viewLabel = VIEWS[activeView];
  const contentViewLabel = contentView === LIST_TAB ? 'List' : 'Gantt';
  const view = contentView !== '' ? ` ${contentViewLabel}` : '';

  return {
    event: `${SORT_ON} ${viewLabel}`,
    payload: {
      [PROPERTY_SORTED_BY]: `${columnName}`,
      [VIEW_SORTED_ON]: `${viewLabel}${view}`,
    },
  };
};

const simplifyFilter = ({ activeFilters, column }) => ({ activeFilters, column });

const isFilterGroupNonEmpty = filter => !!filter?.args?.length;

const isSavedFilterGroup = filter => !!filter?.filterId;

const toUnsavedFilterGroup = ({ name, args, filterType }) => ({ name, args, filterType });

const createFilterGroup = (filterType, defaultFilter, isPursuitEnabled) => {
  const props = { name: 'filter', filterType };
  if (filterType === PROJECT_FILTER_TYPE && isPursuitEnabled === false) {
    return { ...props, args: defaultFiltersWithModule(defaultFilter, PURSUIT_MODULE_STATUS_TO_REMOVE, isPursuitEnabled) };
  }
  return { ...props, args: defaultFilter };
};

const isDefaultFilter = (defaultFilters, filters) => {
  const simplifiedFilters = filters.map(simplifyFilter);
  const simplifiedDefault = defaultFilters.map(simplifyFilter);

  return deepEqual(simplifiedFilters, simplifiedDefault);
};

const getDefaultFilter = (queryId, isPursuitModuleOn) => {
  switch (queryId) {
    case FILTERED_PEOPLE_QUERY_ID:
      return defaultFilters.people;
    case FILTERED_PROJECTS_QUERY_ID:
      return defaultFiltersWithModule(defaultFilters.projects, PURSUIT_MODULE_STATUS_TO_REMOVE, isPursuitModuleOn);
    case FILTERED_PROJECT_DASHBOARD_QUERY_ID:
      return defaultFiltersWithModule(defaultFilters.dashboard, PURSUIT_MODULE_STATUS_TO_REMOVE, isPursuitModuleOn);
    case FILTERED_PROJECT_FORECAST_QUERY_ID:
      return defaultFiltersWithModule(defaultFilters.forecastProject, PURSUIT_MODULE_STATUS_TO_REMOVE, isPursuitModuleOn);
    case FILTERED_UNFILLED_ROLES_QUERY_ID:
      return defaultFiltersWithModule(defaultFilters.projects, PURSUIT_MODULE_STATUS_TO_REMOVE, isPursuitModuleOn);
    default:
      return defaultFilters.empty;
  }
};

const getActiveFiltersCount = (activeFilters) => {
  if (!activeFilters) {
    return 0;
  }

  if (activeFilters.length > 1 && activeFilters[0].selected) {
    let activeFiltersCount = 0;

    activeFilters.forEach((activeFilter) => {
      activeFiltersCount += activeFilter.selected.length;
    });

    return activeFiltersCount;
  }

  if (activeFilters.length === 1 && activeFilters[0].selected) {
    return activeFilters[0].selected.length;
  }
  return activeFilters.length;
};

const getFilterNames = filters => filters.reduce((acc, current) => {
  if ('selected' in current) {
    const multiNames = current.selected.map(selection => selection.name);
    acc.push(multiNames);
  } else if ('name' in current) {
    acc.push(current.name);
  }

  return acc;
}, []);

const getFilterValues = (filter) => {
  const filterValues = [];
  if (filter?.value) filterValues.push(filter?.value);
  if (filter?.secondaryValue) filterValues.push(filter.secondaryValue);
  return filterValues;
};

const generateSelectedFilter = (type, selectedVerb, selectedValue, selectedSecondaryValue) => {
  const isDate = type === DATE_FILTER_TYPE;
  let newSelected = {};
  if (
    !selectedVerb
    || (selectedValue !== 0 && !selectedValue)
    || (selectedVerb === BETWEEN_FILTER && !selectedSecondaryValue)
  ) {
    return null;
  }

  if (selectedVerb === BETWEEN_FILTER) {
    const min = Math.min(selectedValue, selectedSecondaryValue);
    const max = Math.max(selectedValue, selectedSecondaryValue);
    const betweenFilterName = isDate
      ? `${stringToMoment(selectedValue).format(DATE_DISPLAY_FORMAT)} - ${stringToMoment(selectedSecondaryValue).format(DATE_DISPLAY_FORMAT)}`
      : `${min}% - ${max}%`;
    newSelected = {
      name: betweenFilterName,
      verb: selectedVerb,
      value: selectedValue,
      secondaryValue: selectedSecondaryValue,
      type,
    };
  } else {
    const selectedFilterName = isDate
      ? stringToMoment(selectedValue).format(DATE_DISPLAY_FORMAT)
      : `${selectedValue}%`;
    newSelected = {
      name: selectedFilterName,
      verb: selectedVerb,
      value: selectedValue,
      type,
    };
  }

  return newSelected;
};

const multiFilterContainsBlank = filters => filters.some(filter => filter.selected?.some(selected => selected.value === BLANK_FILTER));

const getFilterEventProperties = (newFilter, filters) => {
  const { name, type, filterType } = newFilter;
  const eventBody = {
    'Property filtered by': MAPPED_PEOPLE_FILTER_NAMES[name] || name,
  };

  if (filters && filters.length) {
    const values = getFilterNames(filters);
    const isMulti = Array.isArray(values?.[0]);
    const isNextAvailability = filterType === FILTER_AVAILABILITIES;

    if (isMulti) {
      const isMultiSelect = type === FIELD_TYPE_MULTI_SELECT;
      const isProjectAllocation = filterType === FILTER_ALLOCATION;
      const isPeopleAllocation = filterType === FILTER_ALLOCATED_PEOPLE;
      const isPhase = filterType === FILTER_PHASE;
      const isRoles = filterType === FILTER_ROLES;

      eventBody['Number of filtered values'] = getActiveFiltersCount(filters);
      eventBody['Blank option is selected'] = multiFilterContainsBlank(filters);

      if (isMultiSelect || isProjectAllocation || isPeopleAllocation || isPhase || isRoles) {
        const containsInclude = filters.filter(filter => filter.verb === 'Includes');
        const containsExclude = filters.filter(filter => filter.verb === 'Excludes');
        const verbs = [];
        if (containsInclude.length) verbs.push('Include');
        if (containsExclude.length) verbs.push('Exclude');
        eventBody['Include or Exclude is applied within a filter'] = verbs.join(' and ');
        eventBody['A combination filter is applied'] = filters.length > 1;
        eventBody['Value filtered by'] = values.length === 1
          ? values.join(', ') : JSON.stringify(values);
      } else {
        const { verb } = filters[0];
        eventBody['Filtering dropdown option selected'] = capitalize(verb);
        eventBody['Value filtered by'] = values.join(', ');
      }
    } else if (isNextAvailability) {
      let filteredBy = 'Custom';
      const filterValue = filters[0]?.value;
      if (filterValue === NO_AVAILABILITY_FILTER || filterValue === AVAILABLE_NOW_FILTER) {
        filteredBy = filterValue;
      } else {
        const percentFilter = filters.find(({ type }) => type === PERCENT_FILTER_TYPE);
        const dateFilter = filters.find(({ type }) => type === DATE_FILTER_TYPE);
        if (dateFilter) {
          eventBody['Date option'] = dateFilter.verb;
          eventBody['Date values'] = getFilterValues(dateFilter).join(', ');
          if (dateFilter.verb === BETWEEN_FILTER) {
            eventBody['Date duration'] = getDurationDays(dateFilter.value, dateFilter.secondaryValue);
          }
        }
        if (percentFilter) {
          eventBody['% option'] = percentFilter.verb;
          eventBody['% values'] = getFilterValues(percentFilter).join(', ');
        }
      }
      eventBody['Value filtered by'] = filteredBy;
    } else {
      eventBody['Value filtered by'] = values.join(', ');
      eventBody['Number of filtered values'] = filters.length;
      eventBody['Blank option is selected'] = values.some(a => a === BLANK_FILTER);
    }
  }
  return eventBody;
};

const getFiltersInfo = (filters = []) => {
  const properties = [];
  const values = [];
  let valueCount = 0;

  filters.forEach(({ label, activeFilters, filterType }) => {
    properties.push(label);
    const filterValues = getFilterNames(activeFilters);

    const isMulti = Array.isArray(filterValues?.[0]);

    if (isMulti) {
      filterValues.forEach((valueArray) => {
        values.push(valueArray);
        valueCount += valueArray.length;
      });
    } else if (filterType === FILTER_AVAILABILITIES) {
      let filteredBy = 'Custom';
      const filterValue = activeFilters[0]?.value;
      if (filterValue === NO_AVAILABILITY_FILTER || filterValue === AVAILABLE_NOW_FILTER) {
        filteredBy = filterValue;
      }
      values.push([filteredBy]);
      valueCount += 1;
    } else {
      values.push(filterValues);
      valueCount += filterValues.length;
    }
  });

  return { properties, values, valueCount };
};

function getPeopleFilterSummary(filters) {
  const { properties, values, valueCount } = getFiltersInfo(filters);
  return {
    'Number of filtered people properties': properties.length,
    'People properties filtered by': properties.join(', '),
    'Number of filtered people values': valueCount,
    'People values filtered by': values,
  };
}

function getProjectFilterSummary(filters) {
  const { properties, values, valueCount } = getFiltersInfo(filters);
  return {
    'Number of filtered project properties': properties.length,
    'Project properties filtered by': properties.join(', '),
    'Number of filtered project values': valueCount,
    'Project values filtered by': values,
  };
}

function getFilterSummary({ args, filterType }) {
  const { properties, values, valueCount } = getFiltersInfo(args);
  const capitalType = capitalize(filterType);
  return {
    [`Number of filtered ${filterType} properties`]: properties.length,
    [`${capitalType} properties filtered by`]: properties.join(', '),
    [`Number of filtered ${filterType} values`]: valueCount,
    [`${capitalType} values filtered by`]: values,
  };
}

/*
 Safely extracts the search string from a query object.
 Given the object below, 'abc' will be returned:
  {
    search: {
      name: 'search',
      args: [
        {
          filterType: 'FILTER_NAME',
          activeFilters: [
            'abc'
          ]
        }
      ]
    }
  }
*/
const getSearchStringFromQuery = (queries) => {
  // Validate that this is a query object
  if (!(queries && (typeof queries === 'object'))) return '';

  // Validate that the query contains a search clause with an args array
  if (!('search' in queries && Array.isArray(queries.search.args))) return '';

  // String filters should only have one argument
  const searchArg = queries.search.args[0];

  // Validate that the search arg has a string filter value
  if (!(typeof searchArg === 'object' && Array.isArray(searchArg.activeFilters))) return '';

  return searchArg.activeFilters[0] || '';
};

const checkFilterApplied = (queries, columnName = '') => {
  // Validate inputs
  if (!(
    queries &&
    typeof columnName === 'string' &&
    'filter' in queries &&
    Array.isArray(queries.filter.args))) {
    return false;
  }

  // Look for the given column in the applied filters
  const matchingArg = queries.filter.args.find(arg => arg.column === columnName);

  if (!matchingArg) return false;

  // Not validating the internal structure of the activeFilters, just that at least one exists
  return Array.isArray(matchingArg.activeFilters) && !!matchingArg.activeFilters.length;
};

/*
  Essentially just a wrapper around sortAlphabetically() with a provision to
  make sure (Blank) always appears last. Any inputs that cause sortAlphabetically()
  to throw an exception will be returned unmodified.
*/
const sortFilterOptions = (items, filterKey = '') => {
  try {
    const sortedItems = sortAlphabetically(items, true, filterKey);

    const index = sortedItems.findIndex(i => i[filterKey] === BLANK_FILTER);
    if (index !== -1) {
      sortedItems.push(sortedItems.splice(index, 1)[0]);
    }

    return sortedItems;
  } catch {
    return items;
  }
};

const getFilterIssues = (args) => {
  const filterIssues = args.find(query => query.filterType === FILTER_ISSUES);
  if (!filterIssues || filterIssues.activeFilters.length > 1) {
    // not filtering for issues or filtering for both has issues and does not have issues
    // if both, then case we're not filtering for issues
    return ALL_ISSUES;
  }
  if (filterIssues.activeFilters[0].value) {
    return HAS_ISSUES;
  }
  return NO_ISSUES;
};

const getMappedFilters = columns => columns.reduce(
  (filterableColumns, column) => {
    // Check if column is filterable
    if (column?.options?.filter) {
      const name = MAPPED_PEOPLE_FILTER_NAMES[column.name] || column.name;
      filterableColumns.push({ ...column, name });
    }

    return filterableColumns;
  }, [],
);

const getDashboardEmptyResultText = (isPursuitModuleOn) => {
  const additionalStatuses = isPursuitModuleOn ? ', “Upcoming”, or "Pursuit"' : ' or “Upcoming”';
  return `Dashboards only display projects with an “Active”${additionalStatuses} status. Please adjust your filters.`;
};

export {
  mixpanelSortEvent,
  getActiveFiltersCount,
  getFilterEventProperties,
  getFiltersInfo,
  getProjectFilterSummary,
  getPeopleFilterSummary,
  getFilterSummary,
  getSearchStringFromQuery,
  checkFilterApplied,
  sortFilterOptions,
  getFilterNames,
  getFilterIssues,
  getMappedFilters,
  generateSelectedFilter,
  getDashboardEmptyResultText,
  isDefaultFilter,
  isFilterGroupNonEmpty,
  isSavedFilterGroup,
  toUnsavedFilterGroup,
  createFilterGroup,
  getDefaultFilter,
};
