import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PEOPLE_GET_PERSON_CERTIFICATIONS_BEGIN,
  PEOPLE_GET_PERSON_CERTIFICATIONS_SUCCESS,
  PEOPLE_GET_PERSON_CERTIFICATIONS_FAILURE,
  PEOPLE_GET_PERSON_CERTIFICATIONS_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';
import { DEFAULT_LIST_ITEMS_LIMIT } from '../constants';

export function getPersonCertifications(accountId, personId, offset = 0, limit = DEFAULT_LIST_ITEMS_LIMIT, callback) {
  return {
    type: PEOPLE_GET_PERSON_CERTIFICATIONS_BEGIN,
    accountId,
    personId,
    offset,
    limit,
    callback,
  };
}

export function dismissGetPersonCertificationsError() {
  return {
    type: PEOPLE_GET_PERSON_CERTIFICATIONS_DISMISS_ERROR,
  };
}

export function* doGetPersonCertifications(action) {
  const { accountId, personId, offset, limit, callback } = action;
  let response;
  try {
    response = yield call(protectedJsonGet, `/accounts/${accountId}/persons/${personId}/certifications`, { offset, limit }, true);
  } catch (err) {
    yield put({
      type: PEOPLE_GET_PERSON_CERTIFICATIONS_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PEOPLE_GET_PERSON_CERTIFICATIONS_SUCCESS,
    data: response.data,
    hasMoreItems: response.headers?.['query-has-more'] === 'True',
    clearData: offset === 0,
  });

  if (callback) yield call(callback);
}

export function* watchGetPersonCertifications() {
  yield takeLatest(PEOPLE_GET_PERSON_CERTIFICATIONS_BEGIN, doGetPersonCertifications);
}

export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_GET_PERSON_CERTIFICATIONS_BEGIN:
      return {
        ...state,
        getPersonCertificationsPending: true,
        getPersonCertificationsError: null,
      };

    case PEOPLE_GET_PERSON_CERTIFICATIONS_SUCCESS: {
      const { data, clearData, hasMoreItems } = action;
      const oldItems = clearData || !state.personCertifications?.items ? [] : state.personCertifications.items;

      return {
        ...state,
        personCertifications: {
          items: [...oldItems, ...data],
          hasMore: hasMoreItems,
        },
        getPersonCertificationsPending: false,
        getPersonCertificationsError: null,
      };
    }

    case PEOPLE_GET_PERSON_CERTIFICATIONS_FAILURE:
      return {
        ...state,
        getPersonCertificationsPending: false,
        getPersonCertificationsError: action.data.error,
      };

    case PEOPLE_GET_PERSON_CERTIFICATIONS_DISMISS_ERROR:
      return {
        ...state,
        getPersonCertificationsError: null,
      };

    default:
      return state;
  }
}
