import React, { PureComponent } from 'react';
import { TableRow as MaterialTableRow } from '@material-ui/core';
import PropTypes from 'prop-types';

export default class TableRow extends PureComponent {
  static propTypes = {
    hover: PropTypes.bool,
    selected: PropTypes.bool,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    rowId: PropTypes.string.isRequired,
    rowMeta: PropTypes.object,
  };

  static defaultProps = {
    hover: true,
    selected: false,
    rowMeta: {},
    onClick: () => {},
    onMouseEnter: () => {},
    onMouseLeave: () => {},
  }

  clickHandler = () => {
    const { onClick, rowId, rowMeta } = this.props;
    onClick(rowId, rowMeta);
  }

  mouseEnterHandler = () => {
    const { onMouseEnter, rowId, rowMeta } = this.props;
    onMouseEnter(rowId, rowMeta);
  }

  mouseLeaveHandler = () => {
    const { onMouseLeave, rowId, rowMeta } = this.props;
    onMouseLeave(rowId, rowMeta);
  }

  render() {
    const { hover, selected, children, rowId } = this.props;
    return (
      <MaterialTableRow
        key={rowId}
        hover={hover}
        selected={selected}
        classes={{ selected: 'selected', hover: 'hover' }}
        onClick={this.clickHandler}
        onMouseEnter={this.mouseEnterHandler}
        onMouseLeave={this.mouseLeaveHandler}
        className="table-table-row"
      >
        {children}
      </MaterialTableRow>
    );
  }
}
