import { Login, SignupFromEmailInvite, LoadableEditProfile, ForgotPassword, ProfileInfo } from '.';

export default {
  path: 'login',
  name: 'Login',
  childRoutes: [
    {
      path: 'login',
      name: 'Login',
      component: Login,
      isIndex: true,
    },
    {
      path: '/signup',
      name: 'Sign Up Through Email',
      component: SignupFromEmailInvite,
    },
    {
      path: '/profile',
      name: 'Edit profile',
      component: LoadableEditProfile,
    },
    {
      path: '/forgot-password',
      name: 'Forgot Password',
      component: ForgotPassword,
    },
    {
      path: '/profile-info',
      name: 'Tell Us About Yourself',
      component: ProfileInfo,
    },
  ],
};
