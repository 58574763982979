const CUSTOM_FIELD_DELETED = 'Custom Field Deleted';
const CUSTOM_FIELD_ADDED = 'Custom Field Added';
const CUSTOM_FIELD_UPDATED = 'Custom Field Updated';
const PHASE_CREATED = 'Phase Created';
const PHASE_DELETED = 'Phase Deleted';
const PHASE_EDITED = 'Phase Edited';
const PHASES_REORDERED = 'Phases Reordered';
const PHASE = 'Phase';
const SUB_PHASE = 'Sub-phase';
const PROJECT = 'Project';
const PEOPLE = 'People';
const HOURLY_PEOPLE = 'HourlyPeople';
const SKILL_SET = 'Skill set';
const MULTI_SELECT = 'MultiSelect';
const PURSUIT_TRACKING_ENABLED = 'Pursuit Tracking Enabled';
const PURSUIT_TRACKING_DISABLED = 'Pursuit Tracking Disabled';
const MODULE_COMPONENT_UPDATED = 'Module component updated';
const CERTIFICATION_CREATED = 'Certification Created';
const CERTIFICATION_EDITED = 'Certification Edited';
const CERTIFICATION_DELETED = 'Certification Deleted';

export {
  CUSTOM_FIELD_DELETED,
  CUSTOM_FIELD_ADDED,
  CUSTOM_FIELD_UPDATED,
  PHASE_CREATED,
  PHASE_DELETED,
  PHASE_EDITED,
  PHASES_REORDERED,
  PHASE,
  SUB_PHASE,
  PROJECT,
  PEOPLE,
  HOURLY_PEOPLE,
  SKILL_SET,
  MULTI_SELECT,
  PURSUIT_TRACKING_ENABLED,
  PURSUIT_TRACKING_DISABLED,
  MODULE_COMPONENT_UPDATED,
  CERTIFICATION_CREATED,
  CERTIFICATION_EDITED,
  CERTIFICATION_DELETED,
};
