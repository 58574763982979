import moment from 'moment';
import { DATE_INPUT_FORMAT } from 'src/common/constants';
import { PROJECT_SCHEMAS } from 'src/common/descriptors/project';
import {
  ROLES,
  FILTER_ROLES,
  FILLED_ROLES,
  UNFILLED_ROLES,
  MUST,
  MUST_NOT,
} from './constants';
import { ALL_MONTHS } from '../features/roles/constants';
import {
  COMPLETE,
  LOST,
  CANCELED,
} from '../features/projects/constants';
import { generateRequestFilter } from './graphqlUtils';

const generateTimeframeFilter = (timeframe) => {
  if (timeframe === ALL_MONTHS) {
    return ({
      match: {
        timeframeStartDate: moment().format(DATE_INPUT_FORMAT),
      },
    });
  }

  return ({
    match: {
      timeframeStartDate: moment().format(DATE_INPUT_FORMAT),
      timeframeEndDate: moment().add(timeframe, 'months').format(DATE_INPUT_FORMAT),
    },
  });
};

const generateNameShould = (acc, { name }) => {
  if (name !== FILLED_ROLES && name !== UNFILLED_ROLES) {
    acc.push({
      match: {
        roles__name: name,
      },
    });
  }
  return acc;
};

const generateRoleFilter = (roleQuery) => {
  if (!roleQuery) return [];
  const { activeFilters } = roleQuery;

  return activeFilters.reduce((acc, { inclusive, selected }) => {
    const boolStatement = inclusive ? MUST : MUST_NOT;
    const should = selected.reduce(generateNameShould, []);

    if (should?.length) {
      acc.push({
        bool: {
          [boolStatement]: [
            {
              bool: {
                should,
              },
            },
          ],
        },
      });
    }
    return acc;
  }, []);
};

const generateRoleListRequest = (projectQueries, timeframe) => {
  let roleQuery;
  const path = ROLES;

  const reducedQueries = {
    ...projectQueries,
    args: projectQueries?.args?.reduce((acc, query) => {
      if (query.filterType === FILTER_ROLES) {
        roleQuery = query;
      } else {
        acc.push(query);
      }
      return acc;
    }, []),
  };

  const request = generateRequestFilter(reducedQueries, PROJECT_SCHEMAS);
  const timeframeFilter = generateTimeframeFilter(timeframe);
  const roleFilter = generateRoleFilter(roleQuery);

  return [
    ...request,
    {
      nested: {
        path,
        inner_hits: {}, // eslint-disable-line camelcase
        query: {
          bool: {
            must: [
              {
                match: {
                  roles__isFilled: false, // eslint-disable-line camelcase
                },
              },
              timeframeFilter,
              ...roleFilter,
            ],
          },
        },
      },
    },
    {
      bool: {
        must_not: [
          {
            match: {
              state: COMPLETE,
            },
          },
          {
            match: {
              state: LOST,
            },
          },
          {
            match: {
              state: CANCELED,
            },
          },
        ],
      },
    },
  ];
};

export {
  generateRoleListRequest,
};
