import {
  ACCOUNT_SETTINGS_DELETE_SUB_PHASE_FAILURE,
  ACCOUNT_SETTINGS_UPDATE_SUB_PHASE_FAILURE,
  ACCOUNT_SETTINGS_UPDATE_SUB_PHASES_FAILURE,
  ACCOUNT_SETTINGS_REMOVE_ACCOUNT_MODULE_FAILURE,
} from '../../account-settings/redux/constants';
import { LOGIN_USERLOGOUT_FAILURE } from '../../login/redux/constants';
import {
  PROJECTS_ADD_PROJECT_SUB_PHASE_FAILURE,
  PROJECTS_DELETE_PROJECT_SUB_PHASE_FAILURE,
  PROJECTS_UPDATE_PROJECT_SUB_PHASE_FAILURE,
  PROJECTS_ADD_PROJECT_ROLE_ALLOCATION_FAILURE,
  PROJECTS_REPLACE_PROJECT_ROLE_ALLOCATION_FAILURE,
  PROJECTS_UPDATE_PROJECT_ROLE_ALLOCATION_FAILURE,
} from '../../projects/redux/constants';

export const ERRORS_DISMISS_ERROR = 'ERRORS_DISMISS_ERROR';
export const BENCH_SUPPORT_URL =
  'https://support.bridgitsolutions.com/hc/en-us/categories/360001651432-Bridgit-Bench';
export const FAILURE = 'FAILURE';
export const PERFORM_AN_ACTION = 'perform the requested action';

export const ERROR_SUBSTITUTIONS = {
  [ACCOUNT_SETTINGS_DELETE_SUB_PHASE_FAILURE]: 'delete sub-phase',
  [ACCOUNT_SETTINGS_UPDATE_SUB_PHASE_FAILURE]: 'update sub-phase',
  [ACCOUNT_SETTINGS_UPDATE_SUB_PHASES_FAILURE]: 'updates sub-phases',
  [LOGIN_USERLOGOUT_FAILURE]: 'user logout',
  [PROJECTS_ADD_PROJECT_SUB_PHASE_FAILURE]: 'add project sub-phase',
  [PROJECTS_DELETE_PROJECT_SUB_PHASE_FAILURE]: 'delete project sub-phase',
  [PROJECTS_UPDATE_PROJECT_SUB_PHASE_FAILURE]: 'update project sub-phase',
  [PROJECTS_ADD_PROJECT_ROLE_ALLOCATION_FAILURE]: 'add allocation',
  [PROJECTS_REPLACE_PROJECT_ROLE_ALLOCATION_FAILURE]: 'replace allocation',
  [PROJECTS_UPDATE_PROJECT_ROLE_ALLOCATION_FAILURE]: 'update allocation',
  [ACCOUNT_SETTINGS_REMOVE_ACCOUNT_MODULE_FAILURE]: 'disable module. The Standard or Self-Perform module must be enabled',
};

export const REQUIRED_FIELDS_MISSING = /Value for required fields are missing/g;
export const NOT_ALL_VALUES_AVAILABLE = /Not all value\(s\) for required fields are available/g;
export const INVALID_PROJECT_DATE = /.*date cannot be before.*/g;
export const PROJECT_OPPORTUNITIES_PRESENT = /'Pursuit Tracking' module cannot be disabled when there are Opportunities on the account./g;

export const DEFAULT = 'DEFAULT';
export const AUTH_ERROR = 'AUTH_ERROR';
export const REQUIRED_PRIVATE_FIELD = 'REQUIRED_PRIVATE_FIELD';
export const UNABLE_TO_ADD_PROJECTS = 'UNABLE_TO_ADD_PROJECTS';
export const UNABLE_TO_UPDATE_PROJECTS = 'UNABLE_TO_UPDATE_PROJECTS';
export const UNABLE_TO_DISABLE_PURSUIT_TRACKING = 'UNABLE_TO_DISABLE_PURSUIT_TRACKING';

export const PROJECT_DATES_UPDATE_FAILED = 'Project dates update failed';
export const PROJECT_PHASES_UPDATE_FAILED = 'Project phases update failed';
export const PROJECT_ROLES_UPDATE_FAILED = 'Project roles update failed';

export const PROFILE_ATTACHMENT_NAME_IN_USE = 'PROFILE_ATTACHMENT_NAME_IN_USE';
export const PROFILE_ATTACHMENT_ACCOUNT_LIMIT_REACHED = 'PROFILE_ATTACHMENT_ACCOUNT_LIMIT_REACHED';
export const PROFILE_ATTACHMENT_VALIDATION_ERROR = 'PROFILE_ATTACHMENT_VALIDATION_ERROR';
export const PROFILE_ATTACHMENT_DOWNLOAD_ERROR = 'PROFILE_ATTACHMENT_DOWNLOAD_ERROR';
export const PROFILE_CERTIFICATION_DELETE_INSUFFICIENT_PERMISSIONS = 'PROFILE_CERTIFICATION_DELETE_INSUFFICIENT_PERMISSIONS';
export const PROFILE_ATTACHMENT_UPLOAD_ERROR = 'PROFILE_ATTACHMENT_UPLOAD_ERROR';
export const PROFILE_ATTACHMENT_CERT_LINK_ERROR = 'PROFILE_ATTACHMENT_CERT_LINK_ERROR';

export const SHARE_PEOPLE_GANTT_DATA_INVALID = 'SHARE_PEOPLE_GANTT_DATA_INVALID';
export const SET_SHARE_PEOPLE_GANTT_DATA_FAILED = 'SET_SHARE_PEOPLE_GANTT_DATA_FAILED';

export const CUSTOM_MESSAGES = {
  [REQUIRED_PRIVATE_FIELD]: 'You are unable to add this project to your account due to a required, private field that you do not have access to.',
  [UNABLE_TO_ADD_PROJECTS]: 'Sorry, we are unable to add projects with a start date earlier than January 1, 2000.',
  [UNABLE_TO_UPDATE_PROJECTS]: 'Sorry, we are unable to update projects with a start date earlier than January 1, 2000.',
  [PROJECT_DATES_UPDATE_FAILED]: 'Sorry, there was a technical issue and we were unable to edit project dates. As a result, no phase dates, roles, or allocations were edited. Please try again or contact support.',
  [PROJECT_PHASES_UPDATE_FAILED]: 'Sorry, there was a technical issue and we were unable to edit phase dates. As a result, no roles, or allocations were edited. Please try again or contact support.',
  [PROJECT_ROLES_UPDATE_FAILED]: 'Sorry, there was a technical issue and we were unable to edit role and allocation dates. Please try again or contact support.',
  [UNABLE_TO_DISABLE_PURSUIT_TRACKING]: PROJECT_OPPORTUNITIES_PRESENT.source,
  [PROFILE_ATTACHMENT_NAME_IN_USE]: 'An attachment with that name already exists. Please rename and try again.',
  [PROFILE_ATTACHMENT_ACCOUNT_LIMIT_REACHED]: 'Account file limit reached. Remove files to add more',
  [PROFILE_ATTACHMENT_VALIDATION_ERROR]: 'The file could not be uploaded. Please ensure the file name does not contain special characters and is less than 200 characters in length.',
  [PROFILE_ATTACHMENT_DOWNLOAD_ERROR]: 'Download failed. Please try again.',
  [PROFILE_CERTIFICATION_DELETE_INSUFFICIENT_PERMISSIONS]: 'You do not have permission to delete this certification.',
  [PROFILE_ATTACHMENT_UPLOAD_ERROR]: 'Upload failed. Please delete the attachment and re-upload',
  [PROFILE_ATTACHMENT_CERT_LINK_ERROR]: 'We were unable to link the upload to this certification. The upload can be found in the attachments area of this profile.',
  [SHARE_PEOPLE_GANTT_DATA_INVALID]: 'Sorry, the link has expired. Please contact the person who shared this link with you.',
  [SET_SHARE_PEOPLE_GANTT_DATA_FAILED]: 'The link failed to generate. Please try again.',
};

export const ERRORS_CLEAR_APP_DATA = 'ERRORS_CLEAR_APP_DATA';
