import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ClickAwayListener, Popper } from '@material-ui/core';
import { SelectPhase } from '.';
import { parsePhases } from './utils/phaseUtils';

export class SelectPhaseMenu extends PureComponent {
  static propTypes = {
    anchorEl: PropTypes.object,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    phases: PropTypes.array.isRequired,
    selectedPhases: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
    placement: PropTypes.string,
  };

  static defaultProps = {
    anchorEl: null,
    placement: 'top-start',
  };

  constructor(props) {
    super(props);

    const { phases, selectedPhases } = props;

    this.state = {
      parsedPhases: parsePhases(phases, selectedPhases),
      allPhasesUsed: this.allPhasesSelected(phases, selectedPhases),
      allPhasesSelected: this.allPhasesSelected,
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { phases, selectedPhases } = nextProps;
    const { allPhasesSelected } = state;
    return {
      parsedPhases: parsePhases(phases, selectedPhases),
      allPhasesUsed: allPhasesSelected(phases, selectedPhases),
    };
  }

  allPhasesSelected = (phases, selectedPhases) => {
    if (phases.length !== selectedPhases.length) {
      return false;
    }

    const incomplete = phases.filter((phase) => {
      const selected = selectedPhases.find(p => p.id === phase.id);
      return phase.subPhases.length !== selected.subPhases.length;
    });

    return incomplete.length === 0;
  }

  onClickAway = (evt) => {
    const { onClose } = this.props;
    const targetClass = evt.target.className;
    if (typeof targetClass === 'string' && (targetClass.includes('subphase-select') || targetClass.includes('phases-select-menu'))) return;
    onClose();
  }

  render() {
    const {
      anchorEl,
      open,
      onSelect,
      placement,
    } = this.props;

    const {
      parsedPhases,
      allPhasesUsed,
    } = this.state;

    return (
      <Popper
        className="phases-select-menu"
        anchorEl={anchorEl}
        open={open}
        placement={placement}
      >
        <ClickAwayListener onClickAway={this.onClickAway}>
          <div className="phase-select-container">

            { !parsedPhases.length && (
              <div className="no-phases">
                <p>No phases have been created on your account</p>
              </div>
            )}

            {allPhasesUsed && parsedPhases.length > 0 && (
              <div className="no-phases">
                <p>All phases have been set up on this project</p>
              </div>
            )}

            { parsedPhases.length > 0 && !allPhasesUsed && (
              <>
                {parsedPhases.map(phase => (
                  <SelectPhase
                    key={phase.id}
                    phase={phase}
                    onSelect={onSelect}
                  />
                ))}
              </>
            )}
          </div>
        </ClickAwayListener>
      </Popper>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps({ accountSettings }) {
  return {
    phases: accountSettings.phases,
  };
}

export default connect(
  mapStateToProps,
)(SelectPhaseMenu);
