import { detect } from 'detect-browser';
import config from '../common/envConfig';
import { SUPPORTED_BROWSERS,
  MOBILE_OPERATING_SYSTEMS } from '../config/app-config';

export function getMobileOS() {
  const browser = detect();

  if (!browser) {
    return false;
  }

  const { os } = browser;

  return MOBILE_OPERATING_SYSTEMS.includes(os.toLowerCase()) ? os : null;
}

export function isSupportedBrowser() {
  const browser = detect();

  if (!browser) {
    return false;
  }

  const { name, os } = browser;


  if (SUPPORTED_BROWSERS.includes(name.toLowerCase()) && !MOBILE_OPERATING_SYSTEMS.includes(os.toLowerCase())) {
    return true;
  }
  // Unsupported if user is on a mobile device and using a browser instead of the app
  return false;
}


export function isFirefox() {
  const browser = detect();

  if (!browser) {
    // Assuming firefox if the browser cannot be detected.
    return true;
  }

  return browser.name === 'firefox';
}

export function isEdge() {
  const browser = detect();

  if (!browser) {
    // Assuming edge if the browser cannot be detected.
    return true;
  }

  const { name } = browser;
  return (name === 'edge' || name === 'edge-chromium');
}

export function buildGraphQlUri() {
  const { location } = window;
  const { protocol } = location;
  return `${protocol}//${config.graphapiDomain}/graphql`;
}

export function saveToLocalStorage(config = {}) {
  const { key, value } = config;
  if (key) localStorage.setItem(key, JSON.stringify(value));
}
