import { SETTING_RANGES, SETTING_SLIDER_STEP } from './constants';
import { THEME_MODE_SETTINGS_KEY, THEME_NAME_KEY, THEME_USE_SYSTEM_THEME_KEY } from '../../common/localStorageKeys';

export const getStepNumber = (currentValue, themeName, settingName) => () => {
  const minValue = SETTING_RANGES[themeName][settingName][0];

  return (currentValue - minValue) / SETTING_SLIDER_STEP;
};

export const getStoredThemeSettings = (userId, themeName) => JSON.parse(localStorage.getItem(`${THEME_MODE_SETTINGS_KEY}-${userId}-${themeName}`) || null);
export const setStoredThemeSettings = (themeName, themeObject, userId) => {
  const stringifiedSettings = JSON.stringify(themeObject);

  localStorage.setItem(`${THEME_MODE_SETTINGS_KEY}-${userId}-${themeName}`, stringifiedSettings);
};

export const getStoredSystemThemeStatus = userId => JSON.parse(localStorage.getItem(`${THEME_USE_SYSTEM_THEME_KEY}-${userId}`) || false);
export const setStoredSystemThemeStatus = (status, userId) => localStorage.setItem(`${THEME_USE_SYSTEM_THEME_KEY}-${userId}`, status);

export const getStoredThemeName = userId => localStorage.getItem(`${THEME_NAME_KEY}-${userId}`);
export const setStoredThemeName = (themeName, userId) => localStorage.setItem(`${THEME_NAME_KEY}-${userId}`, themeName);
