import React from 'react';
import PropTypes from 'prop-types';
import { BestMatchListItem } from '.';
import { AUTOMATION_SP_BEST_MATCHES_LIST } from './ids';

const BestMatchList = ({
  matches,
  type,
  onSelectPerson,
  selectedPerson,
}) => {
  const selectedPersonId = selectedPerson?.id;

  if (!matches?.length) {
    return (
      <div className="self-perform-best-match-list empty">{`There are no ${type} matches for this request.`}</div>
    );
  }

  return (
    <div id={AUTOMATION_SP_BEST_MATCHES_LIST} className="self-perform-best-match-list">
      {matches.map(person => (
        <BestMatchListItem
          key={person.id}
          person={person}
          isSelected={selectedPersonId === person.id}
          onSelectPerson={onSelectPerson}
          type={type}
        />
      ))}
    </div>
  );
};

BestMatchList.propTypes = {
  matches: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  onSelectPerson: PropTypes.func.isRequired,
  selectedPerson: PropTypes.object,
};

BestMatchList.defaultProps = {
  selectedPerson: null,
};

export default BestMatchList;
