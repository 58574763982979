import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getDurationDays } from 'src/utils/dateUtils';
import { TimelineSegment } from 'src/features/common';

export default function Timeline({
  startDate,
  endDate,
  onSegmentClick,
  segments,
  placeholder,
}) {
  const [roleDays, setRoleDays] = useState(getDurationDays(startDate, endDate));

  useEffect(() => {
    setRoleDays(getDurationDays(startDate, endDate));
  }, [startDate, endDate]);

  const timeBlocks = segments.map((block) => {
    const { startDate: blockStart, endDate: blockEnd, topClass, bottomClass } = block;
    const segmentDays = getDurationDays(blockStart, blockEnd);
    const percentWidth = (segmentDays / roleDays) * 100;

    return (
      <TimelineSegment
        key={`${blockStart}-${blockEnd}`}
        blockData={block}
        percentWidth={percentWidth}
        topClass={topClass}
        bottomClass={bottomClass}
        onSegmentClick={onSegmentClick}
        placeholder={placeholder}
      />
    );
  });

  return (
    <div className="common-timeline">
      {timeBlocks}
    </div>
  );
}

Timeline.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  onSegmentClick: PropTypes.func,
  segments: PropTypes.arrayOf(PropTypes.object).isRequired,
  placeholder: PropTypes.string,
};

Timeline.defaultProps = {
  onSegmentClick: () => {},
  placeholder: '',
};
