import { LoadableSettings } from '.';

export default {
  path: 'settings',
  name: 'Settings',
  childRoutes: [
    {
      path: '',
      name: '',
      component: LoadableSettings,
      isIndex: true,
    },
  ],
};
