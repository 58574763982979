import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNT_SETTINGS_UPDATE_ROLE_NAMES_BEGIN,
  ACCOUNT_SETTINGS_UPDATE_ROLE_NAMES_SUCCESS,
  ACCOUNT_SETTINGS_UPDATE_ROLE_NAMES_FAILURE,
  ACCOUNT_SETTINGS_UPDATE_ROLE_NAMES_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';

export function updateRoleNames(accountId, roles, alphabetize) {
  return {
    type: ACCOUNT_SETTINGS_UPDATE_ROLE_NAMES_BEGIN,
    accountId,
    roles,
    alphabetize,
  };
}

export function dismissUpdateRoleNamesError() {
  return {
    type: ACCOUNT_SETTINGS_UPDATE_ROLE_NAMES_DISMISS_ERROR,
  };
}

export function* doUpdateRoleNames(action) {
  const { accountId, roles, alphabetize } = action;
  let res;
  try {
    const url = `accounts/${accountId}/role-names${alphabetize ? '?alphabetize=true' : ''}`;
    res = yield call(protectedJsonPost, url, roles);
  } catch (err) {
    yield put({
      type: ACCOUNT_SETTINGS_UPDATE_ROLE_NAMES_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: ACCOUNT_SETTINGS_UPDATE_ROLE_NAMES_SUCCESS,
    data: res,
  });
}

export function* watchUpdateRoleNames() {
  yield takeLatest(ACCOUNT_SETTINGS_UPDATE_ROLE_NAMES_BEGIN, doUpdateRoleNames);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNT_SETTINGS_UPDATE_ROLE_NAMES_BEGIN:
      return {
        ...state,
        updateRoleNamesPending: true,
        updateRoleNamesError: null,
      };

    case ACCOUNT_SETTINGS_UPDATE_ROLE_NAMES_SUCCESS:
      return {
        ...state,
        roles: action.data,
        updateRoleNamesPending: false,
        updateRoleNamesError: null,
      };

    case ACCOUNT_SETTINGS_UPDATE_ROLE_NAMES_FAILURE:
      return {
        ...state,
        updateRoleNamesPending: false,
        updateRoleNamesError: action.data.error,
      };

    case ACCOUNT_SETTINGS_UPDATE_ROLE_NAMES_DISMISS_ERROR:
      return {
        ...state,
        updateRoleNamesError: null,
      };

    default:
      return state;
  }
}
