import React from 'react';
import PropTypes from 'prop-types';
import { AppBar } from '@material-ui/core';
import { BRIDGIT_LOGO } from 'src/features/common/constants';

const RootContent = ({ children }) => (
  <div className="common-root-content">
    <AppBar className="app-header root-header-bar" variant="outlined" />
    <div className="app-page-offset root-logo">
      <img className="bridgit-logo" src={BRIDGIT_LOGO} alt="Bridgit Logo" />
    </div>
    {children}
  </div>
);

RootContent.propTypes = {
  children: PropTypes.node,
};

RootContent.defaultProps = {
  children: null,
};

export default RootContent;
