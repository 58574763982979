import {
  REPORTS_CLEAR_REPORT_DATA,
} from './constants';
import initialState from './initialState';

export function clearReportData() {
  return {
    type: REPORTS_CLEAR_REPORT_DATA,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case REPORTS_CLEAR_REPORT_DATA:
      return initialState;

    default:
      return state;
  }
}
