import React from 'react';
import PropTypes from 'prop-types';
import { DATE_DISPLAY_FORMAT, DATE_INPUT_FORMAT } from 'src/common/constants';
import moment from 'moment';

const SingleRoleRequirement = ({
  startDate,
  endDate,
  percentage,
}) => {
  const formattedStart = moment(startDate, DATE_INPUT_FORMAT).format(DATE_DISPLAY_FORMAT);
  const formattedEnd = moment(endDate, DATE_INPUT_FORMAT).format(DATE_DISPLAY_FORMAT);
  const formattedDates = `${formattedStart} - ${formattedEnd}`;

  return (
    <div className="allocations-single-role-requirement">
      <span className="date-range">{formattedDates}</span>
      <span className="requirement">{percentage}</span>
    </div>
  );
};

SingleRoleRequirement.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
};

export default SingleRoleRequirement;
