import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { EDITABLE_SEGMENT_TYPES, SEGMENT_TYPE_ROLE, SEGMENT_TYPE_PHASE } from './common/constants';
import { GroupHeader, SegmentFactory } from '.';
import { DATE_EDIT_ACTIONS, EDIT_ACTION_EXTENDED } from '../../common/constants';
import { Can } from '../wrapped-components';
import { PERM_ROLE, PERM_WRITE } from '../permissions/utils/constants';

function SegmentGroup({
  groupType,
  segments,
  editAction,
  typeUnderEdit,
}) {
  const typedSegments = useMemo(
    () => segments?.[groupType] || [],
    [segments, groupType],
  );

  const totalImpacted = useMemo(
    () => typedSegments.filter(({ checked }) => !!checked).length,
    [typedSegments],
  );

  if (!typedSegments.length) return null;

  const groupBody = (
    <div className="edit-dates-segment-group">
      <GroupHeader groupType={groupType} totalImpacted={totalImpacted} />

      {typedSegments.map((segment) => {
        const { id } = segment;
        return (
          <SegmentFactory
            key={id}
            segmentType={groupType}
            segment={segment}
          />
        );
      })}

    </div>
  );

  if (groupType === SEGMENT_TYPE_ROLE &&
    (editAction === EDIT_ACTION_EXTENDED || typeUnderEdit === SEGMENT_TYPE_PHASE)) {
    return (
      <Can
        key="no-perms"
        action={PERM_WRITE}
        subject={PERM_ROLE}
        yes={groupBody}
        no={(
          <div className="no-permissions edit-dates-segment-group">
            <p>Sorry, you don&#39;t have permission to edit roles.</p>
          </div>
        )}
      />
    );
  }

  return groupBody;
}

SegmentGroup.propTypes = {
  groupType: PropTypes.oneOf(EDITABLE_SEGMENT_TYPES).isRequired,
  segments: PropTypes.object.isRequired,
  editAction: PropTypes.oneOf(DATE_EDIT_ACTIONS).isRequired,
  typeUnderEdit: PropTypes.oneOf(EDITABLE_SEGMENT_TYPES).isRequired,
};

/* istanbul ignore next */
function mapStateToProps({ editDates }) {
  const { segments, editAction, typeUnderEdit } = editDates;

  return { segments, editAction, typeUnderEdit };
}

export default connect(
  mapStateToProps,
)(SegmentGroup);
