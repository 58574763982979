import { VIEWS } from './constants';

/* Key values used to set and load localStorage values */

export const LocalStorageKeys = {
  projectView: 'projectView',
  peopleView: 'peopleView',
  projectGanttView: 'projectGanttView',
  projectRoleSort: 'projectRoleSort',
  projectRoleStateFilters: 'projectRoleStateFilters',
  dashboardView: 'dashboardView',
  forecastView: 'forecastView',
  selfPerformView: 'selfPerformView',
};

export const SORT_STORAGE_KEY = 'sortStorageKey';
export const DASHBOARD_UNFILLED_ROLES_STORAGE_KEY = 'dashboardUnfilledRolesStorageKey';
export const FILTER_STORAGE_KEY = 'filterStorageKey';
export const PEOPLE_FILTER_STORAGE_KEY = 'peopleFilterStorageKey';
export const PROJECT_FILTER_STORAGE_KEY = 'projectFilterStorageKey';
export const COLUMN_ORDER_STORAGE_KEY = 'columnOrderStorageKey';
export const COLUMN_STORAGE_KEY = 'columnStorageKey';
export const WORKFORCE_ROLE_STORAGE_KEY = 'workforceRoleStorageKey';
export const WORKFORCE_TITLE_STORAGE_KEY = 'workforceTitleStorageKey';
export const WORKFORCE_PURSUIT_STORAGE_KEY = 'workforcePursuitStorageKey';
export const SLIDER_STORAGE_KEY = 'sliderStorageKey';
export const UTILIZATION_RATE_STORAGE_KEY = 'utilizationRateStorageKey';
export const UTILIZATION_TIMEFRAME_STORAGE_KEY = 'forecastingTimeframeStorageKey';
export const SWP_TIMEFRAME_STORAGE_KEY = 'swpTimeframeStorageKey';
export const SWP_GRAPH_TABLE_STORAGE_KEY = 'swpGraphTableStorageKey';
export const BREAKDOWN_FIELD_ID_STORAGE_KEY = 'breakdownFieldIdStorageKey';
export const BREAKDOWN_TAB_STORAGE_KEY = 'breakdownTabStorageKey';
export const DISPLAY_OPTIONS_KEY = 'displayOptionsKey';
export const GANTT_DISPLAY_KEY = 'ganttDisplayKey';
export const COLOR_OPTION_KEY = 'colorOptionKey';
export const LAST_VISITED_ACCOUNT_ID_KEY = 'lastVisitedAccountIdKey';
export const SIDE_PANEL_OPEN_KEY = 'sidePanelOpenKey';
export const PROJECT_DETAILS_SIDE_PANEL_OPEN_KEY = 'notesSidePanelOpenKey';
export const NOTES_SIDE_PANEL_OPEN_KEY = 'notesSidePanelOpenKey';
export const THEME_NAME_KEY = 'themeNameKey';
export const THEME_MODE_SETTINGS_KEY = 'themeModeSettingsKey';
export const THEME_USE_SYSTEM_THEME_KEY = 'themeSystemKey';
export const PERSON_DETAILS_SIDE_PANEL_OPEN_KEY = 'personDetailsSidePanelOpenKey';
export const PEOPLE_GANTT_REPORT_ARGS_KEY = 'PeopleGanttReportArgsKey';
export const PROJECT_GANTT_REPORT_ARGS_KEY = 'ProjectGanttReportArgsKey';
export const PROJECT_GANTT_VIEW_ROLES_KEY = 'ProjectGanttViewRoles';
export const PROJECT_GANTT_SORT_ROLES_KEY = 'ProjectGanttSortRoles';
export const PROJECT_GANTT_FILTER_ROLES_KEY = 'ProjectGanttFilterRoles';
export const ACCOUNTS_LIST_SEARCH_KEY = 'accountsListSearchKey';

export const perAccountUserKey = (prefix, accountId, userId) => [prefix, accountId, userId].join('-');

export const getStorageKey = (activeView, accountId, key, userId) => {
  const view = VIEWS[activeView];

  switch (key) {
    case SORT_STORAGE_KEY:
      return `sorted${view}Columns-${accountId}-${userId}`;
    case DASHBOARD_UNFILLED_ROLES_STORAGE_KEY:
      return `${view}IntervalSelection-${accountId}-${userId}`;
    case FILTER_STORAGE_KEY:
      return `filter${view}Columns-${accountId}-${userId}`;
    case PEOPLE_FILTER_STORAGE_KEY:
      return `${view}-people-filter-${accountId}-${userId}`;
    case PROJECT_FILTER_STORAGE_KEY:
      return `${view}-project-filter-${accountId}-${userId}`;
    case COLUMN_ORDER_STORAGE_KEY:
      return `${view.toLowerCase()}ColumnsSortOrder-${accountId}-${userId}`;
    case COLUMN_STORAGE_KEY:
      return `visible${view}Columns-${accountId}-${userId}`;
    case WORKFORCE_ROLE_STORAGE_KEY:
      return `${view}WorkforceRoleFilter-${accountId}-${userId}`;
    case WORKFORCE_TITLE_STORAGE_KEY:
      return `${view}WorkforceTitleFilter-${accountId}-${userId}`;
    case WORKFORCE_PURSUIT_STORAGE_KEY:
      return `${view}WorkforceScenarioFilter-${accountId}-${userId}`;
    case SLIDER_STORAGE_KEY:
      return `${view}GanttTimeInterval-${accountId}-${userId}`;
    case UTILIZATION_RATE_STORAGE_KEY:
      return `${view}UtilizationRate-${accountId}-${userId}`;
    case UTILIZATION_TIMEFRAME_STORAGE_KEY:
      return `${view}ForecastingTimeframe-${accountId}-${userId}`;
    case SWP_TIMEFRAME_STORAGE_KEY:
      return `${view}SwpTimeframe-${accountId}-${userId}`;
    case SWP_GRAPH_TABLE_STORAGE_KEY:
      return `${view}SwpGraphTableView-${accountId}-${userId}`;
    case BREAKDOWN_FIELD_ID_STORAGE_KEY:
      return `${view}BreakdownFieldId-${accountId}-${userId}`;
    case BREAKDOWN_TAB_STORAGE_KEY:
      return `${view}BreakdownTab-${accountId}-${userId}`;
    case DISPLAY_OPTIONS_KEY:
      return `${view}DisplayOptions-${accountId}-${userId}`;
    case GANTT_DISPLAY_KEY:
      return `${view}GanttDisplay-${accountId}-${userId}`;
    case COLOR_OPTION_KEY:
      return `${view}ColorOption-${accountId}-${userId}`;
    case SIDE_PANEL_OPEN_KEY:
      return `${view}SidePanelOpen-${accountId}-${userId}`;
    case PROJECT_DETAILS_SIDE_PANEL_OPEN_KEY:
      return `${view}NotesSidePanelOpen-${accountId}-${userId}`;
    case PEOPLE_GANTT_REPORT_ARGS_KEY:
      return `PeopleGanttReportArgs-${accountId}-${userId}`;
    case PROJECT_GANTT_REPORT_ARGS_KEY:
      return `ProjectGanttReportArgsKey-${accountId}-${userId}`;
    case PROJECT_GANTT_VIEW_ROLES_KEY:
      return `ProjectGanttViewRoles-${accountId}-${userId}`;
    case PROJECT_GANTT_SORT_ROLES_KEY:
      return `ProjectGanttSortRoles-${accountId}-${userId}`;
    case PROJECT_GANTT_FILTER_ROLES_KEY:
      return `ProjectGanttFilterRoles-${accountId}-${userId}`;
    default:
      return '';
  }
};
