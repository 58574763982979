import { call, put, takeLatest, select } from 'redux-saga/effects';
import {
  ACCOUNTS_GET_ACCOUNTS_BEGIN,
  ACCOUNTS_GET_ACCOUNTS_SUCCESS,
  ACCOUNTS_GET_ACCOUNTS_FAILURE,
  ACCOUNTS_GET_ACCOUNTS_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';
import { setActiveAccountId } from '../../common/redux/actions';
import { getOrSetAccountId } from '../utils/accountUtils';
import { userInfoSelector } from './selectors';
import { accountIdSelector } from '../../common/redux/selectors';

export function getAccounts() {
  // If need to pass args to saga, pass it with the begin action.
  return {
    type: ACCOUNTS_GET_ACCOUNTS_BEGIN,
  };
}

export function dismissGetAccountsError() {
  return {
    type: ACCOUNTS_GET_ACCOUNTS_DISMISS_ERROR,
  };
}

// worker Saga: will be fired on ACCOUNTS_GETACCOUNTS_BEGIN actions
export function* doGetAccounts() {
  try {
    const accounts = yield call(protectedJsonGet, 'accounts');

    // if the user sings up, accountId will be accessible from the store, but userInfo won't be
    const isUserSigningUp = yield select(accountIdSelector);

    if (!isUserSigningUp) {
      const userInfo = yield select(userInfoSelector);
      const { sub: userId, permissions } = userInfo;
      const isBridgitAdmin = permissions?.['*'];

      if (!isBridgitAdmin) {
        const accountId = getOrSetAccountId(accounts, userId);
        yield put(setActiveAccountId(accountId, { isNavigatingBetweenAccounts: false }));
      }
    }

    yield put({
      type: ACCOUNTS_GET_ACCOUNTS_SUCCESS,
      data: accounts,
    });
  } catch (err) {
    yield put({
      type: ACCOUNTS_GET_ACCOUNTS_FAILURE,
      data: { error: err },
    });
  }
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchGetAccounts() {
  yield takeLatest(ACCOUNTS_GET_ACCOUNTS_BEGIN, doGetAccounts);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNTS_GET_ACCOUNTS_BEGIN:
      return {
        ...state,
        getAccountsPending: true,
        getAccountsError: null,
      };

    case ACCOUNTS_GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        entities: action.data,
        getAccountsPending: false,
        getAccountsError: null,
      };

    case ACCOUNTS_GET_ACCOUNTS_FAILURE:
      return {
        ...state,
        getAccountsPending: false,
        getAccountsError: action.data.error,
      };

    case ACCOUNTS_GET_ACCOUNTS_DISMISS_ERROR:
      return {
        ...state,
        getAccountsError: null,
      };

    default:
      return state;
  }
}
