import {
  BLANK_FILTER,
  FIELDS,
  FIELDS_NAME,
  FIELDS_VALUE_SELECTION,
} from './constants';

const generateSingleSelectFilter = (activeFilters, column) => {
  const path = FIELDS;
  const valueFilters = activeFilters.filter(value => value !== BLANK_FILTER);
  const should = [];

  if (valueFilters.length) {
    should.push(
      {
        bool: {
          must: {
            nested: {
              path,
              query: {
                bool: {
                  must: [
                    {
                      match: {
                        [FIELDS_NAME]: column,
                      },
                    },
                    {
                      bool: {
                        should: valueFilters.map(field => (
                          {
                            match: {
                              [FIELDS_VALUE_SELECTION]: field.value,
                            },
                          }
                        )),
                      },
                    },
                  ],
                },
              },
            },
          },
        },
      },
    );
  }

  if (activeFilters.find(field => field.value === BLANK_FILTER)) {
    should.push({
      bool: {
        must_not: [{
          nested: {
            path,
            query: {
              bool: {
                must: {
                  match: {
                    [FIELDS_NAME]: column,
                  },
                },
              },
            },
          },
        }],
      },
    });
  }

  return {
    bool: {
      should,
    },
  };
};

export default generateSingleSelectFilter;
