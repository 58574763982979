import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import deepEqual from 'react-fast-compare';
import { bindActionCreators } from 'redux';
import { PERM_WRITE, PERM_PROJECT } from 'src/features/permissions/utils/constants';
import { Can } from '../wrapped-components';
import { ProjectPhases, ShiftProjectDates, ProjectDates } from '.';
import { getProject } from './redux/actions';

class ProjectDateTab extends PureComponent {
  static propTypes = {
    accountId: PropTypes.number.isRequired,
    selectedProject: PropTypes.object.isRequired,
    getProject: PropTypes.func.isRequired,
    runsInModal: PropTypes.bool,
  };

  static defaultProps = {
    runsInModal: false,
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const {
      accountId,
      selectedProject,
      getProject,
    } = this.props;

    if (!deepEqual(selectedProject.phases, nextProps.selectedProject.phases)) {
      getProject(accountId, selectedProject.id);
    }
  }

  render() {
    const {
      selectedProject,
      runsInModal,
    } = this.props;

    const parentName = `Project Details${runsInModal ? ' Modal' : ''}`;

    return (
      <div className="projects-project-date-tab">
        <Can
          action={PERM_WRITE}
          subject={PERM_PROJECT}
          yes={(
            <div className="project-date-smart-options">
              <span>Smart options</span>
              <div className="action-buttons">
                <ShiftProjectDates project={selectedProject} parentName={parentName} />
              </div>
            </div>
          )}
        />
        <ProjectDates project={selectedProject} parentName={parentName} />
        <ProjectPhases project={selectedProject} parentName={parentName} />
      </div>
    );
  }
}

const mapStateToProps = ({ common }) => {
  const { accountId } = common;

  return {
    accountId,
  };
};

const mapDispatchToProps = dispatch => ({
  getProject: bindActionCreators(getProject, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectDateTab);
export { ProjectDateTab };
