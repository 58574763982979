import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setGanttConfig } from 'src/features/gantt/redux/actions';
import { PeopleGantt } from 'src/features/gantt';
import {
  getPeopleDateRanges,
  getTodayPosition,
  getColumnHeaders,
  PeopleZoomConfig,
} from 'src/features/gantt/utils/ganttUtils';
import { parsePeople } from 'src/features/gantt/utils/peopleGanttUtils';
import { updateMixpanelPeopleGanttProps } from 'src/common/analyticsHelper';
import {
  SLIDER_STORAGE_KEY,
  DISPLAY_OPTIONS_KEY,
  GANTT_DISPLAY_KEY,
  COLOR_OPTION_KEY,
  getStorageKey,
} from 'src/common/localStorageKeys';
import { getValidatedLocalStorage } from 'src/utils/validators';
import {
  PEOPLE_VIEW,
  DATE_INPUT_FORMAT,
  MIN_DATA_RANGE_IN_MONTHS,
  MAX_DATA_RANGE_IN_YEARS,
} from 'src/common/constants';
import { PEOPLE_GANTT_CONFIG_KEY, DEFAULT_GANTT_SCALE } from '../redux/constants';
import { getAccountAllocations } from '../../people/redux/actions';

function PeopleGanttContainer({
  loadMore,
  accountId,
  userId,
  scaleValue,
  scrollPosition,
  displayOptions,
  ganttDisplay,
  colorOption,
  allocations,
  filteredPeople,
  setGanttConfig,
  projectChangePending,
  getAccountAllocations,
}) {
  useEffect(() => {
    const sliderStorageKey = getStorageKey(PEOPLE_VIEW, accountId, SLIDER_STORAGE_KEY, userId);
    const storedSliderValue = getValidatedLocalStorage(sliderStorageKey);
    const scaleValue = Number.isInteger(storedSliderValue) ? storedSliderValue : DEFAULT_GANTT_SCALE;
    setGanttConfig(PEOPLE_GANTT_CONFIG_KEY, { scaleValue }, sliderStorageKey);

    const displayOptionsKey = getStorageKey(PEOPLE_VIEW, accountId, DISPLAY_OPTIONS_KEY, userId);
    const displayOptions = getValidatedLocalStorage(displayOptionsKey);
    if (displayOptions) {
      setGanttConfig(PEOPLE_GANTT_CONFIG_KEY, { displayOptions }, displayOptionsKey);
    }

    const ganttDisplayKey = getStorageKey(PEOPLE_VIEW, accountId, GANTT_DISPLAY_KEY, userId);
    const ganttDisplay = getValidatedLocalStorage(ganttDisplayKey);
    if (ganttDisplay) {
      setGanttConfig(PEOPLE_GANTT_CONFIG_KEY, { ganttDisplay }, ganttDisplayKey);
    }

    const colorOptionKey = getStorageKey(PEOPLE_VIEW, accountId, COLOR_OPTION_KEY, userId);
    const colorOption = getValidatedLocalStorage(colorOptionKey);
    if (colorOption) {
      setGanttConfig(PEOPLE_GANTT_CONFIG_KEY, { colorOption }, colorOptionKey);
    }
  }, [accountId, setGanttConfig, userId]);

  useEffect(() => {
    updateMixpanelPeopleGanttProps(displayOptions, ganttDisplay, colorOption);
  }, [displayOptions, ganttDisplay, colorOption]);

  useEffect(() => {
    const zoomConfig = PeopleZoomConfig[scaleValue];
    const dates = getPeopleDateRanges(zoomConfig);
    const columnHeaders = getColumnHeaders(dates.min, dates.max, zoomConfig);
    const defaultStart = moment.utc().startOf('day').subtract(MIN_DATA_RANGE_IN_MONTHS, 'months');
    const parsedPeople = parsePeople(defaultStart, dates.max, allocations, filteredPeople);

    setGanttConfig(PEOPLE_GANTT_CONFIG_KEY, {
      columnHeaders,
      parsedPeople,
      todayPosition: getTodayPosition(dates.min, zoomConfig),
      scrollToPosition: getTodayPosition(dates.min, zoomConfig, scrollPosition),
    });
  }, [
    scaleValue,
    scrollPosition,
    allocations,
    filteredPeople,
    setGanttConfig,
  ]);

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (!projectChangePending) {
      const startDate = moment.utc().subtract(MIN_DATA_RANGE_IN_MONTHS, 'months');
      const endDate = moment.utc().add(MAX_DATA_RANGE_IN_YEARS, 'years');
      getAccountAllocations(accountId, startDate.format(DATE_INPUT_FORMAT), endDate.format(DATE_INPUT_FORMAT));
    }
  }, [projectChangePending, getAccountAllocations, accountId]);

  return <PeopleGantt loadMore={loadMore} />;
}

PeopleGanttContainer.propTypes = {
  loadMore: PropTypes.func.isRequired,
  accountId: PropTypes.number.isRequired,
  userId: PropTypes.string.isRequired,
  scaleValue: PropTypes.number.isRequired,
  scrollPosition: PropTypes.object,
  displayOptions: PropTypes.object.isRequired,
  ganttDisplay: PropTypes.string.isRequired,
  colorOption: PropTypes.string.isRequired,
  allocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  filteredPeople: PropTypes.arrayOf(PropTypes.object).isRequired,
  setGanttConfig: PropTypes.func.isRequired,
  getAccountAllocations: PropTypes.func.isRequired,
  projectChangePending: PropTypes.bool.isRequired,
};

PeopleGanttContainer.defaultProps = {
  scrollPosition: null,
};

/* istanbul ignore next */
function mapStateToProps({ common, login, gantt, people, projects }) {
  const { accountId } = common;
  const { sub: userId } = login.userInfo;
  const { allocations, filteredPeople } = people;

  const {
    deleteProjectPending,
    replaceProjectRoleAllocationPending,
    updateProjectRoleAllocationPending,
    addProjectRoleAllocationPending,
    deleteProjectAllocationPending,
    updateProjectRolePending,
    updateProjectPending,
  } = projects;

  const projectChangePending = replaceProjectRoleAllocationPending ||
    updateProjectRoleAllocationPending ||
    addProjectRoleAllocationPending ||
    updateProjectRolePending ||
    deleteProjectPending ||
    deleteProjectAllocationPending ||
    updateProjectPending;

  const {
    scaleValue,
    scrollPosition,
    displayOptions,
    ganttDisplay,
    colorOption,
  } = gantt.instances[PEOPLE_GANTT_CONFIG_KEY];

  return {
    accountId,
    userId,
    scaleValue,
    scrollPosition,
    displayOptions,
    ganttDisplay,
    colorOption,
    allocations,
    filteredPeople,
    projectChangePending,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    setGanttConfig: bindActionCreators(setGanttConfig, dispatch),
    getAccountAllocations: bindActionCreators(getAccountAllocations, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PeopleGanttContainer);
