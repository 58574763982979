import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Avatar, Button } from '@material-ui/core';
import { PEOPLE_GANTT_CONFIG_KEY } from 'src/features/gantt/redux/constants';
import emptyAvatar from 'src/images/empty_avatar.svg?url';

const classes = {
  button: {
    root: 'gantt-people-gantt-avatar',
    label: 'avatar-content',
  },
};

function PeopleGanttAvatar({
  person,
  showModal,
  displayOptions,
}) {
  return (
    <Button classes={classes.button} onClick={showModal(person)}>
      {displayOptions.photo && (
        <div className="avatar-container">
          <Avatar className="avatar" src={person.photoUrl ? person.photoUrl : emptyAvatar} />
        </div>
      )}
      <div className="name-wrap">
        <span className="name">{person.name}</span>
        {displayOptions.title && <span className="title">{person.title}</span>}
      </div>
    </Button>
  );
}

PeopleGanttAvatar.propTypes = {
  person: PropTypes.object,
  showModal: PropTypes.func,
  displayOptions: PropTypes.object.isRequired,
};

PeopleGanttAvatar.defaultProps = {
  person: {},
  showModal: () => {},
};

/* istanbul ignore next */
function mapStateToProps({ gantt }) {
  const { displayOptions } = gantt.instances[PEOPLE_GANTT_CONFIG_KEY];
  return { displayOptions };
}

export default connect(
  mapStateToProps,
  null,
)(PeopleGanttAvatar);
