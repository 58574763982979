import {
  GANTT_SET_GANTT_CONFIG_SETTINGS,
} from './constants';

export function setGanttConfigSettings(instanceKey, settings) {
  return {
    type: GANTT_SET_GANTT_CONFIG_SETTINGS,
    instanceKey,
    settings,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case GANTT_SET_GANTT_CONFIG_SETTINGS: {
      const { instanceKey, settings } = action;
      const { instances } = state;
      const prevSettings = instances[instanceKey] || {};
      return {
        ...state,
        instances: {
          ...instances,
          [instanceKey]: {
            ...prevSettings,
            ...settings,
          },
        },
      };
    }

    default:
      return state;
  }
}
