import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { OverflowTooltip, useIsRefOverflowing } from '@bridgit/foundation';
import { LocationOffOutlined } from '@material-ui/icons';

import { AllocationValue } from '.';
import {
  DATE_DISPLAY_FORMAT,
  ACCOUNT_MODULE_LOCATION,
} from '../../common/constants';
import { hasModuleEnabled } from '../permissions/utils/permissionUtils';

const AllocationModalContentHeader = ({
  name,
  note,
  startDate,
  endDate,
  allocations,
  onCustomClick,
  projectAddress,
}) => {
  const isProjectAddressShown = useSelector(({ accountSettings }) => {
    const { accountModules } = accountSettings;

    return hasModuleEnabled(accountModules, ACCOUNT_MODULE_LOCATION);
  });

  const roleStartFormatted = moment(startDate).format(DATE_DISPLAY_FORMAT);
  const roleEndFormatted = moment(endDate).format(DATE_DISPLAY_FORMAT);
  const showCustom = allocations.length > 1;
  const percent = allocations && allocations.length > 0 ? allocations[0].allocatedPercent : 100;

  const { ref: refName, isOverflowing: isNameOverflowing } = useIsRefOverflowing(name);
  const { ref: refNote, isOverflowing: isNoteOverflowing } = useIsRefOverflowing(note);
  const { ref: refAddress, isOverflowing: isAddressOverflowing } = useIsRefOverflowing(projectAddress);

  const renderProjectAddressHeaderItem = useCallback(() => (
    <div className="header-item">
      <span className="section-heading">Project Address</span>
      {projectAddress
        ? (
          <OverflowTooltip text={projectAddress} isOverflowing={isAddressOverflowing}>
            <span ref={refAddress} className="section-value">{projectAddress}</span>
          </OverflowTooltip>
        )
        : (
          <div className="empty-section-container">
            <LocationOffOutlined className="empty-section-icon" />
            <span>No address</span>
          </div>
        )}
    </div>
  ), [isAddressOverflowing, projectAddress, refAddress]);

  return (
    <div className="allocations-allocation-modal-content-header">
      <div className="header-item long-content">
        <span className="section-heading">Role</span>
        <OverflowTooltip text={name} isOverflowing={isNameOverflowing}>
          <span ref={refName} className="section-value">{name}</span>
        </OverflowTooltip>
      </div>

      {note && (
        <div className="header-item long-content">
          <span className="section-heading">Note</span>
          <OverflowTooltip text={note} isOverflowing={isNoteOverflowing}>
            <span ref={refNote} className="section-value">{note}</span>
          </OverflowTooltip>
        </div>
      )}

      <div className="header-item">
        <span className="section-heading">Start Date</span>
        <span className="section-value">{roleStartFormatted}</span>
      </div>

      <div className="header-item">
        <span className="section-heading">End Date</span>
        <span className="section-value">{roleEndFormatted}</span>
      </div>

      <div className="header-item">
        <span className="section-heading">Allocation</span>
        <span className="section-value">
          <AllocationValue allocatedPercent={percent} showCustom={showCustom} onCustomClick={onCustomClick} />
        </span>
      </div>

      {isProjectAddressShown && renderProjectAddressHeaderItem()}
    </div>
  );
};

AllocationModalContentHeader.propTypes = {
  name: PropTypes.string.isRequired,
  note: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  allocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCustomClick: PropTypes.func.isRequired,
  projectAddress: PropTypes.string,
};

AllocationModalContentHeader.defaultProps = {
  note: '',
  projectAddress: null,
};

export default AllocationModalContentHeader;
