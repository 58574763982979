const isColumnStatic = (columnName, staticColumns) => {
  if (!columnName || !staticColumns || !staticColumns.length) {
    return false;
  }
  const columnNameLower = columnName.toLowerCase();
  return staticColumns.some(name => columnNameLower === name.toLowerCase());
};

const defaultFiltersWithModule = (defaultFilter, itemsToFilter, isModuleOn) => {
  if (isModuleOn) return defaultFilter;

  const activeFilters = defaultFilter[0].activeFilters.reduce((newActiveFilters, filter) => {
    if (itemsToFilter.includes(filter.name)) {
      return newActiveFilters;
    }
    newActiveFilters.push(filter);
    return newActiveFilters;
  }, []);

  return [{
    ...defaultFilter[0],
    activeFilters,
  }];
};

export {
  isColumnStatic,
  defaultFiltersWithModule,
};

