import {
  PEOPLE_CLEAR_PERSON_SELECTION,
} from './constants';

export function clearPersonSelection(selectionId) {
  return {
    type: PEOPLE_CLEAR_PERSON_SELECTION,
    selectionId,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_CLEAR_PERSON_SELECTION: {
      const { selectionId } = action;
      const newState = { ...state };
      delete newState.personSelections[selectionId];
      return newState;
    }

    default:
      return state;
  }
}
