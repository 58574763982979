import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tooltip } from '@material-ui/core';

export default function Capsule({
  className,
  style,
  children,
  primaryColorCode,
  borderColorCode,
  onClick,
  tooltip,
}) {
  const capsuleStyles = {
    ...(primaryColorCode && {
      backgroundColor: primaryColorCode,
      borderColor: primaryColorCode,
    }),
    ...(borderColorCode && { borderColor: borderColorCode }),
    ...style,
  };

  const enableTooltip = tooltip.length ? undefined : false;

  return (
    <Tooltip title={tooltip} placement="top" open={enableTooltip}>
      <div
        role="presentation"
        className={classNames('common-capsule', className)}
        style={capsuleStyles}
        onClick={onClick}
      >
        { children }
      </div>
    </Tooltip>
  );
}

Capsule.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  primaryColorCode: PropTypes.string,
  borderColorCode: PropTypes.string,
  onClick: PropTypes.func,
  tooltip: PropTypes.string,
};

Capsule.defaultProps = {
  className: '',
  style: {},
  children: null,
  primaryColorCode: '',
  borderColorCode: '',
  onClick: () => {},
  tooltip: '',
};
