import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

export class PageHeader extends PureComponent {
  static propTypes = {
    headline: PropTypes.string.isRequired,
    children: PropTypes.node,
  };

  static defaultProps = {
    children: '',
  };

  render() {
    const { children, headline } = this.props;

    return (
      <Grid justify="space-between" container className="common-page-header">
        <h5 className="typography--headline">
          {headline}
        </h5>
        {children}
      </Grid>
    );
  }
}

export default PageHeader;
