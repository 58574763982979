import { call, put, takeLatest } from 'redux-saga/effects';
import { DEFAULT_PROJECT_ALLOCATION_STATES } from 'src/features/projects/constants';
import {
  PEOPLE_GET_PERSON_ALLOCATIONS_BY_DATE_BEGIN,
  PEOPLE_GET_PERSON_ALLOCATIONS_BY_DATE_SUCCESS,
  PEOPLE_GET_PERSON_ALLOCATIONS_BY_DATE_FAILURE,
  PEOPLE_GET_PERSON_ALLOCATIONS_BY_DATE_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';

export function getPersonAllocationsByDate(accountId, personId, startDate, endDate, projectState = DEFAULT_PROJECT_ALLOCATION_STATES) {
  return {
    type: PEOPLE_GET_PERSON_ALLOCATIONS_BY_DATE_BEGIN,
    accountId,
    personId,
    startDate,
    endDate,
    projectState,
  };
}

export function dismissGetPersonAllocationsByDateError() {
  return {
    type: PEOPLE_GET_PERSON_ALLOCATIONS_BY_DATE_DISMISS_ERROR,
  };
}

export function* doGetPersonAllocationsByDate(action) {
  const { accountId, personId, startDate, endDate, projectState } = action;
  let res;

  try {
    res = yield call(protectedJsonGet, `accounts/${accountId}/persons/${personId}/allocations?start=${startDate}&end=${endDate}&projectState=${projectState}`);
  } catch (err) {
    yield put({
      type: PEOPLE_GET_PERSON_ALLOCATIONS_BY_DATE_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PEOPLE_GET_PERSON_ALLOCATIONS_BY_DATE_SUCCESS,
    data: res,
    personId,
  });
}

export function* watchGetPersonAllocationsByDate() {
  yield takeLatest(PEOPLE_GET_PERSON_ALLOCATIONS_BY_DATE_BEGIN, doGetPersonAllocationsByDate);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_GET_PERSON_ALLOCATIONS_BY_DATE_BEGIN:
      return {
        ...state,
        getPersonAllocationsByDatePending: true,
        getPersonAllocationsByDateError: null,
      };

    case PEOPLE_GET_PERSON_ALLOCATIONS_BY_DATE_SUCCESS: {
      const { personId } = action;

      return {
        ...state,
        personAllocationsByDate: {
          ...state.personAllocationsByDate,
          [personId]: action.data,
        },
        getPersonAllocationsByDatePending: false,
        getPersonAllocationsByDateError: null,
      };
    }
    case PEOPLE_GET_PERSON_ALLOCATIONS_BY_DATE_FAILURE:
      return {
        ...state,
        getPersonAllocationsByDatePending: false,
        getPersonAllocationsByDateError: action.data.error,
      };

    case PEOPLE_GET_PERSON_ALLOCATIONS_BY_DATE_DISMISS_ERROR:
      return {
        ...state,
        getPersonAllocationsByDateError: null,
      };

    default:
      return state;
  }
}
