import { gql } from 'apollo-boost';
import { PAGE_SIZE } from '../../../common/constants';
import { generateRequestFilter, generateRequestSort } from '../../../filters/graphqlUtils';
import { HOURLY_PERSONS_SCHEMAS } from '../../../common/descriptors/hourlyPerson';

const HOURLY_PERSONS_LIST_QUERY = gql`
    query getHourlyPersons($accountId: Int!, $size: Int, $from: Int, $filters: [ESQuery], $structuredSort: [HourlyPersonSort], $isActive: Boolean) {
      hourlyPersons(accountId: $accountId, size: $size, from: $from, filters: $filters, structuredSort: $structuredSort, isActive: $isActive) {
        count
        hasMore
        cursor
        items {
          id
          accountId
          name
          phoneNumber
          skillSet
          currentAssignments {
            project {
              name
            }
            startDate
            endDate
            description
          }
          upcomingAssignments {
            project {
              name
            }
            startDate
            endDate
            description
          }
          pastAssignments {
            project {
              name
            }
            startDate
            endDate
            description
          }
          availabilities {
            startDate
            endDate
          }
          unavailabilities {
            id
            description
            startDate
            endDate
            isPrivate
            rangeType
          }
          fields {
            id
            isPrivate
            name
            dataType
            values
          }
        }
      }
    }
  `;

const getHourlyPersonsListQuery = (accountId, queries = {}, pageFrom, pageSize, isActive = true) => {
  const defaults = {
    size: pageSize || PAGE_SIZE,
    isActive,
  };

  const { filter, sort, search } = queries;

  const defaultSort = [{ name: { order: 'asc' } }];

  const searchFilter = generateRequestFilter(search, HOURLY_PERSONS_SCHEMAS);
  const filters = generateRequestFilter(filter, HOURLY_PERSONS_SCHEMAS).concat(searchFilter);
  const structuredSort = generateRequestSort(sort, HOURLY_PERSONS_SCHEMAS, defaultSort);

  const variables = { accountId, filters, structuredSort, from: pageFrom, ...defaults };

  return {
    query: HOURLY_PERSONS_LIST_QUERY,
    variables,
  };
};

export {
  getHourlyPersonsListQuery,
};
