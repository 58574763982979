import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Delete } from '@material-ui/icons';
import { CircularProgress, IconButton } from '@material-ui/core';
import { FileUploader } from '@bridgit/foundation';
import { ImageCrop } from '../common';
import {
  LOGO_UPLOAD_CONFIG,
  LOGO_UPLOAD_ERROR_MESSAGES,
} from './redux/constants';

function AddLogo({
  logo,
  onSave,
  onDelete,
  open,
  showModal,
  hideModal,
  loading,
}) {
  const [image, setImage] = useState(null);

  const resetDefaults = () => {
    setImage(null);
  };

  const onUpload = (file) => {
    setImage(file);
    showModal();
  };

  const onClose = () => {
    hideModal();
    resetDefaults();
  };

  const onSaveBlob = (blob) => {
    onSave(blob);
    resetDefaults();
  };

  if (loading) {
    return (
      <div className="accounts-add-logo">
        <div className="loading">
          <CircularProgress size={25} color="primary" />
        </div>
      </div>
    );
  }

  return (
    <div className="accounts-add-logo">
      {!logo
        ? (
          <FileUploader
            title="Click to upload or drag and drop a .jpeg or .png file"
            dropzoneProps={LOGO_UPLOAD_CONFIG}
            errorMessages={LOGO_UPLOAD_ERROR_MESSAGES}
            onUpload={onUpload}
          />
        )
        : (
          <div className="logo-wrap editable">
            <img src={logo} className="logo" alt="logo" />
            <div className="hover-gradient">
              <IconButton className="delete-icon" variant="contained" onClick={onDelete}>
                <Delete aria-label="Required" />
              </IconButton>
            </div>
          </div>
        )}
      {open && !!image && (
        <ImageCrop
          image={image}
          saveBlob={onSaveBlob}
          onClose={onClose}
          borderRadius={0}
          disableBoundaryChecks
          minScale={0.1}
          zoomStep={0.1}
          zoomMax={5}
        />
      )}
    </div>
  );
}

AddLogo.propTypes = {
  logo: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

AddLogo.defaultProps = {
  logo: '',
  loading: false,
};

export default AddLogo;
