import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PROJECTS_REFRESH_PROJECT_ALLOCATIONS_BEGIN,
  PROJECTS_REFRESH_PROJECT_ALLOCATIONS_SUCCESS,
  PROJECTS_REFRESH_PROJECT_ALLOCATIONS_FAILURE,
  PROJECTS_REFRESH_PROJECT_ALLOCATIONS_DISMISS_ERROR,
  MAX_PROJECTS,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';
import { setStartDateTimeStamp } from '../utils/projectUtils';

export function refreshProjectAllocations(accountId) {
  return {
    type: PROJECTS_REFRESH_PROJECT_ALLOCATIONS_BEGIN,
    accountId,
  };
}

export function dismissRefreshProjectAllocationsError() {
  return {
    type: PROJECTS_REFRESH_PROJECT_ALLOCATIONS_DISMISS_ERROR,
  };
}

export function* doRefreshProjectAllocations(action) {
  const { accountId } = action;
  let res;
  try {
    res = yield call(protectedJsonGet, `accounts/${accountId}/projects?limit=${MAX_PROJECTS}`);
  } catch (err) {
    yield put({
      type: PROJECTS_REFRESH_PROJECT_ALLOCATIONS_FAILURE,
      data: { error: err },
    });
    return;
  }

  const result = setStartDateTimeStamp(res);
  yield put({
    type: PROJECTS_REFRESH_PROJECT_ALLOCATIONS_SUCCESS,
    data: result,
  });
}

export function* watchRefreshProjectAllocations() {
  yield takeLatest(PROJECTS_REFRESH_PROJECT_ALLOCATIONS_BEGIN, doRefreshProjectAllocations);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PROJECTS_REFRESH_PROJECT_ALLOCATIONS_BEGIN:
      return {
        ...state,
        refreshProjectAllocationsPending: true,
        refreshProjectAllocationsError: null,
      };

    case PROJECTS_REFRESH_PROJECT_ALLOCATIONS_SUCCESS: {
      const { data } = action;
      const { filteredProjects } = state;
      const newFilteredProjects = filteredProjects.map((filteredProject) => {
        const project = data.find(p => p.id === filteredProject.id);
        const { unfilledRoles, totalRoles, issuesCount } = project;
        return {
          ...filteredProject,
          unfilledRoles,
          totalRoles,
          hasIssues: issuesCount > 0,
        };
      });
      return {
        ...state,
        projects: data,
        filteredProjects: newFilteredProjects,
        refreshProjectAllocationsPending: false,
        refreshProjectAllocationsError: null,
      };
    }

    case PROJECTS_REFRESH_PROJECT_ALLOCATIONS_FAILURE:
      return {
        ...state,
        refreshProjectAllocationsPending: false,
        refreshProjectAllocationsError: action.data.error,
      };

    case PROJECTS_REFRESH_PROJECT_ALLOCATIONS_DISMISS_ERROR:
      return {
        ...state,
        refreshProjectAllocationsError: null,
      };

    default:
      return state;
  }
}
