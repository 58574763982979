import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { IconButton } from '@bridgit/foundation';
import { Edit } from '@material-ui/icons/';
import classNames from 'classnames';

export default class EditButton extends PureComponent {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    tooltipTitle: PropTypes.string,
    tooltipPlacement: PropTypes.string,
    disabled: PropTypes.bool,
    iconClass: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    tooltipTitle: 'Edit',
    tooltipPlacement: 'top',
    disabled: false,
    iconClass: '',
    className: '',
  }

  render() {
    const { onClick, tooltipTitle, tooltipPlacement, disabled, iconClass, className } = this.props;

    return (
      <Tooltip className={classNames('wrapped-components-edit-button', className)} title={tooltipTitle} placement={tooltipPlacement}>
        <IconButton
          size="large"
          variant="plain"
          onClick={onClick}
          disabled={disabled}
          ariaLabel={tooltipTitle}
          className={iconClass}
        >
          <Edit />
        </IconButton>
      </Tooltip>
    );
  }
}
