export default function generateTextFilter(fields) {
  const values = fields.map(field => ({
    match: {
      hasConflict: field.value,
    },
  }));

  return {
    bool: {
      should: values,
    },
  };
}
