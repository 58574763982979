const initialState = {
  people: [],
  ganttReportPeople: [],
  projects: [],
  peopleAllocations: [],
  peopleGanttAllocations: [],
  roleSegments: [],
  showReport: false,
  settings: {},
  type: null,
  imagePreloadPending: false,
  getReportPeoplePending: false,
  getReportPeopleError: null,
  getReportProjectsPending: false,
  getReportProjectsError: null,
  getPeopleAllocationsPending: false,
  getPeopleAllocationsError: null,
  collectPeopleDataPending: false,
  collectPeopleDataError: null,
  collectProjectDataPending: false,
  collectProjectDataError: null,
  getProjectAllocationsPending: false,
  getProjectAllocationsError: null,
  getPeopleGanttAllocationsPending: false,
  getPeopleGanttAllocationsError: null,
  getGanttReportPeoplePending: false,
  getGanttReportPeopleError: null,
  getReportRoleSegmentsPending: false,
  getReportRoleSegmentsError: null,
  collectProjectGanttDataPending: false,
  collectProjectGanttDataError: null,
};

export default initialState;
