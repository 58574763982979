import { call, put, takeLatest } from 'redux-saga/effects';
import {
  COMMON_GRAPH_API_QUERY_BEGIN,
  COMMON_GRAPH_API_QUERY_SUCCESS,
  COMMON_GRAPH_API_QUERY_FAILURE,
  COMMON_GRAPH_API_QUERY_DISMISS_ERROR,
} from './constants';

export function graphApiQuery(client, query, variables, cb) {
  return {
    type: COMMON_GRAPH_API_QUERY_BEGIN,
    client,
    query,
    variables,
    cb,
  };
}

export function dismissGraphApiQueryError() {
  return {
    type: COMMON_GRAPH_API_QUERY_DISMISS_ERROR,
  };
}

export function* doGraphApiQuery(action) {
  const { client, query, variables, cb } = action;
  let res;
  try {
    res = yield call(client.query, { query, variables });

    if (cb) cb(res);
  } catch (err) {
    yield put({
      type: COMMON_GRAPH_API_QUERY_FAILURE,
      data: { error: err },
    });
    return;
  }
  yield put({
    type: COMMON_GRAPH_API_QUERY_SUCCESS,
    data: res,
  });
}

export function* watchGraphApiQuery() {
  yield takeLatest(COMMON_GRAPH_API_QUERY_BEGIN, doGraphApiQuery);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case COMMON_GRAPH_API_QUERY_BEGIN:
      return {
        ...state,
        graphApiQueryPending: true,
        graphApiQueryError: null,
      };

    case COMMON_GRAPH_API_QUERY_SUCCESS:
      return {
        ...state,
        graphApiQueryPending: false,
        graphApiQueryError: null,
      };

    case COMMON_GRAPH_API_QUERY_FAILURE:
      return {
        ...state,
        graphApiQueryPending: false,
        graphApiQueryError: action.data.error,
      };

    case COMMON_GRAPH_API_QUERY_DISMISS_ERROR:
      return {
        ...state,
        graphApiQueryError: null,
      };

    default:
      return state;
  }
}
