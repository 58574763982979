const initialState = {
  bridgitAdmins: [],
  invitations: [],
  inviteAdminPending: false,
  inviteAdminError: null,
  removeAdminPending: false,
  removeAdminError: null,
  removeAdminInvitationPending: false,
  removeAdminInvitationError: null,
  getAdminInvitationsPending: false,
  getAdminInvitationsError: null,
  getAdminsPending: false,
  getAdminsError: null,
  updateUserMembershipPending: false,
  updateUserMembershipError: null,
};

export default initialState;
