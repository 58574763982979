import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PROJECTS_DELETE_PROJECT_PHASE_BEGIN,
  PROJECTS_DELETE_PROJECT_PHASE_SUCCESS,
  PROJECTS_DELETE_PROJECT_PHASE_FAILURE,
  PROJECTS_DELETE_PROJECT_PHASE_DISMISS_ERROR,
} from './constants';
import { protectedJsonDelete } from '../../../utils/api';

export function deleteProjectPhase(accountId, projectId, phase, analyticsPayload = { }) {
  return {
    type: PROJECTS_DELETE_PROJECT_PHASE_BEGIN,
    accountId,
    projectId,
    phase,
    analyticsPayload,
  };
}

export function dismissDeleteProjectPhaseError() {
  return {
    type: PROJECTS_DELETE_PROJECT_PHASE_DISMISS_ERROR,
  };
}

export function* doDeleteProjectPhase(action) {
  const { accountId, projectId, phase, analyticsPayload } = action;
  let res;
  try {
    res = yield call(protectedJsonDelete, `accounts/${accountId}/projects/${projectId}/phases/${phase.id}`);
  } catch (err) {
    yield put({
      type: PROJECTS_DELETE_PROJECT_PHASE_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PROJECTS_DELETE_PROJECT_PHASE_SUCCESS,
    data: res,
    projectId,
    phase,
    analyticsPayload,
  });
}

export function* watchDeleteProjectPhase() {
  yield takeLatest(PROJECTS_DELETE_PROJECT_PHASE_BEGIN, doDeleteProjectPhase);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PROJECTS_DELETE_PROJECT_PHASE_BEGIN:
      return {
        ...state,
        deleteProjectPhasePending: true,
        deleteProjectPhaseError: null,
      };

    case PROJECTS_DELETE_PROJECT_PHASE_SUCCESS: {
      const { projectId, phase } = action;
      const { filteredProjects, projectSelections } = state;

      const newFilteredProjects = filteredProjects.map((project) => {
        const { id, phases, currentPhases } = project;

        if (id === projectId) {
          const newPhases = phases.filter(ph => ph.id !== phase.id);
          const newCurrentPhases = currentPhases.filter(currPhase => currPhase.name !== phase.name);

          return {
            ...project,
            phases: newPhases,
            currentPhases: newCurrentPhases,
          };
        }
        return project;
      });

      const newProjectSelections = { ...projectSelections };
      Object.keys(newProjectSelections).forEach((selectionId) => {
        const selectedProject = newProjectSelections[selectionId];
        if (selectedProject?.id === projectId) {
          const { phases, currentPhases } = selectedProject;

          const newPhases = phases?.filter(ph => ph.id !== phase.id) || [];

          const newCurrentPhases = currentPhases.filter(currPhase => currPhase.name !== phase.name);

          newProjectSelections[selectionId] = {
            ...selectedProject,
            phases: newPhases,
            currentPhases: newCurrentPhases,
          };
        }
      });

      return {
        ...state,
        filteredProjects: newFilteredProjects,
        deleteProjectPhasePending: false,
        deleteProjectPhaseError: null,
        projectSelections: newProjectSelections,
      };
    }

    case PROJECTS_DELETE_PROJECT_PHASE_FAILURE:
      return {
        ...state,
        deleteProjectPhasePending: false,
        deleteProjectPhaseError: action.data.error,
      };

    case PROJECTS_DELETE_PROJECT_PHASE_DISMISS_ERROR:
      return {
        ...state,
        deleteProjectPhaseError: null,
      };

    default:
      return state;
  }
}
