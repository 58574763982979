import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { MoreHoriz } from '@material-ui/icons';
import {
  Input,
  IconButton,
  Tooltip,
  Button,
  CircularProgress,
  Select,
} from '@material-ui/core';
import { trackProjectRoleUpdate } from 'src/common/analyticsHelper';
import { validateRoleUpdate, confirmRoleUpdate } from 'src/features/projects/utils/projectRolesUtils';
import { fitSegmentsToRange } from 'src/utils/dateSegmentUtils';
import { momentToString } from 'src/utils/dateUtils';
import deepEqual from 'react-fast-compare';
import {
  updateProjectRole,
  getProjectAllocations,
  deleteProjectRole,
} from './redux/actions';
import { EditControls, NumberInput } from '../wrapped-components';
import { CancelButton, DatePicker } from '../common';
import { RoleNoteField } from '.';
import { CustomRoleRequirementModal, ConfirmUpdateRole, ConfirmDeleteRole } from '../allocations';
import { DATE_INPUT_FORMAT } from '../../common/constants';
import {
  AUTOMATION_PROJECTS_GANTT_EDIT_ROLE_START_DATE,
  AUTOMATION_PROJECTS_GANTT_EDIT_ROLE_END_DATE,
  AUTOMATION_PROJECTS_GANTT_EDIT_ROLE_START_DATE_WRAPPER,
  AUTOMATION_PROJECTS_GANTT_EDIT_ROLE_END_DATE_WRAPPER,
} from './ids';

export const EditProjectRole = ({
  project,
  role,
  onPreventClickAway,
  onAllowClickAway,
  onRoleDeleted,
}) => {
  const dispatch = useDispatch();

  const { roles = [] } = useSelector(({ accountSettings }) => accountSettings);
  const { accountId } = useSelector(({ common }) => common);
  const {
    updateProjectRolePending,
    deleteProjectRolePending,
    projects = {},
  } = useSelector(({ projects }) => {
    const { updateProjectRolePending, deleteProjectRolePending } = projects;

    return {
      updateProjectRolePending,
      deleteProjectRolePending,
      projects,
    };
  });

  const [selectedRole, setSelectedRole] = useState(role);
  const [startDate, setStartDate] = useState(role.startDate);
  const [endDate, setEndDate] = useState(role.endDate);
  const [workingStartDate, setWorkingStartDate] = useState(role.startDate);
  const [workingEndDate, setWorkingEndDate] = useState(role.endDate);
  const [allocatedPercent, setAllocatedPercent] = useState(role.requirements.length ? role.requirements[0].allocatedPercent : 100);
  const [showModal, setShowModal] = useState(false);
  const [valid, setValid] = useState(false);
  const [requirements, setRequirements] = useState(role.requirements);
  const [editMode, setEditMode] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [saveImmediately, setSaveImmediately] = useState(false);
  const [note, setNote] = useState(role.note);

  const validate = useCallback(() => {
    const existingProject = { startDate: project.startDate, endDate: project.endDate };
    const updatedRole = { startDate, endDate, requirements };
    let valid = true;

    if (!selectedRole) valid = false;

    if (!validateRoleUpdate(existingProject, updatedRole)) valid = false;

    if (editMode === 'note' && role.note === note) {
      valid = false;
    }

    setValid(valid);
  }, [requirements, editMode, endDate, note, project.endDate, project.startDate, role.note, selectedRole, startDate]);

  useEffect(() => {
    validate();
  }, [startDate, endDate, requirements, selectedRole, validate]);

  const prevProjectRef = useRef();
  useEffect(() => {
    prevProjectRef.current = project;
  }, [project]);
  const prevProject = prevProjectRef.current;

  const prevRoleRef = useRef();
  useEffect(() => {
    prevRoleRef.current = role;
  }, [role]);
  const prevRole = prevRoleRef.current;

  useEffect(() => {
    if (!deepEqual(role, prevRole) || !deepEqual(project, prevProject)) {
      dispatch(getProjectAllocations(accountId, project.id));
    }
  }, [project, role, accountId, dispatch, prevRole, prevProject]);

  useEffect(() => {
    if (!updateProjectRolePending) {
      setShowConfirm(false);
    }
  }, [updateProjectRolePending]);

  useEffect(() => {
    if (!deleteProjectRolePending) {
      setShowDeleteConfirm(false);
    }
  }, [deleteProjectRolePending]);

  const clearNote = () => {
    setEditMode('note');
    setNote('');
  };

  const onNoteChange = (evt) => {
    setEditMode('note');
    setNote(evt.target.value);
  };

  const clearAllocations = () => {
    setEditMode('allocations');
    setRequirements([
      {
        startDate,
        endDate,
        allocatedPercent,
      },
    ]);
  };

  const cancelConfirm = () => {
    setShowConfirm(false);
    setShowDeleteConfirm(false);
  };

  const onChangeRole = (evt) => {
    setEditMode('roleType');
    setSelectedRole(roles.find(role => role.id === parseInt(evt.target.value, 10)));
  };

  const selectedRoleValue = () => {
    if (!selectedRole) return null;
    if ('roleId' in selectedRole) {
      return selectedRole.roleId;
    }
    const roleMatch = roles.find(r => r.name === selectedRole.name);

    if (roleMatch && 'id' in roleMatch) {
      return roleMatch.id;
    }
    return null;
  };

  const onSave = useCallback((expandAllocations = false) => {
    const formattedStartDate = momentToString(startDate);
    const formattedEndDate = momentToString(endDate);

    const data = {
      name: selectedRole.name,
      note: note === '' ? null : note,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      expandAllocations,
      allocations: fitSegmentsToRange(requirements, startDate, endDate),
    };

    const formattedRole = {
      ...role,
      allocations: data.allocations,
    };

    trackProjectRoleUpdate(formattedRole, data, project, 'Project Gantt');

    dispatch(updateProjectRole(accountId, project.id, role.id, data));

    setEditMode(null);
    setRequirements(data.allocations);
    setAllocatedPercent(data.allocations[0].allocatedPercent);
  }, [accountId, requirements, endDate, note, project, role, selectedRole.name, startDate, dispatch]);

  useEffect(() => {
    if (saveImmediately) {
      onSave();
      setSaveImmediately(false);
    }
  }, [onSave, saveImmediately]);

  const handleEditMode = mode => () => setEditMode(mode);

  const handleAllocatedPercentChange = (allocatedPercent) => {
    setEditMode('allocations');
    setAllocatedPercent(allocatedPercent);
    setRequirements(requirements.map(req => ({ ...req, allocatedPercent })));
  };

  const onShowModal = () => {
    setShowModal(true);
    onPreventClickAway();
  };

  const onHideModal = () => {
    setShowModal(false);
    onAllowClickAway();
  };

  const updateRoleAllocations = (rowId, requirements) => {
    setEditMode('allocations');
    setSaveImmediately(true);
    setRequirements(requirements);
    setAllocatedPercent(requirements[0].allocatedPercent);
    setShowModal(false);
    onAllowClickAway();
  };

  const updateWorkingStartDate = (date) => {
    setEditMode('startDate');
    setWorkingStartDate(date);
    if (date.isValid()) setStartDate(date);
  };

  const updateWorkingEndDate = (date) => {
    setEditMode('endDate');
    setWorkingEndDate(date);
    if (date.isValid()) setEndDate(date);
  };

  const clearEditMode = () => {
    setEditMode(null);
    setSelectedRole(role);
    setStartDate(role.startDate);
    setEndDate(role.endDate);
    setWorkingStartDate(role.startDate);
    setWorkingEndDate(role.endDate);
    setRequirements(role.requirements);
    setAllocatedPercent(role.requirements.length ? role.requirements[0].allocatedPercent : 100);
    setNote(role.note);
  };

  const clearStart = () => {
    setWorkingStartDate(null);
    setStartDate(null);
    setEditMode('startDate');
  };

  const clearEnd = () => {
    setWorkingEndDate(null);
    setEndDate(null);
    setEditMode('endDate');
  };

  const confirmUpdateRole = () => {
    const peopleAllocations = projects.projectAllocations[project.id].find(a => a.roleId === role.id);
    const editDateMode = editMode === 'startDate' || editMode === 'endDate';

    const newStartDate = moment(startDate);
    const newEndDate = moment(endDate);

    if (editDateMode) {
      const callbacks = {
        saveChange: onSave,
        cancelChange: () => setEditMode(null),
        requestConfirmation: () => setShowConfirm(true),
      };

      confirmRoleUpdate(newStartDate, newEndDate, role, peopleAllocations?.allocations, callbacks);
    } else {
      onSave();
    }
  };

  const onDeleteRole = () => {
    const analyticsPayload = {
      projectName: project.name,
      projectId: project.id,
      projectStatus: project.state,
      projectType: project.type,
      hasAllocatedPeople: role.allocations.length,
      deletedFrom: 'Project Gantt',
    };

    onRoleDeleted();
    dispatch(deleteProjectRole(accountId, project.id, role.id, analyticsPayload));
  };

  const onDeleteRoleConfirm = () => {
    if (role.allocations.length) {
      setShowDeleteConfirm(true);
    } else {
      onDeleteRole();
    }
  };

  const checkEnter = (evt) => {
    if (evt.keyCode === 13 && valid) {
      confirmUpdateRole();
      evt.target.blur();
    }
  };

  const renderEditControls = () => (
    <EditControls
      primaryAction={confirmUpdateRole}
      secondaryAction={clearEditMode}
      disabled={!valid}
      primaryText="Save"
      pending={updateProjectRolePending}
    />
  );

  return (
    <div className="projects-edit-project-role">
      <div className="add-role-row">
        <div className={classNames('add-role-column', { disabled: editMode && editMode !== 'roleType' })}>
          <p>Role</p>
          <div className="project-role-editor-wrapper" id="projects-gantt-edit-role-type">
            <Select
              value={selectedRoleValue()}
              onChange={onChangeRole}
              native
            >
              {roles.map(role => <option key={role.id} value={role.id}>{role.name}</option>)}
            </Select>
          </div>
          {editMode === 'roleType' && renderEditControls()}
        </div>
        <div className={classNames('add-role-column role-note', { disabled: editMode && editMode !== 'note' })} id="projects-gantt-edit-role-note">
          <p>Note:</p>
          <RoleNoteField
            className="role-note-field"
            onNoteChange={onNoteChange}
            note={note}
            clearNote={clearNote}
            onKeyUp={checkEnter}
          />
          {editMode === 'note' && renderEditControls()}
        </div>
        <div className={classNames('add-role-column', { disabled: editMode && editMode !== 'startDate' })}>
          <p>Start date</p>
          <div className="date-wrapper" id={AUTOMATION_PROJECTS_GANTT_EDIT_ROLE_START_DATE_WRAPPER}>
            <DatePicker
              minDate={moment(project.startDate, DATE_INPUT_FORMAT)}
              minDateMessage="Start date cannot be earlier than the project start date"
              maxDate={moment(endDate, DATE_INPUT_FORMAT)}
              maxDateMessage="The role start date must be earlier than the role end date"
              invalidDateMessage="Invalid Date Format"
              date={workingStartDate}
              onChange={updateWorkingStartDate}
              onFocus={handleEditMode('startDate')}
              variant="inline"
              id={AUTOMATION_PROJECTS_GANTT_EDIT_ROLE_START_DATE}
            />
            {startDate && <CancelButton className="date-cancel" onClick={clearStart} />}
          </div>
          {editMode === 'startDate' && renderEditControls()}
        </div>
        <div className={classNames('add-role-column', { disabled: editMode && editMode !== 'endDate' })}>
          <p>End date</p>
          <div className="date-wrapper" id={AUTOMATION_PROJECTS_GANTT_EDIT_ROLE_END_DATE_WRAPPER}>
            <DatePicker
              minDate={moment(startDate, DATE_INPUT_FORMAT)}
              minDateMessage="The role end date must be later than the role start date"
              maxDate={moment(project.endDate, DATE_INPUT_FORMAT)}
              maxDateMessage="End date cannot be later than the project end date"
              invalidDateMessage="Invalid Date Format"
              date={workingEndDate}
              onChange={updateWorkingEndDate}
              onFocus={handleEditMode('endDate')}
              variant="inline"
              id={AUTOMATION_PROJECTS_GANTT_EDIT_ROLE_END_DATE}
            />
            {endDate && <CancelButton className="date-cancel" onClick={clearEnd} />}
          </div>
          {editMode === 'endDate' && renderEditControls()}
        </div>
        <div className={classNames('add-role-column', { disabled: editMode && editMode !== 'allocations' })}>
          <p>Allocation %</p>
          <div className="allocation-wrap" id="projects-gantt-edit-allocation">
            { requirements.length > 1 && (
              <div className="custom-wrap">
                <Input
                  className="allocation-picker"
                  type="text"
                  value="Custom"
                  disabled
                />
                <CancelButton
                  className="clear"
                  onClick={clearAllocations}
                />
              </div>
            )}
            { requirements.length === 1 && (
              <NumberInput
                className="allocation-picker"
                value={allocatedPercent}
                onValueChanged={handleAllocatedPercentChange}
              />
            )}
            <IconButton onClick={onShowModal}>
              <Tooltip title="Customize" placement="top">
                <MoreHoriz />
              </Tooltip>
            </IconButton>
          </div>
          {editMode === 'allocations' && renderEditControls()}
        </div>
      </div>
      <div className="delete-role">
        {deleteProjectRolePending
          ? <CircularProgress size={35} color="primary" />
          : (
            <Button
              variant="outlined"
              size="medium"
              color="default"
              onClick={onDeleteRoleConfirm}
            >
              Remove Role
            </Button>
          )}
      </div>

      {showModal && (
        <CustomRoleRequirementModal
          selectedProject={project}
          onHideModal={onHideModal}
          role={{ ...selectedRole, startDate, endDate, requirements }}
          onSave={updateRoleAllocations}
        />
      )}

      {showConfirm && (
        <ConfirmUpdateRole
          cancelConfirm={cancelConfirm}
          onSave={onSave}
          editMode={editMode}
          project={project}
          startDate={startDate}
          endDate={endDate}
          role={role}
          displayVertical
        />
      )}

      {showDeleteConfirm && (
        <ConfirmDeleteRole
          role={role}
          onDelete={onDeleteRole}
          onCancel={cancelConfirm}
        />
      )}
    </div>
  );
};

EditProjectRole.propTypes = {
  project: PropTypes.object.isRequired,
  role: PropTypes.object.isRequired,
  onPreventClickAway: PropTypes.func,
  onAllowClickAway: PropTypes.func,
  onRoleDeleted: PropTypes.func,
};

EditProjectRole.defaultProps = {
  onPreventClickAway: () => {},
  onAllowClickAway: () => {},
  onRoleDeleted: () => {},
};

export default EditProjectRole;
