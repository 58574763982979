import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Popper, ClickAwayListener } from '@material-ui/core';
import { utils } from '@bridgit/foundation';
import { affectedPhases } from '../phases/utils/phaseUtils';
import { PhaseBars, PhasePopper } from '../phases';
import { TodayIndicator } from '../common';

const { convertHexToRGB } = utils;

export default class FullTimeline extends PureComponent {
  static propTypes = {
    selectedProject: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      highlightedPhases: [],
      containerRef: React.createRef(),
    };
  }

  onClickAway = () => {
    this.setState({ highlightedPhases: [] });
  }

  onMouseMove = (evt) => {
    const phaseElements = [...evt.target.parentElement.children].filter(elem => elem.type === 'button');
    const mouseLocation = evt.pageX;
    const highlightedPhases = affectedPhases(phaseElements, mouseLocation);
    this.setState({ highlightedPhases });
  }

  onMouseLeave = () => {
    this.setState({
      highlightedPhases: [],
    });
  }

  render() {
    const { selectedProject } = this.props;
    const { highlightedPhases, containerRef } = this.state;
    const { startDate, endDate } = selectedProject;

    return (
      <div className="projects-full-timeline">
        <hr />
        <div
          className="projects-phase-bar"
          style={{ backgroundColor: convertHexToRGB(selectedProject.colour, selectedProject.phases.length ? 0.2 : 1) }}
          ref={containerRef}
        >
          <PhaseBars
            selectedProject={selectedProject}
            highlightedPhases={highlightedPhases}
            onMouseMove={this.onMouseMove}
            onMouseLeave={this.onMouseLeave}
          />
          <TodayIndicator
            startDate={startDate}
            endDate={endDate}
          />
        </div>
        <Popper
          className="timeline-phase-info-popper"
          open={highlightedPhases.length > 0}
          anchorEl={highlightedPhases.length === 1 ? highlightedPhases[0].elem : containerRef.current}
          placement="top"
        >
          <ClickAwayListener onClickAway={this.onClickAway}>
            <>
              <PhasePopper
                phases={selectedProject.phases}
                highlightedPhases={highlightedPhases}
              />
            </>
          </ClickAwayListener>
        </Popper>
      </div>
    );
  }
}
