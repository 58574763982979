import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PROJECTS_GET_PROJECT_AUDIT_BEGIN,
  PROJECTS_GET_PROJECT_AUDIT_SUCCESS,
  PROJECTS_GET_PROJECT_AUDIT_FAILURE,
  PROJECTS_GET_PROJECT_AUDIT_DISMISS_ERROR,
} from './constants';
import { getProjectAuditQuery } from '../queries/getProjectAuditQuery';
import graphApiClient from '../../../common/GraphAPIClient';

export function getProjectAudit(accountId, projectId, from) {
  const { query, variables } = getProjectAuditQuery(accountId, projectId, from);
  return {
    type: PROJECTS_GET_PROJECT_AUDIT_BEGIN,
    query,
    variables,
  };
}

export function dismissGetProjectAuditError() {
  return {
    type: PROJECTS_GET_PROJECT_AUDIT_DISMISS_ERROR,
  };
}

export function* doGetProjectAudit(action) {
  const { query, variables } = action;
  let res;
  try {
    res = yield call(graphApiClient.query, { query, variables });
  } catch (err) {
    yield put({
      type: PROJECTS_GET_PROJECT_AUDIT_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PROJECTS_GET_PROJECT_AUDIT_SUCCESS,
    data: res.data,
    clearData: variables.from === 0,
  });
}

export function* watchGetProjectAudit() {
  yield takeLatest(PROJECTS_GET_PROJECT_AUDIT_BEGIN, doGetProjectAudit);
}

export function reducer(state, action) {
  switch (action.type) {
    case PROJECTS_GET_PROJECT_AUDIT_BEGIN:
      return {
        ...state,
        getProjectAuditPending: true,
        getProjectAuditError: null,
      };

    case PROJECTS_GET_PROJECT_AUDIT_SUCCESS: {
      const { clearData, data } = action;
      const { projectAudit } = data;

      const oldItems = clearData || !state.projectAudit?.items ? [] : state.projectAudit.items;
      const newItems = projectAudit.items;

      return {
        ...state,
        projectAudit: {
          ...projectAudit,
          items: [...oldItems, ...newItems],
        },
        getProjectAuditPending: false,
        getProjectAuditError: null,
      };
    }

    case PROJECTS_GET_PROJECT_AUDIT_FAILURE:
      return {
        ...state,
        getProjectAuditPending: false,
        getProjectAuditError: action.data.error,
      };

    case PROJECTS_GET_PROJECT_AUDIT_DISMISS_ERROR:
      return {
        ...state,
        getProjectAuditError: null,
      };

    default:
      return state;
  }
}
