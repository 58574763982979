import { call, put, takeLatest } from 'redux-saga/effects';
import {
  SERVICE_ACCOUNTS_RESET_SERVICE_ACCOUNT_PASSWORD_BEGIN,
  SERVICE_ACCOUNTS_RESET_SERVICE_ACCOUNT_PASSWORD_SUCCESS,
  SERVICE_ACCOUNTS_RESET_SERVICE_ACCOUNT_PASSWORD_FAILURE,
  SERVICE_ACCOUNTS_RESET_SERVICE_ACCOUNT_PASSWORD_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';

export function resetServiceAccountPassword(accountId, serviceAccountId, analyticsPayload) {
  return {
    type: SERVICE_ACCOUNTS_RESET_SERVICE_ACCOUNT_PASSWORD_BEGIN,
    accountId,
    serviceAccountId,
    analyticsPayload,
  };
}

export function dismissResetServiceAccountPasswordError() {
  return {
    type: SERVICE_ACCOUNTS_RESET_SERVICE_ACCOUNT_PASSWORD_DISMISS_ERROR,
  };
}

export function* doResetServiceAccountPassword(action) {
  const { accountId, serviceAccountId, analyticsPayload } = action;
  let res;
  try {
    res = yield call(
      protectedJsonPost, `accounts/${accountId}/service-accounts/${serviceAccountId}/reset-password`,
    );
  } catch (err) {
    yield put({
      type: SERVICE_ACCOUNTS_RESET_SERVICE_ACCOUNT_PASSWORD_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: SERVICE_ACCOUNTS_RESET_SERVICE_ACCOUNT_PASSWORD_SUCCESS,
    data: res,
    analyticsPayload,
  });
}

export function* watchResetServiceAccountPassword() {
  yield takeLatest(SERVICE_ACCOUNTS_RESET_SERVICE_ACCOUNT_PASSWORD_BEGIN, doResetServiceAccountPassword);
}

export function reducer(state, action) {
  switch (action.type) {
    case SERVICE_ACCOUNTS_RESET_SERVICE_ACCOUNT_PASSWORD_BEGIN:
      return {
        ...state,
        resetServiceAccountPasswordPending: true,
        resetServiceAccountPasswordError: null,
      };

    case SERVICE_ACCOUNTS_RESET_SERVICE_ACCOUNT_PASSWORD_SUCCESS: {
      const { data: { password } } = action;

      return {
        ...state,
        account: { ...state.account, password },
        resetServiceAccountPasswordPending: false,
        resetServiceAccountPasswordError: null,
      };
    }

    case SERVICE_ACCOUNTS_RESET_SERVICE_ACCOUNT_PASSWORD_FAILURE:
      return {
        ...state,
        resetServiceAccountPasswordPending: false,
        resetServiceAccountPasswordError: action.data.error,
      };

    case SERVICE_ACCOUNTS_RESET_SERVICE_ACCOUNT_PASSWORD_DISMISS_ERROR:
      return {
        ...state,
        resetServiceAccountPasswordError: null,
      };

    default:
      return state;
  }
}
