import { getTableConfigFromSchema } from 'src/features/table/filterUtils';
import {
  ACCOUNT_MODULE_PURSUIT_TRACKING,
  ACCOUNT_MODULE_CERTIFICATIONS,
  ACCOUNT_MODULE_FINANCIAL_TRACKING,
} from 'src/common/constants';
import {
  FILTER_ISSUES,
  FILTER_AVAILABILITIES,
  FILTER_ALLOCATION,
  FILTER_TITLE,
  FILTER_CERTIFICATIONS,
  FILTER_EMPLOYMENT_DATE_END,
  FILTER_EMPLOYMENT_DATE_START,
  NEXT_AVAILABILITY,
  NEXT_ALLOCATIONS,
  NEXT_ALLOCATIONS_START_DATE,
  CERTIFICATIONS,
  FILTER_COST_RATE,
  HOURLY_COST_RATE,
} from '../../filters/constants';
import {
  PEOPLE_DISPLAY_NEXT_PROJECT,
  PEOPLE_DISPLAY_NEXT_PROJECT_START,
  PEOPLE_DISPLAY_CURRENT_PROJECT_END,
  PEOPLE_DISPLAY_PURSUIT_PROJECTS,
  PEOPLE_DISPLAY_AVAILABILITY_UNTIL,
  PEOPLE_DISPLAY_CURRENT_PROJECT,
  PEOPLE_DISPLAY_NAME,
  PEOPLE_DISPLAY_TITLE,
  PEOPLE_DISPLAY_EMAIL,
  PEOPLE_DISPLAY_ISSUES,
  PEOPLE_DISPLAY_PHOTO,
  PEOPLE_DISPLAY_PERSON_ID,
  PEOPLE_DISPLAY_HIRE_DATE,
  PEOPLE_DISPLAY_TERMINATION_DATE,
  PEOPLE_DISPLAY_ALLOCATED_PEOPLE,
  PEOPLE_DISPLAY_ACCOUNT_ID,
  PEOPLE_DISPLAY_CERTIFICATIONS,
  PEOPLE_DISPLAY_COST_RATE,
} from '../../features/people/constants';
import { PERM_READ, PERM_FINANCIALS } from '../../features/permissions/utils/constants';

const personFields = [
  {
    schemaName: 'id',
    displayName: PEOPLE_DISPLAY_PERSON_ID,
    visible: false,
  },
  {
    schemaName: 'accountId',
    displayName: PEOPLE_DISPLAY_ACCOUNT_ID,
    visible: false,
  },
  {
    schemaName: 'photoUrl',
    displayName: PEOPLE_DISPLAY_PHOTO,
    alwaysVisible: true,
    sort: false,
  },
  {
    schemaName: 'name',
    displayName: PEOPLE_DISPLAY_NAME,
    alwaysVisible: true,
    sortGantt: true,
  },
  {
    schemaName: 'title',
    displayName: PEOPLE_DISPLAY_TITLE,
    defaultVisible: true,
    filter: true,
    filterType: FILTER_TITLE,
    sortGantt: true,
  },
  // Changes to filterType must be matched in rp-graphapi.
  // "FILTER_AVAILABILITIES" is one of the non-field filters referenced in the People Gantt share feature.
  {
    schemaName: NEXT_AVAILABILITY,
    displayName: PEOPLE_DISPLAY_AVAILABILITY_UNTIL,
    defaultVisible: true,
    filter: true,
    filterType: FILTER_AVAILABILITIES,
    sortGantt: true,
  },
  // Changes to filterType must be matched in rp-graphapi.
  // "FILTER_ALLOCATION" is one of the non-field filters referenced in the People Gantt share feature.
  {
    schemaName: 'currentProjects',
    displayName: PEOPLE_DISPLAY_CURRENT_PROJECT,
    defaultVisible: true,
    filter: true,
    filterType: FILTER_ALLOCATION,
    hideColumnFilter: true,
  },
  {
    schemaName: 'pursuitProjects',
    displayName: PEOPLE_DISPLAY_PURSUIT_PROJECTS,
    filter: false,
    module: ACCOUNT_MODULE_PURSUIT_TRACKING,
  },
  {
    schemaName: 'currentAllocations',
    displayName: PEOPLE_DISPLAY_CURRENT_PROJECT_END,
    defaultVisible: false,
    filter: false,
  },
  {
    schemaName: NEXT_ALLOCATIONS,
    displayName: PEOPLE_DISPLAY_NEXT_PROJECT,
    defaultVisible: true,
    filter: false,
  },
  {
    schemaName: NEXT_ALLOCATIONS_START_DATE,
    displayName: PEOPLE_DISPLAY_NEXT_PROJECT_START,
    defaultVisible: false,
    filter: false,
  },
  {
    // Changes to filterType must be matched in rp-graphapi.
    // "FILTER_ISSUES" is one of the non-field filters referenced in the People Gantt share feature.
    schemaName: 'hasConflict',
    displayName: PEOPLE_DISPLAY_ISSUES,
    defaultVisible: true,
    filter: true,
    filterType: FILTER_ISSUES,
    filterOptions: [
      { name: 'Has allocation issue', value: true },
      { name: 'Does not have allocation issue', value: false },
    ],
  },
  {
    schemaName: 'email',
    displayName: PEOPLE_DISPLAY_EMAIL,
    defaultVisible: false,
  },
  {
    schemaName: 'employmentDates__startDate',
    displayName: PEOPLE_DISPLAY_HIRE_DATE,
    defaultVisible: false,
    type: 'Date',
    filter: true,
    filterType: FILTER_EMPLOYMENT_DATE_START,
  },
  {
    schemaName: 'employmentDates__endDate',
    displayName: PEOPLE_DISPLAY_TERMINATION_DATE,
    defaultVisible: false,
    type: 'Date',
    filter: true,
    filterType: FILTER_EMPLOYMENT_DATE_END,
  },
  {
    schemaName: 'allocatedPeople',
    displayName: PEOPLE_DISPLAY_ALLOCATED_PEOPLE,
    visible: false,
  },
  {
    // Changes to filterType must be matched in rp-graphapi.
    // "FILTER_CERTIFICATIONS" is one of the non-field filters referenced in the People Gantt share feature.
    schemaName: CERTIFICATIONS,
    displayName: PEOPLE_DISPLAY_CERTIFICATIONS,
    filter: true,
    filterType: FILTER_CERTIFICATIONS,
    module: ACCOUNT_MODULE_CERTIFICATIONS,
  },
  {
    schemaName: HOURLY_COST_RATE,
    displayName: PEOPLE_DISPLAY_COST_RATE,
    type: 'Currency',
    filter: true,
    filterType: FILTER_COST_RATE,
    module: ACCOUNT_MODULE_FINANCIAL_TRACKING,
    isFinancials: true,
    action: PERM_READ,
    subject: PERM_FINANCIALS,
  },
];

const fieldDefaults = {
  displayName: '',
  visible: true,
  defaultVisible: false,
  alwaysVisible: false,
  filter: false,
  sort: true,
  sortGantt: false,
  controlOptions: [],
  isCustomSort: false,
  hideColumnFilter: false,
};

export const PersonDescriptor = personFields.map(field => ({
  ...fieldDefaults,
  ...field,
}));

export const PEOPLE_SCHEMAS = personFields.map(field => field.schemaName);
export const PersonPropDisplayNames = PersonDescriptor.reduce((displayNames, field) => {
  if (field.displayName.length) displayNames.push(field.displayName);
  return displayNames;
}, []);

export const PersonGanttFilters = PersonDescriptor.reduce((allowedFilters, field) => {
  if (field.sortGantt) {
    allowedFilters.push({
      label: field.displayName,
      column: field.schemaName,
    });
  }
  return allowedFilters;
}, []);

export const PeopleTableConfig = getTableConfigFromSchema(PersonDescriptor);
