import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Divider, Tooltip } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { IconButton, NavigateBack } from '@bridgit/foundation';

export const RoleTemplate = ({
  roleTemplate,
  onBackClick,
  onDeleteClick,
  onEditClick,
}) => {
  const { name, data } = roleTemplate;
  const renderedRoles = useMemo(() => data.map(({ percent, quantity, roleName }, index) => (
    /* eslint-disable react/no-array-index-key */
    <div key={index} className="table-row">
      <p className="quantity-column">{quantity}</p>
      <p className="role-column">{roleName}</p>
      <p className="allocation-column">{percent}</p>
    </div>
  )), [data]);

  const onDelete = useCallback(() => onDeleteClick(roleTemplate), [roleTemplate, onDeleteClick]);

  return (
    <div className="account-settings-role-template">
      <div className="toolbar">
        <NavigateBack onClick={onBackClick} label="Back to role templates list" />
        <div className="right-buttons">
          <Tooltip
            title="Edit"
            placement="bottom"
          >
            <IconButton size="large" variant="plain" onClick={onEditClick} ariaLabel="Edit template">
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Delete"
            placement="bottom"
          >
            <IconButton size="large" variant="plain" onClick={onDelete} ariaLabel="Delete template">
              <Delete />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div>
        <p className="template-header">{name}</p>
        <Divider />
      </div>
      <div className="template-body">
        <div className="table-header">
          <p className="quantity-column">Quantity</p>
          <p className="role-column">Role</p>
          <p className="allocation-column">Allocation %</p>
        </div>
        <div>
          {renderedRoles}
        </div>
      </div>
    </div>
  );
};

RoleTemplate.propTypes = {
  roleTemplate: PropTypes.shape({
    name: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({
      quantity: PropTypes.number,
      roleName: PropTypes.string,
      percent: PropTypes.number,
    })),
  }).isRequired,
  onBackClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
};

export default RoleTemplate;
