const initialState = {
  userInfo: {},
  userInvitationData: {
    email: '',
    group: '',
  },
  userLoginPending: false,
  userLoginError: null,
  acceptInvitationPending: false,
  acceptInvitationError: null,
  getLoginInvitationPending: false,
  getLoginInvitationError: null,
  getUserInfoPending: false,
  getUserInfoError: null,
  postLogoutPending: false,
  token: null,
  acceptInvitationOauthPending: false,
  acceptInvitationOauthError: null,
  updateUserInfoPending: false,
  updateUserInfoError: null,
  updatePasswordPending: false,
  updatePasswordError: null,
  requestForgotPasswordPending: false,
  requestForgotPasswordError: null,
  resetPasswordPending: false,
  resetPasswordError: null,
  getDefaultTeamsPending: false,
  getDefaultTeamsError: null,
  defaultTeams: [],
};

export default initialState;
