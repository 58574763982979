export const fromApiSavedFilter = ({ name, id, type, predicates }) => ({
  name: 'filter',
  filterId: id,
  filterName: name,
  filterType: type?.toLowerCase(),
  args: predicates ? JSON.parse(predicates) : undefined,
});

export const toAPISavedFilter = (name, type, filter) => ({
  name,
  type,
  predicates: JSON.stringify(filter),
});
