const SEGMENT_TYPE_PROJECT = 'ProjectById';
const SEGMENT_TYPE_PHASE = 'Phase';
const SEGMENT_TYPE_ROLE = 'ProjectRoleById';
const SEGMENT_TYPE_PRIMARY_PHASE = 'PrimaryPhase';
const EDITABLE_SEGMENT_TYPES = [SEGMENT_TYPE_PRIMARY_PHASE, SEGMENT_TYPE_PROJECT, SEGMENT_TYPE_PHASE, SEGMENT_TYPE_ROLE];

const PHASE_WILL_BE_DELETED = 'This phase will be deleted on save';
const ROLE_WILL_BE_DELETED = 'This role will be deleted on save';
const ITEM_WILL_BE_DELETED = 'This item will be deleted on save';

export {
  SEGMENT_TYPE_PROJECT,
  SEGMENT_TYPE_PHASE,
  SEGMENT_TYPE_ROLE,
  SEGMENT_TYPE_PRIMARY_PHASE,
  EDITABLE_SEGMENT_TYPES,
  PHASE_WILL_BE_DELETED,
  ROLE_WILL_BE_DELETED,
  ITEM_WILL_BE_DELETED,
};
