import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PEOPLE_UPDATE_PERSON_NOTE_BEGIN,
  PEOPLE_UPDATE_PERSON_NOTE_SUCCESS,
  PEOPLE_UPDATE_PERSON_NOTE_FAILURE,
  PEOPLE_UPDATE_PERSON_NOTE_DISMISS_ERROR,
} from './constants';
import { protectedJsonPatch } from '../../../utils/api';
import { updatePersonNoteById } from '../utils/peopleUtils';

export function updatePersonNote(accountId, personId, noteId, analyticsPayload, data) {
  return {
    type: PEOPLE_UPDATE_PERSON_NOTE_BEGIN,
    accountId,
    personId,
    noteId,
    analyticsPayload,
    data,
  };
}

export function dismissUpdatePersonNoteError() {
  return {
    type: PEOPLE_UPDATE_PERSON_NOTE_DISMISS_ERROR,
  };
}

export function* doUpdatePersonNote(action) {
  const { accountId, personId, noteId, analyticsPayload, data } = action;
  let res;
  try {
    res = yield call(protectedJsonPatch, `accounts/${accountId}/persons/${personId}/notes/${noteId}`, data);
  } catch (err) {
    yield put({
      type: PEOPLE_UPDATE_PERSON_NOTE_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PEOPLE_UPDATE_PERSON_NOTE_SUCCESS,
    accountId,
    personId,
    noteId,
    analyticsPayload,
    data: res,
  });
}

export function* watchUpdatePersonNote() {
  yield takeLatest(PEOPLE_UPDATE_PERSON_NOTE_BEGIN, doUpdatePersonNote);
}

export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_UPDATE_PERSON_NOTE_BEGIN:
      return {
        ...state,
        updatePersonNotePending: true,
        updatePersonNoteError: null,
      };

    case PEOPLE_UPDATE_PERSON_NOTE_SUCCESS: {
      const { personId, noteId, data } = action;
      const { personSelections } = state;

      const newPersonSelections = { ...personSelections };
      Object.keys(personSelections).forEach((selectionId) => {
        const selectedPerson = personSelections[selectionId];
        if (selectedPerson && selectedPerson.id === personId) {
          const updatedPerson = updatePersonNoteById(selectedPerson, noteId, data);
          newPersonSelections[selectionId] = {
            ...updatedPerson,
          };
        }
      });

      return {
        ...state,
        updatePersonNotePending: false,
        updatePersonNoteError: null,
        personSelections: newPersonSelections,
      };
    }

    case PEOPLE_UPDATE_PERSON_NOTE_FAILURE:
      return {
        ...state,
        updatePersonNotePending: false,
        updatePersonNoteError: action.data.error,
      };

    case PEOPLE_UPDATE_PERSON_NOTE_DISMISS_ERROR:
      return {
        ...state,
        updatePersonNoteError: null,
      };

    default:
      return state;
  }
}
