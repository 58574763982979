import React, { useCallback } from 'react';
import { FILTER_NAME } from 'src/filters/constants';
import { ROLE_AVAILABILITIES_QUERY_ID } from 'src/features/queries/redux/constants';
import { SearchBox } from 'src/features/filters';

export default function CandidateSearch() {
  const onSearch = useCallback(() => {
    // Mixpanel - Track person search
    window.mixpanel.track('Place A Person - Search Used');
  }, []);

  return (
    <div className="allocations-candidate-search">
      <SearchBox
        placeholder="Start typing to search a person by name"
        type={FILTER_NAME}
        queryId={ROLE_AVAILABILITIES_QUERY_ID}
        onSearch={onSearch}
      />
    </div>
  );
}

CandidateSearch.propTypes = {};
