const IDLE_TIME = 'Idle Time';
const TRUE_UTILIZATION_RATE = 'True Utilization Rate';
const UTILIZATION = 'Utilization';

const IDLE_TIME_VALUE = 0;

const IDLE_TIME_CONTROLS = [
  {
    name: 0,
    label: IDLE_TIME,
    secondaryLabel: IDLE_TIME,
  },
  {
    name: 1,
    label: UTILIZATION,
    secondaryLabel: 'Utilization Rate (True)',
  },
];

const IDLE_TIME_TOOLTIPS = {
  0: ' measures the average availability for all people within a given time period.',
  1: ' measures the average allocation for all people, where the allocation rate for each person is capped at 100%.',
};

const ENSURE_FILLED_ROLES = 'Ensure all people have roles to fill.';

const WEEK = 'Week';
const MONTH = 'Month';

const IDLE_TIME_BY_TITLE_HEADER = 'Idle Time By Title ';
const IDLE_TIME_BY_TITLE_TOOLTIP = 'measures the average availability for all people within a given job title, across a given period of time.';
const IDLE_TIME_BY_TITLE_EMPTY_FIRST = 'Get an overview of job titles that currently have the most idle time.';
const IDLE_TIME_BY_TITLE_EMPTY_LINK = 'Add some projects';
const IDLE_TIME_BY_TITLE_EMPTY_SECOND = ' with roles assigned to them to get started.';

const TITLE_FIELD_NAME = 'Title';
const AVAILABILITY_SORT_COLUMN = 'nextAvailability';
const AVAILABILITY_SORT_LABEL = 'Availability Until';

const NEGATIVE = 'Negative';
const POSITIVE = 'Positive';

const UTILIZATION_STATE = {
  GOOD: 'Good',
  NEUTRAL: 'Neutral',
  BAD: 'Bad',
};

const INSIGHT_SENTIMENT = {
  POSITIVE: 'Positive',
  IMPROVING: 'Improving',
  NEUTRAL: 'Neutral',
  WORSENING: 'Worsening',
  NEGATIVE: 'Negative',
};

const IDLE_TIME_SENTIMENT_MATRIX = {
  [UTILIZATION_STATE.GOOD]: {
    [UTILIZATION_STATE.GOOD]: INSIGHT_SENTIMENT.POSITIVE,
    [UTILIZATION_STATE.NEUTRAL]: INSIGHT_SENTIMENT.POSITIVE,
    [UTILIZATION_STATE.BAD]: INSIGHT_SENTIMENT.WORSENING,
  },
  [UTILIZATION_STATE.NEUTRAL]: {
    [UTILIZATION_STATE.GOOD]: INSIGHT_SENTIMENT.IMPROVING,
    [UTILIZATION_STATE.NEUTRAL]: INSIGHT_SENTIMENT.NEUTRAL,
    [UTILIZATION_STATE.BAD]: INSIGHT_SENTIMENT.WORSENING,
  },
  [UTILIZATION_STATE.BAD]: {
    [UTILIZATION_STATE.GOOD]: INSIGHT_SENTIMENT.IMPROVING,
    [UTILIZATION_STATE.NEUTRAL]: INSIGHT_SENTIMENT.NEGATIVE,
    [UTILIZATION_STATE.BAD]: INSIGHT_SENTIMENT.NEGATIVE,
  },
};

const IDLE_TIME_MID_STATEMENT = 'People with these titles have the most idle time. Increase efficiency by finding them roles to fill.';

const IDLE_TIME_STATEMENTS = {
  [INSIGHT_SENTIMENT.POSITIVE]: 'Idle time is well controlled—great work!',
  [INSIGHT_SENTIMENT.IMPROVING]: IDLE_TIME_MID_STATEMENT,
  [INSIGHT_SENTIMENT.NEUTRAL]: IDLE_TIME_MID_STATEMENT,
  [INSIGHT_SENTIMENT.WORSENING]: IDLE_TIME_MID_STATEMENT,
  [INSIGHT_SENTIMENT.NEGATIVE]: 'Idle time is high! Allocate people with these titles to increase workforce efficiency.',
};

const IDLE_TIME_GRAPH_Y_TICKS = [0, 50, 100];

export {
  IDLE_TIME_CONTROLS,
  IDLE_TIME_TOOLTIPS,
  IDLE_TIME,
  TRUE_UTILIZATION_RATE,
  WEEK,
  MONTH,
  ENSURE_FILLED_ROLES,
  UTILIZATION,
  IDLE_TIME_BY_TITLE_HEADER,
  IDLE_TIME_BY_TITLE_TOOLTIP,
  IDLE_TIME_BY_TITLE_EMPTY_FIRST,
  IDLE_TIME_BY_TITLE_EMPTY_LINK,
  IDLE_TIME_BY_TITLE_EMPTY_SECOND,
  TITLE_FIELD_NAME,
  AVAILABILITY_SORT_COLUMN,
  AVAILABILITY_SORT_LABEL,
  IDLE_TIME_STATEMENTS,
  INSIGHT_SENTIMENT,
  UTILIZATION_STATE,
  NEGATIVE,
  POSITIVE,
  IDLE_TIME_GRAPH_Y_TICKS,
  IDLE_TIME_SENTIMENT_MATRIX,
  IDLE_TIME_VALUE,
};
