import React from 'react';
import classnames from 'classnames';
import pluralize from 'pluralize';
import { UNAVAILABILITY } from 'src/common/constants';
import { getFormattedDate } from '../../../utils/dateUtils';
import {
  PERSON_ACTIVITY_ENTRY_EXPANDED,
  PERSON_CREATED,
  PERSON_DEACTIVATED,
  PERSON_FIELD_CLEARED,
  PERSON_FIELD_SET,
  PERSON_LOG_RESOURCE_TYPES,
  PERSON_NOTE_ADDED,
  PERSON_NOTE_DELETED,
  PERSON_NOTE_UPDATED,
  PERSON_REACTIVATED,
  PERSON_UPDATED,
  ATTACHMENT_ADDED,
  ATTACHMENT_REMOVED,
  TIME_OFF_ADDED,
  TIME_OFF_DELETED,
  TIME_OFF_UPDATED,
  PROFILE_DETAILS_MODAL_CONTEXT,
  PROFILE_DETAILS_PROFILE_CONTEXT,
  CERTIFICATION_ADDED,
  CERTIFICATION_UPDATED,
  CERTIFICATION_DELETED,
  CERTIFICATION_ATTACHMENT_LINKED,
  CERTIFICATION_ATTACHMENT_UNLINKED,
} from '../constants';
import { capitalize, getFormattedFieldValue } from '../../../utils/formatters';
import { splitByUpperCase } from '../../../utils/miscUtils';

export const trackPersonAuditItemExpanded = (isExpanded, eventData, selectedPerson, isOpenInModal) => {
  if (!isExpanded) {
    const { id, name } = selectedPerson;
    const viewedFrom = isOpenInModal ? PROFILE_DETAILS_MODAL_CONTEXT : PROFILE_DETAILS_PROFILE_CONTEXT;
    const { userName, actionType, eventOn } = eventData;

    window.mixpanel.track(PERSON_ACTIVITY_ENTRY_EXPANDED, {
      'Person name': name,
      'Person id': id,
      'Viewed from': viewedFrom,
      'Expanded entry type': actionType,
      'Expanded entry date': getFormattedDate(eventOn),
      'Expanded entry user': userName,
    });
  }
};

export const getPersonLogEventName = (resourceType, actionType) => PERSON_LOG_RESOURCE_TYPES[resourceType]?.[actionType] || splitByUpperCase(actionType);

const getFieldValues = fields => fields.map(({ name, value, isNestedField, isNestedValue }) => (
  <div key={name} className="people-person-audit-details-row">
    {isNestedField ? (
      <span className="people-person-audit-details-nested">{`○ ${name}: `}</span>
    ) : (
      <span className="people-person-audit-details-label">{`${name}: `}</span>
    )}

    {isNestedValue ? (
      <div className="people-person-audit-details-nested">
        <div className="people-person-audit-details-row">{value ?? ''}</div>
      </div>
    ) : (
      <span>{value ?? ''}</span>
    )}
  </div>
));

const getPersonFields = (name, email, title, shouldGetAllFields, photoUrl, state, hasConflict, lastModifiedOn, employmentDates) => [
  { name: 'Name', value: name },
  { name: 'Email', value: email },
  { name: 'Title', value: title },
  ...(shouldGetAllFields ? [
    { name: 'Photo URL', value: photoUrl ? 'Set' : 'Null' },
    { name: 'Status', value: state },
    { name: 'Has conflict', value: capitalize(`${hasConflict}`) },
    { name: 'Last Modified', value: getFormattedDate(lastModifiedOn) },
    { name: 'Employment Dates', value: employmentDates },
  ] : []),
];

const getUnavailabilityFields = (startDate, endDate, isPrivate, description, rangeType) => [
  { name: 'Start Date', value: getFormattedDate(startDate) },
  { name: 'End Date', value: getFormattedDate(endDate) },
  { name: 'Private', value: capitalize(`${isPrivate}`) },
  { name: 'Description', value: description },
  { name: 'Requires backfill', value: rangeType === UNAVAILABILITY ? 'True' : 'False' },
];

const getPersonNoteFields = (message, isPrivate, creatorName, createdOn, lastModifiedOn) => [
  { name: 'Description', value: message },
  { name: 'Private', value: capitalize(`${isPrivate}`) },
  { name: 'Creator Name', value: creatorName },
  { name: 'Created on', value: getFormattedDate(createdOn) },
  ...(lastModifiedOn ? [{ name: 'Last Modified', value: getFormattedDate(lastModifiedOn) }] : []),
];

const getAttachmentFields = (name, size, isPrivate, certificationName) => [
  { name: 'File Name', value: name },
  { name: 'Size', value: pluralize('Byte', size, true) },
  { name: 'Private', value: capitalize(`${isPrivate}`) },
  ...(certificationName ? [{ name: 'Linked Certification name', value: certificationName }] : []),
];

const getCertificationFields = (name, abbreviation, expiryDate, hasAttachment) => [
  { name: 'Certification name', value: name },
  { name: 'Certification abbreviation', value: abbreviation },
  { name: 'Expiration date', value: expiryDate ? getFormattedDate(expiryDate) : null },
  { name: 'Has attachments', value: capitalize(`${hasAttachment}`) },
];

export const renderEventDetails = (eventName, eventData) => {
  const {
    name,
    email,
    title,
    photoUrl,
    size,
    state,
    hasConflict,
    lastModifiedOn,
    employmentDates,
    data,
    message,
    isPrivate,
    creatorName,
    createdOn,
    abbreviation,
    expiryDate,
    hasAttachment,
    certificationName,
  } = eventData || {};

  switch (eventName) {
    case PERSON_CREATED: {
      const personFieldsConfig = getPersonFields(name, email, title);

      return getFieldValues(personFieldsConfig);
    }

    case PERSON_UPDATED:
    case PERSON_DEACTIVATED:
    case PERSON_REACTIVATED: {
      const startDate = employmentDates?.startDate;
      const endDate = employmentDates?.endDate;
      const employmentDatesMarkup = employmentDates && (
        <div>
          {startDate && <p>{`Start Date: ${getFormattedDate(startDate)}`}</p>}
          {endDate && <p>{`End Date: ${getFormattedDate(endDate)}`}</p>}
        </div>
      );

      const personFieldsConfig = getPersonFields(name, email, title, true, photoUrl, state, hasConflict, lastModifiedOn, employmentDatesMarkup);

      return getFieldValues(personFieldsConfig);
    }

    case PERSON_FIELD_SET:
    case PERSON_FIELD_CLEARED: {
      return (
        <div className="people-person-audit-details">
          {data.map(field => (
            <div key={field.fieldId}>
              <p className="people-person-audit-details-row">
                <span className={classnames('people-person-audit-details-label', { 'deleted-field': !field.fieldName })}>
                  {`${field.fieldName || 'Deleted field'}: `}
                </span>
                <span>{getFormattedFieldValue(field.values, field.fieldType)}</span>
              </p>
            </div>
          ))}
        </div>
      );
    }

    case TIME_OFF_ADDED:
    case TIME_OFF_UPDATED:
    case TIME_OFF_DELETED: {
      return data.map((unavailability) => {
        const { startDate, endDate, isPrivate, description, rangeType } = unavailability;
        const unavailabilityConfig = getUnavailabilityFields(startDate, endDate, isPrivate, description, rangeType);

        return getFieldValues(unavailabilityConfig);
      });
    }

    case PERSON_NOTE_ADDED: {
      const personNoteConfig = getPersonNoteFields(message, isPrivate, creatorName, createdOn);

      return getFieldValues(personNoteConfig);
    }

    case PERSON_NOTE_UPDATED:
    case PERSON_NOTE_DELETED: {
      const personNoteConfig = getPersonNoteFields(message, isPrivate, creatorName, createdOn, lastModifiedOn);

      return getFieldValues(personNoteConfig);
    }

    case ATTACHMENT_ADDED:
    case ATTACHMENT_REMOVED:
    case CERTIFICATION_ATTACHMENT_LINKED:
    case CERTIFICATION_ATTACHMENT_UNLINKED: {
      const attachmentConfig = getAttachmentFields(name, size, isPrivate, certificationName);

      return getFieldValues(attachmentConfig);
    }

    case CERTIFICATION_ADDED:
    case CERTIFICATION_UPDATED:
    case CERTIFICATION_DELETED: {
      const certificationConfig = getCertificationFields(name, abbreviation, expiryDate, hasAttachment);

      return getFieldValues(certificationConfig);
    }
    default:
      return null;
  }
};
