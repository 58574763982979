import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { ValidatedForm } from '../wrapped-components';

function AddProjectSubPhaseForm({
  subPhase,
  subPhaseInputs,
  validateDates,
  onValueChanged,
  errors,
  phaseAlreadyAdded,
  removePhaseToAdd,
  removeSubPhaseToAdd,
  refProp,
}) {
  return (
    <div
      key={subPhase.id}
      ref={refProp}
      className="projects-add-project-sub-phase-form sub-phase-row"
    >
      <div className="table-cell phase-name">{subPhase.name}</div>
      <div className="table-cell">
        <ValidatedForm
          inputs={subPhaseInputs[subPhase.id]}
          validate={validateDates}
          onValueChanged={onValueChanged}
          errors={errors}
        />
      </div>
      <div className="table-cell delete-phase">
        <Tooltip title="Remove sub-phase" placement="top">
          <IconButton onClick={phaseAlreadyAdded ? removePhaseToAdd : removeSubPhaseToAdd(subPhase.id)}>
            <Close fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}

AddProjectSubPhaseForm.propTypes = {
  subPhase: PropTypes.object.isRequired,
  subPhaseInputs: PropTypes.object.isRequired,
  validateDates: PropTypes.func.isRequired,
  onValueChanged: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  phaseAlreadyAdded: PropTypes.bool.isRequired,
  removePhaseToAdd: PropTypes.func.isRequired,
  removeSubPhaseToAdd: PropTypes.func.isRequired,
  refProp: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

AddProjectSubPhaseForm.defaultProps = {
  refProp: null,
};

export default AddProjectSubPhaseForm;
