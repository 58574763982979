import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GanttReport, HeaderButton } from '@bridgit/foundation';
import { formatProjectGanttData } from '../../gantt/project-gantt/utils';
import { TIME_INTERVAL_UNIT_MAP } from '../../gantt/constants';
import { PROJECT_GANTT_REPORT_ARGS_KEY, FILTER_STORAGE_KEY, getStorageKey } from '../../../common/localStorageKeys';
import { getValidatedLocalStorage } from '../../../utils/validators';
import { collectProjectGanttData } from '../redux/actions';
import { setFilterQuery, setSortQuery, setSearchQuery } from '../../queries/redux/actions';
import { FILTERED_PROJECTS_QUERY_ID } from '../../queries/redux/constants';
import { printHandler } from '../utils/reportUtils';

const ProjectGanttReport = () => {
  const dispatch = useDispatch();

  const accountId = useSelector(({ common }) => common?.accountId);
  const { userInfo: { sub: userId, permissions } } = useSelector(({ login }) => login);
  const { collectProjectGanttDataPending } = useSelector(({ reports }) => reports);
  const { entities: people } = useSelector(({ people }) => people);
  const { filteredProjects, expandedAllocations } = useSelector(({ projects }) => projects);
  const activeView = useSelector(({ common }) => common?.activeView);
  const queries = useSelector(({ queries }) => queries?.filteredProjects);

  const filterStorageKey = useMemo(() => getStorageKey(activeView, accountId, FILTER_STORAGE_KEY, userId), [accountId, activeView, userId]);
  const reportArgsStorageKey = useMemo(() => getStorageKey(activeView, accountId, PROJECT_GANTT_REPORT_ARGS_KEY, userId), [accountId, activeView, userId]);
  const reportArgs = useMemo(() => getValidatedLocalStorage(reportArgsStorageKey) || {}, [reportArgsStorageKey]);

  const {
    startDate,
    endDate,
    timeInterval,
    timeMultiplier,
    viewRoles,
    roleSortOption,
    roleFilters,
    includeAllocPerc,
    width,
    pagesPerWindow,
    filter,
    sort,
    search,
  } = reportArgs;

  // Format the bar and legend data so it can be displayed in the Gantt chart.
  const { bars, legend } = useMemo(
    () => formatProjectGanttData(
      filteredProjects,
      people,
      expandedAllocations,
      viewRoles,
      false, // hasRoleWrite perms false to hide actions for report
      false, // hasAllocationWrite perms false to hide actions for report
      startDate,
      endDate,
      includeAllocPerc,
      true,
    ),
    [filteredProjects, people, expandedAllocations, viewRoles, startDate, endDate, includeAllocPerc],
  );

  useEffect(() => {
    dispatch(setFilterQuery(FILTERED_PROJECTS_QUERY_ID, filter, filterStorageKey));
  }, [dispatch, filter, filterStorageKey]);

  useEffect(() => {
    dispatch(setSortQuery(FILTERED_PROJECTS_QUERY_ID, sort, filterStorageKey));
  }, [dispatch, filterStorageKey, sort]);

  useEffect(() => {
    dispatch(setSearchQuery(FILTERED_PROJECTS_QUERY_ID, search, filterStorageKey));
  }, [dispatch, filterStorageKey, search]);

  useEffect(() => {
    const requestData = { roleSortOption, roleFilters, permissions };
    dispatch(collectProjectGanttData(accountId, queries, requestData));
  }, [accountId, dispatch, queries, permissions, roleSortOption, roleFilters]);

  const titleHeader = useMemo(() => <div className="reports-project-gantt-report-title-header">Projects</div>, []);

  return (
    <div className="reports-project-gantt-report">
      <div className="report">
        <HeaderButton
          label="Print"
          onClick={printHandler}
        />
        <div className="content">
          <GanttReport
            bars={bars}
            legend={legend}
            title={titleHeader}
            width={width}
            timeInterval={TIME_INTERVAL_UNIT_MAP[timeInterval]}
            timeMultiplier={timeMultiplier}
            startDate={startDate}
            endDate={endDate}
            isLoading={collectProjectGanttDataPending}
            withHeader
            pagesPerWindow={pagesPerWindow}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectGanttReport;
