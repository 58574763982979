import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, Radio, FormControlLabel } from '@material-ui/core';

export default class UserPermissionOptions extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    selectedRole: PropTypes.string,
  };

  static defaultProps = {
    selectedRole: 'admin',
  };

  render() {
    const { onChange, selectedRole } = this.props;
    return (
      <div className="accounts-user-permission-options">
        <RadioGroup
          aria-label="options"
          name="options"
          className="permission-options"
          value={selectedRole}
          onChange={onChange}
        >
          <FormControlLabel
            value="admin"
            control={<Radio color="primary" />}
            label="Administrator"
            labelPlacement="end"
          />
          <FormControlLabel
            value="viewOnly"
            control={<Radio color="primary" />}
            label="View Only"
            labelPlacement="end"
          />
        </RadioGroup>
      </div>
    );
  }
}
