import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import {
  CircularProgress,
  Paper,
} from '@material-ui/core';
import moment from 'moment';
import { ProjectRoleTitle } from '@bridgit/foundation';
import { ProjectLinkToModalContainer } from '../common';

export function ProfileIssues({
  issues,
  loading,
  runsInModal,
}) {
  const today = new Date();
  return (
    <div className="people-profile-issues">
      {loading && <div className="loading"><CircularProgress color="primary" /></div>}
      {issues.length < 1 && !loading && <div className="header">No upcoming issues</div>}
      {issues.length > 0 && !loading && <div className="header">Upcoming issues</div>}
      {issues.length > 0 && !loading && (
        <div className="issues-wrap">
          <div className="titles">
            <div className="title time">Time until issue</div>
            <div className="title length">Length of issue</div>
            <div className="title total-allocation">Total allocation</div>
            <div className="title projects">Project name</div>
            <div className="title roles">Role on project</div>
          </div>
          {issues.map((issue) => {
            const days = moment(issue.startDate).diff(today, 'days');
            const length = moment(issue.endDate).diff(issue.startDate, 'days') + 1;
            return (
              <Paper className="issue" key={`${issue.startDate}-${issue.endDate}`}>
                <div className="column time">
                  {days < 1 && <div className="now">Active Issue</div>}
                  {days > 0 && <div>{pluralize('day', days, true)}</div>}
                </div>
                <div className="column length">
                  <div className="date-wrap">
                    <div className="number">
                      {`${pluralize('Day', length, true)} ${days < 1 ? 'remaining' : ''}`}
                    </div>
                    <div className="dates">
                      {`${days < 1 ? 'Ends on' : `${moment(issue.startDate).format('ll')} -`} ${moment(issue.endDate).format('ll')}`}
                    </div>
                  </div>
                </div>
                <div className="column total-allocation">
                  <div>{issue.allocatedPercent}</div>
                </div>
                <div className="column projects">
                  {issue.projectRoles.map((role, index) => (
                    /* eslint-disable-next-line react/no-array-index-key */
                    <div className="project-name" key={index}>
                      <ProjectLinkToModalContainer
                        projectId={role.projectId}
                        backgroundColor={role.projectColour}
                        projectName={role.projectName}
                        launchedFrom="Profile Issues"
                        runsInModal={runsInModal}
                      />
                    </div>
                  ))}
                </div>
                <div className="column roles">
                  {issue.projectRoles.map(role => (
                    <ProjectRoleTitle
                      key={`${role.projectId}-${role.roleId}`}
                      className="issues-role-title"
                      roleName={role.roleName}
                      roleNote={role.roleNote}
                    />
                  ))}
                </div>
              </Paper>
            );
          })}
        </div>
      )}
    </div>
  );
}

ProfileIssues.propTypes = {
  issues: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool.isRequired,
  runsInModal: PropTypes.bool,
};

ProfileIssues.defaultProps = {
  issues: [],
  runsInModal: false,
};

export default ProfileIssues;
