import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import deepEqual from 'react-fast-compare';
import GoogleMap from 'google-map-react';
import { ProjectMarker, MarkerCluster, usePrevious } from '@bridgit/foundation';
import useSupercluster from 'use-supercluster';
import pluralize from 'pluralize';
import { LinearProgress } from '@material-ui/core';
import {
  LocalStorageKeys,
  getStorageKey,
  FILTER_STORAGE_KEY,
} from '../../common/localStorageKeys';
import {
  MAP_TAB,
  ACCOUNT_MODULE_PURSUIT_TRACKING,
  GEO_JSON_FEATURE,
  GEO_JSON_POINT,
  PROJECT_LIST_STATIC_COLUMNS,
  PROJECT_VIEW,
} from '../../common/constants';
import {
  PROJECT_MAP_INITIAL_CENTER,
  PROJECT_MAP_MIN_ZOOM,
  PROJECT_MAP_MAX_ZOOM,
  PROJECT_MAP_INITIAL_ZOOM,
  PURSUIT_MODULE_STATUS_TO_REMOVE,
  PROJECT_MAP_CLUSTER_RADIUS,
  PROJECT_MAP_BOUNDS_PADDING,
  PROJECT_MAP_CLUSTER_MAX_ZOOM,
} from './constants';
import { setSelectedProjectId, trackAnalytics } from '../common/redux/actions';
import { MULTI_STATE_MODAL_ID } from '../common/redux/constants';
import {
  filterClearEvent,
  filterClearPayload,
  filterChipRemoveEvent,
  filterChipRemovePayload,
} from '../../analytics/mixpanel';
import { FilteredTable, ChipBowl } from '../table';
import { filterTable } from '../table/filterUtils';
import { defaultFiltersWithModule } from '../../utils/tableUtils';
import { defaultFilters, PROJECT_FILTER_TYPE } from '../filters/common/constants';
import { hasModuleEnabled } from '../permissions/utils/permissionUtils';
import { FILTERED_PROJECTS_QUERY_ID } from '../queries/redux/constants';
import config from '../../common/envConfig';
import { openModal } from '../modal-manager/redux/actions';
import { PROJECT_DETAILS_MODAL_DISPLAYED } from '../../analytics/gantt/constants';

const ProjectMap = () => {
  const dispatch = useDispatch();

  const [map, setMap] = useState(); // Current map instance
  const [maps, setMaps] = useState(); // Google Maps API
  const [bounds, setBounds] = useState();
  const [zoom, setZoom] = useState(PROJECT_MAP_INITIAL_ZOOM);
  const [visibleProjects, setVisibleProjects] = useState([]);
  const [hoveredProjectId, setHoveredProjectId] = useState();
  const [isResizeQueued, setIsResizeQueued] = useState(true); // Queue one initial bounds resize

  const { accountId } = useSelector(({ common }) => common);
  const { userInfo: { sub: userId } } = useSelector(({ login }) => login);
  const { filteredProjects } = useSelector(({ projects }) => projects);
  const { accountModules } = useSelector(({ accountSettings }) => accountSettings);
  const { getFilteredProjectsPending } = useSelector(({ projects }) => projects);
  const projectNameColumn = useSelector(({ table }) => table.visibleColumns.slice(0, 1));
  const { filter, search } = useSelector(({ queries }) => queries[FILTERED_PROJECTS_QUERY_ID]);

  const prevFilter = usePrevious(filter);
  const prevSearch = usePrevious(search);

  const isLoading = useMemo(() => (
    getFilteredProjectsPending || !map || !maps
  ), [getFilteredProjectsPending, map, maps]);

  const projectsWithCoordinates = useMemo(() => (
    filteredProjects.filter(({ coordinates }) => !!coordinates)
  ), [filteredProjects]);

  // GeoJSON features for clustering
  const points = useMemo(() => (
    projectsWithCoordinates.map(({ id, colour, name, coordinates: { lat, lng } }) => ({
      type: GEO_JSON_FEATURE,
      properties: {
        cluster: false,
        id,
        colour,
        name,
      },
      geometry: {
        type: GEO_JSON_POINT,
        coordinates: [lng, lat],
      },
    }))
  ), [projectsWithCoordinates]);

  const { clusters, supercluster } = useSupercluster({
    points,
    zoom,
    bounds,
    options: {
      radius: PROJECT_MAP_CLUSTER_RADIUS,
      minZoom: PROJECT_MAP_MIN_ZOOM,
      maxZoom: PROJECT_MAP_CLUSTER_MAX_ZOOM,
    },
  });

  const tableData = useMemo(() => {
    const data = visibleProjects.map(({ id, name, colour }) => {
      const rawData = { 'Project Name': name };
      const rowData = [name];

      return {
        rowMeta: {
          id,
          name,
          colour,
          rawData,
          isMap: true,
        },
        rowData,
        rowId: id,
      };
    });

    return filterTable(search, data);
  }, [visibleProjects, search]);

  const clusterClickHandler = useCallback((clusterId, lat, lng) => () => {
    const expansionZoom = supercluster.getClusterExpansionZoom(clusterId);

    map.setZoom(expansionZoom);
    map.panTo({ lat, lng });
  }, [supercluster, map]);

  const openProjectModal = useCallback((projectId, projectName, launchedFrom) => {
    dispatch(setSelectedProjectId(projectId));
    dispatch(openModal(MULTI_STATE_MODAL_ID));
    dispatch(trackAnalytics(PROJECT_DETAILS_MODAL_DISPLAYED, {
      'Project name': projectName,
      'Project id': projectId,
      'Launched from': launchedFrom,
    }));
  }, [dispatch]);

  const childClickHandler = useCallback((markerId, meta) => {
    const projectIdInt = Number.parseInt(markerId, 10);

    if (Number.isNaN(projectIdInt)) { // Cluster marker IDs are NaN
      return;
    }

    const { name } = meta;

    openProjectModal(projectIdInt, name, 'Map - Project marker');
  }, [openProjectModal]);

  const chipClickHandler = useCallback(({ projectId, projectName }) => {
    openProjectModal(projectId, projectName, 'Map - Project marker');
  }, [openProjectModal]);

  const markers = useMemo(() => (
    clusters.map((cluster) => {
      const { id: clusterId, geometry: { coordinates }, properties } = cluster;
      const [lng, lat] = coordinates;
      const {
        cluster: isCluster,
        point_count: pointCount,
        id,
        colour,
        name,
      } = properties;

      if (isCluster) {
        const leaves = supercluster.getLeaves(clusterId, Infinity);
        const isClusterHovered = leaves.some(({ properties: { id } }) => id === hoveredProjectId);

        return (
          <MarkerCluster
            key={`cluster-${clusterId}`}
            pointCount={pointCount}
            lat={lat}
            lng={lng}
            onClick={clusterClickHandler(clusterId, lat, lng)}
            isExternallyHovered={isClusterHovered}
          />
        );
      }

      return (
        <ProjectMarker
          key={id}
          id={id}
          color={colour}
          name={name}
          lat={lat}
          lng={lng}
          onProjectChipClick={chipClickHandler}
          isExternallyHovered={hoveredProjectId === id}
        />
      );
    })
  ), [clusters, chipClickHandler, hoveredProjectId, supercluster, clusterClickHandler]);

  const defaultProjectFilters = useMemo(() => {
    const isPursuitModuleOn = hasModuleEnabled(accountModules, ACCOUNT_MODULE_PURSUIT_TRACKING);

    return defaultFiltersWithModule(defaultFilters.projects, PURSUIT_MODULE_STATUS_TO_REMOVE, isPursuitModuleOn);
  }, [accountModules]);

  const noMatch = useMemo(() => (
    filteredProjects.length === 0 ? 'No projects have been added to your account' : 'Sorry, no matching records found'
  ), [filteredProjects.length]);

  const unresolvableCount = useMemo(() => {
    const count = filteredProjects.length - projectsWithCoordinates.length;

    return `${count} without ${pluralize('address', count, false)}`;
  }, [filteredProjects.length, projectsWithCoordinates.length]);

  const googleApiLoadedHandler = useCallback(({ map, maps }) => {
    setMap(map);
    setMaps(maps);
  }, []);

  const mapChangeHandler = useCallback(({ bounds, zoom }) => {
    setBounds([
      bounds.nw.lng,
      bounds.se.lat,
      bounds.se.lng,
      bounds.nw.lat,
    ]);
    setZoom(zoom);
  }, []);

  const rowClickHandler = useCallback((projectId, projectMeta) => {
    const { name } = projectMeta;
    const projectIdInt = Number.parseInt(projectId, 10);

    openProjectModal(projectIdInt, name, 'Map - List');
  }, [openProjectModal]);

  const rowMouseEnterHandler = useCallback((projectId) => {
    const projectIdInt = Number.parseInt(projectId, 10);

    setHoveredProjectId(projectIdInt);
  }, []);

  const rowMouseLeaveHandler = useCallback(() => {
    setHoveredProjectId(null);
  }, []);

  const clearFiltersAnalyticsHandler = useCallback(() => {
    dispatch(trackAnalytics(filterClearEvent('Project'), filterClearPayload('Project Map')));
  }, [dispatch]);

  const updateFiltersAnalyticsHandler = useCallback(() => {
    dispatch(trackAnalytics(filterChipRemoveEvent('Project'), filterChipRemovePayload('Project Map')));
  }, [dispatch]);

  const resizeMapBounds = useCallback(() => {
    // Don't resize map bounds if there are no projects with coordinates
    if (!projectsWithCoordinates?.length) {
      return;
    }

    const newBounds = new maps.LatLngBounds();

    projectsWithCoordinates.forEach(({ coordinates }) => {
      newBounds.extend(coordinates);
    });
    map.fitBounds(newBounds, PROJECT_MAP_BOUNDS_PADDING);
  }, [maps, map, projectsWithCoordinates]);

  const filterChips = useMemo(() => {
    const filterStorageKey = getStorageKey(PROJECT_VIEW, accountId, FILTER_STORAGE_KEY, userId);
    const isPursuitModuleOn = hasModuleEnabled(accountModules, ACCOUNT_MODULE_PURSUIT_TRACKING);
    const useClearText = !defaultFiltersWithModule(defaultFilters.projects, PURSUIT_MODULE_STATUS_TO_REMOVE, isPursuitModuleOn).length;

    return (
      <ChipBowl
        useClearText={useClearText}
        queryId={FILTERED_PROJECTS_QUERY_ID}
        filterStorageKey={filterStorageKey}
        onClearFilters={clearFiltersAnalyticsHandler}
        onUpdateFilters={updateFiltersAnalyticsHandler}
        filterType={PROJECT_FILTER_TYPE}
      />
    );
  }, [accountId, accountModules, clearFiltersAnalyticsHandler, updateFiltersAnalyticsHandler, userId]);

  // Remember which tab we're on
  useEffect(() => {
    localStorage.setItem(LocalStorageKeys.projectView, MAP_TAB);
  }, []);

  // Update visible projects when map bounds change
  useEffect(() => {
    if (!map) {
      return;
    }

    const mapBounds = map.getBounds();
    const projectsInBounds = projectsWithCoordinates.reduce((acc, project) => {
      if (mapBounds.contains(project.coordinates)) {
        acc.push(project);
      }

      return acc;
    }, []);

    setVisibleProjects(projectsInBounds);
  }, [map, projectsWithCoordinates, bounds]);

  // Queue bounds resize when filter or search changes
  useEffect(() => {
    const areQueriesChanged = !deepEqual(prevFilter, filter) || !deepEqual(prevSearch, search);

    if (areQueriesChanged) {
      setIsResizeQueued(true);
    }
  }, [prevFilter, filter, prevSearch, search]);

  // Update map bounds
  useEffect(() => {
    // Don't update bounds if loading or a resize isn't queued
    if (isLoading || !isResizeQueued) {
      return;
    }

    resizeMapBounds();
    setIsResizeQueued(false);
  }, [isLoading, isResizeQueued, resizeMapBounds]);

  return (
    <div className="project-location-map">
      {isLoading && <LinearProgress className="project-location-map-linear-progress" />}
      <div className="project-location-map-floating-list">
        <FilteredTable
          showFilters={false}
          data={tableData}
          columns={projectNameColumn}
          onRowClick={rowClickHandler}
          onRowMouseEnter={rowMouseEnterHandler}
          onRowMouseLeave={rowMouseLeaveHandler}
          noMatch={noMatch}
          defaultFilters={defaultProjectFilters}
          staticColumns={PROJECT_LIST_STATIC_COLUMNS}
          allowColumnReordering
          queryId={FILTERED_PROJECTS_QUERY_ID}
          hasChipBowl={false}
        />
        <div className="project-location-map-floating-list-footer">
          <span className="count-projects-visible">{pluralize('project', visibleProjects.length, true)}</span>
          <span className="count-projects-without-address">{unresolvableCount}</span>
        </div>
      </div>
      <div className="project-location-map-chip-bowl">
        {filterChips}
      </div>
      <GoogleMap
        bootstrapURLKeys={{ key: config.googleMapsKey }}
        defaultCenter={PROJECT_MAP_INITIAL_CENTER}
        defaultZoom={PROJECT_MAP_INITIAL_ZOOM}
        yesIWantToUseGoogleMapApiInternals // Expose 'map' and 'maps' objects in onGoogleApiLoaded
        onChange={mapChangeHandler}
        onChildClick={childClickHandler}
        onGoogleApiLoaded={googleApiLoadedHandler}
        options={{
          fullscreenControl: false,
          streetViewControl: false,
          minZoom: PROJECT_MAP_MIN_ZOOM,
          maxZoom: PROJECT_MAP_MAX_ZOOM,
        }}
      >
        {markers}
      </GoogleMap>
    </div>
  );
};

export default ProjectMap;
