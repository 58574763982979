import { put, takeLatest, all } from 'redux-saga/effects';
import { isAuthorized } from 'src/features/permissions/utils/permissionUtils';
import { PERM_READ, PERM_PERSON } from 'src/features/permissions/utils/constants';
import {
  PROJECTS_COLLECT_PROJECT_TABLE_DATA_BEGIN,
  PROJECTS_COLLECT_PROJECT_TABLE_DATA_SUCCESS,
  PROJECTS_COLLECT_PROJECT_TABLE_DATA_FAILURE,
  PROJECTS_COLLECT_PROJECT_TABLE_DATA_DISMISS_ERROR,
} from './constants';

import { doGetProjects } from './getProjects';
import { doGetProjectFieldValues } from './getProjectFieldValues';
import { doGetProjectFields } from '../../account-settings/redux/getProjectFields';
import { doGetRoleNames } from '../../account-settings/redux/getRoleNames';
import { doGetPhases } from '../../account-settings/redux/getPhases';
import { doGetPeople } from '../../people/redux/getPeople';
import { doGetPersonFieldValues } from '../../people/redux/getPersonFieldValues';
import { doGetPersonUnavailabilities } from '../../people/redux/getPersonUnavailabilities';

export function collectProjectTableData(accountId, permissions) {
  return {
    type: PROJECTS_COLLECT_PROJECT_TABLE_DATA_BEGIN,
    accountId,
    permissions,
  };
}

export function dismissCollectProjectTableDataError() {
  return {
    type: PROJECTS_COLLECT_PROJECT_TABLE_DATA_DISMISS_ERROR,
  };
}

export function* doCollectProjectTableData(action) {
  const { accountId, permissions } = action;
  const accountParam = { accountId };
  const hasPersonPerms = isAuthorized(accountId, permissions, PERM_READ, PERM_PERSON);
  try {
    yield all([
      doGetProjects(accountParam),
      doGetProjectFieldValues(accountParam),
      doGetProjectFields(accountParam),
      doGetRoleNames(accountParam),
      doGetPhases(accountParam),
      ...(hasPersonPerms ? [
        doGetPeople(accountParam),
        doGetPersonFieldValues(accountParam),
        doGetPersonUnavailabilities({ accountId, activeState: 'Active' }),
      ] : []),
    ]);
  } catch (err) {
    yield put({
      type: PROJECTS_COLLECT_PROJECT_TABLE_DATA_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PROJECTS_COLLECT_PROJECT_TABLE_DATA_SUCCESS,
  });
}

export function* watchCollectProjectTableData() {
  yield takeLatest(PROJECTS_COLLECT_PROJECT_TABLE_DATA_BEGIN, doCollectProjectTableData);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PROJECTS_COLLECT_PROJECT_TABLE_DATA_BEGIN:
      return {
        ...state,
        collectProjectTableDataPending: true,
        collectProjectTableDataError: null,
      };

    case PROJECTS_COLLECT_PROJECT_TABLE_DATA_SUCCESS:
      return {
        ...state,
        collectProjectTableDataPending: false,
        collectProjectTableDataError: null,
      };

    case PROJECTS_COLLECT_PROJECT_TABLE_DATA_FAILURE:
      return {
        ...state,
        collectProjectTableDataPending: false,
        collectProjectTableDataError: action.data.error,
      };

    case PROJECTS_COLLECT_PROJECT_TABLE_DATA_DISMISS_ERROR:
      return {
        ...state,
        collectProjectTableDataError: null,
      };

    default:
      return state;
  }
}
