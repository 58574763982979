import { getDateRangeQuery } from './queryUtils';

export default function generateProjectDateFilter(field, fieldName) {
  const selected = field.selected[0];
  const query = getDateRangeQuery(selected, field.verb);

  return {
    range: {
      [fieldName]: query,
    },
  };
}
