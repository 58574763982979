import {
  SHOULD,
  MUST_NOT,
  ROLES,
  ROLES_ALLOCATIONS,
  ROLES_ALLOCATIONS_PERSON_ID,
} from './constants';

const generateAllocatedPeopleFilter = (activeFilter) => {
  const innerFilters = [];

  const { inclusive, selected } = activeFilter;
  const boolStatement = inclusive ? SHOULD : MUST_NOT;

  selected.forEach((person) => {
    innerFilters.push({
      nested: {
        path: ROLES_ALLOCATIONS,
        query: {
          bool: {
            filter: {
              term: {
                [ROLES_ALLOCATIONS_PERSON_ID]: person.value,
              },
            },
          },
        },
      },
    });
  });

  const allocatedPeopleFilter = {
    bool: {
      [boolStatement]: [{
        nested: {
          path: ROLES,
          query: {
            bool: {
              should: innerFilters,
            },
          },
        },
      }],
    },
  };


  return allocatedPeopleFilter;
};

const generateAllocatedPeopleFilters = (activeFilters) => {
  const allocatedPeopleFilters = activeFilters.map(activeFilter => generateAllocatedPeopleFilter(activeFilter));

  return allocatedPeopleFilters;
};

export default generateAllocatedPeopleFilters;
