import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import lockImage from 'src/images/lock_illustration.png';

export default function AccessDenied({ className, message }) {
  return (
    <div className={classnames('permissions-access-denied', className)}>
      <div>{message}</div>
      <img alt="Access Denied Icon" src={lockImage} />
    </div>
  );
}

AccessDenied.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
};

AccessDenied.defaultProps = {
  className: '',
  message: 'You don\'t have permission to view this page.',
};

