import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Obfuscator } from '@bridgit/foundation';
import {
  DATE_DISPLAY_FORMAT,
  DEFAULT_POU_START,
  DEFAULT_POU_END,
  TIME_OFF,
  PRIVATE_MODE_TOOLTIP_TEXT,
} from 'src/common/constants';
import { PERM_READ, PERM_WRITE, PERM_PERSON, PERM_PRIVATE } from 'src/features/permissions/utils/constants';
import { EditButton, Can } from '../wrapped-components';

export const UnavailabilityRow = ({
  unavailability,
  onEdit,
}) => {
  const { privateModeEnabled } = useSelector(({ common }) => common);

  const { id, description, startDate, endDate, isPrivate, rangeType } = unavailability;

  const start = startDate === DEFAULT_POU_START
    ? '' : moment(startDate).format(DATE_DISPLAY_FORMAT);

  const end = endDate === DEFAULT_POU_END
    ? '' : moment(endDate).format(DATE_DISPLAY_FORMAT);

  const handleEdit = () => onEdit(id);

  const renderDescription = () => {
    if (!description) return <div className="unavailable">Unavailable</div>;
    return (
      <Can
        action={PERM_READ}
        subject={PERM_PRIVATE}
        yes={(
          <Obfuscator
            isEnabled={isPrivate && privateModeEnabled}
            label="Hidden description"
            tooltip={PRIVATE_MODE_TOOLTIP_TEXT}
          >
            <div>{description}</div>
          </Obfuscator>
        )}
        no={(
          <>
            { isPrivate
              ? <div className="unavailable">Unavailable</div>
              : <div>{description}</div>}
          </>
        )}
      />
    );
  };

  return (
    <div className="people-unavailability-row">
      {renderDescription()}
      <div>{`${start} - ${end}`}</div>
      <div>{rangeType === TIME_OFF ? 'No' : 'Yes'}</div>
      <Can
        action={PERM_WRITE}
        subject={PERM_PERSON}
        yes={(
          <EditButton
            tooltipTitle="Edit time off"
            onClick={handleEdit}
          />
        )}
      />
    </div>
  );
};

UnavailabilityRow.propTypes = {
  unavailability: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default UnavailabilityRow;
