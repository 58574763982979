import { LIST_TAB, DASHBOARD_VIEW } from 'src/common/constants';

const initialState = {
  open: false,
  editingUser: false,
  collapsedNav: true,
  accountOpen: false,
  graphApiQueryPending: false,
  graphApiQueryError: null,
  accountId: null,
  activeView: DASHBOARD_VIEW,
  contentView: LIST_TAB,
  dragging: false,
  personSidePanelOpen: false,
  projectSidePanelOpen: false,
  multiStateModal: {
    activeView: null,
    selectedPersonId: null,
    selectedProjectId: null,
    roleDetails: null,
    showProjectOnCancel: false,
    showBackButton: false,
    modalOrigin: null,
  },
  collectAppPrerequisitesPending: false,
  collectAppPrerequisitesError: null,
  privateModeEnabled: true,
};

export default initialState;
