import {
  PhaseEditor,
} from '.';

export default {
  path: 'edit-dates',
  name: 'Edit dates',
  childRoutes: [
    { path: 'edit-dates', name: 'Edit dates', component: PhaseEditor, isIndex: true },
  ],
};
