export const DEFAULT_PROJECT_COLOUR = '#018054';

export const TOGGLE_NAVIGATION = 'TOGGLE_NAVIGATION';
export const COMMON_TOGGLE_MODAL = 'COMMON_TOGGLE_MODAL';
export const COMMON_GRAPH_API_QUERY_BEGIN = 'COMMON_GRAPH_API_QUERY_BEGIN';
export const COMMON_GRAPH_API_QUERY_SUCCESS = 'COMMON_GRAPH_API_QUERY_SUCCESS';
export const COMMON_GRAPH_API_QUERY_FAILURE = 'COMMON_GRAPH_API_QUERY_FAILURE';
export const COMMON_GRAPH_API_QUERY_DISMISS_ERROR = 'COMMON_GRAPH_API_QUERY_DISMISS_ERROR';
export const COMMON_SET_ACTIVE_ACCOUNT_ID = 'COMMON_SET_ACTIVE_ACCOUNT_ID';
export const COMMON_SET_ACTIVE_VIEW = 'COMMON_SET_ACTIVE_VIEW';
export const COMMON_SET_CONTENT_VIEW = 'COMMON_SET_CONTENT_VIEW';

export const MONTH_PICKER_VIEWS = ['year', 'month'];

export const MULTI_STATE_MODAL_ID = 'MULTI_STATE_MODAL_ID';
export const COMMON_SET_SELECTED_PERSON_ID = 'COMMON_SET_SELECTED_PERSON_ID';
export const COMMON_SET_SELECTED_PROJECT_ID = 'COMMON_SET_SELECTED_PROJECT_ID';

export const COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PERSON = 'COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PERSON';
export const COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PROJECT = 'COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PROJECT';
export const COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_ALLOCATE_PERSON = 'COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_ALLOCATE_PERSON';
export const COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_EDIT_PROJECT_DATE = 'COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_EDIT_PROJECT_DATE';
export const COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_EDIT_PHASE_DATE = 'COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_EDIT_PHASE_DATE';
export const COMMON_CLEAR_SELECTED_PERSON_ID = 'COMMON_CLEAR_SELECTED_PERSON_ID';
export const COMMON_CLEAR_SELECTED_PROJECT_ID = 'COMMON_CLEAR_SELECTED_PROJECT_ID';
export const COMMON_SET_MODAL_ACTIVE_VIEW = 'COMMON_SET_MODAL_ACTIVE_VIEW';
export const COMMON_CLEAR_MULTI_STATE_MODAL_STATE = 'COMMON_CLEAR_MULTI_STATE_MODAL_STATE';

export const COMMON_MODAL_POSITION_TOP = 'COMMON_MODAL_POSITION_TOP';
export const COMMON_MODAL_POSITION_MIDDLE = 'COMMON_MODAL_POSITION_MIDDLE';
export const COMMON_SET_BACK_BUTTON_STATE = 'COMMON_SET_BACK_BUTTON_STATE';
export const COMMON_TOGGLE_PERSON_SIDE_PANEL = 'COMMON_TOGGLE_PERSON_SIDE_PANEL';
export const COMMON_TOGGLE_PROJECT_SIDE_PANEL = 'COMMON_TOGGLE_PROJECT_SIDE_PANEL';
export const COMMON_COLLECT_APP_PREREQUISITES_BEGIN = 'COMMON_COLLECT_APP_PREREQUISITES_BEGIN';
export const COMMON_COLLECT_APP_PREREQUISITES_SUCCESS = 'COMMON_COLLECT_APP_PREREQUISITES_SUCCESS';
export const COMMON_COLLECT_APP_PREREQUISITES_FAILURE = 'COMMON_COLLECT_APP_PREREQUISITES_FAILURE';
export const COMMON_COLLECT_APP_PREREQUISITES_DISMISS_ERROR = 'COMMON_COLLECT_APP_PREREQUISITES_DISMISS_ERROR';
export const COMMON_SET_PRIVATE_MODE = 'COMMON_SET_PRIVATE_MODE';
export const COMMON_TRACK_ANALYTICS = 'COMMON_TRACK_ANALYTICS';
