import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getRelativeRangeLength, isStringOrMoment, toRange, calculateSpacerDimensions } from 'src/utils/dateUtils';
import { getBoundingRangeFromSegments, isValidSegmentArray } from 'src/utils/dateSegmentUtils';
import { customPropCheck } from 'src/utils/propCheckUtils';

const TimelinePositioner = ({
  boundingStartDate,
  boundingEndDate,
  timelineDates,
  useMarginOffset,
  children,
}) => {
  // What are the bounding dates for our given segments?
  const { startDate: timelineStart, endDate: timelineEnd } = getBoundingRangeFromSegments(timelineDates);

  // What percentage of the overall timeframe does this timeline represent?
  const percentWidth = useMemo(() => getRelativeRangeLength(
    toRange(timelineStart, timelineEnd),
    toRange(boundingStartDate, boundingEndDate),
  ), [boundingEndDate, boundingStartDate, timelineEnd, timelineStart]);

  // What percentage of the overall timeframe separates the timeline from the bounding start?
  const { startPadPercentage: percentLeftMargin } = useMemo(() => calculateSpacerDimensions(
    { startDate: timelineStart, endDate: timelineStart },
    { startDate: boundingStartDate, endDate: boundingEndDate },
  ), [boundingEndDate, boundingStartDate, timelineStart]);

  return (
    children && React.cloneElement(
      children,
      {
        percentWidth,
        ...(useMarginOffset && { percentLeftMargin }),
      },
    ));
};

TimelinePositioner.propTypes = {
  boundingStartDate: customPropCheck(isStringOrMoment).isRequired,
  boundingEndDate: customPropCheck(isStringOrMoment).isRequired,
  timelineDates: customPropCheck(isValidSegmentArray).isRequired,
  useMarginOffset: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

TimelinePositioner.defaultProps = {
  useMarginOffset: true,
};

export default TimelinePositioner;
