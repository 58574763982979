import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import {
  OverflowTooltip,
  useIsRefOverflowing,
  Obfuscator,
  HelpTooltip,
} from '@bridgit/foundation';
import {
  Checkbox,
  Grid,
  Typography,
  FormControlLabel,
  ClickAwayListener,
  Link,
  Tooltip,
} from '@material-ui/core';
import { OpenInNew, Edit } from '@material-ui/icons';
import { getMapsQueryUrl, apiFormatPhoneNumber } from 'src/utils/miscUtils';
import { validateBasicInputs } from 'src/utils/validators';
import { formatPhoneNumber, formatDollarValue } from 'src/utils/formatters';
import { EditControls, Can, ValidatedForm } from '../wrapped-components';
import { AUTOMATION_PROJECT_REQUESTERS_INPUT_ID } from '../projects/ids';
import {
  DATE_DISPLAY_FORMAT,
  EMPTY_FIELD_VALUE,
  FIELD_TYPE_ADDRESS,
  FIELD_TYPE_BOOLEAN,
  FIELD_TYPE_CURRENCY,
  FIELD_TYPE_DATE,
  FIELD_TYPE_MULTI_SELECT,
  FIELD_TYPE_PHONE,
  FIELD_TYPE_SINGLE_SELECT,
  FIELD_TYPE_PHONE_E164,
  PRIVATE_MODE_TOOLTIP_TEXT,
  FIELDS_WITH_TOOLTIPS,
  COLOR,
} from '../../common/constants';
import { getSortedSelectedRequesterNames } from '../projects/utils/projectRequestersUtils';
import { formatWorkingDays, getSelectedWorkDayValues } from '../self-perform/utils/requestUtils';
import {
  AUTOMATION_EMPTY_REQUESTER_TEXT,
  AUTOMATION_CONTENT_ITEM,
  AUTOMATION_CONTENT_TITLE,
} from './ids';
import { formatPhoneNumberInput, formatPhoneNumberDisplay } from '../../utils/phoneNumberUtils';

const FieldEditor = ({
  item,
  checked,
  input,
  onSave,
  disabled,
  permission,
  pending,
  fieldToEdit,
  editing,
  onAddressView,
  onValidate,
  onCancel,
  showName,
  emptySelectionText,
  displayOnly,
  showTooltip,
}) => {
  const [value, setValue] = useState(item.value);
  const [check, setCheck] = useState(checked);
  const [error, setError] = useState('');
  const [focused, setFocused] = useState(false);
  const [selectOpen, setSelectOpen] = useState(false);
  const [touched, setTouched] = useState(false);
  const { ref: displayRef, isOverflowing } = useIsRefOverflowing(value);
  const { privateModeEnabled } = useSelector(({ common }) => common);

  useEffect(() => {
    setValue(item.value);
    setCheck(checked);
    setTouched(false);
  }, [item.value, checked]);

  const onEdit = () => {
    fieldToEdit(item.id);
  };

  const cancelEditing = () => {
    setValue(item.value);
    setCheck(checked);
    setError('');
    fieldToEdit(null);
    onCancel();
    setFocused(false);
    setSelectOpen(false);
    setTouched(false);
  };

  const onClickAway = () => {
    if (!selectOpen && !touched) {
      cancelEditing();
    }
  };

  const handleSave = () => {
    let newValue = item.type === 'Boolean' ? check.toString() : value;

    if (newValue) {
      if (newValue.isArray) {
        newValue = newValue.map(val => val.toString());
      } else if (item.type === FIELD_TYPE_PHONE) {
        newValue = apiFormatPhoneNumber(newValue);
      } else if (item.type === FIELD_TYPE_PHONE_E164) {
        newValue = formatPhoneNumberInput(newValue);
      }
    }

    let values = [newValue].flat();
    if (!newValue || (item.type === FIELD_TYPE_SINGLE_SELECT && newValue === '')) values = [];
    onSave({
      ...item,
      fieldId: item.id,
      values,
    });
  };

  const onValueChanged = (name, val) => {
    let newValue = val[name];

    if (typeof newValue === 'string') {
      newValue = newValue.trim();
    }

    setTouched(true);
    if (item.type === FIELD_TYPE_BOOLEAN) {
      setCheck(newValue);
    } else {
      setValue(newValue);
    }
  };

  const onDateInputFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    setFocused(false);
  };

  const onSelectOpen = () => {
    setSelectOpen(true);
  };

  const onSelectClose = () => {
    setSelectOpen(false);
  };

  const validate = (changedValues) => {
    const errors = {
      ...validateBasicInputs([input], changedValues),
      ...onValidate(Object.values(changedValues)[0]),
    };

    setError(errors[item.id]);
    return errors;
  };

  const obfuscateField = field => (
    <Obfuscator
      isEnabled={(input?.isPrivate || input?.isFinancials) && privateModeEnabled}
      label="Hidden field"
      tooltip={PRIVATE_MODE_TOOLTIP_TEXT}
    >
      {field}
    </Obfuscator>
  );

  const renderItem = () => {
    let display = EMPTY_FIELD_VALUE;

    if (item.type === COLOR) {
      return (<div className="color-circle" style={{ backgroundColor: value }} />);
    }

    if (item.type === FIELD_TYPE_BOOLEAN) {
      return obfuscateField(
        <FormControlLabel
          className="formControlLabel"
          control={<Checkbox checked={check} color="primary" disableRipple />}
        />,
      );
    }

    if (!value || value.length === 0) {
      display = emptySelectionText
        ? <span id={AUTOMATION_EMPTY_REQUESTER_TEXT} className="empty-selection-text">{emptySelectionText}</span>
        : EMPTY_FIELD_VALUE;
    } else if (item.type === FIELD_TYPE_MULTI_SELECT) {
      const isRequestersMultiSelect = item.id === AUTOMATION_PROJECT_REQUESTERS_INPUT_ID;
      let sortedValues;

      if (isRequestersMultiSelect) {
        sortedValues = getSortedSelectedRequesterNames(item.options, value);
      } else {
        sortedValues = value.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).join(', ');
      }

      display = sortedValues.length > item.maxLength
        ? (
          <Tooltip title={sortedValues}>
            <span className="tooltip">
              {`${sortedValues.slice(0, item.maxLength)}...`}
            </span>
          </Tooltip>
        )
        : sortedValues;
    } else if (item.type === FIELD_TYPE_CURRENCY) {
      display = item?.hasValueDescription && !item?.values?.length
        ? (
          <>
            {formatDollarValue(value)}
            <i className="value-description">Title default</i>
          </>
        )
        : formatDollarValue(value);
    } else if (item.type === FIELD_TYPE_DATE) {
      display = moment(value).format(DATE_DISPLAY_FORMAT);
    } else if (item.type === FIELD_TYPE_PHONE) {
      display = formatPhoneNumber(value);
    } else if (item.type === FIELD_TYPE_PHONE_E164) {
      display = formatPhoneNumberDisplay(value);
    } else if (item.type === FIELD_TYPE_ADDRESS) {
      const url = getMapsQueryUrl(value);
      display = (
        <Link className="address-link" href={url} target="_blank" rel="noreferrer" onClick={onAddressView}>
          {value}
          <OpenInNew />
        </Link>
      );
    } else if (item.type === 'MultiCheckbox') {
      if (item.id === 'workDays' && Array.isArray(value)) {
        const selectedDays = getSelectedWorkDayValues(value);

        display = formatWorkingDays(selectedDays);
      } else {
        display = value;
      }
    } else {
      display = value;
    }

    return obfuscateField(
      <OverflowTooltip
        isOverflowing={isOverflowing}
        placement="bottom-start"
        text={display}
        enterDelay={1500}
        leaveDelay={1000}
      >
        <Typography ref={displayRef} className="content-item" component="div" id={`${AUTOMATION_CONTENT_ITEM}-${item.name}`}>
          {display}
        </Typography>
      </OverflowTooltip>,
    );
  };

  const disableSave = item.type !== 'Boolean' && ((!!error && error.length > 0) || (item.isRequired && !value) || focused);

  const checkEnter = (e) => {
    if (e.keyCode === 13 && item.name !== 'Other' && !disableSave) {
      handleSave();
    }
  };

  const renderButton = () => {
    const tooltip = FIELDS_WITH_TOOLTIPS[item.name] && showTooltip
      ? (
        <div className="tooltip">
          <HelpTooltip text={FIELDS_WITH_TOOLTIPS[item.name]} />
        </div>
      )
      : null;

    return (
      <button
        type="button"
        className={classNames('common-field-editor action', { disabled })}
        onClick={onEdit}
      >
        <Grid className="field-editor-container" item>
          {showName && (
            <Typography className="content-item content-title" component="span" gutterBottom variant="caption" id={`${AUTOMATION_CONTENT_TITLE}-${item.name}`}>
              {item.name}
              {tooltip}
            </Typography>
          )}
          {renderItem()}
        </Grid>
        <div className="edit-icon">
          <Edit />
        </div>
      </button>
    );
  };

  const renderDisplayMode = () => {
    if (displayOnly) {
      return (
        <div className={classNames('common-field-editor', { disabled })}>
          <Grid className="static-display" item>
            {showName && <Typography className="content-item content-title" component="span" gutterBottom variant="caption" id={`${AUTOMATION_CONTENT_TITLE}-${item.name}`}>{item.name}</Typography>}
            {renderItem()}
          </Grid>
        </div>
      );
    }

    if (!permission) {
      return renderButton();
    }

    return (
      <Can
        action={permission.action}
        subject={permission.subject}
        yes={renderButton()}
        no={(
          <div className={classNames('common-field-editor', { disabled })}>
            <Grid className="static-display" item>
              {showName && <Typography className="content-item content-title" component="span" gutterBottom variant="caption">{item.name}</Typography>}
              {renderItem()}
            </Grid>
          </div>
        )}
      />
    );
  };

  const renderEditMode = () => (
    <ClickAwayListener onClickAway={onClickAway}>
      <div className="common-field-editor editing">
        <ValidatedForm
          inputs={[input]}
          validate={validate}
          onValueChanged={onValueChanged}
          onDateInputFocus={onDateInputFocus}
          onBlur={onBlur}
          errors={{ [item.id]: error }}
          alwaysShowErrors
          onKeyUp={checkEnter}
          onSelectOpen={onSelectOpen}
          onSelectClose={onSelectClose}
        />
        <EditControls
          disabled={disableSave}
          primaryAction={handleSave}
          secondaryAction={cancelEditing}
          pending={pending}
        />
      </div>
    </ClickAwayListener>
  );

  return editing ? renderEditMode() : renderDisplayMode();
};

FieldEditor.propTypes = {
  item: PropTypes.object.isRequired,
  checked: PropTypes.bool,
  input: PropTypes.object,
  onSave: PropTypes.func,
  disabled: PropTypes.bool,
  permission: PropTypes.object,
  pending: PropTypes.bool,
  fieldToEdit: PropTypes.func,
  editing: PropTypes.bool,
  onAddressView: PropTypes.func,
  onValidate: PropTypes.func,
  onCancel: PropTypes.func,
  showName: PropTypes.bool,
  emptySelectionText: PropTypes.string,
  displayOnly: PropTypes.bool,
  showTooltip: PropTypes.bool,
};

FieldEditor.defaultProps = {
  checked: false,
  input: {},
  onSave: () => {},
  disabled: false,
  permission: null,
  pending: false,
  fieldToEdit: () => {},
  editing: false,
  onAddressView: () => {},
  onValidate: () => {},
  onCancel: () => {},
  showName: true,
  emptySelectionText: '',
  displayOnly: false,
  showTooltip: false,
};

export default FieldEditor;
