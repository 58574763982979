import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Tooltip } from '@material-ui/core';

function TodayIndicator({
  startDate,
  endDate,
}) {
  if (moment().isBetween(startDate, endDate)) {
    const projectLength = moment(endDate).diff(startDate, 'days');
    const offset = moment().diff(startDate, 'days');
    const offsetPercent = (offset / projectLength) * 100;
    return (
      <span className="common-today-indicator">
        <div className="today-indicator" style={{ left: `${offsetPercent}%` }} />
        <Tooltip title="TODAY" placement="top" classes={{ popper: 'today-popper' }}>
          <div className="today-indicator-ball" style={{ left: `${offsetPercent}%` }} />
        </Tooltip>
      </span>
    );
  }

  return '';
}

TodayIndicator.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

export default TodayIndicator;
