import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  ClickAwayListener,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Divider,
  Radio,
} from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { CustomPopper } from 'src/features/wrapped-components';
import { buildRolePhaseData } from 'src/features/allocations/utils/roleRequirementUtils';
import { stringToMoment } from 'src/utils/dateUtils';
import { naturalSort } from 'src/utils/sortUtils';
import { getQuickOptionLabel } from './utils/phaseUtils';

export const RolePhaseSelector = ({
  selectedProject,
  updateRole,
  selectedRole,
  manualChange,
  onPhaseSelect,
  className,
  allocatedPercent,
  parentName,
  defaultPhases,
}) => {
  const isSameDates = useMemo(() => {
    const projectStart = stringToMoment(selectedProject.startDate);
    const projectEnd = stringToMoment(selectedProject.endDate);
    const roleStart = stringToMoment(selectedRole.startDate);
    const roleEnd = stringToMoment(selectedRole.endDate);

    return roleStart.isSame(projectStart) && roleEnd.isSame(projectEnd);
  }, [selectedRole.startDate, selectedRole.endDate, selectedProject.startDate, selectedProject.endDate]);

  const [popperOpen, setPopperOpen] = useState(false);
  const [label, setLabel] = useState(getQuickOptionLabel(defaultPhases, isSameDates));
  const [anchorEl, setAnchorEl] = useState(null);
  const [checked, setChecked] = useState(defaultPhases.map(({ id }) => id));
  const [entireProjectChecked, setEntireProjectChecked] = useState(isSameDates && !defaultPhases?.length);

  const sortedPhases = useMemo(() => naturalSort(selectedProject.phases, ['startDate', 'endDate', 'name']), [selectedProject.phases]);

  useEffect(() => {
    if (manualChange) {
      if (isSameDates) {
        setEntireProjectChecked(true);
      } else if (checked.length || entireProjectChecked) {
        setLabel('Custom');
        setChecked([]);
        setEntireProjectChecked(false);
      }
    }
  }, [
    manualChange,
    isSameDates,
    checked.length,
    entireProjectChecked,
  ]);

  useEffect(() => {
    if (entireProjectChecked) {
      setChecked([]);
      setLabel('Entire project');
    }
  }, [entireProjectChecked]);

  const onClick = (evt) => {
    setPopperOpen(true);
    setAnchorEl(evt.currentTarget);
  };

  const onClickAway = () => {
    setPopperOpen(false);

    const phaseNames = selectedProject.phases
      ? selectedProject.phases.reduce((phaseAcc, phase) => {
        if (checked.includes(phase.id)) {
          phaseAcc.push(phase.name);
        }
        return phaseAcc;
      }, [])
      : [];

    // Mixpanel - track quick option interactions
    const mixPanelData = {
      'Project Name': selectedProject.name,
      'Project ID': selectedProject.id,
      'Project status': selectedProject.state,
      'Project type': selectedProject.type,
      'Role name': selectedRole.name,
      'Interacted from': parentName,
      '# of phases selected': checked.length,
      'Phase name(s) selected': phaseNames,
      'Entire project selected': entireProjectChecked,
    };
    window.mixpanel.track('Quick option interactions', mixPanelData);
  };

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    const selectedPhases = sortedPhases.filter(phase => newChecked.includes(phase.id));
    let roleData = buildRolePhaseData(selectedPhases, selectedRole);

    if (!roleData) return;

    if (newChecked.length) {
      const newLabel = getQuickOptionLabel(selectedPhases);
      setLabel(newLabel);
    }

    if (!newChecked.length && !manualChange) {
      const { startDate, endDate } = selectedProject;
      roleData = {
        startDate,
        endDate,
        requirements: [
          {
            startDate,
            endDate,
            allocatedPercent,
          },
        ],
      };
      setEntireProjectChecked(true);
    }
    updateRole(selectedRole.rowId, roleData);
    setChecked(newChecked);
    if (entireProjectChecked) setEntireProjectChecked(false);
    onPhaseSelect();
  };

  const selectEntireProject = () => {
    const { startDate, endDate } = selectedProject;
    setEntireProjectChecked(true);
    updateRole(selectedRole.rowId, {
      startDate,
      endDate,
      requirements: [{ startDate, endDate, allocatedPercent }],
      selectedPhases: [],
    });
  };

  const renderPhaseList = () => (
    sortedPhases.map((phase) => {
      const labelId = `checkbox-list-label-${phase.id}`;
      return (
        <ListItem
          key={phase.id}
          role={undefined}
          button
          onClick={handleToggle(phase.id)}
        >
          <ListItemIcon>
            <Checkbox
              className="phase-checkbox"
              color="primary"
              edge="start"
              checked={checked.includes(phase.id)}
              tabIndex={-1}
              disableRipple
              inputProps={{ 'aria-labelledby': labelId }}
            />
          </ListItemIcon>
          <ListItemText id={labelId} primary={phase.name} />
        </ListItem>
      );
    })
  );

  return (
    <div className={classNames('phases-role-phase-selector', className)}>
      <div
        className="range-selector"
        role="presentation"
        onClick={onClick}
      >
        {label}
        { popperOpen ? <ArrowDropUp /> : <ArrowDropDown /> }
      </div>
      { popperOpen && (
        <CustomPopper
          popperOpen
          anchorEl={anchorEl}
          classes="projects-role-phase-popper"
          placement="bottom-start"
          hideArrow
        >
          <ClickAwayListener onClickAway={onClickAway}>
            <Paper className="content-wrapper" elevation={4}>
              <List className="phase-checklist" disablePadding>
                <ListItem
                  className="entire-project"
                  onClick={selectEntireProject}
                  button
                >
                  <ListItemIcon>
                    <Radio
                      className="phase-checkbox"
                      color="primary"
                      edge="start"
                      checked={entireProjectChecked}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': 'entire-project' }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Entire project" />
                </ListItem>
                <Divider />
                {renderPhaseList()}
              </List>
            </Paper>
          </ClickAwayListener>
        </CustomPopper>
      )}
    </div>
  );
};

RolePhaseSelector.propTypes = {
  selectedProject: PropTypes.object.isRequired,
  updateRole: PropTypes.func.isRequired,
  selectedRole: PropTypes.object.isRequired,
  manualChange: PropTypes.bool,
  onPhaseSelect: PropTypes.func.isRequired,
  className: PropTypes.string,
  allocatedPercent: PropTypes.number,
  parentName: PropTypes.string,
  defaultPhases: PropTypes.array,
};

RolePhaseSelector.defaultProps = {
  manualChange: false,
  className: '',
  allocatedPercent: 100,
  parentName: 'Project List',
  defaultPhases: [],
};

export default RolePhaseSelector;
