import {
  PROJECTS_CLEAR_PROJECT_SELECTION,
} from './constants';

export function clearProjectSelection(selectionId) {
  return {
    type: PROJECTS_CLEAR_PROJECT_SELECTION,
    selectionId,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case PROJECTS_CLEAR_PROJECT_SELECTION: {
      const { selectionId } = action;
      const newState = { ...state };
      delete newState.projectSelections[selectionId];
      return newState;
    }

    default:
      return state;
  }
}
