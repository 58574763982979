import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { ZoomIn, ZoomOut, RotateLeft } from '@material-ui/icons';
import { Button, IconButton } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import AvatarEditor from 'react-avatar-editor';
import { Modal } from '.';

function ImageCrop({
  image,
  saveBlob,
  onClose,
  borderRadius,
  disableBoundaryChecks,
  minScale,
  zoomStep,
  zoomMax,
}) {
  const [scale, setScale] = useState(1);
  const [rotation, setRotation] = useState(0);
  const editor = useRef(null);

  const onZoomOut = () => {
    setScale(scale <= minScale ? minScale : scale - zoomStep);
  };

  const onZoomIn = () => {
    setScale(scale >= zoomMax ? zoomMax : scale + zoomStep);
  };

  const handleZoom = (e, scale) => {
    setScale(Math.round(scale * 10) / 10);
  };

  const onRotate = () => {
    setRotation(rotation <= 0 ? 270 : rotation - 90);
  };

  const onSave = () => {
    if (editor.current) {
      editor.current.getImage().toBlob(saveBlob, image.type);
    }
  };

  return (
    <Modal
      headline="Edit Profile Picture"
      closeModal={onClose}
      classes={{ root: 'common-modal common-image-crop' }}
      buttons={[(
        <Button
          key="save"
          color="default"
          className="save-button"
          disableRipple
          variant="contained"
          size="medium"
          onClick={onSave}
        >
          Save
        </Button>
      )]}
    >
      <div className="edit-wrap">
        <AvatarEditor
          ref={editor}
          width={250}
          height={250}
          borderRadius={borderRadius}
          color={[0, 0, 0, 0.4]}
          image={image}
          scale={scale}
          rotate={rotation}
          disableBoundaryChecks={disableBoundaryChecks}
        />
        <IconButton className="rotate-icon" variant="contained" onClick={onRotate}>
          <RotateLeft aria-label="Required" />
        </IconButton>
      </div>
      <div className="slider-wrap">
        <IconButton className="zoom-icon" disabled={scale <= minScale} variant="contained" onClick={onZoomOut}>
          <ZoomOut aria-label="Required" color={scale <= minScale ? 'disabled' : 'primary'} />
        </IconButton>
        <Slider
          className="slider"
          value={scale}
          min={minScale}
          max={zoomMax}
          step={zoomStep}
          onChange={handleZoom}
        />
        <IconButton className="zoom-icon" disabled={scale >= zoomMax} variant="contained" onClick={onZoomIn}>
          <ZoomIn aria-label="Required" color={scale >= zoomMax ? 'disabled' : 'primary'} />
        </IconButton>
      </div>
      <div className="slider-instructions">Adjust and drag the picture into position.</div>
    </Modal>
  );
}

ImageCrop.propTypes = {
  image: PropTypes.object.isRequired,
  saveBlob: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  borderRadius: PropTypes.number,
  disableBoundaryChecks: PropTypes.bool,
  minScale: PropTypes.number,
  zoomStep: PropTypes.number,
  zoomMax: PropTypes.number,
};

ImageCrop.defaultProps = {
  borderRadius: 150,
  disableBoundaryChecks: false,
  minScale: 1,
  zoomStep: 0.2,
  zoomMax: 6,
};

export default ImageCrop;
