import { call, put, takeLatest } from 'redux-saga/effects';
import {
  LOGIN_USERLOGIN_BEGIN,
  LOGIN_USERLOGIN_SUCCESS,
  LOGIN_USERLOGIN_FAILURE,
  LOGIN_USERLOGIN_DISMISS_ERROR,
} from './constants';
import { login, protectedJsonGet } from '../../../utils/api';
import { setCookie, doJsCookie } from '../../../utils/cookieUtils';
import { RP_AUTH_COOKIE_ID } from '../../../utils/constants';
import { setUserInfo } from '../../../common/analyticsHelper';

export function userLogin(data, useTokenAuth) {
  return {
    type: LOGIN_USERLOGIN_BEGIN,
    data,
    useTokenAuth,
  };
}

export function dismissUserLoginError() {
  return {
    type: LOGIN_USERLOGIN_DISMISS_ERROR,
  };
}

export function* doUserLogin(action) {
  const { data, useTokenAuth } = action;

  let userInfo;
  let permissions;
  let loginData;
  try {
    loginData = yield call(login, data);

    if (doJsCookie(useTokenAuth)) {
      const nextMonth = new Date();
      nextMonth.setMonth(nextMonth.getMonth() + 1);
      setCookie(RP_AUTH_COOKIE_ID, loginData.access_token, nextMonth);
    }

    userInfo = yield call(protectedJsonGet, 'me');
    permissions = yield call(protectedJsonGet, 'me/permissions');
  } catch (err) {
    yield put({
      type: LOGIN_USERLOGIN_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: LOGIN_USERLOGIN_SUCCESS,
    data: {
      login: loginData,
      userInfo: {
        ...userInfo,
        permissions,
      },
    },
  });
}

export function* watchUserLogin() {
  yield takeLatest(LOGIN_USERLOGIN_BEGIN, doUserLogin);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case LOGIN_USERLOGIN_BEGIN:
      return {
        ...state,
        userLoginPending: true,
        userLoginError: null,
      };

    case LOGIN_USERLOGIN_SUCCESS: {
      const { userInfo } = action.data;
      setUserInfo(userInfo);

      return {
        ...state,
        userInfo,
        userLoginPending: false,
        userLoginError: null,
      };
    }

    case LOGIN_USERLOGIN_FAILURE: {
      const { error } = action.data;

      return {
        ...state,
        userLoginPending: false,
        userLoginError: error,
      };
    }

    case LOGIN_USERLOGIN_DISMISS_ERROR:
      return {
        ...state,
        userLoginError: null,
      };

    default:
      return state;
  }
}
