import {
  GANTT_SET_GANTT_CONFIG,
} from './constants';

export function setGanttConfig(instanceKey, updatedProp, localStorageKey = '', newPeopleGanttFlag, analyticsPayload = null) {
  const config = { ...updatedProp };

  // Temporary conversion for 1 day interval now that it is removed. RP-3632
  if (config.scaleValue === 0) {
    config.scaleValue = 1;
  }

  if (localStorageKey !== '' && newPeopleGanttFlag) {
    localStorage.setItem(localStorageKey, Object.values(config)[0]);
  } else if (localStorageKey !== '') {
    localStorage.setItem(localStorageKey, JSON.stringify(Object.values(config)[0]));
  }

  return {
    type: GANTT_SET_GANTT_CONFIG,
    instanceKey,
    updatedProp: config,
    analyticsPayload,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case GANTT_SET_GANTT_CONFIG: {
      const { instanceKey, updatedProp } = action;
      return {
        ...state,
        instances: {
          ...state.instances,
          [instanceKey]: {
            ...state.instances[instanceKey],
            ...updatedProp,
          },
        },
      };
    }
    default:
      return state;
  }
}
