import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNTS_ADD_ROLE_TEMPLATE_BEGIN,
  ACCOUNTS_ADD_ROLE_TEMPLATE_SUCCESS,
  ACCOUNTS_ADD_ROLE_TEMPLATE_FAILURE,
  ACCOUNTS_ADD_ROLE_TEMPLATE_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';

export function addRoleTemplate(accountId, roleTemplate) {
  return {
    type: ACCOUNTS_ADD_ROLE_TEMPLATE_BEGIN,
    accountId,
    roleTemplate,
  };
}

export function dismissAddRoleTemplateError() {
  return {
    type: ACCOUNTS_ADD_ROLE_TEMPLATE_DISMISS_ERROR,
  };
}

export function* doAddRoleTemplate(action) {
  const { accountId, roleTemplate } = action;
  let res;
  try {
    res = yield call(protectedJsonPost, `accounts/${accountId}/role-templates`, roleTemplate);
  } catch (err) {
    yield put({
      type: ACCOUNTS_ADD_ROLE_TEMPLATE_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: ACCOUNTS_ADD_ROLE_TEMPLATE_SUCCESS,
    data: res,
  });
}

export function* watchAddRoleTemplate() {
  yield takeLatest(ACCOUNTS_ADD_ROLE_TEMPLATE_BEGIN, doAddRoleTemplate);
}

export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNTS_ADD_ROLE_TEMPLATE_BEGIN:
      return {
        ...state,
        addRoleTemplatePending: true,
        addRoleTemplateError: null,
      };

    case ACCOUNTS_ADD_ROLE_TEMPLATE_SUCCESS:
      return {
        ...state,
        addRoleTemplatePending: false,
        addRoleTemplateError: null,
        roleTemplates: [...state.roleTemplates, action.data],
      };

    case ACCOUNTS_ADD_ROLE_TEMPLATE_FAILURE:
      return {
        ...state,
        addRoleTemplatePending: false,
        addRoleTemplateError: action.data.error,
      };

    case ACCOUNTS_ADD_ROLE_TEMPLATE_DISMISS_ERROR:
      return {
        ...state,
        addRoleTemplateError: null,
      };

    default:
      return state;
  }
}
