import {
  ACCOUNTS_DEACTIVATE_ACCOUNT_SUCCESS,
  ACCOUNTS_DELETE_LOGO_SUCCESS,
  ACCOUNTS_INVITE_USER_SUCCESS,
  ACCOUNTS_REACTIVATE_ACCOUNT_SUCCESS,
  ACCOUNTS_REINVITE_USER_SUCCESS,
  ACCOUNTS_REMOVE_INVITATION_SUCCESS,
  ACCOUNTS_REMOVE_USER_SUCCESS,
  ACCOUNTS_SET_LOGO_SUCCESS,
  ACCOUNTS_UPDATE_USER_PERMISSIONS_SUCCESS,
  ACCOUNTS_DELETE_ROLE_TEMPLATE_SUCCESS,
  ACCOUNTS_ADD_ROLE_TEMPLATE_SUCCESS,
  ACCOUNTS_UPDATE_ROLE_TEMPLATE_SUCCESS,
  ACCOUNTS_SET_TITLE_COSTS_BEGIN,
} from 'src/features/accounts/redux/constants';

import {
  ACCOUNT_DEACTIVATED,
  ACCOUNT_LOGO_IS_ADDED,
  ACCOUNT_LOGO_IS_DELETED,
  ACCOUNT_REACTIVATED,
  USER_INVITATION_IS_DELETED,
  USER_INVITATION_IS_RE_SENT,
  USER_IS_ADDED,
  USER_IS_DEACTIVATED,
  USER_PERMISSION_GROUP_UPDATED,
  DELETE_ROLE_TEMPLATE,
  CREATE_ROLE_TEMPLATE,
  UPDATE_ROLE_TEMPLATE,
  DEFAULT_COST_RATE_ADDED,
} from './constants';

const events = {
  [ACCOUNTS_DEACTIVATE_ACCOUNT_SUCCESS]: {
    getEvent: () => ACCOUNT_DEACTIVATED,
  },

  [ACCOUNTS_REACTIVATE_ACCOUNT_SUCCESS]: {
    getEvent: () => ACCOUNT_REACTIVATED,
  },

  [ACCOUNTS_SET_LOGO_SUCCESS]: {
    getEvent: () => ACCOUNT_LOGO_IS_ADDED,
    getPayload: (state, action) => {
      const { accounts } = state;
      const { entities } = accounts;
      const { accountId } = action;

      const currentAccount = entities.find(account => account.id === accountId);
      const { name, id } = currentAccount;

      return {
        'Account Name': name,
        'Account id': id,
      };
    },
  },

  [ACCOUNTS_DELETE_LOGO_SUCCESS]: {
    getEvent: () => ACCOUNT_LOGO_IS_DELETED,
    getPayload: (state, action) => {
      const { accounts } = state;
      const { entities } = accounts;
      const { accountId } = action;

      const currentAccount = entities.find(account => account.id === accountId);
      const { name, id } = currentAccount;

      return {
        'Account Name': name,
        'Account id': id,
      };
    },
  },

  [ACCOUNTS_REMOVE_USER_SUCCESS]: {
    getEvent: () => USER_IS_DEACTIVATED,
    getPayload: (state, action) => {
      const { accounts } = state;
      const { users } = accounts;
      const { accountId, userId } = action;

      const removedUser = users[accountId].find(user => user.id === userId);
      const { email, title, group } = removedUser || {};
      return {
        'Deactivated User Email': email,
        'Deactivated User Title': title,
        'Permission Group': group,
      };
    },
  },

  [ACCOUNTS_REMOVE_INVITATION_SUCCESS]: {
    getEvent: () => USER_INVITATION_IS_DELETED,
    getPayload: (state, action) => {
      const { analytics } = action;
      const { email, group } = analytics;
      return {
        'User email': email,
        'Permission Group': group,
      };
    },
  },

  [ACCOUNTS_REINVITE_USER_SUCCESS]: {
    getEvent: () => USER_INVITATION_IS_RE_SENT,
    getPayload: (store, action) => {
      const { data } = action;
      const { email, group } = data;
      return {
        'User email': email,
        'Permission Group': group,
      };
    },
  },

  [ACCOUNTS_INVITE_USER_SUCCESS]: {
    getEvent: () => USER_IS_ADDED,
    getPayload: (store, action) => {
      const { data } = action;
      const { email, group } = data;
      return {
        'Activated User': email,
        'Permission Group': group,
      };
    },
  },

  [ACCOUNTS_UPDATE_USER_PERMISSIONS_SUCCESS]: {
    getEvent: () => USER_PERMISSION_GROUP_UPDATED,
    getPayload: (store, action) => {
      const { group, analytics } = action;
      const { email, userGroup } = analytics;
      return {
        'User impacted': email,
        'Previous Permission Group': userGroup,
        'New Permission Group': group,
      };
    },
  },

  [ACCOUNTS_DELETE_ROLE_TEMPLATE_SUCCESS]: {
    getEvent: () => DELETE_ROLE_TEMPLATE,
    getPayload: (store, action) => {
      const { analyticsPayload: { templateId, templateName } } = action;
      return {
        'Role template id': templateId,
        'Role template name': templateName,
      };
    },
  },

  [ACCOUNTS_ADD_ROLE_TEMPLATE_SUCCESS]: {
    getEvent: () => CREATE_ROLE_TEMPLATE,
    getPayload: (store, action) => {
      const { data: { id, name, data: templateRoles } } = action;
      return {
        'Role template id': id,
        'Role template name': name,
        'Count of roles': templateRoles.length,
      };
    },
  },

  [ACCOUNTS_UPDATE_ROLE_TEMPLATE_SUCCESS]: {
    getEvent: () => UPDATE_ROLE_TEMPLATE,
    getPayload: (store, action) => {
      const { roleTemplate: { name }, templateId } = action;
      return {
        'Role template id': templateId,
        'Role template name': name,
      };
    },
  },

  [ACCOUNTS_SET_TITLE_COSTS_BEGIN]: {
    getEvent: () => DEFAULT_COST_RATE_ADDED,
    getPayload: (store, { analyticsPayload: { title } }) => ({
      Title: title,
    }),
  },
};

export default events;
