import React, { useState, useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  ClickAwayListener,
  Select,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { momentToString } from 'src/utils/dateUtils';
import { validatePercentInput } from 'src/utils/validators';
import { RangeTextField, DateFilter } from '.';
import { CustomPopper, EditControls } from '../wrapped-components';
import {
  ABOVE_FILTER,
  BELOW_FILTER,
  BETWEEN_FILTER,
  EQUALS_FILTER,
  ON_FILTER,
  NO_AVAILABILITY_FILTER,
  AVAILABLE_NOW_FILTER,
  AVAILABILITY_FILTER_LIST_ITEMS,
  CUSTOM,
  PERCENT_FILTER_TYPE,
  DATE_FILTER_TYPE,
} from '../../filters/constants';
import { setPopperIsOpen } from '../filters/redux/actions';
import { generateSelectedFilter } from '../filters/utils/filterUtils';

export function AvailabilityFilterPopper({
  open,
  anchorEl,
  title,
  onApply,
  onCancel,
  placeholder,
  origSelected,
  setPopperIsOpen,
}) {
  const percentFilter = useMemo(() => origSelected.find(({ type }) => type === PERCENT_FILTER_TYPE), [origSelected]);
  const dateFilter = useMemo(() => origSelected.find(({ type }) => type === DATE_FILTER_TYPE), [origSelected]);
  const initialListSelection = useMemo(() => {
    if (!origSelected.length) return null;
    return (percentFilter || dateFilter) ? CUSTOM : origSelected?.[0]?.value;
  }, [dateFilter, origSelected, percentFilter]);
  const initialPercentVerb = percentFilter?.verb || ABOVE_FILTER;
  const initialPercentValue = percentFilter?.value || '';
  const initialPercentSecondaryValue = percentFilter?.verb === BETWEEN_FILTER ? percentFilter?.secondaryValue : '';
  const initialDateVerb = dateFilter?.verb || ON_FILTER;
  const initialDate = dateFilter?.value || null;
  const initialEndDate = dateFilter?.verb === BETWEEN_FILTER ? dateFilter?.secondaryValue : null;
  const [percentVerb, setPercentVerb] = useState(initialPercentVerb);
  const [percentValue, setPercentValue] = useState(initialPercentValue);
  const [percentSecondaryValue, setPercentSecondaryValue] = useState(initialPercentSecondaryValue);
  const [dateVerb, setDateVerb] = useState(initialDateVerb);
  const [date, setDate] = useState(initialDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [listSelection, setListSelection] = useState(initialListSelection);
  const [customOpen, setCustomOpen] = useState(initialListSelection === CUSTOM);
  const [dateError, setDateError] = useState('');
  const [applyDisabled, setApplyDisabled] = useState(true);

  const isDisabled = useCallback(() => {
    if (dateError) return true;
    const selectionChanged = (
      percentValue !== initialPercentValue
      || percentSecondaryValue !== initialPercentSecondaryValue
      || percentVerb !== initialPercentVerb
      || date !== initialDate
      || endDate !== initialEndDate
      || dateVerb !== initialDateVerb
    );
    if (listSelection) {
      if (listSelection !== CUSTOM) return false;
      if (selectionChanged &&
        (((percentVerb === BETWEEN_FILTER && percentValue && percentSecondaryValue)
          || (dateVerb === BETWEEN_FILTER && date && endDate))
          || ((percentVerb !== BETWEEN_FILTER && percentValue)
          || (dateVerb !== BETWEEN_FILTER && date)))) {
        return false;
      }
    }
    return true;
  }, [
    percentValue,
    initialPercentValue,
    percentSecondaryValue,
    initialPercentSecondaryValue,
    percentVerb,
    initialPercentVerb,
    date,
    initialDate,
    endDate,
    initialEndDate,
    dateVerb,
    initialDateVerb,
    listSelection,
    dateError,
  ]);

  useEffect(() => {
    setApplyDisabled(isDisabled());
  }, [
    isDisabled,
    percentVerb,
    percentValue,
    percentSecondaryValue,
    dateVerb,
    date,
    endDate,
    listSelection,
    dateError,
  ]);

  const onVerbChange = (evt) => {
    setPercentVerb(evt.target.value);
  };

  const onPercentFilterChange = isSecondary => (evt) => {
    const newValue = evt.target.value;
    const validatedValue = validatePercentInput(newValue, 0);
    if (!Number.isNaN(validatedValue)) {
      if (isSecondary) {
        setPercentSecondaryValue(newValue);
      } else {
        setPercentValue(newValue);
      }
    }
  };

  const handleCancel = useCallback(() => {
    setPercentVerb(initialPercentVerb);
    setPercentValue(initialPercentVerb);
    setPercentSecondaryValue(initialPercentSecondaryValue);
    setDateVerb(initialDateVerb);
    setDate(initialDate);
    setEndDate(initialEndDate);
    setApplyDisabled(true);
    setPopperIsOpen(false);
    onCancel();
  }, [
    initialDate,
    initialDateVerb,
    initialEndDate,
    initialPercentSecondaryValue,
    initialPercentVerb,
    onCancel,
    setPopperIsOpen,
  ]);

  const onClickAway = () => {
    handleCancel();
  };

  const onFilterApply = useCallback(() => {
    const selected = [];

    if (listSelection === NO_AVAILABILITY_FILTER) {
      selected.push({
        name: 'No availability',
        verb: '',
        value: NO_AVAILABILITY_FILTER,
      });
    } else if (listSelection === AVAILABLE_NOW_FILTER) {
      selected.push({
        name: 'Available now',
        verb: '',
        value: AVAILABLE_NOW_FILTER,
      });
    } else if (listSelection === CUSTOM) {
      const percentFilter = generateSelectedFilter(PERCENT_FILTER_TYPE, percentVerb, percentValue, percentSecondaryValue);
      if (percentFilter) selected.push(percentFilter);
      const dateRangeFilter = generateSelectedFilter(DATE_FILTER_TYPE, dateVerb, date, endDate);
      if (dateRangeFilter) selected.push(dateRangeFilter);
    }

    onApply(selected);
    setApplyDisabled(true);
  }, [date, dateVerb, endDate, listSelection, onApply, percentSecondaryValue, percentValue, percentVerb]);

  const onDateFilterChange = (newDateVerb, newDate, newEndDate) => {
    setDateVerb(newDateVerb);
    setDate(momentToString(newDate));
    setEndDate(momentToString(newEndDate));
  };

  const onSelectListItem = selection => () => {
    setListSelection(selection);
    setCustomOpen(selection === CUSTOM);
  };

  const onDateError = (error = '') => {
    setDateError(error);
  };

  const renderListItems = () => (
    <List>
      { AVAILABILITY_FILTER_LIST_ITEMS.map(({ label, value }) => (
        <ListItem
          key={value}
          classes={{ root: classNames('list-item', { selected: listSelection === value }) }}
          onClick={onSelectListItem(value)}
          button
          divider
          disableRipple
        >
          <ListItemText id={label} className="list-item-text" primary={label} />
        </ListItem>
      ))}
    </List>
  );

  return (
    <CustomPopper
      popperOpen={open}
      anchorEl={anchorEl}
      classes="common-availability-filter-popper"
      placement="top-start"
      hideArrow
      preventBubbling
    >
      <ClickAwayListener onClickAway={onClickAway}>
        <div className="content-wrap">
          <div className="header">
            <div className="title">{title}</div>
          </div>
          { renderListItems() }
          { customOpen && (
            <div className="custom-section">
              <div className="select-wrap">
                <Select
                  MenuProps={{ className: 'range-filter-popper-select' }}
                  value={percentVerb}
                  onChange={onVerbChange}
                  fullWidth
                  native
                >
                  <option className="verb-select" value={ABOVE_FILTER}>Above</option>
                  <option className="verb-select" value={BELOW_FILTER}>Below</option>
                  <option className="verb-select" value={BETWEEN_FILTER}>Between</option>
                  <option className="verb-select" value={EQUALS_FILTER}>Equals</option>
                </Select>
              </div>
              <RangeTextField
                verb={percentVerb}
                placeholder={placeholder}
                value={percentValue}
                secondaryValue={percentSecondaryValue}
                onValueChange={onPercentFilterChange}
                endAdornment="%"
                min={0}
                max={100}
              />
              <DateFilter
                includeBlank={false}
                initialVerb={dateVerb}
                initialDate={date}
                initialEndDate={endDate}
                onChange={onDateFilterChange}
                onError={onDateError}
              />
            </div>
          )}
          <div className="footer">
            <EditControls
              primaryAction={onFilterApply}
              secondaryAction={handleCancel}
              disabled={applyDisabled}
              primaryText="Apply"
              secondaryText="Cancel"
            />
          </div>
        </div>
      </ClickAwayListener>
    </CustomPopper>
  );
}

AvailabilityFilterPopper.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.object,
  title: PropTypes.string.isRequired,
  onApply: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  origSelected: PropTypes.array,
  setPopperIsOpen: PropTypes.func.isRequired,
};

AvailabilityFilterPopper.defaultProps = {
  anchorEl: null,
  placeholder: '',
  origSelected: [],
};

function mapDispatchToProps(dispatch) {
  return {
    setPopperIsOpen: bindActionCreators(setPopperIsOpen, dispatch),
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(AvailabilityFilterPopper);
