import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SplitMenu, MenuListItem } from '@bridgit/foundation';
import { getAccountCertifications } from './redux/actions';
import CertificationDetails from './CertificationDetails';
import CertificationForm from './CertificationForm';
import { isAuthorized } from '../permissions/utils/permissionUtils';
import { PERM_WRITE, PERM_PERSON } from '../permissions/utils/constants';

const Certifications = () => {
  const dispatch = useDispatch();

  const [activeCertificationId, setActiveCertificationId] = useState();
  const [isAdding, setIsAdding] = useState();
  const [isEditing, setIsEditing] = useState();
  const { accountId } = useSelector(({ common }) => common);
  const { permissions } = useSelector(({ login: { userInfo } }) => userInfo);
  const {
    certifications,
    getAccountCertificationsPending,
  } = useSelector(({ accountSettings }) => accountSettings);

  const hasPeoplePerms = useMemo(
    () => isAuthorized(accountId, permissions, PERM_WRITE, PERM_PERSON),
    [accountId, permissions],
  );

  const activeCertification = useMemo(
    () => certifications.find(cert => cert.id === activeCertificationId),
    [activeCertificationId, certifications],
  );

  const isMenuActive = useMemo(
    () => !!activeCertification || isAdding,
    [activeCertification, isAdding],
  );

  const onAdd = useCallback(() => {
    setIsAdding(true);
    setIsEditing(false);
    setActiveCertificationId(null);
  }, []);

  const onEdit = useCallback(() => {
    setIsEditing(true);
  }, []);

  const onClose = useCallback(() => {
    setIsAdding(false);
    setIsEditing(false);
    setActiveCertificationId(null);
  }, []);

  const onEditClose = useCallback(() => {
    setIsEditing(false);
  }, []);

  const onCertificationClick = useCallback(certificationId => () => {
    setIsAdding(false);
    setIsEditing(false);
    setActiveCertificationId(certificationId);
  }, []);

  // Load account certifications on render
  useEffect(() => {
    dispatch(getAccountCertifications(accountId));

    return () => onClose();
  }, [dispatch, accountId, onClose]);

  const renderCertificationListItem = useCallback((certification) => {
    const { id, name } = certification;

    return (
      <MenuListItem
        key={id}
        title={name}
        onClick={onCertificationClick(id)}
        isActive={id === activeCertificationId}
      />
    );
  }, [activeCertificationId, onCertificationClick]);

  const emptyState = useMemo(() => (
    !isAdding && (
      <div className="certifications-empty-state">
        <div className="empty-symbol" />
        <span className="call-to-action-text">Click the blue + button to add your first certification</span>
      </div>
    )
  ), [isAdding]);

  const activeMenu = useMemo(() => (
    !isAdding && !isEditing
      ? (
        <CertificationDetails
          certification={activeCertification}
          onClose={onClose}
          onEdit={onEdit}
        />
      )
      : (
        <CertificationForm
          onClose={onClose}
          onEditClose={onEditClose}
          certification={activeCertification}
        />
      )
  ), [activeCertification, isAdding, onClose, onEdit, isEditing, onEditClose]);

  const certificationCount = useMemo(() => (
    <span className="certification-count">{certifications.length}</span>
  ), [certifications.length]);

  return (
    <SplitMenu
      className="account-settings-certification-list"
      title="Certifications"
      items={certifications}
      renderItems={renderCertificationListItem}
      emptyNode={emptyState}
      activeNode={activeMenu}
      isLoading={getAccountCertificationsPending}
      isMenuActive={isMenuActive}
      onAdd={!hasPeoplePerms ? null : onAdd}
      addTooltipTitle="Add a certification"
      listActiveWidth="16rem"
      headerRightNode={certificationCount}
    />
  );
};

export default Certifications;
