import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Input,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@material-ui/core';
import { EditControls } from '../wrapped-components';

export function AddSubPhaseForm({
  addSubPhase,
  accountId,
  addSubPhasePending,
  close,
  phase,
  setEditingSubPhase,
  phases,
}) {
  const [name, setName] = useState('');
  const [error, setError] = useState(null);

  const validate = (newName) => {
    const isNotUnique = phases.some((phase) => {
      const phaseMatch = phase.name.toLowerCase() === newName;
      const subMatch = phase.subPhases ? phase.subPhases.find(sp => sp.name.toLowerCase() === newName) : false;
      return phaseMatch || subMatch;
    });
    const isEmpty = !newName.length;
    let error = null;

    if (isEmpty) {
      error = 'Please enter a name';
    } else if (isNotUnique) {
      error = 'Sub-phase name must be unique';
    }
    setError(error);
  };

  const isDisabled = () => error !== null || !name.length;

  const handleAddSubPhase = () => {
    addSubPhase(accountId, phase.id, { name: name.trim() });
    setEditingSubPhase(false);
  };

  const handleOnChange = (e) => {
    const name = e.target.value;
    validate(name.trim().toLowerCase());
    setName(name);
  };

  const checkEnter = (e) => {
    const disabled = isDisabled();
    if (e.keyCode === 13 && !disabled) {
      handleAddSubPhase();
    }
  };

  return (
    <div className="account-settings-add-sub-phase-form">
      <FormControl fullWidth>
        <InputLabel shrink>Title</InputLabel>
        <Input
          name="phase-name"
          value={name}
          placeholder="Enter a title for this sub-phase"
          onChange={handleOnChange}
          onKeyUp={checkEnter}
          inputProps={{ maxLength: 100 }}
          fullWidth
          autoFocus
        />
        {error && (
          <FormHelperText className="form__warning">{error}</FormHelperText>
        )}
      </FormControl>
      <EditControls
        primaryText="Save Sub-Phase"
        primaryAction={handleAddSubPhase}
        secondaryAction={close}
        disabled={isDisabled()}
        pending={addSubPhasePending}
      />
    </div>
  );
}

AddSubPhaseForm.propTypes = {
  addSubPhase: PropTypes.func.isRequired,
  accountId: PropTypes.number.isRequired,
  addSubPhasePending: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  phase: PropTypes.object.isRequired,
  setEditingSubPhase: PropTypes.func.isRequired,
  phases: PropTypes.array.isRequired,
};

/* istanbul ignore next */
function mapStateToProps({ common }) {
  return {
    accountId: common.accountId,
  };
}

export default connect(
  mapStateToProps,
)(AddSubPhaseForm);
