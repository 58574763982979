import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PEOPLE_GET_PERSON_ATTACHMENTS_BEGIN,
  PEOPLE_GET_PERSON_ATTACHMENTS_SUCCESS,
  PEOPLE_GET_PERSON_ATTACHMENTS_FAILURE,
  PEOPLE_GET_PERSON_ATTACHMENTS_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';
import { ATTACHMENTS_STATE_QUERY_PARAM } from '../constants';

export function getPersonAttachments(accountId, personId, offset, limit, hasCertificationsModule) {
  return {
    type: PEOPLE_GET_PERSON_ATTACHMENTS_BEGIN,
    accountId,
    personId,
    offset,
    limit,
    hasCertificationsModule,
  };
}

export function dismissGetPersonAttachmentsError() {
  return {
    type: PEOPLE_GET_PERSON_ATTACHMENTS_DISMISS_ERROR,
  };
}

export function* doGetPersonAttachments(action) {
  const { accountId, personId, offset, limit, hasCertificationsModule } = action;
  let response;
  try {
    response = yield call(
      protectedJsonGet,
      `/accounts/${accountId}/persons/${personId}/attachments`,
      {
        offset,
        limit,
        state: ATTACHMENTS_STATE_QUERY_PARAM,
      },
      true,
    );
  } catch (err) {
    yield put({
      type: PEOPLE_GET_PERSON_ATTACHMENTS_FAILURE,
      data: { error: err },
    });
    return;
  }

  const certificationIds = response?.data?.length
    ? [...new Set(response.data.flatMap(attachment => attachment.certificationIds))]
    : [];
  let certificationsResponse;

  if (hasCertificationsModule && certificationIds.length) {
    try {
      certificationsResponse = yield call(
        protectedJsonGet,
        `/accounts/${accountId}/persons/${personId}/certifications?ids=${certificationIds}`,
        {},
        true,
      );
    } catch (err) {
      yield put({
        type: PEOPLE_GET_PERSON_ATTACHMENTS_FAILURE,
        data: { error: err },
      });
      return;
    }
  }

  yield put({
    type: PEOPLE_GET_PERSON_ATTACHMENTS_SUCCESS,
    data: response.data,
    personCertificationsData: certificationsResponse?.data || [],
    hasMoreItems: response.headers?.['query-has-more'] === 'True',
    clearData: offset === 0,
  });
}

export function* watchGetPersonAttachments() {
  yield takeLatest(PEOPLE_GET_PERSON_ATTACHMENTS_BEGIN, doGetPersonAttachments);
}

export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_GET_PERSON_ATTACHMENTS_BEGIN:
      return {
        ...state,
        getPersonAttachmentsPending: true,
        getPersonAttachmentsError: null,
      };

    case PEOPLE_GET_PERSON_ATTACHMENTS_SUCCESS: {
      const { data, clearData, hasMoreItems, personCertificationsData } = action;
      const oldItems = clearData || !state.personAttachments?.items ? [] : state.personAttachments.items;
      const attachmentsWithCertifications = data.map(attachment => ({
        ...attachment,
        certifications: personCertificationsData.filter(({ id }) => attachment.certificationIds.includes(id)),
      }));

      return {
        ...state,
        personAttachments: {
          items: [...oldItems, ...attachmentsWithCertifications],
          hasMore: hasMoreItems,
        },
        getPersonAttachmentsPending: false,
        getPersonAttachmentsError: null,
      };
    }

    case PEOPLE_GET_PERSON_ATTACHMENTS_FAILURE:
      return {
        ...state,
        getPersonAttachmentsPending: false,
        getPersonAttachmentsError: action.data.error,
      };

    case PEOPLE_GET_PERSON_ATTACHMENTS_DISMISS_ERROR:
      return {
        ...state,
        getPersonAttachmentsError: null,
      };

    default:
      return state;
  }
}
