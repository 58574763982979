import React, { PureComponent } from 'react';
import { LinearProgress, Typography, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getPermissionsForAccount } from 'src/features/permissions/utils/permissionUtils';
import { PasswordForm } from '@bridgit/foundation';
import config from '../../common/envConfig';
import { getAccounts } from '../accounts/redux/actions';
import { userLogin, acceptInvitation } from './redux/actions';

const { useTokenAuth } = config;

export class SignupForm extends PureComponent {
  static propTypes = {
    back: PropTypes.func.isRequired,
    urlToken: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    userLoginPending: PropTypes.bool.isRequired,
    getAccountsPending: PropTypes.bool.isRequired,
    acceptInvitationPending: PropTypes.bool.isRequired,
    userInvitationEmail: PropTypes.string.isRequired,
    acceptInvitation: PropTypes.func.isRequired,
    acceptInvitationError: PropTypes.object,
    userLoginError: PropTypes.object,
    permissions: PropTypes.object,
    entities: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAccounts: PropTypes.func.isRequired,
    userLogin: PropTypes.func.isRequired,
    inviteeAccountId: PropTypes.number,
  };

  static defaultProps = {
    userLoginError: null,
    acceptInvitationError: null,
    permissions: null,
    inviteeAccountId: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,
      tmpPassword: '',
      loading: false,
    };
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { userLoginPending, getAccountsPending, acceptInvitationPending, getAccounts, userLogin, history, userInvitationEmail, inviteeAccountId } = this.props;
    const { tmpPassword } = this.state;
    const loginFinished = userLoginPending && !nextProps.userLoginPending;
    const getAccountsFinished = getAccountsPending && !nextProps.getAccountsPending;

    if (acceptInvitationPending && !nextProps.acceptInvitationPending && !nextProps.acceptInvitationError) {
      userLogin({
        email: userInvitationEmail,
        password: tmpPassword,
      }, config.useTokenAuth);
    }

    if (loginFinished && !nextProps.userLoginError) {
      const bridgitPerms = getPermissionsForAccount('*', nextProps.permissions);
      if (bridgitPerms) {
        history.push('/');
      } else {
        getAccounts();
      }
    }

    // Redirect to account they signed up to
    if (getAccountsFinished && nextProps.entities?.length) {
      history.push(`/accounts/${inviteeAccountId || nextProps.entities[0].id}/dashboard`);
    }

    if (nextProps.acceptInvitationError) {
      this.setState({
        loading: false,
      });
    }
  };

  userSignup = ({ password }) => {
    const { urlToken, userInvitationEmail, acceptInvitation } = this.props;

    const data = {
      token: urlToken,
      password,
    };

    this.setState({
      tmpPassword: password,
      loading: true,
    });

    acceptInvitation(data, userInvitationEmail, useTokenAuth);
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  trimSpaces = value => value && value.trim();

  render() {
    const { back, acceptInvitationError, userInvitationEmail } = this.props;
    const { loading } = this.state;
    const bridgitAdminSignedUp = acceptInvitationError?.status === 422;

    return (
      <span className="login-signup-form">
        {loading && (
          <>
            <Typography
              className="typography--300"
              color="primary"
              variant="h6"
              component="h4"
              gutterBottom
            >
              Signing up...
            </Typography>
            <LinearProgress variant="query" />
          </>
        )}

        {bridgitAdminSignedUp &&
          <FormHelperText className="form__warning">Bridgit Admins must signup with Google.</FormHelperText>}

        <PasswordForm
          email={userInvitationEmail}
          showConfirmPassword
          primaryAction={this.userSignup}
          secondaryAction={back}
          header="Create your Account"
          primaryActionLabel="Sign Up"
        />
      </span>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps({ login, accounts, common }) {
  const { userLoginPending, acceptInvitationPending, acceptInvitationError, userLoginError, userInfo: { permissions }, userInvitationData } = login;
  const { getAccountsPending, entities } = accounts;
  const { accountId: inviteeAccountId } = common;

  return {
    userLoginPending,
    acceptInvitationPending,
    acceptInvitationError,
    userLoginError,
    permissions,
    getAccountsPending,
    entities,
    userInvitationEmail: userInvitationData?.email || '',
    inviteeAccountId,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    getAccounts: bindActionCreators(getAccounts, dispatch),
    userLogin: bindActionCreators(userLogin, dispatch),
    acceptInvitation: bindActionCreators(acceptInvitation, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignupForm);
