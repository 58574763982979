import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import { Avatar, ClickAwayListener, IconButton, Tooltip, Button } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { ProjectRoleTitle } from '@bridgit/foundation';
import { sortSegmentsByStartDate } from '../../utils/dateSegmentUtils';
import { ConfirmAllocationChange, AllocationDateEditor, RoleRequirementList } from '../allocations';
import { allocationUpdateMessage } from '../allocations/utils/allocationUtils';
import { validateAllocationUpdate } from '../people/utils/unavailabilityUtils';
import { PERM_WRITE, PERM_ALLOCATION } from '../permissions/utils/constants';
import { CustomPopper, Can } from '../wrapped-components';
import emptyAvatar from '../../images/empty_avatar_blue.svg?url';
import { deleteProjectAllocation } from '../projects/redux/deleteProjectAllocation';
import { updateProjectRoleAllocation } from '../projects/redux/updateProjectRoleAllocation';
import { DATE_INPUT_FORMAT } from '../../common/constants';
import { setSelectedPersonId, trackAnalytics } from '../common/redux/actions';
import { openModal } from '../modal-manager/redux/actions';
import { MULTI_STATE_MODAL_ID } from '../common/redux/constants';
import { PROFILE_DETAILS_MODAL_DISPLAYED } from '../../analytics/dashboard/constants';
import { ALLOCATION_END_DATE_UPDATED, ALLOCATION_START_DATE_UPDATED } from '../../analytics/projects/constants';

export class ExpandedGanttPopper extends PureComponent {
  static propTypes = {
    allocation: PropTypes.object.isRequired,
    accountId: PropTypes.number.isRequired,
    projects: PropTypes.object.isRequired,
    popperOpen: PropTypes.bool.isRequired,
    anchorEl: PropTypes.object,
    onClickAway: PropTypes.func.isRequired,
    setDatePicker: PropTypes.func,
    deleteProjectAllocation: PropTypes.func.isRequired,
    updateProjectRoleAllocation: PropTypes.func.isRequired,
    entities: PropTypes.array,
    unavailabilities: PropTypes.array,
    setSelectedPersonId: PropTypes.func.isRequired,
    trackAnalytics: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    anchorEl: null,
    setDatePicker: () => {},
    entities: [],
    unavailabilities: [],
  }

  constructor(props) {
    super(props);
    const { allocation } = props;

    this.state = {
      loading: false,
      confirmRemove: false,
      confirmUpdate: false,
      confirmUpdateAllocation: null,
      confirmOverlapUpdate: false,
      overlapMessage: null,
      mixpanelPayload: {
        'Project Name': allocation.projectName,
        'Project ID': allocation.projectId,
        'Project status': allocation.projectState,
        'Project type': allocation.projectType,
        'Role Name': allocation.roleName,
        'Role ID': allocation.roleId,
        'Person Name': allocation.text,
        'Person ID': allocation.personId,
      },
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { projects } = nextProps;
    const { loading } = state;
    const nextLoading = !!projects.deleteProjectAllocationPending || !!projects.updateProjectRoleAllocationPending;
    const stateUpdates = {};

    if (loading && !nextLoading) {
      stateUpdates.confirmRemove = false;
      stateUpdates.confirmUpdate = false;
    }

    return {
      ...stateUpdates,
      loading: nextLoading,
    };
  }

  trackButtonClick = () => {
    const { allocation, trackAnalytics } = this.props;

    trackAnalytics(PROFILE_DETAILS_MODAL_DISPLAYED, {
      'Person name': allocation.text,
      'Person id': allocation.personId,
      'Launched from': 'Project Gantt - People Bar (expanded view)',
    });
  }

  handlePopperClick = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
  }

  preventDrag = evt => evt.stopPropagation();

  handleRemove = () => {
    const {
      accountId,
      allocation,
      deleteProjectAllocation,
    } = this.props;
    const { mixpanelPayload } = this.state;

    const data = {
      roleId: allocation.roleId,
      personId: allocation.personId,
      startDate: moment(allocation.startDate).format(DATE_INPUT_FORMAT),
      endDate: moment(allocation.endDate).format(DATE_INPUT_FORMAT),
    };
    const analyticsPayload = {
      ...mixpanelPayload,
      'Allocation deleted from': 'Project Gantt',
    };

    deleteProjectAllocation(accountId, allocation.projectId, data, analyticsPayload);
  }

  handleUpdate = (allocationChangeParam = null) => {
    const { allocation, accountId, updateProjectRoleAllocation, projects } = this.props;
    const { confirmUpdateAllocation, mixpanelPayload } = this.state;
    const existingAllocation = projects.expandedAllocations
      .find(project => project.projectId === allocation.projectId).roles
      .find(role => role.roleId === allocation.roleId).allocations
      .find(al => moment(al.startDate).isSame(allocation.startDate.format(DATE_INPUT_FORMAT)));

    let startDateUpdated = true;
    const analyticsPayload = {
      ...mixpanelPayload,
      'Allocated Person Updated': false,
      'Allocation updated from': 'Project Gantt',
    };
    if (!confirmUpdateAllocation || !confirmUpdateAllocation.othersAffected) {
      startDateUpdated = allocationChangeParam.startDate;
      updateProjectRoleAllocation(accountId, allocation.projectId, allocation.roleId, existingAllocation, allocationChangeParam, {
        ...analyticsPayload,
        [ALLOCATION_START_DATE_UPDATED]: !!startDateUpdated,
        [ALLOCATION_END_DATE_UPDATED]: !startDateUpdated,
      });
    } else {
      const { allocationChange } = confirmUpdateAllocation;
      startDateUpdated = allocationChange.startDate;
      updateProjectRoleAllocation(accountId, allocation.projectId, allocation.roleId, existingAllocation, allocationChange, {
        ...analyticsPayload,
        [ALLOCATION_START_DATE_UPDATED]: !!startDateUpdated,
        [ALLOCATION_END_DATE_UPDATED]: !startDateUpdated,
      });
    }
  }

  buildConfirmObj = () => {
    const { allocation, projects } = this.props;
    const { confirmRemove, confirmUpdate, confirmUpdateAllocation, confirmOverlapUpdate, overlapMessage } = this.state;
    const loading = !!projects.deleteProjectAllocationPending || !!projects.updateProjectRoleAllocationPending;

    if (!confirmRemove && !confirmUpdate && !confirmOverlapUpdate) {
      return {
        open: loading,
        loading,
      };
    }

    const warningMessage = allocationUpdateMessage(confirmOverlapUpdate, confirmRemove, confirmUpdateAllocation, allocation.text, overlapMessage);
    const { primaryActionText, message, subMessage } = warningMessage;

    return {
      open: confirmRemove || confirmUpdate || confirmOverlapUpdate,
      loading,
      primaryActionText,
      primaryAction: confirmRemove ? this.handleRemove : this.handleUpdate,
      secondaryAction: this.closeConfirm,
      message,
      subMessage,
    };
  }

  confirmRemove = () => {
    const { allocation } = this.props;
    if (moment(allocation.startDate).isAfter()) {
      this.handleRemove();
    } else {
      this.setState({ confirmRemove: true });
    }
  }

  confirmUpdate = (newStartDate, newEndDate, changeType) => {
    const { projects, allocation, entities, unavailabilities } = this.props;
    const selectedPerson = entities.find(person => person.id === allocation.personId);
    const pous = unavailabilities.find(person => person.personId === allocation.personId);
    const data = {};
    const { allocations } = projects.expandedAllocations
      .find(project => project.projectId === allocation.projectId).roles
      .find(role => role.roleId === allocation.roleId);
    const sortedRoleAllocations = sortSegmentsByStartDate(allocations);
    const allocationIndex = sortedRoleAllocations.findIndex(a => moment(a.startDate).isSame(allocation.startDate.format(DATE_INPUT_FORMAT)));

    let othersAffected = false;
    const allocationChange = {};
    if (newStartDate) {
      const previousAllocation = sortedRoleAllocations[allocationIndex - 1];
      othersAffected = previousAllocation && (moment(previousAllocation.endDate).isSameOrAfter(newStartDate));
      data.startDate = moment(newStartDate).format(DATE_INPUT_FORMAT);
      data.endDate = moment(allocation.startDate).format(DATE_INPUT_FORMAT);
      allocationChange.startDate = newStartDate;
    }

    if (newEndDate) {
      const nextAllocation = sortedRoleAllocations[allocationIndex + 1];
      othersAffected = nextAllocation && (moment(nextAllocation.startDate).isSameOrBefore(newEndDate));
      data.startDate = moment(allocation.endDate).format(DATE_INPUT_FORMAT);
      data.endDate = moment(newEndDate).format(DATE_INPUT_FORMAT);
      allocationChange.endDate = newEndDate;
    }

    const overlap = validateAllocationUpdate(selectedPerson && selectedPerson.employmentDates, pous && pous.unavailabilities, data);

    if (!othersAffected && !overlap) {
      this.handleUpdate(allocationChange);
    } else if (overlap && overlap.length) {
      this.setState({
        confirmOverlapUpdate: true,
        overlapMessage: overlap,
      });
    } else {
      this.setState({
        confirmUpdate: true,
        confirmUpdateAllocation: {
          changeType,
          allocationChange,
          othersAffected,
        },
      });
    }
  }

  closeConfirm = () => {
    this.setState({
      confirmRemove: false,
      confirmUpdate: false,
      confirmOverlapUpdate: false,
      overlapMessage: null,
    });
  }

  showModal = () => {
    const { allocation, setSelectedPersonId, openModal, onClickAway } = this.props;
    setSelectedPersonId(allocation.personId);
    openModal(MULTI_STATE_MODAL_ID);
    this.trackButtonClick();
    onClickAway();
  }

  renderAvatarLink = () => {
    const { allocation } = this.props;
    return (
      <Button onClick={this.showModal}>
        <Avatar className="avatar" color="primary" src={allocation.photoUrl ? allocation.photoUrl : emptyAvatar} />
      </Button>
    );
  }

  renderAvatar = () => {
    const { allocation } = this.props;
    return allocation.active
      ? (
        <div className="tooltip">
          { this.renderAvatarLink() }
          <div className="tooltiptext">Visit Profile</div>
        </div>
      )
      : <Avatar className="avatar" color="primary" src={allocation.photoUrl} />;
  }

  render() {
    const {
      popperOpen,
      allocation,
      anchorEl,
      onClickAway,
      setDatePicker,
    } = this.props;

    const {
      open,
      loading,
      primaryActionText,
      primaryAction,
      secondaryAction,
      message,
      subMessage,
    } = this.buildConfirmObj();

    return (
      <CustomPopper
        popperOpen={popperOpen}
        anchorEl={anchorEl}
        classes="gantt-expanded-gantt-popper"
        hideArrow
      >
        <ClickAwayListener onClickAway={onClickAway}>
          <div
            className="hover-box"
            onClick={this.handlePopperClick}
            onMouseDown={this.preventDrag}
            role="presentation"
          >
            <div className="header has-hover">
              { this.renderAvatar() }
              <div className="person-info">
                <div className="name">{allocation.text}</div>
                <div className="info">
                  <div className={classNames('title', { deactivated: !allocation.active })}>{allocation.personTitle}</div>
                </div>
              </div>
              <Can
                action={PERM_WRITE}
                subject={PERM_ALLOCATION}
                yes={(
                  <Tooltip title="Remove person" placement="top">
                    <IconButton className="remove-person" onClick={this.confirmRemove}>
                      <Close fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                )}
              />
              <ConfirmAllocationChange
                open={open}
                loading={loading}
                primaryActionText={primaryActionText}
                primaryAction={primaryAction}
                secondaryAction={secondaryAction}
                message={message}
                subMessage={subMessage}
              />
            </div>
            <div className="color-indicator" style={{ backgroundColor: allocation.projectColor }} />
            <div className="project-name">{allocation.projectName}</div>
            <div className="role-bar">
              <ProjectRoleTitle
                className="gantt-popper-role-title"
                roleName={allocation.roleName}
                roleNote={allocation.roleNote}
              />
              <span>{allocation.allocationPercent}</span>
            </div>
            {allocation.allocationPercent === 'Custom' && (
              <RoleRequirementList
                className="requirements-panel"
                roleRequirements={allocation.requirements}
              />
            )}
            <AllocationDateEditor
              allocation={allocation}
              setDatePicker={setDatePicker}
              confirmUpdate={this.confirmUpdate}
            />
          </div>
        </ClickAwayListener>
      </CustomPopper>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps({ common, projects, people }) {
  const { entities, unavailabilities } = people;
  return {
    accountId: common.accountId,
    projects,
    entities,
    unavailabilities,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    deleteProjectAllocation: bindActionCreators(deleteProjectAllocation, dispatch),
    updateProjectRoleAllocation: bindActionCreators(updateProjectRoleAllocation, dispatch),
    setSelectedPersonId: bindActionCreators(setSelectedPersonId, dispatch),
    openModal: bindActionCreators(openModal, dispatch),
    trackAnalytics: bindActionCreators(trackAnalytics, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExpandedGanttPopper);
