import {
  PEOPLE_CLEAR_SEARCHED_ACTIVE_PEOPLE,
} from './constants';
import initialState from './initialState';

export function clearSearchedActivePeople() {
  return {
    type: PEOPLE_CLEAR_SEARCHED_ACTIVE_PEOPLE,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_CLEAR_SEARCHED_ACTIVE_PEOPLE: {
      const { searchedActivePeople } = initialState;
      return {
        ...state,
        searchedActivePeople,
      };
    }

    default:
      return state;
  }
}
