import { Grid, Typography } from '@material-ui/core';
import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import deepEqual from 'react-fast-compare';
import { PERM_WRITE, PERM_PERSON } from 'src/features/permissions/utils/constants';
import { generateDataset, generateInputs } from './utils/profileContactUtils';
import { updatePersonFieldValue } from './redux/actions';
import { Can } from '../wrapped-components';
import { PersonEmailEditor } from '.';
import { FieldEditor } from '../common';
import { PROFILE_DETAILS_MODAL_CONTEXT } from './constants';

export class ProfileContact extends PureComponent {
  static propTypes = {
    person: PropTypes.object.isRequired,
    selectedPersonFields: PropTypes.array.isRequired,
    personFields: PropTypes.arrayOf(PropTypes.object).isRequired,
    onUpdateEmail: PropTypes.func.isRequired,
    updatePersonError: PropTypes.object,
    updatePersonPending: PropTypes.bool.isRequired,
    updatePersonFieldValue: PropTypes.func.isRequired,
    accountId: PropTypes.number.isRequired,
    updatePersonFieldValuePending: PropTypes.bool.isRequired,
    runsInModal: PropTypes.bool,
  };

  static defaultProps = {
    updatePersonError: null,
    runsInModal: false,
  }

  constructor(props) {
    super(props);

    const dataset = generateDataset(props.selectedPersonFields, props.personFields);
    const inputs = generateInputs(dataset);

    this.state = {
      dataset,
      inputs,
      fieldToEdit: null,
      selectedPersonFields: [],
      updatePersonFieldValuePending: false,
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { updatePersonFieldValuePending, selectedPersonFields, personFields } = nextProps;
    let { dataset, inputs, fieldToEdit } = state;

    const updatePersonFieldComplete = state.updatePersonFieldValuePending && !updatePersonFieldValuePending;
    const personFieldsChanged = !deepEqual(state.selectedPersonFields, selectedPersonFields);

    if (updatePersonFieldComplete || personFieldsChanged) {
      dataset = generateDataset(selectedPersonFields, personFields);
      inputs = generateInputs(dataset);
      fieldToEdit = null;
    }

    return {
      inputs,
      dataset,
      fieldToEdit,
      selectedPersonFields,
      updatePersonFieldValuePending,
    };
  }

  onSave = (data) => {
    const { updatePersonFieldValue, accountId, person, runsInModal } = this.props;

    const { name, id, state, isActive } = person;

    const analyticsPayload = {
      personName: name,
      personId: id,
      field: data.name,
      updatedFrom: runsInModal ? PROFILE_DETAILS_MODAL_CONTEXT : 'People List',
      personState: state,
      isActive,
    };

    updatePersonFieldValue(accountId, person.id, data, analyticsPayload);
  }

  fieldToEdit = (id) => {
    this.setState({ fieldToEdit: id });
  }

  render() {
    const { onUpdateEmail, updatePersonError, updatePersonPending, person, updatePersonFieldValuePending } = this.props;
    const { dataset, inputs, fieldToEdit } = this.state;

    return (
      <div className="people-profile-contact">
        <Grid
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          container
          spacing={1}
        >
          <div className="item-wrap">
            <Can
              action={PERM_WRITE}
              subject={PERM_PERSON}
              yes={(
                <PersonEmailEditor
                  originalEmail={person.email}
                  onSave={onUpdateEmail}
                  updatePersonError={updatePersonError}
                  updatePersonPending={updatePersonPending}
                />
              )}
              no={(
                <Grid className="static-display" item>
                  <Typography className="content-item content-title" component="span" gutterBottom variant="caption">Email</Typography>
                  <Typography className="content-item" component="span" gutterBottom>{person.email}</Typography>
                </Grid>
              )}
            />
          </div>
          {dataset.map((field) => {
            const checked =
              field.type === 'Boolean' &&
              field.value &&
              field.value.toLowerCase() === 'true';

            const input = inputs.find(i => parseInt(i.name, 10) === field.id);

            return (
              <div className="item-wrap" key={field.id}>
                <FieldEditor
                  item={field}
                  checked={checked}
                  input={input}
                  onSave={this.onSave}
                  disabled={fieldToEdit !== null && fieldToEdit !== field.id}
                  permission={{ action: PERM_WRITE, subject: PERM_PERSON }}
                  pending={updatePersonFieldValuePending}
                  fieldToEdit={this.fieldToEdit}
                  editing={fieldToEdit === field.id}
                />
              </div>
            );
          })}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = ({ people, common }) => ({
  updatePersonFieldValuePending: people.updatePersonFieldValuePending,
  accountId: common.accountId,
});

const mapDispatchToProps = dispatch => ({
  updatePersonFieldValue: bindActionCreators(updatePersonFieldValue, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileContact);
