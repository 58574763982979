// Bar variant types
const PROJECT_BAR = 'PROJECT_BAR';
const PHASE_BAR = 'PHASE_BAR';
const FILLED_ROLE_BAR = 'FILLED_ROLE_BAR';
const UNFILLED_ROLE_BAR = 'UNFILLED_ROLE_BAR';

// Legend variant types
const PROJECT_LEGEND = 'PROJECT_LEGEND';
const ROLE_LEGEND = 'ROLE_LEGEND';

const THREE_YEARS = 'THREE_YEARS';
const YEAR = 'YEAR';
const MONTH = 'MONTH';
const SIX_MONTHS = 'SIX_MONTHS';

const INTERVALS_MAP = {
  [THREE_YEARS]: '3 years',
  [YEAR]: '1 year',
  [SIX_MONTHS]: '6 months',
  [MONTH]: '1 month',
};

const TIME_INTERVAL_UNIT_MAP = {
  [THREE_YEARS]: YEAR,
  [YEAR]: YEAR,
  [SIX_MONTHS]: MONTH,
  [MONTH]: MONTH,
};

const TIME_MULTIPLIERS_MAP = {
  [THREE_YEARS]: 3,
  [YEAR]: 1,
  [SIX_MONTHS]: 6,
  [MONTH]: 1,
};

const VALID_TIME_INTERVALS = [THREE_YEARS, YEAR, MONTH, SIX_MONTHS];

const SHARE_DATA_QUERY_PARAM = 'share';

export {
  PROJECT_BAR,
  PHASE_BAR,
  FILLED_ROLE_BAR,
  UNFILLED_ROLE_BAR,
  PROJECT_LEGEND,
  ROLE_LEGEND,
  THREE_YEARS,
  YEAR,
  MONTH,
  SIX_MONTHS,
  INTERVALS_MAP,
  TIME_INTERVAL_UNIT_MAP,
  TIME_MULTIPLIERS_MAP,
  VALID_TIME_INTERVALS,
  SHARE_DATA_QUERY_PARAM,
};
