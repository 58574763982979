import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Loader } from '@bridgit/foundation';
import { getUserInfo } from '../login/redux/actions';
import { clearAppData } from '../errors/redux/actions';
import { AUTH_ERROR } from '../errors/redux/constants';

const AuthenticatedView = ({
  children,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    userInfo,
    getUserInfoPending,
    getUserInfoError,
  } = useSelector(({ login }) => login);

  const { error } = useSelector(({ errors }) => errors);

  const [redirecting, setRedirecting] = useState(false);

  const isAuthPending = useMemo(() => (
    (!userInfo?.sub && !getUserInfoError) || getUserInfoPending || redirecting
  ), [userInfo?.sub, getUserInfoError, getUserInfoPending, redirecting]);

  useEffect(() => {
    if (!userInfo?.sub) {
      dispatch(getUserInfo());
    }
  }, [dispatch, userInfo?.sub]);

  useEffect(() => {
    const { messageId } = error || {};

    /*
      Catch 401 unauthorized errors here and redirect to login
        - expired sessions
        - not logged in yet
        - etc
    */
    if (messageId === AUTH_ERROR) {
      setRedirecting(true);

      dispatch(clearAppData());

      /*
      Using react-router-dom methods in the past caused some issues with redirects.
        The code block below can be very "fragile", so in case changes are needed there, handle with care.
      */
      history.push('/login', {
        // set the state in history to track the originally intended destination (URL)
        from: history.location.pathname,
        search: history.location.search,
      });
    }
  }, [dispatch, error, history]);

  if (isAuthPending) return <Loader />;

  return children;
};

AuthenticatedView.propTypes = {
  children: PropTypes.node,
};

AuthenticatedView.defaultProps = {
  children: null,
};

export default AuthenticatedView;
