import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { CapsuleBar } from '../common';
import { getRelativeRangeLength, momentToString } from '../../utils/dateUtils';
import { EDIT_ACTION_COLLAPSED, EDIT_ACTION_EXTENDED, END_DATE, START_DATE } from '../../common/constants';
import { getDiagonalHashStyle } from '../../utils/uiUtils';
import { customPropCheck } from '../../utils/propCheckUtils';
import { getBoundingRangeFromSegments, isValidSegmentArray } from '../../utils/dateSegmentUtils';

function EditorCapsuleContent({
  timelineDates,
  leftText,
  dateUnderEdit,
  editAction,
  primaryColorCode,
  alternateColorCode,
}) {
  const { startDate: timelineStart, endDate: timelineEnd } = getBoundingRangeFromSegments(timelineDates);

  if (!(timelineStart && timelineEnd)) return null;

  const barClasses = classNames('capsule-bar', { 'has-text': !!leftText });

  const leftTextWrap = leftText.length > 0 ? (
    <span className="text">{leftText}</span>
  ) : null;

  /*
    If more than one bar is required, render custom widths to fit. Otherwise, the default
    CapsuleBar behaviour is sufficient.
  */
  let bars = <CapsuleBar className={barClasses} primaryColorCode={primaryColorCode} />;

  if (timelineDates.length > 1) {
    bars = timelineDates.map((segment, index) => {
      const { startDate, endDate } = segment;

      const barWidthPct = getRelativeRangeLength(
        { startDate, endDate },
        { startDate: timelineStart, endDate: timelineEnd },
      );

      let barColor = primaryColorCode;
      const barStyle = { width: `${barWidthPct}%` };

      // Does this segment represent an expand/collapse?
      if ((dateUnderEdit === START_DATE && index === 0)
        || (dateUnderEdit === END_DATE && index === timelineDates.length - 1)) {
        barColor = alternateColorCode;

        if (editAction === EDIT_ACTION_COLLAPSED) {
          barStyle.background = getDiagonalHashStyle(primaryColorCode, alternateColorCode);
        }
      }

      return (
        <CapsuleBar
          className={barClasses}
          key={`${momentToString(startDate)}-${momentToString(endDate)}`}
          primaryColorCode={barColor}
          percentWidth={barWidthPct}
          style={barStyle}
        />
      );
    });
  }

  return (
    <>
      {leftTextWrap}
      {bars}
    </>
  );
}

EditorCapsuleContent.propTypes = {
  timelineDates: customPropCheck(isValidSegmentArray).isRequired,
  leftText: PropTypes.string,
  dateUnderEdit: PropTypes.oneOf([START_DATE, END_DATE]).isRequired,
  editAction: PropTypes.oneOf([EDIT_ACTION_EXTENDED, EDIT_ACTION_COLLAPSED]),
  primaryColorCode: PropTypes.string,
  alternateColorCode: PropTypes.string,
};

EditorCapsuleContent.defaultProps = {
  leftText: '',
  editAction: EDIT_ACTION_EXTENDED,
  primaryColorCode: '',
  alternateColorCode: '',
};

function mapStateToProps({ editDates }) {
  const { dateUnderEdit, editAction } = editDates;
  return {
    dateUnderEdit,
    editAction,
  };
}

export default connect(
  mapStateToProps,
)(EditorCapsuleContent);
