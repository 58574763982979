import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PEOPLE_ADD_PERSON_NOTE_BEGIN,
  PEOPLE_ADD_PERSON_NOTE_SUCCESS,
  PEOPLE_ADD_PERSON_NOTE_FAILURE,
  PEOPLE_ADD_PERSON_NOTE_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';

export function addPersonNote(accountId, personId, analyticsPayload, data) {
  return {
    type: PEOPLE_ADD_PERSON_NOTE_BEGIN,
    accountId,
    personId,
    analyticsPayload,
    data,
  };
}

export function dismissAddPersonNoteError() {
  return {
    type: PEOPLE_ADD_PERSON_NOTE_DISMISS_ERROR,
  };
}

export function* doAddPersonNote(action) {
  const { accountId, personId, analyticsPayload, data } = action;
  let res;
  try {
    res = yield call(protectedJsonPost, `accounts/${accountId}/persons/${personId}/notes`, data);
  } catch (err) {
    yield put({
      type: PEOPLE_ADD_PERSON_NOTE_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PEOPLE_ADD_PERSON_NOTE_SUCCESS,
    data: res,
    personId,
    analyticsPayload,
  });
}

export function* watchAddPersonNote() {
  yield takeLatest(PEOPLE_ADD_PERSON_NOTE_BEGIN, doAddPersonNote);
}

export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_ADD_PERSON_NOTE_BEGIN:
      return {
        ...state,
        addPersonNotePending: true,
        addPersonNoteError: null,
      };

    case PEOPLE_ADD_PERSON_NOTE_SUCCESS: {
      const { personId, data } = action;
      const { personSelections } = state;

      const newPersonSelections = { ...personSelections };
      Object.keys(personSelections).forEach((selectionId) => {
        const selectedPerson = personSelections[selectionId];
        if (selectedPerson && selectedPerson.id === personId) {
          const existingNotes = selectedPerson?.notes || [];
          const updatedNotes = [
            data,
            ...existingNotes,
          ];
          newPersonSelections[selectionId] = {
            ...selectedPerson,
            notes: updatedNotes,
          };
        }
      });

      return {
        ...state,
        addPersonNotePending: false,
        addPersonNoteError: null,
        personSelections: newPersonSelections,
      };
    }

    case PEOPLE_ADD_PERSON_NOTE_FAILURE:
      return {
        ...state,
        addPersonNotePending: false,
        addPersonNoteError: action.data.error,
      };

    case PEOPLE_ADD_PERSON_NOTE_DISMISS_ERROR:
      return {
        ...state,
        addPersonNoteError: null,
      };

    default:
      return state;
  }
}
