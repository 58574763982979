import {
  SETTINGS_REMOVE_ADMIN_SUCCESS,
  SETTINGS_INVITE_ADMIN_SUCCESS,
} from 'src/features/settings/redux/constants';
import { USER_IS_ADDED, USER_IS_DEACTIVATED } from './constants';

const events = {
  [SETTINGS_REMOVE_ADMIN_SUCCESS]: {
    getEvent: () => USER_IS_DEACTIVATED,
    getPayload: (state, action) => {
      const { settings } = state;
      const removedAdmin = settings?.bridgitAdmins?.find(admin => admin.id === action?.data) || {};
      return {
        'Deactivated User Email': removedAdmin ? removedAdmin.email : 'undefined',
        'Deactivated User Title': removedAdmin ? removedAdmin.title : 'undefined',
        'Permission Group': removedAdmin ? removedAdmin.group : 'undefined',
      };
    },
  },
  [SETTINGS_INVITE_ADMIN_SUCCESS]: {
    getEvent: () => USER_IS_ADDED,
    getPayload: (state, action) => ({
      'Permission Group': 'Bridgit Admin',
      'Activated User': action?.data?.email,
    }),
  },
};

export default events;
