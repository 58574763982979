import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@bridgit/foundation';
import { openModal } from 'src/features/modal-manager/redux/actions';
import CopyContentIcon from '../../images/content_copy.svg';
import { resetServiceAccountPassword, copyServiceAccountInfo } from './redux/actions';
import { DELETE_SERVICE_ACCOUNT_MODAL, SERVICE_ACCOUNT_FIELDS } from './constants';
import { DeleteServiceAccountModal } from '.';

const { ID, PASSWORD } = SERVICE_ACCOUNT_FIELDS;

const ServiceAccountDetails = ({ onNextClick }) => {
  const dispatch = useDispatch();

  const { account } = useSelector(({ serviceAccounts }) => serviceAccounts);
  const { accountId } = useSelector(({ common }) => common);

  const { name, group, login, password, id: serviceAccountId } = account;

  const copyToClipboard = useCallback((textToCopy, field) => () => {
    window.navigator.clipboard.writeText(textToCopy);

    const analyticsPayload = { name };

    if (field === ID) {
      dispatch(copyServiceAccountInfo(ID, analyticsPayload));
    }

    if (field === PASSWORD) {
      dispatch(copyServiceAccountInfo(PASSWORD, analyticsPayload));
    }
  }, [dispatch, name]);

  const handleResetPasswordClick = useCallback(() => {
    const analyticsPayload = { name };

    dispatch(resetServiceAccountPassword(accountId, serviceAccountId, analyticsPayload));
  }, [accountId, name, serviceAccountId, dispatch]);

  const handleOpenDeleteServiceAccountModal = useCallback(() => {
    dispatch(openModal(DELETE_SERVICE_ACCOUNT_MODAL));
  }, [dispatch]);

  const renderPassword = () => {
    if (!password) {
      return (
        <Button variant="plain" onClick={handleResetPasswordClick}>
          Reset password
        </Button>
      );
    }

    return (
      <>
        <div className="row">
          <p className="label">Password</p>
          <p className="value">{password}</p>
          <p className="save-warning">
            Make sure you save this password. You will not be able to access it again.
          </p>
        </div>
        <Button
          variant="plain"
          className="copy-button"
          startIcon={<CopyContentIcon />}
          onClick={copyToClipboard(password, SERVICE_ACCOUNT_FIELDS.PASSWORD)}
        >
          Copy
        </Button>
      </>
    );
  };

  const isExistingServiceAccount = account && !account.password;

  return (
    <>
      <DeleteServiceAccountModal onAccountDeleted={onNextClick} />

      {isExistingServiceAccount && (
        <Button
          color="warning"
          variant="plain"
          className="delete-button"
          onClick={handleOpenDeleteServiceAccountModal}
        >
          Delete
        </Button>
      )}

      <div className="add-account-container">
        <div className="name-and-permission">
          <div className="account-name">
            <p className="label">Name</p>
            <p className="value">{name}</p>
          </div>
          <div className="permission-group">
            <p className="label">Permission group</p>
            <p className="value">{group}</p>
          </div>
        </div>
        <div className="account-identifier">
          <div className="row">
            <p className="label">Unique Identifier</p>
            <p className="value">{login}</p>
          </div>
          <Button
            variant="plain"
            className="copy-button"
            startIcon={<CopyContentIcon />}
            onClick={copyToClipboard(login, SERVICE_ACCOUNT_FIELDS.ID)}
          >
            Copy
          </Button>
        </div>
        <div className="account-password">
          {renderPassword()}
        </div>

        <Button
          color="primary"
          variant="plain"
          className="save-button"
          onClick={onNextClick}
        >
          Done
        </Button>
      </div>
    </>
  );
};

ServiceAccountDetails.propTypes = {
  onNextClick: PropTypes.func.isRequired,
};

export default ServiceAccountDetails;
