import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PEOPLE_GET_PERSON_AUDIT_BEGIN,
  PEOPLE_GET_PERSON_AUDIT_SUCCESS,
  PEOPLE_GET_PERSON_AUDIT_FAILURE,
  PEOPLE_GET_PERSON_AUDIT_DISMISS_ERROR,
} from './constants';
import { getPersonAuditQuery } from '../queries/getPersonAuditQuery';
import graphApiClient from '../../../common/GraphAPIClient';

export function getPersonAudit(accountId, personId, from) {
  const { query, variables } = getPersonAuditQuery(accountId, personId, from);
  return {
    type: PEOPLE_GET_PERSON_AUDIT_BEGIN,
    query,
    variables,
  };
}

export function dismissGetPersonAuditError() {
  return {
    type: PEOPLE_GET_PERSON_AUDIT_DISMISS_ERROR,
  };
}

export function* doGetPersonAudit(action) {
  const { query, variables } = action;
  const { from } = variables;

  let res;
  try {
    res = yield call(graphApiClient.query, { query, variables });
  } catch (err) {
    yield put({
      type: PEOPLE_GET_PERSON_AUDIT_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PEOPLE_GET_PERSON_AUDIT_SUCCESS,
    data: res.data,
    clearData: from === 0,
  });
}

export function* watchGetPersonAudit() {
  yield takeLatest(PEOPLE_GET_PERSON_AUDIT_BEGIN, doGetPersonAudit);
}

export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_GET_PERSON_AUDIT_BEGIN:
      return {
        ...state,
        getPersonAuditPending: true,
        getPersonAuditError: null,
      };

    case PEOPLE_GET_PERSON_AUDIT_SUCCESS: {
      const { clearData, data } = action;
      const { personAudit } = data;

      const oldItems = clearData || !state.personAudit?.items ? [] : state.personAudit.items;
      const newItems = personAudit.items;

      return {
        ...state,
        personAudit: {
          ...personAudit,
          items: [...oldItems, ...newItems],
        },
        getPersonAuditPending: false,
        getPersonAuditError: null,
      };
    }

    case PEOPLE_GET_PERSON_AUDIT_FAILURE:
      return {
        ...state,
        getPersonAuditPending: false,
        getPersonAuditError: action.data.error,
      };

    case PEOPLE_GET_PERSON_AUDIT_DISMISS_ERROR:
      return {
        ...state,
        getPersonAuditError: null,
      };

    default:
      return state;
  }
}
