import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PEOPLE_GET_DEACTIVATED_PEOPLE_BEGIN,
  PEOPLE_GET_DEACTIVATED_PEOPLE_SUCCESS,
  PEOPLE_GET_DEACTIVATED_PEOPLE_FAILURE,
  PEOPLE_GET_DEACTIVATED_PEOPLE_DISMISS_ERROR,
} from './constants';
import graphApiClient from '../../../common/GraphAPIClient';
import { getPeopleListQuery } from '../queries/getPeopleListQuery';
import { MAX_SIZE } from '../../../common/constants';

export function getDeactivatedPeople(accountId, queries) {
  const { query, variables } = getPeopleListQuery(accountId, queries, 0, MAX_SIZE, 'Deactivated');
  return {
    type: PEOPLE_GET_DEACTIVATED_PEOPLE_BEGIN,
    query,
    variables,
  };
}

export function dismissGetDeactivatedPeopleError() {
  return {
    type: PEOPLE_GET_DEACTIVATED_PEOPLE_DISMISS_ERROR,
  };
}

export function* doGetDeactivatedPeople(action) {
  const { query, variables } = action;
  let res;
  try {
    res = yield call(graphApiClient.query, { query, variables });
    const { errors } = res;
    if (errors) {
      throw errors;
    }
  } catch (err) {
    yield put({
      type: PEOPLE_GET_DEACTIVATED_PEOPLE_FAILURE,
      data: { error: err },
    });
    return;
  }
  yield put({
    type: PEOPLE_GET_DEACTIVATED_PEOPLE_SUCCESS,
    data: res.data,
  });
}

export function* watchGetDeactivatedPeople() {
  yield takeLatest(PEOPLE_GET_DEACTIVATED_PEOPLE_BEGIN, doGetDeactivatedPeople);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_GET_DEACTIVATED_PEOPLE_BEGIN:
      return {
        ...state,
        getDeactivatedPeoplePending: true,
        getDeactivatedPeopleError: null,
      };

    case PEOPLE_GET_DEACTIVATED_PEOPLE_SUCCESS: {
      const { data: { people: { items: deactivatedPeople } } } = action;
      return {
        ...state,
        deactivatedPeople,
        getDeactivatedPeoplePending: false,
        getDeactivatedPeopleError: null,
      };
    }

    case PEOPLE_GET_DEACTIVATED_PEOPLE_FAILURE:
      return {
        ...state,
        getDeactivatedPeoplePending: false,
        getDeactivatedPeopleError: action.data.error,
      };

    case PEOPLE_GET_DEACTIVATED_PEOPLE_DISMISS_ERROR:
      return {
        ...state,
        getDeactivatedPeopleError: null,
      };

    default:
      return state;
  }
}
