import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNT_SETTINGS_UPDATE_SKILL_SET_BEGIN,
  ACCOUNT_SETTINGS_UPDATE_SKILL_SET_SUCCESS,
  ACCOUNT_SETTINGS_UPDATE_SKILL_SET_SUCCESS_NO_EVENT,
  ACCOUNT_SETTINGS_UPDATE_SKILL_SET_FAILURE,
  ACCOUNT_SETTINGS_UPDATE_SKILL_SET_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';

export function updateSkillSet(accountId, skills, alphabetize, hasUpdateEvent = true) {
  return {
    type: ACCOUNT_SETTINGS_UPDATE_SKILL_SET_BEGIN,
    accountId,
    skills,
    alphabetize,
    hasUpdateEvent,
  };
}

export function dismissUpdateSkillSetError() {
  return {
    type: ACCOUNT_SETTINGS_UPDATE_SKILL_SET_DISMISS_ERROR,
  };
}

export function* doUpdateSkillSet(action) {
  const { accountId, skills, alphabetize, hasUpdateEvent } = action;
  let res;
  try {
    const url = `accounts/${accountId}/skill-set${alphabetize ? '?alphabetize=true' : ''}`;
    res = yield call(protectedJsonPost, url, skills);
  } catch (err) {
    yield put({
      type: ACCOUNT_SETTINGS_UPDATE_SKILL_SET_FAILURE,
      data: { error: err },
    });
    return;
  }

  // Only fire `Custom Field Updated` event on name add / change
  // Do not fire on list re-order / alphabetize
  const type = hasUpdateEvent ?
    ACCOUNT_SETTINGS_UPDATE_SKILL_SET_SUCCESS : ACCOUNT_SETTINGS_UPDATE_SKILL_SET_SUCCESS_NO_EVENT;

  yield put({
    type,
    data: res,
  });
}

export function* watchUpdateSkillSet() {
  yield takeLatest(ACCOUNT_SETTINGS_UPDATE_SKILL_SET_BEGIN, doUpdateSkillSet);
}

export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNT_SETTINGS_UPDATE_SKILL_SET_BEGIN:
      return {
        ...state,
        updateSkillSetPending: true,
        updateSkillSetError: null,
      };

    case ACCOUNT_SETTINGS_UPDATE_SKILL_SET_SUCCESS:
    case ACCOUNT_SETTINGS_UPDATE_SKILL_SET_SUCCESS_NO_EVENT:
      return {
        ...state,
        skillSet: action.data,
        updateSkillSetPending: false,
        updateSkillSetError: null,
      };

    case ACCOUNT_SETTINGS_UPDATE_SKILL_SET_FAILURE:
      return {
        ...state,
        updateSkillSetPending: false,
        updateSkillSetError: action.data.error,
      };

    case ACCOUNT_SETTINGS_UPDATE_SKILL_SET_DISMISS_ERROR:
      return {
        ...state,
        updateSkillSetError: null,
      };

    default:
      return state;
  }
}
