export const PEOPLE_VIEW_SETTINGS_TIME_INTERVAL = [
  {
    name: 1,
    label: '1 Week',
  },
  {
    name: 2,
    label: '1 Month',
  },
  {
    name: 3,
    label: '3 Months',
  },
  {
    name: 4,
    label: '6 Months',
  },
];

export const PEOPLE_VIEW_SETTINGS_DISPLAY_OPTIONS = [
  {
    name: 'title',
    label: 'Title',
  },
  {
    name: 'photo',
    label: 'Photo',
  },
];

export const PEOPLE_VIEW_SETTINGS_FILTER_OPTIONS = [
  {
    name: 'availableFilter',
    label: 'Has future availability',
  },
  {
    name: 'issuesFilter',
    label: 'Has future allocation issue',
  },
];

export const PEOPLE_VIEW_SETTINGS_PROJECTS = 'projects';
export const PEOPLE_VIEW_SETTINGS_UTILIZATION = 'utilization';
export const PEOPLE_VIEW_SETTINGS_DISPLAY_ALL = 'projectsAndUtil';

export const PEOPLE_VIEW_SETTINGS_GANTT_DISPLAY = [
  {
    name: PEOPLE_VIEW_SETTINGS_DISPLAY_ALL,
    label: 'Projects & utilization',
  },
  {
    name: PEOPLE_VIEW_SETTINGS_PROJECTS,
    label: 'Projects',
  },
  {
    name: PEOPLE_VIEW_SETTINGS_UTILIZATION,
    label: 'Utilization',
  },
];
export const PEOPLE_VIEW_SETTINGS_GANTT_DISPLAY_MAP = {
  [PEOPLE_VIEW_SETTINGS_PROJECTS]: 'Projects',
  [PEOPLE_VIEW_SETTINGS_UTILIZATION]: 'Utilization',
  [PEOPLE_VIEW_SETTINGS_DISPLAY_ALL]: 'Projects & utilization',
};

export const PEOPLE_VIEW_SETTINGS_FULL_COLOR = 'full';
export const PEOPLE_VIEW_SETTINGS_LITE_COLOR = 'lite';
export const PEOPLE_VIEW_SETTINGS_COLOR_OPTIONS = [
  {
    name: PEOPLE_VIEW_SETTINGS_FULL_COLOR,
    label: 'Full color',
  },
  {
    name: PEOPLE_VIEW_SETTINGS_LITE_COLOR,
    label: 'Lite color',
  },
];
export const PEOPLE_VIEW_SETTINGS_COLOR_OPTIONS_MAP = {
  [PEOPLE_VIEW_SETTINGS_FULL_COLOR]: 'Full',
  [PEOPLE_VIEW_SETTINGS_LITE_COLOR]: 'Lite',
};
