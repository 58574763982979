import React, { useState, useEffect, useMemo, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Fab, IconButton, Popper, ClickAwayListener, Tooltip } from '@material-ui/core';
import { Info, Add } from '@material-ui/icons';
import {
  PERMISSION_TYPES,
  SELF_PERFORM_SCHEDULER,
  SELF_PERFORM_REQUESTER,
} from 'src/features/permissions/utils/constants';
import { ACCOUNT_MODULE_STANDARD, ACCOUNT_MODULE_FINANCIAL_TRACKING } from 'src/common/constants';
import { Confirm } from 'src/features/common';
import { Can } from 'src/features/wrapped-components';
import { naturalSort } from 'src/utils/sortUtils';
import {
  AccessDenied,
  PermissionGroupDisplay,
  PermissionGroupSave,
} from '.';
import {
  getPermissionGroups,
  createPermissionGroup,
  updatePermissionGroup,
  deletePermissionGroup,
} from './redux/actions';
import { hasModuleEnabled } from './utils/permissionUtils';
import { AUTOMATION_ADD_CUSTOM_GROUP_BUTTON } from './ids';

function PermissionGroups({
  accountId,
  permissionGroups,
  createPermissionGroupPending,
  getPermissionGroups,
  createPermissionGroup,
  updatePermissionGroup,
  deletePermissionGroup,
  accountModules,
}) {
  const [showInfo, setShowInfo] = useState(false);
  const [showDeleteError, setShowDeleteError] = useState(false);
  const [addGroup, setAddGroup] = useState(false);
  const [editGroup, setEditGroup] = useState(null);
  const systemGroups = useMemo(() => naturalSort(
    // hide self-perform permission groups
    permissionGroups.filter(({ isSystem, name }) => isSystem && ![SELF_PERFORM_REQUESTER, SELF_PERFORM_SCHEDULER].includes(name)),
    'name',
  ),
  [permissionGroups]);
  const enabledPermissionTypes = useMemo(() => {
    const isFinancialModuleEnabled = hasModuleEnabled(accountModules, ACCOUNT_MODULE_FINANCIAL_TRACKING);
    return PERMISSION_TYPES.filter(({ value }) => (value === 'ManageFinancials' ? isFinancialModuleEnabled : true));
  }, [accountModules]);
  const customGroups = useMemo(() => naturalSort(permissionGroups.filter(group => !group.isSystem), 'name'), [permissionGroups]);
  const infoRef = useRef(null);

  useEffect(() => { getPermissionGroups(accountId); }, [getPermissionGroups, accountId]);

  const onInfoClick = () => setShowInfo(!showInfo);
  const onAddGroup = () => setAddGroup(true);
  const dismissDeleteError = () => setShowDeleteError(false);

  const onCancel = () => {
    setAddGroup(false);
    setEditGroup(null);
  };

  const parsePerms = permissions => PERMISSION_TYPES.reduce((activePerms, perm) => {
    if (permissions[perm.value]) {
      activePerms.push(perm.value);
    }
    return activePerms;
  }, []);

  const reverseParsePerms = permissions => PERMISSION_TYPES.reduce((activePerms, perm) => ({
    ...activePerms,
    [perm.value]: permissions.includes(perm.value),
  }), {});

  const onSave = (values) => {
    const { name, permissions } = values;
    const data = {
      name: name.trim(),
      permissions: parsePerms(permissions),
    };

    if (addGroup) {
      createPermissionGroup(accountId, data);
    } else if (editGroup?.id) {
      updatePermissionGroup(accountId, editGroup.id, data);
    }

    onCancel();
  };

  const onDelete = () => {
    const { id, name, permissions, members } = editGroup;

    if (members?.length) {
      setShowDeleteError(true);
    } else {
      const analyticsPayload = {
        name,
        permissions: parsePerms(permissions),
      };
      deletePermissionGroup(accountId, id, analyticsPayload);
      onCancel();
    }
  };

  const onGroupClick = (group) => {
    const { id, name, permissions, members } = group;
    setAddGroup(false);
    setEditGroup({
      id,
      name,
      permissions: reverseParsePerms(permissions),
      members,
    });
  };

  return (
    <Can
      module={ACCOUNT_MODULE_STANDARD}
      yes={(
        <div className="permissions-permission-groups">
          <div className="groups">
            <div className="system-header">
              <h1>System Permission Groups</h1>
              <div className="perm-info-container" ref={infoRef}>
                <Tooltip title="More information" placement="top">
                  <IconButton className="permissions-info" disableRipple variant="contained" onClick={onInfoClick}>
                    <Info aria-label="Permissions Info" />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <PermissionGroupDisplay
              groups={systemGroups}
              isSystem
              permissionTypes={enabledPermissionTypes}
            />
          </div>

          <div className="groups">
            <div className="custom-group-container">
              <div className="section">
                <h1>Custom Permission Groups</h1>
                <PermissionGroupDisplay
                  groups={customGroups}
                  hidePerms={!!(addGroup || editGroup)}
                  onClick={onGroupClick}
                  activeGroup={editGroup}
                  permissionTypes={enabledPermissionTypes}
                />
              </div>
              <PermissionGroupSave
                className="section save-container"
                originalValues={editGroup}
                onSave={onSave}
                onCancel={onCancel}
                onDelete={onDelete}
                pending={createPermissionGroupPending}
                show={!!(addGroup || editGroup)}
                permissionTypes={enabledPermissionTypes}
              />
            </div>
            {!addGroup && !editGroup && (
              <div className="add-permission-group">
                <Fab id={AUTOMATION_ADD_CUSTOM_GROUP_BUTTON} onClick={onAddGroup} aria-label="Add Permission Group">
                  <Add />
                </Fab>
              </div>
            )}
          </div>

          <Popper
            className="info-popper"
            open={showInfo}
            anchorEl={infoRef.current}
            placement="left-start"
            disablePortal
          >
            <ClickAwayListener onClickAway={onInfoClick}>
              <div className="popper-container">
                <h2>Manage Settings</h2>
                <p>View and update account settings, custom fields, users, and permissions. Only Administrators will have this permission.</p>
                {enabledPermissionTypes.map(type => (
                  <Fragment key={type.value}>
                    <h2>{type.label}</h2>
                    <p>{type.description()}</p>
                  </Fragment>
                ))}
              </div>
            </ClickAwayListener>
          </Popper>

          {showDeleteError && (
            <Confirm
              headline="Delete Permission Group"
              acceptButtonText="Okay"
              onAccept={dismissDeleteError}
              showSecondary={false}
            >
              <p>
                {`This permission group cannot be deleted because it is currently assigned to ${
                  editGroup.members.length > 1 ? 'users' : 'a user'
                }.`}
              </p>
            </Confirm>
          )}
        </div>
      )}
      no={<AccessDenied />}
    />
  );
}

PermissionGroups.propTypes = {
  accountId: PropTypes.number.isRequired,
  permissionGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  accountModules: PropTypes.arrayOf(PropTypes.object).isRequired,
  createPermissionGroupPending: PropTypes.bool,
  getPermissionGroups: PropTypes.func.isRequired,
  createPermissionGroup: PropTypes.func.isRequired,
  updatePermissionGroup: PropTypes.func.isRequired,
  deletePermissionGroup: PropTypes.func.isRequired,
};

PermissionGroups.defaultProps = {
  createPermissionGroupPending: false,
};

/* istanbul ignore next */
function mapStateToProps({ common, permissions, accountSettings }) {
  const { accountId } = common;
  const { permissionGroups, createPermissionGroupPending } = permissions;
  const { accountModules } = accountSettings;
  return {
    accountId,
    permissionGroups,
    createPermissionGroupPending,
    accountModules,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    getPermissionGroups: bindActionCreators(getPermissionGroups, dispatch),
    createPermissionGroup: bindActionCreators(createPermissionGroup, dispatch),
    updatePermissionGroup: bindActionCreators(updatePermissionGroup, dispatch),
    deletePermissionGroup: bindActionCreators(deletePermissionGroup, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PermissionGroups);
