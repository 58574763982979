import { microsoftIcon, googleIcon, autodeskIcon, procoreIcon } from 'src/images/index';

export const authBasePath = `${window.location.origin}/auth`;

export const OAUTH_BUTTONS = ['microsoft', 'google', 'autodesk', 'procore'];

export const ICON_MAP = {
  microsoft: microsoftIcon,
  google: googleIcon,
  autodesk: autodeskIcon,
  procore: procoreIcon,
};

export const OAUTH_URL_MAP = {
  microsoft: `${authBasePath}/azure/signin`,
  google: `${authBasePath}/google/clientsignin`,
  autodesk: `${authBasePath}/autodesk/signin`,
  procore: `${authBasePath}/procore/signin`,
};

// Teams
export const OTHER = 'Other';
