import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PEOPLE_GET_UPLOAD_URL_BEGIN,
  PEOPLE_GET_UPLOAD_URL_SUCCESS,
  PEOPLE_GET_UPLOAD_URL_FAILURE,
  PEOPLE_GET_UPLOAD_URL_DISMISS_ERROR,
} from './constants';
import { protectedJsonPut } from '../../../utils/api';

export function getUploadUrl(accountId, personId, cb) {
  return {
    type: PEOPLE_GET_UPLOAD_URL_BEGIN,
    accountId,
    personId,
    cb,
  };
}

export function dismissGetUploadUrlError() {
  return {
    type: PEOPLE_GET_UPLOAD_URL_DISMISS_ERROR,
  };
}

export function* doGetUploadUrl(action) {
  const { accountId, personId, cb } = action;
  let res;
  try {
    res = yield call(protectedJsonPut, `accounts/${accountId}/persons/${personId}/profile-picture`, null);
  } catch (err) {
    yield put({
      type: PEOPLE_GET_UPLOAD_URL_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PEOPLE_GET_UPLOAD_URL_SUCCESS,
    data: res,
  });

  if (cb) {
    cb(res.ImageUrl);
  }
}

export function* watchGetUploadUrl() {
  yield takeLatest(PEOPLE_GET_UPLOAD_URL_BEGIN, doGetUploadUrl);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_GET_UPLOAD_URL_BEGIN:
      return {
        ...state,
        getUploadUrlPending: true,
        getUploadUrlError: null,
      };

    case PEOPLE_GET_UPLOAD_URL_SUCCESS:
      return {
        ...state,
        getUploadUrlPending: false,
        getUploadUrlError: null,
      };

    case PEOPLE_GET_UPLOAD_URL_FAILURE:
      return {
        ...state,
        getUploadUrlPending: false,
        getUploadUrlError: action.data.error,
      };

    case PEOPLE_GET_UPLOAD_URL_DISMISS_ERROR:
      return {
        ...state,
        getUploadUrlError: null,
      };

    default:
      return state;
  }
}
