import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  FormControl,
  ClickAwayListener,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from '@material-ui/core';
import { Button, RadioChipForm } from '@bridgit/foundation';
import { momentToString } from 'src/utils/dateUtils';
import {
  TABLOID_PAPER,
  LETTER_PAPER,
} from './redux/constants';
import {
  MIN_API_CONFIGURABLE_DATE,
  MIN_API_CONFIGURABLE_DATE_MESSAGE,
  DATE_INPUT_FORMAT,
} from '../../common/constants';
import { DatePicker } from '../common';
import { MONTH, SIX_MONTHS, YEAR, THREE_YEARS } from '../gantt/constants';

const DefaultGanttFileExportMenu = ({
  menuTitle,
  onClose,
  onPrint,
  initialTimeInterval,
  renderAdditionalOptions,
}) => {
  const today = new Date();
  const formattedToday = moment(today).startOf('month').format(DATE_INPUT_FORMAT);
  const [selectedDate, setSelectedDate] = useState(formattedToday);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [timelineLength, setTimelineLength] = useState(initialTimeInterval ?? YEAR);
  const [paperSize, setPaperSize] = useState(TABLOID_PAPER);

  const handleDatePickerOpen = () => {
    setIsDatePickerOpen(true);
  };

  const handleDatePickerClose = () => {
    setIsDatePickerOpen(false);
  };

  const handleClickAway = () => {
    if (isDatePickerOpen) return;
    onClose();
  };

  const handleDateChange = (date) => {
    setSelectedDate(momentToString(date));
  };

  const handleTimelineLengthChange = (value) => {
    setTimelineLength(value);
  };

  const handlePaperSizeChange = (event) => {
    const { target: { value } } = event;
    setPaperSize(value);
  };

  const printHandler = useCallback(() => {
    onPrint(selectedDate, timelineLength, paperSize);
  }, [onPrint, paperSize, selectedDate, timelineLength]);

  const renderTimelineLengthSelect = () => {
    const controls = [{
      name: MONTH,
      label: '1 Month',
    }, {
      name: SIX_MONTHS,
      label: '6 Months',
    }, {
      name: YEAR,
      label: '1 Year',
    }, {
      name: THREE_YEARS,
      label: '3 Years',
    }];

    return (
      <FormControl component="fieldset">
        <span className="control-label time-interval">Timeline</span>
        <RadioChipForm
          controls={controls}
          defaultValue={timelineLength}
          onChange={handleTimelineLengthChange}
        />
      </FormControl>
    );
  };

  const renderDatePicker = () => {
    const views = [YEAR.toLowerCase(), MONTH.toLowerCase()];
    const format = 'MMM YYYY';
    return (
      <FormControl component="fieldset">
        <span className="control-label">
          Earliest month displayed
        </span>
        <div className="date-wrapper">
          <DatePicker
            onClose={handleDatePickerClose}
            onOpen={handleDatePickerOpen}
            onChange={handleDateChange}
            date={selectedDate}
            className="date-picker"
            minDate={moment(MIN_API_CONFIGURABLE_DATE)}
            minDateMessage={MIN_API_CONFIGURABLE_DATE_MESSAGE}
            views={views}
            keyboard={false}
            format={format}
            variant="inline"
            openTo="month"
          />
        </div>
      </FormControl>
    );
  };

  const renderPaperSizeOptimizationRadio = () => {
    const tabloidLabel = (
      <div className="radio-label">
        <div>Optimize for Tabloid size paper</div>
        <Typography color="textSecondary">11”x17”</Typography>
      </div>
    );
    const letterLabel = (
      <div className="radio-label">
        <div>Optimize for Letter size paper</div>
        <Typography color="textSecondary">8.5”x11”</Typography>
      </div>
    );
    return (
      <FormControl component="fieldset">
        <RadioGroup aria-label="Paper size optimization" name="paperSizeOptimizationRadio" value={paperSize} onChange={handlePaperSizeChange}>
          <FormControlLabel
            value={TABLOID_PAPER}
            control={<Radio color="primary" />}
            label={tabloidLabel}
            labelPlacement="end"
          />
          <FormControlLabel
            value={LETTER_PAPER}
            control={<Radio color="primary" />}
            label={letterLabel}
            labelPlacement="end"
          />
        </RadioGroup>
      </FormControl>
    );
  };

  const renderExportButton = () => (
    <FormControl component="fieldset" className="print-button">
      <Button
        color="primary"
        variant="plain"
        onClick={printHandler}
        disabled={!selectedDate}
      >
        Export
      </Button>
    </FormControl>
  );

  return (
    <div className="reports-default-gantt-file-export-menu">
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className="content-wrap">
          <div role="presentation" onClick={handleClickAway} className="click-listener" />
          <div className="title">{menuTitle}</div>
          <form className="form">
            {renderTimelineLengthSelect()}
            {renderDatePicker()}
            {renderPaperSizeOptimizationRadio()}
            {renderAdditionalOptions()}
            {renderExportButton()}
          </form>
        </div>
      </ClickAwayListener>
    </div>
  );
};

DefaultGanttFileExportMenu.propTypes = {
  menuTitle: PropTypes.string,
  onClose: PropTypes.func,
  onPrint: PropTypes.func,
  initialTimeInterval: PropTypes.string,
  renderAdditionalOptions: PropTypes.func,
};

DefaultGanttFileExportMenu.defaultProps = {
  menuTitle: '',
  onClose: () => {},
  onPrint: () => {},
  initialTimeInterval: YEAR,
  renderAdditionalOptions: () => {},
};

export default DefaultGanttFileExportMenu;
