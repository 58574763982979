import {
  PEOPLE_CLEAR_ALL_PERSON_SELECTIONS,
} from './constants';
import initialState from './initialState';

export function clearAllPersonSelections() {
  return {
    type: PEOPLE_CLEAR_ALL_PERSON_SELECTIONS,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_CLEAR_ALL_PERSON_SELECTIONS: {
      const { personSelections } = initialState;
      return {
        ...state,
        personSelections,
      };
    }

    default:
      return state;
  }
}
