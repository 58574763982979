import React from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener } from '@material-ui/core';
import { CustomPopper } from '../wrapped-components';
import { EditProjectRole, ProjectStatus } from '.';

const ProjectRoleEditPanel = ({
  open,
  anchorEl,
  onClickAway,
  project,
  role,
}) => {
  if (!project || !role) return null;

  const { id: projectId, colour, name } = project;
  const { id: roleId } = role;

  return (
    <CustomPopper
      popperOpen={open}
      anchorEl={anchorEl}
      classes="project-role-edit-panel"
      placement="right"
      hideArrow
    >
      <ClickAwayListener onClickAway={onClickAway}>
        <div className="edit-role-popper">
          <div className="role-popper-header">
            <div className="color-indicator" style={{ backgroundColor: colour }} />
            <div className="project-name">{name}</div>
            <div className="info">
              <ProjectStatus project={project} />
            </div>
          </div>
          <EditProjectRole
            key={`${projectId}-${roleId}`} // EditProjectRole is not a controlled component, need to force remount if project/role change
            project={project}
            role={role}
            onRoleDeleted={onClickAway}
          />
        </div>
      </ClickAwayListener>
    </CustomPopper>
  );
};

ProjectRoleEditPanel.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  onClickAway: PropTypes.func,
  project: PropTypes.shape({
    id: PropTypes.number,
    colour: PropTypes.string,
    name: PropTypes.string,
  }),
  role: PropTypes.object,
};

ProjectRoleEditPanel.defaultProps = {
  open: false,
  anchorEl: null,
  onClickAway: () => {},
  project: null,
  role: null,
};

export default ProjectRoleEditPanel;
