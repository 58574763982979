import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { OverflowTooltip, useIsRefOverflowing } from '@bridgit/foundation';
import {
  Button,
  Grid,
  Tabs,
  Tab,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { PERM_WRITE, PERM_PROJECT } from 'src/features/permissions/utils/constants';
import {
  ProjectDetailTab,
  ShiftProjectDates,
  ProjectDates,
  ProjectPhases,
  ProjectMoreActions,
  ProjectTypeEditor,
  ProjectNotesContainer,
} from '.';
import { Can } from '../wrapped-components';
import { trackAnalytics } from '../common/redux/actions';
import {
  PROJECT_DETAILS_TAB_CLICKED,
  PROJECT_VIEW_PROJECT_NOTES,
} from '../../analytics/projects/constants';

export const ProjectPanel = ({
  project,
  onClose,
}) => {
  const dispatch = useDispatch();

  const { projectFieldValues } = useSelector(({ projects }) => projects);
  const { projectFields } = useSelector(({ accountSettings }) => accountSettings);

  const [activeTab, setActiveTab] = useState(0);
  const { ref: projectNameRef, isOverflowing } = useIsRefOverflowing();

  if (project === null) return null;

  const selectedProjectFields = projectFieldValues && projectFieldValues.length
    ? projectFieldValues.find(f => f.projectId === project.id)
    : [];

  const handleTabChange = (event, tab) => {
    const analyticsPayload = {
      'Project Name': project.name,
      'Project ID': project.id,
      'Project status': project.state,
      'Project type': project.type,
    };

    if (tab === 1) {
      dispatch(trackAnalytics(PROJECT_DETAILS_TAB_CLICKED, { ...analyticsPayload, 'Clicked from': 'Project Gantt' }));
    }

    if (tab === 2) {
      dispatch(trackAnalytics(PROJECT_VIEW_PROJECT_NOTES, { ...analyticsPayload, 'Viewed from': 'Gantt slider' }));
    }
    setActiveTab(tab);
  };

  const renderContent = () => {
    switch (activeTab) {
      case 0:
        return (
          <div className="project-panel-dates">
            <ProjectDates
              project={project}
              parentName="Project Gantt Panel"
            />
            <Can
              action={PERM_WRITE}
              subject={PERM_PROJECT}
              yes={(
                <ShiftProjectDates
                  project={project}
                  parentName="Project Gantt Panel"
                />
              )}
            />
            <ProjectPhases
              project={project}
              parentName="Project Gantt"
            />
          </div>
        );
      case 1:
        return (
          <div className="project-panel-details">
            <ProjectDetailTab
              project={project}
              projectFields={projectFields}
              selectedProjectFields={selectedProjectFields && selectedProjectFields.fields ? selectedProjectFields.fields : []}
              projectColor={project.colour}
              parentName="Project Gantt"
            />
          </div>
        );
      case 2:
        return (
          <div className="project-panel-notes">
            <ProjectNotesContainer
              projectId={project.id}
              projectName={project?.name}
              projectType={project?.type}
              projectStatus={project?.state}
              projectNotes={project?.notes}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="projects-project-panel">
      <div className="top-section">
        <div className="color-indicator" style={{ backgroundColor: project.colour }} />
        <OverflowTooltip
          text={project.name}
          isOverflowing={isOverflowing}
          enterDelay={1500}
          leaveDelay={1000}
        >
          <div className="project-name" ref={projectNameRef}>{project.name}</div>
        </OverflowTooltip>
        <Button
          aria-label="Close"
          classes={{ root: 'icon__close' }}
          disableRipple
          onClick={onClose}
        >
          <Close />
        </Button>
        <div className="project-actions">
          <ProjectTypeEditor selectedProject={project} />
          <Can
            action={PERM_WRITE}
            subject={PERM_PROJECT}
            yes={(
              <ProjectMoreActions
                selectedProject={project}
                onDelete={onClose}
              />
            )}
          />
        </div>
        <div className="tabs-wrapper">
          <Grid
            className="tabs-grid"
            direction="row"
            justify="space-between"
            alignItems="center"
            container
          >
            <Grid item>
              <Tabs
                className="tabs"
                value={activeTab}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                onChange={handleTabChange}
              >
                <Tab label="Dates" />
                <Tab label="Details" />
                <Tab label="Notes" />
              </Tabs>
            </Grid>
          </Grid>
        </div>
      </div>
      {renderContent()}
    </div>
  );
};

ProjectPanel.propTypes = {
  project: PropTypes.object,
  onClose: PropTypes.func,
};

ProjectPanel.defaultProps = {
  project: null,
  onClose: () => {},
};

export default ProjectPanel;
