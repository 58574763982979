import {
  MODAL_MANAGER_CLOSE_MODAL,
} from './constants';

export function closeModal() {
  return {
    type: MODAL_MANAGER_CLOSE_MODAL,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case MODAL_MANAGER_CLOSE_MODAL:
      return {
        ...state,
        activeModal: null,
      };

    default:
      return state;
  }
}
