import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

export default function SegmentLabel({
  primaryText,
  secondaryText,
}) {
  return (
    <div className="edit-dates-segment-label">
      <span className="primary-text">{primaryText}</span>
      { secondaryText?.length > 0 && (
        <Tooltip title={secondaryText} placement="top">
          <span className="secondary-text">{secondaryText}</span>
        </Tooltip>
      )}
    </div>
  );
}

SegmentLabel.propTypes = {
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string,
};

SegmentLabel.defaultProps = {
  secondaryText: '',
};
