import React from 'react';
import {
  Build,
  Domain,
  Person,
  Dashboard,
  Assignment,
} from '@material-ui/icons';
import { Badge } from '@material-ui/core';
import {
  PERM_READ,
  PERM_ROLE,
  PERM_PERSON,
  PERM_PROJECT,
  PERM_HOURLY_PERSON,
  PERM_REQUEST,
  PERM_ASSIGNMENT,
} from 'src/features/permissions/utils/constants';
import { ACCOUNT_MODULE_HOURLY, ACCOUNT_MODULE_STANDARD } from 'src/common/constants';

export const NAV_ITEMS = [
  {
    path: 'dashboard',
    component: <Dashboard />,
    title: 'Dashboard',
    action: PERM_READ,
    subject: PERM_PERSON,
  },
  {
    path: 'people',
    component: <Person />,
    title: 'People',
    action: PERM_READ,
    subject: [PERM_PERSON, PERM_HOURLY_PERSON],
  },
  {
    path: 'projects',
    component: <Domain />,
    title: 'Projects',
    action: PERM_READ,
    subject: PERM_PROJECT,
  },
  {
    path: 'roles',
    component: <Badge classes={{ badge: 'badge-new' }} badgeContent="New!"><Assignment /></Badge>,
    title: 'Unfilled Roles',
    action: PERM_READ,
    subject: PERM_ROLE,
    module: ACCOUNT_MODULE_STANDARD,
  },
  {
    path: 'self-perform',
    component: <Build />,
    title: 'Self-Perform',
    action: PERM_READ,
    subject: [PERM_REQUEST, PERM_ASSIGNMENT],
    module: ACCOUNT_MODULE_HOURLY,
  },
];

export const SUPPORTED_BROWSERS = [
  {
    name: 'Chrome',
    url: 'https://www.google.com/chrome/',
    src: 'https://bridgit-static.s3.ca-central-1.amazonaws.com/images/bench/web-client/chrome.png',
  },
  {
    name: 'Safari',
    url: 'https://support.apple.com/downloads/safari',
    src: 'https://bridgit-static.s3.ca-central-1.amazonaws.com/images/bench/web-client/safari.png',
  },
  {
    name: 'Firefox',
    url: 'https://www.mozilla.org/',
    src: 'https://bridgit-static.s3.ca-central-1.amazonaws.com/images/bench/web-client/firefox.png',
  },
];

export const BRIDGIT_APP_ICON = 'https://bridgit-static.s3.ca-central-1.amazonaws.com/images/bench/web-client/app_icon.svg';
export const BRIDGIT_ICON_WHITE = 'https://bridgit-static.s3.ca-central-1.amazonaws.com/images/bench/web-client/icon_white.svg';
export const BRIDGIT_LOGO = 'https://bridgit-static.s3.ca-central-1.amazonaws.com/images/bench/web-client/logo_deepgreen.svg';

// when navigating to these urls, we first hit these urls then redirect to /list or /gantt
export const BLACKLIST_DUPLICATE_MIXPANEL_URLS = [
  '/people',
  '/projects',
  '/dashboard',
];

// we don't want to log these urls in the url change event, but we do want to keep them as previous urls
export const BLACKLIST_MIXPANEL_URLS = [
  '/login',
  '/forgot-password',
  '/signup',
  '/auth/google/clientsignin',
  '/auth/azure/signin',
  '/auth/autodesk/signin',
  '/auth/procore/signin',
];

export const LIGHT = 'light';
