import { call, put, takeLatest } from 'redux-saga/effects';
import {
  SELF_PERFORM_REMOVE_ASSIGNEE_BEGIN,
  SELF_PERFORM_REMOVE_ASSIGNEE_SUCCESS,
  SELF_PERFORM_NOTIFICATION_SENT_SUCCESS,
  SELF_PERFORM_REMOVE_ASSIGNEE_FAILURE,
  SELF_PERFORM_REMOVE_ASSIGNEE_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';

export function removeAssignee(accountId, request, message, mixpanelData) {
  return {
    type: SELF_PERFORM_REMOVE_ASSIGNEE_BEGIN,
    accountId,
    request,
    message,
    mixpanelData,
  };
}

export function dismissRemoveAssigneeError() {
  return {
    type: SELF_PERFORM_REMOVE_ASSIGNEE_DISMISS_ERROR,
  };
}

export function* doRemoveAssignee(action) {
  const { accountId, request, message, mixpanelData } = action;
  const { project, id: requestId, assignee } = request;
  const { id: projectId } = project;
  const data = {
    assigneeId: null,
    message,
  };

  try {
    yield call(protectedJsonPost, `accounts/${accountId}/projects/${projectId}/hourly-requests/${requestId}/assignment`, data);
  } catch (err) {
    yield put({
      type: SELF_PERFORM_REMOVE_ASSIGNEE_FAILURE,
      data: { error: err },
    });
    return;
  }

  // dispatch for mixpanel middleware
  if (message !== null) {
    yield put({
      type: SELF_PERFORM_NOTIFICATION_SENT_SUCCESS,
      requestId,
      projectId,
      personId: assignee?.id,
      message,
      mixpanelData,
    });
  }

  yield put({
    type: SELF_PERFORM_REMOVE_ASSIGNEE_SUCCESS,
    requestId,
    request,
    mixpanelData,
  });
}

export function* watchRemoveAssignee() {
  yield takeLatest(SELF_PERFORM_REMOVE_ASSIGNEE_BEGIN, doRemoveAssignee);
}

export function reducer(state, action) {
  switch (action.type) {
    case SELF_PERFORM_REMOVE_ASSIGNEE_BEGIN:
      return {
        ...state,
        removeAssigneePending: true,
        removeAssigneeError: null,
      };

    case SELF_PERFORM_REMOVE_ASSIGNEE_SUCCESS:
      return {
        ...state,
        filteredRequests: state.filteredRequests?.filter(({ id }) => id !== action.requestId) || [],
        selectedRequest: null,
        removeAssigneePending: false,
        removeAssigneeError: null,
      };

    case SELF_PERFORM_REMOVE_ASSIGNEE_FAILURE:
      return {
        ...state,
        removeAssigneePending: false,
        removeAssigneeError: action.data.error,
      };

    case SELF_PERFORM_REMOVE_ASSIGNEE_DISMISS_ERROR:
      return {
        ...state,
        removeAssigneeError: null,
      };

    default:
      return state;
  }
}
