import { SELF_PERFORM_REQUESTER } from './constants';

const getPermissionsForAccount = (accountId, permissions) => {
  if (permissions?.['*']) return permissions['*'];
  if (accountId && permissions?.[accountId]) return permissions[accountId];
  return null;
};

const isAuthorized = (accountId, permissions, action, subject, inclusive = false) => {
  const permsFor = getPermissionsForAccount(accountId, permissions);
  const subjectsArr = Array.isArray(subject) ? subject : [subject];
  const hasSubjectPerms = subjectsArr.some(sub => permsFor?.permissions?.[sub]);
  if (!action || !subject || !hasSubjectPerms) return false;

  if (inclusive) {
    return subjectsArr.every(sub => permsFor?.permissions?.[sub]?.includes(action));
  }

  return subjectsArr.some(sub => permsFor?.permissions?.[sub]?.includes(action));
};

const hasModuleEnabled = (accountModules, module, component = null) => {
  // If no module specified to check for, return true.
  // This is to handle the optional prop in the <Can> component
  if (!module) return true;

  const moduleMatch = accountModules.find(accountModule => accountModule.name === module);
  const componentArr = Array.isArray(component) ? component : [component];

  if (!component || !moduleMatch) {
    return !!moduleMatch;
  }

  return componentArr.some(comp => moduleMatch.components[comp]);
};

const hasOneOfModules = (accountModules, modules) => modules.some(
  module => !!accountModules.find(accountModule => accountModule.name === module),
);

const hasWebPerms = (accountId, permissions) => {
  const permsFor = getPermissionsForAccount(accountId, permissions);
  return permsFor?.group !== SELF_PERFORM_REQUESTER;
};

export {
  getPermissionsForAccount,
  isAuthorized,
  hasModuleEnabled,
  hasOneOfModules,
  hasWebPerms,
};
