import { call, put, takeLatest } from 'redux-saga/effects';
import {
  QUERIES_GET_SAVED_FILTERS_BEGIN,
  QUERIES_GET_SAVED_FILTERS_SUCCESS,
  QUERIES_GET_SAVED_FILTERS_FAILURE,
  QUERIES_GET_SAVED_FILTERS_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';
import { fromApiSavedFilter } from '../utils/queryUtils';

export function getSavedFilters(accountId, savedFilterType) {
  return {
    type: QUERIES_GET_SAVED_FILTERS_BEGIN,
    accountId,
    savedFilterType,
  };
}

export function dismissGetSavedFiltersError() {
  return {
    type: QUERIES_GET_SAVED_FILTERS_DISMISS_ERROR,
  };
}

export function* doGetSavedFilters(action) {
  const { accountId, savedFilterType } = action;
  let res;
  try {
    const urlParams = savedFilterType ? `?type=${savedFilterType}` : '';
    res = yield call(protectedJsonGet, `accounts/${accountId}/my-filters${urlParams}`);
  } catch (err) {
    yield put({
      type: QUERIES_GET_SAVED_FILTERS_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: QUERIES_GET_SAVED_FILTERS_SUCCESS,
    data: res,
  });
}

export function* watchGetSavedFilters() {
  yield takeLatest(QUERIES_GET_SAVED_FILTERS_BEGIN, doGetSavedFilters);
}

export function reducer(state, action) {
  switch (action.type) {
    case QUERIES_GET_SAVED_FILTERS_BEGIN:
      return {
        ...state,
        getSavedFiltersPending: true,
        getSavedFiltersError: null,
      };

    case QUERIES_GET_SAVED_FILTERS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        savedFilters: data?.map(fromApiSavedFilter),
        getSavedFiltersPending: false,
        getSavedFiltersError: null,
      };
    }

    case QUERIES_GET_SAVED_FILTERS_FAILURE:
      return {
        ...state,
        getSavedFiltersPending: false,
        getSavedFiltersError: action.data.error,
      };

    case QUERIES_GET_SAVED_FILTERS_DISMISS_ERROR:
      return {
        ...state,
        getSavedFiltersError: null,
      };

    default:
      return state;
  }
}
