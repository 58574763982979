import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import pluralize from 'pluralize';
import { naturalSort } from 'src/utils/sortUtils';
import { DATE_DISPLAY_FORMAT } from '../../common/constants';

function PhasePopper({
  phases,
  highlightedPhases,
}) {
  return (
    <div className="phases-phase-popper">
      {naturalSort(highlightedPhases, ['startDate', 'endDate', 'name']).map((phase) => {
        const phaseObj = phases.find(p => p.id === phase.id);
        const { id, name, startDate, endDate, subPhases } = phaseObj;
        return (
          <div className="phase-popper-container" key={id}>
            <h1>{name}</h1>
            <div>{`${moment(startDate).format(DATE_DISPLAY_FORMAT)} - ${moment(endDate).format(DATE_DISPLAY_FORMAT)}`}</div>
            <div className="sub-phase">{subPhases && subPhases.length > 0 ? pluralize('Sub-phase', subPhases.length, true) : ''}</div>
          </div>
        );
      })}
    </div>
  );
}

PhasePopper.propTypes = {
  phases: PropTypes.array.isRequired,
  highlightedPhases: PropTypes.array.isRequired,
};

export default PhasePopper;
