import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PROJECTS_ADD_PROJECT_NOTE_BEGIN,
  PROJECTS_ADD_PROJECT_NOTE_SUCCESS,
  PROJECTS_ADD_PROJECT_NOTE_FAILURE,
  PROJECTS_ADD_PROJECT_NOTE_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';

export function addProjectNote(accountId, projectId, analyticsPayload, data) {
  return {
    type: PROJECTS_ADD_PROJECT_NOTE_BEGIN,
    accountId,
    projectId,
    analyticsPayload,
    data,
  };
}

export function dismissAddProjectNoteError() {
  return {
    type: PROJECTS_ADD_PROJECT_NOTE_DISMISS_ERROR,
  };
}

export function* doAddProjectNote(action) {
  const { accountId, projectId, analyticsPayload, data } = action;
  let res;
  try {
    res = yield call(protectedJsonPost, `accounts/${accountId}/projects/${projectId}/notes`, data);
  } catch (err) {
    yield put({
      type: PROJECTS_ADD_PROJECT_NOTE_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PROJECTS_ADD_PROJECT_NOTE_SUCCESS,
    data: res,
    projectId,
    analyticsPayload,
  });
}

export function* watchAddProjectNote() {
  yield takeLatest(PROJECTS_ADD_PROJECT_NOTE_BEGIN, doAddProjectNote);
}

export function reducer(state, action) {
  switch (action.type) {
    case PROJECTS_ADD_PROJECT_NOTE_BEGIN:
      return {
        ...state,
        addProjectNotePending: true,
        addProjectNoteError: null,
      };

    case PROJECTS_ADD_PROJECT_NOTE_SUCCESS: {
      const { data, projectId } = action;
      const { filteredProjects, projectSelections } = state;

      const newFilteredProjects = filteredProjects.map((project) => {
        if (project.id === projectId) {
          const existingNotes = project?.notes || [];
          const newNotes = [
            data,
            ...existingNotes,
          ];
          return {
            ...project,
            notes: newNotes,
          };
        }
        return project;
      });

      const newProjectSelections = { ...projectSelections };
      Object.keys(newProjectSelections).forEach((selectionId) => {
        const selectedProject = newProjectSelections[selectionId];
        if (selectedProject && selectedProject.id === projectId) {
          const existingNotes = selectedProject?.notes || [];
          const updatedNotes = [
            data,
            ...existingNotes,
          ];
          newProjectSelections[selectionId] = {
            ...selectedProject,
            notes: updatedNotes,
          };
        }
      });

      return {
        ...state,
        filteredProjects: newFilteredProjects,
        addProjectNotePending: false,
        addProjectNoteError: null,
        projectSelections: newProjectSelections,
      };
    }

    case PROJECTS_ADD_PROJECT_NOTE_FAILURE:
      return {
        ...state,
        addProjectNotePending: false,
        addProjectNoteError: action.data.error,
      };

    case PROJECTS_ADD_PROJECT_NOTE_DISMISS_ERROR:
      return {
        ...state,
        addProjectNoteError: null,
      };

    default:
      return state;
  }
}
