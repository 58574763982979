export const LOGIN_USERLOGIN_BEGIN = 'LOGIN_USERLOGIN_BEGIN';
export const LOGIN_USERLOGIN_SUCCESS = 'LOGIN_USERLOGIN_SUCCESS';
export const LOGIN_USERLOGIN_FAILURE = 'LOGIN_USERLOGIN_FAILURE';
export const LOGIN_USERLOGIN_DISMISS_ERROR = 'LOGIN_USERLOGIN_DISMISS_ERROR';
export const LOGIN_ACCEPT_INVITATION_BEGIN = 'LOGIN_ACCEPT_INVITATION_BEGIN';
export const LOGIN_ACCEPT_INVITATION_SUCCESS = 'LOGIN_ACCEPT_INVITATION_SUCCESS';
export const LOGIN_ACCEPT_INVITATION_FAILURE = 'LOGIN_ACCEPT_INVITATION_FAILURE';
export const LOGIN_ACCEPT_INVITATION_DISMISS_ERROR = 'LOGIN_ACCEPT_INVITATION_DISMISS_ERROR';
export const LOGIN_GET_LOGIN_INVITATION_BEGIN = 'LOGIN_GET_LOGIN_INVITATION_BEGIN';
export const LOGIN_GET_LOGIN_INVITATION_SUCCESS = 'LOGIN_GET_LOGIN_INVITATION_SUCCESS';
export const LOGIN_GET_LOGIN_INVITATION_FAILURE = 'LOGIN_GET_LOGIN_INVITATION_FAILURE';
export const LOGIN_GET_LOGIN_INVITATION_DISMISS_ERROR = 'LOGIN_GET_LOGIN_INVITATION_DISMISS_ERROR';
export const LOGIN_GET_USER_INFO_BEGIN = 'LOGIN_GET_USER_INFO_BEGIN';
export const LOGIN_GET_USER_INFO_SUCCESS = 'LOGIN_GET_USER_INFO_SUCCESS';
export const LOGIN_GET_USER_INFO_FAILURE = 'LOGIN_GET_USER_INFO_FAILURE';
export const LOGIN_GET_USER_INFO_DISMISS_ERROR = 'LOGIN_GET_USER_INFO_DISMISS_ERROR';
export const LOGIN_USERLOGOUT_BEGIN = 'LOGIN_USERLOGOUT_BEGIN';
export const LOGIN_USERLOGOUT_FAILURE = 'LOGIN_USERLOGOUT_FAILURE';
export const LOGIN_USERLOGOUT_SUCCESS = 'LOGIN_USERLOGOUT_SUCCESS';
export const LOGIN_USERLOGOUT_DISMISS_ERROR = 'LOGIN_USERLOGOUT_DISMISS_ERROR';
export const LOGIN_ACCEPT_INVITATION_OAUTH_BEGIN = 'LOGIN_ACCEPT_INVITATION_OAUTH_BEGIN';
export const LOGIN_ACCEPT_INVITATION_OAUTH_SUCCESS = 'LOGIN_ACCEPT_INVITATION_OAUTH_SUCCESS';
export const LOGIN_ACCEPT_INVITATION_OAUTH_FAILURE = 'LOGIN_ACCEPT_INVITATION_OAUTH_FAILURE';
export const LOGIN_ACCEPT_INVITATION_OAUTH_DISMISS_ERROR = 'LOGIN_ACCEPT_INVITATION_OAUTH_DISMISS_ERROR';
export const LOGIN_UPDATE_USER_INFO_BEGIN = 'LOGIN_UPDATE_USER_INFO_BEGIN';
export const LOGIN_UPDATE_USER_INFO_SUCCESS = 'LOGIN_UPDATE_USER_INFO_SUCCESS';
export const LOGIN_UPDATE_USER_INFO_FAILURE = 'LOGIN_UPDATE_USER_INFO_FAILURE';
export const LOGIN_UPDATE_USER_INFO_DISMISS_ERROR = 'LOGIN_UPDATE_USER_INFO_DISMISS_ERROR';
export const LOGIN_UPDATE_PASSWORD_BEGIN = 'LOGIN_UPDATE_PASSWORD_BEGIN';
export const LOGIN_UPDATE_PASSWORD_SUCCESS = 'LOGIN_UPDATE_PASSWORD_SUCCESS';
export const LOGIN_UPDATE_PASSWORD_FAILURE = 'LOGIN_UPDATE_PASSWORD_FAILURE';
export const LOGIN_UPDATE_PASSWORD_DISMISS_ERROR = 'LOGIN_UPDATE_PASSWORD_DISMISS_ERROR';
export const LOGIN_REQUEST_FORGOT_PASSWORD_BEGIN = 'LOGIN_REQUEST_FORGOT_PASSWORD_BEGIN';
export const LOGIN_REQUEST_FORGOT_PASSWORD_SUCCESS = 'LOGIN_REQUEST_FORGOT_PASSWORD_SUCCESS';
export const LOGIN_REQUEST_FORGOT_PASSWORD_FAILURE = 'LOGIN_REQUEST_FORGOT_PASSWORD_FAILURE';
export const LOGIN_REQUEST_FORGOT_PASSWORD_DISMISS_ERROR = 'LOGIN_REQUEST_FORGOT_PASSWORD_DISMISS_ERROR';
export const LOGIN_RESET_PASSWORD_BEGIN = 'LOGIN_RESET_PASSWORD_BEGIN';
export const LOGIN_RESET_PASSWORD_SUCCESS = 'LOGIN_RESET_PASSWORD_SUCCESS';
export const LOGIN_RESET_PASSWORD_FAILURE = 'LOGIN_RESET_PASSWORD_FAILURE';
export const LOGIN_RESET_PASSWORD_DISMISS_ERROR = 'LOGIN_RESET_PASSWORD_DISMISS_ERROR';
export const LOGIN_GET_DEFAULT_TEAMS_BEGIN = 'LOGIN_GET_DEFAULT_TEAMS_BEGIN';
export const LOGIN_GET_DEFAULT_TEAMS_SUCCESS = 'LOGIN_GET_DEFAULT_TEAMS_SUCCESS';
export const LOGIN_GET_DEFAULT_TEAMS_FAILURE = 'LOGIN_GET_DEFAULT_TEAMS_FAILURE';
export const LOGIN_GET_DEFAULT_TEAMS_DISMISS_ERROR = 'LOGIN_GET_DEFAULT_TEAMS_DISMISS_ERROR';
