import {
  COMMON_CLEAR_MULTI_STATE_MODAL_STATE,
} from './constants';
import initialState from './initialState';

export function clearMultiStateModalState(analyticsPayload) {
  return {
    type: COMMON_CLEAR_MULTI_STATE_MODAL_STATE,
    analyticsPayload,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_CLEAR_MULTI_STATE_MODAL_STATE: {
      const { multiStateModal } = initialState;
      return {
        ...state,
        multiStateModal: {
          ...multiStateModal,
        },
      };
    }

    default:
      return state;
  }
}
