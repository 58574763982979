import accountSettingsEvents from './account-settings';
import accountEvents from './accounts';
import projectsEvents from './projects';
import tableEvents from './table';
import settingsEvents from './settings';
import selfPerformEvents from './self-perform';
import loginEvents from './login';
import peopleEvents from './people';
import themeEvents from './themes';
import commonEvents from './common';
import filterEvents from './filters';
import permissionEvents from './permissions';
import serviceAccountsEvents from './service-accounts';
import ganttEvents from './gantt';

export default {
  ...accountSettingsEvents,
  ...accountEvents,
  ...projectsEvents,
  ...tableEvents,
  ...settingsEvents,
  ...selfPerformEvents,
  ...loginEvents,
  ...peopleEvents,
  ...themeEvents,
  ...commonEvents,
  ...filterEvents,
  ...permissionEvents,
  ...serviceAccountsEvents,
  ...ganttEvents,
};
