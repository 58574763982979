import { ACTIVE, UPCOMING, PURSUIT, COMPLETE } from 'src/features/projects/constants';
import {
  NEXT_ALLOCATIONS,
  NEXT_ALLOCATIONS_START_DATE,
  QUERY_PARAM,
} from '../../../filters/constants';

const defaultFilters = {
  projects: [
    {
      label: 'Status',
      column: 'state',
      type: undefined,
      activeFilters: [{ name: ACTIVE, value: ACTIVE }, { name: UPCOMING, value: UPCOMING }, { name: PURSUIT, value: PURSUIT }],
    },
  ],
  people: [],
  dashboard: [
    {
      label: 'Status',
      column: 'state',
      type: undefined,
      activeFilters: [{ name: ACTIVE, value: ACTIVE }, { name: UPCOMING, value: UPCOMING }, { name: PURSUIT, value: PURSUIT }],
    },
  ],
  forecastPeople: [],
  forecastProject: [
    {
      label: 'Status',
      column: 'state',
      type: undefined,
      activeFilters: [
        { name: ACTIVE, value: ACTIVE },
        { name: UPCOMING, value: UPCOMING },
        { name: PURSUIT, value: PURSUIT },
        { name: COMPLETE, value: COMPLETE },
      ],
    },
  ],
  roleAvailabilities: (titles = [], dateFilterLabel = '') => {
    const filters = [];

    if (typeof dateFilterLabel === 'string' && dateFilterLabel.length) {
      filters.push({
        activeFilters: [
          {
            name: dateFilterLabel,
            value: true,
          },
        ],
        column: 'requireAvailability',
        label: 'Has availability',
        filterType: QUERY_PARAM,
        verb: 'between',
      });
    }

    if (Array.isArray(titles) && titles.length) {
      filters.push({
        activeFilters: titles.map(title => ({
          name: title,
          value: title,
        })),
        column: 'title',
        label: 'Title',
      });
    }

    return filters;
  },
  hourlyPersons: [],
  requests: [],
  empty: [],
};

const EXCLUDED_PROJECT_HEADER_FILTERS = [{
  propName: 'name',
  value: 'project name',
}, {
  propName: 'name',
  value: 'other',
}, {
  propName: 'type',
  value: 'phoneNumber',
}];

const EXCLUDED_UNFILLED_ROLES_PROJECT_HEADER_FILTERS = [
  ...EXCLUDED_PROJECT_HEADER_FILTERS,
  {
    propName: 'name',
    value: 'issues',
  },
];

const EXCLUDED_PEOPLE_HEADER_FILTERS = [{
  propName: 'name',
  value: 'name',
}, {
  propName: 'name',
  value: 'other',
}, {
  propName: 'name',
  value: 'photo',
}, {
  propName: 'name',
  value: 'email',
}, {
  propName: 'type',
  value: 'phoneNumber',
},
{
  propName: 'schemaName',
  value: 'currentAllocations',
},
{
  propName: 'schemaName',
  value: 'pursuitProjects',
},
{
  propName: 'schemaName',
  value: NEXT_ALLOCATIONS,
},
{
  propName: 'schemaName',
  value: NEXT_ALLOCATIONS_START_DATE,
}];

const EXCLUDED_ALLOCATE_PERSON_MODAL_FILTERS = [
  {
    propName: 'schemaName',
    value: 'email',
  },
  {
    propName: 'schemaName',
    value: 'hasConflict',
  },
  {
    propName: 'schemaName',
    value: 'availableInDays',
  },
  {
    propName: 'name',
    value: 'Other',
  },
  {
    propName: 'type',
    value: 'phoneNumber',
  },
];

const PRIORITY_PEOPLE_FILTERS = ['Title', 'Availability'];
const PRIORITY_PROJECT_FILTERS = ['Status', 'Active Phase', 'Roles'];

const PEOPLE_FILTER_TYPE = 'people';
const PROJECT_FILTER_TYPE = 'project';
const HOURLY_PERSON_FILTER_TYPE = 'hourlyPerson';

const APPLY_FILTER_ON_INIT = 'Filter Applied from Page Load';

export {
  defaultFilters,
  EXCLUDED_PROJECT_HEADER_FILTERS,
  EXCLUDED_UNFILLED_ROLES_PROJECT_HEADER_FILTERS,
  EXCLUDED_PEOPLE_HEADER_FILTERS,
  EXCLUDED_ALLOCATE_PERSON_MODAL_FILTERS,
  PRIORITY_PEOPLE_FILTERS,
  PRIORITY_PROJECT_FILTERS,
  PEOPLE_FILTER_TYPE,
  PROJECT_FILTER_TYPE,
  HOURLY_PERSON_FILTER_TYPE,
  APPLY_FILTER_ON_INIT,
};
