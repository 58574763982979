import accountsRoute from '../features/accounts/route';
import { PageNotFound } from '../features/errors';
import commonRoute from '../features/common/route';
import App from '../containers/App';
import settingsRoute from '../features/settings/route';
import loginRoute from '../features/login/route';
import peopleRoute from '../features/people/route';
import projectsRoute from '../features/projects/route';
import accountSettingsRoute from '../features/account-settings/route';
import dashboardRoute from '../features/dashboard/route';
import wrappedComponentsRoute from '../features/wrapped-components/route';
import tableRoute from '../features/table/route';
import phasesRoute from '../features/phases/route';
import ganttRoute from '../features/gantt/route';
import allocationsRoute from '../features/allocations/route';
import filtersRoute from '../features/filters/route';
import reportsRoute from '../features/reports/route';
import queriesRoute from '../features/queries/route';
import modalManagerRoute from '../features/modal-manager/route';
import permissionsRoute from '../features/permissions/route';
import forecastRoute from '../features/forecast/route';
import errorsRoute from '../features/errors/route';
import editDatesRoute from '../features/edit-dates/route';
import selfPerformRoute from '../features/self-perform/route';
import toastsRoute from '../features/toasts/route';
import rolesRoute from '../features/roles/route';

const childRoutes = [
  accountsRoute,
  commonRoute,
  settingsRoute,
  loginRoute,
  peopleRoute,
  projectsRoute,
  accountSettingsRoute,
  dashboardRoute,
  wrappedComponentsRoute,
  tableRoute,
  phasesRoute,
  ganttRoute,
  allocationsRoute,
  filtersRoute,
  reportsRoute,
  queriesRoute,
  modalManagerRoute,
  permissionsRoute,
  forecastRoute,
  errorsRoute,
  editDatesRoute,
  selfPerformRoute,
  toastsRoute,
  rolesRoute,
];

const routes = [
  {
    path: '/',
    component: App,
    childRoutes: [
      ...childRoutes,
      { path: '*', name: 'Page not found', component: PageNotFound },
    ].filter(r => r.component || (r.childRoutes && r.childRoutes.length > 0)),
  },
];

const defaultChildRoutes = [
  accountsRoute,
  settingsRoute,
  loginRoute,
  allocationsRoute,
  filtersRoute,
  reportsRoute,
  queriesRoute,
  modalManagerRoute,
  permissionsRoute,
  forecastRoute,
  errorsRoute,
  editDatesRoute,
];

const defaultRoutes = [
  {
    path: '/',
    component: App,
    childRoutes: [
      ...defaultChildRoutes,
      { path: '*', name: 'Page not found', component: PageNotFound },
    ].filter(r => r.component || (r.childRoutes && r.childRoutes.length > 0)),
  },
];

// Handle isIndex property of route config:
//  Duplicate it and put it as the first route rule.
function handleIndexRoute(route) {
  if (!route.childRoutes || !route.childRoutes.length) {
    return;
  }

  const indexRoute = route.childRoutes.find(child => child.isIndex);
  if (indexRoute) {
    const first = { ...indexRoute };
    first.path = '';
    first.exact = true;
    first.autoIndexRoute = true; // mark it so that the simple nav won't show it.
    route.childRoutes.unshift(first);
  }
  route.childRoutes.forEach(handleIndexRoute);
}

routes.forEach(handleIndexRoute);
defaultRoutes.forEach(handleIndexRoute);
export default {
  routes,
  defaultRoutes,
};
