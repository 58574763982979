import initialState from './initialState';
import { reducer as getReportPeopleReducer } from './getReportPeople';
import { reducer as clearReportDataReducer } from './clearReportData';
import { reducer as getReportProjectsReducer } from './getReportProjects';
import { reducer as setReportReducer } from './setReport';
import { reducer as getPeopleAllocationsReducer } from './getPeopleAllocations';
import { reducer as setImagePreloadPendingReducer } from './setImagePreloadPending';
import { reducer as collectPeopleDataReducer } from './collectPeopleData';
import { reducer as collectProjectDataReducer } from './collectProjectData';
import { reducer as getProjectAllocationsReducer } from './getProjectAllocations';
import { reducer as getPeopleGanttAllocationsReducer } from './getPeopleGanttAllocations';
import { reducer as getGanttReportPeopleReducer } from './getGanttReportPeople';
import { reducer as getReportRoleSegmentsReducer } from './getReportRoleSegments';
import { reducer as collectProjectGanttDataReducer } from './collectProjectGanttData';

const reducers = [
  getReportPeopleReducer,
  clearReportDataReducer,
  getReportProjectsReducer,
  setReportReducer,
  getPeopleAllocationsReducer,
  setImagePreloadPendingReducer,
  collectPeopleDataReducer,
  collectProjectDataReducer,
  getProjectAllocationsReducer,
  getPeopleGanttAllocationsReducer,
  getGanttReportPeopleReducer,
  getReportRoleSegmentsReducer,
  collectProjectGanttDataReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
