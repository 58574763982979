import { call, put, takeLatest } from 'redux-saga/effects';
import { getNewProjectListEntity } from 'src/features/projects/queries/getProjectListQuery';
import { protectedJsonPost } from 'src/utils/api';
import {
  PROJECTS_ADD_PROJECT_BEGIN,
  PROJECTS_ADD_PROJECT_SUCCESS,
  PROJECTS_ADD_PROJECT_FAILURE,
  PROJECTS_ADD_PROJECT_DISMISS_ERROR,
} from './constants';

import { normalizeFields } from '../../../utils/normalizers';

export function addProject(accountId, data, phaseData = [], analyticsPayload, cb) {
  return {
    type: PROJECTS_ADD_PROJECT_BEGIN,
    accountId,
    data,
    phaseData,
    analyticsPayload,
    cb,
  };
}

export function dismissAddProjectError() {
  return {
    type: PROJECTS_ADD_PROJECT_DISMISS_ERROR,
  };
}

export function* doAddProject(action) {
  const { accountId, data, phaseData, analyticsPayload, cb } = action;
  const withPhases = phaseData.length > 0;

  let res;

  try {
    res = yield call(protectedJsonPost, `accounts/${accountId}/projects`, data);
  } catch (err) {
    yield put({
      type: PROJECTS_ADD_PROJECT_FAILURE,
      data: { error: err },
    });
    return;
  }

  if (withPhases) {
    try {
      yield call(protectedJsonPost, `accounts/${accountId}/projects/${res.id}/phases/bulk`, phaseData);
    } catch (err) {
      yield put({
        type: PROJECTS_ADD_PROJECT_SUCCESS,
        data: res,
        withPhases: false,
        phaseData,
        fields: data.fields,
        analyticsPayload,
      });

      if (cb) {
        cb(res);
      }
      return;
    }
  }

  yield put({
    type: PROJECTS_ADD_PROJECT_SUCCESS,
    data: res,
    withPhases,
    phaseData,
    fields: data.fields,
    analyticsPayload,
  });

  if (cb) {
    cb(res);
  }
}

export function* watchAddProject() {
  yield takeLatest(PROJECTS_ADD_PROJECT_BEGIN, doAddProject);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PROJECTS_ADD_PROJECT_BEGIN:
      return {
        ...state,
        addProjectPending: true,
        addProjectError: null,
      };

    case PROJECTS_ADD_PROJECT_SUCCESS: {
      const { data, withPhases, phaseData, fields } = action;
      const normalizedFields = normalizeFields(fields);
      const newProjectList = state.projects.concat(data);
      const projectData = {
        ...data,
        hasIssues: false,
        phases: phaseData,
        roles: [],
      };
      const filteredProjects = state.filteredProjects.concat(getNewProjectListEntity(projectData, normalizedFields));

      if (withPhases) {
        let phaseCount = phaseData.length;

        phaseData.forEach((phase) => {
          phaseCount += phase.subPhases.length;
        });

        // Mixpanel - Track when a phases are added
        window.mixpanel.track('Phases Added to a Project', {
          'Project Name': data.name,
          'Project ID': data.id,
          'Project status': data.state,
          'Project type': data.type,
          Location: 'Add Project',
          'Number of phases added': phaseCount,
        });
      }

      return {
        ...state,
        projects: newProjectList,
        projectFieldValues: [
          ...state.projectFieldValues,
          {
            ...data,
            projectId: data.id,
            fields: normalizedFields,
          },
        ],
        addProjectPending: false,
        addProjectError: null,
        filteredProjects,
      };
    }

    case PROJECTS_ADD_PROJECT_FAILURE:
      return {
        ...state,
        addProjectPending: false,
        addProjectError: action.data.error,
      };

    case PROJECTS_ADD_PROJECT_DISMISS_ERROR:
      return {
        ...state,
        addProjectError: null,
      };

    default:
      return state;
  }
}
