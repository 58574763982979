import initialState from './initialState';
import { reducer as setVisibleColumnsReducer } from './setVisibleColumns';
import { reducer as setColumnsReducer } from './setColumns';
import { reducer as setTableConfigReducer } from './setTableConfig';
import { reducer as setColumnOrderReducer } from './setColumnOrder';
import { reducer as setDescriptorsReducer } from './setDescriptors';

const reducers = [
  setVisibleColumnsReducer,
  setColumnsReducer,
  setTableConfigReducer,
  setColumnOrderReducer,
  setDescriptorsReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
