import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PROJECTS_GET_EXPANDED_ALLOCATIONS_BEGIN,
  PROJECTS_GET_EXPANDED_ALLOCATIONS_SUCCESS,
  PROJECTS_GET_EXPANDED_ALLOCATIONS_FAILURE,
  PROJECTS_GET_EXPANDED_ALLOCATIONS_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';

export function getExpandedAllocations(accountId) {
  return {
    type: PROJECTS_GET_EXPANDED_ALLOCATIONS_BEGIN,
    accountId,
  };
}

export function dismissGetExpandedAllocationsError() {
  return {
    type: PROJECTS_GET_EXPANDED_ALLOCATIONS_DISMISS_ERROR,
  };
}

export function* doGetExpandedAllocations(action) {
  const { accountId } = action;
  let res;
  try {
    res = yield call(protectedJsonGet, `accounts/${accountId}/project-allocations`, { allocationState: 'all', personstate: 'all', limit: 5000 });
  } catch (err) {
    yield put({
      type: PROJECTS_GET_EXPANDED_ALLOCATIONS_FAILURE,
      data: { error: err },
    });
    return;
  }
  yield put({
    type: PROJECTS_GET_EXPANDED_ALLOCATIONS_SUCCESS,
    data: res,
  });
}

export function* watchGetExpandedAllocations() {
  yield takeLatest(PROJECTS_GET_EXPANDED_ALLOCATIONS_BEGIN, doGetExpandedAllocations);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PROJECTS_GET_EXPANDED_ALLOCATIONS_BEGIN:
      return {
        ...state,
        getExpandedAllocationsPending: true,
        getExpandedAllocationsError: null,
      };

    case PROJECTS_GET_EXPANDED_ALLOCATIONS_SUCCESS:
      return {
        ...state,
        expandedAllocations: action.data,
        getExpandedAllocationsPending: false,
        getExpandedAllocationsError: null,
      };

    case PROJECTS_GET_EXPANDED_ALLOCATIONS_FAILURE:
      return {
        ...state,
        getExpandedAllocationsPending: false,
        getExpandedAllocationsError: action.data.error,
      };

    case PROJECTS_GET_EXPANDED_ALLOCATIONS_DISMISS_ERROR:
      return {
        ...state,
        getExpandedAllocationsError: null,
      };

    default:
      return state;
  }
}
