import { AUTH_ERROR } from 'src/features/errors/redux/constants';
import { LOGIN_USERLOGIN_BEGIN, LOGIN_USERLOGOUT_SUCCESS } from 'src/features/login/redux/constants';
import initialState from './initialState';
import { parseError } from '../error-utils';
import { reducer as dismissErrorReducer } from './dismissError';
import { reducer as clearAppDataReducer } from './clearAppData';

const reducers = [
  dismissErrorReducer,
  clearAppDataReducer,
];

export default function reducer(state = initialState, action) {
  let newState = state;
  const { data, type } = action;

  if (type === LOGIN_USERLOGOUT_SUCCESS || type === LOGIN_USERLOGIN_BEGIN) {
    newState = initialState;
  } else if (data?.error?.status === 401) {
    newState = {
      ...state,
      error: { messageId: AUTH_ERROR },
    };
  } else if (data?.error) {
    const { error } = data;
    const parsedError = parseError(error, type);
    newState = {
      ...state,
      error: parsedError,
    };
  }

  return reducers.reduce((s, r) => r(s, action), newState);
}
