import {
  LOGIN_UPDATE_USER_INFO_SUCCESS,
  LOGIN_ACCEPT_INVITATION_SUCCESS,
  LOGIN_ACCEPT_INVITATION_OAUTH_SUCCESS,
  LOGIN_REQUEST_FORGOT_PASSWORD_SUCCESS,
  LOGIN_USERLOGIN_SUCCESS,
} from '../../features/login/redux/constants';
import {
  USER_UPDATED,
  SIGN_UP,
  LOGIN,
  FORGOT_PASSWORD,
} from './constants';

const events = {
  [LOGIN_UPDATE_USER_INFO_SUCCESS]: {
    getEvent: () => USER_UPDATED,
    getPayload: (state, { analyticsPayload }) => {
      const { fieldsUpdated, title, name, team, updatedFrom } = analyticsPayload;
      return {
        'Fields Updated': fieldsUpdated,
        'Title Updated To': title,
        'Name Updated To': name,
        'Team Updated To': team,
        'Updated From': updatedFrom,
      };
    },
  },

  [LOGIN_ACCEPT_INVITATION_SUCCESS]: {
    getEvent: () => SIGN_UP,
    getPayload: state => ({
      'Auth Type': 'Bridgit Auth',
      Email: state.login?.userInvitationData?.email,
    }),
  },

  [LOGIN_ACCEPT_INVITATION_OAUTH_SUCCESS]: {
    getEvent: () => SIGN_UP,
    getPayload: (state, { authType }) => ({
      'Auth Type': authType,
      Email: state.login?.userInvitationData?.email,
    }),
  },

  [LOGIN_USERLOGIN_SUCCESS]: {
    getEvent: () => LOGIN,
    getPayload: () => ({
      'Auth Type': 'Bridgit Auth',
    }),
  },

  [LOGIN_REQUEST_FORGOT_PASSWORD_SUCCESS]: {
    getEvent: () => FORGOT_PASSWORD,
    getPayload: (state, { email }) => ({
      'User Email': email,
    }),
  },
};

export default events;
