import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { ThemeSlider } from './index';
import { SETTING_RANGES, SETTING_SLIDER_STEP } from './constants';
import { capitalize } from '../../utils/formatters';

const ThemeConfigurator = ({ config, onChange, disabled, themeName }) => (
  <div className="theme-configurator-wrapper">
    <Typography gutterBottom className="theme-configurator-title">
      {`${capitalize(themeName)} mode settings`}
    </Typography>
    {Object.keys(config).map(settingName => (
      <ThemeSlider
        key={settingName}
        themeName={themeName}
        settingName={settingName}
        step={SETTING_SLIDER_STEP}
        range={SETTING_RANGES[themeName][settingName]}
        value={config[settingName]}
        disabled={disabled}
        onChange={onChange(themeName, settingName)}
      />
    ))}
  </div>
);

ThemeConfigurator.propTypes = {
  config: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  themeName: PropTypes.string.isRequired,
};

export default ThemeConfigurator;
