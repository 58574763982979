import shortid from 'shortid';
import {
  MIN_CONFIGURABLE_DATE_MESSAGE,
  FIELD_TYPE_ADDRESS,
  FIELD_TYPE_PHONE,
  FIELD_TYPE_PHONE_E164,
  ACCOUNT_MODULE_STANDARD,
  ACCOUNT_MODULE_HOURLY,
  ACCOUNT_MODULE_CERTIFICATIONS,
  ACCOUNT_MODULE_LOCATION,
} from 'src/common/constants';
import {
  PERM_ACCOUNT,
  PERM_PERSON,
  PERM_HOURLY_PERSON,
  PERM_WRITE,
  PERM_ROLE,
} from 'src/features/permissions/utils/constants';

const MODULE_NAME_TRANSLATION = {
  [ACCOUNT_MODULE_HOURLY]: 'Self-Perform',
  [ACCOUNT_MODULE_LOCATION]: 'Distance From Project',
};

const SETTINGS_TABS = {
  PEOPLE: 'People',
  PROJECTS: 'Projects',
  SELF_PERFORM: 'Self-perform',
  ACCOUNT: 'Account',
};

const DISABLED_FIELD_TYPES = {
  [SETTINGS_TABS.PEOPLE]: [FIELD_TYPE_ADDRESS],
  [SETTINGS_TABS.PROJECTS]: [FIELD_TYPE_ADDRESS],
  [SETTINGS_TABS.SELF_PERFORM]: [FIELD_TYPE_PHONE, FIELD_TYPE_PHONE_E164, FIELD_TYPE_ADDRESS],
};

const SETTINGS_TABS_LABELS = {
  PEOPLE: 'People',
  PROJECTS: 'Projects',
  SELF_PERFORM: 'Self-Perform',
  ACCOUNT: 'Account',
};

const SETTINGS_SIDE_TABS = {
  SYSTEM: 'system-fields',
  CUSTOM: 'custom-fields',
  CERTIFICATIONS: 'certifications',
  DEACTIVATED_PEOPLE: 'deactivated-people',
  ROLES: 'roles',
  PHASES: 'phases',
  USERS: 'users',
  SERVICE_ACCOUNTS: 'service-accounts',
  PERMISSION_GROUPS: 'permission-groups',
  ACCOUNT_MANAGEMENT: 'account-management',
  BRIDGIT_ACCOUNT_MANAGEMENT: 'bridgit-account-management',
};

const SETTINGS_SIDE_TABS_LABELS = {
  SYSTEM_FIELDS: 'System fields',
  CUSTOM_FIELDS: 'Custom fields',
  DEACTIVATED_PEOPLE: 'Deactivated people',
  CERTIFICATIONS: 'Certifications',
  ROLES: 'Roles',
  PHASES: 'Phases',
  USERS: 'Users',
  FIELDS: 'Fields',
  SERVICE_ACCOUNTS: 'Service Accounts',
  MANAGE: 'Manage',
  PERMISSION_GROUPS: 'Permission Groups',
  ACCOUNT_MANAGEMENT: 'Account Management',
  BRIDGIT_ACCOUNT_MANAGEMENT: 'Bridgit Account Management',
  SELECTION_LISTS: 'Selection Lists',
  SELF_PERFORM: 'Self-perform',
  SYSTEM: 'System',
  CUSTOM: 'Custom',
  PEOPLE: 'People',
  PROJECTS: 'Project',
  ACCOUNT: 'Account',
};

const tabs = [
  {
    value: SETTINGS_TABS.PEOPLE,
    label: SETTINGS_TABS_LABELS.PEOPLE,
    action: PERM_WRITE,
    subject: [PERM_ACCOUNT, PERM_PERSON],
    module: ACCOUNT_MODULE_STANDARD,
  },
  {
    value: SETTINGS_TABS.PROJECTS,
    label: SETTINGS_TABS_LABELS.PROJECTS,
    action: PERM_WRITE,
    subject: PERM_ACCOUNT,
  },
  {
    value: SETTINGS_TABS.SELF_PERFORM,
    label: SETTINGS_TABS_LABELS.SELF_PERFORM,
    action: PERM_WRITE,
    subject: [PERM_ACCOUNT, PERM_HOURLY_PERSON],
    module: ACCOUNT_MODULE_HOURLY,
  },
  {
    value: SETTINGS_TABS.ACCOUNT,
    label: SETTINGS_TABS_LABELS.ACCOUNT,
    action: PERM_WRITE,
    subject: PERM_ACCOUNT,
  },
];


const sideTabs = {
  [SETTINGS_TABS.PEOPLE]: [
    {
      label: SETTINGS_SIDE_TABS_LABELS.SYSTEM_FIELDS,
      value: SETTINGS_SIDE_TABS.SYSTEM,
      action: PERM_WRITE,
      subject: [PERM_ACCOUNT, PERM_PERSON],
      module: ACCOUNT_MODULE_STANDARD,
    },
    {
      label: SETTINGS_SIDE_TABS_LABELS.CUSTOM_FIELDS,
      value: SETTINGS_SIDE_TABS.CUSTOM,
      action: PERM_WRITE,
      subject: [PERM_ACCOUNT, PERM_PERSON],
      module: ACCOUNT_MODULE_STANDARD,
    },
    {
      label: SETTINGS_SIDE_TABS_LABELS.CERTIFICATIONS,
      value: SETTINGS_SIDE_TABS.CERTIFICATIONS,
      action: PERM_WRITE,
      subject: [PERM_ACCOUNT, PERM_PERSON],
      module: ACCOUNT_MODULE_CERTIFICATIONS,
    },
    {
      label: SETTINGS_SIDE_TABS_LABELS.DEACTIVATED_PEOPLE,
      value: SETTINGS_SIDE_TABS.DEACTIVATED_PEOPLE,
      action: PERM_WRITE,
      subject: [PERM_ACCOUNT, PERM_PERSON],
      module: ACCOUNT_MODULE_STANDARD,
    },
  ],
  [SETTINGS_TABS.PROJECTS]: [
    {
      label: SETTINGS_SIDE_TABS_LABELS.SYSTEM_FIELDS,
      value: SETTINGS_SIDE_TABS.SYSTEM,
      action: PERM_WRITE,
      subject: PERM_ACCOUNT,
    },
    {
      label: SETTINGS_SIDE_TABS_LABELS.CUSTOM_FIELDS,
      value: SETTINGS_SIDE_TABS.CUSTOM,
      action: PERM_WRITE,
      subject: PERM_ACCOUNT,
    },
    {
      label: SETTINGS_SIDE_TABS_LABELS.ROLES,
      value: SETTINGS_SIDE_TABS.ROLES,
      action: PERM_WRITE,
      subject: [PERM_ACCOUNT, PERM_ROLE],
      module: ACCOUNT_MODULE_STANDARD,
    },
    {
      label: SETTINGS_SIDE_TABS_LABELS.PHASES,
      value: SETTINGS_SIDE_TABS.PHASES,
      action: PERM_WRITE,
      subject: PERM_ACCOUNT,
    },
  ],
  [SETTINGS_TABS.SELF_PERFORM]: [
    {
      label: SETTINGS_SIDE_TABS_LABELS.SYSTEM_FIELDS,
      value: SETTINGS_SIDE_TABS.SYSTEM,
      action: PERM_WRITE,
      subject: [PERM_ACCOUNT, PERM_HOURLY_PERSON],
      module: ACCOUNT_MODULE_HOURLY,
    },
    {
      label: SETTINGS_SIDE_TABS_LABELS.CUSTOM_FIELDS,
      value: SETTINGS_SIDE_TABS.CUSTOM,
      action: PERM_WRITE,
      subject: PERM_ACCOUNT,
      module: ACCOUNT_MODULE_HOURLY,
    },
  ],
  [SETTINGS_TABS.ACCOUNT]: [
    {
      label: SETTINGS_SIDE_TABS_LABELS.USERS,
      value: SETTINGS_SIDE_TABS.USERS,
      action: PERM_WRITE,
      subject: PERM_ACCOUNT,
    },
    {
      label: SETTINGS_SIDE_TABS_LABELS.SERVICE_ACCOUNTS,
      value: SETTINGS_SIDE_TABS.SERVICE_ACCOUNTS,
      action: PERM_WRITE,
      subject: PERM_ACCOUNT,
    },
    {
      label: SETTINGS_SIDE_TABS_LABELS.PERMISSION_GROUPS,
      value: SETTINGS_SIDE_TABS.PERMISSION_GROUPS,
      action: PERM_WRITE,
      subject: PERM_ACCOUNT,
      module: ACCOUNT_MODULE_STANDARD,
    },
    {
      label: SETTINGS_SIDE_TABS_LABELS.ACCOUNT_MANAGEMENT,
      value: SETTINGS_SIDE_TABS.ACCOUNT_MANAGEMENT,
      action: PERM_WRITE,
      subject: PERM_ACCOUNT,
    },
    {
      label: SETTINGS_SIDE_TABS_LABELS.BRIDGIT_ACCOUNT_MANAGEMENT,
      value: SETTINGS_SIDE_TABS.BRIDGIT_ACCOUNT_MANAGEMENT,
      action: PERM_WRITE,
      subject: PERM_ACCOUNT,
      checkBridgitPerms: true,
    },
  ],
};

const COUNTRIES = [
  {
    value: 'United States',
    label: 'US',
  },
  {
    value: 'Canada',
    label: 'Canada',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

const LIST_ITEM_IN_USE_MODAL_TEXT = {
  ROLES: 'Role was not deleted. You cannot delete a role that is currently in use.',
  REQUIRED: 'Since this is a required field, you cannot delete this item while in use.',
};

const ADDITIONAL_DEFAULT_FIELDS = {
  [SETTINGS_TABS.PEOPLE]: [
    {
      id: shortid.generate(),
      isSystem: true,
      isRequired: true,
      name: 'Email',
      type: 'Email',
      value: [],
    },
    {
      id: shortid.generate(),
      isSystem: true,
      isRequired: true,
      name: 'Name',
      type: 'Text',
      value: [],
    },
    {
      id: shortid.generate(),
      isSystem: true,
      isRequired: false,
      name: 'Photo',
      type: 'Image',
      value: [],
    },
    {
      id: shortid.generate(),
      isSystem: true,
      isRequired: false,
      name: 'Hire Date',
      type: 'Date',
      value: [],
      minDateMessage: MIN_CONFIGURABLE_DATE_MESSAGE,
    },
    {
      id: shortid.generate(),
      isSystem: true,
      isRequired: false,
      name: 'Termination Date',
      type: 'Date',
      value: [],
      minDateMessage: MIN_CONFIGURABLE_DATE_MESSAGE,
    },
  ],
  [SETTINGS_TABS.PROJECTS]: [
    {
      id: shortid.generate(),
      isSystem: true,
      isRequired: true,
      name: 'Project Name',
      type: 'Text',
      value: [],
    },
    {
      id: shortid.generate(),
      isSystem: true,
      isRequired: true,
      name: 'Start Date',
      type: 'Date',
      value: [],
    },
    {
      id: shortid.generate(),
      isSystem: true,
      isRequired: true,
      name: 'End Date',
      type: 'Date',
      value: [],
    },
    {
      id: shortid.generate(),
      isSystem: true,
      isRequired: true,
      name: 'Type',
      type: 'SingleSelect',
      value: [],
    },
  ],
  [SETTINGS_TABS.SELF_PERFORM]: [
    {
      id: shortid.generate(),
      isSystem: true,
      isRequired: true,
      name: 'Name',
      type: 'Text',
      value: [],
    },
    {
      id: shortid.generate(),
      isSystem: true,
      isRequired: true,
      name: 'Skill set',
      type: 'MultiSelect',
      value: [],
    },
    {
      id: shortid.generate(),
      isSystem: true,
      isRequired: true,
      name: 'Cell number',
      type: 'PhoneNumber',
      value: [],
    },
  ],
};

const { PEOPLE, PROJECTS, FIELDS, SELF_PERFORM, SYSTEM, MANAGE, SELECTION_LISTS, CUSTOM_FIELDS, SYSTEM_FIELDS, ROLES, CUSTOM } = SETTINGS_SIDE_TABS_LABELS;

const SETTINGS_SIDE_TABS_FIELD_HEADLINES = {
  [SETTINGS_SIDE_TABS.SYSTEM]: {
    [SETTINGS_TABS.PEOPLE]: `${SYSTEM} ${PEOPLE} ${FIELDS}`,
    [SETTINGS_TABS.PROJECTS]: `${SYSTEM} ${PROJECTS} ${FIELDS}`,
    [SETTINGS_TABS.SELF_PERFORM]: `${SYSTEM} ${SELF_PERFORM} ${FIELDS}`,
  },
  [SETTINGS_SIDE_TABS.CUSTOM]: {
    [SETTINGS_TABS.PEOPLE]: `${CUSTOM} ${PEOPLE} ${FIELDS}`,
    [SETTINGS_TABS.PROJECTS]: `${CUSTOM} ${PROJECTS} ${FIELDS}`,
    [SETTINGS_TABS.SELF_PERFORM]: `${CUSTOM} ${SELF_PERFORM} ${FIELDS}`,
  },
};

const MANAGE_CUSTOM_SELECTION_LISTS = `${MANAGE} ${CUSTOM_FIELDS} ${SELECTION_LISTS}`;

const SETTINGS_SIDE_TABS_LIST_HEADLINES = {
  [SETTINGS_SIDE_TABS.SYSTEM]: {
    [SETTINGS_TABS.PEOPLE]: `${MANAGE} ${SYSTEM_FIELDS} ${SELECTION_LISTS}`,
    [SETTINGS_TABS.PROJECTS]: `${MANAGE} ${SYSTEM_FIELDS} ${SELECTION_LISTS}`,
    [SETTINGS_TABS.SELF_PERFORM]: `${SYSTEM} ${SELF_PERFORM} ${SELECTION_LISTS}`,
  },
  [SETTINGS_SIDE_TABS.CUSTOM]: {
    [SETTINGS_TABS.PEOPLE]: MANAGE_CUSTOM_SELECTION_LISTS,
    [SETTINGS_TABS.PROJECTS]: MANAGE_CUSTOM_SELECTION_LISTS,
    [SETTINGS_TABS.SELF_PERFORM]: MANAGE_CUSTOM_SELECTION_LISTS,
  },
  [SETTINGS_SIDE_TABS.ROLES]: {
    [SETTINGS_TABS.PROJECTS]: `${MANAGE} ${ROLES}`,
  },
};

// Max title cost supported by the API
const MAX_TITLE_COST = 16777216;

const ROLE_TEMPLATE_NAME_MAX_LENGTH = 50;

export {
  tabs,
  sideTabs,
  SETTINGS_SIDE_TABS,
  SETTINGS_TABS,
  SETTINGS_SIDE_TABS_LABELS,
  SETTINGS_SIDE_TABS_FIELD_HEADLINES,
  SETTINGS_SIDE_TABS_LIST_HEADLINES,
  COUNTRIES,
  MODULE_NAME_TRANSLATION,
  LIST_ITEM_IN_USE_MODAL_TEXT,
  ADDITIONAL_DEFAULT_FIELDS,
  DISABLED_FIELD_TYPES,
  MAX_TITLE_COST,
  ROLE_TEMPLATE_NAME_MAX_LENGTH,
};
