import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormField, EditControls } from 'src/features/wrapped-components';
import { validateBasicFields } from 'src/utils/validators';
import { getFieldProperties } from 'src/utils/miscUtils';
import {
  FIELD_INPUT_TYPE,
  FIELD_TYPE_MULTI_SELECT,
  SAVE,
  CANCEL,
} from 'src/common/constants';

export const FieldInputForm = ({
  fields,
  onSave,
  onCancel,
  validate,
  onSaveText,
  onCancelText,
  pending,
}) => {
  // Assign default values
  const [values, setValues] = useState(() => fields.reduce((acc, field) => {
    const { type, name } = field;
    const obj = { ...acc };
    switch (FIELD_INPUT_TYPE[type]) {
      case 'checkbox': {
        obj[name] = false;
        break;
      }
      case 'select': {
        obj[name] = type === FIELD_TYPE_MULTI_SELECT ? [] : '';
        break;
      }
      default:
        obj[name] = '';
    }
    return obj;
  }, {}));

  const [errors, setErrors] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const required = useMemo(() => fields.filter(field => field.isRequired).map(getFieldProperties), [fields]);
  const optional = useMemo(() => fields.filter(field => !field.isRequired).map(getFieldProperties), [fields]);

  // Validation
  useEffect(() => {
    const errors = {
      ...validateBasicFields(fields, values),
      ...validate(values),
    };

    setErrors(errors);
    setDisabled(!!Object.keys(errors).length);
  }, [validate, fields, values]);

  const onChange = ({ target: { name, value, type } }) => {
    setValues({
      ...values,
      [name]: type === 'checkbox' ? !values[name] : value,
    });
  };

  const onFormSave = () => onSave(values);

  const renderFormField = focus => (field, index) => {
    const { name, type, placeholder, isRequired, options, width, maxLength } = field;
    return (
      <FormField
        key={name}
        className={name.toLowerCase()}
        type={FIELD_INPUT_TYPE[type]}
        label={name}
        name={name}
        value={values[name]}
        placeholder={placeholder}
        onChange={onChange}
        required={isRequired}
        autoFocus={focus && index === 0}
        multi={type === FIELD_TYPE_MULTI_SELECT}
        options={options}
        width={width}
        error={errors && errors[name]}
        maxLength={maxLength}
      />
    );
  };

  return (
    <div className="common-field-input-form">
      <div className="input-header required">Required Fields</div>
      <div className="input-container">
        {required.map(renderFormField(true))}
      </div>
      <div className="input-header">Other Fields</div>
      <div className="input-container">
        {optional.map(renderFormField(false))}
      </div>
      <EditControls
        primaryText={onSaveText}
        primaryAction={onFormSave}
        secondaryText={onCancelText}
        secondaryAction={onCancel}
        disabled={disabled}
        pending={pending}
      />
    </div>
  );
};

FieldInputForm.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object),
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  validate: PropTypes.func,
  onSaveText: PropTypes.string,
  onCancelText: PropTypes.string,
  pending: PropTypes.bool,
};

FieldInputForm.defaultProps = {
  fields: [],
  onSave: () => {},
  onCancel: () => {},
  validate: () => {},
  onSaveText: SAVE,
  onCancelText: CANCEL,
  pending: false,
};

export default FieldInputForm;
