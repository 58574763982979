import moment from 'moment';
import {
  CERTIFICATIONS,
  CERTIFICATIONS_ID,
  CERTIFICATIONS_EXPIRY_DATE,
  CERTIFICATIONS_CONTROL_EXPIRING_SOON,
  CERTIFICATIONS_CONTROL_EXPIRED,
  MUST,
  MUST_NOT,
} from './constants';
import { DATE_INPUT_FORMAT } from '../common/constants';

const generateCertificationFilterQuery = ({ selected, inclusive }) => {
  const today = moment().format(DATE_INPUT_FORMAT);
  const mainShould = [];
  const boolStatement = inclusive ? MUST : MUST_NOT;
  const hasExpiringSoon = !!selected.find(({ value }) => value === CERTIFICATIONS_CONTROL_EXPIRING_SOON);
  const hasExpired = !!selected.find(({ value }) => value === CERTIFICATIONS_CONTROL_EXPIRED);

  const ids = selected.reduce((acc, current) => {
    const { value } = current;
    if (![CERTIFICATIONS_CONTROL_EXPIRING_SOON, CERTIFICATIONS_CONTROL_EXPIRED].includes(value)) {
      acc.push(value);
    }
    return acc;
  }, []);

  // If expiring soon is selected, construct expiring soon for each selected certifications
  if (hasExpiringSoon) {
    if (!ids.length) {
      // No certifications selected, match on any expiring soon cert
      mainShould.push({
        match: { certificationsExpiringSoon: true },
      });
    } else {
      mainShould.push({
        match: { certificationsExpiringSoonIds: ids },
      });
    }
  }

  // If expired is selected, construct the expired query matching any cert IDs if necessary
  if (hasExpired) {
    const expiredFilter = [];

    if (!ids.length) {
      // No certifications selected, match on any expired cert
      expiredFilter.push({
        range: {
          [CERTIFICATIONS_EXPIRY_DATE]: {
            lte: today,
          },
        },
      });
    } else {
      ids.forEach(id => expiredFilter.push({
        bool: {
          must: [
            {
              range: {
                [CERTIFICATIONS_EXPIRY_DATE]: {
                  lte: today,
                },
              },
            },
            { match: { [CERTIFICATIONS_ID]: id } },
          ],
        },
      }));
    }

    mainShould.push({
      nested: {
        path: CERTIFICATIONS,
        query: {
          bool: {
            should: expiredFilter,
          },
        },
      },
    });
  }

  // If neither of the special filters are applied, just match on certification IDs
  if (!hasExpiringSoon && !hasExpired) {
    mainShould.push({
      nested: {
        path: CERTIFICATIONS,
        query: {
          bool: {
            should: ids.map(id => ({ match: { [CERTIFICATIONS_ID]: id } })),
          },
        },
      },
    });
  }

  return {
    bool: {
      [boolStatement]: [
        {
          bool: {
            should: mainShould,
          },
        },
      ],
    },
  };
};

const generateCertificationsFilter = (activeFilters) => {
  const must = activeFilters.map(activeFilter => generateCertificationFilterQuery(activeFilter));
  return {
    bool: {
      must,
    },
  };
};

export default generateCertificationsFilter;
