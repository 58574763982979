import { call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import {
  FORECAST_GET_UTILIZATION_DATA_BEGIN,
  FORECAST_GET_UTILIZATION_DATA_SUCCESS,
  FORECAST_GET_UTILIZATION_DATA_FAILURE,
  FORECAST_GET_UTILIZATION_DATA_DISMISS_ERROR,
} from './constants';
import { getUtilizationDataQuery } from '../queries/getUtilizationDataQuery';
import graphApiClient from '../../../common/GraphAPIClient';
import { MONTH_YEAR_FORMAT } from '../../../common/constants';

export function getUtilizationData(
  accountId,
  queries = {},
  trueValue = false,
  overviewRange = 3,
  isPursuitsEnabled,
  pursuitFilter = [],
) {
  const includePursuitIds = pursuitFilter.map(pursuit => pursuit.id);
  const { query, variables } = getUtilizationDataQuery(accountId, queries, trueValue, overviewRange, isPursuitsEnabled, includePursuitIds);

  return {
    type: FORECAST_GET_UTILIZATION_DATA_BEGIN,
    query,
    variables,
  };
}

export function dismissgetUtilizationDataError() {
  return {
    type: FORECAST_GET_UTILIZATION_DATA_DISMISS_ERROR,
  };
}

export function* doGetUtilizationData(action) {
  const { query, variables } = action;

  let res;

  try {
    res = yield call(graphApiClient.query, { query, variables });
    const { errors } = res;
    if (errors) {
      throw errors;
    }
  } catch (err) {
    yield put({
      type: FORECAST_GET_UTILIZATION_DATA_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: FORECAST_GET_UTILIZATION_DATA_SUCCESS,
    data: res.data,
  });
}

export function* watchgetUtilizationData() {
  yield takeLatest(FORECAST_GET_UTILIZATION_DATA_BEGIN, doGetUtilizationData);
}

export function reducer(state, action) {
  switch (action.type) {
    case FORECAST_GET_UTILIZATION_DATA_BEGIN:
      return {
        ...state,
        getUtilizationDataPending: true,
        getUtilizationDataError: null,
      };

    case FORECAST_GET_UTILIZATION_DATA_SUCCESS: {
      const { awardedOnlyOverview, scenarioOverview } = action.data;

      return {
        ...state,
        ...action.data,
        awardedOnlyOverview: {
          ...awardedOnlyOverview,
          items: awardedOnlyOverview?.items?.map(item => ({
            ...item,
            x: moment(item.x, MONTH_YEAR_FORMAT),
          })) || [],
        },
        ...scenarioOverview && {
          scenarioOverview: {
            ...scenarioOverview,
            items: scenarioOverview.items?.map(item => ({
              ...item,
              x: moment(item.x, MONTH_YEAR_FORMAT),
            })) || [],
          },
        },
        getUtilizationDataPending: false,
        getUtilizationDataError: null,
      };
    }

    case FORECAST_GET_UTILIZATION_DATA_FAILURE:
      return {
        ...state,
        getUtilizationDataPending: false,
        getUtilizationDataError: action.data.error,
      };

    case FORECAST_GET_UTILIZATION_DATA_DISMISS_ERROR:
      return {
        ...state,
        getUtilizationDataError: null,
      };

    default:
      return state;
  }
}
