import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DOT } from '../../common/constants';
import { EXCLUDE_PROJECTS_COLUMNS } from './redux/constants';
import { getProjectValue, getFieldValue } from './utils/reportUtils';
import { ProjectAllocations } from '.';

const ReportProjectList = ({
  projects,
  allocations,
  includeAllocations,
  visibleColumns,
}) => {
  const displayColumns = useMemo(() => (
    visibleColumns.filter(column => EXCLUDE_PROJECTS_COLUMNS.indexOf(column.name) === -1)
  ), [visibleColumns]);

  const showStatus = useMemo(() => (
    !!visibleColumns.find(column => column.name === 'Status')
  ), [visibleColumns]);

  const renderStatus = status => (
    <div className={`status ${status.toLowerCase()}`}>
      <span>{`${DOT} ${status}`}</span>
    </div>
  );

  const renderFields = (project) => {
    const fields = [];
    let other;

    displayColumns.forEach((column) => {
      let fieldValue = getProjectValue(column, project);

      // check for null in case the value is empty or false
      if (fieldValue === null) {
        fieldValue = getFieldValue(column, project);
      }

      if (column.name === 'Other') {
        other = {
          value: fieldValue,
          columnName: column.name,
        };
      } else {
        fields.push(
          <div key={column.name} className="field">
            <div className="field-name"><span>{column.name}</span></div>
            <div className="field-value">{fieldValue}</div>
          </div>,
        );
      }
    });

    if (other) {
      // make sure other is always the last field
      fields.push(
        <div key="Other" className="field other">
          <div className="field-name">{other.columnName}</div>
          <div className="field-value">{other.value}</div>
        </div>,
      );
    }

    return (
      <div className="field-wrap">
        {fields}
      </div>
    );
  };

  return (
    <div className="reports-report-project-list">
      {projects.map(project => (
        <div key={project.id} className="project-row">
          <div className="project-info">
            <span className="dot" style={{ color: project.colour }}>{DOT}</span>
            <div className="name-info">
              <div className="name">{project.name}</div>
              {showStatus && renderStatus(project.state)}
            </div>
            {renderFields(project)}
          </div>
          {includeAllocations && (
            <ProjectAllocations
              project={project}
              allocations={allocations.find(allocation => allocation.projectId === project.id)}
            />
          )}
        </div>
      ))}
    </div>
  );
};

ReportProjectList.propTypes = {
  projects: PropTypes.array.isRequired,
  allocations: PropTypes.array,
  includeAllocations: PropTypes.bool,
  visibleColumns: PropTypes.array.isRequired,
};

ReportProjectList.defaultProps = {
  includeAllocations: false,
  allocations: [],
};

/* istanbul ignore next */
function mapStateToProps({ reports, table }) {
  const { settings, projectAllocations, projects } = reports;
  const { visibleColumns } = table;
  const { includeAllocations } = settings;

  return {
    projects,
    allocations: projectAllocations,
    visibleColumns,
    includeAllocations,
  };
}

export default connect(
  mapStateToProps,
)(ReportProjectList);
