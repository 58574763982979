import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'react-fast-compare';
import pluralize from 'pluralize';
import classNames from 'classnames';
import {
  Grid,
  Paper,
  Tooltip,
  Fab,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { PageHeader } from '../common';
import { AddPhaseForm, EditPhase, PhaseList } from '.';
import emptyImage from '../../images/Phases_EmptyState.svg?url';
import { PHASES_LIMIT } from '../../common/constants';

export class Phases extends PureComponent {
  static propTypes = {
    phases: PropTypes.array.isRequired,
    getPhasesPending: PropTypes.bool.isRequired,
    addPhasePending: PropTypes.bool.isRequired,
    updatePhasePending: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired,
    accountId: PropTypes.number.isRequired,
    addSubPhasePending: PropTypes.bool.isRequired,
    updateSubPhasePending: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      activePhase: null,
      phasesFetched: false,
      editingSubPhase: false,
      activeSubPhase: null,
    };
  }

  componentDidMount() {
    const { accountId, actions } = this.props;

    actions.getPhases(accountId);
  }

  componentDidUpdate(prevProps) {
    const { phases, getPhasesPending, accountId, actions } = this.props;
    const { phasesFetched, activePhase, activeSubPhase } = this.state;

    if (prevProps.accountId !== accountId) {
      actions.getPhases(accountId);
    }

    if (prevProps.getPhasesPending && !getPhasesPending) {
      this.setPhasesState({ phasesFetched: true });
    }

    if (!deepEqual(prevProps.phases, phases)) {
      if (phasesFetched && phases.length > prevProps.phases.length) {
        const newPhase = phases[phases.length - 1];
        this.setPhasesState({
          activePhase: newPhase,
        }, this.scrollDown);
      }
      if (activePhase) {
        const newActivePhase = phases.find(phase => phase.id === activePhase.id);
        this.setPhasesState({
          activePhase: newActivePhase,
        });
        if (activeSubPhase) {
          this.setPhasesState({
            activeSubPhase: newActivePhase.subPhases?.find(sp => sp.id === activeSubPhase.id),
          });
        }
      }
    }
  }

  setPhasesState = (newState, callBack) => this.setState(newState, callBack ? callBack() : null);

  scrollDown = () => {
    const list = document.querySelector('.scrollable');
    if (list) list.scrollTop = list.scrollHeight;
  }

  openCreatePhase = () => {
    this.setState({
      editing: true,
      activePhase: null,
      activeSubPhase: null,
      editingSubPhase: false,
    });
  }

  setEditingSubPhase = (value) => {
    this.setState({
      editingSubPhase: value,
      activeSubPhase: null,
    });
  }

  setActiveSubPhase = (e) => {
    const { activePhase } = this.state;
    const { id } = e.currentTarget.dataset;
    const activeSubPhase = activePhase.subPhases && activePhase.subPhases.find(sp => sp.id === parseInt(id, 10));
    e.stopPropagation();

    if (activePhase) {
      this.setState({
        activeSubPhase,
        activePhase,
        editingSubPhase: false,
      });
    }
  }

  setActivePhase = (e) => {
    const { phases } = this.props;
    const { id } = e.currentTarget.dataset;
    const activePhase = phases.find(phase => phase.id === parseInt(id, 10));

    if (activePhase) {
      this.setState({
        activePhase,
        editing: true,
        activeSubPhase: null,
        editingSubPhase: false,
      });
    }
  }

  closeEditor = () => {
    const { activeSubPhase } = this.state;
    if (activeSubPhase) {
      this.setState({
        activeSubPhase: null,
        editingSubPhase: false,
      });
    } else {
      this.setState({
        editing: false,
        activePhase: null,
      });
    }
  }

  deletePhase = (id) => {
    const { actions, accountId } = this.props;
    actions.deletePhase(accountId, [id]);
    this.closeEditor();
  }

  deleteSubPhase = (id) => {
    const { actions, accountId } = this.props;
    const { activePhase } = this.state;
    actions.deleteSubPhase(accountId, activePhase.id, [id]);
    this.setState({ activeSubPhase: null });
  }

  renderPhaseForm = () => {
    const { phases, actions, addPhasePending, updatePhasePending, addSubPhasePending, updateSubPhasePending } = this.props;
    const { activePhase, editingSubPhase, activeSubPhase } = this.state;

    return (
      <div className="phases-form">
        {activePhase
          ? (
            <EditPhase
              activePhase={activePhase}
              deletePhase={this.deletePhase}
              updatePhase={actions.updatePhase}
              updatePhasePending={updatePhasePending}
              closeEditor={this.closeEditor}
              phases={phases}
              addSubPhase={actions.addSubPhase}
              addSubPhasePending={addSubPhasePending}
              setEditingSubPhase={this.setEditingSubPhase}
              editingSubPhase={editingSubPhase}
              activeSubPhase={activeSubPhase}
              deleteSubPhase={this.deleteSubPhase}
              updateSubPhase={actions.updateSubPhase}
              updateSubPhasePending={updateSubPhasePending}
            />
          )
          : (
            <AddPhaseForm
              addPhase={actions.addPhase}
              addPhasePending={addPhasePending}
              cancel={this.closeEditor}
              phases={phases}
            />
          )}
      </div>
    );
  }

  updatePhases = (newPhases) => {
    const { accountId, actions } = this.props;
    actions.updatePhases(accountId, newPhases);
  }

  updateSubPhases = (newSubPhases) => {
    const { accountId, actions } = this.props;
    const { activePhase } = this.state;
    actions.updateSubPhases(accountId, activePhase.id, newSubPhases);
  }

  render() {
    const { phases, getPhasesPending } = this.props;
    const { editing, activePhase, activeSubPhase, editingSubPhase } = this.state;

    return (
      <Grid container spacing={3} className="account-settings-phases main-wrap">
        <Grid item xs={12}>
          <Paper className={classNames('paper__container', 'phases', { editing })}>
            <div className="phases-container">
              <div className="phase-count">
                <p>{pluralize('phase', phases.length, true)}</p>
                {phases.length >= PHASES_LIMIT && <p className="limit-warning">Limit for phases reached</p>}
              </div>
              <PageHeader headline="Phases" />
              { !phases.length && !editing
                ? (
                  <div className="empty-wrapper">
                    <img src={emptyImage} alt="emptyImg" />
                    <p>Click the blue + button to add your first phase</p>
                  </div>
                )
                : (
                  <PhaseList
                    phases={phases}
                    getPhasesPending={getPhasesPending}
                    activePhase={activePhase}
                    editing={editing}
                    activeSubPhase={activeSubPhase}
                    editingSubPhase={editingSubPhase}
                    setActivePhase={this.setActivePhase}
                    setActiveSubPhase={this.setActiveSubPhase}
                    updatePhases={this.updatePhases}
                    updateSubPhases={this.updateSubPhases}
                  />
                )}
              {((!editing || activePhase) && phases.length < PHASES_LIMIT) && (
                <div className="add-phase-button">
                  <Tooltip title="Add a phase" placement="top">
                    <Fab onClick={this.openCreatePhase} aria-label="Add a phase">
                      <Add />
                    </Fab>
                  </Tooltip>
                </div>
              )}
            </div>
            <div className="add-phases-container">
              {editing && this.renderPhaseForm()}
            </div>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps({ accountSettings, common }) {
  const {
    phases,
    getPhasesPending,
    addPhasePending,
    updatePhasePending,
    addSubPhasePending,
    updateSubPhasePending,
  } = accountSettings;
  return {
    phases,
    getPhasesPending,
    addPhasePending,
    updatePhasePending,
    addSubPhasePending,
    updateSubPhasePending,
    accountId: common.accountId,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  const {
    addPhase,
    getPhases,
    deletePhase,
    updatePhase,
    addSubPhase,
    deleteSubPhase,
    updateSubPhase,
    updatePhases,
    updateSubPhases,
  } = actions;
  return {
    actions: bindActionCreators({
      addPhase,
      getPhases,
      deletePhase,
      updatePhase,
      addSubPhase,
      deleteSubPhase,
      updateSubPhase,
      updatePhases,
      updateSubPhases,
    }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Phases);
