import { gql } from 'apollo-boost';
import moment from 'moment';
import { generateRequestFilter } from 'src/filters/graphqlUtils';
import { PEOPLE_SCHEMAS } from 'src/common/descriptors/person';
import { DATE_INPUT_FORMAT } from 'src/common/constants';

const BREAKDOWN_QUERY = gql`
    query breakdownQuery($accountId: Int!, $filters: [ESQuery], $trueValue: Boolean!, $breakdownStartDate: String!, $breakdownEndDate: String!, $fieldId: Int!, $isPursuitsEnabled: Boolean!, $includePursuitIds: [Int]) {
      awardedOnlyBreakdown: forecastingBreakdown(accountId: $accountId, filters: $filters, trueValue: $trueValue, startDate: $breakdownStartDate, endDate: $breakdownEndDate fieldId: $fieldId, includePursuitIds: []) {
        ...breakdownFields
      }
      scenarioBreakdown: forecastingBreakdown(accountId: $accountId, filters: $filters, trueValue: $trueValue, startDate: $breakdownStartDate, endDate: $breakdownEndDate fieldId: $fieldId, includePursuitIds: $includePursuitIds) @include(if: $isPursuitsEnabled) {
        ...breakdownFields
      }
    }
    fragment breakdownFields on ForecastingBreakdownResponse {
      fieldId
      fieldName
      count
      items {
        percent
        name
        stats {
          fullyAllocated
          underAllocated
          overAllocated
        }
      }
    }
  `;

const getBreakdownQuery = (accountId, queries, trueValue, bDate, fieldId, isPursuitsEnabled, includePursuitIds) => {
  const breakdownStartDate = moment(bDate).startOf('month').format(DATE_INPUT_FORMAT);
  const breakdownEndDate = moment(bDate).endOf('month').format(DATE_INPUT_FORMAT);

  const { filter } = queries;
  const filters = generateRequestFilter(filter, PEOPLE_SCHEMAS);

  const variables = {
    accountId,
    filters,
    trueValue,
    breakdownStartDate,
    breakdownEndDate,
    fieldId,
    isPursuitsEnabled,
    includePursuitIds,
  };

  return {
    query: BREAKDOWN_QUERY,
    variables,
  };
};

export {
  getBreakdownQuery,
};
