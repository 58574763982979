const initialState = {
  filteredHourlyPersons: [],
  filteredRequests: [],
  hourlyPersonFieldValues: [],
  hourlyPeopleFieldValues: [],
  projectRequesters: [],
  bestMatches: [],
  otherMatches: [],
  pageFrom: 0,
  hasMore: true,
  getFilteredHourlyPersonsPending: false,
  getFilteredHourlyPersonsPagePending: false,
  getFilteredHourlyPersonsError: null,
  getFilteredHourlyPersonsPageError: null,
  addHourlyPersonPending: false,
  addHourlyPersonError: null,
  getProjectRequestersPending: false,
  getProjectRequestersError: null,
  assignRequesterPending: false,
  assignRequesterError: null,
  deleteRequesterPending: false,
  deleteRequesterError: null,
  selectedHourlyPerson: null,
  selectedRequest: null,
  getHourlyPersonByIdPending: false,
  getHourlyPersonByIdError: null,
  getFilteredRequestsPending: false,
  getFilteredRequestsError: null,
  updateHourlyPersonPending: false,
  updateHourlyPersonError: null,
  updateHourlyPersonFieldValuePending: false,
  updateHourlyPersonFieldValueError: null,
  getHourlyPersonFieldValuesPending: false,
  getHourlyPersonFieldValuesError: null,
  addHourlyRequestPending: false,
  addHourlyRequestError: null,
  getHourlyPeopleFieldValuesPending: false,
  getHourlyPeopleFieldValuesError: null,
  getRequestByIdPending: false,
  getRequestByIdError: null,
  getBestMatchesPending: false,
  getBestMatchesError: null,
  deactivateHourlyPersonPending: false,
  deactivateHourlyPersonError: null,
  assignRequestPending: false,
  assignRequestError: null,
  sendNotificationPending: false,
  sendNotificationError: null,
  removeAssigneePending: false,
  removeAssigneeError: null,
  deleteRequestPending: false,
  deleteRequestError: null,
  updateHourlyRequestPending: false,
  updateHourlyRequestError: null,
};

export default initialState;
