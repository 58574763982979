import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { ProjectLinkToModalContainer } from '../common';
import { DATE_INPUT_FORMAT, DATE_DISPLAY_FORMAT } from '../../common/constants';

class AllocationInfo extends PureComponent {
  static propTypes = {
    personId: PropTypes.number,
    personAllocationsByDate: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    runsInModal: PropTypes.bool,
  };

  static defaultProps = {
    personId: null,
    personAllocationsByDate: {},
    runsInModal: false,
  }

  render() {
    const {
      loading,
      personAllocationsByDate,
      personId,
      runsInModal,
    } = this.props;
    const allocations = personAllocationsByDate[personId] || [];

    return (
      <div className="allocations-allocation-info">
        <div className="content-header">
          <span className="section-heading">Project</span>
          <span className="section-heading">Role</span>
          <span className="section-heading percent">%</span>
        </div>

        { !loading && allocations.map((a, index) => {
          const startFormatted = moment(a.startDate, DATE_INPUT_FORMAT).format(DATE_DISPLAY_FORMAT);
          const endFormatted = moment(a.endDate, DATE_INPUT_FORMAT).format(DATE_DISPLAY_FORMAT);

          /* eslint-disable react/no-array-index-key */
          return (
            <div key={index} className="content-row">
              <div className="project-name">
                <ProjectLinkToModalContainer
                  backgroundColor={a.projectColour}
                  projectName={a.projectName}
                  projectId={a.projectId}
                  launchedFrom="Place a Person Modal"
                  runsInModal={runsInModal}
                />
              </div>
              <div className="role-details">
                <span className="role-name">{a.roleName}</span>
                <span className="role-timeframe">{`${startFormatted} - ${endFormatted}`}</span>
              </div>
              <div className="allocation-percentage">{a.allocatedPercent}</div>
            </div>
          );
        })}
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps({ people }) {
  const { personAllocationsByDate, getPersonAllocationsByDatePending } = people;

  return {
    personAllocationsByDate,
    loading: getPersonAllocationsByDatePending,
  };
}

export default connect(
  mapStateToProps,
)(AllocationInfo);
