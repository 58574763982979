import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { BRIDGIT_LOGO, SUPPORTED_BROWSERS } from './constants';

const UnsupportedBrowser = () => {
  useEffect(() => {
    window.mixpanel.track('Unsupported Browser Message Displayed');
  }, []);

  const onClick = (browser, url) => () => {
    window.open(url, '_blank');
    window.mixpanel.track('Unsupported Browser - Browser Button Clicked', {
      'Browser Clicked': browser,
    });
  };

  return (
    <div className="common-unsupported-browser">
      <img className="logo" src={BRIDGIT_LOGO} alt="Bridgit Logo" />
      <hr className="separator" />
      <Typography variant="h5" color="primary">
        We&apos;re sorry. The browser you&apos;re using is not supported by Bridgit.
      </Typography>
      <Typography className="subtitle" variant="subtitle1" color="textPrimary">
        For the best experience, please use one of the following browsers.
      </Typography>
      <div className="icon-container">
        {SUPPORTED_BROWSERS.map(({ name, url, src }) => (
          <button type="button" onClick={onClick(name, url)} key={name}>
            <img src={src} alt="name" />
            <Typography className="subtitle">{name}</Typography>
          </button>
        ))}
      </div>
    </div>
  );
};

export default UnsupportedBrowser;
