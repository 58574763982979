import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import moment from 'moment';
import { CustomRoleRequirementModal } from 'src/features/allocations';
import { validateRoleUpdate, formatProjectRoles } from 'src/features/projects/utils/projectRolesUtils';
import { fitSegmentsToRange } from 'src/utils/dateSegmentUtils';
import { EditControls } from '../wrapped-components';
import { DATE_INPUT_FORMAT, MAX_ALLOCATION_PERCENT } from '../../common/constants';
import { QuickAddRoleSelector } from './QuickAddRoleSelector';
import { AddRoleSelector } from './AddRoleSelector';
import { AddProjectRoleRow } from './AddProjectRoleRow';
import { RoleTemplateSelector } from '.';

export class EditProjectRoles extends PureComponent {
  static propTypes = {
    selectedProject: PropTypes.object.isRequired,
    roles: PropTypes.array,
    onSaveProjectRoles: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    addProjectRolesPending: PropTypes.bool.isRequired,
    getProjectRolesPending: PropTypes.bool.isRequired,
    initialRoles: PropTypes.arrayOf(PropTypes.object),
    parentName: PropTypes.string,
    projectRoles: PropTypes.arrayOf(PropTypes.object),
    onSelectItem: PropTypes.func.isRequired,
    projectsData: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    initialRoles: [],
    roles: [],
    projectRoles: [],
    projectsData: [],
    parentName: 'Project List',
  }

  constructor(props) {
    super(props);
    const { initialRoles } = props;

    const startDate = moment(props.selectedProject.startDate, DATE_INPUT_FORMAT);
    const endDate = moment(props.selectedProject.endDate, DATE_INPUT_FORMAT);

    this.rowId = 0;

    const initialProjectRoles = [];

    if (initialRoles.length) {
      initialRoles.reduce((initialRolesArray, currentRole) => {
        initialRolesArray.push({
          rowId: this.rowId,
          startDate,
          endDate,
          requirements: [
            {
              startDate,
              endDate,
              allocatedPercent: currentRole?.allocatedPercent || MAX_ALLOCATION_PERCENT,
            },
          ],
          roleId: currentRole.id,
          name: currentRole.name,
          note: null,
        });

        this.rowId += 1;

        return initialRolesArray;
      }, initialProjectRoles);
    }

    this.state = {
      valid: true,
      startDate,
      endDate,
      newProjectRoles: initialProjectRoles,
      showCustomModal: false,
      editingRowId: -1,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selectedProject, onCancel } = this.props;
    const { newProjectRoles } = this.state;

    if (selectedProject.id !== nextProps.selectedProject.id && newProjectRoles.length) {
      onCancel();
    }
  }

  onSave = () => {
    const { onSaveProjectRoles, addProjectRolesPending } = this.props;
    const { newProjectRoles } = this.state;

    if (addProjectRolesPending) {
      return;
    }

    const formattedRoles = formatProjectRoles(newProjectRoles);

    onSaveProjectRoles(formattedRoles);
  }

  onShowModal = (evt) => {
    const { rowid } = evt.currentTarget.dataset;

    this.setState({
      showCustomModal: true,
      editingRowId: Number(rowid),
    });
  }

  onHideModal = () => {
    this.setState({
      showCustomModal: false,
      editingRowId: -1,
    });
  }

  getRoleName = (roleId) => {
    const { roles } = this.props;
    const role = roles.find(r => r.id === roleId);
    if (role && role.name) {
      return role.name;
    }
    return '';
  }

  copyProjectRole = (rowId) => {
    this.rowId += 1;

    const { newProjectRoles } = this.state;
    const indexToCopy = newProjectRoles.findIndex(projectRole => projectRole.rowId === rowId);
    const duplicateProjectRole = { ...newProjectRoles[indexToCopy], rowId: this.rowId };
    const projectRolesCollection = [...newProjectRoles];
    projectRolesCollection.splice((indexToCopy === -1 ? 0 : indexToCopy + 1), 0, duplicateProjectRole);

    this.setState({
      newProjectRoles: projectRolesCollection,
    });
  }

  addNewProjectRole = (newRoles) => {
    const { newProjectRoles, startDate, endDate } = this.state;

    const additionalProjectRoles = newRoles.reduce((initialRolesArray, currentRole) => {
      this.rowId += 1;

      initialRolesArray.push({
        rowId: this.rowId,
        startDate,
        endDate,
        requirements: [
          {
            startDate,
            endDate,
            allocatedPercent: currentRole?.allocatedPercent || MAX_ALLOCATION_PERCENT,
          },
        ],
        roleId: currentRole.id,
        name: currentRole.name,
        note: currentRole.note,
      });

      return initialRolesArray;
    }, []);

    const updatedProjectRoles = [...additionalProjectRoles, ...newProjectRoles];

    this.setState({
      newProjectRoles: updatedProjectRoles,
    });
  }

  removeResource = (rowId) => {
    const { newProjectRoles } = this.state;
    const indexToRemove = newProjectRoles.findIndex(role => role.rowId === rowId);
    const updatedResources = [...newProjectRoles];
    updatedResources.splice(indexToRemove, 1);

    this.setState({
      newProjectRoles: updatedResources,
      valid: this.validate(updatedResources),
    });
  }

  validate = (newProjectRoles) => {
    const { selectedProject } = this.props;
    return newProjectRoles.every(role => validateRoleUpdate(selectedProject, role));
  }

  onRoleTypeUpdate = (rowId, roleId) => {
    this.updateRole(rowId, { roleId });
  }

  updateRole = (rowId, values) => {
    const { newProjectRoles } = this.state;
    const { selectedProject } = this.props;
    const updatedResources = [...newProjectRoles];
    const index = newProjectRoles.findIndex(res => res.rowId === rowId);
    const newValues = { ...newProjectRoles[index] };

    const defaultRequirements = [{
      startDate: selectedProject.startDate,
      endDate: selectedProject.endDate,
      allocatedPercent: MAX_ALLOCATION_PERCENT,
    }];

    if (!newValues.requirements.length) {
      newValues.requirements = defaultRequirements;
    }

    if ('roleId' in values) {
      newValues.name = this.getRoleName(values.roleId);
      newValues.roleId = values.roleId;
    }

    if ('note' in values) {
      newValues.note = values.note;
    }

    if ('startDate' in values) {
      const newStartDate = moment(values.startDate);

      newValues.startDate = newStartDate;
      newValues.requirements = fitSegmentsToRange(newValues.requirements, newStartDate, newValues.endDate);
      newValues.selectedPhases = [];
    }

    if ('endDate' in values) {
      const newEndDate = moment(values.endDate);

      newValues.endDate = newEndDate;
      newValues.requirements = fitSegmentsToRange(newValues.requirements, newValues.startDate, newEndDate);
      newValues.selectedPhases = [];
    }

    if ('requirements' in values) {
      newValues.requirements = values.requirements;
    }

    if ('selectedPhases' in values) {
      newValues.selectedPhases = values.selectedPhases;
    }

    updatedResources[index] = {
      ...newValues,
    };

    this.setState({
      newProjectRoles: updatedResources,
      valid: this.validate(updatedResources),
    });
  }

  updateRoleRequirements = (rowId, requirements) => {
    const { newProjectRoles } = this.state;
    const updatedResources = [...newProjectRoles];
    const index = newProjectRoles.findIndex(res => res.rowId === rowId);

    const newValues = { ...newProjectRoles[index] };

    newValues.requirements = requirements;

    updatedResources[index] = {
      ...newValues,
    };

    this.setState({
      newProjectRoles: updatedResources,
      showCustomModal: false,
      valid: this.validate(updatedResources),
    });
  }

  clearRequirements = (rowId) => {
    const { newProjectRoles } = this.state;
    const updatedResources = [...newProjectRoles];
    const index = newProjectRoles.findIndex(res => res.rowId === rowId);

    const newValues = { ...newProjectRoles[index] };

    newValues.requirements = [{
      startDate: newValues.startDate,
      endDate: newValues.endDate,
      allocatedPercent: newValues.requirements?.[0]?.allocatedPercent || MAX_ALLOCATION_PERCENT,
    }];

    updatedResources[index] = {
      ...newValues,
    };

    this.setState({
      newProjectRoles: updatedResources,
      valid: this.validate(updatedResources),
    });
  }

  render() {
    const {
      valid,
      startDate,
      endDate,
      newProjectRoles,
      showCustomModal,
      editingRowId,
    } = this.state;
    const {
      onCancel,
      selectedProject,
      addProjectRolesPending,
      parentName,
      projectRoles,
      onSelectItem,
      getProjectRolesPending,
      projectsData,
      roles,
    } = this.props;
    const disabled = !(valid && newProjectRoles.length);
    const role = newProjectRoles.find(role => role.rowId === editingRowId);

    const hasPhases = selectedProject?.phases?.length > 0;

    return (
      <div className="projects-edit-project-roles">
        <div className="roles-controls-container">
          <span className="roles-subtitle">Roles on Project</span>
          <div className="role-control-wrapper">
            <AddRoleSelector
              onSelectRole={this.addNewProjectRole}
              project={selectedProject}
              roles={roles}
            />
            <QuickAddRoleSelector
              projects={projectsData}
              onSelectItem={onSelectItem}
              projectRoles={projectRoles}
              getProjectRolesPending={getProjectRolesPending}
              onSave={this.addNewProjectRole}
              selectedProject={selectedProject}
            />
            <RoleTemplateSelector
              onSave={this.addNewProjectRole}
              selectedProject={selectedProject}
            />
          </div>
        </div>
        <Table
          padding="none"
          className={classNames('resource-role-table',
            { 'has-phases': hasPhases })}
        >
          <TableHead>
            <TableRow>
              <TableCell className="roles-column">Roles</TableCell>
              <TableCell className="note-column">Note</TableCell>
              { hasPhases && (
                <TableCell>Quick Options</TableCell>
              )}
              <TableCell className="date-column">Start date</TableCell>
              <TableCell className="date-column">End date</TableCell>
              <TableCell className="percentage-column">Allocation %</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {newProjectRoles.map(res => (
              <AddProjectRoleRow
                key={res.rowId}
                selectedRole={res}
                selectedProject={selectedProject}
                onRoleTypeUpdate={this.onRoleTypeUpdate}
                updateRole={this.updateRole}
                clearRequirements={this.clearRequirements}
                onShowModal={this.onShowModal}
                removeResource={this.removeResource}
                startDate={startDate}
                endDate={endDate}
                parentName={parentName}
                copyRole={this.copyProjectRole}
                roleNote={res.note}
                selectedPhases={res.selectedPhases}
              />
            ))}

          </TableBody>
        </Table>
        <EditControls
          primaryAction={this.onSave}
          secondaryAction={onCancel}
          disabled={disabled}
          primaryText="Save Roles"
          pending={addProjectRolesPending}
        />

        {showCustomModal && (
          <CustomRoleRequirementModal
            selectedProject={selectedProject}
            onHideModal={this.onHideModal}
            role={role}
            onSave={this.updateRoleRequirements}
            selectedPhases={role?.selectedPhases || []}
          />
        )}
      </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = ({ projects, accountSettings }) => {
  const {
    addProjectRolesPending,
    getProjectRolesPending,
    projectRoles,
  } = projects;
  const { roles } = accountSettings;

  return {
    addProjectRolesPending,
    getProjectRolesPending,
    roles,
    projectRoles,
  };
};

export default connect(
  mapStateToProps,
)(EditProjectRoles);
