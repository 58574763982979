import { gql } from 'apollo-boost';
import moment from 'moment';
import { generateRequestFilter } from 'src/filters/graphqlUtils';
import { PEOPLE_SCHEMAS } from 'src/common/descriptors/person';
import { DATE_INPUT_FORMAT } from 'src/common/constants';

const BENCH_COST_OVERVIEW_QUERY = gql`
    query benchCostOverview($accountId: Int!, $filters: [ESQuery], $startDate: String!, $endDate: String!, $includePursuitIds: [Int]) {
      benchCostOverview: benchCostOverview(accountId: $accountId, filters: $filters, startDate: $startDate, endDate: $endDate, includePursuitIds: $includePursuitIds) {
        items {
          x
          y
        }
        count
        noCostRateCount
        costsSet
      }
    }
  `;

const getBenchCostOverviewQuery = (accountId, queries, startDate, endDate, includePursuitIds = []) => {
  const overviewStartDate = moment(startDate).format(DATE_INPUT_FORMAT);
  const overviewEndDate = moment(endDate).format(DATE_INPUT_FORMAT);

  const { filter } = queries;
  const filters = generateRequestFilter(filter, PEOPLE_SCHEMAS);

  const variables = {
    accountId,
    filters,
    startDate: overviewStartDate,
    endDate: overviewEndDate,
    includePursuitIds,
  };

  return {
    query: BENCH_COST_OVERVIEW_QUERY,
    variables,
  };
};

export {
  getBenchCostOverviewQuery,
};
