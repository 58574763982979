import { cloneDeep } from 'src/utils/miscUtils';
import {
  SORT_ASC,
  SORT_DESC,
  FILTER_CERTIFICATIONS,
  FILTER_COST_RATE,
  FILTER_ISSUES,
  FILTER_ROLES,
  FIELDS,
  FIELDS_NAME,
  FIELDS_VALUE_TEXT_SORT,
  CUSTOM_VALUE_PATHS,
  FILTER_AVAILABILITIES,
  FILTER_ALLOCATION,
  FILTER_PROJECT_DATE,
  FILTER_GENERIC_PROJECT_SEARCH,
  FILTER_GENERIC_PEOPLE_SEARCH,
  FILTER_ALLOCATED_PEOPLE,
  FILTER_PHASE,
  FILTER_NAME,
  QUERY_PARAM,
  EMPLOYMENT_DATES,
  FILTER_EMPLOYMENT_DATE_END,
  FILTER_EMPLOYMENT_DATE_START,
  FILTER_GENERIC_HOURLY_PERSONS_SEARCH,
  CURRENT_ASSIGNMENTS,
  UPCOMING_ASSIGNMENTS,
  PROJECT_NAME,
  START_DATE,
  END_DATE,
  FILLED_ROLES,
  UNFILLED_ROLES,
  NEXT_AVAILABILITY,
  ASSIGNEE,
  REQUESTER_NAME,
  NEXT_ALLOCATIONS_START_DATE,
  NEXT_ALLOCATIONS,
} from 'src/filters/constants';
import { DEFAULT_PROJECT_ROLE_SORT_BY_OPTION } from '../features/gantt/redux/constants';
import generateSingleSelectFilter from './singleSelectFilter';
import generatePropertyFilter from './propertyFilter';
import generateMultiSelectFilter from './multiSelectFilter';
import generatePhaseFilter from './phaseFilter';
import generateAvailabilityFilter from './availabilityFilter';
import generateCostRateFilter from './costRateFilter';
import generateTextFilter from './textFilter';
import generateProjectAllocationFilter from './projectAllocationFilter';
import generateProjectDateFilter from './projectDateFilter';
import generateDateFilter from './dateFilter';
import generateRolesFilter from './rolesFilter';
import generateIssueFilter from './issueFilter';
import generateBooleanFilter from './booleanFilter';
import generateCurrencyFilter from './currencyFilter';
import generateNameSearchFilter from './nameSearchFilter';
import generateEmploymentDateFilter from './employmentDateFilter';
import generateAllocatedPeopleFilter from './allocatedPeopleFilter';
import generateCertificationsFilter from './certificationsFilter';
import {
  generateGenericProjectSearchFilter,
  generateGenericPeopleSearchFilter,
  generateGenericHourlyPersonsSearchFilter,
} from './genericSearchFilter';
import { SORT_TYPES } from '../common/constants';

const { REQUEST_LIST, ASSIGNMENT_LIST } = SORT_TYPES;

const collateFieldValues = entity => entity.reduce((acc, current) => {
  const { fields } = current;

  const compressedFields = fields
    ? fields.map(field => ({
      type: field.dataType,
      name: field.name,
      isPrivate: field.isPrivate,
      values: field.values?.filter(value => value !== null) ?? [],
      __typename: field.__typename,
    }))
    : [];

  return acc.concat({
    ...current,
    fields: compressedFields,
  });
}, []);

/*
  Accepts an object in the shape of a regular query arg.
  Note that some extra fields have been omitted:
  {
    args: [
      {
        activeFilters: [
          {
            value: true,
          },
        ],
        column: 'paramKey',
        filterType: 'QUERY_PARAM',
      },
    ],
  }
*/
const getQueryParamsFromFilters = (filters = {}) => {
  if (!(filters && Array.isArray(filters.args) && filters.args.length)) return [];

  const params = filters.args.filter(arg => arg.filterType === QUERY_PARAM);

  if (!(Array.isArray(params))) return [];

  return params.reduce((params, arg) => {
    if ('column' in arg
      && typeof arg.column === 'string'
      && Array.isArray(arg.activeFilters)
      && typeof arg.activeFilters[0] === 'object'
      && 'value' in arg.activeFilters[0]
    ) {
      params.push({ [arg.column]: arg.activeFilters[0].value });
    }

    return params;
  }, []);
};

const generateProjectRoleFilter = (filters = []) => {
  if (!filters) return [];

  const query = [];
  const roleNameIds = [];
  let hasUnfilled = false;
  let hasFilled = false;

  filters.forEach((filter) => {
    if (filter.value === UNFILLED_ROLES) {
      hasUnfilled = true;
    } else if (filter.value === FILLED_ROLES) {
      hasFilled = true;
    } else {
      roleNameIds.push(filter.id);
    }
  });

  if ((hasUnfilled || hasFilled) && !(hasUnfilled && hasFilled)) {
    const value = hasUnfilled ? UNFILLED_ROLES : FILLED_ROLES;
    query.push({
      filterKey: 'filledState',
      filter: { value },
    });
  }

  if (roleNameIds.length) {
    query.push({
      filterKey: 'roleNameIds',
      filter: {
        value: roleNameIds,
      },
    });
  }

  return query;
};

const generateProjectRoleSort = (roleSortOption) => {
  const roleNameSort = { sortOrder: { order: 'asc' } };
  const startDateSort = { startDate: { order: 'asc' } };
  const endDateSort = { endDate: { order: 'asc' } };

  return roleSortOption === DEFAULT_PROJECT_ROLE_SORT_BY_OPTION
    ? [startDateSort, endDateSort, roleNameSort]
    : [roleNameSort, startDateSort, endDateSort];
};

const generateRequestFilter = (filterQuery, entityProperties) => {
  const filters = [];

  if (filterQuery?.args?.length) {
    filterQuery.args.forEach((argument) => {
      const { column, activeFilters, type, filterType } = argument;

      /*
        QUERY_PARAM filters are a special case, and are translated into
        variables included with the request, instead of being treated as
        filter clauses.
      */
      if (filterType === QUERY_PARAM) return;

      if (filterType === FILTER_AVAILABILITIES) {
        filters.push(generateAvailabilityFilter(activeFilters));
      } else if (filterType === FILTER_CERTIFICATIONS) {
        filters.push(generateCertificationsFilter(activeFilters));
      } else if (filterType === FILTER_COST_RATE) {
        filters.push(generateCostRateFilter(activeFilters[0]));
      } else if (filterType === FILTER_ALLOCATION) {
        filters.push(generateProjectAllocationFilter(activeFilters));
      } else if (filterType === FILTER_ALLOCATED_PEOPLE) {
        filters.push(generateAllocatedPeopleFilter(activeFilters));
      } else if (filterType === FILTER_PROJECT_DATE) {
        filters.push(generateProjectDateFilter(activeFilters[0], column));
      } else if (filterType === FILTER_ROLES) {
        filters.push(generateRolesFilter(activeFilters));
      } else if (filterType === FILTER_ISSUES) {
        filters.push(generateIssueFilter(activeFilters));
      } else if (filterType === FILTER_NAME) {
        filters.push(generateNameSearchFilter(activeFilters[0]));
      } else if (type === 'Address' || type === 'Text') {
        filters.push(generateTextFilter(activeFilters, column));
      } else if (type === 'MultiSelect') {
        filters.push(generateMultiSelectFilter(activeFilters, column));
      } else if (filterType === FILTER_PHASE) {
        filters.push(generatePhaseFilter(activeFilters));
      } else if (filterType === FILTER_EMPLOYMENT_DATE_END || filterType === FILTER_EMPLOYMENT_DATE_START) {
        filters.push(generateEmploymentDateFilter(activeFilters[0], column));
      } else if (type === 'Date') {
        filters.push(generateDateFilter(activeFilters[0], column));
      } else if (filterType === FILTER_GENERIC_PROJECT_SEARCH) {
        const genericSearchFilter = generateGenericProjectSearchFilter(activeFilters[0]);
        if (genericSearchFilter) {
          filters.push(genericSearchFilter);
        }
      } else if (filterType === FILTER_GENERIC_PEOPLE_SEARCH) {
        const genericSearchFilter = generateGenericPeopleSearchFilter(activeFilters[0]);
        if (genericSearchFilter) {
          filters.push(genericSearchFilter);
        }
      } else if (filterType === FILTER_GENERIC_HOURLY_PERSONS_SEARCH) {
        const genericSearchFilter = generateGenericHourlyPersonsSearchFilter(activeFilters[0]);

        if (genericSearchFilter) {
          filters.push(genericSearchFilter);
        }
      } else if (type === 'Boolean') {
        const booleanFilter = generateBooleanFilter(activeFilters, column);
        if (booleanFilter !== null) {
          filters.push(booleanFilter);
        }
      } else if (type === 'Currency') {
        filters.push(generateCurrencyFilter(activeFilters[0], column));
      } else if (entityProperties.includes(column)) { // Filtering against a top-level property
        filters.push(generatePropertyFilter(activeFilters, column));
      } else { // Filtering against a custom field
        filters.push(generateSingleSelectFilter(activeFilters, column));
      }
    });
  }

  return filters.flat();
};

const generateRequestSort = (sortQuery, entityProperties, defaultSort, sortType) => {
  if (!(sortQuery && Array.isArray(sortQuery.args))) return defaultSort;

  const args = sortQuery.args[0];
  const { column, ascending, type } = args;
  const order = ascending ? SORT_ASC : SORT_DESC;
  const reverseOrder = ascending ? SORT_DESC : SORT_ASC;
  const valuePath = CUSTOM_VALUE_PATHS[type] || FIELDS_VALUE_TEXT_SORT;
  const isEmploymentStartDate = column === 'employmentDates__startDate';
  const isEmploymentEndDate = column === 'employmentDates__endDate';
  const isNextAllocationsStartDate = column === NEXT_ALLOCATIONS_START_DATE;
  const isCurrentAssignments = column === CURRENT_ASSIGNMENTS;

  const isUpcomingAssignments = column === UPCOMING_ASSIGNMENTS;
  const isAssignmentSort = isCurrentAssignments || isUpcomingAssignments;

  const isRequestRequesterSort = column === REQUESTER_NAME;

  const isRequestsAssignmentsProjectNameSort = [REQUEST_LIST, ASSIGNMENT_LIST].includes(sortType) && column === PROJECT_NAME;
  const isAssignmentsAssigneeSort = sortType === ASSIGNMENT_LIST && column === ASSIGNEE;

  // Primary sort: % of availability as of now.
  //  A value of 100 will be at the top when sort is descending.
  //    asc: 100-0
  //    desc: 0-100
  // Secondary sort: number of days until a person is available (reverse).
  //  This will be 0 if person is available now and 9999 if a person has no availability.
  //    asc: 0-9999
  //    desc: 9999-0
  // Tertiary sort: sort available people by number of days that a person is available for.
  //  If a person has no upcoming allocations or unavailabilities, this value is 8888.
  //    asc: 8888-0
  //    desc: 0-8888
  if (column === NEXT_AVAILABILITY) {
    return [
      {
        percentAvailableToday: {
          order: reverseOrder,
        },
      },
      {
        availableInDays: {
          order,
        },
      },
      {
        availableFor: {
          order: reverseOrder,
        },
      },
      ...defaultSort,
    ];
  }

  if (isEmploymentStartDate || isEmploymentEndDate) {
    return [
      {
        employmentDates: {
          order,
          nested: {
            path: EMPLOYMENT_DATES,
            filter: {
              term: isEmploymentStartDate ? START_DATE : END_DATE,
            },
          },
        },
      },
      ...defaultSort,
    ];
  }

  if (isNextAllocationsStartDate) {
    return [
      {
        [NEXT_ALLOCATIONS_START_DATE]: {
          order,
          nested: {
            path: NEXT_ALLOCATIONS,
            filter: {
              term: START_DATE,
            },
          },
        },
      },
      ...defaultSort,
    ];
  }

  if (isAssignmentSort) {
    return [
      {
        assignments: {
          order,
          nested: {
            path: isCurrentAssignments ? CURRENT_ASSIGNMENTS : UPCOMING_ASSIGNMENTS,
            filter: {
              term: isUpcomingAssignments ? START_DATE : END_DATE,
            },
          },
        },
      },
      {
        assignments: {
          order: SORT_ASC,
          nested: {
            path: isCurrentAssignments ? CURRENT_ASSIGNMENTS : UPCOMING_ASSIGNMENTS,
            filter: {
              term: PROJECT_NAME,
            },
          },
        },
      },
      ...defaultSort,
    ];
  }

  if (isRequestRequesterSort) {
    return [
      {
        requester: {
          order,
          nested: {
            path: 'requester',
            filter: {
              term: 'name',
            },
          },
        },
      },
    ];
  }

  if (isRequestsAssignmentsProjectNameSort) {
    return [
      {
        project: {
          order,
          nested: {
            path: 'project',
            filter: {
              term: 'name',
            },
          },
        },
      },
    ];
  }

  if (isAssignmentsAssigneeSort) {
    return [
      {
        [ASSIGNEE]: {
          order,
          nested: {
            path: ASSIGNEE,
            filter: {
              term: 'name',
            },
          },
        },
      },
    ];
  }

  // Sort on top-level or computed prop
  if (entityProperties.includes(column)) {
    const sort = [];

    // Conditional rendering of the roles column
    // requires that we also sort by totalRoles
    // Must also query graphQL with "unfilledRoles" and not "roles" to get properly sorted results
    if (column === 'roles') {
      sort.push({
        unfilledRoles: {
          order,
        },
      },
      {
        totalRoles: {
          order,
        },
      });
    } else {
      sort.push({
        [column]: {
          order,
        },
      });
    }

    return [...sort, ...defaultSort];
  }

  // Sort on custom field value
  return [
    {
      [valuePath]: {
        order,
        nested: {
          path: FIELDS,
          filter: {
            term: {
              [FIELDS_NAME]: column,
            },
          },
        },
      },
    },
    ...defaultSort,
  ];
};

const getUpdatedEntity = (typeName, entity, data) => {
  const dataArray = Array.isArray(data) ? data : [data];
  return dataArray.reduce(
    (dataArrayEntity, dataArrayData) => {
      const { id } = dataArrayData;
      const iterateProps = (item) => {
        if (!item) return item;

        if (!(typeof item === 'object')) return item;

        if (Array.isArray(item)) {
          return item.map(element => iterateProps(element));
        }

        if (typeof item === 'object') {
          // Try to match the object type and id against the given params
          if ('id' in item && '__typename' in item && item.id === id && item.__typename === typeName) {
            return {
              ...item,
              ...dataArrayData,
            };
          }

          // Iterate through child objects
          const updates = Object.entries(item).reduce((acc, current) => {
            const [key, value] = current;
            if (typeof value === 'object') {
              acc[key] = iterateProps(value);
            }

            return acc;
          }, {});

          return {
            ...item,
            ...updates,
          };
        }
        return item;
      };
      return iterateProps(cloneDeep(dataArrayEntity));
    },
    entity,
  );
};

const pickObjectProps = (obj, props) => {
  // Make sure object and properties are provided
  if (!obj || !props) throw new Error('Must supply target object and desired properties');

  const availableKeys = Object.keys(obj);

  const invalidProps = props.filter(prop => !availableKeys.includes(prop));

  if (invalidProps.length) {
    throw new Error(`Property mismatch: Target object contains no definition for key(s) ${invalidProps}`);
  }

  // Create new object
  const picked = {};

  // Loop through props and push to new object
  props.forEach((prop) => {
    picked[prop] = obj[prop];
  });

  // Return new object
  return picked;
};

export {
  collateFieldValues,
  getQueryParamsFromFilters,
  generateRequestFilter,
  generateRequestSort,
  generateProjectRoleFilter,
  generateProjectRoleSort,
  getUpdatedEntity,
  pickObjectProps,
};
