import moment from 'moment';
import { REQUIRED_SKILLS, START_DATE, END_DATE } from 'src/filters/constants';
import {
  MIN_API_CONFIGURABLE_DATE,
  MIN_API_CONFIGURABLE_DATE_MESSAGE,
  REQUEST_ADDED_FROM_MAP,
} from '../../../common/constants';
import { validateBasicInputs } from '../../../utils/validators';
import { DEFAULT_WORK_DAYS_OPTIONS } from '../constants';
import {
  getSelectedWorkDayValues,
  isNoWorkDaysWithinRange,
  isDateInSelectedWorkingDays,
  isSomeWorkingDaysOutsideOfRange,
} from './requestUtils';

const getRequesterPlaceholder = (selectedProjectId, projectRequesters) => {
  if (!selectedProjectId) return 'Select a project first';
  if (!projectRequesters.length) return 'No requesters have been added to this project';
  return 'Select Requester';
};

export const getAddRequestInputs = (skillSet, filteredProjects, selectedProjectId, projectRequesters, startDate) => [
  {
    id: 'description',
    label: 'Description',
    name: 'description',
    type: 'text',
    placeholder: 'Enter the task that needs to be done',
    required: true,
    maxLength: 100,
  },
  {
    id: REQUIRED_SKILLS,
    label: 'Skill set (select all that apply)',
    name: REQUIRED_SKILLS,
    type: 'select',
    placeholder: 'Select skill set',
    options: skillSet.map(skill => ({ label: skill.name, value: skill.name })),
    multi: true,
  },
  {
    id: 'project',
    label: 'Project',
    name: 'project',
    type: 'search',
    placeholder: 'Start typing to search for a project',
    options: filteredProjects.map(({ name, value, colour }) => ({ value, name, colour })),
    className: 'add-request-project-search-input',
    required: true,
    value: filteredProjects.find(({ value }) => value === selectedProjectId),
  },
  {
    id: 'requesterId',
    label: 'Requested by',
    name: 'requesterId',
    type: 'select',
    placeholder: getRequesterPlaceholder(selectedProjectId, projectRequesters),
    options: selectedProjectId
      ? projectRequesters.map(requester => ({ label: requester.name, value: requester.id }))
      : [],
    disabled: !selectedProjectId,
    className: !selectedProjectId ? 'disabled' : '',
  },
  {
    id: START_DATE,
    label: 'Start date',
    name: START_DATE,
    type: 'date',
    minDate: moment(MIN_API_CONFIGURABLE_DATE),
    minDateMessage: MIN_API_CONFIGURABLE_DATE_MESSAGE,
    required: true,
    width: 'quarter',
  },
  {
    id: END_DATE,
    label: 'End date',
    name: END_DATE,
    type: 'date',
    minDate: startDate ? moment(startDate) : moment(MIN_API_CONFIGURABLE_DATE),
    minDateMessage: startDate ? '' : MIN_API_CONFIGURABLE_DATE_MESSAGE,
    required: true,
    width: 'quarter',
    initDate: startDate ? moment(startDate).add(1, 'day') : moment(),
  },
  {
    id: 'workDays',
    label: 'Working days',
    name: 'workDays',
    type: 'checkbox',
    options: DEFAULT_WORK_DAYS_OPTIONS,
    multi: true,
    required: true,
    width: 'medium',
    className: 'working-days-container',
    defaultTouched: true,
  },
  {
    id: 'note',
    label: 'Notes',
    name: 'note',
    multi: true,
    maxLength: 2400,
    width: 'full',
    placeholder: 'Enter notes',
  },
];

export const validateAddRequestInputs = (inputs, values) => {
  const { startDate, endDate, workDays } = values;

  const errors = {
    ...validateBasicInputs(inputs, values),
  };

  if (startDate && endDate) {
    if (endDate.isBefore(startDate)) {
      errors.endDate = 'End date must be later than the start date';
    }

    if (startDate.isAfter(endDate)) {
      errors.startDate = 'Start date must be earlier than the end date';
    }

    if (isSomeWorkingDaysOutsideOfRange(workDays, startDate, endDate)) {
      errors.workDays = 'There are working days selected outside of the request start and end date';
    }

    if (isNoWorkDaysWithinRange(workDays, startDate, endDate)) {
      errors.workDays = 'There are no working days in the request date range';
    }
  }

  if (startDate && !isDateInSelectedWorkingDays(startDate, workDays)) {
    errors.startDate = 'The start date does not align with the selected working days';
  }

  if (endDate && !isDateInSelectedWorkingDays(endDate, workDays)) {
    errors.endDate = 'The end date does not align with the selected working days';
  }

  if (workDays.every(({ checked }) => !checked)) {
    errors.workDays = 'Required field';
  }

  return errors;
};

export const normalizeAddRequestData = (projectRequesters, addRequestData, activeView) => {
  const requester = projectRequesters.find(({ id }) => id === addRequestData.requesterId);
  const selectedWorkingDays = getSelectedWorkDayValues(addRequestData.workDays);
  const { project, ...apiNewRequestData } = addRequestData;
  const newRequestData = [{ ...apiNewRequestData, workDays: selectedWorkingDays }];

  return {
    newRequestData,
    requester,
    project,
    addedFrom: REQUEST_ADDED_FROM_MAP[activeView],
  };
};
