import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNTS_REMOVE_INVITATION_BEGIN,
  ACCOUNTS_REMOVE_INVITATION_SUCCESS,
  ACCOUNTS_REMOVE_INVITATION_FAILURE,
  ACCOUNTS_REMOVE_INVITATION_DISMISS_ERROR,
} from './constants';
import { protectedJsonDelete } from '../../../utils/api';

export function removeInvitation(accountId, invitationId, analytics) {
  return {
    type: ACCOUNTS_REMOVE_INVITATION_BEGIN,
    accountId,
    invitationId,
    analytics,
  };
}

export function dismissRemoveInvitationError() {
  return {
    type: ACCOUNTS_REMOVE_INVITATION_DISMISS_ERROR,
  };
}

export function* doRemoveInvitation(action) {
  const { accountId, invitationId, analytics } = action;
  try {
    yield call(protectedJsonDelete, `accounts/${accountId}/invitations/${invitationId}`);
  } catch (err) {
    yield put({
      type: ACCOUNTS_REMOVE_INVITATION_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: ACCOUNTS_REMOVE_INVITATION_SUCCESS,
    accountId,
    invitationId,
    analytics,
  });
}

export function* watchRemoveInvitation() {
  yield takeLatest(ACCOUNTS_REMOVE_INVITATION_BEGIN, doRemoveInvitation);
}

export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNTS_REMOVE_INVITATION_BEGIN:
      return {
        ...state,
        removeInvitationPending: true,
        removeInvitationError: null,
      };

    case ACCOUNTS_REMOVE_INVITATION_SUCCESS: {
      const updatedInvitations = state.invitations[action.accountId].filter(i => i.id !== action.invitationId);

      return {
        ...state,
        invitations: {
          ...state.invitations,
          [action.accountId]: updatedInvitations,
        },
        removeInvitationPending: false,
        removeInvitationError: null,
      };
    }

    case ACCOUNTS_REMOVE_INVITATION_FAILURE:
      return {
        ...state,
        removeInvitationPending: false,
        removeInvitationError: action.data.error,
      };

    case ACCOUNTS_REMOVE_INVITATION_DISMISS_ERROR:
      return {
        ...state,
        removeInvitationError: null,
      };

    default:
      return state;
  }
}
