import { gql } from 'apollo-boost';
import { MIN_FROM, MAX_SIZE } from '../../../common/constants';

const SEARCH_ACTIVE_PEOPLE_QUERY = gql`
  query searchActivePeopleQuery($accountId: Int!, $from: Int, $size: Int,$filters: [ESQuery]) {
    people(accountId: $accountId, from: $from, size: $size, filters: $filters) {
      count
      items {
        name
        value: id
      }
    }
  }
`;

export const searchActivePeopleQuery = (accountId, searchQuery) => {
  const combinedSearchQuery =
    searchQuery ?
      searchQuery.map(word => `*${word}*`).join(' AND ')
      : '';

  const variables = {
    accountId,
    size: MAX_SIZE,
    from: MIN_FROM,
    filters: [
      {
        bool: {
          must: [
            {
              term: { isActive: true },
            },
            {
              // eslint-disable-next-line camelcase
              query_string: {
                fields: ['name'],
                query: combinedSearchQuery,
              },
            },
          ],
        },
      },
    ],
  };

  return {
    query: SEARCH_ACTIVE_PEOPLE_QUERY,
    variables,
  };
};
