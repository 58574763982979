import { getConditionalArray } from 'src/utils/arrayUtils';

function sortAlphabetically(arr, ascending = true, sortKey = null) {
  if (!arr || !arr.length) return [];

  const map = arr.map((e, i) => ({ index: i, value: sortKey ? e[sortKey].toLowerCase() : e.toLowerCase() }));

  map.sort((a, b) => +(ascending ? (a.value > b.value) : (a.value < b.value)) || +(a.value === b.value) - 1);

  return map.map(e => arr[e.index]);
}

/* eslint-disable */
// Based on the work seen here: // https://github.com/overset/javascript-natural-sort/blob/master/naturalSort.js
// Modified to always sort case-insensitive
function natSort(a, b) {
  const re = /(^([+\-]?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?(?=\D|\s|$))|^0x[\da-fA-F]+$|\d+)/g;
  const sre = /^\s+|\s+$/g; // trim pre-post whitespace
  const snre = /\s+/g; // normalize all whitespace to single ' ' character
  const dre = /(^([\w ]+,?[\w ]+)?[\w ]+,?[\w ]+\d+:\d+(:\d+)?[\w ]?|^\d{1,4}[\/\-]\d{1,4}[\/\-]\d{1,4}|^\w+, \w+ \d+, \d{4})/;
  const hre = /^0x[0-9a-f]+$/i;
  const ore = /^0/;
  const i = function (s) {
    // return (naturalSort.insensitive && ('' + s).toLowerCase() || '' + s).replace(sre, '');
    // ^^ original line. Below is a change to always force case insensitive sorting
    return ((`${s}`).toLowerCase() || `${s}`).replace(sre, '');
  };
  // convert all to strings strip whitespace
  const x = i(a);
  const y = i(b);
  // chunk/tokenize
  const xN = x.replace(re, '\0$1\0')
    .replace(/\0$/, '')
    .replace(/^\0/, '')
    .split('\0');
  const yN = y.replace(re, '\0$1\0')
    .replace(/\0$/, '')
    .replace(/^\0/, '')
    .split('\0');
  // numeric, hex or date detection
  const xD = parseInt(x.match(hre), 16) || (xN.length !== 1 && Date.parse(x));
  const yD = parseInt(y.match(hre), 16) || xD && y.match(dre) && Date.parse(y) || null;
  const normChunk = function (s, l) {
    // normalize spaces; find floats not starting with '0', string or 0 if not defined (Clint Priest)
    return (!s.match(ore) || l == 1) && parseFloat(s) || s.replace(snre, ' ')
      .replace(sre, '') || 0;
  };
  let oFxNcL;
  let
    oFyNcL;
  // first try and sort Hex codes or Dates
  if (yD) {
    if (xD < yD) {
      return -1;
    }
    if (xD > yD) {
      return 1;
    }
  }
  // natural sorting through split numeric strings and default strings
  for (let cLoc = 0, xNl = xN.length, yNl = yN.length, numS = Math.max(xNl, yNl); cLoc < numS; cLoc++) {
    oFxNcL = normChunk(xN[cLoc] || '', xNl);
    oFyNcL = normChunk(yN[cLoc] || '', yNl);
    // handle numeric vs string comparison - number < string - (Kyle Adams)
    if (isNaN(oFxNcL) !== isNaN(oFyNcL)) {
      return isNaN(oFxNcL) ? 1 : -1;
    }
    // if unicode use locale comparison
    if (/[^\x00-\x80]/.test(oFxNcL + oFyNcL) && oFxNcL.localeCompare) {
      const comp = oFxNcL.localeCompare(oFyNcL);
      return comp / Math.abs(comp);
    }
    if (oFxNcL < oFyNcL) {
      return -1;
    }
    if (oFxNcL > oFyNcL) {
      return 1;
    }
  }

  return 0;
}
/* eslint-enable */

const asc = 'asc';
const desc = 'desc';

// Based off ObjectSort and Compare functions found here:
// https://github.com/jmjuanes/objectsort/blob/master/dist/objectsort.js

// Function for compare two elements
function multiCompare(left, right, columns = [], order = []) {
  const iterations = (columns.length === 0 ? 1 : columns.length);

  // Compare all
  for (let i = 0; i < iterations; i += 1) {
    let leftVal;
    let rightVal;
    const sortOrder = order[i] === desc ? desc : asc;

    const column = columns[i];

    if (typeof column === 'function') {
      leftVal = column(left);
      rightVal = column(right);
    } else {
      leftVal = typeof left === 'object' && column in left ? left[column] : left;
      rightVal = typeof right === 'object' && column in right ? right[column] : right;
    }

    const retVal = natSort(leftVal, rightVal);

    // Invert the result if sorting descending
    if (retVal !== 0) {
      if (sortOrder === desc) return retVal * -1;

      return retVal;
    }
  }

  // Values are equal, return 0
  return 0;
}

function objectSort(array, columns, order) {
  // Check the columns
  if (!columns.length) {
    // Add the keys
    Object.keys(array[0]).forEach(key => columns.push(key));
  }

  const validatedOrders = order.map(orderKey => (typeof orderKey === 'string' ? orderKey.toLowerCase() : asc));

  // Complete the order array, if necessary
  if (validatedOrders.length < columns.length) {
    for (let i = validatedOrders.length; i < columns.length; i += 1) {
      validatedOrders.push(asc);
    }
  }

  // Sort and return
  return [...array].sort((left, right) => multiCompare(left, right, columns, validatedOrders));
}

function naturalSort(arr, key, order) {
  const columns = getConditionalArray(key);
  const orders = getConditionalArray(order);

  return objectSort(arr, columns, orders);
}

function insertToSortedArray(sortedArr = [], element, comparator) {
  let index = 0;
  while (index < sortedArr.length && comparator(sortedArr[index], element) < 0) {
    index += 1;
  }
  return [...sortedArr.slice(0, index), element, ...sortedArr.slice(index)];
}

export {
  sortAlphabetically,
  naturalSort,
  insertToSortedArray,
};
