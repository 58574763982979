const COMMON_NAVIGATE_BETWEEN_ACCOUNTS = 'Navigate Between Accounts';
const COMMON_SUPPORT_CLICKED = 'Support Clicked';
const COMMON_PROJECT_DATES_UPDATED = 'Project Dates Updated';
const COMMON_PHASE_EDITED_ON_PROJECT = 'Phase Edited on a Project';
const COMMON_MODAL_CLOSED = 'In-Context Modal is closed';
const COMMON_NAVIGATE_BETWEEN_MODALS = 'Navigate between Modals';
const COMMON_CONFIDENTIAL_MODE_TOGGLE_USED = 'Confidential Mode Toggle Used';

export {
  COMMON_NAVIGATE_BETWEEN_ACCOUNTS,
  COMMON_SUPPORT_CLICKED,
  COMMON_PROJECT_DATES_UPDATED,
  COMMON_PHASE_EDITED_ON_PROJECT,
  COMMON_MODAL_CLOSED,
  COMMON_NAVIGATE_BETWEEN_MODALS,
  COMMON_CONFIDENTIAL_MODE_TOGGLE_USED,
};
