import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import tinycolor from 'tinycolor2';
import {
  getStartPx,
  getBarWidth,
} from 'src/features/gantt/utils/ganttUtils';
import { Tooltip, ClickAwayListener } from '@material-ui/core';
import { BLACK, WHITE, BLUE } from '@bridgit/foundation';
import { CustomPopper } from 'src/features/wrapped-components';
import { getMousePosition } from 'src/utils/uiUtils';

export default function GanttBar({
  startDate,
  endDate,
  barColor,
  borderColor,
  borderType,
  textColor,
  shortLabel,
  label,
  dates,
  zoomConfig,
  onClick,
  customPopperContent,
  columnWidth,
}) {
  const [startPx, setStartPx] = useState(0);
  const [barWidth, setBarWidth] = useState(0);
  const [popperOpen, setPopperOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const styles = useMemo(() => {
    let color = textColor;
    if (!color) {
      color = tinycolor(barColor).isLight() ? BLACK : WHITE;
    }
    return {
      marginLeft: `${startPx}px`,
      width: `${barWidth}px`,
      backgroundColor: barColor,
      border: `1px ${borderType} ${borderColor}`,
      color,
    };
  }, [startPx, barWidth, barColor, borderColor, borderType, textColor]);

  useEffect(() => {
    const momentStartDate = moment(startDate);
    setStartPx(getStartPx(dates.min, momentStartDate, zoomConfig, columnWidth));
    setBarWidth(getBarWidth(momentStartDate, moment(endDate), zoomConfig, true, columnWidth));
  }, [startDate, endDate, dates, zoomConfig, columnWidth]);

  const onBarClick = (evt) => {
    if (onClick) onClick();
    if (customPopperContent) {
      const mouseLoc = getMousePosition(evt, 0);
      setPopperOpen(true);
      setAnchorEl(mouseLoc);
    }
  };

  const onClickAway = () => {
    setPopperOpen(false);
    setAnchorEl(null);
  };

  const renderLabel = () => {
    if (barWidth > 100 || (shortLabel && barWidth > 45)) {
      return (
        <div className="gantt-bar-label">
          <span className="gantt-bar-text">{barWidth > 100 ? label : shortLabel}</span>
        </div>
      );
    }

    return (
      <Tooltip title={label} placement="top">
        <div className="gantt-bar-label" />
      </Tooltip>
    );
  };

  if (!onClick && !customPopperContent) {
    return (
      <div className="gantt-gantt-bar" style={styles}>
        {renderLabel()}
      </div>
    );
  }

  return (
    <button
      type="button"
      className="gantt-gantt-bar button"
      style={styles}
      onClick={onBarClick}
    >
      {renderLabel()}
      {/* if barData includes customPopperContent render it in a popper managed here */}
      {customPopperContent && (
      <CustomPopper
        popperOpen={popperOpen}
        anchorEl={anchorEl}
        hideArrow
      >
        <ClickAwayListener onClickAway={onClickAway}>
          <div>
            {/* onClickAway available for child to use if need be */}
            {customPopperContent(onClickAway)}
          </div>
        </ClickAwayListener>
      </CustomPopper>
      )}
    </button>
  );
}

GanttBar.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  barColor: PropTypes.string,
  borderColor: PropTypes.string,
  borderType: PropTypes.string,
  textColor: PropTypes.string,
  shortLabel: PropTypes.string,
  label: PropTypes.string,
  dates: PropTypes.object.isRequired,
  zoomConfig: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  customPopperContent: PropTypes.func,
  columnWidth: PropTypes.number,
};

GanttBar.defaultProps = {
  barColor: BLUE,
  borderColor: BLUE,
  borderType: 'solid',
  textColor: null,
  shortLabel: null,
  label: '',
  onClick: null,
  customPopperContent: null,
  columnWidth: undefined,
};
