import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PERMISSIONS_DELETE_PERMISSION_GROUP_BEGIN,
  PERMISSIONS_DELETE_PERMISSION_GROUP_SUCCESS,
  PERMISSIONS_DELETE_PERMISSION_GROUP_FAILURE,
  PERMISSIONS_DELETE_PERMISSION_GROUP_DISMISS_ERROR,
} from './constants';
import { protectedJsonDelete } from '../../../utils/api';

export function deletePermissionGroup(accountId, groupId, analyticsPayload) {
  return {
    type: PERMISSIONS_DELETE_PERMISSION_GROUP_BEGIN,
    accountId,
    groupId,
    analyticsPayload,
  };
}

export function dismissDeletePermissionGroupError() {
  return {
    type: PERMISSIONS_DELETE_PERMISSION_GROUP_DISMISS_ERROR,
  };
}

export function* doDeletePermissionGroup(action) {
  const { accountId, groupId, analyticsPayload } = action;
  try {
    yield call(protectedJsonDelete, `accounts/${accountId}/groups/${groupId}`);
  } catch (err) {
    yield put({
      type: PERMISSIONS_DELETE_PERMISSION_GROUP_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PERMISSIONS_DELETE_PERMISSION_GROUP_SUCCESS,
    groupId,
    analyticsPayload,
  });
}

export function* watchDeletePermissionGroup() {
  yield takeLatest(PERMISSIONS_DELETE_PERMISSION_GROUP_BEGIN, doDeletePermissionGroup);
}

export function reducer(state, action) {
  switch (action.type) {
    case PERMISSIONS_DELETE_PERMISSION_GROUP_BEGIN:
      return {
        ...state,
        deletePermissionGroupPending: true,
        deletePermissionGroupError: null,
      };

    case PERMISSIONS_DELETE_PERMISSION_GROUP_SUCCESS:
      return {
        ...state,
        permissionGroups: state.permissionGroups.filter(group => group.id !== action.groupId),
        deletePermissionGroupPending: false,
        deletePermissionGroupError: null,
      };

    case PERMISSIONS_DELETE_PERMISSION_GROUP_FAILURE:
      return {
        ...state,
        deletePermissionGroupPending: false,
        deletePermissionGroupError: action.data.error,
      };

    case PERMISSIONS_DELETE_PERMISSION_GROUP_DISMISS_ERROR:
      return {
        ...state,
        deletePermissionGroupError: null,
      };

    default:
      return state;
  }
}
