import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { DATE_DISPLAY_FORMAT } from 'src/common/constants';
import { clearReportData, setReport } from './redux/actions';
import { generatePeopleCSV } from './utils/reportUtils';
import { getAccountById } from '../../utils/miscUtils';
import { CERTIFICATIONS } from '../../filters/constants';

const PeopleCSVReport = () => {
  const dispatch = useDispatch();

  const { visibleColumns } = useSelector(({ table }) => table);
  const { accountId } = useSelector(({ common }) => common);
  const { people } = useSelector(({ reports }) => reports);
  const { entities: accounts } = useSelector(({ accounts }) => accounts);
  const { certifications: accountCertifications } = useSelector(({ accountSettings }) => accountSettings);

  const csvInstance = useRef();
  const hasPeople = people && people.length > 0;

  useEffect(() => {
    if (hasPeople && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        dispatch(clearReportData());

        window.mixpanel.track('Export People List', {
          'Report type': 'CSV',
        });

        dispatch(setReport(false));
      });
    }
  }, [hasPeople, dispatch]);

  if (hasPeople) {
    const filteredColumns = visibleColumns.filter(column => column.name !== 'Photo' && column.schemaName !== CERTIFICATIONS);
    const isCertificationsOnReport = visibleColumns.some(column => column.schemaName === CERTIFICATIONS);
    const headers = filteredColumns.map(column => column.name);
    const today = moment().format(DATE_DISPLAY_FORMAT);
    const currentAccount = getAccountById(accounts, accountId) || { name: '' };
    const certificationColumns = [];

    if (isCertificationsOnReport) {
      accountCertifications.forEach(({ name, id }) => {
        headers.push(name);
        certificationColumns.push({ id });
      });
    }

    return (
      <CSVLink
        className="reports-people-csv"
        headers={headers}
        data={generatePeopleCSV(filteredColumns, people, certificationColumns)}
        filename={`${currentAccount.name} - People List - ${today}.csv`}
        ref={csvInstance}
      />
    );
  }

  return null;
};

export default PeopleCSVReport;
