import moment from 'moment';
import { DATE_INPUT_FORMAT, DATE_DISPLAY_FORMAT } from 'src/common/constants';
import { defaultFilters } from 'src/features/filters/common/constants';

function getTitlesFromFieldDefs(personFields) {
  if (!Array.isArray(personFields)) return [];

  const { definedValues } = personFields.find(f => (
    f.name === 'Title' && 'definedValues' in f && Array.isArray(f.definedValues))) || {};

  return definedValues
    ? definedValues.map(f => f.definedValue)
    : [];
}

function getInitialFilters(startDate, endDate, resourceTitles, titles = []) {
  let dateFilterLabel = '';

  if (typeof startDate === 'string'
    && typeof endDate === 'string'
    && startDate.length
    && endDate.length) {
    const roleStartFormatted = moment(startDate, DATE_INPUT_FORMAT).format(DATE_DISPLAY_FORMAT);
    const roleEndFormatted = moment(endDate, DATE_INPUT_FORMAT).format(DATE_DISPLAY_FORMAT);

    dateFilterLabel = `${roleStartFormatted} - ${roleEndFormatted}`;
  }

  const matchingTitles = titles.filter(name => resourceTitles.find(title => name === title));

  return {
    name: 'filter',
    args: defaultFilters.roleAvailabilities(matchingTitles, dateFilterLabel),
  };
}

export {
  getTitlesFromFieldDefs,
  getInitialFilters,
};
