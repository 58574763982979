import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PROJECTS_GET_PROJECT_ROLES_BEGIN,
  PROJECTS_GET_PROJECT_ROLES_SUCCESS,
  PROJECTS_GET_PROJECT_ROLES_FAILURE,
  PROJECTS_GET_PROJECT_ROLES_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';

export function getProjectRoles(accountId, projectId) {
  return {
    type: PROJECTS_GET_PROJECT_ROLES_BEGIN,
    accountId,
    projectId,
  };
}

export function dismissGetProjectRolesError() {
  return {
    type: PROJECTS_GET_PROJECT_ROLES_DISMISS_ERROR,
  };
}

export function* doGetProjectRoles(action) {
  const { accountId, projectId } = action;
  let res;
  try {
    res = yield call(protectedJsonGet, `accounts/${accountId}/roles`, { projectId });
  } catch (err) {
    yield put({
      type: PROJECTS_GET_PROJECT_ROLES_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PROJECTS_GET_PROJECT_ROLES_SUCCESS,
    data: res,
  });
}

export function* watchGetProjectRoles() {
  yield takeLatest(PROJECTS_GET_PROJECT_ROLES_BEGIN, doGetProjectRoles);
}

export function reducer(state, action) {
  switch (action.type) {
    case PROJECTS_GET_PROJECT_ROLES_BEGIN:
      return {
        ...state,
        getProjectRolesPending: true,
        getProjectRolesError: null,
      };

    case PROJECTS_GET_PROJECT_ROLES_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        projectRoles: data,
        getProjectRolesPending: false,
        getProjectRolesError: null,
      };
    }

    case PROJECTS_GET_PROJECT_ROLES_FAILURE: {
      return {
        ...state,
        getProjectRolesPending: false,
        getProjectRolesError: action.data.error,
      };
    }

    case PROJECTS_GET_PROJECT_ROLES_DISMISS_ERROR:
      return {
        ...state,
        getProjectRolesError: null,
      };

    default:
      return state;
  }
}
