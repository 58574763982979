import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNTS_DELETE_LOGO_BEGIN,
  ACCOUNTS_DELETE_LOGO_SUCCESS,
  ACCOUNTS_DELETE_LOGO_FAILURE,
  ACCOUNTS_DELETE_LOGO_DISMISS_ERROR,
} from './constants';
import { protectedJsonDelete } from '../../../utils/api';

export function deleteLogo(accountId) {
  return {
    type: ACCOUNTS_DELETE_LOGO_BEGIN,
    accountId,
  };
}

export function dismissDeleteLogoError() {
  return {
    type: ACCOUNTS_DELETE_LOGO_DISMISS_ERROR,
  };
}

export function* doDeleteLogo(action) {
  const { accountId } = action;
  try {
    yield call(protectedJsonDelete, `accounts/${accountId}/logos`);
  } catch (err) {
    yield put({
      type: ACCOUNTS_DELETE_LOGO_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: ACCOUNTS_DELETE_LOGO_SUCCESS,
    accountId,
  });
}

export function* watchDeleteLogo() {
  yield takeLatest(ACCOUNTS_DELETE_LOGO_BEGIN, doDeleteLogo);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNTS_DELETE_LOGO_BEGIN:
      return {
        ...state,
        deleteLogoPending: true,
        deleteLogoError: null,
      };

    case ACCOUNTS_DELETE_LOGO_SUCCESS: {
      const { accountId } = action;
      const foundAccount = state.entities.find(account => account.id === accountId);
      const updatedAccount = { ...foundAccount, logoUrl: '' };

      return {
        ...state,
        entities: [
          ...state.entities.filter(account => account.id !== accountId),
          updatedAccount,
        ],
        deleteLogoPending: false,
        deleteLogoError: null,
      };
    }

    case ACCOUNTS_DELETE_LOGO_FAILURE:
      return {
        ...state,
        deleteLogoPending: false,
        deleteLogoError: action.data.error,
      };

    case ACCOUNTS_DELETE_LOGO_DISMISS_ERROR:
      return {
        ...state,
        deleteLogoError: null,
      };

    default:
      return state;
  }
}
