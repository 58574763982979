import { call, put, takeLatest } from 'redux-saga/effects';
import {
  LOGIN_RESET_PASSWORD_BEGIN,
  LOGIN_RESET_PASSWORD_SUCCESS,
  LOGIN_RESET_PASSWORD_FAILURE,
  LOGIN_RESET_PASSWORD_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';

export function resetPassword(token, password) {
  return {
    type: LOGIN_RESET_PASSWORD_BEGIN,
    token,
    password,
  };
}

export function dismissResetPasswordError() {
  return {
    type: LOGIN_RESET_PASSWORD_DISMISS_ERROR,
  };
}

export function* doResetPassword(action) {
  const { token, password } = action;

  try {
    yield call(protectedJsonPost, `/forgot-password/reset?token=${token}`, { password });
  } catch (err) {
    yield put({
      type: LOGIN_RESET_PASSWORD_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: LOGIN_RESET_PASSWORD_SUCCESS,
  });
}

export function* watchResetPassword() {
  yield takeLatest(LOGIN_RESET_PASSWORD_BEGIN, doResetPassword);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case LOGIN_RESET_PASSWORD_BEGIN:
      return {
        ...state,
        resetPasswordPending: true,
        resetPasswordError: null,
      };

    case LOGIN_RESET_PASSWORD_SUCCESS:
      // Mixpanel - Track update password success with forgot password
      window.mixpanel.track('Update Password', {
        'Updated From': 'Forgot Password',
      });

      return {
        ...state,
        resetPasswordPending: false,
        resetPasswordError: null,
      };

    case LOGIN_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPasswordPending: false,
        resetPasswordError: action.data.error,
      };

    case LOGIN_RESET_PASSWORD_DISMISS_ERROR:
      return {
        ...state,
        resetPasswordError: null,
      };

    default:
      return state;
  }
}
