import { call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { DATE_INPUT_FORMAT } from 'src/common/constants';
import { protectedJsonPatch } from '../../../utils/api';
import {
  PEOPLE_UPDATE_PERSON_BEGIN,
  PEOPLE_UPDATE_PERSON_SUCCESS,
  PEOPLE_UPDATE_PERSON_FAILURE,
  PEOPLE_UPDATE_PERSON_DISMISS_ERROR,
} from './constants';
import { getUpdatedEntity } from '../../../filters/graphqlUtils';
import { updatePousByEmploymentDates } from '../utils/peopleUtils';

export function updatePerson(accountId, personId, data, analyticsPayload) {
  return {
    type: PEOPLE_UPDATE_PERSON_BEGIN,
    accountId,
    personId,
    data,
    analyticsPayload,
  };
}

export function dismissUpdatePersonError() {
  return {
    type: PEOPLE_UPDATE_PERSON_DISMISS_ERROR,
  };
}

// worker Saga: will be fired on PEOPLE_UPDATE_PERSON_BEGIN actions
export function* doUpdatePerson(action) {
  const { accountId, personId, data, analyticsPayload } = action;
  let res;
  try {
    res = yield call(protectedJsonPatch, `accounts/${accountId}/persons/${personId}`, data);
  } catch (err) {
    yield put({
      type: PEOPLE_UPDATE_PERSON_FAILURE,
      data: { error: err },
    });
    return;
  }
  // Dispatch success action out of try/catch so that render errors are not caught.
  yield put({
    type: PEOPLE_UPDATE_PERSON_SUCCESS,
    accountId,
    personId,
    data: res,
    formData: data,
    analyticsPayload,
  });
}

export function* watchUpdatePerson() {
  yield takeLatest(PEOPLE_UPDATE_PERSON_BEGIN, doUpdatePerson);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_UPDATE_PERSON_BEGIN:
      return {
        ...state,
        updatePersonPending: true,
        updatePersonError: null,
      };

    case PEOPLE_UPDATE_PERSON_SUCCESS: {
      const { personId, data, formData } = action;
      const { employmentDates } = formData;
      const { personSelections, allocations, filteredPeople, deactivatedPeople } = state;
      let newAllocations = allocations ? [...allocations] : [];

      const updatedFilteredPeople = getUpdatedEntity('Person', filteredPeople, data);

      // Add/remove POU's based on employment dates
      const newFilteredPeople = updatedFilteredPeople.map((person) => {
        if (person.id === personId) {
          return updatePousByEmploymentDates(person, employmentDates);
        }
        return person;
      });

      const updatedDeactivatedPeople = getUpdatedEntity('Person', deactivatedPeople, data);

      // Add/remove POU's based on employment dates
      const newDeactivatedPeople = updatedDeactivatedPeople.map((person) => {
        if (person.id === personId) {
          return updatePousByEmploymentDates(person, employmentDates);
        }
        return person;
      });

      if (employmentDates) {
        const { startDate, endDate } = employmentDates;
        newAllocations = allocations.map((allocation) => {
          if (allocation.personId === personId) {
            if ('startDate' in employmentDates && moment(allocation.startDate).isBefore(startDate)) {
              return {
                ...allocation,
                startDate: moment(startDate).format(DATE_INPUT_FORMAT),
              };
            }
            if ('endDate' in employmentDates && moment(allocation.endDate).isAfter(endDate)) {
              return {
                ...allocation,
                endDate: moment(endDate).format(DATE_INPUT_FORMAT),
              };
            }
          }
          return allocation;
        });
      }

      const newPersonSelections = { ...personSelections };
      Object.keys(personSelections).forEach((selectionId) => {
        const selectedPerson = personSelections[selectionId];
        if (selectedPerson && selectedPerson.id === personId) {
          const updatedPerson = updatePousByEmploymentDates(selectedPerson, employmentDates);
          newPersonSelections[selectionId] = {
            ...updatedPerson,
            ...data,
          };
        }
      });

      return {
        ...state,
        entities: [
          ...state.entities.filter(p => p.id !== personId),
          action.data,
        ],
        allocations: newAllocations,
        updatePersonPending: false,
        updatePersonError: null,
        filteredPeople: newFilteredPeople,
        deactivatedPeople: newDeactivatedPeople,
        personSelections: newPersonSelections,
      };
    }

    case PEOPLE_UPDATE_PERSON_FAILURE:
      return {
        ...state,
        updatePersonPending: false,
        updatePersonError: action.data.error,
      };

    case PEOPLE_UPDATE_PERSON_DISMISS_ERROR:
      return {
        ...state,
        updatePersonError: null,
      };

    default:
      return state;
  }
}
