import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@material-ui/core';
import { Edit, MoreHoriz } from '@material-ui/icons';
import { NumberInput } from '../wrapped-components';

export default class MultiStateNumberInput extends PureComponent {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    onChange: PropTypes.func.isRequired,
    onEditToggle: PropTypes.func.isRequired,
    onEditCustom: PropTypes.func.isRequired,
    editRoleRequirementMode: PropTypes.bool.isRequired,
    editCustomRequirementMode: PropTypes.bool.isRequired,
    editIconTooltip: PropTypes.string,
  };

  static defaultProps = {
    editIconTooltip: '',
  };

  render() {
    const {
      value,
      onEditCustom,
      onEditToggle,
      editRoleRequirementMode,
      editCustomRequirementMode,
      editIconTooltip,
      onChange,
    } = this.props;
    return (
      <div className={`projects-multi-state-number-input ${editRoleRequirementMode ? 'allocation-enabled' : ''}`}>
        {editRoleRequirementMode && (
          <>
            <NumberInput
              className="allocation-picker"
              value={value}
              onValueChanged={onChange}
            />
            <button
              type="button"
              className="edit-allocation"
              onClick={onEditCustom}
            >
              <Tooltip title="Customize" placement="top">
                <div><MoreHoriz /></div>
              </Tooltip>
            </button>
          </>
        )}

        {!editRoleRequirementMode && !editCustomRequirementMode && (
          <>
            {value}
            <Tooltip title={editIconTooltip} placement="top">
              <IconButton className="edit-date" onClick={onEditToggle}>
                <Edit fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </>
        )}
      </div>
    );
  }
}
