import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import deepEqual from 'react-fast-compare';
import { Tooltip, ClickAwayListener } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import { ProjectRoleTitle } from '@bridgit/foundation';
import { confirmRoleUpdate } from './utils/projectRolesUtils';
import { fitSegmentsToRange } from '../../utils/dateSegmentUtils';
import { trackProjectRoleUpdate } from '../../common/analyticsHelper';
import { CustomRoleRequirementModal, RoleRequirementPopper, ConfirmUpdateRole } from '../allocations';
import { stringToMoment } from '../../utils/dateUtils';
import { DATE_INPUT_FORMAT, DATE_DISPLAY_FORMAT } from '../../common/constants';
import { PERM_WRITE, PERM_ROLE } from '../permissions/utils/constants';
import {
  RoleCoverageIndicator,
  ProjectRoleEditor,
  MultiStateNumberInput,
  RoleNotePopper,
} from '.';
import { updateProjectRole } from './redux/actions';
import { Can, EditControls } from '../wrapped-components';
import { DateEditor } from '../common';

class RoleDetailRow extends Component {
  static propTypes = {
    role: PropTypes.object.isRequired,
    editMode: PropTypes.string,
    selectedProject: PropTypes.object.isRequired,
    valid: PropTypes.bool.isRequired,
    existingAllocations: PropTypes.array.isRequired,
    pending: PropTypes.bool,
    onUpdateRoleName: PropTypes.func.isRequired,
    onUpdateRoleNote: PropTypes.func.isRequired,
    onEditToggle: PropTypes.func.isRequired,
    onEditNoteToggle: PropTypes.func.isRequired,
    onSelectOpen: PropTypes.func.isRequired,
    onSelectClose: PropTypes.func.isRequired,
    onConfirmCancel: PropTypes.func.isRequired,
    onClickAway: PropTypes.func.isRequired,
    onDateChange: PropTypes.func.isRequired,
    onDateError: PropTypes.func.isRequired,
    onNumberInputChange: PropTypes.func.isRequired,
    roles: PropTypes.array,
    projectAllocations: PropTypes.object,
    accountId: PropTypes.number.isRequired,
    updateProjectRole: PropTypes.func.isRequired,
    runsInModal: PropTypes.bool,
  };

  static defaultProps = {
    editMode: null,
    pending: false,
    roles: [],
    projectAllocations: {},
    runsInModal: false,
  };

  constructor(props) {
    super(props);

    const startDate = moment(props.role.startDate, DATE_INPUT_FORMAT);
    const endDate = moment(props.role.endDate, DATE_INPUT_FORMAT);
    const workingRoleName = props.role.name;
    const workingRoleNote = props.role.note;
    const workingRequirements = props.role?.requirements ? [...props.role.requirements] : [];

    this.state = {
      showCustomModal: false,
      showCustomPopper: false,
      startDate,
      endDate,
      workingRoleName,
      workingRoleNote,
      workingRequirements,
      showConfirmUpdateRole: false,
    };
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { role, pending } = this.props;

    if (!deepEqual(role?.requirements, nextProps.role?.requirements)) {
      this.setState({
        workingRequirements: nextProps.role?.requirements || [],
      });
    }

    if (pending && !nextProps.pending) {
      this.setState({
        showConfirmUpdateRole: false,
        showCustomModal: false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { role } = this.props;
    const { role: prevRole } = prevProps;

    if (role.startDate !== prevRole.startDate || role.endDate !== prevRole.endDate) {
      const startDate = stringToMoment(role.startDate);
      const endDate = stringToMoment(role.endDate);
      this.setState({ // eslint-disable-line react/no-did-update-set-state
        startDate,
        endDate,
      });
    }
  }

  customPopperTarget = null;

  notePopperTarget = null;

  confirmUpdateRole = () => {
    const { projectAllocations, role, selectedProject, editMode, onConfirmCancel, onEditToggle, onEditNoteToggle } = this.props;
    const { startDate, endDate, workingRoleName, workingRoleNote, workingRequirements } = this.state;
    const peopleAllocations = projectAllocations[selectedProject.id].find(a => a.roleId === role.id);

    const newStartDate = moment(startDate);
    const newEndDate = moment(endDate);

    const editDateMode = editMode === 'startDate' || editMode === 'endDate';
    const editTypeMode = editMode === 'type';
    const editRoleRequirementMode = editMode === 'requirements';
    const editNoteMode = editMode === 'note';

    if (editDateMode) {
      const callbacks = {
        saveChange: this.updateRole,
        cancelChange: onConfirmCancel,
        requestConfirmation: () => this.setState({ showConfirmUpdateRole: true }),
      };

      confirmRoleUpdate(newStartDate, newEndDate, role, peopleAllocations?.allocations, callbacks);
    }

    if (editTypeMode) {
      if (workingRoleName === role.name) {
        onEditToggle(null);
      } else {
        this.updateRole();
      }
    }

    if (editNoteMode) {
      if (workingRoleNote === role.note) {
        onEditNoteToggle();
      } else {
        this.updateRole();
      }
    }

    if (editRoleRequirementMode) {
      if (deepEqual(role.requirements, workingRequirements)) {
        onEditToggle(null);
      } else {
        this.updateRole();
      }
    }
  }

  updateRole = (expandAllocations = false) => {
    const { accountId, role, selectedProject, updateProjectRole, runsInModal } = this.props;
    const { startDate, endDate, workingRoleName, workingRoleNote, workingRequirements } = this.state;

    const formattedStartDate = moment(startDate).format(DATE_INPUT_FORMAT);
    const formattedEndDate = moment(endDate).format(DATE_INPUT_FORMAT);

    const data = {
      name: workingRoleName,
      note: workingRoleNote === '' ? null : workingRoleNote,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      expandAllocations,
    };

    // Fit role requirements to the new range
    data.allocations = fitSegmentsToRange(workingRequirements, moment(startDate), moment(endDate));
    const parentName = runsInModal ? 'Project Details Modal' : 'Project List';

    trackProjectRoleUpdate(role, data, selectedProject, parentName);
    updateProjectRole(accountId, selectedProject.id, role.id, data);
  }

  onSaveCustomAllocations = (roleId, workingRequirements) => {
    this.setState({
      workingRequirements,
    }, this.updateRole);
  }

  handleCancel = () => {
    const { onConfirmCancel, role } = this.props;
    const startDate = moment(role.startDate, DATE_INPUT_FORMAT);
    const endDate = moment(role.endDate, DATE_INPUT_FORMAT);
    const workingRoleName = role.name;
    const workingRoleNote = role.note;
    const workingRequirements = [...role.requirements];

    this.setState({
      startDate,
      endDate,
      workingRoleName,
      workingRoleNote,
      workingRequirements,
      showConfirmUpdateRole: false,
    });
    onConfirmCancel();
  }

  renderEditControls = () => {
    const { pending, valid } = this.props;

    return (
      <EditControls
        primaryAction={this.confirmUpdateRole}
        secondaryAction={this.handleCancel}
        disabled={!valid}
        primaryText="Save"
        pending={pending}
      />
    );
  }

  onCustomClick = (evt) => {
    const { editMode } = this.props;
    this.customPopperTarget = evt.currentTarget;
    const visibilities = editMode === 'requirements'
      ? { showCustomModal: true, showCustomPopper: false }
      : { showCustomModal: false, showCustomPopper: true };

    this.setState({ ...visibilities });
  }

  onShowCustomModal = () => {
    this.setState({
      showCustomModal: true,
      showCustomPopper: false,
    });
  }

  onHideCustom = () => {
    this.setState({
      showCustomModal: false,
      showCustomPopper: false,
    });
  }

  saveCustomAllocations = (roleId, workingRequirements) => {
    this.onSaveCustomAllocations(roleId, workingRequirements);
  }

  handleDateChange = (date, field) => {
    const { onDateChange } = this.props;
    this.setState({
      [field]: date,
    });
    onDateChange(date, field);
  }

  handleUpdateRoleName = (roleId) => {
    const { roles, onUpdateRoleName } = this.props;
    const workingRoleName = roles.find(r => r.id === roleId).name;

    this.setState({
      workingRoleName,
    });
    onUpdateRoleName();
  }

  handleUpdateRoleNote = (note) => {
    const { onUpdateRoleNote } = this.props;
    const workingRoleNote = note === '' ? null : note;

    this.setState({
      workingRoleNote,
    });
    onUpdateRoleNote(note);
  }

  handleEditNoteToggle = (ref) => {
    const { onEditNoteToggle } = this.props;
    this.notePopperTarget = ref;
    onEditNoteToggle();
  }

  handleNumberInputChange = (value) => {
    const { onNumberInputChange, role } = this.props;
    const { workingRequirements } = this.state;

    const newWorkingRequirements = [
      {
        ...workingRequirements[0],
        allocatedPercent: value,
      },
      ...workingRequirements.slice(1),
    ];

    this.setState({
      workingRequirements: newWorkingRequirements,
    });

    const hasPercentageChanged = !deepEqual(role.requirements, newWorkingRequirements);

    onNumberInputChange(value, hasPercentageChanged);
  }

  render() {
    const {
      role,
      editMode,
      selectedProject,
      valid,
      existingAllocations,
      onEditToggle,
      onSelectOpen,
      onSelectClose,
      onClickAway,
      onDateError,
      pending,
    } = this.props;

    const {
      showCustomModal,
      showCustomPopper,
      startDate,
      endDate,
      workingRoleName,
      workingRoleNote,
      workingRequirements,
      showConfirmUpdateRole,
    } = this.state;

    const customPercentages = workingRequirements.length > 1;

    const editTypeMode = editMode === 'type';
    const editRoleRequirementMode = editMode === 'requirements';
    const editStartMode = editMode === 'startDate';
    const editEndMode = editMode === 'endDate';
    const editNoteMode = editMode === 'note';

    const formattedStartDate = moment(role.startDate, DATE_INPUT_FORMAT).format(DATE_DISPLAY_FORMAT);
    const formattedEndDate = moment(role.endDate, DATE_INPUT_FORMAT).format(DATE_DISPLAY_FORMAT);

    return (
      <div className="projects-role-detail-row">
        <div className="role-title">
          <Can
            action={PERM_WRITE}
            subject={PERM_ROLE}
            yes={(
              <ClickAwayListener onClickAway={onClickAway} mouseEvent={editTypeMode ? 'onClick' : false}>
                <div className="field-container">
                  <ProjectRoleEditor
                    selectedRole={{ name: workingRoleName, note: role.note }}
                    editMode={editTypeMode}
                    onChange={this.handleUpdateRoleName}
                    onToggleEditMode={onEditToggle('type')}
                    onToggleNoteEditMode={this.handleEditNoteToggle}
                    onSelectOpen={onSelectOpen}
                    onSelectClose={onSelectClose}
                  />
                  {editTypeMode && this.renderEditControls()}
                </div>
              </ClickAwayListener>
            )}
            no={(
              <ProjectRoleTitle
                className="project-role-title"
                roleName={workingRoleName}
                roleNote={role.note}
              />
            )}
          />
        </div>
        <div className="start-date" title={formattedStartDate}>
          <Can
            action={PERM_WRITE}
            subject={PERM_ROLE}
            yes={(
              <ClickAwayListener onClickAway={onClickAway} mouseEvent={editStartMode ? 'onClick' : false}>
                <div className="field-container">
                  <DateEditor
                    date={stringToMoment(startDate)}
                    minDate={stringToMoment(selectedProject.startDate)}
                    minDateMessage="Start date cannot be earlier than the project start date"
                    maxDate={stringToMoment(role.endDate)}
                    maxDateMessage="The role start date must be earlier than the role end date"
                    onChange={date => this.handleDateChange(date, 'startDate')}
                    editMode={editStartMode}
                    onEditToggle={onEditToggle('startDate')}
                    editIconTooltip="Edit role start date"
                    onError={onDateError}
                    disableSaveButton={!valid}
                  />
                  {editStartMode && this.renderEditControls()}
                </div>
              </ClickAwayListener>
            )}
            no={<span>{formattedStartDate}</span>}
          />
        </div>

        <div className="allocation-visualization">
          <RoleCoverageIndicator
            roleStartDate={role.startDate}
            roleEndDate={role.endDate}
            projectStartDate={selectedProject.startDate}
            projectEndDate={selectedProject.endDate}
            existingAllocations={existingAllocations}
            roleRequirements={role.requirements}
          />
        </div>

        <div className="end-date" title={formattedEndDate}>
          <Can
            action={PERM_WRITE}
            subject={PERM_ROLE}
            yes={(
              <ClickAwayListener onClickAway={onClickAway} mouseEvent={editEndMode ? 'onClick' : false}>
                <div className="field-container">
                  <DateEditor
                    date={stringToMoment(endDate)}
                    minDate={stringToMoment(role.startDate)}
                    minDateMessage="The role end date must be later than the role start date"
                    maxDate={stringToMoment(selectedProject.endDate)}
                    maxDateMessage="End date cannot be later than the project end date"
                    onChange={date => this.handleDateChange(date, 'endDate')}
                    editMode={editEndMode}
                    onEditToggle={onEditToggle('endDate')}
                    editIconTooltip="Edit role end date"
                    onError={onDateError}
                    disableSaveButton={!valid}
                  />
                  {editEndMode && this.renderEditControls()}
                </div>
              </ClickAwayListener>
            )}
            no={<span>{formattedEndDate}</span>}
          />
        </div>
        <div className={`allocation-percentage${customPercentages ? ' compact' : ''}`}>
          {!customPercentages && (
            <Can
              action={PERM_WRITE}
              subject={PERM_ROLE}
              yes={(
                <ClickAwayListener onClickAway={onClickAway} mouseEvent={editRoleRequirementMode ? 'onClick' : false}>
                  <div className="field-container">
                    <MultiStateNumberInput
                      value={workingRequirements.length ? workingRequirements[0].allocatedPercent : 100}
                      onChange={this.handleNumberInputChange}
                      onEditToggle={onEditToggle('requirements')}
                      onEditCustom={this.onCustomClick}
                      editRoleRequirementMode={editRoleRequirementMode}
                      editCustomRequirementMode={showCustomModal}
                      editIconTooltip="Edit allocation %"
                    />
                    {editRoleRequirementMode && this.renderEditControls()}
                  </div>
                </ClickAwayListener>
              )}
              no={workingRequirements.length ? workingRequirements[0].allocatedPercent : 100}
            />
          )}
          {customPercentages && (
            <Can
              action={PERM_WRITE}
              subject={PERM_ROLE}
              yes={(
                <button
                  type="button"
                  className={`custom ${editRoleRequirementMode ? 'allocation-enabled' : ''}`}
                  data-id={role.id}
                  onClick={this.onCustomClick}
                >
                  <div>Custom</div>
                  <Tooltip title="View breakdown" placement="top">
                    <div><MoreHoriz /></div>
                  </Tooltip>
                </button>
              )}
              no={(
                <button
                  type="button"
                  className="custom"
                  data-id={role.id}
                  onClick={this.onCustomClick}
                >
                  <div>Custom</div>
                  <Tooltip title="View breakdown" placement="top">
                    <div><MoreHoriz /></div>
                  </Tooltip>
                </button>
              )}
            />
          )}
        </div>

        {showCustomModal && (
          <CustomRoleRequirementModal
            selectedProject={selectedProject}
            onHideModal={this.onHideCustom}
            role={{ ...role, rowId: role.id, requirements: workingRequirements }}
            onSave={this.saveCustomAllocations}
            loading={pending}
          />
        )}

        {showCustomPopper && (
          <RoleRequirementPopper
            onHidePopper={this.onHideCustom}
            onEditRequirements={this.onShowCustomModal}
            targetRef={this.customPopperTarget}
            roleRequirements={workingRequirements}
            project={selectedProject}
          />
        )}

        {editNoteMode && (
          <RoleNotePopper
            targetRef={this.notePopperTarget}
            onClickAway={onClickAway}
            initialNote={workingRoleNote}
            onUpdate={this.handleUpdateRoleNote}
            onCancel={this.handleCancel}
            onSave={this.confirmUpdateRole}
            pending={pending}
          />
        )}

        {showConfirmUpdateRole && (
          <ConfirmUpdateRole
            cancelConfirm={this.handleCancel}
            onSave={this.updateRole}
            editMode={editMode}
            project={selectedProject}
            pending={pending}
            startDate={startDate}
            endDate={endDate}
            role={role}
          />
        )}
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps({ common, projects, accountSettings }) {
  const { updateProjectRolePending, getProjectAllocationsPending, projectAllocations } = projects;
  const { roles } = accountSettings;
  const { accountId } = common;
  const pending = updateProjectRolePending || getProjectAllocationsPending;

  return {
    pending,
    roles,
    projectAllocations,
    accountId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateProjectRole: bindActionCreators(updateProjectRole, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RoleDetailRow);
