import React from 'react';
import PropTypes from 'prop-types';
import {
  LinearProgress,
  TableCell,
  TableRow,
} from '@material-ui/core';

function PageLoading({
  colSpan,
}) {
  return (
    <TableRow key="loading" hover={false} className="table-page-loading">
      <TableCell colSpan={colSpan}>
        <div className="progress-container">
          <LinearProgress variant="query" className="progress" />
        </div>
      </TableCell>
    </TableRow>
  );
}

PageLoading.propTypes = {
  colSpan: PropTypes.number.isRequired,
};

export default PageLoading;
