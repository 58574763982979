import { generatePeopleFilters } from '../utils/generateFilters';
import {
  FILTERS_SET_PEOPLE_FILTER_DATA,
} from './constants';

export function setPeopleFilterData(accountId, personFields, projectNames, accountModules, permissions) {
  return {
    type: FILTERS_SET_PEOPLE_FILTER_DATA,
    peopleFilterData: generatePeopleFilters(personFields, projectNames, accountModules, accountId, permissions),
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FILTERS_SET_PEOPLE_FILTER_DATA:
      return {
        ...state,
        peopleFilterData: action.peopleFilterData,
      };

    default:
      return state;
  }
}
