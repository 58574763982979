import {
  PEOPLE_CLEAR_PERSON_CERTIFICATIONS,
} from './constants';

export function clearPersonCertifications() {
  return {
    type: PEOPLE_CLEAR_PERSON_CERTIFICATIONS,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_CLEAR_PERSON_CERTIFICATIONS:
      return {
        ...state,
        personCertifications: {},
      };

    default:
      return state;
  }
}
