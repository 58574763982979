import moment from 'moment';
import {
  DATE_INPUT_FORMAT,
  INVALID_STATUS_FILTER,
  MONTH_YEAR_SHORT_FORMAT,
} from '../../../common/constants';
import { DEFAULT_MONTH_OFFSET } from '../redux/constants';
import { ACTIVE, UPCOMING, PURSUIT } from '../../projects/constants';
import {
  UTILIZATION,
  ENSURE_FILLED_ROLES,
  INSIGHT_SENTIMENT,
  UTILIZATION_STATE,
  IDLE_TIME_SENTIMENT_MATRIX,
} from '../constants';

const filterRoles = (roles, monthOffset = DEFAULT_MONTH_OFFSET) => {
  if (monthOffset === -1) {
    return roles;
  }

  const endFilter = moment().add(monthOffset, 'month').endOf('day');

  return roles.filter((role) => {
    if (role.isFilled) return false;

    // Make sure this role has unfilled ranges
    if (!role?.unfilledRanges?.length) return false;

    // Make sure the first unfilled range has the expected properties
    const firstUnfilledRange = role.unfilledRanges[0];
    if (!('startDate' in firstUnfilledRange && 'endDate' in firstUnfilledRange)) return false;

    const startDate = moment(firstUnfilledRange.startDate, DATE_INPUT_FORMAT);
    return startDate <= endFilter;
  });
};

const getFilterGroupApplied = (filter) => {
  if (!filter?.args?.length) return 'empty';

  for (let queryIndex = 0; queryIndex < filter.args.length; queryIndex += 1) {
    const query = filter.args[queryIndex];

    if (query.column === 'state' && query.activeFilters?.length > 0) {
      // filtering by only status of complete, no other status, could be with other filters
      if (!query.activeFilters.some(fil => [ACTIVE, UPCOMING, PURSUIT].includes(fil.value))) {
        return INVALID_STATUS_FILTER;
      }

      // filtering only by status, no other filters
      if (filter.args.length === 1) {
        return 'default';
      }

      return 'filtered';
    }
  }

  return 'filtered';
};

const getIdleTimeState = (utilization) => {
  if (utilization >= 85) {
    return UTILIZATION_STATE.GOOD;
  }
  if (utilization < 70) {
    return UTILIZATION_STATE.BAD;
  }
  return UTILIZATION_STATE.NEUTRAL;
};

const getIdleTimeSentiment = (utilization1, utilization2) => {
  const state1 = getIdleTimeState(utilization1);
  const state2 = getIdleTimeState(utilization2);

  return IDLE_TIME_SENTIMENT_MATRIX?.[state1]?.[state2] ?? null;
};

const generateInsightfulStatement = type => ({
  [INSIGHT_SENTIMENT.POSITIVE]: `${type} looks great this month. Keep it up!`,
  [INSIGHT_SENTIMENT.IMPROVING]: `${type} is expected to ${type === UTILIZATION ? 'rise' : 'fall'}. Keep it up!`,
  [INSIGHT_SENTIMENT.NEUTRAL]: `Keep ${type === UTILIZATION ? 'increasing' : 'lowering'} ${type}! ${ENSURE_FILLED_ROLES}`,
  [INSIGHT_SENTIMENT.WORSENING]: `${type} is expected to ${type === UTILIZATION ? 'fall' : 'rise'}. ${ENSURE_FILLED_ROLES}`,
  [INSIGHT_SENTIMENT.NEGATIVE]: `${type} is ${type === UTILIZATION ? 'low' : 'high'} this month. ${ENSURE_FILLED_ROLES}`,
});

const generateXLabels = (data, graphStartDate) => data.map(({ x }) => {
  // Start with next months label if graph start date is within 7 days of the next month (to prevent xLabels from not lining up with the correct month)
  if (!graphStartDate.clone().add(7, 'days').isSame(moment(graphStartDate), 'month')) {
    return moment(x).add(1, 'month').format(MONTH_YEAR_SHORT_FORMAT);
  }
  return moment(x).format(MONTH_YEAR_SHORT_FORMAT);
});

export {
  filterRoles,
  getFilterGroupApplied,
  generateInsightfulStatement,
  getIdleTimeState,
  getIdleTimeSentiment,
  generateXLabels,
};
