import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { LongTooltip } from '@bridgit/foundation';
import { trackAnalytics } from '../common/redux/actions';
import { IDLE_TIME_TOOLTIP_CLICKED } from '../../analytics/roles/constants';
import { DOT } from '../../common/constants';
import { IDLE_TIME_TOOLTIP_TEXT } from './constants';

const AvailablePeople = () => {
  const dispatch = useDispatch();

  const tooltipClickHandler = useCallback(() => {
    dispatch(trackAnalytics(IDLE_TIME_TOOLTIP_CLICKED));
  }, [dispatch]);

  return (
    <div className="roles-available-people">
      <div className="header">
        <div className="title">
          People
        </div>
      </div>
      <div className="people-list">
        <div className="people-list-header">
          <div className="header-column drag-column" />
          <div className="header-column name-column">Name and title</div>
          <div className="header-column idle-time-column">
            <span>Idle time in the next 1 year</span>
            <LongTooltip onOpen={tooltipClickHandler}>
              <div className="roles-available-people-idle-time-tooltip">
                <p>
                  <b>{IDLE_TIME_TOOLTIP_TEXT.title}</b>
                  {' '}
                  {IDLE_TIME_TOOLTIP_TEXT.line1}
                </p>
                <p>{IDLE_TIME_TOOLTIP_TEXT.line2}</p>
                <div className="legend">
                  <p>
                    <span className="dot">{DOT}</span>
                    {' '}
                    {IDLE_TIME_TOOLTIP_TEXT.greenDot}
                  </p>
                </div>
              </div>
            </LongTooltip>
          </div>
          <div className="header-column total-column">Total %</div>
        </div>
      </div>
    </div>
  );
};

export default AvailablePeople;
