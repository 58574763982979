import { call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { DATE_INPUT_FORMAT } from 'src/common/constants';
import { NO_AVAILABILITY } from 'src/filters/constants';
import {
  PEOPLE_GET_PERSON_AVAILABILITIES_BEGIN,
  PEOPLE_GET_PERSON_AVAILABILITIES_SUCCESS,
  PEOPLE_GET_PERSON_AVAILABILITIES_FAILURE,
  PEOPLE_GET_PERSON_AVAILABILITIES_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';

export function getPersonAvailabilities(accountId) {
  // If need to pass args to saga, pass it with the begin action.
  return {
    type: PEOPLE_GET_PERSON_AVAILABILITIES_BEGIN,
    accountId,
  };
}

export function dismissGetPersonAvailabilitiesError() {
  return {
    type: PEOPLE_GET_PERSON_AVAILABILITIES_DISMISS_ERROR,
  };
}

// worker Saga: will be fired on PEOPLE_GET_PERSON_AVAILABILITIES_BEGIN actions
export function* doGetPersonAvailabilities(action) {
  const { accountId } = action;
  let res;
  try {
    const localNow = moment().local().format(DATE_INPUT_FORMAT);
    res = yield call(protectedJsonGet, `accounts/${accountId}/persons/availabilities?relativeDate=${localNow}`);
  } catch (err) {
    yield put({
      type: PEOPLE_GET_PERSON_AVAILABILITIES_FAILURE,
      data: { error: err },
    });
    return;
  }
  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: PEOPLE_GET_PERSON_AVAILABILITIES_SUCCESS,
    data: res,
  });
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchGetPersonAvailabilities() {
  yield takeLatest(PEOPLE_GET_PERSON_AVAILABILITIES_BEGIN, doGetPersonAvailabilities);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_GET_PERSON_AVAILABILITIES_BEGIN:
      return {
        ...state,
        getPersonAvailabilitiesPending: true,
        getPersonAvailabilitiesError: null,
      };

    case PEOPLE_GET_PERSON_AVAILABILITIES_SUCCESS: {
      const { filteredPeople } = state;
      const { data } = action;

      const newFilteredPeople = filteredPeople.map((person) => {
        const personAvailability = data.find(availability => availability.personId === person.id);
        const availableInDays = personAvailability ? personAvailability.availableInDays : NO_AVAILABILITY;
        return {
          ...person,
          availableInDays,
        };
      });

      return {
        ...state,
        availabilities: data,
        filteredPeople: newFilteredPeople,
        getPersonAvailabilitiesPending: false,
        getPersonAvailabilitiesError: null,
      };
    }

    case PEOPLE_GET_PERSON_AVAILABILITIES_FAILURE:
      return {
        ...state,
        getPersonAvailabilitiesPending: false,
        getPersonAvailabilitiesError: action.data.error,
      };

    case PEOPLE_GET_PERSON_AVAILABILITIES_DISMISS_ERROR:
      return {
        ...state,
        getPersonAvailabilitiesError: null,
      };

    default:
      return state;
  }
}
