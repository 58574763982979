import {
  TOASTS_SHOW_SUCCESS_TOAST,
  GANTT_GET_PEOPLE_GANTT_SHARE_DATA_SUCCESS_MESSAGE,
  GANTT_SET_PEOPLE_GANTT_SHARE_DATA_SUCCESS_MESSAGE,
} from './constants';
import {
  SELF_PERFORM_ASSIGN_REQUEST_SUCCESS,
  SELF_PERFORM_REMOVE_ASSIGNEE_SUCCESS,
} from '../../self-perform/redux/constants';
import {
  GANTT_GET_PEOPLE_GANTT_SHARE_DATA_SUCCESS,
  GANTT_SET_PEOPLE_GANTT_SHARE_DATA_SUCCESS,
} from '../../gantt/redux/constants';

export function showSuccessToast(successMessage) {
  return {
    type: TOASTS_SHOW_SUCCESS_TOAST,
    successMessage,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case SELF_PERFORM_ASSIGN_REQUEST_SUCCESS: {
      const { message } = action;
      const isNotified = message !== null;
      const successMessage = isNotified
        ? 'The request has been filled and communicated'
        : 'Your request has been filled, find it in the assignments tab';
      return {
        ...state,
        showSuccess: true,
        successMessage,
      };
    }
    case SELF_PERFORM_REMOVE_ASSIGNEE_SUCCESS: {
      const successMessage = 'The assignee has been removed. The assignment can now be found under the requests area.';
      return {
        ...state,
        showSuccess: true,
        successMessage,
      };
    }
    case GANTT_GET_PEOPLE_GANTT_SHARE_DATA_SUCCESS: {
      const successMessage = GANTT_GET_PEOPLE_GANTT_SHARE_DATA_SUCCESS_MESSAGE;
      return {
        ...state,
        showSuccess: true,
        successMessage,
      };
    }
    case GANTT_SET_PEOPLE_GANTT_SHARE_DATA_SUCCESS: {
      const successMessage = GANTT_SET_PEOPLE_GANTT_SHARE_DATA_SUCCESS_MESSAGE;
      return {
        ...state,
        showSuccess: true,
        successMessage,
      };
    }
    case TOASTS_SHOW_SUCCESS_TOAST:
      return {
        ...state,
        showSuccess: true,
        successMessage: action.successMessage || '',
      };

    default:
      return state;
  }
}
