import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  TextFilterPopper,
  CurrencyFilterPopper,
  DateFilterPopper,
  MultiFilterPopper,
  AvailabilityFilterPopper,
  FilterPopper,
} from '../common';
import { CertificationFilter } from '.';
import {
  FILTER_PROJECT_DATE,
  FILTER_ALLOCATION,
  FILTER_ALLOCATED_PEOPLE,
  FILTER_PHASE,
  FILTER_ROLES,
  FILTER_SORTED_LIST,
  FILTER_ISSUES,
  SELECT_LIST,
  SELECT_LIST_SEARCH,
  MAPPED_PEOPLE_FILTER_NAMES,
  FILTER_AVAILABILITIES,
  FILTER_CERTIFICATIONS,
} from '../../filters/constants';
import { PEOPLE_DISPLAY_CURRENT_PROJECT } from '../people/constants';

const FilterPopperFactory = ({ selectedFilter, anchorEl, onCancel, onApply, open, filterQueries, placement }) => {
  const {
    type,
    filterType,
    options: {
      filterOptions = [],
      controlOptions,
      singleFilter = false,
      isCustomSort = false,
    } = {},
  } = selectedFilter;

  const { name } = selectedFilter;
  const { args: filterArguments } = filterQueries;

  const filterArgument = useMemo(() => {
    const filterName = MAPPED_PEOPLE_FILTER_NAMES[name] || name;
    return filterArguments?.find(argument => argument.label === filterName);
  }, [filterArguments, name]);

  const selected = useMemo(() => (
    filterArgument ? filterArgument.activeFilters : []
  ), [filterArgument]);

  const selectedItems = useMemo(() => (
    filterOptions.length > 0
      ? selected.map(item => item.name)
      : []
  ), [selected, filterOptions.length]);

  const commonProps = {
    anchorEl,
    open,
    title: MAPPED_PEOPLE_FILTER_NAMES[name] || name,
    onApply,
    onCancel,
    placement,
  };

  if (filterType === FILTER_AVAILABILITIES) {
    return (
      <AvailabilityFilterPopper
        {...commonProps} // eslint-disable-line react/jsx-props-no-spreading
        placeholder="Enter Availability"
        origSelected={selected}
      />
    );
  }

  if (filterType === FILTER_CERTIFICATIONS) {
    return (
      <CertificationFilter
        popperProps={commonProps}
        origSelected={selected}
      />
    );
  }

  if (type === 'Currency') {
    return (
      <CurrencyFilterPopper
        {...commonProps} // eslint-disable-line react/jsx-props-no-spreading
        placeholder={`Enter ${name}`}
        origSelected={selected}
      />
    );
  }

  if (type === 'Address' || type === 'Text') {
    return (
      <TextFilterPopper
        {...commonProps} // eslint-disable-line react/jsx-props-no-spreading
        selected={selected && selected.length ? selected[0] : null}
      />
    );
  }

  const isDate = type === 'Date';
  const isProjectDate = filterType === FILTER_PROJECT_DATE;

  if (isDate || isProjectDate) {
    return (
      <DateFilterPopper
        {...commonProps} // eslint-disable-line react/jsx-props-no-spreading
        selected={selected && selected.length ? selected[0] : null}
        includeBlank={isDate}
      />
    );
  }

  if (filterOptions.length > 0 || filterType === FILTER_ALLOCATED_PEOPLE) {
    const isMultiSelect = type === 'MultiSelect';
    const isProjectAllocation = filterType === FILTER_ALLOCATION;
    const isPeopleAllocation = filterType === FILTER_ALLOCATED_PEOPLE;
    const isPhase = filterType === FILTER_PHASE;
    const isRoles = filterType === FILTER_ROLES;

    if (isMultiSelect
        || isProjectAllocation
        || isPeopleAllocation
        || isPhase
        || isRoles
    ) {
      const isCurrentProject = name === PEOPLE_DISPLAY_CURRENT_PROJECT;

      return (
        <MultiFilterPopper
          {...commonProps} // eslint-disable-line react/jsx-props-no-spreading
          items={filterOptions}
          origSelected={selected}
          filterKey="value"
          type={isPeopleAllocation ? SELECT_LIST_SEARCH : SELECT_LIST}
          searchPlaceholder={isCurrentProject ? 'Projects' : ''}
          verbText={isCurrentProject ? ' people allocated to' : ''}
          subText={isPhase ? 'Sub-phases' : ''}
          hasSubOptions={isPhase}
          controlOptions={controlOptions}
          isCustomSort={isCustomSort}
        />
      );
    }

    if (filterType === FILTER_SORTED_LIST) {
      return (
        <FilterPopper
          {...commonProps} // eslint-disable-line react/jsx-props-no-spreading
          items={filterOptions}
          selected={selectedItems}
          filterKey="name"
          placeholder={`Search ${name}`}
          placement="bottom"
          singleFilter={false}
          sortItems={!isCustomSort}
          showSearch
          showSelectAll
        />
      );
    }

    const isIssues = filterType === FILTER_ISSUES;
    const isBoolean = type === 'Boolean';
    const hasNoSearch = name === 'Status' || name === 'Type';
    const showSearch = !singleFilter && !isIssues && !isBoolean && !hasNoSearch;

    return (
      <FilterPopper
        {...commonProps} // eslint-disable-line react/jsx-props-no-spreading
        items={filterOptions}
        selected={selectedItems}
        filterKey="name"
        placeholder={`Search ${name}`}
        showSearch={showSearch}
        showSelectAll={!singleFilter && !isIssues}
        singleFilter={singleFilter}
        sortItems={!singleFilter}
      />
    );
  }
  return false;
};

FilterPopperFactory.propTypes = {
  filterQueries: PropTypes.object,
  selectedFilter: PropTypes.object,
  anchorEl: PropTypes.object,
  onCancel: PropTypes.func,
  onApply: PropTypes.func,
  open: PropTypes.bool,
  placement: PropTypes.string,
};

FilterPopperFactory.defaultProps = {
  filterQueries: {},
  selectedFilter: {},
  anchorEl: {},
  onCancel: () => {},
  onApply: () => {},
  open: false,
  placement: 'bottom-start',
};

export default FilterPopperFactory;
