import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PEOPLE_GET_PERSON_ATTACHMENT_BY_ID_BEGIN,
  PEOPLE_GET_PERSON_ATTACHMENT_BY_ID_SUCCESS,
  PEOPLE_GET_PERSON_ATTACHMENT_BY_ID_FAILURE,
  PEOPLE_GET_PERSON_ATTACHMENT_BY_ID_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';

export function getPersonAttachmentById(accountId, personId, attachmentId) {
  return {
    type: PEOPLE_GET_PERSON_ATTACHMENT_BY_ID_BEGIN,
    accountId,
    personId,
    attachmentId,
  };
}

export function dismissGetPersonAttachmentByIdError() {
  return {
    type: PEOPLE_GET_PERSON_ATTACHMENT_BY_ID_DISMISS_ERROR,
  };
}

export function* doGetPersonAttachmentById(action) {
  const { accountId, personId, attachmentId } = action;
  let res;
  try {
    res = yield call(protectedJsonGet, `/accounts/${accountId}/persons/${personId}/attachments/${attachmentId}`);
  } catch (err) {
    yield put({
      type: PEOPLE_GET_PERSON_ATTACHMENT_BY_ID_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PEOPLE_GET_PERSON_ATTACHMENT_BY_ID_SUCCESS,
    data: res,
    attachmentId,
  });
}

export function* watchGetPersonAttachmentById() {
  yield takeLatest(PEOPLE_GET_PERSON_ATTACHMENT_BY_ID_BEGIN, doGetPersonAttachmentById);
}

export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_GET_PERSON_ATTACHMENT_BY_ID_BEGIN:
      return {
        ...state,
        getPersonAttachmentByIdPending: true,
        getPersonAttachmentByIdError: null,
      };

    case PEOPLE_GET_PERSON_ATTACHMENT_BY_ID_SUCCESS: {
      const { attachmentId, data } = action;

      let newItems = state.personAttachments?.items || [];
      let newCertificationItems = state.personCertifications?.items || [];

      if (data?.binaryUrl) {
        newItems = newItems.map(attachment => (attachment.id === attachmentId ? data : attachment));

        newCertificationItems = newCertificationItems.map((cert) => {
          const hasAttachment = cert?.attachments?.find(attachment => attachment.id === attachmentId);
          return hasAttachment
            ? { ...cert, attachments: cert.attachments.map(attachment => (attachment.id === attachmentId ? data : attachment)) }
            : cert;
        });
      }

      return {
        ...state,
        personAttachments: {
          ...state.personAttachments,
          items: newItems,
        },
        personCertifications: {
          ...state.personCertifications,
          items: newCertificationItems,
        },
        getPersonAttachmentByIdPending: false,
        getPersonAttachmentByIdError: null,
      };
    }

    case PEOPLE_GET_PERSON_ATTACHMENT_BY_ID_FAILURE:
      return {
        ...state,
        getPersonAttachmentByIdPending: false,
        getPersonAttachmentByIdError: action.data.error,
      };

    case PEOPLE_GET_PERSON_ATTACHMENT_BY_ID_DISMISS_ERROR:
      return {
        ...state,
        getPersonAttachmentByIdError: null,
      };

    default:
      return state;
  }
}
