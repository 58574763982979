import React, { useState, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '@material-ui/core';
import { NavigateBack } from '@bridgit/foundation';
import { FieldEditor, ChipDropdownToggle, Modal } from 'src/features/common';
import {
  clearSelectedRequest,
  getBestMatches,
  assignRequest,
  deleteRequest,
  updateHourlyRequest,
} from 'src/features/self-perform/redux/actions';
import {
  REQUEST_STATUS_OPTIONS,
  REQUEST_WRITE_PERMS,
  REQUEST_REJECTED,
  SKILL_SET_FIELD,
} from 'src/features/self-perform/constants';
import {
  getMainItemsDesc,
  getOtherItemsDesc,
  getDeleteRequestModalText,
  getNormalizedUpdateRequestData,
  validateUpdateRequestData,
} from 'src/features/self-perform/utils/requestDetailsUtils';
import { Can, EditControls } from 'src/features/wrapped-components';
import {
  PERM_REQUEST,
  PERM_WRITE,
  REQUEST_STATUS_TOGGLE_PERMISSIONS,
} from 'src/features/permissions/utils/constants';
import { getFieldInput } from 'src/utils/miscUtils';
import { AssigneeDetails, BestMatches } from '.';
import { getSkillSet } from '../account-settings/redux/actions';

function RequestDetails({
  accountId,
  selectedRequest,
  clearSelectedRequest,
  getBestMatches,
  assignRequest,
  updateHourlyRequest,
  deleteRequest,
  deleteRequestPending,
  getSkillSet,
  skillSet,
  updateHourlyRequestPending,
  bestMatchSearchQuery,
}) {
  const [showModal, setShowModal] = useState(false);
  const [fieldToEdit, setFieldToEdit] = useState(null);

  const mainItems = useMemo(() => getMainItemsDesc(selectedRequest, skillSet), [selectedRequest, skillSet]);
  const otherItems = useMemo(() => getOtherItemsDesc(selectedRequest), [selectedRequest]);

  useEffect(() => {
    getSkillSet(accountId);
  }, [accountId, getSkillSet]);

  const openModal = useCallback(() => setShowModal(true), []);
  const closeModal = useCallback(() => setShowModal(false), []);

  useEffect(() => {
    if (selectedRequest) {
      const { assignee, project, id, state } = selectedRequest;
      const { id: projectId, name: projectName } = project;
      const mixpanelData = { projectName };

      if (!assignee && state !== REQUEST_REJECTED) {
        getBestMatches(accountId, projectId, id, bestMatchSearchQuery, mixpanelData);
      }
    }
  }, [selectedRequest, accountId, getBestMatches, bestMatchSearchQuery]);

  const handleSetEditing = useCallback(id => () => setFieldToEdit(id), []);

  const handleCancelEditing = useCallback(() => setFieldToEdit(null), []);

  const onSave = useCallback(({ fieldId, values }) => {
    const { id: requestId, project, state } = selectedRequest;
    const { id: projectId, name: projectName } = project;

    const newData = getNormalizedUpdateRequestData(fieldId, values);

    updateHourlyRequest(accountId, projectId, requestId, newData, projectName, state);

    handleCancelEditing();
  }, [selectedRequest, updateHourlyRequest, accountId, handleCancelEditing]);

  const onBackClick = useCallback(() => {
    clearSelectedRequest();
  }, [clearSelectedRequest]);

  const onAssign = useCallback((assigneeId, assigneeName, message, defaultMessage) => {
    if (selectedRequest) {
      const { project, state, requester, id } = selectedRequest;
      const { id: projectId, name: projectName } = project;
      const mixpanelData = {
        projectName,
        personName: assigneeName,
        requestState: state,
        requester,
        defaultMessage,
      };
      assignRequest(accountId, projectId, id, { assigneeId, message }, mixpanelData);
      clearSelectedRequest();
    }
  }, [accountId, selectedRequest, assignRequest, clearSelectedRequest]);

  const onValidate = useCallback(data => validateUpdateRequestData(fieldToEdit, data, selectedRequest), [fieldToEdit, selectedRequest]);

  const renderItems = items => items.map(item => (
    <div
      key={item.id}
      className={classnames(
        'item',
        { full: item.fullWidth, header: item.isHeader, 'skill-set': item.name === SKILL_SET_FIELD },
      )}
    >
      <FieldEditor
        item={item}
        input={getFieldInput(item)}
        onSave={onSave}
        onCancel={handleCancelEditing}
        onValidate={onValidate}
        disabled={fieldToEdit !== null && fieldToEdit !== item.id}
        displayOnly={item.displayOnly}
        permission={REQUEST_WRITE_PERMS}
        pending={updateHourlyRequestPending}
        fieldToEdit={handleSetEditing(item.id)}
        editing={fieldToEdit === item.id}
      />
    </div>
  ));

  const { modalText, modalTitle } = useMemo(() => getDeleteRequestModalText(selectedRequest), [selectedRequest]);

  const removeRequestButton = useMemo(() => (
    <Button
      className="deactivate-button"
      disableRipple
      variant="contained"
      size="medium"
      onClick={openModal}
    >
      {modalTitle}
    </Button>
  ), [openModal, modalTitle]);

  const onDeleteRequest = useCallback(() => {
    const { id, project, requester, note, state } = selectedRequest;
    const { id: projectId, name: projectName } = project;
    const mixpanelData = {
      projectName,
      requester,
      note,
      status: state,
    };

    deleteRequest(accountId, projectId, id, mixpanelData);
    closeModal();
  }, [selectedRequest, accountId, deleteRequest, closeModal]);

  const renderSidePanel = () => {
    if (selectedRequest?.assignee?.id) {
      return <AssigneeDetails />;
    }

    if (selectedRequest?.state !== REQUEST_REJECTED) {
      return <BestMatches onAssign={onAssign} selectedRequest={selectedRequest} />;
    }

    return (
      <div className="rejected-message">
        <div className="message">
          This request has been rejected. Please re-open the request in order to assign someone to it.
        </div>
      </div>
    );
  };

  const updateRequestStatus = useCallback((state) => {
    const { project, id, state: status } = selectedRequest;
    const { id: projectId, name: projectName } = project;
    updateHourlyRequest(accountId, projectId, id, { state }, projectName, status);
  }, [accountId, selectedRequest, updateHourlyRequest]);

  if (!selectedRequest) return null;

  return (
    <div className="self-perform-request-details">
      <div className="back-button">
        <NavigateBack onClick={onBackClick} label="Back to list of requests" />
      </div>
      <div className="request-details">
        <ChipDropdownToggle
          options={REQUEST_STATUS_OPTIONS}
          selectedId={selectedRequest.state}
          onSelect={updateRequestStatus}
          permissions={REQUEST_STATUS_TOGGLE_PERMISSIONS}
        />
        <div className="request-details-section">
          {renderItems(mainItems)}
        </div>
        <div className="request-details-section">
          {renderItems(otherItems)}
        </div>
        <div className="request-details-section">
          <Can
            action={PERM_WRITE}
            subject={PERM_REQUEST}
            yes={removeRequestButton}
          />
        </div>
      </div>
      {renderSidePanel()}

      {showModal && (
      <Modal
        className="delete-request-modal"
        headline={modalTitle}
        showClose={false}
      >
        {modalText}
        <EditControls
          className="delete-request-modal-buttons"
          primaryAction={onDeleteRequest}
          secondaryAction={closeModal}
          primaryText="Delete"
          pending={deleteRequestPending}
        />
      </Modal>
      )}
    </div>
  );
}

RequestDetails.propTypes = {
  accountId: PropTypes.number.isRequired,
  selectedRequest: PropTypes.object,
  clearSelectedRequest: PropTypes.func.isRequired,
  getBestMatches: PropTypes.func.isRequired,
  assignRequest: PropTypes.func.isRequired,
  updateHourlyRequest: PropTypes.func.isRequired,
  deleteRequest: PropTypes.func.isRequired,
  deleteRequestPending: PropTypes.bool.isRequired,
  getSkillSet: PropTypes.func.isRequired,
  skillSet: PropTypes.array.isRequired,
  updateHourlyRequestPending: PropTypes.bool,
  bestMatchSearchQuery: PropTypes.object,
};

RequestDetails.defaultProps = {
  selectedRequest: null,
  updateHourlyRequestPending: false,
  bestMatchSearchQuery: {},
};

/* istanbul ignore next */
function mapStateToProps({ selfPerform, common, accountSettings, queries }) {
  const { selectedRequest, updateHourlyRequestPending, deleteRequestPending } = selfPerform;
  const { accountId } = common;
  const { skillSet } = accountSettings;

  const { search: bestMatchSearchQuery = null } = queries?.bestMatches || {};

  return {
    selectedRequest,
    accountId,
    deleteRequestPending,
    skillSet,
    updateHourlyRequestPending,
    bestMatchSearchQuery,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    clearSelectedRequest: bindActionCreators(clearSelectedRequest, dispatch),
    getBestMatches: bindActionCreators(getBestMatches, dispatch),
    assignRequest: bindActionCreators(assignRequest, dispatch),
    deleteRequest: bindActionCreators(deleteRequest, dispatch),
    getSkillSet: bindActionCreators(getSkillSet, dispatch),
    updateHourlyRequest: bindActionCreators(updateHourlyRequest, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestDetails);
