import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNTS_UPDATE_ROLE_TEMPLATE_BEGIN,
  ACCOUNTS_UPDATE_ROLE_TEMPLATE_SUCCESS,
  ACCOUNTS_UPDATE_ROLE_TEMPLATE_FAILURE,
  ACCOUNTS_UPDATE_ROLE_TEMPLATE_DISMISS_ERROR,
} from './constants';
import { protectedJsonPut } from '../../../utils/api';

export function updateRoleTemplate(accountId, roleTemplate, templateId) {
  return {
    type: ACCOUNTS_UPDATE_ROLE_TEMPLATE_BEGIN,
    accountId,
    roleTemplate,
    templateId,
  };
}

export function dismissUpdateRoleTemplateError() {
  return {
    type: ACCOUNTS_UPDATE_ROLE_TEMPLATE_DISMISS_ERROR,
  };
}

export function* doUpdateRoleTemplate(action) {
  const { accountId, roleTemplate, templateId } = action;
  let res;
  try {
    res = yield call(protectedJsonPut, `accounts/${accountId}/role-templates/${templateId}`, roleTemplate);
  } catch (err) {
    yield put({
      type: ACCOUNTS_UPDATE_ROLE_TEMPLATE_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: ACCOUNTS_UPDATE_ROLE_TEMPLATE_SUCCESS,
    data: res,
    roleTemplate,
    templateId,
  });
}

export function* watchUpdateRoleTemplate() {
  yield takeLatest(ACCOUNTS_UPDATE_ROLE_TEMPLATE_BEGIN, doUpdateRoleTemplate);
}

export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNTS_UPDATE_ROLE_TEMPLATE_BEGIN:
      return {
        ...state,
        updateRoleTemplatePending: true,
        updateRoleTemplateError: null,
      };

    case ACCOUNTS_UPDATE_ROLE_TEMPLATE_SUCCESS: {
      const { roleTemplate, templateId } = action;

      // Combine roles with the same name and allocation percent (increment quantity by current role quantity instead)
      const templateRolesCombined = roleTemplate.data.reduce((newTemplateRoles, currentRole) => {
        const sameRole = newTemplateRoles.find(({ roleName, percent }) => roleName === currentRole.roleName && percent === currentRole.percent);

        if (sameRole) {
          sameRole.quantity += currentRole.quantity;
        } else {
          newTemplateRoles.push(currentRole);
        }

        return newTemplateRoles;
      }, []);

      const roleTemplatesCopy = [...state.roleTemplates];
      const templateToUpdate = roleTemplatesCopy.find(({ id }) => id === templateId);

      if (templateToUpdate) {
        templateToUpdate.data = templateRolesCombined;
        templateToUpdate.name = roleTemplate.name;
      }

      return {
        ...state,
        updateRoleTemplatePending: false,
        updateRoleTemplateError: null,
        roleTemplates: roleTemplatesCopy,
      };
    }

    case ACCOUNTS_UPDATE_ROLE_TEMPLATE_FAILURE:
      return {
        ...state,
        updateRoleTemplatePending: false,
        updateRoleTemplateError: action.data.error,
      };

    case ACCOUNTS_UPDATE_ROLE_TEMPLATE_DISMISS_ERROR:
      return {
        ...state,
        updateRoleTemplateError: null,
      };

    default:
      return state;
  }
}
