import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { getAccountById } from '../../utils/miscUtils';
import { FILTERED_PROJECTS_QUERY_ID } from '../queries/redux/constants';
import { PROJECT_GANTT_CONFIG_KEY } from '../gantt/redux/constants';
import { EXPANDED_PROJECT_GANTT, GANTT_TAB, PROJECT_VIEW } from '../../common/constants';
import {
  FILLED_ROLES,
  UNFILLED_ROLES,
  FILTER_AVAILABILITIES,
} from '../../filters/constants';
import { SORT_ROLES_BY_DISPLAY_MAP } from '../gantt/project-gantt/constants';
import {
  TABLOID_PAPER,
  LETTER_PAPER,
  MAX_LETTER_ROLE_COUNT,
  MAX_TABLOID_ROLE_COUNT,
} from './redux/constants';
import { parseRoleFilters } from './utils/reportUtils';

function CoverPage({
  accounts,
  accountId,
  queries,
  visibleColumns,
  title,
  subTitle,
  showFields,
  queryId,
  roleSortOption,
  roleFilters,
  isWithProjectRoles,
  isProjectGanttView,
  paperSize,
}) {
  const now = moment.tz(moment.tz.guess()).format('MMMM D, YYYY [at] h:mm A z');
  const account = getAccountById(accounts, accountId);
  const queryObj = queries[queryId];

  const filters = queryObj.filter;
  const sortQuery = queryObj.sort;
  const searchQuery = queryObj.search;

  const renderGroup = (label, filterNames, verb, key) => (
    <div key={key} className="field-wrap">
      <div className="filter-label">{`${label} ${verb}:`}</div>
      <div>{filterNames}</div>
    </div>
  );

  const renderFilters = () => {
    const filterArgs = filters.args;
    const chipData = filterArgs && filterArgs.length ? filters.args.filter(arg => 'activeFilters' in arg) : [];
    const filterGroups = [];
    chipData.forEach((filter) => {
      if (filter.filterType === FILTER_AVAILABILITIES) {
        filter.activeFilters.forEach((activeFilter) => {
          filterGroups.push(
            renderGroup(
              filter.label, activeFilter.name, activeFilter.verb.toLowerCase(),
              `${activeFilter.verb}-${activeFilter.name}`,
            ),
          );
        });
      } else if (filter.activeFilters.length && filter.activeFilters[0].verb) {
        filter.activeFilters.forEach((activeFilter, index) => {
          const filterNames = activeFilter.selected.map(selected => selected.name).join(', ');
          filterGroups.push(renderGroup(filter.label, filterNames, activeFilter.verb.toLowerCase(), `${filter.label}-${index}`));
        });
      } else {
        const filterNames = filter.activeFilters.map(activeFilter => activeFilter.name).join(', ');
        filterGroups.push(renderGroup(filter.label, filterNames, 'includes', filter.label));
      }
    });

    if (searchQuery && searchQuery.args && searchQuery.args.length) {
      filterGroups.push(renderGroup('Searched', searchQuery.args[0].activeFilters[0], 'on', 'bridgit-search'));
    }

    return filterGroups;
  };

  const renderGanttSettings = () => {
    if (!isProjectGanttView || !isWithProjectRoles) return null;

    const { nameFilters, hasFilledFilter, hasUnfilledFilter } = parseRoleFilters(roleFilters);
    let hasMoreRolesCount = 0;
    let slicedNameFilters = nameFilters;

    if (nameFilters.length > MAX_LETTER_ROLE_COUNT && paperSize === LETTER_PAPER) {
      hasMoreRolesCount = nameFilters.length - MAX_LETTER_ROLE_COUNT;
      slicedNameFilters = nameFilters.slice(0, MAX_LETTER_ROLE_COUNT);
    } else if (nameFilters.length > MAX_TABLOID_ROLE_COUNT && paperSize === TABLOID_PAPER) {
      hasMoreRolesCount = nameFilters.length - MAX_TABLOID_ROLE_COUNT;
      slicedNameFilters = nameFilters.slice(0, MAX_TABLOID_ROLE_COUNT);
    }

    const isSingleFilter = (hasFilledFilter && !hasUnfilledFilter) || (!hasFilledFilter && hasUnfilledFilter);

    return (
      <>
        <div className="filter-title">Gantt settings applied:</div>
        <div className="filters-applied">
          <div className="field-wrap">
            <div className="filter-label">Roles sorted by:</div>
            <div>{SORT_ROLES_BY_DISPLAY_MAP[roleSortOption]}</div>
          </div>
          {isSingleFilter && (
            <div className="field-wrap">
              <div className="filter-label">Filter on Roles:</div>
              <div>{hasFilledFilter ? FILLED_ROLES : UNFILLED_ROLES}</div>
            </div>
          )}
          {slicedNameFilters.length > 0 && (
            <div className="field-wrap">
              <div className="filter-label">Roles included:</div>
              <div>{slicedNameFilters.join(', ')}</div>
              {hasMoreRolesCount > 0 && <div>{`+${hasMoreRolesCount} More Roles`}</div>}
            </div>
          )}
        </div>
      </>
    );
  };

  if (!account) return null;

  const { logoUrl, name } = account;
  const filterTitlePrefix = queryId === FILTERED_PROJECTS_QUERY_ID ? 'Project' : 'People';

  return (
    <div className="reports-cover-page">
      <table>
        <tbody>
          <tr>
            <td>
              {logoUrl && <img src={logoUrl} className="logo" alt="logo" />}
              <div className="account-info">
                <div className="account-name">{name}</div>
                <div className="report-title">{title}</div>
                <div className="report-subtitle">{subTitle}</div>
                <div className="now">{now}</div>
              </div>
              <div className="divider" />
              <div className="filter-title">{`${filterTitlePrefix} filters applied:`}</div>
              <div className="filters-applied">
                {renderFilters()}
                <div className="field-wrap">
                  <div>Sort order:</div>
                  <div>{sortQuery?.args?.[0]?.label || ''}</div>
                </div>
              </div>
              <div className="divider" />
              {renderGanttSettings()}
              {showFields && (
                <div className="field-wrap">
                  <div>Fields included:</div>
                  <div>
                    {visibleColumns.map(column => column.name).join(', ')}
                  </div>
                </div>
              )}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              <div className="footer-space" />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

CoverPage.propTypes = {
  accountId: PropTypes.number,
  accounts: PropTypes.arrayOf(PropTypes.object),
  queries: PropTypes.object,
  visibleColumns: PropTypes.array,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  showFields: PropTypes.bool,
  queryId: PropTypes.string.isRequired,
  isWithProjectRoles: PropTypes.bool,
  roleSortOption: PropTypes.string,
  roleFilters: PropTypes.array,
  isProjectGanttView: PropTypes.bool.isRequired,
  paperSize: PropTypes.string,
};

CoverPage.defaultProps = {
  title: '',
  subTitle: '',
  accountId: null,
  accounts: [],
  visibleColumns: [],
  showFields: true,
  queries: {},
  isWithProjectRoles: false,
  roleSortOption: null,
  roleFilters: [],
  paperSize: TABLOID_PAPER,
};

function mapStateToProps({
  common: { accountId, contentView, activeView },
  table: { visibleColumns },
  accounts: { entities },
  reports: { settings },
  queries,
  gantt,
}) {
  const { roleSortOption, roleFilters, view } = gantt?.instances?.[PROJECT_GANTT_CONFIG_KEY] || {};
  const isWithProjectRoles = view === EXPANDED_PROJECT_GANTT;
  const isProjectGanttView = activeView === PROJECT_VIEW && contentView === GANTT_TAB;
  const { paperSize } = settings;

  return {
    accounts: entities,
    accountId,
    queries,
    visibleColumns,
    isWithProjectRoles,
    roleSortOption,
    roleFilters,
    isProjectGanttView,
    paperSize,
  };
}

export default connect(
  mapStateToProps,
)(CoverPage);
