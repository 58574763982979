import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export class TabbedPageHeader extends PureComponent {
  static propTypes = {
    tabs: PropTypes.node,
    controls: PropTypes.node,
    pageTitle: PropTypes.node,
  };

  static defaultProps = {
    tabs: '',
    controls: '',
    pageTitle: '',
  };

  render() {
    const { pageTitle, tabs, controls } = this.props;
    return (
      <div className="common-tabbed-page-header">
        {!!pageTitle && <div className="page-title">{pageTitle}</div>}

        <div className="tab-wrapper">
          {tabs}
        </div>
        <div className="control-wrapper">
          {controls}
        </div>
      </div>
    );
  }
}

export default TabbedPageHeader;
