import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getRelativeAvailabilityBlocks } from 'src/features/allocations/utils/timelineUtils';
import { Timeline } from 'src/features/common';
import classNames from 'classnames';
import { TIME_OFF } from 'src/common/constants';

const AvailabilityIndicator = ({
  personId,
  fullCoverage,
  roleStart,
  roleEnd,
  availabilities,
  roleRequirements,
  unavailabilities,
  onSegmentClick,
  placeholder,
}) => {
  const handleSegmentClick = (segmentRef, blockData) => {
    const { fullCoverage } = blockData;

    if (fullCoverage) return;

    onSegmentClick({
      segmentRef,
      ...blockData,
    }, personId);
  };

  const getSegmentClasses = useCallback((segment) => {
    if (segment.fullCoverage) return { topClass: '', bottomClass: '' };
    if (segment.partialCoverage) return { topClass: classNames('greyed-out', { 'tooltip-hover': placeholder }), bottomClass: '' };
    if (segment.unavailable) {
      const unavailabilityClassName = segment.rangeType === TIME_OFF ? 'no-backfill' : 'blacked-out';
      return { topClass: unavailabilityClassName, bottomClass: unavailabilityClassName };
    }

    return { topClass: classNames('greyed-out', { 'tooltip-hover': placeholder }), bottomClass: classNames('greyed-out', { 'tooltip-hover': placeholder }) };
  }, [placeholder]);

  const segments = useMemo(() => {
    const relativeBlocks = getRelativeAvailabilityBlocks(roleRequirements, availabilities, unavailabilities, roleStart, roleEnd);
    return relativeBlocks.map(block => ({ ...block, ...getSegmentClasses(block) }));
  }, [roleRequirements, availabilities, unavailabilities, roleStart, roleEnd, getSegmentClasses]);

  return (
    <div className="allocations-availability-indicator">
      <div className={`status-indicator ${fullCoverage ? 'available' : 'unavailable'}`} />
      <div className="timeline-wrapper">
        <Timeline
          startDate={roleStart}
          endDate={roleEnd}
          segments={segments}
          onSegmentClick={handleSegmentClick}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

AvailabilityIndicator.propTypes = {
  personId: PropTypes.number.isRequired,
  fullCoverage: PropTypes.bool.isRequired,
  availabilities: PropTypes.arrayOf(PropTypes.object).isRequired,
  unavailabilities: PropTypes.arrayOf(PropTypes.object),
  roleRequirements: PropTypes.array,
  roleStart: PropTypes.string.isRequired,
  roleEnd: PropTypes.string.isRequired,
  onSegmentClick: PropTypes.func,
  placeholder: PropTypes.string,
};

AvailabilityIndicator.defaultProps = {
  onSegmentClick: () => {},
  unavailabilities: [],
  roleRequirements: [],
  placeholder: '',
};

export default AvailabilityIndicator;
