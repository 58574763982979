import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, CircularProgress, FormHelperText, Grid, Input, Paper, Typography } from '@material-ui/core';
import queryString from 'query-string';
import { validateEmail } from 'src/utils/validators';
import { PasswordForm } from '@bridgit/foundation';
import * as actions from './redux/actions';
import { ExpiredReset } from '.';

export class ForgotPassword extends PureComponent {
  static propTypes = {
    login: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
  };

  constructor(props) {
    super(props);
    const { token } = queryString.parse(props.location.search);

    this.state = {
      email: '',
      error: false,
      token,
    };
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { login } = this.props;
    if (login.requestForgotPasswordPending && !nextProps.login.requestForgotPasswordPending) {
      nextProps.history.push('/login?msg=emailSuccess');
    } else if (login.resetPasswordPending && !nextProps.login.resetPasswordPending && !nextProps.login.resetPasswordError) {
      nextProps.history.push('/login?msg=resetSuccess');
    }
  }

  onEmailChange = (e) => {
    const { value } = e.currentTarget;

    this.setState({
      email: value,
      error: false,
    });
  }

  validateEmail = () => {
    const { email } = this.state;

    // if email input is empty and the user clicks `BACK` button, do no show error message
    if (!email.length) return null;

    if (!validateEmail(email)) {
      this.setState({
        error: true,
      });
      return false;
    }
    return true;
  }

  send = () => {
    const { actions } = this.props;
    const { email } = this.state;

    actions.requestForgotPassword(email);
  }

  checkEnter = (e) => {
    if (e.keyCode === 13 && this.validateEmail()) {
      this.send();
    }
  }

  forgotPassword = () => {
    const { email, error } = this.state;
    const { login } = this.props;

    return (
      <Paper component="section" variant="outlined" className="spacious-container">
        <Typography
          className="headline"
          color="primary"
          variant="subtitle1"
          component="h4"
        >
          Forgot Password
        </Typography>

        <Typography variant="body1" className="login-forgot-password-subtitle">Enter your email and we will send you instructions to reset your password.</Typography>
        <Typography component="span" variant="caption" className="email-label">Email</Typography>
        <Input
          fullWidth
          autoFocus
          value={email}
          inputProps={{ maxLength: 60 }}
          onChange={this.onEmailChange}
          onBlur={this.validateEmail}
          onKeyUp={this.checkEnter}
        />
        {error && <FormHelperText className="form__warning">Please enter a valid email address</FormHelperText>}

        <section className="button__actions">
          <Button
            color="primary"
            disableRipple
          >
            <Link className="back" to="/login">Back</Link>
          </Button>
          {!login.requestForgotPasswordPending
            ? (
              <Button
                // disable the button by default (when the user just lands on this page, and there's no email.length)
                disabled={error || !email.length}
                color="primary"
                disableRipple
                size="medium"
                type="submit"
                variant="contained"
                onClick={this.send}
              >
                Send
              </Button>
            )
            : <CircularProgress size={25} color="primary" />}
        </section>
      </Paper>
    );
  }

  handleResetPassword = ({ password }) => {
    const { token } = this.state;
    const { actions } = this.props;

    actions.resetPassword(token, password);
  }

  backToLogin = () => {
    const { history } = this.props;
    history.push('/login');
  }

  renderSwitch = () => {
    const { token } = this.state;
    const { actions, login } = this.props;

    if (login.resetPasswordError && login.resetPasswordError.status === 403) {
      return <ExpiredReset dismissResetPasswordError={actions.dismissResetPasswordError} />;
    }

    if (token) {
      return (
        <Paper component="section" variant="outlined" className="login-reset-password spacious-container">
          <PasswordForm
            primaryAction={this.handleResetPassword}
            primaryActionLabel="Reset"
            primaryActionPending={login.resetPasswordPending}
            secondaryAction={this.backToLogin}
            secondaryActionLabel="Sign In"
            passwordPlaceholder="Enter new password"
            passwordFieldLabel="New password"
            showConfirmPassword
            confirmPasswordPlaceholder="Re-enter new password"
            confirmPasswordFieldLabel="Confirm new password"
            header="Reset your password"
            showEmail={false}
          />
        </Paper>
      );
    }

    return this.forgotPassword();
  }

  render() {
    return (
      <Grid container justify="center" item xs={12} className="login-forgot-password">
        <Grid container justify="center" item xs={12}>
          <div className="login-forgot-password-box">
            {this.renderSwitch()}
          </div>
        </Grid>
      </Grid>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    login: state.login,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgotPassword);
