import { call, put, takeLatest } from 'redux-saga/effects';
import {
  SELF_PERFORM_UPDATE_HOURLY_PERSON_BEGIN,
  SELF_PERFORM_UPDATE_HOURLY_PERSON_SUCCESS,
  SELF_PERFORM_UPDATE_HOURLY_PERSON_FAILURE,
  SELF_PERFORM_UPDATE_HOURLY_PERSON_DISMISS_ERROR,
} from './constants';
import { protectedJsonPatch } from '../../../utils/api';

export function updateHourlyPerson(accountId, hourlyPersonId, data, hourlyPersonName, updatedFrom) {
  return {
    type: SELF_PERFORM_UPDATE_HOURLY_PERSON_BEGIN,
    accountId,
    hourlyPersonId,
    data,
    hourlyPersonName,
    updatedFrom,
  };
}

export function dismissUpdateHourlyPersonError() {
  return {
    type: SELF_PERFORM_UPDATE_HOURLY_PERSON_DISMISS_ERROR,
  };
}

export function* doUpdateHourlyPerson(action) {
  const { accountId, hourlyPersonId, data, hourlyPersonName, updatedFrom } = action;
  let res;
  try {
    res = yield call(protectedJsonPatch, `accounts/${accountId}/hourly-persons/${hourlyPersonId}`, data);
  } catch (err) {
    yield put({
      type: SELF_PERFORM_UPDATE_HOURLY_PERSON_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: SELF_PERFORM_UPDATE_HOURLY_PERSON_SUCCESS,
    data: res,
    hourlyPersonId,
    formData: data,
    hourlyPersonName,
    updatedFrom,
  });
}

export function* watchUpdateHourlyPerson() {
  yield takeLatest(SELF_PERFORM_UPDATE_HOURLY_PERSON_BEGIN, doUpdateHourlyPerson);
}

export function reducer(state, action) {
  switch (action.type) {
    case SELF_PERFORM_UPDATE_HOURLY_PERSON_BEGIN:
      return {
        ...state,
        updateHourlyPersonPending: true,
        updateHourlyPersonError: null,
      };

    case SELF_PERFORM_UPDATE_HOURLY_PERSON_SUCCESS: {
      const { hourlyPersonId, formData } = action;
      const updatedHourlyPersons = state.filteredHourlyPersons.map((hourlyPerson) => {
        if (hourlyPerson.id === hourlyPersonId) {
          return { ...hourlyPerson, ...formData };
        }

        return hourlyPerson;
      });

      return {
        ...state,
        filteredHourlyPersons: updatedHourlyPersons,
        selectedHourlyPerson: { ...state.selectedHourlyPerson, ...formData },
        updateHourlyPersonPending: false,
        updateHourlyPersonError: null,
      };
    }

    case SELF_PERFORM_UPDATE_HOURLY_PERSON_FAILURE:
      return {
        ...state,
        updateHourlyPersonPending: false,
        updateHourlyPersonError: action.data.error,
      };

    case SELF_PERFORM_UPDATE_HOURLY_PERSON_DISMISS_ERROR:
      return {
        ...state,
        updateHourlyPersonError: null,
      };

    default:
      return state;
  }
}
