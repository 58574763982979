const getDateFormat = (nonTrimmedDate) => {
  const date = nonTrimmedDate.trim().replace(/-/g, '/');

  // mm/dd/yyyy
  const isDate1 = /^((0[1-9]|1[0-2])[/-](0[1-9]|[12]\d|3[01])[/-][12]\d{3})$/;
  if (date.match(isDate1)) return 'MM/DD/YYYY';

  // mm/dd/yy
  const isDate2 = /^((0[1-9]|1[0-2])[/](0[1-9]|[12]\d|3[01])[/]\d{2})$/;
  if (date.match(isDate2)) return 'MM/DD/YY';

  // yy/mm/dd
  const isDate3 = /^(\d{2}[/](0[1-9]|1[0-2])[/](0[1-9]|[12]\d|3[01]))$/;
  if (date.match(isDate3)) return 'YY/MM/DD';

  // yyyy/mm/dd
  const isDate4 = /^([12]\d{3}[/](0[1-9]|1[0-2])[/](0[1-9]|[12]\d|3[01]))$/;
  if (date.match(isDate4)) return 'YYYY/MM/DD';

  if (date.length >= 10 && date.length <= 12) {
    // MMM D, YYYY
    // MMM D YYYY
    const monthList = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
    const hasComma = date.indexOf(',') !== -1;
    const splitDates = date.replace(/,/g, '').split(' ');

    if (splitDates.length === 3) {
      const [month, day, year] = splitDates;
      const lowerMonth = month.toLowerCase();
      const dayInt = parseInt(day, 10);
      const yearInt = parseInt(year, 10);

      if (dayInt > 0 && dayInt < 32 && yearInt > 1000 && yearInt < 3000 && monthList.indexOf(lowerMonth) !== -1) {
        return hasComma ? 'MMM D, YYYY' : 'MMM D YYYY';
      }
    }
  }

  return false;
};

const removeReservedCharacters = (string) => {
  const unsupportedChars = /[<>+\-=&|!(){}[\]^"~*?:\\/,$]/g;
  const space = /\s+/g;
  return string
    .trim()
    .replace(unsupportedChars, '')
    .replace(space, '*');
};

export {
  getDateFormat,
  removeReservedCharacters,
};
