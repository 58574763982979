import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  PeoplePDFReport,
  PeopleCSVReport,
  ProjectCSVReport,
  ProjectPDFReport,
  GanttReport,
  RoleSegmentCSVReport,
} from '.';
import { setReport, clearReportData } from './redux/actions';
import {
  PEOPLE_LIST_PDF_REPORT,
  PROJECT_GANTT_PDF_REPORT,
  PEOPLE_GANTT_PDF_REPORT,
  PEOPLE_LIST_CSV_REPORT,
  PROJECT_LIST_CSV_REPORT,
  PROJECT_LIST_PDF_REPORT,
  ROLE_SEGMENT_CSV_REPORT,
} from './redux/constants';
import { SETTINGS_TABS } from '../account-settings/common/constants';

const { PEOPLE, PROJECTS } = SETTINGS_TABS;

const ReportFactory = () => {
  const dispatch = useDispatch();

  const { showReport, type } = useSelector(({ reports }) => reports);
  const { newPeopleGantt: newPeopleGanttFlag } = useSelector(({ launchDarkly }) => launchDarkly);

  const REPORTS_MAP = {
    [PEOPLE_LIST_PDF_REPORT]: <PeoplePDFReport />,
    [PEOPLE_LIST_CSV_REPORT]: <PeopleCSVReport />,
    [PROJECT_LIST_CSV_REPORT]: <ProjectCSVReport />,
    [PROJECT_LIST_PDF_REPORT]: <ProjectPDFReport />,
    [PROJECT_GANTT_PDF_REPORT]: <GanttReport type={PROJECTS} />,
    [PEOPLE_GANTT_PDF_REPORT]: <GanttReport type={PEOPLE} />,
    [ROLE_SEGMENT_CSV_REPORT]: <RoleSegmentCSVReport />,
  };

  useEffect(() => {
    const onAfterPrint = () => {
      setTimeout(() => {
        dispatch(setReport(false));
        if (!newPeopleGanttFlag) dispatch(clearReportData());
      }, 0);
    };

    const printListener = (print) => {
      if (!print.matches) {
        onAfterPrint();
      }
    };

    let listener;
    if (window.matchMedia) {
      listener = window.matchMedia('print');
      listener.addListener(printListener);
    }

    window.onafterprint = onAfterPrint;

    return () => {
      if (listener) {
        listener.removeListener(printListener);
      }
    };
  }, [newPeopleGanttFlag, dispatch]);

  if (!showReport) return null;

  return REPORTS_MAP[type];
};

export default ReportFactory;
