import {
  COMMON_SET_ACTIVE_ACCOUNT_ID,
  COMMON_CLEAR_MULTI_STATE_MODAL_STATE,
  COMMON_SET_SELECTED_PROJECT_ID,
  COMMON_SET_SELECTED_PERSON_ID,
  COMMON_SET_PRIVATE_MODE,
  COMMON_TRACK_ANALYTICS,
} from 'src/features/common/redux/constants';
import {
  COMMON_NAVIGATE_BETWEEN_ACCOUNTS,
  COMMON_MODAL_CLOSED,
  COMMON_NAVIGATE_BETWEEN_MODALS,
  COMMON_CONFIDENTIAL_MODE_TOGGLE_USED,
} from './constants';

const events = {
  [COMMON_SET_ACTIVE_ACCOUNT_ID]: {
    getEvent: (state, { analyticsPayload }) => (analyticsPayload?.isNavigatingBetweenAccounts ? COMMON_NAVIGATE_BETWEEN_ACCOUNTS : null),
    getPayload: () => null,
  },

  [COMMON_CLEAR_MULTI_STATE_MODAL_STATE]: {
    getEvent: (state, { analyticsPayload }) => (analyticsPayload ? COMMON_MODAL_CLOSED : null),
    getPayload: (state, { analyticsPayload: { closedFrom } }) => ({
      'Closed from': closedFrom,
    }),
  },

  [COMMON_SET_SELECTED_PROJECT_ID]: {
    getEvent: (state, { analyticsPayload }) => (analyticsPayload?.runsInModal ? COMMON_NAVIGATE_BETWEEN_MODALS : null),
    getPayload: (state, { analyticsPayload: { data: { projectName, projectId, from, to, projectStatus, projectType } } }) => {
      const mixpanelData = {
        'Project Name': projectName,
        'Project ID': projectId,
        'Navigated from': from,
        'Navigated to': to,
      };
      if (projectStatus) {
        mixpanelData['Project status'] = projectStatus;
      }
      if (projectType) {
        mixpanelData['Project type'] = projectType;
      }
      return mixpanelData;
    },
  },

  [COMMON_SET_SELECTED_PERSON_ID]: {
    getEvent: (state, { analyticsPayload }) => (analyticsPayload?.runsInModal ? COMMON_NAVIGATE_BETWEEN_MODALS : null),
    getPayload: (state, { analyticsPayload: { data: { personName, personId, from, to } } }) => ({
      'Person Name': personName,
      'Person ID': personId,
      'Navigated from': from,
      'Navigated to': to,
    }),
  },

  [COMMON_SET_PRIVATE_MODE]: {
    getEvent: () => COMMON_CONFIDENTIAL_MODE_TOGGLE_USED,
    getPayload: (state, { privateModeEnabled }) => ({
      'Confidential fields displayed': !privateModeEnabled,
    }),
  },

  [COMMON_TRACK_ANALYTICS]: {
    getEvent: (state, { event }) => event,
    getPayload: (state, { analyticsPayload }) => analyticsPayload,
  },
};

export default events;
