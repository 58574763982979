import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Avatar, Tooltip, Button } from '@material-ui/core';
import { ProjectRoleTitle } from '@bridgit/foundation';
import { Can } from '../wrapped-components';
import { ProjectLinkToModalContainer } from '../common';
import { DATE_INPUT_FORMAT, DATE_DISPLAY_FORMAT, DOT, DEFAULT_POU_START, DEFAULT_POU_END } from '../../common/constants';
import emptyAvatar from '../../images/empty_avatar.svg?url';
import { PERM_WRITE, PERM_PERSON } from '../permissions/utils/constants';
import { MULTI_STATE_MODAL_ID } from '../common/redux/constants';
import { setSelectedPersonId, trackAnalytics } from '../common/redux/actions';
import { openModal } from '../modal-manager/redux/actions';
import { PROFILE_DETAILS_MODAL_DISPLAYED } from '../../analytics/dashboard/constants';

export class PeopleGanttPopper extends PureComponent {
  static propTypes = {
    allocation: PropTypes.object,
    onClickAway: PropTypes.func.isRequired,
    person: PropTypes.object.isRequired,
    setSelectedPersonId: PropTypes.func.isRequired,
    trackAnalytics: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    allocation: null,
  }

  handlePopperClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  }

  trackButtonClick = (id, name) => {
    const { trackAnalytics } = this.props;
    const personId = parseInt(id, 10);
    trackAnalytics(PROFILE_DETAILS_MODAL_DISPLAYED, {
      'Person name': name,
      'Person id': personId,
      'Launched from': 'People Gantt - Popper',
    });
  }

  renderPouDescription = () => {
    const { allocation: { details: { description, isPrivate } } } = this.props;

    if (!description) return <div className="pou-description unavailable">Unavailable</div>;
    return (
      <Can
        action={PERM_WRITE}
        subject={PERM_PERSON}
        yes={<div className="pou-description">{description}</div>}
        no={(
          <>
            { isPrivate
              ? <div className="pou-description unavailable">Unavailable</div>
              : <div className="pou-description">{description}</div>}
          </>
        )}
      />
    );
  };

  renderUnavailableDates = () => {
    const { allocation: { details: { startDate, endDate } } } = this.props;
    const start = startDate === DEFAULT_POU_START ? '' : moment(startDate).format(DATE_DISPLAY_FORMAT);
    const end = endDate === DEFAULT_POU_END ? '' : moment(endDate).format(DATE_DISPLAY_FORMAT);
    return <div className="date">{`${start} - ${end}`}</div>;
  };

  showModal = () => {
    const { person, setSelectedPersonId, openModal, onClickAway } = this.props;
    const { id, name } = person;
    setSelectedPersonId(id);
    openModal(MULTI_STATE_MODAL_ID);
    onClickAway();
    this.trackButtonClick(id, name);
  }

  renderAvatar = () => {
    const { person } = this.props;
    return (
      <Button className="avatar-button" onClick={this.showModal}>
        <Avatar className="avatar" color="primary" src={person.photoUrl ? person.photoUrl : emptyAvatar} />
      </Button>
    );
  }

  renderAllocations = () => {
    const { allocation, person, onClickAway } = this.props;

    return (
      <>
        {!allocation.details
          ? <div>Find an unfilled role</div>
          : (
            <>
              <div className="header">
                <Tooltip title="Visit Profile" placement="top">
                  { this.renderAvatar() }
                </Tooltip>
                <div className="person-info">
                  <div className="name">{person.name}</div>
                  <div className="info">
                    <div className="title">{person.title}</div>
                    {allocation.isOverAllocated && <div className="status">{`${DOT} Over allocated`}</div>}
                  </div>
                </div>
              </div>
              <div className="date-container">
                <div className="date">
                  {moment(allocation.startDate, DATE_INPUT_FORMAT).format(DATE_DISPLAY_FORMAT)}
                  {' - '}
                  {moment(allocation.endDate, DATE_INPUT_FORMAT).format(DATE_DISPLAY_FORMAT)}
                </div>
                <div className="percent">{`${allocation.details.allocatedPercent}%`}</div>
              </div>
              <div className="row header">
                <div className="project">Project</div>
                <div className="role">Role</div>
                <div className="allocation-percent">%</div>
              </div>
              <div className="allocations-container">
                {allocation.details.allocations.map(a => (
                  <div key={`${a.roleId}-${a.startDate}`} className="row">
                    <div className="project">
                      <ProjectLinkToModalContainer
                        backgroundColor={a.projectColour}
                        projectName={a.projectName}
                        projectId={a.projectId}
                        launchedFrom="People Gantt"
                        onClick={onClickAway}
                      />
                    </div>
                    <div className="role">
                      <ProjectRoleTitle
                        roleName={a.roleName}
                        roleNote={a.roleNote}
                      />
                    </div>
                    <div className="allocation-percent">{a.allocatedPercent}</div>
                  </div>
                ))}
              </div>
            </>
          )}
      </>
    );
  }

  render() {
    const { allocation } = this.props;

    if (!allocation) return null;

    return (
      <div className="gantt-people-gantt-popper">
        <div
          className="hover-box"
          onClick={this.handlePopperClick}
          role="presentation"
        >
          {
            allocation.isUnavailable ? (
              <div className="unavailability-popper">
                <div className="date-container">
                  {this.renderUnavailableDates()}
                </div>
                {this.renderPouDescription()}
              </div>
            )
              : this.renderAllocations()
          }
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setSelectedPersonId: bindActionCreators(setSelectedPersonId, dispatch),
    openModal: bindActionCreators(openModal, dispatch),
    trackAnalytics: bindActionCreators(trackAnalytics, dispatch),
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(PeopleGanttPopper);
