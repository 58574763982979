import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNTS_GET_ROLE_TEMPLATES_BEGIN,
  ACCOUNTS_GET_ROLE_TEMPLATES_SUCCESS,
  ACCOUNTS_GET_ROLE_TEMPLATES_FAILURE,
  ACCOUNTS_GET_ROLE_TEMPLATES_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';

export function getRoleTemplates(accountId) {
  return {
    type: ACCOUNTS_GET_ROLE_TEMPLATES_BEGIN,
    accountId,
  };
}

export function dismissGetRoleTemplatesError() {
  return {
    type: ACCOUNTS_GET_ROLE_TEMPLATES_DISMISS_ERROR,
  };
}

export function* doGetRoleTemplates(action) {
  const { accountId } = action;
  let res;
  try {
    res = yield call(protectedJsonGet, `accounts/${accountId}/role-templates`);
  } catch (err) {
    yield put({
      type: ACCOUNTS_GET_ROLE_TEMPLATES_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: ACCOUNTS_GET_ROLE_TEMPLATES_SUCCESS,
    data: res,
  });
}

export function* watchGetRoleTemplates() {
  yield takeLatest(ACCOUNTS_GET_ROLE_TEMPLATES_BEGIN, doGetRoleTemplates);
}

export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNTS_GET_ROLE_TEMPLATES_BEGIN:
      return {
        ...state,
        getRoleTemplatesPending: true,
        getRoleTemplatesError: null,
      };

    case ACCOUNTS_GET_ROLE_TEMPLATES_SUCCESS:
      return {
        ...state,
        getRoleTemplatesPending: false,
        getRoleTemplatesError: null,
        roleTemplates: action.data,
      };

    case ACCOUNTS_GET_ROLE_TEMPLATES_FAILURE:
      return {
        ...state,
        getRoleTemplatesPending: false,
        getRoleTemplatesError: action.data.error,
      };

    case ACCOUNTS_GET_ROLE_TEMPLATES_DISMISS_ERROR:
      return {
        ...state,
        getRoleTemplatesError: null,
      };

    default:
      return state;
  }
}
