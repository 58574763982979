import initialState from './initialState';
import { reducer as getPermissionGroupsReducer } from './getPermissionGroups';
import { reducer as createPermissionGroupReducer } from './createPermissionGroup';
import { reducer as updatePermissionGroupReducer } from './updatePermissionGroup';
import { reducer as deletePermissionGroupReducer } from './deletePermissionGroup';

const reducers = [
  getPermissionGroupsReducer,
  createPermissionGroupReducer,
  updatePermissionGroupReducer,
  deletePermissionGroupReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
