import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { Button, Divider } from '@material-ui/core';
import { SplitMenu, ConfirmationModal } from '@bridgit/foundation';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { getRoleTemplates } from '../accounts/redux/getRoleTemplates';
import { RoleTemplate } from './RoleTemplate';
import { EditRoleTemplate } from './EditRoleTemplate';
import { DATE_DISPLAY_FORMAT, DATE_INPUT_FORMAT } from '../../common/constants';
import { deleteRoleTemplate } from '../accounts/redux/deleteRoleTemplate';

const RoleTemplatesContainer = ({
  accountId,
}) => {
  const dispatch = useDispatch();
  const roleTemplates = useSelector(({ accounts: { roleTemplates } }) => roleTemplates);
  const roleTemplatesLoading = useSelector(({ accounts: { getRoleTemplatesPending } }) => getRoleTemplatesPending);
  const deleteRoleTemplateLoading = useSelector(({ accounts: { deleteRoleTemplatePending } }) => deleteRoleTemplatePending);
  const addRoleTemplateLoading = useSelector(({ accounts: { addRoleTemplatePending } }) => addRoleTemplatePending);
  const updateRoleTemplateLoading = useSelector(({ accounts: { updateRoleTemplatePending } }) => updateRoleTemplatePending);
  const roles = useSelector(({ accountSettings: { roles } }) => roles);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRoleTemplate, setSelectedRoleTemplate] = useState(null);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedTemplate, setEditedTemplate] = useState(null);

  const isLoading = useMemo(() => (
    roleTemplatesLoading || deleteRoleTemplateLoading || addRoleTemplateLoading || updateRoleTemplateLoading
  ), [roleTemplatesLoading, deleteRoleTemplateLoading, addRoleTemplateLoading, updateRoleTemplateLoading]);

  useEffect(() => {
    dispatch(getRoleTemplates(accountId));
  }, [dispatch, accountId]);

  useEffect(() => {
    setIsOpen(!!selectedRoleTemplate || isEditing);
  }, [selectedRoleTemplate, isEditing]);

  const onRoleTemplateClick = useCallback((roleTemplate) => {
    if (selectedRoleTemplate?.id === roleTemplate.id) {
      setSelectedRoleTemplate(null);
    } else {
      window.mixpanel.track('Open Role Template', {
        'Role template name': roleTemplate.name,
      });
      setSelectedRoleTemplate(roleTemplate);
      setIsEditing(false);
    }
  }, [selectedRoleTemplate]);

  const onBackClick = useCallback((isReset = true) => {
    setIsOpen(false);
    setIsEditing(false);
    if (isReset) setSelectedRoleTemplate(null);
    setEditedTemplate(null);
  }, []);

  const onDeleteClick = useCallback(() => setIsConfirmingDelete(true), []);

  const onEditClick = useCallback(() => {
    setIsEditing(true);

    const formattedTemplateRoles = selectedRoleTemplate.data.map(({ roleName, percent, quantity }) => (
      {
        quantity,
        role: {
          name: roleName,
        },
        allocationPercentage: percent,
      }
    ));

    setEditedTemplate({
      id: selectedRoleTemplate.id,
      name: selectedRoleTemplate.name,
      roles: formattedTemplateRoles,
    });
  }, [selectedRoleTemplate]);

  const onAdd = useCallback(() => {
    setIsOpen(true);
    setIsEditing(true);
    setSelectedRoleTemplate(null);
    setEditedTemplate(null);
  }, []);

  const renderItems = useCallback((roleTemplate) => {
    const { name, id, data, createdOn } = roleTemplate;
    const numRoles = data.reduce((collector, { quantity }) => collector + quantity, 0);
    const formattedDate = moment(createdOn, DATE_INPUT_FORMAT).format(DATE_DISPLAY_FORMAT);
    return (
      <div key={id}>
        <Button
          className={classNames('role-template-row', { 'active-row': roleTemplate.id === selectedRoleTemplate?.id })}
          onClick={() => onRoleTemplateClick(roleTemplate)}
        >
          <div className="description-wrapper">
            <p className="template-name">{name}</p>
            <p className="date">{`Created ${formattedDate}`}</p>
          </div>
          {!isOpen && (
            <div className="count-wrapper">
              <p>{pluralize('role', numRoles, true)}</p>
            </div>
          )}
        </Button>
        <Divider />
      </div>
    );
  }, [isOpen, onRoleTemplateClick, selectedRoleTemplate]);

  const headerRightNode = useMemo(() => {
    const numTemplates = roleTemplates?.length ?? 0;
    if (numTemplates === 0 || isOpen || isLoading) {
      return null;
    }
    return (
      <p className="num-templates">{pluralize('Template', numTemplates, true)}</p>
    );
  }, [roleTemplates, isOpen, isLoading]);

  const emptyState = useMemo(() => {
    if (isOpen) return null;
    return (
      <div className="empty-state">
        <p className="subtitle">Click the blue + button to add your first Role Template</p>
      </div>
    );
  }, [isOpen]);

  const rightPanel = useMemo(() => {
    if (isOpen && isEditing) {
      return (
        <EditRoleTemplate
          accountId={accountId}
          onBackClick={onBackClick}
          roles={roles}
          editedTemplate={editedTemplate}
          roleTemplates={roleTemplates}
        />
      );
    }

    if (!isOpen || !selectedRoleTemplate) {
      return null;
    }

    return (
      <RoleTemplate
        roleTemplate={selectedRoleTemplate}
        onBackClick={onBackClick}
        onDeleteClick={onDeleteClick}
        onEditClick={onEditClick}
      />
    );
  }, [isOpen, isEditing, selectedRoleTemplate, onBackClick, onDeleteClick, onEditClick, roles, accountId, editedTemplate, roleTemplates]);

  const deleteTemplate = useCallback(() => {
    const { id, name } = selectedRoleTemplate;
    setSelectedRoleTemplate(null);
    setIsConfirmingDelete(false);
    const analyticsPayload = {
      templateId: id,
      templateName: name,
    };
    dispatch(deleteRoleTemplate(accountId, id, analyticsPayload));
  }, [dispatch, accountId, selectedRoleTemplate]);

  const onCancelDelete = useCallback(() => setIsConfirmingDelete(false), []);

  const confirmationModal = useMemo(() => (
    <ConfirmationModal
      open={isConfirmingDelete}
      headline="Delete Template"
      confirmText="Delete Template"
      cancelText="Cancel"
      onConfirm={deleteTemplate}
      onCancel={onCancelDelete}
      className="delete-modal"
    >
      <p className="delete-text">Are you sure you want to delete this role template?</p>
    </ConfirmationModal>
  ), [deleteTemplate, isConfirmingDelete, onCancelDelete]);

  return (
    <div className="account-settings-role-templates-container">
      <SplitMenu
        title="Role Templates"
        isLoading={isLoading}
        items={roleTemplates}
        renderItems={renderItems}
        emptyNode={emptyState}
        onAdd={onAdd}
        headerRightNode={headerRightNode}
        isMenuActive={isOpen}
        activeNode={rightPanel}
        listActiveWidth="30%"
        className="role-template-split-menu"
        addTooltipTitle="Add a Role Template"
      />
      {confirmationModal}
    </div>
  );
};

RoleTemplatesContainer.propTypes = {
  accountId: PropTypes.number.isRequired,
};

export default RoleTemplatesContainer;
