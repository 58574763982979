import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Avatar } from '@material-ui/core';
import { ProjectRoleTitle } from '@bridgit/foundation';
import { parseRoles } from './utils/reportUtils';
import { DOT } from '../../common/constants';

function ProjectAllocations({
  allocations,
  people,
  project,
}) {
  const roleAllocations = allocations ? allocations.roles : [];
  const parsedRoles = parseRoles(project.roles, roleAllocations, people);

  const activeAllocations = parsedRoles ? parsedRoles.filter(allocation => allocation.state === 'Current') : [];
  const upcomingAllocations = parsedRoles ? parsedRoles.filter(allocation => allocation.state === 'Upcoming') : [];
  const pastAllocations = parsedRoles ? parsedRoles.filter(allocation => allocation.state === 'Past') : [];

  const renderAllocations = (allocations) => {
    // sort by allocation start date then person name
    const sortedAllocations = [...allocations].sort((a, b) => {
      if (!a.startDate.isSame(b.startDate, 'day')) {
        return moment(a.startDate) - moment(b.startDate);
      }

      const aName = a.personName.toLowerCase();
      const bName = b.personName.toLowerCase();
      if (aName !== bName) {
        return aName < bName ? -1 : 1;
      }
      return 0;
    });

    return sortedAllocations.map(allocation => (
      <div key={`${allocation.id}-${allocation.startDate}`} className="allocation">
        <div className="project">
          <div className="person-photo">
            {allocation.isUnfilled
              ? <span className="unfilled-dot">{DOT}</span>
              : <Avatar className="avatar" src={allocation.photoUrl} />}
          </div>
          <div>
            <div className={classNames('person-name', { unfilled: allocation.isUnfilled })}>
              {allocation.personName}
              {allocation.hasConflict && <span className="conflict-dot">{DOT}</span>}
            </div>
            <ProjectRoleTitle
              className="report-role-title"
              roleName={allocation.roleName}
              roleNote={allocation.roleNote}
            />
          </div>
        </div>
        <div className="role-info">
          <div className="role-dates">{`${allocation.startDateFormatted} - ${allocation.endDateFormatted}`}</div>
          <div>{allocation.allocatedPercent}</div>
        </div>
      </div>
    ));
  };

  const renderIssues = () => {
    if (project.hasIssues) {
      return (
        <div className="conflict">
          <svg height="10" width="15">
            <circle cx="5" cy="5" r="5" fill="#F39729" />
          </svg>
          Over allocation issues
        </div>
      );
    }

    return <div className="no-conflict">No allocation issues</div>;
  };

  const renderAllocationSection = (type, filteredAllocations) => (
    <div className="allocations-wrap">
      <div className="allocation-type">{`${type} (${filteredAllocations.length})`}</div>
      {renderAllocations(filteredAllocations)}
    </div>
  );

  return (
    <div className="reports-project-allocations">
      <div className="title-wrap">
        <div className="allocation-title">Project Allocations</div>
        {renderIssues()}
      </div>
      {parsedRoles.length === 0 && <div className="empty">No allocations</div>}

      {activeAllocations.length > 0 && renderAllocationSection('Active', activeAllocations)}
      {upcomingAllocations.length > 0 && renderAllocationSection('Upcoming', upcomingAllocations)}
      {pastAllocations.length > 0 && renderAllocationSection('Completed', pastAllocations)}
    </div>
  );
}

ProjectAllocations.propTypes = {
  project: PropTypes.object.isRequired,
  allocations: PropTypes.object,
  people: PropTypes.array.isRequired,
};

ProjectAllocations.defaultProps = {
  allocations: null,
};


/* istanbul ignore next */
function mapStateToProps({ people }) {
  return {
    people: people.entities,
  };
}

export default connect(
  mapStateToProps,
)(ProjectAllocations);
