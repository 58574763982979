import {
  FIELDS,
  FIELDS_NAME,
  FIELDS_VALUE_CURRENCY,
  BLANK_FILTER,
} from './constants';

const blankFilter = column => (
  {
    bool: {
      must_not: [
        {
          nested: {
            path: FIELDS,
            query: { match: { [FIELDS_NAME]: column } },
          },
        },
      ],
    },
  }
);

const queryFilter = (activeFilter, column) => {
  const filterQuery = {};
  const { selected, verb } = activeFilter;

  if (verb === 'above') {
    filterQuery.range = {
      [FIELDS_VALUE_CURRENCY]: { gt: selected[0].value },
    };
  } else if (verb === 'below') {
    filterQuery.range = {
      [FIELDS_VALUE_CURRENCY]: { lt: selected[0].value },
    };
  } else if (verb === 'between') {
    filterQuery.range = {
      [FIELDS_VALUE_CURRENCY]: { gte: Math.min(selected[0].value, selected[0].secondaryValue), lte: Math.max(selected[0].value, selected[0].secondaryValue) },
    };
  } else {
    filterQuery.match = {
      [FIELDS_VALUE_CURRENCY]: selected[0].value,
    };
  }

  return {
    nested: {
      path: FIELDS,
      query: {
        bool: {
          must: [
            { match: { [FIELDS_NAME]: column } },
            { ...filterQuery },
          ],
        },
      },
    },
  };
};

const generateCurrencyFilter = (activeFilter, column) => {
  const { verb } = activeFilter;

  if (verb === BLANK_FILTER) {
    return blankFilter(column);
  }

  return queryFilter(activeFilter, column);
};

export default generateCurrencyFilter;
