import { naturalSort } from 'src/utils/sortUtils';

export const getSortedAccounts = (accounts, sortField) => naturalSort(accounts, sortField)
  .map(({ name, login, group, createdOn, id }) => (
    [
      { fieldName: 'id', value: id, type: 'hidden', id },
      { fieldName: 'name', value: name, type: 'string' },
      { fieldName: 'login', value: login, type: 'string' },
      { fieldName: 'group', value: group, type: 'string' },
      { fieldName: 'createdOn', value: createdOn, type: 'date' },
    ]
  ));
