import PropTypes from 'prop-types';

/*
  To be used as the 'loading' component for chunks loaded via react-loadable.

  In the event that a chunk cannot be loaded, one attempt will be made to resolve
  the problem by refreshing the page. If this doesn't work, the 'autoreload' argument
  will prevent an infinite loop.

  Note that the value of the 'autoreload' argument isn't meaningful, the arg just has
  to be present.
*/
const ChunkErrorReloader = ({ error }) => {
  if (error && !window.location.search.includes('autoreload')) {
    // Appending a new argument will automatically cause the page to be reloaded
    window.location.search += 'autoreload=false';
  }
  return null;
};

ChunkErrorReloader.propTypes = {
  error: PropTypes.instanceOf(Error),
};

ChunkErrorReloader.defaultProps = {
  error: null,
};

export default ChunkErrorReloader;
