import { call, put, takeLatest } from 'redux-saga/effects';
import { ACTIVE, COMPLETE } from 'src/features/projects/constants';
import {
  PEOPLE_GET_PERSON_PAST_ALLOCATIONS_BEGIN,
  PEOPLE_GET_PERSON_PAST_ALLOCATIONS_SUCCESS,
  PEOPLE_GET_PERSON_PAST_ALLOCATIONS_FAILURE,
  PEOPLE_GET_PERSON_PAST_ALLOCATIONS_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';

export function getPersonPastAllocations(accountId, personId, offset) {
  return {
    type: PEOPLE_GET_PERSON_PAST_ALLOCATIONS_BEGIN,
    accountId,
    personId,
    offset,
  };
}

export function dismissGetPersonPastAllocationsError() {
  return {
    type: PEOPLE_GET_PERSON_PAST_ALLOCATIONS_DISMISS_ERROR,
  };
}

export function* doGetPersonPastAllocations(action) {
  const { accountId, personId, offset } = action;
  let res;
  try {
    res = yield call(protectedJsonGet, `accounts/${accountId}/persons/${personId}/past-allocations`, { limit: 10, offset, projectState: `${ACTIVE},${COMPLETE}` }, true);
  } catch (err) {
    yield put({
      type: PEOPLE_GET_PERSON_PAST_ALLOCATIONS_FAILURE,
      data: { error: err },
    });
    return;
  }
  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: PEOPLE_GET_PERSON_PAST_ALLOCATIONS_SUCCESS,
    data: res.data,
    headers: res.headers,
    personId,
    offset,
  });
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchGetPersonPastAllocations() {
  yield takeLatest(PEOPLE_GET_PERSON_PAST_ALLOCATIONS_BEGIN, doGetPersonPastAllocations);
}

export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_GET_PERSON_PAST_ALLOCATIONS_BEGIN:
      return {
        ...state,
        getPersonPastAllocationsPending: true,
        getPersonPastAllocationsError: null,
      };

    case PEOPLE_GET_PERSON_PAST_ALLOCATIONS_SUCCESS:
      return {
        ...state,
        pastAllocations: {
          ...state.pastAllocations,
          [action.personId]: action.offset > 0 ? state.pastAllocations[action.personId].concat(action.data) : action.data,
        },
        morePastAllocations: action.headers['query-has-more'] === 'True',
        getPersonPastAllocationsPending: false,
        getPersonPastAllocationsError: null,
      };

    case PEOPLE_GET_PERSON_PAST_ALLOCATIONS_FAILURE:
      return {
        ...state,
        getPersonPastAllocationsPending: false,
        getPersonPastAllocationsError: action.data.error,
      };

    case PEOPLE_GET_PERSON_PAST_ALLOCATIONS_DISMISS_ERROR:
      return {
        ...state,
        getPersonPastAllocationsError: null,
      };

    default:
      return state;
  }
}
