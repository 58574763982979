const OPEN_SCENARIO_PLANNING_PANEL = 'Open Scenario Planning Panel';
const CLOSE_SCENARIO_PLANNING_PANEL = 'Close Scenario Planning Panel';
const NAVIGATE_TO_FORECASTING = 'Navigate to Forecasting';
const DASHBOARD = 'Dashboard';
const UNFILLED_ROLES_FILTER_CHANGED = 'Unfilled Roles - Time Filter Changes';
const TIME_FILTER_SELECTED = 'Time Filter Selected';
const PROFILE_DETAILS_MODAL_DISPLAYED = 'Profile Details Modal Displayed';

const IDLE_TIME_TITLE_CLICK = 'Insights - Idle Time Title Clicked';
const IDLE_TIME_TITLE_GRAPH_CLICK = 'Insights - Idle Time Title Graph Clicked';
const IDLE_TIME_TITLE_CTA_CLICK = 'Insights - Idle Time Title CTA Clicked';

const IDLE_TIME_METRIC_CLICK = 'Insights - Idle Time Metric Clicked';
const IDLE_TIME_GRAPH_CLICK = 'Insights - Idle Time Graph Clicked';
const IDLE_TIME_CTA_CLICK = 'Insights - Idle Time CTA Clicked';

export {
  OPEN_SCENARIO_PLANNING_PANEL,
  CLOSE_SCENARIO_PLANNING_PANEL,
  NAVIGATE_TO_FORECASTING,
  DASHBOARD,
  UNFILLED_ROLES_FILTER_CHANGED,
  TIME_FILTER_SELECTED,
  PROFILE_DETAILS_MODAL_DISPLAYED,
  IDLE_TIME_TITLE_CLICK,
  IDLE_TIME_TITLE_GRAPH_CLICK,
  IDLE_TIME_TITLE_CTA_CLICK,
  IDLE_TIME_METRIC_CLICK,
  IDLE_TIME_GRAPH_CLICK,
  IDLE_TIME_CTA_CLICK,
};
