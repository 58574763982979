import React from 'react';

export const PERM_ACCOUNT = 'Account';
export const PERM_PERSON = 'Person';
export const PERM_PROJECT = 'Project';
export const PERM_ROLE = 'Role';
export const PERM_ALLOCATION = 'Allocation';
export const PERM_PRIVATE = 'Private';
export const PERM_HOURLY_PERSON = 'HourlyPerson';
export const PERM_REQUEST = 'HourlyRequest';
export const PERM_ASSIGNMENT = 'HourlyAssignment';
export const PERM_PROJECT_USER_ASSOCIATION = 'ProjectUserAssociation';
export const PERM_SETTINGS = 'Settings';
export const PERM_FINANCIALS = 'Finance';

export const PERM_READ = 'Read';
export const PERM_WRITE = 'Write';

// Permission Groups
export const SELF_PERFORM_REQUESTER = 'Self-Perform Requester';
export const SELF_PERFORM_SCHEDULER = 'Self-Perform Scheduler';

export const MAX_GROUP_NAME_LENGTH = 50;

export const REQUEST_STATUS_TOGGLE_PERMISSIONS = {
  action: PERM_WRITE,
  subject: PERM_ASSIGNMENT,
};

export const PERMISSION_TYPES = [
  {
    label: 'View All',
    value: 'ReadOnly',
    description: () => 'View all people and projects in the account and the account dashboard.',
    disabled: true,
  },
  {
    label: 'View Private Fields',
    value: 'ManagePrivateValues',
    description: () => 'View private fields on people and projects.',
  },
  {
    label: 'View Financial Fields',
    value: 'ManageFinancials',
    description: () => 'View financial fields on people.',
  },
  {
    label: 'Manage People',
    value: 'ManagePeople',
    description: () => 'Add, edit, and deactivate people in the account.',
  },
  {
    label: 'Manage Projects',
    value: 'ManageProjects',
    description: () => (
      <span>
        Add, edit, and delete projects in the account. This includes project details and dates.&nbsp;
        <i>
          Note that editing project dates can impact roles and allocations on the project.
        </i>
      </span>
    ),
  },
  {
    label: 'Manage Roles',
    value: 'ManageRoles',
    description: () => (
      <span>
        Add, edit, and remove roles on a project.&nbsp;
        <i>
          Note that editing and removing roles can impact allocations on the project.
        </i>
      </span>
    ),
  },
  {
    label: 'Manage Allocations',
    value: 'ManageAllocations',
    description: () => 'Add, edit, and remove allocations on a project.',
  },
];

export const PERMISSION_DEFAULT_VALUES = {
  name: '',
  permissions: {
    ReadOnly: true,
    ManagePrivateValues: false,
    ManagePeople: false,
    ManageProjects: false,
    ManageRoles: false,
    ManageAllocations: false,
    ManageFinancials: false,
  },
};
