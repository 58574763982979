import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Capsule } from 'src/features/common';
import { isValidSegmentArray } from 'src/utils/dateSegmentUtils';
import { customPropCheck } from 'src/utils/propCheckUtils';
import tinycolor from 'tinycolor2';
import { getShiftedColor } from './utils/dateEditorUtils';
import { EditorCapsuleContent } from '.';

function EditorCapsule({
  timelineDates,
  percentWidth,
  percentLeftMargin,
  primaryColorCode,
  borderColorCode,
  superText,
  leftText,
  tooltip,
}) {
  // Set a left margin threshold, beyond which the supertext will be displayed via tooltip
  const showSuperTextAsTooltip = parseInt(percentLeftMargin, 10) >= 85;

  const alternateBarColor =
    useMemo(() => (getShiftedColor(primaryColorCode)), [primaryColorCode]);

  const colorIsLight =
    useMemo(() => tinycolor(primaryColorCode).isLight(),
      [primaryColorCode]);

  /*
  If color code is given, use that for everything. Otherwise adjust based on the light/dark
  balance of the primary color.
*/
  let borderColor = borderColorCode;
  if (!borderColor) borderColor = colorIsLight ? alternateBarColor : null;

  const widthStyle = { marginLeft: `${percentLeftMargin}%` };
  const capsuleStyle = {
    width: `${percentWidth}%`,
    ...widthStyle,
  };

  let tooltipText = tooltip || '';

  if (showSuperTextAsTooltip && superText) {
    tooltipText = tooltip ? `${tooltip} - ${superText}` : superText;
  }

  const adjustedSuperText = showSuperTextAsTooltip ? '' : superText;

  const superTextWrap = adjustedSuperText?.length > 0 ? (
    <span className="super-text" style={widthStyle}>{adjustedSuperText}</span>
  ) : null;

  return (
    <div className="edit-dates-editor-capsule">
      {superTextWrap}

      <Capsule
        className="capsule"
        style={capsuleStyle}
        borderColorCode={borderColor}
        leftText={leftText}
        tooltip={tooltipText}
      >
        <EditorCapsuleContent
          timelineDates={timelineDates}
          leftText={leftText}
          primaryColorCode={primaryColorCode}
          alternateColorCode={alternateBarColor}
        />
      </Capsule>
    </div>
  );
}

function mapStateToProps({ editDates }) {
  const { dateUnderEdit, editAction } = editDates;
  return {
    dateUnderEdit,
    editAction,
  };
}

EditorCapsule.propTypes = {
  timelineDates: customPropCheck(isValidSegmentArray).isRequired,
  percentWidth: PropTypes.number,
  percentLeftMargin: PropTypes.number,
  primaryColorCode: PropTypes.string,
  borderColorCode: PropTypes.string,
  superText: PropTypes.string,
  leftText: PropTypes.string,
  tooltip: PropTypes.string,
};

EditorCapsule.defaultProps = {
  percentWidth: null,
  percentLeftMargin: null,
  primaryColorCode: '',
  borderColorCode: '',
  superText: '',
  leftText: '',
  tooltip: '',
};

export default connect(
  mapStateToProps,
)(EditorCapsule);
