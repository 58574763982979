const RP_AUTH_COOKIE_ID = 'RP_AUTH_COOKIE_ID';

const DEFAULT_COUNTRY_CODE = 'US';

const UNKNOWN_MENTION = 'Unknown';

export {
  RP_AUTH_COOKIE_ID,
  DEFAULT_COUNTRY_CODE,
  UNKNOWN_MENTION,
};
