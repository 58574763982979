import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '@material-ui/core';
import { validateAllocationUpdate } from 'src/features/people/utils/unavailabilityUtils';
import { trimSegmentsToRange } from 'src/utils/dateSegmentUtils';
import { DATE_INPUT_FORMAT } from 'src/common/constants';
import { ConfirmOverlay } from 'src/features/common';
import { deleteProjectRole } from 'src/features/projects/redux/actions';
import { roleIsShrinking, getPersonImpacted } from './utils/allocationUtils';

export const ConfirmUpdateRole = ({
  accountId,
  cancelConfirm,
  onSave,
  editMode,
  project,
  startDate,
  endDate,
  role,
  people,
  pending,
  projectAllocations,
  deleteProjectRole,
  displayVertical,
}) => {
  const [overlapWarningMessages, setOverlapWarningMessages] = useState(null);
  const [expand, setExpand] = useState(false);

  const editDateMode = editMode === 'startDate' || editMode === 'endDate';

  const peopleAllocations = projectAllocations[project.id]?.find(a => a.roleId === role.id)?.allocations || [];
  const personImpacted = getPersonImpacted(peopleAllocations, people?.entities, role, moment(startDate));

  const handleSave = (expandAllocations = false, skipValidation = false) => () => {
    setExpand(expandAllocations);

    if (expandAllocations && personImpacted && !skipValidation) {
      const pous = people.unavailabilities.find(person => person.personId === personImpacted.id);
      const data = editMode === 'startDate'
        ? {
          startDate: moment(startDate).format(DATE_INPUT_FORMAT),
          endDate: moment(role.startDate).format(DATE_INPUT_FORMAT),
        }
        : {
          startDate: moment(role.endDate).format(DATE_INPUT_FORMAT),
          endDate: moment(endDate).format(DATE_INPUT_FORMAT),
        };

      const overlap = validateAllocationUpdate(personImpacted && personImpacted.employmentDates, pous && pous.unavailabilities, data);

      if (overlap && overlap.length) {
        setOverlapWarningMessages(overlap);
      } else {
        onSave(expandAllocations);
      }
    } else {
      onSave(expandAllocations);
    }
  };

  const logRoleDeleteDecision = (didDeleteRole) => {
    const startDateUpdated = !moment(startDate).isSame(role.startDate);
    const endDateUpdated = !moment(endDate).isSame(role.endDate);

    // Mixpanel - Track role edit leaving only 0% requirements resulting in deletion
    window.mixpanel.track('Role Update caused Role Deletion', {
      'Start date updated': startDateUpdated,
      'End date updated': endDateUpdated,
      'Deletion cancelled': !didDeleteRole,
      'Deletion confirmed': didDeleteRole,
    });
  };

  const onCancelDeleteRole = () => {
    logRoleDeleteDecision(false);
    cancelConfirm();
  };

  const onDeleteRole = () => {
    const startDateUpdated = !moment(startDate).isSame(role.startDate);
    const endDateUpdated = !moment(endDate).isSame(role.endDate);
    const analyticsPayload = {
      startDateUpdated,
      endDateUpdated,
      isRoleDeleted: true,
    };

    deleteProjectRole(accountId, project.id, role.id, analyticsPayload);
  };

  const roleShrinking = roleIsShrinking(startDate, endDate, role);

  const trimmedSegments = trimSegmentsToRange(role.requirements, startDate, endDate);
  const allRequirementsZero = trimmedSegments.every(req => req.allocatedPercent === 0);

  /*
    If edit date mode and roleIsShrinking and the role will only have zero-percent left,
    offer the option to just delete the role
  */
  if (editDateMode && roleShrinking && allRequirementsZero) {
    const confirmMessage = 'A role must have at least one day with an allocation % greater than 0%.';
    const confirmSubMessage = 'Making this change will delete this role.';

    return (
      <ConfirmOverlay
        className="allocations-confirm-update-role"
        onAccept={onDeleteRole}
        onCancel={onCancelDeleteRole}
        message={confirmMessage}
        subtext={confirmSubMessage}
        pending={pending}
        displayVertical={displayVertical}
      />
    );
  }

  if (editDateMode && !roleShrinking && personImpacted) {
    const confirmMessage = overlapWarningMessages
      ? `${personImpacted.name} cannot be placed on the role for days they are unavailable`
      : `This date change will extend the allocation dates for ${personImpacted.name}.`;
    const confirmSubMessage = overlapWarningMessages
      ? overlapWarningMessages.join(', ')
      : 'Do you want to extend their allocation, or leave the additional time unfilled?';

    const actionButtons = !overlapWarningMessages
      ? (
        <>
          <Button
            className="button__default allocation"
            variant="contained"
            size="medium"
            onClick={handleSave(true)}
          >
            Extend Allocation
          </Button>

          <Button
            className="button__default allocation"
            variant="contained"
            size="medium"
            onClick={handleSave(false)}
          >
            Leave Unfilled
          </Button>
        </>
      ) : undefined;

    return (
      <ConfirmOverlay
        className="allocations-confirm-update-role"
        onAccept={handleSave(expand, true)}
        onCancel={cancelConfirm}
        message={confirmMessage}
        subtext={confirmSubMessage}
        pending={pending}
        actionButtons={actionButtons}
        displayVertical={displayVertical}
      />
    );
  }

  return null;
};

ConfirmUpdateRole.propTypes = {
  accountId: PropTypes.number.isRequired,
  cancelConfirm: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  editMode: PropTypes.string,
  project: PropTypes.object.isRequired,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  role: PropTypes.object.isRequired,
  people: PropTypes.object.isRequired,
  pending: PropTypes.bool.isRequired,
  projectAllocations: PropTypes.object.isRequired,
  deleteProjectRole: PropTypes.func.isRequired,
  displayVertical: PropTypes.bool,
};

ConfirmUpdateRole.defaultProps = {
  editMode: '',
  startDate: '',
  endDate: '',
  displayVertical: false,
};

function mapStateToProps({ projects, people, common }) {
  const { accountId } = common;
  const {
    updateProjectRolePending,
    getProjectAllocationsPending,
    projectAllocations,
    deleteProjectRolePending,
  } = projects;

  const pending =
    updateProjectRolePending ||
    getProjectAllocationsPending ||
    deleteProjectRolePending;

  return {
    accountId,
    people,
    pending,
    projectAllocations,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    deleteProjectRole: bindActionCreators(deleteProjectRole, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfirmUpdateRole);
