import {
  TABLE_SET_VISIBLE_COLUMNS,
} from './constants';

export function setVisibleColumns(columns, selectedColumnNames) {
  return {
    type: TABLE_SET_VISIBLE_COLUMNS,
    columns,
    selectedColumnNames,
  };
}

export function getNewVisibleColumns(columns, selectedColumnNames, alwaysVisibleColumns, currentVisibleColumns) {
  const filteredColumns = columns.filter(col => selectedColumnNames.includes(col.name) || alwaysVisibleColumns.includes(col.name));
  const newVisibleColumns = currentVisibleColumns.filter(col => selectedColumnNames.includes(col.name) || alwaysVisibleColumns.includes(col.name));

  filteredColumns.forEach((filteredColumn) => {
    if (!currentVisibleColumns.find(c => c.name === filteredColumn.name)) {
      newVisibleColumns.push(filteredColumn);
    }
  });

  return newVisibleColumns;
}

export function reducer(state, action) {
  switch (action.type) {
    case TABLE_SET_VISIBLE_COLUMNS: {
      const { columns, selectedColumnNames } = action;
      const visibleColumns = getNewVisibleColumns(columns, selectedColumnNames, state.alwaysVisible, state.visibleColumns);
      return {
        ...state,
        visibleColumns,
        visibleColumnNames: selectedColumnNames,
      };
    }
    default:
      return state;
  }
}
