import { generateProjectFilters } from '../utils/generateFilters';
import {
  FILTERS_SET_PROJECT_FILTER_DATA,
} from './constants';

export function setProjectFilterData(projectDescriptor = [], projectFields, accountModules, phases = [], roles = []) {
  return {
    type: FILTERS_SET_PROJECT_FILTER_DATA,
    projectFilterData: generateProjectFilters(projectDescriptor, projectFields, accountModules, phases, roles),
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FILTERS_SET_PROJECT_FILTER_DATA:
      return {
        ...state,
        projectFilterData: action.projectFilterData,
      };

    default:
      return state;
  }
}
