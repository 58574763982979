import {
  COMMON_SET_SELECTED_PROJECT_ID,
  COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PROJECT,
} from './constants';

export function setSelectedProjectId(
  selectedProjectId,
  activeView = COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PROJECT,
  roleDetails = null,
  showProjectOnCancel = false,
  showBackButton = false,
  modalOrigin = null,
  analyticsPayload = null,
) {
  return {
    type: COMMON_SET_SELECTED_PROJECT_ID,
    selectedProjectId,
    activeView,
    roleDetails,
    showProjectOnCancel,
    showBackButton,
    modalOrigin,
    analyticsPayload,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_SET_SELECTED_PROJECT_ID: {
      const { selectedProjectId, activeView, roleDetails, showProjectOnCancel, showBackButton, modalOrigin } = action;
      const { multiStateModal } = state;
      const { roleDetails: oldRoleDetails } = multiStateModal;
      const newRoleDetails = roleDetails || oldRoleDetails;
      return {
        ...state,
        multiStateModal: {
          ...multiStateModal,
          selectedProjectId,
          roleDetails: newRoleDetails,
          activeView,
          showProjectOnCancel,
          showBackButton,
          modalOrigin,
        },
      };
    }

    default:
      return state;
  }
}
