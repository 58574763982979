import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import pluralize from 'pluralize';
import { Done } from '@material-ui/icons';

export default function PermissionGroupDisplay({
  groups,
  isSystem,
  hidePerms,
  onClick,
  activeGroup,
  permissionTypes,
}) {
  if (!groups?.length) {
    return hidePerms ? null : <div>No custom permission groups have been added to your account. Click the + button below to add a group.</div>;
  }

  const onGroupClick = group => () => {
    onClick(group);
  };

  const renderGroup = group => (
    <>
      <div className="group-name">{group.name}</div>
      <div className="assigned-info">
        {group.members.length
          ? `Assigned to ${group.members.length} ${pluralize('users', group.members.length)}`
          : 'Not Assigned'}
      </div>
    </>
  );

  const renderGroups = () => groups.map((group) => {
    if (isSystem) {
      return (
        <div className="group-info" key={group.id}>
          {renderGroup(group)}
        </div>
      );
    }

    return (
      <button
        type="button"
        className={classNames('group-info', { active: group.name === activeGroup?.name })}
        key={group.id}
        onClick={onGroupClick(group)}
      >
        {renderGroup(group)}
      </button>
    );
  });

  return (
    <div
      className={classNames('permissions-permission-group-display', {
        system: isSystem,
        'hide-perms': hidePerms,
      })}
    >
      <div className="group-names">
        <div className="empty-placeholder" />
        {renderGroups()}
      </div>
      {!hidePerms && (
        <div className="permission-values">
          <div className="value-names">
            {isSystem && <div>Manage Settings</div>}
            {permissionTypes.map(type => <div key={type.value}>{type.label}</div>)}
          </div>
          <div className="permission-value-display">
            {groups.map((group) => {
              let types = [...permissionTypes];
              if (isSystem) {
                types = [
                  {
                    label: 'Manage Settings',
                    value: 'Admin',
                  },
                  ...permissionTypes,
                ];
              }
              return (
                <div className="value-display-row" key={group.id}>
                  {types.map((type) => {
                    const hasPerm = group.permissions.includes(type.value) || group.permissions.includes('Admin');
                    return <div key={type.value}>{hasPerm ? <Done /> : ''}</div>;
                  })}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

PermissionGroupDisplay.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.object).isRequired,
  isSystem: PropTypes.bool,
  hidePerms: PropTypes.bool,
  onClick: PropTypes.func,
  activeGroup: PropTypes.object,
  permissionTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

PermissionGroupDisplay.defaultProps = {
  isSystem: false,
  hidePerms: false,
  onClick: () => {},
  activeGroup: null,
};
