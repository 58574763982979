import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PEOPLE_GET_PERSON_FIELD_VALUES_BEGIN,
  PEOPLE_GET_PERSON_FIELD_VALUES_SUCCESS,
  PEOPLE_GET_PERSON_FIELD_VALUES_FAILURE,
  PEOPLE_GET_PERSON_FIELD_VALUES_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';

export function getPersonFieldValues(accountId, state = 'active') {
  // If need to pass args to saga, pass it with the begin action.
  return {
    type: PEOPLE_GET_PERSON_FIELD_VALUES_BEGIN,
    accountId,
    state,
  };
}

export function dismissGetPersonFieldValuesError() {
  return {
    type: PEOPLE_GET_PERSON_FIELD_VALUES_DISMISS_ERROR,
  };
}

export function* doGetPersonFieldValues(action) {
  const { accountId, state } = action;
  let res;
  try {
    res = yield call(protectedJsonGet, `accounts/${accountId}/person-field-values`, { state });
  } catch (err) {
    yield put({
      type: PEOPLE_GET_PERSON_FIELD_VALUES_FAILURE,
      data: { error: err },
    });
    return;
  }
  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: PEOPLE_GET_PERSON_FIELD_VALUES_SUCCESS,
    data: res,
  });
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchGetPersonFieldValues() {
  yield takeLatest(PEOPLE_GET_PERSON_FIELD_VALUES_BEGIN, doGetPersonFieldValues);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_GET_PERSON_FIELD_VALUES_BEGIN:
      return {
        ...state,
        getPersonFieldValuesPending: true,
        getPersonFieldValuesError: null,
      };

    case PEOPLE_GET_PERSON_FIELD_VALUES_SUCCESS:
      return {
        ...state,
        personFieldValues: action.data || [],
        getPersonFieldValuesPending: false,
        getPersonFieldValuesError: null,
      };

    case PEOPLE_GET_PERSON_FIELD_VALUES_FAILURE:
      return {
        ...state,
        personFieldValues: action.data,
        getPersonFieldValuesPending: false,
        getPersonFieldValuesError: action.data.error,
      };

    case PEOPLE_GET_PERSON_FIELD_VALUES_DISMISS_ERROR:
      return {
        ...state,
        getPersonFieldValuesError: null,
      };

    default:
      return state;
  }
}
