import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import deepEqual from 'react-fast-compare';
import shortid from 'shortid';
import {
  Button,
  ClickAwayListener,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { CustomPopper } from '../wrapped-components';
import { SelectList, SelectListSearch } from '.';
import { setPopperIsOpen } from '../filters/redux/actions';
import {
  SELECT_LIST_SEARCH,
  MULTI_SELECT_FILTER_VERBS,
} from '../../filters/constants';

class MultiFilterPopper extends PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    anchorEl: PropTypes.object,
    title: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    origSelected: PropTypes.array.isRequired,
    filterKey: PropTypes.string.isRequired,
    onApply: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    verbs: PropTypes.array,
    searchPlaceholder: PropTypes.string,
    verbText: PropTypes.string,
    subText: PropTypes.string,
    hasSubOptions: PropTypes.bool,
    setPopperIsOpen: PropTypes.func.isRequired,
    type: PropTypes.string,
    controlOptions: PropTypes.array,
    isCustomSort: PropTypes.bool,
  };

  static defaultProps = {
    anchorEl: null,
    verbs: MULTI_SELECT_FILTER_VERBS,
    searchPlaceholder: '',
    verbText: '',
    subText: '',
    hasSubOptions: false,
    type: null,
    controlOptions: [],
    isCustomSort: false,
  }

  constructor(props) {
    super(props);

    this.defaultSelected = [{ ...props.verbs[0], selected: [], id: shortid.generate() }];
    const selected = props.origSelected.length ? props.origSelected : this.defaultSelected;

    this.state = {
      selected,
      applyDisabled: true,
      expandedList: selected[0].id,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { origSelected } = this.props;

    const stateUpdates = {};

    if (!deepEqual(origSelected, nextProps.origSelected)) {
      stateUpdates.selected = nextProps.origSelected;
    }

    this.setState(stateUpdates);
  }

  onCancel = ({ target: { className } }) => {
    const { selected } = this.state;
    const { onCancel, origSelected, setPopperIsOpen } = this.props;

    // Verb select elements are positioned outside of the popper, we need to catch clicking them and the backdrop here
    if (className && typeof className === 'string' && (className.indexOf('verb-select') !== -1 || className.indexOf('MuiBackdrop') !== -1)) {
      return;
    }

    onCancel();
    setPopperIsOpen(false);
    this.setState({
      selected: origSelected.length ? origSelected : this.defaultSelected,
      applyDisabled: true,
      expandedList: selected[0].id,
    });
  }

  onSelect = (id, items) => {
    const { origSelected } = this.props;
    const { selected } = this.state;
    let currentSelected;
    const newSelected = selected.map((obj) => {
      const newObj = { ...obj };
      if (newObj.id === id) {
        currentSelected = newObj;
        newObj.selected = items;
      }
      return newObj;
    });

    const applyDisabled =
      deepEqual(origSelected, newSelected) ||
      (!origSelected.length && deepEqual(newSelected, this.defaultSelected)) ||
      (!origSelected.length && !currentSelected.selected?.length);

    this.setState({
      selected: newSelected,
      applyDisabled,
    });
  }

  onApply = () => {
    const { onApply } = this.props;
    const { selected } = this.state;
    const appliedSelected = selected.filter(obj => obj.selected.length);

    onApply(appliedSelected);
    this.setState({
      selected: appliedSelected,
      applyDisabled: true,
    });
  }

  onExpand = (id) => {
    const { expandedList } = this.state;
    this.setState({ expandedList: expandedList === id ? '' : id });
  }

  onVerbChange = (id, verb) => {
    const { origSelected, verbs } = this.props;
    const { selected } = this.state;
    let currentSelected;
    const newSelected = selected.map((obj) => {
      const newObj = { ...obj };
      if (newObj.id === id) {
        currentSelected = newObj;
        newObj.verb = verb;
        newObj.inclusive = verbs.find(v => v.verb === verb).inclusive;
      }
      return newObj;
    });

    const applyDisabled =
      deepEqual(origSelected, newSelected) ||
      (!origSelected.length && deepEqual(newSelected, this.defaultSelected)) ||
      // If no items selected for current filter section and only verb changes
      !currentSelected.selected?.length;

    this.setState({
      selected: newSelected,
      applyDisabled,
    });
  }

  addFilter = () => {
    const { verbs } = this.props;
    const { selected } = this.state;
    const expandedList = shortid.generate();
    this.setState({
      selected: [...selected, {
        ...verbs[0],
        selected: [],
        id: expandedList,
      }],
      expandedList,
    });
  }

  deleteFilter = (id) => {
    const { origSelected } = this.props;
    const { selected, expandedList } = this.state;
    const newSelected = selected.filter(obj => obj.id !== id);
    const newExpanded = expandedList === id ? '' : expandedList;

    this.setState({
      selected: newSelected,
      expandedList: newExpanded,
      applyDisabled: deepEqual(origSelected, newSelected) || (!origSelected.length && deepEqual(newSelected, this.defaultSelected)),
    });
  }

  renderSelectList = () => {
    const { filterKey, items, verbs, title, searchPlaceholder, verbText, subText, hasSubOptions, type, controlOptions, isCustomSort } = this.props;
    const { selected, expandedList } = this.state;
    const disableDelete = selected.length < 2;
    const placeholder = `Search ${searchPlaceholder || title}`;

    if (type === SELECT_LIST_SEARCH) {
      return (
        selected.map(obj => (
          <SelectListSearch
            key={obj.id}
            verbs={verbs}
            filterKey={filterKey}
            selectedItems={obj}
            expanded={expandedList}
            onSelected={this.onSelect}
            onDelete={this.deleteFilter}
            onExpanded={this.onExpand}
            onVerbChanged={this.onVerbChange}
            placeholder={placeholder}
            disableDelete={disableDelete}
            verbText={verbText}
            subText={subText}
          />
        ))
      );
    }

    // Else if type === SELECT_LIST
    return (
      selected.map(obj => (
        <SelectList
          key={obj.id}
          items={items}
          verbs={verbs}
          filterKey={filterKey}
          selectedItems={obj}
          expanded={expandedList}
          onSelect={this.onSelect}
          onDelete={this.deleteFilter}
          onExpand={this.onExpand}
          onVerbChange={this.onVerbChange}
          placeholder={placeholder}
          disableDelete={disableDelete}
          verbText={verbText}
          subText={subText}
          hasSubOptions={hasSubOptions}
          controlOptions={controlOptions}
          isCustomSort={isCustomSort}
        />
      ))
    );
  }

  render() {
    const { anchorEl, open, title } = this.props;
    const { applyDisabled, selected } = this.state;

    // If any list has no items selected, do not display "Add another filter"
    const showAddFilter = selected.every(item => item.selected.length);

    return (
      <CustomPopper
        popperOpen={open}
        anchorEl={anchorEl}
        classes="common-multi-filter-popper"
        placement="top-start"
        hideArrow
        preventBubbling
      >
        <ClickAwayListener onClickAway={this.onCancel}>
          <div>
            <div className="content-wrap">
              <div className="header">
                <div className="title">{title}</div>
              </div>

              {this.renderSelectList()}
            </div>
            <div className="footer">
              {showAddFilter && (
                <button type="button" className="multi-filter-add" onClick={this.addFilter}>
                  <div className="add-button"><Add /></div>
                  <div>Add another filter</div>
                </button>
              )}
              <div className="footer-actions">
                <Button
                  disableRipple
                  size="medium"
                  onClick={this.onCancel}
                >
                  Cancel
                </Button>
                <Button
                  disableRipple
                  size="medium"
                  onClick={this.onApply}
                  color="primary"
                  disabled={applyDisabled}
                >
                  Apply
                </Button>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      </CustomPopper>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setPopperIsOpen: bindActionCreators(setPopperIsOpen, dispatch),
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(MultiFilterPopper);
