import {
  Button,
  DialogActions,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { Image } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, Form } from 'react-final-form';
import { AddLogo } from '.';

export class CreateNewAccountForm extends Component {
  static propTypes = {
    accounts: PropTypes.object.isRequired,
    createAccount: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      disableForm: false,
      logoImage: null,
      logoUrl: '',
      logoOpen: false,
    };
  }

  validate = (values) => {
    const errors = {};

    if (!values.name || !values.name.replace(/\s/g, '').length) {
      errors.name = 'Please enter an account name';
    }
    if (this.checkAccountName(values.name) !== undefined) {
      errors.name = 'Please enter a unique account name';
    }

    return errors;
  }

  checkAccountName = (name) => {
    const { accounts } = this.props;
    const newName = (name || '').toLowerCase();
    return accounts.entities.find(account => account.name.toLowerCase() === newName);
  }

  addAccount = (values) => {
    const { createAccount } = this.props;
    const { logoImage } = this.state;

    createAccount(values, logoImage);
  }

  onAddLogo = (image) => {
    this.setState({
      logoOpen: false,
      logoImage: image,
      logoUrl: window.URL.createObjectURL(image),
    });
  }

  onDeleteLogo = () => {
    this.setState({
      logoImage: null,
      logoUrl: '',
    });
  }

  showLogo = () => {
    this.setState({
      logoOpen: true,
    });
  }

  hideLogo = () => {
    this.setState({
      logoOpen: false,
    });
  }

  render() {
    const { disableForm, logoUrl, logoOpen } = this.state;

    return (
      <Form
        onSubmit={this.addAccount}
        validate={this.validate}
        render={({ handleSubmit, values, hasValidationErrors }) => (
          <form onSubmit={handleSubmit} className="accounts-create-new-account-form form__container">
            <Field component={TextField} name="name" onChange={values}>
              {({ input, meta }) => (
                <div>
                  <TextField
                    {...input}
                    name="name"
                    placeholder="Add account name"
                    fullWidth
                    margin="none"
                    inputProps={{ maxLength: 50 }}
                    /* eslint-disable-next-line react/jsx-no-duplicate-props */
                    InputProps={{ classes: { input: 'dialog__input' } }}
                    InputLabelProps={{ shrink: true, margin: 'none' }}
                  />
                  {meta.error &&
                    meta.touched && <FormHelperText error>{meta.error}</FormHelperText>}
                </div>
              )}
            </Field>

            <div className="flex--center">
              <Image classes={{ root: 'icon' }} color="primary" />
              <FormControl classes={{ root: 'dialog__select' }}>
                <InputLabel shrink classes={{ root: 'typography--grey logo-label' }}>Logo</InputLabel>

                <AddLogo
                  logo={logoUrl}
                  onSave={this.onAddLogo}
                  onDelete={this.onDeleteLogo}
                  open={logoOpen}
                  showModal={this.showLogo}
                  hideModal={this.hideLogo}
                  loading={false}
                />
              </FormControl>
            </div>

            <DialogActions>
              <Button
                type="submit"
                color="primary"
                disabled={hasValidationErrors || disableForm}
                classes={{ text: 'button__text' }}
              >
                Add Account
              </Button>
            </DialogActions>
          </form>
        )}
      />
    );
  }
}

export default CreateNewAccountForm;
