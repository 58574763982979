import React from 'react';
import PropTypes from 'prop-types';
import {
  InputAdornment,
  TextField,
} from '@material-ui/core';
import {
  BLANK_FILTER,
  BETWEEN_FILTER,
} from '../../filters/constants';

function RangeTextField({
  verb,
  placeholder,
  value,
  secondaryValue,
  onValueChange,
  startAdornment,
  endAdornment,
  min,
  max,
}) {
  if (verb === BLANK_FILTER) return null;

  return (
    <div className="common-range-text-field">
      <TextField
        className="range-field"
        placeholder={placeholder}
        value={value}
        fullWidth
        onChange={onValueChange(false)}
        autoFocus
        InputProps={{
          type: 'number',
          startAdornment: startAdornment && (
            <InputAdornment position="start">
              {startAdornment}
            </InputAdornment>
          ),
          endAdornment: endAdornment && (
            <InputAdornment position="end">
              {endAdornment}
            </InputAdornment>
          ),
        }}
        // Attributes applied to the HTML input element. Different than InputProps.
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{
          min,
          max,
        }}
      />
      {verb === BETWEEN_FILTER && (
        <>
          <div className="range-field-and">and</div>
          <TextField
            className="range-field"
            placeholder={placeholder}
            value={secondaryValue}
            fullWidth
            onChange={onValueChange(true)}
            autoFocus
            InputProps={{
              type: 'number',
              startAdornment: startAdornment && (
                <InputAdornment position="start">
                  {startAdornment}
                </InputAdornment>
              ),
              endAdornment: endAdornment && (
                <InputAdornment position="end">
                  {endAdornment}
                </InputAdornment>
              ),
            }}
            // Attributes applied to the HTML input element. Different than InputProps.
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
              min,
              max,
            }}
          />
        </>
      )}
    </div>
  );
}

RangeTextField.propTypes = {
  verb: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  secondaryValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onValueChange: PropTypes.func,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  min: PropTypes.number,
  max: PropTypes.number,
};

RangeTextField.defaultProps = {
  placeholder: '',
  value: '',
  secondaryValue: '',
  onValueChange: () => {},
  startAdornment: '',
  endAdornment: '',
  min: null,
  max: null,
};

export default RangeTextField;
