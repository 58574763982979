import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { perAccountUserKey } from '../../../common/localStorageKeys';
import { saveToLocalStorage } from '../../../utils/browser-utils';
import { getValidatedLocalStorage } from '../../../utils/validators';
import { DEFAULT_ROLE_SEGMENT_SORT, DEFAULT_TIMEFRAME } from '../constants';
import { getFilteredRoles } from '../redux/actions';
import { FILTERED_UNFILLED_ROLES_QUERY_ID } from '../../queries/redux/constants';
import { FILLED_ROLES, FILTER_ROLES } from '../../../filters/constants';

export default function useRolesFilter({ view, accountId, userId } = {}) {
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);

  const isRemembered = !!view && !!accountId && !!userId;
  const timeframeKey = useMemo(() => isRemembered && perAccountUserKey(`${view}RoleSegmentTimeframe`, accountId, userId), [isRemembered, view, accountId, userId]);
  const sortKey = useMemo(() => isRemembered && perAccountUserKey(`${view}Sort`, accountId, userId), [isRemembered, view, accountId, userId]);

  const storedTimeframeValue = useMemo(() => getValidatedLocalStorage(timeframeKey), [timeframeKey]);
  const storedSortValue = useMemo(() => getValidatedLocalStorage(sortKey), [sortKey]);

  const [timeframe, setTimeframe] = useState(storedTimeframeValue || DEFAULT_TIMEFRAME);
  const [currentSort, setCurrentSort] = useState(storedSortValue || DEFAULT_ROLE_SEGMENT_SORT);

  const { projectQueries } = useSelector(({ queries }) => ({
    projectQueries: queries[FILTERED_UNFILLED_ROLES_QUERY_ID]?.filter,
  }));

  const filledRolesFilterSelected = useMemo(() => {
    const roleFilter = projectQueries?.args?.find(arg => arg.filterType === FILTER_ROLES);
    return !!roleFilter?.activeFilters[0]?.selected?.find(selected => selected.value === FILLED_ROLES);
  }, [projectQueries?.args]);

  const applyFilter = useCallback(({ timeframeFilter, sortQuery, pageFrom, projectQueries: appliedQueries } = {}) => {
    if (filledRolesFilterSelected) return;

    dispatch(getFilteredRoles({
      accountId,
      timeframeFilter: timeframeFilter || timeframe,
      sortQuery: sortQuery || currentSort,
      pageFrom: pageFrom || 0,
      projectQueries: appliedQueries || projectQueries,
    }));
  }, [filledRolesFilterSelected, dispatch, accountId, timeframe, currentSort, projectQueries]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      applyFilter();
    }
  }, [applyFilter]);

  const updateTimeframe = useCallback((value) => {
    setTimeframe(value);
    saveToLocalStorage({ key: timeframeKey, value });
  }, [timeframeKey]);

  const updateSort = useCallback((value) => {
    setCurrentSort(value);
    saveToLocalStorage({ key: sortKey, value });
  }, [sortKey]);

  return {
    timeframe,
    currentSort,
    updateTimeframe,
    updateSort,
    applyFilter,
    filledRolesFilterSelected,
  };
}
