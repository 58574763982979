import { call, put, takeLatest } from 'redux-saga/effects';
import {
  SERVICE_ACCOUNTS_ADD_SERVICE_ACCOUNT_BEGIN,
  SERVICE_ACCOUNTS_ADD_SERVICE_ACCOUNT_SUCCESS,
  SERVICE_ACCOUNTS_ADD_SERVICE_ACCOUNT_FAILURE,
  SERVICE_ACCOUNTS_ADD_SERVICE_ACCOUNT_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';

export function addServiceAccount({ accountId, ...accountData }, analyticsPayload) {
  return {
    type: SERVICE_ACCOUNTS_ADD_SERVICE_ACCOUNT_BEGIN,
    accountId,
    accountData,
    analyticsPayload,
  };
}

export function dismissAddServiceAccountError() {
  return {
    type: SERVICE_ACCOUNTS_ADD_SERVICE_ACCOUNT_DISMISS_ERROR,
  };
}

export function* doAddServiceAccount(action) {
  const { accountId, accountData, analyticsPayload } = action;
  let res;
  try {
    res = yield call(protectedJsonPost, `accounts/${accountId}/service-accounts`, accountData);
  } catch (err) {
    yield put({
      type: SERVICE_ACCOUNTS_ADD_SERVICE_ACCOUNT_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: SERVICE_ACCOUNTS_ADD_SERVICE_ACCOUNT_SUCCESS,
    data: res,
    analyticsPayload,
  });
}

export function* watchAddServiceAccount() {
  yield takeLatest(SERVICE_ACCOUNTS_ADD_SERVICE_ACCOUNT_BEGIN, doAddServiceAccount);
}

export function reducer(state, action) {
  switch (action.type) {
    case SERVICE_ACCOUNTS_ADD_SERVICE_ACCOUNT_BEGIN:
      return {
        ...state,
        addServiceAccountPending: true,
        addServiceAccountError: null,
      };

    case SERVICE_ACCOUNTS_ADD_SERVICE_ACCOUNT_SUCCESS: {
      const { data } = action;
      const { password, ...sanitizedNewServiceAccount } = data;
      return {
        ...state,
        account: data,
        accounts: [...state.accounts, sanitizedNewServiceAccount],
        addServiceAccountPending: false,
        addServiceAccountError: null,
      };
    }

    case SERVICE_ACCOUNTS_ADD_SERVICE_ACCOUNT_FAILURE:
      return {
        ...state,
        addServiceAccountPending: false,
        addServiceAccountError: action.data.error,
      };

    case SERVICE_ACCOUNTS_ADD_SERVICE_ACCOUNT_DISMISS_ERROR:
      return {
        ...state,
        addServiceAccountError: null,
      };

    default:
      return state;
  }
}
