export const MAX_LIST_ITEM_LENGTH = 250;
export const MAX_ROLE_NAME_LENGTH = 50;
export const MAX_LIST_LENGTH = 250;

export const TOGGLE_REQUIRED_SYSTEM_FIELDS = ['Home Address', 'Address', 'Cell number'];
export const EDITABLE_SYSTEM_FIELDS = [
  ...TOGGLE_REQUIRED_SYSTEM_FIELDS,
  'Other',
  'Employee ID',
];

export const ACCOUNT_SETTINGS_GET_PERSON_FIELDS_BEGIN = 'ACCOUNT_SETTINGS_GET_PERSON_FIELDS_BEGIN';
export const ACCOUNT_SETTINGS_GET_PERSON_FIELDS_SUCCESS = 'ACCOUNT_SETTINGS_GET_PERSON_FIELDS_SUCCESS';
export const ACCOUNT_SETTINGS_GET_PERSON_FIELDS_FAILURE = 'ACCOUNT_SETTINGS_GET_PERSON_FIELDS_FAILURE';
export const ACCOUNT_SETTINGS_GET_PERSON_FIELDS_DISMISS_ERROR = 'ACCOUNT_SETTINGS_GET_PERSON_FIELDS_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_REMOVE_PERSON_FIELDS_BEGIN = 'ACCOUNT_SETTINGS_REMOVE_PERSON_FIELDS_BEGIN';
export const ACCOUNT_SETTINGS_REMOVE_PERSON_FIELDS_SUCCESS = 'ACCOUNT_SETTINGS_REMOVE_PERSON_FIELDS_SUCCESS';
export const ACCOUNT_SETTINGS_REMOVE_PERSON_FIELDS_FAILURE = 'ACCOUNT_SETTINGS_REMOVE_PERSON_FIELDS_FAILURE';
export const ACCOUNT_SETTINGS_REMOVE_PERSON_FIELDS_DISMISS_ERROR = 'ACCOUNT_SETTINGS_REMOVE_PERSON_FIELDS_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_ADD_PERSON_FIELDS_BEGIN = 'ACCOUNT_SETTINGS_ADD_PERSON_FIELDS_BEGIN';
export const ACCOUNT_SETTINGS_ADD_PERSON_FIELDS_SUCCESS = 'ACCOUNT_SETTINGS_ADD_PERSON_FIELDS_SUCCESS';
export const ACCOUNT_SETTINGS_ADD_PERSON_FIELDS_FAILURE = 'ACCOUNT_SETTINGS_ADD_PERSON_FIELDS_FAILURE';
export const ACCOUNT_SETTINGS_ADD_PERSON_FIELDS_DISMISS_ERROR = 'ACCOUNT_SETTINGS_ADD_PERSON_FIELDS_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_UPDATE_PERSON_FIELDS_BEGIN = 'ACCOUNT_SETTINGS_UPDATE_PERSON_FIELDS_BEGIN';
export const ACCOUNT_SETTINGS_UPDATE_PERSON_FIELDS_SUCCESS = 'ACCOUNT_SETTINGS_UPDATE_PERSON_FIELDS_SUCCESS';
export const ACCOUNT_SETTINGS_UPDATE_PERSON_FIELDS_FAILURE = 'ACCOUNT_SETTINGS_UPDATE_PERSON_FIELDS_FAILURE';
export const ACCOUNT_SETTINGS_UPDATE_PERSON_FIELDS_DISMISS_ERROR = 'ACCOUNT_SETTINGS_UPDATE_PERSON_FIELDS_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_GET_PROJECT_FIELDS_BEGIN = 'ACCOUNT_SETTINGS_GET_PROJECT_FIELDS_BEGIN';
export const ACCOUNT_SETTINGS_GET_PROJECT_FIELDS_SUCCESS = 'ACCOUNT_SETTINGS_GET_PROJECT_FIELDS_SUCCESS';
export const ACCOUNT_SETTINGS_GET_PROJECT_FIELDS_FAILURE = 'ACCOUNT_SETTINGS_GET_PROJECT_FIELDS_FAILURE';
export const ACCOUNT_SETTINGS_GET_PROJECT_FIELDS_DISMISS_ERROR = 'ACCOUNT_SETTINGS_GET_PROJECT_FIELDS_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_UPDATE_PROJECT_FIELDS_BEGIN = 'ACCOUNT_SETTINGS_UPDATE_PROJECT_FIELDS_BEGIN';
export const ACCOUNT_SETTINGS_UPDATE_PROJECT_FIELDS_SUCCESS = 'ACCOUNT_SETTINGS_UPDATE_PROJECT_FIELDS_SUCCESS';
export const ACCOUNT_SETTINGS_UPDATE_PROJECT_FIELDS_FAILURE = 'ACCOUNT_SETTINGS_UPDATE_PROJECT_FIELDS_FAILURE';
export const ACCOUNT_SETTINGS_UPDATE_PROJECT_FIELDS_DISMISS_ERROR = 'ACCOUNT_SETTINGS_UPDATE_PROJECT_FIELDS_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_ADD_PROJECT_FIELDS_BEGIN = 'ACCOUNT_SETTINGS_ADD_PROJECT_FIELDS_BEGIN';
export const ACCOUNT_SETTINGS_ADD_PROJECT_FIELDS_SUCCESS = 'ACCOUNT_SETTINGS_ADD_PROJECT_FIELDS_SUCCESS';
export const ACCOUNT_SETTINGS_ADD_PROJECT_FIELDS_FAILURE = 'ACCOUNT_SETTINGS_ADD_PROJECT_FIELDS_FAILURE';
export const ACCOUNT_SETTINGS_ADD_PROJECT_FIELDS_DISMISS_ERROR = 'ACCOUNT_SETTINGS_ADD_PROJECT_FIELDS_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_REMOVE_PROJECT_FIELDS_BEGIN = 'ACCOUNT_SETTINGS_REMOVE_PROJECT_FIELDS_BEGIN';
export const ACCOUNT_SETTINGS_REMOVE_PROJECT_FIELDS_SUCCESS = 'ACCOUNT_SETTINGS_REMOVE_PROJECT_FIELDS_SUCCESS';
export const ACCOUNT_SETTINGS_REMOVE_PROJECT_FIELDS_FAILURE = 'ACCOUNT_SETTINGS_REMOVE_PROJECT_FIELDS_FAILURE';
export const ACCOUNT_SETTINGS_REMOVE_PROJECT_FIELDS_DISMISS_ERROR = 'ACCOUNT_SETTINGS_REMOVE_PROJECT_FIELDS_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_GET_ROLE_NAMES_BEGIN = 'ACCOUNT_SETTINGS_GET_ROLE_NAMES_BEGIN';
export const ACCOUNT_SETTINGS_GET_ROLE_NAMES_SUCCESS = 'ACCOUNT_SETTINGS_GET_ROLE_NAMES_SUCCESS';
export const ACCOUNT_SETTINGS_GET_ROLE_NAMES_FAILURE = 'ACCOUNT_SETTINGS_GET_ROLE_NAMES_FAILURE';
export const ACCOUNT_SETTINGS_GET_ROLE_NAMES_DISMISS_ERROR = 'ACCOUNT_SETTINGS_GET_ROLE_NAMES_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_UPDATE_ROLE_NAMES_BEGIN = 'ACCOUNT_SETTINGS_UPDATE_ROLE_NAMES_BEGIN';
export const ACCOUNT_SETTINGS_UPDATE_ROLE_NAMES_SUCCESS = 'ACCOUNT_SETTINGS_UPDATE_ROLE_NAMES_SUCCESS';
export const ACCOUNT_SETTINGS_UPDATE_ROLE_NAMES_FAILURE = 'ACCOUNT_SETTINGS_UPDATE_ROLE_NAMES_FAILURE';
export const ACCOUNT_SETTINGS_UPDATE_ROLE_NAMES_DISMISS_ERROR = 'ACCOUNT_SETTINGS_UPDATE_ROLE_NAMES_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_REMOVE_ROLE_NAMES_BEGIN = 'ACCOUNT_SETTINGS_REMOVE_ROLE_NAMES_BEGIN';
export const ACCOUNT_SETTINGS_REMOVE_ROLE_NAMES_SUCCESS = 'ACCOUNT_SETTINGS_REMOVE_ROLE_NAMES_SUCCESS';
export const ACCOUNT_SETTINGS_REMOVE_ROLE_NAMES_FAILURE = 'ACCOUNT_SETTINGS_REMOVE_ROLE_NAMES_FAILURE';
export const ACCOUNT_SETTINGS_REMOVE_ROLE_NAMES_DISMISS_ERROR = 'ACCOUNT_SETTINGS_REMOVE_ROLE_NAMES_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_GET_PHASES_BEGIN = 'ACCOUNT_SETTINGS_GET_PHASES_BEGIN';
export const ACCOUNT_SETTINGS_GET_PHASES_SUCCESS = 'ACCOUNT_SETTINGS_GET_PHASES_SUCCESS';
export const ACCOUNT_SETTINGS_GET_PHASES_FAILURE = 'ACCOUNT_SETTINGS_GET_PHASES_FAILURE';
export const ACCOUNT_SETTINGS_GET_PHASES_DISMISS_ERROR = 'ACCOUNT_SETTINGS_GET_PHASES_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_ADD_PHASE_BEGIN = 'ACCOUNT_SETTINGS_ADD_PHASE_BEGIN';
export const ACCOUNT_SETTINGS_ADD_PHASE_SUCCESS = 'ACCOUNT_SETTINGS_ADD_PHASE_SUCCESS';
export const ACCOUNT_SETTINGS_ADD_PHASE_FAILURE = 'ACCOUNT_SETTINGS_ADD_PHASE_FAILURE';
export const ACCOUNT_SETTINGS_ADD_PHASE_DISMISS_ERROR = 'ACCOUNT_SETTINGS_ADD_PHASE_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_DELETE_PHASE_BEGIN = 'ACCOUNT_SETTINGS_DELETE_PHASE_BEGIN';
export const ACCOUNT_SETTINGS_DELETE_PHASE_SUCCESS = 'ACCOUNT_SETTINGS_DELETE_PHASE_SUCCESS';
export const ACCOUNT_SETTINGS_DELETE_PHASE_FAILURE = 'ACCOUNT_SETTINGS_DELETE_PHASE_FAILURE';
export const ACCOUNT_SETTINGS_DELETE_PHASE_DISMISS_ERROR = 'ACCOUNT_SETTINGS_DELETE_PHASE_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_UPDATE_PHASE_BEGIN = 'ACCOUNT_SETTINGS_UPDATE_PHASE_BEGIN';
export const ACCOUNT_SETTINGS_UPDATE_PHASE_SUCCESS = 'ACCOUNT_SETTINGS_UPDATE_PHASE_SUCCESS';
export const ACCOUNT_SETTINGS_UPDATE_PHASE_FAILURE = 'ACCOUNT_SETTINGS_UPDATE_PHASE_FAILURE';
export const ACCOUNT_SETTINGS_UPDATE_PHASE_DISMISS_ERROR = 'ACCOUNT_SETTINGS_UPDATE_PHASE_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_ADD_SUB_PHASE_BEGIN = 'ACCOUNT_SETTINGS_ADD_SUB_PHASE_BEGIN';
export const ACCOUNT_SETTINGS_ADD_SUB_PHASE_SUCCESS = 'ACCOUNT_SETTINGS_ADD_SUB_PHASE_SUCCESS';
export const ACCOUNT_SETTINGS_ADD_SUB_PHASE_FAILURE = 'ACCOUNT_SETTINGS_ADD_SUB_PHASE_FAILURE';
export const ACCOUNT_SETTINGS_ADD_SUB_PHASE_DISMISS_ERROR = 'ACCOUNT_SETTINGS_ADD_SUB_PHASE_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_DELETE_SUB_PHASE_BEGIN = 'ACCOUNT_SETTINGS_DELETE_SUB_PHASE_BEGIN';
export const ACCOUNT_SETTINGS_DELETE_SUB_PHASE_SUCCESS = 'ACCOUNT_SETTINGS_DELETE_SUB_PHASE_SUCCESS';
export const ACCOUNT_SETTINGS_DELETE_SUB_PHASE_FAILURE = 'ACCOUNT_SETTINGS_DELETE_SUB_PHASE_FAILURE';
export const ACCOUNT_SETTINGS_DELETE_SUB_PHASE_DISMISS_ERROR = 'ACCOUNT_SETTINGS_DELETE_SUB_PHASE_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_UPDATE_SUB_PHASE_BEGIN = 'ACCOUNT_SETTINGS_UPDATE_SUB_PHASE_BEGIN';
export const ACCOUNT_SETTINGS_UPDATE_SUB_PHASE_SUCCESS = 'ACCOUNT_SETTINGS_UPDATE_SUB_PHASE_SUCCESS';
export const ACCOUNT_SETTINGS_UPDATE_SUB_PHASE_FAILURE = 'ACCOUNT_SETTINGS_UPDATE_SUB_PHASE_FAILURE';
export const ACCOUNT_SETTINGS_UPDATE_SUB_PHASE_DISMISS_ERROR = 'ACCOUNT_SETTINGS_UPDATE_SUB_PHASE_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_UPDATE_PHASES_BEGIN = 'ACCOUNT_SETTINGS_UPDATE_PHASES_BEGIN';
export const ACCOUNT_SETTINGS_UPDATE_PHASES_SUCCESS = 'ACCOUNT_SETTINGS_UPDATE_PHASES_SUCCESS';
export const ACCOUNT_SETTINGS_UPDATE_PHASES_FAILURE = 'ACCOUNT_SETTINGS_UPDATE_PHASES_FAILURE';
export const ACCOUNT_SETTINGS_UPDATE_PHASES_DISMISS_ERROR = 'ACCOUNT_SETTINGS_UPDATE_PHASES_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_UPDATE_SUB_PHASES_BEGIN = 'ACCOUNT_SETTINGS_UPDATE_SUB_PHASES_BEGIN';
export const ACCOUNT_SETTINGS_UPDATE_SUB_PHASES_SUCCESS = 'ACCOUNT_SETTINGS_UPDATE_SUB_PHASES_SUCCESS';
export const ACCOUNT_SETTINGS_UPDATE_SUB_PHASES_FAILURE = 'ACCOUNT_SETTINGS_UPDATE_SUB_PHASES_FAILURE';
export const ACCOUNT_SETTINGS_UPDATE_SUB_PHASES_DISMISS_ERROR = 'ACCOUNT_SETTINGS_UPDATE_SUB_PHASES_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_COLLECT_FIELDS_BEGIN = 'ACCOUNT_SETTINGS_COLLECT_FIELDS_BEGIN';
export const ACCOUNT_SETTINGS_COLLECT_FIELDS_SUCCESS = 'ACCOUNT_SETTINGS_COLLECT_FIELDS_SUCCESS';
export const ACCOUNT_SETTINGS_COLLECT_FIELDS_FAILURE = 'ACCOUNT_SETTINGS_COLLECT_FIELDS_FAILURE';
export const ACCOUNT_SETTINGS_COLLECT_FIELDS_DISMISS_ERROR = 'ACCOUNT_SETTINGS_COLLECT_FIELDS_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_GET_ACCOUNT_PROPERTIES_BEGIN = 'ACCOUNT_SETTINGS_GET_ACCOUNT_PROPERTIES_BEGIN';
export const ACCOUNT_SETTINGS_GET_ACCOUNT_PROPERTIES_SUCCESS = 'ACCOUNT_SETTINGS_GET_ACCOUNT_PROPERTIES_SUCCESS';
export const ACCOUNT_SETTINGS_GET_ACCOUNT_PROPERTIES_FAILURE = 'ACCOUNT_SETTINGS_GET_ACCOUNT_PROPERTIES_FAILURE';
export const ACCOUNT_SETTINGS_GET_ACCOUNT_PROPERTIES_DISMISS_ERROR = 'ACCOUNT_SETTINGS_GET_ACCOUNT_PROPERTIES_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_UPDATE_ACCOUNT_PROPERTIES_BEGIN = 'ACCOUNT_SETTINGS_UPDATE_ACCOUNT_PROPERTIES_BEGIN';
export const ACCOUNT_SETTINGS_UPDATE_ACCOUNT_PROPERTIES_SUCCESS = 'ACCOUNT_SETTINGS_UPDATE_ACCOUNT_PROPERTIES_SUCCESS';
export const ACCOUNT_SETTINGS_UPDATE_ACCOUNT_PROPERTIES_FAILURE = 'ACCOUNT_SETTINGS_UPDATE_ACCOUNT_PROPERTIES_FAILURE';
export const ACCOUNT_SETTINGS_UPDATE_ACCOUNT_PROPERTIES_DISMISS_ERROR = 'ACCOUNT_SETTINGS_UPDATE_ACCOUNT_PROPERTIES_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_ADD_ACCOUNT_MODULE_BEGIN = 'ACCOUNT_SETTINGS_ADD_ACCOUNT_MODULE_BEGIN';
export const ACCOUNT_SETTINGS_ADD_ACCOUNT_MODULE_SUCCESS = 'ACCOUNT_SETTINGS_ADD_ACCOUNT_MODULE_SUCCESS';
export const ACCOUNT_SETTINGS_ADD_ACCOUNT_MODULE_FAILURE = 'ACCOUNT_SETTINGS_ADD_ACCOUNT_MODULE_FAILURE';
export const ACCOUNT_SETTINGS_ADD_ACCOUNT_MODULE_DISMISS_ERROR = 'ACCOUNT_SETTINGS_ADD_ACCOUNT_MODULE_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_GET_ACCOUNT_MODULES_BEGIN = 'ACCOUNT_SETTINGS_GET_ACCOUNT_MODULES_BEGIN';
export const ACCOUNT_SETTINGS_GET_ACCOUNT_MODULES_SUCCESS = 'ACCOUNT_SETTINGS_GET_ACCOUNT_MODULES_SUCCESS';
export const ACCOUNT_SETTINGS_GET_ACCOUNT_MODULES_FAILURE = 'ACCOUNT_SETTINGS_GET_ACCOUNT_MODULES_FAILURE';
export const ACCOUNT_SETTINGS_GET_ACCOUNT_MODULES_DISMISS_ERROR = 'ACCOUNT_SETTINGS_GET_ACCOUNT_MODULES_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_REMOVE_ACCOUNT_MODULE_BEGIN = 'ACCOUNT_SETTINGS_REMOVE_ACCOUNT_MODULE_BEGIN';
export const ACCOUNT_SETTINGS_REMOVE_ACCOUNT_MODULE_SUCCESS = 'ACCOUNT_SETTINGS_REMOVE_ACCOUNT_MODULE_SUCCESS';
export const ACCOUNT_SETTINGS_REMOVE_ACCOUNT_MODULE_FAILURE = 'ACCOUNT_SETTINGS_REMOVE_ACCOUNT_MODULE_FAILURE';
export const ACCOUNT_SETTINGS_REMOVE_ACCOUNT_MODULE_DISMISS_ERROR = 'ACCOUNT_SETTINGS_REMOVE_ACCOUNT_MODULE_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_GET_MODULES_BEGIN = 'ACCOUNT_SETTINGS_GET_MODULES_BEGIN';
export const ACCOUNT_SETTINGS_GET_MODULES_SUCCESS = 'ACCOUNT_SETTINGS_GET_MODULES_SUCCESS';
export const ACCOUNT_SETTINGS_GET_MODULES_FAILURE = 'ACCOUNT_SETTINGS_GET_MODULES_FAILURE';
export const ACCOUNT_SETTINGS_GET_MODULES_DISMISS_ERROR = 'ACCOUNT_SETTINGS_GET_MODULES_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_GET_HOURLY_PERSON_FIELDS_BEGIN = 'ACCOUNT_SETTINGS_GET_HOURLY_PERSON_FIELDS_BEGIN';
export const ACCOUNT_SETTINGS_GET_HOURLY_PERSON_FIELDS_SUCCESS = 'ACCOUNT_SETTINGS_GET_HOURLY_PERSON_FIELDS_SUCCESS';
export const ACCOUNT_SETTINGS_GET_HOURLY_PERSON_FIELDS_FAILURE = 'ACCOUNT_SETTINGS_GET_HOURLY_PERSON_FIELDS_FAILURE';
export const ACCOUNT_SETTINGS_GET_HOURLY_PERSON_FIELDS_DISMISS_ERROR = 'ACCOUNT_SETTINGS_GET_HOURLY_PERSON_FIELDS_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_UPDATE_HOURLY_PERSON_FIELDS_BEGIN = 'ACCOUNT_SETTINGS_UPDATE_HOURLY_PERSON_FIELDS_BEGIN';
export const ACCOUNT_SETTINGS_UPDATE_HOURLY_PERSON_FIELDS_SUCCESS = 'ACCOUNT_SETTINGS_UPDATE_HOURLY_PERSON_FIELDS_SUCCESS';
export const ACCOUNT_SETTINGS_UPDATE_HOURLY_PERSON_FIELDS_FAILURE = 'ACCOUNT_SETTINGS_UPDATE_HOURLY_PERSON_FIELDS_FAILURE';
export const ACCOUNT_SETTINGS_UPDATE_HOURLY_PERSON_FIELDS_DISMISS_ERROR = 'ACCOUNT_SETTINGS_UPDATE_HOURLY_PERSON_FIELDS_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_GET_SKILL_SET_BEGIN = 'ACCOUNT_SETTINGS_GET_SKILL_SET_BEGIN';
export const ACCOUNT_SETTINGS_GET_SKILL_SET_SUCCESS = 'ACCOUNT_SETTINGS_GET_SKILL_SET_SUCCESS';
export const ACCOUNT_SETTINGS_GET_SKILL_SET_FAILURE = 'ACCOUNT_SETTINGS_GET_SKILL_SET_FAILURE';
export const ACCOUNT_SETTINGS_GET_SKILL_SET_DISMISS_ERROR = 'ACCOUNT_SETTINGS_GET_SKILL_SET_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_UPDATE_SKILL_SET_BEGIN = 'ACCOUNT_SETTINGS_UPDATE_SKILL_SET_BEGIN';
export const ACCOUNT_SETTINGS_UPDATE_SKILL_SET_SUCCESS = 'ACCOUNT_SETTINGS_UPDATE_SKILL_SET_SUCCESS';
export const ACCOUNT_SETTINGS_UPDATE_SKILL_SET_SUCCESS_NO_EVENT = 'ACCOUNT_SETTINGS_UPDATE_SKILL_SET_SUCCESS_NO_EVENT';
export const ACCOUNT_SETTINGS_UPDATE_SKILL_SET_FAILURE = 'ACCOUNT_SETTINGS_UPDATE_SKILL_SET_FAILURE';
export const ACCOUNT_SETTINGS_UPDATE_SKILL_SET_DISMISS_ERROR = 'ACCOUNT_SETTINGS_UPDATE_SKILL_SET_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_REMOVE_SKILL_BEGIN = 'ACCOUNT_SETTINGS_REMOVE_SKILL_BEGIN';
export const ACCOUNT_SETTINGS_REMOVE_SKILL_SUCCESS = 'ACCOUNT_SETTINGS_REMOVE_SKILL_SUCCESS';
export const ACCOUNT_SETTINGS_REMOVE_SKILL_FAILURE = 'ACCOUNT_SETTINGS_REMOVE_SKILL_FAILURE';
export const ACCOUNT_SETTINGS_REMOVE_SKILL_DISMISS_ERROR = 'ACCOUNT_SETTINGS_REMOVE_SKILL_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_ADD_HOURLY_PERSON_FIELDS_BEGIN = 'ACCOUNT_SETTINGS_ADD_HOURLY_PERSON_FIELDS_BEGIN';
export const ACCOUNT_SETTINGS_ADD_HOURLY_PERSON_FIELDS_SUCCESS = 'ACCOUNT_SETTINGS_ADD_HOURLY_PERSON_FIELDS_SUCCESS';
export const ACCOUNT_SETTINGS_ADD_HOURLY_PERSON_FIELDS_FAILURE = 'ACCOUNT_SETTINGS_ADD_HOURLY_PERSON_FIELDS_FAILURE';
export const ACCOUNT_SETTINGS_ADD_HOURLY_PERSON_FIELDS_DISMISS_ERROR = 'ACCOUNT_SETTINGS_ADD_HOURLY_PERSON_FIELDS_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_REMOVE_HOURLY_PERSON_FIELDS_BEGIN = 'ACCOUNT_SETTINGS_REMOVE_HOURLY_PERSON_FIELDS_BEGIN';
export const ACCOUNT_SETTINGS_REMOVE_HOURLY_PERSON_FIELDS_SUCCESS = 'ACCOUNT_SETTINGS_REMOVE_HOURLY_PERSON_FIELDS_SUCCESS';
export const ACCOUNT_SETTINGS_REMOVE_HOURLY_PERSON_FIELDS_FAILURE = 'ACCOUNT_SETTINGS_REMOVE_HOURLY_PERSON_FIELDS_FAILURE';
export const ACCOUNT_SETTINGS_REMOVE_HOURLY_PERSON_FIELDS_DISMISS_ERROR = 'ACCOUNT_SETTINGS_REMOVE_HOURLY_PERSON_FIELDS_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_ADD_PURSUIT_TRACKING_MODULE_SUCCESS = 'ACCOUNT_SETTINGS_ADD_PURSUIT_TRACKING_MODULE_SUCCESS';
export const ACCOUNT_SETTINGS_REMOVE_PURSUIT_TRACKING_MODULE_SUCCESS = 'ACCOUNT_SETTINGS_REMOVE_PURSUIT_TRACKING_MODULE_SUCCESS';
export const ACCOUNT_SETTINGS_UPDATE_ACCOUNT_MODULE_BEGIN = 'ACCOUNT_SETTINGS_UPDATE_ACCOUNT_MODULE_BEGIN';
export const ACCOUNT_SETTINGS_UPDATE_ACCOUNT_MODULE_SUCCESS = 'ACCOUNT_SETTINGS_UPDATE_ACCOUNT_MODULE_SUCCESS';
export const ACCOUNT_SETTINGS_UPDATE_ACCOUNT_MODULE_FAILURE = 'ACCOUNT_SETTINGS_UPDATE_ACCOUNT_MODULE_FAILURE';
export const ACCOUNT_SETTINGS_UPDATE_ACCOUNT_MODULE_DISMISS_ERROR = 'ACCOUNT_SETTINGS_UPDATE_ACCOUNT_MODULE_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_GET_ACCOUNT_LIMITS_BEGIN = 'ACCOUNT_SETTINGS_GET_ACCOUNT_LIMITS_BEGIN';
export const ACCOUNT_SETTINGS_GET_ACCOUNT_LIMITS_SUCCESS = 'ACCOUNT_SETTINGS_GET_ACCOUNT_LIMITS_SUCCESS';
export const ACCOUNT_SETTINGS_GET_ACCOUNT_LIMITS_FAILURE = 'ACCOUNT_SETTINGS_GET_ACCOUNT_LIMITS_FAILURE';
export const ACCOUNT_SETTINGS_GET_ACCOUNT_LIMITS_DISMISS_ERROR = 'ACCOUNT_SETTINGS_GET_ACCOUNT_LIMITS_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_GET_CERTIFICATIONS_BEGIN = 'ACCOUNT_SETTINGS_GET_CERTIFICATIONS_BEGIN';
export const ACCOUNT_SETTINGS_GET_CERTIFICATIONS_SUCCESS = 'ACCOUNT_SETTINGS_GET_CERTIFICATIONS_SUCCESS';
export const ACCOUNT_SETTINGS_GET_CERTIFICATIONS_FAILURE = 'ACCOUNT_SETTINGS_GET_CERTIFICATIONS_FAILURE';
export const ACCOUNT_SETTINGS_GET_CERTIFICATIONS_DISMISS_ERROR = 'ACCOUNT_SETTINGS_GET_CERTIFICATIONS_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_ADD_CERTIFICATION_BEGIN = 'ACCOUNT_SETTINGS_ADD_CERTIFICATION_BEGIN';
export const ACCOUNT_SETTINGS_ADD_CERTIFICATION_SUCCESS = 'ACCOUNT_SETTINGS_ADD_CERTIFICATION_SUCCESS';
export const ACCOUNT_SETTINGS_ADD_CERTIFICATION_FAILURE = 'ACCOUNT_SETTINGS_ADD_CERTIFICATION_FAILURE';
export const ACCOUNT_SETTINGS_ADD_CERTIFICATION_DISMISS_ERROR = 'ACCOUNT_SETTINGS_ADD_CERTIFICATION_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_UPDATE_CERTIFICATION_BEGIN = 'ACCOUNT_SETTINGS_UPDATE_CERTIFICATION_BEGIN';
export const ACCOUNT_SETTINGS_UPDATE_CERTIFICATION_SUCCESS = 'ACCOUNT_SETTINGS_UPDATE_CERTIFICATION_SUCCESS';
export const ACCOUNT_SETTINGS_UPDATE_CERTIFICATION_FAILURE = 'ACCOUNT_SETTINGS_UPDATE_CERTIFICATION_FAILURE';
export const ACCOUNT_SETTINGS_UPDATE_CERTIFICATION_DISMISS_ERROR = 'ACCOUNT_SETTINGS_UPDATE_CERTIFICATION_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_DELETE_CERTIFICATION_BEGIN = 'ACCOUNT_SETTINGS_DELETE_CERTIFICATION_BEGIN';
export const ACCOUNT_SETTINGS_DELETE_CERTIFICATION_SUCCESS = 'ACCOUNT_SETTINGS_DELETE_CERTIFICATION_SUCCESS';
export const ACCOUNT_SETTINGS_DELETE_CERTIFICATION_FAILURE = 'ACCOUNT_SETTINGS_DELETE_CERTIFICATION_FAILURE';
export const ACCOUNT_SETTINGS_DELETE_CERTIFICATION_DISMISS_ERROR = 'ACCOUNT_SETTINGS_DELETE_CERTIFICATION_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_GET_CERTIFICATION_BY_ID_BEGIN = 'ACCOUNT_SETTINGS_GET_CERTIFICATION_BY_ID_BEGIN';
export const ACCOUNT_SETTINGS_GET_CERTIFICATION_BY_ID_SUCCESS = 'ACCOUNT_SETTINGS_GET_CERTIFICATION_BY_ID_SUCCESS';
export const ACCOUNT_SETTINGS_GET_CERTIFICATION_BY_ID_FAILURE = 'ACCOUNT_SETTINGS_GET_CERTIFICATION_BY_ID_FAILURE';
export const ACCOUNT_SETTINGS_GET_CERTIFICATION_BY_ID_DISMISS_ERROR = 'ACCOUNT_SETTINGS_GET_CERTIFICATION_BY_ID_DISMISS_ERROR';
export const ACCOUNT_SETTINGS_CLEAR_SELECTED_CERTIFICATION = 'ACCOUNT_SETTINGS_CLEAR_SELECTED_CERTIFICATION';
