import React, { useState, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { MessageOutlined, Restore, AttachFile, Beenhere } from '@material-ui/icons';
import { ModalSidePanel } from 'src/features/common';
import { PersonNotesContainer, PersonAudit, ProfileAttachments, ProfileCertifications } from '.';
import {
  PERSON_MODAL_NOTES_TAB,
  PERSON_MODAL_AUDIT_TAB,
  PERSON_MODAL_ATTACHMENTS_TAB,
  PERSON_MODAL_CERTIFICATIONS_TAB,
} from './constants';
import { hasModuleEnabled } from '../permissions/utils/permissionUtils';
import {
  ACCOUNT_MODULE_ATTACHMENTS,
  ACCOUNT_MODULE_CERTIFICATIONS,
  PEOPLE_ACCOUNT_MODULE_COMPONENT,
} from '../../common/constants';

const PersonModalSidePanel = ({ selectedPerson }) => {
  const { accountModules } = useSelector(({ accountSettings }) => accountSettings);

  const [activePanel, setActivePanel] = useState(null);

  const hasAttachmentModule = useMemo(
    () => hasModuleEnabled(accountModules, ACCOUNT_MODULE_ATTACHMENTS, PEOPLE_ACCOUNT_MODULE_COMPONENT),
    [accountModules],
  );

  const hasCertificationsModule = useMemo(
    () => hasModuleEnabled(accountModules, ACCOUNT_MODULE_CERTIFICATIONS, PEOPLE_ACCOUNT_MODULE_COMPONENT),
    [accountModules],
  );

  const options = useMemo(() => [
    {
      value: PERSON_MODAL_NOTES_TAB,
      display: 'Notes',
      eventName: 'View Person Notes',
      icon: <MessageOutlined color="primary" />,
    },
    ...(hasCertificationsModule ? [{
      value: PERSON_MODAL_CERTIFICATIONS_TAB,
      display: 'Certifications',
      eventName: 'View Certifications',
      icon: <Beenhere color="primary" />,
    }] : []),
    ...(hasAttachmentModule ? [{
      value: PERSON_MODAL_ATTACHMENTS_TAB,
      display: 'Attachments',
      eventName: 'View Profile Attachments',
      icon: <AttachFile color="primary" />,
    }] : []),
    {
      value: PERSON_MODAL_AUDIT_TAB,
      display: 'Profile Activity',
      eventName: 'View Profile Activity Log',
      icon: <Restore color="primary" />,
    },
  ], [hasAttachmentModule, hasCertificationsModule]);

  useEffect(() => {
    if (options.length) {
      setActivePanel(options[0]);
    }
  }, [options]);

  const onChange = useCallback((evt) => {
    const { value } = evt.target;
    const { eventName } = value;
    const { id, name } = selectedPerson;

    const mixpanelData = {
      'Person name': name,
      'Person ID': id,
      'Viewed from': 'Person details modal',
    };

    window.mixpanel.track(eventName, mixpanelData);

    setActivePanel(value);
  }, [selectedPerson]);

  const renderContent = () => {
    const { value } = activePanel;
    const { id, name, notes } = selectedPerson;

    switch (value) {
      case PERSON_MODAL_NOTES_TAB:
        return (
          <PersonNotesContainer
            personId={id}
            personName={name}
            personNotes={notes}
          />
        );
      case PERSON_MODAL_CERTIFICATIONS_TAB: return <ProfileCertifications />;
      case PERSON_MODAL_AUDIT_TAB: return <PersonAudit />;
      case PERSON_MODAL_ATTACHMENTS_TAB: return <ProfileAttachments />;
      default: return null;
    }
  };

  if (!selectedPerson || !options.length || !activePanel) return null;

  return (
    <ModalSidePanel
      className="people-person-modal-side-panel"
      options={options}
      activePanel={activePanel}
      onChange={onChange}
    >
      {renderContent()}
    </ModalSidePanel>
  );
};

PersonModalSidePanel.propTypes = {
  selectedPerson: PropTypes.object,
};

PersonModalSidePanel.defaultProps = {
  selectedPerson: null,
};

export default PersonModalSidePanel;
