import { TOGGLE_NAVIGATION } from './constants';

export function toggleNavigation() {
  return {
    type: TOGGLE_NAVIGATION,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case TOGGLE_NAVIGATION:
      return {
        ...state,
        collapsedNav: !state.collapsedNav,
      };

    default:
      return state;
  }
}
