import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DatePicker, RemoveButton } from 'src/features/common';
import moment from 'moment';
import { validateDate } from 'src/utils/dateUtils';
import { DATE_INPUT_FORMAT } from 'src/common/constants';
import { NumberInput } from '../wrapped-components';

const CustomRequirementRow = React.memo(({
  reqStart,
  reqEnd,
  allocatedPercent,
  onUpdate,
  onError,
  roleStart,
  roleEnd,
}) => {
  const initialRequirement = {
    startDate: reqStart,
    endDate: reqEnd,
    allocatedPercent,
  };

  const format = date => (date === null ? date : moment(date, DATE_INPUT_FORMAT).format(DATE_INPUT_FORMAT));
  const parse = date => (date === null ? date : moment(date, DATE_INPUT_FORMAT));

  const reqStartParsed = parse(reqStart);
  const reqEndParsed = parse(reqEnd);
  const roleStartParsed = parse(roleStart);
  const roleEndParsed = parse(roleEnd);

  const [workingStart, setWorkingStart] = useState(reqStartParsed);
  const [workingEnd, setWorkingEnd] = useState(reqEndParsed);
  const [startDisabled, setStartDisabled] = useState(false);
  const [endDisabled, setEndDisabled] = useState(false);

  useEffect(() => { setWorkingStart(parse(reqStart)); }, [reqStart]);
  useEffect(() => { setWorkingEnd(parse(reqEnd)); }, [reqEnd]);

  const updateRequirement = updatedRequirement => onUpdate(initialRequirement, updatedRequirement);

  const updateStart = (newStart) => {
    setWorkingStart(newStart);

    if (validateDate(newStart, roleStartParsed, reqEndParsed)) {
      updateRequirement({
        ...initialRequirement,
        startDate: format(newStart),
      });
    }
  };

  const updateEnd = (newEnd) => {
    setWorkingEnd(newEnd);

    if (validateDate(newEnd, reqStartParsed, roleEndParsed)) {
      updateRequirement({
        ...initialRequirement,
        endDate: format(newEnd),
      });
    }
  };

  const updateAllocatedPercent = newPercent => updateRequirement({
    ...initialRequirement,
    allocatedPercent: newPercent,
  });

  const handleError = (err) => {
    if (err.length) onError();
  };

  const handleStartError = (err) => {
    handleError(err);
    setEndDisabled(!!err.length);
  };

  const handleEndError = (err) => {
    handleError(err);
    setStartDisabled(!!err.length);
  };

  const removeRange = () => {
    updateRequirement(null);
  };

  const allowDelete = reqStart === null || reqEnd === null;

  const dateRangeError = 'Date outside of role date range';

  return (
    <div className="allocations-custom-requirement-row">
      <div className="range-date-editor">
        <DatePicker
          className="date-picker"
          date={workingStart}
          disabled={startDisabled}
          onChange={updateStart}
          variant="inline"
          initialFocusedDate={roleStartParsed}
          minDate={roleStartParsed}
          maxDate={reqEndParsed || roleEndParsed}
          minDateMessage={dateRangeError}
          maxDateMessage={dateRangeError}
          onError={handleStartError}
        />
        <DatePicker
          className="date-picker"
          date={workingEnd}
          disabled={endDisabled}
          onChange={updateEnd}
          variant="inline"
          initialFocusedDate={roleEndParsed}
          minDate={reqStartParsed || roleStartParsed}
          maxDate={roleEndParsed}
          minDateMessage={dateRangeError}
          maxDateMessage={dateRangeError}
          onError={handleEndError}
        />
      </div>

      <NumberInput
        className="allocation-picker"
        value={allocatedPercent}
        onValueChanged={updateAllocatedPercent}
        minValue={0}
      />

      { allowDelete && (
        <RemoveButton
          className="button-delete-range"
          tooltipValue="Delete range"
          onClick={removeRange}
        />
      )}
    </div>
  );
});

CustomRequirementRow.propTypes = {
  reqStart: PropTypes.string,
  reqEnd: PropTypes.string,
  allocatedPercent: PropTypes.number,
  onUpdate: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  roleStart: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]).isRequired,
  roleEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]).isRequired,
};

CustomRequirementRow.defaultProps = {
  reqStart: null,
  reqEnd: null,
  allocatedPercent: 100,
};

export default CustomRequirementRow;
