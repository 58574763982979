import { call, put, takeEvery } from 'redux-saga/effects';
import { protectedJsonPost } from '../../../utils/api';
import {
  PROJECTS_ADD_PROJECT_ROLE_ALLOCATION_BEGIN,
  PROJECTS_ADD_PROJECT_ROLE_ALLOCATION_SUCCESS,
  PROJECTS_ADD_PROJECT_ROLE_ALLOCATION_FAILURE,
  PROJECTS_ADD_PROJECT_ROLE_ALLOCATION_DISMISS_ERROR,
} from './constants';

export function addProjectRoleAllocation(accountId, projectId, data, analytics) {
  // If need to pass args to saga, pass it with the begin action.
  return {
    type: PROJECTS_ADD_PROJECT_ROLE_ALLOCATION_BEGIN,
    accountId,
    projectId,
    data,
    analytics,
  };
}

export function dismissAddProjectRoleAllocationError() {
  return {
    type: PROJECTS_ADD_PROJECT_ROLE_ALLOCATION_DISMISS_ERROR,
  };
}

export function* doAddProjectRoleAllocation(action) {
  const { accountId, projectId, data, analytics } = action;
  try {
    yield call(protectedJsonPost, `accounts/${accountId}/projects/${projectId}/allocations`, data);
  } catch (err) {
    yield put({
      type: PROJECTS_ADD_PROJECT_ROLE_ALLOCATION_FAILURE,
      data: { error: err },
    });
    return;
  }
  yield put({
    type: PROJECTS_ADD_PROJECT_ROLE_ALLOCATION_SUCCESS,
    projectId,
    data: action.data,
    analytics,
  });
}

export function* watchAddProjectRoleAllocation() {
  yield takeEvery(PROJECTS_ADD_PROJECT_ROLE_ALLOCATION_BEGIN, doAddProjectRoleAllocation);
}

export function reducer(state, action) {
  switch (action.type) {
    case PROJECTS_ADD_PROJECT_ROLE_ALLOCATION_BEGIN:
      return {
        ...state,
        addProjectRoleAllocationPending: true,
        addProjectRoleAllocationError: null,
      };

    case PROJECTS_ADD_PROJECT_ROLE_ALLOCATION_SUCCESS: {
      const { projectId, data } = action;
      let existingAllocations = [];

      if (projectId.toString() in state.projectAllocations) {
        const role = state.projectAllocations[projectId].find(a => a.roleId === data.roleId);
        if (role) {
          existingAllocations = role.allocations;
        }
      }

      const updatedRoleAllocations = {
        roleId: data.roleId,
        allocations: [
          ...existingAllocations,
          data,
        ],
      };

      const currentProjectAllocations = state.projectAllocations[projectId] ? state.projectAllocations[projectId].filter(a => a.roleId !== data.roleId) : [];

      const expandedAllocations = [...state.expandedAllocations];
      const projectRoles = expandedAllocations.find(a => a.projectId === projectId);
      if (projectRoles) {
        const role = projectRoles.roles.find(r => r.roleId === action.data.roleId);

        if (!role) {
          projectRoles.roles.push({ roleId: action.data.roleId, allocations: [action.data] });
        } else {
          role.allocations.push(action.data);
        }
      } else {
        expandedAllocations.push({
          projectId,
          roles: [{ roleId: action.data.roleId, allocations: [action.data] }],
        });
      }

      const { projectSelections } = state;
      const newProjectSelections = { ...projectSelections };
      Object.keys(newProjectSelections).forEach((selectionId) => {
        const selectedProject = newProjectSelections[selectionId];
        if (selectedProject && selectedProject.id === projectId) {
          const { unfilledRoles, roles } = selectedProject;
          let newUnfilledRoles = unfilledRoles;

          // Update roles and allocations
          const newRoles = roles.map((role) => {
            const newRole = { ...role };
            if (role.id === data.roleId) {
              newUnfilledRoles = unfilledRoles > 0 ? unfilledRoles - 1 : 0;
              newRole.allocations = [...role.allocations, data];
            }
            return newRole;
          });

          newProjectSelections[selectionId] = {
            ...selectedProject,
            roles: newRoles,
            unfilledRoles: newUnfilledRoles,
          };
        }
      });

      return {
        ...state,
        expandedAllocations,
        projectAllocations: {
          ...state.projectAllocations,
          [projectId]: [
            ...currentProjectAllocations,
            updatedRoleAllocations,
          ],
        },
        addProjectRoleAllocationPending: false,
        addProjectRoleAllocationError: null,
        projectSelections: newProjectSelections,
        triggerRefreshAllocationMeta: true,
      };
    }
    case PROJECTS_ADD_PROJECT_ROLE_ALLOCATION_FAILURE:
      return {
        ...state,
        addProjectRoleAllocationPending: false,
        addProjectRoleAllocationError: action.data.error,
      };

    case PROJECTS_ADD_PROJECT_ROLE_ALLOCATION_DISMISS_ERROR:
      return {
        ...state,
        addProjectRoleAllocationError: null,
      };

    default:
      return state;
  }
}
