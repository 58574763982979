import {
  COMMON_CLEAR_SELECTED_PROJECT_ID, COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PROJECT,
} from './constants';

export function clearSelectedProjectId() {
  return {
    type: COMMON_CLEAR_SELECTED_PROJECT_ID,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_CLEAR_SELECTED_PROJECT_ID: {
      const { multiStateModal } = state;
      const { activeView } = multiStateModal;
      return {
        ...state,
        multiStateModal: {
          ...multiStateModal,
          selectedProjectId: null,
          roleDetails: null,
          activeView: activeView === COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PROJECT ? null : activeView,
        },
      };
    }

    default:
      return state;
  }
}
