import { call, put, takeLatest } from 'redux-saga/effects';
import { SEGMENT_TYPE_ROLE } from 'src/features/edit-dates/common/constants';
import {
  PROJECTS_ADD_PROJECT_ROLES_BEGIN,
  PROJECTS_ADD_PROJECT_ROLES_SUCCESS,
  PROJECTS_ADD_PROJECT_ROLES_FAILURE,
  PROJECTS_ADD_PROJECT_ROLES_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';
import { normalizeRolesToRequirements, normalizeRolesToAllocations, addRolesToProject } from '../utils/projectUtils';
import { MULTI_STATE_MODAL_ID } from '../../common/redux/constants';

export function addProjectRoles(accountId, projectId, projectRoleData, parentName) {
  return {
    type: PROJECTS_ADD_PROJECT_ROLES_BEGIN,
    accountId,
    projectId,
    projectRoleData,
    parentName,
  };
}

export function dismissAddProjectRolesError() {
  return {
    type: PROJECTS_ADD_PROJECT_ROLES_DISMISS_ERROR,
  };
}

export function* doAddProjectRoles(action) {
  const { accountId, projectId, projectRoleData, parentName } = action;
  let res;
  try {
    const roles = normalizeRolesToAllocations(projectRoleData);
    res = yield call(protectedJsonPost, `accounts/${accountId}/projects/${projectId}/roles/bulk`, roles);
  } catch (err) {
    yield put({
      type: PROJECTS_ADD_PROJECT_ROLES_FAILURE,
      data: { error: err },
    });
    return;
  }
  yield put({
    type: PROJECTS_ADD_PROJECT_ROLES_SUCCESS,
    data: res,
    projectRoleData,
    projectId,
    parentName,
  });
}

export function* watchAddProjectRoles() {
  yield takeLatest(PROJECTS_ADD_PROJECT_ROLES_BEGIN, doAddProjectRoles);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PROJECTS_ADD_PROJECT_ROLES_BEGIN:
      return {
        ...state,
        addProjectRolesPending: true,
        addProjectRolesError: null,
      };

    case PROJECTS_ADD_PROJECT_ROLES_SUCCESS: {
      const { projectId, parentName, data, projectRoleData } = action;
      const formattedData = data.map(role => ({ ...role, __typename: SEGMENT_TYPE_ROLE }));
      const newRoles = normalizeRolesToRequirements(formattedData);
      const currentProject = state.projects.find(project => project.id === projectId);
      const { filteredProjects, projectSelections } = state;
      let isInitialRoles = false;

      const newFilteredProjects = filteredProjects.map((project) => {
        if (project.id === projectId) {
          return addRolesToProject(project, newRoles);
        }
        return project;
      });

      const newProjectSelections = { ...projectSelections };
      Object.keys(newProjectSelections).forEach((selectionId) => {
        const selectedProject = newProjectSelections[selectionId];
        if (selectedProject && selectedProject.id === projectId) {
          isInitialRoles = selectedProject.roles ? selectedProject.roles.length === 0 : true;
          const updatedProject = addRolesToProject(selectedProject, newRoles);
          newProjectSelections[selectionId] = {
            ...updatedProject,
          };
        }
      });

      let numCustom = 0;
      let numStandardFull = 0;
      let numStandardLess = 0;
      const roleNotes = [];

      action.data.forEach((role) => {
        if (role.allocations.length === 1) {
          if (role.allocations[0].allocatedPercent === 100) {
            numStandardFull += 1;
          } else {
            numStandardLess += 1;
          }
        } else {
          numCustom += 1;
        }

        if (role.note) {
          roleNotes.push(role.note);
        }
      });

      const selectedProject = currentProject || state.projectSelections[MULTI_STATE_MODAL_ID];

      // Mixpanel - Track roles added
      window.mixpanel.track('Roles Added to Project', {
        'Project Name': selectedProject.name,
        'Project ID': selectedProject.id,
        'Project status': selectedProject.state,
        'Project type': selectedProject.type,
        'Number Of Roles Added': newRoles.length,
        ...(numStandardFull > 0 && { 'Number Of Roles With Standard Allocation = 100%': numStandardFull }),
        ...(numStandardLess > 0 && { 'Number Of Roles With Standard Allocation < 100%': numStandardLess }),
        ...(numCustom > 0 && { 'Number Of Roles With Custom Allocation %': numCustom }),
        'Added to a project from': parentName,
        'Initial roles added': isInitialRoles,
        'Number of Role Notes Added': roleNotes.length,
        'Role Notes Added': roleNotes,
      });

      // Mixpanel - Track roles added with phases
      projectRoleData.forEach((role) => {
        if (role?.selectedPhases?.length) {
          const phaseNames = role.selectedPhases.map(phase => phase.name);
          const mixPanelData = {
            'Project Name': selectedProject.name,
            'Project ID': selectedProject.id,
            'Project status': selectedProject.state,
            'Project type': selectedProject.type,
            'Role name': role.name,
            'Added from': parentName,
            '# of phases selected': role.selectedPhases.length,
            'Phase name(s) selected': phaseNames,
          };
          window.mixpanel.track('Role added using phase dates (Quick option)', mixPanelData);
        }
      });

      return {
        ...state,
        filteredProjects: newFilteredProjects,
        addProjectRolesPending: false,
        addProjectRolesError: null,
        projectSelections: newProjectSelections,
      };
    }

    case PROJECTS_ADD_PROJECT_ROLES_FAILURE:
      return {
        ...state,
        addProjectRolesPending: false,
        addProjectRolesError: action.data.error,
      };

    case PROJECTS_ADD_PROJECT_ROLES_DISMISS_ERROR:
      return {
        ...state,
        addProjectRolesError: null,
      };

    default:
      return state;
  }
}
