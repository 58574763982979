export const PERMISSIONS_GET_PERMISSION_GROUPS_BEGIN = 'PERMISSIONS_GET_PERMISSION_GROUPS_BEGIN';
export const PERMISSIONS_GET_PERMISSION_GROUPS_SUCCESS = 'PERMISSIONS_GET_PERMISSION_GROUPS_SUCCESS';
export const PERMISSIONS_GET_PERMISSION_GROUPS_FAILURE = 'PERMISSIONS_GET_PERMISSION_GROUPS_FAILURE';
export const PERMISSIONS_GET_PERMISSION_GROUPS_DISMISS_ERROR = 'PERMISSIONS_GET_PERMISSION_GROUPS_DISMISS_ERROR';
export const PERMISSIONS_CREATE_PERMISSION_GROUP_BEGIN = 'PERMISSIONS_CREATE_PERMISSION_GROUP_BEGIN';
export const PERMISSIONS_CREATE_PERMISSION_GROUP_SUCCESS = 'PERMISSIONS_CREATE_PERMISSION_GROUP_SUCCESS';
export const PERMISSIONS_CREATE_PERMISSION_GROUP_FAILURE = 'PERMISSIONS_CREATE_PERMISSION_GROUP_FAILURE';
export const PERMISSIONS_CREATE_PERMISSION_GROUP_DISMISS_ERROR = 'PERMISSIONS_CREATE_PERMISSION_GROUP_DISMISS_ERROR';
export const PERMISSIONS_UPDATE_PERMISSION_GROUP_BEGIN = 'PERMISSIONS_UPDATE_PERMISSION_GROUP_BEGIN';
export const PERMISSIONS_UPDATE_PERMISSION_GROUP_SUCCESS = 'PERMISSIONS_UPDATE_PERMISSION_GROUP_SUCCESS';
export const PERMISSIONS_UPDATE_PERMISSION_GROUP_FAILURE = 'PERMISSIONS_UPDATE_PERMISSION_GROUP_FAILURE';
export const PERMISSIONS_UPDATE_PERMISSION_GROUP_DISMISS_ERROR = 'PERMISSIONS_UPDATE_PERMISSION_GROUP_DISMISS_ERROR';
export const PERMISSIONS_DELETE_PERMISSION_GROUP_BEGIN = 'PERMISSIONS_DELETE_PERMISSION_GROUP_BEGIN';
export const PERMISSIONS_DELETE_PERMISSION_GROUP_SUCCESS = 'PERMISSIONS_DELETE_PERMISSION_GROUP_SUCCESS';
export const PERMISSIONS_DELETE_PERMISSION_GROUP_FAILURE = 'PERMISSIONS_DELETE_PERMISSION_GROUP_FAILURE';
export const PERMISSIONS_DELETE_PERMISSION_GROUP_DISMISS_ERROR = 'PERMISSIONS_DELETE_PERMISSION_GROUP_DISMISS_ERROR';
