import { call, put, takeLatest } from 'redux-saga/effects';
import {
  REPORTS_GET_REPORT_PROJECTS_BEGIN,
  REPORTS_GET_REPORT_PROJECTS_SUCCESS,
  REPORTS_GET_REPORT_PROJECTS_FAILURE,
  REPORTS_GET_REPORT_PROJECTS_DISMISS_ERROR,
} from './constants';
import { getProjectListQuery } from '../../projects/queries/getProjectListQuery';
import graphApiClient from '../../../common/GraphAPIClient';
import { collateFieldValues } from '../../../filters/graphqlUtils';
import { MIN_FROM, MAX_SIZE } from '../../../common/constants';
import initialState from './initialState';

export function getReportProjects(accountId, queries, roleSortOption) {
  const { query, variables } = getProjectListQuery(accountId, queries, MIN_FROM, MAX_SIZE, roleSortOption);
  return {
    type: REPORTS_GET_REPORT_PROJECTS_BEGIN,
    query,
    variables,
  };
}

export function dismissGetReportProjectsError() {
  return {
    type: REPORTS_GET_REPORT_PROJECTS_DISMISS_ERROR,
  };
}

export function* doGetReportProjects(action) {
  const { query, variables } = action;
  let response;
  try {
    response = yield call(graphApiClient.query, { query, variables });
    const { errors } = response;
    if (errors) {
      throw errors;
    }
  } catch (err) {
    yield put({
      type: REPORTS_GET_REPORT_PROJECTS_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: REPORTS_GET_REPORT_PROJECTS_SUCCESS,
    data: response.data,
  });
}

export function* watchGetReportProjects() {
  yield takeLatest(REPORTS_GET_REPORT_PROJECTS_BEGIN, doGetReportProjects);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case REPORTS_GET_REPORT_PROJECTS_BEGIN:
      return {
        ...state,
        getReportProjectsPending: true,
        getReportProjectsError: null,
      };

    case REPORTS_GET_REPORT_PROJECTS_SUCCESS: {
      const { data } = action;
      const { projects: { items } } = data;

      return {
        ...state,
        projects: collateFieldValues(items),
        getReportProjectsPending: false,
        getReportProjectsError: null,
      };
    }

    case REPORTS_GET_REPORT_PROJECTS_FAILURE:
      return {
        ...initialState,
        getReportProjectsPending: false,
        getReportProjectsError: action.data.error,
      };

    case REPORTS_GET_REPORT_PROJECTS_DISMISS_ERROR:
      return {
        ...initialState,
        getReportProjectsError: null,
      };

    default:
      return state;
  }
}
