import { gql } from 'apollo-boost';

const ACCOUNT_COLORS_QUERY = gql`
    query getAccountColorsQuery($accountId: Int!){
      accountColors(accountId: $accountId) {
        items
        count
      }
    }
  `;

const getAccountColorsQuery = (accountId) => {
  const variables = { accountId };

  return {
    query: ACCOUNT_COLORS_QUERY,
    variables,
  };
};

export {
  getAccountColorsQuery,
};
