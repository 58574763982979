import { call, put, takeEvery } from 'redux-saga/effects';
import moment from 'moment';
import {
  DASHBOARD_GET_UNFILLED_ROLES_BEGIN,
  DASHBOARD_GET_UNFILLED_ROLES_SUCCESS,
  DASHBOARD_GET_UNFILLED_ROLES_FAILURE,
  DASHBOARD_GET_UNFILLED_ROLES_DISMISS_ERROR,
} from './constants';
import { getUnfilledRolesQuery } from '../queries/getUnfilledRolesQuery';
import graphApiClient from '../../../common/GraphAPIClient';

export function getUnfilledRoles(accountId, queries) {
  const { query, variables } = getUnfilledRolesQuery(accountId, queries);
  return {
    type: DASHBOARD_GET_UNFILLED_ROLES_BEGIN,
    query,
    variables,
  };
}

export function dismissGetUnfilledRolesError() {
  return {
    type: DASHBOARD_GET_UNFILLED_ROLES_DISMISS_ERROR,
  };
}

export function* doGetUnfilledRoles(action) {
  const { query, variables } = action;
  let res;

  try {
    res = yield call(graphApiClient.query, { query, variables });
    const { errors } = res;
    if (errors) {
      throw errors;
    }
  } catch (err) {
    yield put({
      type: DASHBOARD_GET_UNFILLED_ROLES_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: DASHBOARD_GET_UNFILLED_ROLES_SUCCESS,
    data: res.data,
  });
}

export function* watchGetUnfilledRoles() {
  yield takeEvery(DASHBOARD_GET_UNFILLED_ROLES_BEGIN, doGetUnfilledRoles);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case DASHBOARD_GET_UNFILLED_ROLES_BEGIN:
      return {
        ...state,
        getUnfilledRolesPending: true,
        getUnfilledRolesError: null,
      };

    case DASHBOARD_GET_UNFILLED_ROLES_SUCCESS: {
      const unfilledRoles = [];
      const today = moment();
      if (action?.data?.projects?.items?.length) {
        action.data.projects.items.forEach((project) => {
          if (project.roles) {
            const roleProject = {
              projectId: project.id,
              projectName: project.name,
              projectColour: project.colour,
            };

            project.roles.forEach((role) => {
              if (!role.isFilled && today.isSameOrBefore(role.endDate)) {
                const unfilledRanges = role.unfilledRanges.filter(range => today.isSameOrBefore(range.endDate));
                if (unfilledRanges.length) {
                  unfilledRoles.push({
                    ...roleProject,
                    ...role,
                    unfilledRanges,
                  });
                }
              }
            });
          }
        });
      }

      return {
        ...state,
        unfilledRoles,
        getUnfilledRolesPending: false,
        getUnfilledRolesError: null,
      };
    }

    case DASHBOARD_GET_UNFILLED_ROLES_FAILURE:
      return {
        ...state,
        getUnfilledRolesPending: false,
        getUnfilledRolesError: action.data.error,
      };

    case DASHBOARD_GET_UNFILLED_ROLES_DISMISS_ERROR:
      return {
        ...state,
        getUnfilledRolesError: null,
      };

    default:
      return state;
  }
}
