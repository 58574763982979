import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PEOPLE_GET_PERSON_BY_ID_BEGIN,
  PEOPLE_GET_PERSON_BY_ID_SUCCESS,
  PEOPLE_GET_PERSON_BY_ID_FAILURE,
  PEOPLE_GET_PERSON_BY_ID_DISMISS_ERROR,
} from './constants';
import { getPersonByIdQuery } from '../queries/getPersonByIdQuery';
import graphApiClient from '../../../common/GraphAPIClient';

export function getPersonById(accountId, selectedPersonId, selectionId) {
  const { query, variables } = getPersonByIdQuery(accountId, selectedPersonId);
  return {
    type: PEOPLE_GET_PERSON_BY_ID_BEGIN,
    selectedPersonId,
    query,
    variables,
    selectionId,
  };
}

export function dismissGetPersonByIdError() {
  return {
    type: PEOPLE_GET_PERSON_BY_ID_DISMISS_ERROR,
  };
}

export function* doGetPersonById(action) {
  const { query, variables, selectionId } = action;
  let res;
  try {
    res = yield call(graphApiClient.query, { query, variables });
    const { errors } = res;
    if (errors) {
      throw errors;
    }
  } catch (err) {
    yield put({
      type: PEOPLE_GET_PERSON_BY_ID_FAILURE,
      data: { error: err },
    });
    return;
  }
  yield put({
    type: PEOPLE_GET_PERSON_BY_ID_SUCCESS,
    data: {
      selectedPerson: res?.data?.person || null,
      selectionId,
    },
  });
}

export function* watchGetPersonById() {
  yield takeLatest(PEOPLE_GET_PERSON_BY_ID_BEGIN, doGetPersonById);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_GET_PERSON_BY_ID_BEGIN:
      return {
        ...state,
        getPersonByIdPending: true,
        getPersonByIdError: null,
      };

    case PEOPLE_GET_PERSON_BY_ID_SUCCESS: {
      const { personSelections } = state;
      const { data: { selectedPerson, selectionId } } = action;
      return {
        ...state,
        personSelections: {
          ...personSelections,
          [selectionId]: {
            ...selectedPerson,
          },
        },
        getPersonByIdPending: false,
        getPersonByIdError: null,
        personAttachments: {},
      };
    }

    case PEOPLE_GET_PERSON_BY_ID_FAILURE:
      return {
        ...state,
        getPersonByIdPending: false,
        getPersonByIdError: action.data.error,
      };

    case PEOPLE_GET_PERSON_BY_ID_DISMISS_ERROR:
      return {
        ...state,
        getPersonByIdError: null,
      };

    default:
      return state;
  }
}
