import { call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { DATE_INPUT_FORMAT, MONTH_YEAR_FORMAT } from 'src/common/constants';
import {
  FORECAST_GET_BREAKDOWN_BEGIN,
  FORECAST_GET_BREAKDOWN_SUCCESS,
  FORECAST_GET_BREAKDOWN_FAILURE,
  FORECAST_GET_BREAKDOWN_DISMISS_ERROR,
} from './constants';
import { getBreakdownQuery } from '../queries/getBreakdownQuery';
import graphApiClient from '../../../common/GraphAPIClient';
import { BLANK_FILTER } from '../../../filters/constants';

export function getBreakdown(
  accountId,
  fieldId,
  breakdownDate,
  trueValue = false,
  queries = {},
  isPursuitsEnabled,
  pursuitFilter = [],
) {
  const formattedBreakdownDate = moment(breakdownDate, MONTH_YEAR_FORMAT).startOf('month').format(DATE_INPUT_FORMAT);
  const includePursuitIds = pursuitFilter.map(pursuit => pursuit.id);
  const { query, variables } = getBreakdownQuery(accountId, queries, trueValue, formattedBreakdownDate, fieldId, isPursuitsEnabled, includePursuitIds);
  return {
    type: FORECAST_GET_BREAKDOWN_BEGIN,
    query,
    variables,
  };
}

export function dismissGetBreakdownError() {
  return {
    type: FORECAST_GET_BREAKDOWN_DISMISS_ERROR,
  };
}

export function* doGetBreakdown(action) {
  const { query, variables } = action;

  let res;

  try {
    res = yield call(graphApiClient.query, { query, variables });
    const { errors } = res;
    if (errors) {
      throw errors;
    }
  } catch (err) {
    yield put({
      type: FORECAST_GET_BREAKDOWN_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: FORECAST_GET_BREAKDOWN_SUCCESS,
    data: res.data,
  });
}

export function* watchGetBreakdown() {
  yield takeLatest(FORECAST_GET_BREAKDOWN_BEGIN, doGetBreakdown);
}

export function reducer(state, action) {
  switch (action.type) {
    case FORECAST_GET_BREAKDOWN_BEGIN:
      return {
        ...state,
        getBreakdownPending: true,
        getBreakdownError: null,
      };

    case FORECAST_GET_BREAKDOWN_SUCCESS: {
      const { awardedOnlyBreakdown, scenarioBreakdown } = action.data;
      return {
        ...state,
        awardedOnlyBreakdown: {
          ...awardedOnlyBreakdown,
          items: awardedOnlyBreakdown?.items?.map(item => ({ ...item, name: item.name || BLANK_FILTER })) || [],
        },
        ...scenarioBreakdown && {
          scenarioBreakdown: {
            ...scenarioBreakdown,
            items: scenarioBreakdown.items?.map(item => ({ ...item, name: item.name || BLANK_FILTER })) || [],
          },
        },
        getBreakdownPending: false,
        getBreakdownError: null,
      };
    }

    case FORECAST_GET_BREAKDOWN_FAILURE:
      return {
        ...state,
        getBreakdownPending: false,
        getBreakdownError: action.data.error,
      };

    case FORECAST_GET_BREAKDOWN_DISMISS_ERROR:
      return {
        ...state,
        getBreakdownError: null,
      };

    default:
      return state;
  }
}
