import { gql } from 'apollo-boost';
import moment from 'moment';
import { DATE_INPUT_FORMAT } from 'src/common/constants';

const GET_PERSON_BY_ID_QUERY = gql`
  query personByIdQuery($accountId: Int!, $personId: Int!, $relativeDate: String) {
    person(accountId: $accountId, personId: $personId, relativeDate: $relativeDate) {
      id
      email
      name
      title
      photoUrl
      isActive
      hasConflict
      availableInDays
      unavailabilities {
        id
        description
        startDate
        endDate
        isPrivate
        rangeType
      }
      employmentDates {
        startDate
        endDate
      }
      fields {
        fieldId: id
        isPrivate
        name
        type: dataType
        values
      }
      notes {
        id
        creatorId
        creatorName
        message
        isPrivate
        createdOn
        lastModifiedOn
      }
    }
  }
`;

export const getPersonByIdQuery = (accountId, personId) => {
  const variables = {
    accountId,
    personId,
    relativeDate: moment().local().format(DATE_INPUT_FORMAT),
  };

  return {
    query: GET_PERSON_BY_ID_QUERY,
    variables,
  };
};
