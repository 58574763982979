import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PEOPLE_UPLOAD_PERSON_ATTACHMENT_BEGIN,
  PEOPLE_UPLOAD_PERSON_ATTACHMENT_SUCCESS,
  PEOPLE_UPLOAD_PERSON_ATTACHMENT_FAILURE,
  PEOPLE_UPLOAD_PERSON_ATTACHMENT_S3_FAILURE,
  PEOPLE_UPLOAD_PERSON_ATTACHMENT_CERT_LINK_FAILURE,
  PEOPLE_UPLOAD_PERSON_ATTACHMENT_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost, uploadFile } from '../../../utils/api';
import { ATTACHMENT_POLLING_STATE, ATTACHMENT_FAILED_STATE } from '../constants';
import { getAttachmentUploadState } from '../utils/attachmentUtils';

export function uploadPersonAttachment(
  accountId,
  personId,
  data,
  binary,
  analyticsPayload,
  certificationId = null,
) {
  return {
    type: PEOPLE_UPLOAD_PERSON_ATTACHMENT_BEGIN,
    accountId,
    personId,
    data,
    binary,
    analyticsPayload,
    certificationId,
  };
}

export function dismissUploadPersonAttachmentError() {
  return {
    type: PEOPLE_UPLOAD_PERSON_ATTACHMENT_DISMISS_ERROR,
  };
}

export function* doUploadPersonAttachment(action) {
  const { accountId, personId, data, binary, analyticsPayload, certificationId } = action;
  let attachment;

  try {
    attachment = yield call(protectedJsonPost, `/accounts/${accountId}/persons/${personId}/attachments`, data);
  } catch (err) {
    yield put({
      type: PEOPLE_UPLOAD_PERSON_ATTACHMENT_FAILURE,
      data: { error: err },
    });
    return;
  }

  if (certificationId) {
    try {
      const { id } = attachment;
      yield call(
        protectedJsonPost,
        `/accounts/${accountId}/persons/${personId}/certifications/${certificationId}/attachments`,
        { attachmentId: id },
      );
    } catch (err) {
      yield put({
        type: PEOPLE_UPLOAD_PERSON_ATTACHMENT_CERT_LINK_FAILURE,
        data: { error: err },
      });
      return;
    }
  }

  try {
    const { uploadUrl } = attachment;
    yield call(uploadFile, uploadUrl, binary, 'Upload profile attachment API request error');
  } catch (err) {
    yield put({
      type: PEOPLE_UPLOAD_PERSON_ATTACHMENT_S3_FAILURE,
      attachment,
      certificationId,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PEOPLE_UPLOAD_PERSON_ATTACHMENT_SUCCESS,
    attachment,
    analyticsPayload,
    certificationId,
  });
}

export function* watchUploadPersonAttachment() {
  yield takeLatest(PEOPLE_UPLOAD_PERSON_ATTACHMENT_BEGIN, doUploadPersonAttachment);
}

export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_UPLOAD_PERSON_ATTACHMENT_BEGIN:
      return {
        ...state,
        uploadPersonAttachmentPending: true,
        uploadPersonAttachmentError: null,
      };

    case PEOPLE_UPLOAD_PERSON_ATTACHMENT_SUCCESS: {
      const stateToUpdate = getAttachmentUploadState(state, action, ATTACHMENT_POLLING_STATE);

      return {
        ...state,
        ...stateToUpdate,
        uploadPersonAttachmentPending: false,
        uploadPersonAttachmentError: action?.data?.error || null,
      };
    }

    case PEOPLE_UPLOAD_PERSON_ATTACHMENT_S3_FAILURE: {
      const stateToUpdate = getAttachmentUploadState(state, action, ATTACHMENT_FAILED_STATE);

      return {
        ...state,
        ...stateToUpdate,
        uploadPersonAttachmentPending: false,
        uploadPersonAttachmentError: action?.data?.error || null,
      };
    }

    case PEOPLE_UPLOAD_PERSON_ATTACHMENT_FAILURE:
    case PEOPLE_UPLOAD_PERSON_ATTACHMENT_CERT_LINK_FAILURE:
      return {
        ...state,
        uploadPersonAttachmentPending: false,
        uploadPersonAttachmentError: action.data.error,
      };

    case PEOPLE_UPLOAD_PERSON_ATTACHMENT_DISMISS_ERROR:
      return {
        ...state,
        uploadPersonAttachmentError: null,
      };

    default:
      return state;
  }
}
