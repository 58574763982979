import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNT_SETTINGS_DELETE_CERTIFICATION_BEGIN,
  ACCOUNT_SETTINGS_DELETE_CERTIFICATION_SUCCESS,
  ACCOUNT_SETTINGS_DELETE_CERTIFICATION_FAILURE,
  ACCOUNT_SETTINGS_DELETE_CERTIFICATION_DISMISS_ERROR,
} from './constants';
import { protectedJsonDelete } from '../../../utils/api';

export function deleteAccountCertification(accountId, certificationId) {
  return {
    type: ACCOUNT_SETTINGS_DELETE_CERTIFICATION_BEGIN,
    accountId,
    certificationId,
  };
}

export function dismissDeleteAccountCertificationError() {
  return {
    type: ACCOUNT_SETTINGS_DELETE_CERTIFICATION_DISMISS_ERROR,
  };
}

export function* doDeleteAccountCertification(action) {
  const { accountId, certificationId } = action;

  try {
    yield call(protectedJsonDelete, `accounts/${accountId}/certifications/${certificationId}`);
  } catch (err) {
    yield put({
      type: ACCOUNT_SETTINGS_DELETE_CERTIFICATION_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: ACCOUNT_SETTINGS_DELETE_CERTIFICATION_SUCCESS,
    data: certificationId,
  });
}

export function* watchDeleteAccountCertification() {
  yield takeLatest(ACCOUNT_SETTINGS_DELETE_CERTIFICATION_BEGIN, doDeleteAccountCertification);
}

export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNT_SETTINGS_DELETE_CERTIFICATION_BEGIN:
      return {
        ...state,
        deleteAccountCertificationPending: true,
        deleteAccountCertificationError: null,
      };

    case ACCOUNT_SETTINGS_DELETE_CERTIFICATION_SUCCESS: {
      const { data: certificationId } = action;
      const { certifications } = state;
      const newCertifications = certifications.filter(({ id }) => id !== certificationId);

      return {
        ...state,
        certifications: newCertifications,
        deleteAccountCertificationPending: false,
        deleteAccountCertificationError: null,
      };
    }

    case ACCOUNT_SETTINGS_DELETE_CERTIFICATION_FAILURE:
      return {
        ...state,
        deleteAccountCertificationPending: false,
        deleteAccountCertificationError: action.data.error,
      };

    case ACCOUNT_SETTINGS_DELETE_CERTIFICATION_DISMISS_ERROR:
      return {
        ...state,
        deleteAccountCertificationError: null,
      };

    default:
      return state;
  }
}
