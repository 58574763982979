import React, { useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Notes, Loader } from '@bridgit/foundation';
import { PERM_WRITE, PERM_PRIVATE, PERM_ACCOUNT } from 'src/features/permissions/utils/constants';
import { parseNotes } from 'src/utils/noteUtils';
import { MAX_NOTE_LENGTH, MAX_NOTES, PRIVATE_MODE_TOOLTIP_TEXT } from '../../common/constants';
import { addProjectNote, updateProjectNote, deleteProjectNote } from './redux/actions';
import { isAuthorized } from '../permissions/utils/permissionUtils';
import { getUsers } from '../accounts/redux/actions';
import { formatMentionDisplay } from '../../utils/mentionUtils';

export const ProjectNotesContainer = ({
  headerLabel,
  className,
  projectId,
  projectName,
  projectType,
  projectStatus,
  projectNotes,
}) => {
  const dispatch = useDispatch();

  const commonAnalyticsPayload = useMemo(() => ({
    projectName,
    projectId,
    projectType,
    projectStatus,
  }), [projectId, projectName, projectStatus, projectType]);

  const {
    accountId,
    userInfo,
    addProjectNotePending,
    updateProjectNotePending,
    privateModeEnabled,
  } = useSelector(({ common, login, projects }) => {
    const { accountId, privateModeEnabled } = common;
    const { userInfo } = login;
    const {
      addProjectNotePending,
      updateProjectNotePending,
    } = projects;

    return {
      accountId,
      userInfo,
      addProjectNotePending,
      updateProjectNotePending,
      privateModeEnabled,
    };
  });

  const { users, getUsersPending } = useSelector(({ accounts }) => accounts);

  useEffect(() => {
    dispatch(getUsers(accountId));
  }, [accountId, dispatch]);

  const hasPrivateNotePerms = useMemo(() => (
    isAuthorized(accountId, userInfo.permissions, PERM_WRITE, PERM_PRIVATE)
  ), [accountId, userInfo.permissions]);

  const hasAccountPerms = useMemo(() => (
    isAuthorized(accountId, userInfo.permissions, PERM_WRITE, PERM_ACCOUNT)
  ), [accountId, userInfo.permissions]);

  const parsedNotes = useMemo(() => (
    parseNotes(projectNotes, parseInt(userInfo.sub, 10), hasAccountPerms)
  ), [hasAccountPerms, projectNotes, userInfo.sub]);

  const onCreate = useCallback((data) => {
    dispatch(addProjectNote(accountId, projectId, commonAnalyticsPayload, data));
  }, [dispatch, accountId, projectId, commonAnalyticsPayload]);

  const onUpdate = useCallback((noteId, data) => {
    dispatch(updateProjectNote(accountId, projectId, noteId, commonAnalyticsPayload, data));
  }, [dispatch, accountId, projectId, commonAnalyticsPayload]);

  const onDelete = useCallback((id, userIsAuthor) => {
    const analyticsPayload = {
      ...commonAnalyticsPayload,
      isOwn: userIsAuthor,
    };
    dispatch(deleteProjectNote(accountId, projectId, id, analyticsPayload));
  }, [commonAnalyticsPayload, dispatch, accountId, projectId]);

  const renderMentionHandler = useCallback(mention => formatMentionDisplay(
    mention,
    users,
    accountId,
    userInfo?.sub,
  ), [accountId, userInfo?.sub, users]);

  if (getUsersPending) return <Loader />;

  return (
    <div className={classNames('projects-project-notes-container', className)}>
      <Notes
        headerLabel={headerLabel}
        notes={parsedNotes}
        onCreate={onCreate}
        onUpdate={onUpdate}
        onDelete={onDelete}
        createPending={addProjectNotePending}
        updatePending={updateProjectNotePending}
        maxLength={MAX_NOTE_LENGTH}
        showPrivacyToggle={hasPrivateNotePerms}
        emptyMessage="There are no notes for this project."
        limit={MAX_NOTES}
        showEditor
        privateModeEnabled={privateModeEnabled}
        privacyLabel="Hidden note"
        privacyTooltip={PRIVATE_MODE_TOOLTIP_TEXT}
        renderMention={renderMentionHandler}
      />
    </div>
  );
};

ProjectNotesContainer.propTypes = {
  headerLabel: PropTypes.string,
  className: PropTypes.string,
  projectId: PropTypes.number.isRequired,
  projectName: PropTypes.string.isRequired,
  projectType: PropTypes.string.isRequired,
  projectStatus: PropTypes.string.isRequired,
  projectNotes: PropTypes.array,
};

ProjectNotesContainer.defaultProps = {
  headerLabel: '',
  className: '',
  projectNotes: [],
};

export default ProjectNotesContainer;
