import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { PERM_WRITE, PERM_PERSON } from 'src/features/permissions/utils/constants';
import { trimUnavailabilities } from './utils/unavailabilityUtils';
import { ManageUnavailability, UnavailabilityRow } from '.';
import { Can } from '../wrapped-components';

export const PeriodsOfUnavailability = ({
  person,
  runsInModal,
}) => {
  const { employmentDates, unavailabilities } = person;
  const [modalOpen, setModalOpen] = useState(false);
  const [activePou, setActivePou] = useState(null);

  const filteredUnavailabilities = useMemo(() => trimUnavailabilities(employmentDates, unavailabilities || []), [employmentDates, unavailabilities]);

  const onCloseModal = () => {
    setModalOpen(false);
    setActivePou(null);
  };

  useEffect(() => {
    onCloseModal();
  }, [person]);

  const onAddClick = () => setModalOpen(true);

  const onEdit = (id) => {
    setActivePou(unavailabilities.find(pou => pou.id === id));
    setModalOpen(true);
  };

  return (
    <div className="people-periods-of-unavailability">
      <div className="pou-table">
        <Can
          action={PERM_WRITE}
          subject={PERM_PERSON}
          yes={(
            <Button
              color="primary"
              variant="contained"
              onClick={onAddClick}
            >
              Add Time Off
            </Button>
        )}
        />

        {filteredUnavailabilities.length > 0 && (
          <>
            <div className="title">Time Off</div>
            <div className="table-header">
              <div className="table-row">
                <div>Description</div>
                <div>Dates</div>
                <div>Requires Backfill</div>
              </div>
            </div>
            {filteredUnavailabilities.map(pou => (
              <UnavailabilityRow
                key={pou.id}
                unavailability={pou}
                onEdit={onEdit}
              />
            ))}
          </>
        )}
      </div>
      { modalOpen && (
        <ManageUnavailability
          person={person}
          onCloseModal={onCloseModal}
          activePou={activePou}
          pous={filteredUnavailabilities}
          runsInModal={runsInModal}
        />
      )}
    </div>
  );
};

PeriodsOfUnavailability.propTypes = {
  person: PropTypes.object.isRequired,
  runsInModal: PropTypes.bool,
};

PeriodsOfUnavailability.defaultProps = {
  runsInModal: false,
};

export default PeriodsOfUnavailability;
