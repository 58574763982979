export const SETTINGS_INVITE_ADMIN_BEGIN = 'SETTINGS_INVITE_ADMIN_BEGIN';
export const SETTINGS_INVITE_ADMIN_SUCCESS = 'SETTINGS_INVITE_ADMIN_SUCCESS';
export const SETTINGS_INVITE_ADMIN_FAILURE = 'SETTINGS_INVITE_ADMIN_FAILURE';
export const SETTINGS_INVITE_ADMIN_DISMISS_ERROR = 'SETTINGS_INVITE_ADMIN_DISMISS_ERROR';
export const SETTINGS_REMOVE_ADMIN_BEGIN = 'SETTINGS_REMOVE_ADMIN_BEGIN';
export const SETTINGS_REMOVE_ADMIN_SUCCESS = 'SETTINGS_REMOVE_ADMIN_SUCCESS';
export const SETTINGS_REMOVE_ADMIN_FAILURE = 'SETTINGS_REMOVE_ADMIN_FAILURE';
export const SETTINGS_REMOVE_ADMIN_DISMISS_ERROR = 'SETTINGS_REMOVE_ADMIN_DISMISS_ERROR';
export const SETTINGS_GET_ADMINS_BEGIN = 'SETTINGS_GET_ADMINS_BEGIN';
export const SETTINGS_GET_ADMINS_SUCCESS = 'SETTINGS_GET_ADMINS_SUCCESS';
export const SETTINGS_GET_ADMINS_FAILURE = 'SETTINGS_GET_ADMINS_FAILURE';
export const SETTINGS_GET_ADMINS_DISMISS_ERROR = 'SETTINGS_GET_ADMINS_DISMISS_ERROR';
export const SETTINGS_UPDATE_ADMIN_BEGIN = 'SETTINGS_UPDATE_ADMIN_BEGIN';
export const SETTINGS_UPDATE_ADMIN_SUCCESS = 'SETTINGS_UPDATE_ADMIN_SUCCESS';
export const SETTINGS_UPDATE_ADMIN_FAILURE = 'SETTINGS_UPDATE_ADMIN_FAILURE';
export const SETTINGS_UPDATE_ADMIN_DISMISS_ERROR = 'SETTINGS_UPDATE_ADMIN_DISMISS_ERROR';
export const SETTINGS_REMOVE_ADMIN_INVITATION_BEGIN = 'SETTINGS_REMOVE_ADMIN_INVITATION_BEGIN';
export const SETTINGS_REMOVE_ADMIN_INVITATION_SUCCESS = 'SETTINGS_REMOVE_ADMIN_INVITATION_SUCCESS';
export const SETTINGS_REMOVE_ADMIN_INVITATION_FAILURE = 'SETTINGS_REMOVE_ADMIN_INVITATION_FAILURE';
export const SETTINGS_REMOVE_ADMIN_INVITATION_DISMISS_ERROR = 'SETTINGS_REMOVE_ADMIN_INVITATION_DISMISS_ERROR';
export const SETTINGS_GET_ADMIN_INVITATIONS_BEGIN = 'SETTINGS_GET_ADMIN_INVITATIONS_BEGIN';
export const SETTINGS_GET_ADMIN_INVITATIONS_SUCCESS = 'SETTINGS_GET_ADMIN_INVITATIONS_SUCCESS';
export const SETTINGS_GET_ADMIN_INVITATIONS_FAILURE = 'SETTINGS_GET_ADMIN_INVITATIONS_FAILURE';
export const SETTINGS_GET_ADMIN_INVITATIONS_DISMISS_ERROR = 'SETTINGS_GET_ADMIN_INVITATIONS_DISMISS_ERROR';
export const SETTINGS_UPDATE_USER_MEMBERSHIP_BEGIN = 'SETTINGS_UPDATE_USER_MEMBERSHIP_BEGIN';
export const SETTINGS_UPDATE_USER_MEMBERSHIP_SUCCESS = 'SETTINGS_UPDATE_USER_MEMBERSHIP_SUCCESS';
export const SETTINGS_UPDATE_USER_MEMBERSHIP_FAILURE = 'SETTINGS_UPDATE_USER_MEMBERSHIP_FAILURE';
export const SETTINGS_UPDATE_USER_MEMBERSHIP_DISMISS_ERROR = 'SETTINGS_UPDATE_USER_MEMBERSHIP_DISMISS_ERROR';
