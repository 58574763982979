import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, NavigateBack } from '@bridgit/foundation';
import {
  Divider,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from '@material-ui/core';
import pluralize from 'pluralize';

import { EditButton } from '../wrapped-components';
import DeleteCertificationModal from './DeleteCertificationModal';

const CertificationDetails = ({ certification, onClose, onEdit }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { name, daysWarnBeforeExpire, requireExpiration, id } = certification;

  const toggleModal = useCallback(open => () => setIsModalOpen(open), []);

  return (
    <div className="account-settings-certification-details">
      <div className="options-wrapper">
        <NavigateBack onClick={onClose} edge="start" label="Back to certifications list" />
        <EditButton tooltipTitle="Edit certification" onClick={onEdit} />
      </div>

      <div className="certification-info-wrapper">
        <span className="certification-name">{name}</span>
        <Divider className="divider" />
        <FormGroup>
          <FormControlLabel
            label={<span className="form-text">Expiration date required</span>}
            control={(
              <Checkbox
                disabled
                className="checkbox"
                name="requireExpiration"
                checked={requireExpiration}
              />
            )}
          />
        </FormGroup>
        {daysWarnBeforeExpire && (
          <span className="form-text">
            {`Warning ${pluralize('day', daysWarnBeforeExpire, true)} before expiration`}
          </span>
        )}
      </div>

      <div>
        <Divider className="divider" />
        <Button onClick={toggleModal(true)} color="warning" variant="plain">Delete Certification</Button>
      </div>

      <DeleteCertificationModal
        open={isModalOpen}
        onClose={toggleModal(false)}
        certificationId={id}
      />
    </div>
  );
};

CertificationDetails.propTypes = {
  certification: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

CertificationDetails.defaultProps = {
  certification: {},
};

export default CertificationDetails;
