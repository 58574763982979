import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNTS_GET_USERS_BEGIN,
  ACCOUNTS_GET_USERS_SUCCESS,
  ACCOUNTS_GET_USERS_FAILURE,
  ACCOUNTS_GET_USERS_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';

export function getUsers(accountId, params) {
  // If need to pass args to saga, pass it with the begin action.
  return {
    type: ACCOUNTS_GET_USERS_BEGIN,
    accountId,
    ...params,
  };
}

export function dismissGetUsersError() {
  return {
    type: ACCOUNTS_GET_USERS_DISMISS_ERROR,
  };
}

// worker Saga: will be fired on ACCOUNTS_GET_USERS_BEGIN actions
export function* doGetUsers({ accountId, group }) {
  // If necessary, use argument to receive the begin action with parameters.
  let res;
  try {
    res = yield call(protectedJsonGet, `accounts/${accountId}/users${group ? `?group=${group}` : ''}`);
  } catch (err) {
    yield put({
      type: ACCOUNTS_GET_USERS_FAILURE,
      data: { error: err },
    });
    return;
  }
  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: ACCOUNTS_GET_USERS_SUCCESS,
    data: {
      id: accountId,
      users: res,
    },
  });
}

/*
  Alternatively you may use takeEvery.
q
  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchGetUsers() {
  yield takeLatest(ACCOUNTS_GET_USERS_BEGIN, doGetUsers);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNTS_GET_USERS_BEGIN:
      return {
        ...state,
        getUsersPending: true,
        getUsersError: null,
      };

    case ACCOUNTS_GET_USERS_SUCCESS: {
      return {
        ...state,
        users: {
          ...state.users,
          [action.data.id]: action.data.users,
        },
        getUsersPending: false,
        getUsersError: null,
      };
    }

    case ACCOUNTS_GET_USERS_FAILURE:
      return {
        ...state,
        getUsersPending: false,
        getUsersError: action.data.error,
      };

    case ACCOUNTS_GET_USERS_DISMISS_ERROR:
      return {
        ...state,
        getUsersError: null,
      };

    default:
      return state;
  }
}
