import {
  SERVICE_ACCOUNTS_ADD_SERVICE_ACCOUNT_SUCCESS,
  SERVICE_ACCOUNTS_COPY_SERVICE_ACCOUNT_ID,
  SERVICE_ACCOUNTS_COPY_SERVICE_ACCOUNT_PASSWORD,
  SERVICE_ACCOUNTS_DELETE_SERVICE_ACCOUNT_SUCCESS,
  SERVICE_ACCOUNTS_RESET_SERVICE_ACCOUNT_PASSWORD_SUCCESS,
  SERVICE_ACCOUNTS_SELECT_SERVICE_ACCOUNT,
} from '../../features/service-accounts/redux/constants';
import {
  ADD_SERVICE_ACCOUNT, COPY_SERVICE_ACCOUNT_ID,
  COPY_SERVICE_ACCOUNT_PASSWORD,
  DELETE_SERVICE_ACCOUNT,
  NAVIGATE_TO_SERVICE_ACCOUNT_DETAILS,
  RESET_SERVICE_ACCOUNT_PASSWORD,
} from './constants';

const getServiceAccountsPayload = (state, { analyticsPayload }) => {
  const { name } = analyticsPayload;

  return {
    'Service account name': name,
  };
};

const events = {
  [SERVICE_ACCOUNTS_ADD_SERVICE_ACCOUNT_SUCCESS]: {
    getEvent: () => ADD_SERVICE_ACCOUNT,
    getPayload: getServiceAccountsPayload,
  },

  [SERVICE_ACCOUNTS_RESET_SERVICE_ACCOUNT_PASSWORD_SUCCESS]: {
    getEvent: () => RESET_SERVICE_ACCOUNT_PASSWORD,
    getPayload: getServiceAccountsPayload,
  },

  [SERVICE_ACCOUNTS_DELETE_SERVICE_ACCOUNT_SUCCESS]: {
    getEvent: () => DELETE_SERVICE_ACCOUNT,
    getPayload: getServiceAccountsPayload,
  },

  [SERVICE_ACCOUNTS_SELECT_SERVICE_ACCOUNT]: {
    getEvent: () => NAVIGATE_TO_SERVICE_ACCOUNT_DETAILS,
    getPayload: getServiceAccountsPayload,
  },

  [SERVICE_ACCOUNTS_COPY_SERVICE_ACCOUNT_ID]: {
    getEvent: () => COPY_SERVICE_ACCOUNT_ID,
    getPayload: getServiceAccountsPayload,
  },

  [SERVICE_ACCOUNTS_COPY_SERVICE_ACCOUNT_PASSWORD]: {
    getEvent: () => COPY_SERVICE_ACCOUNT_PASSWORD,
    getPayload: getServiceAccountsPayload,
  },
};

export default events;
