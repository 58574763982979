import { call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import {
  SELF_PERFORM_SEND_NOTIFICATION_BEGIN,
  SELF_PERFORM_SEND_NOTIFICATION_SUCCESS,
  SELF_PERFORM_NOTIFICATION_SENT_SUCCESS,
  SELF_PERFORM_SEND_NOTIFICATION_FAILURE,
  SELF_PERFORM_SEND_NOTIFICATION_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';
import { ASSIGNED_NOTIFIED } from '../constants';

export function sendNotification(accountId, request, data, mixpanelData) {
  return {
    type: SELF_PERFORM_SEND_NOTIFICATION_BEGIN,
    accountId,
    request,
    data,
    mixpanelData,
  };
}

export function dismissSendNotificationError() {
  return {
    type: SELF_PERFORM_SEND_NOTIFICATION_DISMISS_ERROR,
  };
}

export function* doSendNotification(action) {
  const { accountId, request, data, mixpanelData } = action;
  const { project, id: requestId } = request;
  const { id: projectId } = project;
  const { message } = data;

  try {
    yield call(protectedJsonPost, `accounts/${accountId}/projects/${projectId}/hourly-requests/${requestId}/assignment`, data);
  } catch (err) {
    yield put({
      type: SELF_PERFORM_SEND_NOTIFICATION_FAILURE,
      data: { error: err },
    });
    return;
  }

  // dispatch for mixpanel middleware
  if (message !== null) {
    yield put({
      type: SELF_PERFORM_NOTIFICATION_SENT_SUCCESS,
      requestId,
      projectId,
      personId: data?.assigneeId,
      message,
      mixpanelData,
    });
  }

  yield put({
    type: SELF_PERFORM_SEND_NOTIFICATION_SUCCESS,
    requestId,
  });
}

export function* watchSendNotification() {
  yield takeLatest(SELF_PERFORM_SEND_NOTIFICATION_BEGIN, doSendNotification);
}

export function reducer(state, action) {
  switch (action.type) {
    case SELF_PERFORM_SEND_NOTIFICATION_BEGIN:
      return {
        ...state,
        sendNotificationPending: true,
        sendNotificationError: null,
      };

    case SELF_PERFORM_SEND_NOTIFICATION_SUCCESS: {
      const { requestId } = action;

      const filteredRequests = state.filteredRequests.map((request) => {
        if (request.id === requestId) {
          return {
            ...request,
            state: ASSIGNED_NOTIFIED,
            lastNotifiedOn: moment(),
          };
        }
        return request;
      });

      const selectedRequest = {
        ...state.selectedRequest,
        state: ASSIGNED_NOTIFIED,
        lastNotifiedOn: moment(),
      };

      return {
        ...state,
        filteredRequests,
        selectedRequest,
        sendNotificationPending: false,
        sendNotificationError: null,
      };
    }

    case SELF_PERFORM_SEND_NOTIFICATION_FAILURE:
      return {
        ...state,
        sendNotificationPending: false,
        sendNotificationError: action.data.error,
      };

    case SELF_PERFORM_SEND_NOTIFICATION_DISMISS_ERROR:
      return {
        ...state,
        sendNotificationError: null,
      };

    default:
      return state;
  }
}
