import initialState from './initialState';
import { reducer as setEditorConfigReducer } from './setEditorConfig';
import { reducer as setEditorSegmentsReducer } from './setEditorSegments';
import { reducer as setSegmentSelectedReducer } from './setSegmentSelected';
import { reducer as resetEditorStateReducer } from './resetEditorState';

const reducers = [
  setEditorConfigReducer,
  setEditorSegmentsReducer,
  setSegmentSelectedReducer,
  resetEditorStateReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
