import {
  COMMON_SET_PRIVATE_MODE,
} from './constants';

export function setPrivateMode(privateModeEnabled = true) {
  return {
    type: COMMON_SET_PRIVATE_MODE,
    privateModeEnabled,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_SET_PRIVATE_MODE: {
      const { privateModeEnabled } = action;
      return {
        ...state,
        privateModeEnabled,
      };
    }

    default:
      return state;
  }
}
