import React, { useRef, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import classnames from 'classnames';

import {
  generateRequestColumns,
  getRequestRowData,
} from 'src/features/self-perform/utils/requestUtils';
import {
  SELF_PERFORM_TAB_MAP,
  ASSIGNMENTS_TAB,
  REQUESTS_TAB,
} from 'src/common/constants';
import {
  FILTERED_REQUESTS_QUERY_ID,
  FILTERED_ASSIGNMENTS_QUERY_ID,
} from 'src/features/queries/redux/constants';
import { REQUEST_TABLE_CONFIG } from 'src/common/descriptors/request';
import { ASSIGNMENT_TABLE_CONFIG } from 'src/common/descriptors/assignment';
import { DEFAULT_REQUEST_SORT, DEFAULT_ASSIGNMENT_SORT } from 'src/features/self-perform/redux/constants';
import { LocalStorageKeys } from 'src/common/localStorageKeys';
import { FilteredTable } from 'src/features/table';
import { FilterManager } from 'src/features/filters';
import { getFilteredRequests, getRequestById } from 'src/features/self-perform/redux/actions';
import { setColumns } from 'src/features/table/redux/actions';
import { openModal, closeModal } from 'src/features/modal-manager/redux/actions';
import { AUTOMATION_EMPTY_REQUESTS_PAGE_TEXT } from './ids';
import { RequestDetails, AddRequestModal } from '.';
import { PERM_REQUEST, PERM_WRITE } from '../permissions/utils/constants';
import { Can } from '../wrapped-components';
import { ADD_REQUEST_MODAL } from './constants';
import useRememberedFilters from '../filters/utils/useRememberedFilters';

export function RequestList({
  className,
  accountId,
  userId,
  contentView,
  isAssignmentTable,
  queries,
  filteredRequests,
  selectedRowId,
  requestCount,
  pageFrom,
  hasMore,
  visibleColumns,
  table,
  tableConfig,
  queryId,
  getFilteredRequestsPending,
  getFilteredRequestsPagePending,
  getFilteredRequestsError,
  getFilteredRequestPageError,
  getFilteredRequests,
  getRequestById,
  setColumns,
  openModal,
  closeModal,
  currentView,
  isModalOpen,
  addHourlyRequestPending,
  activeView,
}) {
  const isLoading = useRef(false);
  const getFilteredItemsData = useMemo(() => ({ isAssignmentTable }), [isAssignmentTable]);

  useEffect(() => {
    localStorage.setItem(LocalStorageKeys.selfPerformView, contentView);
  }, [contentView]);

  useEffect(() => {
    if (isLoading.current && !getFilteredRequestsPending) {
      const { newVisibleColumns, newVisibleColumnNames } = generateRequestColumns(table, isAssignmentTable);
      setColumns(newVisibleColumns, newVisibleColumnNames);
    }
    isLoading.current = getFilteredRequestsPending;
  }, [table, setColumns, getFilteredRequestsPending, isAssignmentTable]);

  useEffect(() => {
    if (!addHourlyRequestPending) closeModal(ADD_REQUEST_MODAL);
  }, [closeModal, addHourlyRequestPending]);

  useRememberedFilters({ accountId, userId, viewId: activeView });

  const data = useMemo(() => getRequestRowData(filteredRequests, visibleColumns, isAssignmentTable),
    [filteredRequests, isAssignmentTable, visibleColumns]);

  const columns = useMemo(() => (selectedRowId ? visibleColumns.slice(0, 2) : visibleColumns),
    [selectedRowId, visibleColumns]);

  const loadMore = useCallback(() => {
    if (hasMore && !getFilteredRequestsPagePending) {
      getFilteredRequests(accountId, queries, pageFrom, getFilteredItemsData);
    }
  }, [
    hasMore,
    getFilteredRequestsPagePending,
    accountId,
    queries,
    pageFrom,
    getFilteredRequests,
    getFilteredItemsData,
  ]);

  const onRowClick = useCallback((rowId, rowMeta) => {
    const { id, project, state, assignee } = rowMeta.rawData;
    const { id: projectId, name: projectName } = project;

    const mixpanelPayload = {
      'Request id': id,
      'Project name': projectName,
      'Project id': projectId,
      Status: state,
    };

    if (assignee) {
      window.mixpanel.track('View Assignment Details', {
        ...mixpanelPayload,
        'Assignee name': assignee.name,
        'Assignee id': assignee.id,
        'Opened from': 'Assignment List',
      });
    } else {
      window.mixpanel.track('View Request Details', {
        ...mixpanelPayload,
        'Opened from': 'Request List',
      });
    }

    getRequestById(accountId, id);
  }, [accountId, getRequestById]);

  const openAddRequestModal = useCallback(() => openModal(ADD_REQUEST_MODAL), [openModal]);

  const addRequestButton = useMemo(() => (
    <Button
      className="add-request-button"
      onClick={openAddRequestModal}
      disableRipple
    >
      Add Request
    </Button>
  ), [openAddRequestModal]);

  const noMatch = isAssignmentTable
    ? 'Sorry, no matching records found'
    : (
      <>
        <p>
          Your request queue is clear
          {' '}
          <span role="img" aria-label="meditate">🧘</span>
        </p>
        <p id={AUTOMATION_EMPTY_REQUESTS_PAGE_TEXT} className="no-match-text">
          Once you’re done enjoying your empty list, you can add a new request using the top left button. You can also head over to your assignments list to check on and optimize your workforce plan.
        </p>
      </>
    );

  return (
    <div className={classnames('self-perform-requests', className)}>
      {currentView === REQUESTS_TAB && (
        <Can
          action={PERM_WRITE}
          subject={PERM_REQUEST}
          yes={addRequestButton}
        />
      )}

      {isModalOpen && <AddRequestModal />}

      <FilterManager
        queries={queries}
        view={activeView}
        defaultSort={currentView === REQUESTS_TAB ? DEFAULT_REQUEST_SORT : DEFAULT_ASSIGNMENT_SORT}
        tableConfig={tableConfig}
        queryId={queryId}
        getFilteredItems={getFilteredRequests}
        getFilteredItemsData={getFilteredItemsData}
        getFilteredItemsError={!!getFilteredRequestsError}
      >
        <div className={classnames(
          'self-perform-request-assignment-list',
          {
            'request-list-collapse': !!selectedRowId,
            'request-list': currentView === REQUESTS_TAB,
          },
        )}
        >
          <FilteredTable
            data={data}
            columns={columns}
            loadMore={loadMore}
            loading={getFilteredRequestsPending}
            loadingPage={getFilteredRequestsPagePending}
            pagingError={!!getFilteredRequestPageError}
            allowColumnReordering
            queryId={queryId}
            resultCount={requestCount}
            resultLabel={SELF_PERFORM_TAB_MAP[contentView]}
            onRowClick={onRowClick}
            selectedRowId={selectedRowId}
            noMatch={noMatch}
          />

          <RequestDetails />

        </div>
      </FilterManager>
    </div>
  );
}

RequestList.propTypes = {
  className: PropTypes.string,
  accountId: PropTypes.number.isRequired,
  userId: PropTypes.string.isRequired,
  contentView: PropTypes.number.isRequired,
  isAssignmentTable: PropTypes.bool.isRequired,
  queries: PropTypes.object,
  filteredRequests: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedRowId: PropTypes.number,
  requestCount: PropTypes.number,
  pageFrom: PropTypes.number,
  hasMore: PropTypes.bool,
  visibleColumns: PropTypes.array.isRequired,
  table: PropTypes.object.isRequired,
  tableConfig: PropTypes.object.isRequired,
  queryId: PropTypes.string.isRequired,
  getFilteredRequestsPending: PropTypes.bool,
  getFilteredRequestsPagePending: PropTypes.bool,
  getFilteredRequestsError: PropTypes.object,
  getFilteredRequestPageError: PropTypes.object,
  getFilteredRequests: PropTypes.func.isRequired,
  getRequestById: PropTypes.func.isRequired,
  setColumns: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  currentView: PropTypes.number.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  addHourlyRequestPending: PropTypes.bool,
  activeView: PropTypes.number.isRequired,
};

RequestList.defaultProps = {
  className: '',
  queries: {},
  selectedRowId: null,
  requestCount: 0,
  pageFrom: 0,
  hasMore: false,
  getFilteredRequestsPending: false,
  getFilteredRequestsPagePending: false,
  getFilteredRequestsError: null,
  getFilteredRequestPageError: null,
  addHourlyRequestPending: false,
};

/* istanbul ignore next */
function mapStateToProps({ common, login, selfPerform, table, queries, modalManager }) {
  const { accountId, contentView, activeView } = common;
  const { sub: userId } = login.userInfo;
  const {
    filteredRequests,
    selectedRequest,
    requestCount,
    pageFrom,
    hasMore,
    getFilteredRequestsPending,
    getFilteredRequestsPagePending,
    getFilteredRequestsError,
    getFilteredRequestPageError,
    addHourlyRequestPending,
  } = selfPerform;
  const { visibleColumns } = table;
  const { activeModal } = modalManager;
  const isAssignmentTable = contentView === ASSIGNMENTS_TAB;
  const selectedRowId = selectedRequest?.id;

  return {
    accountId,
    userId,
    contentView,
    isAssignmentTable,
    queries: isAssignmentTable
      ? queries.filteredAssignments
      : queries.filteredRequests,
    filteredRequests,
    selectedRowId,
    requestCount,
    pageFrom,
    hasMore,
    visibleColumns,
    table,
    tableConfig: isAssignmentTable
      ? ASSIGNMENT_TABLE_CONFIG
      : REQUEST_TABLE_CONFIG,
    queryId: isAssignmentTable
      ? FILTERED_ASSIGNMENTS_QUERY_ID
      : FILTERED_REQUESTS_QUERY_ID,
    getFilteredRequestsPending,
    getFilteredRequestsPagePending,
    getFilteredRequestsError,
    getFilteredRequestPageError,
    isModalOpen: activeModal === ADD_REQUEST_MODAL,
    addHourlyRequestPending,
    activeView,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    getFilteredRequests: bindActionCreators(getFilteredRequests, dispatch),
    getRequestById: bindActionCreators(getRequestById, dispatch),
    setColumns: bindActionCreators(setColumns, dispatch),
    openModal: bindActionCreators(openModal, dispatch),
    closeModal: bindActionCreators(closeModal, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestList);
