import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PROJECTS_GET_PROJECTS_BEGIN,
  PROJECTS_GET_PROJECTS_SUCCESS,
  PROJECTS_GET_PROJECTS_FAILURE,
  PROJECTS_GET_PROJECTS_DISMISS_ERROR,
  MAX_PROJECTS,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';
import { setStartDateTimeStamp } from '../utils/projectUtils';

export function getProjects(accountId) {
  return {
    type: PROJECTS_GET_PROJECTS_BEGIN,
    accountId,
  };
}

export function dismissGetProjectsError() {
  return {
    type: PROJECTS_GET_PROJECTS_DISMISS_ERROR,
  };
}

export function* doGetProjects(action) {
  const { accountId } = action;
  let res;
  try {
    res = yield call(protectedJsonGet, `accounts/${accountId}/projects?limit=${MAX_PROJECTS}`);
  } catch (err) {
    yield put({
      type: PROJECTS_GET_PROJECTS_FAILURE,
      data: { error: err },
    });
    return;
  }

  const result = setStartDateTimeStamp(res);
  yield put({
    type: PROJECTS_GET_PROJECTS_SUCCESS,
    data: result,
  });
}

export function* watchGetProjects() {
  yield takeLatest(PROJECTS_GET_PROJECTS_BEGIN, doGetProjects);
}

export function reducer(state, action) {
  switch (action.type) {
    case PROJECTS_GET_PROJECTS_BEGIN:
      return {
        ...state,
        getProjectsPending: true,
        getProjectsError: null,
      };

    case PROJECTS_GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.data,
        getProjectsPending: false,
        getProjectsError: null,
      };

    case PROJECTS_GET_PROJECTS_FAILURE:
      return {
        ...state,
        getProjectsPending: false,
        getProjectsError: action.data.error,
      };

    case PROJECTS_GET_PROJECTS_DISMISS_ERROR:
      return {
        ...state,
        getProjectsError: null,
      };

    default:
      return state;
  }
}
