import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PEOPLE_GET_PERSON_ISSUES_BEGIN,
  PEOPLE_GET_PERSON_ISSUES_SUCCESS,
  PEOPLE_GET_PERSON_ISSUES_FAILURE,
  PEOPLE_GET_PERSON_ISSUES_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';

export function getPersonIssues(accountId, personId) {
  // If need to pass args to saga, pass it with the begin action.
  return {
    type: PEOPLE_GET_PERSON_ISSUES_BEGIN,
    accountId,
    personId,
  };
}

export function dismissGetPersonIssuesError() {
  return {
    type: PEOPLE_GET_PERSON_ISSUES_DISMISS_ERROR,
  };
}

// worker Saga: will be fired on PEOPLE_GET_PERSON_ISSUES_BEGIN actions
export function* doGetPersonIssues(action) {
  const { accountId, personId } = action;
  let res;
  try {
    res = yield call(protectedJsonGet, `accounts/${accountId}/persons/${personId}/allocation-conflicts`);
  } catch (err) {
    yield put({
      type: PEOPLE_GET_PERSON_ISSUES_FAILURE,
      data: { error: err },
    });
    return;
  }
  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: PEOPLE_GET_PERSON_ISSUES_SUCCESS,
    data: res,
    personId,
  });
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchGetPersonIssues() {
  yield takeLatest(PEOPLE_GET_PERSON_ISSUES_BEGIN, doGetPersonIssues);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_GET_PERSON_ISSUES_BEGIN:
      return {
        ...state,
        getPersonIssuesPending: true,
        getPersonIssuesError: null,
      };

    case PEOPLE_GET_PERSON_ISSUES_SUCCESS:
      return {
        ...state,
        issues: {
          ...state.issues,
          [action.personId]: action.data,
        },
        getPersonIssuesPending: false,
        getPersonIssuesError: null,
      };

    case PEOPLE_GET_PERSON_ISSUES_FAILURE:
      return {
        ...state,
        getPersonIssuesPending: false,
        getPersonIssuesError: action.data.error,
      };

    case PEOPLE_GET_PERSON_ISSUES_DISMISS_ERROR:
      return {
        ...state,
        getPersonIssuesError: null,
      };

    default:
      return state;
  }
}
