import { call, put, takeLatest } from 'redux-saga/effects';
import {
  SELF_PERFORM_DELETE_REQUESTER_BEGIN,
  SELF_PERFORM_DELETE_REQUESTER_SUCCESS,
  SELF_PERFORM_DELETE_REQUESTER_FAILURE,
  SELF_PERFORM_DELETE_REQUESTER_DISMISS_ERROR,
} from './constants';
import { protectedJsonDelete } from '../../../utils/api';

export function deleteRequester(accountId, projectId, requesterIds, projectName) {
  return {
    type: SELF_PERFORM_DELETE_REQUESTER_BEGIN,
    accountId,
    projectId,
    requesterIds,
    projectName,
  };
}

export function dismissDeleteRequesterError() {
  return {
    type: SELF_PERFORM_DELETE_REQUESTER_DISMISS_ERROR,
  };
}

export function* doDeleteRequester(action) {
  const { accountId, projectId, requesterIds, projectName } = action;

  let res;
  try {
    res = yield call(protectedJsonDelete, `accounts/${accountId}/projects/${projectId}/users`, requesterIds);
  } catch (err) {
    yield put({
      type: SELF_PERFORM_DELETE_REQUESTER_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: SELF_PERFORM_DELETE_REQUESTER_SUCCESS,
    data: res,
    deletedRequesterIds: requesterIds,
    projectId,
    projectName,
  });
}

export function* watchDeleteRequester() {
  yield takeLatest(SELF_PERFORM_DELETE_REQUESTER_BEGIN, doDeleteRequester);
}

export function reducer(state, action) {
  switch (action.type) {
    case SELF_PERFORM_DELETE_REQUESTER_BEGIN:
      return {
        ...state,
        deleteRequesterPending: true,
        deleteRequesterError: null,
      };

    case SELF_PERFORM_DELETE_REQUESTER_SUCCESS: {
      const { deletedRequesterIds } = action;

      const updatedRequesters = state.projectRequesters.filter(({ id }) => !deletedRequesterIds.includes(id));

      return {
        ...state,
        projectRequesters: updatedRequesters,
        deleteRequesterPending: false,
        deleteRequesterError: null,
      };
    }

    case SELF_PERFORM_DELETE_REQUESTER_FAILURE:
      return {
        ...state,
        deleteRequesterPending: false,
        deleteRequesterError: action.data.error,
      };

    case SELF_PERFORM_DELETE_REQUESTER_DISMISS_ERROR:
      return {
        ...state,
        deleteRequesterError: null,
      };

    default:
      return state;
  }
}
