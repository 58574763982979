import {
  PROJECTS_UPDATE_PROJECT_SUCCESS,
  PROJECTS_UPDATE_PROJECT_ROLE_ALLOCATION_SUCCESS,
  PROJECTS_DELETE_PROJECT_ALLOCATION_SUCCESS,
  PROJECTS_DELETE_PROJECT_ROLE_SUCCESS,
} from 'src/features/projects/redux/constants';
import {
  updateProjectForPeople,
  updateAllocationDatesForPeople,
  removeAllocationForPeople,
  removeRoleForPeople,
} from 'src/common/crossFeatureUtils';

import initialState from './initialState';
import { reducer as getPeopleReducer } from './getPeople';
import { reducer as removePersonReducer } from './removePerson';
import { reducer as addPersonReducer } from './addPerson';
import { reducer as getPersonFieldValuesReducer } from './getPersonFieldValues';
import { reducer as getPersonAvailabilitiesReducer } from './getPersonAvailabilities';
import { reducer as getPersonProjectsReducer } from './getPersonProjects';
import { reducer as getPersonAllocationsByDateReducer } from './getPersonAllocationsByDate';
import { reducer as getPersonCurrentAllocationsReducer } from './getPersonCurrentAllocations';
import { reducer as getPersonPastAllocationsReducer } from './getPersonPastAllocations';
import { reducer as getPersonIssuesReducer } from './getPersonIssues';
import { reducer as getAccountAllocationsReducer } from './getAccountAllocations';
import { reducer as getUploadUrlReducer } from './getUploadUrl';
import { reducer as setProfilePictureReducer } from './setProfilePicture';
import { reducer as removeProfilePictureReducer } from './removeProfilePicture';
import { reducer as updatePersonReducer } from './updatePerson';
import { reducer as getFilteredPeopleReducer } from './getFilteredPeople';
import { reducer as reactivatePersonReducer } from './reactivatePerson';
import { reducer as collectPeopleTableDataReducer } from './collectPeopleTableData';
import { reducer as updatePersonFieldValueReducer } from './updatePersonFieldValue';
import { reducer as addUnavailabilityReducer } from './addUnavailability';
import { reducer as updateUnavailabilityReducer } from './updateUnavailability';
import { reducer as deleteUnavailabilityReducer } from './deleteUnavailability';
import { reducer as getPersonUnavailabilitiesReducer } from './getPersonUnavailabilities';
import { reducer as getPersonByIdReducer } from './getPersonById';
import { reducer as clearPersonSelectionReducer } from './clearPersonSelection';
import { reducer as clearAllPersonSelectionsReducer } from './clearAllPersonSelections';
import { reducer as getDeactivatedPeopleReducer } from './getDeactivatedPeople';
import { reducer as searchActivePeopleReducer } from './searchActivePeople';
import { reducer as clearSearchedActivePeopleReducer } from './clearSearchedActivePeople';
import { reducer as getPersonAllocationsReducer } from './getPersonAllocations';
import { reducer as getPersonUpcomingAllocationsReducer } from './getPersonUpcomingAllocations';
import { reducer as addPersonNoteReducer } from './addPersonNote';
import { reducer as deletePersonNoteReducer } from './deletePersonNote';
import { reducer as updatePersonNoteReducer } from './updatePersonNote';
import { reducer as getPersonAuditReducer } from './getPersonAudit';
import { reducer as uploadPersonAttachmentReducer } from './uploadPersonAttachment';
import { reducer as getPersonAttachmentsReducer } from './getPersonAttachments';
import { reducer as getPersonAttachmentByIdReducer } from './getPersonAttachmentById';
import { reducer as downloadPersonAttachmentReducer } from './downloadPersonAttachment';
import { reducer as deletePersonAttachmentReducer } from './deletePersonAttachment';
import { reducer as getPersonCertificationsReducer } from './getPersonCertifications';
import { reducer as getPersonCertificationAttachmentsReducer } from './getPersonCertificationAttachments';
import { reducer as clearPersonCertificationsReducer } from './clearPersonCertifications';
import { reducer as addPersonCertificationReducer } from './addPersonCertification';
import { reducer as updatePersonCertificationReducer } from './updatePersonCertification';
import { reducer as deletePersonCertificationReducer } from './deletePersonCertification';
import { reducer as toggleCertificationAttachmentsReducer } from './toggleCertificationAttachments';

const reducers = [
  getPeopleReducer,
  removePersonReducer,
  addPersonReducer,
  getPersonFieldValuesReducer,
  getPersonAvailabilitiesReducer,
  getPersonProjectsReducer,
  getPersonAllocationsByDateReducer,
  getPersonCurrentAllocationsReducer,
  getPersonPastAllocationsReducer,
  getPersonIssuesReducer,
  getAccountAllocationsReducer,
  getUploadUrlReducer,
  setProfilePictureReducer,
  removeProfilePictureReducer,
  updatePersonReducer,
  getFilteredPeopleReducer,
  reactivatePersonReducer,
  collectPeopleTableDataReducer,
  updatePersonFieldValueReducer,
  addUnavailabilityReducer,
  updateUnavailabilityReducer,
  deleteUnavailabilityReducer,
  getPersonUnavailabilitiesReducer,
  getPersonByIdReducer,
  clearPersonSelectionReducer,
  clearAllPersonSelectionsReducer,
  getDeactivatedPeopleReducer,
  searchActivePeopleReducer,
  clearSearchedActivePeopleReducer,
  getPersonAllocationsReducer,
  getPersonUpcomingAllocationsReducer,
  addPersonNoteReducer,
  deletePersonNoteReducer,
  updatePersonNoteReducer,
  getPersonAuditReducer,
  uploadPersonAttachmentReducer,
  getPersonAttachmentsReducer,
  getPersonAttachmentByIdReducer,
  downloadPersonAttachmentReducer,
  deletePersonAttachmentReducer,
  getPersonCertificationsReducer,
  getPersonCertificationAttachmentsReducer,
  clearPersonCertificationsReducer,
  addPersonCertificationReducer,
  updatePersonCertificationReducer,
  deletePersonCertificationReducer,
  toggleCertificationAttachmentsReducer,
];

export default function reducer(state = initialState, action) {
  const { filteredPeople } = state;
  let newState;
  switch (action.type) {
    case PROJECTS_UPDATE_PROJECT_SUCCESS: {
      newState = {
        ...state,
        filteredPeople: updateProjectForPeople(filteredPeople, action),
      };
      break;
    }
    case PROJECTS_UPDATE_PROJECT_ROLE_ALLOCATION_SUCCESS: {
      newState = {
        ...state,
        filteredPeople: updateAllocationDatesForPeople(filteredPeople, action),
      };
      break;
    }
    case PROJECTS_DELETE_PROJECT_ALLOCATION_SUCCESS: {
      newState = {
        ...state,
        filteredPeople: removeAllocationForPeople(filteredPeople, action),
      };
      break;
    }
    case PROJECTS_DELETE_PROJECT_ROLE_SUCCESS: {
      newState = {
        ...state,
        filteredPeople: removeRoleForPeople(filteredPeople, action),
      };
      break;
    }
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
