import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNTS_DELETE_ROLE_TEMPLATE_BEGIN,
  ACCOUNTS_DELETE_ROLE_TEMPLATE_SUCCESS,
  ACCOUNTS_DELETE_ROLE_TEMPLATE_FAILURE,
  ACCOUNTS_DELETE_ROLE_TEMPLATE_DISMISS_ERROR,
} from './constants';
import { protectedJsonDelete } from '../../../utils/api';

export function deleteRoleTemplate(accountId, templateId, analyticsPayload = { }) {
  return {
    type: ACCOUNTS_DELETE_ROLE_TEMPLATE_BEGIN,
    accountId,
    templateId,
    analyticsPayload,
  };
}

export function dismissDeleteRoleTemplateError() {
  return {
    type: ACCOUNTS_DELETE_ROLE_TEMPLATE_DISMISS_ERROR,
  };
}

export function* doDeleteRoleTemplate(action) {
  const { accountId, templateId, analyticsPayload } = action;
  let res;
  try {
    res = yield call(protectedJsonDelete, `accounts/${accountId}/role-templates/${templateId}`);
  } catch (err) {
    yield put({
      type: ACCOUNTS_DELETE_ROLE_TEMPLATE_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: ACCOUNTS_DELETE_ROLE_TEMPLATE_SUCCESS,
    data: res,
    analyticsPayload,
    templateId,
  });
}

export function* watchDeleteRoleTemplate() {
  yield takeLatest(ACCOUNTS_DELETE_ROLE_TEMPLATE_BEGIN, doDeleteRoleTemplate);
}

export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNTS_DELETE_ROLE_TEMPLATE_BEGIN:
      return {
        ...state,
        deleteRoleTemplatePending: true,
        deleteRoleTemplateError: null,
      };

    case ACCOUNTS_DELETE_ROLE_TEMPLATE_SUCCESS:
      return {
        ...state,
        deleteRoleTemplatePending: false,
        deleteRoleTemplateError: null,
        roleTemplates: (state.roleTemplates ? [...state.roleTemplates] : []).filter(({ id }) => id !== action.templateId),
      };
    case ACCOUNTS_DELETE_ROLE_TEMPLATE_FAILURE:
      return {
        ...state,
        deleteRoleTemplatePending: false,
        deleteRoleTemplateError: action.data.error,
      };

    case ACCOUNTS_DELETE_ROLE_TEMPLATE_DISMISS_ERROR:
      return {
        ...state,
        deleteRoleTemplateError: null,
      };

    default:
      return state;
  }
}
