import React from 'react';
import { Mention } from '@bridgit/foundation';
import { UNKNOWN_MENTION } from './constants';

const formatMentionDisplay = (mention, users, accountId, userId) => {
  const mentionUserId = mention.match(/\d+/)[0];
  const user = users[accountId]?.find(({ id }) => id === parseInt(mentionUserId, 10));
  const userName = user?.name || UNKNOWN_MENTION;
  return (
    <Mention
      name={userName}
      isSelf={mentionUserId === userId}
    />
  );
};

export { formatMentionDisplay };
