const ACCOUNT_DEACTIVATED = 'Account Deactivated';
const ACCOUNT_REACTIVATED = 'Account Reactivated';
const ACCOUNT_LOGO_IS_ADDED = 'Account Logo is added';
const ACCOUNT_LOGO_IS_DELETED = 'Account Logo is deleted';
const USER_IS_ADDED = 'User is added';
const USER_IS_DEACTIVATED = 'User is deactivated';
const USER_INVITATION_IS_DELETED = 'User invitation is deleted';
const USER_INVITATION_IS_RE_SENT = 'User invitation is re-sent';
const USER_PERMISSION_GROUP_UPDATED = 'User Permission Group updated';
const DELETE_ROLE_TEMPLATE = 'Delete a Role Template';
const CREATE_ROLE_TEMPLATE = 'Create Role Template';
const UPDATE_ROLE_TEMPLATE = 'Edit a Role Template';
const DEFAULT_COST_RATE_ADDED = 'Default Cost Rate Added';

export {
  ACCOUNT_DEACTIVATED,
  ACCOUNT_REACTIVATED,
  ACCOUNT_LOGO_IS_ADDED,
  ACCOUNT_LOGO_IS_DELETED,
  USER_IS_ADDED,
  USER_IS_DEACTIVATED,
  USER_INVITATION_IS_DELETED,
  USER_INVITATION_IS_RE_SENT,
  USER_PERMISSION_GROUP_UPDATED,
  DELETE_ROLE_TEMPLATE,
  CREATE_ROLE_TEMPLATE,
  UPDATE_ROLE_TEMPLATE,
  DEFAULT_COST_RATE_ADDED,
};
