import { call, put, takeLatest } from 'redux-saga/effects';
import {
  DASHBOARD_GET_PROJECT_BREAKDOWN_BEGIN,
  DASHBOARD_GET_PROJECT_BREAKDOWN_SUCCESS,
  DASHBOARD_GET_PROJECT_BREAKDOWN_FAILURE,
  DASHBOARD_GET_PROJECT_BREAKDOWN_DISMISS_ERROR,
} from './constants';
import { getProjectBreakdownQuery } from '../queries/getProjectBreakdownQuery';
import graphApiClient from '../../../common/GraphAPIClient';

export function getProjectBreakdown(accountId, queries) {
  const { query, variables } = getProjectBreakdownQuery(accountId, queries);
  return {
    type: DASHBOARD_GET_PROJECT_BREAKDOWN_BEGIN,
    query,
    variables,
  };
}

export function dismissGetProjectBreakdownError() {
  return {
    type: DASHBOARD_GET_PROJECT_BREAKDOWN_DISMISS_ERROR,
  };
}

export function* doGetProjectBreakdown(action) {
  const { query, variables } = action;

  let res;
  try {
    res = yield call(graphApiClient.query, { query, variables });
    const { errors } = res;
    if (errors) {
      throw errors;
    }
  } catch (err) {
    yield put({
      type: DASHBOARD_GET_PROJECT_BREAKDOWN_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: DASHBOARD_GET_PROJECT_BREAKDOWN_SUCCESS,
    data: res.data,
  });
}

export function* watchGetProjectBreakdown() {
  yield takeLatest(DASHBOARD_GET_PROJECT_BREAKDOWN_BEGIN, doGetProjectBreakdown);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case DASHBOARD_GET_PROJECT_BREAKDOWN_BEGIN:
      return {
        ...state,
        getProjectBreakdownPending: true,
        getProjectBreakdownError: null,
      };

    case DASHBOARD_GET_PROJECT_BREAKDOWN_SUCCESS:
      return {
        ...state,
        projectBreakdown: action?.data?.projects?.items?.length ? action.data.projects.items : [],
        getProjectBreakdownPending: false,
        getProjectBreakdownError: null,
      };

    case DASHBOARD_GET_PROJECT_BREAKDOWN_FAILURE:
      return {
        ...state,
        getProjectBreakdownPending: false,
        getProjectBreakdownError: action.data.error,
      };

    case DASHBOARD_GET_PROJECT_BREAKDOWN_DISMISS_ERROR:
      return {
        ...state,
        getProjectBreakdownError: null,
      };

    default:
      return state;
  }
}
