import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { getPermissionsForAccount, isAuthorized } from '../permissions/utils/permissionUtils';
import {
  PERM_READ,
  PERM_PERSON,
  PERM_REQUEST,
  PERM_ASSIGNMENT,
} from '../permissions/utils/constants';
import { getOrSetAccountId } from '../accounts/utils/accountUtils';

const supportedRedirect = ['/accounts/', '/accounts', '/'];

const Redirector = ({
  accounts,
  location,
  permissions,
  userId,
  incompleteProfile,
  history,
}) => {
  const hasBridgitPerms = useMemo(() => getPermissionsForAccount('*', permissions), [permissions]);

  if (incompleteProfile) {
    return <Redirect to="/profile-info" />;
  }

  if (permissions && !hasBridgitPerms && accounts.length && supportedRedirect.includes(location.pathname)) {
    const accountId = getOrSetAccountId(accounts, userId);
    if (!accountId) {
      return <Redirect to="/PageNotFound" />;
    }

    const hasPersonPerms = isAuthorized(accountId, permissions, PERM_READ, PERM_PERSON);
    const hasRequestPerms = isAuthorized(accountId, permissions, PERM_READ, PERM_REQUEST);
    const hasAssignmentPerms = isAuthorized(accountId, permissions, PERM_READ, PERM_ASSIGNMENT);
    const { from, search } = history.location.state || {};

    if (from) {
      return <Redirect to={{ pathname: from, search }} />;
    }

    if (hasPersonPerms) {
      return <Redirect to={`/accounts/${accountId}/dashboard`} />;
    }

    return <Redirect to={`/accounts/${accountId}/${hasRequestPerms || hasAssignmentPerms ? 'self-perform' : 'projects'}`} />;
  }

  return null;
};

Redirector.propTypes = {
  accounts: PropTypes.array,
  location: PropTypes.object.isRequired,
  permissions: PropTypes.object,
  userId: PropTypes.string,
  incompleteProfile: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
};

Redirector.defaultProps = {
  permissions: null,
  accounts: [],
  userId: null,
};

/* istanbul ignore next */
function mapStateToProps({ accounts, login }) {
  const { userInfo } = login;
  const { sub: userId } = userInfo;
  const { entities } = accounts;
  const incompleteProfile = ('name' in userInfo && 'title' in userInfo && 'team' in userInfo) &&
    (!userInfo?.name || !userInfo?.title || !userInfo?.team);

  return {
    permissions: userInfo?.permissions,
    userId,
    accounts: entities,
    incompleteProfile,
  };
}

export default withRouter(
  connect(
    mapStateToProps,
  )(Redirector),
);
