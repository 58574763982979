import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
} from '@material-ui/core';
import { validatePercentInput } from 'src/utils/validators';
import { MIN_ALLOCATION_PERCENT, MAX_ALLOCATION_PERCENT } from 'src/common/constants';

const NumberInput = React.memo(({
  className,
  value,
  onValueChanged,
  onBlur,
  disabled,
  index,
  placeholder,
  minValue,
  maxValue,
}) => {
  const [initialInputValue] = useState(value);
  const [workingValue, setWorkingValue] = useState(value);

  useEffect(
    () => { setWorkingValue(value); },
    [value],
  );

  const onKeyDown = (evt) => {
    const { key } = evt;
    const invalidChars = ['.', '-', '+', 'e'];

    if (invalidChars.includes(key)) evt.preventDefault();
  };

  const onChange = (evt) => {
    const { target } = evt;
    const { value: inputVal } = target;

    const validated = validatePercentInput(inputVal, minValue);

    if (!Number.isNaN(validated)) {
      setWorkingValue(validated);

      if (typeof validated === 'number') {
        onValueChanged(validated, index);
      }
    }
  };

  const handleBlur = () => {
    onBlur();

    if (typeof workingValue !== 'number') {
      setWorkingValue(initialInputValue);
      onValueChanged(initialInputValue, index);
    }
  };


  return (
    <Input
      className={className}
      type="number"
      value={workingValue}
      inputProps={{ min: minValue, max: maxValue, pattern: '[0-9]' }}
      onChange={onChange}
      onKeyDown={onKeyDown}
      disabled={disabled}
      onBlur={handleBlur}
      placeholder={placeholder}
    />
  );
});

NumberInput.propTypes = {
  className: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onValueChanged: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  index: PropTypes.number,
  placeholder: PropTypes.string,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
};

NumberInput.defaultProps = {
  onBlur: () => {},
  disabled: false,
  index: undefined,
  placeholder: '',
  minValue: MIN_ALLOCATION_PERCENT,
  maxValue: MAX_ALLOCATION_PERCENT,
};

export default NumberInput;
