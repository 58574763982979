import React, { useMemo, useCallback, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import pluralize from 'pluralize';
import { Beta, Page, Button, Loader, IconButton } from '@bridgit/foundation';
import { useMediaQuery, FormControl, Link, Select, MenuItem, InputBase, Typography } from '@material-ui/core';
import {
  ArrowForward,
  GetApp as DownloadIcon,
} from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import {
  ROLE_SEGMENT_VIEW,
  VIEWS,
  ACCOUNT_MODULE_PURSUIT_TRACKING,
  PAGE_NAMES,
  STORAGE_KEYS_BY_VIEW,
} from '../../common/constants';
import { hasModuleEnabled } from '../permissions/utils/permissionUtils';
import { ROLE_DESCRIPTOR, ROLE_SCHEMA } from '../../common/descriptors/role';
import { ROLE_SEGMENT_CSV_REPORT } from '../reports/redux/constants';
import allRolesFilled from '../../images/tada.svg?url';
import { getRoleNames, getProjectFields, getPhases } from '../account-settings/redux/actions';
import { getReportRoleSegments } from '../reports/redux/actions';
import {
  LoadableMomentDate,
  ProjectLinkToModalContainer,
  TabbedPageHeader,
} from '../common';
import { trackAnalytics, setActiveView } from '../common/redux/actions';
import { getPeople } from '../people/redux/actions';
import {
  FEEDBACK_URL,
  ALL_MONTHS,
  TIMEFRAME_MENU_ITEMS,
  EMPTY_STATE_ALL_FILLED,
} from './constants';
import { FILTERED_UNFILLED_ROLES_QUERY_ID } from '../queries/redux/constants';
import { ErrorBoundary } from '../errors';
import { Table, SortableTableCell, ChipBowl } from '../table';
import { FilterMenu } from '../filters';
import { setDescriptors } from '../table/redux/actions';
import { useProjectFilter } from '../filters/utils/useFilters';
import { getMappedFilters } from '../filters/utils/filterUtils';
import {
  EXCLUDED_UNFILLED_ROLES_PROJECT_HEADER_FILTERS,
  PRIORITY_PROJECT_FILTERS,
  PROJECT_FILTER_TYPE,
} from '../filters/common/constants';
import { AUTOMATION_PROJECT_FILTER_MENU_BUTTON } from '../filters/ids';
import useRememberedFilters from '../filters/utils/useRememberedFilters';
import { activeModal as getActiveModal } from '../modal-manager/redux/selectors';
import useRolesFilter from './utils/useRolesFilter';
import {
  rolesCount,
  rolesHaveMore,
  rolesLoading as getRolesLoading,
  rolesLoadingError as getRolesLoadingError,
  rolesPageFrom,
  rolesPageLoading,
  rolesUnfilledSegments,
} from './redux/selectors';
import { AvailablePeople } from '.';
import { perAccountUserKey } from '../../common/localStorageKeys';
import {
  filterClearEvent,
  filterClearPayload,
  filterChipRemoveEvent,
  filterChipRemovePayload,
} from '../../analytics/mixpanel/filterChips';

const Roles = () => {
  const dispatch = useDispatch();
  const { availablePeople: availablePeopleFlag } = useSelector(({ launchDarkly }) => launchDarkly);
  const accountId = useSelector(({ common: { accountId } }) => accountId);
  const roleSegments = useSelector(rolesUnfilledSegments);
  const rolesLoading = useSelector(getRolesLoading);
  const rolesLoadingError = useSelector(getRolesLoadingError);
  const rolesLoadingPage = useSelector(rolesPageLoading);
  const pageFrom = useSelector(rolesPageFrom);
  const hasMore = useSelector(rolesHaveMore);
  const totalCount = useSelector(rolesCount);
  const { userId, permissions } = useSelector(({ login: { userInfo: { sub, permissions } } }) => ({ userId: sub, permissions }));
  const { accountModules } = useSelector(({ accountSettings }) => accountSettings);
  const { projectFilterData } = useSelector(({ filters }) => filters);
  const { projectQueries } = useSelector(({ queries }) => ({
    projectQueries: queries[FILTERED_UNFILLED_ROLES_QUERY_ID]?.filter,
  }));

  const isBigScreen = useMediaQuery('(min-width:1700px)');

  const {
    timeframe,
    currentSort,
    updateTimeframe,
    updateSort,
    applyFilter: getUnfilledRoles,
    filledRolesFilterSelected,
  } = useRolesFilter({ view: VIEWS[ROLE_SEGMENT_VIEW], accountId, userId });
  useProjectFilter();

  const roleSegmentPage = useMemo(() => PAGE_NAMES[ROLE_SEGMENT_VIEW], []);
  const activeModal = useSelector(getActiveModal);
  const activeModalRef = useRef(null);
  const trackingContext = useMemo(() => ({ page: 'Roles', view: roleSegmentPage }), [roleSegmentPage]);
  const isPursuitModuleOn = useMemo(() => (hasModuleEnabled(accountModules, ACCOUNT_MODULE_PURSUIT_TRACKING)), [accountModules]);

  const { updateCurrentFilter } = useRememberedFilters({
    accountId,
    userId,
    viewId: ROLE_SEGMENT_VIEW,
    filterType: PROJECT_FILTER_TYPE,
    isPursuitsEnabled: isPursuitModuleOn,
    trackingContext,
  });

  useEffect(() => {
    if (!!activeModalRef.current && !activeModal) {
      getUnfilledRoles();
    }
    activeModalRef.current = activeModal;
  }, [activeModal, getUnfilledRoles]);

  useEffect(() => {
    dispatch(setActiveView(ROLE_SEGMENT_VIEW));
  }, [dispatch]);

  useEffect(() => {
    dispatch(setDescriptors(accountId, permissions, accountModules));
  }, [dispatch, accountId, permissions, accountModules]);

  useEffect(() => {
    dispatch(getRoleNames(accountId));
    dispatch(getPeople(accountId));
    dispatch(getProjectFields(accountId));
    dispatch(getPhases(accountId));
  }, [accountId, dispatch]);

  const columns = useMemo(() => {
    const RENDER_MAP = {
      [ROLE_SCHEMA.projectName]: (value, rowMeta) => value && (
        <ProjectLinkToModalContainer
          backgroundColor={rowMeta.projectColor}
          projectName={value}
          projectId={rowMeta.projectId}
          launchedFrom="Roles page - Project Name"
        />
      ),
      [ROLE_SCHEMA.startDate]: value => <LoadableMomentDate date={value} />,
      [ROLE_SCHEMA.endDate]: value => <LoadableMomentDate date={value} />,
    };

    const allocationColumnName = !availablePeopleFlag || isBigScreen ? 'Allocation %' : '%';

    return ROLE_DESCRIPTOR.map(({ schemaName, name, sort }) => {
      const nameResponsive = schemaName === ROLE_SCHEMA.allocation
        ? allocationColumnName
        : name;

      return {
        schemaName,
        name: nameResponsive,
        options: {
          sort,
          customBodyRender: RENDER_MAP[schemaName],
        },
      };
    });
  }, [availablePeopleFlag, isBigScreen]);

  const tableData = useMemo(() => roleSegments.map(segment => ({
    rowMeta: segment?.meta,
    rowData: columns.map(({ schemaName }) => segment?.[schemaName]),
    rowId: segment?.id,
  })), [roleSegments, columns]);

  const applyTimeframeFilter = useCallback((evt) => {
    const { value } = evt.target;
    updateTimeframe(value);

    dispatch(trackAnalytics('Roles View - Timeframe Selected', {
      'Timeframe Selected': value !== ALL_MONTHS ? pluralize('month', value, true) : 'All unfilled roles',
    }));
  }, [dispatch, updateTimeframe]);

  const trackFeedback = useCallback(() => {
    dispatch(trackAnalytics('Feedback Clicked', { View: 'Unfilled Roles' }));
  }, [dispatch]);

  const sortColumn = useCallback((query) => {
    updateSort(query);
    dispatch(trackAnalytics('Roles View - Sort Applied', { 'Column sorted by': query?.args?.[0]?.label }));
  }, [dispatch, updateSort]);

  const renderColumn = useCallback(column => (
    <SortableTableCell
      key={`${column.name}${column?.schemaName || ''}`}
      classes={{ root: 'table-table-header-cell' }}
      column={column}
      sortQuery={currentSort?.args?.[0]}
      onClick={sortColumn}
    />
  ), [currentSort, sortColumn]);

  const rolesTitleBeta = useMemo(() => (
    <>
      Unfilled Roles
      <Beta className="roles-title-beta" />
    </>
  ), []);

  const downloadCsv = useCallback(() => {
    const settings = { type: ROLE_SEGMENT_CSV_REPORT };
    dispatch(getReportRoleSegments(accountId, projectQueries, timeframe, settings, currentSort));
  }, [dispatch, accountId, projectQueries, timeframe, currentSort]);

  const headerControlColumns = useMemo(() => getMappedFilters(projectFilterData), [projectFilterData]);

  const projectChips = useMemo(() => {
    const projectFilterStorageKey = STORAGE_KEYS_BY_VIEW[ROLE_SEGMENT_VIEW].project;
    const filterStorageKey = perAccountUserKey(projectFilterStorageKey, accountId, userId);
    const onClear = () => dispatch(trackAnalytics(filterClearEvent('Project'), filterClearPayload(roleSegmentPage)));
    const onRemove = () => dispatch(trackAnalytics(filterChipRemoveEvent('Project'), filterChipRemovePayload(roleSegmentPage)));

    return (
      <ChipBowl
        queryId={FILTERED_UNFILLED_ROLES_QUERY_ID}
        bowlLabel="Project"
        filterStorageKey={filterStorageKey}
        useClearText={false}
        onClearFilters={onClear}
        onUpdateFilters={onRemove}
        showSaveFilterButton={false}
        filterType={PROJECT_FILTER_TYPE}
      />
    );
  }, [accountId, dispatch, roleSegmentPage, userId]);

  const controls = (
    <div className="controls">
      <Button
        className="feedback-link"
        href={FEEDBACK_URL}
        target="_blank"
        rel="noreferrer"
        underline="none"
        onClick={trackFeedback}
        variant="contained"
        color="primary"
      >
        Share feedback
      </Button>
      {!availablePeopleFlag && (
        <Button
          endIcon={<DownloadIcon />}
          className="role-name-filter"
          onClick={downloadCsv}
        >
          Download CSV
        </Button>
      )}
      <FilterMenu
        disableRipple
        label="Project Filters"
        columns={headerControlColumns}
        exclude={EXCLUDED_UNFILLED_ROLES_PROJECT_HEADER_FILTERS}
        priorityColumns={PRIORITY_PROJECT_FILTERS}
        buttonId={AUTOMATION_PROJECT_FILTER_MENU_BUTTON}
        updateCurrentFilter={updateCurrentFilter(PROJECT_FILTER_TYPE)}
        filterQueries={projectQueries}
      />
      {!availablePeopleFlag && (
        <FormControl variant="outlined">
          <Select
            value={timeframe}
            onChange={applyTimeframeFilter}
            className="time-frame-select"
            input={<InputBase classes={{ input: 'time-frame-input' }} />}
          >
            {TIMEFRAME_MENU_ITEMS.map(({ value, label }) => (
              <MenuItem
                key={value}
                className="time-frame-menu-item"
                value={value}
              >
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  );

  const renderChipBowl = useCallback(() => (
    <div className="roles-filter-chip-bowl">
      {projectChips}
    </div>
  ), [projectChips]);

  const loadMoreRoles = useCallback(() => {
    if (!hasMore || rolesLoadingPage) return;
    getUnfilledRoles({ pageFrom });
  }, [pageFrom, hasMore, rolesLoadingPage, getUnfilledRoles]);

  const isDataLoading = rolesLoading || !!rolesLoadingError;
  const hasUnfilledRoles = !isDataLoading && !!roleSegments?.length && !filledRolesFilterSelected;
  const noUnfilledRoles = !isDataLoading && !hasUnfilledRoles;

  const renderContent = () => (
    <>
      {hasUnfilledRoles && (
        <Table
          data={tableData}
          columns={columns}
          className="roles-table"
          renderColumn={renderColumn}
          loadMore={loadMoreRoles}
          loading={rolesLoading}
          loadingPage={rolesLoadingPage}
        />
      )}
      {noUnfilledRoles && (
        <>
          <img src={allRolesFilled} alt="No unfilled roles on this account" />
          <Typography variant="subtitle1">{EMPTY_STATE_ALL_FILLED}</Typography>
          <div className="shortcuts">
            <Link component={RouterLink} to={`/accounts/${accountId}/people`}>
              Go to People
              <ArrowForward />
            </Link>
            <Link component={RouterLink} to={`/accounts/${accountId}/projects`}>
              Go to Projects
              <ArrowForward />
            </Link>
          </div>
        </>
      )}
    </>
  );

  const renderPage = () => (
    <Page className={classNames('app-page-main', { 'empty-state': noUnfilledRoles })}>
      {renderChipBowl()}
      {isDataLoading && <Loader />}
      {hasUnfilledRoles && (
        <div className="roles-count-wrap">
          <div className="roles-total">{pluralize('Role', totalCount, true)}</div>
        </div>
      )}
      {renderContent()}
    </Page>
  );

  const renderContainer = () => (
    <Page className={classNames('app-page-main', { 'empty-state': noUnfilledRoles })}>
      {renderChipBowl()}
      <div className="roles-page-container">
        <div className="table-container roles-table-container">
          {!isDataLoading && (
            <div className="header">
              <div className="title">Unfilled Roles</div>
              {hasUnfilledRoles && (
                <div className="roles-count header-spacing">{`Unfilled Roles: ${totalCount}`}</div>
              )}
              <FormControl variant="outlined" className="header-spacing">
                <Select
                  value={timeframe}
                  onChange={applyTimeframeFilter}
                  className="time-frame-select"
                  input={<InputBase classes={{ input: 'time-frame-input' }} />}
                >
                  {TIMEFRAME_MENU_ITEMS.map(({ value, label }) => (
                    <MenuItem
                      key={value}
                      className="time-frame-menu-item"
                      value={value}
                    >
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <IconButton
                size="medium"
                variant="outlined"
                onClick={downloadCsv}
                ariaLabel="Download CSV"
              >
                <DownloadIcon />
              </IconButton>
            </div>
          )}
          {isDataLoading && <Loader />}
          {renderContent()}
        </div>
        <div className="table-container people-list-container">
          <AvailablePeople />
        </div>
      </div>
    </Page>
  );

  return (
    <ErrorBoundary>
      <div className="roles-roles">
        <TabbedPageHeader
          pageTitle={rolesTitleBeta}
          controls={controls}
        />
        {availablePeopleFlag
          ? renderContainer()
          : renderPage()}
      </div>
    </ErrorBoundary>
  );
};

export default Roles;
