import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNT_SETTINGS_GET_PHASES_BEGIN,
  ACCOUNT_SETTINGS_GET_PHASES_SUCCESS,
  ACCOUNT_SETTINGS_GET_PHASES_FAILURE,
  ACCOUNT_SETTINGS_GET_PHASES_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';

export function getPhases(accountId) {
  return {
    type: ACCOUNT_SETTINGS_GET_PHASES_BEGIN,
    accountId,
  };
}

export function dismissGetPhasesError() {
  return {
    type: ACCOUNT_SETTINGS_GET_PHASES_DISMISS_ERROR,
  };
}

export function* doGetPhases(action) {
  const { accountId } = action;
  let res;
  try {
    res = yield call(protectedJsonGet, `accounts/${accountId}/phases`, { includeSubPhases: true });
  } catch (err) {
    yield put({
      type: ACCOUNT_SETTINGS_GET_PHASES_FAILURE,
      data: { error: err },
    });
    return;
  }
  yield put({
    type: ACCOUNT_SETTINGS_GET_PHASES_SUCCESS,
    data: res,
  });
}

export function* watchGetPhases() {
  yield takeLatest(ACCOUNT_SETTINGS_GET_PHASES_BEGIN, doGetPhases);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNT_SETTINGS_GET_PHASES_BEGIN:
      return {
        ...state,
        getPhasesPending: true,
        getPhasesError: null,
      };

    case ACCOUNT_SETTINGS_GET_PHASES_SUCCESS:
      return {
        ...state,
        phases: action.data,
        getPhasesPending: false,
        getPhasesError: null,
      };

    case ACCOUNT_SETTINGS_GET_PHASES_FAILURE:
      return {
        ...state,
        getPhasesPending: false,
        getPhasesError: action.data.error,
      };

    case ACCOUNT_SETTINGS_GET_PHASES_DISMISS_ERROR:
      return {
        ...state,
        getPhasesError: null,
      };

    default:
      return state;
  }
}
