/* eslint-disable no-dupe-keys */
export const DEFAULT_UNFILLED_ROLES_SORT = { name: 'sort', args: [{ column: 'startDate', label: 'Start Date', ascending: true, type: null }] };

export const FILTER_UNPLANNED_PROJECTS = [{
  bool: {
    must_not: [ // eslint-disable-line camelcase
      {
        match: {
          state: 'Complete',
        },
      },
      {
        match: {
          state: 'Canceled',
        },
      },
      {
        match: {
          state: 'Lost',
        },
      },
    ],
  },
}];

export const DEFAULT_MONTH_OFFSET = 3;

export const DASHBOARD_GET_UNFILLED_ROLES_BEGIN = 'DASHBOARD_GET_UNFILLED_ROLES_BEGIN';
export const DASHBOARD_GET_UNFILLED_ROLES_SUCCESS = 'DASHBOARD_GET_UNFILLED_ROLES_SUCCESS';
export const DASHBOARD_GET_UNFILLED_ROLES_FAILURE = 'DASHBOARD_GET_UNFILLED_ROLES_FAILURE';
export const DASHBOARD_GET_UNFILLED_ROLES_DISMISS_ERROR = 'DASHBOARD_GET_UNFILLED_ROLES_DISMISS_ERROR';
export const DASHBOARD_CLEAR_UNFILLED_ROLES = 'DASHBOARD_CLEAR_UNFILLED_ROLES';
export const DASHBOARD_GET_PROJECT_BREAKDOWN_BEGIN = 'DASHBOARD_GET_PROJECT_BREAKDOWN_BEGIN';
export const DASHBOARD_GET_PROJECT_BREAKDOWN_SUCCESS = 'DASHBOARD_GET_PROJECT_BREAKDOWN_SUCCESS';
export const DASHBOARD_GET_PROJECT_BREAKDOWN_FAILURE = 'DASHBOARD_GET_PROJECT_BREAKDOWN_FAILURE';
export const DASHBOARD_GET_PROJECT_BREAKDOWN_DISMISS_ERROR = 'DASHBOARD_GET_PROJECT_BREAKDOWN_DISMISS_ERROR';
export const DASHBOARD_GET_PROJECT_BREAKDOWN_AND_ROLES_BEGIN = 'DASHBOARD_GET_PROJECT_BREAKDOWN_AND_ROLES_BEGIN';
export const DASHBOARD_GET_PROJECT_BREAKDOWN_AND_ROLES_SUCCESS = 'DASHBOARD_GET_PROJECT_BREAKDOWN_AND_ROLES_SUCCESS';
export const DASHBOARD_GET_PROJECT_BREAKDOWN_AND_ROLES_FAILURE = 'DASHBOARD_GET_PROJECT_BREAKDOWN_AND_ROLES_FAILURE';
export const DASHBOARD_GET_PROJECT_BREAKDOWN_AND_ROLES_DISMISS_ERROR = 'DASHBOARD_GET_PROJECT_BREAKDOWN_AND_ROLES_DISMISS_ERROR';
export const DASHBOARD_GET_IDLE_TIME_BREAKDOWN_BEGIN = 'DASHBOARD_GET_IDLE_TIME_BREAKDOWN_BEGIN';
export const DASHBOARD_GET_IDLE_TIME_BREAKDOWN_SUCCESS = 'DASHBOARD_GET_IDLE_TIME_BREAKDOWN_SUCCESS';
export const DASHBOARD_GET_IDLE_TIME_BREAKDOWN_FAILURE = 'DASHBOARD_GET_IDLE_TIME_BREAKDOWN_FAILURE';
export const DASHBOARD_GET_IDLE_TIME_BREAKDOWN_DISMISS_ERROR = 'DASHBOARD_GET_IDLE_TIME_BREAKDOWN_DISMISS_ERROR';
export const DASHBOARD_GET_IDLE_TIME_OVERVIEW_BEGIN = 'DASHBOARD_GET_IDLE_TIME_OVERVIEW_BEGIN';
export const DASHBOARD_GET_IDLE_TIME_OVERVIEW_SUCCESS = 'DASHBOARD_GET_IDLE_TIME_OVERVIEW_SUCCESS';
export const DASHBOARD_GET_IDLE_TIME_OVERVIEW_FAILURE = 'DASHBOARD_GET_IDLE_TIME_OVERVIEW_FAILURE';
export const DASHBOARD_GET_IDLE_TIME_OVERVIEW_DISMISS_ERROR = 'DASHBOARD_GET_IDLE_TIME_OVERVIEW_DISMISS_ERROR';
export const DASHBOARD_GET_MONTHLY_IDLE_TIME_OVERVIEW_BEGIN = 'DASHBOARD_GET_MONTHLY_IDLE_TIME_OVERVIEW_BEGIN';
export const DASHBOARD_GET_MONTHLY_IDLE_TIME_OVERVIEW_SUCCESS = 'DASHBOARD_GET_MONTHLY_IDLE_TIME_OVERVIEW_SUCCESS';
export const DASHBOARD_GET_MONTHLY_IDLE_TIME_OVERVIEW_FAILURE = 'DASHBOARD_GET_MONTHLY_IDLE_TIME_OVERVIEW_FAILURE';
export const DASHBOARD_GET_MONTHLY_IDLE_TIME_OVERVIEW_DISMISS_ERROR = 'DASHBOARD_GET_MONTHLY_IDLE_TIME_OVERVIEW_DISMISS_ERROR';
