import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import deepEqual from 'react-fast-compare';
import moment from 'moment';
import {
  getPersonFieldValues,
  getPersonAllocationsByDate,
  getPersonAllocations,
} from '../people/redux/actions';
import {
  addProjectRoleAllocation,
  replaceProjectRoleAllocation,
  getProjectNames,
  sendRoleNotification,
  getProjectRole,
} from '../projects/redux/actions';
import { ROLE_AVAILABILITIES_QUERY_ID } from '../queries/redux/constants';
import { getActiveFiltersCount, getFilterNames } from '../filters/utils/filterUtils';
import {
  FILTER_ALLOCATION,
  FILTER_ALLOCATED_PEOPLE,
  FILTER_PHASE,
  FILTER_ROLES,
  FILTER_DISTANCE_FROM_PROJECT,
} from '../../filters/constants';
import {
  RoleRequirementPopper,
  SelectedPerson,
  AllocationModalContentHeader,
  CandidateList,
  CandidateFilters,
  CandidateSearch,
  AllocationModalContentStructure,
  EditCandidateFilters,
  FilterMismatchWarning,
} from '.';
import { EMPTY_RADIUS } from './common/constants';
import { getFilteredRoleAvailabilities, setRadius } from './redux/actions';
import { setFilterQuery, clearQueries } from '../queries/redux/actions';
import {
  DATE_INPUT_FORMAT,
  ALLOCATION_MODAL_PAGE_SIZE,
  FIELD_TYPE_MULTI_SELECT,
  ACCOUNT_MODULE_ASSIGNMENT_COMMUNICATION,
  COMPONENT_ASSIGNMENT_COMMUNICATION_EMAIL,
  TIME_OFF,
  FIELD_TYPE_ADDRESS,
} from '../../common/constants';
import { getTitlesFromFieldDefs, getInitialFilters } from './utils/allocationFilterUtils';
import { getPersonFields } from '../account-settings/redux/actions';
import { EditControls } from '../wrapped-components';
import {
  clearSelectedProjectId,
  setModalActiveView,
  setBackButtonState,
  trackAnalytics,
} from '../common/redux/actions';
import { closeModal } from '../modal-manager/redux/actions';
import { MULTI_STATE_MODAL_ID, COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PROJECT } from '../common/redux/constants';
import { CommunicateAssignmentRemovalOrReplacementModal } from '../projects';
import { hasModuleEnabled } from '../permissions/utils/permissionUtils';
import {
  ALLOCATION_REPLACED,
  INCLUDE_PROJECT_ADDRESS,
  INCLUDE_ROLE_NAME,
  ROLE_NOTIFY_EMAIL,
  ROLE_NOTIFY_TYPE_CLEAR,
  LOST,
  CANCELED,
  PURSUIT,
} from '../projects/constants';
import { updateColumnFilter } from '../table/filterUtils';
import {
  PLACE_A_PERSON_OPENED,
  PLACE_A_PERSON_FILTERS_USED,
  PAYLOAD_DISTANCE_FROM_PROJECT,
} from '../../analytics/allocations/constants';
import { getPageName } from '../../analytics/utils';
import { DISTANCE_UNITS } from '../accounts/common/constants';
import { convertMetersToDistance } from '../../utils/formatters';

const { KM, MI } = DISTANCE_UNITS;

export class AllocatePerson extends PureComponent {
  static propTypes = {
    accountActions: PropTypes.object.isRequired,
    peopleActions: PropTypes.object.isRequired,
    projectActions: PropTypes.object.isRequired,
    people: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
    selectedResource: PropTypes.object.isRequired,
    selectedProject: PropTypes.object.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    currentlyAllocatedPerson: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    updatePending: PropTypes.bool.isRequired,
    accountId: PropTypes.number.isRequired,
    getFilteredRoleAvailabilities: PropTypes.func.isRequired,
    roleAvailabilities: PropTypes.array.isRequired, // eslint-disable-line react/no-unused-prop-types
    originalRoleAvailabilities: PropTypes.array.isRequired,
    radius: PropTypes.number.isRequired,
    queries: PropTypes.object,
    getFilteredRoleAvailabilitiesPending: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
    setFilterQuery: PropTypes.func.isRequired,
    clearQueries: PropTypes.func.isRequired,
    personFields: PropTypes.array.isRequired, // eslint-disable-line react/no-unused-prop-types
    clearSelectedProjectId: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    setModalActiveView: PropTypes.func.isRequired,
    setBackButtonState: PropTypes.func.isRequired,
    showProjectOnCancel: PropTypes.bool.isRequired,
    runsInModal: PropTypes.bool,
    modalOrigin: PropTypes.string,
    benchy20Flag: PropTypes.bool.isRequired,
    sendRoleNotification: PropTypes.func.isRequired,
    accountModules: PropTypes.arrayOf(PropTypes.object).isRequired,
    personAllocations: PropTypes.objectOf(PropTypes.array),
    getProjectRole: PropTypes.func.isRequired,
    activeRole: PropTypes.object,
    setRadius: PropTypes.func.isRequired,
    trackAnalytics: PropTypes.func.isRequired,
    conversionFactorMetric: PropTypes.number.isRequired,
    activeView: PropTypes.number.isRequired,
    contentView: PropTypes.number.isRequired,
  };

  static defaultProps = {
    currentlyAllocatedPerson: null,
    runsInModal: false,
    modalOrigin: null,
    queries: {},
    personAllocations: {},
    activeRole: null,
  };

  constructor(props) {
    super(props);
    const { updatePending } = props;

    this.customPopperTarget = null;

    this.state = {
      initialFilters: null,
      filteredPeople: [],
      initialFilteredPeople: [],
      originalFilteredPeople: [], // Original order before recommendation
      valid: false,
      selectedPerson: null,
      showCustomModal: false,
      updatePending,
      showFilterEdit: false,
      showFilterWarning: false,
      isNotifyModalShown: false,
      relatedTitles: [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      selectedPerson,
      initialFilteredPeople,
      originalFilteredPeople,
    } = prevState;
    const {
      people,
      roleAvailabilities,
      originalRoleAvailabilities,
      currentlyAllocatedPerson,
      selectedResource,
      startDate,
      endDate,
      getFilteredRoleAvailabilitiesPending,
      queries,
      activeRole,
      benchy20Flag,
    } = nextProps;

    const stateUpdates = {
      updatePending: nextProps.updatePending,
    };

    const titles = getTitlesFromFieldDefs(nextProps.personFields);
    const relatedTitles = benchy20Flag ? activeRole?.relatedTitles || [] : [];
    const roleFilters = getInitialFilters(startDate, endDate, [selectedResource.name, ...relatedTitles.filter(related => related !== selectedResource.name)], titles);

    // Rebuild the dataset
    if (!nextProps.loading && !getFilteredRoleAvailabilitiesPending) {
      stateUpdates.filteredPeople = roleAvailabilities.reduce((peopleAcc, availablePerson) => {
        if (currentlyAllocatedPerson && availablePerson.id === currentlyAllocatedPerson.id) {
          return peopleAcc;
        }

        const apiPerson = people.entities.find(p => availablePerson.id === p.id);

        if (apiPerson && 'photoUrl' in apiPerson) {
          peopleAcc.push({
            ...availablePerson,
            photoUrl: apiPerson.photoUrl ? apiPerson.photoUrl : '',
          });
        }

        return peopleAcc;
      }, []);

      if (currentlyAllocatedPerson && !selectedPerson) {
        const person = nextProps.currentlyAllocatedPerson;

        stateUpdates.selectedPerson = {
          id: person.id,
          name: person.name,
          title: person.title,
          photoUrl: person.photoUrl ? person.photoUrl : '',
        };
      }

      // Set initial filtered people when the filter query is the default
      if (deepEqual(roleFilters, queries.filter) && !initialFilteredPeople.length && !originalFilteredPeople.length) {
        stateUpdates.initialFilteredPeople = roleAvailabilities;
        const filteredRelatedTitles = relatedTitles.filter(relatedTitle => relatedTitle !== selectedResource.name);
        const additionalIds = roleAvailabilities.filter(roleAvailability => filteredRelatedTitles.find(relatedTitle => relatedTitle === roleAvailability.title)).map(({ id }) => id);
        stateUpdates.originalFilteredPeople = originalRoleAvailabilities.filter(filteredPerson => !additionalIds.find(additionalId => additionalId === filteredPerson.id));
      }
    }

    return stateUpdates;
  }

  componentDidMount() {
    const {
      accountActions,
      peopleActions,
      projectActions,
      getProjectRole,
      accountId,
      selectedProject,
      selectedResource,
      modalOrigin,
      trackAnalytics,
    } = this.props;

    accountActions.getPersonFields(accountId);
    peopleActions.getPersonFieldValues(accountId);
    projectActions.getProjectNames(accountId);
    getProjectRole(accountId, selectedProject.id, selectedResource.id);

    const analyticsPayload = {
      'Project Name': selectedProject.name,
      'Project ID': selectedProject.id,
      'Project status': selectedProject.state,
      'Project type': selectedProject.type,
      'Role Name': selectedResource.name,
      'Role ID': selectedResource.id,
      'Navigated to from': modalOrigin,
    };

    trackAnalytics(PLACE_A_PERSON_OPENED, analyticsPayload);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      queries,
      clearSelectedProjectId,
      closeModal,
      showProjectOnCancel,
      setModalActiveView,
      setBackButtonState,
      personFields,
      benchy20Flag,
      activeRole,
      startDate,
      endDate,
      selectedResource,
      setFilterQuery,
      getFilteredRoleAvailabilities,
      accountId,
      selectedProject,
      loading,
      radius,
      setRadius,
    } = this.props;
    const {
      updatePending,
      initialFilters,
      relatedTitles: stateRelatedTitles,
    } = this.state;

    if ((queries && !deepEqual(prevProps.queries, queries)) || prevProps.radius !== radius) {
      this.loadRoleAvailabilities();
    }

    const titles = getTitlesFromFieldDefs(personFields);
    const relatedTitles = benchy20Flag ? activeRole?.relatedTitles || [] : [];
    const roleFilters = getInitialFilters(startDate, endDate, [selectedResource.name, ...relatedTitles.filter(related => related !== selectedResource.name)], titles);

    if (!loading && ((!initialFilters && personFields.length) || (!deepEqual(stateRelatedTitles, relatedTitles)))) {
      if (!queries.filter || !deepEqual(roleFilters, queries.filter)) {
        setRadius(EMPTY_RADIUS);
        setFilterQuery(ROLE_AVAILABILITIES_QUERY_ID, roleFilters);
        getFilteredRoleAvailabilities({
          accountId,
          projectId: selectedProject.id,
          roleId: selectedResource.id,
          queries: {
            filter: roleFilters,
          },
          startDate,
          endDate,
          requireAvailability: false,
          pageFrom: 0,
          pageSize: ALLOCATION_MODAL_PAGE_SIZE,
          radius: EMPTY_RADIUS,
        });
      }
      this.updateAllocatePersonState({
        relatedTitles,
        initialFilters: roleFilters,
        showFilterWarning: Array.isArray(roleFilters.args) && !roleFilters.args.find(filter => filter.column === 'title'),
      });
    }

    // Close the modal when done saving an allocation
    if (prevState.updatePending && !updatePending) {
      if (showProjectOnCancel) {
        setBackButtonState(false);
        setModalActiveView(COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PROJECT);
      } else {
        clearSelectedProjectId();
        closeModal(MULTI_STATE_MODAL_ID);
      }
    }
  }

  updateAllocatePersonState = newState => this.setState(newState)

  loadRoleAvailabilities = (pageFrom = 0) => {
    const {
      queries,
      accountId,
      selectedProject,
      selectedResource,
      startDate,
      endDate,
      getFilteredRoleAvailabilities,
      radius,
    } = this.props;

    getFilteredRoleAvailabilities({
      accountId,
      projectId: selectedProject.id,
      roleId: selectedResource.id,
      queries,
      startDate,
      endDate,
      requireAvailability: false,
      pageFrom,
      pageSize: ALLOCATION_MODAL_PAGE_SIZE,
      radius,
    });
  }

  setInitialFilters = () => {
    const { setFilterQuery, setRadius } = this.props;
    const { initialFilters } = this.state;

    setFilterQuery(ROLE_AVAILABILITIES_QUERY_ID, initialFilters);
    setRadius(EMPTY_RADIUS);
  }

  onApplyFilter = (column, updatedFilters = []) => {
    const { setFilterQuery, queries } = this.props;
    const filter = updateColumnFilter(column, updatedFilters, queries?.filter); // this filter is not remembered
    setFilterQuery(ROLE_AVAILABILITIES_QUERY_ID, filter);

    this.setState({
      showFilterWarning: false,
    });

    const type = column.type || column.filterType;
    const count = getActiveFiltersCount(updatedFilters);
    const isMultiFilter = (type === FIELD_TYPE_MULTI_SELECT ||
      type === FILTER_ALLOCATION ||
      type === FILTER_ALLOCATED_PEOPLE ||
      type === FILTER_PHASE ||
      type === FILTER_ROLES);

    if (count === 0) { // Filter was effectively cleared, do not log
      return;
    }

    const values = getFilterNames(updatedFilters);
    const filterValues = isMultiFilter && values.length > 1
      ? JSON.stringify(values) : values.join(', ');

    this.trackApplyFilter(PLACE_A_PERSON_FILTERS_USED, column.name, filterValues, type, count);
  };

  onApplyDistanceFilter = (radius) => {
    this.trackApplyFilter(PLACE_A_PERSON_FILTERS_USED, PAYLOAD_DISTANCE_FROM_PROJECT, radius, FILTER_DISTANCE_FROM_PROJECT, 1);
  }

  trackApplyFilter = (event, property, value, type, count) => {
    const { selectedProject, selectedResource, trackAnalytics } = this.props;
    const analyticsPayload = {
      'Project name': selectedProject.name,
      'Project id': selectedProject.id,
      'Project status': selectedProject.state,
      'Project type': selectedProject.type,
      'Role name': selectedResource.name,
      'Role id': selectedResource.id,
      'Property filtered by': property,
      'Value filtered by': value,
      'Field type filtered by': type,
      'Number of filtered values': count,
    };

    trackAnalytics(event, analyticsPayload);
  }

  onSaveAllocation = () => {
    const {
      projectActions,
      selectedResource,
      currentlyAllocatedPerson,
      startDate,
      endDate,
      selectedProject,
      accountId,
      updatePending,
      modalOrigin,
      clearQueries,
      personAllocations,
      conversionFactorMetric,
      activeView,
      contentView,
    } = this.props;
    const {
      selectedPerson,
      initialFilteredPeople,
      originalFilteredPeople,
    } = this.state;

    const hasAllocationHistory = personAllocations[selectedPerson.id].length > 0;
    const defaultResultRank = initialFilteredPeople.findIndex(({ id }) => id === selectedPerson.id) + 1 || null;
    const originalDefaultResultRank = originalFilteredPeople.findIndex(({ id }) => id === selectedPerson.id) + 1 || null;
    const isInDefaultResult = !!defaultResultRank;
    const isInDefaultOriginalResult = !!originalDefaultResultRank;

    const analyticsPayload = {
      'Project Name': selectedProject.name,
      'Project ID': selectedProject.id,
      'Project status': selectedProject.state,
      'Project type': selectedProject.type,
      'Role Name': selectedResource.name,
      'Role ID': selectedResource.id,
      'Person Name': selectedPerson.name,
      'Person ID': selectedPerson.id,
      'In default filters': isInDefaultResult,
      'Rank in default filters': defaultResultRank,
      'In original filters': isInDefaultOriginalResult,
      'Rank in original filters': originalDefaultResultRank,
      'Has allocation history': hasAllocationHistory,
    };

    const newAllocation = {
      roleId: selectedResource.id,
      personId: selectedPerson.id,
      name: selectedPerson.name,
      startDate,
      endDate,
    };

    if (updatePending) {
      return;
    }

    clearQueries(ROLE_AVAILABILITIES_QUERY_ID);

    if (currentlyAllocatedPerson) {
      const oldData = {
        roleId: selectedResource.id,
        personId: currentlyAllocatedPerson.id,
        startDate,
        endDate,
      };

      const analytics = {
        ...analyticsPayload,
        modalOrigin,
      };

      projectActions.replaceProjectRoleAllocation(accountId, selectedProject.id, oldData, newAllocation, analytics);
    } else {
      const allocationCausedConflicts = ![LOST, CANCELED, PURSUIT].includes(selectedProject.state) && !selectedPerson.canFillAllocationRange;
      const distanceAnalytics = selectedPerson.distanceFromProject != null
        ? convertMetersToDistance(selectedPerson.distanceFromProject, conversionFactorMetric)
        : null;
      const analytics = {
        ...analyticsPayload,
        [PAYLOAD_DISTANCE_FROM_PROJECT]: distanceAnalytics,
        'Page added from': getPageName(activeView, contentView),
        modalOrigin,
        allocationCausedConflicts,
      };

      projectActions.addProjectRoleAllocation(accountId, selectedProject.id, newAllocation, analytics);
    }
  }

  onHideFilterWarning = () => {
    this.setState({
      showFilterWarning: false,
    });
  }

  onSelectPerson = (selectedPerson) => {
    const {
      peopleActions: { getPersonAllocations },
      accountId,
    } = this.props;

    getPersonAllocations(accountId, selectedPerson.id, 0, 'All', 1);

    this.setState({
      selectedPerson,
      valid: true,
    });
  }

  onClearSelectedPerson = () => {
    this.setState({
      selectedPerson: null,
      valid: false,
    });
  }

  onCustomClick = (evt) => {
    this.customPopperTarget = evt.currentTarget;

    this.setState({
      showCustomModal: true,
    });
  }

  onHideCustom = () => {
    this.setState({
      showCustomModal: false,
    });
  }

  toggleFilterEdit = () => {
    const { showFilterEdit } = this.state;
    this.setState({
      showFilterEdit: !showFilterEdit,
    });
  }

  stopPropagation = (event) => {
    event.stopPropagation();
  }

  dismissModal = () => {
    const {
      clearSelectedProjectId,
      closeModal,
      showProjectOnCancel,
      setModalActiveView,
      clearQueries,
      setBackButtonState,
    } = this.props;

    clearQueries(ROLE_AVAILABILITIES_QUERY_ID);

    if (showProjectOnCancel) {
      setBackButtonState(false);
      setModalActiveView(COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PROJECT);
    } else {
      clearSelectedProjectId();
      closeModal(MULTI_STATE_MODAL_ID);
    }
  }

  showNotifyModal = () => this.setState({ isNotifyModalShown: true });

  hideNotifyModal = () => this.setState({ isNotifyModalShown: false, valid: false })

  sendReplacementNotification = (message, standardMessage, options) => {
    const {
      accountId,
      selectedProject,
      selectedResource,
      currentlyAllocatedPerson,
      sendRoleNotification,
      startDate,
      endDate,
    } = this.props;

    const data = [{
      personId: currentlyAllocatedPerson.id,
      method: ROLE_NOTIFY_EMAIL,
      type: ROLE_NOTIFY_TYPE_CLEAR,
      ...(message ? { message } : {}),
      includeProjectAddress: options[INCLUDE_PROJECT_ADDRESS],
      includeRoleName: options[INCLUDE_ROLE_NAME],
    }];

    const allocation = {
      ...currentlyAllocatedPerson,
      startDate,
      endDate,
    };

    const analyticsPayload = {
      project: selectedProject,
      role: selectedResource,
      allocations: selectedResource.allocations,
      allocation,
      options,
      allocationChangeType: ALLOCATION_REPLACED,
    };

    sendRoleNotification(accountId, selectedProject.id, selectedResource.id, data, analyticsPayload, this.onSaveAllocation);

    this.hideNotifyModal();
  }

  doNotSendReplacementNotification = () => {
    this.onSaveAllocation();
    this.hideNotifyModal();
  }

  render() {
    const {
      loading,
      selectedResource,
      selectedProject,
      startDate,
      endDate,
      updatePending,
      currentlyAllocatedPerson,
      runsInModal,
      accountModules,
    } = this.props;
    const {
      valid,
      selectedPerson,
      filteredPeople,
      showCustomModal,
      showFilterEdit,
      showFilterWarning,
      isNotifyModalShown,
    } = this.state;

    if (loading || !selectedResource) {
      return null;
    }

    const allocations = selectedResource && selectedResource.requirements ? selectedResource.requirements : [];

    const leftContent = showFilterEdit ? (
      <EditCandidateFilters onApplyFilter={this.onApplyFilter} />
    ) : (
      <>
        <CandidateSearch />
        {showFilterWarning && (<FilterMismatchWarning onDelete={this.onHideFilterWarning} />)}
        <CandidateList
          selectedPerson={selectedPerson}
          filteredPeople={filteredPeople}
          roleStart={moment(startDate, DATE_INPUT_FORMAT)}
          roleEnd={moment(endDate, DATE_INPUT_FORMAT)}
          onHideModal={this.dismissModal}
          onSelectPerson={this.onSelectPerson}
          onSearchChange={this.onSearchChange}
          loadMore={this.loadRoleAvailabilities}
          roleRequirements={allocations}
          runsInModal={runsInModal}
          roleName={selectedResource.name}
          roleId={selectedResource.id}
          project={selectedProject}
        />
      </>
    );

    const rightContent = (
      <CandidateFilters
        editing={showFilterEdit}
        toggleFilterEdit={this.toggleFilterEdit}
        onResetFilters={this.setInitialFilters}
        onApplyDistanceFilter={this.onApplyDistanceFilter}
      />
    );

    const showClearButton = selectedPerson?.id && currentlyAllocatedPerson?.id && selectedPerson.id !== currentlyAllocatedPerson.id;

    const allocation = {
      name: currentlyAllocatedPerson?.name,
      personId: currentlyAllocatedPerson?.id,
      startDate,
      endDate,
    };

    const shouldShowNotifyModal = (
      hasModuleEnabled(accountModules, ACCOUNT_MODULE_ASSIGNMENT_COMMUNICATION, COMPONENT_ASSIGNMENT_COMMUNICATION_EMAIL) &&
      selectedResource.isCommunicated &&
      currentlyAllocatedPerson &&
      !moment(endDate).isBefore(moment(), 'date')
    );

    const selectedPersonHasTimeOff = selectedPerson?.unavailabilities
      ?.some((unavailability => unavailability?.rangeType === TIME_OFF));

    const projectAddress = selectedProject?.fields?.find(({ name }) => name === FIELD_TYPE_ADDRESS)?.values?.[0];

    return (
      <div className="allocations-allocate-person" onClick={this.stopPropagation} role="presentation">
        {isNotifyModalShown && (
          <CommunicateAssignmentRemovalOrReplacementModal
            role={selectedResource}
            selectedProject={selectedProject}
            primaryAction={this.sendReplacementNotification}
            secondaryAction={this.doNotSendReplacementNotification}
            allocation={allocation}
            type={ALLOCATION_REPLACED}
          />
        )}

        <AllocationModalContentHeader
          startDate={startDate}
          endDate={endDate}
          name={selectedResource.name}
          note={selectedResource.note}
          allocations={allocations}
          onCustomClick={this.onCustomClick}
          projectAddress={projectAddress}
        />

        <AllocationModalContentStructure
          leftColumnClass={showFilterEdit ? 'width-50' : 'width-60'}
          rightColumnClass={showFilterEdit ? 'width-50' : 'width-40'}
          leftContent={leftContent}
          rightContent={rightContent}
        />

        {showCustomModal && (
          <RoleRequirementPopper
            onHidePopper={this.onHideCustom}
            targetRef={this.customPopperTarget}
            roleRequirements={selectedResource.requirements}
            project={selectedProject}
          />
        )}
        <div key="footer-controls" className="footer-controls">
          <div className="allocated-people-container">
            { selectedPerson && (
              <SelectedPerson
                person={selectedPerson}
                onDelete={this.onClearSelectedPerson}
                showClearButton={showClearButton}
              />
            )}
          </div>
          <div className="submit-controls">
            { selectedPersonHasTimeOff && (
              <span className="warning-label">
                This person has time off during the selected dates
              </span>
            )}
            <EditControls
              disabled={!valid}
              secondaryAction={this.dismissModal}
              primaryAction={shouldShowNotifyModal ? this.showNotifyModal : this.onSaveAllocation}
              primaryText="Save"
              pending={updatePending}
              secondaryText="Cancel"
            />
          </div>
        </div>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps({
  accountSettings,
  projects,
  people,
  common,
  allocations,
  queries,
  launchDarkly,
  accounts,
}) {
  const { personFields, accountModules } = accountSettings;
  const { entities, personAllocations } = people;
  const { roleAvailabilities, originalRoleAvailabilities, radius } = allocations;
  const { accountId, multiStateModal, activeView, contentView } = common;
  const { showProjectOnCancel, modalOrigin } = multiStateModal;
  const { benchy20 } = launchDarkly;
  const { getProjectNamesPending, addProjectRoleAllocationPending, replaceProjectRoleAllocationPending, getProjectRolePending, activeRole } = projects;
  const { entities: accountEntities } = accounts;

  const loading =
    people.getPersonFieldValuesPending ||
    accountSettings.getPersonFieldsPending ||
    getProjectRolePending ||
    getProjectNamesPending;

  const updatePending =
    addProjectRoleAllocationPending ||
    replaceProjectRoleAllocationPending;

  const { useMetricUnits } = accountEntities.find(({ id }) => id === accountId) || {};
  const { conversionFactorMetric } = useMetricUnits ? KM : MI;

  return {
    roleAvailabilities,
    originalRoleAvailabilities,
    radius,
    personFields,
    people: {
      entities,
    },
    personAllocations,
    accountId,
    loading,
    updatePending,
    queries: queries[ROLE_AVAILABILITIES_QUERY_ID],
    getFilteredRoleAvailabilitiesPending: allocations.getFilteredRoleAvailabilitiesPending,
    showProjectOnCancel,
    modalOrigin,
    benchy20Flag: benchy20,
    accountModules,
    activeRole,
    conversionFactorMetric,
    activeView,
    contentView,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    accountActions: bindActionCreators({ getPersonFields }, dispatch),
    peopleActions: bindActionCreators({ getPersonFieldValues, getPersonAllocationsByDate, getPersonAllocations }, dispatch),
    projectActions: bindActionCreators({ addProjectRoleAllocation, replaceProjectRoleAllocation, getProjectNames }, dispatch),
    getFilteredRoleAvailabilities: bindActionCreators(getFilteredRoleAvailabilities, dispatch),
    setFilterQuery: bindActionCreators(setFilterQuery, dispatch),
    clearQueries: bindActionCreators(clearQueries, dispatch),
    clearSelectedProjectId: bindActionCreators(clearSelectedProjectId, dispatch),
    closeModal: bindActionCreators(closeModal, dispatch),
    setModalActiveView: bindActionCreators(setModalActiveView, dispatch),
    setBackButtonState: bindActionCreators(setBackButtonState, dispatch),
    sendRoleNotification: bindActionCreators(sendRoleNotification, dispatch),
    getProjectRole: bindActionCreators(getProjectRole, dispatch),
    setRadius: bindActionCreators(setRadius, dispatch),
    trackAnalytics: bindActionCreators(trackAnalytics, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AllocatePerson);
