import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Done } from '@material-ui/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { hideSuccessToast } from './redux/actions';

export const SuccessToast = ({
  showSuccess,
  successMessage,
  hideSuccessToast,
}) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (showSuccess) {
      setTimeout(() => setIsMounted(true), 1);
      setTimeout(() => {
        setIsMounted(false);
        setTimeout(() => hideSuccessToast(), 501);
      }, 3000);
    }
  }, [showSuccess, hideSuccessToast]);

  if (!showSuccess) return null;

  return (
    <div className={classNames('toasts-success-toast', { 'slide-in': isMounted })}>
      <div className="toast">
        <Done className="checkmark" />
        <span className="text">{successMessage}</span>
      </div>
    </div>
  );
};

SuccessToast.propTypes = {
  showSuccess: PropTypes.bool.isRequired,
  successMessage: PropTypes.string.isRequired,
  hideSuccessToast: PropTypes.func.isRequired,
};

/* istanbul ignore next */
function mapStateToProps({ toasts }) {
  const { showSuccess, successMessage } = toasts;
  return {
    showSuccess,
    successMessage,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    hideSuccessToast: bindActionCreators(hideSuccessToast, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SuccessToast);
