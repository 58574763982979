import { gql } from 'apollo-boost';
import { momentToString } from '../../../utils/dateUtils';

const IDLE_TIME_BREAKDOWN_QUERY = gql`
    query idleTimeBreakdownQuery($accountId: Int!, $trueValue: Boolean!, $startDate: String!, $endDate: String!, $fieldId: Int!) {
      idleTimeBreakdown(accountId: $accountId, trueValue: $trueValue, startDate: $startDate, endDate: $endDate, fieldId: $fieldId) {
        items {
          title
          barData {
              x
              y
              numPeople
          }
          thisMonth {
            numPeople
            idleTime
          }
        }
        count
      }
    }
  `;

const getIdleTimeBreakdownQuery = (accountId, startDate, endDate, fieldId) => {
  const variables = {
    accountId,
    trueValue: true,
    startDate: momentToString(startDate),
    endDate: momentToString(endDate),
    fieldId,
  };

  return {
    query: IDLE_TIME_BREAKDOWN_QUERY,
    variables,
  };
};

export {
  getIdleTimeBreakdownQuery,
};
