import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNT_SETTINGS_GET_ACCOUNT_MODULES_BEGIN,
  ACCOUNT_SETTINGS_GET_ACCOUNT_MODULES_SUCCESS,
  ACCOUNT_SETTINGS_GET_ACCOUNT_MODULES_FAILURE,
  ACCOUNT_SETTINGS_GET_ACCOUNT_MODULES_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';

export function getAccountModules(accountId) {
  return {
    type: ACCOUNT_SETTINGS_GET_ACCOUNT_MODULES_BEGIN,
    accountId,
  };
}

export function dismissGetAccountModulesError() {
  return {
    type: ACCOUNT_SETTINGS_GET_ACCOUNT_MODULES_DISMISS_ERROR,
  };
}

export function* doGetAccountModules(action) {
  const { accountId } = action;
  let accountModules;

  try {
    accountModules = yield call(protectedJsonGet, `accounts/${accountId}/modules`);
  } catch (err) {
    yield put({
      type: ACCOUNT_SETTINGS_GET_ACCOUNT_MODULES_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: ACCOUNT_SETTINGS_GET_ACCOUNT_MODULES_SUCCESS,
    accountModules,
  });
}

export function* watchGetAccountModules() {
  yield takeLatest(ACCOUNT_SETTINGS_GET_ACCOUNT_MODULES_BEGIN, doGetAccountModules);
}

export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNT_SETTINGS_GET_ACCOUNT_MODULES_BEGIN:
      return {
        ...state,
        getAccountModulesPending: true,
        getAccountModulesError: null,
      };

    case ACCOUNT_SETTINGS_GET_ACCOUNT_MODULES_SUCCESS: {
      const { accountModules } = action;
      return {
        ...state,
        accountModules,
        getAccountModulesPending: false,
        getAccountModulesError: null,
      };
    }
    case ACCOUNT_SETTINGS_GET_ACCOUNT_MODULES_FAILURE:
      return {
        ...state,
        getAccountModulesPending: false,
        getAccountModulesError: action.data.error,
      };

    case ACCOUNT_SETTINGS_GET_ACCOUNT_MODULES_DISMISS_ERROR:
      return {
        ...state,
        getAccountModulesError: null,
      };

    default:
      return state;
  }
}
