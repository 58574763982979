import { gql } from 'apollo-boost';
import moment from 'moment';
import { generateRequestFilter } from 'src/filters/graphqlUtils';
import { PEOPLE_SCHEMAS } from 'src/common/descriptors/person';
import { DATE_INPUT_FORMAT } from 'src/common/constants';

const UTILIZATION_DATA_QUERY = gql`
    query utilizationDataQuery($accountId: Int!, $filters: [ESQuery], $trueValue: Boolean!, $overviewStartDate: String!, $overviewEndDate: String!, $isPursuitsEnabled: Boolean!, $includePursuitIds: [Int]){
      awardedOnlyOverview: forecastingOverview(accountId: $accountId, filters: $filters, trueValue: $trueValue, startDate: $overviewStartDate, endDate: $overviewEndDate, includePursuitIds: []) {
        ...overviewFields
      }
      scenarioOverview: forecastingOverview(accountId: $accountId, filters: $filters, trueValue: $trueValue, startDate: $overviewStartDate, endDate: $overviewEndDate, includePursuitIds: $includePursuitIds) @include(if: $isPursuitsEnabled) {
        ...overviewFields
      }
    }
    fragment overviewFields on ForecastingOverviewResponse {
      count
      items {
        x
        y
        stats {
          fullyAllocated
          underAllocated
          overAllocated
        }
      }
    }
  `;

const getUtilizationDataQuery = (accountId, queries, trueValue, overviewRange, isPursuitsEnabled, includePursuitIds) => {
  const overviewStartDate = moment().startOf('month').format(DATE_INPUT_FORMAT);
  const overviewEndDate = moment().endOf('month').add(overviewRange, 'y').format(DATE_INPUT_FORMAT);

  const { filter } = queries;
  const filters = generateRequestFilter(filter, PEOPLE_SCHEMAS);

  const variables = {
    accountId,
    filters,
    trueValue,
    overviewStartDate,
    overviewEndDate,
    isPursuitsEnabled,
    includePursuitIds,
  };

  return {
    query: UTILIZATION_DATA_QUERY,
    variables,
  };
};

export {
  getUtilizationDataQuery,
};
