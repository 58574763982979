import { all, put, takeLatest } from 'redux-saga/effects';
import { doGetAccountModules } from 'src/features/account-settings/redux/getAccountModules';
import {
  COMMON_COLLECT_APP_PREREQUISITES_BEGIN,
  COMMON_COLLECT_APP_PREREQUISITES_SUCCESS,
  COMMON_COLLECT_APP_PREREQUISITES_FAILURE,
  COMMON_COLLECT_APP_PREREQUISITES_DISMISS_ERROR,
} from './constants';

export function collectAppPrerequisites(accountId) {
  return {
    type: COMMON_COLLECT_APP_PREREQUISITES_BEGIN,
    accountId,
  };
}

export function dismissCollectAppPrerequisitesError() {
  return {
    type: COMMON_COLLECT_APP_PREREQUISITES_DISMISS_ERROR,
  };
}

export function* doCollectAppPrerequisites(action) {
  const { accountId } = action;
  try {
    yield all([
      doGetAccountModules({ accountId }),
    ]);
  } catch (err) {
    yield put({
      type: COMMON_COLLECT_APP_PREREQUISITES_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: COMMON_COLLECT_APP_PREREQUISITES_SUCCESS,
  });
}

export function* watchCollectAppPrerequisites() {
  yield takeLatest(COMMON_COLLECT_APP_PREREQUISITES_BEGIN, doCollectAppPrerequisites);
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_COLLECT_APP_PREREQUISITES_BEGIN:
      return {
        ...state,
        collectAppPrerequisitesPending: true,
        collectAppPrerequisitesError: null,
      };

    case COMMON_COLLECT_APP_PREREQUISITES_SUCCESS:
      return {
        ...state,
        collectAppPrerequisitesPending: false,
        collectAppPrerequisitesError: null,
      };

    case COMMON_COLLECT_APP_PREREQUISITES_FAILURE:
      return {
        ...state,
        collectAppPrerequisitesPending: false,
        collectAppPrerequisitesError: action.data.error,
      };

    case COMMON_COLLECT_APP_PREREQUISITES_DISMISS_ERROR:
      return {
        ...state,
        collectAppPrerequisitesError: null,
      };

    default:
      return state;
  }
}
