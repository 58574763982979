const initialState = {
  projects: [],
  projectNames: [],
  projectFields: [],
  projectFieldValues: [],
  expandedAllocations: [],
  projectRoleAvailabilities: {},
  projectRoles: [],
  projectAllocations: {},
  filteredProjects: [],
  pageFrom: 0,
  projectSelections: {},
  projectAudit: null,
  getProjectsPending: false,
  getProjectsError: null,
  getProjectFieldValuesPending: false,
  getProjectFieldValuesError: null,
  addProjectPending: false,
  addProjectError: null,
  updateProjectPending: false,
  updateProjectError: null,
  addProjectRolesPending: false,
  addProjectRolesError: null,
  getProjectRoleAvailabilitiesPending: false,
  getProjectRoleAvailabilitiesError: null,
  addProjectRoleAllocationPending: false,
  addProjectRoleAllocationError: null,
  getProjectAllocationsPending: false,
  getProjectAllocationsError: null,
  getProjectPending: false,
  getProjectError: null,
  replaceProjectRoleAllocationPending: false,
  replaceProjectRoleAllocationError: null,
  deleteProjectRolePending: false,
  deleteProjectRoleError: null,
  deleteProjectPending: false,
  deleteProjectError: null,
  deleteProjectAllocationPending: false,
  deleteProjectAllocationError: null,
  updateProjectRolePending: false,
  updateProjectRoleError: null,
  updateProjectRoleAllocationPending: false,
  updateProjectRoleAllocationError: null,
  getExpandedAllocationsPending: false,
  getExpandedAllocationsError: null,
  getFilteredProjectsPagePending: false,
  getFilteredProjectsPending: false,
  getFilteredProjectsError: null,
  getFilteredProjectsPageError: null,
  deleteProjectPhasePending: false,
  deleteProjectPhaseError: null,
  deleteProjectSubPhasePending: false,
  deleteProjectSubPhaseError: null,
  addProjectPhasesPending: false,
  addProjectPhasesError: null,
  addProjectSubPhasePending: false,
  addProjectSubPhaseError: null,
  getProjectNamesPending: false,
  getProjectNamesError: null,
  collectProjectTableDataPending: false,
  collectProjectTableDataError: null,
  updateProjectPhasePending: false,
  updateProjectPhaseError: null,
  updateProjectSubPhasePending: false,
  updateProjectSubPhaseError: null,
  updateProjectFieldValuePending: false,
  updateProjectFieldValueError: null,
  refreshAllocationMetaPending: false,
  refreshAllocationMetaError: null,
  getProjectByIdPending: false,
  getProjectByIdError: null,
  refreshProjectAllocationsPending: false,
  refreshProjectAllocationsError: null,
  triggerRefreshAllocationMeta: false,
  updateProjectDurationPending: false,
  updateProjectDurationError: null,
  getProjectDefaultsPending: false,
  getProjectDefaultsError: null,
  projectDefaults: {},
  sendRoleNotificationPending: false,
  sendRoleNotificationError: null,
  addProjectNotePending: false,
  addProjectNoteError: null,
  deleteProjectNotePending: false,
  deleteProjectNoteError: null,
  updateProjectNotePending: false,
  updateProjectNoteError: null,
  getProjectAuditPending: false,
  getProjectAuditError: null,
  getProjectRolesPending: false,
  getProjectRolesError: null,
  getProjectRolePending: false,
  getProjectRoleError: null,
};

export default initialState;
