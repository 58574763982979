import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PEOPLE_DOWNLOAD_PERSON_ATTACHMENT_BEGIN,
  PEOPLE_DOWNLOAD_PERSON_ATTACHMENT_SUCCESS,
  PEOPLE_DOWNLOAD_PERSON_ATTACHMENT_FAILURE,
  PEOPLE_DOWNLOAD_PERSON_ATTACHMENT_DISMISS_ERROR,
} from './constants';
import { downloadFile } from '../../../utils/api';

export function downloadPersonAttachment(url, analyticsPayload) {
  return {
    type: PEOPLE_DOWNLOAD_PERSON_ATTACHMENT_BEGIN,
    url,
    analyticsPayload,
  };
}

export function dismissDownloadPersonAttachmentError() {
  return {
    type: PEOPLE_DOWNLOAD_PERSON_ATTACHMENT_DISMISS_ERROR,
  };
}

export function* doDownloadPersonAttachment(action) {
  const { url, analyticsPayload } = action;

  try {
    yield call(downloadFile, url);
  } catch (err) {
    yield put({
      type: PEOPLE_DOWNLOAD_PERSON_ATTACHMENT_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PEOPLE_DOWNLOAD_PERSON_ATTACHMENT_SUCCESS,
    analyticsPayload,
  });
}

export function* watchDownloadPersonAttachment() {
  yield takeLatest(PEOPLE_DOWNLOAD_PERSON_ATTACHMENT_BEGIN, doDownloadPersonAttachment);
}

export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_DOWNLOAD_PERSON_ATTACHMENT_BEGIN:
      return {
        ...state,
        downloadPersonAttachmentPending: true,
        downloadPersonAttachmentError: null,
      };

    case PEOPLE_DOWNLOAD_PERSON_ATTACHMENT_SUCCESS:
      return {
        ...state,
        downloadPersonAttachmentPending: false,
        downloadPersonAttachmentError: null,
      };

    case PEOPLE_DOWNLOAD_PERSON_ATTACHMENT_FAILURE:
      return {
        ...state,
        downloadPersonAttachmentPending: false,
        downloadPersonAttachmentError: action.data.error,
      };

    case PEOPLE_DOWNLOAD_PERSON_ATTACHMENT_DISMISS_ERROR:
      return {
        ...state,
        downloadPersonAttachmentError: null,
      };

    default:
      return state;
  }
}
