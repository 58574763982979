import React from 'react';
import PropTypes from 'prop-types';
import { CustomRequirementRow } from '.';

const CustomRequirements = ({
  role,
  requirements,
  onRequirementChange,
  onError,
}) => (
  <div className="allocations-custom-requirements">
    { requirements.map((req) => {
      const { startDate, endDate, allocatedPercent } = req;

      return (
        <CustomRequirementRow
          key={`${startDate}-${endDate}`}
          reqStart={startDate}
          reqEnd={endDate}
          allocatedPercent={allocatedPercent}
          onUpdate={onRequirementChange}
          onError={onError}
          roleStart={role.startDate}
          roleEnd={role.endDate}
        />
      );
    })}
  </div>
);

CustomRequirements.propTypes = {
  role: PropTypes.object.isRequired,
  requirements: PropTypes.array.isRequired,
  onRequirementChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default CustomRequirements;
