const parseNotes = (notes, userId, hasAccountPerms) => (
  notes.map((note) => {
    const userIsAuthor = note.creatorId === userId;
    const showDelete = userIsAuthor || hasAccountPerms;
    return {
      ...note,
      showEdit: userIsAuthor,
      showDelete,
      userIsAuthor,
    };
  })
);

export {
  parseNotes,
};
