import initialState from './initialState';
import { reducer as setGanttConfigReducer } from './setGanttConfig';
import { reducer as setGanttConfigSettingsReducer } from './setGanttConfigSettings';
import { reducer as getPeopleGanttShareDataReducer } from './getPeopleGanttShareData';
import { reducer as setPeopleGanttShareDataReducer } from './setPeopleGanttShareData';
import { reducer as setPeopleGanttConfigSettingsReducer } from './setPeopleGanttConfigSettings';
import { reducer as clearPeopleGanttShareExpiryDateReducer } from './clearPeopleGanttShareExpiryDate';

const reducers = [
  setGanttConfigReducer,
  setGanttConfigSettingsReducer,
  getPeopleGanttShareDataReducer,
  setPeopleGanttShareDataReducer,
  setPeopleGanttConfigSettingsReducer,
  clearPeopleGanttShareExpiryDateReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
