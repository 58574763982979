import { call, put, takeLatest } from 'redux-saga/effects';
import {
  SETTINGS_INVITE_ADMIN_BEGIN,
  SETTINGS_INVITE_ADMIN_DISMISS_ERROR,
  SETTINGS_INVITE_ADMIN_SUCCESS,
  SETTINGS_INVITE_ADMIN_FAILURE,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';
import { PERM_BRIDGIT_ADMIN_VIEW_ONLY } from '../../../common/constants';

export function inviteAdmin(email, group = PERM_BRIDGIT_ADMIN_VIEW_ONLY) {
  // If need to pass args to saga, pass it with the begin action.
  return {
    type: SETTINGS_INVITE_ADMIN_BEGIN,
    email,
    group,
  };
}

export function dismissInviteAdminError() {
  return {
    type: SETTINGS_INVITE_ADMIN_DISMISS_ERROR,
  };
}

// worker Saga: will be fired on SETTINGS_INVITE_ADMIN_BEGIN actions
export function* doInviteAdmin(action) {
  const { email, group } = action;
  let res;
  try {
    res = yield call(protectedJsonPost, 'invitations', { email, group });
  } catch (err) {
    yield put({
      type: SETTINGS_INVITE_ADMIN_FAILURE,
      data: { error: err },
      email,
    });
    return;
  }
  // Dispatch success action out of try/catch so that render errors are not caught.
  yield put({
    type: SETTINGS_INVITE_ADMIN_SUCCESS,
    data: res,
  });
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchInviteAdmin() {
  yield takeLatest(SETTINGS_INVITE_ADMIN_BEGIN, doInviteAdmin);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case SETTINGS_INVITE_ADMIN_BEGIN:
      return {
        ...state,
        inviteAdminPending: true,
        inviteAdminError: null,
      };

    case SETTINGS_INVITE_ADMIN_SUCCESS: {
      const updatedInvitations = state.invitations.filter(i => i.email !== action.data.email).concat(action.data);

      return {
        ...state,
        invitations: updatedInvitations,
        inviteAdminPending: false,
        inviteAdminError: null,
      };
    }

    case SETTINGS_INVITE_ADMIN_FAILURE: {
      return {
        ...state,
        inviteAdminPending: false,
        inviteAdminError: action.data.error,
      };
    }

    case SETTINGS_INVITE_ADMIN_DISMISS_ERROR:
      return {
        ...state,
        inviteAdminError: null,
      };

    default:
      return state;
  }
}
