import { getDateRangeQuery } from './queryUtils';
import {
  BLANK_FILTER,
  EMPLOYMENT_DATES,
} from './constants';

const blankFilter = column => (
  {
    bool: {
      must_not: [
        {
          nested: {
            path: EMPLOYMENT_DATES,
            query: { exists: { field: column } },
          },
        },
      ],
    },
  }
);

const queryFilter = (activeFilter, column) => {
  const selected = activeFilter.selected[0];
  const query = getDateRangeQuery(selected, activeFilter.verb);

  return {
    nested: {
      path: EMPLOYMENT_DATES,
      query: {
        bool: {
          must: [
            { range: { [column]: query } },
          ],
        },
      },
    },
  };
};

export default function generateEmploymentDateFilter(activeFilter, column) {
  if (activeFilter.verb === BLANK_FILTER) {
    return blankFilter(column);
  }

  return queryFilter(activeFilter, column);
}
