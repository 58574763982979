import { call, put, takeLatest } from 'redux-saga/effects';
import {
  SETTINGS_REMOVE_ADMIN_BEGIN,
  SETTINGS_REMOVE_ADMIN_SUCCESS,
  SETTINGS_REMOVE_ADMIN_FAILURE,
  SETTINGS_REMOVE_ADMIN_DISMISS_ERROR,
} from './constants';
import { protectedJsonDelete } from '../../../utils/api';

export function removeAdmin(data) {
  // If need to pass args to saga, pass it with the begin action.
  return {
    type: SETTINGS_REMOVE_ADMIN_BEGIN,
    data,
  };
}

export function dismissRemoveAdminError() {
  return {
    type: SETTINGS_REMOVE_ADMIN_DISMISS_ERROR,
  };
}

// worker Saga: will be fired on SETTINGS_REMOVE_ADMIN_BEGIN actions
export function* doRemoveAdmin(action) {
  // If necessary, use argument to receive the begin action with parameters.
  // the delete operation will not return anything, get the id from SETTINGS_REMOVE_ADMIN_BEGIN.data
  try {
    yield call(protectedJsonDelete, `users/${action.data}`);
  } catch (err) {
    yield put({
      type: SETTINGS_REMOVE_ADMIN_FAILURE,
      data: { error: err },
    });
    return;
  }
  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: SETTINGS_REMOVE_ADMIN_SUCCESS,
    data: action.data,
  });
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchRemoveAdmin() {
  yield takeLatest(SETTINGS_REMOVE_ADMIN_BEGIN, doRemoveAdmin);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case SETTINGS_REMOVE_ADMIN_BEGIN:
      return {
        ...state,
        removeAdminPending: true,
        removeAdminError: null,
      };

    case SETTINGS_REMOVE_ADMIN_SUCCESS: {
      const updatedBridgitAdminList = state.bridgitAdmins.filter(admin => admin.id !== action.data);

      return {
        ...state,
        bridgitAdmins: updatedBridgitAdminList,
        removeAdminPending: false,
        removeAdminError: null,
      };
    }

    case SETTINGS_REMOVE_ADMIN_FAILURE:
      return {
        ...state,
        removeAdminPending: false,
        removeAdminError: action.data.error,
      };

    case SETTINGS_REMOVE_ADMIN_DISMISS_ERROR:
      return {
        ...state,
        removeAdminError: null,
      };

    default:
      return state;
  }
}
