import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@bridgit/foundation';
import { SimpleTable } from '../common';
import { MAX_ALLOWED_SERVICE_ACCOUNTS, SERVICE_ACCOUNTS_LIST_COLUMNS } from './constants';
import { getSortedAccounts } from './utils/serviceAccoutsListUtils';
import { selectServiceAccount } from './redux/actions';
import { getPermissionGroups } from '../permissions/redux/actions';

const ServiceAccountsList = ({ onNextClick }) => {
  const dispatch = useDispatch();

  const { accounts } = useSelector(({ serviceAccounts }) => serviceAccounts);
  const { accountId } = useSelector(({ common }) => common);
  const { permissionGroups } = useSelector(({ permissions }) => permissions);

  useEffect(() => {
    if (!permissionGroups.length) dispatch(getPermissionGroups(accountId));
  }, [accountId, permissionGroups.length, dispatch]);

  const accountsData = useMemo(() => {
    if (!accounts.length) return [];

    return getSortedAccounts(accounts, 'name');
  }, [accounts]);

  const handleRowClick = useCallback((selectedAccountId) => {
    const { name } = accounts.find(({ id }) => id === selectedAccountId);
    const analyticsPayload = { name };

    dispatch(selectServiceAccount(selectedAccountId, analyticsPayload));

    onNextClick();
  }, [accounts, dispatch, onNextClick]);

  const isServiceAccountLimitReached = accounts.length >= MAX_ALLOWED_SERVICE_ACCOUNTS;

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        className="add-button"
        onClick={onNextClick}
        disabled={isServiceAccountLimitReached}
      >
        Add service account
      </Button>

      <SimpleTable
        className="table-table"
        cols={SERVICE_ACCOUNTS_LIST_COLUMNS}
        rows={accountsData}
        onRowClick={handleRowClick}
        noDataText="No service accounts have been added."
      />
    </>
  );
};

ServiceAccountsList.propTypes = {
  onNextClick: PropTypes.func.isRequired,
};

export default ServiceAccountsList;
