export function normalizeFields(fields) {
  if (!fields) return [];

  return fields.map((field) => {
    if (field.type === 'Boolean') {
      return {
        ...field,
        values: (field.values?.[0] === 'true' || field.values?.[0] === true) ? ['true'] : ['false'],
      };
    }
    return field;
  });
}
