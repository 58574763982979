import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, FormHelperText, Input, FormControl, Grid } from '@material-ui/core';
import { Form } from 'react-final-form';
import { Field } from 'react-final-form-html5-validation';
import { UserPermissionOptions } from '../accounts';

export class Invite extends PureComponent {
  static propTypes = {
    addUser: PropTypes.func.isRequired,
    hidePermOptions: PropTypes.bool,
    admins: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    hidePermOptions: false,
    admins: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedRole: 'admin',
    };
  }

  inviteAdmin = (values) => {
    const { addUser } = this.props;
    const { selectedRole } = this.state;
    addUser(values.email, selectedRole);
    const reset = values;
    reset.email = '';
  };

  onUserPermissionChange = (evt) => {
    this.setState({
      selectedRole: evt.target.value,
    });
  }

  checkIfAdmin = (value) => {
    const { admins } = this.props;
    return admins.some(({ email }) => email === value) ? 'User is either already a Bridgit Admin, or has been invited.' : undefined;
  }

  render() {
    const { hidePermOptions } = this.props;
    const { selectedRole } = this.state;
    return (
      <Form
        onSubmit={this.inviteAdmin}
        render={({ handleSubmit, values }) => (
          <form className="invite" onSubmit={handleSubmit} noValidate>
            <Grid container alignItems="flex-start">
              <Grid item xs={8}>
                <Field
                  validate={this.checkIfAdmin}
                  component="input"
                  type="email"
                  name="email"
                  placeholder="Invite more users via email"
                  inputProps={{ 'aria-label': 'Admin invite' }}
                  tooLong="Please enter a valid email address."
                  tooShort="Please enter a valid email address."
                  typeMismatch="Please enter a valid email address."
                  valueMissing="Please enter a valid email address."
                  patternMismatch="Please enter a valid email address."
                >
                  {({ input, meta: { error, touched }, ...rest }) => (
                    <FormControl fullWidth>
                      <Input {...input} {...rest} inputProps={{ maxLength: 254, minLength: 6, pattern: '[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{1,63}$', autoComplete: 'off' }} />
                      {error && touched && (
                        <FormHelperText className="form__warning">{error}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                </Field>
              </Grid>
              <Grid container justify="flex-end" item xs={4}>
                <Button
                  className="button--large"
                  disableRipple
                  disabled={!values.email}
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Send Invite
                </Button>
              </Grid>
            </Grid>
            {!hidePermOptions && (
              <UserPermissionOptions
                selectedRole={selectedRole}
                onChange={this.onUserPermissionChange}
              />
            )}
          </form>
        )}
      />
    );
  }
}

export default Invite;
