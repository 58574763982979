import { call, put, takeLatest } from 'redux-saga/effects';
import { getSearchStringFromQuery } from 'src/features/filters/utils/filterUtils';
import {
  SELF_PERFORM_GET_BEST_MATCHES_BEGIN,
  SELF_PERFORM_GET_BEST_MATCHES_SUCCESS,
  SELF_PERFORM_GET_BEST_MATCHES_FAILURE,
  SELF_PERFORM_GET_BEST_MATCHES_DISMISS_ERROR,
  SELF_PERFORM_SEARCH_BEST_MATCHES_SUCCESS,
} from './constants';
import { getHourlyPersonsBestMatchesQuery } from '../queries/getHourlyPersonsBestMatchesQuery';
import { filterBestMatches } from '../utils/requestUtils';
import { protectedJsonGet } from '../../../utils/api';
import graphApiClient from '../../../common/GraphAPIClient';

export function getBestMatches(
  accountId,
  projectId,
  requestId,
  searchQuery = {},
  mixpanelData = {},
) {
  return {
    type: SELF_PERFORM_GET_BEST_MATCHES_BEGIN,
    accountId,
    projectId,
    requestId,
    searchQuery,
    mixpanelData,
  };
}

export function dismissGetBestMatchesError() {
  return {
    type: SELF_PERFORM_GET_BEST_MATCHES_DISMISS_ERROR,
  };
}

export function* doGetBestMatches(action) {
  const { accountId, projectId, requestId, searchQuery, mixpanelData } = action;
  let matches;
  let sortedPeople;

  try {
    matches = yield call(protectedJsonGet, `accounts/${accountId}/projects/${projectId}/hourly-requests/${requestId}/availabilities`);

    const { query, variables } = getHourlyPersonsBestMatchesQuery(accountId, matches, searchQuery);

    sortedPeople = yield call(graphApiClient.query, { query, variables });
  } catch (err) {
    yield put({
      type: SELF_PERFORM_GET_BEST_MATCHES_FAILURE,
      data: { error: err },
    });
    return;
  }

  const type = searchQuery
    ? SELF_PERFORM_SEARCH_BEST_MATCHES_SUCCESS
    : SELF_PERFORM_GET_BEST_MATCHES_SUCCESS;

  yield put({
    type,
    data: {
      matches,
      sortedPeople: sortedPeople?.data?.hourlyPersons?.items || [],
    },
    ...searchQuery && {
      requestId,
      projectId,
      projectName: mixpanelData?.projectName,
      searchString: getSearchStringFromQuery(({ search: searchQuery })),
    },
  });
}

export function* watchGetBestMatches() {
  yield takeLatest(SELF_PERFORM_GET_BEST_MATCHES_BEGIN, doGetBestMatches);
}

export function reducer(state, action) {
  switch (action.type) {
    case SELF_PERFORM_GET_BEST_MATCHES_BEGIN:
      return {
        ...state,
        getBestMatchesPending: true,
        getBestMatchesError: null,
      };

    case SELF_PERFORM_GET_BEST_MATCHES_SUCCESS:
    case SELF_PERFORM_SEARCH_BEST_MATCHES_SUCCESS: {
      const { matches, sortedPeople } = action.data;
      const { bestMatches, otherMatches } = filterBestMatches(sortedPeople, matches);

      return {
        ...state,
        bestMatches,
        otherMatches,
        getBestMatchesPending: false,
        getBestMatchesError: null,
      };
    }

    case SELF_PERFORM_GET_BEST_MATCHES_FAILURE:
      return {
        ...state,
        getBestMatchesPending: false,
        getBestMatchesError: action.data.error,
      };

    case SELF_PERFORM_GET_BEST_MATCHES_DISMISS_ERROR:
      return {
        ...state,
        getBestMatchesError: null,
      };

    default:
      return state;
  }
}
