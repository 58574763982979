import {
  PEOPLE_ADD_PERSON_NOTE_SUCCESS,
  PEOPLE_UPDATE_PERSON_NOTE_SUCCESS,
  PEOPLE_DELETE_PERSON_NOTE_SUCCESS,
  PEOPLE_UPLOAD_PERSON_ATTACHMENT_SUCCESS,
  PEOPLE_DOWNLOAD_PERSON_ATTACHMENT_SUCCESS,
  PEOPLE_ADD_UNAVAILABILITY_SUCCESS,
  PEOPLE_UPDATE_UNAVAILABILITY_SUCCESS,
  PEOPLE_DELETE_UNAVAILABILITY_SUCCESS,
  PEOPLE_ADD_PERSON_SUCCESS,
  PEOPLE_DELETE_PERSON_ATTACHMENT_SUCCESS,
  PEOPLE_REMOVE_PERSON_SUCCESS,
  PEOPLE_REACTIVATE_PERSON_SUCCESS,
  PEOPLE_REMOVE_PROFILE_PICTURE_SUCCESS,
  PEOPLE_SET_PROFILE_PICTURE_SUCCESS,
  PEOPLE_UPDATE_PERSON_SUCCESS,
  PEOPLE_UPDATE_PERSON_FIELD_VALUE_SUCCESS,
  PEOPLE_ADD_PERSON_CERTIFICATION_SUCCESS,
  PEOPLE_UPDATE_PERSON_CERTIFICATION_SUCCESS,
  PEOPLE_DELETE_PERSON_CERTIFICATION_SUCCESS,
  PEOPLE_MANAGE_CERTIFICATION_ATTACHMENTS,
  PEOPLE_HIDE_CERTIFICATION_ATTACHMENTS,
} from 'src/features/people/redux/constants';
import {
  PERSON_NOTE_ADDED,
  PERSON_NOTE_EDITED,
  PERSON_NOTE_DELETED,
  UPLOAD_PROFILE_ATTACHMENT,
  DOWNLOAD_PROFILE_ATTACHMENT,
  TIME_OFF_ADDED,
  TIME_OFF_UPDATED,
  TIME_OFF_DELETED,
  PERSON_ADDED,
  DELETE_PROFILE_ATTACHMENT,
  PERSON_DEACTIVATED,
  PERSON_REACTIVATED,
  PERSON_DELETE_PROFILE_PICTURE,
  PERSON_UPLOAD_PROFILE_PICTURE,
  PERSON_ADD_CERTIFICATION,
  PERSON_EDIT_CERTIFICATION,
  PERSON_DELETE_CERTIFICATION,
  MANAGE_CERTIFICATION_ATTACHMENTS,
  HIDE_CERTIFICATION_ATTACHMENTS,
} from './constants';
import {
  getUpdatePersonEvent,
  getUpdatePersonPayload,
} from './utils';
import { getFileExtension } from '../../utils/miscUtils';

const events = {
  [PEOPLE_ADD_PERSON_NOTE_SUCCESS]: {
    getEvent: () => PERSON_NOTE_ADDED,
    getPayload: (state, action) => {
      const { data: { isPrivate }, analyticsPayload } = action;
      const { personId, personName } = analyticsPayload;

      return {
        'Person Name': personName,
        'Person ID': personId,
        'Is Private': isPrivate,
      };
    },
  },

  [PEOPLE_UPDATE_PERSON_NOTE_SUCCESS]: {
    getEvent: () => PERSON_NOTE_EDITED,
    getPayload: (state, { analyticsPayload }) => {
      const { personId, personName } = analyticsPayload;

      return {
        'Person Name': personName,
        'Person ID': personId,
      };
    },
  },

  [PEOPLE_DELETE_PERSON_NOTE_SUCCESS]: {
    getEvent: () => PERSON_NOTE_DELETED,
    getPayload: (state, { analyticsPayload }) => {
      const { personId, personName, isOwn } = analyticsPayload;

      return {
        'Person Name': personName,
        'Person ID': personId,
        'Is own': isOwn,
      };
    },
  },

  [PEOPLE_UPLOAD_PERSON_ATTACHMENT_SUCCESS]: {
    getEvent: () => UPLOAD_PROFILE_ATTACHMENT,
    getPayload: (state, { analyticsPayload }) => {
      const {
        personId,
        personName,
        uploadedFrom,
        file: { size, name },
        isPrivate,
        tabUploadedFrom,
        certificationName = null,
        daysWarnBeforeExpire = null,
        requireExpiration = null,
      } = analyticsPayload;

      return {
        'Person name': personName,
        'Person id': personId,
        'Uploaded from': uploadedFrom,
        'File Extension': getFileExtension(name),
        'File size': size,
        Private: isPrivate,
        'Tab uploaded from': tabUploadedFrom,
        'Certification name': certificationName,
        'Expiration date required': requireExpiration,
        'Warning range': daysWarnBeforeExpire,
      };
    },
  },

  [PEOPLE_DOWNLOAD_PERSON_ATTACHMENT_SUCCESS]: {
    getEvent: () => DOWNLOAD_PROFILE_ATTACHMENT,
    getPayload: (state, { analyticsPayload }) => {
      const {
        personId,
        personName,
        downloadedFrom,
        fileExtension,
        size,
        activeTab,
        certificationName,
        isExpiryDateRequired,
        warningRange,
        isPrivate,
      } = analyticsPayload;

      return {
        'Person name': personName,
        'Person id': personId,
        'Downloaded from': downloadedFrom,
        'File Extension': fileExtension,
        'File size': size,
        'Tab downloaded from': activeTab,
        'Certification name': certificationName,
        'Expiration date required': isExpiryDateRequired,
        'Warning range': warningRange,
        Private: isPrivate,
      };
    },
  },

  [PEOPLE_DELETE_PERSON_ATTACHMENT_SUCCESS]: {
    getEvent: () => DELETE_PROFILE_ATTACHMENT,
    getPayload: (state, { analyticsPayload }) => {
      const {
        personId,
        personName,
        deletedFrom,
        fileExtension,
        size,
        activeTab,
        certificationName,
        isExpiryDateRequired,
        warningRange,
        isPrivate,
      } = analyticsPayload;

      return {
        'Person name': personName,
        'Person id': personId,
        'Deleted from': deletedFrom,
        'File Extension': fileExtension,
        'File size': size,
        'Tab deleted from': activeTab,
        'Certification name': certificationName,
        'Expiration date required': isExpiryDateRequired,
        'Warning range': warningRange,
        Private: isPrivate,
      };
    },
  },

  [PEOPLE_ADD_UNAVAILABILITY_SUCCESS]: {
    getEvent: () => TIME_OFF_ADDED,
    getPayload: (state, { analyticsPayload }) => {
      const {
        endDate,
        description,
        isPrivate,
        personName,
        personId,
        affectedProjects,
        runsInModal,
        hasBackfill,
        lengthOfTimeOff = null,
      } = analyticsPayload;

      return {
        'End date added': !!endDate,
        Description: description,
        'Private Description': isPrivate,
        'Person name': personName,
        'Person id': personId,
        'Requires backfill': hasBackfill,
        'Overlaps with allocations': !!affectedProjects.length,
        'Number of overlapping projects': affectedProjects.length,
        'Added from': runsInModal ? 'Modal' : 'List view',
        ...(lengthOfTimeOff && { 'Length of time off': lengthOfTimeOff }),
      };
    },
  },

  [PEOPLE_UPDATE_UNAVAILABILITY_SUCCESS]: {
    getEvent: () => TIME_OFF_UPDATED,
    getPayload: (state, { analyticsPayload }) => {
      const { personName, personId, runsInModal, fieldsUpdated } = analyticsPayload;

      return {
        'Person name': personName,
        'Person id': personId,
        'Fields updated': fieldsUpdated,
        'Updated from': runsInModal ? 'Modal' : 'List view',
      };
    },
  },

  [PEOPLE_DELETE_UNAVAILABILITY_SUCCESS]: {
    getEvent: () => TIME_OFF_DELETED,
    getPayload: (state, { analyticsPayload }) => {
      const { personName, personId, runsInModal } = analyticsPayload;

      return {
        'Person name': personName,
        'Person id': personId,
        'Updated from': runsInModal ? 'Modal' : 'List view',
      };
    },
  },

  [PEOPLE_ADD_PERSON_SUCCESS]: {
    getEvent: () => PERSON_ADDED,
    getPayload: (state, { person, modalOrigin }) => {
      const { id, name } = person;

      return {
        'Person id': id,
        'Person name': name,
        'Person added from': modalOrigin,
      };
    },
  },

  [PEOPLE_UPDATE_PERSON_SUCCESS]: {
    getEvent: getUpdatePersonEvent,
    getPayload: getUpdatePersonPayload,
  },

  [PEOPLE_UPDATE_PERSON_FIELD_VALUE_SUCCESS]: {
    getEvent: getUpdatePersonEvent,
    getPayload: getUpdatePersonPayload,
  },

  [PEOPLE_REMOVE_PERSON_SUCCESS]: {
    getEvent: () => PERSON_DEACTIVATED,
    getPayload: (state, { personName, personId, parentName }) => {
      const allocations = state.people.currentAllocations?.[personId] ?? [];

      return {
        'Person name': personName,
        'Person id': personId,
        'Has allocations': allocations.length > 0,
        'Deactivated from': parentName,
      };
    },
  },

  [PEOPLE_REACTIVATE_PERSON_SUCCESS]: {
    getEvent: () => PERSON_REACTIVATED,
    getPayload: () => null,
  },

  [PEOPLE_REMOVE_PROFILE_PICTURE_SUCCESS]: {
    getEvent: () => PERSON_DELETE_PROFILE_PICTURE,
    getPayload: (state, { personName, personId, parentName }) => ({
      'Person Name': personName,
      'Person ID': personId,
      'Deleted an image from': parentName,
    }),
  },

  [PEOPLE_SET_PROFILE_PICTURE_SUCCESS]: {
    getEvent: () => PERSON_UPLOAD_PROFILE_PICTURE,
    getPayload: (state, { analyticsPayload }) => {
      const { personName, personId, uploadedFrom } = analyticsPayload;

      return {
        'Person Name': personName,
        'Person ID': personId,
        'Upload An Image From': uploadedFrom,
      };
    },
  },

  [PEOPLE_ADD_PERSON_CERTIFICATION_SUCCESS]: {
    getEvent: () => PERSON_ADD_CERTIFICATION,
    getPayload: (_, { personId, data, analyticsPayload }) => {
      const { name, expiryDate, daysWarnBeforeExpire } = data;
      const { personName, currentView, requireExpiration } = analyticsPayload;

      return {
        'Person name': personName,
        'Person id': personId,
        'Added from': currentView,
        'Certification name': name,
        'Expiration date required': requireExpiration,
        'Expiration date': expiryDate,
        'Warning range': daysWarnBeforeExpire,
      };
    },
  },

  [PEOPLE_UPDATE_PERSON_CERTIFICATION_SUCCESS]: {
    getEvent: () => PERSON_EDIT_CERTIFICATION,
    getPayload: (_, { personId, data, analyticsPayload }) => {
      const { expiryDate } = data;
      const {
        personName,
        currentView,
        certificationName,
        daysWarnBeforeExpire,
        requireExpiration,
      } = analyticsPayload;

      return {
        'Person name': personName,
        'Person id': personId,
        'Edited from': currentView,
        'Certification name': certificationName,
        'Expiration date required': requireExpiration,
        'Expiration date': expiryDate,
        'Warning range': daysWarnBeforeExpire,
      };
    },
  },

  [PEOPLE_DELETE_PERSON_CERTIFICATION_SUCCESS]: {
    getEvent: () => PERSON_DELETE_CERTIFICATION,
    getPayload: (_, { personId, analyticsPayload }) => {
      const {
        personName,
        currentView,
        certificationName,
        expiryDate,
        daysWarnBeforeExpire,
        hasAttachment,
        requireExpiration,
      } = analyticsPayload;

      return {
        'Person name': personName,
        'Person id': personId,
        'Deleted from': currentView,
        'Certification name': certificationName,
        'Expiration date required': requireExpiration,
        'Expiration date': expiryDate,
        'Warning range': daysWarnBeforeExpire,
        'Had linked attachments': hasAttachment,
      };
    },
  },

  [PEOPLE_MANAGE_CERTIFICATION_ATTACHMENTS]: {
    getEvent: () => MANAGE_CERTIFICATION_ATTACHMENTS,
  },

  [PEOPLE_HIDE_CERTIFICATION_ATTACHMENTS]: {
    getEvent: () => HIDE_CERTIFICATION_ATTACHMENTS,
  },
};

export default events;
