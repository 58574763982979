import { gql } from 'apollo-boost';
import moment from 'moment';
import { DATE_INPUT_FORMAT } from 'src/common/constants';
import { generateRequestFilter } from 'src/filters/graphqlUtils';
import { PEOPLE_SCHEMAS } from '../../../common/descriptors/person';
import { PROJECT_SCHEMAS } from '../../../common/descriptors/project';
import { ALL_ISSUES, FILTER_ISSUES, FILTER_ROLES } from '../../../filters/constants';
import { getFilterIssues } from '../../filters/utils/filterUtils';

const PROJECT_IDS_PARAM = ', $projectIds: [Int]';

const PURSUIT_QUERY = `
  pursuits: workforceDemand(accountId: $accountId, filters: $projectFilters, filterIssues: $projectIssuesFilterType, startDate: $startDate, endDate: $endDate, roleNames: $roleNames, projectIds: $projectIds, projectType: Opportunity) {
    count
    items {
      x
      y
    }
  }
`;

const constructQuery = hasPursuitPerms => gql`
  query workforcePlanQuery($accountId: Int!, $peopleFilters: [ESQuery], $projectFilters: [ESQuery], $projectIssuesFilterType: Int, $startDate: String!, $endDate: String!, $roleNames: [String]${hasPursuitPerms ? PROJECT_IDS_PARAM : ''}){
    workforceSupply(accountId: $accountId, filters: $peopleFilters, startDate: $startDate, endDate: $endDate) {
      count
      items {
        x
        y
      }
    }
    projectDemand: workforceDemand(accountId: $accountId, filters: $projectFilters, filterIssues: $projectIssuesFilterType, startDate: $startDate, endDate: $endDate, roleNames: $roleNames, projectType: Awarded) {
      count
      items {
        x
        y
      }
    }
    ${hasPursuitPerms ? PURSUIT_QUERY : ''}
  }
`;

const getRolesNames = (rolesFilter) => {
  const filters = rolesFilter?.activeFilters;
  if (
    !filters ||
    filters.length !== 1 || // no combination filter
    !filters[0].inclusive || // no "Excludes"
    filters[0].selected?.some(({ value }) => !Number.isInteger(value)) // no filled/unfilled option
  ) return undefined;

  return filters[0].selected?.map(item => item.name);
};

const getWorkforcePlanQuery = (accountId, peopleQueries, projectQueries, startDate, endDate, projectIds, hasPursuitPerms) => {
  const { filter: peopleFilter } = peopleQueries;
  const { filter: projectFilter } = projectQueries;

  const hasProjectFilter = !!projectFilter?.args;
  const projectIssuesFilterType = hasProjectFilter ? getFilterIssues(projectFilter.args) : ALL_ISSUES;
  const projectFilters = hasProjectFilter ? { ...projectFilter, args: projectFilter.args.filter(arg => arg.filterType !== FILTER_ISSUES) } : {};

  const variables = {
    accountId,
    peopleFilters: generateRequestFilter(peopleFilter, PEOPLE_SCHEMAS),
    projectFilters: generateRequestFilter(projectFilters, PROJECT_SCHEMAS), // removed issues filter
    projectIssuesFilterType,
    roleNames: getRolesNames(projectFilter?.args?.find(arg => arg?.filterType === FILTER_ROLES)),
    projectIds,
    startDate: moment(startDate).format(DATE_INPUT_FORMAT),
    endDate: moment(endDate).format(DATE_INPUT_FORMAT),
  };

  return {
    query: constructQuery(hasPursuitPerms),
    variables,
  };
};

export {
  getWorkforcePlanQuery,
};
