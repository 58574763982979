import React, { useEffect, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { Grid, Button } from '@material-ui/core';
import { updateHourlyPerson } from './redux/updateHourlyPerson';
import { updateHourlyPersonFieldValue } from './redux/updateHourlyPersonFieldValue';
import { FieldEditor, Modal } from '../common';
import { PERM_HOURLY_PERSON, PERM_WRITE } from '../permissions/utils/constants';
import {
  CELL_NUMBER_FIELD,
  DATE_DISPLAY_FORMAT,
  FIELD_TYPE_BOOLEAN,
  FIELD_TYPE_DATE,
  FIELD_TYPE_LONG_TEXT,
  NOTES_FIELD,
} from '../../common/constants';
import { getHourlyPersonFields } from '../account-settings/redux/getHourlyPersonFields';
import { SKILL_SET_FIELD } from './constants';
import { generateHourlyPersonProfileDetailsInputs } from './utils/hourlyPersonUtils';
import { Can, EditControls } from '../wrapped-components';
import { deactivateHourlyPerson } from './redux/deactivateHourlyPerson';
import { formatPhoneNumberInput } from '../../utils/phoneNumberUtils';

export const HourlyPersonProfileDetail = ({
  updateHourlyPerson,
  deactivateHourlyPerson,
  deactivateHourlyPersonPending,
  updateHourlyPersonFieldValue,
  accountId,
  selectedHourlyPersonId,
  selectedHourlyPerson,
  updateHourlyPersonFieldValuePending,
  updateHourlyPersonPending,
  hourlyPersonFields,
  getHourlyPersonFields,
  skillSet,
}) => {
  useEffect(() => {
    getHourlyPersonFields(accountId);
  }, [getHourlyPersonFields, accountId]);
  const [fieldToEdit, setFieldToEdit] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const onSave = (data) => {
    const { name, values, fieldId } = data;
    if (name === SKILL_SET_FIELD) {
      updateHourlyPerson(accountId, selectedHourlyPersonId, { skillSet: values }, selectedHourlyPerson.name, 'List');
    } else if (name === CELL_NUMBER_FIELD) {
      const phoneNumber = values[0] ? formatPhoneNumberInput(values[0]) : null;
      updateHourlyPerson(accountId, selectedHourlyPersonId, { phoneNumber }, selectedHourlyPerson.name, 'List');
    } else {
      updateHourlyPersonFieldValue(accountId, selectedHourlyPersonId, fieldId, data, selectedHourlyPerson.name, 'List');
    }

    setFieldToEdit(null);
  };

  const openModal = useCallback(() => setShowModal(true), []);
  const closeModal = useCallback(() => setShowModal(false), []);

  const removeHourlyPerson = useCallback(() => {
    deactivateHourlyPerson(accountId, selectedHourlyPerson.id, selectedHourlyPerson.name);
    closeModal();
  }, [
    accountId,
    deactivateHourlyPerson,
    selectedHourlyPerson.id,
    selectedHourlyPerson.name,
    closeModal,
  ]);

  const { dataset, inputs } = useMemo(
    () => generateHourlyPersonProfileDetailsInputs(selectedHourlyPerson.fields, hourlyPersonFields, selectedHourlyPerson, skillSet),
    [hourlyPersonFields, selectedHourlyPerson, skillSet],
  );

  // Use set to avoid duplicate entries
  const assignmentNamesList = useMemo(
    () => [
      ...new Set(
        [
          ...selectedHourlyPerson.currentAssignments,
          ...selectedHourlyPerson.upcomingAssignments,
        ].map(({ projectName }) => <li key={projectName}>{projectName}</li>),
      ),
    ],
    [selectedHourlyPerson.currentAssignments, selectedHourlyPerson.upcomingAssignments],
  );

  const deactivateButton = useMemo(() => (
    (
      <div className="deactivate">
        <Button variant="outlined" onClick={openModal}>Deactivate Person</Button>
      </div>
    )
  ), [openModal]);

  const handleCancelEditing = () => setFieldToEdit(null);

  return (
    <div className="people-self-perform-profile-detail">
      {showModal && (
      <Modal
        className="deactivate-person-modal"
        headline="Deactivate Person"
        showClose={false}
      >
        <div className="deactivate-modal-text">
          <div>Are you sure you want to deactivate this person?</div>
          {!!assignmentNamesList.length && (
          <div>
            They will be removed from any current or upcoming assignments:
            <div>
              {assignmentNamesList}
            </div>
          </div>
          )}
        </div>
        <EditControls
          className="deactivate-modal-buttons"
          primaryAction={removeHourlyPerson}
          secondaryAction={closeModal}
          primaryText="Deactivate"
          pending={deactivateHourlyPersonPending}
        />
      </Modal>
      )}

      <Grid
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        container
        spacing={1}
      >
        {dataset.map((dataItem) => {
          const field = { ...dataItem };
          const checked = field.type === FIELD_TYPE_BOOLEAN && field?.value?.toString().toLowerCase() === 'true';

          if (field.type === FIELD_TYPE_DATE && field.value && moment(field.value).isValid()) {
            field.value = moment(field.value).format(DATE_DISPLAY_FORMAT);
          }

          const input = inputs.find(({ name }) => name === field.id.toString());

          return (
            <div key={field.id} className={field.type === FIELD_TYPE_LONG_TEXT && field.name === NOTES_FIELD ? 'notes-wrapper' : 'item-wrap'}>
              <FieldEditor
                item={field}
                checked={checked}
                input={input}
                onSave={onSave}
                onCancel={handleCancelEditing}
                disabled={fieldToEdit !== null && fieldToEdit !== field.id}
                permission={{ action: PERM_WRITE, subject: PERM_HOURLY_PERSON }}
                pending={field.name === SKILL_SET_FIELD ? updateHourlyPersonPending : updateHourlyPersonFieldValuePending}
                fieldToEdit={() => setFieldToEdit(field.id)}
                editing={fieldToEdit === field.id}
              />
            </div>
          );
        })}
      </Grid>

      <Can
        action={PERM_WRITE}
        subject={PERM_HOURLY_PERSON}
        yes={deactivateButton}
      />
    </div>
  );
};

HourlyPersonProfileDetail.propTypes = {
  updateHourlyPerson: PropTypes.func.isRequired,
  deactivateHourlyPerson: PropTypes.func.isRequired,
  deactivateHourlyPersonPending: PropTypes.bool.isRequired,
  updateHourlyPersonFieldValue: PropTypes.func.isRequired,
  selectedHourlyPersonId: PropTypes.number.isRequired,
  accountId: PropTypes.number.isRequired,
  selectedHourlyPerson: PropTypes.object.isRequired,
  updateHourlyPersonFieldValuePending: PropTypes.bool.isRequired,
  updateHourlyPersonPending: PropTypes.bool.isRequired,
  hourlyPersonFields: PropTypes.array.isRequired,
  getHourlyPersonFields: PropTypes.func.isRequired,
  skillSet: PropTypes.array.isRequired,
};

const mapStateToProps = ({
  selfPerform: {
    selectedHourlyPerson,
    updateHourlyPersonFieldValuePending,
    updateHourlyPersonPending,
    deactivateHourlyPersonPending,
  },
  common: { accountId },
  accountSettings: { hourlyPersonFields, skillSet },
}) => ({
  selectedHourlyPersonId: selectedHourlyPerson.id,
  accountId,
  selectedHourlyPerson,
  updateHourlyPersonFieldValuePending,
  updateHourlyPersonPending,
  deactivateHourlyPersonPending,
  hourlyPersonFields,
  skillSet,
});

const mapDispatchToProps = dispatch => ({
  updateHourlyPerson: bindActionCreators(updateHourlyPerson, dispatch),
  updateHourlyPersonFieldValue: bindActionCreators(updateHourlyPersonFieldValue, dispatch),
  getHourlyPersonFields: bindActionCreators(getHourlyPersonFields, dispatch),
  deactivateHourlyPerson: bindActionCreators(deactivateHourlyPerson, dispatch) });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HourlyPersonProfileDetail);
