import {
  Avatar,
  ClickAwayListener,
  Drawer,
  Grow,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Paper,
  Popper,
  Typography,
  Grid,
  Tooltip,
} from '@material-ui/core';
import {
  AccountCircle,
  ArrowDropDown,
  ChevronLeft,
  Settings,
  Language,
} from '@material-ui/icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { matchPath, Link, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { getPermissionsForAccount, hasWebPerms } from 'src/features/permissions/utils/permissionUtils';
import { PERM_ACCOUNT, PERM_WRITE } from 'src/features/permissions/utils/constants';
import { PERM_SUPER_ADMIN } from 'src/common/constants';
import * as actions from './redux/actions';
import { Can } from '../wrapped-components';
import { clearAllPersonSelections } from '../people/redux/actions';
import { clearAllProjectSelections } from '../projects/redux/actions';
import { BRIDGIT_ICON_WHITE, NAV_ITEMS } from './constants';
import { setContentView } from './redux/setContentView';
import {
  AUTOMATION_SIDENAV_ICON_PREFIX,
  AUTOMATION_POPPER_MENU,
} from './ids';
import { trackAnalytics } from './redux/actions';

const hasAccount = RegExp('/accounts/.+');
const hasManageProf = RegExp('/profile');

export class SideNav extends PureComponent {
  static propTypes = {
    accountId: PropTypes.number,
    collapsedNav: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    userInfo: PropTypes.object,
    accounts: PropTypes.array,
    history: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    clearAllPersonSelections: PropTypes.func.isRequired,
    clearAllProjectSelections: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    setContentView: PropTypes.func.isRequired,
    contentView: PropTypes.number.isRequired,
    hasWebPermissions: PropTypes.bool,
    trackAnalytics: PropTypes.func.isRequired,
  };

  static defaultProps = {
    accountId: null,
    loading: false,
    userInfo: {},
    accounts: [],
    hasWebPermissions: false,
  };

  constructor(props) {
    super(props);

    this.paths = {
      dashboard: [
        '/accounts/:id/dashboard',
        '/accounts/:id/dashboard/snapshot',
        '/accounts/:id/dashboard/forecasting',
      ],
      people: ['/accounts/:id/people/:contentView', '/accounts/:id/people/:contentView/:peopleId'],
      projects: ['/accounts/:id/projects/:contentView', '/accounts/:id/projects/:contentView/:projectId'],
      roles: ['/accounts/:id/roles'],
      settings: ['/accounts/:id/settings/:type/:subType'],
      accounts: ['/'],
      manageAdmins: ['/settings'],
      'self-perform': ['/accounts/:id/self-perform/:contentView', '/accounts/:id/self-perform/:contentView/:requestId'],
    };

    this.supportedRedirect = ['/accounts/', '/accounts', '/'];

    const { pathname } = window.location;

    const tempSelectedItem = this.getSelectedItem(pathname);

    this.state = {
      open: false,
      selectedItem: tempSelectedItem,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.checkCollapseNav);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location, userInfo, collapsedNav, actions } = this.props;
    const { pathname } = location;
    if (location !== nextProps.location || userInfo !== nextProps.userInfo) {
      const tempSelectedItem = this.getSelectedItem(nextProps.location.pathname);
      this.setState({ selectedItem: tempSelectedItem });
    }

    const isSmallWindow = window.innerWidth < 1440;

    if (isSmallWindow && pathname !== nextProps.location.pathname && !collapsedNav) {
      const isPersonDetails = matchPath(nextProps.location.pathname, '/accounts/:id/people/list/:personId');
      const isProjectDetails = matchPath(nextProps.location.pathname, '/accounts/:id/projects/list/:projectId');
      const isDetails = isPersonDetails || isProjectDetails;

      if (isDetails) {
        actions.toggleNavigation();
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkCollapseNav);
  }

  checkCollapseNav = () => {
    const { collapsedNav, location, actions } = this.props;

    const isSmallWindow = window.innerWidth < 1440;
    const isPersonDetails = matchPath(location.pathname, '/accounts/:id/people/list/:personId');
    const isProjectDetails = matchPath(location.pathname, '/accounts/:id/projects/list/:projectId');
    const isDetails = isPersonDetails || isProjectDetails;

    if (isSmallWindow && isDetails && !collapsedNav) {
      actions.toggleNavigation();
    }
  }

  tryToMatchPath = (pathname, path, exact) => {
    const matchProfile = matchPath(pathname, {
      path,
      exact,
    });
    if (matchProfile) {
      return matchProfile.params;
    }
    return null;
  }

  getSelectedItem = (pathname) => {
    let tempSelectedItem = '';
    Object.keys(this.paths).forEach((key) => {
      if (this.paths[key].find(ele => this.tryToMatchPath(pathname, ele, true) !== null)) {
        tempSelectedItem = key;
      }
    });

    if (this.supportedRedirect.includes(pathname)) {
      tempSelectedItem = 'accounts';
    }

    return tempSelectedItem;
  }

  handleToggle = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  handleListItemClick = (event, selectedItem) => {
    const { clearAllPersonSelections, clearAllProjectSelections, trackAnalytics } = this.props;

    // Mixpanel - Track side navigation
    const analyticsPayload = {
      dashboard: 'Navigate to Dashboard',
      people: 'Navigate to People',
      projects: 'Navigate to Projects',
      roles: 'Navigate to Roles View',
      'self-perform': 'Navigate to Hourly People List',
    };

    trackAnalytics(analyticsPayload[selectedItem]);

    clearAllPersonSelections();
    clearAllProjectSelections();

    this.setState({ selectedItem });
  }

  navigateToProfile = () => {
    const { history } = this.props;
    this.handleClose();
    history.push('/profile');
  }

  render() {
    const {
      accountId,
      collapsedNav,
      location,
      logout,
      userInfo,
      loading,
      hasWebPermissions,
    } = this.props;
    const { open, selectedItem } = this.state;

    if (loading) return null;

    let accountNavOptions;
    let backToAccountList;
    let accountsNavPath = '/';

    const checkAccount = hasAccount.test(location.pathname);
    const checkManageProfile = hasManageProf.test(location.pathname);
    const hasBridgitPerms = getPermissionsForAccount('*', userInfo.permissions);
    const accountWithPerms = userInfo?.permissions ? Object.keys(userInfo.permissions)[0] : null;

    if (
      !(hasBridgitPerms && checkManageProfile) &&
      (checkAccount || checkManageProfile) &&
      (accountId || (accountWithPerms !== '*' && typeof accountWithPerms === 'number'))
    ) {
      const parsedAccountId = Number(accountId || accountWithPerms);
      if (!parsedAccountId) {
        // this should never happen anymore, and is only here to protect against using an incorrect account ID
        // once SideNav is removed, this does not need to move anywhere
        return <Redirect to="/PageNotFound" />;
      }
      const accountsPath = `/accounts/${parsedAccountId}`;
      accountsNavPath = { pathname: `${accountsPath}`, state: { accountId: parsedAccountId } };

      // ListItemIcon ids are used in TestCafe for automation
      accountNavOptions = (
        <div>
          {NAV_ITEMS.map(({ path, component, title, action, subject, module }, idx) => (
            <Can
              key={path}
              action={action}
              subject={subject}
              module={module}
              yes={(
                <Link
                  to={{ pathname: `${accountsPath}/${path}`, state: { accountId: parsedAccountId } }}
                >
                  <ListItem
                    classes={{ root: 'li-root ripple', selected: 'li-selected' }}
                    component="li"
                    selected={selectedItem === path}
                    onClick={event => this.handleListItemClick(event, path)}
                    button
                    divider={idx === NAV_ITEMS.length - 1}
                  >
                    {collapsedNav ? (
                      <Tooltip title={title} placement="top">
                        <ListItemIcon id={`${AUTOMATION_SIDENAV_ICON_PREFIX}${path}`}>{component}</ListItemIcon>
                      </Tooltip>
                    ) : (
                      <ListItemIcon id={`${AUTOMATION_SIDENAV_ICON_PREFIX}${path}`}>{component}</ListItemIcon>
                    )}
                    {!collapsedNav && <ListItemText primary={title} />}
                  </ListItem>
                </Link>
              )}
            />
          ))}

          <Can
            action={PERM_WRITE}
            subject={PERM_ACCOUNT}
            yes={(
              <Link to={{ pathname: `${accountsPath}/settings`, state: { accountId: parsedAccountId } }}>
                <ListItem
                  classes={{ root: 'li-root ripple', selected: 'li-selected' }}
                  component="li"
                  divider
                  button
                  selected={selectedItem === 'settings'}
                  onClick={event => this.handleListItemClick(event, 'settings')}
                >
                  {collapsedNav ? (
                    <Tooltip title="Settings" placement="top">
                      <ListItemIcon id={`${AUTOMATION_SIDENAV_ICON_PREFIX}settings`}>
                        <Settings />
                      </ListItemIcon>
                    </Tooltip>
                  ) : (
                    <ListItemIcon id={`${AUTOMATION_SIDENAV_ICON_PREFIX}settings`}>
                      <Settings />
                    </ListItemIcon>
                  )}
                  {!collapsedNav && <ListItemText primary="Settings" />}
                </ListItem>
              </Link>
            )}
          />
        </div>
      );

      backToAccountList = (
        <Link className="nav-item-center" to={{ pathname: '/', state: { accountId: parsedAccountId } }}>
          <ListItem
            classes={{ root: 'li-root ripple' }}
            component="li"
            button
            selected={selectedItem === 'accounts'}
            onClick={event => this.handleListItemClick(event, 'accounts')}
          >
            {collapsedNav ? (
              <Tooltip title="Manage Accounts" placement="top">
                <ListItemIcon id={`${AUTOMATION_SIDENAV_ICON_PREFIX}back`}>
                  <ChevronLeft />
                </ListItemIcon>
              </Tooltip>
            ) : (
              <ListItemIcon id={`${AUTOMATION_SIDENAV_ICON_PREFIX}back`}>
                <ChevronLeft />
              </ListItemIcon>
            )}
            {!collapsedNav && <ListItemText primary="Manage Accounts" />}
          </ListItem>
        </Link>
      );
    }

    const popper = (
      <Popper
        className="popper-container"
        open={open}
        anchorEl={this.anchorEl}
        placement="bottom-end"
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={this.handleClose}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Grow {...TransitionProps} id={AUTOMATION_POPPER_MENU}>
              <Paper classes={{ root: 'popper' }}>
                {collapsedNav && <span className="popper-arrow" /> }
                <Grid
                  container
                  direction="row"
                  justify="space-around"
                  spacing={2}
                  alignItems="center"
                  wrap="nowrap"
                >
                  {hasWebPermissions && (
                    <Grid item xs>
                      <Button
                        className="nowrap"
                        variant="contained"
                        onClick={this.navigateToProfile}
                      >
                        Manage Profile
                      </Button>
                    </Grid>
                  )}

                  <Grid item xs>
                    <Button
                      className="nowrap"
                      color="primary"
                      variant="contained"
                      onClick={logout}
                    >
                      Sign Out
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    );

    return (
      <Drawer
        classes={{
          root: 'common-side-nav',
          docked: classNames('drawer-container', collapsedNav && 'drawer-collapsed'),
          paperAnchorDockedLeft: classNames('drawer', collapsedNav && 'drawer-collapsed'),
        }}
        variant="permanent"
        open
        anchor="left"
      >
        <div className="drawer-header">
          <Avatar
            component="picture"
            classes={{ root: 'avatar-container' }}
            alt="Bridgit Icon"
            src={BRIDGIT_ICON_WHITE}
          />
          {collapsedNav ? (
            <>
              <Tooltip title={userInfo.email || 'Loading...'} placement="top">
                <IconButton
                  buttonRef={(node) => {
                    this.anchorEl = node;
                  }}
                  classes={{ root: 'icon-account' }}
                  color="inherit"
                  aria-label="Profile"
                  aria-owns={open ? AUTOMATION_POPPER_MENU : null}
                  aria-haspopup="true"
                  onClick={this.handleToggle}
                >
                  <AccountCircle />
                </IconButton>
              </Tooltip>
              {popper}
            </>
          ) : (
            <>
              <Typography
                noWrap
                classes={{ root: 'white-text' }}
                component="span"
              >
                {userInfo.name}
              </Typography>
              <Typography
                noWrap
                classes={{ root: 'white-text' }}
                component="span"
              >
                {userInfo.email}
              </Typography>
              <>
                <IconButton
                  buttonRef={(node) => {
                    this.anchorEl = node;
                  }}
                  aria-owns={open ? AUTOMATION_POPPER_MENU : null}
                  aria-haspopup="true"
                  onClick={this.handleToggle}
                  className="icon-arrow"
                >
                  <ArrowDropDown className={open ? 'icon-inverted' : null} />
                </IconButton>
                {popper}
              </>
            </>
          )}
        </div>

        <List className="nav-item-center" disablePadding>
          {!checkAccount && hasBridgitPerms && (
            <Link to={accountsNavPath}>
              <ListItem
                classes={{ root: 'li-root ripple', selected: 'li-selected' }}
                component="li"
                selected={selectedItem === 'accounts'}
                onClick={event => this.handleListItemClick(event, 'accounts')}
                button
              >
                {collapsedNav ? (
                  <Tooltip title="Accounts" placement="top">
                    <ListItemIcon id="accounts-icon">
                      <Language />
                    </ListItemIcon>
                  </Tooltip>
                ) : (
                  <ListItemIcon id="accounts-icon">
                    <Language />
                  </ListItemIcon>
                )}
                {!collapsedNav && <ListItemText primary="Accounts" />}
              </ListItem>
            </Link>
          )}

          {hasWebPermissions && (
            <>
              {accountNavOptions}

              {!checkAccount && hasBridgitPerms?.group === PERM_SUPER_ADMIN && (
                <Link to="/settings">
                  <ListItem
                    classes={{ root: 'li-root ripple', selected: 'li-selected' }}
                    component="li"
                    button
                    divider
                    selected={selectedItem === 'manageAdmins'}
                    onClick={event => this.handleListItemClick(event, 'manageAdmins')}
                  >
                    {collapsedNav ? (
                      <Tooltip title="Manage Admins" placement="top">
                        <ListItemIcon id="manage-admin-icon">
                          <Settings />
                        </ListItemIcon>
                      </Tooltip>
                    ) : (
                      <ListItemIcon id="manage-admin-icon">
                        <Settings />
                      </ListItemIcon>
                    )}
                    {!collapsedNav && <ListItemText primary="Manage Admins" />}
                  </ListItem>
                </Link>
              )}
            </>
          )}
        </List>

        {hasBridgitPerms && backToAccountList}

      </Drawer>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps({ common, login, accounts }) {
  const { userLoginPending, getUserInfoPending, userInfo } = login;
  const { entities } = accounts;
  const { permissions } = userInfo;
  const { accountId, collapsedNav, contentView } = common;
  const loading = userLoginPending || getUserInfoPending;
  const hasWebPermissions = hasWebPerms(accountId, permissions);

  return {
    accountId,
    collapsedNav,
    userInfo,
    accounts: entities,
    loading,
    contentView,
    hasWebPermissions,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
    clearAllPersonSelections: bindActionCreators(clearAllPersonSelections, dispatch),
    clearAllProjectSelections: bindActionCreators(clearAllProjectSelections, dispatch),
    setContentView: bindActionCreators(setContentView, dispatch),
    trackAnalytics: bindActionCreators(trackAnalytics, dispatch),
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SideNav),
);
