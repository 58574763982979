import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNT_SETTINGS_GET_HOURLY_PERSON_FIELDS_BEGIN,
  ACCOUNT_SETTINGS_GET_HOURLY_PERSON_FIELDS_SUCCESS,
  ACCOUNT_SETTINGS_GET_HOURLY_PERSON_FIELDS_FAILURE,
  ACCOUNT_SETTINGS_GET_HOURLY_PERSON_FIELDS_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';

export function getHourlyPersonFields(accountId) {
  return {
    type: ACCOUNT_SETTINGS_GET_HOURLY_PERSON_FIELDS_BEGIN,
    accountId,
  };
}

export function dismissGetHourlyPersonFieldsError() {
  return {
    type: ACCOUNT_SETTINGS_GET_HOURLY_PERSON_FIELDS_DISMISS_ERROR,
  };
}

export function* doGetHourlyPersonFields(action) {
  const { accountId } = action;
  let res;
  try {
    res = yield call(protectedJsonGet, `accounts/${accountId}/hourly-person-fields`);
  } catch (err) {
    yield put({
      type: ACCOUNT_SETTINGS_GET_HOURLY_PERSON_FIELDS_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: ACCOUNT_SETTINGS_GET_HOURLY_PERSON_FIELDS_SUCCESS,
    data: res,
  });
}

export function* watchGetHourlyPersonFields() {
  yield takeLatest(ACCOUNT_SETTINGS_GET_HOURLY_PERSON_FIELDS_BEGIN, doGetHourlyPersonFields);
}

export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNT_SETTINGS_GET_HOURLY_PERSON_FIELDS_BEGIN:
      return {
        ...state,
        getHourlyPersonFieldsPending: true,
        getHourlyPersonFieldsError: null,
      };

    case ACCOUNT_SETTINGS_GET_HOURLY_PERSON_FIELDS_SUCCESS:
      return {
        ...state,
        hourlyPersonFields: action.data || [],
        getHourlyPersonFieldsPending: false,
        getHourlyPersonFieldsError: null,
      };

    case ACCOUNT_SETTINGS_GET_HOURLY_PERSON_FIELDS_FAILURE:
      return {
        ...state,
        getHourlyPersonFieldsPending: false,
        getHourlyPersonFieldsError: action.data.error,
      };

    case ACCOUNT_SETTINGS_GET_HOURLY_PERSON_FIELDS_DISMISS_ERROR:
      return {
        ...state,
        getHourlyPersonFieldsError: null,
      };

    default:
      return state;
  }
}
