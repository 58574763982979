import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import moment from 'moment';
import { DATE_DISPLAY_FORMAT, DATE_INPUT_FORMAT } from '../../common/constants';

const SimpleTable = ({ cols, rows, className, noDataText, onRowClick, tableTitle, tableClass, rowClass, withShadow }) => {
  const rowData = useMemo(() => {
    if (!rows.length) {
      return (
        <TableRow>
          <TableCell className="no-match" colSpan={cols.length}>
            <span>{noDataText}</span>
          </TableCell>
        </TableRow>
      );
    }

    const handleRowClick = selectedAccountId => () => onRowClick(selectedAccountId);

    return rows.map((row, idx) => (
      <TableRow key={rows[idx][0].id} className="table-row" onClick={handleRowClick(rows[idx][0].id)} classes={rowClass}>
        {row
          .map(({ value, type, fieldName, classes }) => {
            if (type === 'hidden') return null;

            const formattedValue = type === 'date'
              ? moment(value, DATE_INPUT_FORMAT).format(DATE_DISPLAY_FORMAT)
              : value;

            return (
              <TableCell key={fieldName} classes={classes}>{formattedValue}</TableCell>
            );
          })}
      </TableRow>
    ));
  }, [cols.length, noDataText, onRowClick, rows, rowClass]);

  return (
    <>
      {tableTitle && <p className="simple-table-title">{tableTitle}</p>}

      <Paper className={classNames('simple-table', className, { 'simple-table-no-shadow': !withShadow })}>
        <Table className={classNames('simple-table-root', tableClass)}>
          <TableHead className="simple-table-head">
            <TableRow className="simple-table-row">
              {cols.map(({ name }) => (
                <TableCell key={name}>{name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="simple-table-body">
            {rowData}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

SimpleTable.propTypes = {
  cols: PropTypes.arrayOf(PropTypes.object).isRequired,
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
  className: PropTypes.string,
  noDataText: PropTypes.string,
  onRowClick: PropTypes.func,
  tableTitle: PropTypes.string,
  tableClass: PropTypes.string,
  rowClass: PropTypes.object,
  withShadow: PropTypes.bool,
};

SimpleTable.defaultProps = {
  className: '',
  onRowClick: () => {},
  tableTitle: '',
  noDataText: '',
  tableClass: '',
  rowClass: {},
  withShadow: false,
};

export default SimpleTable;
