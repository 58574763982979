import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, LinearProgress, Popper } from '@material-ui/core';
import { Close } from '@material-ui/icons';

export class InfoPopper extends PureComponent {
  static propTypes = {
    loading: PropTypes.bool,
    popperTargetRef: PropTypes.object,
    popperOpen: PropTypes.bool.isRequired,
    onPopperClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    loading: false,
    popperTargetRef: null,
  }

  state = {
    arrowRef: null,
  }

  classes = {
    button: { root: 'button-close' },
    close: { root: 'icon-close' },
  }

  handleArrowRef = (node) => {
    this.setState({
      arrowRef: node,
    });
  };

  render() {
    const {
      popperOpen,
      popperTargetRef,
      onPopperClose,
      loading,
      children,
    } = this.props;
    const { arrowRef } = this.state;

    const containers = document.getElementsByClassName('allocations-allocation-modal-content-structure');
    const boundariesElement = containers instanceof HTMLCollection && containers.length ? containers[0] : 'window';

    const modifiers = {
      arrow: {
        enabled: true,
        element: arrowRef,
      },
      flip: {
        enabled: true,
      },
      preventOverflow: {
        enabled: true,
        boundariesElement,
      },
    };

    return (
      <div className="allocations-info-popper">
        <Popper
          className="allocation-popper"
          open={popperOpen}
          anchorEl={popperTargetRef}
          placement="bottom"
          disablePortal
          modifiers={modifiers}
        >
          <span className="popper-arrow" ref={this.handleArrowRef} />
          <div className="allocation-popper-content-wrapper">
            <Button
              size="small"
              aria-label="Close"
              classes={this.classes.button}
              color="inherit"
              disableRipple
              onClick={onPopperClose}
            >
              <Close classes={this.classes.close} />
            </Button>

            { loading && (
              <div className="progress-container">
                <LinearProgress variant="query" color="primary" />
              </div>
            )}

            <div className="popper-content-container">
              {children}
            </div>
          </div>
        </Popper>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps({ people }) {
  const { getPersonAllocationsByDatePending } = people;

  return {
    loading: getPersonAllocationsByDatePending,
  };
}

export default connect(
  mapStateToProps,
)(InfoPopper);
