import React, { useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, EditControls } from '@bridgit/foundation';
import pluralize from 'pluralize';
import { LinearProgress } from '@material-ui/core';

import {
  deleteAccountCertification,
  getAccountCertificationById,
  clearSelectedAccountCertification,
} from './redux/actions';

const DeleteCertificationModal = ({
  open,
  onClose,
  certificationId,
}) => {
  const dispatch = useDispatch();

  const { accountId } = useSelector(({ common }) => common);
  const { selectedCertification: { name = '', inUse } } = useSelector(({ accountSettings }) => accountSettings);
  const {
    getAccountCertificationByIdPending,
    getAccountCertificationByIdError,
  } = useSelector(({ accountSettings }) => accountSettings);

  // Clear selected certification data on modal close
  const onCloseWrapper = useCallback(() => {
    dispatch(clearSelectedAccountCertification());
    onClose();
  }, [dispatch, onClose]);

  const pluralInUse = useMemo(() => (
    inUse?.length
      ? pluralize('person', inUse.length, true)
      : null
  ), [inUse]);

  const onDelete = useCallback(() => {
    dispatch(deleteAccountCertification(accountId, certificationId));
    onCloseWrapper();
  }, [accountId, dispatch, certificationId, onCloseWrapper]);

  // Fetch latest certification data when the modal is opened
  useEffect(() => {
    if (open) {
      dispatch(getAccountCertificationById(accountId, certificationId));
    }
  }, [accountId, dispatch, certificationId, open]);

  const modalText = useMemo(() => (
    pluralInUse
      ? (
        <>
          <div>{`Are you sure you want to delete the certification${name ? ` "${name}"` : ''} and any attachments associated with it?`}</div>
          <div className="delete-certification-modal-spacer">{`It will be removed from the ${pluralInUse} it was added to.`}</div>
        </>
      )
      : <div>{`Are you sure you want to delete the certification${name ? ` "${name}"` : ''}?`}</div>
  ), [name, pluralInUse]);

  const isDeleteDisabled = useMemo(
    () => getAccountCertificationByIdPending || !!getAccountCertificationByIdError || !name,
    [getAccountCertificationByIdPending, getAccountCertificationByIdError, name],
  );

  return (
    <Modal
      headline="Delete Certification"
      open={open}
      onClose={onCloseWrapper}
    >
      <div className="account-settings-delete-certification-modal">
        {getAccountCertificationByIdPending
          ? <LinearProgress />
          : modalText}
        <EditControls
          className="delete-certification-modal-spacer"
          primaryText="Delete"
          primaryAction={onDelete}
          secondaryAction={onCloseWrapper}
          disabled={isDeleteDisabled}
        />
      </div>
    </Modal>
  );
};

DeleteCertificationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  certificationId: PropTypes.number.isRequired,
};

export default DeleteCertificationModal;
