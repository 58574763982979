import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { MenuItem, CircularProgress } from '@material-ui/core';
import { MoreActionsButton } from '@bridgit/foundation';
import { updateProject, deleteProject } from './redux/actions';
import { Confirm } from '../common';
import { closeModal } from '../modal-manager/redux/actions';
import { MULTI_STATE_MODAL_ID } from '../common/redux/constants';
import { CANCELED, ACTIVE, LOST, REACTIVATED, PURSUIT, REACTIVATE_API_MAPPING } from './constants';
import { AUTOMATION_DELETE_PROJECT_ACTION } from './ids';

const ProjectMoreActions = ({
  accountId,
  selectedProject,
  updateProject,
  updateProjectPending,
  deleteProject,
  deleteProjectPending,
  activeModal,
  closeModal,
  onDelete,
  runsInModal,
}) => {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const confirmMsg = useMemo(() => {
    if (selectedProject?.roles?.length) {
      const pastRoles = selectedProject?.roles?.filter(role => !moment(role.startDate).isAfter());
      if (pastRoles.length) {
        return 'All roles and allocations on the project and related project history will also be deleted. ';
      }
      return 'All roles and allocations on the project will also be deleted. ';
    }
    return '';
  }, [selectedProject?.roles]);

  const parentName = `Project Details${runsInModal ? ' Modal' : ''}`;

  const onDeleteConfirm = () => {
    setShowDeleteConfirm(true);
  };

  const onHideConfirm = useCallback(() => setShowDeleteConfirm(false), []);

  const handleStatusChange = useCallback(statusType => () => {
    const APIStatusType = statusType === REACTIVATED
      ? REACTIVATE_API_MAPPING[selectedProject.state] || ACTIVE
      : statusType;
    const data = {
      state: selectedProject.state === PURSUIT ? LOST : APIStatusType,
    };
    updateProject(accountId, selectedProject.id, data, selectedProject, parentName, statusType);
  }, [accountId, parentName, selectedProject, updateProject]);

  const onProjectDelete = useCallback(() => {
    deleteProject(accountId, selectedProject.id, parentName);
    onDelete();
    if (activeModal === MULTI_STATE_MODAL_ID) {
      closeModal(MULTI_STATE_MODAL_ID);
    }
  }, [accountId, activeModal, closeModal, deleteProject, onDelete, parentName, selectedProject.id]);

  const menuItems = useMemo(() => {
    const isInactive = [CANCELED, LOST].includes(selectedProject.state);
    const updateOrDeletePending = updateProjectPending || deleteProjectPending;

    return [
      ...(selectedProject.state === PURSUIT
        ? [{
          id: 'lost-project-action',
          key: 'lost',
          onClick: handleStatusChange(LOST),
          disabled: updateProjectPending,
          content: 'Project Lost',
        }]
        : [{
          id: 'cancel-reactivate-project-action',
          key: 'cancel-reactivate',
          onClick: isInactive ? handleStatusChange(REACTIVATED) : handleStatusChange(CANCELED),
          disabled: updateOrDeletePending,
          content: `${isInactive ? 'Reactivate' : 'Cancel'} Project`,
        }]
      ),
      {
        id: AUTOMATION_DELETE_PROJECT_ACTION,
        key: 'delete',
        onClick: onDeleteConfirm,
        disabled: updateOrDeletePending,
        content: 'Delete Project',
        className: 'delete-project-action',
      },
    ];
  }, [updateProjectPending, deleteProjectPending, selectedProject.state, handleStatusChange]);

  return (
    <div className="projects-project-more-actions">
      <MoreActionsButton
        popperPlacement="bottom-end"
        buttonColor="primary"
        pending={updateProjectPending}
      >
        {menuItems.map(({ id, key, onClick, disabled, content, className }) => (
          <MenuItem id={id} key={key} onClick={onClick} disabled={disabled} className={className}>
            {content}
          </MenuItem>
        ))}
      </MoreActionsButton>
      {showDeleteConfirm && (
        <Confirm
          headline="Delete project"
          acceptButtonText="Delete"
          cancelButtonText="Cancel"
          onCancel={onHideConfirm}
          onAccept={onProjectDelete}
        >
          {deleteProjectPending
            ? <CircularProgress className="confirm-delete-loader" size={25} color="primary" />
            : (
              <div className="confirm-delete-message">
                <div>Are you sure you want to delete this project?</div>
                <div>{`${confirmMsg}This cannot be undone.`}</div>
              </div>
            )}
        </Confirm>
      )}
    </div>
  );
};

ProjectMoreActions.propTypes = {
  accountId: PropTypes.number.isRequired,
  selectedProject: PropTypes.object.isRequired,
  updateProject: PropTypes.func.isRequired,
  updateProjectPending: PropTypes.bool.isRequired,
  deleteProject: PropTypes.func.isRequired,
  deleteProjectPending: PropTypes.bool.isRequired,
  activeModal: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  runsInModal: PropTypes.bool,
};

ProjectMoreActions.defaultProps = {
  activeModal: null,
  onDelete: () => {},
  runsInModal: false,
};

/* istanbul ignore next */
function mapStateToProps({ projects, common, modalManager }) {
  const { accountId } = common;
  const { activeModal } = modalManager;
  const { updateProjectPending, deleteProjectPending } = projects;

  return {
    accountId,
    activeModal,
    updateProjectPending,
    deleteProjectPending,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    updateProject: bindActionCreators(updateProject, dispatch),
    deleteProject: bindActionCreators(deleteProject, dispatch),
    closeModal: bindActionCreators(closeModal, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectMoreActions);
