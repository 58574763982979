import { getProjectEditorConfig, getPhaseEditorConfig } from 'src/features/edit-dates/utils/dateEditorUtils';
import { SEGMENT_TYPE_PHASE } from '../common/constants';
import {
  EDIT_DATES_SET_EDITOR_CONFIG,
} from './constants';

export function setEditorConfig(
  typeUnderEdit,
  projectStart,
  projectEnd,
  newStartDate,
  newEndDate,
  parentName = '',
  primaryId,
  phaseStart,
  phaseEnd,
) {
  let configValues;

  if (typeUnderEdit === SEGMENT_TYPE_PHASE) {
    configValues = getPhaseEditorConfig(typeUnderEdit, projectStart, projectEnd, newStartDate, newEndDate, phaseStart, phaseEnd);
  } else {
    configValues = getProjectEditorConfig(typeUnderEdit, projectStart, projectEnd, newStartDate, newEndDate);
  }

  return {
    type: EDIT_DATES_SET_EDITOR_CONFIG,
    parentName,
    primaryId,
    ...configValues,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case EDIT_DATES_SET_EDITOR_CONFIG: {
      const {
        editAction,
        dateUnderEdit,
        typeUnderEdit,
        offset,
        newStartDate,
        newEndDate,
        boundingStartDate,
        boundingEndDate,
        parentName,
        primaryId,
      } = action;

      return {
        ...state,
        editAction,
        dateUnderEdit,
        typeUnderEdit,
        offset,
        newStartDate,
        newEndDate,
        boundingStartDate,
        boundingEndDate,
        parentName,
        primaryId,
      };
    }
    default:
      return state;
  }
}
