import {
  PEOPLE_GANTT_CONFIG_KEY_V2,
  GANTT_SET_PEOPLE_GANTT_CONFIG_SETTINGS,
} from './constants';
import { setPeopleGanttConfigLocalStorageValues } from '../people-gantt-v2/utils';

export function setPeopleGanttConfigSettings(accountId, userId, settings) {
  // Bulk update localstorage values
  setPeopleGanttConfigLocalStorageValues(accountId, userId, settings);

  return {
    type: GANTT_SET_PEOPLE_GANTT_CONFIG_SETTINGS,
    settings,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case GANTT_SET_PEOPLE_GANTT_CONFIG_SETTINGS: {
      const { settings } = action || {};
      const { instances } = state || {};
      const prevSettings = instances?.[PEOPLE_GANTT_CONFIG_KEY_V2] || {};
      return {
        ...state,
        instances: {
          ...instances,
          [PEOPLE_GANTT_CONFIG_KEY_V2]: {
            ...prevSettings,
            ...settings,
          },
        },
      };
    }

    default:
      return state;
  }
}
