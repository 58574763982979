// Person side menu panels
export const SIDE_PANEL_PERSON_NOTES = 'personNotes';
export const SIDE_PANEL_PERSON_HISTORY = 'personHistory';
export const SIDE_PANEL_PERSON_ATTACHMENTS = 'personAttachments';
export const SIDE_PANEL_PERSON_CERTIFICATIONS = 'personCertifications';

// Person modal side menu tabs
export const PERSON_MODAL_NOTES_TAB = 0;
export const PERSON_MODAL_CERTIFICATIONS_TAB = 1;
export const PERSON_MODAL_AUDIT_TAB = 2;
export const PERSON_MODAL_ATTACHMENTS_TAB = 3;

// Profile certifications states
export const PROFILE_CERTIFICATION_LIST = 0;
export const PROFILE_CERTIFICATION_ADD = 1;
export const PROFILE_CERTIFICATION_EDIT = 2;

export const PERSON_CERTIFICATIONS_TAB_NAME = 'Certifications';
export const PERSON_ATTACHMENTS_TAB_NAME = 'Attachments';

// People list column display names
export const PEOPLE_DISPLAY_NEXT_PROJECT = 'Next Project';
export const PEOPLE_DISPLAY_NEXT_PROJECT_START = 'Next Project Start';
export const PEOPLE_DISPLAY_CURRENT_PROJECT_END = 'Current Project End';
export const PEOPLE_DISPLAY_PURSUIT_PROJECTS = 'Pursuit Projects';
export const PEOPLE_DISPLAY_AVAILABILITY_UNTIL = 'Availability Until';
export const PEOPLE_DISPLAY_CURRENT_PROJECT = 'Current Project';
export const PEOPLE_DISPLAY_NAME = 'Name';
export const PEOPLE_DISPLAY_TITLE = 'Title';
export const PEOPLE_DISPLAY_EMAIL = 'Email';
export const PEOPLE_DISPLAY_ISSUES = 'Issues';
export const PEOPLE_DISPLAY_PHOTO = 'Photo';
export const PEOPLE_DISPLAY_PERSON_ID = 'Person ID';
export const PEOPLE_DISPLAY_HIRE_DATE = 'Hire Date';
export const PEOPLE_DISPLAY_TERMINATION_DATE = 'Termination Date';
export const PEOPLE_DISPLAY_ALLOCATED_PEOPLE = 'Allocated People';
export const PEOPLE_DISPLAY_ACCOUNT_ID = 'Account id';
export const PEOPLE_DISPLAY_CERTIFICATIONS = 'Certifications';
export const PEOPLE_DISPLAY_COST_RATE = 'Cost Rate (hr)';
export const PEOPLE_DISPLAY_EMPLOYEE_ID = 'Employee ID';
export const PEOPLE_DISPLAY_OTHER = 'Other';

export const TIME_OFF_BACKFILL_LABEL = 'Backfill required on projects?';
export const TIME_OFF_BACKFILL_TOOLTIP = 'Please select Yes (Backfill required) if another person will fill this role during the time off period.';
export const TIME_OFF_BACKFILL_CONTROL_VALUES = {
  YES: 1,
  NO: 2,
};
export const TIME_OFF_BACKFILL_CONTROLS = [
  {
    name: TIME_OFF_BACKFILL_CONTROL_VALUES.YES,
    label: 'Yes',
  },
  {
    name: TIME_OFF_BACKFILL_CONTROL_VALUES.NO,
    label: 'No',
  },
];

export const PERSON_CREATED = 'Person created';
export const PERSON_UPDATED = 'Person updated';
export const PERSON_DEACTIVATED = 'Person deactivated';
export const PERSON_REACTIVATED = 'Person reactivated';
export const PERSON_FIELD_SET = 'Person field set';
export const PERSON_FIELD_CLEARED = 'Person field cleared';
export const TIME_OFF_ADDED = 'Time off added';
export const TIME_OFF_UPDATED = 'Time off updated';
export const TIME_OFF_DELETED = 'Time off deleted';
export const PERSON_NOTE_ADDED = 'Person note added';
export const PERSON_NOTE_UPDATED = 'Person note updated';
export const PERSON_NOTE_DELETED = 'Person note deleted';
export const SET_PROFILE_PICTURE = 'Profile picture set';
export const ATTACHMENT_ADDED = 'Attachment added';
export const ATTACHMENT_REMOVED = 'Attachment deleted';
export const CERTIFICATION_ADDED = 'Certification added';
export const CERTIFICATION_UPDATED = 'Certification updated';
export const CERTIFICATION_DELETED = 'Certification deleted';
export const CERTIFICATION_ATTACHMENT_LINKED = 'Attachment linked to certification';
export const CERTIFICATION_ATTACHMENT_UNLINKED = 'Attachment unlinked from certification';

export const PERSON_LOG_EVENT_NAME_MAP = {
  Created: PERSON_CREATED,
  Updated: PERSON_UPDATED,
  SetFieldValue: PERSON_FIELD_SET,
  ClearFieldValue: PERSON_FIELD_CLEARED,
  SetProcessedProfilePicture: SET_PROFILE_PICTURE,
  Deactivated: PERSON_DEACTIVATED,
  Restored: PERSON_REACTIVATED,
  AddedUnavailability: TIME_OFF_ADDED,
  UpdatedUnavailability: TIME_OFF_UPDATED,
  RemovedUnavailability: TIME_OFF_DELETED,
  AddedPersonNote: PERSON_NOTE_ADDED,
  UpdatedPersonNote: PERSON_NOTE_UPDATED,
  RemovedPersonNote: PERSON_NOTE_DELETED,
  AddedAttachment: ATTACHMENT_ADDED,
  RemovedAttachment: ATTACHMENT_REMOVED,
};

export const PERSON_CERTIFICATIONS_LOG_EVENT_NAME_MAP = {
  AddedAttachment: CERTIFICATION_ATTACHMENT_LINKED,
  RemovedAttachment: CERTIFICATION_ATTACHMENT_UNLINKED,
  AddedCertification: CERTIFICATION_ADDED,
  UpdatedCertification: CERTIFICATION_UPDATED,
  RemovedCertification: CERTIFICATION_DELETED,
};

export const PERSON_LOG_RESOURCE_TYPES = {
  Person: PERSON_LOG_EVENT_NAME_MAP,
  PersonCertification: PERSON_CERTIFICATIONS_LOG_EVENT_NAME_MAP,
};

export const PERSON_ACTIVITY_ENTRY_EXPANDED = 'Profile Activity entry expanded';

// Upload Attachments Options
export const UPLOAD_ATTACHMENT_PUBLIC = 0;
export const UPLOAD_ATTACHMENT_PRIVATE = 1;
export const PROFILE_UPLOAD_PROPS = { maxSize: 1024 * 1024 * 1024 }; // 1GB
export const PROFILE_UPLOAD_ERRORS = {
  'too-many-files': 'Please upload one file at a time',
  'file-too-large': 'File must be smaller than 1 GB',
};

// Analytics context
export const PROFILE_DETAILS_MODAL_CONTEXT = 'Profile Details Modal';
export const PROFILE_DETAILS_PROFILE_CONTEXT = 'Profile';

export const DEFAULT_LIST_ITEMS_LIMIT = 15;
export const DEFAULT_LIST_OFFSET = 15;
export const CERT_ATTACHMENTS_LIST_OFFSET = 10;
export const CERT_ATTACHMENTS_LIST_LIMIT = 10;

export const ATTACHMENT_COMPLETED_STATE = 'Completed';
export const ATTACHMENT_PROCESSING_STATE = 'InProgress';
export const ATTACHMENT_POLLING_STATE = 'Uploading';
export const ATTACHMENT_FAILED_STATE = 'Failed';

export const ATTACHMENT_STATE_MAP = {
  [ATTACHMENT_COMPLETED_STATE]: 'completed',
  [ATTACHMENT_PROCESSING_STATE]: 'processing',
  [ATTACHMENT_POLLING_STATE]: 'uploading',
  [ATTACHMENT_FAILED_STATE]: 'error',
};

export const DEFAULT_REQUEST_DELAY = 1000;
export const REQUEST_DELAY_MULTIPLIER = 1.7;
export const MAX_POLLING_TIME = 90 * 1000; // 90 seconds

export const ATTACHMENTS_STATE_QUERY_PARAM = 'All';

export const CERTIFICATION_STATUSES = {
  ACTIVE: 'active',
  EXPIRING_SOON: 'expiringSoon',
  EXPIRED: 'expired',
};

export const CERTIFICATION_VARIANTS = {
  [CERTIFICATION_STATUSES.ACTIVE]: 'default',
  [CERTIFICATION_STATUSES.EXPIRING_SOON]: 'warning',
  [CERTIFICATION_STATUSES.EXPIRED]: 'error',
};

export const COST_RATE_FIELD_TOOLTIP = 'This field may inherit a default cost rate when it is left blank.';
