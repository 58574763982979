import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@bridgit/foundation';
import { Star } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';

export const SaveFilterButton = ({
  onClick,
  isFilterSaved,
  isMaxFiltersReached,
}) => {
  const isDisabled = isFilterSaved || isMaxFiltersReached;
  let tooltipText = 'Save filter';
  if (isFilterSaved) {
    tooltipText = 'Applied filter is already saved';
  } else if (isMaxFiltersReached) {
    tooltipText = 'Maximum saved filters reached';
  }

  return (
    <Tooltip
      title={tooltipText}
      placement="top"
    >
      <IconButton
        size="small"
        color={isDisabled ? 'secondary' : 'primary'}
        onClick={onClick}
        variant="contained"
        disabled={isDisabled}
        ariaLabel="Save Filter"
        className="save-filter-button"
      >
        <Star />
      </IconButton>
    </Tooltip>
  );
};

SaveFilterButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isFilterSaved: PropTypes.bool,
  isMaxFiltersReached: PropTypes.bool,
};

SaveFilterButton.defaultProps = {
  isFilterSaved: false,
  isMaxFiltersReached: false,
};

export default SaveFilterButton;
