import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'react-fast-compare';
import { sortFilterOptions } from 'src/features/filters/utils/filterUtils';
import { MultiSelect } from '..';

export default class SelectList extends PureComponent {
  static propTypes = {
    items: PropTypes.array.isRequired,
    verbs: PropTypes.array,
    filterKey: PropTypes.string.isRequired,
    expanded: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    onExpand: PropTypes.func,
    onDelete: PropTypes.func,
    onVerbChange: PropTypes.func,
    selectedItems: PropTypes.object.isRequired,
    placeholder: PropTypes.string,
    disableDelete: PropTypes.bool,
    verbText: PropTypes.string,
    subText: PropTypes.string,
    hasSubOptions: PropTypes.bool,
    controlOptions: PropTypes.array,
    isCustomSort: PropTypes.bool,
    hideVerbSelector: PropTypes.bool,
    showSelectedCount: PropTypes.bool,
    primaryContent: PropTypes.string,
  };

  static defaultProps = {
    expanded: '',
    placeholder: '',
    disableDelete: false,
    verbText: '',
    subText: '',
    hasSubOptions: false,
    controlOptions: [],
    isCustomSort: false,
    hideVerbSelector: false,
    showSelectedCount: false,
    verbs: null,
    onExpand: () => {},
    onDelete: () => {},
    onVerbChange: () => {},
    primaryContent: undefined,
  }

  constructor(props) {
    super(props);
    const {
      items,
      selectedItems,
      filterKey,
      hasSubOptions,
      isCustomSort,
    } = props;
    const hasItems = items.length > 0;
    const expandedSubs = hasSubOptions && selectedItems.selected && selectedItems.selected.length > 0
      ? selectedItems.selected.reduce((expanded, selected) => {
        const parentItemValue = items.find(({ name }) => name === selected?.parentId)?.value;
        if (parentItemValue && expanded.indexOf(parentItemValue) === -1) {
          expanded.push(parentItemValue);
        }
        return expanded;
      }, [])
      : [];
    const filterBy = filterKey === 'value' ? 'name' : filterKey;

    this.state = {
      items,
      selectedVerb: selectedItems.verb,
      selectedAll: hasItems && items.length === selectedItems.selected.length,
      selectedSome: selectedItems.selected.length > 0 && items.length !== selectedItems.selected.length,
      visibleItems: isCustomSort
        ? items
        : sortFilterOptions(items, filterBy),
      searchString: '',
      expandedSubs,
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { filterKey, selectedItems, items, isCustomSort } = nextProps;
    const { visibleItems } = state;

    let newVisibleItems = visibleItems;

    if (!deepEqual(items, state.items)) {
      const filterBy = filterKey === 'value' ? 'name' : filterKey;
      newVisibleItems = isCustomSort
        ? items
        : sortFilterOptions(items, filterBy);
    }

    const visibleSelected = newVisibleItems.filter(item => selectedItems.selected.findIndex(selected => selected[filterKey] === item[filterKey]) !== -1);
    const someSelected = visibleSelected.length > 0;

    return {
      items,
      visibleItems: newVisibleItems,
      selectedVerb: nextProps.selectedItems.verb,
      selectedAll: someSelected && visibleSelected.length === newVisibleItems.length,
      selectedSome: someSelected && newVisibleItems.length > visibleSelected.length,
    };
  }

  onVerbChange = (evt) => {
    const { selectedItems, onVerbChange } = this.props;
    onVerbChange(selectedItems.id, evt.target.value);
    this.setState({ selectedVerb: evt.target.value });
  }

  onSelect = (item) => {
    const { onSelect, selectedItems, filterKey } = this.props;
    const { searchString, expandedSubs } = this.state;
    const parentId = item[filterKey];

    const selectedIdx = selectedItems.selected.findIndex(selected => selected[filterKey] === parentId);
    const newSelected = [...selectedItems.selected];

    if (selectedIdx === -1) {
      if (item.subOptions) {
        newSelected.push({ name: item.name, value: item.value });

        if (!searchString && item.subOptions.length > 0) {
          if (expandedSubs.indexOf(parentId) === -1) {
            this.onExpandSub(parentId).apply();
          }
          item.subOptions.forEach((sub) => {
            if (selectedItems.selected.findIndex(selected => selected.value === sub.value) === -1) {
              newSelected.push(sub);
            }
          });
        }
      } else {
        newSelected.push(item);
      }
    } else {
      newSelected.splice(selectedIdx, 1);
    }

    onSelect(selectedItems.id, newSelected);
  }

  onSelectAll = () => {
    const { selectedItems, onSelect, hasSubOptions } = this.props;
    const { id, selected } = selectedItems;
    const { selectedAll, selectedSome, visibleItems, searchString } = this.state;

    // Items not part of the core list of selectable items
    // Eg. Filled/Unfilled chips for Roles filter
    const specialItems = selected.filter(
      selectedItem => !visibleItems.some(({ value }) => value === selectedItem.value) && !selectedItem.isSub,
    );
    let items;

    if (hasSubOptions && !selectedAll && !selectedSome && !searchString) {
      items = [];
      visibleItems.forEach(({ name, value, hasSubOptions, subOptions }) => {
        items.push({ name, value });

        if (hasSubOptions) {
          subOptions.forEach((sub) => {
            items.push(sub);
          });
        }
      });
    } else {
      items = visibleItems;
    }

    onSelect(id, selectedAll || selectedSome ? specialItems : [...items, ...specialItems]);
  }

  onSearch = (searchString = '') => {
    const { items, filterKey, selectedItems, hasSubOptions } = this.props;
    const query = searchString.toLowerCase();

    let visibleItems;
    if (hasSubOptions) {
      visibleItems = [];
      items.forEach((item) => {
        if (item.name.toLowerCase().indexOf(query) !== -1) {
          visibleItems.push(item);
        }
        if (item.hasSubOptions) {
          item.subOptions.forEach((sub) => {
            if (sub.name.toLowerCase().indexOf(query) !== -1) {
              visibleItems.push({ name: sub.name, value: sub.value });
            }
          });
        }
      });
    } else {
      visibleItems = items.filter(item => item.name.toLowerCase().indexOf(query) !== -1);
    }

    const visibleSelected = visibleItems.filter(item => selectedItems.selected.findIndex(selected => selected[filterKey] === item.value) !== -1);
    const selectedAll = visibleItems.length > 0 && visibleSelected.length === visibleItems.length;
    const selectedSome = visibleSelected.length > 0 && visibleItems.length > visibleSelected.length;
    const filterBy = filterKey === 'value' ? 'name' : filterKey;

    this.setState({
      visibleItems: sortFilterOptions(visibleItems, filterBy),
      selectedAll,
      selectedSome,
      searchString,
    });
  }

  onClearSearch = () => {
    const { items, filterKey, selectedItems, isCustomSort } = this.props;
    const filterBy = filterKey === 'value' ? 'name' : filterKey;
    const visibleItems = isCustomSort
      ? items
      : sortFilterOptions(items, filterBy);
    const visibleSelected = visibleItems.filter(item => selectedItems.selected.findIndex(selected => selected[filterKey] === item.name) !== -1);
    const selectedAll = visibleItems.length > 0 && visibleSelected.length === visibleItems.length;
    const selectedSome = visibleSelected.length > 0 && visibleItems.length > visibleSelected.length;

    this.setState({
      visibleItems,
      selectedAll,
      selectedSome,
      searchString: '',
    });
  }

  onExpand = () => {
    const { selectedItems, onExpand } = this.props;
    onExpand(selectedItems.id);
  }

  onExpandSub = id => () => {
    const { expandedSubs } = this.state;

    if (expandedSubs.indexOf(id) === -1) {
      this.setState({
        expandedSubs: [...expandedSubs, id],
      });
    } else {
      this.setState({
        expandedSubs: expandedSubs.filter(sub => sub !== id),
      });
    }
  }

  render() {
    const {
      selectedItems,
      expanded,
      verbs,
      verbText,
      disableDelete,
      filterKey,
      subText,
      placeholder,
      onDelete,
      controlOptions,
      hideVerbSelector,
      showSelectedCount,
      primaryContent,
    } = this.props;

    const {
      visibleItems,
      selectedVerb,
      searchString,
      expandedSubs,
      selectedSome,
      selectedAll,
    } = this.state;

    return (
      <MultiSelect
        expandedSubs={expandedSubs}
        subText={subText}
        onExpandSub={this.onExpandSub}
        selectedAll={selectedAll}
        selectedSome={selectedSome}
        onSelectAll={this.onSelectAll}
        visibleItems={visibleItems}
        selectedItems={selectedItems}
        filterKey={filterKey}
        expanded={expanded}
        selectedVerb={selectedVerb}
        verbs={verbs}
        verbText={verbText}
        disableDelete={disableDelete}
        searchString={searchString}
        placeholder={placeholder}
        onVerbChange={this.onVerbChange}
        onDelete={onDelete}
        onSelect={this.onSelect}
        onExpand={this.onExpand}
        onSearch={this.onSearch}
        onClearSearch={this.onClearSearch}
        controlOptions={controlOptions}
        hideVerbSelector={hideVerbSelector}
        showSelectedCount={showSelectedCount}
        primaryContent={primaryContent}
      />
    );
  }
}
