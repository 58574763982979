import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import error404 from 'src/images/error-page/404.svg?url';
import { ErrorPage } from '.';

const PageNotFound = ({ accountId }) => {
  const mainText = <div>Sorry, we couldn&apos;t find the page you&apos;re looking for.</div>;
  const subText = accountId ? (
    <div>
      Try checking to make sure you have the correct url, or
      {' '}
      <a href={`/accounts/${accountId}/dashboard`}>click here to go to your dashboard</a>
      .
    </div>
  ) : null;
  const image = <img src={error404} alt="Page not found" />;
  return <ErrorPage mainText={mainText} subText={subText} image={image} />;
};

PageNotFound.propTypes = {
  accountId: PropTypes.number,
};

PageNotFound.defaultProps = {
  accountId: null,
};

const mapStateToProps = ({ common }) => {
  const { accountId } = common;
  return { accountId };
};

export default connect(
  mapStateToProps,
)(PageNotFound);
