import momentBase from 'moment';
import { extendMoment } from 'moment-range';
import { DATE_INPUT_FORMAT } from 'src/common/constants';
import { cloneDeep } from './miscUtils';
import {
  fromRange,
  isStringOrMoment,
  subtractMomentRanges,
  toRange,
  getIntersectingMomentRanges,
  stringToMoment,
} from './dateUtils';

const moment = extendMoment(momentBase);

/*
  This file is differentiated from dateUtils.js in that instead of dealing
  directly with dates and date ranges themselves, the functions here are for
  creating and manipulating 'segments'.

  For our purposes, a segment is an object with startDate and endDate props, plus
  any other optional props that may be associated with that object. The idea here
  is that operations can be performed on an object meeting the requirements of a
  'segment' without concern for its other internal details.
*/

function sortSegmentsByStartDate(segments) {
  return [...segments].sort((a, b) => stringToMoment(a.startDate).diff(stringToMoment(b.startDate)));
}

function sortSegmentsByRangeStart(ranges) {
  return [...ranges].sort((a, b) => a.range?.start?.diff(b.range?.start));
}

function sortRangesByStart(ranges) {
  return [...ranges].sort((a, b) => a.start?.diff(b.start));
}

function objectIsSegment(obj) {
  return (
    typeof obj === 'object' &&
    isStringOrMoment(obj?.startDate) &&
    isStringOrMoment(obj?.endDate)
  );
}

function isValidSegmentArray(segments) {
  return Array.isArray(segments) &&
  segments.length &&
  segments.every(segment => objectIsSegment(segment));
}

function isRangeOrSegment(obj) {
  return moment.isRange(obj) || objectIsSegment(obj);
}

// Converts a requirement with start and end date strings to use a moment-range object
const convertSegmentToRange = (segment) => {
  if (!objectIsSegment(segment)) return segment;

  const { startDate, endDate, ...remainingProps } = segment;

  return {
    range: toRange(startDate, endDate),
    ...remainingProps,
  };
};

// Inverse of convertToRange. Switches from a moment-range back to start and end date strings
const convertSegmentFromRange = (segment) => {
  if (!moment.isRange(segment?.range)) return segment;

  const { range, ...remainingProps } = segment;

  return {
    ...fromRange(range),
    ...remainingProps,
  };
};

/*
  Given an array of segments, this function will stitch or overlay each
  into the ones before it, while leaving object props intact. This has
  the effect of subtracting overlapping segments, then merging everything
  back together.

  As an example:
  Passing this:
  [ { startDate: '2020-01-01', endDate: '2020-01-10', aaa: 'aaa' },
    { startDate: '2020-01-03', endDate: '2020-01-05', bbb: 'bbb' } ]

  Will return this:
  [ { startDate: '2020-01-01', endDate: '2020-01-02', aaa: 'aaa' },
    { startDate: '2020-01-03', endDate: '2020-01-05', bbb: 'bbb' },
    { startDate: '2020-01-06', endDate: '2020-01-10', aaa: 'aaa' },]

  The segment with property 'bbb' will be stitched with the segment
  that comes before it, with all dates being adjusted and all props as
  they were. This can be done with any number of segments at the same time.
*/
function stitchSegmentsByRange(existingSegments) {
  if (!isValidSegmentArray(existingSegments)) return existingSegments;

  const converted = existingSegments.map(range => convertSegmentToRange(range));
  const ranges = [...converted];

  // Start with an array containing only the first of the given ranges
  let stitched = [ranges.shift()];

  ranges.forEach((incoming) => {
    const unsorted = stitched.reduce((acc, curr) => {
      if (curr.range.overlaps(incoming.range, { adjacent: true })) {
        const leftoverRanges = subtractMomentRanges(curr.range, incoming.range);

        acc.push(...leftoverRanges.map(r => ({
          ...curr,
          range: r,
        })));
      } else {
        acc.push(curr);
      }

      return acc;
    }, []);

    stitched = [...unsorted, incoming];
  });

  return sortSegmentsByRangeStart(stitched).map(range => convertSegmentFromRange(range));
}

/*
  Returns an array of segments representing the space(s) between the
  existing segments within the given start and end date.
*/
function getUnfilledSegments(existingSegments, startDate, endDate) {
  if (!(
    Array.isArray(existingSegments)
    && isStringOrMoment(startDate)
    && isStringOrMoment(endDate)
  )) {
    return [];
  }

  const baseRange = toRange(startDate, endDate);
  if (!moment.isRange(baseRange)) return [];

  let remainderAcc = [baseRange];

  existingSegments.forEach((allocation) => {
    const existingRange = toRange(allocation.startDate, allocation.endDate);

    const unsorted = remainderAcc.reduce((acc, curr) => {
      if (curr.overlaps(existingRange, { adjacent: true })) {
        acc.push(...subtractMomentRanges(curr, existingRange));
      } else {
        acc.push(curr);
      }

      return acc;
    }, []);

    remainderAcc = sortRangesByStart(unsorted);
  });

  return remainderAcc.map(range => fromRange(range));
}

/*
  Given an array of segments and a start and end date, truncate or eliminate
  as required so all segments fit within the boundaries. Empty array will be
  returned if no segments overlap the given range.
*/
function trimSegmentsToRange(existingSegments, startDate, endDate) {
  if (
    !isValidSegmentArray(existingSegments) ||
    !isStringOrMoment(startDate) ||
    !isStringOrMoment(endDate)
  ) return [];

  const trimmedSegments = [];
  const boundingRange = toRange(startDate, endDate);

  existingSegments.forEach((segment) => {
    const { startDate: segmentStart, endDate: segmentEnd } = segment;
    const segmentRange = toRange(segmentStart, segmentEnd);
    const remainder = getIntersectingMomentRanges(boundingRange, segmentRange);

    if (remainder) trimmedSegments.push({ ...segment, ...fromRange(remainder) });
  });

  return sortSegmentsByStartDate(trimmedSegments);
}

/*
  Similar to trimSegmentsToRange, but will also adjust the start or end dates
  on the first/last segment to ensure the given range is entirely covered.
*/
function fitSegmentsToRange(existingSegments, startDate, endDate) {
  if (
    !isValidSegmentArray(existingSegments) ||
    !isStringOrMoment(startDate) ||
    !isStringOrMoment(endDate)
  ) return [];

  let adjustedSegments = cloneDeep(sortSegmentsByStartDate(existingSegments));

  const boundaryStart = stringToMoment(startDate);
  const boundaryEnd = stringToMoment(endDate);

  let firstSegmentStart = stringToMoment(adjustedSegments[0].startDate);
  let lastSegmentEnd = stringToMoment(adjustedSegments[adjustedSegments.length - 1].endDate);

  // Does the start or end need to be trimmed?
  if (firstSegmentStart.isBefore(boundaryStart) || lastSegmentEnd.isAfter(boundaryEnd)) {
    adjustedSegments = trimSegmentsToRange(adjustedSegments, startDate, endDate);

    firstSegmentStart = stringToMoment(adjustedSegments[0]?.startDate);
    lastSegmentEnd = stringToMoment(adjustedSegments[adjustedSegments.length - 1]?.endDate);
  }

  // Does the first segment need to be extended to fill the range?
  if (boundaryStart.isBefore(firstSegmentStart)) {
    adjustedSegments[0].startDate = boundaryStart.format(DATE_INPUT_FORMAT);
  }

  // Does the last segment need to be extended to fill the range?
  if (boundaryEnd.isAfter(lastSegmentEnd)) {
    adjustedSegments[adjustedSegments.length - 1].endDate = boundaryEnd.format(DATE_INPUT_FORMAT);
  }

  return adjustedSegments;
}

/*
  Given an array of segments, this function will split
  any overlaps into separate segments and append names if they exist.

  As an example:
  Passing this:
  [
    { name: 'Phase 1', startDate: '2021-01-01', endDate: '2021-02-01' },
    { name: 'Phase 2', startDate: '2021-01-15', endDate: '2021-02-14' },
    { name: 'Phase 3', startDate: '2021-02-02', endDate: '2021-04-01' },
    { name: 'Phase 4', startDate: '2021-04-02', endDate: '2021-04-30' },
    { name: 'Phase 5', startDate: '2021-05-01', endDate: '2021-06-01' },
  ]

  Will return this:
  [
    { name: 'Phase 1', startDate: '2021-01-01', endDate: '2021-01-14' },
    { name: 'Phase 1, Phase 2', startDate: '2021-01-15', endDate: '2021-02-01' },
    { name: 'Phase 2, Phase 3', startDate: '2021-02-02', endDate: '2021-02-14' },
    { name: 'Phase 3', startDate: '2021-02-15', endDate: '2021-04-01' },
    { name: 'Phase 4', startDate: '2021-04-02', endDate: '2021-04-30' },
    { name: 'Phase 5', startDate: '2021-05-01', endDate: '2021-06-01' },
  ]

  If segments include names, any overlaps that create a new segment will
  have a combined name noting which segments the overlap consists of.
  If names are not included in the segments, they are ignored.
  This can be done with any number of segments at the same time.
*/
function splitOverlappingSegments(existingSegments) {
  if (!isValidSegmentArray(existingSegments)) return existingSegments;

  const ranges = existingSegments.map(range => convertSegmentToRange(range));

  const intersections = [];
  ranges.forEach((incoming, index) => {
    if (index === 0) return;
    const prev = ranges[index - 1];

    if (prev.range.overlaps(incoming.range, { adjacent: true })) {
      const overlap = getIntersectingMomentRanges(prev.range, incoming.range);
      intersections.push({
        ...incoming,
        range: overlap,
      });
    }
  });

  const newSegments = sortSegmentsByRangeStart([...ranges, ...intersections]);
  const timeSegments = newSegments.map(range => convertSegmentFromRange(range));
  return stitchSegmentsByRange(timeSegments);
}

/*
  Given an array of segments, will return a range representing the earliest and latest
  dates that appear across all segments.

  If a single segment is given instead of an array, it will be treated as an array of
  one element.
*/
function getBoundingRangeFromSegments(segments) {
  if (!(
    objectIsSegment(segments) ||
    isValidSegmentArray(segments)
  )) return null;

  const startDates = [];
  const endDates = [];

  // If a single segment was passed in, wrap and use like an array
  (Array.isArray(segments) ? segments : [segments])
    .forEach((segment) => {
      const { start, end } = convertSegmentToRange(segment).range;

      startDates.push(start);
      endDates.push(end);
    });


  const startDate = moment.min(startDates);
  const endDate = moment.max(endDates);

  return { startDate, endDate };
}

/*
  Given an array of Objects that have start/end dates,
  Returns:
  nonOverlapping - Array of objects that do not overlap at all in their date ranges
  leftovers - Array of objects that had overlaps and not included in nonOverlapping
*/
const getNonOverlapping = (arrayOfObjectsWithDates) => {
  const leftovers = [];
  const nonOverlapping = arrayOfObjectsWithDates.reduce((accumulator, currentValue, idx) => {
    if (idx === 0 || moment(currentValue.startDate).isAfter(accumulator[accumulator.length - 1].endDate)) {
      accumulator.push(currentValue);
    } else {
      leftovers.push(currentValue);
    }
    return accumulator;
  }, []);
  return { nonOverlapping, leftovers };
};

/*
  Given an array of objects with start/end dates and a specific day
  Return a subset of objects that include that day within their date range
*/
const getSegmentsWithDayInRange = (arrayOfObjectsWithDates, day) => {
  const dayToCheck = moment(day);
  return arrayOfObjectsWithDates.reduce((acc, current) => {
    const { startDate, endDate } = current;
    if (dayToCheck.isBetween(startDate, endDate)) acc.push(current);
    return acc;
  }, []);
};

export {
  sortSegmentsByStartDate,
  isValidSegmentArray,
  isRangeOrSegment,
  getUnfilledSegments,
  stitchSegmentsByRange,
  objectIsSegment,
  convertSegmentFromRange,
  convertSegmentToRange,
  trimSegmentsToRange,
  fitSegmentsToRange,
  splitOverlappingSegments,
  getBoundingRangeFromSegments,
  getNonOverlapping,
  getSegmentsWithDayInRange,
};
