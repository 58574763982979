import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PEOPLE_GET_PERSON_CERTIFICATION_ATTACHMENTS_BEGIN,
  PEOPLE_GET_PERSON_CERTIFICATION_ATTACHMENTS_SUCCESS,
  PEOPLE_GET_PERSON_CERTIFICATION_ATTACHMENTS_FAILURE,
  PEOPLE_GET_PERSON_CERTIFICATION_ATTACHMENTS_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';
import { CERT_ATTACHMENTS_LIST_LIMIT, ATTACHMENTS_STATE_QUERY_PARAM } from '../constants';

export function getPersonCertificationAttachments(accountId, personId, certificationId, offset = 0, limit = CERT_ATTACHMENTS_LIST_LIMIT) {
  return {
    type: PEOPLE_GET_PERSON_CERTIFICATION_ATTACHMENTS_BEGIN,
    accountId,
    personId,
    certificationId,
    offset,
    limit,
  };
}

export function dismissGetPersonCertificationAttachmentsError() {
  return {
    type: PEOPLE_GET_PERSON_CERTIFICATION_ATTACHMENTS_DISMISS_ERROR,
  };
}

export function* doGetPersonCertificationAttachments(action) {
  const { accountId, personId, certificationId, offset, limit } = action;
  let response;
  try {
    response = yield call(
      protectedJsonGet,
      `/accounts/${accountId}/persons/${personId}/certifications/${certificationId}/attachments`,
      { offset, limit, state: ATTACHMENTS_STATE_QUERY_PARAM },
      true,
    );
  } catch (err) {
    yield put({
      type: PEOPLE_GET_PERSON_CERTIFICATION_ATTACHMENTS_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PEOPLE_GET_PERSON_CERTIFICATION_ATTACHMENTS_SUCCESS,
    certificationId,
    data: response.data,
    hasMoreItems: response.headers?.['query-has-more'] === 'True',
    clearData: offset === 0,
  });
}

export function* watchGetPersonCertificationAttachments() {
  yield takeLatest(PEOPLE_GET_PERSON_CERTIFICATION_ATTACHMENTS_BEGIN, doGetPersonCertificationAttachments);
}

export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_GET_PERSON_CERTIFICATION_ATTACHMENTS_BEGIN:
      return {
        ...state,
        getPersonCertificationAttachmentsPending: true,
        getPersonCertificationAttachmentsError: null,
      };

    case PEOPLE_GET_PERSON_CERTIFICATION_ATTACHMENTS_SUCCESS: {
      const { data, certificationId, hasMoreItems, clearData } = action;

      const newPersonCertificationItems = state.personCertifications.items.map((cert) => {
        if (cert.id === certificationId) {
          const oldAttachments = cert.attachments || [];

          return {
            ...cert,
            attachments: clearData ? data : [...oldAttachments, ...data],
            hasMoreAttachments: hasMoreItems,
          };
        }

        return cert;
      });

      return {
        ...state,
        personCertifications: {
          ...state.personCertifications,
          items: newPersonCertificationItems,
        },
        getPersonCertificationAttachmentsPending: false,
        getPersonCertificationAttachmentsError: null,
      };
    }

    case PEOPLE_GET_PERSON_CERTIFICATION_ATTACHMENTS_FAILURE:
      return {
        ...state,
        getPersonCertificationAttachmentsPending: false,
        getPersonCertificationAttachmentsError: action.data.error,
      };

    case PEOPLE_GET_PERSON_CERTIFICATION_ATTACHMENTS_DISMISS_ERROR:
      return {
        ...state,
        getPersonCertificationAttachmentsError: null,
      };

    default:
      return state;
  }
}
