import React, { useMemo, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GanttReport, HeaderButton } from '@bridgit/foundation';
import { detect } from 'detect-browser';
import { formatPeopleGanttData } from '../../gantt/people-gantt-v2/utils';
import { LIGHT_COLOR } from '../../gantt/people-gantt-v2/constants';
import { TIME_INTERVAL_UNIT_MAP } from '../../gantt/constants';
import { getGanttReportPeople } from '../redux/getGanttReportPeople';
import { getAccountAllocations } from '../../people/redux/getAccountAllocations';
import { FILTERED_PEOPLE_QUERY_ID } from '../../queries/redux/constants';
import { setFilterQuery } from '../../queries/redux/setFilterQuery';
import { setSortQuery } from '../../queries/redux/setSortQuery';
import { setSearchQuery } from '../../queries/redux/setSearchQuery';
import { PEOPLE_VIEW } from '../../../common/constants';
import { FILTER_STORAGE_KEY, PEOPLE_GANTT_REPORT_ARGS_KEY, getStorageKey } from '../../../common/localStorageKeys';
import { getValidatedLocalStorage } from '../../../utils/validators';
import { quickFilterValues } from '../../gantt/utils/peopleGanttUtils';

const titleHeader = <div className="reports-people-gantt-report-title-header">People</div>;

const PeopleGanttReport = () => {
  const dispatch = useDispatch();

  const people = useSelector(({ reports }) => reports?.ganttReportPeople);
  const allocations = useSelector(({ people }) => people?.allocations);
  const accountId = useSelector(({ common }) => common?.accountId);
  const activeView = useSelector(({ common }) => common?.activeView);
  const filteredPeopleQueries = useSelector(({ queries }) => queries?.filteredPeople);
  const getAccountAllocationsPending = useSelector(({ people }) => people?.getAccountAllocationsPending);
  const getGanttReportPeoplePending = useSelector(({ reports }) => reports?.getGanttReportPeoplePending);
  const userId = useSelector(({ login }) => login?.userInfo?.sub);

  const reportArgsStorageKey = useMemo(() => getStorageKey(activeView, accountId, PEOPLE_GANTT_REPORT_ARGS_KEY, userId), [accountId, activeView, userId]);
  const reportArgs = useMemo(() => getValidatedLocalStorage(reportArgsStorageKey) || {}, [reportArgsStorageKey]);

  const {
    startDate,
    endDate,
    timeInterval,
    timeMultiplier,
    width,
    displayOption,
    colorOption,
    showAvatar,
    filter,
    sort,
    search,
    pagesPerWindow,
  } = reportArgs;

  const showLightBackground = colorOption === LIGHT_COLOR;

  const filterStorageKey = useMemo(() => getStorageKey(PEOPLE_VIEW, accountId, FILTER_STORAGE_KEY, userId), [accountId, userId]);

  useEffect(() => {
    dispatch(setFilterQuery(FILTERED_PEOPLE_QUERY_ID, filter, filterStorageKey));
  }, [dispatch, filter, filterStorageKey]);

  useEffect(() => {
    dispatch(setSortQuery(FILTERED_PEOPLE_QUERY_ID, sort, filterStorageKey));
  }, [dispatch, sort, filterStorageKey]);

  useEffect(() => {
    dispatch(setSearchQuery(FILTERED_PEOPLE_QUERY_ID, search, filterStorageKey));
  }, [dispatch, search, filterStorageKey]);

  useEffect(() => {
    dispatch(getGanttReportPeople(accountId, filteredPeopleQueries, startDate));
  }, [accountId, dispatch, filteredPeopleQueries, startDate]);

  useEffect(() => {
    dispatch(getAccountAllocations(accountId, startDate, endDate));
  }, [accountId, dispatch, endDate, startDate]);

  const filterOptions = useMemo(() => quickFilterValues(filteredPeopleQueries.filter), [filteredPeopleQueries.filter]);

  // Format the bar and legend data so it can be displayed in the Gantt chart.
  const { bars, legend } = useMemo(() => {
    const { availableFilter, issuesFilter } = filterOptions;
    return people?.length
      ? formatPeopleGanttData(
        people,
        allocations,
        startDate,
        endDate,
        showAvatar,
        showLightBackground,
        displayOption,
        availableFilter,
        issuesFilter,
      )
      : { bars: [], legend: [] };
  }, [filterOptions, people, allocations, startDate, endDate, showAvatar, showLightBackground, displayOption]);

  const containerStyle = useMemo(() => ({
    minHeight: `${window.innerHeight}px`,
    width: `${width}px`,
  }), [width]);

  const reportStyle = useMemo(() => ({
    width: `${width}px`,
  }), [width]);

  const handlePrint = useCallback(() => {
    const browser = detect();
    try {
      if (browser && browser.name.toLowerCase() === 'safari') {
        setTimeout(() => document.execCommand('print', false, null), 0);
      } else {
        setTimeout(() => window.print(), 0);
      }
    } catch (e) {
      setTimeout(() => window.print(), 0);
    }
  }, []);

  if (!startDate || !endDate) return null;

  return (
    <div className="reports-people-gantt-report" style={containerStyle}>
      <div className="report" style={reportStyle}>
        <HeaderButton
          label="Print"
          onClick={handlePrint}
        />
        <div className="content">
          <GanttReport
            bars={bars}
            legend={legend}
            title={titleHeader}
            width={width}
            timeInterval={TIME_INTERVAL_UNIT_MAP[timeInterval]}
            timeMultiplier={timeMultiplier}
            startDate={startDate}
            endDate={endDate}
            isLoading={getAccountAllocationsPending || getGanttReportPeoplePending}
            withHeader
            pagesPerWindow={pagesPerWindow}
          />
        </div>
      </div>
    </div>
  );
};

export default PeopleGanttReport;
