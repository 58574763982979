import {
  TABLE_SET_COLUMNS,
} from './constants';

export function setColumns(newVisibleColumns, newVisibleColumnNames) {
  return {
    type: TABLE_SET_COLUMNS,
    data: { newVisibleColumns, newVisibleColumnNames },
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case TABLE_SET_COLUMNS: {
      const { newVisibleColumns, newVisibleColumnNames } = action.data;

      if (!newVisibleColumns || !newVisibleColumnNames) {
        return state;
      }

      return {
        ...state,
        visibleColumns: newVisibleColumns,
        visibleColumnNames: newVisibleColumnNames,
      };
    }
    default:
      return state;
  }
}
