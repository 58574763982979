import { call, put, takeLatest } from 'redux-saga/effects';
import {
  FORECAST_GET_BENCH_COST_INDIVIDUALS_BEGIN,
  FORECAST_GET_BENCH_COST_INDIVIDUALS_SUCCESS,
  FORECAST_GET_BENCH_COST_INDIVIDUALS_FAILURE,
  FORECAST_GET_BENCH_COST_INDIVIDUALS_DISMISS_ERROR,
} from './constants';
import graphApiClient from '../../../common/GraphAPIClient';
import { getBenchCostIndividualQuery } from '../queries/getBenchCostIndividualQuery';

export function getBenchCostIndividuals(accountId, queries, startDate, endDate, pursuitFilter = []) {
  const includePursuitIds = pursuitFilter.map(pursuit => pursuit.id);
  const { query, variables } = getBenchCostIndividualQuery(accountId, queries, startDate, endDate, includePursuitIds);

  return {
    type: FORECAST_GET_BENCH_COST_INDIVIDUALS_BEGIN,
    query,
    variables,
  };
}

export function dismissGetBenchCostIndividualsError() {
  return {
    type: FORECAST_GET_BENCH_COST_INDIVIDUALS_DISMISS_ERROR,
  };
}

export function* doGetBenchCostIndividuals(action) {
  const { query, variables } = action;
  let res;
  try {
    res = yield call(graphApiClient.query, { query, variables });
  } catch (err) {
    yield put({
      type: FORECAST_GET_BENCH_COST_INDIVIDUALS_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: FORECAST_GET_BENCH_COST_INDIVIDUALS_SUCCESS,
    data: res.data,
  });
}

export function* watchGetBenchCostIndividuals() {
  yield takeLatest(FORECAST_GET_BENCH_COST_INDIVIDUALS_BEGIN, doGetBenchCostIndividuals);
}

export function reducer(state, action) {
  switch (action.type) {
    case FORECAST_GET_BENCH_COST_INDIVIDUALS_BEGIN:
      return {
        ...state,
        getBenchCostIndividualsPending: true,
        getBenchCostIndividualsError: null,
      };

    case FORECAST_GET_BENCH_COST_INDIVIDUALS_SUCCESS:
      return {
        ...state,
        getBenchCostIndividualsPending: false,
        getBenchCostIndividualsError: null,
        benchCostIndividuals: action.data?.benchCostIndividuals?.items,
      };

    case FORECAST_GET_BENCH_COST_INDIVIDUALS_FAILURE:
      return {
        ...state,
        getBenchCostIndividualsPending: false,
        getBenchCostIndividualsError: action.data.error,
      };

    case FORECAST_GET_BENCH_COST_INDIVIDUALS_DISMISS_ERROR:
      return {
        ...state,
        getBenchCostIndividualsError: null,
      };

    default:
      return state;
  }
}
