import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PROJECT_TYPES, TYPE } from 'src/features/projects/constants';
import { ACCOUNT_MODULE_PURSUIT_TRACKING } from 'src/common/constants';
import { Can } from 'src/features/wrapped-components';
import { isAuthorized } from 'src/features/permissions/utils/permissionUtils';
import { PERM_WRITE, PERM_PROJECT } from 'src/features/permissions/utils/constants';
import { updateProject } from './redux/actions';

const ProjectTypeEditor = ({
  accountId,
  selectedProject,
  updateProject,
  updateProjectPending,
  runsInModal,
  permissions,
}) => {
  const parentName = `Project Details${runsInModal ? ' Modal' : ''}`;
  const selectDisabled = useMemo(() => (
    updateProjectPending || !isAuthorized(accountId, permissions, PERM_WRITE, PERM_PROJECT)
  ),
  [accountId, permissions, updateProjectPending]);

  const updateProjectType = useCallback((event) => {
    updateProject(
      accountId,
      selectedProject.id,
      { type: event.target.value },
      selectedProject,
      parentName,
      TYPE,
    );
  }, [accountId, parentName, selectedProject, updateProject]);

  return (
    <Can
      module={ACCOUNT_MODULE_PURSUIT_TRACKING}
      yes={(
        <FormControl className="projects-project-type-editor" variant="outlined">
          <Select
            className="project-type-select"
            value={selectedProject.type}
            onChange={updateProjectType}
            disabled={selectDisabled}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
              getContentAnchorEl: null,
            }}
          >
            { PROJECT_TYPES.map(projectType => (
              <MenuItem
                key={projectType}
                value={projectType}
                disabled={selectedProject.type === projectType}
                className="project-type-select-item"
              >
                {projectType}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};

ProjectTypeEditor.propTypes = {
  accountId: PropTypes.number.isRequired,
  selectedProject: PropTypes.object.isRequired,
  updateProject: PropTypes.func.isRequired,
  updateProjectPending: PropTypes.bool.isRequired,
  runsInModal: PropTypes.bool,
  permissions: PropTypes.object.isRequired,
};

ProjectTypeEditor.defaultProps = {
  runsInModal: false,
};

/* istanbul ignore next */
function mapStateToProps({ common, projects, login }) {
  const { accountId } = common;
  const { updateProjectPending } = projects;
  const { userInfo: { permissions } } = login;

  return {
    accountId,
    updateProjectPending,
    permissions,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    updateProject: bindActionCreators(updateProject, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectTypeEditor);
