import { call, put, takeLatest } from 'redux-saga/effects';
import {
  LOGIN_REQUEST_FORGOT_PASSWORD_BEGIN,
  LOGIN_REQUEST_FORGOT_PASSWORD_SUCCESS,
  LOGIN_REQUEST_FORGOT_PASSWORD_FAILURE,
  LOGIN_REQUEST_FORGOT_PASSWORD_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';

export function requestForgotPassword(email) {
  return {
    type: LOGIN_REQUEST_FORGOT_PASSWORD_BEGIN,
    email,
  };
}

export function dismissRequestForgotPasswordError() {
  return {
    type: LOGIN_REQUEST_FORGOT_PASSWORD_DISMISS_ERROR,
  };
}

export function* doRequestForgotPassword(action) {
  const { email } = action;

  try {
    yield call(protectedJsonPost, '/forgot-password', { email });
  } catch (err) {
    yield put({
      type: LOGIN_REQUEST_FORGOT_PASSWORD_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    email,
    type: LOGIN_REQUEST_FORGOT_PASSWORD_SUCCESS,
  });
}

export function* watchRequestForgotPassword() {
  yield takeLatest(LOGIN_REQUEST_FORGOT_PASSWORD_BEGIN, doRequestForgotPassword);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case LOGIN_REQUEST_FORGOT_PASSWORD_BEGIN:
      return {
        ...state,
        requestForgotPasswordPending: true,
        requestForgotPasswordError: null,
      };

    case LOGIN_REQUEST_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        requestForgotPasswordPending: false,
        requestForgotPasswordError: null,
      };

    case LOGIN_REQUEST_FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        requestForgotPasswordPending: false,
        requestForgotPasswordError: action.data.error,
      };

    case LOGIN_REQUEST_FORGOT_PASSWORD_DISMISS_ERROR:
      return {
        ...state,
        requestForgotPasswordError: null,
      };

    default:
      return state;
  }
}
