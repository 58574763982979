import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Clear } from '@material-ui/icons';
import { Avatar } from '@material-ui/core';
import emptyAvatar from '../../images/empty_avatar_blue.svg?url';

export default class SelectedPerson extends PureComponent {
  static propTypes = {
    onDelete: PropTypes.func.isRequired,
    person: PropTypes.object.isRequired,
    showClearButton: PropTypes.bool,
  };

  static defaultProps = {
    showClearButton: true,
  }

  render() {
    const { person, onDelete, showClearButton } = this.props;
    return (
      <div className="allocations-selected-person">
        {!person.photoUrl
          ? <Avatar className="person-portrait" src={emptyAvatar} />
          : <Avatar className="person-portrait" src={person.photoUrl} />}

        <div className="person-identity">
          <span className="person-name" title={person.name}>{person.name}</span>
          <span className="person-title" title={person.title}>{person.title}</span>
        </div>

        { showClearButton && <Clear className="clear-icon" onClick={onDelete} /> }
      </div>
    );
  }
}
