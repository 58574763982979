import moment from 'moment';
import { PURSUIT } from '../projects/constants';

export const FORECASTING_TIME_INTERVALS = [
  {
    name: 0.5,
    label: '6M',
  },
  {
    name: 1,
    label: '1Y',
  },
  {
    name: 2,
    label: '2Y',
  },
  {
    name: 3,
    label: '3Y',
  },
  {
    name: 4,
    label: '4Y',
  },
  {
    name: 5,
    label: '5Y',
  },
];

export const THREE_YEARS = 3;
export const TRUE_UTILIZATION_HELP = 'The true utilization rate is calculated by averaging the monthly allocation rates for all people matching the applied filters, where the daily allocation rate for each person is capped at 100%.';
export const AVERAGE_UTILIZATION_HELP = 'The average utilization rate is calculated by averaging the monthly allocation rates for all people matching the applied filters.';
export const BENCH_COST_HELP = 'The Bench Cost report calculates the cost of under-utilized people using the Cost Rate (hr) value from their profiles';
export const SIDE_PANEL_WIDTH = 360;
export const SUPPLY_ROW_ID = 'people';
export const DEMAND_ROW_ID = 'projectDemand';
export const PURSUITS_ROW_ID = 'pursuits';
export const TOTAL_DEMAND_ROW_ID = 'totalDemand';
export const DELTA_ROW_ID = 'delta';
export const ROLE_TYPE = 'Roles';
export const TITLE_TYPE = 'Titles';
export const HEADER_TYPE = 'Header';
export const GRAPH_TAB = 'Graph';
export const TABLE_TAB = 'Table';

export const WORKFORCE_TOOLTIPS = {
  [ROLE_TYPE]: 'The Project Demand data is calculated by taking a weighted sum of your Project roles and factoring in both the allocation percentage and length of the roles. You can filter by role or any other Project field to get more specific data.',
  [TITLE_TYPE]: 'The People data is calculated by summing up your People and factoring in employment dates and time off. You can filter by title or any other People field to get more specific data.',
  [HEADER_TYPE]: 'The Strategic Workforce Planning report gives a comparison of the People you have on staff and the demand for people on your projects (Project demand).',
};

export const WORKFORCE_ANALYTICS_PROPERTIES = {
  [ROLE_TYPE]: 'Project Demand',
  [TITLE_TYPE]: 'People',
  [HEADER_TYPE]: 'Title',
};

export const PURSUIT_QUERY_FILTER = [
  { bool: { should: [{ match: { state: PURSUIT } }] } },
  { range: { endDate: { gte: moment(Date.now()).format('YYYY-MM-DD') } } },
];
export const PURSUIT_QUERY_SORT = [
  { startDate: { order: 'asc' } },
  { name: { order: 'asc' } },
];

export const BREAKDOWN_SELECT_DEFAULT = -1;

export const BREAKDOWN_TAB_AWARDED = 0;
export const BREAKDOWN_TAB_AWARDED_AND_PURSUITS = 1;
export const BREAKDOWN_TAB_DEFAULT = BREAKDOWN_TAB_AWARDED;

export const CHART_TOOLTIP_WIDTH = 275;
