import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Add } from '@material-ui/icons';
import moment from 'moment';
import { validateBasicInputs } from 'src/utils/validators';
import { apiFormatPhoneNumber } from 'src/utils/miscUtils';
import { PERM_WRITE, PERM_PROJECT } from 'src/features/permissions/utils/constants';
import { DEFAULT_PROJECT_COLOUR } from 'src/features/common/redux/constants';
import { getColorType } from './utils/projectUtils';
import { FloatingActionButton, ValidatedForm, Can, EditControls } from '../wrapped-components';
import { Modal, Confirm } from '../common';
import {
  DATE_INPUT_FORMAT,
  FIELD_TYPE_PHONE,
  MIN_API_CONFIGURABLE_DATE,
  MIN_API_CONFIGURABLE_DATE_MESSAGE,
  FIELD_TYPE_PHONE_E164,
} from '../../common/constants';
import { AddPhasesModal } from '.';
import { addProject, dismissAddProjectError } from './redux/actions';
import { hasModuleEnabled } from '../permissions/utils/permissionUtils';
import { formatPhoneNumberInput } from '../../utils/phoneNumberUtils';
import { WIN_PERCENT, WIN_PERCENT_STEP, WIN_PERCENT_LABEL, OPPORTUNITY } from './constants';

export class AddProjectModal extends Component {
  static propTypes = {
    addProject: PropTypes.func.isRequired,
    dismissAddProjectError: PropTypes.func.isRequired,
    addProjectError: PropTypes.object,
    accountId: PropTypes.number.isRequired,
    projectFields: PropTypes.arrayOf(PropTypes.object).isRequired,
    projects: PropTypes.arrayOf(PropTypes.object).isRequired,
    addProjectPending: PropTypes.bool.isRequired,
    phases: PropTypes.array.isRequired,
    requiredInputs: PropTypes.arrayOf(PropTypes.object),
    optionalInputs: PropTypes.arrayOf(PropTypes.object),
    dateInputs: PropTypes.arrayOf(PropTypes.object),
    defaultPhases: PropTypes.arrayOf(PropTypes.object),
    accountColors: PropTypes.array,
    defaultProjectColors: PropTypes.array,
    accountModules: PropTypes.arrayOf(PropTypes.object).isRequired,
    modalOrigin: PropTypes.string,
    redirectToProject: PropTypes.func,
    winPercentageFlag: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    addProjectError: null,
    requiredInputs: [],
    optionalInputs: [],
    dateInputs: [],
    defaultPhases: [],
    accountColors: [],
    defaultProjectColors: [],
    modalOrigin: '',
    redirectToProject: () => {},
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { addProjectPending, defaultPhases } = nextProps;
    const open = state.addProjectPending && !addProjectPending ? false : state.open;
    return {
      open,
      addProjectPending,
      defaultPhases,
      selectedPhases: defaultPhases.length && !state.defaultPhases.length ? defaultPhases : state.selectedPhases,
    };
  }

  state = {
    open: false,
    isValid: false,
    values: {},
    phaseValues: {},
    nextPageIsActive: false,
    requiredInputs: [],
    optionalInputs: [],
    dateInputs: [],
    selectedPhases: [],
    defaultPhases: [],
    addProjectPending: false,
  }

  componentDidMount() {
    const { defaultPhases } = this.props;
    this.setState({
      selectedPhases: defaultPhases,
    });
  }

  getNewInput = (input) => {
    const { values } = this.state;
    const newInput = { ...input };
    newInput.value = values[newInput.name] || null;
    return newInput;
  }

  assignValues = () => {
    const { requiredInputs, optionalInputs, dateInputs, accountModules } = this.props;

    const newRequiredInputs = requiredInputs.reduce((acc, curr) => {
      if (hasModuleEnabled(accountModules, curr?.module)) {
        acc.push(this.getNewInput(curr));
      }
      return acc;
    }, []);

    const newOptionalInputs = optionalInputs.map(input => this.getNewInput(input));

    const newDateInputs = dateInputs.map(input => this.getNewInput(input));

    this.setState({
      requiredInputs: newRequiredInputs,
      optionalInputs: newOptionalInputs,
      dateInputs: newDateInputs,
    });
  }

  toggle = () => {
    const { nextPageIsActive } = this.state;

    if (nextPageIsActive) {
      this.assignValues();
      this.setState({ nextPageIsActive: false });
    } else {
      this.toggleModal();
    }
  }

  toggleModal = () => {
    const { open } = this.state;

    this.setState({
      open: !open,
      isValid: false,
      nextPageIsActive: false,
      values: {
        Color: DEFAULT_PROJECT_COLOUR,
      },
      phaseValues: {},
    }, () => {
      this.assignValues();
    });
  }

  clickNext = () => {
    this.setState({ nextPageIsActive: true });
  }

  addProject = () => {
    const {
      addProject,
      accountId,
      projectFields,
      addProjectPending,
      phases,
      accountColors,
      defaultProjectColors,
      modalOrigin,
      redirectToProject,
    } = this.props;
    const { values, phaseValues, selectedPhases } = this.state;
    const phaseData = [];
    const fields = [];

    if (addProjectPending) return;

    if (phases.length) {
      selectedPhases.forEach((phase) => {
        const phaseStartDate = phaseValues[`Start date-${phase.id}`];
        const phaseEndDate = phaseValues[`End date-${phase.id}`];

        if (!phaseStartDate || !phaseEndDate) return;

        const phaseObj = {
          id: phase.id,
          name: phase.name,
          startDate: moment(phaseStartDate).format(DATE_INPUT_FORMAT),
          endDate: moment(phaseEndDate).format(DATE_INPUT_FORMAT),
          subPhases: [],
        };

        phase.subPhases.forEach((sub) => {
          const subStartDate = phaseValues[`Start date-${sub.id}`];
          const subEndDate = phaseValues[`End date-${sub.id}`];

          if (!subStartDate || !subEndDate) return;

          phaseObj.subPhases.push({
            id: sub.id,
            name: sub.name,
            startDate: moment(subStartDate).format(DATE_INPUT_FORMAT),
            endDate: moment(subEndDate).format(DATE_INPUT_FORMAT),
          });
        });

        phaseData.push(phaseObj);
      });
    }

    Object.keys(values).filter(v => Number(v)).forEach((key) => {
      if (values[key]) {
        const fieldId = Number(key);
        const { name, type, isPrivate } = projectFields.find(f => f.id === fieldId);
        const field = { fieldId, name, type, isPrivate };
        if (Array.isArray(values[key])) {
          field.values = values[key];
        } else if (type === 'Date') {
          const dateString = values[key].toJSON().split('T')[0];
          field.values = [dateString];
        } else if (type === FIELD_TYPE_PHONE) {
          field.values = [apiFormatPhoneNumber(values[key])];
        } else if (type === FIELD_TYPE_PHONE_E164) {
          field.values = [formatPhoneNumberInput(values[key])];
        } else {
          field.values = [values[key]];
        }

        fields.push(field);
      }
    });

    const data = {
      name: values.Name,
      colour: values.Color,
      startDate: moment(values['Start date']).format(DATE_INPUT_FORMAT),
      endDate: moment(values['End date']).format(DATE_INPUT_FORMAT),
      type: values.Type,
      winPercent: values.winPercent,
      fields,
    };

    const colorType = getColorType(defaultProjectColors, accountColors, values.Color);

    const analyticsPayload = {
      name: data.name,
      phaseDataLength: phaseData.length,
      colour: data.colour,
      colorType,
      type: data.type,
      winPercent: data.winPercent,
      modalOrigin,
    };

    addProject(accountId, data, phaseData, analyticsPayload, (project) => {
      const { name, id, type, state: status } = project;
      const mixpanelData = {
        name,
        id,
        type,
        status,
      };

      redirectToProject(null, mixpanelData);
    });
  }

  validate = (changedValues) => {
    const { projects, phases } = this.props;
    const { requiredInputs, optionalInputs, dateInputs, nextPageIsActive, values } = this.state;
    const newValues = { ...values, ...changedValues };
    const projectNames = projects.map(project => project.name);
    const inputsToValidate =
      !phases.length || nextPageIsActive
        ? [...requiredInputs, ...optionalInputs, ...dateInputs]
        : [...requiredInputs, ...optionalInputs];
    const errors = validateBasicInputs(inputsToValidate, newValues);

    const startDate = newValues['Start date'];
    const endDate = newValues['End date'];
    if (moment(startDate).isBefore(MIN_API_CONFIGURABLE_DATE) || moment(endDate).isBefore(MIN_API_CONFIGURABLE_DATE)) {
      errors.minDate = MIN_API_CONFIGURABLE_DATE_MESSAGE;
    }
    if (startDate && endDate && startDate >= endDate) {
      errors['End date'] = 'End date must be later than the Start date';
    }

    const projectName = newValues.Name;
    if (projectNames.indexOf(projectName) !== -1) {
      errors.Name = 'Project name must be unique.';
    }

    if (Object.keys(errors).length === 0) {
      this.setState({
        isValid: true,
        values: newValues,
      });
    } else {
      this.setState({
        isValid: false,
      });
    }

    return errors;
  }

  setValid = (isValid) => {
    this.setState({ isValid });
  }

  onValueChanged = (name, values) => {
    const { winPercentageFlag } = this.props;
    const { dateInputs, optionalInputs } = this.state;

    if (name === 'Start date') {
      const startDate = values['Start date'];
      const startParse = startDate ? moment(startDate).add(1, 'days') : null;
      const newDateInputs = dateInputs.map((i) => {
        const newInput = { ...i };
        if (newInput.name === 'End date') {
          newInput.initDate = startParse;
          newInput.minDate = startParse;
        }

        return newInput;
      });

      this.setState({
        dateInputs: newDateInputs,
      });
    }

    if (name === 'Type' && winPercentageFlag) {
      const winPercentInput = {
        placeholder: 'Enter win %',
        name: WIN_PERCENT,
        type: 'percentage',
        label: WIN_PERCENT_LABEL,
        numStep: WIN_PERCENT_STEP,
      };
      const newOptionalInputs = values?.Type === OPPORTUNITY
        ? [winPercentInput, ...optionalInputs]
        : optionalInputs.filter(({ name }) => name !== WIN_PERCENT);
      this.setState({
        optionalInputs: newOptionalInputs,
      });
    }
  }

  updatePhases = (phaseValues, selectedPhases) => {
    this.setState({ phaseValues, selectedPhases });
  }

  renderButtons = () => {
    const { addProjectPending, phases } = this.props;
    const { nextPageIsActive, isValid } = this.state;

    if (phases.length && !nextPageIsActive) {
      return [
        <div key="buttons" className="projects-next-modal-buttons">
          <EditControls
            primaryAction={this.clickNext}
            secondaryAction={this.toggle}
            disabled={!isValid}
            primaryText="Next"
          />
        </div>,
      ];
    }

    return [
      <div key="buttons" className="projects-add-project-modal-buttons">
        <EditControls
          primaryAction={this.addProject}
          secondaryAction={this.toggle}
          disabled={!isValid}
          pending={addProjectPending}
          primaryText="Add Project"
          secondaryText={nextPageIsActive ? 'Back' : 'Cancel'}
        />
      </div>,
    ];
  }

  renderMainPage = () => {
    const { requiredInputs, optionalInputs } = this.state;

    return (
      <>
        <div className="input-container">
          <div className="input-header required">Required Fields</div>
          <ValidatedForm
            inputs={requiredInputs}
            validate={this.validate}
            onValueChanged={this.onValueChanged}
            forceUpdate
          />
        </div>
        <div className="input-container other">
          <div className="input-header">Other Fields</div>
          <ValidatedForm
            inputs={optionalInputs}
            validate={this.validate}
            onValueChanged={this.onValueChanged}
            forceUpdate
          />
        </div>
      </>
    );
  }

  renderNextPage = () => {
    const {
      selectedPhases,
      dateInputs,
      values,
      phaseValues,
    } = this.state;

    return (
      <AddPhasesModal
        inputs={dateInputs}
        validateProjectDates={this.validate}
        onValueChanged={this.onValueChanged}
        projectValues={values}
        phaseValues={phaseValues}
        setValid={this.setValid}
        selectedPhases={selectedPhases}
        updatePhases={this.updatePhases}
      />
    );
  }

  render() {
    const { dismissAddProjectError, addProjectError } = this.props;
    const { open, nextPageIsActive } = this.state;

    return (
      <div className="projects-add-project-modal">
        <Can
          action={PERM_WRITE}
          subject={PERM_PROJECT}
          yes={(
            <FloatingActionButton
              icon={<Add />}
              tooltipText="Add a Project"
              onClick={this.toggle}
            />
          )}
        />

        {open && (
          <Modal
            classes={{ paper: 'projects-add-project-modal-dialog' }}
            headline="New Project"
            showClose={nextPageIsActive}
            buttons={this.renderButtons()}
            closeModal={this.toggleModal}
            disableEnforceFocus
          >
            { nextPageIsActive ? this.renderNextPage() : this.renderMainPage() }
          </Modal>
        )}

        {
          addProjectError?.data?.errorType === 'MissingRequiredField' &&
          (
            <Confirm
              headline="Unable to add project"
              acceptButtonText="Okay"
              onAccept={dismissAddProjectError}
              showSecondary={false}
            >
              <p>You are unable to add this project to your account due to a required, private field that you do not have access to.</p>
            </Confirm>
          )
        }
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps({ projects, accountSettings, common, accounts, launchDarkly }) {
  const { projectFields, phases, accountModules } = accountSettings;
  const { addProjectPending, projects: projectList, addProjectError, projectDefaults } = projects;
  const { accountId } = common;
  const { accountColors } = accounts;
  const { pursuitsWinPercentage } = launchDarkly;

  return {
    addProjectPending,
    phases,
    projectFields,
    projects: projectList,
    accountId,
    addProjectError,
    accountColors,
    defaultProjectColors: projectDefaults?.colors || [],
    accountModules,
    winPercentageFlag: pursuitsWinPercentage,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    addProject: bindActionCreators(addProject, dispatch),
    dismissAddProjectError: bindActionCreators(dismissAddProjectError, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddProjectModal);
