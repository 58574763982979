import React from 'react';
import PropTypes from 'prop-types';
import { ProjectLink } from '@bridgit/foundation';
import { AUTOMATION_NOTIFY_HEADER_VALUE } from './ids';

export default function ProjectNotifyHeader({
  label,
  value,
  project,
}) {
  const { colour, name, id } = project;

  return (
    <>
      <div>
        <span>{label}</span>
        <div className="notify-header-value" id={AUTOMATION_NOTIFY_HEADER_VALUE}>{value}</div>
      </div>
      <div className="notify-header-project">
        <span>Project</span>
        <div>
          <ProjectLink
            backgroundColor={colour}
            projectName={name}
            projectId={id}
          />
        </div>
      </div>
    </>
  );
}

ProjectNotifyHeader.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  project: PropTypes.object.isRequired,
};
