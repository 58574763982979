import {
  COMMON_SET_CONTENT_VIEW,
} from './constants';

export function setContentView(contentView = null) {
  return {
    contentView,
    type: COMMON_SET_CONTENT_VIEW,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_SET_CONTENT_VIEW: {
      const { contentView = null } = action;
      return {
        ...state,
        contentView,
      };
    }

    default:
      return state;
  }
}
