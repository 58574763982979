import { gql } from 'apollo-boost';
import { MAX_SIZE, MIN_FROM } from '../../../common/constants';
import { generateRequestFilter, generateRequestSort } from '../../../filters/graphqlUtils';
import { PROJECT_SCHEMAS } from '../../../common/descriptors/project';

const PROJECT_NAMES_QUERY = gql`
    query projectNamesQuery($accountId: Int!, $structuredSort: [ProjectSort], $from: Int, $size: Int, $filters: [ESQuery]){
      projects(accountId: $accountId, structuredSort: $structuredSort, from: $from, size: $size, filters: $filters ) {
        count
        items {
          id
          name
          colour
          state
        }
      }
    }
  `;

export const getProjectNamesQuery = (accountId, queries = {}) => {
  const defaults = {
    size: MAX_SIZE,
    from: MIN_FROM,
  };

  const { filter, sort } = queries;
  const filters = filter ? generateRequestFilter(filter, PROJECT_SCHEMAS) : [];
  const structuredSort = sort ? generateRequestSort(sort, PROJECT_SCHEMAS, []) : [];

  const variables = {
    accountId,
    filters,
    structuredSort,
    ...defaults,
  };

  return {
    query: PROJECT_NAMES_QUERY,
    variables,
  };
};
