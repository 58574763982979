import {
  GANTT_CLEAR_PEOPLE_GANTT_SHARE_EXPIRY_DATE,
} from './constants';

export function clearPeopleGanttShareExpiryDate() {
  return {
    type: GANTT_CLEAR_PEOPLE_GANTT_SHARE_EXPIRY_DATE,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case GANTT_CLEAR_PEOPLE_GANTT_SHARE_EXPIRY_DATE:
      return {
        ...state,
        sharePeopleGanttDataExpiryDate: null,
      };

    default:
      return state;
  }
}
