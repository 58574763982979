const ALLOCATION_UPDATED = 'Allocation Updated';
const ALLOCATION_ADDED = 'Allocation Added';
const ALLOCATION_DELETED = 'Allocation Deleted';
const PROJECT_REQUESTER_ADDED = 'Project Requester Added';
const PROJECT_REQUESTER_DELETED = 'Project Requester Deleted';
const PROJECT_DETAILS_UPDATED = 'Project Details Updated';
const PROJECT_CANCELED = 'Project Canceled';
const PROJECT_REACTIVATED = 'Project Reactivated';
const PROJECT_LOST = 'Project Lost';
const PROJECT_TYPE_UPDATED = 'Project Type Updated';
const ASSIGNMENT_COMMUNICATION_SENT = 'Communication sent - Assignment';
const ASSIGNMENT_REMOVE_REPLACE_COMMUNICATION_SENT = 'Communication sent - Remove/Replace';
const ASSIGNMENT_REMOVE_REPLACE_MODAL_LAUNCHED = 'Removal/Replace communication modal launches';
const PROJECT_NOTE_ADDED = 'Project Note Added';
const PROJECT_NOTE_EDITED = 'Project Note Edited';
const PROJECT_NOTE_DELETED = 'Project Note Deleted';
const PROJECT_ADDED = 'Project Added';
const PROJECT_PHASES_ADDED = 'Phases Added to a Project';
const PROJECT_PHASE_DELETED = 'Phase Deleted from Project';
const PROJECT_SINGLE_ROLE_BUTTON_CLICKED = 'Add Roles - Add Single Role Button Clicked';
const PROJECT_SINGLE_ROLE_SELECTED = 'Add Roles - Single Role Selected';
const PROJECT_DUPLICATE_ROLE_SETUP = 'Duplicate Role in Setup';
const PROJECT_ROLE_DELETED_FROM_PROJECT = 'Role Deleted from Project';
const PROJECT_ROLE_UPDATE_CAUSED_DELETION = 'Role Update caused Role Deletion';
const PROJECT_CANCEL_ASSIGNMENT_COMMUNICATION_MODAL = 'Assignment Communication modal - Cancel button is selected';
const PROJECT_GANTT_VIEW = 'Project Gantt View';
const PROJECT_SEARCH_PROJECTS = 'Search on Projects';
const PROJECT_VIEW_ROLES_SORTED = 'Project View - Roles Sorted';
const PROJECT_ROLE_STATUS_FILTER_REMOVED = 'Role Status Filter Removed';
const PROJECT_ROLE_STATUS_FILTER_ADDED = 'Role Status Filter Added';
const PROJECT_ROLES_TAB_CLICKED = 'Project - Roles Tab Clicked';
const PROJECT_HOURLY_ASSIGNMENTS_TAB_CLICKED = 'Project - Hourly Assignments Tab Clicked';
const PROJECT_DETAILS_TAB_CLICKED = 'Project - Details Tab Clicked';
const PROJECT_DATES_TAB_CLICKED = 'Project - Dates Tab Clicked';
const PROJECT_NAME_UPDATED = 'Project Name Updated';
const PROJECT_CLOSE_PROJECT_PANEL = 'Close Project Panel';
const PROJECT_VIEW_PROJECT_NOTES = 'View Project Notes';
const PROJECT_VIEW_PROJECT_ACTIVITY = 'View Project Activity';
const PROJECT_VIEW_PROJECT_DETAILS = 'View Project Details';
const PROJECT_VIEW_ADDRESS_ON_MAP = 'View Address On A Map';
const PROJECT_COLOUR_UPDATED = 'Project colour updated';
const PROJECT_ROLE_DUPLICATED = 'Role Duplicated';
const PROJECT_COPY_ROLE_FROM_PROJECT = 'Add Roles - Copy from Project';
const PROJECT_ROLE_TEMPLATE_BUTTON_CLICKED = 'Role template button clicked';
const PROJECT_ADD_ROLES_TEMPLATE_USED = 'Add Roles - Template Used';
const PROJECT_DATES_SHIFTED = 'Project Dates Shifted';
const PROJECT_ACTIVITY_ENTRY_EXPANDED = 'Project activity entry expanded';
const PROJECT_ADDRESS_UPDATED = 'Project Address Updated';
const PROJECT_VIEW_PANEL = 'Project Gantt - View Project in Panel';
const ALLOCATION_START_DATE_UPDATED = 'Allocation Start Date Updated';
const ALLOCATION_END_DATE_UPDATED = 'Allocation End Date Updated';
const PROJECT_NAVIGATE_TO_MAP = 'Navigate to Map';
const PROJECT_WIN_PERCENT_UPDATED = 'Project Win % Updated';

export {
  ALLOCATION_UPDATED,
  ALLOCATION_ADDED,
  ALLOCATION_DELETED,
  PROJECT_REQUESTER_ADDED,
  PROJECT_REQUESTER_DELETED,
  PROJECT_DETAILS_UPDATED,
  PROJECT_CANCELED,
  PROJECT_REACTIVATED,
  PROJECT_LOST,
  PROJECT_TYPE_UPDATED,
  ASSIGNMENT_COMMUNICATION_SENT,
  ASSIGNMENT_REMOVE_REPLACE_COMMUNICATION_SENT,
  ASSIGNMENT_REMOVE_REPLACE_MODAL_LAUNCHED,
  PROJECT_NOTE_ADDED,
  PROJECT_NOTE_EDITED,
  PROJECT_NOTE_DELETED,
  PROJECT_ADDED,
  PROJECT_PHASES_ADDED,
  PROJECT_PHASE_DELETED,
  PROJECT_SINGLE_ROLE_BUTTON_CLICKED,
  PROJECT_SINGLE_ROLE_SELECTED,
  PROJECT_DUPLICATE_ROLE_SETUP,
  PROJECT_ROLE_DELETED_FROM_PROJECT,
  PROJECT_ROLE_UPDATE_CAUSED_DELETION,
  PROJECT_CANCEL_ASSIGNMENT_COMMUNICATION_MODAL,
  PROJECT_GANTT_VIEW,
  PROJECT_SEARCH_PROJECTS,
  PROJECT_VIEW_ROLES_SORTED,
  PROJECT_ROLE_STATUS_FILTER_REMOVED,
  PROJECT_ROLE_STATUS_FILTER_ADDED,
  PROJECT_ROLES_TAB_CLICKED,
  PROJECT_HOURLY_ASSIGNMENTS_TAB_CLICKED,
  PROJECT_DETAILS_TAB_CLICKED,
  PROJECT_DATES_TAB_CLICKED,
  PROJECT_NAME_UPDATED,
  PROJECT_CLOSE_PROJECT_PANEL,
  PROJECT_VIEW_PROJECT_NOTES,
  PROJECT_VIEW_PROJECT_ACTIVITY,
  PROJECT_VIEW_PROJECT_DETAILS,
  PROJECT_VIEW_ADDRESS_ON_MAP,
  PROJECT_COLOUR_UPDATED,
  PROJECT_ROLE_DUPLICATED,
  PROJECT_COPY_ROLE_FROM_PROJECT,
  PROJECT_ROLE_TEMPLATE_BUTTON_CLICKED,
  PROJECT_ADD_ROLES_TEMPLATE_USED,
  PROJECT_DATES_SHIFTED,
  PROJECT_ACTIVITY_ENTRY_EXPANDED,
  PROJECT_ADDRESS_UPDATED,
  PROJECT_VIEW_PANEL,
  ALLOCATION_START_DATE_UPDATED,
  ALLOCATION_END_DATE_UPDATED,
  PROJECT_NAVIGATE_TO_MAP,
  PROJECT_WIN_PERCENT_UPDATED,
};
