import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  IconButton,
  Tooltip,
} from '@material-ui/core';
import {
  Close,
} from '@material-ui/icons';

const RemoveButton = ({
  className,
  tooltipValue,
  tooltipPlacement,
  onClick,
}) => (
  <Tooltip title={tooltipValue} placement={tooltipPlacement} className={classNames('common-remove-button', className)}>
    <IconButton onClick={onClick}>
      <Close fontSize="inherit" />
    </IconButton>
  </Tooltip>
);

RemoveButton.propTypes = {
  className: PropTypes.string,
  tooltipValue: PropTypes.string,
  tooltipPlacement: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

RemoveButton.defaultProps = {
  className: '',
  tooltipValue: '',
  tooltipPlacement: 'top',
};

export default RemoveButton;
