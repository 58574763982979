import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import googleStoreSmall from '../../images/download-app-buttons/googleplay-download-1X.png';
import googleStoreMed from '../../images/download-app-buttons/googleplay-download-2X.png';
import googleStoreLg from '../../images/download-app-buttons/googleplay-download-3X.png';
import appleStoreSm from '../../images/download-app-buttons/iOS-download-1X.png';
import appleStoreMed from '../../images/download-app-buttons/iOS-download-2X.png';
import appleStoreLg from '../../images/download-app-buttons/iOS-download-3X.png';
import { BRIDGIT_SUPPORT_EMAIL } from '../../common/constants';
import { BRIDGIT_APP_ICON, BRIDGIT_LOGO } from './constants';

export default function UnsupportedMobileBrowser({ os }) {
  useEffect(() => {
    window.mixpanel.track('Unsupported Browser Message Displayed');
  }, []);

  const onClick = OS => () => {
    window.mixpanel.track('Get Mobile App Button Clicked', { OS });
  };

  const googleStoreButton = (
    <a href="https://play.google.com/store/apps/details?id=com.gobridgit.resourceplanning&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" onClick={onClick('Android OS')}>
      <img
        srcSet={`${googleStoreSmall},
                 ${googleStoreMed} 1.5x,
                 ${googleStoreLg} 2x`}
        src={googleStoreMed}
        alt="Get it on Google Play"
      />
    </a>
  );

  const appleStoreButton = (
    <a href="https://apps.apple.com/app/bridgit-bench/id1455901742" onClick={onClick('iOS')}>
      <img
        srcSet={`${appleStoreSm},
                 ${appleStoreMed} 1.5x,
                 ${appleStoreLg} 2x`}
        src={appleStoreMed}
        alt="Download on the App Store"
      />
    </a>
  );

  const createButtons = () => {
    if (os === 'Android OS') return googleStoreButton;
    if (os === 'iOS') return appleStoreButton;
    // If user is on mobile but not using Android OS or iOS - show both available options
    return (
      <>
        {appleStoreButton}
        {googleStoreButton}
      </>
    );
  };

  return (
    <div className="common-unsupported-mobile-browser">
      <img className="logo" src={BRIDGIT_LOGO} alt="Bridgit Logo" />
      <Typography variant="h6" color="primary">
        To use Bridgit Bench on your mobile device, please download the app.
      </Typography>
      <div className="store-button-container">
        <div>
          <img src={BRIDGIT_APP_ICON} alt="Bridgit App" />
          <Typography className="subtitle">Bridgit Bench</Typography>
        </div>
        {createButtons()}
      </div>
      <span>
        Contact Support:
        {' '}
        <a href={`mailto:${BRIDGIT_SUPPORT_EMAIL}`}>
          {BRIDGIT_SUPPORT_EMAIL}
        </a>
      </span>
    </div>
  );
}

UnsupportedMobileBrowser.propTypes = {
  os: PropTypes.string.isRequired,
};
