import { matchPath } from 'react-router-dom';
import { LAST_VISITED_ACCOUNT_ID_KEY } from '../../../common/localStorageKeys';

export const getOrSetAccountId = (accounts, userId) => {
  let accountId = localStorage.getItem(`${LAST_VISITED_ACCOUNT_ID_KEY}-${userId}`);

  if (!accountId) {
    // copy accounts, in order to avoid mutation caused by sorting
    accountId = [...accounts].sort((a, b) => a.name.localeCompare(b.name))[0].id;

    localStorage.setItem(`${LAST_VISITED_ACCOUNT_ID_KEY}-${userId}`, accountId);
  }

  return Number(accountId);
};

export const selectLastVisitedAccountId = (pathname, userId) => {
  let accountId = localStorage.getItem(`${LAST_VISITED_ACCOUNT_ID_KEY}-${userId}`);

  if (accountId) return Number(accountId);

  const mp = matchPath(pathname, {
    path: '/accounts/:id',
  });

  if (mp && mp.params) {
    accountId = Number(mp.params.id);
    localStorage.setItem(`${LAST_VISITED_ACCOUNT_ID_KEY}-${userId}`, accountId);
  }

  return accountId;
};
