import { call, put, takeLatest } from 'redux-saga/effects';
import {
  QUERIES_ADD_SAVED_FILTER_BEGIN,
  QUERIES_ADD_SAVED_FILTER_SUCCESS,
  QUERIES_ADD_SAVED_FILTER_FAILURE,
  QUERIES_ADD_SAVED_FILTER_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';
import { ERRORS_DISMISS_ERROR } from '../../errors/redux/constants';
import { fromApiSavedFilter } from '../utils/queryUtils';

export function addSavedFilter(accountId, data, queryId, analyticsPayload) {
  return {
    type: QUERIES_ADD_SAVED_FILTER_BEGIN,
    accountId,
    data,
    queryId,
    analyticsPayload,
  };
}

export function dismissAddSavedFilterError() {
  return {
    type: QUERIES_ADD_SAVED_FILTER_DISMISS_ERROR,
  };
}

export function* doAddSavedFilter(action) {
  const { accountId, data, queryId, analyticsPayload } = action;
  let res;
  try {
    res = yield call(protectedJsonPost, `accounts/${accountId}/my-filters`, data);
  } catch (error) {
    yield put({ type: QUERIES_ADD_SAVED_FILTER_FAILURE, data: { error } });
    if (error?.data?.status === 409) yield put({ type: ERRORS_DISMISS_ERROR });
    return;
  }

  yield put({
    type: QUERIES_ADD_SAVED_FILTER_SUCCESS,
    data: res,
    queryId,
    analyticsPayload,
  });
}

export function* watchAddSavedFilter() {
  yield takeLatest(QUERIES_ADD_SAVED_FILTER_BEGIN, doAddSavedFilter);
}

export function reducer(state, action) {
  switch (action.type) {
    case QUERIES_ADD_SAVED_FILTER_BEGIN:
      return {
        ...state,
        addSavedFilterPending: true,
        addSavedFilterError: null,
      };

    case QUERIES_ADD_SAVED_FILTER_SUCCESS: {
      const { data, queryId } = action;
      const filter = fromApiSavedFilter(data);
      return {
        ...state,
        [queryId]: { ...state[queryId], filter },
        savedFilters: [
          filter,
          ...state.savedFilters,
        ],
        addSavedFilterPending: false,
        addSavedFilterError: null,
      };
    }

    case QUERIES_ADD_SAVED_FILTER_FAILURE:
      return {
        ...state,
        addSavedFilterPending: false,
        addSavedFilterError: action.data.error,
      };

    case QUERIES_ADD_SAVED_FILTER_DISMISS_ERROR:
      return {
        ...state,
        addSavedFilterError: null,
      };

    default:
      return state;
  }
}
