import React from 'react';
import moment from 'moment';
import { ProjectLink } from '@bridgit/foundation';
import {
  CELL_NUMBER_FIELD,
  DATE_DISPLAY_FORMAT,
  DATE_INPUT_FORMAT,
  FIELD_TYPE_MULTI_SELECT,
  FIELD_TYPE_PHONE_E164,
  HOME_ADDRESS_FIELD,
  NOTES_FIELD,
  PHONE_NUMBER_FIELD,
} from '../../../common/constants';
import { SKILL_SET_FIELD, SYSTEM_FIELD_SETTINGS } from '../constants';
import { getFieldInput } from '../../../utils/miscUtils';
import { formatWorkingDays } from './requestUtils';

export const getHourlyPersonsTableRows = (filteredHourlyPersons, visibleColumns) => filteredHourlyPersons.map(({
  id,
  name,
  skillSet,
  currentAssignments,
  upcomingAssignments,
  fields,
}) => {
  const hourlyPersonObj = {
    'Hourly person ID': id,
    Name: name,
    [SKILL_SET_FIELD]: skillSet,
    'Current assignment end': currentAssignments,
    'Next assignment start': upcomingAssignments,
  };

  if (fields) {
    fields.forEach((field) => {
      if (field.values.length > 1) {
        hourlyPersonObj[field.name] = field.values.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).join(', ');
      } else if (field.type === 'Boolean') {
        hourlyPersonObj[field.name] = field.values?.[0]?.toString().toLowerCase() || 'false';
      } else if (field.values.length === 1) {
        hourlyPersonObj[field.name] = field.values[0].toString();
      }
    });
  }

  const rowData = !visibleColumns ? [] : visibleColumns.map((column) => {
    if (typeof hourlyPersonObj[column.name] === 'undefined') {
      hourlyPersonObj[column.name] = '';
    }
    return hourlyPersonObj[column.name];
  });

  return {
    rowMeta: {
      id,
      name,
      rawData: hourlyPersonObj,
    },
    rowData,
    rowId: id,
  };
});

export const getHourlyPersonFieldsDesc = (fields, skillSets) => {
  // Notes should be last in the form and full width
  const notes = fields.find(field => field.name === NOTES_FIELD);
  const fieldDesc = [
    {
      name: 'Name',
      type: 'Text',
      width: 'full',
      ...SYSTEM_FIELD_SETTINGS,
    },
    {
      name: CELL_NUMBER_FIELD,
      type: FIELD_TYPE_PHONE_E164,
      ...SYSTEM_FIELD_SETTINGS,
    },
    {
      name: SKILL_SET_FIELD,
      type: FIELD_TYPE_MULTI_SELECT,
      ...SYSTEM_FIELD_SETTINGS,
      definedValues: skillSets.map(({ id, name }) => ({ valueId: id, definedValue: name })),
    },
    ...fields.filter(field => field.name !== NOTES_FIELD),
  ];

  if (notes) {
    fieldDesc.push({
      ...notes,
      width: 'full',
    });
  }

  return fieldDesc;
};

export const updateHourlyPersonField = (person, data) => {
  const existingField = person.fields.find(({ id }) => id === data.id);
  let newFieldValues;
  if (existingField) {
    newFieldValues = person.fields.map((field) => {
      if (field.id === data.id) {
        return {
          ...field,
          values: data.values,
          __typename: 'HourlyField',
        };
      }
      return field;
    });
  } else {
    newFieldValues = [...person.fields, data];
  }
  return {
    ...person,
    fields: newFieldValues,
  };
};

export const generateHourlyPersonProfileDetailsDataset = (selectedPersonFields, hourlyPersonFields, person, skillSet) => {
  const preScreenDataName = [NOTES_FIELD];

  const detailFields = hourlyPersonFields.filter(
    ({ type, name }) => !!type && type !== FIELD_TYPE_PHONE_E164 && !preScreenDataName.includes(name),
  );

  const noteData = hourlyPersonFields.find(({ name }) => name === NOTES_FIELD);

  const skillSetField = {
    definedValues: skillSet.map(({ id, name }) => ({ valueId: id, definedValue: name })),
    id: SKILL_SET_FIELD,
    name: SKILL_SET_FIELD,
    type: FIELD_TYPE_MULTI_SELECT,
    value: person.skillSet,
    ...SYSTEM_FIELD_SETTINGS,
  };

  const phoneField = {
    definedValues: person.phoneNumber,
    id: PHONE_NUMBER_FIELD,
    name: CELL_NUMBER_FIELD,
    type: FIELD_TYPE_PHONE_E164,
    value: person.phoneNumber,
    ...SYSTEM_FIELD_SETTINGS,
  };

  const dataset = detailFields.map((defaultField) => {
    const populatedField = selectedPersonFields?.length
      ? selectedPersonFields.find(({ id }) => id === defaultField.id)
      : {};
    let value;

    if (populatedField?.values) {
      if (defaultField.type === FIELD_TYPE_MULTI_SELECT) {
        value = populatedField.values;
      } else {
        [value] = populatedField.values;
      }
    }

    return {
      ...defaultField,
      ...populatedField,
      value,
    };
  });

  // display system fields (except Notes) first
  dataset.sort((a, b) => b.isSystem - a.isSystem);

  if (noteData) {
    const populatedNote = selectedPersonFields?.length
      ? selectedPersonFields.find(({ name }) => name === noteData.name)
      : {};
    dataset.push({
      ...noteData,
      ...populatedNote,
      value: populatedNote?.values?.[0] ?? undefined,
    });
  }

  return [skillSetField, phoneField, ...dataset];
};

export const generateHourlyPersonProfileDetailsInputs = (selectedHourlyPersonFields, hourlyPersonFields, selectedHourlyPerson, skillSet) => {
  const dataset = generateHourlyPersonProfileDetailsDataset(selectedHourlyPersonFields, hourlyPersonFields, selectedHourlyPerson, skillSet);

  const inputs = [];
  const exceptedFields = [
    NOTES_FIELD,
    HOME_ADDRESS_FIELD,
    SKILL_SET_FIELD,
    CELL_NUMBER_FIELD,
  ];

  if (!dataset || dataset.length === 0) return [];
  const fields = Array.isArray(dataset) ? dataset : [dataset];

  fields.forEach((field) => {
    if (exceptedFields.includes(field.name) || !field.isSystem) {
      inputs.push(getFieldInput(field));
    }
  });

  return { dataset, inputs };
};

export const getAssignmentsRows = assignments => assignments.map(({ project, description, startDate, endDate }) => {
  const { name: projectName, id: projectId, colour } = project;

  return ([
    { fieldName: 'id', value: <ProjectLink projectName={projectName} projectId={projectId} backgroundColor={colour} />, type: 'string', id: projectName },
    { fieldName: 'description', value: description, type: 'string' },
    {
      fieldName: 'assignmentDates',
      value:
        `${moment(startDate).format(DATE_DISPLAY_FORMAT)} - ${moment(endDate).format(DATE_DISPLAY_FORMAT)}`,
      type: 'string',
    },
  ]);
});

export const generateHourlyPersonAssignmentsData = (currentAssignments, upcomingAssignments, pastAssignments) => [
  {
    rows: getAssignmentsRows(currentAssignments),
    assignmentType: 'current',
  },
  {
    rows: getAssignmentsRows(upcomingAssignments),
    assignmentType: 'upcoming',
  },
  {
    rows: getAssignmentsRows(pastAssignments),
    assignmentType: 'completed',
  },
];

export const generateProjectRequestsData = (requests = []) => requests.map(({
  id,
  startDate,
  endDate,
  description,
  assignee,
  workDays,
  requester,
}) => {
  const dateRange = `${moment(startDate, DATE_INPUT_FORMAT).format(DATE_DISPLAY_FORMAT)} - ${moment(endDate, DATE_INPUT_FORMAT).format(DATE_DISPLAY_FORMAT)}`;
  const workingDays = formatWorkingDays(workDays);
  const assigneeName = assignee?.name || 'Unassigned';
  const requesterName = requester?.name || '';

  return ([
    { fieldName: 'description', value: description, type: 'string', id },
    { fieldName: 'assigneeName', value: assigneeName, type: 'string' },
    { fieldName: 'dateRange', value: dateRange, classes: { root: 'date-cell' }, type: 'string' },
    { fieldName: 'workingDays', value: workingDays, type: 'string' },
    { fieldName: 'requesterName', value: requesterName, type: 'string' },
  ]);
});
