import React, { useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Field } from 'react-final-form';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';

import { updateUserInfo, userLogout } from './redux/actions';
import { OTHER } from './constants';
import { isRequired } from '../../utils/validators';
import { getChangedObjectProperties, isEmptyObject } from '../../utils/miscUtils';
import { capitalize } from '../../utils/formatters';
import { FormField } from '../wrapped-components';
import { ProfileTeamInput } from '.';

export const ProfileInfo = ({ history }) => {
  const dispatch = useDispatch();

  const { accountId } = useSelector(({ common }) => common);
  const { userInfo, updateUserInfoPending, defaultTeams } = useSelector(({ login }) => login);

  const initialTeamSelect = useMemo(() => {
    if (userInfo?.team) {
      return defaultTeams.includes(userInfo.team) ? userInfo.team : OTHER;
    }
    return null;
  }, [defaultTeams, userInfo]);

  useEffect(() => {
    if (!updateUserInfoPending && (userInfo?.name && userInfo?.title && userInfo?.team)) {
      history.replace('/');
    }
  }, [history, updateUserInfoPending, userInfo?.name, userInfo?.team, userInfo?.title]);

  const submitHandler = useCallback(({ name, title, team, teamName }) => {
    const accountIdToUpdate = '*' in userInfo.permissions ? '*' : accountId;
    const data = {
      name,
      title,
      team: team === OTHER ? teamName : team,
    };

    // Mixpanel - Tracks when user updates field or name on welcome page
    const analyticsPayload = {
      ...data,
      fieldsUpdated: getChangedObjectProperties(userInfo, data).join(', '),
      updatedFrom: 'Welcome page',
    };

    dispatch(updateUserInfo(userInfo.sub, accountIdToUpdate, data, analyticsPayload));
  }, [accountId, dispatch, userInfo]);

  const logout = useCallback(() => {
    dispatch(userLogout());
    history.replace('/login');
  }, [dispatch, history]);

  const renderInput = useCallback(({ input, meta, autoFocus }) => (
    <FormField
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...input}
      type="text"
      width="full"
      label={capitalize(input.name)}
      error={meta.error}
      alwaysShowErrors
      placeholder={`Enter ${input.name}`}
      maxLength={50}
      autoFocus={autoFocus}
    />
  ), []);

  const renderForm = useCallback(() => (
    <Paper component="section" variant="outlined" className="spacious-container">
      <Typography
        className="headline"
        variant="subtitle1"
        component="h4"
      >
        Tell us about yourself
      </Typography>

      <Typography variant="body1" gutterBottom className="login-profile-info-subtitle">
        To improve your experience, we need some details about you. You can always update this from your profile later.
      </Typography>

      <Form
        onSubmit={submitHandler}
        initialValues={{
          name: userInfo?.name || '',
          title: userInfo?.title || '',
          team: initialTeamSelect,
          teamName: initialTeamSelect === OTHER ? userInfo.team : null,
        }}
        render={({ handleSubmit, values, errors }) => (
          <>
            <Field
              name="name"
              render={renderInput}
              validate={isRequired}
              autoFocus
            />

            <Field
              name="title"
              render={renderInput}
              validate={isRequired}
            />

            <ProfileTeamInput values={values} />

            <section className="button-actions">
              {updateUserInfoPending
                ? <CircularProgress size={25} color="primary" />
                : (
                  <>
                    <Button
                      disabled={updateUserInfoPending}
                      color="primary"
                      disableRipple
                      size="medium"
                      onClick={logout}
                    >
                      Log out
                    </Button>
                    <Button
                      disabled={!isEmptyObject(errors)}
                      color="primary"
                      disableRipple
                      size="medium"
                      variant="contained"
                      onClick={handleSubmit}
                    >
                      Continue
                    </Button>
                  </>
                )}
            </section>
          </>
        )}
      />
    </Paper>
  ), [logout, submitHandler, renderInput, updateUserInfoPending, userInfo, initialTeamSelect]);

  return (
    <Grid container justify="center" item xs={12} className="login-profile-info">
      <div className="login-profile-info-box">
        {renderForm()}
      </div>
    </Grid>
  );
};

ProfileInfo.propTypes = {
  history: PropTypes.object.isRequired,
};

export default ProfileInfo;
