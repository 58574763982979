import React, { useState, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { NotifyModal } from '@bridgit/foundation';
import { getHourlyPersonFields } from 'src/features/account-settings/redux/actions';
import { Confirm } from 'src/features/common';
import { Can } from 'src/features/wrapped-components';
import { PERM_WRITE, PERM_ASSIGNMENT } from 'src/features/permissions/utils/constants';
import { HourlyPersonCard } from '.';
import { ProjectNotifyHeader } from '../projects';
import { sendNotification, removeAssignee } from './redux/actions';
import {
  getHourlyPersonMainDesc,
  getHourlyPersonOtherDesc,
  getStandardRemoveNotifyText,
  getRequestRecipients,
  getStandardNotifyText,
} from './utils/requestDetailsUtils';
import { renderCommunicatedStatus } from './utils/requestUtils';
import {
  ASSIGNED_NOTIFIED,
  REMOVE_ASSIGNEE,
  NOTIFY_EXISTING,
} from './constants';

export function AssigneeDetails({
  accountId,
  selectedRequest,
  hourlyPersonFields,
  assignRequestPending,
  removeAssigneePending,
  getHourlyPersonFields,
  sendNotification,
  removeAssignee,
}) {
  const [showConfirm, setShowConfirm] = useState(false);
  const [showNotifyModal, setShowNotifyModal] = useState(false);
  const [notifyState, setNotifyState] = useState(null);

  useEffect(() => {
    getHourlyPersonFields(accountId);
  }, [accountId, getHourlyPersonFields]);

  const mixpanelPayload = useMemo(() => {
    const { assignee, project, state, requester } = selectedRequest;
    return {
      projectName: project?.name,
      personName: assignee?.name,
      requestState: state,
      requester,
    };
  }, [selectedRequest]);

  const fields = useMemo(() => getHourlyPersonMainDesc(selectedRequest?.assignee), [selectedRequest]);
  const expandedFields = useMemo(() => getHourlyPersonOtherDesc(selectedRequest?.assignee, hourlyPersonFields),
    [hourlyPersonFields, selectedRequest?.assignee]);

  const standardText = useMemo(() => getStandardNotifyText(selectedRequest.assignee, selectedRequest), [selectedRequest]);
  const removeNotifyText = useMemo(() => getStandardRemoveNotifyText(selectedRequest), [selectedRequest]);
  const recipients = useMemo(() => getRequestRecipients(selectedRequest.assignee, selectedRequest), [selectedRequest]);

  const notifyHeader = useMemo(() => {
    const { description, project } = selectedRequest;
    return (
      <ProjectNotifyHeader
        label="Description"
        value={description}
        project={project}
      />
    );
  }, [selectedRequest]);

  const acceptConfirmRemove = useCallback(() => {
    const { state } = selectedRequest;
    if (state === ASSIGNED_NOTIFIED) {
      setNotifyState(REMOVE_ASSIGNEE);
      setShowNotifyModal(true);
    } else {
      removeAssignee(accountId, selectedRequest, null, mixpanelPayload);
    }
    setShowConfirm(false);
  }, [accountId, mixpanelPayload, removeAssignee, selectedRequest]);

  const showExistingNotify = useCallback(() => {
    setNotifyState(NOTIFY_EXISTING);
    setShowNotifyModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setNotifyState(null);
    setShowNotifyModal(false);
  }, []);

  const showRemoveConfirm = useCallback(() => setShowConfirm(true), []);
  const closeConfirm = useCallback(() => setShowConfirm(false), []);

  const onSendNotify = useCallback((message = null, defaultMessage = null) => {
    const { assignee } = selectedRequest;
    const data = {
      assigneeId: assignee?.id || null,
      message,
    };
    sendNotification(accountId, selectedRequest, data, { ...mixpanelPayload, defaultMessage });
    setNotifyState(null);
    setShowNotifyModal(false);
  }, [accountId, mixpanelPayload, selectedRequest, sendNotification]);

  const onRemoveAndNotify = useCallback((message = null, defaultMessage = null) => {
    removeAssignee(accountId, selectedRequest, message, { ...mixpanelPayload, defaultMessage });
    setNotifyState(null);
    setShowNotifyModal(false);
  }, [accountId, mixpanelPayload, removeAssignee, selectedRequest]);

  const onRemoveAssignee = useCallback(() => {
    removeAssignee(accountId, selectedRequest, null, { ...mixpanelPayload, defaultMessage: null });
    setNotifyState(null);
    setShowNotifyModal(false);
  }, [accountId, mixpanelPayload, removeAssignee, selectedRequest]);

  const removeButton = useMemo(() => (
    <Button
      onClick={showRemoveConfirm}
      disableRipple
      variant="outlined"
      color="primary"
    >
      Remove Assignee
    </Button>
  ), [showRemoveConfirm]);

  const notifyButton = useMemo(() => (
    <Button
      onClick={showExistingNotify}
      disableRipple
      variant="outlined"
      color="primary"
    >
      Notify
    </Button>
  ), [showExistingNotify]);

  if (!selectedRequest?.assignee) return null;

  const {
    assignee,
    lastNotifiedOn,
  } = selectedRequest;

  return (
    <div className="self-perform-assignee-details">
      <div className="section assignee-details">
        <div className="assignee-details-header">Assignee</div>
        <HourlyPersonCard
          hourlyPerson={assignee}
          fields={fields}
          expandedFields={expandedFields}
        />
        <Can
          action={PERM_WRITE}
          subject={PERM_ASSIGNMENT}
          yes={removeButton}
        />
      </div>
      <div className="section">
        <div className="assignee-details-header">Communication status</div>
        <div id="assignee-communication-status" className="assignee-communication-status">{renderCommunicatedStatus(lastNotifiedOn)}</div>
        <Can
          action={PERM_WRITE}
          subject={PERM_ASSIGNMENT}
          yes={notifyButton}
        />
      </div>
      {notifyState === NOTIFY_EXISTING
        ? (
          <NotifyModal
            headline="Notify via text"
            open={showNotifyModal}
            pending={assignRequestPending}
            primaryAction={onSendNotify}
            secondaryAction={closeModal}
            standardText={standardText}
            recipients={recipients}
            notifyHeader={notifyHeader}
          />
        ) : (
          <NotifyModal
            open={showNotifyModal}
            pending={removeAssigneePending}
            primaryAction={onRemoveAndNotify}
            secondaryAction={onRemoveAssignee}
            secondaryText="Don't Send"
            headline="Assignment removed - update via text"
            standardText={removeNotifyText}
            recipients={recipients}
            notifyHeader={notifyHeader}
          />
        )}
      {showConfirm && (
        <Confirm
          headline="Remove Assignee"
          cancelButtonText="Cancel"
          acceptButtonText="Remove assignment"
          onCancel={closeConfirm}
          onAccept={acceptConfirmRemove}
          loading={false}
        >
          <div>
            <p>Are you sure you want to remove this assignment?</p>
            <p>You will be able to reassign this request in the &#8216;Requests&#8217; area.</p>
          </div>
        </Confirm>
      )}
    </div>
  );
}

AssigneeDetails.propTypes = {
  accountId: PropTypes.number.isRequired,
  selectedRequest: PropTypes.object,
  hourlyPersonFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  assignRequestPending: PropTypes.bool,
  removeAssigneePending: PropTypes.bool,
  getHourlyPersonFields: PropTypes.func.isRequired,
  sendNotification: PropTypes.func.isRequired,
  removeAssignee: PropTypes.func.isRequired,
};

AssigneeDetails.defaultProps = {
  selectedRequest: null,
  assignRequestPending: false,
  removeAssigneePending: false,
};

/* istanbul ignore next */
function mapStateToProps({ common, selfPerform, accountSettings }) {
  const { accountId } = common;
  const {
    selectedRequest,
    assignRequestPending,
    removeAssigneePending,
  } = selfPerform;
  const { hourlyPersonFields } = accountSettings;
  return {
    accountId,
    selectedRequest,
    hourlyPersonFields,
    assignRequestPending,
    removeAssigneePending,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    getHourlyPersonFields: bindActionCreators(getHourlyPersonFields, dispatch),
    sendNotification: bindActionCreators(sendNotification, dispatch),
    removeAssignee: bindActionCreators(removeAssignee, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssigneeDetails);
