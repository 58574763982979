import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PERMISSIONS_CREATE_PERMISSION_GROUP_BEGIN,
  PERMISSIONS_CREATE_PERMISSION_GROUP_SUCCESS,
  PERMISSIONS_CREATE_PERMISSION_GROUP_FAILURE,
  PERMISSIONS_CREATE_PERMISSION_GROUP_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';

export function createPermissionGroup(accountId, data) {
  return {
    type: PERMISSIONS_CREATE_PERMISSION_GROUP_BEGIN,
    accountId,
    data,
  };
}

export function dismissCreatePermissionGroupError() {
  return {
    type: PERMISSIONS_CREATE_PERMISSION_GROUP_DISMISS_ERROR,
  };
}

export function* doCreatePermissionGroup(action) {
  const { accountId, data } = action;
  let res;
  try {
    res = yield call(protectedJsonPost, `accounts/${accountId}/groups`, data);
  } catch (err) {
    yield put({
      type: PERMISSIONS_CREATE_PERMISSION_GROUP_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PERMISSIONS_CREATE_PERMISSION_GROUP_SUCCESS,
    data: res,
    analyticsPayload: data,
  });
}

export function* watchCreatePermissionGroup() {
  yield takeLatest(PERMISSIONS_CREATE_PERMISSION_GROUP_BEGIN, doCreatePermissionGroup);
}

export function reducer(state, action) {
  switch (action.type) {
    case PERMISSIONS_CREATE_PERMISSION_GROUP_BEGIN:
      return {
        ...state,
        createPermissionGroupPending: true,
        createPermissionGroupError: null,
      };

    case PERMISSIONS_CREATE_PERMISSION_GROUP_SUCCESS:
      return {
        ...state,
        permissionGroups: [
          ...state.permissionGroups,
          action.data,
        ],
        createPermissionGroupPending: false,
        createPermissionGroupError: null,
      };

    case PERMISSIONS_CREATE_PERMISSION_GROUP_FAILURE:
      return {
        ...state,
        createPermissionGroupPending: false,
        createPermissionGroupError: action.data.error,
      };

    case PERMISSIONS_CREATE_PERMISSION_GROUP_DISMISS_ERROR:
      return {
        ...state,
        createPermissionGroupError: null,
      };

    default:
      return state;
  }
}
