import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Select } from '@material-ui/core';
import { AFTER_FILTER, BLANK_FILTER, BETWEEN_FILTER } from 'src/filters/constants';
import { isStringOrMoment } from 'src/utils/dateUtils';
import { DatePicker } from '..';
import { MAX_CONFIGURABLE_DATE, MIN_CONFIGURABLE_DATE } from '../../../common/constants';
import { shouldDisableDate, shouldDisableEndDate } from '../dateFilterUtils';

const DateFilter = ({
  className,
  includeBlank,
  initialVerb,
  initialDate,
  initialEndDate,
  onChange,
  onOpenDatePicker,
  onCloseDatePicker,
  onError,
}) => {
  const [verb, setVerb] = useState(initialVerb);
  const [date, setDate] = useState(initialDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  const onVerbChange = useCallback((event) => {
    const newVerb = event.target.value;
    setVerb(newVerb);
    onChange(newVerb, date, endDate);
  }, [date, endDate, onChange]);

  const onDateChange = useCallback((newDate) => {
    setDate(newDate);
    onChange(verb, newDate, endDate);
  }, [endDate, onChange, verb]);

  const onEndDateChange = useCallback((newEndDate) => {
    setEndDate(newEndDate);
    onChange(verb, date, newEndDate);
  }, [date, onChange, verb]);

  const renderSelect = () => (
    <div className="select-wrap">
      <Select
        MenuProps={{ className: 'date-filter-popper-select' }}
        value={verb}
        onChange={onVerbChange}
        fullWidth
        native
      >
        <option className="verb-select" value="after">After</option>
        <option className="verb-select" value="before">Before</option>
        <option className="verb-select" value="between">Between</option>
        <option className="verb-select" value="on">On</option>
        {includeBlank && <option className="verb-select" value={BLANK_FILTER}>{BLANK_FILTER}</option>}
      </Select>
    </div>
  );

  const renderDatePickers = () => {
    const isBetween = verb === BETWEEN_FILTER;

    const maxDate = isBetween && isStringOrMoment(endDate) ? endDate : MAX_CONFIGURABLE_DATE;
    const minDate = isStringOrMoment(date) ? date : MIN_CONFIGURABLE_DATE;

    return (
      <>
        {verb !== BLANK_FILTER && (
          <div className="date-wrap">
            <DatePicker
              maxDate={maxDate}
              maxDateMessage="Start date must be earlier than the end date"
              shouldDisableDate={shouldDisableDate(maxDate, verb)}
              date={date}
              onOpen={onOpenDatePicker}
              onClose={onCloseDatePicker}
              onChange={onDateChange}
              onError={onError}
            />
          </div>
        )}
        {isBetween && (
          <>
            <div className="and">and</div>
            <div className="date-wrap">
              <DatePicker
                minDate={minDate}
                minDateMessage="End date must be later than the start date"
                shouldDisableDate={shouldDisableEndDate(minDate)}
                date={endDate}
                onOpen={onOpenDatePicker}
                onClose={onCloseDatePicker}
                onChange={onEndDateChange}
                onError={onError}
              />
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <div className={classNames('common-date-filter', className)}>
      {renderSelect()}
      {renderDatePickers()}
    </div>
  );
};

DateFilter.propTypes = {
  className: PropTypes.string,
  includeBlank: PropTypes.bool,
  initialVerb: PropTypes.string,
  initialDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
  initialEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
  onChange: PropTypes.func.isRequired,
  onOpenDatePicker: PropTypes.func,
  onCloseDatePicker: PropTypes.func,
  onError: PropTypes.func,
};

DateFilter.defaultProps = {
  className: '',
  initialVerb: AFTER_FILTER,
  initialDate: null,
  initialEndDate: null,
  includeBlank: true,
  onOpenDatePicker: () => {},
  onCloseDatePicker: () => {},
  onError: () => {},
};

export default DateFilter;
