import { PERM_WRITE, PERM_REQUEST } from 'src/features/permissions/utils/constants';
import {
  DARK_GREY,
  LIGHT_GREY,
  RED,
  DARK_RED,
} from '@bridgit/foundation';
import { defaultFiltersWithModule } from 'src/utils/tableUtils';
import { PURSUIT_MODULE_STATUS_TO_REMOVE } from 'src/features/projects/constants';
import { DEFAULT_PROJECT_SORT } from 'src/features/projects/redux/constants';
import { defaultFilters } from '../filters/common/constants';

export const ASSIGNMENT_TYPES = {
  CURRENT: 'CURRENT',
  NEXT: 'NEXT',
};

export const ASSIGNMENT_TYPE_TO_DATE_TYPE_MAP = {
  [ASSIGNMENT_TYPES.CURRENT]: 'endDate',
  [ASSIGNMENT_TYPES.NEXT]: 'startDate',
};
export const SKILL_SET_FIELD = 'Skill set';

export const SYSTEM_FIELD_SETTINGS = { isRequired: true, isSystem: true, isPrivate: false };

export const ASSIGNMENTS_COLUMNS = [
  { name: 'Project name' },
  { name: 'Description' },
  { name: 'Assignment dates' },
];

export const REQUESTS_COLUMNS = [
  { name: 'Description' },
  { name: 'Assignee' },
  { name: 'Dates' },
  { name: 'Working days' },
  { name: 'Requested by' },
];

export const REQUEST_REJECTED = 'Rejected';
export const REQUEST_REJECT = 'Reject';
export const REQUEST_OPEN = 'Open';
export const REQUEST_REOPEN = 'Re-open';

export const ASSIGNED = 'Assigned';
export const ASSIGNED_NOTIFIED = 'AssignedNotified';

export const REMOVE_ASSIGNEE = 'REMOVE_ASSIGNEE';
export const NOTIFY_EXISTING = 'NOTIFY_EXISTING';

export const BEST_MATCH_TOP_TYPE = 'top';
export const BEST_MATCH_OTHER_TYPE = 'other';

export const REQUEST_STATUS_OPTIONS = [
  {
    id: REQUEST_OPEN,
    label: REQUEST_OPEN,
    menuLabel: REQUEST_REOPEN,
    background: {
      focused: { backgroundColor: DARK_GREY },
      unfocused: { backgroundColor: LIGHT_GREY },
    },
    text: { color: RED },
  },
  {
    id: REQUEST_REJECTED,
    label: REQUEST_REJECTED,
    menuLabel: REQUEST_REJECT,
    background: {
      focused: { backgroundColor: DARK_RED },
      unfocused: { backgroundColor: RED },
    },
  },
];

export const REQUEST_WRITE_PERMS = { action: PERM_WRITE, subject: PERM_REQUEST };

export const ADD_REQUEST_MODAL = 'ADD_REQUEST_MODAL';

export const DEFAULT_WORK_DAYS_OPTIONS = [
  { label: 'Su', checked: false, value: 0, name: 'workDays' },
  { label: 'M', checked: true, value: 1, name: 'workDays' },
  { label: 'T', checked: true, value: 2, name: 'workDays' },
  { label: 'W', checked: true, value: 3, name: 'workDays' },
  { label: 'Th', checked: true, value: 4, name: 'workDays' },
  { label: 'F', checked: true, value: 5, name: 'workDays' },
  { label: 'S', checked: false, value: 6, name: 'workDays' },
];

export const PROJECT_LIST_DEFAULT_QUERY = {
  filter: {
    name: 'filter',
    args: defaultFiltersWithModule(defaultFilters.projects, PURSUIT_MODULE_STATUS_TO_REMOVE, false),
  },
  sort: DEFAULT_PROJECT_SORT,
};

export const PROJECT_LIST_DEFAULT_QUERY_PURSUITS_ON = {
  filter: {
    name: 'filter',
    args: defaultFiltersWithModule(defaultFilters.projects, PURSUIT_MODULE_STATUS_TO_REMOVE, true),
  },
  sort: DEFAULT_PROJECT_SORT,
};

export const ACTIVE = 'Active';
export const UPCOMING = 'Upcoming';
export const COMPLETE = 'Complete';
