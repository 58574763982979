import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  MultiFilterPopper,
} from '../common';
import {
  SELECT_LIST,
  CERTIFICATIONS_CONTROL_OPTIONS,
} from '../../filters/constants';
import { getAccountCertifications } from '../account-settings/redux/actions';

export const CertificationFilter = ({
  popperProps,
  origSelected,
}) => {
  const dispatch = useDispatch();

  const { accountId } = useSelector(({ common }) => common);
  const {
    certifications,
    getAccountCertificationsPending,
  } = useSelector(({ accountSettings }) => accountSettings);

  const items = useMemo(() => certifications.map(({ name, id: value }) => ({
    name,
    value,
  })), [certifications]);

  useEffect(() => {
    if (accountId) {
      dispatch(getAccountCertifications(accountId));
    }
  }, [dispatch, accountId]);

  if (getAccountCertificationsPending) return null;

  return (
    <MultiFilterPopper
      {...popperProps} // eslint-disable-line react/jsx-props-no-spreading
      items={items}
      origSelected={origSelected}
      filterKey="value"
      type={SELECT_LIST}
      controlOptions={CERTIFICATIONS_CONTROL_OPTIONS}
    />
  );
};

CertificationFilter.propTypes = {
  popperProps: PropTypes.object,
  origSelected: PropTypes.array.isRequired,
};

CertificationFilter.defaultProps = {
  popperProps: {},
};

export default CertificationFilter;
