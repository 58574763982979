import { getFieldInput } from '../../../utils/miscUtils';
import { CELL_NUMBER_FIELD, MIN_CONFIGURABLE_DATE_MESSAGE } from '../../../common/constants';
import { PEOPLE_DISPLAY_COST_RATE, COST_RATE_FIELD_TOOLTIP, PEOPLE_DISPLAY_EMPLOYEE_ID } from '../constants';

const generateInputs = (personFields) => {
  if (personFields.length === 0) {
    return {
      requiredInputs: [],
      optionalInputs: [],
    };
  }

  const requiredInputs = [
    {
      type: 'text',
      placeholder: 'Enter name',
      name: 'name',
      required: true,
      width: 'full',
      primary: true,
    },
    {
      type: 'text',
      label: 'Email',
      placeholder: 'Enter email address',
      name: 'email',
      required: true,
      maxLength: 254,
    },
  ];

  const optionalInputs = [
    {
      type: 'image',
      width: 'full',
      name: 'avatar',
    },
    {
      type: 'date',
      label: 'Hire Date',
      name: 'startDate',
      minDateMessage: MIN_CONFIGURABLE_DATE_MESSAGE,
    },
    {
      type: 'date',
      label: 'Termination Date',
      name: 'endDate',
      minDateMessage: MIN_CONFIGURABLE_DATE_MESSAGE,
    },
  ];

  const customFields = [];
  let cellNumberField;

  // Split person fields into system fields and custom fields
  const systemFields = personFields.reduce((systemFieldsArray, field) => {
    if (field.name === CELL_NUMBER_FIELD) { // Handle 'Cell number' separately (should appear as the final system field)
      cellNumberField = field;
    } else if (field.isSystem) {
      systemFieldsArray.push(field);
    } else {
      customFields.push(field);
    }
    return systemFieldsArray;
  }, []);
  // Move 'Cell number' to the end of the list
  if (cellNumberField) {
    systemFields.push(cellNumberField);
  }

  // Order system fields before custom fields
  [...systemFields, ...customFields].forEach((field) => {
    let inputField = { ...field };
    if (inputField.isRequired) {
      requiredInputs.push(getFieldInput(inputField));
    } else {
      if (field.name === PEOPLE_DISPLAY_COST_RATE) {
        inputField = {
          ...field,
          tooltipText: COST_RATE_FIELD_TOOLTIP,
        };
      }
      if (field.name === PEOPLE_DISPLAY_EMPLOYEE_ID) {
        inputField = {
          ...field,
          placeholder: 'Enter employee ID',
        };
      }
      optionalInputs.push(getFieldInput(inputField));
    }
  });

  // Move Other to the end of the list
  optionalInputs.push(...optionalInputs.splice(optionalInputs.findIndex(({ label }) => label === 'Other'), 1));

  return {
    requiredInputs,
    optionalInputs,
  };
};

export {
  generateInputs,
};
