import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
  ClickAwayListener,
} from '@material-ui/core';
import { Button } from '@bridgit/foundation';
import { collectPeopleData, collectProjectData } from './redux/actions';
import {
  PEOPLE_LIST_CSV_REPORT,
  PEOPLE_LIST_PDF_REPORT,
  PROJECT_LIST_CSV_REPORT,
  PROJECT_LIST_PDF_REPORT,
} from './redux/constants';
import { PROJECT_VIEW } from '../../common/constants';
import { CERTIFICATIONS } from '../../filters/constants';

const ListFileExportMenu = ({
  handleClose,
  menuTitle,
  queryId,
}) => {
  const dispatch = useDispatch();

  const { accountId, activeView } = useSelector(({ common }) => common);
  const queries = useSelector(({ queries }) => queries || {});
  const { permissions } = useSelector(({ login: { userInfo } }) => userInfo);
  const { visibleColumns } = useSelector(({ table }) => table);

  const isProjectView = activeView === PROJECT_VIEW;
  const pdfReport = isProjectView ? PROJECT_LIST_PDF_REPORT : PEOPLE_LIST_PDF_REPORT;
  const csvReport = isProjectView ? PROJECT_LIST_CSV_REPORT : PEOPLE_LIST_CSV_REPORT;

  const [type, setType] = useState(pdfReport);
  const [includeAllocations, setIncludeAllocations] = useState(false);

  const handleChange = (event) => {
    setType(event.target.value);
  };

  const handleAllocationChange = (event) => {
    setIncludeAllocations(event.target.checked);
  };

  const exportReport = () => {
    const queryObj = queries[queryId];
    if (isProjectView) {
      const settings = { includeAllocations, type, permissions };
      settings.preloadPhotos = includeAllocations && type === pdfReport;
      dispatch(collectProjectData(accountId, queryObj, settings));
    } else {
      const includeCertifications = visibleColumns.some(column => column.schemaName === CERTIFICATIONS);
      dispatch(collectPeopleData(accountId, queryObj, { includeAllocations, type, includeCertifications }));
    }

    handleClose();
  };

  return (
    <div className="reports-list-file-export-menu">
      <ClickAwayListener onClickAway={handleClose}>
        <div className="content-wrap">
          <div role="presentation" onClick={handleClose} className="click-listener" />
          <div className="title">{menuTitle}</div>
          <FormControl component="fieldset" className="form">
            <RadioGroup aria-label="People report" name="report" value={type} onChange={handleChange}>
              <FormControlLabel
                value={csvReport}
                control={<Radio color="primary" />}
                label="CSV"
                labelPlacement="end"
              />
              <FormControlLabel
                value={pdfReport}
                control={<Radio color="primary" />}
                label="PDF"
                labelPlacement="end"
              />
            </RadioGroup>
            <FormControlLabel
              className="allocation-wrap"
              control={(
                <Switch
                  checked={includeAllocations}
                  onChange={handleAllocationChange}
                  value="includeAllocations"
                  color="primary"
                  disabled={type === csvReport && !isProjectView}
                />
              )}
              label="Include allocations"
            />
            <Button
              color="primary"
              variant="plain"
              onClick={exportReport}
            >
              Export and download
            </Button>
          </FormControl>
        </div>
      </ClickAwayListener>
    </div>
  );
};

ListFileExportMenu.propTypes = {
  handleClose: PropTypes.func.isRequired,
  menuTitle: PropTypes.string.isRequired,
  queryId: PropTypes.string.isRequired,
};

export default ListFileExportMenu;
