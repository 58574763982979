import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { DATE_DISPLAY_FORMAT } from 'src/common/constants';
import { ROLE_FIELDS } from 'src/common/descriptors/role';
import { trackAnalytics } from '../common/redux/trackAnalytics';
import { clearReportData, setReport } from './redux/actions';
import { generateRoleSegmentCSV } from './utils/reportUtils';
import { getAccountById } from '../../utils/miscUtils';

const RoleSegmentCSVReport = () => {
  const dispatch = useDispatch();

  const { accountId } = useSelector(({ common }) => common);
  const { entities: accounts } = useSelector(({ accounts }) => accounts);
  const { roleSegments } = useSelector(({ reports }) => reports);


  const csvInstance = useRef();
  const hasRoleSegments = roleSegments?.length > 0;

  useEffect(() => {
    if (hasRoleSegments && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        dispatch(clearReportData());
        dispatch(trackAnalytics('Export Unfilled Roles List', { 'Report type': 'CSV' }));
        dispatch(setReport(false));
      });
    }
  }, [hasRoleSegments, dispatch]);

  if (hasRoleSegments) {
    const headers = ROLE_FIELDS.map(column => column.name);
    const today = moment().format(DATE_DISPLAY_FORMAT);
    const currentAccount = getAccountById(accounts, accountId) || { name: '' };

    return (
      <CSVLink
        headers={headers}
        data={generateRoleSegmentCSV(ROLE_FIELDS, roleSegments)}
        filename={`${currentAccount.name} - Unfilled Roles List - ${today}.csv`}
        ref={csvInstance}
      />
    );
  }

  return null;
};

export default RoleSegmentCSVReport;
