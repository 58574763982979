import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNT_SETTINGS_ADD_PHASE_BEGIN,
  ACCOUNT_SETTINGS_ADD_PHASE_SUCCESS,
  ACCOUNT_SETTINGS_ADD_PHASE_FAILURE,
  ACCOUNT_SETTINGS_ADD_PHASE_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';

export function addPhase(accountId, data) {
  return {
    type: ACCOUNT_SETTINGS_ADD_PHASE_BEGIN,
    accountId,
    data,
  };
}

export function dismissAddPhaseError() {
  return {
    type: ACCOUNT_SETTINGS_ADD_PHASE_DISMISS_ERROR,
  };
}

export function* doAddPhase(action) {
  const { accountId, data } = action;
  let res;
  try {
    res = yield call(protectedJsonPost, `accounts/${accountId}/phases`, data);
  } catch (err) {
    yield put({
      type: ACCOUNT_SETTINGS_ADD_PHASE_FAILURE,
      data: { error: err },
    });
    return;
  }
  yield put({
    type: ACCOUNT_SETTINGS_ADD_PHASE_SUCCESS,
    data: res,
  });
}

export function* watchAddPhase() {
  yield takeLatest(ACCOUNT_SETTINGS_ADD_PHASE_BEGIN, doAddPhase);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNT_SETTINGS_ADD_PHASE_BEGIN:
      return {
        ...state,
        addPhasePending: true,
        addPhaseError: null,
      };

    case ACCOUNT_SETTINGS_ADD_PHASE_SUCCESS:
      return {
        ...state,
        phases: [
          ...state.phases,
          {
            ...action.data,
            subPhases: [],
          },
        ],
        addPhasePending: false,
        addPhaseError: null,
      };

    case ACCOUNT_SETTINGS_ADD_PHASE_FAILURE:
      return {
        ...state,
        addPhasePending: false,
        addPhaseError: action.data.error,
      };

    case ACCOUNT_SETTINGS_ADD_PHASE_DISMISS_ERROR:
      return {
        ...state,
        addPhaseError: null,
      };

    default:
      return state;
  }
}
