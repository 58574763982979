import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { PERM_WRITE, PERM_ALLOCATION } from 'src/features/permissions/utils/constants';
import { DATE_INPUT_FORMAT, DATE_DISPLAY_FORMAT } from '../../common/constants';
import { Can } from '../wrapped-components';

export default class AllocationSingleEmpty extends PureComponent {
  static propTypes = {
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    onRowClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    startDate: '',
    endDate: '',
  }

  handleRowClick = () => {
    const { onRowClick, startDate, endDate } = this.props;
    if (startDate && endDate) {
      onRowClick(startDate, endDate);
    } else {
      onRowClick();
    }
  }

  render() {
    const { startDate, endDate } = this.props;

    const startDateFormatted = startDate.length ? moment(startDate, DATE_INPUT_FORMAT).format(DATE_DISPLAY_FORMAT) : '';
    const endDateFormatted = endDate.length ? moment(endDate, DATE_INPUT_FORMAT).format(DATE_DISPLAY_FORMAT) : '';

    return (
      <div className="allocations-allocation-single-empty single-row">
        <div className="person-info">
          <div className="person-photo">
            <div className="person-input-dot" />
          </div>
          <Can
            action={PERM_WRITE}
            subject={PERM_ALLOCATION}
            yes={(
              <button
                type="button"
                className="person-name"
                onClick={this.handleRowClick}
                title="Place a person"
              >
                Place a person
              </button>
            )}
            no={(
              <p className="unfilled-role">Unfilled</p>
            )}
          />
        </div>
        <div className="start-date">{startDateFormatted}</div>
        <div className="allocation-visualization" />
        <div className="end-date">{endDateFormatted}</div>
        <div className="allocation-percentage" />
      </div>
    );
  }
}
