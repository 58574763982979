import React, { useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { DASHBOARD_OVERVIEW_TAB, DASHBOARD_TABS, DASHBOARD_VIEW } from '../../common/constants';
import { FILTER_STORAGE_KEY, LocalStorageKeys, getStorageKey } from '../../common/localStorageKeys';
import { clearUnfilledRoles, getProjectBreakdownAndRoles, getMonthlyIdleTimeOverview } from './redux/actions';
import { getProjects } from '../projects/redux/actions';
import { getPeople, getPersonFieldValues } from '../people/redux/actions';
import { RoleBreakdown, UnfilledRoles, IdleTimeWidgit, IdleTimeByTitle } from '.';
import { HeaderControls, TabbedPageHeader } from '../common';
import { clearMultiStateModalState, trackAnalytics } from '../common/redux/actions';
import { closeModal } from '../modal-manager/redux/actions';
import { ChipBowl } from '../table';
import { FILTERED_PROJECT_DASHBOARD_QUERY_ID } from '../queries/redux/constants';
import { getFilterGroupApplied } from './utils/dashboardUtils';
import { PROJECT_FILTER_TYPE, EXCLUDED_PROJECT_HEADER_FILTERS, PRIORITY_PROJECT_FILTERS } from '../filters/common/constants';
import { AUTOMATION_PROJECT_FILTER_MENU_BUTTON } from '../filters/ids';
import { getMappedFilters } from '../filters/utils/filterUtils';
import useSavedFilters from '../filters/utils/useSavedFilters';
import { FilterMenu, SavedFiltersDropdown } from '../filters';
import { PermissionTabs } from '../wrapped-components';
import {
  filterClearEvent,
  filterClearPayload,
  filterChipRemoveEvent,
  filterChipRemovePayload,
} from '../../analytics/mixpanel/filterChips';
import { DASHBOARD } from '../../analytics/dashboard/constants';

function DashboardOverview({ isPursuitsEnabled, onTabsChange }) {
  const accountId = useSelector(({ common }) => common.accountId);
  const { userInfo: { sub: userId } } = useSelector(({ login }) => login);
  const {
    addProjectRoleAllocationPending,
    deleteProjectAllocationPending,
    replaceProjectRoleAllocationPending,
    updateProjectPending,
    addProjectRolesPending,
    deleteProjectRolePending,
    updateProjectRolePending,
  } = useSelector(({ projects }) => projects);
  const queries = useSelector(({ queries }) => queries[FILTERED_PROJECT_DASHBOARD_QUERY_ID]?.filter);
  const { projectFilterData } = useSelector(({ filters }) => filters);

  const dispatch = useDispatch();

  const changeProjectPending =
    addProjectRoleAllocationPending ||
    deleteProjectAllocationPending ||
    replaceProjectRoleAllocationPending ||
    updateProjectPending ||
    addProjectRolesPending ||
    deleteProjectRolePending ||
    updateProjectRolePending;

  const filterGroupApplied = useMemo(() => getFilterGroupApplied(queries), [queries]);

  const storageKey = getStorageKey(DASHBOARD_VIEW, accountId, FILTER_STORAGE_KEY, userId);

  useEffect(() => {
    localStorage.setItem(LocalStorageKeys.dashboardView, DASHBOARD_OVERVIEW_TAB);
  }, []);

  useEffect(() => {
    dispatch(clearMultiStateModalState());
    dispatch(closeModal());
    dispatch(clearUnfilledRoles());
    dispatch(getProjects(accountId));
    dispatch(getPeople(accountId));
    dispatch(getPersonFieldValues(accountId, 'active'));
    dispatch(getMonthlyIdleTimeOverview(accountId, moment(), moment().add(12, 'week')));
  }, [accountId, dispatch]);

  const prevChangeProjectPending = useRef(changeProjectPending);

  useEffect(() => {
    if (queries) {
      if (prevChangeProjectPending.current && !changeProjectPending) {
        setTimeout(() => {
          dispatch(getProjectBreakdownAndRoles());
        }, 3000); // TODO: Find a better solution.
      } else {
        dispatch(getProjectBreakdownAndRoles());
      }
    }
    prevChangeProjectPending.current = changeProjectPending;
  }, [dispatch, changeProjectPending, queries]);

  const trackingContext = useMemo(() => ({
    page: 'Dashboard',
    view: 'Dashboard',
    viewType: 'Dashboard',
  }), []);

  const {
    applyFilter,
    updateCurrentFilter,
    savedFilters,
    isSavedFiltersReady,
    deleteSavedFilter,
    filterToDelete,
    cancelDeleteSavedFilter,
    confirmDeleteSavedFilter,
  } = useSavedFilters({
    accountId,
    userId,
    viewId: DASHBOARD_VIEW,
    isPursuitsEnabled,
    filterType: PROJECT_FILTER_TYPE,
    trackingContext,
  });

  const tabs = useMemo(() => (
    <PermissionTabs value={DASHBOARD_OVERVIEW_TAB} onChange={onTabsChange} tabs={DASHBOARD_TABS} />
  ), [onTabsChange]);

  const headerControlOptions = { showSearch: false, viewColumns: false };
  const headerControlColumns = useMemo(() => getMappedFilters(projectFilterData), [projectFilterData]);

  const headerControls = (
    <HeaderControls options={headerControlOptions}>
      <FilterMenu
        label="Project filters"
        columns={headerControlColumns}
        exclude={EXCLUDED_PROJECT_HEADER_FILTERS}
        priorityColumns={PRIORITY_PROJECT_FILTERS}
        buttonId={AUTOMATION_PROJECT_FILTER_MENU_BUTTON}
        updateCurrentFilter={updateCurrentFilter(PROJECT_FILTER_TYPE)}
        filterQueries={queries}
      />
      <SavedFiltersDropdown
        savedFilters={savedFilters}
        disabled={!isSavedFiltersReady}
        onApply={applyFilter}
        onDelete={deleteSavedFilter}
        isApplied={!!queries?.filterId}
        filterToDelete={filterToDelete}
        onCancelDelete={cancelDeleteSavedFilter}
        onConfirmDelete={confirmDeleteSavedFilter}
      />
    </HeaderControls>
  );

  const filterChips = useMemo(() => {
    const onClear = () => dispatch(trackAnalytics(filterClearEvent('Projects'), filterClearPayload(DASHBOARD)));
    const onUpdate = () => dispatch(trackAnalytics(filterChipRemoveEvent('Project'), filterChipRemovePayload(DASHBOARD)));

    return (
      <ChipBowl
        queryId={FILTERED_PROJECT_DASHBOARD_QUERY_ID}
        filterStorageKey={storageKey}
        useClearText={false}
        onClearFilters={onClear}
        onUpdateFilters={onUpdate}
        filterType={PROJECT_FILTER_TYPE}
      />
    );
  }, [storageKey, dispatch]);

  return (
    <>
      <TabbedPageHeader tabs={tabs} controls={headerControls} />
      <div className="app-page-main content-container dashboard-content">
        {filterChips}
        <div className="dashboard-overview-paper-container">
          <div className="insight-widgets">
            <IdleTimeWidgit onTabsChange={onTabsChange} />
            <IdleTimeByTitle />
          </div>
          <div className="overview-details">
            <RoleBreakdown filterGroupApplied={filterGroupApplied} />
            <UnfilledRoles filterGroupApplied={filterGroupApplied} />
          </div>
        </div>
      </div>
    </>
  );
}

DashboardOverview.propTypes = {
  isPursuitsEnabled: PropTypes.bool.isRequired,
  onTabsChange: PropTypes.func.isRequired,
};

export default DashboardOverview;
