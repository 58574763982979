import { AppBar, Button, Dialog, Toolbar, Typography } from '@material-ui/core';
import { Add, Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CreateNewAccountForm } from '.';
import { FloatingActionButton } from '../wrapped-components';
import { createNewAccount } from './redux/actions';

export class CreateNewAccountModal extends PureComponent {
  static propTypes = {
    accounts: PropTypes.object.isRequired,
    createAccount: PropTypes.func.isRequired,
  };

  state = {
    modalOpen: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { accounts } = this.props;
    if (accounts.createNewAccountPending && !nextProps.accounts.createNewAccountPending) {
      this.setState({
        modalOpen: false,
      });
    }
  }

  toggleModal = () => {
    const { modalOpen } = this.state;
    this.setState({
      modalOpen: !modalOpen,
    });
  }

  render() {
    const { accounts, createAccount } = this.props;
    const { modalOpen } = this.state;

    return (
      <div className="new-account-modal">
        <FloatingActionButton
          icon={<Add />}
          tooltipText="Add a New Account"
          onClick={this.toggleModal}
        />

        <div className="new-account__modal">
          <Dialog
            classes={{ paperWidthSm: 'dialog__container' }}
            open={modalOpen}
            onClose={this.toggleModal}
            aria-labelledby="form-dialog-title"
          >
            <AppBar position="static" color="primary" className="createAccountSize">
              <Toolbar>
                <Typography color="inherit" variant="h6">New Account</Typography>
                <Button onClick={this.toggleModal} classes={{ root: 'icon__close' }} disableRipple>
                  <Close />
                </Button>
              </Toolbar>
            </AppBar>

            <CreateNewAccountForm createAccount={createAccount} accounts={accounts} />
          </Dialog>
        </div>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    createAccount: bindActionCreators(createNewAccount, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(CreateNewAccountModal);
