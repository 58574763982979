import { call, put, takeLatest } from 'redux-saga/effects';
import {
  SELF_PERFORM_ADD_HOURLY_PERSON_BEGIN,
  SELF_PERFORM_ADD_HOURLY_PERSON_SUCCESS,
  SELF_PERFORM_ADD_HOURLY_PERSON_FAILURE,
  SELF_PERFORM_ADD_HOURLY_PERSON_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';

export function addHourlyPerson(accountId, data) {
  return {
    type: SELF_PERFORM_ADD_HOURLY_PERSON_BEGIN,
    accountId,
    data,
  };
}

export function dismissAddHourlyPersonError() {
  return {
    type: SELF_PERFORM_ADD_HOURLY_PERSON_DISMISS_ERROR,
  };
}

export function* doAddHourlyPerson(action) {
  const { accountId, data } = action;
  let res;
  try {
    res = yield call(protectedJsonPost, `accounts/${accountId}/hourly-persons`, data);
  } catch (err) {
    yield put({
      type: SELF_PERFORM_ADD_HOURLY_PERSON_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: SELF_PERFORM_ADD_HOURLY_PERSON_SUCCESS,
    data: res,
    fields: data?.fields || [],
  });
}

export function* watchAddHourlyPerson() {
  yield takeLatest(SELF_PERFORM_ADD_HOURLY_PERSON_BEGIN, doAddHourlyPerson);
}

export function reducer(state, action) {
  switch (action.type) {
    case SELF_PERFORM_ADD_HOURLY_PERSON_BEGIN:
      return {
        ...state,
        addHourlyPersonPending: true,
        addHourlyPersonError: null,
      };

    case SELF_PERFORM_ADD_HOURLY_PERSON_SUCCESS: {
      const { data, fields } = action;
      return {
        ...state,
        filteredHourlyPersons: [
          ...state.filteredHourlyPersons,
          {
            ...data,
            fields,
            currentAssignments: [],
            upcomingAssignments: [],
            assignments: [],
          },
        ],
        addHourlyPersonPending: false,
        addHourlyPersonError: null,
      };
    }

    case SELF_PERFORM_ADD_HOURLY_PERSON_FAILURE:
      return {
        ...state,
        addHourlyPersonPending: false,
        addHourlyPersonError: action.data.error,
      };

    case SELF_PERFORM_ADD_HOURLY_PERSON_DISMISS_ERROR:
      return {
        ...state,
        addHourlyPersonError: null,
      };

    default:
      return state;
  }
}
