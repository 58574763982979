import moment from 'moment';
import { caseInsensitiveStringIncludes } from 'src/utils/miscUtils';
import { DATE_INPUT_FORMAT } from 'src/common/constants';
import { naturalSort } from 'src/utils/sortUtils';

const updatePhases = (projectDates, expandPhases, phases, updateType, updateDates = {}) => {
  const momentStart = moment(updateDates.startDate);
  const momentEnd = moment(updateDates.endDate);

  if (updateType === 'end') {
    if (!projectDates.endDate || !updateDates.endDate) return phases;

    return phases.reduce((newPhases, phase) => {
      if ((expandPhases && projectDates.endDate.isSame(phase.endDate)) || momentEnd.isBefore(phase.endDate)) {
        if (!momentEnd.isBefore(phase.startDate)) {
          const newPhase = {
            ...phase,
            endDate: updateDates.endDate,
          };

          if (phase.subPhases && phase.subPhases.length) {
            newPhase.subPhases = updatePhases(projectDates, expandPhases, phase.subPhases, updateType, updateDates);
          }
          newPhases.push(newPhase);
        }
      } else {
        newPhases.push(phase);
      }

      return newPhases;
    }, []);
  }

  if (updateType === 'start') {
    if (!projectDates.startDate || !updateDates.startDate) return phases;

    return phases.reduce((newPhases, phase) => {
      if ((expandPhases && projectDates.startDate.isSame(phase.startDate)) || momentStart.isAfter(phase.startDate)) {
        if (!momentStart.isAfter(phase.endDate)) {
          const newPhase = {
            ...phase,
            startDate: updateDates.startDate,
          };

          if (phase.subPhases && phase.subPhases.length) {
            newPhase.subPhases = updatePhases(projectDates, expandPhases, phase.subPhases, updateType, updateDates);
          }

          newPhases.push(newPhase);
        }
      } else {
        newPhases.push(phase);
      }

      return newPhases;
    }, []);
  }

  if (!projectDates.startDate || !projectDates.endDate || !updateDates.startDate || !updateDates.endDate) return phases;

  // updateType === 'both'
  const shiftLength = momentStart.diff(projectDates.startDate, 'days');
  return phases.map((phase) => {
    const startDate = moment(phase.startDate).add(shiftLength, 'days').format(DATE_INPUT_FORMAT);
    const endDate = moment(phase.endDate).add(shiftLength, 'days').format(DATE_INPUT_FORMAT);

    const newPhase = {
      ...phase,
      startDate,
      endDate,
    };

    if (phase.subPhases && phase.subPhases.length) {
      newPhase.subPhases = updatePhases(projectDates, expandPhases, phase.subPhases, updateType, updateDates);
    }

    return newPhase;
  });
};

const updateRoles = (projectDates, expandRoles, roles, updateType, updateDates = {}) => {
  const momentStart = moment(updateDates.startDate);
  const momentEnd = moment(updateDates.endDate);

  if (updateType === 'end') {
    if (!projectDates.endDate || !updateDates.endDate) return roles;

    return roles.reduce((newRoles, role) => {
      if ((expandRoles && projectDates.endDate.isSame(role.endDate)) || momentEnd.isBefore(role.endDate)) {
        if (!momentEnd.isBefore(role.startDate)) {
          newRoles.push({
            ...role,
            endDate: updateDates.endDate,
          });
        }
      } else {
        newRoles.push(role);
      }

      return newRoles;
    }, []);
  }

  if (updateType === 'start') {
    if (!projectDates.startDate || !updateDates.startDate) return roles;

    return roles.reduce((newRoles, role) => {
      if ((expandRoles && projectDates.startDate.isSame(role.startDate)) || momentStart.isAfter(role.startDate)) {
        if (!momentStart.isAfter(role.endDate)) {
          newRoles.push({
            ...role,
            startDate: updateDates.startDate,
          });
        }
      } else {
        newRoles.push(role);
      }

      return newRoles;
    }, []);
  }

  if (!projectDates.endDate || !projectDates.startDate || !updateDates.startDate || !updateDates.endDate) return roles;

  // updateType === 'both'
  const shiftLength = momentStart.diff(projectDates.startDate, 'days');
  return roles.map((role) => {
    const startDate = moment(role.startDate).add(shiftLength, 'days').format(DATE_INPUT_FORMAT);
    const endDate = moment(role.endDate).add(shiftLength, 'days').format(DATE_INPUT_FORMAT);
    const roleRequirements = role.requirements || [];
    const requirements = roleRequirements.map(req => ({
      ...req,
      startDate: moment(req.startDate, DATE_INPUT_FORMAT).add(shiftLength, 'days').format(DATE_INPUT_FORMAT),
      endDate: moment(req.endDate, DATE_INPUT_FORMAT).add(shiftLength, 'days').format(DATE_INPUT_FORMAT),
    }));

    return {
      ...role,
      startDate,
      endDate,
      requirements,
    };
  });
};

const normalizeRoleToRequirements = (role) => {
  if (role.requirements) return role;

  const { allocations, ...normalizedRole } = role;
  normalizedRole.requirements = allocations;
  normalizedRole.allocations = [];
  return normalizedRole;
};

const normalizeRolesToRequirements = roles => roles.map(normalizeRoleToRequirements);

const normalizeRoleToAllocations = (role) => {
  if (role.allocations) return role;

  const { requirements, ...normalizedRole } = role;
  normalizedRole.allocations = requirements;
  return normalizedRole;
};

const normalizeRolesToAllocations = roles => roles.map(normalizeRoleToAllocations);

const updateProjectField = (project, data) => {
  const existingField = project.fields.find(f => f.fieldId === data.id);
  let newFieldValues;
  if (existingField) {
    newFieldValues = project.fields.map((field) => {
      if (field.fieldId === data.id) {
        return {
          ...field,
          values: data.values,
          __typename: 'Field',
        };
      }
      return field;
    });
  } else {
    newFieldValues = [...project.fields, data];
  }
  return {
    ...project,
    fields: newFieldValues,
  };
};

const updateProjectPhaseById = (project, phaseId, data) => {
  const { phases, currentPhases } = project;
  const { startDate, endDate, name } = data;

  const newPhases = phases.map((phase) => {
    if (phase.id === phaseId) {
      return data;
    }
    return phase;
  });

  let newCurrentPhases;
  if (moment().isBetween(startDate, endDate, null, '[]')) {
    if (currentPhases.find(currPhase => currPhase.name === name)) { // updating a phase that is current (active)
      newCurrentPhases = currentPhases.map(currPhase => (currPhase.name === data.name
        ? {
          ...data,
          subPhases: data.subPhases
            .filter(({ startDate, endDate }) => moment().isBetween(startDate, endDate, null, '[]'))
            .map(({ name }) => name),
        }
        : currPhase
      ));
    } else { // adding a phase to the currentPhases list
      newCurrentPhases = [
        ...currentPhases,
        {
          ...data,
          subPhases: data.subPhases.map(({ name }) => name),
        }];
    }
  } else { // removing a phase from a currentPhases list
    newCurrentPhases = currentPhases.filter(currPhase => currPhase.name !== data.name);
  }

  return {
    ...project,
    phases: naturalSort(newPhases, ['startDate', 'endDate', 'name']),
    currentPhases: naturalSort(newCurrentPhases, ['startDate', 'endDate', 'name']),
  };
};

const removeProjectSubPhase = (project, phase, subPhase) => {
  const newPhases = project.phases.map((ph) => {
    if (ph.id === phase.id) {
      const newSubPhases = ph.subPhases
        ? ph.subPhases.filter(sp => sp.id !== subPhase.id)
        : [];

      return {
        ...ph,
        subPhases: newSubPhases,
      };
    }

    return ph;
  });

  const newCurrentPhases = project.currentPhases.map((currPhase) => {
    const { name, subPhases } = currPhase;

    if (name === phase.name) {
      const newSubPhases = subPhases?.filter(subPhaseName => subPhaseName !== subPhase.name) || [];

      return {
        ...currPhase,
        subPhases: newSubPhases,
      };
    }

    return currPhase;
  });

  return {
    ...project,
    phases: newPhases,
    currentPhases: newCurrentPhases,
  };
};

const updateProjectSubPhaseById = (project, subPhase, startDate, endDate, phaseId) => {
  let phaseName;
  const newProjectPhases = project.phases.map((phase) => {
    const newSubPhases = phase.subPhases
      ? phase.subPhases.map((sp) => {
        if (sp.id === subPhase.id) {
          phaseName = phase.name;

          return {
            ...sp,
            startDate: moment(startDate).format(DATE_INPUT_FORMAT),
            endDate: moment(endDate).format(DATE_INPUT_FORMAT),
          };
        }

        return sp;
      })
      : [];

    if (phase.id === phaseId) {
      return {
        ...phase,
        subPhases: naturalSort(newSubPhases, ['startDate', 'endDate', 'name']),
      };
    }
    return phase;
  });

  const isUpdatedPhaseActive = moment().isBetween(startDate, endDate, null, '[]');
  let newSubPhases;

  const newCurrentPhases = project.currentPhases.map((currPhase) => {
    if (currPhase.name === phaseName) { // id's are not stored for currentPhases, hence this "hack" with the name
      if (isUpdatedPhaseActive) {
        newSubPhases = currPhase.subPhases.includes(subPhase.name)
          ? currPhase.subPhases // do not update the list
          : [...currPhase.subPhases, subPhase.name]; // add new subPhase name to the list
      } else { // remove from the currentPhases list
        newSubPhases = currPhase.subPhases.filter(spName => spName !== subPhase.name);
      }

      return {
        ...currPhase,
        subPhases: newSubPhases,
      };
    }

    return currPhase;
  });

  return {
    ...project,
    phases: newProjectPhases,
    currentPhases: newCurrentPhases,
  };
};

const deleteProjectRoleById = (project, roleId) => {
  const role = project.roles ? project.roles.find(role => role.id === roleId) : null;
  const unfilledRoles = role && !role.isFilled ? project.unfilledRoles - 1 : project.unfilledRoles;

  return {
    ...project,
    totalRoles: project.totalRoles - 1,
    unfilledRoles,
    roles: project.roles ? project.roles.filter(role => role.id !== roleId) : [],
  };
};

const addRolesToProject = (project, newRoles) => ({
  ...project,
  totalRoles: project.totalRoles + newRoles.length,
  unfilledRoles: project.unfilledRoles + newRoles.length,
  roles: [...newRoles, ...(project.roles || [])],
});

const addSubPhaseToProject = (project, phase, subPhase) => {
  const newPhases = project.phases.map((ph) => {
    if (ph.id === phase.id) {
      const subPhases = ph.subPhases || [];

      return {
        ...ph,
        subPhases: [...subPhases, subPhase],
      };
    }
    return ph;
  });

  const newCurrentPhases = project.currentPhases.map((currPhase) => {
    const { name, subPhases } = currPhase;

    if (name === phase.name) {
      const oldSubPhases = subPhases || [];

      return {
        ...currPhase,
        subPhases: [...oldSubPhases, subPhase.name],
      };
    }

    return currPhase;
  });

  return {
    ...project,
    phases: newPhases,
    currentPhases: newCurrentPhases,
  };
};

const getProjectUpdateType = (startDate, endDate, data) => {
  let updateType;
  const sameStartDate = moment(startDate).isSame(data.startDate);
  const sameEndDate = moment(endDate).isSame(data.endDate);
  if (!sameStartDate && !sameEndDate) {
    updateType = 'both';
  } else {
    updateType = sameStartDate ? 'end' : 'start';
  }
  return updateType;
};

const setStartDateTimeStamp = (res = []) => {
  if (!(Array.isArray(res))) return [];

  return res.map((element) => {
    const temp = { ...element };
    temp.startDateTimeStamp = moment(temp.startDate, DATE_INPUT_FORMAT).unix();
    return temp;
  });
};

const getColorType = (defaultProjectColors, accountColors, value) => {
  let colorType = 'Custom';

  if (caseInsensitiveStringIncludes(defaultProjectColors, value)) {
    colorType = 'Default';
  } else if (caseInsensitiveStringIncludes(accountColors, value)) {
    colorType = 'Account colour';
  }

  return colorType;
};

const updateProjectNoteById = (project, noteId, data) => {
  const newNotes = project?.notes?.map((note) => {
    if (note.id === noteId) {
      return data;
    }
    return note;
  });
  return {
    ...project,
    notes: newNotes,
  };
};

const aggregateRoleTemplateList = templateRoles => (
  templateRoles.map(({ roleName, quantity }) => (
    quantity > 1 ? `${roleName} (${quantity})` : roleName
  )).join(', ')
);

const generateFormattedProjectRoleData = (projectRoles) => {
  const roleCountMap = {};

  const formattedProjectRoles = [...projectRoles].map((role) => {
    const { roleName, requirements } = role;
    if (!(roleName in roleCountMap)) {
      roleCountMap[roleName] = 1;
    } else {
      roleCountMap[roleName] += 1;
    }

    return {
      name: roleName,
      allocatedPercent: requirements.length > 1 ? 100 : requirements[0].allocatedPercent,
    };
  });

  const rolesListString = Object.entries(roleCountMap).map(([role, count]) => {
    if (count > 1) {
      return `${role} (${count})`;
    }
    return role;
  });

  return {
    aggregatedRoleList: rolesListString.join(', '),
    formattedProjectRoles: naturalSort(formattedProjectRoles, 'name'),
  };
};

export {
  updatePhases,
  updateRoles,
  normalizeRolesToRequirements,
  normalizeRoleToRequirements,
  normalizeRoleToAllocations,
  normalizeRolesToAllocations,
  updateProjectField,
  updateProjectPhaseById,
  removeProjectSubPhase,
  updateProjectSubPhaseById,
  deleteProjectRoleById,
  addRolesToProject,
  addSubPhaseToProject,
  getProjectUpdateType,
  setStartDateTimeStamp,
  getColorType,
  updateProjectNoteById,
  generateFormattedProjectRoleData,
  aggregateRoleTemplateList,
};
