import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNTS_GET_ACCOUNT_COLORS_BEGIN,
  ACCOUNTS_GET_ACCOUNT_COLORS_SUCCESS,
  ACCOUNTS_GET_ACCOUNT_COLORS_FAILURE,
  ACCOUNTS_GET_ACCOUNT_COLORS_DISMISS_ERROR,
} from './constants';
import { getAccountColorsQuery } from '../queries/getAccountColorsQuery';
import graphApiClient from '../../../common/GraphAPIClient';

export function getAccountColors(accountId) {
  const { query, variables } = getAccountColorsQuery(accountId);

  return {
    type: ACCOUNTS_GET_ACCOUNT_COLORS_BEGIN,
    query,
    variables,
  };
}

export function dismissGetAccountColorsError() {
  return {
    type: ACCOUNTS_GET_ACCOUNT_COLORS_DISMISS_ERROR,
  };
}

export function* doGetAccountColors(action) {
  const { query, variables } = action;
  let res;
  try {
    res = yield call(graphApiClient.query, { query, variables });
  } catch (err) {
    yield put({
      type: ACCOUNTS_GET_ACCOUNT_COLORS_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: ACCOUNTS_GET_ACCOUNT_COLORS_SUCCESS,
    data: res.data,
  });
}

export function* watchGetAccountColors() {
  yield takeLatest(ACCOUNTS_GET_ACCOUNT_COLORS_BEGIN, doGetAccountColors);
}

export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNTS_GET_ACCOUNT_COLORS_BEGIN:
      return {
        ...state,
        getAccountColorsPending: true,
        getAccountColorsError: null,
      };

    case ACCOUNTS_GET_ACCOUNT_COLORS_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        accountColors: data?.accountColors?.items || [],
        getAccountColorsPending: false,
        getAccountColorsError: null,
      };
    }

    case ACCOUNTS_GET_ACCOUNT_COLORS_FAILURE:
      return {
        ...state,
        getAccountColorsPending: false,
        getAccountColorsError: action.data.error,
      };

    case ACCOUNTS_GET_ACCOUNT_COLORS_DISMISS_ERROR:
      return {
        ...state,
        getAccountColorsError: null,
      };

    default:
      return state;
  }
}
