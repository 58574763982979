import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { sortSegmentsByStartDate } from 'src/utils/dateSegmentUtils';
import { SingleRoleRequirement } from '.';

export const RoleRequirementList = ({
  className,
  roleRequirements,
}) => {
  const sortedRequirements = sortSegmentsByStartDate(roleRequirements);

  return (
    <div className={classNames('allocations-role-requirement-list', className)}>
      {sortedRequirements.map(requirement => (
        <SingleRoleRequirement
          key={`${requirement.startDate}-${requirement.endDate}`}
          startDate={requirement.startDate}
          endDate={requirement.endDate}
          percentage={requirement.allocatedPercent}
        />
      ))}
    </div>
  );
};

RoleRequirementList.propTypes = {
  className: PropTypes.string,
  roleRequirements: PropTypes.array,
};

RoleRequirementList.defaultProps = {
  className: '',
  roleRequirements: [],
};

export default RoleRequirementList;
