import { call, put, takeLatest } from 'redux-saga/effects';
import {
  SELF_PERFORM_DELETE_REQUEST_BEGIN,
  SELF_PERFORM_DELETE_REQUEST_SUCCESS,
  SELF_PERFORM_DELETE_REQUEST_FAILURE,
  SELF_PERFORM_DELETE_REQUEST_DISMISS_ERROR,
} from './constants';
import { protectedJsonDelete } from '../../../utils/api';

export function deleteRequest(accountId, projectId, requestId, mixpanelData) {
  return {
    type: SELF_PERFORM_DELETE_REQUEST_BEGIN,
    accountId,
    projectId,
    requestId,
    mixpanelData,
  };
}

export function dismissDeleteRequestError() {
  return {
    type: SELF_PERFORM_DELETE_REQUEST_DISMISS_ERROR,
  };
}

export function* doDeleteRequest(action) {
  const { accountId, projectId, requestId, mixpanelData } = action;
  try {
    yield call(protectedJsonDelete, `accounts/${accountId}/projects/${projectId}/hourly-requests/${requestId}`);
  } catch (err) {
    yield put({
      type: SELF_PERFORM_DELETE_REQUEST_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: SELF_PERFORM_DELETE_REQUEST_SUCCESS,
    requestId,
    projectId,
    mixpanelData,
  });
}

export function* watchDeleteRequest() {
  yield takeLatest(SELF_PERFORM_DELETE_REQUEST_BEGIN, doDeleteRequest);
}

export function reducer(state, action) {
  switch (action.type) {
    case SELF_PERFORM_DELETE_REQUEST_BEGIN:
      return {
        ...state,
        deleteRequestPending: true,
        deleteRequestError: null,
      };

    case SELF_PERFORM_DELETE_REQUEST_SUCCESS: {
      const { requestId } = action;
      return {
        ...state,
        deleteRequestPending: false,
        deleteRequestError: null,
        selectedRequest: null,
        filteredRequests: state.filteredRequests.filter(({ id }) => id !== requestId),
      };
    }

    case SELF_PERFORM_DELETE_REQUEST_FAILURE:
      return {
        ...state,
        deleteRequestPending: false,
        deleteRequestError: action.data.error,
      };

    case SELF_PERFORM_DELETE_REQUEST_DISMISS_ERROR:
      return {
        ...state,
        deleteRequestError: null,
      };

    default:
      return state;
  }
}
