import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { NotifyModal } from '@bridgit/foundation';

import { getCommunicateAssignmentRemovalModalProps } from './utils/projectRolesUtils';
import { ASSIGNMENT_REMOVE_REPLACE_MODAL_LAUNCHED } from '../../analytics/projects/constants';
import { getEntityState } from '../../utils/dateUtils';
import { trackAnalytics } from '../common/redux/actions';

const CommunicateAssignmentRemovalOrReplacementModal = ({
  role,
  selectedProject,
  primaryAction,
  secondaryAction,
  allocation,
  type,
}) => {
  const dispatch = useDispatch();

  const { sendRoleNotificationPending } = useSelector(({ projects }) => projects);
  const { people = [] } = useSelector(({ people }) => {
    const { entities } = people;

    return { people: entities };
  });

  useEffect(() => {
    const { name, id } = selectedProject;
    const { name: roleName, id: roleId, allocations } = role;
    const { startDate, endDate } = allocation;

    const allocationState = getEntityState(moment(startDate), moment(endDate));

    const analyticsPayload = {
      'Project name': name,
      'Project id': id,
      'Role name': roleName,
      'Role id': roleId,
      'Launched to communicate': type,
      'Allocation status': allocationState,
      '# of people in the role at time of action': allocations.length,
    };

    dispatch(trackAnalytics(ASSIGNMENT_REMOVE_REPLACE_MODAL_LAUNCHED, analyticsPayload));
  }, [allocation, role, selectedProject, type, dispatch]);

  const {
    headline,
    standardText,
    recipients,
    notifyHeader,
    subtitle,
    options,
    defaultOptions,
  } = useMemo(
    () => getCommunicateAssignmentRemovalModalProps(role, selectedProject, people, allocation),
    [role, selectedProject, people, allocation],
  );

  return (
    <NotifyModal
      headline={headline}
      open
      standardText={standardText}
      recipients={recipients}
      notifyHeader={notifyHeader}
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
      secondaryText="Do not send"
      pending={sendRoleNotificationPending}
      includeAvatar
      showCommunicationMethods
      subtitle={subtitle}
      options={options}
      defaultOptions={defaultOptions}
      alertMessage="This assignment has already been removed in Bench - selecting “do not send” will not undo the removal."
      alertSeverity="warning"
    />
  );
};

CommunicateAssignmentRemovalOrReplacementModal.propTypes = {
  role: PropTypes.object.isRequired,
  selectedProject: PropTypes.object.isRequired,
  primaryAction: PropTypes.func.isRequired,
  secondaryAction: PropTypes.func.isRequired,
  allocation: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default CommunicateAssignmentRemovalOrReplacementModal;
