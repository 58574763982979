import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { TimelinePositioner } from 'src/features/common';
import { getBoundingRangeFromSegments, isValidSegmentArray } from 'src/utils/dateSegmentUtils';
import { customPropCheck } from 'src/utils/propCheckUtils';
import { Checkbox, Tooltip } from '@material-ui/core';
import { Report } from '@material-ui/icons';
import { SegmentLabel, EditorCapsule } from '.';
import { isStringOrMoment, stringToMoment } from '../../utils/dateUtils';
import {
  DATE_DISPLAY_FORMAT,
  EDIT_ACTION_COLLAPSED,
  END_DATE,
  START_DATE,
} from '../../common/constants';
import { ITEM_WILL_BE_DELETED } from './common/constants';

function SingleSegment({
  onToggleEdit,
  name,
  secondaryText,
  capsulePrimaryColor,
  capsuleBorderColor,
  capsuleLeftText,
  timelineDates,
  endContent,
  boundingStartDate,
  boundingEndDate,
  checked,
  dateUnderEdit,
  editAction,
  showCheckbox,
  warningText,
  showDeleteWarning,
  tooltip,
  compact,
  disabled,
  endContentTooltip,
}) {
  if (!isValidSegmentArray(timelineDates)) return null;

  let labelSegment;
  if (editAction === EDIT_ACTION_COLLAPSED) {
    labelSegment = dateUnderEdit === START_DATE ? timelineDates?.[timelineDates.length - 1] : timelineDates?.[0];
  } else {
    labelSegment = getBoundingRangeFromSegments(timelineDates);
  }

  const { startDate, endDate } = { ...labelSegment };
  const formattedStart = stringToMoment(startDate).format(DATE_DISPLAY_FORMAT);
  const formattedEnd = stringToMoment(endDate).format(DATE_DISPLAY_FORMAT);
  const superText = `${formattedStart} - ${formattedEnd}`;

  return (
    <div className={classNames('edit-dates-single-segment', { compact, 'is-disabled': disabled })}>
      <SegmentLabel primaryText={name} secondaryText={secondaryText} />
      <div className="timeline-container">
        <TimelinePositioner
          timelineDates={timelineDates}
          boundingStartDate={boundingStartDate}
          boundingEndDate={boundingEndDate}
        >
          <EditorCapsule
            timelineDates={timelineDates}
            primaryColorCode={capsulePrimaryColor}
            borderColorCode={capsuleBorderColor}
            superText={superText}
            leftText={capsuleLeftText}
            tooltip={tooltip}
          />
        </TimelinePositioner>
        { showDeleteWarning && (
          <div className="warning">
            <Report className="report-icon" fontSize="small" />
            <div className="text">{warningText}</div>
          </div>
        )}
      </div>
      <Tooltip
        title={endContentTooltip || ''}
        placement="top"
      >
        <div className="end-content">
          { endContent }
          { showCheckbox && (
            <Checkbox
              color="primary"
              checked={checked}
              onChange={onToggleEdit}
              disabled={disabled}
            />
          )}
        </div>
      </Tooltip>
    </div>
  );
}

SingleSegment.propTypes = {
  onToggleEdit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  secondaryText: PropTypes.string,
  capsulePrimaryColor: PropTypes.string,
  capsuleBorderColor: PropTypes.string,
  capsuleLeftText: PropTypes.string,
  timelineDates: customPropCheck(isValidSegmentArray),
  endContent: PropTypes.node,
  boundingStartDate: customPropCheck(isStringOrMoment).isRequired,
  boundingEndDate: customPropCheck(isStringOrMoment).isRequired,
  checked: PropTypes.bool,
  dateUnderEdit: PropTypes.oneOf([START_DATE, END_DATE]).isRequired,
  editAction: PropTypes.string.isRequired,
  showCheckbox: PropTypes.bool,
  warningText: PropTypes.string,
  showDeleteWarning: PropTypes.bool,
  tooltip: PropTypes.string,
  compact: PropTypes.bool,
  disabled: PropTypes.bool,
  endContentTooltip: PropTypes.string,
};

SingleSegment.defaultProps = {
  capsulePrimaryColor: '',
  capsuleBorderColor: '',
  capsuleLeftText: '',
  secondaryText: '',
  timelineDates: [],
  endContent: null,
  checked: false,
  showCheckbox: true,
  warningText: ITEM_WILL_BE_DELETED,
  showDeleteWarning: false,
  tooltip: '',
  compact: false,
  disabled: false,
  endContentTooltip: null,
};

/* istanbul ignore next */
function mapStateToProps({ editDates }) {
  const {
    boundingStartDate,
    boundingEndDate,
    dateUnderEdit,
    editAction,
  } = editDates;

  return {
    boundingStartDate,
    boundingEndDate,
    dateUnderEdit,
    editAction,
  };
}

export default connect(
  mapStateToProps,
)(SingleSegment);
