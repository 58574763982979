import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import classNames from 'classnames';
import {
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListSubheader,
  Tooltip,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { naturalSort } from 'src/utils/sortUtils';
import { OutlinedInput, EditControls } from '../wrapped-components';
import { Confirm, Modal } from '../common';
import { cloneDeep } from '../../utils/miscUtils';
import { validateListItem } from '../../utils/validators';
import { MAX_LIST_ITEM_LENGTH, MAX_LIST_LENGTH } from './redux/constants';

export class CustomLists extends PureComponent {
  static propTypes = {
    list: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    updateSelectionList: PropTypes.func.isRequired,
    pending: PropTypes.bool,
  };

  static defaultProps = {
    pending: false,
  };

  constructor(props) {
    super(props);

    this.inputProps = { maxLength: MAX_LIST_ITEM_LENGTH };
    this.classes = {
      modal: { root: ' common-modal delete-list-item-modal' },
      subheader: { root: 'lists__subheader lists__flex' },
      listItem: { root: 'list-item-spacing' },
      listItemText: { root: 'form__li--small' },
    };

    this.state = {
      values: cloneDeep(naturalSort(props.list.definedValues, 'definedValue')),
      tempValue: '',
      hasChanged: false,
      showError: false,
      showEditError: false,
      confirmDeletingIndex: -1,
      preventDeleteModal: false,
      editIndex: -1,
      tempEditValue: '',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { list } = this.props;
    if (list.name !== nextProps.list.name || list.definedValues.length !== nextProps.list.definedValues.length) {
      this.setState({
        values: cloneDeep(naturalSort(nextProps.list.definedValues, 'definedValue')),
        hasChanged: false,
      });
    }
  }

  setValue = (e) => {
    this.setState({
      tempValue: e.currentTarget.value,
    });
  }

  setEditValue = (e) => {
    this.setState({
      tempEditValue: e.currentTarget.value,
    });
  }

  onCancel = () => {
    const { onCancel } = this.props;
    onCancel();
  }

  onSubmit = (values, preventClose) => {
    const { updateSelectionList, list } = this.props;
    const submitValues = values.map(value => ({ valueId: value.valueId, definedValue: value.definedValue, forRemoval: value.forRemoval }));
    updateSelectionList(list.name, submitValues, list.id, preventClose);
  }

  removeItem = (index, inUse) => () => {
    const { values } = this.state;
    const { list } = this.props;

    if (inUse && list.isRequired) {
      this.setState({
        preventDeleteModal: true,
      });
    } else if (list.id) {
      if (!inUse) {
        const newValues = [...values];
        newValues[index] = {
          ...newValues[index],
          forRemoval: true,
        };
        this.onSubmit(newValues, true);
        this.setState({
          showError: false,
        });
      } else {
        this.setState({
          confirmDeletingIndex: index,
        });
      }
    } else {
      // remove item from index from an immutable array
      const newValues = values.filter((_, valueIndex) => valueIndex !== index);
      this.setState({
        values: newValues,
        hasChanged: true,
        showError: false,
      });
    }
  }

  checkEnter = (e) => {
    const { list } = this.props;
    const { showError, values } = this.state;
    const value = e.currentTarget.value.trim();

    if (e.keyCode === 13) {
      if (value.length === 0) {
        return;
      }

      if (validateListItem(values, 'definedValue', value)) {
        const { values } = this.state;
        const newValues = naturalSort([...values, { definedValue: value }], 'definedValue');
        this.setState({
          values: newValues,
          tempValue: '',
          hasChanged: true,
        });
        if (list.id) {
          this.onSubmit(newValues, true);
        }
      } else {
        this.setState({
          showError: true,
        });
      }
    } else if (showError) {
      this.setState({
        showError: false,
      });
    }
  }

  checkEditEnter = (e) => {
    const { showEditError, values, editIndex } = this.state;
    const { list } = this.props;

    if (e.keyCode === 13) {
      const value = e.currentTarget.value.trim();

      if (value.length === 0) {
        const { editIndex, values } = this.state;
        const existingValue = values[editIndex];
        this.removeItem(existingValue.definedValue);
        this.setState({
          tempEditValue: '',
          editIndex: -1,
        });
      } else if (validateListItem(values, 'definedValue', value, editIndex)) {
        const { values, editIndex } = this.state;
        const newValues = [...values];
        newValues[editIndex].definedValue = value;
        this.setState({
          values: newValues,
          tempEditValue: '',
          hasChanged: true,
          editIndex: -1,
        });

        if (list.id) {
          this.onSubmit(newValues, true);
        }
      } else {
        this.setState({
          showEditError: true,
        });
      }
    } else if (showEditError) {
      this.setState({
        showEditError: false,
      });
    }
  }

  onSave = () => {
    const { values } = this.state;
    this.onSubmit(values, false);
  }

  hideModal = () => {
    this.setState({
      confirmDeletingIndex: -1,
      preventDeleteModal: false,
    });
  }

  onDeleteUnRequiredField = () => {
    const { values, confirmDeletingIndex } = this.state;

    const newValues = [...values];
    newValues[confirmDeletingIndex] = {
      ...newValues[confirmDeletingIndex],
      forRemoval: true,
    };
    this.onSubmit(newValues, true);

    this.hideModal();
  }

  editItem = (e) => {
    const { values, editIndex } = this.state;
    const index = parseInt(e.currentTarget.dataset.id, 10);
    if (editIndex !== index) {
      this.setState({
        editIndex: index,
        tempEditValue: values[index].definedValue,
      });
    }
  }

  exitEdit = () => {
    this.setState({
      editIndex: -1,
      tempEditValue: '',
    });
  }

  render() {
    const {
      values,
      tempValue,
      hasChanged,
      showError,
      showEditError,
      confirmDeletingIndex,
      preventDeleteModal,
      editIndex,
      tempEditValue,
    } = this.state;
    const { list, pending } = this.props;
    const isNewList = !list.id;

    return (
      <Grid className="account-settings-custom-lists" item xs={6}>
        {preventDeleteModal && (
          <Modal
            classes={this.classes.modal}
            headline="Delete list item"
            showClose={false}
            buttons={[(
              <Button
                key="ok"
                className="button__default"
                disableRipple
                variant="contained"
                size="medium"
                onClick={this.hideModal}
              >
                Ok
              </Button>
            )]}
          >
            <div>Since this is a required field, you cannot delete this item while it is in use.</div>
          </Modal>
        )}

        {confirmDeletingIndex !== -1 && (
          <Confirm
            headline="Delete list item"
            acceptButtonText="Delete"
            cancelButtonText="Cancel"
            onCancel={this.hideModal}
            onAccept={this.onDeleteUnRequiredField}
          >
            <div>This item is currently in use. Are you sure you want to delete it?</div>
          </Confirm>
        )}
        <List component="nav">
          <ListSubheader classes={this.classes.subheader} disableGutters>List name</ListSubheader>
          <ListItem button divider disableGutters disableRipple className="list-nav">
            <ListItemText primary={list.name} />
          </ListItem>
        </List>

        <List component="nav">
          <ListSubheader classes={this.classes.subheader} disableGutters>
            <span>List Content</span>
            <span>{pluralize('item', values.length, true)}</span>
          </ListSubheader>
          {values.length >= MAX_LIST_LENGTH
            ? (
              <div className="limit-reached">
                {`The limit of ${MAX_LIST_LENGTH} list items has been reached`}
              </div>
            )
            : (
              <OutlinedInput
                className={classNames('show-enter', 'bottom-space', { 'error item-unique': showError, 'error item-missing': hasChanged && values.length === 0 })}
                inputProps={this.inputProps}
                value={tempValue}
                placeholder="Add a list item"
                onChange={this.setValue}
                onKeyUp={this.checkEnter}
                disabled={pending}
                slim
                fullWidth
                autoFocus
              />
            )}
          <Divider />

          {values.map((value, index) => (
            <ListItem key={value.definedValue} classes={this.classes.listItem} data-id={index} onClick={this.editItem} button divider disableGutters>
              {editIndex === index
                ? (
                  <OutlinedInput
                    className={classNames('show-enter', { 'error item-unique': showEditError })}
                    inputProps={this.inputProps}
                    value={tempEditValue}
                    placeholder="Edit list item"
                    onChange={this.setEditValue}
                    onKeyUp={this.checkEditEnter}
                    onBlur={this.exitEdit}
                    disabled={pending}
                    slim
                    fullWidth
                    autoFocus
                  />
                )
                : <ListItemText classes={this.classes.listItemText} primary={value.definedValue} />}
              {(isNewList || values.length > 1) && (
                <ListItemSecondaryAction className="delete-list-item">
                  <Tooltip title="Delete item" placement="top">
                    <IconButton
                      onClick={this.removeItem(index, value?.inUse?.length > 0)}
                      disabled={pending}
                    >
                      <Close />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          ))}
          {isNewList && (
            <section className="form__actions">
              <EditControls
                primaryAction={this.onSave}
                secondaryAction={this.onCancel}
                disabled={!hasChanged || values.length === 0}
                pending={pending}
              />
            </section>
          )}
        </List>
      </Grid>
    );
  }
}

const mapStateToProps = ({ accountSettings }) => ({
  pending: accountSettings.updatePersonFieldsPending || accountSettings.updateProjectFieldsPending,
});

export default connect(mapStateToProps)(CustomLists);
