import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FilteredTable } from '../table';
import {
  SELF_PERFORM_TAB,
  HOURLY_PERSONS_LIST_STATIC_COLUMNS,
  CONTENT_VIEW_MAP,
  PEOPLE_HOURLY_PERSONS_LABEL,
} from '../../common/constants';
import { FILTERED_HOURLY_PERSONS_QUERY_ID } from '../queries/redux/constants';
import { LocalStorageKeys } from '../../common/localStorageKeys';
import { getHourlyPersonsTableRows } from './utils/hourlyPersonUtils';
import { filterTable } from '../table/filterUtils';
import { AddHourlyPersonModal, HourlyPersonProfile } from '.';
import {
  getHourlyPersonById,
  clearSelectedHourlyPerson,
} from './redux/actions';

const PeopleSelfPerform = ({
  filteredHourlyPersons,
  visibleColumns,
  queries,
  hourlyPersonsCount,
  selectedHourlyPerson,
  loadMore,
  loading,
  getFilteredHourlyPersonsPagePending,
  getFilteredPeoplePageError,
  getHourlyPersonById,
  history,
  accountId,
  clearSelectedHourlyPerson,
  match,
}) => {
  useEffect(() => {
    localStorage.setItem(LocalStorageKeys.peopleView, SELF_PERFORM_TAB);
  }, []);

  useEffect(() => {
    const { contentView, peopleId } = match.params;
    const isSelfPerformTab = contentView === CONTENT_VIEW_MAP[SELF_PERFORM_TAB];
    const selectedHourlyPersonId = Number(peopleId) || null;

    if (isSelfPerformTab && selectedHourlyPersonId) {
      getHourlyPersonById(accountId, selectedHourlyPersonId);
    }
  }, [accountId, getHourlyPersonById, match.params]);

  const hourlyPersonsRows = useMemo(() => {
    const data = getHourlyPersonsTableRows(filteredHourlyPersons, visibleColumns);

    return filterTable(queries.search, data);
  }, [filteredHourlyPersons, queries.search, visibleColumns]);

  const onTableRowSelected = (rowId, rowMeta) => {
    if (rowMeta.id === selectedHourlyPerson?.id) return;

    window.mixpanel.track('View Hourly Profile', {
      'Person Name': rowMeta.name,
      'Person ID': rowMeta.id,
      'Opened from': 'Hourly People List',
    });

    getHourlyPersonById(accountId, rowMeta.id);
    history.replace(`/accounts/${accountId}/people/self-perform/${rowMeta.id}`);
  };

  const onCloseDetail = () => {
    clearSelectedHourlyPerson();
  };

  useEffect(() => {
    if (!selectedHourlyPerson) {
      history.replace(`/accounts/${accountId}/people/self-perform`);
    }
  }, [selectedHourlyPerson, accountId, history]);

  const columns = selectedHourlyPerson ? visibleColumns.slice(0, 2) : visibleColumns;
  const hasInitiatedSearch = queries.search?.args?.[0]?.activeFilters?.[0];
  const noMatch = hasInitiatedSearch && !filteredHourlyPersons.length
    ? 'Sorry, no matching records found'
    : 'No people have been added to your account. Click the + button below to add a person.';
  const showTableLoader = loading && !selectedHourlyPerson;
  const selectedRowId = selectedHourlyPerson?.id || '';
  const hourlyPersonProfileClass = queries.filters?.args?.length ? 'detail-compact' : '';

  return (
    <div className="people-self-perform-list">
      <div className={`listWrapper${selectedHourlyPerson ? ' listWrapper--left' : ''}`}>
        <FilteredTable
          showFilters={!selectedHourlyPerson}
          data={hourlyPersonsRows}
          columns={columns}
          onRowClick={onTableRowSelected}
          loadMore={loadMore}
          noMatch={noMatch}
          loading={showTableLoader}
          loadingPage={getFilteredHourlyPersonsPagePending}
          selectedRowId={selectedRowId}
          pagingError={!!getFilteredPeoplePageError}
          staticColumns={HOURLY_PERSONS_LIST_STATIC_COLUMNS}
          allowColumnReordering
          queryId={FILTERED_HOURLY_PERSONS_QUERY_ID}
          resultCount={hourlyPersonsCount}
          resultLabel={PEOPLE_HOURLY_PERSONS_LABEL}
        />
      </div>
      {selectedHourlyPerson && (
        <HourlyPersonProfile
          className={hourlyPersonProfileClass}
          onBackClick={onCloseDetail}
          selectedPerson={selectedHourlyPerson}
        />
      )}
      {!selectedHourlyPerson && (
        <AddHourlyPersonModal />
      )}
    </div>
  );
};

PeopleSelfPerform.propTypes = {
  loadMore: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  hourlyPersonsCount: PropTypes.number,
  visibleColumns: PropTypes.array.isRequired,
  selectedHourlyPerson: PropTypes.object,
  filteredHourlyPersons: PropTypes.array,
  queries: PropTypes.object,
  getFilteredHourlyPersonsPagePending: PropTypes.bool.isRequired,
  getFilteredPeoplePageError: PropTypes.object,
  history: PropTypes.object.isRequired,
  getHourlyPersonById: PropTypes.func.isRequired,
  accountId: PropTypes.number.isRequired,
  clearSelectedHourlyPerson: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

PeopleSelfPerform.defaultProps = {
  hourlyPersonsCount: 0,
  selectedHourlyPerson: null,
  filteredHourlyPersons: [],
  queries: {},
  getFilteredPeoplePageError: null,
};

/* istanbul ignore next */
const mapStateToProps = ({ table, queries, selfPerform, common }) => {
  const { visibleColumns } = table;
  const {
    selectedHourlyPerson,
    hourlyPersonsCount,
    filteredHourlyPersons,
    getFilteredHourlyPersonsPending,
    getFilteredHourlyPersonsPagePending,
  } = selfPerform;
  const { filteredHourlyPersons: filteredHourlyPersonsQueries } = queries;
  const { accountId } = common;

  const loading = getFilteredHourlyPersonsPending || getFilteredHourlyPersonsPagePending;

  return {
    visibleColumns,
    queries: filteredHourlyPersonsQueries,
    selectedHourlyPerson,
    hourlyPersonsCount,
    filteredHourlyPersons,
    accountId,
    getFilteredHourlyPersonsPending,
    getFilteredHourlyPersonsPagePending,
    loading,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  getHourlyPersonById: bindActionCreators(getHourlyPersonById, dispatch),
  clearSelectedHourlyPerson: bindActionCreators(clearSelectedHourlyPerson, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(PeopleSelfPerform));
