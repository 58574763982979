import { compose } from 'redux';

const roles = root => root.roles;

const pageFrom = state => state.pageFrom;
const hasMore = state => state.hasMore;
const unfilledSegments = state => state.roleSegments;
const count = state => state.count;
const pending = state => state.getFilteredRolesPending;
const error = state => state.getFilteredRolesError;
const pagePending = state => state.getFilteredRolesPagePending;

const rolesPageFrom = compose(pageFrom, roles);
const rolesHaveMore = compose(hasMore, roles);
const rolesCount = compose(count, roles);
const rolesLoading = compose(pending, roles);
const rolesLoadingError = compose(error, roles);
const rolesPageLoading = compose(pagePending, roles);
const rolesUnfilledSegments = compose(unfilledSegments, roles);

export {
  rolesPageFrom,
  rolesHaveMore,
  rolesCount,
  rolesLoading,
  rolesLoadingError,
  rolesPageLoading,
  unfilledSegments,
  rolesUnfilledSegments,
};
