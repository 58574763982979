import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { Button } from '@material-ui/core';
import { NotifyModal, Loader } from '@bridgit/foundation';
import { connect } from 'react-redux';
import { FILTER_NAME } from 'src/filters/constants';
import { FILTERED_MATCHES_QUERY_ID } from 'src/features/queries/redux/constants';
import { getStandardNotifyText, getRequestRecipients } from 'src/features/self-perform/utils/requestDetailsUtils';
import { BestMatchList } from '.';
import { ProjectNotifyHeader } from '../projects';
import {
  BEST_MATCH_TOP_TYPE,
  BEST_MATCH_OTHER_TYPE,
} from './constants';
import { SearchBox } from '../filters';
import { clearQueries } from '../queries/redux/actions';

export const BestMatches = ({
  bestMatches,
  otherMatches,
  getBestMatchesPending,
  assignRequestPending,
  onAssign,
  selectedRequest,
  clearQueries,
}) => {
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [showNotifyModal, setShowNotifyModal] = useState(false);
  const [clearSearch, setClearSearch] = useState(false);

  const standardText = useMemo(() => getStandardNotifyText(selectedPerson, selectedRequest),
    [selectedPerson, selectedRequest]);

  const recipients = useMemo(() => getRequestRecipients(selectedPerson, selectedRequest),
    [selectedPerson, selectedRequest]);

  const notifyHeader = useMemo(() => {
    const { description, project } = selectedRequest;
    return (
      <ProjectNotifyHeader
        label="Description"
        value={description}
        project={project}
      />
    );
  }, [selectedRequest]);

  useEffect(() => {
    setSelectedPerson(null);
  }, [bestMatches, otherMatches, getBestMatchesPending]);

  useEffect(() => {
    setClearSearch(true);
    clearQueries(FILTERED_MATCHES_QUERY_ID);
  }, [selectedRequest.id, clearQueries]);

  useEffect(() => {
    if (clearSearch) setClearSearch(false);
  }, [clearSearch]);

  const onSelectPerson = useCallback((person) => {
    if (person.id === selectedPerson?.id) {
      setSelectedPerson(null);
    } else {
      setSelectedPerson(person);
    }
  }, [selectedPerson]);

  const onPersonAssign = useCallback(() => {
    onAssign(selectedPerson.id, selectedPerson.name, null, null);
    setShowNotifyModal(false);
  }, [onAssign, selectedPerson?.id, selectedPerson?.name]);

  const onNotifyCallback = useCallback((message = null, defaultMessage = null) => {
    onAssign(selectedPerson.id, selectedPerson.name, message, defaultMessage);
    setShowNotifyModal(false);
  }, [onAssign, selectedPerson?.id, selectedPerson?.name]);

  const onNotifyAssign = useCallback(() => setShowNotifyModal(true), []);
  const closeModal = useCallback(() => setShowNotifyModal(false), []);
  const onCancel = useCallback(() => setSelectedPerson(null), []);

  const searchBox = useMemo(() => (
    <SearchBox
      placeholder="Search for available people by name"
      type={FILTER_NAME}
      queryId={clearSearch ? null : FILTERED_MATCHES_QUERY_ID}
    />
  ), [clearSearch]);

  if (getBestMatchesPending) {
    return (
      <div className="self-perform-best-matches">
        {searchBox}
        <Loader />
      </div>
    );
  }

  const hasBestMatches = bestMatches.length > 0;
  const hasOtherMatches = otherMatches.length > 0;

  const renderList = () => (
    <div className="list-wrap">
      <div className="title">Matches</div>
      <BestMatchList
        type={BEST_MATCH_TOP_TYPE}
        matches={bestMatches}
        selectedPerson={selectedPerson}
        onSelectPerson={onSelectPerson}
      />
      <div className="title other">Other</div>
      <BestMatchList
        type={BEST_MATCH_OTHER_TYPE}
        matches={otherMatches}
        selectedPerson={selectedPerson}
        onSelectPerson={onSelectPerson}
      />
    </div>
  );

  return (
    <div className="self-perform-best-matches">
      {searchBox}
      {hasBestMatches || hasOtherMatches
        ? renderList()
        : <div className="empty-list">There are no matches for this request.</div>}
      <div className={classNames('footer', { active: !!selectedPerson })}>
        <Button color="default" disableRipple size="medium" onClick={onCancel}>
          Cancel
        </Button>
        <Button color="primary" disableRipple size="medium" onClick={onPersonAssign}>
          Assign
        </Button>
        <Button color="primary" variant="outlined" disableRipple size="medium" onClick={onNotifyAssign}>
          Assign & Notify
        </Button>
      </div>
      {selectedPerson && (
        <NotifyModal
          headline="Notify via text"
          open={showNotifyModal}
          pending={assignRequestPending}
          primaryAction={onNotifyCallback}
          secondaryAction={closeModal}
          selectedPerson={selectedPerson}
          standardText={standardText}
          recipients={recipients}
          notifyHeader={notifyHeader}
        />
      )}
    </div>
  );
};

BestMatches.propTypes = {
  bestMatches: PropTypes.array.isRequired,
  otherMatches: PropTypes.array.isRequired,
  getBestMatchesPending: PropTypes.bool,
  assignRequestPending: PropTypes.bool,
  onAssign: PropTypes.func.isRequired,
  selectedRequest: PropTypes.object.isRequired,
  clearQueries: PropTypes.func.isRequired,
};

BestMatches.defaultProps = {
  getBestMatchesPending: false,
  assignRequestPending: false,
};

/* istanbul ignore next */
const mapStateToProps = ({ selfPerform }) => {
  const {
    bestMatches,
    otherMatches,
    getBestMatchesPending,
    assignRequestPending,
  } = selfPerform;

  return {
    bestMatches,
    otherMatches,
    getBestMatchesPending,
    assignRequestPending,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    clearQueries: bindActionCreators(clearQueries, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BestMatches);
