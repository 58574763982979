import {
  CELL_NUMBER_FIELD,
  FIELD_TYPE_CURRENCY,
  FIELD_TYPE_DATE,
  FIELD_TYPE_MULTI_SELECT,
  FIELD_TYPE_PHONE,
  FIELD_TYPE_PHONE_E164,
  FIELD_TYPE_SINGLE_SELECT,
} from '../common/constants';
import { SKILL_SET_FIELD } from '../features/self-perform/constants';
import { getFormattedDate } from './dateUtils';
import { formatPhoneNumberInput } from './phoneNumberUtils';

export function formatDollarValue(value, options = {}) {
  const filteredString = value.toString().replace(/[^\d.]/g, '');
  const parsedValue = parseFloat(filteredString);

  const formatOptions = {
    includeSymbol: true,
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
  };

  const symbol = formatOptions.includeSymbol ? '$' : '';

  if (Number.isNaN(parsedValue)) {
    return null;
  }

  return `${symbol}${parsedValue.toLocaleString(undefined, formatOptions)}`;
}

export function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) {
    return '';
  }

  const sections = phoneNumber.trim().replace('+', '').toLowerCase().split('x');
  const phone = sections[0];
  let formatted = phoneNumber; // Default the formatted value to the initial phone number

  if (phone.length === 10) {
    formatted = `${phone.slice(0, 3)}-${phone.slice(3, 6)}-${phone.slice(6)}`;
  } else if (phone.length === 11) {
    formatted = `${phone.slice(0, 1)}-${phone.slice(1, 4)}-${phone.slice(4, 7)}-${phone.slice(7)}`;
  }

  if (sections.length > 1) {
    formatted = `${formatted}, ext. ${sections[1]}`;
  }

  return formatted;
}

export const formatHourlyPersonData = (fields, values) => {
  const { Name: name, [CELL_NUMBER_FIELD]: phoneNumber, [SKILL_SET_FIELD]: skillSet } = values;
  return {
    name,
    phoneNumber: formatPhoneNumberInput(phoneNumber),
    skillSet,
    fields: fields.map(({ id, type, name }) => {
      const fieldObj = {
        fieldId: id,
        type,
        name,
        values: Array.isArray(values[name]) ? values[name] : [values[name]],
      };
      if (values[name] === '') {
        fieldObj.values = [];
      }
      return fieldObj;
    }),
  };
};

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export const getFormattedFieldValue = (value, fieldType) => {
  switch (fieldType) {
    case FIELD_TYPE_SINGLE_SELECT:
    case FIELD_TYPE_MULTI_SELECT: {
      return value.join(', ');
    }

    case FIELD_TYPE_PHONE:
    case FIELD_TYPE_PHONE_E164: {
      return formatPhoneNumber(value[0]);
    }

    case FIELD_TYPE_DATE: {
      return getFormattedDate(value[0]);
    }

    case FIELD_TYPE_CURRENCY: {
      return `$${value[0]}`;
    }

    default:
      return value?.[0] || '';
  }
};

export const generateAbbreviation = (string = '', maxLength = 6) => {
  if (string.length <= maxLength) return string;
  if (string.indexOf(' ') === -1) return string.slice(0, maxLength);

  // Return the first character of each word, up to maxLength characters
  return string
    .split(/\s/)
    .reduce((result, word) => result + word.charAt(0), '')
    .slice(0, maxLength);
};

export const trimField = value => value && value.trim();

export const convertMetersToDistance = (meters = 0, conversionFactorMetric) => {
  const [parsedMeters, parsedFactor] = [parseFloat(meters), parseFloat(conversionFactorMetric)];

  if (Number.isNaN(parsedMeters) || Number.isNaN(parsedFactor)) {
    return null;
  }

  return Math.ceil(parsedMeters / parsedFactor);
};

export const convertDistanceToMeters = (distance = 0, conversionFactorMetric) => {
  const [parsedDistance, parsedFactor] = [parseFloat(distance), parseFloat(conversionFactorMetric)];

  if (Number.isNaN(parsedDistance) || Number.isNaN(parsedFactor)) {
    return null;
  }

  return parsedDistance * parsedFactor;
};
