import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Radio, FormControlLabel } from '@material-ui/core';
import { DATE_DISPLAY_FORMAT } from 'src/common/constants';
import { Confirm } from '../common';

const DateUpdateConfirmModal = ({
  project,
  startDate,
  endDate,
  onCancel,
  onAccept,
  loading,
  stopClickPropagation,
}) => {
  const [updateMode, setUpdateMode] = useState('expand');

  const momentStart = moment(startDate);
  const momentEnd = moment(endDate);
  const isStartDateEdit = !momentStart.isSame(project.startDate);
  const newDate = isStartDateEdit ? `start on ${momentStart.format(DATE_DISPLAY_FORMAT)}` : `end on ${momentEnd.format(DATE_DISPLAY_FORMAT)}`;
  const oldDate = isStartDateEdit ? `start on ${moment(project.startDate)
    .format(DATE_DISPLAY_FORMAT)}` : `end on ${moment(project.endDate)
    .format(DATE_DISPLAY_FORMAT)}`;
  const impacted = [];
  const hasRoles = project.roles && project.roles.length > 0;
  const hasAllocations = project.roles && project.roles.find(role => role.allocations && role.allocations.length);
  const hasPhases = project.phases && project.phases.length > 0;
  const hasSubPhases = project.phases &&
    project.phases.find(phase => Array.isArray(phase.subPhases) && phase.subPhases.length > 0);

  if (hasRoles) impacted.push('roles');
  if (hasAllocations) impacted.push('allocations');
  if (hasPhases) impacted.push('phases');
  if (hasSubPhases) impacted.push('sub-phases');

  const impactedText = impacted.length > 2 ? impacted.join(', ')
    .replace(/, ([^,]*)$/, ', and $1') : impacted.join(' and ');
  let confirmMessage = '';
  let confirmOptions;

  const onConfirmChange = evt => setUpdateMode(evt.target.value);

  if (momentStart.isBefore(project.startDate) || momentEnd.isAfter(project.endDate)) {
    confirmMessage = `What would you like to do to all ${impactedText} currently scheduled to ${oldDate}?`;
    confirmOptions = (
      <>
        <FormControlLabel
          checked={updateMode === 'expand'}
          onChange={onConfirmChange}
          control={<Radio color="primary" />}
          value="expand"
          label={`Edit impacted ${impactedText} to ${newDate}.`}
          disabled={loading}
        />
        <FormControlLabel
          checked={updateMode === 'dontExpand'}
          onChange={onConfirmChange}
          control={<Radio color="primary" />}
          value="dontExpand"
          label={`Maintain existing ${isStartDateEdit ? 'start' : 'end'} dates for all ${impactedText}.`}
          disabled={loading}
        />
      </>
    );
  } else {
    confirmMessage = `All impacted ${impactedText} will be updated to reflect this change.`;
  }

  const acceptChanges = () => {
    onAccept(updateMode);
  };

  const stopPropagation = (evt) => {
    if (stopClickPropagation) evt.stopPropagation();
  };

  return (
    <div className="projects-date-update-confirm-modal" onClick={stopPropagation} role="presentation">
      <Confirm
        headline={`Edit ${isStartDateEdit ? 'Start' : 'End'} Date`}
        acceptButtonText="Confirm"
        cancelButtonText="Cancel"
        onCancel={onCancel}
        onAccept={acceptChanges}
        loading={loading}
      >
        <div className="confirm-message">
          <div className="update-project-message">
            {`${project.name} will now ${newDate}.`}
            <p>{confirmMessage}</p>
          </div>
          <div>{confirmOptions}</div>
        </div>
      </Confirm>
    </div>
  );
};

DateUpdateConfirmModal.propTypes = {
  project: PropTypes.object.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  stopClickPropagation: PropTypes.bool,
};

DateUpdateConfirmModal.defaultProps = {
  stopClickPropagation: false,
};

export default DateUpdateConfirmModal;
