import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import pluralize from 'pluralize';
import { backoffRetry } from 'src/utils/api';
import { FILTER_STORAGE_KEY, SORT_STORAGE_KEY, getStorageKey, COLUMN_ORDER_STORAGE_KEY } from '../../common/localStorageKeys';
import {
  getFilterEventProperties,
  getPeopleFilterSummary,
  getProjectFilterSummary,
  mixpanelSortEvent,
} from '../filters/utils/filterUtils';
import {
  PROJECT_FILTER_TYPE,
  PEOPLE_FILTER_TYPE,
} from '../filters/common/constants';
import SingleFilter from '../filters/SingleFilter';
import { updateColumnFilter } from './filterUtils';
import { setFilterQuery, setSortQuery } from '../queries/redux/actions';
import {
  filterClearEvent,
  filterClearPayload,
  filterChipRemoveEvent,
  filterChipRemovePayload,
} from '../../analytics/mixpanel/filterChips';
import { PEOPLE_VIEW, PROJECT_VIEW, LIST_TAB, VIEWS } from '../../common/constants';
import { EnhancedTableCell, Table, ChipBowl } from '.';
import { trackAnalytics } from '../common/redux/actions';
import { AUTOMATION_TABLE_HEAD_TITLE } from './ids';

export class FilteredTable extends PureComponent {
  static propTypes = {
    accountId: PropTypes.number.isRequired,
    userId: PropTypes.string.isRequired,
    showFilters: PropTypes.bool,
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    visibleColumns: PropTypes.array.isRequired,
    setFilterQuery: PropTypes.func.isRequired,
    setSortQuery: PropTypes.func.isRequired,
    rowHover: PropTypes.bool,
    onRowClick: PropTypes.func,
    onRowMouseEnter: PropTypes.func,
    onRowMouseLeave: PropTypes.func,
    loadMore: PropTypes.func,
    noMatch: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    loading: PropTypes.bool,
    loadingPage: PropTypes.bool,
    pagingError: PropTypes.bool,
    selectedRowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    activeView: PropTypes.number.isRequired,
    defaultFilters: PropTypes.array,
    staticColumns: PropTypes.arrayOf(PropTypes.string),
    allowColumnReordering: PropTypes.bool,
    queries: PropTypes.object.isRequired,
    queryId: PropTypes.string.isRequired,
    hasChipBowl: PropTypes.bool,
    resultCount: PropTypes.number,
    resultLabel: PropTypes.string,
    trackAnalytics: PropTypes.func.isRequired,
  };

  static defaultProps = {
    showFilters: true,
    rowHover: true,
    onRowClick: () => {},
    onRowMouseEnter: () => {},
    onRowMouseLeave: () => {},
    loadMore: () => {},
    noMatch: 'Sorry, no matching records found',
    loading: false,
    loadingPage: false,
    pagingError: false,
    selectedRowId: '',
    defaultFilters: [],
    staticColumns: [],
    allowColumnReordering: false,
    hasChipBowl: true,
    resultCount: 0,
    resultLabel: null,
  };

  constructor(props) {
    super(props);
    const { accountId, activeView, loadMore, userId } = props;
    const filterStorageKey = getStorageKey(activeView, accountId, FILTER_STORAGE_KEY, userId);
    const sortStorageKey = getStorageKey(activeView, accountId, SORT_STORAGE_KEY, userId);
    const columnOrderStorageKey = getStorageKey(activeView, accountId, COLUMN_ORDER_STORAGE_KEY, userId);
    this.retryHandler = backoffRetry(loadMore);

    this.view = VIEWS[activeView];

    this.state = {
      filterStorageKey,
      sortStorageKey,
      columnOrderStorageKey,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { pagingError, loadingPage, accountId, activeView, userId } = this.props;

    if ((!pagingError && nextProps.pagingError) || (nextProps.pagingError && loadingPage && !nextProps.loadingPage)) {
      this.retryHandler.retry();
    } else if (pagingError && !nextProps.pagingError) {
      this.retryHandler.resetTries();
    }

    if (activeView !== nextProps.activeView || accountId !== nextProps.accountId) {
      const filterStorageKey = getStorageKey(nextProps.activeView, nextProps.accountId, FILTER_STORAGE_KEY, userId);
      const sortStorageKey = getStorageKey(nextProps.activeView, nextProps.accountId, SORT_STORAGE_KEY, userId);
      const columnOrderStorageKey = getStorageKey(nextProps.activeView, accountId, COLUMN_ORDER_STORAGE_KEY, userId);
      this.view = VIEWS[nextProps.activeView];
      this.setState({ filterStorageKey, sortStorageKey, columnOrderStorageKey });
    }
  }

  componentWillUnmount() {
    this.retryHandler.stopTrying();
  }

  retryHandler = null;

  onUpdateColumnFilter = (column, updatedFilters) => {
    const {
      setFilterQuery,
      queries,
      queryId,
    } = this.props;

    const { filterStorageKey } = this.state;
    const queryObj = queries[queryId];
    const filterQueryObj = queryObj ? queryObj.filter : {};
    const filter = updateColumnFilter(column, updatedFilters, filterQueryObj);
    setFilterQuery(queryId, filter);
    localStorage.setItem(filterStorageKey, JSON.stringify(filter));

    const type = this.view === 'Project' ? 'Projects' : this.view;

    window.mixpanel.track(`Filter on ${type}`, {
      'View filtered on': `${this.view} List`,
      'Filter type used': 'Column',
      ...getFilterEventProperties(column, updatedFilters),
      ...(type === 'People' && getPeopleFilterSummary(filter.args)),
      ...(type === 'Projects' && getProjectFilterSummary(filter.args)),
    });
  }

  updateColumnSort = (sortQuery) => {
    const { setSortQuery, activeView, queryId, trackAnalytics } = this.props;

    const name = sortQuery?.args?.[0]?.label;
    if (name) {
      const { event, payload } = mixpanelSortEvent(name, activeView, LIST_TAB);

      if (event && payload) {
        trackAnalytics(event, payload);
      }
    }

    const { sortStorageKey } = this.state;
    setSortQuery(queryId, sortQuery, sortStorageKey);
  }

  renderColumn = (column) => {
    const {
      visibleColumns,
      staticColumns,
      allowColumnReordering,
      queries,
      queryId,
    } = this.props;
    const { columnOrderStorageKey } = this.state;

    return (
      <EnhancedTableCell
        key={`${column.name}${column?.schemaName || ''}`}
        classes={{
          root: classNames('table-table-header-cell', column?.options?.columnClass),
          cellTitle: 'table-head-title',
        }}
        id={`${AUTOMATION_TABLE_HEAD_TITLE}-${column.name}`}
        column={column}
        onClick={this.updateColumnSort}
        visibleColumns={visibleColumns}
        staticColumns={staticColumns}
        allowColumnReordering={allowColumnReordering}
        columnOrderStorageKey={columnOrderStorageKey}
        sortQuery={queries[queryId]?.sort?.args?.[0]}
      >
        <SingleFilter queries={queries} queryId={queryId} column={column} onFilterApplied={this.onUpdateColumnFilter} />
      </EnhancedTableCell>
    );
  }

  render() {
    const {
      showFilters,
      data,
      columns,
      rowHover,
      onRowClick,
      onRowMouseEnter,
      onRowMouseLeave,
      noMatch,
      loading,
      loadingPage,
      loadMore,
      pagingError,
      selectedRowId,
      defaultFilters,
      queries,
      queryId,
      accountId,
      userId,
      activeView,
      hasChipBowl,
      resultCount,
      resultLabel,
      trackAnalytics,
    } = this.props;

    const useClearText = !defaultFilters.length;
    const queryObj = queries[queryId];
    const filterQuery = queryObj ? queryObj.filter : {};
    const noMatchMsg = filterQuery && filterQuery.args && filterQuery.args.length ? 'No results found' : noMatch;
    const filterStorageKey = getStorageKey(activeView, accountId, FILTER_STORAGE_KEY, userId);
    const resultCountText = resultLabel || this.view;
    const filterType = this.view === 'People' ? PEOPLE_FILTER_TYPE : PROJECT_FILTER_TYPE;
    const chipLocation = (activeView === PEOPLE_VIEW || activeView === PROJECT_VIEW) ? `${this.view} List` : 'List';
    const onClearFilters = () => trackAnalytics(filterClearEvent(this.view), filterClearPayload(chipLocation));
    const onUpdateFilters = () => trackAnalytics(filterChipRemoveEvent(this.view), filterChipRemovePayload(chipLocation));

    return (
      <div className="table-filtered-table">
        {hasChipBowl && (
          <div className="display-table-row">
            <div className="pre-table">
              <ChipBowl
                useClearText={useClearText}
                showFilters={showFilters}
                queryId={queryId}
                filterStorageKey={filterStorageKey}
                onClearFilters={onClearFilters}
                onUpdateFilters={onUpdateFilters}
                filterType={filterType}
              />
              <div className="total-results">
                {pluralize(resultCountText, resultCount, true)}
              </div>
            </div>
          </div>
        )}

        <div className="list display-table-row">
          <div className="list-content-wrapper">
            <Table
              className="list-content"
              data={data}
              columns={columns}
              renderColumn={this.renderColumn}
              rowHover={rowHover}
              onRowClick={onRowClick}
              onRowMouseEnter={onRowMouseEnter}
              onRowMouseLeave={onRowMouseLeave}
              noMatch={noMatchMsg}
              loading={loading}
              loadingPage={loadingPage}
              pagingError={pagingError}
              selectedRowId={selectedRowId}
              loadMore={loadMore}
            />
          </div>
        </div>
      </div>
    );
  }
}


/* istanbul ignore next */
function mapStateToProps({ common, queries, login, table }) {
  const { accountId, activeView } = common;
  const { sub: userId } = login.userInfo;
  const { visibleColumns } = table;

  return {
    accountId,
    userId,
    activeView,
    queries,
    visibleColumns,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    setFilterQuery: bindActionCreators(setFilterQuery, dispatch),
    setSortQuery: bindActionCreators(setSortQuery, dispatch),
    trackAnalytics: bindActionCreators(trackAnalytics, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FilteredTable);
