import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ArrowDropDown } from '@material-ui/icons';
import { ClickAwayListener } from '@material-ui/core';
import { CustomPopper } from 'src/features/wrapped-components';
import { getAccountColors } from 'src/features/accounts/redux/getAccountColors';
import { AdvancedColorPicker } from '@bridgit/foundation';

export const ColorPicker = ({
  accountId,
  getAccountColors,
  accountColors,
  onChange,
  value,
  name,
  defaultProjectColors,
}) => {
  const [showPicker, setShowPicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    getAccountColors(accountId);
  }, [accountId, getAccountColors]);

  const togglePicker = (evt) => {
    setShowPicker(true);
    setAnchorEl(evt.currentTarget);
  };

  const onClickAway = () => {
    setShowPicker(false);
  };

  const handleChange = ({ hex }) => {
    onChange({ target: { name, value: hex } });
  };

  return (
    <div className="common-color-picker">
      <div
        role="presentation"
        className="color-field"
        onClick={togglePicker}
      >
        <div className="selected-color" style={{ backgroundColor: value }} />
        <ArrowDropDown />
      </div>
      {showPicker && (
        <CustomPopper
          popperOpen={showPicker}
          anchorEl={anchorEl}
          classes="color-picker-popper"
          placement="bottom-start"
          hideArrow
        >
          <ClickAwayListener onClickAway={onClickAway}>
            <AdvancedColorPicker
              className="picker"
              defaultColors={defaultProjectColors}
              customColors={accountColors}
              initialValue={value}
              onChange={handleChange}
            />
          </ClickAwayListener>
        </CustomPopper>
      )}
    </div>
  );
};

ColorPicker.propTypes = {
  accountId: PropTypes.number.isRequired,
  getAccountColors: PropTypes.func.isRequired,
  accountColors: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  name: PropTypes.string,
  defaultProjectColors: PropTypes.array,
};

ColorPicker.defaultProps = {
  accountColors: [],
  value: '',
  name: '',
  defaultProjectColors: [],
};

function mapStateToProps({ projects, common, accounts }) {
  const { projectDefaults } = projects;
  const { accountId } = common;
  const { accountColors } = accounts;

  return {
    accountId,
    accountColors,
    defaultProjectColors: projectDefaults?.colors || [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAccountColors: bindActionCreators(getAccountColors, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ColorPicker);
