import React from 'react';
import { Tooltip, Fab, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const themeOverride = {
  root: {
    position: 'fixed',
    bottom: '40px',
    right: '60px',
    zIndex: 1,
  },
};
const useStyles = makeStyles(themeOverride);

const FloatingActionButton = (props) => {
  const { icon, tooltipText, onClick } = props;
  const themeOverrides = useStyles();

  return (
    <Tooltip title={tooltipText} placement="top">
      <Fab onClick={onClick} aria-label={tooltipText} classes={themeOverrides}>
        {icon}
      </Fab>
    </Tooltip>
  );
};

FloatingActionButton.propTypes = {
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  tooltipText: PropTypes.string.isRequired,
};

export default FloatingActionButton;
