import { call, put, takeLatest } from 'redux-saga/effects';
import {
  LOGIN_ACCEPT_INVITATION_BEGIN,
  LOGIN_ACCEPT_INVITATION_SUCCESS,
  LOGIN_ACCEPT_INVITATION_FAILURE,
  LOGIN_ACCEPT_INVITATION_DISMISS_ERROR,
  LOGIN_USERLOGIN_BEGIN,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';

export function acceptInvitation(data, email, useTokenAuth) {
  // If need to pass args to saga, pass it with the begin action.
  return {
    type: LOGIN_ACCEPT_INVITATION_BEGIN,
    data,
    email,
    useTokenAuth,
  };
}

export function dismissAcceptInvitationError() {
  return {
    type: LOGIN_ACCEPT_INVITATION_DISMISS_ERROR,
  };
}

// worker Saga: will be fired on LOGIN_ACCEPT_INVITATION_BEGIN actions
export function* doAcceptInvitation(action) {
  // If necessary, use argument to receive the begin action with parameters.
  let res;
  try {
    res = yield call(protectedJsonPost, 'signup', action.data);
  } catch (err) {
    yield put({
      type: LOGIN_ACCEPT_INVITATION_FAILURE,
      data: { error: err },
    });
    return;
  }
  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: LOGIN_ACCEPT_INVITATION_SUCCESS,
    data: res,
  });

  const { email, useTokenAuth, data: { password } } = action;
  // email, token and password are passed for new users, who do not belong to any accounts
  // login those new users right after signup
  if (email && password) {
    yield put({
      type: LOGIN_USERLOGIN_BEGIN,
      data: { email, password },
      useTokenAuth,
    });
  }
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchAcceptInvitation() {
  yield takeLatest(LOGIN_ACCEPT_INVITATION_BEGIN, doAcceptInvitation);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case LOGIN_ACCEPT_INVITATION_BEGIN:
      return {
        ...state,
        acceptInvitationPending: true,
        acceptInvitationError: null,
      };

    case LOGIN_ACCEPT_INVITATION_SUCCESS:
      return {
        ...state,
        acceptInvitationPending: false,
        acceptInvitationError: null,
      };

    case LOGIN_ACCEPT_INVITATION_FAILURE:
      return {
        ...state,
        acceptInvitationPending: false,
        acceptInvitationError: action.data.error,
      };

    case LOGIN_ACCEPT_INVITATION_DISMISS_ERROR:
      return {
        ...state,
        acceptInvitationError: null,
      };

    default:
      return state;
  }
}
