import { call, put, takeLatest } from 'redux-saga/effects';
import { UPCOMING, ACTIVE } from 'src/features/projects/constants';
import {
  PEOPLE_GET_PERSON_UPCOMING_ALLOCATIONS_BEGIN,
  PEOPLE_GET_PERSON_UPCOMING_ALLOCATIONS_SUCCESS,
  PEOPLE_GET_PERSON_UPCOMING_ALLOCATIONS_FAILURE,
  PEOPLE_GET_PERSON_UPCOMING_ALLOCATIONS_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';

export function getPersonUpcomingAllocations(accountId, personId, offset) {
  return {
    type: PEOPLE_GET_PERSON_UPCOMING_ALLOCATIONS_BEGIN,
    accountId,
    personId,
    offset,
  };
}

export function dismissGetPersonUpcomingAllocationsError() {
  return {
    type: PEOPLE_GET_PERSON_UPCOMING_ALLOCATIONS_DISMISS_ERROR,
  };
}

export function* doGetPersonUpcomingAllocations(action) {
  const { accountId, offset, personId } = action;
  let res;
  try {
    res = yield call(protectedJsonGet, `accounts/${accountId}/persons/${personId}/current-allocations`, { offset, projectState: `${UPCOMING},${ACTIVE}`, limit: 5000 }, true);
  } catch (err) {
    yield put({
      type: PEOPLE_GET_PERSON_UPCOMING_ALLOCATIONS_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PEOPLE_GET_PERSON_UPCOMING_ALLOCATIONS_SUCCESS,
    data: res.data,
    headers: res.headers,
    personId,
    offset,
  });
}

export function* watchGetPersonUpcomingAllocations() {
  yield takeLatest(PEOPLE_GET_PERSON_UPCOMING_ALLOCATIONS_BEGIN, doGetPersonUpcomingAllocations);
}

export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_GET_PERSON_UPCOMING_ALLOCATIONS_BEGIN:
      return {
        ...state,
        getPersonUpcomingAllocationsPending: true,
        getPersonUpcomingAllocationsError: null,
      };

    case PEOPLE_GET_PERSON_UPCOMING_ALLOCATIONS_SUCCESS:
      return {
        ...state,
        upcomingAllocations: {
          ...state.upcomingAllocations,
          [action.personId]: action.offset > 0 ? state.upcomingAllocations[action.personId].concat(action.data) : action.data,
        },
        moreUpcomingAllocations: action.headers['query-has-more'] === 'True',
        getPersonUpcomingAllocationsPending: false,
        getPersonUpcomingAllocationsError: null,
      };

    case PEOPLE_GET_PERSON_UPCOMING_ALLOCATIONS_FAILURE:
      return {
        ...state,
        getPersonUpcomingAllocationsPending: false,
        getPersonUpcomingAllocationsError: action.data.error,
      };

    case PEOPLE_GET_PERSON_UPCOMING_ALLOCATIONS_DISMISS_ERROR:
      return {
        ...state,
        getPersonUpcomingAllocationsError: null,
      };

    default:
      return state;
  }
}
