import initialState from './initialState';
import { reducer as getServiceAccountsReducer } from './getServiceAccounts';
import { reducer as addServiceAccountReducer } from './addServiceAccount';
import { reducer as resetServiceAccountReducer } from './resetServiceAccount';
import { reducer as resetServiceAccountPasswordReducer } from './resetServiceAccountPassword';
import { reducer as selectServiceAccountReducer } from './selectServiceAccount';
import { reducer as deleteServiceAccountReducer } from './deleteServiceAccount';
import { reducer as copyServiceAccountInfoReducer } from './copyServiceAccountInfo';

const reducers = [
  getServiceAccountsReducer,
  addServiceAccountReducer,
  resetServiceAccountReducer,
  resetServiceAccountPasswordReducer,
  selectServiceAccountReducer,
  deleteServiceAccountReducer,
  copyServiceAccountInfoReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
