import { connect } from 'react-redux';
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import moment from 'moment';
import deepEqual from 'react-fast-compare';
import {
  LIST_TAB,
  PEOPLE_LIST_STATIC_COLUMNS,
  DATE_DISPLAY_FORMAT,
  DATE_INPUT_FORMAT,
  TIME_OFF_LABEL_MAPPING,
  EMPTY_FIELD_VALUE,
  LIST,
} from 'src/common/constants';
import { NO_AVAILABILITY, FULL_AVAILABILITY } from 'src/filters/constants';
import { LocalStorageKeys } from 'src/common/localStorageKeys';
import {
  PEOPLE_DISPLAY_NEXT_PROJECT,
  PEOPLE_DISPLAY_NEXT_PROJECT_START,
  PEOPLE_DISPLAY_CURRENT_PROJECT_END,
  PEOPLE_DISPLAY_PURSUIT_PROJECTS,
  PEOPLE_DISPLAY_AVAILABILITY_UNTIL,
  PEOPLE_DISPLAY_CURRENT_PROJECT,
  PEOPLE_DISPLAY_NAME,
  PEOPLE_DISPLAY_TITLE,
  PEOPLE_DISPLAY_EMAIL,
  PEOPLE_DISPLAY_ISSUES,
  PEOPLE_DISPLAY_PHOTO,
  PEOPLE_DISPLAY_PERSON_ID,
  PEOPLE_DISPLAY_HIRE_DATE,
  PEOPLE_DISPLAY_TERMINATION_DATE,
  PEOPLE_DISPLAY_CERTIFICATIONS,
  PEOPLE_DISPLAY_COST_RATE,
} from './constants';
import { filterTable } from '../table/filterUtils';
import { FilteredTable } from '../table';
import { AddPersonModal, PeopleProfile } from '.';
import { FILTERED_PEOPLE_QUERY_ID } from '../queries/redux/constants';
import { PEOPLE_LIST_SELECTION_ID } from './redux/constants';
import { getPersonById, clearPersonSelection, removePerson } from './redux/actions';
import { formatDollarValue } from '../../utils/formatters';

export class PeopleList extends PureComponent {
  static propTypes = {
    people: PropTypes.object.isRequired,
    visibleColumns: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
    accountId: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    loadMore: PropTypes.func,
    history: PropTypes.object.isRequired,
    getPersonById: PropTypes.func.isRequired,
    clearPersonSelection: PropTypes.func.isRequired,
    removePerson: PropTypes.func.isRequired,
    queries: PropTypes.object,
    selectedPerson: PropTypes.object,
    peopleCount: PropTypes.number,
    personSidePanelOpen: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    loadMore: () => {},
    selectedPerson: null,
    queries: {},
    peopleCount: 0,
  }

  state = {
    peopleRows: [],
  }

  componentDidMount() {
    const {
      accountId,
      getPersonById,
      match,
      people,
      visibleColumns,
      queries,
    } = this.props;

    const { filteredPeople, entities } = people;

    localStorage.setItem(LocalStorageKeys.peopleView, LIST_TAB);

    this.setState({
      peopleRows: this.mapPeopleToRows(filteredPeople, entities, visibleColumns, queries),
    });

    const selectedPersonId = Number(match.params.peopleId) || null;

    if (selectedPersonId) {
      getPersonById(accountId, selectedPersonId, PEOPLE_LIST_SELECTION_ID);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { people, visibleColumns, queries } = this.props;
    const { filteredPeople, entities } = people;

    if (
      !deepEqual(nextProps.people.filteredPeople, filteredPeople)
      || !deepEqual(nextProps.queries, queries)
      || !deepEqual(nextProps.people.entities, entities)
      || !deepEqual(nextProps.visibleColumns, visibleColumns)
    ) {
      this.setState({
        peopleRows: this.mapPeopleToRows(nextProps.people.filteredPeople, nextProps.people.entities, nextProps.visibleColumns, nextProps.queries),
      });
    }
  }

  mapPeopleToRows = (filteredPeople, entities, visibleColumns, queries) => {
    const data = filteredPeople.map(({
      id,
      name,
      photoUrl,
      title,
      email,
      currentProjects,
      currentAllocations,
      nextAllocations,
      employmentDates,
      hasConflict,
      fields,
      availableInDays,
      availableFor,
      percentAvailableToday,
      unavailabilities,
      pursuitProjects,
      certifications,
      hourlyCostRate,
    }) => {
      const entity = entities.find(entity => entity.id === id);

      const entityPhoto = entity && entity.photoUrl ? entity.photoUrl : '';
      const photo = photoUrl || entityPhoto;

      const nextAvailabilityDisplayDate = () => {
        if (availableInDays === 0) {
          if (availableFor === FULL_AVAILABILITY) return EMPTY_FIELD_VALUE;
          return moment().add(availableFor, 'days').format(DATE_DISPLAY_FORMAT);
        }
        return availableInDays
          ? moment().add(availableInDays - 1, 'days').format(DATE_DISPLAY_FORMAT)
          : '';
      };

      let timeOffLabel;
      let percentAvailable = percentAvailableToday;
      const availabilityUntil = nextAvailabilityDisplayDate();

      // For people who are currently busy, are they allocated to a job or do they have time off?
      if (availableInDays > 0) {
        const today = moment().format(DATE_INPUT_FORMAT);
        const currentUnavailability = unavailabilities.find(
          ({ startDate, endDate }) => moment(today).isBetween(startDate, endDate, undefined, '[]'),
        );

        if (currentUnavailability) {
          percentAvailable = 0;
          timeOffLabel = TIME_OFF_LABEL_MAPPING[currentUnavailability.rangeType];
        }
      }

      const personObj = {
        [PEOPLE_DISPLAY_PERSON_ID]: id,
        [PEOPLE_DISPLAY_NAME]: name,
        [PEOPLE_DISPLAY_TITLE]: title,
        [PEOPLE_DISPLAY_PHOTO]: photo,
        [PEOPLE_DISPLAY_EMAIL]: email,
        [PEOPLE_DISPLAY_AVAILABILITY_UNTIL]: { availableInDays, percentAvailable, timeOffLabel, availabilityUntil },
        [PEOPLE_DISPLAY_CURRENT_PROJECT]: currentProjects?.length ? currentProjects.map(project => project.name).join(';') : '',
        [PEOPLE_DISPLAY_CURRENT_PROJECT_END]: currentAllocations?.length
          ? currentAllocations.map(allocation => moment(allocation.endDate).format(DATE_DISPLAY_FORMAT)).join(';') : '',
        [PEOPLE_DISPLAY_NEXT_PROJECT]: nextAllocations?.length ? nextAllocations.map(allocation => allocation.projectName).join(';') : '',
        [PEOPLE_DISPLAY_NEXT_PROJECT_START]: nextAllocations?.length
          ? nextAllocations.map(allocation => moment(allocation.startDate).format(DATE_DISPLAY_FORMAT)).join(';') : '',
        [PEOPLE_DISPLAY_ISSUES]: hasConflict,
        [PEOPLE_DISPLAY_HIRE_DATE]: employmentDates?.startDate ? moment(employmentDates.startDate).format(DATE_DISPLAY_FORMAT) : '',
        [PEOPLE_DISPLAY_TERMINATION_DATE]: employmentDates?.endDate ? moment(employmentDates.endDate).format(DATE_DISPLAY_FORMAT) : '',
        [PEOPLE_DISPLAY_PURSUIT_PROJECTS]: pursuitProjects?.length ? pursuitProjects.map(proj => proj.projectName).join(';') : '',
        [PEOPLE_DISPLAY_CERTIFICATIONS]: certifications,
        [PEOPLE_DISPLAY_COST_RATE]: hourlyCostRate ? formatDollarValue(hourlyCostRate) : '',
      };

      if (fields) {
        fields.forEach((field) => {
          if (field.values.length > 1) {
            personObj[field.name] = field.values.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).join(', ');
          } else if (field.type === 'Boolean') {
            personObj[field.name] = field.values.length ? field.values[0].toString().toLowerCase() : 'false';
          } else if (field.type === 'Currency') {
            personObj[field.name] = field.values.length ? formatDollarValue(field.values[0]) : '';
          } else if (field.values.length === 1) {
            personObj[field.name] = field.values[0].toString();
          }
        });
      }

      const rowData = !visibleColumns ? [] : visibleColumns.map((column) => {
        if (typeof personObj[column.name] === 'undefined') {
          personObj[column.name] = '';
        }
        return personObj[column.name];
      });

      return {
        rowMeta: {
          id,
          name,
          rawData: personObj,
        },
        rowData,
        rowId: id,
      };
    });

    return filterTable(queries.search, data);
  }

  onCloseDetail = () => {
    const { accountId, history, clearPersonSelection } = this.props;
    clearPersonSelection(PEOPLE_LIST_SELECTION_ID);
    history.replace(`/accounts/${accountId}/people/list`);
  }

  onTableRowSelected = (rowId, rowMeta) => {
    const { accountId, history, getPersonById } = this.props;

    window.mixpanel.track('View Profile', {
      'Person Name': rowMeta.name,
      'Person ID': rowMeta.id,
    });

    getPersonById(accountId, rowMeta.id, PEOPLE_LIST_SELECTION_ID);
    history.replace(`/accounts/${accountId}/people/list/${rowMeta.id}`);
  }

  removePerson = (parentName) => {
    const { removePerson, accountId, selectedPerson } = this.props;
    removePerson(accountId, selectedPerson.id, selectedPerson.name, parentName);
    this.onCloseDetail();
  }

  render() {
    const {
      people,
      visibleColumns,
      loading,
      loadMore,
      queries,
      selectedPerson,
      peopleCount,
      personSidePanelOpen,
    } = this.props;
    const { peopleRows } = this.state;

    const filteredPeopleLoading = people.getFilteredPeoplePending;
    const showTableLoader = (loading || filteredPeopleLoading) && !selectedPerson;
    const columns = selectedPerson ? visibleColumns.slice(0, 3) : visibleColumns;
    const filters = queries.filter || {};
    const peopleProfileClass = filters.args && filters.args.length ? 'detail-compact' : '';
    const selectedPersonAvailability = selectedPerson
      ? people.availabilities.find(a => a.personId === selectedPerson.id) || { availableInDays: NO_AVAILABILITY }
      : { availableInDays: 0 };
    const selectedRowId = selectedPerson ? selectedPerson.id : '';
    const noMatch = !people.entities || people.entities.length === 0
      ? 'No people have been added to your account. Click the + button below to add a person.'
      : 'Sorry, no matching records found';

    return (
      <div className="people-people-list">
        <div className={classNames('listWrapper', { 'listWrapper--left': !!selectedPerson, 'person-panel-open': personSidePanelOpen })}>
          <FilteredTable
            showFilters={!selectedPerson}
            data={peopleRows}
            columns={columns}
            onRowClick={this.onTableRowSelected}
            loadMore={loadMore}
            noMatch={noMatch}
            loading={showTableLoader}
            loadingPage={people.getFilteredPeoplePagePending}
            selectedRowId={selectedRowId}
            pagingError={!!people.getFilteredPeoplePageError}
            staticColumns={PEOPLE_LIST_STATIC_COLUMNS}
            allowColumnReordering
            queryId={FILTERED_PEOPLE_QUERY_ID}
            resultCount={peopleCount}
          />
        </div>

        {selectedPerson && (
          <PeopleProfile
            className={peopleProfileClass}
            onBackClick={this.onCloseDetail}
            selectedPerson={selectedPerson}
            selectedPersonAvailability={selectedPersonAvailability.availableInDays}
            toggleActivation={this.removePerson}
          />
        )}

        {!selectedPerson && (
          <AddPersonModal
            modalOrigin={LIST}
            redirectToPerson={this.onTableRowSelected}
          />
        )}
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps({ table, common, queries, people }) {
  const { accountId, personSidePanelOpen } = common;
  const { filteredPeople: filteredPeopleQueries } = queries;
  const { personSelections, peopleCount } = people;
  const { visibleColumns } = table;

  return {
    people,
    visibleColumns,
    accountId,
    queries: filteredPeopleQueries,
    selectedPerson: personSelections[PEOPLE_LIST_SELECTION_ID],
    peopleCount,
    personSidePanelOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPersonById: bindActionCreators(getPersonById, dispatch),
    clearPersonSelection: bindActionCreators(clearPersonSelection, dispatch),
    removePerson: bindActionCreators(removePerson, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(PeopleList));
