import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNT_SETTINGS_ADD_CERTIFICATION_BEGIN,
  ACCOUNT_SETTINGS_ADD_CERTIFICATION_SUCCESS,
  ACCOUNT_SETTINGS_ADD_CERTIFICATION_FAILURE,
  ACCOUNT_SETTINGS_ADD_CERTIFICATION_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';
import { insertToSortedArray } from '../../../utils/sortUtils';

export function addAccountCertification(accountId, data) {
  return {
    type: ACCOUNT_SETTINGS_ADD_CERTIFICATION_BEGIN,
    accountId,
    data,
  };
}

export function dismissAddAccountCertificationError() {
  return {
    type: ACCOUNT_SETTINGS_ADD_CERTIFICATION_DISMISS_ERROR,
  };
}

export function* doAddAccountCertification(action) {
  const { accountId, data } = action;
  let res;
  try {
    res = yield call(protectedJsonPost, `accounts/${accountId}/certifications`, data);
  } catch (err) {
    yield put({
      type: ACCOUNT_SETTINGS_ADD_CERTIFICATION_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: ACCOUNT_SETTINGS_ADD_CERTIFICATION_SUCCESS,
    data: res,
  });
}

export function* watchAddAccountCertification() {
  yield takeLatest(ACCOUNT_SETTINGS_ADD_CERTIFICATION_BEGIN, doAddAccountCertification);
}

export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNT_SETTINGS_ADD_CERTIFICATION_BEGIN:
      return {
        ...state,
        addAccountCertificationPending: true,
        addAccountCertificationError: null,
      };

    case ACCOUNT_SETTINGS_ADD_CERTIFICATION_SUCCESS: {
      const { certifications } = state;
      const compareName = (a, b) => a.name.localeCompare(b.name);
      const newSortedCertifications = insertToSortedArray(certifications, action.data, compareName);

      return {
        ...state,
        certifications: newSortedCertifications,
        addAccountCertificationPending: false,
        addAccountCertificationError: null,
      };
    }

    case ACCOUNT_SETTINGS_ADD_CERTIFICATION_FAILURE:
      return {
        ...state,
        addAccountCertificationPending: false,
        addAccountCertificationError: action.data.error,
      };

    case ACCOUNT_SETTINGS_ADD_CERTIFICATION_DISMISS_ERROR:
      return {
        ...state,
        addAccountCertificationError: null,
      };

    default:
      return state;
  }
}
