import { call, put, takeLatest } from 'redux-saga/effects';
import {
  SETTINGS_GET_ADMIN_INVITATIONS_BEGIN,
  SETTINGS_GET_ADMIN_INVITATIONS_SUCCESS,
  SETTINGS_GET_ADMIN_INVITATIONS_FAILURE,
  SETTINGS_GET_ADMIN_INVITATIONS_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';

export function getAdminInvitations() {
  // If need to pass args to saga, pass it with the begin action.
  return {
    type: SETTINGS_GET_ADMIN_INVITATIONS_BEGIN,
  };
}

export function dismissGetAdminInvitationsError() {
  return {
    type: SETTINGS_GET_ADMIN_INVITATIONS_DISMISS_ERROR,
  };
}

// worker Saga: will be fired on SETTINGS_GET_ADMIN_INVITATIONS_BEGIN actions
export function* doGetAdminInvitations() {
  let res;
  try {
    res = yield call(protectedJsonGet, 'invitations');
  } catch (err) {
    yield put({
      type: SETTINGS_GET_ADMIN_INVITATIONS_FAILURE,
      data: { error: err },
    });
    return;
  }
  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: SETTINGS_GET_ADMIN_INVITATIONS_SUCCESS,
    data: res,
  });
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchGetAdminInvitations() {
  yield takeLatest(SETTINGS_GET_ADMIN_INVITATIONS_BEGIN, doGetAdminInvitations);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case SETTINGS_GET_ADMIN_INVITATIONS_BEGIN:
      return {
        ...state,
        getAdminInvitationsPending: true,
        getAdminInvitationsError: null,
      };

    case SETTINGS_GET_ADMIN_INVITATIONS_SUCCESS:
      return {
        ...state,
        invitations: action.data,
        getAdminInvitationsPending: false,
        getAdminInvitationsError: null,
      };

    case SETTINGS_GET_ADMIN_INVITATIONS_FAILURE:
      return {
        ...state,
        getAdminInvitationsPending: false,
        getAdminInvitationsError: action.data.error,
      };

    case SETTINGS_GET_ADMIN_INVITATIONS_DISMISS_ERROR:
      return {
        ...state,
        getAdminInvitationsError: null,
      };

    default:
      return state;
  }
}
