import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { SideSlideMenu, SideSlideMenuButton } from '@bridgit/foundation';
import { MessageOutlined, Restore } from '@material-ui/icons';
import { toggleProjectSidePanel, trackAnalytics } from 'src/features/common/redux/actions';
import { SIDE_PANEL_PROJECT_HISTORY, SIDE_PANEL_PROJECT_NOTES } from './constants';
import { Can } from '../wrapped-components';
import { ProjectNotesContainer, ProjectAudit } from '.';
import { PERM_READ, PERM_ROLE } from '../permissions/utils/constants';
import { ACCOUNT_MODULE_STANDARD } from '../../common/constants';
import { getStorageKey, PROJECT_DETAILS_SIDE_PANEL_OPEN_KEY } from '../../common/localStorageKeys';
import { getValidatedLocalStorage } from '../../utils/validators';
import {
  PROJECT_CLOSE_PROJECT_PANEL,
  PROJECT_VIEW_PROJECT_NOTES,
  PROJECT_VIEW_PROJECT_ACTIVITY,
} from '../../analytics/projects/constants';

const ProjectDetailsSideMenu = ({
  selectedProject,
}) => {
  const dispatch = useDispatch();

  const { accountId, activeView } = useSelector(({ common }) => common);
  const { sub: userId } = useSelector(({ login: { userInfo } }) => userInfo);

  const sidePanelOpenKey = useMemo(() => (
    getStorageKey(activeView, accountId, PROJECT_DETAILS_SIDE_PANEL_OPEN_KEY, userId)
  ), [activeView, accountId, userId]);

  const defaultSidePanelOpen = useMemo(() => {
    const isOpened = getValidatedLocalStorage(sidePanelOpenKey);
    return isOpened ?? true;
  }, [sidePanelOpenKey]);

  useEffect(() => {
    dispatch(toggleProjectSidePanel(defaultSidePanelOpen));
  }, [defaultSidePanelOpen, dispatch]);

  const [open, setOpen] = useState(defaultSidePanelOpen);
  const [activePanel, setActivePanel] = useState(SIDE_PANEL_PROJECT_NOTES);

  const trackSlideMenuState = (eventName, open) => {
    const { name, id, type, state } = selectedProject;
    const analyticsPayload = {
      'Project name': name,
      'Project ID': id,
      'Project type': type,
      'Project status': state,
      'Viewed from': 'Project profile',
    };

    dispatch(trackAnalytics(eventName, analyticsPayload));
    localStorage.setItem(sidePanelOpenKey, open);
  };

  const openSidePanel = newActivePanel => () => {
    if (newActivePanel === activePanel && open) {
      trackSlideMenuState(PROJECT_CLOSE_PROJECT_PANEL, false);
      dispatch(toggleProjectSidePanel(false));
      setOpen(false);
    } else {
      let eventName;
      switch (newActivePanel) {
        case SIDE_PANEL_PROJECT_NOTES:
          eventName = PROJECT_VIEW_PROJECT_NOTES;
          break;

        case SIDE_PANEL_PROJECT_HISTORY:
          eventName = PROJECT_VIEW_PROJECT_ACTIVITY;
          break;

        default:
          break;
      }

      trackSlideMenuState(eventName, true);
      setActivePanel(newActivePanel);
      dispatch(toggleProjectSidePanel(true));
      setOpen(true);
    }
  };

  const onSlideMenuToggle = () => {
    if (open) {
      trackSlideMenuState(PROJECT_CLOSE_PROJECT_PANEL, false);
    } else {
      trackSlideMenuState(PROJECT_VIEW_PROJECT_NOTES, true);
    }
    // Reset active panel to default on toggle
    setActivePanel(SIDE_PANEL_PROJECT_NOTES);
    dispatch(toggleProjectSidePanel(!open));
    setOpen(!open);
  };

  const renderSideButtons = () => (
    <>
      <SideSlideMenuButton
        tooltip="Project notes"
        icon={<MessageOutlined />}
        isActive={open && activePanel === SIDE_PANEL_PROJECT_NOTES}
        onClick={openSidePanel(SIDE_PANEL_PROJECT_NOTES)}
      />

      <Can
        action={PERM_READ}
        subject={PERM_ROLE}
        module={ACCOUNT_MODULE_STANDARD}
        yes={(
          <SideSlideMenuButton
            tooltip="Project activity"
            icon={<Restore />}
            isActive={open && activePanel === SIDE_PANEL_PROJECT_HISTORY}
            onClick={openSidePanel(SIDE_PANEL_PROJECT_HISTORY)}
          />
          )}
      />
    </>
  );

  const projectNotesPanel = useMemo(() => (
    <ProjectNotesContainer
      headerLabel="Notes"
      projectId={selectedProject.id}
      projectName={selectedProject?.name}
      projectType={selectedProject?.type}
      projectStatus={selectedProject?.state}
      projectNotes={selectedProject?.notes}
    />
  ), [selectedProject.id, selectedProject?.name, selectedProject?.type, selectedProject?.state, selectedProject?.notes]);

  const projectAudit = useMemo(() => <ProjectAudit headerLabel="Project Activity" />, []);

  const renderActivePanel = useMemo(() => {
    switch (activePanel) {
      case SIDE_PANEL_PROJECT_NOTES:
        return projectNotesPanel;

      case SIDE_PANEL_PROJECT_HISTORY:
        return projectAudit;

      default:
        return null;
    }
  }, [activePanel, projectAudit, projectNotesPanel]);

  return (
    <SideSlideMenu
      open={open}
      hide={false}
      top={134} // related to @app-header-height, @app-page-header-height
      renderSideButtons={renderSideButtons}
      onSlideMenuToggle={onSlideMenuToggle}
    >
      {renderActivePanel}
    </SideSlideMenu>
  );
};

ProjectDetailsSideMenu.propTypes = {
  selectedProject: PropTypes.object.isRequired,
};

export default ProjectDetailsSideMenu;
