import { call, put, takeLatest } from 'redux-saga/effects';
import {
  HOURLY_PERSONS_GET_FILTERED_HOURLY_PERSONS_BEGIN,
  HOURLY_PERSONS_GET_FILTERED_HOURLY_PERSONS_SUCCESS,
  HOURLY_PERSONS_GET_FILTERED_HOURLY_PERSONS_FAILURE,
  HOURLY_PERSONS_GET_FILTERED_HOURLY_PERSONS_DISMISS_ERROR,
} from './constants';
import { getHourlyPersonsListQuery } from '../queries/getHourlyPersonsListQuery';
import graphApiClient from '../../../common/GraphAPIClient';
import { collateFieldValues } from '../../../filters/graphqlUtils';
import initialState from '../../people/redux/initialState';

export function getFilteredHourlyPersons(accountId, queries, pageFrom) {
  const { query, variables } = getHourlyPersonsListQuery(accountId, queries, pageFrom);

  return {
    type: HOURLY_PERSONS_GET_FILTERED_HOURLY_PERSONS_BEGIN,
    query,
    variables,
  };
}

export function dismissGetFilteredHourlyPersonsError() {
  return {
    type: HOURLY_PERSONS_GET_FILTERED_HOURLY_PERSONS_DISMISS_ERROR,
  };
}

export function* doGetFilteredHourlyPersons(action) {
  const { query, variables } = action;

  let res;
  try {
    res = yield call(graphApiClient.query, { query, variables });
  } catch (err) {
    yield put({
      type: HOURLY_PERSONS_GET_FILTERED_HOURLY_PERSONS_FAILURE,
      data: { error: err },
      isPaging: variables.from !== 0,
    });
    return;
  }

  yield put({
    type: HOURLY_PERSONS_GET_FILTERED_HOURLY_PERSONS_SUCCESS,
    data: res.data,
    clearData: variables.from === 0,
  });
}

export function* watchGetFilteredHourlyPersons() {
  yield takeLatest(HOURLY_PERSONS_GET_FILTERED_HOURLY_PERSONS_BEGIN, doGetFilteredHourlyPersons);
}

export function reducer(state, action) {
  switch (action.type) {
    case HOURLY_PERSONS_GET_FILTERED_HOURLY_PERSONS_BEGIN: {
      const { variables } = action;
      const isPaging = variables.from > 0;

      return {
        ...state,
        getFilteredHourlyPersonsPagePending: isPaging,
        getFilteredHourlyPersonsPending: !isPaging,
        getFilteredHourlyPersonsError: null,
      };
    }

    case HOURLY_PERSONS_GET_FILTERED_HOURLY_PERSONS_SUCCESS: {
      const {
        data: { hourlyPersons: { items, count, cursor, hasMore } },
        clearData,
      } = action;

      const collatedData = collateFieldValues(items);

      return {
        ...state,
        filteredHourlyPersons: clearData ? collatedData : [...state.filteredHourlyPersons, ...collatedData],
        hourlyPersonsCount: count,
        pageFrom: cursor,
        hasMore,
        getFilteredHourlyPersonsPending: false,
        getFilteredHourlyPersonsPagePending: false,
        getFilteredHourlyPersonsError: null,
        getFilteredHourlyPersonsPageError: null,
      };
    }

    case HOURLY_PERSONS_GET_FILTERED_HOURLY_PERSONS_FAILURE: {
      const { isPaging, data } = action;
      const { filteredHourlyPersons } = initialState;

      return {
        ...state,
        filteredHourlyPersons,
        getFilteredHourlyPersonsPending: false,
        getFilteredHourlyPersonsError: isPaging ? null : data.error,
        getFilteredHourlyPersonsPageError: isPaging ? data.error : null,
      };
    }

    case HOURLY_PERSONS_GET_FILTERED_HOURLY_PERSONS_DISMISS_ERROR:
      return {
        ...state,
        getFilteredHourlyPersonsError: null,
        getFilteredHourlyPersonsPageError: null,
      };

    default:
      return state;
  }
}
