import { call, put, takeLatest } from 'redux-saga/effects';
import { SEGMENT_TYPE_ROLE } from 'src/features/edit-dates/common/constants';
import { protectedJsonPut } from '../../../utils/api';
import { normalizeRoleToRequirements } from '../utils/projectUtils';
import {
  PROJECTS_UPDATE_PROJECT_ROLE_BEGIN,
  PROJECTS_UPDATE_PROJECT_ROLE_SUCCESS,
  PROJECTS_UPDATE_PROJECT_ROLE_FAILURE,
  PROJECTS_UPDATE_PROJECT_ROLE_DISMISS_ERROR,
} from './constants';

export function updateProjectRole(accountId, projectId, roleId, data) {
  return {
    type: PROJECTS_UPDATE_PROJECT_ROLE_BEGIN,
    accountId,
    projectId,
    roleId,
    data,
  };
}

export function dismissUpdateProjectRoleError() {
  return {
    type: PROJECTS_UPDATE_PROJECT_ROLE_DISMISS_ERROR,
  };
}

export function* doUpdateProjectRole(action) {
  const { accountId, projectId, roleId, data } = action;
  const { expandAllocations } = data;
  let res;
  try {
    res = yield call(protectedJsonPut, `accounts/${accountId}/projects/${projectId}/roles/${roleId}`, data);
  } catch (err) {
    yield put({
      type: PROJECTS_UPDATE_PROJECT_ROLE_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PROJECTS_UPDATE_PROJECT_ROLE_SUCCESS,
    projectId,
    roleId,
    data: res,
    expandAllocations, // Needed in rootReducer.js
  });
}

export function* watchUpdateProjectRole() {
  yield takeLatest(PROJECTS_UPDATE_PROJECT_ROLE_BEGIN, doUpdateProjectRole);
}

export function reducer(state, action) {
  switch (action.type) {
    case PROJECTS_UPDATE_PROJECT_ROLE_BEGIN:
      return {
        ...state,
        updateProjectRolePending: true,
        updateProjectRoleError: null,
      };

    case PROJECTS_UPDATE_PROJECT_ROLE_SUCCESS: {
      const { projectId, roleId, data } = action;
      const { filteredProjects, projectSelections } = state;
      const formattedData = { ...data, __typename: SEGMENT_TYPE_ROLE };

      const newFilteredProjects = filteredProjects.map((project) => {
        if (project.id === projectId) {
          return {
            ...project,
            roles: project.roles.map((role) => {
              if (role.id === roleId) {
                return normalizeRoleToRequirements(formattedData);
              }
              return role;
            }),
          };
        }
        return project;
      });

      const newProjectSelections = { ...projectSelections };
      Object.keys(newProjectSelections).forEach((selectionId) => {
        const selectedProject = newProjectSelections[selectionId];
        if (selectedProject && selectedProject.id === projectId) {
          const updatedProject = {
            ...selectedProject,
            roles: selectedProject.roles.map((role) => {
              if (role.id === roleId) {
                return normalizeRoleToRequirements(formattedData);
              }
              return role;
            }),
          };
          newProjectSelections[selectionId] = {
            ...updatedProject,
          };
        }
      });

      return {
        ...state,
        filteredProjects: newFilteredProjects,
        updateProjectRolePending: false,
        updateProjectRoleError: null,
        projectSelections: newProjectSelections,
      };
    }

    case PROJECTS_UPDATE_PROJECT_ROLE_FAILURE:
      return {
        ...state,
        updateProjectRolePending: false,
        updateProjectRoleError: action.data.error,
      };

    case PROJECTS_UPDATE_PROJECT_ROLE_DISMISS_ERROR:
      return {
        ...state,
        updateProjectRoleError: null,
      };

    default:
      return state;
  }
}
