import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { CheckboxForm } from '@bridgit/foundation';
import { PROJECT_GANTT_CONFIG_KEY } from '../../gantt/redux/constants';
import { DefaultGanttFileExportMenu } from '..';
import { DATE_INPUT_FORMAT } from '../../../common/constants';
import { MONTH, THREE_YEARS, TIME_INTERVAL_UNIT_MAP, TIME_MULTIPLIERS_MAP, INTERVALS_MAP } from '../../gantt/constants';
import { PROJECT_GANTT_REPORT_ARGS_KEY, getStorageKey } from '../../../common/localStorageKeys';
import { ADDITIONAL_REPORT_OPTIONS } from './constants';
import { REPORTS_EXPORT_PROJECT_GANTT } from '../../../analytics/reports/constants';
import { trackAnalytics } from '../../common/redux/trackAnalytics';
import { getProjectGanttReportAnalyticsPayload } from '../../../analytics/reports/utils';
import { getPaperWidth } from '../utils/reportUtils';

const ProjectGanttFileExportMenu = ({
  menuTitle,
  handleClose,
}) => {
  const dispatch = useDispatch();

  const [includeAllocPerc, setIncludeAllocPerc] = useState(false);

  const { accountId, activeView } = useSelector(({ common }) => common);
  const { timeInterval, viewRoles, roleSortOption, roleFilters } = useSelector(({ gantt }) => gantt?.instances[PROJECT_GANTT_CONFIG_KEY]);
  const userId = useSelector(({ login }) => login?.userInfo?.sub);
  const { filter, sort, search } = useSelector(({ queries }) => queries?.filteredProjects || {});

  const printHandler = useCallback((selectedDate, timelineLength, paperSize) => {
    const startDate = moment(selectedDate)
      .clone()
      .startOf(MONTH)
      .format(DATE_INPUT_FORMAT);

    const timeMultiplier = TIME_MULTIPLIERS_MAP[timelineLength];
    const timeInterval = TIME_INTERVAL_UNIT_MAP[timelineLength];

    const endDate = moment(selectedDate)
      .clone()
      .add(timeMultiplier, timeInterval)
      .subtract(1, 'day')
      .format(DATE_INPUT_FORMAT);

    const width = getPaperWidth(paperSize);

    const reportArgs = {
      startDate,
      endDate,
      timeInterval,
      timeMultiplier,
      viewRoles,
      roleSortOption,
      roleFilters,
      includeAllocPerc,
      width,
      pagesPerWindow: timelineLength === THREE_YEARS ? 3 : 1,
      filter,
      sort,
      search,
    };

    const reportArgsStorageKey = getStorageKey(activeView, accountId, PROJECT_GANTT_REPORT_ARGS_KEY, userId);
    localStorage.setItem(reportArgsStorageKey, JSON.stringify(reportArgs));

    dispatch(trackAnalytics(REPORTS_EXPORT_PROJECT_GANTT, {
      'Earliest month displayed': moment(startDate).format('MMM, YYYY'),
      'Interval selected': INTERVALS_MAP[timelineLength],
      'Page size selected': paperSize,
      'Filters applied': filter.args.map(({ label }) => label).join(', '),
      'Sort applied': sort.args?.[0]?.label,
      'Search applied': search?.args?.[0]?.activeFilters?.[0] || '',
      ...getProjectGanttReportAnalyticsPayload(reportArgs),
    }));

    const url = `${window.location.origin}/accounts/${accountId}/reports/project/gantt`;
    window.open(url, null, 'noopener');
    handleClose();
  }, [
    accountId,
    activeView,
    filter,
    handleClose,
    roleFilters,
    roleSortOption,
    search,
    sort,
    userId,
    viewRoles,
    includeAllocPerc,
    dispatch,
  ]);

  const additionalOptionsHandler = useCallback((key, checked) => setIncludeAllocPerc(checked), []);

  const renderAdditionalOptions = useCallback(() => (
    <CheckboxForm
      className="allocation-percent-option"
      controls={ADDITIONAL_REPORT_OPTIONS}
      onChange={additionalOptionsHandler}
      isSwitch
      pending={!viewRoles}
    />
  ), [additionalOptionsHandler, viewRoles]);

  return (
    <div className="reports-project-gantt-file-export">
      <DefaultGanttFileExportMenu
        menuTitle={menuTitle}
        onClose={handleClose}
        onPrint={printHandler}
        initialTimeInterval={timeInterval}
        renderAdditionalOptions={renderAdditionalOptions}
      />
    </div>
  );
};

ProjectGanttFileExportMenu.propTypes = {
  menuTitle: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ProjectGanttFileExportMenu;
