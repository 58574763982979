import React, { useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Notes, Loader } from '@bridgit/foundation';
import { PERM_WRITE, PERM_PRIVATE, PERM_ACCOUNT } from 'src/features/permissions/utils/constants';
import { parseNotes } from 'src/utils/noteUtils';
import { MAX_NOTE_LENGTH, MAX_NOTES, PRIVATE_MODE_TOOLTIP_TEXT } from '../../common/constants';
import { addPersonNote, updatePersonNote, deletePersonNote } from './redux/actions';
import { isAuthorized } from '../permissions/utils/permissionUtils';
import { getUsers } from '../accounts/redux/actions';
import { formatMentionDisplay } from '../../utils/mentionUtils';

export const PersonNotesContainer = ({
  headerLabel,
  className,
  personId,
  personName,
  personNotes,
}) => {
  const dispatch = useDispatch();

  const commonAnalyticsPayload = useMemo(() => ({
    personName,
    personId,
  }), [personId, personName]);

  const {
    accountId,
    userInfo,
    addPersonNotePending,
    updatePersonNotePending,
    privateModeEnabled,
  } = useSelector(({ common, login, people }) => {
    const { accountId, privateModeEnabled } = common;
    const { userInfo } = login;
    const { addPersonNotePending, updatePersonNotePending } = people;

    return {
      accountId,
      userInfo,
      addPersonNotePending,
      updatePersonNotePending,
      privateModeEnabled,
    };
  });

  const { users, getUsersPending } = useSelector(({ accounts }) => accounts);

  useEffect(() => {
    dispatch(getUsers(accountId));
  }, [accountId, dispatch]);

  const hasPrivateNotePerms = useMemo(() => (
    isAuthorized(accountId, userInfo.permissions, PERM_WRITE, PERM_PRIVATE)
  ), [accountId, userInfo.permissions]);

  const hasAccountPerms = useMemo(() => (
    isAuthorized(accountId, userInfo.permissions, PERM_WRITE, PERM_ACCOUNT)
  ), [accountId, userInfo.permissions]);

  const parsedNotes = useMemo(() => (
    parseNotes(personNotes, parseInt(userInfo.sub, 10), hasAccountPerms)
  ), [hasAccountPerms, personNotes, userInfo.sub]);

  const onCreate = useCallback((data) => {
    dispatch(addPersonNote(accountId, personId, commonAnalyticsPayload, data));
  }, [dispatch, accountId, personId, commonAnalyticsPayload]);

  const onUpdate = useCallback((noteId, data) => {
    dispatch(updatePersonNote(accountId, personId, noteId, commonAnalyticsPayload, data));
  }, [dispatch, accountId, personId, commonAnalyticsPayload]);

  const onDelete = useCallback((id, userIsAuthor) => {
    const analyticsPayload = {
      ...commonAnalyticsPayload,
      isOwn: userIsAuthor,
    };
    dispatch(deletePersonNote(accountId, personId, id, analyticsPayload));
  }, [commonAnalyticsPayload, dispatch, accountId, personId]);

  const renderMentionHandler = useCallback(mention => formatMentionDisplay(
    mention,
    users,
    accountId,
    userInfo?.sub,
  ), [accountId, userInfo?.sub, users]);

  if (getUsersPending) return <Loader />;

  return (
    <div className={classNames('people-person-notes-container', className)}>
      <Notes
        headerLabel={headerLabel}
        notes={parsedNotes}
        onCreate={onCreate}
        onUpdate={onUpdate}
        onDelete={onDelete}
        createPending={addPersonNotePending}
        updatePending={updatePersonNotePending}
        maxLength={MAX_NOTE_LENGTH}
        showPrivacyToggle={hasPrivateNotePerms}
        emptyMessage="There are no notes for this person."
        limit={MAX_NOTES}
        showEditor
        privateModeEnabled={privateModeEnabled}
        privacyLabel="Hidden note"
        privacyTooltip={PRIVATE_MODE_TOOLTIP_TEXT}
        renderMention={renderMentionHandler}
      />
    </div>
  );
};

PersonNotesContainer.propTypes = {
  headerLabel: PropTypes.string,
  className: PropTypes.string,
  personId: PropTypes.number.isRequired,
  personName: PropTypes.string.isRequired,
  personNotes: PropTypes.array,
};

PersonNotesContainer.defaultProps = {
  headerLabel: '',
  className: '',
  personNotes: [],
};

export default PersonNotesContainer;
