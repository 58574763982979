import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isAuthorized, hasModuleEnabled } from 'src/features/permissions/utils/permissionUtils';

export class Can extends PureComponent {
  static propTypes = {
    accountId: PropTypes.number,
    permissions: PropTypes.object,
    accountModules: PropTypes.arrayOf(PropTypes.object).isRequired,
    action: PropTypes.string,
    subject: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    module: PropTypes.string,
    component: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    yes: PropTypes.node,
    no: PropTypes.node,
    checkBridgitPerms: PropTypes.bool,
    inclusive: PropTypes.bool,
  };

  static defaultProps = {
    accountId: null,
    permissions: null,
    action: null,
    subject: null,
    module: null,
    component: null,
    yes: null,
    no: null,
    checkBridgitPerms: false,
    inclusive: false,
  }

  render() {
    const { accountId, permissions, accountModules, action, subject, module, component, yes, no, checkBridgitPerms, inclusive } = this.props;
    const hasMod = hasModuleEnabled(accountModules, module, component);

    // If no permissions passed, check the module if it is not null
    if (!permissions || !action || !subject) {
      return module && hasMod ? yes : no;
    }

    const idToCheck = checkBridgitPerms ? '*' : accountId;
    const isAuth = isAuthorized(idToCheck, permissions, action, subject, inclusive);
    return isAuth && hasMod ? yes : no;
  }
}

/* istanbul ignore next */
function mapStateToProps({ common, login, accountSettings }) {
  const { accountId } = common;
  const { permissions } = login.userInfo;
  const { accountModules } = accountSettings;
  return {
    accountId,
    permissions,
    accountModules,
  };
}

export default connect(
  mapStateToProps,
  null,
)(Can);
