import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNT_SETTINGS_UPDATE_PROJECT_FIELDS_BEGIN,
  ACCOUNT_SETTINGS_UPDATE_PROJECT_FIELDS_SUCCESS,
  ACCOUNT_SETTINGS_UPDATE_PROJECT_FIELDS_FAILURE,
  ACCOUNT_SETTINGS_UPDATE_PROJECT_FIELDS_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';

export function updateProjectFields(accountId, data) {
  return {
    type: ACCOUNT_SETTINGS_UPDATE_PROJECT_FIELDS_BEGIN,
    accountId,
    data,
  };
}

export function dismissUpdateProjectFieldsError() {
  return {
    type: ACCOUNT_SETTINGS_UPDATE_PROJECT_FIELDS_DISMISS_ERROR,
  };
}

export function* doUpdateProjectFields(action) {
  const { accountId, data } = action;
  let res;
  try {
    res = yield call(protectedJsonPost, `accounts/${accountId}/project-fields`, data);
  } catch (err) {
    yield put({
      type: ACCOUNT_SETTINGS_UPDATE_PROJECT_FIELDS_FAILURE,
      data: { error: err },
    });
    return;
  }
  yield put({
    type: ACCOUNT_SETTINGS_UPDATE_PROJECT_FIELDS_SUCCESS,
    data: res,
  });
}

export function* watchUpdateProjectFields() {
  yield takeLatest(ACCOUNT_SETTINGS_UPDATE_PROJECT_FIELDS_BEGIN, doUpdateProjectFields);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNT_SETTINGS_UPDATE_PROJECT_FIELDS_BEGIN:
      return {
        ...state,
        updateProjectFieldsPending: true,
        updateProjectFieldsError: null,
      };

    case ACCOUNT_SETTINGS_UPDATE_PROJECT_FIELDS_SUCCESS: {
      const newProjectFields = state.projectFields.map((field) => {
        const newField = action.data.find(data => data.id === field.id);
        if (newField) {
          return {
            ...field,
            ...newField,
          };
        }
        return field;
      });

      return {
        ...state,
        projectFields: newProjectFields,
        updateProjectFieldsPending: false,
        updateProjectFieldsError: null,
      };
    }

    case ACCOUNT_SETTINGS_UPDATE_PROJECT_FIELDS_FAILURE:
      return {
        ...state,
        updateProjectFieldsPending: false,
        updateProjectFieldsError: action.data.error,
      };

    case ACCOUNT_SETTINGS_UPDATE_PROJECT_FIELDS_DISMISS_ERROR:
      return {
        ...state,
        updateProjectFieldsError: null,
      };

    default:
      return state;
  }
}
