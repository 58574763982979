import React, { useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import shortid from 'shortid';
import moment from 'moment';
import pluralize from 'pluralize';
import { SORT_STORAGE_KEY, getStorageKey } from 'src/common/localStorageKeys';
import { INVITED_USERS_QUERY_ID, ACTIVE_USERS_QUERY_ID } from 'src/features/queries/redux/constants';
import { DEFAULT_ACTIVE_USER_SORT } from 'src/features/accounts/redux/constants';
import {
  DATE_INPUT_FORMAT,
  DATE_DISPLAY_FORMAT,
  ACTIVE_USERS,
} from 'src/common/constants';
import {
  CircularProgress,
  Select,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import { Button, IconButton, Loader } from '@bridgit/foundation';
import { Replay, Close, KeyboardArrowDown } from '@material-ui/icons';
import { Confirm } from 'src/features/common';
import { ValidatedForm } from 'src/features/wrapped-components';
import { ManagedModal } from 'src/features/modal-manager';
import { openModal, closeModal } from 'src/features/modal-manager/redux/actions';
import { validateEmail } from 'src/utils/validators';
import { getPermissionGroups } from 'src/features/permissions/redux/actions';
import { naturalSort } from 'src/utils/sortUtils';
import { invitationInputs, INVITE_USER_MODAL } from './common/constants';
import {
  getUsers,
  getInvitations,
  removeInvitation,
  inviteUser,
  reinviteUser,
  updateUserPermissions,
  removeUser,
} from './redux/actions';
import { Table, SortableTableCell } from '../table';
import { sortTable, getInitialSort } from '../table/filterUtils';
import { setSortQuery } from '../queries/redux/actions';
import { AUTOMATION_CONFIRM_INVITE_BUTTON } from './ids';

function AccountUserList({
  accountId,
  accounts,
  userEmail,
  userSub,
  queries,
  invitedUsersQueries,
  permissionGroups,
  loading,
  updateUserPermissionsPending,
  getUsers,
  getInvitations,
  removeInvitation,
  inviteUser,
  reinviteUser,
  updateUserPermissions,
  removeUser,
  setSortQuery,
  openModal,
  closeModal,
  getPermissionGroups,
}) {
  const [sent, setSent] = useState(null);
  const [confirmRemove, setConfirmRemove] = useState(null);
  const [inviteUserData, setInviteUserData] = useState(null);
  const [disableSend, setDisableSend] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const sortStorageKey = useMemo(() => getStorageKey(ACTIVE_USERS, accountId, SORT_STORAGE_KEY, userSub), [accountId, userSub]);

  useEffect(() => {
    getUsers(accountId);
    getInvitations(accountId);
    getPermissionGroups(accountId);
  }, [getUsers, getInvitations, getPermissionGroups, accountId]);

  useEffect(() => {
    if (!accounts.inviteUserPending) closeModal(INVITE_USER_MODAL);
  }, [closeModal, accounts.inviteUserPending]);

  useEffect(() => {
    const currentSort = getInitialSort(sortStorageKey, DEFAULT_ACTIVE_USER_SORT);
    setSortQuery(ACTIVE_USERS_QUERY_ID, currentSort, sortStorageKey);
  }, [setSortQuery, sortStorageKey]);

  const invitationColumns = useMemo(() => {
    const onRemoveInvitation = (invitationId, email, group) => () => {
      const analytics = { email, group };
      removeInvitation(accountId, invitationId, analytics);
    };

    const resendInvite = (email, group) => () => {
      setSent(email);
      setTimeout(() => setSent(null), 2000);
      reinviteUser(accountId, email, group);
    };

    return [
      {
        name: 'Invitation status',
        type: 'Text',
        options: {
          sort: false,
          customBodyRender: (value, rowMeta) => (
            <div className={`status ${value}`}>
              <div>{value}</div>
              {sent !== rowMeta.email ? (
                <Tooltip title="Re-send invitation" placement="top">
                  <IconButton
                    size="small"
                    color="primary"
                    variant="plain"
                    className="resend-invite-button"
                    ariaLabel="Re-send invitation"
                    onClick={resendInvite(rowMeta.email, rowMeta.group)}
                  >
                    <Replay />
                  </IconButton>
                </Tooltip>
              ) : (
                <span className="uppercase">Sent</span>
              )}
            </div>
          ),
        },
      },
      {
        name: 'Email',
        type: 'Text',
        options: { sort: false },
      },
      {
        name: 'Permission group',
        type: 'Text',
        options: { sort: false },
      },
      {
        name: 'Invitation sent',
        type: 'Date',
        options: {
          sort: false,
          customBodyRender: value => (value ? moment(value, DATE_INPUT_FORMAT).format(DATE_DISPLAY_FORMAT) : ''),
        },
      },
      {
        name: '',
        options: {
          sort: false,
          customBodyRender: (value, rowMeta) => (
            <Tooltip title="Delete" placement="top">
              <IconButton
                size="small"
                color="warning"
                variant="plain"
                className="remove-button"
                ariaLabel="Delete invitation"
                onClick={onRemoveInvitation(value, rowMeta.email, rowMeta.group)}
              >
                <Close />
              </IconButton>
            </Tooltip>
          ),
        },
      },
    ];
  }, [accountId, reinviteUser, removeInvitation, sent]);

  const invitationData = useMemo(() => {
    if (accounts.invitations[accountId]) {
      return naturalSort(accounts.invitations[accountId], 'email').map((user) => {
        const rowData = [
          user.status,
          user.email,
          user.group,
          user.createdOn,
          user.id,
        ];
        return {
          rowMeta: user,
          rowData,
          rowId: shortid.generate(),
        };
      });
    }
    return [];
  }, [accounts.invitations, accountId]);

  const activeColumns = useMemo(() => {
    const openConfirm = user => () => setConfirmRemove(user);
    const handleChange = user => (group) => {
      setEditingUser(user);
      const { id, email, group: userGroup } = user;
      const analytics = { email, userGroup };
      updateUserPermissions(
        accountId,
        id,
        group.target.value,
        analytics,
      );
    };

    return [
      {
        name: 'Name and Title',
        type: 'Text',
        options: {
          customBodyRender: (value, rowMeta) => (
            <div className="name-and-title">
              {value}
              {rowMeta.title && <p>{rowMeta.title}</p>}
            </div>
          ),
        },
      },
      {
        name: 'Email',
        type: 'Text',
        options: { sort: false },
      },
      {
        name: 'Permission group',
        type: 'Text',
        options: {
          customBodyRender: (value, rowMeta) => {
            if (rowMeta.email === userEmail) return value;
            if (editingUser && editingUser.id === rowMeta.id && updateUserPermissionsPending) {
              return (
                <div className="perm-spinner">
                  <CircularProgress className="perm-spinner" size={16} color="primary" />
                </div>
              );
            }
            return (
              <Select
                className="group-dropdown"
                value={value}
                onChange={handleChange(rowMeta)}
                IconComponent={KeyboardArrowDown}
              >
                {naturalSort(permissionGroups, 'name').map(group => (
                  <MenuItem key={group.id} value={group.name}>{group.name}</MenuItem>
                ))}
              </Select>
            );
          },
        },
      },
      {
        name: 'Account creation',
        type: 'Date',
        options: {
          sort: false,
          customBodyRender: value => (value ? moment(value, DATE_INPUT_FORMAT).format(DATE_DISPLAY_FORMAT) : ''),
        },
      },
      {
        name: '',
        options: {
          sort: false,
          customBodyRender: (value, rowMeta) => {
            if (rowMeta.email === userEmail) return '';
            return (
              <Tooltip title="Delete" placement="top">
                <IconButton
                  size="small"
                  color="warning"
                  variant="plain"
                  className="remove-button"
                  ariaLabel="Delete user"
                  onClick={openConfirm(rowMeta)}
                >
                  <Close />
                </IconButton>
              </Tooltip>
            );
          },
        },
      },
    ];
  }, [
    accountId,
    userEmail,
    updateUserPermissions,
    permissionGroups,
    editingUser,
    updateUserPermissionsPending,
  ]);

  const activeData = useMemo(() => {
    if (accounts.users[accountId]) {
      const activeUsers = accounts.users[accountId].filter(user => user.state === 'Enabled');
      const mappedUsers = activeUsers.map((user) => {
        const rowData = [
          user.name,
          user.email,
          user.group,
          user.createdOn,
          user.id,
        ];
        return {
          rowMeta: {
            ...user,
            rawData: {
              'Name and Title': user.name,
              'Permission group': user.group,
            },
          },
          rowData,
          rowId: shortid.generate(),
        };
      });

      return sortTable(queries.sort, mappedUsers);
    }
    return [];
  }, [accounts.users, accountId, queries.sort]);

  const onInviteUser = () => {
    const { email, permissionGroup } = inviteUserData;
    inviteUser(accountId, email, permissionGroup);
  };

  const openInviteModal = () => openModal(INVITE_USER_MODAL);

  const onRemoveConfirmed = () => {
    removeUser(accountId, confirmRemove.id);
    setConfirmRemove(null);
  };

  const onRemoveCanceled = () => setConfirmRemove(null);

  const validate = (values) => {
    const { email, permissionGroup } = values;
    const errors = {};

    if (!validateEmail(email)) {
      errors.email = 'Please enter a valid email address';
    }

    const existingUser = accounts.users[accountId].find(user => user.email === email);
    const existingInvite = accounts.invitations[accountId].find(user => user.email === email);

    if (existingUser && existingUser.state === 'Enabled') {
      errors.email = 'This email is already active in this account';
    }

    if (existingInvite) {
      errors.email = 'This email has already been invited to this account.';
    }

    if (!permissionGroup) {
      errors.permissionGroup = 'This field is required';
    }

    setDisableSend(!!(errors.email || errors.permissionGroup));
    return errors;
  };

  const onValueChanged = (input, values) => setInviteUserData(values);

  const renderInvitedColumn = useCallback((column) => {
    const onInvitedUserSort = query => setSortQuery(INVITED_USERS_QUERY_ID, query);

    return (
      <SortableTableCell
        key={`${column.name}${column?.schemaName || ''}`}
        classes={{ root: 'table-table-header-cell', cellTitle: 'table-head-title' }}
        column={column}
        onClick={onInvitedUserSort}
        sortQuery={invitedUsersQueries?.sort?.args?.[0]}
      />
    );
  }, [setSortQuery, invitedUsersQueries]);

  const renderActiveColumn = useCallback((column) => {
    const onActiveUserSort = query => setSortQuery(ACTIVE_USERS_QUERY_ID, query, sortStorageKey);

    return (
      <SortableTableCell
        key={`${column.name}${column?.schemaName || ''}`}
        classes={{ root: 'table-table-header-cell', cellTitle: 'table-head-title' }}
        column={column}
        onClick={onActiveUserSort}
        sortQuery={queries?.sort?.args?.[0]}
      />
    );
  }, [sortStorageKey, queries, setSortQuery]);

  if (loading) {
    return (
      <div className="accounts-account-user-list">
        <Loader className="transparent-loader" />
      </div>
    );
  }

  return (
    <div className="accounts-account-user-list">
      <div className="users invited-users">
        <div className="header">Invited Users</div>
        <Button
          color="primary"
          variant="contained"
          className="invite-button"
          onClick={openInviteModal}
        >
          Invite user
        </Button>
        <Table
          data={invitationData}
          columns={invitationColumns}
          renderColumn={renderInvitedColumn}
          rowHover={false}
          noMatch="There are no pending invitations for the account"
        />
      </div>
      <div className="users active-users">
        <div className="header">Active Users</div>
        <div className="user-count">{`${activeData.length} ${pluralize('User', activeData.length)}`}</div>
        <Table
          data={activeData}
          columns={activeColumns}
          renderColumn={renderActiveColumn}
          rowHover={false}
          noMatch="There are no users in this account"
        />
      </div>

      {confirmRemove && (
        <Confirm
          headline="Delete User"
          acceptButtonText="Delete User"
          cancelButtonText="Cancel"
          onCancel={onRemoveCanceled}
          onAccept={onRemoveConfirmed}
        >
          <div>
            <p>Are you sure you want to delete this user?</p>
            <p>Once deleted, they will no longer be able to log in.</p>
          </div>
        </Confirm>
      )}

      <ManagedModal
        className="accounts-account-user-list-invite-modal"
        modalId={INVITE_USER_MODAL}
        headline="Invite User"
        pending={accounts.inviteUserPending}
      >
        <div className="invite-user-container">
          <ValidatedForm
            inputs={invitationInputs(naturalSort(permissionGroups, 'name'))}
            validate={validate}
            onValueChanged={onValueChanged}
          />
          <div className="invite-confirm-container">
            <Button
              id={AUTOMATION_CONFIRM_INVITE_BUTTON}
              color="primary"
              variant="plain"
              onClick={onInviteUser}
              disabled={disableSend}
            >
              Send
            </Button>
          </div>
        </div>
      </ManagedModal>
    </div>
  );
}

AccountUserList.propTypes = {
  accountId: PropTypes.number.isRequired,
  accounts: PropTypes.object.isRequired,
  userEmail: PropTypes.string,
  userSub: PropTypes.string,
  queries: PropTypes.object,
  invitedUsersQueries: PropTypes.object,
  permissionGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  updateUserPermissionsPending: PropTypes.bool,
  getUsers: PropTypes.func.isRequired,
  getInvitations: PropTypes.func.isRequired,
  removeInvitation: PropTypes.func.isRequired,
  inviteUser: PropTypes.func.isRequired,
  reinviteUser: PropTypes.func.isRequired,
  updateUserPermissions: PropTypes.func.isRequired,
  removeUser: PropTypes.func.isRequired,
  setSortQuery: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  getPermissionGroups: PropTypes.func.isRequired,
};

AccountUserList.defaultProps = {
  queries: {},
  invitedUsersQueries: {},
  loading: false,
  updateUserPermissionsPending: false,
  userEmail: null,
  userSub: null,
};

/* istanbul ignore next */
function mapStateToProps({ accounts, common, queries, permissions, login }) {
  const { accountId } = common;
  const { userInfo = {} } = login;
  const { email, sub } = userInfo;
  const { permissionGroups } = permissions;
  const { getUsersPending, getInvitationsPending, updateUserPermissionsPending } = accounts;
  const loading = getUsersPending || getInvitationsPending;

  return {
    accounts,
    accountId,
    userEmail: email,
    userSub: sub,
    queries: queries[ACTIVE_USERS_QUERY_ID],
    invitedUsersQueries: queries[INVITED_USERS_QUERY_ID],
    permissionGroups,
    loading,
    updateUserPermissionsPending,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    getUsers: bindActionCreators(getUsers, dispatch),
    getInvitations: bindActionCreators(getInvitations, dispatch),
    removeInvitation: bindActionCreators(removeInvitation, dispatch),
    inviteUser: bindActionCreators(inviteUser, dispatch),
    reinviteUser: bindActionCreators(reinviteUser, dispatch),
    updateUserPermissions: bindActionCreators(updateUserPermissions, dispatch),
    removeUser: bindActionCreators(removeUser, dispatch),
    setSortQuery: bindActionCreators(setSortQuery, dispatch),
    openModal: bindActionCreators(openModal, dispatch),
    closeModal: bindActionCreators(closeModal, dispatch),
    getPermissionGroups: bindActionCreators(getPermissionGroups, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountUserList);
