import {
  REPORTS_SET_IMAGE_PRELOAD_PENDING,
} from './constants';

export function setImagePreloadPending(imagePreloadPending) {
  return {
    type: REPORTS_SET_IMAGE_PRELOAD_PENDING,
    imagePreloadPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case REPORTS_SET_IMAGE_PRELOAD_PENDING: {
      const { imagePreloadPending } = action;
      return {
        ...state,
        imagePreloadPending,
      };
    }

    default:
      return state;
  }
}
