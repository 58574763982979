import { put, takeLatest, all, select } from 'redux-saga/effects';
import { getPeopleListQuery } from 'src/features/people/queries/getPeopleListQuery';
import deactivatedAvatar from 'src/images/deactivated_avatar.svg?url';
import emptyAvatar from 'src/images/empty_avatar.svg?url';
import {
  REPORTS_COLLECT_PEOPLE_DATA_BEGIN,
  REPORTS_COLLECT_PEOPLE_DATA_SUCCESS,
  REPORTS_COLLECT_PEOPLE_DATA_FAILURE,
  REPORTS_COLLECT_PEOPLE_DATA_DISMISS_ERROR,
} from './constants';
import { MIN_FROM, MAX_SIZE } from '../../../common/constants';
import { dateStringAdd } from '../../../utils/dateUtils';
import { resolveImage } from '../utils/reportUtils';
import { peoplePhotos } from '../../people/redux/selectors';
import { doGetReportPeople } from './getReportPeople';
import { doGetPeopleAllocations } from './getPeopleAllocations';
import { doGetPeopleGanttAllocations } from './getPeopleGanttAllocations';
import { doGetAccountCertifications } from '../../account-settings/redux/getAccountCertifications';

export function collectPeopleData(accountId, queries, settings) {
  return {
    type: REPORTS_COLLECT_PEOPLE_DATA_BEGIN,
    queries,
    settings,
    accountId,
  };
}

export function dismissCollectPeopleDataError() {
  return {
    type: REPORTS_COLLECT_PEOPLE_DATA_DISMISS_ERROR,
  };
}

export function* doCollectPeopleData(action) {
  const { queries, accountId, settings } = action;
  const {
    includeAllocations,
    includeGanttAllocations,
    includeCertifications,
    earliestDate,
    timelineLength,
    preloadPhotos,
  } = settings;

  const endDate = earliestDate ? dateStringAdd(earliestDate, timelineLength - 1, 'years') : undefined;
  const { query, variables } = getPeopleListQuery(accountId, queries, MIN_FROM, MAX_SIZE, 'Active', earliestDate, endDate);

  try {
    const requests = [doGetReportPeople({ query, variables, settings, accountId })];

    if (includeAllocations) {
      requests.push(doGetPeopleAllocations({ accountId }));
    }

    if (includeGanttAllocations) {
      requests.push(doGetPeopleGanttAllocations({ accountId, start: earliestDate, end: endDate }));
    }

    if (includeCertifications) {
      requests.push(doGetAccountCertifications({ accountId }));
    }

    if (preloadPhotos) {
      const peoplePhotoUrls = yield select(peoplePhotos);
      peoplePhotoUrls.forEach(url => requests.push(resolveImage(url)));
      requests.push(resolveImage(deactivatedAvatar));
      requests.push(resolveImage(emptyAvatar));
    }

    yield all(requests);
  } catch (err) {
    yield put({
      type: REPORTS_COLLECT_PEOPLE_DATA_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: REPORTS_COLLECT_PEOPLE_DATA_SUCCESS,
    settings,
  });
}

export function* watchCollectPeopleData() {
  yield takeLatest(REPORTS_COLLECT_PEOPLE_DATA_BEGIN, doCollectPeopleData);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case REPORTS_COLLECT_PEOPLE_DATA_BEGIN:
      return {
        ...state,
        collectPeopleDataPending: true,
        collectPeopleDataError: null,
      };

    case REPORTS_COLLECT_PEOPLE_DATA_SUCCESS: {
      const { settings } = action;
      const { settings: previousSettings } = state;
      const { type } = settings;

      return {
        ...state,
        showReport: true,
        type,
        settings: {
          ...previousSettings,
          ...settings,
        },
        collectPeopleDataPending: false,
        collectPeopleDataError: null,
      };
    }

    case REPORTS_COLLECT_PEOPLE_DATA_FAILURE:
      return {
        ...state,
        collectPeopleDataPending: false,
        collectPeopleDataError: action.data.error,
      };

    case REPORTS_COLLECT_PEOPLE_DATA_DISMISS_ERROR:
      return {
        ...state,
        collectPeopleDataError: null,
      };

    default:
      return state;
  }
}
