import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { ManagedModal } from '../modal-manager';
import { EditControls } from '../wrapped-components';
import { deleteServiceAccount } from './redux/actions';
import { closeModal } from '../modal-manager/redux/closeModal';
import { DELETE_SERVICE_ACCOUNT_MODAL } from './constants';

const DeleteServiceAccountModal = ({ onAccountDeleted }) => {
  const dispatch = useDispatch();

  const { accountId } = useSelector(({ common }) => common);
  const { deleteServiceAccountPending, account } = useSelector(({ serviceAccounts }) => serviceAccounts);

  const handleCloseDeleteServiceAccountModal = useCallback(() => {
    dispatch(closeModal(DELETE_SERVICE_ACCOUNT_MODAL));
  }, [dispatch]);

  const handleDeleteServiceAccount = useCallback(() => {
    const { name, id } = account;
    const analyticsPayload = { name };

    dispatch(deleteServiceAccount(accountId, id, analyticsPayload));

    handleCloseDeleteServiceAccountModal();

    onAccountDeleted();
  }, [account, dispatch, accountId, handleCloseDeleteServiceAccountModal, onAccountDeleted]);


  return (
    <ManagedModal
      className="service-accounts-delete-account-modal"
      modalId={DELETE_SERVICE_ACCOUNT_MODAL}
      headline="Delete Service Account"
      showClose={false}
    >
      <div className="delete-service-account-container">
        <p>Are you sure want to delete this service account?</p>
        <p>This service account can not be restored after this action is taken.</p>

        <EditControls
          primaryText="Delete"
          secondaryText="Cancel"
          primaryAction={handleDeleteServiceAccount}
          secondaryAction={handleCloseDeleteServiceAccountModal}
          pending={deleteServiceAccountPending}
          className="service-accounts-form-controls"
          variant="contained"
        />
      </div>
    </ManagedModal>
  );
};

DeleteServiceAccountModal.propTypes = {
  onAccountDeleted: PropTypes.func.isRequired,
};

export default DeleteServiceAccountModal;
