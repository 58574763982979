const filterArrayByProp = (inputArray, exclude) => {
  const lowerCaseExclude = exclude.map(item => ({
    ...item,
    value: item.value.toLowerCase(),
  }));

  return inputArray.filter((item) => {
    const index = lowerCaseExclude.findIndex((excludeItem) => {
      if (!item[excludeItem.propName]) return false;
      const itemValue = item[excludeItem.propName].toLowerCase();
      return excludeItem.value === itemValue;
    });
    return index < 0;
  });
};

const getConditionalArray = (value) => {
  if (value === null || typeof value === 'undefined') return [];

  if (Array.isArray(value)) return [...value];

  return [value];
};

const moveObjToStartOfArray = (arr = [], key = null, searchValue = null) => {
  const index = arr.findIndex(elem => elem[key] === searchValue);
  if (index === -1) return;
  arr.unshift(arr.splice(index, 1)[0]);
};

export {
  filterArrayByProp,
  getConditionalArray,
  moveObjToStartOfArray,
};
