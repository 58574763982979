import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { ProjectLink } from '@bridgit/foundation';

import { setSelectedProjectId } from './redux/actions';
import { openModal } from '../modal-manager/redux/actions';
import {
  MULTI_STATE_MODAL_ID,
  COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_ALLOCATE_PERSON,
  COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PROJECT,
  COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PERSON,
} from './redux/constants';
import {
  PROJECTS_PROJECT_SELECTION_BACKUP,
  PROJECT_LIST_SELECTION_ID,
} from '../projects/redux/constants';
import { PROFILE_DETAILS_MODAL_CONTEXT } from '../people/constants';

export const ProjectLinkToModalContainer = ({
  projectId,
  backgroundColor,
  projectName,
  onClick,
  launchedFrom,
  runsInModal,
}) => {
  const dispatch = useDispatch();

  const {
    activeModal,
    modalActiveView,
    projectListSelection,
    projectSelectionBackup,
  } = useSelector(({ common, projects, modalManager }) => {
    const { activeModal } = modalManager;
    const { multiStateModal } = common;
    const { projectSelections } = projects;
    const { activeView: modalActiveView } = multiStateModal;
    const projectListSelection = projectSelections[PROJECT_LIST_SELECTION_ID];
    const projectSelectionBackup = projectSelections[PROJECTS_PROJECT_SELECTION_BACKUP];

    return {
      activeModal,
      modalActiveView,
      projectListSelection,
      projectSelectionBackup,
    };
  }, shallowEqual);

  const isAllocationModal = useMemo(() => (
    modalActiveView === COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_ALLOCATE_PERSON
  ), [modalActiveView]);

  const showBackButton = useMemo(() => {
    let showBackButton = false;
    if (!projectSelectionBackup && projectListSelection?.id) {
      showBackButton = projectListSelection.id !== projectId && isAllocationModal;
    } else if (projectSelectionBackup?.id) {
      showBackButton = projectSelectionBackup.id !== projectId && isAllocationModal;
    }
    return showBackButton;
  }, [isAllocationModal, projectId, projectSelectionBackup, projectListSelection?.id]);

  const triggerMixpanelEvents = useCallback(() => {
    window.mixpanel.track('Project Details Modal Displayed', {
      'Project Name': projectName,
      'Project ID': projectId,
      'Launched from': launchedFrom,
    });

    if (launchedFrom === 'Dashboard - Unfilled Roles') {
      window.mixpanel.track('Unfilled Roles - Click Project Name', {
        'Project Name': projectName,
        'Project ID': projectId,
      });
    }
  }, [projectId, projectName, launchedFrom]);

  const mixpanelModalData = useMemo(() => {
    const to = 'Project Details Modal';
    const from = modalActiveView === COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PERSON
      ? PROFILE_DETAILS_MODAL_CONTEXT
      : 'Place a Person Modal';

    const analyticsPayload = {
      runsInModal,
      data: {
        projectName,
        projectId,
        from,
        to,
      },
    };
    return analyticsPayload;
  }, [modalActiveView, projectName, projectId, runsInModal]);

  const onClickWrapper = useCallback((project) => {
    const analyticsPayload = mixpanelModalData;
    dispatch(setSelectedProjectId(projectId, COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PROJECT, null, false, showBackButton, null, analyticsPayload));

    if (!activeModal || activeModal !== MULTI_STATE_MODAL_ID) {
      dispatch(openModal(MULTI_STATE_MODAL_ID));
    }

    if (onClick) {
      onClick(project);
    }
    triggerMixpanelEvents();
  }, [onClick, dispatch, projectId, showBackButton, activeModal, triggerMixpanelEvents, mixpanelModalData]);

  return (
    <ProjectLink
      projectId={projectId}
      backgroundColor={backgroundColor}
      projectName={projectName}
      onClick={onClickWrapper}
    />
  );
};

ProjectLinkToModalContainer.propTypes = {
  projectId: PropTypes.number.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  launchedFrom: PropTypes.string,
  runsInModal: PropTypes.bool,
};

ProjectLinkToModalContainer.defaultProps = {
  onClick: null,
  launchedFrom: 'Other',
  runsInModal: false,
};

export default ProjectLinkToModalContainer;
