import { PERM_READ, PERM_REQUEST, PERM_ASSIGNMENT } from 'src/features/permissions/utils/constants';
import { ACCOUNT_MODULE_HOURLY } from 'src/common/constants';
import { LoadableSelfPerform } from '.';

export default {
  path: 'accounts/:id/self-perform/:contentView?/:requestId?',
  name: 'Self perform',
  component: LoadableSelfPerform,
  action: PERM_READ,
  subject: [PERM_REQUEST, PERM_ASSIGNMENT],
  module: ACCOUNT_MODULE_HOURLY,
};
