import React from 'react';
import { LinearProgress } from '@material-ui/core';

function GanttPageLoading() {
  return (
    <div className="gantt-gantt-page-loading">
      <LinearProgress variant="query" className="progress" />
    </div>
  );
}

export default GanttPageLoading;
