export const getProjectPhasePayload = (state, { analyticsPayload: { project, parentName, phaseCount } }) => ({
  'Project name': project?.name,
  'Project id': project?.id,
  'Project status': project?.state,
  'Project type': project?.type,
  Location: parentName === 'Project Details' ? 'Edit Project' : parentName,
  'Number of phases added': phaseCount,
});

export const getUpdateProjectPhasePayload = (state, { analyticsPayload: { project, parentName, fieldEdited, updateType, editAction, offset } }) => ({
  'Project name': project?.name,
  'Project id': project?.id,
  'Project status': project?.state,
  'Project type': project?.type,
  Location: parentName === 'Project Details' ? 'Project Dates' : parentName,
  'Date edited': `${fieldEdited} date`,
  Type: updateType,
  Impact: editAction,
  '# of days offset': offset,
});

export const getUpdateProjectCausedRoleDeletePayload = (analyticsPayload) => {
  const { startDateUpdated, endDateUpdated } = analyticsPayload;

  return {
    'Start date updated': startDateUpdated,
    'End date updated': endDateUpdated,
    'Deletion cancelled': false,
    'Deletion confirmed': true,
  };
};

export const getDeleteProjectRolePayload = (analyticsPayload) => {
  const { projectName, projectId, projectStatus, projectType, hasAllocatedPeople, deletedFrom } = analyticsPayload;

  return {
    'Project Name': projectName,
    'Project ID': projectId,
    'Project status': projectStatus,
    'Project type': projectType,
    'Has Allocated People': hasAllocatedPeople,
    'Role deleted from': deletedFrom,
  };
};
