import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ScrollContainer from 'react-indiana-drag-scroll';
import {
  PeopleGanttTitleHeader,
  TimelineHeader,
  GanttPageLoading,
  PeopleGanttRow,
} from 'src/features/gantt';
import { GANTT_COLUMN_WIDTH, PEOPLE_GANTT_CONFIG_KEY } from 'src/features/gantt/redux/constants';
import { PeopleZoomConfig, getPeopleDateRanges } from '../utils/ganttUtils';

function PeopleGanttChart({
  onScroll,
  showModal,
  loadingPage,
  pagingError,
  columnHeaders,
  parsedPeople,
  todayPosition,
  scrollToPosition,
  zoomConfig,
}) {
  const [chartRef, setChartRef] = useState(null);
  const [currentScrollPos, setCurrentScrollPos] = useState(null);
  const dates = useMemo(() => getPeopleDateRanges(zoomConfig), [zoomConfig]);

  useEffect(() => {
    // We only want to scrollToToday on page load and when todayPosition changes
    // currentScrollPos allows us to track if we have already scrolled to today AND what the previous value was
    // Some dependencies were causing this hook to fire off even with no changes to todayPosition
    // Note - scrollToPosition tracks today AND date passed when redirecting from forecasting
    if (scrollToPosition && currentScrollPos !== scrollToPosition) {
      const monthWidth = columnHeaders.length * GANTT_COLUMN_WIDTH;
      if (chartRef?.scrollTo && scrollToPosition < monthWidth) {
        chartRef.scrollTo({ left: scrollToPosition - 20 });
        setCurrentScrollPos(scrollToPosition);
      }
    }
  }, [columnHeaders, scrollToPosition, chartRef, currentScrollPos]);

  if (parsedPeople.length === 0) {
    return <div className="gantt-people-gantt-chart chart no-results"><div>No results found</div></div>;
  }

  const monthWidth = columnHeaders.length * GANTT_COLUMN_WIDTH;
  const showToday = todayPosition >= 0 && todayPosition < monthWidth;

  const refHandler = (node) => {
    if (node?.container?.current) setChartRef(node.container.current);
  };

  return (
    <div className="gantt-people-gantt-chart chart" onScroll={onScroll}>
      <div className="chart-content-wrapper">
        <div className="blank-space">
          <PeopleGanttTitleHeader />
        </div>
        <ScrollContainer
          hideScrollbars={false}
          className="chart-content"
          ref={refHandler}
        >
          <TimelineHeader
            columnHeaders={columnHeaders}
            monthWidth={monthWidth}
            todayPosition={todayPosition}
          />
          <div className="content" style={{ width: `${monthWidth + GANTT_COLUMN_WIDTH}px` }}>
            {parsedPeople.map(person => <PeopleGanttRow key={person.id} person={person} showModal={showModal} dates={dates} zoomConfig={zoomConfig} />)}
            {showToday && <div className="today-line" style={{ left: `${todayPosition + GANTT_COLUMN_WIDTH - 1}px` }} />}
          </div>
          {(loadingPage || pagingError) && <GanttPageLoading />}
        </ScrollContainer>
      </div>
    </div>
  );
}

PeopleGanttChart.propTypes = {
  onScroll: PropTypes.func,
  showModal: PropTypes.func,
  loadingPage: PropTypes.bool,
  pagingError: PropTypes.bool,
  parsedPeople: PropTypes.array,
  todayPosition: PropTypes.number,
  scrollToPosition: PropTypes.number,
  columnHeaders: PropTypes.arrayOf(PropTypes.string),
  zoomConfig: PropTypes.object.isRequired,
};

PeopleGanttChart.defaultProps = {
  onScroll: () => {},
  showModal: () => {},
  loadingPage: false,
  pagingError: false,
  parsedPeople: [],
  todayPosition: null,
  scrollToPosition: null,
  columnHeaders: [],
};

/* istanbul ignore next */
function mapStateToProps({ people, gantt }) {
  const {
    getFilteredPeoplePagePending,
    getFilteredPeoplePageError,
  } = people;
  const {
    columnHeaders,
    parsedPeople,
    todayPosition,
    scrollToPosition,
    scaleValue,
  } = gantt.instances[PEOPLE_GANTT_CONFIG_KEY];

  return {
    loadingPage: getFilteredPeoplePagePending,
    pagingError: !!getFilteredPeoplePageError,
    columnHeaders,
    parsedPeople,
    todayPosition,
    scrollToPosition,
    zoomConfig: PeopleZoomConfig[scaleValue],
  };
}

export default connect(
  mapStateToProps,
)(PeopleGanttChart);
