import { call, put, takeLatest } from 'redux-saga/effects';
import {
  SELF_PERFORM_DEACTIVATE_HOURLY_PERSON_BEGIN,
  SELF_PERFORM_DEACTIVATE_HOURLY_PERSON_SUCCESS,
  SELF_PERFORM_DEACTIVATE_HOURLY_PERSON_FAILURE,
  SELF_PERFORM_DEACTIVATE_HOURLY_PERSON_DISMISS_ERROR,
} from './constants';
import { protectedJsonDelete } from '../../../utils/api';

export function deactivateHourlyPerson(accountId, hourlyPersonId, hourlyPersonName) {
  return {
    type: SELF_PERFORM_DEACTIVATE_HOURLY_PERSON_BEGIN,
    accountId,
    hourlyPersonId,
    hourlyPersonName,
  };
}

export function dismissDeactivateHourlyPersonError() {
  return {
    type: SELF_PERFORM_DEACTIVATE_HOURLY_PERSON_DISMISS_ERROR,
  };
}

export function* doDeactivateHourlyPerson(action) {
  const { accountId, hourlyPersonId, hourlyPersonName } = action;
  let res;
  try {
    res = yield call(protectedJsonDelete, `accounts/${accountId}/hourly-persons/${hourlyPersonId}`);
  } catch (err) {
    yield put({
      type: SELF_PERFORM_DEACTIVATE_HOURLY_PERSON_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: SELF_PERFORM_DEACTIVATE_HOURLY_PERSON_SUCCESS,
    data: res,
    hourlyPersonId,
    hourlyPersonName,
  });
}

export function* watchDeactivateHourlyPerson() {
  yield takeLatest(SELF_PERFORM_DEACTIVATE_HOURLY_PERSON_BEGIN, doDeactivateHourlyPerson);
}

export function reducer(state, action) {
  switch (action.type) {
    case SELF_PERFORM_DEACTIVATE_HOURLY_PERSON_BEGIN:
      return {
        ...state,
        deactivateHourlyPersonPending: true,
        deactivateHourlyPersonError: null,
      };

    case SELF_PERFORM_DEACTIVATE_HOURLY_PERSON_SUCCESS: {
      return {
        ...state,
        filteredHourlyPersons: state.filteredHourlyPersons.filter(({ id }) => id !== action.hourlyPersonId),
        selectedHourlyPerson: null,
        deactivateHourlyPersonPending: false,
        deactivateHourlyPersonError: null,
      };
    }

    case SELF_PERFORM_DEACTIVATE_HOURLY_PERSON_FAILURE:
      return {
        ...state,
        deactivateHourlyPersonPending: false,
        deactivateHourlyPersonError: action.data.error,
      };

    case SELF_PERFORM_DEACTIVATE_HOURLY_PERSON_DISMISS_ERROR:
      return {
        ...state,
        deactivateHourlyPersonError: null,
      };

    default:
      return state;
  }
}
