import {
  QUERIES_CLEAR_QUERIES,
} from './constants';

export function clearQueries(id) {
  return {
    type: QUERIES_CLEAR_QUERIES,
    id,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case QUERIES_CLEAR_QUERIES: {
      const { id } = action;
      const newState = { ...state };
      newState[id] = {};
      return newState;
    }

    default:
      return state;
  }
}
