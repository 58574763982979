import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { OutlinedInput as MaterialInput } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

export default class OutlinedInput extends PureComponent {
  static propTypes = {
    slim: PropTypes.bool,
    className: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultValue: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    inputProps: PropTypes.object,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyUp: PropTypes.func,
    fullWidth: PropTypes.bool,
    autoFocus: PropTypes.bool,
  };

  static defaultProps = {
    slim: false,
    className: '',
    name: undefined,
    value: undefined,
    defaultValue: undefined,
    readOnly: false,
    disabled: false,
    inputProps: undefined,
    placeholder: undefined,
    onChange: undefined,
    onBlur: undefined,
    onKeyUp: undefined,
    fullWidth: false,
    autoFocus: false,
  };

  getMuiTheme = (outerTheme) => {
    const { slim } = this.props;
    return {
      ...outerTheme,
      overrides: {
        MuiOutlinedInput: {
          root: {
            minWidth: '185px',
          },
          input: {
            paddingTop: slim ? '5px' : '10px',
            paddingBottom: slim ? '5px' : '10px',
          },
        },
      },
    };
  }

  render() {
    /*
      This component may receive props that are not explicitly defined by us when consumed by other
      Material components such as 'Select'. The 'rest' spread makes this much easier to maintain.
    */
    const {
      slim,
      className,
      name,
      value,
      defaultValue,
      readOnly,
      disabled,
      inputProps,
      placeholder,
      onChange,
      onBlur,
      onKeyUp,
      fullWidth,
      autoFocus,
      ...materialProps
    } = this.props;

    return readOnly
      ? (
        <div className={`wrapped-components-outlined-input no-input ${className}${disabled ? ' input-disabled' : ''}`}>
          {value || defaultValue || (!!inputProps && !!inputProps.children ? inputProps.children : '')}
        </div>
      )
      : (
        <MuiThemeProvider theme={this.getMuiTheme()}>
          {/* eslint-disable react/jsx-props-no-spreading */}
          <MaterialInput
            className={`wrapped-components-outlined-input ${className}`}
            value={value}
            name={name}
            autoFocus={autoFocus}
            defaultValue={defaultValue}
            disabled={disabled}
            fullWidth={fullWidth}
            inputProps={inputProps}
            labelWidth={0}
            notched={false}
            onBlur={onBlur}
            onChange={onChange}
            onKeyUp={onKeyUp}
            placeholder={placeholder}
            readOnly={readOnly}
            {...materialProps}
          />
        </MuiThemeProvider>
      );
  }
}
