import {
  HOURLY_COST_RATE,
  BLANK_FILTER,
  ABOVE_FILTER,
  BELOW_FILTER,
  BETWEEN_FILTER,
} from './constants';

const blankFilter = () => (
  {
    bool: {
      must_not: {
        bool: {
          must: {
            exists: {
              field: HOURLY_COST_RATE,
            },
          },
        },
      },
    },
  }
);

const queryFilter = (activeFilter) => {
  const filterQuery = {};
  const { selected, verb } = activeFilter;

  if (verb === ABOVE_FILTER) {
    filterQuery.range = {
      [HOURLY_COST_RATE]: { gt: selected[0].value },
    };
  } else if (verb === BELOW_FILTER) {
    filterQuery.range = {
      [HOURLY_COST_RATE]: { lt: selected[0].value },
    };
  } else if (verb === BETWEEN_FILTER) {
    filterQuery.range = {
      [HOURLY_COST_RATE]: { gte: Math.min(selected[0].value, selected[0].secondaryValue), lte: Math.max(selected[0].value, selected[0].secondaryValue) },
    };
  } else {
    filterQuery.match = {
      [HOURLY_COST_RATE]: selected[0].value,
    };
  }

  return {
    bool: {
      must: { ...filterQuery },
    },
  };
};

const generateCostRateFilter = (activeFilter) => {
  const { verb } = activeFilter;

  if (verb === BLANK_FILTER) {
    return blankFilter();
  }

  return queryFilter(activeFilter);
};

export default generateCostRateFilter;
