import { call, put, takeLatest } from 'redux-saga/effects';
import { PAGE_SIZE } from '../../../common/constants';
import graphApiClient from '../../../common/GraphAPIClient';
import { getFilteredRolesQuery } from '../queries/getFilteredRolesQuery';
import { parseRoleSegment } from '../utils/adaptors';
import {
  UNFILLED_ROLES_GET_FILTERED_ROLES_BEGIN,
  UNFILLED_ROLES_GET_FILTERED_ROLES_DISMISS_ERROR,
  UNFILLED_ROLES_GET_FILTERED_ROLES_FAILURE,
  UNFILLED_ROLES_GET_FILTERED_ROLES_SUCCESS,
} from './constants';
import { unfilledSegments } from './selectors';

export function getFilteredRoles({
  accountId,
  projectQueries,
  timeframeFilter,
  sortQuery,
  pageFrom,
  pageSize = PAGE_SIZE,
}) {
  const { query, variables } = getFilteredRolesQuery(
    accountId,
    projectQueries,
    timeframeFilter,
    sortQuery,
    pageFrom,
    pageSize,
  );

  return {
    type: UNFILLED_ROLES_GET_FILTERED_ROLES_BEGIN,
    query,
    variables,
  };
}

export function dismissGetFilteredRolesError() {
  return {
    type: UNFILLED_ROLES_GET_FILTERED_ROLES_DISMISS_ERROR,
  };
}

export function* doGetFilteredRoles(action) {
  const { query, variables } = action;
  let res;

  try {
    res = yield call(graphApiClient.query, { query, variables });
    const { errors } = res;
    if (errors) {
      throw errors;
    }
  } catch (err) {
    yield put({
      type: UNFILLED_ROLES_GET_FILTERED_ROLES_FAILURE,
      data: { error: err },
      isPaging: variables.from !== 0,
    });
    return;
  }

  yield put({
    type: UNFILLED_ROLES_GET_FILTERED_ROLES_SUCCESS,
    data: res.data,
    clearData: variables.from === 0,
  });
}

export function* watchGetFilteredRoles() {
  yield takeLatest(UNFILLED_ROLES_GET_FILTERED_ROLES_BEGIN, doGetFilteredRoles);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case UNFILLED_ROLES_GET_FILTERED_ROLES_BEGIN: {
      const { variables } = action;
      const isPaging = variables.from > 0;

      return {
        ...state,
        getFilteredRolesError: null,
        getFilteredRolesPageError: null,
        getFilteredRolesPending: !isPaging,
        getFilteredRolesPagePending: isPaging,
      };
    }

    case UNFILLED_ROLES_GET_FILTERED_ROLES_SUCCESS: {
      const { clearData, data } = action;
      const { items, cursor: pageFrom, hasMore, count } = data?.roleSegments || {};
      const segments = items?.map(parseRoleSegment) || [];
      return {
        ...state,
        roleSegments: clearData ? segments : [...unfilledSegments(state), ...segments],
        pageFrom,
        hasMore,
        count,
        ...(clearData && {
          getFilteredRolesError: null,
          getFilteredRolesPending: false,
        }),
        ...(!clearData && {
          getFilteredRolesPageError: null,
          getFilteredRolesPagePending: false,
        }),
      };
    }

    case UNFILLED_ROLES_GET_FILTERED_ROLES_FAILURE:
      return {
        ...state,
        ...(action.isPaging && {
          getFilteredRolesPageError: action.data.error,
          getFilteredRolesPagePending: false,
        }),
        ...(!action.isPaging && {
          getFilteredRolesError: action.data.error,
          getFilteredRolesPending: false,
        }),
      };

    case UNFILLED_ROLES_GET_FILTERED_ROLES_DISMISS_ERROR:
      return {
        ...state,
        getFilteredRolesError: null,
        getFilteredRolesPageError: null,
      };

    default:
      return state;
  }
}
