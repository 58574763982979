import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import deepEqual from 'react-fast-compare';
import classNames from 'classnames';
import {
  Button,
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@material-ui/core';
import { Search, Clear } from '@material-ui/icons';
import { sortFilterOptions } from 'src/features/filters/utils/filterUtils';
import { CustomPopper } from '../wrapped-components';
import { setPopperIsOpen } from '../filters/redux/actions';
import { AUTOMATION_SELECT_ALL_CHECKBOX } from './ids';

class FilterPopper extends PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    anchorEl: PropTypes.object,
    title: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    sortItems: PropTypes.bool,
    selected: PropTypes.array.isRequired,
    filterKey: PropTypes.string.isRequired,
    onApply: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    showSearch: PropTypes.bool,
    showSelectAll: PropTypes.bool,
    singleFilter: PropTypes.bool,
    autoApply: PropTypes.bool,
    placeholder: PropTypes.string,
    setPopperIsOpen: PropTypes.func,
    placement: PropTypes.string,
  };

  static defaultProps = {
    anchorEl: null,
    showSearch: false,
    showSelectAll: true,
    singleFilter: false,
    autoApply: false,
    sortItems: true,
    placeholder: '',
    setPopperIsOpen: () => {},
    placement: 'bottom-start',
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedIds: props.selected,
      visibleIds: props.selected,
      visibleItems: props.sortItems ? sortFilterOptions(props.items, props.filterKey) : props.items,
      applyDisabled: true,
      searchString: '',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { items, filterKey, selected, open } = this.props;
    const stateUpdates = {};

    if (!deepEqual(items, nextProps.items) || filterKey !== nextProps.filterKey) {
      stateUpdates.visibleItems = nextProps.sortItems ? sortFilterOptions(nextProps.items, nextProps.filterKey) : nextProps.items;
    }

    if (!deepEqual(selected, nextProps.selected)) {
      stateUpdates.selectedIds = nextProps.selected;
      stateUpdates.visibleIds = nextProps.selected;
    }

    if (open && !nextProps.open) {
      stateUpdates.searchString = '';
    }

    this.setState(stateUpdates);
  }

  onSelectAll = () => {
    const { filterKey, selected } = this.props;
    const { selectedIds, visibleIds, visibleItems } = this.state;
    const someSelected = visibleIds.length > 0;

    if (someSelected) {
      const newIds = selectedIds.filter(id => visibleIds.indexOf(id) === -1);
      this.setState({
        selectedIds: newIds,
        visibleIds: [],
        applyDisabled: deepEqual(newIds, selected),
      }, this.autoApply);
    } else {
      const newVisibleIds = visibleItems.map(item => item[filterKey]);
      const newIds = [...selectedIds, ...newVisibleIds];
      this.setState({
        selectedIds: newIds,
        visibleIds: newVisibleIds,
        applyDisabled: deepEqual(newIds, selected),
      }, this.autoApply);
    }
  }

  onSelect = (e) => {
    const { singleFilter } = this.props;
    const { id } = e.currentTarget.dataset;

    if (singleFilter) {
      this.onSingleSelect(id);
    } else {
      this.onMultiSelect(id);
    }
  }

  onSingleSelect = (selectedId) => {
    const { visibleIds } = this.state;
    const index = visibleIds.indexOf(selectedId);

    if (index === -1) {
      const newIds = [selectedId];
      this.setState({
        selectedIds: newIds,
        visibleIds: newIds,
        applyDisabled: false,
      }, this.autoApply);
    } else {
      const newIds = [];
      this.setState({
        selectedIds: newIds,
        visibleIds: newIds,
        applyDisabled: true,
      }, this.autoApply);
    }
  }

  onMultiSelect = (selectedId) => {
    const { selected } = this.props;
    const { selectedIds, visibleIds } = this.state;
    const index = visibleIds.indexOf(selectedId);

    if (index === -1) {
      const newIds = [...selectedIds, selectedId];
      this.setState({
        selectedIds: newIds,
        visibleIds: [...visibleIds, selectedId],
        applyDisabled: deepEqual(newIds, selected),
      }, this.autoApply);
    } else {
      const newIds = selectedIds.filter(selected => selected !== selectedId);
      this.setState({
        selectedIds: newIds,
        visibleIds: visibleIds.filter(selected => selected !== selectedId),
        applyDisabled: deepEqual(newIds, selected),
      }, this.autoApply);
    }
  }

  onCancel = () => {
    const { selected, onCancel, items, filterKey, sortItems, setPopperIsOpen } = this.props;

    onCancel();
    this.setState({
      selectedIds: selected,
      visibleIds: selected,
      applyDisabled: true,
      visibleItems: sortItems ? sortFilterOptions(items, filterKey) : items,
    });
    setPopperIsOpen(false);
  }

  onApply = () => {
    const { onApply, items, filterKey, sortItems } = this.props;
    const { selectedIds } = this.state;

    const selectedItems = items.filter(item => selectedIds.indexOf(item[filterKey]) !== -1);

    onApply(selectedItems);
    this.setState({
      applyDisabled: true,
      visibleItems: sortItems ? sortFilterOptions(items, filterKey) : items,
    });
  }

  onSearch = (searchString = '') => {
    const { selectedIds } = this.state;
    const { items, filterKey, sortItems } = this.props;
    const query = searchString.toLowerCase();
    const visibleItems = items.filter(item => item.value.toLowerCase().indexOf(query) !== -1);

    this.setState({
      searchString,
      visibleItems: sortItems ? sortFilterOptions(visibleItems, filterKey) : visibleItems,
      visibleIds: visibleItems.filter(item => selectedIds.indexOf(item[filterKey]) !== -1).map(item => item[filterKey]),
    });
  }

  onSearchChange = (e) => {
    this.onSearch(e.currentTarget.value);
  }

  onClearSearch = () => {
    this.onSearch();
  }

  autoApply = () => {
    const { autoApply } = this.props;
    if (autoApply) {
      this.onApply();
    }
  }

  renderSearch = () => {
    const { placeholder } = this.props;
    const { searchString } = this.state;

    return (
      <div className="search-wrap">
        <TextField
          placeholder={placeholder}
          value={searchString}
          fullWidth
          onChange={this.onSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {searchString.length > 0 && (
                  <Clear className="filter-clear" onClick={this.onClearSearch} />
                )}
              </InputAdornment>
            ),
          }}
        />
      </div>
    );
  }

  renderSelectAll = () => {
    const { visibleIds, visibleItems } = this.state;
    const someSelected = visibleIds.length > 0;

    return (
      <div className="select-wrap">
        <FormControlLabel
          classes={{ root: 'select-label' }}
          label="Select all"
          onChange={this.onSelectAll}
          control={(
            <Checkbox
              classes={{ root: 'checkbox' }}
              checked={someSelected}
              onChange={this.toggleMaybe}
              indeterminate={someSelected && visibleIds.length < visibleItems.length}
              color="primary"
              id={AUTOMATION_SELECT_ALL_CHECKBOX}
            />
          )}
        />
      </div>
    );
  }

  renderFooter = () => {
    const { applyDisabled } = this.state;

    return (
      <div className="footer">
        <Button
          disableRipple
          size="medium"
          onClick={this.onCancel}
        >
          Cancel
        </Button>
        <Button
          disableRipple
          size="medium"
          onClick={this.onApply}
          color="primary"
          disabled={applyDisabled}
        >
          Apply
        </Button>
      </div>
    );
  }

  renderItems = () => {
    const { filterKey, singleFilter } = this.props;
    const { visibleIds, visibleItems } = this.state;

    if (visibleItems.length === 0) {
      return <div className="item-wrap empty">No results found</div>;
    }

    return (
      <List className="item-wrap">
        {visibleItems.map((item) => {
          const checked = visibleIds.indexOf(item[filterKey]) !== -1;
          return (
            <ListItem
              key={item[filterKey]}
              classes={{ root: classNames('list-item', { selected: singleFilter && checked }) }}
              onClick={this.onSelect}
              data-id={item[filterKey]}
              button
              divider
              disableRipple
            >
              <ListItemText id={item} className="list-item-text" primary={item.label || item[filterKey]} />
              {!singleFilter && (
                <Checkbox
                  classes={{ root: 'checkbox' }}
                  checked={checked}
                  tabIndex={-1}
                  disableRipple
                  color="primary"
                />
              )}
            </ListItem>
          );
        })}
      </List>
    );
  }

  render() {
    const {
      anchorEl,
      open,
      title,
      showSearch,
      showSelectAll,
      autoApply,
      placement,
    } = this.props;

    return (
      <CustomPopper
        popperOpen={open}
        anchorEl={anchorEl}
        classes="common-filter-popper"
        placement={placement}
        hideArrow
        preventBubbling
      >
        <ClickAwayListener onClickAway={this.onCancel}>
          <div className="content-wrap">
            <div role="presentation" onClick={this.onCancel} className="click-listener" />
            <div className="header">
              <div className="title">{title}</div>
              {showSearch && this.renderSearch()}
              {showSelectAll && this.renderSelectAll()}
            </div>
            {this.renderItems()}
            {!autoApply && this.renderFooter()}
          </div>
        </ClickAwayListener>
      </CustomPopper>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setPopperIsOpen: bindActionCreators(setPopperIsOpen, dispatch),
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(FilterPopper);
