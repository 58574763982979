import { combineReducers } from 'redux';
import ldRedux from 'ld-redux';
import { connectRouter } from 'connected-react-router';
import {
  PROJECTS_UPDATE_PROJECT_ROLE_SUCCESS,
  PROJECTS_ADD_PROJECT_ROLE_ALLOCATION_SUCCESS,
  PROJECTS_REPLACE_PROJECT_ROLE_ALLOCATION_SUCCESS,
  PROJECT_LIST_SELECTION_ID,
} from 'src/features/projects/redux/constants';
import {
  PEOPLE_UPLOAD_PERSON_ATTACHMENT_SUCCESS,
  PEOPLE_ADD_PERSON_CERTIFICATION_SUCCESS,
  PEOPLE_DELETE_PERSON_CERTIFICATION_SUCCESS,
} from 'src/features/people/redux/constants';
import {
  updateRoleForPeople,
  addAllocationForPeople,
  replaceAllocationForPeople,
  addRequestToTheSelectedProject,
} from 'src/common/crossFeatureUtils';
import { LOGIN_USERLOGOUT_SUCCESS } from 'src/features/login/redux/constants';
import { ERRORS_CLEAR_APP_DATA } from 'src/features/errors/redux/constants';
import history from './history';
import commonReducer from '../features/common/redux/reducer';
import accountsReducer from '../features/accounts/redux/reducer';
import settingsReducer from '../features/settings/redux/reducer';
import loginReducer from '../features/login/redux/reducer';
import peopleReducer from '../features/people/redux/reducer';
import projectsReducer from '../features/projects/redux/reducer';
import accountSettingsReducer from '../features/account-settings/redux/reducer';
import dashboardReducer from '../features/dashboard/redux/reducer';
import wrappedComponentsReducer from '../features/wrapped-components/redux/reducer';
import tableReducer from '../features/table/redux/reducer';
import phasesReducer from '../features/phases/redux/reducer';
import ganttReducer from '../features/gantt/redux/reducer';
import allocationsReducer from '../features/allocations/redux/reducer';
import filtersReducer from '../features/filters/redux/reducer';
import reportsReducer from '../features/reports/redux/reducer';
import queriesReducer from '../features/queries/redux/reducer';
import modalManagerReducer from '../features/modal-manager/redux/reducer';
import permissionsReducer from '../features/permissions/redux/reducer';
import forecastReducer from '../features/forecast/redux/reducer';
import errorsReducer from '../features/errors/redux/reducer';
import editDatesReducer from '../features/edit-dates/redux/reducer';
import serviceAccountsReducer from '../features/service-accounts/redux/reducer';
import selfPerformReducer from '../features/self-perform/redux/reducer';
import toastsReducer from '../features/toasts/redux/reducer';
import themesReducer from '../features/themes/redux/reducer';
import { SELF_PERFORM_ADD_HOURLY_REQUEST_SUCCESS } from '../features/self-perform/redux/constants';
import localeReducer from '../features/locale/redux/reducer';
import rolesReducer from '../features/roles/redux/reducer';
import { COMMON_SET_CONTENT_VIEW } from '../features/common/redux/constants';
import { MAP_TAB, PROJECT_VIEW } from './constants';

const reducerMap = {
  launchDarkly: ldRedux.reducer(),
  accounts: accountsReducer,
  common: commonReducer,
  settings: settingsReducer,
  login: loginReducer,
  people: peopleReducer,
  projects: projectsReducer,
  accountSettings: accountSettingsReducer,
  dashboard: dashboardReducer,
  wrappedComponents: wrappedComponentsReducer,
  table: tableReducer,
  phases: phasesReducer,
  gantt: ganttReducer,
  allocations: allocationsReducer,
  filters: filtersReducer,
  reports: reportsReducer,
  queries: queriesReducer,
  modalManager: modalManagerReducer,
  permissions: permissionsReducer,
  forecast: forecastReducer,
  errors: errorsReducer,
  editDates: editDatesReducer,
  serviceAccounts: serviceAccountsReducer,
  selfPerform: selfPerformReducer,
  toasts: toastsReducer,
  locale: localeReducer,
  themes: themesReducer,
  roles: rolesReducer,
};

const combinedReducers = combineReducers({
  router: connectRouter(history),
  ...reducerMap,
});

// Reset entire app state to default on successful logout or expired session (requires access to ALL state)
const rootReducer = (state, action) => {
  if (state === undefined) return combinedReducers(state, action);
  const { people, projects } = state;
  const { projectSelections } = projects;
  const { filteredPeople } = people;

  let newState;
  switch (action.type) {
    case PROJECTS_UPDATE_PROJECT_ROLE_SUCCESS: {
      newState = {
        ...state,
        people: {
          ...people,
          filteredPeople: updateRoleForPeople(filteredPeople, action, projectSelections),
        },
      };
      break;
    }
    case PROJECTS_ADD_PROJECT_ROLE_ALLOCATION_SUCCESS: {
      newState = {
        ...state,
        people: {
          ...people,
          filteredPeople: addAllocationForPeople(filteredPeople, action, projectSelections),
        },
      };
      break;
    }
    case PROJECTS_REPLACE_PROJECT_ROLE_ALLOCATION_SUCCESS: {
      newState = {
        ...state,
        people: {
          ...people,
          filteredPeople: replaceAllocationForPeople(filteredPeople, action, projectSelections),
        },
      };
      break;
    }
    case SELF_PERFORM_ADD_HOURLY_REQUEST_SUCCESS: {
      // only update projectSelections if request was added from project view to currently selected project;
      // if a different project is selected from inside of the AddRequestModal while in the Project details view,
      // projectSelections should not be updated
      const isAddingRequestToSelectedProject = action.projectId === projectSelections?.[PROJECT_LIST_SELECTION_ID]?.id;

      if (isAddingRequestToSelectedProject) {
        newState = {
          ...state,
          projects: {
            ...projects,
            projectSelections: addRequestToTheSelectedProject(projectSelections, action),
          },
        };
      } else {
        newState = state;
      }
      break;
    }
    case PEOPLE_UPLOAD_PERSON_ATTACHMENT_SUCCESS: {
      const { analyticsPayload: { file: { size } } } = action;
      const { common: { accountId } } = state;
      newState = {
        ...state,
        accounts: {
          ...state.accounts,
          entities: state.accounts.entities.map((account) => {
            if (account.id !== accountId) return account;
            return {
              ...account,
              totalPeopleAttachmentBytes: account.totalPeopleAttachmentBytes + size,
            };
          }),
        },
      };
      break;
    }
    // Update a certification's inUse array after the certification is added to a person
    // This tracks who is using a certification without refreshing the entire account certification list
    case PEOPLE_ADD_PERSON_CERTIFICATION_SUCCESS: {
      const { data: { id: certificationId }, personId } = action;
      newState = {
        ...state,
        accountSettings: {
          ...state.accountSettings,
          certifications: state.accountSettings.certifications.map(cert => (
            cert.id === certificationId
              ? { ...cert, inUse: [...cert.inUse, personId] }
              : cert
          )),
        },
      };
      break;
    }
    // Update a certification's inUse array after the certification is removed from a person
    case PEOPLE_DELETE_PERSON_CERTIFICATION_SUCCESS: {
      const { certificationId, personId } = action;
      newState = {
        ...state,
        accountSettings: {
          ...state.accountSettings,
          certifications: state.accountSettings.certifications.map(cert => (
            cert.id === certificationId
              ? { ...cert, inUse: cert.inUse?.filter(id => id !== personId) }
              : cert
          )),
        },
      };
      break;
    }
    // Reset filteredProjects array when navigating from the map tab to another section of the app
    case COMMON_SET_CONTENT_VIEW: {
      const { activeView, contentView } = state.common;
      const isLeavingMap = activeView === PROJECT_VIEW && contentView === MAP_TAB;

      newState = {
        ...state,
        projects: {
          ...state.projects,
          filteredProjects: isLeavingMap ? [] : state.projects.filteredProjects,
        },
      };
      break;
    }
    case LOGIN_USERLOGOUT_SUCCESS:
    case ERRORS_CLEAR_APP_DATA:
      newState = undefined;
      break;
    default:
      newState = state;
      break;
  }

  return combinedReducers(newState, action);
};

export default rootReducer;
