import { call, put, takeLatest } from 'redux-saga/effects';
import {
  LOGIN_UPDATE_PASSWORD_BEGIN,
  LOGIN_UPDATE_PASSWORD_SUCCESS,
  LOGIN_UPDATE_PASSWORD_FAILURE,
  LOGIN_UPDATE_PASSWORD_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';

export function updatePassword(data) {
  return {
    type: LOGIN_UPDATE_PASSWORD_BEGIN,
    data,
  };
}

export function dismissUpdatePasswordError() {
  return {
    type: LOGIN_UPDATE_PASSWORD_DISMISS_ERROR,
  };
}

export function* doUpdatePassword(action) {
  const { data } = action;
  const { accountId, accountName } = data;

  try {
    yield call(protectedJsonPost, '/users/reset-password', data);
  } catch (err) {
    yield put({
      type: LOGIN_UPDATE_PASSWORD_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: LOGIN_UPDATE_PASSWORD_SUCCESS,
    accountId,
    accountName,
  });
}

export function* watchUpdatePassword() {
  yield takeLatest(LOGIN_UPDATE_PASSWORD_BEGIN, doUpdatePassword);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case LOGIN_UPDATE_PASSWORD_BEGIN:
      return {
        ...state,
        updatePasswordPending: true,
        updatePasswordError: null,
      };

    case LOGIN_UPDATE_PASSWORD_SUCCESS: {
      const { accountName, accountId } = action;
      const email = state.userInfo?.email ?? 'No email found';
      const group = state.userInfo?.permissions?.[accountId]?.group ?? 'No group found';

      window.mixpanel.track('Update Password', {
        'User Email': email,
        'Account Name': accountName,
        'Account ID': accountId,
        'Permission Group': group,
        'Updated From': 'User Profile',
      });

      return {
        ...state,
        updatePasswordPending: false,
        updatePasswordError: null,
      };
    }

    case LOGIN_UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        updatePasswordPending: false,
        updatePasswordError: action.data.error,
      };

    case LOGIN_UPDATE_PASSWORD_DISMISS_ERROR:
      return {
        ...state,
        updatePasswordError: null,
      };

    default:
      return state;
  }
}
