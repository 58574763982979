import darkThemeStyleOverrides from './style-overrides-dark.theme.css';

export const STANDARD_THEME = 'standard';
export const DARK_THEME = 'dark';
export const DIM_THEME = 'dim';

export const BRIGHTNESS = 'brightness';
export const CONTRAST = 'contrast';
export const SEPIA = 'sepia';
export const GRAYSCALE = 'grayscale';

export const SETTING_RANGES = {
  [DARK_THEME]: {
    [BRIGHTNESS]: [80, 130],
    [CONTRAST]: [60, 110],
    [SEPIA]: [-10, 40],
    [GRAYSCALE]: [10, 60],
  },
  [DIM_THEME]: {
    [BRIGHTNESS]: [60, 110],
    [CONTRAST]: [80, 130],
    [SEPIA]: [0, 50],
    [GRAYSCALE]: [0, 50],
  },
};

export const DEFAULT_DARK_MODE_SETTINGS = {
  [BRIGHTNESS]: 115,
  [CONTRAST]: 90,
  [SEPIA]: 10,
  [GRAYSCALE]: 35,
};

export const DEFAULT_DIM_MODE_SETTINGS = {
  [BRIGHTNESS]: 90,
  [CONTRAST]: 105,
  [SEPIA]: 30,
  [GRAYSCALE]: 5,
};

export const SETTING_SLIDER_STEP = 5;

export const darkThemeFixes = {
  invert: [],
  css: darkThemeStyleOverrides,
  ignoreInlineStyle: [],
  ignoreImageAnalysis: [],
};

export const TOOLTIP = {
  systemThemeText: 'Disable "Use OS system theme" setting on Manage Profile page to toggle Dark mode',
  darkOrDimText: 'Switch to light mode',
  standardText: 'Switch to dark mode',
};
