import momentBase from 'moment';
import { extendMoment } from 'moment-range';

import {
  subtractMomentRanges,
  isStringOrMoment,
  toRange,
  fromRange,
  stringToMoment,
  momentToString,
  getIntersectingMomentRanges,
} from '../../../utils/dateUtils';
import { DATE_INPUT_FORMAT } from '../../../common/constants';
import { sortSegmentsByStartDate, isValidSegmentArray } from '../../../utils/dateSegmentUtils';
import { naturalSort } from '../../../utils/sortUtils';

const moment = extendMoment(momentBase);

const buildSegment = (range, availablePercent, allocatedPercent) => {
  const { startDate, endDate } = fromRange(range);
  return ({
    startDate,
    endDate,
    fullCoverage: availablePercent >= allocatedPercent,
    partialCoverage: true,
  });
};

/*
  This function takes the availability of a person, and matches it up with the requirements of
  a project role. For each segment, flags are set to indicate if the availability is enough to meet the
  requirements of that role.
*/
function getPossibleAllocations(roleRequirements = [], personAvailabilities = []) {
  if (!isValidSegmentArray(roleRequirements) || !isValidSegmentArray(personAvailabilities)) return [];

  let requirementIndex = 0;
  let currentSegment;
  const coverageSegments = [];
  const requirementsSorted = sortSegmentsByStartDate(roleRequirements);
  let currentRequirement = requirementsSorted[requirementIndex];

  personAvailabilities.forEach(({ startDate, endDate, availablePercent }) => {
    const availabilityStart = stringToMoment(startDate);
    const availabilityEnd = stringToMoment(endDate);
    const availabilityRange = toRange(startDate, endDate);

    while (currentRequirement && availabilityStart.isAfter(currentRequirement.endDate)) {
      // skip requirements that are before availability
      requirementIndex += 1;
      currentRequirement = requirementsSorted[requirementIndex];
    }

    let requirementEnd = currentRequirement ? stringToMoment(currentRequirement.endDate) : null;

    while (currentRequirement && (availabilityEnd.isSameOrAfter(currentRequirement.endDate) || requirementEnd.isSameOrAfter(availabilityEnd))) {
      const requirementRange = toRange(currentRequirement.startDate, requirementEnd);
      // get overlapping segments between the role requirements and person availability
      const range = getIntersectingMomentRanges(availabilityRange, requirementRange);

      // there is no overlap, go to the next availability
      if (!range) break;

      if (!currentSegment) {
        currentSegment = buildSegment(range, availablePercent, currentRequirement.allocatedPercent);
      } else if (
        (currentSegment.fullCoverage === (availablePercent >= currentRequirement.allocatedPercent)) // coverage is the same
        && stringToMoment(currentSegment.endDate).add(1, 'days').isSame(range.start) // current segment is a day after the previous one
      ) {
        // extend existing segment since current one is the next day and has the same coverage
        currentSegment.endDate = momentToString(range.end);
      } else {
        // current segment does not match the existing one, push the existing segment and build a new segment
        coverageSegments.push({ ...currentSegment });
        currentSegment = buildSegment(range, availablePercent, currentRequirement.allocatedPercent);
      }

      // requirement goes past current availability, go to next availability to complete calculation
      if (requirementEnd.isAfter(availabilityEnd)) break;

      // we're done with this requirement, go to the next one
      requirementIndex += 1;
      currentRequirement = requirementsSorted[requirementIndex];

      if (currentRequirement) {
        requirementEnd = stringToMoment(currentRequirement.endDate);
      }
    }
  });

  if (currentSegment) {
    // the last segment is leftover from the last iteration, flush it here
    coverageSegments.push({ ...currentSegment });
  }

  return coverageSegments;
}

function filterRoleTimeline(role, allocations) {
  const roleStart = moment(role.startDate, DATE_INPUT_FORMAT);
  const roleEnd = moment(role.endDate, DATE_INPUT_FORMAT);
  const roleRangeAccumulator = [moment.range(roleStart, roleEnd)];

  const removeAllocationBlock = (allocationRange) => {
    for (let i = 0; i < roleRangeAccumulator.length; i += 1) {
      if (allocationRange.overlaps(roleRangeAccumulator[i], { adjacent: true })) {
        const updatedRange = subtractMomentRanges(roleRangeAccumulator[i], allocationRange);
        roleRangeAccumulator.splice(i, 1, ...updatedRange);
        return;
      }
    }
  };

  allocations.forEach((a) => {
    const allocationStart = moment(a.startDate, DATE_INPUT_FORMAT);
    const allocationEnd = moment(a.endDate, DATE_INPUT_FORMAT);
    const allocationRange = moment.range(allocationStart, allocationEnd);

    removeAllocationBlock(allocationRange);
  });

  return roleRangeAccumulator;
}

function getTimelineGaps(role, allocations) {
  const gaps = filterRoleTimeline(role, allocations);

  return gaps.map(gap => ({
    startDate: gap.start.format(DATE_INPUT_FORMAT),
    endDate: gap.end.format(DATE_INPUT_FORMAT),
  }));
}

function roleIsShrinking(startDate, endDate, role) {
  const newStartDate = moment(startDate);
  const newEndDate = moment(endDate);
  const oldStartDate = moment(role.startDate);
  const oldEndDate = moment(role.endDate);
  return (newStartDate.isAfter(oldStartDate) || newEndDate.isBefore(oldEndDate));
}

function getImpactedPersonId(peopleAllocations = [], role, startDate) {
  if (!(
    peopleAllocations?.length &&
    isStringOrMoment(role?.startDate) &&
    isStringOrMoment(role?.endDate) &&
    isStringOrMoment(startDate)
  )) return null;

  const sortedAllocations = naturalSort(peopleAllocations, 'startDate');
  const newStartDate = moment(startDate);
  const oldStartDate = moment(role.startDate);
  const oldEndDate = moment(role.endDate);
  const firstAllocation = sortedAllocations[0];
  const lastAllocation = sortedAllocations[sortedAllocations.length - 1];
  const isStartDateChange = !newStartDate.isSame(oldStartDate);

  let personId = null;

  if (isStartDateChange) {
    if (moment(firstAllocation.startDate).isSame(oldStartDate)) {
      ({ personId } = firstAllocation);
    }
  } else if (moment(lastAllocation.endDate).isSame(oldEndDate)) {
    ({ personId } = lastAllocation);
  }

  return personId;
}

function getPersonImpacted(peopleAllocations = [], people, role, startDate) {
  if (!(
    peopleAllocations?.length &&
    people?.length &&
    isStringOrMoment(role?.startDate) &&
    isStringOrMoment(role?.endDate) &&
    isStringOrMoment(startDate)
  )) return null;

  let personImpacted = null;

  const personId = getImpactedPersonId(peopleAllocations, role, startDate);

  if (personId) personImpacted = people.find(p => p.id === personId);

  return personImpacted;
}

function allocationUpdateMessage(confirmOverlapUpdate, confirmRemove, confirmUpdateAllocation, personName, overlapMessage) {
  let message = null;
  let subMessage = null;
  let primaryActionText = 'Yes';
  if (confirmOverlapUpdate) {
    message = `${personName} cannot be placed on the role for days they are unavailable.`;
    subMessage = overlapMessage;
    primaryActionText = 'OK';
  } else if (confirmRemove) {
    message = `Are you sure you want to remove ${personName} from this role?`;
    subMessage = 'All history for this placement will be lost';
    primaryActionText = 'Remove';
  } else {
    message = `Editing the ${confirmUpdateAllocation.changeType} for ${personName} will impact the dates for other people in this role.`;
    subMessage = 'Are you sure you want to edit this date?';
  }
  return {
    message,
    subMessage,
    primaryActionText,
  };
}

function roleIsFilled(startDate, endDate, requirements, allocations) {
  if (Array.isArray(allocations) && !allocations.length) return false;

  if (
    !isStringOrMoment(startDate) ||
    !isStringOrMoment(endDate) ||
    !isValidSegmentArray(requirements) ||
    !isValidSegmentArray(allocations)
  ) return null;

  const roleRange = { startDate, endDate };
  const zeroRequirements = requirements.filter(req => req.allocatedPercent === 0);
  const combinedCoverage = [...allocations, ...zeroRequirements];

  const gaps = filterRoleTimeline(roleRange, combinedCoverage);

  return gaps.length === 0;
}

/*
  Returns filtered allocations removing any that connect with the new one
  Returns new allocations dates for the new allocation that needs to be added
*/
const filterConnectedAllocations = (projectAllocations, roleId, allocatonStart, allocationEnd) => {
  let newStartDate = allocatonStart;
  let newEndDate = allocationEnd;

  const filteredProjectAllocations = projectAllocations.filter((a) => {
    if (a.roleId !== roleId) return true;
    const connectsAtStart = moment(a.endDate).add(1, 'day').isSame(allocatonStart);
    const connectsAtEnd = moment(a.startDate).subtract(1, 'day').isSame(allocationEnd);
    if (connectsAtStart) newStartDate = a.startDate;
    if (connectsAtEnd) newEndDate = a.endDate;
    return !connectsAtStart && !connectsAtEnd;
  });

  return {
    filteredProjectAllocations,
    newStartDate,
    newEndDate,
  };
};

/*
  This is a helper function to handle removing allocations that no longer belong in the parent's new date range
*/
const filterSquashedAllocations = (
  projectAllocations,
  parentStart,
  parentEnd,
  projectId = null,
  roleId = null,
) => projectAllocations.filter(
  (allocation) => {
    if (roleId && allocation.roleId !== roleId) return true;
    if (projectId && allocation.projectId !== projectId) return true;
    const roleStartAfterEnd = moment(parentStart).isSameOrAfter(allocation.endDate);
    const roleEndBeforeStart = moment(parentEnd).isSameOrBefore(allocation.startDate);
    return (!roleStartAfterEnd && !roleEndBeforeStart);
  },
);

/*
  This is a helper function to handle project or role date changes that affect allocation dates
*/
const handleParentDateChange = (parentStart, parentEnd, allocation, oldParentStart, oldParentEnd, expand) => {
  const momentParentStart = moment(parentStart);
  const momentParentEnd = moment(parentEnd);

  /*
    If new parent start date is after allocation start date
    If new parent end date is before allocation end date
    Shrink the allocation to fit in the new parent's date range
  */
  let newStart = momentParentStart.isAfter(allocation.startDate) ? parentStart : allocation.startDate;
  let newEnd = momentParentEnd.isBefore(allocation.endDate) ? parentEnd : allocation.endDate;

  /*
    If expand allocations with new parent date is selected
    If the allocation started on the old parent start date or the allocation ended on the old parent end date
    Extend the allocaton with the new parent date
  */
  if (expand) {
    if (moment(oldParentStart).isSame(allocation.startDate)) newStart = parentStart;
    if (moment(oldParentEnd).isSame(allocation.endDate)) newEnd = parentEnd;
  }

  return { newStart, newEnd };
};

/*
  This function handles when a role with custom allocation percent has a zero percent block
*/
const handleZeroPercentBlock = (projectAllocations, zeroBlocks, roleId = null) => projectAllocations.reduce((acc, allocation) => {
  const { startDate, endDate } = allocation;
  const momentStart = moment(startDate);
  const momentEnd = moment(endDate);

  if (roleId && roleId !== allocation.roleId) {
    acc.push(allocation);
    return acc;
  }

  // First check if there is a zero block that caused the allocation to no longer exist
  const isInZeroBlock = zeroBlocks.find(zeroBlock => (
    momentStart.isSameOrAfter(zeroBlock.startDate) &&
    momentEnd.isSameOrBefore(zeroBlock.endDate)
  ));
  if (isInZeroBlock) return acc;

  let newAllocation = { ...allocation };

  // Next we need to check if any zero blocks cause the start/end dates to change
  zeroBlocks.forEach((zeroBlock) => {
    // If the zero block causes the allocations start date to change
    if (momentStart.isSameOrAfter(zeroBlock.startDate) && momentStart.isSameOrBefore(zeroBlock.endDate)) {
      newAllocation = {
        ...newAllocation,
        startDate: moment(zeroBlock.endDate).add(1, 'day').format(DATE_INPUT_FORMAT),
      };
    }
    // If the zero block causes the allocation end date to change
    if (momentEnd.isSameOrBefore(zeroBlock.endDate) && momentEnd.isSameOrAfter(zeroBlock.startDate)) {
      newAllocation = {
        ...newAllocation,
        endDate: moment(zeroBlock.startDate).subtract(1, 'day').format(DATE_INPUT_FORMAT),
      };
    }
  });

  // Finally, if the zero block is inside the allocation we need to split the allocation into two
  const allocationsToAdd = [];
  let nextSplitStartDate = newAllocation.startDate;
  zeroBlocks.forEach((zeroBlock) => {
    if (moment(newAllocation.startDate).isBefore(zeroBlock.startDate) && moment(newAllocation.endDate).isAfter(zeroBlock.endDate)) {
      allocationsToAdd.push({
        ...newAllocation,
        startDate: nextSplitStartDate,
        endDate: moment(zeroBlock.startDate).subtract(1, 'day').format(DATE_INPUT_FORMAT),
      });
      nextSplitStartDate = moment(zeroBlock.endDate).add(1, 'day').format(DATE_INPUT_FORMAT);
    }
  });
  allocationsToAdd.push({
    ...newAllocation,
    startDate: nextSplitStartDate,
  });

  return [
    ...acc,
    ...allocationsToAdd,
  ];
}, []);

export {
  getPossibleAllocations,
  getTimelineGaps,
  roleIsShrinking,
  getImpactedPersonId,
  getPersonImpacted,
  allocationUpdateMessage,
  roleIsFilled,
  filterConnectedAllocations,
  filterSquashedAllocations,
  handleParentDateChange,
  handleZeroPercentBlock,
};
