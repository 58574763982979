import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Paper } from '@material-ui/core';
import { projectGanttEmpty } from 'src/images/index';

function EmptyGantt({
  className,
  welcomeText,
  bodyText,
  children,
}) {
  return (
    <div className={classNames('gantt-empty-gantt', className)}>
      <Paper className="empty-image-paper">
        <div className="empty-image-wrapper">
          <div className="empty-welcome-text">{welcomeText}</div>
          <img src={projectGanttEmpty} alt="emptyImg" />
          <div className="empty-empty-text">{bodyText}</div>
        </div>
      </Paper>
      {children}
    </div>
  );
}

EmptyGantt.propTypes = {
  className: PropTypes.string,
  welcomeText: PropTypes.string,
  bodyText: PropTypes.string,
  children: PropTypes.node,
};

EmptyGantt.defaultProps = {
  className: '',
  welcomeText: '',
  bodyText: '',
  children: '',
};

export default EmptyGantt;
