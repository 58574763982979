import { call, put, takeLatest } from 'redux-saga/effects';
import {
  DASHBOARD_GET_MONTHLY_IDLE_TIME_OVERVIEW_BEGIN,
  DASHBOARD_GET_MONTHLY_IDLE_TIME_OVERVIEW_SUCCESS,
  DASHBOARD_GET_MONTHLY_IDLE_TIME_OVERVIEW_FAILURE,
  DASHBOARD_GET_MONTHLY_IDLE_TIME_OVERVIEW_DISMISS_ERROR,
} from './constants';
import graphApiClient from '../../../common/GraphAPIClient';
import { getIdleTimeOverviewQuery } from '../queries/getIdleTimeOverviewQuery';
import { MONTH } from '../constants';
import { getIdleTimeSentiment } from '../utils/dashboardUtils';

export function getMonthlyIdleTimeOverview(accountId, startDate, endDate) {
  const { query, variables } = getIdleTimeOverviewQuery(accountId, startDate, endDate, MONTH);
  return {
    type: DASHBOARD_GET_MONTHLY_IDLE_TIME_OVERVIEW_BEGIN,
    query,
    variables,
  };
}

export function dismissGetMonthlyIdleTimeOverviewError() {
  return {
    type: DASHBOARD_GET_MONTHLY_IDLE_TIME_OVERVIEW_DISMISS_ERROR,
  };
}

export function* doGetMonthlyIdleTimeOverview(action) {
  const { query, variables } = action;
  let res;
  try {
    res = yield call(graphApiClient.query, { query, variables });
  } catch (err) {
    yield put({
      type: DASHBOARD_GET_MONTHLY_IDLE_TIME_OVERVIEW_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: DASHBOARD_GET_MONTHLY_IDLE_TIME_OVERVIEW_SUCCESS,
    data: res.data,
  });
}

export function* watchGetMonthlyIdleTimeOverview() {
  yield takeLatest(DASHBOARD_GET_MONTHLY_IDLE_TIME_OVERVIEW_BEGIN, doGetMonthlyIdleTimeOverview);
}

export function reducer(state, action) {
  switch (action.type) {
    case DASHBOARD_GET_MONTHLY_IDLE_TIME_OVERVIEW_BEGIN:
      return {
        ...state,
        getMonthlyIdleTimeOverviewPending: true,
        getMonthlyIdleTimeOverviewError: null,
      };

    case DASHBOARD_GET_MONTHLY_IDLE_TIME_OVERVIEW_SUCCESS: {
      const overviewItems = action?.data?.forecastingOverview?.items;
      const month1 = overviewItems?.[0]?.y;
      const month2 = overviewItems?.[1]?.y;
      const idleTimeSentiment = getIdleTimeSentiment(month1, month2);
      return {
        ...state,
        monthlyIdleTimeOverview: overviewItems || [],
        idleTimeSentiment,
        getMonthlyIdleTimeOverviewPending: false,
        getMonthlyIdleTimeOverviewError: null,
      };
    }
    case DASHBOARD_GET_MONTHLY_IDLE_TIME_OVERVIEW_FAILURE:
      return {
        ...state,
        getMonthlyIdleTimeOverviewPending: false,
        getMonthlyIdleTimeOverviewError: action.data.error,
      };

    case DASHBOARD_GET_MONTHLY_IDLE_TIME_OVERVIEW_DISMISS_ERROR:
      return {
        ...state,
        getMonthlyIdleTimeOverviewError: null,
      };

    default:
      return state;
  }
}
