import React, { useMemo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  IconButton,
  MenuItem,
  Select,
  Tooltip,
} from '@material-ui/core';
import {
  Edit,
  NoteAddOutlined,
} from '@material-ui/icons';
import { ProjectRoleTitle } from '@bridgit/foundation';

export const ProjectRoleEditor = ({
  selectedRole,
  roles,
  editMode,
  onChange,
  onToggleEditMode,
  onToggleNoteEditMode,
  onSelectOpen,
  onSelectClose,
}) => {
  const noteRef = useRef(null);

  const onSelectionChange = useCallback((evt) => {
    onChange(evt.target.value);
  }, [onChange]);

  const toggleEditMode = () => {
    onToggleEditMode();
  };

  const toggleEditNote = () => {
    onToggleNoteEditMode(noteRef.current);
  };

  const onSelectClick = (evt) => {
    evt.stopPropagation();
  };

  const renderEditModeSelect = useMemo(() => {
    if (!editMode) return null;

    let roleValue = '';

    if (selectedRole) {
      if ('roleId' in selectedRole) {
        roleValue = selectedRole.roleId;
      }

      const roleMatch = roles.find(role => role.name === selectedRole.name);

      if (roleMatch && 'id' in roleMatch) {
        roleValue = roleMatch.id;
      }
    }

    return (
      <Select
        className="resource-role-select"
        displayEmpty
        value={roleValue}
        onClick={onSelectClick}
        onChange={onSelectionChange}
        onOpen={onSelectOpen}
        onClose={onSelectClose}
        MenuProps={{
          classes: {
            paper: 'select-menu-400',
          },
        }}
      >
        {!selectedRole && (
          <MenuItem className="placeholder" value="">
            <em className="resource-role-select-default">Select Role</em>
          </MenuItem>
        )}

        {roles.map(role => (
          <MenuItem key={role.id} value={role.id} className="select-item">{role.name}</MenuItem>
        ))}
      </Select>
    );
  }, [editMode, onSelectOpen, onSelectClose, roles, selectedRole, onSelectionChange]);

  return (
    <div className={`projects-project-role-editor ${editMode ? 'title-enabled' : ''}`}>
      {!editMode && selectedRole && (
        <>
          <ProjectRoleTitle
            className="role-editor-role-title"
            roleName={selectedRole.name}
            roleNote={selectedRole.note}
          />
          <div className="controls" ref={noteRef}>
            <Tooltip title="Edit role type" placement="top">
              <IconButton className="edit-role-title edit-icon" onClick={toggleEditMode}>
                <Edit fontSize="inherit" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit role note" placement="top">
              <IconButton className="edit-role-title edit-icon" onClick={toggleEditNote}>
                <NoteAddOutlined fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </div>
        </>
      )}
      {renderEditModeSelect}
    </div>
  );
};

ProjectRoleEditor.propTypes = {
  selectedRole: PropTypes.object,
  roles: PropTypes.array.isRequired,
  editMode: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onToggleEditMode: PropTypes.func,
  onToggleNoteEditMode: PropTypes.func,
  onSelectOpen: PropTypes.func,
  onSelectClose: PropTypes.func,
};

ProjectRoleEditor.defaultProps = {
  selectedRole: null,
  editMode: true,
  onToggleEditMode: () => {},
  onToggleNoteEditMode: () => {},
  onSelectOpen: () => {},
  onSelectClose: () => {},
};

function mapStateToProps({ accountSettings }) {
  const { roles } = accountSettings;

  return {
    roles,
  };
}

export default connect(
  mapStateToProps,
)(ProjectRoleEditor);
