import moment from 'moment';
import {
  FIELD_TYPE_CURRENCY,
  FIELD_TYPE_CUSTOM_ORDER,
  FIELD_TYPE_DATE,
  FIELD_TYPE_LONG_TEXT,
  FIELD_TYPE_MULTI_SELECT,
  FIELD_TYPE_PHONE,
  FIELD_TYPE_SINGLE_SELECT,
  FIELD_TYPE_PHONE_E164,
  COLOR,
  PERCENTAGE,
} from 'src/common/constants';
import { naturalSort } from 'src/utils/sortUtils';
import config from '../common/envConfig';

export function cloneDeep(obj) {
  let retVal;

  if (obj === null) {
    // Null
    retVal = null;
  } else if (moment.isMoment(obj)) {
    // Moment Object
    retVal = obj.clone();
  } else if (obj instanceof Date) {
    // Date
    retVal = new Date(obj);
  } else if (typeof obj === 'object' && typeof obj.length === 'undefined') {
    // JS Object
    retVal = {};
    Object.keys(obj).forEach((prop) => {
      retVal[prop] = cloneDeep(obj[prop]);
    });
  } else if (Array.isArray(obj)) {
    // Array
    retVal = [];
    obj.forEach((x) => {
      retVal.push(cloneDeep(x));
    });
  } else {
    // Everything else
    retVal = obj;
  }

  return retVal;
}

// taken from: https://stackoverflow.com/a/32108184
export function isEmptyObject(obj) {
  return obj.constructor === Object && Object.keys(obj).length === 0;
}

function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

// taken from: https://stackoverflow.com/a/37164538
export function mergeDeep(target, source) {
  const output = { ...target };
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) {
          Object.assign(output, { [key]: source[key] });
        } else {
          output[key] = mergeDeep(target[key], source[key]);
        }
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
}

export function unique(arr, prop) {
  const uniqueProps = [];
  const uniqueArr = [];
  for (let i = 0; i < arr.length; i += 1) {
    if (uniqueProps.indexOf(arr[i][prop]) === -1) {
      uniqueProps.push(arr[i][prop]);
      uniqueArr.push(arr[i]);
    }
  }
  return uniqueArr;
}

export function apiFormatPhoneNumber(phoneNumber) {
  return phoneNumber.split(/\r?\n/)[0].toLowerCase().replace(/[^0-9x,]/g, '');
}

// https://gist.github.com/igodorogea/4f42a95ea31414c3a755a8b202676dfd
// Algorithm for Optimal Scaling on a Chart Axis (Nice Numbers for Graph Labels)
/* eslint-disable */
export function niceScale (lowerBound, upperBound, _maxTicks) {
  var maxTicks = _maxTicks || 10;
  var tickSpacing;
  var range;
  var upperBound;

  function calculate () {
    range = niceNum(upperBound - lowerBound, false);
    tickSpacing = niceNum(range / (maxTicks - 1), true);
    upperBound = Math.ceil(upperBound / tickSpacing) * tickSpacing;
  }

  function niceNum (range, round) {
    var exponent = Math.floor(Math.log10(range));
    var fraction = range / Math.pow(10, exponent);
    var niceFraction;

    if (round) {
      if (fraction < 1.5) niceFraction = 1;
      else if (fraction < 3) niceFraction = 2;
      else if (fraction < 7) niceFraction = 5;
      else niceFraction = 10;
    } else {
      if (fraction <= 1) niceFraction = 1;
      else if (fraction <= 2) niceFraction = 2;
      else if (fraction <= 5) niceFraction = 5;
      else niceFraction = 10;
    }

    return niceFraction * Math.pow(10, exponent);
  }

  calculate();
  return { upperBound, tickSpacing: tickSpacing < 1 ? 1 : tickSpacing };
}
/* eslint-enable */

// Call with a param key, and you get back either the matching value, or undefined.
export function getUrlParam(key = '') {
  if (typeof key !== 'string') {
    return undefined;
  }

  const params = new URL(window.location.href).searchParams;
  return params.get(key);
}

export function toUrlEncoded(obj) {
  return Object.keys(obj)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
    .join('&');
}

export function getMapsQueryUrl(locationSearch) {
  const params = {
    api: 1,
    query: locationSearch,
  };

  const queryString = toUrlEncoded(params);
  return `${config.mapsQueryPath}?${queryString}`;
}

// Add placeholder and options to a field based on type
export const getFieldProperties = (field) => {
  const input = {
    ...field,
    placeholder: `Enter ${field.name.toLowerCase()}`,
  };

  switch (field.type) {
    case FIELD_TYPE_PHONE:
      input.maxLength = 22;
      break;
    case FIELD_TYPE_SINGLE_SELECT:
    case FIELD_TYPE_MULTI_SELECT: {
      const values = field.definedValues.map(v => ({ label: v.definedValue, value: v.definedValue }));

      input.options = FIELD_TYPE_CUSTOM_ORDER.includes(field.name)
        ? values
        : naturalSort(values, 'label');
      input.placeholder = `Select ${field.name.toLowerCase()}`;
      break;
    }
    case FIELD_TYPE_DATE:
      input.placeholder = 'MM/DD/YYYY';
      break;
    case FIELD_TYPE_CURRENCY:
      input.maxLength = 18;
      break;
    case FIELD_TYPE_LONG_TEXT:
      input.width = 'full';
      input.maxLength = 2400;
      break;
    default:
      break;
  }

  return input;
};

export function getFieldInput(field) {
  const placeholder = (field.type === 'SingleSelect' || field.type === 'MultiSelect') ? `Select ${field.name.toLowerCase()}` : `Enter ${field.name.toLowerCase()}`;

  const input = {
    ...field,
    label: field.name,
    name: field.id.toString(),
    required: field.isRequired,
    placeholder: field.placeholder || placeholder,
    fieldType: field.type,
  };

  if ('value' in field) {
    input.value = field.value;
  }

  switch (field.type) {
    case 'Text':
      input.type = 'text';
      break;
    case 'PhoneNumber':
    case FIELD_TYPE_PHONE_E164:
      input.type = 'text';
      input.maxLength = 22;
      break;
    case 'SingleSelect': {
      const values = field.definedValues.map(v => ({ label: v.definedValue, value: v.definedValue }));
      input.options = naturalSort(values, 'label');
      input.type = 'select';
      break;
    }
    case 'MultiSelect': {
      const values = field.definedValues.map(v => ({ label: v.definedValue, value: v.definedValue }));
      input.options = FIELD_TYPE_CUSTOM_ORDER.includes(field.name)
        ? values
        : naturalSort(values, 'label');
      input.type = 'select';
      input.value = field.value;
      input.multi = true;
      break;
    }
    case 'Date':
      input.type = 'date';
      break;
    case 'Boolean':
      input.type = 'checkbox';
      break;
    case 'LongText':
      input.type = 'text';
      input.width = 'full';
      input.multi = true;
      input.maxLength = 2400;
      break;
    case COLOR:
      input.type = 'color';
      break;
    case PERCENTAGE:
      input.type = 'percentage';
      break;
    case 'Currency':
      input.type = 'currency';
      input.maxLength = 18;
      break;
    case 'Address':
      input.type = 'address';
      input.maxLength = 250;
      break;
    case 'MultiCheckbox':
      input.type = 'checkbox';
      input.multi = true;
      break;
    default:
      // Shouldn't get here, but if we do, render text input
      input.type = 'text';
  }

  return input;
}

export function reorderList(items, srcIndex, destIndex) {
  const newList = [...items];
  newList.splice(srcIndex, 1);
  newList.splice(destIndex, 0, items[srcIndex]);
  return newList;
}

export function getAccountById(accounts, accountId) {
  return accounts.find(account => account.id === accountId);
}

/* eslint-enable no-mixed-operators, no-bitwise */

// Checks to see if a case insensitive string value is contained in an array of strings.
// Returns a boolean value.
export function caseInsensitiveStringIncludes(arr, comparisonString) {
  if (arr.some(item => typeof item !== 'string') || !arr.length) return false;
  return arr.map(str => str.toLowerCase()).includes(comparisonString.toLowerCase());
}

// Compares 2 objects and returns an array of property names that have changed
export function getChangedObjectProperties(origObject, newObject) {
  return Object.entries(newObject).reduce((acc, [key, value]) => {
    if (origObject[key] !== value) {
      acc.push(key);
    }
    return acc;
  }, []);
}

/**
 * Split UpperCase words into separate words
 * @param {string} string - The string to split.
 * @returns {string} - The string with words split by uppercase letters
 * @example
 * // returns The Quick Brown Fox
 * splitByUpperCase('TheQuickBrownFox);
 */
export const splitByUpperCase = string => string.split(/(?=[A-Z])/).join(' ');

/**
 * Get the file extension (the part of the string after the last period)
 * @param {string} string - The string/file name to get the extension from
 * @returns {string} - The file extension (if it was in the name of the file, period separated)
 * @example
 * // returns jpeg
 * getFileExtension('cat.jpeg')
 */
export const getFileExtension = string => string.match(/[0-9a-z]+$/i)[0] || '';
