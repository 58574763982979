import { call, put, takeLatest } from 'redux-saga/effects';
import { UNAVAILABILITY, TIME_OFF } from 'src/common/constants';
import {
  PEOPLE_ADD_UNAVAILABILITY_BEGIN,
  PEOPLE_ADD_UNAVAILABILITY_SUCCESS,
  PEOPLE_ADD_UNAVAILABILITY_FAILURE,
  PEOPLE_ADD_UNAVAILABILITY_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';
import { handleZeroPercentBlock } from '../../allocations/utils/allocationUtils';

export function addUnavailability(accountId, personId, data, analyticsPayload) {
  return {
    type: PEOPLE_ADD_UNAVAILABILITY_BEGIN,
    accountId,
    personId,
    data,
    analyticsPayload,
  };
}

export function dismissAddUnavailabilityError() {
  return {
    type: PEOPLE_ADD_UNAVAILABILITY_DISMISS_ERROR,
  };
}

export function* doAddUnavailability(action) {
  const { accountId, personId, data, analyticsPayload } = action;
  let res;
  try {
    res = yield call(protectedJsonPost, `accounts/${accountId}/persons/${personId}/unavailabilities`, data);
  } catch (err) {
    yield put({
      type: PEOPLE_ADD_UNAVAILABILITY_FAILURE,
      data: { error: err },
    });
    return;
  }
  yield put({
    type: PEOPLE_ADD_UNAVAILABILITY_SUCCESS,
    data: res,
    personId,
    analyticsPayload,
  });
}

export function* watchAddUnavailability() {
  yield takeLatest(PEOPLE_ADD_UNAVAILABILITY_BEGIN, doAddUnavailability);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_ADD_UNAVAILABILITY_BEGIN:
      return {
        ...state,
        addUnavailabilityPending: true,
        addUnavailabilityError: null,
      };

    case PEOPLE_ADD_UNAVAILABILITY_SUCCESS: {
      const { data, personId } = action;
      const { filteredPeople, unavailabilities, personSelections } = state;
      const newUnavailability = {
        ...data[0],
        __typename: UNAVAILABILITY,
      };

      const newFilteredPeople = filteredPeople.map((person) => {
        if (person.id === personId) {
          const pous = person.unavailabilities || [];
          const { projectAllocations } = person;
          return {
            ...person,
            ...(newUnavailability.rangeType !== TIME_OFF && {
              projectAllocations: handleZeroPercentBlock(projectAllocations, data),
            }),
            unavailabilities: [...pous, newUnavailability],
          };
        }
        return person;
      });

      let personFound = false;
      const newUnavailabilities = unavailabilities || [];
      const newPous = newUnavailabilities.map((pous) => {
        if (personId === pous.personId) {
          personFound = true;
          return {
            ...pous,
            unavailabilities: [...pous.unavailabilities, newUnavailability],
          };
        }
        return pous;
      });

      if (!personFound) {
        newPous.push({
          state: 'Deactivated',
          personId,
          unavailabilities: [newUnavailability],
        });
      }

      const newPersonSelections = { ...personSelections };
      Object.keys(newPersonSelections).forEach((selectionId) => {
        const selectedPerson = { ...newPersonSelections[selectionId] };
        if (selectedPerson && selectedPerson.id === personId) {
          selectedPerson.unavailabilities = [...selectedPerson.unavailabilities, newUnavailability];
        }

        newPersonSelections[selectionId] = selectedPerson;
      });

      return {
        ...state,
        unavailabilities: newPous,
        addUnavailabilityPending: false,
        addUnavailabilityError: null,
        filteredPeople: newFilteredPeople,
        personSelections: newPersonSelections,
      };
    }

    case PEOPLE_ADD_UNAVAILABILITY_FAILURE:
      return {
        ...state,
        addUnavailabilityPending: false,
        addUnavailabilityError: action.data.error,
      };

    case PEOPLE_ADD_UNAVAILABILITY_DISMISS_ERROR:
      return {
        ...state,
        addUnavailabilityError: null,
      };

    default:
      return state;
  }
}
