import initialState from './initialState';
import { reducer as getFilteredHourlyPersonsReducer } from './getFilteredHourlyPersons';
import { reducer as addHourlyPersonReducer } from './addHourlyPerson';
import { reducer as getRequestersReducer } from './getRequesters';
import { reducer as assignRequesterReducer } from './assignRequester';
import { reducer as deleteRequesterReducer } from './deleteRequester';
import { reducer as getHourlyPersonByIdReducer } from './getHourlyPersonById';
import { reducer as clearSelectedHourlyPersonReducer } from './clearSelectedHourlyPerson';
import { reducer as getFilteredRequestsReducer } from './getFilteredRequests';
import { reducer as updateHourlyPersonReducer } from './updateHourlyPerson';
import { reducer as updateHourlyPersonFieldValueReducer } from './updateHourlyPersonFieldValue';
import { reducer as getHourlyPersonFieldValuesReducer } from './getHourlyPersonFieldValues';
import { reducer as addHourlyRequestReducer } from './addHourlyRequest';
import { reducer as getHourlyPeopleFieldValuesReducer } from './getHourlyPeopleFieldValues';
import { reducer as getRequestByIdReducer } from './getRequestById';
import { reducer as getBestMatchesReducer } from './getBestMatches';
import { reducer as clearSelectedRequestReducer } from './clearSelectedRequest';
import { reducer as deactivateHourlyPersonReducer } from './deactivateHourlyPerson';
import { reducer as assignRequestReducer } from './assignRequest';
import { reducer as sendNotificationReducer } from './sendNotification';
import { reducer as removeAssigneeReducer } from './removeAssignee';
import { reducer as deleteRequestReducer } from './deleteRequest';
import { reducer as updateHourlyRequestReducer } from './updateHourlyRequest';

const reducers = [
  getFilteredHourlyPersonsReducer,
  addHourlyPersonReducer,
  getRequestersReducer,
  assignRequesterReducer,
  deleteRequesterReducer,
  getHourlyPersonByIdReducer,
  clearSelectedHourlyPersonReducer,
  getFilteredRequestsReducer,
  updateHourlyPersonReducer,
  updateHourlyPersonFieldValueReducer,
  getHourlyPersonFieldValuesReducer,
  addHourlyRequestReducer,
  getHourlyPeopleFieldValuesReducer,
  getRequestByIdReducer,
  getBestMatchesReducer,
  clearSelectedRequestReducer,
  deactivateHourlyPersonReducer,
  assignRequestReducer,
  sendNotificationReducer,
  removeAssigneeReducer,
  deleteRequestReducer,
  updateHourlyRequestReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
