import React from 'react';
import {
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';

export default function SelectAll({
  selectedAll,
  selectedSome,
  onSelectAll,
}) {
  return (
    <FormControlLabel
      label="Select all"
      labelPlacement="start"
      onChange={onSelectAll}
      classes={{ label: 'select-label' }}
      control={(
        <Checkbox
          classes={{ root: 'checkbox' }}
          checked={selectedSome || selectedAll}
          indeterminate={selectedSome}
          color="primary"
        />
    )}
    />
  );
}

SelectAll.propTypes = {
  onSelectAll: PropTypes.func.isRequired,
  selectedSome: PropTypes.bool.isRequired,
  selectedAll: PropTypes.bool.isRequired,
};
