import moment from 'moment';

import {
  SELF_PERFORM_ADD_HOURLY_PERSON_SUCCESS,
  SELF_PERFORM_UPDATE_HOURLY_PERSON_SUCCESS,
  SELF_PERFORM_UPDATE_HOURLY_PERSON_FIELD_VALUE_SUCCESS,
  SELF_PERFORM_DEACTIVATE_HOURLY_PERSON_SUCCESS,
  SELF_PERFORM_ADD_HOURLY_REQUEST_SUCCESS,
  SELF_PERFORM_ASSIGN_REQUEST_SUCCESS,
  SELF_PERFORM_DELETE_REQUEST_SUCCESS,
  SELF_PERFORM_UPDATE_HOURLY_REQUEST_SUCCESS,
  SELF_PERFORM_NOTIFICATION_SENT_SUCCESS,
  SELF_PERFORM_REJECT_REQUEST_SUCCESS,
  SELF_PERFORM_REOPEN_REQUEST_SUCCESS,
  SELF_PERFORM_REMOVE_ASSIGNEE_SUCCESS,
  SELF_PERFORM_SEARCH_BEST_MATCHES_SUCCESS,
} from 'src/features/self-perform/redux/constants';

import { DATE_DISPLAY_FORMAT } from 'src/common/constants';
import { REQUIRED_SKILLS } from 'src/filters/constants';
import {
  HOURLY_PERSON_ADDED,
  HOURLY_PERSON_UPDATED,
  HOURLY_REQUEST_UPDATED,
  HOURLY_PERSON_DEACTIVATED,
  REQUEST_ADDED,
  REQUEST_DELETED,
  REQUEST_ASSIGNMENT_ADDED,
  REQUEST_ASSIGNMENT_DELETED,
  REQUEST_NOTIFIED,
  HOURLY_REQUEST_REJECTED,
  HOURLY_REQUEST_REOPENED,
  REQUEST_MATCHES_SEARCHED,
} from './constants';

const events = {
  [SELF_PERFORM_ADD_HOURLY_PERSON_SUCCESS]: {
    getEvent: () => HOURLY_PERSON_ADDED,
    getPayload: (state, action) => {
      const { data: { id, name } } = action;
      return {
        'Person name': name,
        'Person id': id,
      };
    },
  },
  [SELF_PERFORM_UPDATE_HOURLY_PERSON_SUCCESS]: {
    getEvent: () => HOURLY_PERSON_UPDATED,
    getPayload: (state, action) => {
      const {
        hourlyPersonId,
        hourlyPersonName,
        formData,
        updatedFrom,
      } = action;
      return {
        // in case "name" is the field that's being updated, use it; if not, default to the passed property
        'Person name': formData.name || hourlyPersonName,
        'Person id': hourlyPersonId,
        'Field updated': Object.keys(formData)[0],
        'Updated from': updatedFrom,
      };
    },
  },
  [SELF_PERFORM_UPDATE_HOURLY_PERSON_FIELD_VALUE_SUCCESS]: {
    getEvent: () => HOURLY_PERSON_UPDATED,
    getPayload: (state, action) => {
      const {
        hourlyPersonId,
        data,
        updatedFrom,
        hourlyPersonName,
      } = action;

      return {
        // in case "name" is the field that's being updated, use it; if not, default to the passed property
        'Person name': hourlyPersonName,
        'Person id': hourlyPersonId,
        'Field updated': data.name,
        'Updated from': updatedFrom,
      };
    },
  },
  [SELF_PERFORM_DEACTIVATE_HOURLY_PERSON_SUCCESS]: {
    getEvent: () => HOURLY_PERSON_DEACTIVATED,
    getPayload: (state, action) => {
      const { hourlyPersonId, hourlyPersonName } = action;

      return {
        'Person name': hourlyPersonName,
        'Person id': hourlyPersonId,
      };
    },
  },

  [SELF_PERFORM_ADD_HOURLY_REQUEST_SUCCESS]: {
    getEvent: () => REQUEST_ADDED,
    getPayload: (state, action) => {
      const { data, requester, project, viewAddedFrom } = action;
      const { description, endDate, startDate, requiredSkills = [], workDays, note } = data[0];

      return {
        'Requester name': requester?.name || '',
        'Requester id': requester?.id || '',
        'Project name': project.name,
        'Project id': project.value,
        Description: description,
        'Skill set': requiredSkills.join(', '),
        'Start date': startDate,
        'End date': endDate,
        'Working days': workDays.join(', '),
        Notes: note,
        'View Added From': viewAddedFrom,
      };
    },
  },

  [SELF_PERFORM_ASSIGN_REQUEST_SUCCESS]: {
    getEvent: () => REQUEST_ASSIGNMENT_ADDED,
    getPayload: (state, action) => {
      const { requestId, projectId, personId, mixpanelData } = action;
      const { projectName, personName } = mixpanelData;

      return {
        'Request id': requestId,
        'Project name': projectName,
        'Project id': projectId,
        'Person name': personName,
        'Person id': personId,
        'View added from': 'Request List',
      };
    },
  },
  [SELF_PERFORM_REMOVE_ASSIGNEE_SUCCESS]: {
    getEvent: () => REQUEST_ASSIGNMENT_DELETED,
    getPayload: (state, action) => {
      const { requestId, projectId, mixpanelData } = action;
      const { projectName, requestState } = mixpanelData;

      return {
        'Request id': requestId,
        'Project name': projectName,
        'Project id': projectId,
        Status: requestState,
      };
    },
  },
  [SELF_PERFORM_DELETE_REQUEST_SUCCESS]: {
    getEvent: () => REQUEST_DELETED,
    getPayload: (state, action) => {
      const { mixpanelData, projectId } = action;
      const { projectName, requester, note, status } = mixpanelData;

      return {
        'Requester name': requester?.name,
        'Requester id': requester?.id,
        'Project name': projectName,
        'Project id': projectId,
        Notes: note,
        Status: status,
      };
    },
  },
  [SELF_PERFORM_NOTIFICATION_SENT_SUCCESS]: {
    getEvent: () => REQUEST_NOTIFIED,
    getPayload: (state, action) => {
      const { requestId, projectId, personId, message, mixpanelData } = action;
      const {
        projectName,
        personName,
        requestState,
        requester,
        defaultMessage,
      } = mixpanelData;

      return {
        'Request id': requestId,
        'Project name': projectName,
        'Project id': projectId,
        Status: requestState,
        'Notification type': 'Text message',
        'Assignee id': personId,
        'Assignee name': personName,
        'Requester id': requester?.id || '',
        'Requester name': requester?.name || '',
        'Default message': defaultMessage,
        'Additional text': message,
      };
    },
  },
  [SELF_PERFORM_UPDATE_HOURLY_REQUEST_SUCCESS]: {
    getEvent: () => HOURLY_REQUEST_UPDATED,
    getPayload: (state, action) => {
      const { requestId, projectId, projectName, state: status, data } = action;

      const [fieldUpdated, value] = Object.entries(data)[0];

      let newValue;

      if (['startDate', 'endDate'].includes(fieldUpdated)) {
        newValue = moment(value).format(DATE_DISPLAY_FORMAT);
      } else if (fieldUpdated === REQUIRED_SKILLS) {
        newValue = value.join(', ');
      } else {
        newValue = value;
      }

      return {
        'Request id': requestId,
        'Project name': projectName,
        'Project id': projectId,
        'Field updated': fieldUpdated,
        'Updated value': newValue,
        Status: status,
      };
    },
  },
  [SELF_PERFORM_REJECT_REQUEST_SUCCESS]: {
    getEvent: () => HOURLY_REQUEST_REJECTED,
    getPayload: (state, action) => {
      const { requestId, projectName, projectId, state: prevStatus } = action;

      return {
        'Request id': requestId,
        'Project name': projectName,
        'Project id': projectId,
        'Previous Status': prevStatus,
      };
    },
  },
  [SELF_PERFORM_REOPEN_REQUEST_SUCCESS]: {
    getEvent: () => HOURLY_REQUEST_REOPENED,
    getPayload: (state, action) => {
      const { requestId, projectName, projectId } = action;

      return {
        'Request id': requestId,
        'Project name': projectName,
        'Project id': projectId,
      };
    },
  },
  [SELF_PERFORM_SEARCH_BEST_MATCHES_SUCCESS]: {
    getEvent: () => REQUEST_MATCHES_SEARCHED,
    getPayload: (state, action) => {
      const { requestId, projectName, projectId, searchString } = action;

      return {
        'Request id': requestId,
        'Project name': projectName,
        'Project id': projectId,
        'Search text': searchString,
      };
    },
  },
};

export default events;
