import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Popper } from '@material-ui/core';
import { ArrowRight } from '@material-ui/icons';

function SelectPhase({
  phase,
  onSelect,
}) {
  const [openPopper, setOpenPopper] = useState(false);
  const selectEl = useRef(null);

  const onPhaseClick = () => onSelect(phase);

  const onSubClick = id => () => {
    onSelect({
      ...phase,
      subPhases: phase.subPhases.filter(sub => sub.id === id || sub.selected),
    }, phase.subPhases.find(sub => sub.id === id));
  };

  const onMouseEnter = () => setOpenPopper(true);
  const onMouseLeave = () => setOpenPopper(false);

  if (!phase.subPhases.length) {
    return (
      <button
        type="button"
        className={classNames('phase-select', { disabled: phase.selected })}
        onClick={onPhaseClick}
      >
        <div>{phase.name}</div>
      </button>
    );
  }

  return (
    <div className="phases-select-phase">
      <div
        ref={selectEl}
        className="phase-select"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div>{phase.name}</div>
        <ArrowRight />
      </div>
      <Popper
        className="subphases-select-menu"
        anchorEl={selectEl.current}
        open={openPopper}
        placement="right"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div>
          <button
            type="button"
            className={classNames('phase-select', 'subphase-select', { disabled: phase.selected })}
            onClick={onPhaseClick}
          >
            <div className="subphase-select">{phase.name}</div>
          </button>
          {phase.subPhases.map(sub => (
            <button
              key={sub.id}
              type="button"
              className={classNames('phase-select', 'subphase-select', 'sub-phase', { disabled: sub.selected })}
              onClick={onSubClick(sub.id)}
            >
              <div className="subphase-select">{sub.name}</div>
            </button>
          ))}
        </div>
      </Popper>
    </div>
  );
}

SelectPhase.propTypes = {
  phase: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default SelectPhase;
