import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PEOPLE_GET_PERSON_UNAVAILABILITIES_BEGIN,
  PEOPLE_GET_PERSON_UNAVAILABILITIES_SUCCESS,
  PEOPLE_GET_PERSON_UNAVAILABILITIES_FAILURE,
  PEOPLE_GET_PERSON_UNAVAILABILITIES_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';

export function getPersonUnavailabilities(accountId, activeState) {
  return {
    type: PEOPLE_GET_PERSON_UNAVAILABILITIES_BEGIN,
    accountId,
    activeState,
  };
}

export function dismissGetPersonUnavailabilitiesError() {
  return {
    type: PEOPLE_GET_PERSON_UNAVAILABILITIES_DISMISS_ERROR,
  };
}

export function* doGetPersonUnavailabilities(action) {
  const { accountId, activeState } = action;
  let res;
  try {
    res = yield call(protectedJsonGet, `accounts/${accountId}/persons/unavailabilities?state=${activeState}`);
  } catch (err) {
    yield put({
      type: PEOPLE_GET_PERSON_UNAVAILABILITIES_FAILURE,
      data: { error: err },
    });
    return;
  }
  yield put({
    type: PEOPLE_GET_PERSON_UNAVAILABILITIES_SUCCESS,
    data: res,
  });
}

export function* watchGetPersonUnavailabilities() {
  yield takeLatest(PEOPLE_GET_PERSON_UNAVAILABILITIES_BEGIN, doGetPersonUnavailabilities);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_GET_PERSON_UNAVAILABILITIES_BEGIN:
      return {
        ...state,
        getPersonUnavailabilitiesPending: true,
        getPersonUnavailabilitiesError: null,
      };

    case PEOPLE_GET_PERSON_UNAVAILABILITIES_SUCCESS:
      return {
        ...state,
        unavailabilities: action.data,
        getPersonUnavailabilitiesPending: false,
        getPersonUnavailabilitiesError: null,
      };

    case PEOPLE_GET_PERSON_UNAVAILABILITIES_FAILURE:
      return {
        ...state,
        getPersonUnavailabilitiesPending: false,
        getPersonUnavailabilitiesError: action.data.error,
      };

    case PEOPLE_GET_PERSON_UNAVAILABILITIES_DISMISS_ERROR:
      return {
        ...state,
        getPersonUnavailabilitiesError: null,
      };

    default:
      return state;
  }
}
