import { TOASTS_HIDE_SUCCESS_TOAST } from './constants';

export function hideSuccessToast() {
  return {
    type: TOASTS_HIDE_SUCCESS_TOAST,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case TOASTS_HIDE_SUCCESS_TOAST:
      return {
        ...state,
        showSuccess: false,
      };

    default:
      return state;
  }
}
