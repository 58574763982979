import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'react-fast-compare';
import pluralize from 'pluralize';
import { connect } from 'react-redux';
import { Button, IconButton } from '@material-ui/core';
import { AddCircle, Cancel } from '@material-ui/icons';
import { validateBasicInputs, validatePhases } from 'src/utils/validators';
import { ValidatedForm } from '../wrapped-components';
import { SelectPhaseMenu } from '../phases';
import { reorderPhases } from '../phases/utils/phaseUtils';
import { MIN_API_CONFIGURABLE_DATE } from '../../common/constants';

export class AddPhasesModal extends PureComponent {
  static propTypes = {
    inputs: PropTypes.array.isRequired,
    validateProjectDates: PropTypes.func.isRequired,
    onValueChanged: PropTypes.func.isRequired,
    projectValues: PropTypes.object.isRequired,
    phaseValues: PropTypes.object.isRequired,
    setValid: PropTypes.func.isRequired,
    selectedPhases: PropTypes.array.isRequired,
    updatePhases: PropTypes.func.isRequired,
    phases: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);

    const { projectValues, selectedPhases } = props;

    this.state = {
      phaseInputs: this.generatePhaseInputs(projectValues, selectedPhases),
      showAddPhase: false,
      addPhaseButtonNode: null,
      errors: {},
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { projectValues, selectedPhases } = this.props;

    if (!deepEqual(projectValues, nextProps.projectValues) || !deepEqual(selectedPhases, nextProps.selectedPhases)) {
      this.setState({
        phaseInputs: this.generatePhaseInputs(nextProps.projectValues, nextProps.selectedPhases),
      }, this.validatePhases);
    }
  }

  generatePhaseInputs = (projectValues, selectedPhases) => {
    const minDate = projectValues['Start date'] || MIN_API_CONFIGURABLE_DATE;
    const maxDate = projectValues['End date'];
    const phaseDateInputs = [
      {
        required: true,
        label: '',
        type: 'date',
        placeholder: 'Start date',
        minDate,
        maxDate,
      },
      {
        required: true,
        label: '',
        type: 'date',
        placeholder: 'End date',
        minDate,
        maxDate,
      },
    ];

    const subPhaseInputs = [
      {
        label: '',
        type: 'date',
        placeholder: 'Start date*',
      },
      {
        label: '',
        type: 'date',
        placeholder: 'End date*',
      },
    ];

    let inputs = [];

    selectedPhases.forEach((phase) => {
      inputs = [
        ...inputs,
        {
          ...phaseDateInputs[0],
          name: `Start date-${phase.id}`,
        },
        {
          ...phaseDateInputs[1],
          name: `End date-${phase.id}`,
        },
      ];

      phase.subPhases.forEach((sub) => {
        inputs = [
          ...inputs,
          {
            ...subPhaseInputs,
            required: true,
            name: `Start date-${sub.id}`,
          },
          {
            ...subPhaseInputs,
            required: true,
            name: `End date-${sub.id}`,
          },
        ];
      });
    });

    return {
      inputs,
      phaseDateInputs,
      subPhaseInputs,
    };
  }

  toggleAddPhase = (evt) => {
    const { showAddPhase } = this.state;
    this.setState({
      showAddPhase: !showAddPhase,
      addPhaseButtonNode: evt.currentTarget,
    });
  }

  closeAddPhase = () => {
    this.setState({ showAddPhase: false });
  }

  removePhase = (evt) => {
    const { phaseValues, selectedPhases, updatePhases } = this.props;
    const phaseId = parseInt(evt.currentTarget.dataset.id, 10);
    const phaseToRemove = selectedPhases.find(phase => phase.id === phaseId);

    const newPhaseValues = {
      ...phaseValues,
      [`Start date-${phaseId}`]: null,
      [`End date-${phaseId}`]: null,
    };

    phaseToRemove.subPhases.forEach((sub) => {
      newPhaseValues[`Start date-${sub.id}`] = null;
      newPhaseValues[`End date-${sub.id}`] = null;
    });

    const newSelected = selectedPhases.filter(phase => phase.id !== phaseId);

    updatePhases(newPhaseValues, newSelected);
  }

  removeSubPhase = (evt) => {
    const { phaseValues, selectedPhases, updatePhases } = this.props;
    const phaseId = parseInt(evt.currentTarget.dataset.id, 10);
    const subId = parseInt(evt.currentTarget.dataset.subid, 10);
    const newPhaseValues = {
      ...phaseValues,
      [`Start date-${subId}`]: null,
      [`End date-${subId}`]: null,
    };

    const newSelected = selectedPhases.map((phase) => {
      const newPhase = { ...phase };
      if (newPhase.id === phaseId) {
        newPhase.subPhases = newPhase.subPhases.filter(sub => sub.id !== subId);
      }
      return newPhase;
    });

    updatePhases(newPhaseValues, newSelected);
  }

  validateDates = changedValues => this.validatePhases({}, changedValues);

  validatePhases = (changedValues = {}, changedProjectDates = {}) => {
    const { validateProjectDates, setValid, projectValues, phaseValues, updatePhases, selectedPhases } = this.props;
    const { phaseInputs } = this.state;
    const newValues = { ...phaseValues, ...changedValues };
    const newProjectValues = deepEqual(changedProjectDates, {}) ? projectValues : changedProjectDates;
    const errors = {
      ...validateBasicInputs(phaseInputs.inputs, newValues),
      ...validateProjectDates(changedProjectDates),
      ...validatePhases(selectedPhases, newValues, newProjectValues),
    };

    this.setState({ errors });
    updatePhases(newValues, selectedPhases);
    setValid(Object.keys(errors).length === 0);
    return errors;
  }

  onSelect = (phase) => {
    const { phaseValues, updatePhases, selectedPhases, phases } = this.props;
    let newSelectedPhases = [...selectedPhases];

    if (phase.selected) {
      newSelectedPhases = selectedPhases.filter(p => p.id !== phase.id);
    }

    newSelectedPhases.push(phase);
    updatePhases(phaseValues, reorderPhases(phases, newSelectedPhases));
    this.setState({ showAddPhase: false });
  }

  removeAllSelected = () => {
    const { updatePhases } = this.props;
    updatePhases({}, []);
  }

  renderPhases = () => {
    const { projectValues, phaseValues, selectedPhases } = this.props;
    const { phaseInputs, errors } = this.state;

    if (!selectedPhases.length) return <p className="phases-empty">No phases have been added to the project</p>;

    return selectedPhases.map(phase => (
      <div key={phase.id} className="phase-row">
        <div className="phase-box">
          <div>
            <h2>{phase.name}</h2>
            <ValidatedForm
              inputs={phaseInputs.phaseDateInputs.map(input => ({
                ...input,
                name: `${input.placeholder}-${phase.id}`,
                value: phaseValues[`${input.placeholder}-${phase.id}`],
              }))}
              validate={this.validatePhases}
              errors={errors}
            />
          </div>
          {phase.subPhases.length > 0 && phase.subPhases.map((sub) => {
            const inputs = phaseInputs.subPhaseInputs.map(input => ({
              ...input,
              name: `${input.placeholder.replace('*', '')}-${sub.id}`,
              minDate: phaseValues[`Start date-${phase.id}`] || projectValues['Start date'] || MIN_API_CONFIGURABLE_DATE,
              maxDate: phaseValues[`End date-${phase.id}`] || projectValues['End date'],
              value: phaseValues[`${input.placeholder.replace('*', '')}-${sub.id}`],
            }));

            return (
              <div key={sub.id} className="subphase-container">
                <h2>{sub.name}</h2>
                <ValidatedForm
                  inputs={inputs}
                  validate={this.validatePhases}
                  errors={errors}
                />
                <IconButton data-id={phase.id} data-subid={sub.id} className="remove-button" onClick={this.removeSubPhase}>
                  <Cancel />
                </IconButton>
              </div>
            );
          })}
        </div>
        <IconButton data-id={phase.id} className="remove-button" onClick={this.removePhase}>
          <Cancel />
        </IconButton>
      </div>
    ));
  }

  render() {
    const { inputs, onValueChanged, selectedPhases } = this.props;
    const { showAddPhase, addPhaseButtonNode } = this.state;

    return (
      <div className="projects-add-phases-modal">
        <div className="date-fields">
          <h1>Date Fields</h1>
          <ValidatedForm
            inputs={inputs}
            validate={this.validateDates}
            onValueChanged={onValueChanged}
          />
        </div>
        <div className="phases">
          <div className="phase-header">
            <h1>Phases</h1>
            {selectedPhases.length > 0 && (
              <div className="remove-all-container">
                {`${selectedPhases.length} ${pluralize('Phase')}`}
                <Button
                  className="remove-all"
                  onClick={this.removeAllSelected}
                  disableRipple
                >
                  Remove all
                </Button>
              </div>
            )}
          </div>
          <div className="phases-container">{this.renderPhases()}</div>
          <Button
            className="add-phase-button"
            onClick={this.toggleAddPhase}
            disableRipple
          >
            <AddCircle />
            Add phase
          </Button>
          <SelectPhaseMenu
            anchorEl={addPhaseButtonNode}
            open={showAddPhase}
            onClose={this.closeAddPhase}
            selectedPhases={selectedPhases}
            onSelect={this.onSelect}
          />
        </div>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps({ accountSettings }) {
  return {
    phases: accountSettings.phases,
  };
}

export default connect(
  mapStateToProps,
)(AddPhasesModal);
