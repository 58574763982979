import { QUERIES_SET_FILTER_QUERY } from './constants';

export function setFilterQuery(id, query, columnStorageKey) {
  if (columnStorageKey) {
    localStorage.setItem(columnStorageKey, JSON.stringify(query));
  }

  return {
    type: QUERIES_SET_FILTER_QUERY,
    id,
    query,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case QUERIES_SET_FILTER_QUERY: {
      const { id, query } = action;
      const newState = { ...state };
      newState[id] = {
        ...newState[id],
        filter: query,
      };
      return newState;
    }

    default:
      return state;
  }
}
