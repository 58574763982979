import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { Button } from '@bridgit/foundation';
import deepEqual from 'react-fast-compare';
import pluralize from 'pluralize';
import { cloneDeep } from '../../utils/miscUtils';
import { TOGGLE_REQUIRED_SYSTEM_FIELDS, EDITABLE_SYSTEM_FIELDS } from './redux/constants';
import { Confirm } from '../common';
import { CustomField, CustomFieldAdd, CustomFieldEdit } from '.';
import { EditControls } from '../wrapped-components';
import { SETTINGS_SIDE_TABS, SETTINGS_TABS, DISABLED_FIELD_TYPES } from './common/constants';
import { PEOPLE_DISPLAY_COST_RATE } from '../people/constants';

const { CUSTOM, SYSTEM } = SETTINGS_SIDE_TABS;
const { SELF_PERFORM, PEOPLE } = SETTINGS_TABS;

export class ProfileFields extends PureComponent {
  static propTypes = {
    fieldSet: PropTypes.arrayOf(PropTypes.object),
    profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
    additionalFields: PropTypes.arrayOf(PropTypes.object), // eslint-disable-line react/no-unused-prop-types
    accountId: PropTypes.number.isRequired,
    updateFields: PropTypes.func.isRequired,
    removeField: PropTypes.func.isRequired,
    selectList: PropTypes.func.isRequired,
    onAddFields: PropTypes.func.isRequired,
    onEditFields: PropTypes.func.isRequired,
    onDeleteFields: PropTypes.func.isRequired,
    newFields: PropTypes.array.isRequired,
    disabled: PropTypes.bool.isRequired,
    activeTab: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    requestPending: PropTypes.bool,
    activeSideTab: PropTypes.string.isRequired,
    handleClickManageDefaultsButton: PropTypes.func.isRequired,
  };

  static defaultProps = {
    fieldSet: [],
    additionalFields: [],
    loading: true,
    requestPending: false,
  }

  constructor(props) {
    super(props);

    this.fieldContainerRef = React.createRef();
    const allFields = this.mapFieldsToState(props);

    this.state = {
      ...allFields,
      showConfirm: false,
      tempField: {},
      showDeleteConfirm: false,
      conflictCount: 0,
      editField: null,
      addNewErrorScrollFlag: false,
      showRequiredToggle: true,
      showPrivateToggle: true,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { requestPending, fieldSet, activeSideTab } = this.props;

    const fieldsChanged = !deepEqual(fieldSet, nextProps.fieldSet);
    const doneRequest = requestPending && !nextProps.requestPending;

    if (fieldsChanged || doneRequest) {
      const newState = this.mapFieldsToState(nextProps);

      newState.tempField = {};
      newState.showDeleteConfirm = false;
      newState.conflictCount = 0;
      newState.editField = null;

      this.setState(newState);
    }

    if (activeSideTab !== nextProps.activeSideTab) {
      this.setState({
        editField: null,
      });
    }
  }

  componentDidUpdate() {
    this.updateScrollState();
  }

  updateScrollState = () => {
    const { addNewErrorScrollFlag } = this.state;

    if (addNewErrorScrollFlag) {
      this.onAddNewErrorScroll();
      this.setState({ addNewErrorScrollFlag: false });
    }
  }

  setEditField = (field) => {
    this.setState({
      editField: field.name,
      tempField: cloneDeep(field),
      showRequiredToggle: (!field.isSystem && field.type !== 'Boolean') || (field.isSystem && TOGGLE_REQUIRED_SYSTEM_FIELDS.includes(field.name)),
      showPrivateToggle: !(field.isSystem && ['Address', 'Cell number'].includes(field.name)),
    });
  }

  updateTempField = (tempField) => {
    this.setState({
      tempField,
    });
  }

  deleteTempField = () => {
    const { tempField } = this.state;
    this.setState({
      tempField: { id: tempField.id, deleted: true },
    });
  }

  mapFieldsToState = (props) => {
    const systemFields = props.fieldSet.filter(field => field.isSystem);
    systemFields.unshift(...props.additionalFields);

    return {
      systemFields,
      fields: props.fieldSet.filter(field => !field.isSystem).map(field => ({
        ...field,
        values: field.definedValues,
      })),
    };
  }

  validateTrimName = (index) => {
    const { updateFields, newFields } = this.props;
    const errorFields = cloneDeep(newFields);
    const field = errorFields[index];

    field.name = field.name.trim();

    if (!field.name) {
      field.nameError = 'empty';
      updateFields(errorFields);
      return false;
    }
    if (!this.isUniqueName(field.name)) {
      field.nameError = 'unique';
      updateFields(errorFields);
      return false;
    }
    updateFields(errorFields);
    return true;
  }

  validate = () => {
    const { updateFields, newFields } = this.props;
    let hasError = false;

    const errorFields = newFields.map((field) => {
      const clonedField = { ...field };
      if (!clonedField.name) {
        clonedField.nameError = 'empty';
        hasError = true;
      } else if (!this.isUniqueName(clonedField.name)) {
        clonedField.nameError = 'unique';
        hasError = true;
      }

      if (!clonedField.type) {
        clonedField.typeError = 'type';
        hasError = true;
      } else if ((clonedField.type === 'SingleSelect' || clonedField.type === 'MultiSelect') && clonedField.definedValues.length === 0) {
        clonedField.listError = 'list';
        hasError = true;
      }

      return clonedField;
    });

    if (hasError) {
      updateFields(errorFields);
      return false;
    }
    return true;
  }

  isUniqueName = (name) => {
    const lowerName = name.toLowerCase();
    const { newFields } = this.props;
    const { fields, systemFields } = this.state;
    const reservedFieldNames = ['notes'];
    const isCurrentField = [...fields, ...systemFields].find(field => field.name.toLowerCase() === lowerName);
    const newFieldMatches = newFields.filter(field => field.name.toLowerCase() === lowerName).length;
    return !isCurrentField && newFieldMatches === 1 && !reservedFieldNames.includes(lowerName);
  }

  onSubmit = () => {
    const { editField, tempField } = this.state;

    this.setState({ addNewErrorScrollFlag: true });

    if (!editField) {
      this.onAddSubmit();
    } else if (!tempField?.nameError) {
      this.onEditSubmit();
    }
  };

  onAddSubmit = () => {
    const { accountId, newFields, onAddFields } = this.props;
    if (this.validate()) {
      const cleanedFields = newFields.map(field => ({
        ...field,
        definedValues: (field.type === 'SingleSelect' || field.type === 'MultiSelect') ? field.definedValues : [],
      }));

      onAddFields(accountId, cleanedFields);
    }
  }

  onEditSubmit = () => {
    const { accountId, onEditFields, onDeleteFields } = this.props;
    const { tempField } = this.state;

    if (tempField.deleted) {
      const conflictCount = this.countFieldUse(tempField.id);
      if (conflictCount === 0) {
        onDeleteFields(accountId, [tempField.id]);
      } else {
        this.setState({
          showDeleteConfirm: true,
          conflictCount,
        });
      }
    } else {
      onEditFields(accountId, [tempField]);
    }
  }

  onTitleChange = (e, index) => {
    const { updateFields, newFields } = this.props;
    const clonedFields = cloneDeep(newFields);
    const field = clonedFields[index];
    const type = (field.type === 'SingleSelect' || field.type === 'MultiSelect') ? 'list' : '';

    field.name = e.currentTarget.value;
    field.nameError = false;

    updateFields(clonedFields, type);
  }

  onTypeChange = (e, index) => {
    const { updateFields, newFields } = this.props;
    const clonedFields = cloneDeep(newFields);
    const field = clonedFields[index];
    const type = (e.target.value === 'SingleSelect' || e.target.value === 'MultiSelect') ? 'list' : '';

    if (e.target.value === 'Boolean') {
      field.isRequired = false;
    }

    field.type = e.target.value;
    field.typeError = false;

    if (type !== 'list') {
      field.listError = false;
    }

    updateFields(clonedFields, type);
  }

  onRequiredChange = (index) => {
    const { updateFields, newFields } = this.props;
    const clonedFields = cloneDeep(newFields);
    const currentField = clonedFields[index];

    currentField.isRequired = !currentField.isRequired;
    updateFields(clonedFields);
  }

  onPrivateChange = (index) => {
    const { updateFields, newFields } = this.props;
    const clonedFields = cloneDeep(newFields);
    const currentField = clonedFields[index];

    currentField.isPrivate = !currentField.isPrivate;
    updateFields(clonedFields);
  }

  onCancel = () => {
    const { updateFields } = this.props;
    updateFields([], 'list');
    this.setState({
      showConfirm: false,
    });
  }

  onFieldDelete = () => {
    const { accountId, onDeleteFields } = this.props;
    const { tempField } = this.state;
    onDeleteFields(accountId, [tempField.id]);
  }

  countFieldUse = (fieldId) => {
    const { profiles } = this.props;
    let count = 0;

    profiles.forEach((profile) => {
      profile.fields.forEach((field) => {
        if (field.fieldId === fieldId) {
          if (field.type === 'Boolean') {
            if (field.values[0] === 'true') count += 1;
          } else {
            count += 1;
          }
        }
      });
    });

    return count;
  }

  cancelSwitch = () => {
    const { editField } = this.state;
    const { updateFields, newFields } = this.props;
    const isNewFieldsTouched = !!newFields.find(element => element.name || element.type || element.definedValues.length !== 0);
    if (!editField) {
      if (!isNewFieldsTouched) {
        updateFields([], undefined, this.scrollFieldIntoView);
      }
      this.setState({
        showConfirm: isNewFieldsTouched,
      });
    } else {
      this.setState({
        tempField: {},
        editField: null,
      });
    }
  }

  hideConfirm = () => {
    this.setState({
      showConfirm: false,
    });
  }

  hideDeleteConfirm = () => {
    this.setState({
      showDeleteConfirm: false,
      tempField: {},
      editField: null,
    });
  }

  createList = (index) => {
    const { newFields, selectList } = this.props;
    if (this.validateTrimName(index)) {
      selectList(newFields[index]);
    }
  }

  scrollFieldIntoView = () => {
    const position = this.fieldContainerRef.current.scrollHeight;

    if (this.fieldContainerRef.current.scrollTo) {
      this.fieldContainerRef.current.scrollTo({
        top: position,
        behavior: 'smooth',
      });
    } else {
      this.fieldContainerRef.current.scrollTop = position;
    }
  }

  onAddNewErrorScroll = () => {
    const { newFields } = this.props;

    let lastErrorIndex = -1;

    // find out the last error field's position
    for (let i = newFields.length - 1; i >= 0; i -= 1) {
      const field = newFields[i];
      if (field.nameError || field.typeError || field.listError) {
        lastErrorIndex = i;
        break;
      }
    }

    if (lastErrorIndex >= 0) {
      const lastErrorEle = document.getElementById(`CustomFieldAdd${lastErrorIndex}`);
      const style = lastErrorEle.currentStyle || window.getComputedStyle(lastErrorEle);
      const lastErrorElePosition = lastErrorEle.offsetHeight + lastErrorEle.offsetTop + parseInt(style.marginBottom, 10);
      const currentLastCellPosition = this.fieldContainerRef.current.clientHeight + this.fieldContainerRef.current.scrollTop + this.fieldContainerRef.current.offsetTop;
      const shouldScrollTo = lastErrorElePosition - this.fieldContainerRef.current.offsetTop - this.fieldContainerRef.current.clientHeight;
      const bottomToLastErrorEle = lastErrorElePosition - currentLastCellPosition;
      if (bottomToLastErrorEle > 0 || bottomToLastErrorEle < -(this.fieldContainerRef.current.clientHeight - lastErrorEle.offsetHeight - parseInt(style.marginTop, 10) - parseInt(style.marginBottom, 10))) {
        this.fieldContainerRef.current.scrollTo({
          top: shouldScrollTo,
          behavior: 'smooth',
        });
      }
    }
  }

  addField = () => {
    const { updateFields, newFields } = this.props;

    const newField = {
      name: '',
      type: '',
      definedValues: [],
      isRequired: false,
      isPrivate: false,
      isSystem: false,
    };

    updateFields([...newFields, newField], undefined, this.scrollFieldIntoView);
  }

  removeField = (index) => {
    const { removeField, newFields } = this.props;

    const field = newFields[index];
    const type = (field.type === 'SingleSelect' || field.type === 'MultiSelect') ? 'list' : '';
    removeField(index, type);
  }

  isDisabledField = () => {
    const { newFields, disabled } = this.props;
    const { editField } = this.state;
    const addingFields = newFields.length > 0;
    return disabled || addingFields || editField !== null;
  }

  renderFieldCount = count => <div className="field-count">{pluralize('field', count, true)}</div>;

  renderSystemFields = () => {
    const { systemFields, tempField, editField, showRequiredToggle, showPrivateToggle } = this.state;
    const { fieldSet, additionalFields, handleClickManageDefaultsButton } = this.props;
    const allFields = [...fieldSet, ...additionalFields];

    const visibleSystemFields = systemFields.filter(field => !field.hidden);

    return (
      <div className="system-wrap">
        {this.renderFieldCount(visibleSystemFields.length)}
        {visibleSystemFields.map((field) => {
          if (editField === field.name) {
            return (
              <CustomFieldEdit
                key={field.id || field.name}
                field={tempField}
                onUpdateField={this.updateTempField}
                onDeleteField={this.deleteTempField}
                showRequiredToggle={showRequiredToggle}
                showPrivateToggle={showPrivateToggle}
                currentId={field.id}
                allFields={allFields}
                isSystemField
              />
            );
          }
          return (
            <CustomField
              key={field.id}
              field={field}
              disabled={this.isDisabledField()}
              readOnly
              showEdit={EDITABLE_SYSTEM_FIELDS.includes(field.name)}
              onSetEdit={this.setEditField}
              onButtonClick={field.name === PEOPLE_DISPLAY_COST_RATE ? handleClickManageDefaultsButton : null}
            />
          );
        })}
      </div>
    );
  }

  renderSavedFields = () => {
    const { fields, tempField, editField, showRequiredToggle, showPrivateToggle } = this.state;
    const { fieldSet, additionalFields } = this.props;
    const allFields = [...fieldSet, ...additionalFields];

    return (
      <div className="custom-fields-wrap">
        {this.renderFieldCount(fields.length)}
        {fields.map((field) => {
          if (editField === field.name) {
            if (tempField.deleted) {
              return false;
            }

            return (
              <CustomFieldEdit
                key={field.id || field.name}
                field={tempField}
                onUpdateField={this.updateTempField}
                onDeleteField={this.deleteTempField}
                showRequiredToggle={showRequiredToggle}
                showPrivateToggle={showPrivateToggle}
                currentId={field.id}
                allFields={allFields}
              />
            );
          }
          return (
            <CustomField
              key={field.id || field.name}
              field={field}
              disabled={this.isDisabledField()}
              onSetEdit={this.setEditField}
              showEdit
              readOnly
            />
          );
        })}
      </div>
    );
  }

  renderNewFields = () => {
    const { newFields, disabled, activeTab } = this.props;
    const disabledFieldTypes = DISABLED_FIELD_TYPES[activeTab];

    return newFields.map((field, index) => (
      /* eslint-disable react/no-array-index-key */
      <CustomFieldAdd
        key={index}
        id={`CustomFieldAdd${index}`}
        field={field}
        onChange={e => this.onTitleChange(e, index)}
        onTypeChange={e => this.onTypeChange(e, index)}
        onBlur={() => this.validateTrimName(index)}
        onRequiredChange={() => this.onRequiredChange(index)}
        onPrivateChange={() => this.onPrivateChange(index)}
        onFieldRemove={() => this.removeField(index)}
        onCreateList={() => this.createList(index)}
        disabled={disabled}
        disabledFieldTypes={disabledFieldTypes}
      />
    ));
  }

  renderFields() {
    const { activeSideTab } = this.props;

    return (
      <div ref={this.fieldContainerRef} className="content-wrapper">
        {activeSideTab === SYSTEM
          ? this.renderSystemFields()
          : (
            <>
              {this.renderSavedFields()}
              {this.renderNewFields()}
            </>
          )}
      </div>
    );
  }

  render() {
    const { newFields, disabled, requestPending, loading, activeTab, activeSideTab } = this.props;
    const { fields, showConfirm, tempField, showDeleteConfirm, conflictCount, editField } = this.state;
    const hitFieldLimit = (fields.length + newFields.length) > 79;
    const deleteFieldConfirmLabel = activeTab === SELF_PERFORM ? PEOPLE : activeTab;
    const itemCount = pluralize(deleteFieldConfirmLabel, conflictCount, true).toLowerCase();

    return (
      <div className="account-settings-profile-fields">
        {showDeleteConfirm && (
          <Confirm
            headline="Delete custom field"
            acceptButtonText="Delete"
            cancelButtonText="Cancel"
            onCancel={this.hideDeleteConfirm}
            onAccept={this.onFieldDelete}
          >
            <div>{`Are you sure you want to delete this field? It is currently used for ${itemCount}. This action cannot be undone.`}</div>
          </Confirm>
        )}
        {showConfirm && (
          <Confirm
            headline="Cancel"
            acceptButtonText="Delete Changes"
            cancelButtonText="Cancel"
            onCancel={this.hideConfirm}
            onAccept={this.onCancel}
          >
            <div>Are you sure you want to cancel? All changes will be deleted.</div>
          </Confirm>
        )}
        {loading && (
          <LinearProgress variant="query" />
        )}
        {!loading && (
          <>
            {this.renderFields()}

            <div className="form__actions button-container">
              {activeSideTab === CUSTOM && (
                <Button
                  className="button--custom"
                  color="primary"
                  onClick={this.addField}
                  variant="contained"
                  disabled={hitFieldLimit || disabled || requestPending}
                >
                  Add custom field
                </Button>
              )}
              {(newFields.length > 0 || editField) && (
                <EditControls
                  pending={requestPending}
                  disabled={disabled || (!editField && !!tempField.nameError)}
                  secondaryDisabled={disabled}
                  primaryAction={this.onSubmit}
                  secondaryAction={this.cancelSwitch}
                />
              )}
            </div>
            {hitFieldLimit && <Typography variant="caption" color="primary">You have reached the maximum of 80 custom fields. Contact Bridgit Support for help.</Typography>}
          </>
        )}
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps({ common, accountSettings }) {
  const loading = accountSettings.collectFieldsPending;
  const updating = accountSettings.updatePersonFieldsPending || accountSettings.updateProjectFieldsPending || accountSettings.updateHourlyPersonFieldsPending;
  const deleting = accountSettings.removePersonFieldsPending || accountSettings.removeProjectFieldsPending || accountSettings.removeHourlyPersonFieldsPending;
  const adding = accountSettings.addPersonFieldsPending || accountSettings.addProjectFieldsPending || accountSettings.addHourlyPersonFieldsPending;
  const requestPending = updating || deleting || adding || loading;

  return {
    accountId: common.accountId,
    loading,
    requestPending,
  };
}

export default connect(
  mapStateToProps,
)(ProfileFields);
