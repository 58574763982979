const AUTOMATION_PROJECT_BREAKDOWN_TOTAL_ROLES = 'AUTOMATION_PROJECT_BREAKDOWN_TOTAL_ROLES';
const AUTOMATION_PROJECT_BREAKDOWN_FILLED_ROLES = 'AUTOMATION_PROJECT_BREAKDOWN_FILLED_ROLES';
const AUTOMATION_PROJECT_BREAKDOWN_UNFILLED_ROLES = 'AUTOMATION_PROJECT_BREAKDOWN_UNFILLED_ROLES';
const AUTOMATION_UNFILLED_ROLES_PROJECT_WITH_UNFILLED_ROLES = 'AUTOMATION_UNFILLED_ROLES_PROJECT_WITH_UNFILLED_ROLES';
const AUTOMATION_UNFILLED_ROLES_TOTAL_UNFILLED_ROLES = 'AUTOMATION_UNFILLED_ROLES_TOTAL_UNFILLED_ROLES';

export {
  AUTOMATION_PROJECT_BREAKDOWN_TOTAL_ROLES,
  AUTOMATION_PROJECT_BREAKDOWN_FILLED_ROLES,
  AUTOMATION_PROJECT_BREAKDOWN_UNFILLED_ROLES,
  AUTOMATION_UNFILLED_ROLES_PROJECT_WITH_UNFILLED_ROLES,
  AUTOMATION_UNFILLED_ROLES_TOTAL_UNFILLED_ROLES,
};
