import { call, put, takeLatest } from 'redux-saga/effects';
import {
  DASHBOARD_GET_IDLE_TIME_OVERVIEW_BEGIN,
  DASHBOARD_GET_IDLE_TIME_OVERVIEW_SUCCESS,
  DASHBOARD_GET_IDLE_TIME_OVERVIEW_FAILURE,
  DASHBOARD_GET_IDLE_TIME_OVERVIEW_DISMISS_ERROR,
} from './constants';
import graphApiClient from '../../../common/GraphAPIClient';
import { getIdleTimeOverviewQuery } from '../queries/getIdleTimeOverviewQuery';

export function getIdleTimeOverview(accountId, startDate, endDate) {
  const { query, variables } = getIdleTimeOverviewQuery(accountId, startDate, endDate);
  return {
    type: DASHBOARD_GET_IDLE_TIME_OVERVIEW_BEGIN,
    query,
    variables,
  };
}

export function dismissGetIdleTimeOverviewError() {
  return {
    type: DASHBOARD_GET_IDLE_TIME_OVERVIEW_DISMISS_ERROR,
  };
}

export function* doGetIdleTimeOverview(action) {
  const { query, variables } = action;
  let res;
  try {
    res = yield call(graphApiClient.query, { query, variables });
  } catch (err) {
    yield put({
      type: DASHBOARD_GET_IDLE_TIME_OVERVIEW_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: DASHBOARD_GET_IDLE_TIME_OVERVIEW_SUCCESS,
    data: res.data,
  });
}

export function* watchGetIdleTimeOverview() {
  yield takeLatest(DASHBOARD_GET_IDLE_TIME_OVERVIEW_BEGIN, doGetIdleTimeOverview);
}

export function reducer(state, action) {
  switch (action.type) {
    case DASHBOARD_GET_IDLE_TIME_OVERVIEW_BEGIN:
      return {
        ...state,
        getIdleTimeOverviewPending: true,
        getIdleTimeOverviewError: null,
      };

    case DASHBOARD_GET_IDLE_TIME_OVERVIEW_SUCCESS:
      return {
        ...state,
        idleTimeOverview: action?.data?.forecastingOverview?.items || [],
        getIdleTimeOverviewPending: false,
        getIdleTimeOverviewError: null,
      };

    case DASHBOARD_GET_IDLE_TIME_OVERVIEW_FAILURE:
      return {
        ...state,
        getIdleTimeOverviewPending: false,
        getIdleTimeOverviewError: action.data.error,
      };

    case DASHBOARD_GET_IDLE_TIME_OVERVIEW_DISMISS_ERROR:
      return {
        ...state,
        getIdleTimeOverviewError: null,
      };

    default:
      return state;
  }
}
