import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import moment from 'moment';
import { GanttBar } from 'src/features/gantt';
import { DATE_INPUT_FORMAT } from '../../common/constants';

export default function GanttBarRow({
  barData,
  dates,
  zoomConfig,
  dataMapper,
  onClick,
  columnWidth,
}) {
  return (
    <div className="gantt-gantt-bar-row row">
      {barData.map((bar) => {
        const mappedData = dataMapper(bar);
        const defaultDate = moment().format(DATE_INPUT_FORMAT);
        const {
          id,
          startDate,
          endDate,
          barColor,
          borderColor,
          borderType,
          textColor,
          shortLabel,
          label,
          customRender,
          customPopperContent,
        } = {
          id: shortid.generate(),
          startDate: defaultDate,
          endDate: defaultDate,
          ...mappedData,
        };

        if (customRender) {
          return customRender;
        }

        return (
          <GanttBar
            key={id}
            startDate={startDate}
            endDate={endDate}
            barColor={barColor}
            borderColor={borderColor}
            borderType={borderType}
            textColor={textColor}
            shortLabel={shortLabel}
            label={label}
            dates={dates}
            zoomConfig={zoomConfig}
            onClick={onClick(bar)}
            customPopperContent={customPopperContent}
            columnWidth={columnWidth}
          />
        );
      })}
    </div>
  );
}

GanttBarRow.propTypes = {
  barData: PropTypes.arrayOf(PropTypes.object).isRequired,
  dates: PropTypes.object.isRequired,
  zoomConfig: PropTypes.object.isRequired,
  dataMapper: PropTypes.func,
  onClick: PropTypes.func,
  columnWidth: PropTypes.number,
};

GanttBarRow.defaultProps = {
  dataMapper: data => data,
  onClick: () => null,
  columnWidth: undefined,
};
