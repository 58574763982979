
import { removeReservedCharacters } from '../utils/filters';

const generateNameSearchFilter = (searchString) => {
  if (!(typeof searchString === 'string' && searchString.length)) {
    return null;
  }

  const searchTerm = removeReservedCharacters(searchString);

  return {
    bool: {
      should: [
        {
          query_string: {
            query: `*${searchTerm}*`,
            fields: ['name'],
          },
        },
      ],
    },
  };
};

export default generateNameSearchFilter;
