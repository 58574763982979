import { reducer as createNewAccountReducer } from './createNewAccount';
import initialState from './initialState';
import { reducer as updateAccountReducer } from './updateAccount';
import { reducer as getAccountsReducer } from './getAccounts';
import { reducer as inviteUserReducer } from './inviteUser';
import { reducer as getUsersReducer } from './getUsers';
import { reducer as removeUserReducer } from './removeUser';
import { reducer as removeInvitationReducer } from './removeInvitation';
import { reducer as getInvitationsReducer } from './getInvitations';
import { reducer as updateUserPermissionsReducer } from './updateUserPermissions';
import { reducer as setLogoReducer } from './setLogo';
import { reducer as deleteLogoReducer } from './deleteLogo';
import { reducer as deactivateAccountReducer } from './deactivateAccount';
import { reducer as reactivateAccountReducer } from './reactivateAccount';
import { reducer as getAccountColorsReducer } from './getAccountColors';
import { PEOPLE_DELETE_PERSON_ATTACHMENT_SUCCESS } from '../../people/redux/constants';
import { decreaseTotalPeopleAttachmentBytes } from '../../../common/crossFeatureUtils';
import { reducer as getRoleTemplatesReducer } from './getRoleTemplates';
import { reducer as deleteRoleTemplateReducer } from './deleteRoleTemplate';
import { reducer as addRoleTemplateReducer } from './addRoleTemplate';
import { reducer as updateRoleTemplateReducer } from './updateRoleTemplate';
import { reducer as getTitleCostsReducer } from './getTitleCosts';
import { reducer as setTitleCostsReducer } from './setTitleCosts';

const reducers = [
  createNewAccountReducer,
  updateAccountReducer,
  getAccountsReducer,
  inviteUserReducer,
  getUsersReducer,
  removeUserReducer,
  removeInvitationReducer,
  getInvitationsReducer,
  updateUserPermissionsReducer,
  setLogoReducer,
  deleteLogoReducer,
  deactivateAccountReducer,
  reactivateAccountReducer,
  getAccountColorsReducer,
  getRoleTemplatesReducer,
  deleteRoleTemplateReducer,
  addRoleTemplateReducer,
  updateRoleTemplateReducer,
  getTitleCostsReducer,
  setTitleCostsReducer,
];

export default function reducer(state = initialState, action) {
  const { entities } = state;

  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    case PEOPLE_DELETE_PERSON_ATTACHMENT_SUCCESS: {
      newState = {
        ...state,
        entities: decreaseTotalPeopleAttachmentBytes(entities, action),
      };
      break;
    }
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
