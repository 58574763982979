import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
import { g800steelGrey } from '@bridgit/foundation';

const useStyles = makeStyles({
  root: {
    '&.sortable': {
      '&.sorted, &:hover': {
        color: g800steelGrey,
      },
      '&.sorted $sortArrow, &:hover $sortArrow': {
        visibility: 'visible',
      },
    },
  },
  sortArrow: {
    cursor: 'pointer',
    visibility: 'hidden',
    flex: '0 0 24px',
    fontSize: '18px',
  },
});

export default function withSort(WrappedComponent) {
  const SortableComponent = ({
    column,
    onClick,
    sortQuery,
    className,
    children,
    ...otherProps
  }) => {
    const {
      name,
      schemaName,
      type = null,
      options: { sort: sortable = true } = {},
    } = column || {};

    const { label = null, ascending = true, column: currentColumn } = sortQuery || {};
    const sorted = label === name;
    const customClasses = useStyles();

    const handleClick = useCallback(() => {
      if (!sortable || !onClick) return;

      const nextColumn = schemaName || name;

      onClick({
        name: 'sort',
        args: [{
          label: name,
          column: nextColumn,
          ascending: currentColumn === nextColumn ? !ascending : true,
          type,
        }],
      });
    }, [schemaName, name, type, currentColumn, onClick, sortable, ascending]);

    const renderSortIcon = useCallback((sorted, ascending) => {
      if (ascending || !sorted) return <ArrowUpward className={`${customClasses.sortArrow} automated-testing-arrow-up`} />;
      return <ArrowDownward className={`${customClasses.sortArrow} automated-testing-arrow-down`} />;
    }, [customClasses.sortArrow]);

    const customClassName = useMemo(() => classNames(className, customClasses.root, {
      sortable,
      sorted,
    }), [className, customClasses.root, sortable, sorted]);

    return (
      <WrappedComponent
        className={customClassName}
        column={column}
        onClick={handleClick}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
      >
        {sortable && renderSortIcon(sorted, ascending)}
        {children}
      </WrappedComponent>
    );
  };

  SortableComponent.propTypes = {
    column: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    sortQuery: PropTypes.object,
    className: PropTypes.string,
    children: PropTypes.node,
  };

  SortableComponent.defaultProps = {
    onClick: undefined,
    sortQuery: undefined,
    className: undefined,
    children: undefined,
  };

  return SortableComponent;
}
