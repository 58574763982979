import { ROLE_SCHEMA } from '../common/descriptors/role';
import { SORT_ASC, SORT_DESC } from './constants';

const FIELDS = {
  [ROLE_SCHEMA.roleName]: 'sortOrder',
  [ROLE_SCHEMA.projectName]: 'name',
  [ROLE_SCHEMA.projectStatus]: 'state',
  [ROLE_SCHEMA.startDate]: 'startDate',
  [ROLE_SCHEMA.endDate]: 'endDate',
  [ROLE_SCHEMA.allocation]: 'allocation',
};

function projectNestedSort(term, order = SORT_ASC) {
  return ({
    project: {
      order,
      nested: {
        path: 'project',
        filter: {
          term: FIELDS[term],
        },
      },
    },
  });
}

const sortOnProjectName = projectNestedSort(ROLE_SCHEMA.projectName);

const ROLES_SECONDARY_SORT_MAP = {
  [ROLE_SCHEMA.roleName]: sortOnProjectName,
  [ROLE_SCHEMA.projectName]: { startDate: { order: SORT_ASC } },
  [ROLE_SCHEMA.projectStatus]: sortOnProjectName,
  [ROLE_SCHEMA.startDate]: sortOnProjectName,
  [ROLE_SCHEMA.endDate]: sortOnProjectName,
};

export const generateRoleSort = (query) => {
  const { column, ascending } = query?.args?.[0] || {};
  const order = ascending ? SORT_ASC : SORT_DESC;

  if (column === ROLE_SCHEMA.projectName || column === ROLE_SCHEMA.projectStatus) {
    return [projectNestedSort(column, order), ROLES_SECONDARY_SORT_MAP[column]];
  }

  return [
    {
      [FIELDS[column]]: {
        order,
      },
    },
    ROLES_SECONDARY_SORT_MAP[column],
  ];
};
