import initialState from './initialState';
import { reducer as setFilterQueryReducer } from './setFilterQuery';
import { reducer as setSortQueryReducer } from './setSortQuery';
import { reducer as setSearchQueryReducer } from './setSearchQuery';
import { reducer as clearQueriesReducer } from './clearQueries';
import { reducer as getSavedFiltersReducer } from './getSavedFilters';
import { reducer as addSavedFilterReducer } from './addSavedFilter';
import { reducer as deleteSavedFilterReducer } from './deleteSavedFilter';

const reducers = [
  setFilterQueryReducer,
  setSortQueryReducer,
  setSearchQueryReducer,
  clearQueriesReducer,
  getSavedFiltersReducer,
  addSavedFilterReducer,
  deleteSavedFilterReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
