import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ProjectRoleTitle } from '@bridgit/foundation';
import { CURRENT, ACTIVE, UPCOMING, PURSUIT } from '../projects/constants';
import { DATE_INPUT_FORMAT } from '../../common/constants';

function PersonAllocations({
  personAllocations,
  person,
}) {
  const activeAllocations = personAllocations ? personAllocations.allocations.filter(
    allocation => allocation.allocationState === CURRENT && allocation.projectStatus === ACTIVE,
  ) : [];
  const upcomingAllocations = personAllocations ? personAllocations.allocations.filter(
    allocation => allocation.allocationState === UPCOMING &&
    [ACTIVE, UPCOMING].includes(allocation.projectStatus),
  ) : [];
  const pursuitAllocations = personAllocations ? personAllocations.allocations.filter(
    allocation => allocation.projectStatus === PURSUIT,
  ) : [];

  const renderAllocations = (allocations) => {
    // sort by allocation start date then project name
    const sortedAllocations = [...allocations].sort((a, b) => {
      if (a.startDate !== b.startDate) {
        return moment(a.startDate) - moment(b.startDate);
      }

      const aName = a.projectName.toUpperCase();
      const bName = b.projectName.toUpperCase();
      if (aName !== bName) {
        return aName < bName ? -1 : 1;
      }
      return 0;
    });

    return sortedAllocations.map(allocation => (
      <div key={`${allocation.roleId}-${allocation.startDate}`} className="allocation">
        <div className="project">
          <div className="dot">
            <svg height="30" width="30">
              <circle cx="10" cy="10" r="10" fill={allocation.projectColour} />
            </svg>
          </div>
          <div className="project-info">
            <div>{allocation.projectName}</div>
            <ProjectRoleTitle
              className="report-role-title"
              roleName={allocation.roleName}
              roleNote={allocation.roleNote}
            />
          </div>
        </div>
        <div className="role-info">
          <div className="role-dates">{`${moment(allocation.startDate, DATE_INPUT_FORMAT).format('MMM DD, YYYY')} - ${moment(allocation.endDate, DATE_INPUT_FORMAT).format('MMM DD, YYYY')}`}</div>
          <div>{`${allocation.allocatedPercent}%`}</div>
        </div>
      </div>
    ));
  };

  const renderIssues = () => {
    if (person.hasConflict) {
      return (
        <div className="conflict">
          <svg height="10" width="15">
            <circle cx="5" cy="5" r="5" fill="#F39729" />
          </svg>
          Over allocation issue
        </div>
      );
    }

    return <div className="no-conflict">No allocation issues</div>;
  };

  const renderAllocationSectionByType = (allocationType, allocations) => (
    <div className="allocations-wrap">
      <div className="allocation-type">{`${allocationType} (${allocations.length})`}</div>
      {renderAllocations(allocations)}
    </div>
  );

  return (
    <div className="reports-person-allocations">
      <div className="title-wrap">
        <div className="allocation-title">Project Allocations</div>
        {renderIssues()}
      </div>
      {!personAllocations && <div className="empty">No active or upcoming allocations</div>}
      {activeAllocations.length > 0 && renderAllocationSectionByType(ACTIVE, activeAllocations)}
      {upcomingAllocations.length > 0 && renderAllocationSectionByType(UPCOMING, upcomingAllocations)}
      {pursuitAllocations.length > 0 && renderAllocationSectionByType(PURSUIT, pursuitAllocations)}
    </div>
  );
}

PersonAllocations.propTypes = {
  personAllocations: PropTypes.object,
  person: PropTypes.object.isRequired,
};

PersonAllocations.defaultProps = {
  personAllocations: null,
};

export default PersonAllocations;
