import { gql } from 'apollo-boost';
import { LocalStorageKeys } from 'src/common/localStorageKeys';
import { MIN_FROM } from 'src/common/constants';
import { getValidatedLocalStorage } from 'src/utils/validators';
import { PERM_READ, PERM_ROLE, PERM_HOURLY_PERSON } from 'src/features/permissions/utils/constants';
import { isAuthorized } from 'src/features/permissions/utils/permissionUtils';
import { DEFAULT_PROJECT_ROLE_SORT, DEFAULT_PROJECT_BY_ID_ROLE_SORT } from '../redux/constants';
import { generateRequestFilter } from '../../../filters/graphqlUtils';
import {
  DEFAULT_REQUEST_STATE_FILTER,
  DEFAULT_PROJECT_REQUEST_SORT,
  MAX_REQUEST_SIZE,
} from '../../self-perform/redux/constants';
import { REQUEST_SCHEMAS } from '../../../common/descriptors/request';

const ROLE_SORT_PARAM = ', $structuredSort: [RoleSort]';
const REQUEST_PARAMS = ', $filters: [ESQuery], $requestSort: [RequestSort], $size: Int, $from: Int';

const ROLES_QUERY = `
  roles(structuredSort: $structuredSort) {
    id
    name
    startDate
    endDate
    isFilled
    note
    requirements {
      startDate
      endDate
      allocatedPercent: percent
    }
    allocations {
      startDate
      endDate
      personId
      name
      allocatedPercent
    }
    notification {
      notifiedOn
      recipientIds
    }
    isCommunicated
  }
  roleStats {
    filled
    unfilled
    total
  }
`;

const REQUEST_QUERY = `
  requests(accountId: $accountId, filters: $filters, structuredSort: $requestSort, size: $size, from: $from) {
    count
    hasMore
    cursor
    items {
      id
      description
      startDate
      endDate
      requester {
        name
      }
      assignee {
        name
      }
      workDays
    }
  }
`;

const constructQuery = (hasRolesPerms, hasRequestPerms) => gql`
  query projectByIdQuery($accountId: Int!, $projectId: Int!${hasRolesPerms ? ROLE_SORT_PARAM : ''}${hasRequestPerms ? REQUEST_PARAMS : ''}) {
    project(accountId: $accountId, projectId: $projectId) {
      id
      name
      colour
      startDate
      endDate
      state
      type
      winPercent
      unfilledRoles
      totalRoles
      hasIssues
      lastModifiedOn
      ${hasRolesPerms ? ROLES_QUERY : ''}
      fields {
        fieldId: id
        isPrivate
        name
        dataType
        values
      }
      currentPhases {
        name
        subPhases
      }
      phases {
        id
        name
        startDate
        endDate
        subPhases {
          id
          name
          startDate
          endDate
        }
      }
      notes {
        id
        creatorId
        creatorName
        message
        isPrivate
        createdOn
        lastModifiedOn
      }
    }
    ${hasRequestPerms ? REQUEST_QUERY : ''}
  }
`;

export const getProjectByIdQuery = (accountId, projectId, permissions) => {
  const storageSort = getValidatedLocalStorage(LocalStorageKeys.projectRoleSort);
  const nestedSortObj = storageSort || DEFAULT_PROJECT_ROLE_SORT;
  const structuredSort = nestedSortObj?.roles?.nested?.structuredSort
    ? nestedSortObj.roles.nested.structuredSort
    : DEFAULT_PROJECT_BY_ID_ROLE_SORT;

  const requestFilter = {
    name: 'filter',
    args: [
      DEFAULT_REQUEST_STATE_FILTER,
      {
        label: 'Project Id',
        column: 'projectId',
        activeFilters: [
          { name: projectId, value: projectId },
        ],
      },
    ],
  };

  const filters = generateRequestFilter(requestFilter, REQUEST_SCHEMAS);
  const variables = {
    accountId,
    projectId,
    structuredSort,
    filters,
    requestSort: DEFAULT_PROJECT_REQUEST_SORT,
    size: MAX_REQUEST_SIZE,
    from: MIN_FROM,
  };
  const hasRolesPerms = isAuthorized(accountId, permissions, PERM_READ, PERM_ROLE);
  const hasRequestPerms = isAuthorized(accountId, permissions, PERM_READ, PERM_HOURLY_PERSON);

  return {
    query: constructQuery(hasRolesPerms, hasRequestPerms),
    variables,
  };
};
