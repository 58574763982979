import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PEOPLE_ADD_PERSON_CERTIFICATION_BEGIN,
  PEOPLE_ADD_PERSON_CERTIFICATION_SUCCESS,
  PEOPLE_ADD_PERSON_CERTIFICATION_FAILURE,
  PEOPLE_ADD_PERSON_CERTIFICATION_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';
import { insertToSortedArray } from '../../../utils/sortUtils';

export function addPersonCertification(accountId, personId, data, analyticsPayload) {
  return {
    type: PEOPLE_ADD_PERSON_CERTIFICATION_BEGIN,
    accountId,
    personId,
    data,
    analyticsPayload,
  };
}

export function dismissAddPersonCertificationError() {
  return {
    type: PEOPLE_ADD_PERSON_CERTIFICATION_DISMISS_ERROR,
  };
}

export function* doAddPersonCertification(action) {
  const { accountId, personId, data, analyticsPayload } = action;
  let res;
  try {
    res = yield call(protectedJsonPost, `accounts/${accountId}/persons/${personId}/certifications`, data);
  } catch (err) {
    yield put({
      type: PEOPLE_ADD_PERSON_CERTIFICATION_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PEOPLE_ADD_PERSON_CERTIFICATION_SUCCESS,
    data: res,
    personId,
    analyticsPayload,
  });
}

export function* watchAddPersonCertification() {
  yield takeLatest(PEOPLE_ADD_PERSON_CERTIFICATION_BEGIN, doAddPersonCertification);
}

export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_ADD_PERSON_CERTIFICATION_BEGIN:
      return {
        ...state,
        addPersonCertificationPending: true,
        addPersonCertificationError: null,
      };

    case PEOPLE_ADD_PERSON_CERTIFICATION_SUCCESS: {
      const { data, personId } = action;
      const { personCertifications, filteredPeople } = state;
      const compareAbbreviation = (a, b) => a.abbreviation.localeCompare(b.abbreviation);
      const newPersonCertifications = {
        ...personCertifications,
        items: [data, ...personCertifications.items || []],
      };
      const newFilteredPeople = filteredPeople.map(person => (
        person.id === personId
          ? ({
            ...person,
            certifications: insertToSortedArray(person.certifications || [], data, compareAbbreviation),
          })
          : person
      ));

      return {
        ...state,
        personCertifications: newPersonCertifications,
        filteredPeople: newFilteredPeople,
        addPersonCertificationPending: false,
        addPersonCertificationError: null,
      };
    }

    case PEOPLE_ADD_PERSON_CERTIFICATION_FAILURE:
      return {
        ...state,
        addPersonCertificationPending: false,
        addPersonCertificationError: action.data.error,
      };

    case PEOPLE_ADD_PERSON_CERTIFICATION_DISMISS_ERROR:
      return {
        ...state,
        addPersonCertificationError: null,
      };

    default:
      return state;
  }
}
