import { call, put, takeLatest } from 'redux-saga/effects';
import { MAX_SIZE } from '../../../common/constants';
import graphAPIClient from '../../../common/GraphAPIClient';
import { getFilteredRolesQuery } from '../../roles/queries/getFilteredRolesQuery';
import { parseRoleSegment } from '../../roles/utils/adaptors';
import {
  REPORTS_GET_REPORT_ROLE_SEGMENTS_BEGIN,
  REPORTS_GET_REPORT_ROLE_SEGMENTS_DISMISS_ERROR,
  REPORTS_GET_REPORT_ROLE_SEGMENTS_FAILURE,
  REPORTS_GET_REPORT_ROLE_SEGMENTS_SUCCESS,
} from './constants';

export function getReportRoleSegments(accountId, projectQueries, timeframeFilter, settings, sortQuery) {
  const { query, variables } = getFilteredRolesQuery(accountId, projectQueries, timeframeFilter, sortQuery, 0, MAX_SIZE);

  return {
    type: REPORTS_GET_REPORT_ROLE_SEGMENTS_BEGIN,
    query,
    variables,
    settings,
  };
}

export function dismissGetReportRoleSegmentsError() {
  return {
    type: REPORTS_GET_REPORT_ROLE_SEGMENTS_DISMISS_ERROR,
  };
}

export function* doGetReportRoleSegments(action) {
  const { query, variables, settings } = action;
  let res;

  try {
    res = yield call(graphAPIClient.query, { query, variables });
  } catch (err) {
    yield put({
      type: REPORTS_GET_REPORT_ROLE_SEGMENTS_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: REPORTS_GET_REPORT_ROLE_SEGMENTS_SUCCESS,
    data: res.data,
    settings,
  });
}

export function* watchGetReportRoleSegments() {
  yield takeLatest(REPORTS_GET_REPORT_ROLE_SEGMENTS_BEGIN, doGetReportRoleSegments);
}

export function reducer(state, action) {
  switch (action.type) {
    case REPORTS_GET_REPORT_ROLE_SEGMENTS_BEGIN:
      return {
        ...state,
        getReportRoleSegmentsPending: true,
        getReportRoleSegmentsError: null,
      };

    case REPORTS_GET_REPORT_ROLE_SEGMENTS_SUCCESS: {
      const { settings, data } = action;
      const { type } = settings;

      return {
        ...state,
        showReport: true,
        type,
        settings,
        roleSegments: data?.roleSegments?.items?.map(parseRoleSegment) || [],
        getReportRoleSegmentsPending: false,
        getReportRoleSegmentsError: null,
      };
    }

    case REPORTS_GET_REPORT_ROLE_SEGMENTS_FAILURE:
      return {
        ...state,
        getReportRoleSegmentsPending: false,
        getReportRoleSegmentsError: action.data.error,
      };

    case REPORTS_GET_REPORT_ROLE_SEGMENTS_DISMISS_ERROR:
      return {
        ...state,
        getReportRoleSegmentsError: null,
      };

    default:
      return state;
  }
}
