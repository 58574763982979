import { gql } from 'apollo-boost';
import {
  generateRequestFilter,
  generateRequestSort,
  generateProjectRoleFilter,
  generateProjectRoleSort,
  pickObjectProps,
} from 'src/filters/graphqlUtils';
import { PROJECT_SCHEMAS } from 'src/common/descriptors/project';
import { PAGE_SIZE } from 'src/common/constants';
import { PERM_READ, PERM_ROLE } from 'src/features/permissions/utils/constants';
import { isAuthorized } from 'src/features/permissions/utils/permissionUtils';
import { getFilterIssues } from '../../filters/utils/filterUtils';
import { ALL_ISSUES, FILTER_ISSUES } from '../../../filters/constants';

const PROJECT_ROLE_PARAMS = ', $roleStructuredSort: [RoleSort], $roleFilters: [ProjectRoleFilter]';
const ROLES_QUERY = `
  roles(structuredSort: $roleStructuredSort, filters: $roleFilters) {
    id
    name
    startDate
    endDate
    isFilled
    sortOrder
    note
    requirements {
      startDate
      endDate
      allocatedPercent: percent
    }
    allocations {
      startDate
      endDate
      personId
      allocatedPercent: percent
    }
  }
`;

const constructQuery = hasRolesPerms => gql`
  query projectListQuery($accountId: Int!, $structuredSort: [ProjectSort]${hasRolesPerms ? PROJECT_ROLE_PARAMS : ''}, $from: Int, $size: Int, $filters: [ESQuery], $filterIssues: Int){
    projects(accountId: $accountId, structuredSort: $structuredSort, from: $from, size: $size, filters: $filters, filterIssues: $filterIssues ) {
      hasMore
      cursor
      count
      items {
        id
        state
        name
        startDate
        endDate
        totalRoles
        unfilledRoles
        hasIssues
        lastModifiedOn
        colour
        type
        winPercent
        ${hasRolesPerms ? ROLES_QUERY : ''}
        fields {
          dataType
          name
          values
          isPrivate
        }
        currentPhases {
          name
          subPhases
        }
        phases {
          id
          name
          startDate
          endDate
          subPhases {
            id
            name
            startDate
            endDate
          }
        }
        coordinates {
          lat
          lng
        }
      }
    }
  }
`;

const getNewProjectListEntity = (projectData, fieldData = []) => {
  const projectProps = [
    'id',
    'state',
    'name',
    'startDate',
    'endDate',
    'totalRoles',
    'unfilledRoles',
    'hasIssues',
    'lastModifiedOn',
    'colour',
    'phases',
    'roles',
    'type',
    'winPercent',
  ];

  const fieldProps = [
    'type',
    'name',
    'values',
    'isPrivate',
  ];

  const project = pickObjectProps(projectData, projectProps);
  const fields = fieldData.map(field => ({
    ...pickObjectProps(field, fieldProps),
    __typename: 'Field',
  }));
  const currentPhases = [];

  return {
    ...project,
    fields,
    currentPhases,
    __typename: 'Project',
  };
};

const getProjectListQuery = (accountId, queries = {}, pageFrom, pageSize, roleSortOption, roleFilterOption, permissions) => {
  const defaults = {
    size: pageSize || PAGE_SIZE,
  };

  const defaultSort = [{ name: { order: 'asc' } }];

  const { filter, sort, search } = queries;
  const hasFilterQuery = filter && filter.args;

  const filterIssues = hasFilterQuery ? getFilterIssues(filter.args) : ALL_ISSUES;
  const filteredQuery = hasFilterQuery ? { ...filter, args: filter.args.filter(arg => arg.filterType !== FILTER_ISSUES) } : {};
  const searchFilter = generateRequestFilter(search, PROJECT_SCHEMAS);
  const filters = generateRequestFilter(filteredQuery, PROJECT_SCHEMAS).concat(searchFilter);
  const structuredSort = generateRequestSort(sort, PROJECT_SCHEMAS, defaultSort);
  const roleFilters = generateProjectRoleFilter(roleFilterOption);
  const roleStructuredSort = generateProjectRoleSort(roleSortOption);

  const variables = {
    accountId,
    filters,
    structuredSort,
    roleStructuredSort,
    roleFilters,
    filterIssues,
    from: pageFrom,
    ...defaults,
  };

  const hasRolesPerms = isAuthorized(accountId, permissions, PERM_READ, PERM_ROLE);

  return {
    query: constructQuery(hasRolesPerms),
    variables,
  };
};

export {
  getNewProjectListEntity,
  getProjectListQuery,
};
