import React, { PureComponent } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';

export default class CancelButton extends PureComponent {
  static propTypes = {
    color: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired,
    tooltipText: PropTypes.string,
  };

  static defaultProps = {
    color: 'secondary',
    tooltipText: null,
  };

  render() {
    const { color, onClick, className, tooltipText } = this.props;
    const closeButton = (
      <IconButton className={`common-cancel-button ${className}`} color={color} onClick={onClick} disableRipple>
        <Close aria-label="Delete" className="icon--delete" />
      </IconButton>
    );
    return tooltipText
      ? (
        <Tooltip title={tooltipText} placement="top">
          {closeButton}
        </Tooltip>
      ) : closeButton;
  }
}
