import { call, put, takeLatest } from 'redux-saga/effects';
import {
  SETTINGS_UPDATE_ADMIN_BEGIN,
  SETTINGS_UPDATE_ADMIN_SUCCESS,
  SETTINGS_UPDATE_ADMIN_FAILURE,
  SETTINGS_UPDATE_ADMIN_DISMISS_ERROR,
} from './constants';
import { protectedJsonPatch } from '../../../utils/api';

export function updateAdmin(data) {
  // If need to pass args to saga, pass it with the begin action.
  return {
    type: SETTINGS_UPDATE_ADMIN_BEGIN,
    data,
  };
}

export function dismissUpdateAdminError() {
  return {
    type: SETTINGS_UPDATE_ADMIN_DISMISS_ERROR,
  };
}

// worker Saga: will be fired on SETTINGS_UPDATE_USERS_BEGIN actions
export function* doUpdateAdmin(action) {
  // If necessary, use argument to receive the begin action with parameters.
  let res;
  try {
    res = yield call(protectedJsonPatch, `users/${action.data.userId}`);
  } catch (err) {
    yield put({
      type: SETTINGS_UPDATE_ADMIN_FAILURE,
      data: { error: err },
    });
    return;
  }
  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: SETTINGS_UPDATE_ADMIN_SUCCESS,
    data: res,
  });
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchUpdateAdmin() {
  yield takeLatest(SETTINGS_UPDATE_ADMIN_BEGIN, doUpdateAdmin);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case SETTINGS_UPDATE_ADMIN_BEGIN:
      return {
        ...state,
        updateAdminPending: true,
        updateAdminError: null,
      };

    case SETTINGS_UPDATE_ADMIN_SUCCESS: {
      const newBridgitAdminList = [...state.bridgitAdmins, action.data];
      return {
        ...state,
        bridgitAdmins: newBridgitAdminList,
        updateAdminPending: false,
        updateAdminError: null,
      };
    }

    case SETTINGS_UPDATE_ADMIN_FAILURE:
      return {
        ...state,
        updateAdminPending: false,
        updateAdminError: action.data.error,
      };

    case SETTINGS_UPDATE_ADMIN_DISMISS_ERROR:
      return {
        ...state,
        updateAdminError: null,
      };

    default:
      return state;
  }
}
