import {
  PEOPLE_DISPLAY_AVAILABILITY_UNTIL,
  PEOPLE_DISPLAY_CURRENT_PROJECT,
} from '../features/people/constants';

const FILTER_ISSUES = 'FILTER_ISSUES';
const ALL_ISSUES = 2;
const HAS_ISSUES = 1;
const NO_ISSUES = 0;

const BLANK_FILTER = '(Blank)';
const ABOVE_FILTER = 'above';
const BELOW_FILTER = 'below';
const AFTER_FILTER = 'after';
const BEFORE_FILTER = 'before';
const BETWEEN_FILTER = 'between';
const ON_FILTER = 'on';
const EQUALS_FILTER = 'equals';
const NO_AVAILABILITY_FILTER = 'no-availability';
const AVAILABLE_NOW_FILTER = 'available-now';
const FUTURE_AVAILABILITY = 'future-availability';
const CUSTOM = 'custom';

const MUST = 'must';
const SHOULD = 'should';
const MUST_NOT = 'must_not';
const FIELDS = 'fields';
const ROLES = 'roles';
const FIELDS_NAME = `${FIELDS}.name`;
const FILLED_ROLES = 'Filled';
const UNFILLED_ROLES = 'Unfilled';
const ROLE_STATS = 'roleStats';
const CERTIFICATIONS = 'certifications';
const HOURLY_COST_RATE = 'hourlyCostRate';

// Nested field value paths
const FIELDS_VALUE_SELECTION = `${FIELDS}__value_selection`;
const FIELDS_VALUE_TEXT = `${FIELDS}__value_text`;
const FIELDS_VALUE_DATE = `${FIELDS}__value_date`;
const FIELDS_VALUE_CURRENCY = `${FIELDS}__value_currency`;
const FIELDS_VALUE_BOOLEAN = `${FIELDS}__value_boolean`;
const FIELDS_VALUE_TEXT_SORT = `${FIELDS}__value_str_sort`;
const CERTIFICATIONS_ID = `${CERTIFICATIONS}__id`;
const CERTIFICATIONS_NAME = `${CERTIFICATIONS}__name`;
const CERTIFICATIONS_ABBREVIATION = `${CERTIFICATIONS}__abbreviation`;
const CERTIFICATIONS_EXPIRY_DATE = `${CERTIFICATIONS}__expiryDate`;

// Nested roles value paths
const ROLES_ALLOCATIONS = `${ROLES}__allocations`;
const ROLES_ALLOCATIONS_PERSON_ID = `${ROLES_ALLOCATIONS}__personId`;
const ROLES_NAME = `${ROLES}__name`;
const ROLES_ISFILLED = `${ROLES}__isFilled`;

// Nested roleStats value paths
const ROLE_STATS_UNFILLED = `${ROLE_STATS}__unfilled`;
const ROLE_STATS_TOTAL = `${ROLE_STATS}__total`;

const FILTER_GENERIC_PROJECT_SEARCH = 'FILTER_GENERIC_PROJECT_SEARCH';
const FILTER_GENERIC_PEOPLE_SEARCH = 'FILTER_GENERIC_PEOPLE_SEARCH';
const FILTER_GENERIC_HOURLY_PERSONS_SEARCH = 'FILTER_GENERIC_HOURLY_PERSONS_SEARCH';
const FILTER_AVAILABILITIES = 'FILTER_AVAILABILITIES';
const FILTER_ALLOCATION = 'FILTER_ALLOCATION';
const FILTER_ALLOCATED_PEOPLE = 'FILTER_ALLOCATED_PEOPLE';
const FILTER_PROJECT_DATE = 'FILTER_PROJECT_DATE';
const FILTER_PHASE = 'FILTER_PHASE';
const FILTER_NAME = 'FILTER_NAME';
const FILTER_TYPE = 'FILTER_TYPE';
const FILTER_TITLE = 'FILTER_TITLE';
const FILTER_STATUS = 'FILTER_STATUS';
const QUERY_PARAM = 'QUERY_PARAM';
const FILTER_EMPLOYMENT_DATE_END = 'FILTER_EMPLOYMENT_DATE_END';
const FILTER_EMPLOYMENT_DATE_START = 'FILTER_EMPLOYMENT_DATE_START';
const FILTER_ROLES = 'FILTER_ROLES';
const FILTER_ROLE_SEGMENTS = 'FILTER_ROLE_SEGMENTS';
const FORECASTING_FILTER_ROLES = 'FORECASTING_FILTER_ROLES'; // remove after "some" time
const FILTER_SORTED_LIST = 'FILTER_SORTED_LIST';
const FILTER_CERTIFICATIONS = 'FILTER_CERTIFICATIONS';
const FILTER_COST_RATE = 'FILTER_COST_RATE';
const FILTER_DISTANCE_FROM_PROJECT = 'FILTER_DISTANCE_FROM_PROJECT';

const PHASES = 'phases';
const PHASES_START_DATE = `${PHASES}.startDate`;
const PHASES_END_DATE = `${PHASES}.endDate`;
const PHASES_NAME = `${PHASES}.name`;
const SUB_PHASES = `${PHASES}.subPhases`;
const SUB_PHASES_START_DATE = `${SUB_PHASES}.startDate`;
const SUB_PHASES_END_DATE = `${SUB_PHASES}.endDate`;
const SUB_PHASES_NAME = `${SUB_PHASES}.name`;

const EMPLOYMENT_DATES = 'employmentDates';
const EMPLOYMENT_DATES_START_DATE = `${EMPLOYMENT_DATES}__startDate`;
const EMPLOYMENT_DATES_END_DATE = `${EMPLOYMENT_DATES}__endDate`;
const NEXT_ALLOCATIONS = 'nextAllocations';
const NEXT_ALLOCATIONS_START_DATE = `${NEXT_ALLOCATIONS}__startDate`;
const CURRENT_ASSIGNMENTS = 'currentAssignments';
const UPCOMING_ASSIGNMENTS = 'upcomingAssignments';
const PROJECT_NAME = 'projectName';
const START_DATE = 'startDate';
const END_DATE = 'endDate';
const ASSIGNEE = 'assignee';
const LAST_NOTIFIED_ON = 'lastNotifiedOn';
const REQUIRED_SKILLS = 'requiredSkills';
const REQUESTER_NAME = 'requesterName';
const STATUS = 'status';

const NO_AVAILABILITY = 999999;
const FULL_AVAILABILITY = 888888;
const NEXT_AVAILABILITY = 'nextAvailability';
const AVAILABILITIES = 'availabilities';
const AVAILABILITIES_PERCENT = 'availabilities__percent';
const AVAILABILITIES_START_DATE = 'availabilities__startDate';
const AVAILABILITIES_END_DATE = 'availabilities__endDate';
const PERCENT_FILTER_TYPE = 'percent';
const DATE_FILTER_TYPE = 'date';

const SELECT_LIST = 'SELECT_LIST';
const SELECT_LIST_SEARCH = 'SELECT_LIST_SEARCH';

// Sort constants
const SORT_ASC = 'asc';
const SORT_DESC = 'desc';

const CUSTOM_VALUE_PATHS = {
  SingleSelect: FIELDS_VALUE_SELECTION,
  MultiSelect: FIELDS_VALUE_SELECTION,
  Currency: FIELDS_VALUE_CURRENCY,
  Date: FIELDS_VALUE_DATE,
  Boolean: FIELDS_VALUE_BOOLEAN,
  Text: FIELDS_VALUE_TEXT_SORT,
};

const MULTI_SELECT_FILTER_VERBS = [
  {
    verb: 'Includes',
    inclusive: true,
  },
  {
    verb: 'Excludes',
    inclusive: false,
  },
];

/* Control options - used to define unique filtering options */

const ROLE_CONTROL_UNFILLED = 'Unfilled';
const ROLE_CONTROL_FILLED = 'Filled';
const ROLE_CONTROL_OPTIONS = [
  {
    name: ROLE_CONTROL_UNFILLED,
    label: 'Unfilled Roles',
  },
  {
    name: ROLE_CONTROL_FILLED,
    label: 'Filled Roles',
  },
];

const CERTIFICATIONS_CONTROL_EXPIRING_SOON = 'Expiring soon';
const CERTIFICATIONS_CONTROL_EXPIRED = 'Expired';
const CERTIFICATIONS_CONTROL_OPTIONS = [
  {
    name: CERTIFICATIONS_CONTROL_EXPIRING_SOON,
    label: CERTIFICATIONS_CONTROL_EXPIRING_SOON,
  },
  {
    name: CERTIFICATIONS_CONTROL_EXPIRED,
    label: CERTIFICATIONS_CONTROL_EXPIRED,
  },
];

const ROLE_STATE_CONTROL_OPTIONS = [{
  name: 'Upcoming',
  label: 'Upcoming',
},
{
  name: 'Current',
  label: 'Current',
},
{
  name: 'Past',
  label: 'Past',
}];

// Use this to whitelist to remove the "or" when displaying filter chips
const CONTROL_OPTIONS_FILTER_NAMES = {
  [ROLES]: [
    ROLE_CONTROL_UNFILLED,
    ROLE_CONTROL_FILLED,
  ],
  [CERTIFICATIONS]: [
    CERTIFICATIONS_CONTROL_EXPIRING_SOON,
    CERTIFICATIONS_CONTROL_EXPIRED,
  ],
};

/* End - Control options */

const MAPPED_PEOPLE_FILTER_NAMES = {
  [PEOPLE_DISPLAY_CURRENT_PROJECT]: 'Project Allocations',
  [PEOPLE_DISPLAY_AVAILABILITY_UNTIL]: 'Availability',
};

const AVAILABILITY_FILTER_LIST_ITEMS = [
  {
    label: 'Available Now',
    value: AVAILABLE_NOW_FILTER,
  },
  {
    label: 'No Availability',
    value: NO_AVAILABILITY_FILTER,
  },
  {
    label: 'Custom',
    value: CUSTOM,
  },
];

export {
  FILTER_ROLES,
  FORECASTING_FILTER_ROLES,
  FILTER_ROLE_SEGMENTS,
  FILTER_SORTED_LIST,
  FILTER_CERTIFICATIONS,
  FILTER_ISSUES,
  ALL_ISSUES,
  NO_ISSUES,
  HAS_ISSUES,
  BLANK_FILTER,
  ABOVE_FILTER,
  BELOW_FILTER,
  BETWEEN_FILTER,
  AFTER_FILTER,
  BEFORE_FILTER,
  ON_FILTER,
  EQUALS_FILTER,
  MUST,
  SHOULD,
  MUST_NOT,
  FIELDS,
  FIELDS_NAME,
  FIELDS_VALUE_SELECTION,
  FIELDS_VALUE_TEXT,
  FIELDS_VALUE_TEXT_SORT,
  FIELDS_VALUE_DATE,
  FIELDS_VALUE_CURRENCY,
  FIELDS_VALUE_BOOLEAN,
  FILTER_GENERIC_PROJECT_SEARCH,
  FILTER_GENERIC_PEOPLE_SEARCH,
  FILTER_GENERIC_HOURLY_PERSONS_SEARCH,
  FILTER_AVAILABILITIES,
  FILTER_ALLOCATION,
  FILTER_ALLOCATED_PEOPLE,
  FILTER_PROJECT_DATE,
  FILTER_PHASE,
  FILTER_NAME,
  QUERY_PARAM,
  FILTER_TYPE,
  FILTER_TITLE,
  FILTER_STATUS,
  FILTER_EMPLOYMENT_DATE_END,
  FILTER_EMPLOYMENT_DATE_START,
  FILTER_DISTANCE_FROM_PROJECT,
  PHASES,
  PHASES_START_DATE,
  PHASES_END_DATE,
  PHASES_NAME,
  SUB_PHASES,
  SUB_PHASES_START_DATE,
  SUB_PHASES_END_DATE,
  SUB_PHASES_NAME,
  SORT_ASC,
  SORT_DESC,
  CUSTOM_VALUE_PATHS,
  EMPLOYMENT_DATES,
  EMPLOYMENT_DATES_START_DATE,
  EMPLOYMENT_DATES_END_DATE,
  NEXT_ALLOCATIONS,
  NEXT_ALLOCATIONS_START_DATE,
  NO_AVAILABILITY,
  FULL_AVAILABILITY,
  SELECT_LIST,
  SELECT_LIST_SEARCH,
  ROLES,
  ROLES_ALLOCATIONS,
  ROLES_ALLOCATIONS_PERSON_ID,
  FILLED_ROLES,
  UNFILLED_ROLES,
  ROLES_NAME,
  ROLES_ISFILLED,
  ROLE_STATS_UNFILLED,
  ROLE_CONTROL_OPTIONS,
  CERTIFICATIONS_CONTROL_EXPIRING_SOON,
  CERTIFICATIONS_CONTROL_EXPIRED,
  CERTIFICATIONS_CONTROL_OPTIONS,
  ROLE_STATE_CONTROL_OPTIONS,
  MULTI_SELECT_FILTER_VERBS,
  ROLE_STATS_TOTAL,
  MAPPED_PEOPLE_FILTER_NAMES,
  NEXT_AVAILABILITY,
  AVAILABILITIES,
  AVAILABILITIES_PERCENT,
  AVAILABILITIES_START_DATE,
  AVAILABILITIES_END_DATE,
  NO_AVAILABILITY_FILTER,
  AVAILABLE_NOW_FILTER,
  FUTURE_AVAILABILITY,
  AVAILABILITY_FILTER_LIST_ITEMS,
  CUSTOM,
  PERCENT_FILTER_TYPE,
  DATE_FILTER_TYPE,
  CURRENT_ASSIGNMENTS,
  UPCOMING_ASSIGNMENTS,
  PROJECT_NAME,
  START_DATE,
  END_DATE,
  ASSIGNEE,
  LAST_NOTIFIED_ON,
  REQUIRED_SKILLS,
  REQUESTER_NAME,
  STATUS,
  CERTIFICATIONS,
  CERTIFICATIONS_ID,
  CERTIFICATIONS_NAME,
  CERTIFICATIONS_ABBREVIATION,
  CERTIFICATIONS_EXPIRY_DATE,
  CONTROL_OPTIONS_FILTER_NAMES,
  FILTER_COST_RATE,
  HOURLY_COST_RATE,
};
