import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PERMISSIONS_UPDATE_PERMISSION_GROUP_BEGIN,
  PERMISSIONS_UPDATE_PERMISSION_GROUP_SUCCESS,
  PERMISSIONS_UPDATE_PERMISSION_GROUP_FAILURE,
  PERMISSIONS_UPDATE_PERMISSION_GROUP_DISMISS_ERROR,
} from './constants';
import { protectedJsonPut } from '../../../utils/api';

export function updatePermissionGroup(accountId, groupId, data) {
  return {
    type: PERMISSIONS_UPDATE_PERMISSION_GROUP_BEGIN,
    accountId,
    groupId,
    data,
  };
}

export function dismissUpdatePermissionGroupError() {
  return {
    type: PERMISSIONS_UPDATE_PERMISSION_GROUP_DISMISS_ERROR,
  };
}

export function* doUpdatePermissionGroup(action) {
  const { accountId, groupId, data } = action;
  let res;
  try {
    res = yield call(protectedJsonPut, `accounts/${accountId}/groups/${groupId}`, data);
  } catch (err) {
    yield put({
      type: PERMISSIONS_UPDATE_PERMISSION_GROUP_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PERMISSIONS_UPDATE_PERMISSION_GROUP_SUCCESS,
    data: res,
    analyticsPayload: data,
  });
}

export function* watchUpdatePermissionGroup() {
  yield takeLatest(PERMISSIONS_UPDATE_PERMISSION_GROUP_BEGIN, doUpdatePermissionGroup);
}

export function reducer(state, action) {
  switch (action.type) {
    case PERMISSIONS_UPDATE_PERMISSION_GROUP_BEGIN:
      return {
        ...state,
        updatePermissionGroupPending: true,
        updatePermissionGroupError: null,
      };

    case PERMISSIONS_UPDATE_PERMISSION_GROUP_SUCCESS: {
      return {
        ...state,
        permissionGroups: state.permissionGroups.map((group) => {
          if (group.id === action.data.id) {
            return action.data;
          }
          return group;
        }),
        updatePermissionGroupPending: false,
        updatePermissionGroupError: null,
      };
    }

    case PERMISSIONS_UPDATE_PERMISSION_GROUP_FAILURE:
      return {
        ...state,
        updatePermissionGroupPending: false,
        updatePermissionGroupError: action.data.error,
      };

    case PERMISSIONS_UPDATE_PERMISSION_GROUP_DISMISS_ERROR:
      return {
        ...state,
        updatePermissionGroupError: null,
      };

    default:
      return state;
  }
}
