import { logRollbar } from 'src/common/analyticsHelper';
import analyticsEvents from './events';

const analyticsMiddleware = store => next => (action) => {
  const { type, mixpanelEvent } = action;
  const mixpanelEventName = mixpanelEvent || type;
  const eventConfig = analyticsEvents[mixpanelEventName];

  try {
    if (eventConfig) {
      const { getState } = store;
      const state = getState();

      const { getPayload, getEvent } = eventConfig;

      const eventString = typeof getEvent === 'function' ? getEvent(state, action) : null;

      if (eventString) {
        const payload = getPayload ? getPayload(state, action) : null;

        if (payload) {
          if (Array.isArray(payload)) {
            payload.forEach(pl => window.mixpanel.track(eventString, pl));
          } else {
            window.mixpanel.track(eventString, payload);
          }
        } else {
          window.mixpanel.track(eventString);
        }
      }
    }
  } catch (err) {
    logRollbar('Caught error in analytics middleware: ', err);
  }

  return next(action);
};

export default analyticsMiddleware;
