import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNT_SETTINGS_REMOVE_ACCOUNT_MODULE_BEGIN,
  ACCOUNT_SETTINGS_REMOVE_ACCOUNT_MODULE_SUCCESS,
  ACCOUNT_SETTINGS_REMOVE_ACCOUNT_MODULE_FAILURE,
  ACCOUNT_SETTINGS_REMOVE_ACCOUNT_MODULE_DISMISS_ERROR,
  ACCOUNT_SETTINGS_REMOVE_PURSUIT_TRACKING_MODULE_SUCCESS,
} from './constants';
import { protectedJsonDelete } from '../../../utils/api';
import { ACCOUNT_MODULE_PURSUIT_TRACKING } from '../../../common/constants';

export function removeAccountModule(accountId, moduleId, moduleName) {
  return {
    type: ACCOUNT_SETTINGS_REMOVE_ACCOUNT_MODULE_BEGIN,
    accountId,
    moduleId,
    moduleName,
  };
}

export function dismissRemoveAccountModuleError() {
  return {
    type: ACCOUNT_SETTINGS_REMOVE_ACCOUNT_MODULE_DISMISS_ERROR,
  };
}

export function* doRemoveAccountModule(action) {
  const { accountId, moduleId, moduleName } = action;
  try {
    yield call(protectedJsonDelete, `accounts/${accountId}/modules/${moduleId}`);
  } catch (err) {
    yield put({
      type: ACCOUNT_SETTINGS_REMOVE_ACCOUNT_MODULE_FAILURE,
      data: { error: err },
    });
    return;
  }

  if (moduleName === ACCOUNT_MODULE_PURSUIT_TRACKING) {
    yield put({
      type: ACCOUNT_SETTINGS_REMOVE_PURSUIT_TRACKING_MODULE_SUCCESS,
      moduleName,
      accountId,
    });
  }

  yield put({
    type: ACCOUNT_SETTINGS_REMOVE_ACCOUNT_MODULE_SUCCESS,
    moduleName,
  });
}

export function* watchRemoveAccountModule() {
  yield takeLatest(ACCOUNT_SETTINGS_REMOVE_ACCOUNT_MODULE_BEGIN, doRemoveAccountModule);
}

export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNT_SETTINGS_REMOVE_ACCOUNT_MODULE_BEGIN:
      return {
        ...state,
        removeAccountModulePending: true,
        removeAccountModuleError: null,
      };

    case ACCOUNT_SETTINGS_REMOVE_ACCOUNT_MODULE_SUCCESS: {
      const { moduleName } = action;
      return {
        ...state,
        accountModules: state.accountModules.filter(module => module.name !== moduleName),
        removeAccountModulePending: false,
        removeAccountModuleError: null,
      };
    }
    case ACCOUNT_SETTINGS_REMOVE_ACCOUNT_MODULE_FAILURE:
      return {
        ...state,
        removeAccountModulePending: false,
        removeAccountModuleError: action.data.error,
      };

    case ACCOUNT_SETTINGS_REMOVE_ACCOUNT_MODULE_DISMISS_ERROR:
      return {
        ...state,
        removeAccountModuleError: null,
      };

    default:
      return state;
  }
}
