const PROJECT = 'PROJECT';

const AVAILABILITY = 'Availability';
const UNAVAILABILITY = 'Unavailability';

const TIME_OFF_RANGE_TYPE = 'TimeOff';

const PROJECTS = 'PROJECTS';
const UTILIZATION = 'UTILIZATION';
const PROJECTS_AND_UTILIZATION = 'PROJECTS_AND_UTILIZATION';
const FULL_COLOR = 'FULL_COLOR';
const LIGHT_COLOR = 'LIGHT_COLOR';

const PEOPLE = 'PEOPLE';
const BLANK = 'BLANK';

const PURSUIT = 'Pursuit';
const CANCELED = 'Canceled';
const LOST = 'Lost';

const VALID_GANTT_DISPLAYOPTIONS = [PROJECTS, UTILIZATION, PROJECTS_AND_UTILIZATION];
const VALID_COLOR_OPTIONS = [FULL_COLOR, LIGHT_COLOR];

const GANTT_DISPLAY_MAP = {
  [PROJECTS]: 'Projects',
  [UTILIZATION]: 'Utilization',
  [PROJECTS_AND_UTILIZATION]: 'Projects & utilization',
};

const COLOR_OPTIONS_MAP = {
  [FULL_COLOR]: 'Full',
  [LIGHT_COLOR]: 'Lite',
};

export {
  PROJECT,
  AVAILABILITY,
  UNAVAILABILITY,
  TIME_OFF_RANGE_TYPE,
  PROJECTS,
  UTILIZATION,
  PROJECTS_AND_UTILIZATION,
  FULL_COLOR,
  LIGHT_COLOR,
  PEOPLE,
  BLANK,
  PURSUIT,
  CANCELED,
  LOST,
  VALID_GANTT_DISPLAYOPTIONS,
  VALID_COLOR_OPTIONS,
  GANTT_DISPLAY_MAP,
  COLOR_OPTIONS_MAP,
};
