import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DATE_INPUT_FORMAT } from '../../../common/constants';
import { MONTH, YEAR, THREE_YEARS, TIME_INTERVAL_UNIT_MAP, TIME_MULTIPLIERS_MAP, INTERVALS_MAP } from '../../gantt/constants';
import { PEOPLE_GANTT_CONFIG_KEY_V2 } from '../../gantt/redux/constants';
import { PEOPLE_GANTT_REPORT_ARGS_KEY, getStorageKey } from '../../../common/localStorageKeys';
import { trackAnalytics } from '../../common/redux/trackAnalytics';
import { DefaultGanttFileExportMenu } from '..';
import { COLOR_OPTIONS_MAP, GANTT_DISPLAY_MAP } from '../../gantt/people-gantt-v2/constants';
import { REPORTS_EXPORT_PEOPLE_GANTT } from '../../../analytics/reports/constants';
import { getPaperWidth } from '../utils/reportUtils';

const PeopleGanttFileExportMenu = ({
  menuTitle,
  handleClose,
}) => {
  const dispatch = useDispatch();

  const { accountId, activeView } = useSelector(({ common }) => common);
  const { filter, sort, search } = useSelector(({ queries }) => queries?.filteredPeople || {});
  const ganttSettings = useSelector(({ gantt }) => gantt?.instances[PEOPLE_GANTT_CONFIG_KEY_V2]);
  const userId = useSelector(({ login }) => login?.userInfo?.sub);

  const initialTimeInterval = ganttSettings?.timeInterval ?? YEAR;

  const printHandler = useCallback((selectedDate, timelineLength, paperSize) => {
    const { displayOption, colorOption, showAvatar } = ganttSettings;

    const startDate = moment(selectedDate)
      .clone()
      .startOf(MONTH)
      .format(DATE_INPUT_FORMAT);

    const timeMultiplier = TIME_MULTIPLIERS_MAP[timelineLength];
    const timeInterval = TIME_INTERVAL_UNIT_MAP[timelineLength];

    const endDate = moment(selectedDate)
      .clone()
      .add(timeMultiplier, timeInterval)
      .subtract(1, 'day')
      .format(DATE_INPUT_FORMAT);

    const width = getPaperWidth(paperSize);

    const reportArgs = JSON.stringify({
      startDate,
      endDate,
      timeInterval,
      timeMultiplier,
      width,
      displayOption,
      colorOption,
      showAvatar,
      filter,
      sort,
      search,
      pagesPerWindow: timelineLength === THREE_YEARS ? 3 : 1,
    });

    const reportArgsStorageKey = getStorageKey(activeView, accountId, PEOPLE_GANTT_REPORT_ARGS_KEY, userId);
    localStorage.setItem(reportArgsStorageKey, reportArgs);

    dispatch(trackAnalytics(REPORTS_EXPORT_PEOPLE_GANTT, {
      'Earliest month displayed': moment(startDate).format('MMM, YYYY'),
      'Interval selected': INTERVALS_MAP[timelineLength],
      'Page size selected': paperSize,
      'Filters applied': filter.args.map(({ label }) => label).join(', '),
      'Sort applied': sort.args?.[0]?.label,
      'Search applied': search?.args?.[0]?.activeFilters?.[0] || '',
      'Gantt display option applied': GANTT_DISPLAY_MAP[displayOption],
      'Project color option applied': COLOR_OPTIONS_MAP[colorOption],
      'Photos displayed': !!showAvatar,
    }));

    const url = `${window.location.origin}/accounts/${accountId}/reports/people/gantt`;
    window.open(url, null, 'noopener');
    handleClose();
  }, [accountId, activeView, dispatch, filter, ganttSettings, handleClose, search, sort, userId]);

  return (
    <DefaultGanttFileExportMenu
      menuTitle={menuTitle}
      onClose={handleClose}
      onPrint={printHandler}
      initialTimeInterval={initialTimeInterval}
    />
  );
};

PeopleGanttFileExportMenu.propTypes = {
  menuTitle: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default PeopleGanttFileExportMenu;
