import { PERM_READ, PERM_ROLE } from '../permissions/utils/constants';
import { ACCOUNT_MODULE_STANDARD } from '../../common/constants';
import { LoadableRoles } from '.';

export default {
  path: 'accounts/:id/roles',
  name: 'Unfilled Roles',
  module: ACCOUNT_MODULE_STANDARD,
  action: PERM_READ,
  subject: PERM_ROLE,
  component: LoadableRoles,
};
