import React from 'react';
import { Grid, Typography, Paper, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

const ExpiredInvite = () => (
  <Paper component="section" variant="outlined" className="spacious-container login-expired-invite">
    <Grid
      container
      item
      direction="column"
      spacing={3}
      justify="center"
      xs={12}
    >
      <Grid item>
        <Typography
          className="headline"
          variant="subtitle1"
          component="h4"
        >
          Oops! It looks like your invitation has expired.
        </Typography>
      </Grid>

      <Grid item>
        <Typography
          align="center"
          component="span"
        >
          Already have an account?
        </Typography>
      </Grid>

      <Button
        className="login-redirect"
        color="primary"
        disableRipple
        size="medium"
        variant="contained"
      >
        <Link to="/login">
          LOG IN
        </Link>
      </Button>

    </Grid>
  </Paper>
);

export default ExpiredInvite;
