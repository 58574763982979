import {
  ACCOUNT_MODULE_ASSIGNMENT_COMMUNICATION,
  ACCOUNT_MODULE_ATTACHMENTS,
  ACCOUNT_MODULE_LOCATION,
} from '../../../common/constants';

const invitationInputs = groups => [
  {
    id: 1,
    label: 'Email',
    name: 'email',
    type: 'text',
    placeholder: 'Enter an email address',
    required: true,
  },
  {
    id: 2,
    label: 'Permission Group',
    name: 'permissionGroup',
    type: 'select',
    placeholder: 'Select a permission group',
    options: groups.map(group => ({ label: group.name, value: group.name })),
    required: true,
  },
];

const INVITE_USER_MODAL = 'INVITE_USER_MODAL';

const MANAGED_MODULES = [
  ACCOUNT_MODULE_ASSIGNMENT_COMMUNICATION,
  ACCOUNT_MODULE_ATTACHMENTS,
  ACCOUNT_MODULE_LOCATION,
];

const DISTANCE_UNITS = {
  KM: {
    value: 'km',
    label: 'kilometers',
    conversionFactorMetric: 1_000,
  },
  MI: {
    value: 'mi',
    label: 'miles',
    conversionFactorMetric: 1_609.344,
  },
};

const ACCOUNTS_COLUMNS = [
  { name: 'Account Name' },
  { name: '# of Projects' },
  { name: '# of People' },
  { name: '# of Bench Users' },
];

const ACCOUNTS_SORT_KEY = 'sortedColumns-accounts';

const ACCOUNTS_DEBOUNCE_DURATION = 200;

export {
  invitationInputs,
  INVITE_USER_MODAL,
  MANAGED_MODULES,
  DISTANCE_UNITS,
  ACCOUNTS_COLUMNS,
  ACCOUNTS_SORT_KEY,
  ACCOUNTS_DEBOUNCE_DURATION,
};
