import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListWithLoader, CertificationItem, Loader } from '@bridgit/foundation';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { MULTI_STATE_MODAL_ID } from '../common/redux/constants';
import {
  DEFAULT_LIST_ITEMS_LIMIT,
  DEFAULT_LIST_OFFSET,
  CERT_ATTACHMENTS_LIST_OFFSET,
  PROFILE_CERTIFICATION_LIST,
  PROFILE_CERTIFICATION_ADD,
  PROFILE_CERTIFICATION_EDIT,
  PERSON_CERTIFICATIONS_TAB_NAME,
  PROFILE_DETAILS_MODAL_CONTEXT,
  PROFILE_DETAILS_PROFILE_CONTEXT,
} from './constants';
import { PEOPLE_LIST_SELECTION_ID } from './redux/constants';
import { getCertificationStatus } from './utils/certificationUtils';
import {
  clearPersonCertifications,
  getPersonCertificationAttachments,
  getPersonCertifications,
  addPersonCertification,
  updatePersonCertification,
  deletePersonCertification,
  uploadPersonAttachment,
  toggleCertificationAttachments,
} from './redux/actions';
import { ProfileAttachment, PersonCertificationForm, ProfileUploadAttachment } from '.';
import { getAccountCertifications } from '../account-settings/redux/actions';
import { Can } from '../wrapped-components';
import { PERM_ACCOUNT, PERM_PERSON, PERM_WRITE } from '../permissions/utils/constants';
import { hasModuleEnabled, isAuthorized } from '../permissions/utils/permissionUtils';
import { ACCOUNT_MODULE_ATTACHMENTS, PEOPLE_ACCOUNT_MODULE_COMPONENT } from '../../common/constants';
import { momentToString } from '../../utils/dateUtils';

const ProfileCertifications = () => {
  const dispatch = useDispatch();

  const { accountId } = useSelector(({ common }) => common);

  const isOpenInModal = useSelector(({ modalManager }) => modalManager.activeModal === MULTI_STATE_MODAL_ID);

  const { permissions } = useSelector(({ login }) => login.userInfo);

  const {
    personCertificationItems,
    getPersonCertificationsPending,
    selectedPerson,
    hasMorePersonCertifications,
    getPersonCertificationAttachmentsPending,
    deletePersonAttachmentPending,
    uploadPersonAttachmentPending,
  } = useSelector(({ people }) => {
    const {
      personCertifications,
      getPersonCertificationsPending,
      personSelections,
      getPersonCertificationAttachmentsPending,
      deletePersonAttachmentPending,
      uploadPersonAttachmentPending,
    } = people;

    return {
      personCertificationItems: personCertifications.items || [],
      getPersonCertificationsPending,
      selectedPerson: isOpenInModal ? personSelections?.[MULTI_STATE_MODAL_ID] : personSelections?.[PEOPLE_LIST_SELECTION_ID],
      hasMorePersonCertifications: personCertifications.hasMore,
      getPersonCertificationAttachmentsPending,
      deletePersonAttachmentPending,
      uploadPersonAttachmentPending,
    };
  });

  const { accountCertifications, getAccountCertificationsPending, isAttachmentModuleOn } = useSelector(({ accountSettings }) => {
    const { accountModules, getAccountCertificationsPending, certifications } = accountSettings;
    const isAttachmentModuleOn = hasModuleEnabled(accountModules, ACCOUNT_MODULE_ATTACHMENTS, PEOPLE_ACCOUNT_MODULE_COMPONENT);

    return {
      accountCertifications: certifications,
      getAccountCertificationsPending,
      isAttachmentModuleOn,
    };
  });

  const hasPersonWritePerms = useMemo(
    () => isAuthorized(accountId, permissions, PERM_WRITE, PERM_PERSON),
    [accountId, permissions],
  );

  const isAddVisible = useMemo(
    () => !!accountCertifications.length && hasPersonWritePerms,
    [accountCertifications, hasPersonWritePerms],
  );

  const currentView = useMemo(() => (isOpenInModal ? PROFILE_DETAILS_MODAL_CONTEXT : PROFILE_DETAILS_PROFILE_CONTEXT), [isOpenInModal]);

  const [activeState, setActiveState] = useState(PROFILE_CERTIFICATION_LIST);
  const [editCertification, setEditCertification] = useState();

  const selectCertification = useCallback(
    certId => personCertificationItems.find(cert => cert.id === certId),
    [personCertificationItems],
  );

  const selectAccountCertification = useCallback(
    certId => accountCertifications.find(cert => cert.id === certId),
    [accountCertifications],
  );

  const addHandler = useCallback(() => setActiveState(PROFILE_CERTIFICATION_ADD), []);

  const editHandler = useCallback(certId => () => {
    setEditCertification(selectCertification(certId));
    setActiveState(PROFILE_CERTIFICATION_EDIT);
  }, [selectCertification]);

  const getAnalyticsPayload = useCallback(() => {
    const {
      id: certificationId,
      name: certificationName,
      expiryDate,
      daysWarnBeforeExpire,
      hasAttachment,
    } = editCertification;
    const { name: personName } = selectedPerson || {};
    const { requireExpiration } = selectAccountCertification(certificationId);

    return {
      personName,
      currentView,
      certificationName,
      expiryDate,
      daysWarnBeforeExpire,
      hasAttachment,
      requireExpiration,
    };
  }, [selectAccountCertification, currentView, editCertification, selectedPerson]);

  const closeHandler = useCallback(() => setActiveState(PROFILE_CERTIFICATION_LIST), []);

  const addCertificationSubmitHandler = useCallback(({ certificationId, expiryDate }) => {
    if (!selectedPerson?.id) return;
    const { requireExpiration } = selectAccountCertification(certificationId);
    const analyticsPayload = {
      personName: selectedPerson?.name,
      currentView,
      requireExpiration,
    };
    const data = {
      certificationId,
      expiryDate: momentToString(expiryDate),
    };

    dispatch(addPersonCertification(accountId, selectedPerson.id, data, analyticsPayload));
    closeHandler();
  }, [accountId, currentView, dispatch, closeHandler, selectAccountCertification, selectedPerson]);

  const editCertificationSubmitHandler = useCallback(({ certificationId, expiryDate }) => {
    if (!selectedPerson?.id) return;
    const analyticsPayload = getAnalyticsPayload();
    const data = { expiryDate: momentToString(expiryDate) };

    dispatch(updatePersonCertification(accountId, selectedPerson.id, certificationId, data, analyticsPayload));
    closeHandler();
  }, [accountId, dispatch, getAnalyticsPayload, closeHandler, selectedPerson?.id]);

  const deleteCertificationHandler = useCallback(() => {
    if (!selectedPerson?.id) return;
    const analyticsPayload = getAnalyticsPayload();

    dispatch(deletePersonCertification(accountId, selectedPerson.id, editCertification.id, analyticsPayload));
    closeHandler();
  }, [accountId, dispatch, getAnalyticsPayload, closeHandler, editCertification?.id, selectedPerson?.id]);

  const [offset, setOffset] = useState(0);
  const prevGetPersonCertificationsPending = useRef(getPersonCertificationsPending);

  const [attachmentsOffset, setAttachmentsOffset] = useState(0);
  const prevGetPersonCertificationAttachmentsPending = useRef(getPersonCertificationAttachmentsPending);

  // increment offset before getting a new portion of certificates
  useEffect(() => {
    if (!prevGetPersonCertificationsPending.current && getPersonCertificationsPending) {
      setOffset(offset + DEFAULT_LIST_OFFSET);
    }
    prevGetPersonCertificationsPending.current = getPersonCertificationsPending;
  }, [getPersonCertificationsPending, offset]);

  // increment attachments offset before getting a new portion of cert attachments
  useEffect(() => {
    if (!prevGetPersonCertificationAttachmentsPending.current && getPersonCertificationAttachmentsPending) {
      setAttachmentsOffset(attachmentsOffset + CERT_ATTACHMENTS_LIST_OFFSET);
    }
    prevGetPersonCertificationAttachmentsPending.current = getPersonCertificationAttachmentsPending;
  }, [getPersonCertificationAttachmentsPending, attachmentsOffset]);

  useEffect(() => {
    const callback = () => dispatch(getAccountCertifications(accountId));

    if (selectedPerson?.id) {
      dispatch(getPersonCertifications(accountId, selectedPerson.id, 0, DEFAULT_LIST_ITEMS_LIMIT, callback));
    }

    return () => {
      closeHandler(); // Set active state to certifications list on person or account change
      dispatch(clearPersonCertifications());
    };
  }, [accountId, dispatch, selectedPerson?.id, closeHandler]);

  const emptyText = useMemo(() => {
    if (getPersonCertificationsPending || getAccountCertificationsPending) return <Loader />;

    if (!accountCertifications.length) {
      return (
        <Can
          action={PERM_WRITE}
          subject={PERM_ACCOUNT}
          yes={(
            <div className="people-profile-certifications-empty">
              <p>Make sure your people have the right certifications to get the job done by tracking them in Bridgit Bench.</p>
              <p>No certifications have been set up on your account, visit your account settings to get started.</p>
              <Link className="people-profile-certifications-link" to={`/accounts/${accountId}/settings/people/certifications`}>
                Go to settings
              </Link>
            </div>
          )}
          no={(
            <div className="people-profile-certifications-empty">
              <p>Make sure your people have the right certifications to get the job done by tracking them in Bridgit Bench.</p>
              <p>No certifications have been set up on your account, reach out to your account administrator to get started.</p>
            </div>
          )}
        />
      );
    }

    return (
      <div className="people-profile-certifications-empty-person-certs">
        <Can
          action={PERM_WRITE}
          subject={PERM_PERSON}
          yes="No Certifications have been added. Select ‘Add certification’ to get started"
          no="No Certifications have been added"
        />
      </div>
    );
  }, [accountCertifications, accountId, getPersonCertificationsPending, getAccountCertificationsPending]);

  const loadMoreItems = useCallback(
    () => hasMorePersonCertifications && selectedPerson?.id && dispatch(getPersonCertifications(accountId, selectedPerson.id, offset, DEFAULT_LIST_ITEMS_LIMIT)),
    [hasMorePersonCertifications, selectedPerson?.id, dispatch, accountId, offset],
  );

  const manageAttachmentsHandler = useCallback(
    (certificationId, isCollapsing, hasAttachment) => {
      if (!isCollapsing && hasAttachment && selectedPerson?.id) {
        dispatch(getPersonCertificationAttachments(accountId, selectedPerson.id, certificationId));
      }

      if (isCollapsing) {
        setAttachmentsOffset(0);
      }

      dispatch(toggleCertificationAttachments(isCollapsing));
    },
    [accountId, dispatch, selectedPerson?.id],
  );

  const viewMoreAttachmentsHandler = useCallback(certId => () => {
    const certification = selectCertification(certId);

    if (certification.hasMoreAttachments && selectedPerson?.id) {
      dispatch(getPersonCertificationAttachments(accountId, selectedPerson.id, certId, attachmentsOffset));
    }
  }, [accountId, attachmentsOffset, dispatch, selectCertification, selectedPerson?.id]);

  const renderAttachment = useCallback(certId => (attachment) => {
    const { id, binaryUrl, createdOn, isPrivate, name, state, size } = attachment;

    const certification = selectCertification(certId);
    const numOfLoadedAttachments = certification.attachments?.length;

    return (
      <ProfileAttachment
        key={id}
        id={id}
        name={name}
        createdOn={createdOn}
        binaryUrl={binaryUrl}
        isPrivate={isPrivate}
        state={state}
        size={size}
        numOfLoadedAttachments={numOfLoadedAttachments}
        certificationId={certId}
        activeTab={PERSON_CERTIFICATIONS_TAB_NAME}
      />
    );
  }, [selectCertification]);

  const attachmentUploadHandler = useCallback(id => (file, binary, isPrivate) => {
    const { name } = file;
    const { id: personId, name: personName } = selectedPerson;

    const {
      name: certificationName,
      daysWarnBeforeExpire,
      requireExpiration,
    } = selectAccountCertification(id);

    const data = { name, isPrivate };

    const analyticsPayload = {
      personName,
      personId,
      uploadedFrom: currentView,
      file,
      isPrivate,
      tabUploadedFrom: PERSON_CERTIFICATIONS_TAB_NAME,
      certificationName,
      daysWarnBeforeExpire,
      requireExpiration,
    };

    dispatch(uploadPersonAttachment(accountId, personId, data, binary, analyticsPayload, id));
  }, [accountId, dispatch, currentView, selectAccountCertification, selectedPerson]);

  const renderPersonCertificationItem = useCallback(({ id, name, expiryDate, daysWarnBeforeExpire, hasAttachment }) => {
    const { attachments, hasMoreAttachments } = selectCertification(id);

    const renderUploader = () => (
      <ProfileUploadAttachment onUpload={attachmentUploadHandler(id)} pending={uploadPersonAttachmentPending} />
    );

    return (
      <CertificationItem
        id={id}
        key={id}
        name={name}
        expiryDate={expiryDate}
        status={getCertificationStatus(expiryDate, daysWarnBeforeExpire)}
        onManageAttachmentsClick={isAttachmentModuleOn ? manageAttachmentsHandler : null}
        attachments={attachments}
        renderAttachment={renderAttachment(id)}
        hasAttachments={hasAttachment}
        onViewMoreAttachmentsClick={hasMoreAttachments ? viewMoreAttachmentsHandler(id) : null}
        onEditClick={hasPersonWritePerms ? editHandler(id) : null}
        renderUploader={hasPersonWritePerms ? renderUploader : null}
      />
    );
  }, [
    manageAttachmentsHandler,
    viewMoreAttachmentsHandler,
    hasPersonWritePerms,
    isAttachmentModuleOn,
    editHandler,
    renderAttachment,
    selectCertification,
    attachmentUploadHandler,
    uploadPersonAttachmentPending,
  ]);

  const certificationsList = useMemo(() => (
    <>
      {isAddVisible && (
        <Button className="add-certification-button" color="primary" variant="contained" onClick={addHandler}>
          Add certification
        </Button>
      )}
      <ListWithLoader
        items={personCertificationItems}
        renderItems={renderPersonCertificationItem}
        loadMore={loadMoreItems}
        isLoading={getPersonCertificationsPending}
        isLoadingPage={getPersonCertificationsPending || getPersonCertificationAttachmentsPending || deletePersonAttachmentPending}
        emptyText={emptyText}
        className={classnames(`people-profile-certifications-list${isAddVisible ? '-add' : ''}`)}
      />
    </>
  ), [
    emptyText,
    getPersonCertificationAttachmentsPending,
    getPersonCertificationsPending,
    isAddVisible,
    loadMoreItems,
    addHandler,
    personCertificationItems,
    renderPersonCertificationItem,
    deletePersonAttachmentPending,
  ]);

  const certificationStates = useMemo(() => ({
    [PROFILE_CERTIFICATION_LIST]: {
      display: 'Certifications',
      content: certificationsList,
    },
    [PROFILE_CERTIFICATION_ADD]: {
      display: 'Add Certification',
      content: (
        <PersonCertificationForm
          onClose={closeHandler}
          onSubmit={addCertificationSubmitHandler}
        />
      ),
    },
    [PROFILE_CERTIFICATION_EDIT]: {
      display: 'Edit Certification',
      content: (
        <PersonCertificationForm
          onClose={closeHandler}
          onSubmit={editCertificationSubmitHandler}
          onDelete={deleteCertificationHandler}
          editCertification={editCertification}
        />
      ),
    },
  }), [
    certificationsList,
    closeHandler,
    editCertification,
    editCertificationSubmitHandler,
    addCertificationSubmitHandler,
    deleteCertificationHandler,
  ]);

  const renderContent = useCallback(() => {
    const { display, content } = certificationStates[activeState];

    return (
      <div className="people-profile-certifications">
        <div className="people-profile-certifications-header">{display}</div>
        {content}
      </div>
    );
  }, [activeState, certificationStates]);

  return renderContent();
};

export default ProfileCertifications;
