import React from 'react';
import PropTypes from 'prop-types';
import { DOT } from '../../common/constants';
import { ACTIVE, PURSUIT } from './constants';

function ProjectStatus({ project }) {
  return (
    <div className={`projects-project-status ${project.state.toLowerCase()}`}>
      <span>{`${DOT} ${project.state}`}</span>
      {[ACTIVE, PURSUIT].includes(project.state) && project.currentPhases &&
        project.currentPhases.map(phase => (
          phase?.subPhases?.length
            ? phase.subPhases.map(sp => ` (${phase.name} / ${sp})`)
            : ` (${phase.name})`
        ))}
    </div>
  );
}

ProjectStatus.propTypes = {
  project: PropTypes.object.isRequired,
};

export default ProjectStatus;
