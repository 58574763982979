import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CircularProgress } from '@material-ui/core';

function LoadingPaper({
  loading,
  children,
  className,
  title,
}) {
  return (
    <div className={classNames('common-loading-paper', className)}>
      {title}
      {children}
      {loading && <CircularProgress className="spinner" size={20} />}
    </div>
  );
}

LoadingPaper.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.node,
};

LoadingPaper.defaultProps = {
  loading: false,
  className: '',
  title: null,
};

export default LoadingPaper;
