import {
  Paper,
  Tabs,
  Tab,
  Grid,
} from '@material-ui/core';
import { Button, NavigateBack } from '@bridgit/foundation';
import React, { PureComponent, createRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import deepEqual from 'react-fast-compare';
import moment from 'moment';
import classNames from 'classnames';
import {
  PERM_WRITE,
  PERM_PERSON,
  PERM_READ,
  PERM_FINANCIALS,
  PERM_SETTINGS,
} from 'src/features/permissions/utils/constants';
import {
  DOT,
  DATE_INPUT_FORMAT,
  MIN_DATA_RANGE_IN_MONTHS,
  MAX_DATA_RANGE_IN_YEARS,
  ACCOUNT_MODULE_FINANCIAL_TRACKING,
} from 'src/common/constants';
import { PURSUIT } from 'src/features/projects/constants';
import { hasModuleEnabled, isAuthorized } from 'src/features/permissions/utils/permissionUtils';
import { PROFILE_DETAILS_MODAL_CONTEXT } from './constants';
import {
  getPersonIssues,
  getPersonCurrentAllocations,
  getPersonAllocations,
  getPersonUpcomingAllocations,
  getPersonPastAllocations,
  getPersonAvailabilities,
  getAccountAllocations,
  updatePerson,
  getUploadUrl,
  removeProfilePicture,
  setProfilePicture,
} from './redux/actions';
import { Confirm, FieldEditor } from '../common';
import {
  AddAvatar,
  ProfileContact,
  ProfileDetail,
  ProfileProjects,
  ProfileIssues,
  ProfileEmployment,
  PeriodsOfUnavailability,
  PersonAvailability,
  PersonDetailsSideMenu,
} from '.';
import { closeModal } from '../modal-manager/redux/actions';
import { getPersonFields } from '../account-settings/redux/actions';
import { getTitleCosts } from '../accounts/redux/getTitleCosts';

export class PeopleProfile extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    onBackClick: PropTypes.func,
    selectedPerson: PropTypes.object.isRequired,
    people: PropTypes.object.isRequired,
    accountSettings: PropTypes.object.isRequired,
    selectedPersonAvailability: PropTypes.number.isRequired,
    accountId: PropTypes.number.isRequired,
    toggleActivation: PropTypes.func.isRequired,
    showBackButton: PropTypes.bool.isRequired,
    projectChanged: PropTypes.bool.isRequired,
    runsInModal: PropTypes.bool,
    personSidePanelOpen: PropTypes.bool.isRequired,
    getPersonFields: PropTypes.func.isRequired,
    getPersonIssues: PropTypes.func.isRequired,
    getPersonCurrentAllocations: PropTypes.func.isRequired,
    getPersonAllocations: PropTypes.func.isRequired,
    getPersonUpcomingAllocations: PropTypes.func.isRequired,
    getPersonPastAllocations: PropTypes.func.isRequired,
    getPersonAvailabilities: PropTypes.func.isRequired,
    getAccountAllocations: PropTypes.func.isRequired,
    updatePerson: PropTypes.func.isRequired,
    getUploadUrl: PropTypes.func.isRequired,
    removeProfilePicture: PropTypes.func.isRequired,
    setProfilePicture: PropTypes.func.isRequired,
    getTitleCosts: PropTypes.func.isRequired,
    defaultCostRateFlag: PropTypes.bool.isRequired,
    hasTitleCostsPerms: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    className: '',
    onBackClick: () => { },
    runsInModal: false,
  };

  constructor(props) {
    super(props);

    this.scrollRef = createRef();

    this.state = {
      selectedTab: 'projects',
      avatarOpen: false,
      deleteModalOpen: false,
      reactivateModalOpen: false,
      editing: null,
    };
  }

  componentDidMount() {
    const {
      selectedPerson,
      people,
      accountId,
      getPersonFields,
      getPersonIssues,
      getPersonCurrentAllocations,
      getPersonAllocations,
      getPersonUpcomingAllocations,
      getPersonPastAllocations,
      getTitleCosts,
      defaultCostRateFlag,
      accountSettings,
      hasTitleCostsPerms,
    } = this.props;

    const selectedPersonId = selectedPerson.id;
    const isDeactivated = !selectedPerson.isActive && selectedPerson.state !== 'Active';

    const allocations = people.currentAllocations[selectedPersonId];
    const selectedPersonProjects = allocations ? [...new Set(allocations.map(allocation => allocation.projectName))] : [];
    const avatar = selectedPerson.photoUrl || null;

    const hasFinancialModuleEnabled = hasModuleEnabled(accountSettings.accountModules, ACCOUNT_MODULE_FINANCIAL_TRACKING);

    if (!isDeactivated) {
      getPersonIssues(accountId, selectedPersonId);
      getPersonCurrentAllocations(accountId, selectedPersonId);
      getPersonAllocations(accountId, selectedPersonId, 0, PURSUIT);
      getPersonUpcomingAllocations(accountId, selectedPersonId);
      getPersonFields(accountId);

      if (defaultCostRateFlag && hasFinancialModuleEnabled && hasTitleCostsPerms) {
        getTitleCosts(accountId);
      }
    }
    getPersonPastAllocations(accountId, selectedPersonId);

    this.setState({
      selectedPersonId,
      selectedPersonProjects,
      avatar,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      selectedPerson,
      people,
      projectChanged,
      accountId,
      getPersonFields,
      getPersonIssues,
      getPersonCurrentAllocations,
      getPersonAllocations,
      getPersonUpcomingAllocations,
      getPersonPastAllocations,
      getPersonAvailabilities,
      getAccountAllocations,
    } = this.props;

    const selectedPersonId = selectedPerson.id;

    const personChanged = !deepEqual(prevProps.selectedPerson, selectedPerson);
    const doneFieldUpdate = prevProps.people.updatePersonFieldValuePending && !people.updatePersonFieldValuePending;
    const donePersonUpdate = prevProps.people.updatePersonPending && !people.updatePersonPending;
    const pouChanged = (prevProps.people.addUnavailabilityPending && !people.addUnavailabilityPending)
      || (prevProps.people.updateUnavailabilityPending && !people.updateUnavailabilityPending);

    if (doneFieldUpdate || donePersonUpdate || personChanged) {
      const avatar = selectedPerson?.photoUrl || null;

      this.updatePeopleProfileState({
        selectedPersonId,
        avatar,
      });
    }

    if (prevProps.people.getPersonCurrentAllocationsPending && !people.getPersonCurrentAllocationsPending) {
      const allocations = people.currentAllocations[selectedPersonId];
      const selectedPersonProjects = allocations ? [...new Set(allocations.map(allocation => allocation.projectName))] : [];

      this.updatePeopleProfileState({ selectedPersonProjects });
    }

    if (prevProps.selectedPerson.id !== selectedPersonId
      || !deepEqual(prevProps.selectedPerson.employmentDates, selectedPerson.employmentDates)
      || pouChanged
      || (prevProps.projectChanged && !projectChanged)) {
      const isDeactivated = !selectedPerson.isActive && selectedPerson.state !== 'Active';

      if (!isDeactivated) {
        getPersonIssues(accountId, selectedPersonId);
        getPersonCurrentAllocations(accountId, selectedPersonId);
        getPersonAllocations(accountId, selectedPersonId, 0, PURSUIT);
        getPersonUpcomingAllocations(accountId, selectedPersonId);
        getPersonFields(accountId);

        const startDate = moment.utc().subtract(MIN_DATA_RANGE_IN_MONTHS, 'months');
        const endDate = moment.utc().add(MAX_DATA_RANGE_IN_YEARS, 'years');
        getAccountAllocations(accountId, startDate.format(DATE_INPUT_FORMAT), endDate.format(DATE_INPUT_FORMAT));
      }

      getPersonPastAllocations(accountId, selectedPersonId);
      getPersonAvailabilities(accountId);
    }
  }

  updatePeopleProfileState = newState => this.setState(newState);

  fieldToEdit = (field) => {
    this.setState({ editing: field });
  }

  onSave = (name) => {
    const { updatePerson, accountId, selectedPerson, runsInModal } = this.props;
    const analyticsPayload = {
      personName: selectedPerson.name,
      personId: selectedPerson.id,
      field: 'Name',
      updatedFrom: runsInModal ? PROFILE_DETAILS_MODAL_CONTEXT : 'People List',
      isActive: selectedPerson.isActive,
    };
    updatePerson(accountId, selectedPerson.id, { name: name.values[0] }, analyticsPayload);

    this.setState({ editing: null });
  }

  onUpdateEmail = (email) => {
    const { updatePerson, accountId, selectedPerson, runsInModal } = this.props;
    const analyticsPayload = {
      personName: selectedPerson.name,
      personId: selectedPerson.id,
      field: 'Email',
      updatedFrom: runsInModal ? PROFILE_DETAILS_MODAL_CONTEXT : 'People List',
      isActive: selectedPerson.isActive,
    };
    updatePerson(accountId, selectedPerson.id, { email }, analyticsPayload);
  }

  handleChange = (event, value) => {
    const { selectedPerson, runsInModal } = this.props;
    const clickedFrom = runsInModal ? PROFILE_DETAILS_MODAL_CONTEXT : 'People List';

    const trackInfo = {
      'Person Name': selectedPerson.name,
      'Person ID': selectedPerson.id,
      'Clicked from': clickedFrom,
    };

    const trackMessage = {
      projects: 'Profile - Projects Tab Clicked',
      issues: 'Profile - Issues Tab Clicked',
      employment: 'Profile - Employment Tab Clicked',
      details: 'Profile - Details Tab Clicked',
      contact: 'Profile - Contact Tab Clicked',
    };

    // Mixpanel - Track Project tab clicks
    window.mixpanel.track(trackMessage[value], trackInfo);

    this.scrollRef.current.scrollTo(0, 0);

    this.setState({ selectedTab: value });
  }

  showDeleteModal = () => {
    this.setState({
      deleteModalOpen: true,
    });
  }

  hideDeleteModal = () => {
    this.setState({
      deleteModalOpen: false,
    });
  }

  onAddAvatar = (image) => {
    const { getUploadUrl, accountId, selectedPerson, runsInModal } = this.props;
    const { selectedPersonId } = this.state;
    const blobUrl = window.URL.createObjectURL(image);

    this.setState({
      avatar: blobUrl,
      avatarOpen: false,
    });

    getUploadUrl(accountId, selectedPersonId, (url) => {
      const analyticsPayload = {
        personName: selectedPerson.name,
        personId: selectedPerson.id,
        uploadedFrom: runsInModal ? PROFILE_DETAILS_MODAL_CONTEXT : 'A Person\'s Profile',
      };
      this.uploadImage(url, image, selectedPersonId, blobUrl, analyticsPayload);
    });
  }

  onDeleteAvatar = () => {
    const { removeProfilePicture, accountId, runsInModal, selectedPerson } = this.props;
    const { selectedPersonId } = this.state;

    this.setState({
      avatar: null,
      deleteModalOpen: false,
    });

    const parentName = runsInModal ? PROFILE_DETAILS_MODAL_CONTEXT : 'A person\'s profile';

    removeProfilePicture(accountId, selectedPersonId, selectedPerson.name, parentName);
  }

  uploadImage = (uploadUrl, imageBlob, selectedPersonId, blobUrl, analyticsPayload) => {
    const { setProfilePicture } = this.props;
    setProfilePicture(uploadUrl, imageBlob, selectedPersonId, blobUrl, analyticsPayload);
  }

  showAvatar = () => {
    this.setState({
      avatarOpen: true,
    });
  }

  hideAvatar = () => {
    this.setState({
      avatarOpen: false,
    });
  }

  showReactivateModal = () => {
    this.setState({
      reactivateModalOpen: true,
    });
  }

  hideReactivateModal = () => {
    this.setState({
      reactivateModalOpen: false,
    });
  }

  onReactivatePerson = () => {
    const { toggleActivation, runsInModal } = this.props;

    this.hideReactivateModal();
    const parentName = runsInModal ? PROFILE_DETAILS_MODAL_CONTEXT : 'People List';
    toggleActivation(parentName);
  }

  Content = () => {
    const { selectedTab, selectedPersonProjects } = this.state;
    const { accountSettings, people, toggleActivation, selectedPerson, runsInModal } = this.props;

    switch (selectedTab) {
      case 'projects':
        return (
          <ProfileProjects
            personId={selectedPerson.id}
            isDeactivated={!selectedPerson.isActive && selectedPerson.state !== 'Active'}
            runsInModal={runsInModal}
          />
        );
      case 'issues':
        return (
          <ProfileIssues
            issues={people.issues[selectedPerson.id]}
            loading={people.getPersonIssuesPending}
            runsInModal={runsInModal}
          />
        );
      case 'employment':
        return (
          <div>
            <ProfileEmployment
              person={selectedPerson}
              runsInModal={runsInModal}
            />
            <PeriodsOfUnavailability
              person={selectedPerson}
              runsInModal={runsInModal}
            />
          </div>
        );
      case 'details':
        return (
          <ProfileDetail
            person={selectedPerson}
            selectedPersonFields={selectedPerson.fields}
            personFields={accountSettings.personFields}
            selectedPersonProjects={selectedPersonProjects}
            allocations={people.currentAllocations[selectedPerson.id]}
            hasMoreAllocations={people.moreCurrentAllocations}
            toggleActivation={toggleActivation}
            runsInModal={runsInModal}
          />
        );
      case 'contact':
        return (
          <ProfileContact
            person={selectedPerson}
            selectedPersonFields={selectedPerson.fields}
            personFields={accountSettings.personFields}
            onUpdateEmail={this.onUpdateEmail}
            updatePersonError={people.updatePersonError}
            updatePersonPending={people.updatePersonPending}
            runsInModal={runsInModal}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const {
      selectedTab,
      editing,
      avatar,
      avatarOpen,
      deleteModalOpen,
      reactivateModalOpen,
    } = this.state;
    const {
      onBackClick,
      selectedPerson,
      people,
      className,
      selectedPersonAvailability,
      showBackButton,
      runsInModal,
      personSidePanelOpen,
    } = this.props;

    if (!selectedPerson) return null;

    const entity = people.entities.find(entity => entity.id === selectedPerson.id);
    let personAvatar = avatar || '';
    personAvatar = entity && entity.photoUrl ? entity.photoUrl : personAvatar;

    if (!selectedPerson) {
      return false;
    }
    const { id: selectedPersonId, title, name } = selectedPerson;
    const isDeactivated = !selectedPerson.isActive && selectedPerson.state !== 'Active';
    const nameInput = {
      id: 'name',
      name: 'name',
      type: 'text',
      required: true,
      value: name,
    };

    const showBack = runsInModal ? showBackButton : true;
    const navLabel = runsInModal ? 'Back to allocation modal' : 'Back to list of people';

    return (
      <Paper className={classNames('people-people-profile', className, { 'runs-in-modal': runsInModal, 'panel-open': personSidePanelOpen })} ref={this.scrollRef}>
        {!isDeactivated && showBack && (
          <div className={classNames('nav-wrapper', { 'back-to-papm': runsInModal })}>
            <NavigateBack
              className="back-button"
              onClick={onBackClick}
              label={navLabel}
              showLabel={runsInModal}
              edge="start"
            />
          </div>
        )}
        <div className="detail">
          <div className="detail-wrapper">
            <div className="header-content">
              <div className="headerLeft">
                <AddAvatar
                  avatar={personAvatar}
                  onSave={this.onAddAvatar}
                  onDelete={this.showDeleteModal}
                  open={avatarOpen}
                  showModal={this.showAvatar}
                  hideModal={this.hideAvatar}
                  loading={people.getUploadUrlPending || people.setProfilePicturePending}
                  compact
                />
                <div className="title-wrapper">
                  <FieldEditor
                    item={nameInput}
                    input={nameInput}
                    onSave={this.onSave}
                    disabled={people.updatePersonPending}
                    permission={{ action: PERM_WRITE, subject: PERM_PERSON }}
                    pending={people.updatePersonPending}
                    fieldToEdit={this.fieldToEdit}
                    editing={editing === 'name'}
                    showName={false}
                  />
                  <p className="title" variant="subtitle1">{title}</p>
                </div>
                {isDeactivated
                  ? (
                    <div className="reactivate">
                      <div className="deactivated-text">Deactivated</div>
                      <Button variant="outlined" color="primary" className="reactivate-btn" onClick={this.showReactivateModal}>Reactivate</Button>
                    </div>
                  )
                  : (
                    <PersonAvailability
                      className="availability"
                      availableInDays={selectedPersonAvailability}
                    />
                  )}
              </div>
              <div className={classNames('tabs-wrapper', { disabled: !!editing })}>
                <Grid
                  className="tabsGrid"
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  container
                >
                  <Grid item>
                    <Tabs
                      className="tabs"
                      value={selectedTab}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="fullWidth"
                      onChange={this.handleChange}
                    >
                      <Tab label="Projects" value="projects" />
                      {!isDeactivated && (
                        <Tab
                          label={(
                            <div className="issues-label">
                              <span>Issues</span>
                              {people.issues[selectedPersonId] && people.issues[selectedPersonId].length > 0 && (
                                <span className="dot">{DOT}</span>
                              )}
                            </div>
                          )}
                          value="issues"
                        />
                      )}
                      <Tab label="Employment" value="employment" />
                      <Tab label="Details" value="details" />
                      <Tab label="Contact" value="contact" />
                    </Tabs>
                  </Grid>
                </Grid>
                <hr />
              </div>
            </div>
            <div className={classNames('content-wrapper', { disabled: !!editing })}>
              <this.Content />
            </div>
          </div>
        </div>

        {deleteModalOpen && (
          <Confirm
            headline="Delete profile picture"
            acceptButtonText="Delete"
            cancelButtonText="Cancel"
            onCancel={this.hideDeleteModal}
            onAccept={this.onDeleteAvatar}
          >
            <div>Are you sure you want to delete this profile picture?</div>
          </Confirm>
        )}

        {reactivateModalOpen && (
          <Confirm
            className="people-people-profile-reactivate-modal"
            headline="Reactivate Person"
            acceptButtonText="Reactivate"
            cancelButtonText="Cancel"
            onCancel={this.hideReactivateModal}
            onAccept={this.onReactivatePerson}
          >
            <div>You are about to reactivate this person, all historical information associated with this profile will also be visible.</div>
            <div className="small-text">Are you sure you want to reactivate?</div>
          </Confirm>
        )}

        {!runsInModal && <PersonDetailsSideMenu selectedPerson={selectedPerson} />}
      </Paper>
    );
  }
}

const mapStateToProps = ({ people, accountSettings, common, projects, launchDarkly, login }) => {
  const { accountId, multiStateModal, personSidePanelOpen } = common;
  const { showBackButton } = multiStateModal;
  const { defaultCostRate } = launchDarkly;
  const { userInfo: { permissions } } = login;
  const {
    deleteProjectPending,
    updateProjectPending,
    replaceProjectRoleAllocationPending,
    updateProjectRoleAllocationPending,
    addProjectRoleAllocationPending,
    deleteProjectAllocationPending,
  } = projects;

  const projectChanged = deleteProjectPending ||
    updateProjectPending ||
    replaceProjectRoleAllocationPending ||
    updateProjectRoleAllocationPending ||
    addProjectRoleAllocationPending ||
    deleteProjectAllocationPending;

  const hasTitleCostsPerms = isAuthorized(accountId, permissions, PERM_READ, [PERM_FINANCIALS, PERM_SETTINGS]);

  return {
    people,
    accountSettings,
    accountId,
    projectChanged,
    showBackButton,
    personSidePanelOpen,
    defaultCostRateFlag: defaultCostRate,
    hasTitleCostsPerms,
  };
};

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    getPersonIssues: bindActionCreators(getPersonIssues, dispatch),
    getPersonCurrentAllocations: bindActionCreators(getPersonCurrentAllocations, dispatch),
    getPersonAllocations: bindActionCreators(getPersonAllocations, dispatch),
    getPersonUpcomingAllocations: bindActionCreators(getPersonUpcomingAllocations, dispatch),
    getPersonPastAllocations: bindActionCreators(getPersonPastAllocations, dispatch),
    getPersonAvailabilities: bindActionCreators(getPersonAvailabilities, dispatch),
    getAccountAllocations: bindActionCreators(getAccountAllocations, dispatch),
    updatePerson: bindActionCreators(updatePerson, dispatch),
    getUploadUrl: bindActionCreators(getUploadUrl, dispatch),
    removeProfilePicture: bindActionCreators(removeProfilePicture, dispatch),
    setProfilePicture: bindActionCreators(setProfilePicture, dispatch),
    closeModal: bindActionCreators(closeModal, dispatch),
    getPersonFields: bindActionCreators(getPersonFields, dispatch),
    getTitleCosts: bindActionCreators(getTitleCosts, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PeopleProfile);
