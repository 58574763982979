import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { SearchList, PopperButton } from '@bridgit/foundation';

import { generateFormattedProjectRoleData } from './utils/projectUtils';
import { trackAnalytics } from '../common/redux/actions';
import { PROJECT_COPY_ROLE_FROM_PROJECT } from '../../analytics/projects/constants';

const popperModifiers = {
  preventOverflow: {
    enabled: false,
  },
  flip: {
    enabled: false,
  },
};

export const QuickAddRoleSelector = ({
  projects,
  selectedProject,
  onSelectItem,
  projectRoles,
  getProjectRolesPending,
  onSave,
}) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const clickHandler = isOpen => setOpen(isOpen);
  const onCancel = () => setOpen(false);

  const { aggregatedRoleList, formattedProjectRoles } = generateFormattedProjectRoleData(projectRoles);

  const onApplyHandler = (project) => {
    const analyticsPayload = {
      'Project Name': selectedProject.name,
      'Project id': selectedProject.id,
      'Project name copied from': project.name,
      'Project id copied from': project.id,
      'Count of roles added': projectRoles.length,
    };

    dispatch(trackAnalytics(PROJECT_COPY_ROLE_FROM_PROJECT, analyticsPayload));

    setOpen(false);

    onSave(formattedProjectRoles);
  };

  return (
    <div className="projects-quick-add-role-selector">
      <PopperButton
        isOpen={open}
        buttonText="Copy"
        classes="quick-add-filter-popper"
        onClick={clickHandler}
        popperModifiers={popperModifiers}
      >
        <SearchList
          filterKey="name"
          primaryActionText="Save"
          showSelectAll={false}
          items={projects}
          onCancel={onCancel}
          placeholder="Search Projects"
          showSearch
          singleFilter
          onSelectItem={onSelectItem}
          secondaryListText={aggregatedRoleList}
          secondaryListTextLoading={getProjectRolesPending}
          onApply={onApplyHandler}
        />
      </PopperButton>
    </div>
  );
};

QuickAddRoleSelector.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.object),
  onSelectItem: PropTypes.func.isRequired,
  projectRoles: PropTypes.arrayOf(PropTypes.object),
  getProjectRolesPending: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  selectedProject: PropTypes.object.isRequired,
};

QuickAddRoleSelector.defaultProps = {
  projects: [],
  projectRoles: [],
  getProjectRolesPending: false,
};

export default QuickAddRoleSelector;
