import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNT_SETTINGS_DELETE_SUB_PHASE_BEGIN,
  ACCOUNT_SETTINGS_DELETE_SUB_PHASE_SUCCESS,
  ACCOUNT_SETTINGS_DELETE_SUB_PHASE_FAILURE,
  ACCOUNT_SETTINGS_DELETE_SUB_PHASE_DISMISS_ERROR,
} from './constants';
import { protectedJsonDelete } from '../../../utils/api';

export function deleteSubPhase(accountId, phaseId, subPhaseIds) {
  return {
    type: ACCOUNT_SETTINGS_DELETE_SUB_PHASE_BEGIN,
    accountId,
    phaseId,
    subPhaseIds,
  };
}

export function dismissDeleteSubPhaseError() {
  return {
    type: ACCOUNT_SETTINGS_DELETE_SUB_PHASE_DISMISS_ERROR,
  };
}

export function* doDeleteSubPhase(action) {
  const { accountId, phaseId, subPhaseIds } = action;
  try {
    yield call(protectedJsonDelete, `accounts/${accountId}/phases/${phaseId}/subphases`, subPhaseIds);
  } catch (err) {
    yield put({
      type: ACCOUNT_SETTINGS_DELETE_SUB_PHASE_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: ACCOUNT_SETTINGS_DELETE_SUB_PHASE_SUCCESS,
    phaseId,
    data: subPhaseIds,
  });
}

export function* watchDeleteSubPhase() {
  yield takeLatest(ACCOUNT_SETTINGS_DELETE_SUB_PHASE_BEGIN, doDeleteSubPhase);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNT_SETTINGS_DELETE_SUB_PHASE_BEGIN:
      return {
        ...state,
        deleteSubPhasePending: true,
        deleteSubPhaseError: null,
      };

    case ACCOUNT_SETTINGS_DELETE_SUB_PHASE_SUCCESS: {
      const { data, phaseId } = action;
      const { phases } = state;

      const newPhases = phases.map((phase) => {
        if (phaseId === phase.id) {
          const newSubPhases = phase.subPhases ? phase.subPhases.filter(sp => !data.includes(sp.id)) : [];
          return {
            ...phase,
            subPhases: newSubPhases,
          };
        }
        return phase;
      });

      return {
        ...state,
        phases: newPhases,
        deleteSubPhasePending: false,
        deleteSubPhaseError: null,
      };
    }

    case ACCOUNT_SETTINGS_DELETE_SUB_PHASE_FAILURE:
      return {
        ...state,
        deleteSubPhasePending: false,
        deleteSubPhaseError: action.data.error,
      };

    case ACCOUNT_SETTINGS_DELETE_SUB_PHASE_DISMISS_ERROR:
      return {
        ...state,
        deleteSubPhaseError: null,
      };

    default:
      return state;
  }
}
