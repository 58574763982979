import { gql } from 'apollo-boost';
import { PAGE_SIZE, SORT_TYPES } from '../../../common/constants';
import { generateRequestFilter, generateRequestSort } from '../../../filters/graphqlUtils';
import { DEFAULT_REQUEST_SECONDARY_SORT } from '../redux/constants';
import { REQUEST_SCHEMAS } from '../../../common/descriptors/request';
import { ASSIGNMENT_SCHEMAS } from '../../../common/descriptors/assignment';

const { REQUEST_LIST, ASSIGNMENT_LIST } = SORT_TYPES;

const REQUEST_LIST_QUERY = gql`
  query getRequests($accountId: Int!, $size: Int, $from: Int, $filters: [ESQuery], $structuredSort: [RequestSort]) {
    requests(accountId: $accountId, size: $size, from: $from, filters: $filters, structuredSort: $structuredSort) {
      count
      hasMore
      cursor
      items {
        id
        description
        accountId
        project {
          id
          name
          colour
        }
        startDate
        endDate
        requester {
          id
          name
        }
        requestedOn
        assignee {
          id
          name
        }
        workDays
        requiredSkills
        startAt
        endAt
        state
        lastNotifiedOn
        status
      }
    }
  }
`;

const getRequestListQuery = (accountId, queries = {}, pageFrom, pageSize) => {
  const defaults = {
    size: pageSize || PAGE_SIZE,
  };

  const { filter, sort, search } = queries;

  // Requests always return pending or rejected
  const requestFilter = {
    name: 'filter',
    args: [
      ...(filter?.args?.length ? filter.args : []),
      {
        label: 'Status',
        column: 'state',
        activeFilters: [
          { name: 'Open', value: 'Open' },
          { name: 'Rejected', value: 'Rejected' },
        ],
      },
    ],
  };

  const searchFilter = generateRequestFilter(search, REQUEST_SCHEMAS);
  const filters = generateRequestFilter(requestFilter, REQUEST_SCHEMAS).concat(searchFilter);
  const structuredSort = generateRequestSort(sort, REQUEST_SCHEMAS, DEFAULT_REQUEST_SECONDARY_SORT, REQUEST_LIST);
  const variables = { accountId, filters, structuredSort, from: pageFrom, ...defaults };

  return {
    query: REQUEST_LIST_QUERY,
    variables,
  };
};

const getAssignmentListQuery = (accountId, queries = {}, pageFrom, pageSize) => {
  const defaults = {
    size: pageSize || PAGE_SIZE,
  };

  const { filter, sort, search } = queries;

  // Assignments always return assigned requests
  const requestFilter = {
    name: 'filter',
    args: [
      ...(filter?.args?.length ? filter.args : []),
      {
        label: 'Status',
        column: 'state',
        activeFilters: [
          { name: 'Assigned', value: 'Assigned' },
          { name: 'AssignedNotified', value: 'AssignedNotified' },
        ],
      },
    ],
  };

  const searchFilter = generateRequestFilter(search, ASSIGNMENT_SCHEMAS);
  const filters = generateRequestFilter(requestFilter, ASSIGNMENT_SCHEMAS).concat(searchFilter);
  const structuredSort = generateRequestSort(sort, ASSIGNMENT_SCHEMAS, DEFAULT_REQUEST_SECONDARY_SORT, ASSIGNMENT_LIST);
  const variables = { accountId, filters, structuredSort, from: pageFrom, ...defaults };

  return {
    query: REQUEST_LIST_QUERY,
    variables,
  };
};

export {
  getRequestListQuery,
  getAssignmentListQuery,
};
