import React, { PureComponent } from 'react';
import { matchPath } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { disable as disableDarkMode } from 'darkreader';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ldRedux from 'ld-redux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import 'typeface-roboto';
import { setUserInfo } from 'src/common/analyticsHelper';
import {
  isListIdChange,
  getShortUrl,
} from 'src/analytics/utils';
import config from '../common/envConfig';
import { SideNav, TopBar, Redirector, MainContent, RootContent } from '../features/common';
import { AuthenticatedView } from '../features/permissions';
import { userLogout, getDefaultTeams } from '../features/login/redux/actions';
import { getAccounts } from '../features/accounts/redux/actions';
import { setActiveAccountId } from '../features/common/redux/actions';
import MultiStateModal from '../features/common/MultiStateModal';
import { MULTI_STATE_MODAL_ID } from '../features/common/redux/constants';
import {
  BLACKLIST_MIXPANEL_URLS,
  BLACKLIST_DUPLICATE_MIXPANEL_URLS,
} from '../features/common/constants';
import { STANDARD_THEME } from '../features/themes/constants';
import { ErrorSnackbar, ErrorBoundary } from '../features/errors';
import { SuccessToast } from '../features/toasts';
import { getProjectDefaults } from '../features/projects/redux/actions';
import { getMessagesForLocale } from '../i18n';
import { EN } from '../features/locale/redux/constants';

class App extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    userLogout: PropTypes.func.isRequired,
    getAccounts: PropTypes.func.isRequired,
    setActiveAccountId: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    login: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    accountEntities: PropTypes.arrayOf(PropTypes.object),
    getAccountsPending: PropTypes.bool.isRequired,
    dragging: PropTypes.bool.isRequired,
    collapsedNav: PropTypes.bool.isRequired,
    getProjectDefaults: PropTypes.func.isRequired,
    getDefaultTeams: PropTypes.func.isRequired,
    locale: PropTypes.string,
    isStandardTheme: PropTypes.bool,
    privateModeEnabled: PropTypes.bool,
    isLDReady: PropTypes.bool,
  };

  static defaultProps = {
    children: '',
    accountEntities: null,
    locale: EN,
    isStandardTheme: true,
    privateModeEnabled: true,
    isLDReady: false,
  };

  componentDidMount() {
    const { history } = this.props;
    this.setHistoryListener(history);
  }

  componentDidUpdate(prevProps) {
    const { login, getAccounts, getAccountsPending, accountEntities, dispatch, getProjectDefaults, getDefaultTeams, isLDReady } = this.props;

    const nextUserInfo = login?.userInfo;
    const isGetUserFinished = prevProps.login.getUserInfoPending && !login.getUserInfoPending;
    const isLoginFinished = prevProps.login.userLoginPending && !login.userLoginPending;
    const isGetAccountsFinished = prevProps.getAccountsPending && !getAccountsPending;

    if (login.userInfo?.permissions && (isLoginFinished || isGetUserFinished)) {
      getAccounts();
      getProjectDefaults();
      getDefaultTeams();
    }

    if (!isLDReady && isGetAccountsFinished && nextUserInfo?.permissions && accountEntities?.length) {
      const permisionIds = Object.keys(nextUserInfo.permissions);
      const accountId = permisionIds && permisionIds.length ? permisionIds[0] : null;
      const userRole =
        accountId && nextUserInfo.permissions[accountId]
          ? nextUserInfo.permissions[accountId].group
          : null;

      let accountName;

      if (accountId === '*') {
        accountName = 'All';
      } else {
        const accountInstance =
          accountEntities?.length
            ? accountEntities.find(account => account.id === parseInt(accountId, 10))
            : null;
        accountName = accountInstance ? accountInstance.name : null;
      }

      ldRedux.init({
        clientSideId: config.launchDarklyKey,
        subscribe: false,
        dispatch,
        user: {
          key: nextUserInfo.sub,
          email: nextUserInfo.email,
          custom: {
            webVersion: config.version,
            accountName,
            userRole,
          },
        },
        flags: {
          'new-people-gantt': false,
          'benchy-2-0': false,
          i18n: false,
          'default-cost-rate': false,
          'project-location-map': false,
          'pursuits-win-percentage': false,
          'roles-project-filters': false,
          'available-people': false,
        },
      });
    }
  }

  isRoot = path => path === '/login' || path === '/signup' || path === '/forgot-password';

  isProfileInfo = path => path === '/profile-info';

  isReport = path => path.includes('/reports');

  setAccountId = (location) => {
    const { setActiveAccountId, login } = this.props;
    const { pathname } = location;
    let accountId;

    const mp = matchPath(pathname, {
      path: '/accounts/:id',
    });

    if (mp?.params) {
      accountId = Number(mp.params.id);

      setActiveAccountId(accountId, { isNavigatingBetweenAccounts: false });
      if (login?.userInfo) {
        setUserInfo(login.userInfo, accountId);
      }
    }

    this.mixpanelUrlChange(pathname);
  };

  mixpanelUrlChange = (pathname) => {
    const shortUrl = getShortUrl(pathname);

    if (!BLACKLIST_DUPLICATE_MIXPANEL_URLS.includes(shortUrl)) {
      // isListIdChange prevents tracking url changes within the list. Those changes would skew analytics
      if (!BLACKLIST_MIXPANEL_URLS.includes(shortUrl) && !isListIdChange(pathname) && this.previousUrl) {
        window.mixpanel.track('URL Change', {
          'Short Current URL': shortUrl,
          'Last URL': this.previousUrl,
          'Short Last URL': getShortUrl(this.previousUrl),
        });
      }

      this.previousUrl = pathname;
    }
  };

  setHistoryListener = (history) => {
    // Listen for changes the path, and update the stored accountId accordingly
    history.listen(this.setAccountId);

    // Set initial accountId state
    this.setAccountId(history.location);
  };

  logout = () => {
    const { userLogout, history, isStandardTheme } = this.props;

    // If dark mode is activated, deactivate it on logout
    if (!isStandardTheme) {
      disableDarkMode();
    }

    userLogout();
    history.replace('/login');
  };

  renderRootBody = () => {
    const { children } = this.props;

    return (
      <div className="app__wrapper">
        <RootContent>
          <main className="app__root">{children}</main>
        </RootContent>
      </div>
    );
  }

  renderProfileInfo = () => {
    const { children } = this.props;

    return (
      <div className="app__wrapper">
        <AuthenticatedView>
          <RootContent>
            <main className="app__root">{children}</main>
          </RootContent>
        </AuthenticatedView>
      </div>
    );
  }

  renderReportBody = () => {
    const { children } = this.props;
    return (
      <AuthenticatedView>
        <main className="report">
          {children}
        </main>
      </AuthenticatedView>
    );
  }

  renderDefaultBody = () => {
    const {
      children,
      collapsedNav,
      dragging,
      history,
      privateModeEnabled,
    } = this.props;

    return (
      <div className="app__wrapper">
        <AuthenticatedView>
          <Redirector />
          { !privateModeEnabled && (
            <div className="private-mode-notification">
              <span>You are viewing confidential fields</span>
            </div>
          )}
          <TopBar pathname={history.location.pathname} />
          <SideNav logout={this.logout} />
          <main
            className={classNames(
              'app__main',
              'app-page',
              { 'app--collapsed': collapsedNav },
              { 'app--dragging': dragging },
            )}
          >
            <MainContent>{children}</MainContent>
          </main>
          <MultiStateModal modalId={MULTI_STATE_MODAL_ID} />
          <ErrorSnackbar />
          <SuccessToast />
        </AuthenticatedView>
      </div>
    );
  }

  renderBody = () => {
    const { history } = this.props;
    const isRoot = this.isRoot(history.location.pathname);
    const isProfileInfo = this.isProfileInfo(history.location.pathname);
    const isReport = this.isReport(history.location.pathname);

    if (isRoot) {
      return this.renderRootBody();
    }

    if (isProfileInfo) {
      return this.renderProfileInfo();
    }

    if (isReport) {
      return this.renderReportBody();
    }

    return this.renderDefaultBody();
  }

  render() {
    const { locale } = this.props;
    const messages = getMessagesForLocale(locale);

    return (
      <IntlProvider messages={messages} locale={locale} defaultLocale={EN}>
        <ErrorBoundary>
          { this.renderBody() }
        </ErrorBoundary>
      </IntlProvider>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps({ common, login, accounts, themes, locale, launchDarkly }) {
  const { entities, getAccountsPending } = accounts;
  const { dragging, collapsedNav, privateModeEnabled } = common;
  const { theme } = themes;
  const accountEntities = entities;
  const { locale: localeValue } = locale;
  const { isLDReady } = launchDarkly;

  return {
    login,
    accountEntities,
    getAccountsPending,
    dragging,
    collapsedNav,
    locale: localeValue,
    isStandardTheme: theme === STANDARD_THEME,
    privateModeEnabled,
    isLDReady,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    userLogout: bindActionCreators(userLogout, dispatch),
    getAccounts: bindActionCreators(getAccounts, dispatch),
    setActiveAccountId: bindActionCreators(setActiveAccountId, dispatch),
    getProjectDefaults: bindActionCreators(getProjectDefaults, dispatch),
    getDefaultTeams: bindActionCreators(getDefaultTeams, dispatch),
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
