import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import deepEqual from 'react-fast-compare';
import pluralize from 'pluralize';
import { TextField, InputAdornment, LinearProgress } from '@material-ui/core';
import { Clear, Search } from '@material-ui/icons';
import { DEACTIVATED_VIEW } from 'src/common/constants';
import { SORT_STORAGE_KEY, getStorageKey } from 'src/common/localStorageKeys';
import * as peopleActions from '../people/redux/actions';
import { Table, SortableTableCell } from '../table';
import { PeopleProfile } from '../people';
import { filterTable, sortTable } from '../table/filterUtils';
import { getValidatedLocalStorage } from '../../utils/validators';
import { DEFAULT_PEOPLE_SORT, DEACTIVATED_PEOPLE_SELECTION_ID } from '../people/redux/constants';
import { setSortQuery, clearQueries, setSearchQuery } from '../queries/redux/actions';
import { DEACTIVATED_PEOPLE_QUERY_ID } from '../queries/redux/constants';

export class DeactivatedPeople extends PureComponent {
  static propTypes = {
    peopleActions: PropTypes.object.isRequired,
    accountId: PropTypes.number.isRequired,
    userId: PropTypes.string.isRequired,
    people: PropTypes.object.isRequired,
    setSortQuery: PropTypes.func.isRequired,
    setSearchQuery: PropTypes.func.isRequired,
    clearQueries: PropTypes.func.isRequired,
    queries: PropTypes.object,
    selectedPerson: PropTypes.object,
  };

  static defaultProps = {
    selectedPerson: null,
    queries: {},
  }

  constructor(props) {
    super(props);

    const { accountId, userId } = props;

    const sortStorageKey = getStorageKey(DEACTIVATED_VIEW, accountId, SORT_STORAGE_KEY, userId);
    this.sortStorageKey = sortStorageKey;

    this.columns = [{
      name: 'Name',
      type: 'Text',
    }];

    this.state = {
      sortStorageKey,
      tableData: [],
    };
  }

  componentDidMount() {
    const { accountId, peopleActions, setSortQuery, clearQueries } = this.props;

    // We need the list of people who are not deactivated to show them in
    // the project details modal if the user clicks on any of the projects
    // associated with a deactivated person.
    peopleActions.getPeople(accountId);
    peopleActions.getDeactivatedPeople(accountId);
    peopleActions.getPersonFieldValues(accountId, 'deactivated');

    clearQueries(DEACTIVATED_PEOPLE_QUERY_ID);
    setSortQuery(
      DEACTIVATED_PEOPLE_QUERY_ID,
      getValidatedLocalStorage(this.sortStorageKey) || DEFAULT_PEOPLE_SORT,
      this.sortStorageKey,
    );
  }

  componentDidUpdate = (prevProps) => {
    const { people, queries, accountId, peopleActions, selectedPerson } = this.props;

    const peopleChanged = prevProps.people.getPeoplePending && !people.getPeoplePending;
    const deactivatedPeopleChanged = prevProps.people.getDeactivatedPeoplePending && !people.getDeactivatedPeoplePending;
    const personUpdated = prevProps.people.updatePersonPending && !people.updatePersonPending;
    const profilePicUpdated = prevProps.people.setProfilePicturePending && !people.setProfilePicturePending;
    const personReactivated = prevProps.people.reactivatePersonPending && !people.reactivatePersonPending;
    const queryChanged = !deepEqual(prevProps.queries, queries);
    const isAccountChanged = accountId !== prevProps.accountId;

    if (isAccountChanged) peopleActions.getDeactivatedPeople(accountId);

    if (deactivatedPeopleChanged || peopleChanged || queryChanged || personReactivated || personUpdated || profilePicUpdated) {
      const parsedPeople = this.parsePeople(people.deactivatedPeople, people.unavailabilities);
      const tableData = this.mapRows(parsedPeople, queries);

      if (tableData && tableData.length && !selectedPerson) {
        if (tableData[0].rowMeta && tableData[0].rowMeta.rawData && tableData[0].rowMeta.rawData.id) {
          peopleActions.getPersonById(accountId, tableData[0].rowMeta.rawData.id, DEACTIVATED_PEOPLE_SELECTION_ID, 'Deactivated');
        }
      }

      if ((!tableData || !tableData.length) && selectedPerson) {
        peopleActions.clearPersonSelection(DEACTIVATED_PEOPLE_SELECTION_ID);
      }

      this.updateTableState(tableData);
    }
  }

  updateTableState = tableData => this.setState({ tableData })

  parsePeople = (deactivatedPeople, unavailabilities) => deactivatedPeople.map((person) => {
    const pous = unavailabilities.find(pou => person.id === pou.personId);
    return {
      ...person,
      unavailabilities: (pous && pous.unavailabilities) || [],
    };
  })

  mapRows = (deactivatedPeople, queries) => {
    if (!deactivatedPeople) return [];
    const sortQuery = queries.sort;

    const people = deactivatedPeople.map(person => ({
      rowMeta: {
        rawData: { ...person, Name: person.name },
      },
      rowData: [person.name],
      rowId: person.id,
    }));

    return sortTable(sortQuery, filterTable(queries.search, people));
  }

  handleSetSearchQuery = (queryString) => {
    const { setSearchQuery } = this.props;

    const query = {
      name: 'search',
      args: [queryString],
    };

    setSearchQuery(DEACTIVATED_PEOPLE_QUERY_ID, query, this.sortStorageKey);
  }

  onSearchChange = (evt) => {
    this.handleSetSearchQuery(evt.target.value);
  }

  onClearSearch = () => {
    this.handleSetSearchQuery('');
  }

  onRowClick = (rowId, rowMeta) => {
    const { peopleActions, accountId } = this.props;
    const { getPersonById } = peopleActions;

    getPersonById(accountId, rowMeta.rawData.id, DEACTIVATED_PEOPLE_SELECTION_ID, 'Deactivated');
  }

  activatePerson = () => {
    const { peopleActions, accountId, selectedPerson } = this.props;
    peopleActions.reactivatePerson(accountId, selectedPerson.id);
    peopleActions.clearPersonSelection(DEACTIVATED_PEOPLE_SELECTION_ID);
  }

  onUpdateColumnSort = (sortQuery) => {
    const { setSortQuery } = this.props;
    const { sortStorageKey } = this.state;
    setSortQuery(DEACTIVATED_PEOPLE_QUERY_ID, sortQuery, sortStorageKey);
  }

  renderLoading = () => (
    <>
      <div className="header">
        <div className="title">Deactivated People</div>
      </div>
      <div className="loading-wrap">
        <LinearProgress variant="query" className="progress-bar" />
      </div>
    </>
  )

  renderColumn = (column) => {
    const { queries } = this.props;
    return (
      <SortableTableCell
        key={`${column.name}${column?.schemaName || ''}`}
        classes={{ root: 'table-table-header-cell' }}
        column={column}
        onClick={this.onUpdateColumnSort}
        sortQuery={queries?.sort?.args?.[0]}
      />
    );
  }

  renderContent = () => {
    const { queries, people, selectedPerson } = this.props;
    const { tableData } = this.state;
    const searchQuery = queries.search || '';
    const filterString = searchQuery && 'args' in searchQuery ? searchQuery.args[0] : '';

    return (
      <>
        <div className="header">
          <div className="title">Deactivated People</div>
          <div className="count">{pluralize('Person', people.deactivatedPeople.length, true)}</div>
          <TextField
            classes={{ root: 'search' }}
            value={filterString}
            onChange={this.onSearchChange}
            onFocus={this.onSearchFocus}
            id="search-textfield"
            placeholder="Search by name"
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <Search className="filter-icon" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {filterString.length > 0 && (
                    <Clear className="filter-clear" onClick={this.onClearSearch} />
                  )}
                </InputAdornment>
              ),
              classes: {
                root: 'filter-text-field',
                focused: 'focused',
              },
            }}
          />
        </div>
        <div className="content">
          <div className="table-container">
            <Table
              data={tableData}
              columns={this.columns}
              renderColumn={this.renderColumn}
              onRowClick={this.onRowClick}
              selectedRowId={selectedPerson ? selectedPerson.id : ''}
              noMatch="No deactivated people"
            />
          </div>
          {selectedPerson && (
            <PeopleProfile
              className="deactivated-profile"
              selectedPerson={selectedPerson}
              selectedPersonAvailability={1}
              toggleActivation={this.activatePerson}
            />
          )}
        </div>
      </>
    );
  }

  render() {
    const { people } = this.props;

    return (
      <div className="account-settings-deactivated-people">
        {people.getPeoplePending
          ? this.renderLoading()
          : this.renderContent()}
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps({ people, common, queries, login }) {
  const { sub: userId } = login.userInfo;
  const { personSelections } = people;

  return {
    userId,
    people,
    accountId: common.accountId,
    queries: queries[DEACTIVATED_PEOPLE_QUERY_ID],
    selectedPerson: personSelections[DEACTIVATED_PEOPLE_SELECTION_ID],
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    peopleActions: bindActionCreators({ ...peopleActions }, dispatch),
    setSortQuery: bindActionCreators(setSortQuery, dispatch),
    setSearchQuery: bindActionCreators(setSearchQuery, dispatch),
    clearQueries: bindActionCreators(clearQueries, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeactivatedPeople);
