const USER_UPDATED = 'User Updated';
const SIGN_UP = 'Sign Up';
const LOGIN = 'Login';
const FORGOT_PASSWORD = 'Forgot Password';

export {
  USER_UPDATED,
  SIGN_UP,
  LOGIN,
  FORGOT_PASSWORD,
};
