import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { DateUpdateConfirmModal } from 'src/features/projects';
import { PERM_WRITE, PERM_PROJECT } from 'src/features/permissions/utils/constants';
import { setEditorConfig } from 'src/features/edit-dates/redux/actions';
import {
  setModalActiveView,
  setSelectedProjectId,
  trackAnalytics,
} from 'src/features/common/redux/actions';
import { openModal } from 'src/features/modal-manager/redux/actions';
import { COMMON_PROJECT_DATES_UPDATED } from 'src/analytics/common/constants';
import { updateProject } from './redux/actions';
import { Can } from '../wrapped-components';
import { DateEditor } from '../common';
import {
  DATE_INPUT_FORMAT,
  DATE_DISPLAY_FORMAT,
  MIN_API_CONFIGURABLE_DATE,
  MIN_API_CONFIGURABLE_DATE_MESSAGE,
} from '../../common/constants';
import { SEGMENT_TYPE_PHASE, SEGMENT_TYPE_PROJECT, SEGMENT_TYPE_ROLE } from '../edit-dates/common/constants';
import {
  COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_EDIT_PROJECT_DATE, MULTI_STATE_MODAL_ID,
} from '../common/redux/constants';
import { getAffectedProjectEntities, getDateEditOptions } from '../edit-dates/utils/dateEditorUtils';

export const ProjectDates = ({
  project,
  parentName,
}) => {
  const dispatch = useDispatch();

  const { accountId } = useSelector(({ common }) => common);
  const { updateProjectPending } = useSelector(({ projects }) => projects);
  const { dateUnderEdit } = useSelector(({ editDates }) => editDates);
  const modalIsOpen = useSelector(({ modalManager }) => {
    const { activeModal } = modalManager;

    return activeModal === MULTI_STATE_MODAL_ID;
  });

  // start/end date must be string DATE_INPUT_FORMAT and not be moment objects to handle timezones
  const [startDate, setStartDate] = useState(project.startDate);
  const [endDate, setEndDate] = useState(project.endDate);

  const [editDateMode, setEditDateMode] = useState(false);
  const [showUpdateConfirm, setShowUpdateConfirm] = useState(false);

  const hideConfirm = useCallback(() => {
    setStartDate(project.startDate);
    setEndDate(project.endDate);
    setShowUpdateConfirm(false);
  }, [project.endDate, project.startDate]);

  useEffect(() => {
    if (!dateUnderEdit) {
      hideConfirm();
    }
  }, [dateUnderEdit, hideConfirm]);

  useEffect(() => {
    if (!updateProjectPending) {
      setEditDateMode(false);
      setShowUpdateConfirm(false);
      setStartDate(project.startDate);
      setEndDate(project.endDate);
    }
  }, [project, updateProjectPending]);

  const theme = {
    overrides: {
      MuiInput: {
        underline: {
          '&::before': {
            border: 'none',
          },
          '&:hover::before': {
            border: 'none !important',
          },
        },
      },
      MuiDialogActions: {
        root: {
          display: 'none',
        },
      },
    },
  };

  const updateStartDate = (date) => {
    const isValidDate = moment(date).isValid();
    setStartDate(isValidDate ? date.format(DATE_INPUT_FORMAT) : null);
  };

  const updateEndDate = (date) => {
    const isValidDate = moment(date).isValid();
    setEndDate(isValidDate ? date.format(DATE_INPUT_FORMAT) : null);
  };

  const onToggleEdit = () => {
    const { startDate: currentStart, endDate: currentEnd } = project;
    setStartDate(currentStart);
    setEndDate(currentEnd);
    setEditDateMode(!editDateMode);
  };

  const saveProject = (updateMode) => {
    const { startDate: currentStart, endDate: currentEnd, name, id, state: status, type: projectType } = project;

    const data = {
      startDate,
      endDate,
      expandRole: updateMode === 'expand',
      expandPhases: updateMode === 'expand',
    };

    dispatch(updateProject(accountId, id, data));

    const { editAction, offset } = getDateEditOptions(currentStart, currentEnd, startDate, endDate);
    const analyticsPayload = {
      'Start Date Updated': !moment(startDate).isSame(currentStart),
      'End Date Updated': !moment(endDate).isSame(currentEnd),
      'Project name': name,
      'Project id': id,
      'Project status': status,
      'Project type': projectType,
      'Project dates updated from': parentName,
      '# of days offset': offset,
      'Impact to project': editAction,
    };

    dispatch(trackAnalytics(COMMON_PROJECT_DATES_UPDATED, analyticsPayload));
  };

  const confirmUpdateProject = () => {
    const {
      id,
      startDate: currentStartDate,
      endDate: currentEndDate,
      name,
      state: status,
      type: projectType,
    } = project;

    const editorSegments = getAffectedProjectEntities(project, startDate, endDate);
    const showAdvancedEdit =
      editorSegments?.[SEGMENT_TYPE_ROLE]?.length > 0 ||
      editorSegments?.[SEGMENT_TYPE_PHASE]?.length > 0;

    if (showAdvancedEdit) {
      const analyticsPayload = {
        runsInModal: modalIsOpen,
        data: {
          projectName: name,
          projectId: id,
          from: 'Project Details Modal',
          to: 'Edit Project Modal',
          projectStatus: status,
          projectType,
        },
      };
      dispatch(setSelectedProjectId(id, COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_EDIT_PROJECT_DATE, null, modalIsOpen, false, null, analyticsPayload));
      dispatch(setModalActiveView(COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_EDIT_PROJECT_DATE));
      dispatch(setEditorConfig(SEGMENT_TYPE_PROJECT, currentStartDate, currentEndDate, startDate, endDate, parentName));

      if (!modalIsOpen) {
        dispatch(openModal(MULTI_STATE_MODAL_ID));
      }

      setEditDateMode(false);
    } else {
      saveProject();
    }
  };

  return (
    <div className="projects-project-dates">
      <div className={classNames('project-date-edit', { 'date-edit-disabled': editDateMode })}>
        <span className="project-date-label">Project dates</span>
        <div className="project-date-editors">
          <div className="project-date">
            <span className="project-date-label">Start Date</span>
            <Can
              action={PERM_WRITE}
              subject={PERM_PROJECT}
              yes={(
                <DateEditor
                  date={moment(startDate, DATE_INPUT_FORMAT)}
                  maxDate={moment(endDate, DATE_INPUT_FORMAT).subtract(1, 'days').toDate()}
                  minDate={moment(MIN_API_CONFIGURABLE_DATE)}
                  minDateMessage={MIN_API_CONFIGURABLE_DATE_MESSAGE}
                  maxDateMessage="Start date must be earlier than the end date"
                  onChange={updateStartDate}
                  editMode={editDateMode}
                  onEditToggle={onToggleEdit}
                  outerTheme={theme}
                  onSave={confirmUpdateProject}
                  pending={updateProjectPending}
                />
              )}
              no={(
                <p>{moment(startDate).format(DATE_DISPLAY_FORMAT)}</p>
              )}
            />
          </div>
          <div className="project-date">
            <span className="project-date-label">End Date</span>
            <Can
              action={PERM_WRITE}
              subject={PERM_PROJECT}
              yes={(
                <DateEditor
                  date={moment(endDate, DATE_INPUT_FORMAT)}
                  minDate={moment(startDate, DATE_INPUT_FORMAT).add(1, 'days').toDate()}
                  minDateMessage="End date must be later than the start date"
                  onChange={updateEndDate}
                  editMode={editDateMode}
                  onEditToggle={onToggleEdit}
                  outerTheme={theme}
                  onSave={confirmUpdateProject}
                  pending={updateProjectPending}
                />
              )}
              no={<p>{moment(endDate).format(DATE_DISPLAY_FORMAT)}</p>}
            />
          </div>
        </div>
        {showUpdateConfirm && (
          <DateUpdateConfirmModal
            project={project}
            onAccept={saveProject}
            onCancel={hideConfirm}
            loading={updateProjectPending}
            startDate={moment(startDate).format(DATE_INPUT_FORMAT)}
            endDate={moment(endDate).format(DATE_INPUT_FORMAT)}
          />
        )}
      </div>
    </div>
  );
};

ProjectDates.propTypes = {
  project: PropTypes.object.isRequired,
  parentName: PropTypes.string.isRequired,
};

export default ProjectDates;
