import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PEOPLE_GET_PERSON_PROJECTS_BEGIN,
  PEOPLE_GET_PERSON_PROJECTS_SUCCESS,
  PEOPLE_GET_PERSON_PROJECTS_FAILURE,
  PEOPLE_GET_PERSON_PROJECTS_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';

export function getPersonProjects(accountId) {
  return {
    type: PEOPLE_GET_PERSON_PROJECTS_BEGIN,
    accountId,
  };
}

export function dismissGetPersonProjectsError() {
  return {
    type: PEOPLE_GET_PERSON_PROJECTS_DISMISS_ERROR,
  };
}

// worker Saga: will be fired on PEOPLE_GET_PERSON_PROJECTS_BEGIN actions
export function* doGetPersonProjects(action) {
  const { accountId } = action;
  let res;
  try {
    res = yield call(protectedJsonGet, `accounts/${accountId}/persons/current-projects`);
  } catch (err) {
    yield put({
      type: PEOPLE_GET_PERSON_PROJECTS_FAILURE,
      data: { error: err },
    });
    return;
  }
  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: PEOPLE_GET_PERSON_PROJECTS_SUCCESS,
    data: res,
  });
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchGetPersonProjects() {
  yield takeLatest(PEOPLE_GET_PERSON_PROJECTS_BEGIN, doGetPersonProjects);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_GET_PERSON_PROJECTS_BEGIN:
      return {
        ...state,
        getPersonProjectsPending: true,
        getPersonProjectsError: null,
      };

    case PEOPLE_GET_PERSON_PROJECTS_SUCCESS:
      return {
        ...state,
        currentProjects: action.data,
        getPersonProjectsPending: false,
        getPersonProjectsError: null,
      };

    case PEOPLE_GET_PERSON_PROJECTS_FAILURE:
      return {
        ...state,
        getPersonProjectsPending: false,
        getPersonProjectsError: action.data.error,
      };

    case PEOPLE_GET_PERSON_PROJECTS_DISMISS_ERROR:
      return {
        ...state,
        getPersonProjectsError: null,
      };

    default:
      return state;
  }
}
