import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  MenuItem,
  Select,
} from '@material-ui/core';
import { SORT_STORAGE_KEY, getStorageKey } from 'src/common/localStorageKeys';
import { setSortQuery } from '../queries/redux/actions';

function SortDropdown({
  queries,
  trackSortChange,
  sortStorageKey,
  filterOptions,
  setSortQuery,
  queryId,
}) {
  const queryObj = queries[queryId];
  const sortQuery = queryObj && queryObj.sort ? queryObj.sort : {};
  const { args: sortArgs = [{}] } = sortQuery;
  const { label, column } = sortArgs[0];

  if (!label || !label.length) return null;

  const getRenderValue = () => (
    <div className="stacked-select-wrapper">
      <span className="stacked-select-label">Sort by</span>
      <span className="stacked-select-value">{label}</span>
    </div>
  );

  const onChange = (evt) => {
    const { value } = evt.target;
    const { label } = filterOptions.find(filter => filter.column === value);
    const newArg = {
      label,
      column: value,
      ascending: true,
      type: null,
    };

    const query = {
      name: 'sort',
      args: [newArg],
    };

    trackSortChange(label);

    setSortQuery(queryId, query, sortStorageKey);
  };

  return (
    <Select
      variant="outlined"
      className="filters-sort-dropdown"
      renderValue={getRenderValue}
      onChange={onChange}
      value={column}
      classes={{
        selectMenu: 'select-menu',
      }}
    >
      {filterOptions.map(option => (
        <MenuItem
          key={option.column}
          value={option.column}
        >
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
}

/* istanbul ignore next */
function mapStateToProps({ common, queries, login }) {
  const { activeView, accountId } = common;
  const { sub: userId } = login.userInfo;
  const sortStorageKey = getStorageKey(activeView, accountId, SORT_STORAGE_KEY, userId);

  return {
    sortStorageKey,
    queries,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    setSortQuery: bindActionCreators(setSortQuery, dispatch),
  };
}

SortDropdown.propTypes = {
  trackSortChange: PropTypes.func,
  sortStorageKey: PropTypes.string.isRequired,
  filterOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSortQuery: PropTypes.func.isRequired,
  queries: PropTypes.object.isRequired,
  queryId: PropTypes.string.isRequired,
};

SortDropdown.defaultProps = {
  trackSortChange: () => {},
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SortDropdown);
