// Project properties
export const TYPE = 'Type';

export const WIN_PERCENT_LABEL = 'Win %';
export const WIN_PERCENT = 'winPercent';
export const WIN_PERCENT_STEP = 5;

// Project states
export const ACTIVE = 'Active';
export const UPCOMING = 'Upcoming';
export const COMPLETE = 'Complete';
export const PURSUIT = 'Pursuit';
export const LOST = 'Lost';
export const CANCELED = 'Canceled';
export const REACTIVATED = 'Reactivated';

// Project allocation states
export const CURRENT = 'Current';

// Project types
export const OPPORTUNITY = 'Opportunity';
export const AWARDED = 'Awarded';
export const PROJECT_TYPES = [OPPORTUNITY, AWARDED];

export const REACTIVATE_API_MAPPING = {
  [LOST]: PURSUIT,
};

// Module constants for Project (eg. to filter out)
export const PURSUIT_MODULE_STATUS_TO_REMOVE = [PURSUIT, LOST];

export const DEFAULT_PROJECT_ALLOCATION_STATES = [COMPLETE, ACTIVE, UPCOMING].join(',');

export const ROLE_NOTIFY_SMS = 'SMS';
export const ROLE_NOTIFY_EMAIL = 'Email';

export const ROLE_NOTIFY_TYPE_SET = 'AllocationSet';
export const ROLE_NOTIFY_TYPE_CLEAR = 'AllocationClear';

export const INCLUDE_PROJECT_ADDRESS = 'projectAddress';
export const INCLUDE_ROLE_NAME = 'roleName';

// Allocation change types
export const ALLOCATION_REMOVED = 'Removal';
export const ALLOCATION_REPLACED = 'Replacement';

// Side menu panels
export const SIDE_PANEL_PROJECT_NOTES = 'projectNotes';
export const SIDE_PANEL_PROJECT_HISTORY = 'projectHistory';

// Modal side menu tabs
export const MODAL_NOTES_TAB = 0;
export const MODAL_PROJECT_AUDIT_TAB = 1;

// Project map
export const PROJECT_MAP_MIN_ZOOM = 3;
export const PROJECT_MAP_INITIAL_ZOOM = 4;
export const PROJECT_MAP_MAX_ZOOM = 16;
export const PROJECT_MAP_INITIAL_CENTER = { lat: 52.816, lng: -100.002 };
export const PROJECT_MAP_CLUSTER_RADIUS = 75;
export const PROJECT_MAP_CLUSTER_MAX_ZOOM = 15;
export const PROJECT_MAP_BOUNDS_PADDING = { top: 60, right: 60, bottom: 60, left: 300 };

export const PROJECT_CREATED = 'Project created';
export const PROJECT_UPDATED = 'Project updated';
export const PROJECT_FILED_SET = 'Project field set';
export const PROJECT_FIELD_CLEARED = 'Project field cleared';
export const PHASE_ADDED = 'Phase added';
export const PHASE_UPDATED = 'Phase updated';
export const PHASE_DELETED = 'Phase deleted';
export const SUB_PHASE_ADDED = 'Sub-phase added';
export const SUB_PHASE_UPDATED = 'Sub-phase updated';
export const SUB_PHASE_DELETED = 'Sub-phase deleted';
export const PROJECT_NOTE_ADDED = 'Project note added';
export const PROJECT_NOTE_UPDATED = 'Project note updated';
export const PROJECT_NOTE_DELETED = 'Project note deleted';
export const ROLE_ADDED = 'Role added';
export const ROLE_UPDATED = 'Role updated';
export const ROLE_DELETED = 'Role deleted';
export const ALLOCATION_SET = 'Allocation set';
export const ALLOCATION_DELETED = 'Allocation deleted';

export const PROJECT_LOG_EVENT_NAME_MAP = {
  Created: PROJECT_CREATED,
  Updated: PROJECT_UPDATED,
  SetFieldValue: PROJECT_FILED_SET,
  ClearFieldValue: PROJECT_FIELD_CLEARED,
  AddedPhase: PHASE_ADDED,
  UpdatedPhase: PHASE_UPDATED,
  RemovedPhase: PHASE_DELETED,
  AddedSubPhase: SUB_PHASE_ADDED,
  UpdatedSubPhase: SUB_PHASE_UPDATED,
  RemovedSubPhase: SUB_PHASE_DELETED,
  AddedProjectNote: PROJECT_NOTE_ADDED,
  UpdatedProjectNote: PROJECT_NOTE_UPDATED,
  RemovedProjectNote: PROJECT_NOTE_DELETED,
};

export const ROLE_LOG_EVENT_NAME_MAP = {
  Created: ROLE_ADDED,
  Updated: ROLE_UPDATED,
  Removed: ROLE_DELETED,
  SetAllocation: ALLOCATION_SET,
  ClearAllocation: ALLOCATION_DELETED,
};

export const PROJECT_LOG_RESOURCE_TYPES = {
  Project: PROJECT_LOG_EVENT_NAME_MAP,
  Role: ROLE_LOG_EVENT_NAME_MAP,
};
