import { call, put, takeLatest } from 'redux-saga/effects';
import { protectedJsonPut } from 'src/utils/api';
import {
  ACCOUNT_SETTINGS_UPDATE_SUB_PHASES_BEGIN,
  ACCOUNT_SETTINGS_UPDATE_SUB_PHASES_SUCCESS,
  ACCOUNT_SETTINGS_UPDATE_SUB_PHASES_FAILURE,
  ACCOUNT_SETTINGS_UPDATE_SUB_PHASES_DISMISS_ERROR,
} from './constants';

export function updateSubPhases(accountId, phaseId, newSubPhases) {
  return {
    type: ACCOUNT_SETTINGS_UPDATE_SUB_PHASES_BEGIN,
    accountId,
    phaseId,
    data: newSubPhases,
  };
}

export function dismissUpdateSubPhasesError() {
  return {
    type: ACCOUNT_SETTINGS_UPDATE_SUB_PHASES_DISMISS_ERROR,
  };
}

export function* doUpdateSubPhases(action) {
  const { accountId, phaseId, data } = action;
  try {
    yield call(protectedJsonPut, `accounts/${accountId}/phases/${phaseId}/subphases`, data);
  } catch (err) {
    yield put({
      type: ACCOUNT_SETTINGS_UPDATE_SUB_PHASES_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: ACCOUNT_SETTINGS_UPDATE_SUB_PHASES_SUCCESS,
    accountId,
    phaseId,
    data,
  });
}

export function* watchUpdateSubPhases() {
  yield takeLatest(ACCOUNT_SETTINGS_UPDATE_SUB_PHASES_BEGIN, doUpdateSubPhases);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNT_SETTINGS_UPDATE_SUB_PHASES_BEGIN:
      return {
        ...state,
        updateSubPhasesPending: true,
        updateSubPhasesError: null,
      };

    case ACCOUNT_SETTINGS_UPDATE_SUB_PHASES_SUCCESS: {
      const { phases } = state;
      const { phaseId, data } = action;

      const newPhases = phases.map((phase) => {
        if (phaseId === phase.id) {
          return {
            ...phase,
            subPhases: data,
          };
        }
        return phase;
      });

      return {
        ...state,
        phases: newPhases,
        updateSubPhasesPending: false,
        updateSubPhasesError: null,
      };
    }

    case ACCOUNT_SETTINGS_UPDATE_SUB_PHASES_FAILURE:
      return {
        ...state,
        updateSubPhasesPending: false,
        updateSubPhasesError: action.data.error,
      };

    case ACCOUNT_SETTINGS_UPDATE_SUB_PHASES_DISMISS_ERROR:
      return {
        ...state,
        updateSubPhasesError: null,
      };

    default:
      return state;
  }
}
