import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { FormControl, LinearProgress, MenuItem, Paper, Select } from '@material-ui/core';
import moment from 'moment';
import deepEqual from 'react-fast-compare';
import shortid from 'shortid';
import { DASHBOARD_UNFILLED_ROLES_STORAGE_KEY, SORT_STORAGE_KEY, getStorageKey } from 'src/common/localStorageKeys';
import {
  DATE_INPUT_FORMAT,
  DATE_DISPLAY_FORMAT,
  ROLES_VIEW,
  DASHBOARD_VIEW,
  INVALID_STATUS_FILTER,
  ACCOUNT_MODULE_PURSUIT_TRACKING,
} from 'src/common/constants';
import {
  mixpanelSortEvent,
  getDashboardEmptyResultText,
} from 'src/features/filters/utils/filterUtils';
import { getValidatedLocalStorage } from 'src/utils/validators';
import { trackAnalytics } from 'src/features/common/redux/actions';
import { OutlinedInput } from '../wrapped-components';
import { Table, SortableTableCell } from '../table';
import { ProjectLinkToModalContainer } from '../common';
import { DEFAULT_UNFILLED_ROLES_SORT, DEFAULT_MONTH_OFFSET } from './redux/constants';
import { sortTable, getInitialSort } from '../table/filterUtils';
import { filterRoles } from './utils/dashboardUtils';
import { clearQueries, setSortQuery } from '../queries/redux/actions';
import { DASHBOARD_UNFILLED_ROLES_QUERY_ID } from '../queries/redux/constants';
import { hasModuleEnabled } from '../permissions/utils/permissionUtils';
import {
  AUTOMATION_UNFILLED_ROLES_PROJECT_WITH_UNFILLED_ROLES,
  AUTOMATION_UNFILLED_ROLES_TOTAL_UNFILLED_ROLES,
} from './ids';
import { UNFILLED_ROLES_FILTER_CHANGED, TIME_FILTER_SELECTED } from '../../analytics/dashboard/constants';

export class UnfilledRoles extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    unfilledRoles: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    accountId: PropTypes.number.isRequired,
    queries: PropTypes.object,
    clearQueries: PropTypes.func.isRequired,
    setSortQuery: PropTypes.func.isRequired,
    filterGroupApplied: PropTypes.string,
    isPursuitModuleOn: PropTypes.bool.isRequired,
    trackAnalytics: PropTypes.func.isRequired,
  };

  static defaultProps = {
    queries: {},
    filterGroupApplied: 'default',
  }

  constructor(props) {
    super(props);
    this.setColumns();

    this.state = {
      roles: [],
      filter: 3,
      projectCount: 0,
      unfilledCount: 0,
    };
  }

  componentDidMount() {
    const { unfilledRoles, accountId, queries, clearQueries, setSortQuery, userId } = this.props;

    const filteredRoles = filterRoles(unfilledRoles, DEFAULT_MONTH_OFFSET);
    const sortStorageKey = getStorageKey(ROLES_VIEW, accountId, SORT_STORAGE_KEY, userId);
    this.intervalStorageKey = getStorageKey(ROLES_VIEW, accountId, DASHBOARD_UNFILLED_ROLES_STORAGE_KEY, userId);

    clearQueries(DASHBOARD_UNFILLED_ROLES_QUERY_ID);

    const currentSort = getInitialSort(sortStorageKey, DEFAULT_UNFILLED_ROLES_SORT);
    setSortQuery(DASHBOARD_UNFILLED_ROLES_QUERY_ID, currentSort, sortStorageKey);

    this.setState({
      roles: this.mapRoles(filteredRoles, queries.sort),
      filter: getValidatedLocalStorage(this.intervalStorageKey),
      projectCount: this.countUniqueProjects(filteredRoles),
      unfilledCount: filteredRoles.length,
      sortStorageKey,
    });
  }

  componentDidUpdate(prevProps) {
    const { accountId, userId, unfilledRoles, queries } = this.props;

    if (prevProps.accountId !== accountId) {
      this.intervalStorageKey = getStorageKey(ROLES_VIEW, accountId, DASHBOARD_UNFILLED_ROLES_STORAGE_KEY, userId);
      this.updateState({ filter: getValidatedLocalStorage(this.intervalStorageKey) });
    }

    if (!deepEqual(prevProps.queries, queries) || !deepEqual(prevProps.unfilledRoles, unfilledRoles)) {
      const filteredRoles = filterRoles(unfilledRoles, this.getTimeFilter());

      this.updateState({
        roles: this.mapRoles(filteredRoles, queries.sort),
        projectCount: this.countUniqueProjects(filteredRoles),
        unfilledCount: filteredRoles.length,
      });
    }
  }

  updateState = newState => this.setState(newState);

  setColumns = () => {
    const dateDisplay = value => (<p className="role-date">{(value ? moment(value, DATE_INPUT_FORMAT).format(DATE_DISPLAY_FORMAT) : '')}</p>);

    this.columns = [
      {
        name: 'Role',
        type: 'Text',
        options: {
          customBodyRender: (value, rowMeta) => (
            <p className="role-name-and-note">
              <span className="role-name">{value}</span>
              {!!rowMeta.note && <span className="role-note">{` - ${rowMeta.note}`}</span>}
            </p>
          ),
          columnClass: 'wide-column column-text',
        },
      },
      {
        name: 'Project',
        options: {
          customBodyRender: (value, rowMeta) => (
            <ProjectLinkToModalContainer
              backgroundColor={rowMeta.colour}
              projectName={value}
              projectId={rowMeta.projectId}
              launchedFrom="Dashboard - Unfilled Roles"
            />
          ),
          columnClass: 'column-text',
        },
        type: 'Text',
      },
      {
        name: 'Start Date',
        options: {
          customBodyRender: dateDisplay,
          sortDirection: 'asc',
          columnClass: ' column-text',
        },
        type: 'Date',
      },
      {
        name: 'End Date',
        options: {
          customBodyRender: dateDisplay,
          columnClass: ' column-text',
        },
        type: 'Date',
      },
      {
        name: '%',
        options: {
          customBodyRender: value => (<p className="role-allocation">{(value === 'Custom' ? value : `${value}%`)}</p>),
          columnClass: 'short-column column-text',
        },
      },
    ];
  }

  getTimeFilter() {
    const { filter } = this.state;
    return filter || 3;
  }

  handleChange = (e) => {
    const { unfilledRoles, queries, trackAnalytics } = this.props;
    const filter = e.target.value;
    localStorage.setItem(this.intervalStorageKey, filter);
    const filteredRoles = filterRoles(unfilledRoles, filter);

    // Mixpanel - Track time filter change
    const analyticsPayload = {
      [TIME_FILTER_SELECTED]: filter === -1 ? 'All unfilled roles' : `Next ${filter} months`,
    };
    trackAnalytics(UNFILLED_ROLES_FILTER_CHANGED, analyticsPayload);

    this.setState({
      filter,
      roles: this.mapRoles(filteredRoles, queries.sort),
      projectCount: this.countUniqueProjects(filteredRoles),
      unfilledCount: filteredRoles.length,
    });
  }

  countUniqueProjects = (roles) => {
    const projectIds = roles.map(role => role.projectId);
    return new Set(projectIds).size;
  }

  onUpdateColumnSort = (sortQuery) => {
    const { trackAnalytics } = this.props;
    const name = sortQuery?.args?.[0]?.label;
    if (name) {
      const { event, payload } = mixpanelSortEvent(name, DASHBOARD_VIEW, '');

      if (event && payload) {
        trackAnalytics(event, payload);
      }
    }
    const { setSortQuery } = this.props;
    const { sortStorageKey } = this.state;
    setSortQuery(DASHBOARD_UNFILLED_ROLES_QUERY_ID, sortQuery, sortStorageKey);
  }

  mapRoles = (roles, sortQuery) => {
    const unfilledRoles = [];

    roles.forEach((role) => {
      const percent = role.requirements.length === 1 ? role.requirements[0].allocatedPercent : 'Custom';
      if (!role.isfilled && role?.unfilledRanges?.length) {
        role.unfilledRanges.forEach((gap) => {
          unfilledRoles.push(this.mapRow(role, gap.startDate, gap.endDate, percent));
        });
      }
    });

    return sortTable(sortQuery, unfilledRoles);
  }

  mapRow = (role, startDate, endDate, percent) => {
    const roleObj = {
      id: role.projectId,
      Role: role.name,
      Project: role.projectName,
      'Start Date': startDate,
      'End Date': endDate,
      '%': percent,
    };

    const rowData = this.columns.map((column) => {
      if (typeof roleObj[column.name] !== 'undefined') {
        return roleObj[column.name];
      }
      return '';
    });

    return {
      rowMeta: {
        id: role.id,
        colour: role.projectColour,
        sortOrder: role.sortOrder,
        note: role.note,
        projectId: role.projectId,
        rawData: roleObj,
      },
      rowData,
      rowId: shortid.generate(),
    };
  }

  renderColumn = (column) => {
    const { queries } = this.props;
    return (
      <SortableTableCell
        key={`${column.name}${column?.schemaName || ''}`}
        classes={{ root: classNames('table-table-header-cell', column?.options?.columnClass) }}
        column={column}
        onClick={this.onUpdateColumnSort}
        sortQuery={queries?.sort?.args?.[0]}
      />
    );
  }

  render() {
    const { loading, filterGroupApplied, isPursuitModuleOn } = this.props;
    const { roles, projectCount, unfilledCount } = this.state;

    let noMatch;
    if (filterGroupApplied === INVALID_STATUS_FILTER) {
      noMatch = getDashboardEmptyResultText(isPursuitModuleOn);
    } else if (filterGroupApplied === 'filtered') {
      noMatch = 'No roles match the filters applied';
    } else {
      noMatch = 'Sorry, no matching records found';
    }

    if (loading) {
      return (
        <Paper className="dashboard-unfilled-roles empty">
          <LinearProgress color="primary" />
        </Paper>
      );
    }

    const isNoProjectDataAdded = (!projectCount || !unfilledCount) && noMatch === 'Sorry, no matching records found';

    return (
      <Paper className={classNames('dashboard-unfilled-roles', { 'empty-list': isNoProjectDataAdded })}>
        <div className="dash-header">
          <div className="title">Unfilled Roles</div>
          <div className="details">
            <div className="header-count" id={AUTOMATION_UNFILLED_ROLES_TOTAL_UNFILLED_ROLES}>{`Total unfilled roles: ${unfilledCount}`}</div>
            <div className="header-count" id={AUTOMATION_UNFILLED_ROLES_PROJECT_WITH_UNFILLED_ROLES}>{`Projects with unfilled roles: ${projectCount}`}</div>
          </div>
          <div className="filter-wrap">
            <FormControl variant="outlined">
              <Select
                value={this.getTimeFilter()}
                onChange={this.handleChange}
                input={
                  <OutlinedInput />
                }
              >
                <MenuItem value={3}>Next 3 months</MenuItem>
                <MenuItem value={6}>Next 6 months</MenuItem>
                <MenuItem value={9}>Next 9 months</MenuItem>
                <MenuItem value={-1}>All unfilled roles</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="list-wrap">
          <Table
            data={roles}
            columns={this.columns}
            renderColumn={this.renderColumn}
            rowHover={false}
            noMatch={noMatch}
          />
        </div>
      </Paper>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps({ common, queries, dashboard, login, accountSettings }) {
  const { sub: userId } = login.userInfo;
  const { accountModules } = accountSettings;
  const isPursuitModuleOn = hasModuleEnabled(accountModules, ACCOUNT_MODULE_PURSUIT_TRACKING);

  return {
    userId,
    accountId: common.accountId,
    queries: queries[DASHBOARD_UNFILLED_ROLES_QUERY_ID],
    unfilledRoles: dashboard.unfilledRoles,
    loading: dashboard.getUnfilledRolesPending,
    isPursuitModuleOn,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    clearQueries: bindActionCreators(clearQueries, dispatch),
    setSortQuery: bindActionCreators(setSortQuery, dispatch),
    trackAnalytics: bindActionCreators(trackAnalytics, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(UnfilledRoles));
