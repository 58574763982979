import moment from 'moment';
import {
  BLANK_FILTER,
  FILTER_PHASE,
  FILTER_ROLES,
  FORECASTING_FILTER_ROLES,
  FILTER_PROJECT_DATE,
  FILTER_ALLOCATION,
  FILTER_ALLOCATED_PEOPLE,
  FILTER_CERTIFICATIONS,
} from 'src/filters/constants';
import {
  MIN_ALLOCATION_PERCENT,
  MAX_ALLOCATION_PERCENT,
  MAX_DOLLAR_VALUE,
  MIN_CONFIGURABLE_DATE_MESSAGE,
  MIN_CONFIGURABLE_DATE,
  FIELD_TYPE_PHONE,
  FIELD_TYPE_CURRENCY,
  FIELD_TYPE_PHONE_E164,
} from 'src/common/constants';
import { validateDate } from './dateUtils';
import { validatePhoneNumberFormField } from './phoneNumberUtils';

function validateSimplePhoneNumber(phone) {
  if (!phone) {
    return true;
  }

  const sanitized = phone.split(/\r?\n/)[0].toLowerCase().replace(/[^0-9x,]/g, '');
  const sections = sanitized.split(/[x,]/).filter(String); // split out extension, remove empty sections if both x and , were in the string

  if (sections.length < 1 || sections.length > 2 || sections[0].length < 10 || sections[0].length > 11) {
    return false;
  }

  if (sections.length === 2 && (sections[1].length > 10)) {
    return false;
  }

  return true;
}

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function validateDollarValue(value = '') {
  if (value === '' || value === null) {
    return true;
  }

  // Inputs can be considered valid with any of comma, space, or dollar sign
  const filteredValue = value.toString().replace(/[,$ ]/g, '');

  const parsedValue = parseFloat(filteredValue);

  if (Number.isNaN(parsedValue)) {
    return false;
  }

  /*
    Compare the parsed value against the original, allowing coercion. This will ensure that
    parseFloat didn't clean up the input, and that the magnitude of the number hasn't changed.
   */
  if (parsedValue != filteredValue) { // eslint-disable-line eqeqeq
    return false;
  }

  if (parsedValue > MAX_DOLLAR_VALUE || parsedValue < 0) {
    return false;
  }

  return true;
}

function validatePercentInput(input, minVal = MIN_ALLOCATION_PERCENT) {
  if (input === '' || input === null) {
    return '';
  }

  const parsedInput = parseInt(input, 10);

  if (Number.isNaN(parsedInput) || parsedInput > MAX_ALLOCATION_PERCENT || parsedInput < minVal) {
    return NaN;
  }

  return parsedInput;
}

function validatePercentValue(value, min = 0, max = 100) {
  return !(parseInt(value, 10) > max || parseInt(value, 10) < min);
}

function validateEmploymentDate(input, value) {
  if (!value) return null;
  if (input.minDate === MIN_CONFIGURABLE_DATE && moment(value).isBefore(input.minDate)) {
    return MIN_CONFIGURABLE_DATE_MESSAGE;
  }
  if (input.minDate && moment(value).isBefore(input.minDate)) {
    return 'Termination Date must be later than the Hire Date';
  }
  if (input.maxDate && moment(value).isAfter(input.maxDate)) {
    return 'Hire Date must be earlier than Termination Date';
  }
  return null;
}

const validateRequiredInput = (values, input) => {
  // forbid values are just empty spaces for text input fields
  // in some cases input.type is uppercase (FieldInputForm), and lowercase in others
  if (['text', 'Text'].includes(input.type) && !values[input.name]?.match(/\S/)) return false;

  if (!values[input.name] || values[input.name].length === 0) return false;

  return true;
};

function validateBasicInputs(inputs, values) {
  const errors = {};

  inputs.forEach((input) => {
    const { required, type, name, fieldType, label } = input;

    if (required && !validateRequiredInput(values, input)) {
      errors[name] = 'This field is required';
    }

    if (fieldType === FIELD_TYPE_PHONE && !validateSimplePhoneNumber(values[name])) {
      errors[name] = 'Please enter a valid phone number';
    }

    if (fieldType === FIELD_TYPE_PHONE_E164 && !validatePhoneNumberFormField(values[name])) {
      errors[name] = 'Please enter a valid phone number';
    }

    if (type === 'currency' && !validateDollarValue(values[name])) {
      errors[name] = 'Please enter a valid dollar value';
    }

    if (label === 'Hire Date' || label === 'Termination Date') {
      const invalidEmploymentDate = validateEmploymentDate(input, values[name]);
      if (invalidEmploymentDate) {
        errors[name] = invalidEmploymentDate;
      }
    }

    if (input.type === 'percentage' && !validatePercentValue(values[name])) {
      errors[input.name] = 'Please enter a valid percentage value';
    }
  });

  return errors;
}

/* Similar to validateBasicInputs where inputs are not transformed from API response to GET fields */
function validateBasicFields(fields, values) {
  const errors = {};

  fields.forEach((field) => {
    if (field.isRequired && !validateRequiredInput(values, field)) {
      errors[field.name] = 'This field is required';
    }

    if (field.type === FIELD_TYPE_PHONE && !validateSimplePhoneNumber(values[field.name])) {
      errors[field.name] = 'Please enter a valid phone number';
    }

    if (field.type === FIELD_TYPE_PHONE_E164 && !validatePhoneNumberFormField(values[field.name])) {
      errors[field.name] = 'Please enter a valid phone number';
    }

    if (field.type === FIELD_TYPE_CURRENCY && !validateDollarValue(values[field.name])) {
      errors[field.name] = 'Please enter a valid dollar value';
    }
  });

  return errors;
}

function validateDefaultFilter(activeFilters) {
  const requiredKeys = ['name', 'value'];
  for (let j = 0; j < activeFilters.length; j += 1) {
    const active = activeFilters[j];
    if (typeof active !== 'object') {
      return false;
    }
    if (!(requiredKeys.every(key => key in active))) {
      return false;
    }
  }
  return true;
}

function validateMultiSelectFilter(activeFilters) {
  const requiredKeys = ['id', 'inclusive', 'selected', 'verb'];
  for (let j = 0; j < activeFilters.length; j += 1) {
    const active = activeFilters[j];
    if (typeof active !== 'object') {
      return false;
    }
    if (!(requiredKeys.every(key => key in active))) {
      return false;
    }
    if (!validateDefaultFilter(active.selected)) {
      return false;
    }
  }
  return true;
}

function validateDateFilter(activeFilters) {
  const requiredKeys = ['selected', 'verb'];
  const requiredSelectedKeys = ['name'];
  for (let i = 0; i < activeFilters.length; i += 1) {
    const active = activeFilters[i];
    if (typeof active !== 'object') {
      return false;
    }
    if (!(requiredKeys.every(key => key in active))) {
      return false;
    }
    for (let j = 0; j < active.selected.length; j += 1) {
      const selected = active.selected[j];
      if (!(requiredSelectedKeys.every(key => key in selected))) {
        return false;
      }
      if (active.verb === 'between' && !('endDate' in selected)) {
        return false;
      }
      if (active.verb !== BLANK_FILTER && !('date' in selected)) {
        return false;
      }
    }
  }
  return true;
}

function validateTextFilter(activeFilters) {
  const requiredKeys = ['name', 'blankFilter'];
  for (let j = 0; j < activeFilters.length; j += 1) {
    const active = activeFilters[j];
    if (typeof active !== 'object') {
      return false;
    }
    if (!(requiredKeys.every(key => key in active))) {
      return false;
    }
    if (!active.blankFilter && !('value' in active)) {
      return false;
    }
  }
  return true;
}

function validateCurrencyFilter(activeFilters) {
  const requiredKeys = ['selected', 'verb'];
  for (let j = 0; j < activeFilters.length; j += 1) {
    const active = activeFilters[j];
    if (typeof active !== 'object') {
      return false;
    }
    if (!(requiredKeys.every(key => key in active))) {
      return false;
    }
  }
  return true;
}

function failValidation() {
  console.error('Stored query failed validation'); // eslint-disable-line no-console
  return false;
}

function validateStoredFilter(filter) {
  if (filter === null) return false;
  if (typeof filter !== 'object') return failValidation();
  if (!(filter && 'args' in filter && Array.isArray(filter.args))) return failValidation();

  for (let i = 0; i < filter.args.length; i += 1) {
    const arg = filter.args[i];
    const { type, filterType } = arg;
    if (!(Array.isArray(arg.activeFilters) && 'label' in arg && 'column' in arg)) return failValidation();

    if (
      type === 'MultiSelect' ||
      [
        FILTER_ALLOCATION,
        FILTER_ALLOCATED_PEOPLE,
        FILTER_PHASE,
        FILTER_ROLES,
        FORECASTING_FILTER_ROLES,
        FILTER_CERTIFICATIONS,
      ].includes(filterType)
    ) {
      if (!validateMultiSelectFilter(arg.activeFilters)) return failValidation();
    } else if (arg.type === 'Text' || arg.type === 'Address') {
      if (!validateTextFilter(arg.activeFilters)) return failValidation();
    } else if (arg.type === 'Date' || arg.filterType === FILTER_PROJECT_DATE) {
      if (!validateDateFilter(arg.activeFilters)) return failValidation();
    } else if (arg.type === 'Currency') {
      if (!validateCurrencyFilter(arg.activeFilters)) return failValidation();
    } else if (!validateDefaultFilter(arg.activeFilters)) {
      return failValidation();
    }
  }

  return true;
}

function validateStoredSort(sort) {
  if (sort === null) return false;
  if (typeof sort !== 'object') return failValidation();
  if (!(
    sort
    && 'args' in sort
    && Array.isArray(sort.args)
    && sort.args.length
  )) return failValidation();

  const expectedKeys = ['label', 'column', 'ascending', 'type'];

  for (let i = 0; i < sort.args.length; i += 1) {
    const argKeys = Object.keys(sort.args[i]);
    if (!(argKeys.every(key => expectedKeys.includes(key)) && expectedKeys.every(key => argKeys.includes(key)))) {
      return failValidation();
    }
  }

  return true;
}

function getValidatedLocalStorage(storageKey) {
  let value;

  try {
    value = JSON.parse(localStorage.getItem(storageKey));
  } catch (err) {
    value = null;
  }

  return value;
}

function validatePhases(phases, values, projectDates) {
  const errors = {};
  const projectStart = projectDates['Start date'];
  const projectEnd = projectDates['End date'];

  phases.forEach((phase) => {
    const startDate = values[`Start date-${phase.id}`];
    const endDate = values[`End date-${phase.id}`];

    if (startDate && endDate && moment(startDate).isAfter(endDate, 'day')) {
      errors[`End date-${phase.id}`] = 'End date must be later than the Start date';
    }

    if (projectStart && projectEnd) {
      if (startDate && (moment(startDate).isBefore(projectStart, 'day') || moment(startDate).isAfter(projectEnd, 'day'))) {
        errors[`Start date-${phase.id}`] = 'Start date outside of project dates';
      }

      if (endDate && (moment(endDate).isBefore(projectStart, 'day') || moment(endDate).isAfter(projectEnd, 'day'))) {
        errors[`End date-${phase.id}`] = 'End date outside of project dates';
      }
    }

    phase.subPhases.forEach((sub) => {
      const subStartDate = values[`Start date-${sub.id}`];
      const subEndDate = values[`End date-${sub.id}`];

      if (subStartDate && subEndDate && moment(subStartDate).isAfter(subEndDate, 'day')) {
        errors[`End date-${sub.id}`] = 'End date must be later than the Start date';
      }

      if (subStartDate) {
        const momentStart = moment(subStartDate);

        if (startDate && endDate && (momentStart.isBefore(startDate, 'day') || momentStart.isAfter(endDate, 'day'))) {
          errors[`Start date-${sub.id}`] = 'Start date outside of phase dates';
        }

        if (projectStart && projectEnd && (momentStart.isBefore(projectStart, 'day') || momentStart.isAfter(projectEnd, 'day'))) {
          errors[`Start date-${sub.id}`] = 'Start date outside of project dates';
        }
      }

      if (subEndDate) {
        const momentEnd = moment(subEndDate);

        if (startDate && endDate && (momentEnd.isBefore(startDate, 'day') || momentEnd.isAfter(endDate, 'day'))) {
          errors[`End date-${sub.id}`] = 'End date outside of phase dates';
        }

        if (projectStart && projectEnd && (momentEnd.isBefore(projectStart, 'day') || momentEnd.isAfter(projectEnd, 'day'))) {
          errors[`End date-${sub.id}`] = 'End date outside of project dates';
        }
      }
    });
  });

  return errors;
}

function validateListItem(listItems, key, name, excludeIndex = -1) {
  const lowerName = name.toLowerCase();
  if (listItems.find((value, index) => value[key].toLowerCase() === lowerName && index !== excludeIndex)) {
    return false;
  }
  return true;
}

function validateServiceAccountName(name) {
  // allow all characters and spaces, but forbid using only space
  return /.*\S.*/gi.test(name);
}

function validateAPICurrencyString(value) {
  return /^-?\d+(\.\d\d?)?$/.test(value);
}

// react-final-form validators
const composeFormValidators = (...validators) => value => (
  validators.reduce((error, validator) => error || validator(value), undefined)
);

const isRequired = value => (value ? undefined : 'Required');

const isNumberInRange = (min, max) => value => (
  Number.isNaN(value) || (value >= min && value <= max)
    ? undefined
    : `Should be between ${min} and ${max}`
);

const isLengthInRange = (min, max) => value => (
  value?.length >= min && value?.length <= max
    ? undefined
    : `Should be between ${min} and ${max} characters long`
);

const isValueInUse = (arr = [], key) => value => (
  arr.some(el => el[key] === value)
    ? `This ${key} already exists`
    : undefined
);

const isUnique = (arr = [], item, errMsg) => (
  arr.some(el => el === item)
    ? errMsg || 'This value already exists'
    : undefined
);

const isDateValid = (min, max, errMsg) => value => (
  validateDate(value, min, max) || !value
    ? undefined
    : errMsg || 'Date is invalid'
);

export {
  validateSimplePhoneNumber,
  validateEmail,
  validateDollarValue,
  validatePercentInput,
  validateBasicInputs,
  validateBasicFields,
  validateStoredFilter,
  validateStoredSort,
  getValidatedLocalStorage,
  validatePhases,
  validateListItem,
  validateServiceAccountName,
  validateAPICurrencyString,
  composeFormValidators,
  isRequired,
  isNumberInRange,
  isLengthInRange,
  isValueInUse,
  isDateValid,
  isUnique,
};
