import React, { useState, useMemo, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Tooltip, ClickAwayListener } from '@material-ui/core';
import { InsertLink, ViewColumn } from '@material-ui/icons';
import { Button, IconButton } from '@bridgit/foundation';
import { trackAnalytics } from './redux/actions';
import { COLUMN_STORAGE_KEY, getStorageKey } from '../../common/localStorageKeys';
import { mixpanelSortEvent } from '../filters/utils/filterUtils';
import { setVisibleColumns } from '../table/redux/actions';
import { clearPeopleGanttShareExpiryDate, setPeopleGanttShareData } from '../gantt/redux/actions';
import { FilterPopper } from '.';
import { FileExportMenu } from '../reports';
import { SearchBox, SortDropdown, SortDirectionToggle } from '../filters';
import { PEOPLE_FILTER_TYPE, PROJECT_FILTER_TYPE, HOURLY_PERSON_FILTER_TYPE } from '../filters/common/constants';
import { CustomPopper } from '../wrapped-components';
import { PEOPLE_GANTT_CONFIG_KEY_V2 } from '../gantt/redux/constants';
import { SHARED_GANTT_EXPIRATION_TIMESTAMP_FORMAT } from '../../common/constants';

function HeaderControls({
  onSearch,
  options,
  queryId,
  searchPlaceholder,
  children,
}) {
  const dispatch = useDispatch();

  const { setPeopleGanttShareDataPending, peopleGanttConfig, sharePeopleGanttDataExpiryDate } = useSelector(({ gantt }) => {
    const { setPeopleGanttShareDataPending, instances, sharePeopleGanttDataExpiryDate } = gantt;
    const peopleGanttConfig = instances[PEOPLE_GANTT_CONFIG_KEY_V2];

    return {
      setPeopleGanttShareDataPending,
      peopleGanttConfig,
      sharePeopleGanttDataExpiryDate,
    };
  });
  const { alwaysVisible, neverVisible, visibleColumnNames } = useSelector(({ table }) => table);
  const { activeView, contentView, accountId } = useSelector(({ common }) => common);
  const { peopleFilterData, projectFilterData, hourlyPersonFilterData } = useSelector(({ filters }) => filters);
  const { filteredPeople: filteredPeopleQuery } = useSelector(({ queries }) => queries);
  const { sub: userId } = useSelector(({ login: { userInfo } }) => userInfo);

  const {
    showShareAction,
    showSearch,
    showSort,
    filterOptions,
    searchType,
    viewColumns,
    columnSelectionKey,
  } = options;

  const [showColumnsOpen, setShowColumnsOpen] = useState(false);
  const [shareGanttOpen, setShareGanttOpen] = useState(false);

  const showColumnsRef = useRef(null);
  const shareGanttRef = useRef(null);

  const closeShareGanttPopper = useCallback(() => {
    setShareGanttOpen(false);
    dispatch(clearPeopleGanttShareExpiryDate());
  }, [dispatch]);

  const openShareGanttPopper = useCallback(() => setShareGanttOpen(true), []);

  const filterData = useMemo(() => {
    switch (columnSelectionKey) {
      case PEOPLE_FILTER_TYPE:
        return peopleFilterData;
      case PROJECT_FILTER_TYPE:
        return projectFilterData;
      case HOURLY_PERSON_FILTER_TYPE:
        return hourlyPersonFilterData;
      default:
        return [];
    }
  }, [columnSelectionKey, peopleFilterData, projectFilterData, hourlyPersonFilterData]);

  /**
   * Get People Gantt data (filter/sort & gantt config) and trigger action to create a share URL
   */
  const getShareData = useCallback(() => {
    const data = {
      filter: filteredPeopleQuery,
      config: peopleGanttConfig,
    };

    dispatch(setPeopleGanttShareData(accountId, JSON.stringify(data)));
  }, [filteredPeopleQuery, peopleGanttConfig, accountId, dispatch]);

  const openShowColumns = useCallback(() => {
    setShowColumnsOpen(true);
  }, []);

  const closeShowColumns = useCallback(() => {
    setShowColumnsOpen(false);
  }, []);

  const applyShowColumns = useCallback((visibleColumns) => {
    const columnStorageKey = getStorageKey(activeView, accountId, COLUMN_STORAGE_KEY, userId);
    const columnList = visibleColumns.map(column => column.name);
    const visibleColumnsObj = { visibleColumns: columnList };

    localStorage.setItem(columnStorageKey, JSON.stringify(visibleColumnsObj));
    dispatch(setVisibleColumns(filterData, columnList));
  }, [accountId, activeView, userId, dispatch, filterData]);

  const trackSortChange = useCallback((columnName) => {
    const { event, payload } = mixpanelSortEvent(columnName, activeView, contentView);
    if (event && payload) {
      dispatch(trackAnalytics(event, payload));
    }
  }, [activeView, contentView, dispatch]);

  const renderFileExportMenu = () => {
    const { showExport, exportLoading, exportTitle } = options;
    if (!showExport) return null;
    return <FileExportMenu loading={exportLoading} title={exportTitle} queryId={queryId} />;
  };

  const renderShareAction = () => {
    if (!showShareAction) return null;

    return (
      <>
        <Tooltip title="Share" placement="bottom">
          <IconButton
            color="secondary"
            variant="outlined"
            onClick={openShareGanttPopper}
            ariaLabel="Share People Gantt configuration"
            buttonRef={shareGanttRef}
          >
            <InsertLink />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const selectableColumns = useMemo(() => {
    if (!viewColumns) return null;

    return filterData.filter(column => !alwaysVisible.includes(column.name) && !neverVisible.includes(column.name));
  }, [alwaysVisible, filterData, neverVisible, viewColumns]);

  const renderShareGanttPopper = () => (
    <div className="common-gantt-share-container">
      <ClickAwayListener onClickAway={closeShareGanttPopper}>
        <div>
          <p className="common-gantt-share-header">Share</p>
          <p className="common-gantt-share-content">Create a link to share this People Gantt configuration with other people in Bench.</p>
          <Button
            isLoading={setPeopleGanttShareDataPending}
            variant="plain"
            onClick={getShareData}
            startIcon={<InsertLink />}
            color="primary"
          >
            Copy link
          </Button>
          {sharePeopleGanttDataExpiryDate && (
            <p className="common-gantt-share-expiration-content">
              This link will be active until
              <span className="common-gantt-share-expiration-date">
                {' '}
                {sharePeopleGanttDataExpiryDate.format(SHARED_GANTT_EXPIRATION_TIMESTAMP_FORMAT)}
              </span>
            </p>
          )}
        </div>
      </ClickAwayListener>
    </div>
  );

  return (
    <div className="common-header-controls">
      <div className="button-group-wrapper">
        {renderShareAction()}
        {renderFileExportMenu()}
        {viewColumns && (
          <Tooltip title="View Columns" placement="bottom">
            <IconButton
              color="secondary"
              variant="outlined"
              onClick={openShowColumns}
              ariaLabel="View Columns"
              buttonRef={showColumnsRef}
            >
              <ViewColumn />
            </IconButton>
          </Tooltip>
        )}

        {showSort && (
          <>
            <SortDirectionToggle trackSortChange={trackSortChange} queryId={queryId} />
            <SortDropdown
              filterOptions={filterOptions}
              trackSortChange={trackSortChange}
              queryId={queryId}
            />
          </>
        )}

        {children}

        {showSearch && searchType && (
          <SearchBox
            onSearch={onSearch}
            type={searchType}
            queryId={queryId}
            placeholder={searchPlaceholder}
          />
        )}
      </div>

      {viewColumns && (
        <FilterPopper
          anchorEl={showColumnsRef.current}
          open={showColumnsOpen}
          title="Show Columns"
          items={selectableColumns}
          selected={visibleColumnNames}
          filterKey="name"
          onApply={applyShowColumns}
          onCancel={closeShowColumns}
          autoApply
        />
      )}

      <CustomPopper
        popperOpen={shareGanttOpen}
        anchorEl={shareGanttRef.current}
        classes="common-gantt-share-popper"
        placement="bottom-start"
        hideArrow
        preventBubbling
        disablePortal
      >
        {renderShareGanttPopper()}
      </CustomPopper>
    </div>
  );
}

HeaderControls.propTypes = {
  onSearch: PropTypes.func,
  options: PropTypes.shape({
    showShareAction: PropTypes.bool,
    showSearch: PropTypes.bool,
    showSort: PropTypes.bool,
    filters: PropTypes.arrayOf(PropTypes.shape({
      filterKey: PropTypes.string.isRequired,
      queryId: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      buttonId: PropTypes.string.isRequired,
    })),
    filterOptions: PropTypes.array,
    searchType: PropTypes.string,
    viewColumns: PropTypes.bool,
    columnSelectionKey: PropTypes.string,
    showExport: PropTypes.bool,
    exportLoading: PropTypes.bool,
    exportTitle: PropTypes.string,
  }),
  queryId: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  children: PropTypes.node,
};

HeaderControls.defaultProps = {
  onSearch: () => {},
  options: {
    showSearch: true,
    viewColumns: true,
  },
  searchPlaceholder: null,
  queryId: '',
  children: undefined,
};

export default HeaderControls;
