import {
  FIELDS,
  FIELDS_NAME,
  FIELDS_VALUE_TEXT,
} from './constants';

export default function generateTextFilter(fields, fieldName) {
  const path = FIELDS;
  const field = fields[0];

  if (!field.blankFilter) {
    return {
      bool: {
        must: {
          nested: {
            path,
            query: {
              bool: {
                must: [
                  {
                    match: {
                      [FIELDS_NAME]: fieldName,
                    },
                  },
                  {
                    query_string: {
                      query: `${FIELDS_VALUE_TEXT}: *${field.value}*`,
                    },
                  },
                ],
              },
            },
          },
        },
      },
    };
  }

  return {
    bool: {
      must_not: {
        nested: {
          path,
          query: {
            bool: {
              must: [
                {
                  match: {
                    [FIELDS_NAME]: fieldName,
                  },
                },
              ],
            },
          },
        },
      },
    },
  };
}
