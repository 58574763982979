import config from '../common/envConfig';
import { RP_AUTH_COOKIE_ID } from './constants';


export function setCookie(name, value, expiryDate) {
  let expires = '';
  if (expiryDate) {
    expires = `; expires=${expiryDate.toUTCString()}`;
  }

  document.cookie = `${name}=${value || ''}${expires}; Secure=true; SameSite=None; path=/;`;
}

export function expireCookie(name) {
  const expireDay = new Date('01-01-1970');
  document.cookie = `${name}=expires=${expireDay.toUTCString()}; max-age=0; Secure=true; SameSite=Strict; path=/;`;
}

export function getCookie(name) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function getAuthHeader() {
  if (config.useTokenAuth) {
    const authCookie = getCookie(RP_AUTH_COOKIE_ID);
    if (authCookie) {
      const authToken = `Bearer ${authCookie}`;
      return authToken;
    }
  }
  return '';
}

// NOTE: nginx is responsible for injecting Content-Security-Policy header (must restrict frame-ancestors)
// See: https://github.com/Bridgit/devOps/tree/master/kubernetes/bench/ingress for current configurations
export function doJsCookie(useTokenAuth) {
  return useTokenAuth || (window.self !== window.top);
}
