import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DragHandle } from '@material-ui/icons';
import classNames from 'classnames';
import pluralize from 'pluralize';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { reorderList } from '../../utils/miscUtils';

function SubPhaseList({
  phase,
  phases,
  activePhase,
  activeSubPhase,
  editingSubPhase,
  setActiveSubPhase,
  updateSubPhases,
}) {
  const [orderedSubPhases, setOrderedSubPhases] = useState(activePhase && activePhase.subPhases ? activePhase.subPhases : []);
  const [subDraggingIndex, setSubDraggingIndex] = useState(null);

  useEffect(() => {
    if (activePhase && activePhase.subPhases) setOrderedSubPhases(activePhase.subPhases);
  }, [activePhase, phases]);

  const onSubDragStart = (result) => {
    const { source } = result;
    setSubDraggingIndex(source.index);
  };

  const onSubDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
      setSubDraggingIndex(null);
      return;
    }
    const newSubPhases = reorderList(orderedSubPhases, source.index, destination.index);
    setOrderedSubPhases(newSubPhases);
    setSubDraggingIndex(null);
    updateSubPhases(newSubPhases);
  };

  const renderSubPhaseList = () => (
    <DragDropContext onDragStart={onSubDragStart} onDragEnd={onSubDragEnd}>
      <Droppable droppableId={`sub-phases-${phase.id}`}>
        {provided => (
          <div className="sub-phase-list fade" ref={provided.innerRef} {...provided.droppableProps}>
            {orderedSubPhases.map((sp, index) => (
              <Draggable draggableId={String(sp.id)} index={index} key={sp.id}>
                {provided => (
                  <div
                    className={classNames('sub-phase-item', { active: activeSubPhase && activeSubPhase.id === sp.id, dragging: subDraggingIndex === index })}
                    onClick={setActiveSubPhase}
                    data-id={sp.id}
                    role="presentation"
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                  >
                    <div className="sub-phase-item-inner">
                      <div className="drag-handle" {...provided.dragHandleProps}>
                        <DragHandle />
                      </div>
                      <p>{sp.name}</p>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
            {editingSubPhase && <p className={classNames('sub-phase-item', 'active', 'new')}>Sub-phase</p>}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );

  return (
    <div className="account-settings-sub-phase-list">
      {activePhase && phase.id === activePhase.id
        ? renderSubPhaseList()
        : (
          <div className="sub-phase-list">
            {phase.subPhases && phase.subPhases.length > 0 && <p className="sub-phase-label">{pluralize('sub-phase', phase.subPhases.length, true)}</p>}
          </div>
        )}
    </div>
  );
}

SubPhaseList.propTypes = {
  phase: PropTypes.object.isRequired,
  phases: PropTypes.array.isRequired,
  activePhase: PropTypes.object,
  activeSubPhase: PropTypes.object,
  editingSubPhase: PropTypes.bool.isRequired,
  setActiveSubPhase: PropTypes.func.isRequired,
  updateSubPhases: PropTypes.func.isRequired,
};

SubPhaseList.defaultProps = {
  activePhase: null,
  activeSubPhase: null,
};

export default SubPhaseList;
