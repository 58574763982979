const initialState = {
  unfilledRoles: [],
  projectBreakdown: [],
  idleTimeBreakdown: [],
  idleTimeOverview: [],
  monthlyIdleTimeOverview: [],
  idleTimeSentiment: '',
  getUnfilledRolesPending: false,
  getUnfilledRolesError: null,
  getProjectBreakdownPending: false,
  getProjectBreakdownError: null,
  getProjectBreakdownAndRolesPending: false,
  getProjectBreakdownAndRolesError: null,
  getIdleTimeBreakdownPending: false,
  getIdleTimeBreakdownError: null,
  getIdleTimeOverviewPending: false,
  getIdleTimeOverviewError: null,
  getMonthlyIdleTimeOverviewPending: false,
  getMonthlyIdleTimeOverviewError: null,
};

export default initialState;
