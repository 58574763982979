import { THEMES_CHANGE_THEME } from './constants';
import { setStoredThemeName } from '../utils';

export function changeTheme(themeName, userId, analyticsPayload) {
  return {
    type: THEMES_CHANGE_THEME,
    userId,
    themeName,
    analyticsPayload,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case THEMES_CHANGE_THEME: {
      const { themeName, userId } = action;

      setStoredThemeName(themeName, userId);

      return {
        ...state,
        theme: themeName,
      };
    }

    default:
      return state;
  }
}
