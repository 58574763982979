import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import pluralize from 'pluralize';
import classNames from 'classnames';
import {
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { KeyboardArrowUp, KeyboardArrowDown, Close } from '@material-ui/icons';
import { momentToString } from 'src/utils/dateUtils';
import { isAuthorized } from 'src/features/permissions/utils/permissionUtils';
import { PERM_WRITE, PERM_PROJECT } from 'src/features/permissions/utils/constants';
import { validatePhases } from 'src/utils/validators';
import { setEditorConfig } from 'src/features/edit-dates/redux/actions';
import { setModalActiveView, setSelectedProjectId } from 'src/features/common/redux/actions';
import { openModal } from 'src/features/modal-manager/redux/actions';
import { bindActionCreators } from 'redux';
import { phaseModalShouldOpen } from 'src/features/edit-dates/utils/dateEditorUtils';
import { Can } from '../wrapped-components';
import { DateEditor } from '../common';
import {
  DATE_DISPLAY_FORMAT,
  DATE_INPUT_FORMAT,
  PROJECT_DETAILS_MODAL,
  PROJECT_DATES,
  PROJECT_GANTT,
  LIST_TAB,
} from '../../common/constants';
import {
  COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_EDIT_PHASE_DATE,
  MULTI_STATE_MODAL_ID,
} from '../common/redux/constants';
import { SEGMENT_TYPE_PHASE } from '../edit-dates/common/constants';

const ProjectPhaseRow = ({
  project,
  phase,
  subPhase,
  handleDeleteClick,
  disabled,
  openPhases,
  handlePhaseToggle,
  updatePhase,
  accountId,
  userInfo,
  modalIsOpen,
  setModalActiveView,
  openModal,
  setSelectedProjectId,
  setEditorConfig,
  contentView,
}) => {
  const initialStartDate = subPhase ? subPhase.startDate : phase.startDate;
  const initialEndDate = subPhase ? subPhase.endDate : phase.endDate;
  const [editDateMode, setEditDateMode] = useState(false);
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [fieldEdited, setFieldEdited] = useState(null);
  const [startDateMessage, setStartDateMessage] = useState('');
  const [endDateMessage, setEndDateMessage] = useState('');
  const [errors, setErrors] = useState({});

  const minStartDate = subPhase ? phase.startDate : project.startDate;
  const maxStartDate = endDate;
  const minEndDate = startDate;
  const maxEndDate = subPhase ? phase.endDate : project.endDate;

  useEffect(() => {
    if (!subPhase) {
      setStartDate(phase.startDate);
      setEndDate(phase.endDate);
    }
  }, [project, phase, subPhase]);

  useEffect(() => {
    setStartDateMessage(errors[`Start date-${subPhase ? subPhase.id : phase.id}`]
      || errors[`End date-${subPhase ? subPhase.id : phase.id}`]);
    setEndDateMessage(errors[`End date-${subPhase ? subPhase.id : phase.id}`]);
  }, [errors, phase.id, subPhase]);

  useEffect(() => {
    if (subPhase) {
      setStartDate(subPhase.startDate);
      setEndDate(subPhase.endDate);
    }
  }, [phase, subPhase]);

  const cancelEditing = () => {
    setStartDate(initialStartDate);
    setEndDate(initialEndDate);
    setEditDateMode(!editDateMode);
    setStartDateMessage('');
    setEndDateMessage('');
  };

  const savePhaseDates = () => {
    const data = {
      startDate: momentToString(startDate),
      endDate: momentToString(endDate),
    };

    updatePhase(phase, data, fieldEdited, subPhase);
    setEditDateMode(!editDateMode);
  };

  const onSave = () => {
    const dateUnchanged = fieldEdited === 'start'
      ? moment(startDate).isSame(initialStartDate)
      : moment(endDate).isSame(initialEndDate);

    if (dateUnchanged) {
      cancelEditing();
      return;
    }

    const showAdvancedEdit = !subPhase && phaseModalShouldOpen(project, { ...phase, startDate, endDate });

    if (showAdvancedEdit) {
      const {
        id,
        startDate: projectStart,
        endDate: projectEnd,
        name: projectName,
        state: status,
        type: projectType,
      } = project;
      const { id: primaryId } = phase;

      let parentName = contentView === LIST_TAB ? PROJECT_DATES : PROJECT_GANTT;
      if (modalIsOpen) {
        parentName = PROJECT_DETAILS_MODAL;
      }

      const analyticsPayload = {
        runsInModal: modalIsOpen,
        data: {
          projectName,
          projectId: id,
          from: 'Project Details Modal',
          to: 'Edit Phase Modal',
          projectStatus: status,
          projectType,
        },
      };

      setSelectedProjectId(id, COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_EDIT_PHASE_DATE, null, modalIsOpen, false, null, analyticsPayload);
      setModalActiveView(COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_EDIT_PHASE_DATE);

      setEditorConfig(SEGMENT_TYPE_PHASE, projectStart, projectEnd, startDate, endDate, parentName, primaryId, phase.startDate, phase.endDate);

      if (!modalIsOpen) {
        openModal(MULTI_STATE_MODAL_ID);
      }

      setEditDateMode(!editDateMode);
    } else {
      savePhaseDates();
    }
  };

  const validateDates = (name, value) => {
    const start = name === 'start';
    const end = name === 'end';
    const phaseStart = start ? value : phase.startDate;
    const phaseEnd = end ? value : phase.endDate;

    const phases = [
      {
        ...phase,
        subPhases: subPhase ? [subPhase] : [],
      },
    ];

    const values = {
      [`Start date-${phase.id}`]: subPhase ? phase.startDate : phaseStart,
      [`End date-${phase.id}`]: subPhase ? phase.endDate : phaseEnd,
    };

    if (subPhase) {
      values[`Start date-${subPhase.id}`] = start ? value : subPhase.startDate;
      values[`End date-${subPhase.id}`] = end ? value : subPhase.endDate;
    }

    const projectDates = {
      'Start date': project.startDate,
      'End date': project.endDate,
    };

    const newErrors = validatePhases(phases, values, projectDates);
    setErrors(newErrors);
  };

  const updateStartDate = (start) => {
    setStartDate(start);
    setFieldEdited('start');
    validateDates('start', start);
  };

  const updateEndDate = (end) => {
    setEndDate(end);
    setFieldEdited('end');
    validateDates('end', end);
  };

  return (
    <div className={classNames(
      'projects-project-phase-row table-row',
      {
        'phase-row': !subPhase,
        'sub-phase-row': subPhase,
        'has-perms': isAuthorized(accountId, userInfo.permissions, PERM_WRITE, PERM_PROJECT),
        disabled,
      },
    )}
    >
      <div className="table-cell phase-name">{subPhase ? subPhase.name : phase.name}</div>
      <div className="table-cell phase-start">
        <span className="date-label">Start date</span>
        <Can
          action={PERM_WRITE}
          subject={PERM_PROJECT}
          yes={(
            <DateEditor
              date={moment(startDate, DATE_INPUT_FORMAT)}
              minDate={moment(minStartDate)}
              maxDate={moment(maxStartDate)}
              minDateMessage={startDateMessage}
              maxDateMessage={startDateMessage}
              onChange={updateStartDate}
              editMode={editDateMode}
              onEditToggle={cancelEditing}
              onSave={onSave}
              disableSaveButton={!!startDateMessage}
            />
          )}
          no={(
            <p className="plain-text-date">{moment(initialStartDate).format(DATE_DISPLAY_FORMAT)}</p>
          )}
        />
      </div>
      <div className="table-cell phase-end">
        <span className="date-label">End date</span>
        <Can
          action={PERM_WRITE}
          subject={PERM_PROJECT}
          yes={(
            <DateEditor
              date={moment(endDate, DATE_INPUT_FORMAT)}
              minDate={moment(minEndDate)}
              maxDate={subPhase ? moment(maxEndDate) : undefined}
              minDateMessage={endDateMessage}
              maxDateMessage={endDateMessage}
              onChange={updateEndDate}
              editMode={editDateMode}
              onEditToggle={cancelEditing}
              onSave={onSave}
              disableSaveButton={!!endDateMessage}
            />
          )}
          no={(
            <p className="plain-text-date">{moment(initialEndDate).format(DATE_DISPLAY_FORMAT)}</p>
          )}
        />
      </div>
      <div className="table-cell sub-toggle">
        {!subPhase && phase.subPhases && phase.subPhases.length > 0 &&
        (
          <div className="sub-phase-cell" onClick={handlePhaseToggle} data-id={phase.id} role="presentation">
            <p className="sub-phase-label">{pluralize('sub-phase', phase.subPhases.length, true)}</p>
            {openPhases.includes(phase.id) ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </div>
        )}
      </div>
      <div className="table-cell delete-phase">
        <Can
          action={PERM_WRITE}
          subject={PERM_PROJECT}
          yes={(
            <Tooltip title={`Remove ${subPhase ? 'sub-phase' : 'phase'}`} placement="top">
              <IconButton
                onClick={handleDeleteClick}
                data-id={subPhase ? subPhase.id : phase.id}
                data-parent={phase.id}
              >
                <Close fontSize="inherit" />
              </IconButton>
            </Tooltip>
          )}
        />
      </div>
    </div>
  );
};

ProjectPhaseRow.propTypes = {
  project: PropTypes.object.isRequired,
  phase: PropTypes.object.isRequired,
  subPhase: PropTypes.object,
  handleDeleteClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  openPhases: PropTypes.array.isRequired,
  handlePhaseToggle: PropTypes.func.isRequired,
  updatePhase: PropTypes.func.isRequired,
  accountId: PropTypes.number.isRequired,
  userInfo: PropTypes.object.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  setEditorConfig: PropTypes.func.isRequired,
  setModalActiveView: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  setSelectedProjectId: PropTypes.func.isRequired,
  contentView: PropTypes.number.isRequired,
};

ProjectPhaseRow.defaultProps = {
  subPhase: null,
};

/* istanbul ignore next */
function mapStateToProps({ common, login, modalManager }) {
  const { activeModal } = modalManager;
  const { accountId, contentView } = common;
  const { userInfo } = login;
  return {
    accountId,
    userInfo,
    modalIsOpen: activeModal === MULTI_STATE_MODAL_ID,
    contentView,
  };
}

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  setEditorConfig: bindActionCreators(setEditorConfig, dispatch),
  setModalActiveView: bindActionCreators(setModalActiveView, dispatch),
  openModal: bindActionCreators(openModal, dispatch),
  setSelectedProjectId: bindActionCreators(setSelectedProjectId, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectPhaseRow);
