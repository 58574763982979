import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import { Button, CircularProgress } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { KeyboardArrowLeft, KeyboardArrowRight, Event } from '@material-ui/icons';
import { b500bridgitBlue } from '@bridgit/foundation';

import { Modal } from '../common';
import { updateProject } from './redux/actions';
import { trackAnalytics } from '../common/redux/actions';
import {
  DATE_INPUT_FORMAT,
  DATE_DISPLAY_FORMAT,
  MIN_API_CONFIGURABLE_DATE,
  MIN_API_CONFIGURABLE_DATE_MESSAGE,
} from '../../common/constants';
import { PROJECT_DATES_SHIFTED } from '../../analytics/projects/constants';

export const ShiftProjectDates = ({
  project,
  parentName,
}) => {
  const dispatch = useDispatch();

  const { accountId } = useSelector(({ common }) => common);
  const { updateProjectPending } = useSelector(({ projects }) => projects);

  const initialStartDate = moment(project.startDate, DATE_INPUT_FORMAT);
  const [showModal, setShowModal] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [shiftedStartDate, setShiftedStartDate] = useState(initialStartDate);
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(true);
  const shiftedStartDateRef = useRef(null);

  useEffect(() => {
    setShowModal(false);
    setShowConfirm(false);
    setShiftedStartDate(moment(project.startDate, DATE_INPUT_FORMAT));
  }, [project, updateProjectPending]);

  const onButtonClick = () => setShowModal(true);

  const renderShiftDatesConfirm = () => {
    const daysDifference = moment(initialStartDate).from(shiftedStartDate, true);
    const impacted = [];
    const hasRoles = project.roles && project.roles.length > 0;
    const hasAllocations = hasRoles && project.roles.find(role => role.allocations && role.allocations.length);
    const hasPhases = project.phases && project.phases.length > 0;
    const hasSubPhases = project.phases &&
      project.phases.find(phase => Array.isArray(phase.subPhases) && phase.subPhases.length > 0);

    if (hasRoles) impacted.push('roles');
    if (hasAllocations) impacted.push('allocations');
    if (hasPhases) impacted.push('phases');
    if (hasSubPhases) impacted.push('sub-phases');

    const impactedText = impacted.length > 2 ? impacted.join(', ').replace(/, ([^,]*)$/, ', and $1') : impacted.join(' and ');

    return (
      <div className="confirm-shift-container">
        {updateProjectPending
          ? <CircularProgress className="confirm-loader" size={25} color="primary" />
          : (
            <>
              <h3>{`${project.name} will be shifted by ${daysDifference} and will now start on ${moment(shiftedStartDate).format(DATE_DISPLAY_FORMAT)}.`}</h3>
              <h3>{`This will also shift all ${impactedText} by ${daysDifference}.`}</h3>
            </>
          )}
      </div>
    );
  };

  const closeModal = () => {
    setShowModal(false);
    setShowConfirm(false);
  };

  const shiftProjectDates = () => {
    const data = {
      startDate: moment(shiftedStartDate, DATE_DISPLAY_FORMAT),
      shiftDates: true,
    };
    const isDelayed = moment(shiftedStartDate).isAfter(initialStartDate);
    const analyticsPayload = {
      'Project delayed': isDelayed,
      'Project moved forward': !isDelayed,
      'Project Name': project.name,
      'Project ID': project.id,
      'Project dates updated from': parentName,
      'Project status': project.state,
      'Project type': project.type,
    };

    dispatch(updateProject(accountId, project.id, data));
    dispatch(trackAnalytics(PROJECT_DATES_SHIFTED, analyticsPayload));
  };

  const confirmShiftDate = () => {
    const hasRoles = project.roles && project.roles.length > 0;
    const hasPhases = project.phases && project.phases.length > 0;

    if (!showConfirm && (hasRoles || hasPhases)) {
      setShowConfirm(true);
    } else {
      shiftProjectDates();
    }
  };

  const updateShiftedDates = date => setShiftedStartDate(date);

  const onError = error => setError(error);

  useEffect(() => {
    const disabled =
      error.length
      || !moment(shiftedStartDate).isValid()
      || moment(shiftedStartDate).isSame(initialStartDate, 'day')
      || updateProjectPending;

    setDisabled(disabled);
  }, [error, initialStartDate, shiftedStartDate, updateProjectPending]);

  const theme = {
    overrides: {
      MuiInput: {
        error: {
          '&::after': {
            borderBottom: `2px solid ${b500bridgitBlue} !important`,
          },
        },
        underline: {
          '&::after': {
            borderBottom: `2px solid ${b500bridgitBlue}`,
          },
        },
      },
      MuiDialogActions: {
        root: {
          display: 'none',
        },
      },
    },
  };

  const renderModal = () => (
    <Modal
      headline="Shift Project"
      closeModal={closeModal}
      className="shift-modal"
      buttons={[
        <Button
          key="shiftProj"
          color="primary"
          variant={showConfirm ? 'contained' : 'text'}
          onClick={confirmShiftDate}
          className={classNames({ disabled })}
        >
          {showConfirm ? 'Confirm' : 'Save'}
        </Button>,
      ]}
    >
      {showConfirm
        ? renderShiftDatesConfirm()
        : (
          <div className="shift-date-container">
            <span>New start date</span>
            <MuiThemeProvider theme={theme}>
              <KeyboardDatePicker
                className="date-picker"
                FormHelperTextProps={{
                  classes: {
                    error: 'mui-form-helper-text',
                  },
                }}
                inputRef={shiftedStartDateRef}
                format="MM/DD/YYYY"
                placeholder="MM/DD/YYYY"
                minDate={moment(MIN_API_CONFIGURABLE_DATE)}
                minDateMessage={MIN_API_CONFIGURABLE_DATE_MESSAGE}
                invalidDateMessage="Invalid Date Format"
                onChange={updateShiftedDates}
                onError={onError}
                value={shiftedStartDate}
                initialFocusedDate={initialStartDate}
                autoOk
                leftArrowIcon={<KeyboardArrowLeft />}
                rightArrowIcon={<KeyboardArrowRight />}
                keyboardIcon={<Event className="date-picker-icon" />}
                variant="inline"
              />
            </MuiThemeProvider>
          </div>
        )}
    </Modal>
  );

  return (
    <div className="projects-shift-project-dates">
      <Button color="primary" variant="contained" onClick={onButtonClick}>Shift Project</Button>
      {showModal && renderModal()}
    </div>
  );
};

ShiftProjectDates.propTypes = {
  project: PropTypes.object.isRequired,
  parentName: PropTypes.string.isRequired,
};

export default ShiftProjectDates;
