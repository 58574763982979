import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { NumberInput } from 'src/features/wrapped-components';
import classNames from 'classnames';

const RequirementBulkInput = ({
  className,
  onUpdate,
  percentages,
}) => {
  const getSingleValue = useCallback(() => {
    const isCustom = new Set(percentages).size > 1;
    return isCustom ? '' : percentages?.[0];
  }, [percentages]);

  const [workingValue, setWorkingValue] = useState(getSingleValue());

  useEffect(() => {
    setWorkingValue(getSingleValue());
  }, [getSingleValue, percentages]);

  const onInputChange = value => setWorkingValue(value);

  const onBlur = () => {
    if (workingValue !== '') onUpdate(workingValue);
  };

  return (
    <Tooltip title="Set all values" placement="top">
      <div className={classNames('allocations-requirement-bulk-input', className)}>
        <NumberInput
          placeholder="Custom"
          className="requirement-bulk-input"
          onValueChanged={onInputChange}
          value={workingValue}
          onBlur={onBlur}
        />
      </div>
    </Tooltip>
  );
};

RequirementBulkInput.propTypes = {
  className: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  percentages: PropTypes.array.isRequired,
};

RequirementBulkInput.defaultProps = {
  className: '',
};

export default RequirementBulkInput;
