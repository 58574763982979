export const HOURLY_PERSON_ADDED = 'Hourly Person Added';
export const HOURLY_PERSON_UPDATED = 'Hourly Person Updated';
export const HOURLY_PERSON_DEACTIVATED = 'Deactivate Hourly Person';

export const REQUEST_ADDED = 'Request Added';
export const REQUEST_DELETED = 'Request Deleted';

export const REQUEST_ASSIGNMENT_ADDED = 'Request - Assignment Added';
export const REQUEST_ASSIGNMENT_DELETED = 'Request - Assignment Deleted';
export const REQUEST_NOTIFIED = 'Request - Assignment Notification Sent';
export const HOURLY_REQUEST_UPDATED = 'Request Updated';

export const HOURLY_REQUEST_REJECTED = 'Request Rejected';
export const HOURLY_REQUEST_REOPENED = 'Request Reopened';

export const REQUEST_MATCHES_SEARCHED = 'Request Detail - Search for Hourly Person';
