import { call, put, takeLatest } from 'redux-saga/effects';
import { protectedJsonPut } from 'src/utils/api';
import {
  PROJECTS_UPDATE_PROJECT_SUB_PHASE_BEGIN,
  PROJECTS_UPDATE_PROJECT_SUB_PHASE_SUCCESS,
  PROJECTS_UPDATE_PROJECT_SUB_PHASE_FAILURE,
  PROJECTS_UPDATE_PROJECT_SUB_PHASE_DISMISS_ERROR,
} from './constants';
import { updateProjectSubPhaseById } from '../utils/projectUtils';

export function updateProjectSubPhase(accountId, project, phaseId, subPhase, data, analyticsPayload = { }) {
  return {
    type: PROJECTS_UPDATE_PROJECT_SUB_PHASE_BEGIN,
    accountId,
    project,
    phaseId,
    subPhase,
    data,
    analyticsPayload,
  };
}

export function dismissUpdateProjectSubPhaseError() {
  return {
    type: PROJECTS_UPDATE_PROJECT_SUB_PHASE_DISMISS_ERROR,
  };
}

export function* doUpdateProjectSubPhase(action) {
  const { accountId, project, phaseId, subPhase, data, analyticsPayload } = action;
  try {
    yield call(protectedJsonPut, `accounts/${accountId}/projects/${project.id}/phases/${phaseId}/subphases/${subPhase.id}`, data);
  } catch (err) {
    yield put({
      type: PROJECTS_UPDATE_PROJECT_SUB_PHASE_FAILURE,
      data: { error: err },
    });
    return;
  }
  yield put({
    type: PROJECTS_UPDATE_PROJECT_SUB_PHASE_SUCCESS,
    accountId,
    project,
    phaseId,
    subPhase,
    data,
    analyticsPayload,
  });
}

export function* watchUpdateProjectSubPhase() {
  yield takeLatest(PROJECTS_UPDATE_PROJECT_SUB_PHASE_BEGIN, doUpdateProjectSubPhase);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PROJECTS_UPDATE_PROJECT_SUB_PHASE_BEGIN:
      return {
        ...state,
        updateProjectSubPhasePending: true,
        updateProjectSubPhaseError: null,
      };

    case PROJECTS_UPDATE_PROJECT_SUB_PHASE_SUCCESS: {
      const { project, phaseId, subPhase, data: { startDate, endDate } } = action;
      const { filteredProjects, projectSelections } = state;

      const newFilteredProjects = filteredProjects.map((proj) => {
        if (proj.id === project.id) {
          return updateProjectSubPhaseById(project, subPhase, startDate, endDate, phaseId);
        }
        return proj;
      });

      const newProjectSelections = { ...projectSelections };
      Object.keys(newProjectSelections).forEach((selectionId) => {
        const selectedProject = newProjectSelections[selectionId];
        if (selectedProject?.id === project.id) {
          const updatedProject = updateProjectSubPhaseById(selectedProject, subPhase, startDate, endDate, phaseId);
          newProjectSelections[selectionId] = {
            ...updatedProject,
          };
        }
      });

      return {
        ...state,
        filteredProjects: newFilteredProjects,
        updateProjectSubPhasePending: false,
        updateProjectSubPhaseError: null,
        projectSelections: newProjectSelections,
      };
    }

    case PROJECTS_UPDATE_PROJECT_SUB_PHASE_FAILURE:
      return {
        ...state,
        updateProjectSubPhasePending: false,
        updateProjectSubPhaseError: action.data.error,
      };

    case PROJECTS_UPDATE_PROJECT_SUB_PHASE_DISMISS_ERROR:
      return {
        ...state,
        updateProjectSubPhaseError: null,
      };

    default:
      return state;
  }
}
