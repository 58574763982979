const initialState = {
  getServiceAccountsPending: false,
  getServiceAccountsError: null,
  accounts: [],
  account: null,
  addServiceAccountPending: false,
  addServiceAccountError: null,
  resetServiceAccountPasswordPending: false,
  resetServiceAccountPasswordError: null,
  deleteServiceAccountPending: false,
  deleteServiceAccountError: null,
};

export default initialState;
