import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Loader } from '@bridgit/foundation';
import { GANTT_TAB, GANTT } from 'src/common/constants';
import { LocalStorageKeys } from 'src/common/localStorageKeys';
import {
  EmptyGantt,
  GanttControls,
  PeopleGanttViewSettings,
  PeopleGanttChart,
} from 'src/features/gantt';
import { infiniteScrollWatcher } from 'src/utils/uiUtils';
import { backoffRetry } from 'src/utils/api';
import { PEOPLE_GANTT_CONFIG_KEY } from './redux/constants';
import { setGanttConfig } from './redux/actions';
import { AddPersonModal } from '../people';
import { defaultFilters } from '../filters/common/constants';
import { FILTERED_PEOPLE_QUERY_ID } from '../queries/redux/constants';
import { MULTI_STATE_MODAL_ID } from '../common/redux/constants';
import { setSelectedPersonId } from '../common/redux/actions';
import { openModal } from '../modal-manager/redux/actions';


export class PeopleGantt extends Component {
  static propTypes = {
    people: PropTypes.object.isRequired,
    accountId: PropTypes.number.isRequired,
    actions: PropTypes.object.isRequired,
    accounts: PropTypes.array.isRequired,
    loadMore: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    loadingPage: PropTypes.bool,
    pagingError: PropTypes.bool,
    setSelectedPersonId: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    parsedPeople: PropTypes.array,
    peopleCount: PropTypes.number,
  };

  static defaultProps = {
    loading: false,
    loadingPage: false,
    pagingError: false,
    parsedPeople: [],
    peopleCount: 0,
  }

  constructor(props) {
    super(props);
    const { loadMore } = props;

    this.retryHandler = backoffRetry(loadMore);

    localStorage.setItem(LocalStorageKeys.peopleView, GANTT_TAB);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { pagingError, loadingPage } = this.props;
    if ((!pagingError && nextProps.pagingError) || (nextProps.pagingError && loadingPage && !nextProps.loadingPage)) {
      this.retryHandler.retry();
    } else if (pagingError && !nextProps.pagingError) {
      this.retryHandler.resetTries();
    }
  }

  componentWillUnmount() {
    const { actions } = this.props;
    this.retryHandler.stopTrying();
    actions.setGanttConfig(PEOPLE_GANTT_CONFIG_KEY, { scrollPosition: null });
  }

  retryHandler = null;

  onScroll = (element) => {
    const { loadMore, people, pagingError } = this.props;
    infiniteScrollWatcher(element, loadMore, people.getFilteredPeoplePending, pagingError);
  }

  trackButtonClick = (person) => {
    const { name, id } = person;
    window.mixpanel.track('Profile Details Modal Displayed', {
      'Person name': name,
      'Person id': id,
      'Launched from': 'People Gantt',
    });
  }

  showModal = person => () => {
    const { setSelectedPersonId, openModal } = this.props;
    setSelectedPersonId(person.id);
    openModal(MULTI_STATE_MODAL_ID);
    this.trackButtonClick(person);
  };

  render() {
    const {
      accountId,
      accounts,
      parsedPeople,
      loading,
      peopleCount,
    } = this.props;

    const useClearText = !defaultFilters.people.length;
    const accountInfo = accounts.find(a => a.id === accountId);

    if (parsedPeople.length === 0) {
      if (accountInfo && accountInfo.numPeople === 0) {
        return (
          <EmptyGantt
            className="gantt-people-gantt"
            welcomeText="Welcome to your people gantt chart."
            bodyText="You have no active people, click the + button below to add one."
          >
            <AddPersonModal modalOrigin={GANTT} />
          </EmptyGantt>
        );
      }
    }

    return (
      <div className="gantt-people-gantt">
        <div className="chart-wrapper">
          <div className="gantt-filtered-gantt">
            <GanttControls
              useClearText={useClearText}
              queryId={FILTERED_PEOPLE_QUERY_ID}
              sliderContent={<PeopleGanttViewSettings />}
              resultCount={peopleCount}
            />
            {loading
              ? <Loader />
              : <PeopleGanttChart onScroll={this.onScroll} showModal={this.showModal} />}
          </div>
        </div>
        <AddPersonModal modalOrigin={GANTT} />
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps({ common, accounts, people, gantt }) {
  const { accountId } = common;
  const { getFilteredPeoplePending, peopleCount } = people;
  const {
    parsedPeople,
  } = gantt.instances[PEOPLE_GANTT_CONFIG_KEY];

  const loadingPage = people.getFilteredPeoplePagePending;
  const pagingError = !!people.getFilteredPeoplePageError;

  return {
    people,
    accountId,
    accounts: accounts.entities,
    loading: getFilteredPeoplePending,
    loadingPage,
    pagingError,
    parsedPeople,
    peopleCount,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ setGanttConfig }, dispatch),
    setSelectedPersonId: bindActionCreators(setSelectedPersonId, dispatch),
    openModal: bindActionCreators(openModal, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PeopleGantt);
