const DISTANCE_FILTER_RANGE = {
  MIN: 1,
  MAX: 150,
};

const EMPTY_RADIUS = 0;

export {
  DISTANCE_FILTER_RANGE,
  EMPTY_RADIUS,
};
