import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNTS_UPDATE_USER_PERMISSIONS_BEGIN,
  ACCOUNTS_UPDATE_USER_PERMISSIONS_SUCCESS,
  ACCOUNTS_UPDATE_USER_PERMISSIONS_FAILURE,
  ACCOUNTS_UPDATE_USER_PERMISSIONS_DISMISS_ERROR,
} from './constants';
import { protectedJsonPut } from '../../../utils/api';

export function updateUserPermissions(accountId, userId, group, analytics) {
  return {
    type: ACCOUNTS_UPDATE_USER_PERMISSIONS_BEGIN,
    accountId,
    userId,
    group,
    analytics,
  };
}

export function dismissUpdateUserPermissionsError() {
  return {
    type: ACCOUNTS_UPDATE_USER_PERMISSIONS_DISMISS_ERROR,
  };
}

export function* doUpdateUserPermissions(action) {
  const { accountId, userId, group, analytics } = action;
  try {
    yield call(protectedJsonPut, `accounts/${accountId}/users/${userId}/membership`, { group });
  } catch (err) {
    yield put({
      type: ACCOUNTS_UPDATE_USER_PERMISSIONS_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: ACCOUNTS_UPDATE_USER_PERMISSIONS_SUCCESS,
    accountId,
    userId,
    group,
    analytics,
  });
}

export function* watchUpdateUserPermissions() {
  yield takeLatest(ACCOUNTS_UPDATE_USER_PERMISSIONS_BEGIN, doUpdateUserPermissions);
}

export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNTS_UPDATE_USER_PERMISSIONS_BEGIN:
      return {
        ...state,
        updateUserPermissionsPending: true,
        updateUserPermissionsError: null,
      };

    case ACCOUNTS_UPDATE_USER_PERMISSIONS_SUCCESS: {
      const { accountId, userId, group } = action;
      const user = state.users[accountId].find(user => user.id === userId);
      user.group = group;

      const newUsers = {
        ...state.users,
        ...{
          [accountId]: [
            ...state.users[accountId].filter(user => user.id !== userId),
            user,
          ],
        },
      };
      return {
        ...state,
        users: newUsers,
        updateUserPermissionsPending: false,
        updateUserPermissionsError: null,
      };
    }

    case ACCOUNTS_UPDATE_USER_PERMISSIONS_FAILURE:
      return {
        ...state,
        updateUserPermissionsPending: false,
        updateUserPermissionsError: action.data.error,
      };

    case ACCOUNTS_UPDATE_USER_PERMISSIONS_DISMISS_ERROR:
      return {
        ...state,
        updateUserPermissionsError: null,
      };

    default:
      return state;
  }
}
