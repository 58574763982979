import React, { useState } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { MenuItem, InputBase, InputAdornment } from '@material-ui/core';
import { Search, Close } from '@material-ui/icons';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { sortAlphabetically } from 'src/utils/sortUtils';
import { filterArrayByProp, moveObjToStartOfArray } from '../../utils/arrayUtils';
import { DOT } from '../../common/constants';

const FilterList = ({
  columns,
  priorityColumns,
  exclude,
  onFilterSelect,
  filteredColumns,
  alphabetize,
  placeholder,
}) => {
  const [searchValue, setSearchValue] = useState('');

  const onClick = filter => () => {
    onFilterSelect(filter);
    return false;
  };

  const handleSearchChange = (event) => {
    const { target: { value } } = event;
    setSearchValue(value);
  };

  const handleClearSearchValue = () => {
    setSearchValue('');
  };

  const renderMenuItems = () => {
    const filteredItems = filterArrayByProp(columns, exclude, 'name');

    const formattedItems = alphabetize ? sortAlphabetically(filteredItems, true, 'name') : filteredItems;

    // Move each "priority column" to the top of the filter list
    priorityColumns.slice().reverse().forEach(elem => (
      moveObjToStartOfArray(formattedItems, 'name', elem)
    ));

    const menuItems = [];
    const lowerSearchValue = searchValue.toLowerCase();
    formattedItems.forEach((column) => {
      const { name } = column;
      if (name.toLowerCase().indexOf(lowerSearchValue) >= 0) {
        const applied = filteredColumns.includes(name);

        menuItems.push(
          <MenuItem id={name} className="menu-item" key={shortid.generate()} onClick={onClick(column)}>
            {applied && (
              <ListItemIcon className="filter-indicator">
                <>{DOT}</>
              </ListItemIcon>
            )}
            {name}
          </MenuItem>,
        );
      }
    });

    if (!menuItems.length) {
      return <div className="no-results-found">No results found</div>;
    }
    return menuItems;
  };

  return (
    <div className="filters-filter-list">
      <InputBase
        className="search-field"
        onChange={handleSearchChange}
        placeholder={placeholder}
        value={searchValue}
        startAdornment={
          (
            <InputAdornment position="start">
              <Search className="search-icon" />
            </InputAdornment>
          )
        }
        endAdornment={
          !!searchValue.length && (
            <InputAdornment position="start">
              <Close className="search-icon clickable" onClick={handleClearSearchValue} />
            </InputAdornment>
          )
        }
      />
      { renderMenuItems() }
    </div>
  );
};

FilterList.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  priorityColumns: PropTypes.arrayOf(PropTypes.string),
  exclude: PropTypes.arrayOf(PropTypes.object),
  onFilterSelect: PropTypes.func,
  filteredColumns: PropTypes.array,
  alphabetize: PropTypes.bool,
  placeholder: PropTypes.string,
};

FilterList.defaultProps = {
  columns: [],
  priorityColumns: [],
  exclude: [],
  onFilterSelect: () => {},
  filteredColumns: [],
  alphabetize: true,
  placeholder: 'Search on field name',
};

export default FilterList;
