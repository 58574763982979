import { call, put, takeLatest } from 'redux-saga/effects';

import {
  LOGIN_GET_LOGIN_INVITATION_BEGIN,
  LOGIN_GET_LOGIN_INVITATION_SUCCESS,
  LOGIN_GET_LOGIN_INVITATION_FAILURE,
  LOGIN_GET_LOGIN_INVITATION_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';
import { COMMON_SET_ACTIVE_ACCOUNT_ID } from '../../common/redux/constants';

export function getLoginInvitation(data) {
  // If need to pass args to saga, pass it with the begin action.
  return {
    type: LOGIN_GET_LOGIN_INVITATION_BEGIN,
    data,
  };
}

export function dismissGetLoginInvitationError() {
  return {
    type: LOGIN_GET_LOGIN_INVITATION_DISMISS_ERROR,
  };
}

// worker Saga: will be fired on LOGIN_GET_LOGIN_INVITATION_BEGIN actions
export function* doGetLoginInvitation(action) {
  // If necessary, use argument to receive the begin action with parameters.
  try {
    // example urlToken = '9131fe4d-16d6-4810-b1ab-5d373f68bb79';
    const loginData = yield call(protectedJsonGet, `signup?token=${action.data}`);

    const { accountId } = loginData;

    yield put({
      type: COMMON_SET_ACTIVE_ACCOUNT_ID,
      accountId,
    });

    yield put({
      type: LOGIN_GET_LOGIN_INVITATION_SUCCESS,
      data: loginData,
    });
  } catch (err) {
    yield put({
      type: LOGIN_GET_LOGIN_INVITATION_FAILURE,
      data: { error: err },
    });
  }
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchGetLoginInvitation() {
  yield takeLatest(LOGIN_GET_LOGIN_INVITATION_BEGIN, doGetLoginInvitation);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case LOGIN_GET_LOGIN_INVITATION_BEGIN:
      return {
        ...state,
        getLoginInvitationPending: true,
        getLoginInvitationError: null,
      };

    case LOGIN_GET_LOGIN_INVITATION_SUCCESS: {
      const { email, group } = action.data;

      return {
        ...state,
        userInvitationData: {
          email,
          group,
        },
        getLoginInvitationPending: false,
        getLoginInvitationError: null,
      };
    }

    case LOGIN_GET_LOGIN_INVITATION_FAILURE:
      return {
        ...state,
        getLoginInvitationPending: false,
        getLoginInvitationError: true,
      };

    case LOGIN_GET_LOGIN_INVITATION_DISMISS_ERROR:
      return {
        ...state,
        getLoginInvitationError: null,
      };

    default:
      return state;
  }
}
