import React, { useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Loader } from '@bridgit/foundation';
import { ValidatedForm } from 'src/features/wrapped-components';
import { COUNTRIES } from 'src/features/account-settings/common/constants';
import { getAccountProperties, updateAccountProperties } from './redux/actions';

function BridgitAccountDetails({
  accountId,
  accountProperties,
  accountPropertyOptions,
  getAccountPropertiesPending,
  getAccountProperties,
  updateAccountProperties,
}) {
  const inputs = useMemo(() => {
    const {
      enr400,
      country,
      provinceState,
      demo,
    } = accountProperties;

    return [
      {
        label: 'Country',
        name: 'country',
        type: 'select',
        placeholder: 'Select a country',
        options: COUNTRIES,
        value: country,
      },
      ...(country === 'Canada' || country === 'United States')
        ? [{
          label: country === 'Canada' ? 'Province' : 'State',
          name: 'provinceState',
          type: 'select',
          placeholder: `Select a ${country === 'Canada' ? 'province' : 'state'}`,
          options: accountPropertyOptions[country].map(option => ({
            value: option,
            label: option,
          })),
          value: provinceState,
        }]
        : [],
      {
        label: 'ENR 400 Company',
        name: 'enr400',
        type: 'checkbox',
        value: enr400,
      },
      {
        label: 'Demo Account',
        name: 'demo',
        type: 'checkbox',
        value: demo,
      },
    ];
  }, [accountProperties, accountPropertyOptions]);

  useEffect(() => {
    getAccountProperties(accountId);
  }, [accountId, getAccountProperties]);

  const onValueChanged = useCallback((input, values) => {
    let { provinceState } = values;

    // If country changed, clear value for provinceState
    if (input === 'country' || provinceState === '') {
      provinceState = null;
    }

    updateAccountProperties(accountId, {
      ...values,
      provinceState,
    });
  }, [updateAccountProperties, accountId]);

  if (getAccountPropertiesPending) {
    return (
      <div className="account-settings-bridgit-account-details">
        <Loader />
      </div>
    );
  }

  return (
    <div className="account-settings-bridgit-account-details">
      <div className="bridgit-account-header">Account Properties</div>
      <div className="bridgit-account-fields">
        <ValidatedForm
          inputs={inputs}
          onValueChanged={onValueChanged}
          forceUpdate
        />
      </div>
    </div>
  );
}

BridgitAccountDetails.propTypes = {
  accountId: PropTypes.number.isRequired,
  accountProperties: PropTypes.object,
  accountPropertyOptions: PropTypes.object,
  getAccountPropertiesPending: PropTypes.bool,
  getAccountProperties: PropTypes.func.isRequired,
  updateAccountProperties: PropTypes.func.isRequired,
};

BridgitAccountDetails.defaultProps = {
  accountProperties: {},
  accountPropertyOptions: {},
  getAccountPropertiesPending: false,
};

/* istanbul ignore next */
function mapStateToProps({ common, accountSettings }) {
  const { accountId } = common;
  const {
    accountProperties,
    accountPropertyOptions,
    getAccountPropertiesPending,
  } = accountSettings;
  return {
    accountId,
    accountProperties,
    accountPropertyOptions: accountPropertyOptions?.countries,
    getAccountPropertiesPending,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    getAccountProperties: bindActionCreators(getAccountProperties, dispatch),
    updateAccountProperties: bindActionCreators(updateAccountProperties, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BridgitAccountDetails);
