import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import classNames from 'classnames';

export default class FilterChip extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    removeFilter: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: '',
  };

  removeChip = () => {
    const { removeFilter, label } = this.props;
    removeFilter(label);
  }

  render() {
    const { label, className } = this.props;

    return (
      <Chip
        size="small"
        label={label}
        onDelete={this.removeChip}
        className={classNames('table-filter-chip', className)}
        classes={{ deleteIcon: 'icon-delete', label: 'label' }}
      />
    );
  }
}
