import { gql } from 'apollo-boost';

const PLACE_SUGGESTIONS_QUERY = gql`
  query places($accountId: Int!, $input: String!) {
    places(accountId: $accountId, input: $input) {
      suggestions
      status
    }
  }
 `;

export const getPlaceSuggestionsQuery = (accountId, input) => {
  const variables = { accountId, input };

  return {
    query: PLACE_SUGGESTIONS_QUERY,
    variables,
  };
};
