import {
  ACCOUNT_SETTINGS_ADD_PERSON_FIELDS_SUCCESS,
  ACCOUNT_SETTINGS_ADD_HOURLY_PERSON_FIELDS_SUCCESS,
  ACCOUNT_SETTINGS_ADD_PHASE_SUCCESS,
  ACCOUNT_SETTINGS_ADD_PROJECT_FIELDS_SUCCESS,
  ACCOUNT_SETTINGS_ADD_SUB_PHASE_SUCCESS,
  ACCOUNT_SETTINGS_DELETE_PHASE_SUCCESS,
  ACCOUNT_SETTINGS_DELETE_SUB_PHASE_SUCCESS,
  ACCOUNT_SETTINGS_REMOVE_PERSON_FIELDS_SUCCESS,
  ACCOUNT_SETTINGS_REMOVE_HOURLY_PERSON_FIELDS_SUCCESS,
  ACCOUNT_SETTINGS_REMOVE_PROJECT_FIELDS_SUCCESS,
  ACCOUNT_SETTINGS_UPDATE_PERSON_FIELDS_SUCCESS,
  ACCOUNT_SETTINGS_UPDATE_HOURLY_PERSON_FIELDS_SUCCESS,
  ACCOUNT_SETTINGS_UPDATE_PHASES_SUCCESS,
  ACCOUNT_SETTINGS_UPDATE_PHASE_SUCCESS,
  ACCOUNT_SETTINGS_UPDATE_PROJECT_FIELDS_SUCCESS,
  ACCOUNT_SETTINGS_UPDATE_SUB_PHASES_SUCCESS,
  ACCOUNT_SETTINGS_UPDATE_SUB_PHASE_SUCCESS,
  ACCOUNT_SETTINGS_REMOVE_SKILL_SUCCESS,
  ACCOUNT_SETTINGS_UPDATE_SKILL_SET_SUCCESS,
  ACCOUNT_SETTINGS_ADD_PURSUIT_TRACKING_MODULE_SUCCESS,
  ACCOUNT_SETTINGS_REMOVE_PURSUIT_TRACKING_MODULE_SUCCESS,
  ACCOUNT_SETTINGS_UPDATE_ACCOUNT_MODULE_SUCCESS,
  ACCOUNT_SETTINGS_ADD_CERTIFICATION_SUCCESS,
  ACCOUNT_SETTINGS_UPDATE_CERTIFICATION_SUCCESS,
  ACCOUNT_SETTINGS_DELETE_CERTIFICATION_SUCCESS,
} from 'src/features/account-settings/redux/constants';

import {
  CUSTOM_FIELD_ADDED,
  CUSTOM_FIELD_DELETED,
  CUSTOM_FIELD_UPDATED,
  PEOPLE,
  PHASE,
  PHASES_REORDERED,
  PHASE_CREATED,
  PHASE_DELETED,
  PHASE_EDITED,
  PROJECT,
  SUB_PHASE,
  HOURLY_PEOPLE,
  SKILL_SET,
  MULTI_SELECT,
  PURSUIT_TRACKING_ENABLED,
  PURSUIT_TRACKING_DISABLED,
  MODULE_COMPONENT_UPDATED,
  CERTIFICATION_CREATED,
  CERTIFICATION_EDITED,
  CERTIFICATION_DELETED,
} from './constants';

const combineParams = (data) => {
  const [name, type, isRequired, isPrivate] = data.reduce((acc, val) => {
    acc[0].push(val.name);
    acc[1].push(val.type);
    acc[2].push(val.isRequired);
    acc[3].push(val.isPrivate);
    return acc;
  }, [[], [], [], []]);
  return { name, type, isRequired, isPrivate };
};

const events = {
  [ACCOUNT_SETTINGS_UPDATE_SKILL_SET_SUCCESS]: {
    getEvent: () => CUSTOM_FIELD_UPDATED,
    getPayload: () => ({
      'Field Name': SKILL_SET,
      'Field Type': MULTI_SELECT,
      isMandatory: true,
      isPrivate: false,
      'Project/People Field': HOURLY_PEOPLE,
    }),
  },

  [ACCOUNT_SETTINGS_REMOVE_SKILL_SUCCESS]: {
    getEvent: () => CUSTOM_FIELD_UPDATED,
    getPayload: () => ({
      'Field Name': SKILL_SET,
      'Field Type': MULTI_SELECT,
      isMandatory: true,
      isPrivate: false,
      'Project/People Field': HOURLY_PEOPLE,
    }),
  },

  [ACCOUNT_SETTINGS_REMOVE_PERSON_FIELDS_SUCCESS]: {
    getEvent: () => CUSTOM_FIELD_DELETED,
    getPayload: (state, action) => {
      const { personFields } = state.accountSettings;
      const deletedField = personFields.find(({ id }) => action.data.indexOf(id) !== -1);
      const { name, type, isRequired, isPrivate } = deletedField || {};

      return {
        'Field Name': name,
        'Field Type': type,
        'Is Mandatory': isRequired,
        'Is Private': isPrivate,
        'Project/People Field': PEOPLE,
      };
    },
  },

  [ACCOUNT_SETTINGS_REMOVE_HOURLY_PERSON_FIELDS_SUCCESS]: {
    getEvent: () => CUSTOM_FIELD_DELETED,
    getPayload: (state, action) => {
      const { hourlyPersonFields } = state.accountSettings;
      const deletedField = hourlyPersonFields.find(({ id }) => action.data.indexOf(id) !== -1);
      const { name, type, isRequired, isPrivate } = deletedField || {};

      return {
        'Field Name': name,
        'Field Type': type,
        'Is Mandatory': isRequired,
        'Is Private': isPrivate,
        'Project/People Field': HOURLY_PEOPLE,
      };
    },
  },

  [ACCOUNT_SETTINGS_REMOVE_PROJECT_FIELDS_SUCCESS]: {
    getEvent: () => CUSTOM_FIELD_DELETED,
    getPayload: (state, action) => {
      const { projectFields } = state.accountSettings;
      const deletedField = projectFields.find(({ id }) => action.data.indexOf(id) !== -1);
      const { name, type, isRequired, isPrivate } = deletedField || {};
      return {
        'Field Name': name,
        'Field Type': type,
        'Is Mandatory': isRequired,
        'Is Private': isPrivate,
        'Project/People Field': PROJECT,
      };
    },
  },

  [ACCOUNT_SETTINGS_ADD_PERSON_FIELDS_SUCCESS]: {
    getEvent: () => CUSTOM_FIELD_ADDED,
    getPayload: (state, action) => {
      const { data } = action;
      const { name, type, isRequired, isPrivate } = combineParams(data);

      return {
        'Field Name': name.join(', '),
        'Field Type': type.join(', '),
        'Is Mandatory': isRequired.join(', '),
        'Is Private': isPrivate.join(', '),
        'Project/People Field': PEOPLE,
      };
    },
  },

  [ACCOUNT_SETTINGS_ADD_HOURLY_PERSON_FIELDS_SUCCESS]: {
    getEvent: () => CUSTOM_FIELD_ADDED,
    getPayload: (state, action) => {
      const { data } = action;
      const { name, type, isRequired, isPrivate } = combineParams(data);

      return {
        'Field Name': name.join(', '),
        'Field Type': type.join(', '),
        'Is Mandatory': isRequired.join(', '),
        'Is Private': isPrivate.join(', '),
        'Project/People Field': HOURLY_PEOPLE,
      };
    },
  },

  [ACCOUNT_SETTINGS_UPDATE_PERSON_FIELDS_SUCCESS]: {
    getEvent: () => CUSTOM_FIELD_UPDATED,
    getPayload: (state, action) => {
      const { name, type, isRequired, isPrivate } = action.data?.[0] || {};
      return {
        'Field Name': name,
        'Field Type': type,
        'Is Mandatory': isRequired,
        'Is Private': isPrivate,
        'Project/People Field': PEOPLE,
      };
    },
  },

  [ACCOUNT_SETTINGS_UPDATE_HOURLY_PERSON_FIELDS_SUCCESS]: {
    getEvent: () => CUSTOM_FIELD_UPDATED,
    getPayload: (state, action) => {
      const { name, type, isRequired, isPrivate } = action.data?.[0] || {};
      return {
        'Field Name': name,
        'Field Type': type,
        'Is Mandatory': isRequired,
        'Is Private': isPrivate,
        'Project/People Field': HOURLY_PEOPLE,
      };
    },
  },

  [ACCOUNT_SETTINGS_ADD_PROJECT_FIELDS_SUCCESS]: {
    getEvent: () => CUSTOM_FIELD_ADDED,
    getPayload: (state, action) => {
      const { data } = action;
      const { name, type, isRequired, isPrivate } = combineParams(data);

      return {
        'Field Name': name.join(', '),
        'Field Type': type.join(', '),
        'Is Mandatory': isRequired.join(', '),
        'Is Private': isPrivate.join(', '),
        'Project/People Field': PROJECT,
      };
    },
  },

  [ACCOUNT_SETTINGS_UPDATE_PROJECT_FIELDS_SUCCESS]: {
    getEvent: () => CUSTOM_FIELD_UPDATED,
    getPayload: (state, action) => {
      const { name, type, isRequired, isPrivate } = action.data?.[0] || {};
      return {
        'Field Name': name,
        'Field Type': type,
        'Is Mandatory': isRequired,
        'Is Private': isPrivate,
        'Project/People Field': PROJECT,
      };
    },
  },

  [ACCOUNT_SETTINGS_ADD_PURSUIT_TRACKING_MODULE_SUCCESS]: {
    getEvent: () => PURSUIT_TRACKING_ENABLED,
    getPayload: ({ accounts }, action) => {
      const { accountId } = action;
      const { entities } = accounts;
      const accountName = entities?.find(({ id }) => id === accountId)?.name || '';

      return {
        'Account ID': accountId,
        'Account Name': accountName,
      };
    },
  },

  [ACCOUNT_SETTINGS_REMOVE_PURSUIT_TRACKING_MODULE_SUCCESS]: {
    getEvent: () => PURSUIT_TRACKING_DISABLED,
    getPayload: ({ accounts }, action) => {
      const { accountId } = action;
      const { entities } = accounts;
      const accountName = entities?.find(({ id }) => id === accountId)?.name || '';

      return {
        'Account ID': accountId,
        'Account Name': accountName,
      };
    },
  },

  [ACCOUNT_SETTINGS_ADD_PHASE_SUCCESS]: {
    getEvent: () => PHASE_CREATED,
    getPayload: (state, action) => ({
      Type: PHASE,
      'Phase Name': action.data.name,
    }),
  },

  [ACCOUNT_SETTINGS_ADD_SUB_PHASE_SUCCESS]: {
    getEvent: () => PHASE_CREATED,
    getPayload: (state, action) => ({
      Type: SUB_PHASE,
      'Phase Name': action.data.name,
    }),
  },

  [ACCOUNT_SETTINGS_DELETE_PHASE_SUCCESS]: {
    getEvent: () => PHASE_DELETED,
    getPayload: () => ({ Type: PHASE }),
  },

  [ACCOUNT_SETTINGS_DELETE_SUB_PHASE_SUCCESS]: {
    getEvent: () => PHASE_DELETED,
    getPayload: () => ({ Type: SUB_PHASE }),
  },

  [ACCOUNT_SETTINGS_UPDATE_PHASE_SUCCESS]: {
    getEvent: () => PHASE_EDITED,
    getPayload: () => ({ Type: PHASE }),
  },

  [ACCOUNT_SETTINGS_UPDATE_PHASES_SUCCESS]: {
    getEvent: () => PHASES_REORDERED,
    getPayload: () => ({ Type: PHASE }),
  },

  [ACCOUNT_SETTINGS_UPDATE_SUB_PHASE_SUCCESS]: {
    getEvent: () => PHASE_EDITED,
    getPayload: () => ({ Type: SUB_PHASE }),
  },

  [ACCOUNT_SETTINGS_UPDATE_SUB_PHASES_SUCCESS]: {
    getEvent: () => PHASES_REORDERED,
    getPayload: () => ({ Type: SUB_PHASE }),
  },

  [ACCOUNT_SETTINGS_UPDATE_ACCOUNT_MODULE_SUCCESS]: {
    getEvent: () => MODULE_COMPONENT_UPDATED,
    getPayload: (state, action) => {
      const { data, response } = action;
      const { name } = response;

      // Currently, there are no bulk changes with this action so we just send the first change listed
      const componentName = Object.keys(data)[0];

      return {
        Module: name,
        'Component name': componentName,
        'Update made': data[componentName],
      };
    },
  },

  [ACCOUNT_SETTINGS_ADD_CERTIFICATION_SUCCESS]: {
    getEvent: () => CERTIFICATION_CREATED,
    getPayload: (_, action) => {
      const { name, abbreviation, requireExpiration, daysWarnBeforeExpire } = action.data;

      return {
        'Certification title': name,
        'Certification abbreviation': abbreviation,
        'Expiration date required': requireExpiration,
        'Warning range': daysWarnBeforeExpire !== null,
        '# of days before warning': daysWarnBeforeExpire,
      };
    },
  },

  [ACCOUNT_SETTINGS_UPDATE_CERTIFICATION_SUCCESS]: {
    getEvent: () => CERTIFICATION_EDITED,
    getPayload: (state, action) => {
      const {
        data: { name, abbreviation, requireExpiration, daysWarnBeforeExpire },
        certificationId,
      } = action;
      const prev = state.accountSettings.certifications.find(cert => cert.id === certificationId);

      // Track only changed properties
      return {
        ...(name !== prev.name)
          && { 'Certification title': name },
        ...(abbreviation !== prev.abbreviation)
          && { 'Certification abbreviation': abbreviation },
        ...(requireExpiration !== prev.requireExpiration)
          && { 'Expiration date required': requireExpiration },
        ...(daysWarnBeforeExpire !== prev.daysWarnBeforeExpire)
          && { 'Warning range': daysWarnBeforeExpire !== null },
        ...(daysWarnBeforeExpire !== prev.daysWarnBeforeExpire)
          && { '# of days before warning': daysWarnBeforeExpire },
      };
    },
  },

  [ACCOUNT_SETTINGS_DELETE_CERTIFICATION_SUCCESS]: {
    getEvent: () => CERTIFICATION_DELETED,
    getPayload: (state, action) => {
      const { data: certificationId } = action;
      const { name } = state.accountSettings.certifications.find(
        ({ id }) => id === certificationId,
      );

      return {
        'Certification title': name,
      };
    },
  },
};

export default events;
