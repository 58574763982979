/* eslint-disable import/no-extraneous-dependencies */

import { ApolloClient } from 'apollo-client';
import { from } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { buildGraphQlUri } from '../utils/browser-utils';
import { getAuthHeader } from '../utils/cookieUtils';
import { logRollbar } from './analyticsHelper';

class GraphAPIClient {
  constructor() {
    const httpLink = createHttpLink({
      uri: buildGraphQlUri(),
    });

    const errorHandler = onError(({ operation, response, graphQLErrors, networkError }) => {
      const err = {
        operation,
        response,
        graphQLErrors,
        networkError,
      };

      logRollbar('GraphQL error', err);
    });

    const authLink = setContext((_, { headers }) => {
      const newHeaders = { headers: { ...headers } };

      newHeaders.headers.Authorization = getAuthHeader();

      return newHeaders;
    });

    const defaultOptions = {
      query: {
        fetchPolicy: 'no-cache',
      },
    };

    this.client = new ApolloClient({
      link: from([
        authLink,
        errorHandler,
        httpLink,
      ]),
      cache: new InMemoryCache(),
      defaultOptions,
    });
  }
}

const graphApiClient = new GraphAPIClient();

export default graphApiClient.client;
