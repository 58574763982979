import { gql } from 'apollo-boost';
import { generateRequestFilter, generateRequestSort } from 'src/filters/graphqlUtils';
import { PROJECT_SCHEMAS } from 'src/common/descriptors/project';
import { MAX_SIZE } from 'src/common/constants';
import { FILTER_UNPLANNED_PROJECTS } from '../redux/constants';
import { getFilterIssues } from '../../filters/utils/filterUtils';
import { ALL_ISSUES, FILTER_ISSUES } from '../../../filters/constants';

const UNFILLED_ROLES_QUERY = gql`
  query unfilledRolesQuery($accountId: Int!, $structuredSort: [ProjectSort], $from: Int, $size: Int, $filters: [ESQuery], $filterIssues: Int){
    projects(accountId: $accountId, structuredSort: $structuredSort, from: $from, size: $size, filters: $filters, filterIssues: $filterIssues ) {
      hasMore
      cursor
      count
      items {
        id
        name
        colour
        roles {
          id
          name
          startDate
          endDate
          isFilled
          sortOrder
          note
          requirements {
            startDate
            endDate
            allocatedPercent: percent
          }
          unfilledRanges {
            startDate
            endDate
          }
        }
      }
    }
  }
`;

export const getUnfilledRolesQuery = (accountId, queries = {}, pageFrom, pageSize) => {
  const defaults = {
    size: pageSize || MAX_SIZE,
  };

  const defaultSort = [{
    name: { order: 'asc' },
  }];

  const { filter, sort } = queries;
  const hasFilterQuery = filter && filter.args;

  const filterIssues = hasFilterQuery ? getFilterIssues(filter.args) : ALL_ISSUES;
  const filteredQuery = hasFilterQuery ? { ...filter, args: filter.args.filter(arg => arg.filterType !== FILTER_ISSUES) } : {};

  const filters = generateRequestFilter(filteredQuery, PROJECT_SCHEMAS).concat(FILTER_UNPLANNED_PROJECTS);
  const structuredSort = generateRequestSort(sort, PROJECT_SCHEMAS, defaultSort);

  const variables = { accountId, filters, filterIssues, structuredSort, from: pageFrom, ...defaults };

  return {
    query: UNFILLED_ROLES_QUERY,
    variables,
  };
};
