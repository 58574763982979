import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  DATE_EDIT_ACTIONS,
  START_DATE,
  END_DATE,
} from 'src/common/constants';
import { dateStringAdd } from 'src/utils/dateUtils';
import { fitSegmentsToRange } from 'src/utils/dateSegmentUtils';
import { PERM_WRITE, PERM_ROLE } from 'src/features/permissions/utils/constants';
import { isAuthorized } from 'src/features/permissions/utils/permissionUtils';
import {
  EDITABLE_SEGMENT_TYPES,
  SEGMENT_TYPE_PHASE,
  SEGMENT_TYPE_PROJECT,
  SEGMENT_TYPE_ROLE,
} from './common/constants';
import { SegmentGroup, DateModalControls } from '.';
import { setEditorSegments } from './redux/actions';
import {
  getAffectedPhaseEntities,
  getGroupData,
  getSingleSegmentDates,
  extendingByEndDate,
} from './utils/dateEditorUtils';
import { updateProjectDuration } from '../projects/redux/updateProjectDuration';

const PhaseEditor = ({
  accountId,
  project,
  newStartDate,
  newEndDate,
  boundingStartDate,
  boundingEndDate,
  isSaving,
  onClose,
  setEditorSegments,
  phaseId,
  trackDateEdit,
  updateProjectDuration,
  segments,
  permissions,
  editAction,
  dateUnderEdit,
  offset,
}) => {
  const isSavingRef = useRef(isSaving);

  useEffect(() => {
    const updatedPhase = { id: phaseId, startDate: newStartDate, endDate: newEndDate };
    const editorSegments = getAffectedPhaseEntities(project, updatedPhase);

    const sortedSegments = {
      ...editorSegments,
    };

    setEditorSegments(sortedSegments);
  }, [newEndDate, newStartDate, phaseId, project, setEditorSegments]);

  // Close the modal after saving the changes.
  useEffect(() => {
    if (isSavingRef.current && !isSaving) {
      onClose();
    }

    isSavingRef.current = isSaving;
  }, [isSaving, onClose]);

  const onSave = () => {
    const {
      selectedSegments: selectedPhases,
      totalCount: phaseCount,
      updatedCount: updatedPhaseCount,
      deleteCount: deletedPhaseCount,
    } = getGroupData(segments?.[SEGMENT_TYPE_PHASE]);

    const {
      selectedSegments: selectedRoles,
      totalCount: roleCount,
      updatedCount: updatedRoleCount,
      deleteCount: deletedRoleCount,
    } = getGroupData(segments?.[SEGMENT_TYPE_ROLE]);

    const { startDate: projectStart, endDate: projectEnd, checked: projectChecked } = segments?.[SEGMENT_TYPE_PROJECT]?.[0];

    const updatedProjectEnd = extendingByEndDate(editAction, dateUnderEdit) && projectChecked
      ? dateStringAdd(projectEnd, offset)
      : projectEnd;

    const hasRolePerms = isAuthorized(accountId, permissions, PERM_WRITE, PERM_ROLE);

    let phaseData = [];
    let roleData = [];

    phaseData = selectedPhases.map((segment) => {
      const { id } = segment;
      const { startDate, endDate } = getSingleSegmentDates(segment, dateUnderEdit, editAction, newEndDate, offset);

      return {
        id,
        startDate,
        endDate,
        expandSubphases: true,
      };
    });

    if (hasRolePerms) {
      roleData = selectedRoles.map((role) => {
        const { id, name } = role;
        const { startDate, endDate } = getSingleSegmentDates(role, dateUnderEdit, editAction, newEndDate, offset);
        const allocations = fitSegmentsToRange(role.requirements, startDate, endDate);

        const formattedAllocations = allocations.map(({ allocatedPercent, startDate, endDate }) => (
          { allocatedPercent, startDate, endDate }
        ));

        return {
          id,
          name,
          startDate,
          endDate,
          expandAllocations: true,
          allocations: formattedAllocations,
        };
      });
    }

    // add primary phase to data
    phaseData.push({
      id: phaseId,
      startDate: newStartDate,
      endDate: newEndDate,
      expandSubphases: true,
    });

    const projectDateImpacted = extendingByEndDate(editAction, dateUnderEdit);
    const projectDateUpdated = projectDateImpacted && projectChecked;

    trackDateEdit(
      phaseCount,
      updatedPhaseCount,
      deletedPhaseCount,
      roleCount,
      updatedRoleCount,
      deletedRoleCount,
      projectDateImpacted,
      projectDateUpdated,
    );

    updateProjectDuration(accountId, project.id, projectStart, updatedProjectEnd, phaseData, roleData);
  };

  const renderGroups = () => {
    if (!boundingStartDate || !boundingEndDate) return null;
    const groups = EDITABLE_SEGMENT_TYPES.map(type => <SegmentGroup key={type} groupType={type} />);

    return (
      <>{groups}</>
    );
  };

  return (
    <div className="edit-dates-phase-editor">
      {renderGroups()}
      <DateModalControls
        pending={isSaving}
        primaryAction={onSave}
        secondaryAction={onClose}
      />
    </div>
  );
};

PhaseEditor.propTypes = {
  accountId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  newStartDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
  newEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
  boundingStartDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
  boundingEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
  isSaving: PropTypes.bool.isRequired,
  setEditorSegments: PropTypes.func.isRequired,
  project: PropTypes.object,
  phaseId: PropTypes.number.isRequired,
  trackDateEdit: PropTypes.func.isRequired,
  updateProjectDuration: PropTypes.func.isRequired,
  segments: PropTypes.object,
  permissions: PropTypes.object.isRequired,
  editAction: PropTypes.oneOf(DATE_EDIT_ACTIONS),
  dateUnderEdit: PropTypes.oneOf([START_DATE, END_DATE]),
  offset: PropTypes.number.isRequired,
};

PhaseEditor.defaultProps = {
  newStartDate: null,
  newEndDate: null,
  boundingStartDate: null,
  boundingEndDate: null,
  project: {},
  segments: {},
  editAction: null,
  dateUnderEdit: null,
};

/* istanbul ignore next */
function mapStateToProps({ common, editDates, projects, login }) {
  const { accountId } = common;
  const { userInfo: { permissions } } = login;
  const {
    newStartDate,
    newEndDate,
    boundingStartDate,
    boundingEndDate,
    primaryId,
    segments,
    editAction,
    dateUnderEdit,
    offset,
  } = editDates;

  const { projectSelections, updateProjectDurationPending } = projects;

  return {
    accountId,
    newStartDate,
    newEndDate,
    boundingStartDate,
    boundingEndDate,
    isSaving: updateProjectDurationPending,
    projectSelections,
    phaseId: primaryId,
    segments,
    permissions,
    editAction,
    dateUnderEdit,
    offset,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    setEditorSegments: bindActionCreators(setEditorSegments, dispatch),
    updateProjectDuration: bindActionCreators(updateProjectDuration, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PhaseEditor);
