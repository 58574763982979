import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { COMMON_PROJECT_DATES_UPDATED, COMMON_PHASE_EDITED_ON_PROJECT } from '../../analytics/common/constants';
import { PhaseEditor, ProjectEditor } from '.';
import { END_DATE, START_DATE } from '../../common/constants';
import {
  COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PROJECT,
  MULTI_STATE_MODAL_ID,
} from '../common/redux/constants';
import { closeModal } from '../modal-manager/redux/actions';
import { trackAnalytics, setModalActiveView } from '../common/redux/actions';
import { SEGMENT_TYPE_PHASE, SEGMENT_TYPE_PROJECT } from './common/constants';

const EditorModalManager = ({
  onModalClose,
}) => {
  const dispatch = useDispatch();

  const {
    editAction = null,
    dateUnderEdit = null,
    parentName = '',
    offset,
    typeUnderEdit,
  } = useSelector(({ editDates }) => editDates);
  const { projectSelections } = useSelector(({ projects }) => projects);
  const { showProjectOnCancel } = useSelector(({ common: { multiStateModal } }) => multiStateModal);

  const project = useMemo(() => (
    projectSelections[MULTI_STATE_MODAL_ID]
  ), [projectSelections]);

  const onClose = useCallback(() => {
    if (showProjectOnCancel) {
      dispatch(setModalActiveView(COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PROJECT));
    } else {
      onModalClose();
      dispatch(closeModal(MULTI_STATE_MODAL_ID));
    }
  }, [dispatch, onModalClose, showProjectOnCancel]);

  const trackDateEdit = useCallback((
    phaseCount,
    selectedPhaseCount,
    deletedPhaseCount,
    roleCount,
    selectedRoleCount,
    deletedRoleCount,
    projectDateImpacted = false,
    projectDateUpdated = false,
  ) => {
    const { name, id, state, type } = project;
    const commonProps = {
      'Project name': name,
      'Project id': id,
      'Project status': state,
      'Project type': type,
      '# of days offset': Math.abs(offset),
      '# of phases impacted': phaseCount,
      '# of phases updated': selectedPhaseCount,
      '# of roles impacted': roleCount,
      '# of roles updated': selectedRoleCount,
    };

    if (typeUnderEdit === SEGMENT_TYPE_PROJECT) {
      dispatch(trackAnalytics(COMMON_PROJECT_DATES_UPDATED, {
        'Start Date Updated': dateUnderEdit === START_DATE,
        'End Date Updated': dateUnderEdit === END_DATE,
        'Project dates updated from': parentName,
        'Impact to project': editAction,
        '# of phases removed': deletedPhaseCount,
        '# of roles removed': deletedRoleCount,
        ...commonProps,
      }));
    } else {
      dispatch(trackAnalytics(COMMON_PHASE_EDITED_ON_PROJECT, {
        Location: parentName,
        'Date edited': dateUnderEdit === START_DATE ? 'Start date' : 'End date',
        Type: 'Phase',
        Impact: editAction,
        'Project date impacted': projectDateImpacted,
        'Project date updated': projectDateUpdated,
        ...commonProps,
      }));
    }
  }, [dateUnderEdit, editAction, offset, parentName, project, typeUnderEdit, dispatch]);

  return useMemo(() => {
    if (typeUnderEdit === SEGMENT_TYPE_PROJECT) {
      return (
        <ProjectEditor
          project={project}
          onClose={onClose}
          trackDateEdit={trackDateEdit}
        />
      );
    }

    if (typeUnderEdit === SEGMENT_TYPE_PHASE) {
      return (
        <PhaseEditor
          project={project}
          onClose={onClose}
          trackDateEdit={trackDateEdit}
        />
      );
    }

    return null;
  }, [onClose, project, trackDateEdit, typeUnderEdit]);
};

EditorModalManager.propTypes = {
  onModalClose: PropTypes.func.isRequired,
};

export default EditorModalManager;
