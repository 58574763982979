import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import BaseThemeSlider from './BaseThemeSlider';
import { getStepNumber } from './utils';

const ThemeSlider = ({ settingName, value, disabled, range, step, onChange, themeName }) => {
  const min = useMemo(() => range[0], [range]);
  const max = useMemo(() => range[1], [range]);

  return (
    <div key={settingName} className="theme-slider-container">
      <Typography gutterBottom key={settingName} className="theme-slider-title">
        {settingName}
      </Typography>
      <BaseThemeSlider
        valueLabelDisplay="auto"
        aria-label={`${settingName} slider`}
        value={value}
        step={step}
        marks
        onChange={onChange}
        min={min}
        max={max}
        disabled={disabled}
        valueLabelFormat={getStepNumber(value, themeName, settingName)}
      />
    </div>
  );
};

ThemeSlider.propTypes = {
  settingName: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  range: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  themeName: PropTypes.string.isRequired,
};

export default ThemeSlider;
