import React from 'react';
import { TableCell, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { g400concreteGrey } from '@bridgit/foundation';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const useStyles = makeStyles({
  root: {
    cursor: 'pointer',
    userSelect: 'none',
    color: g400concreteGrey,
    lineHeight: 'normal',
  },
  cellContainer: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '30px',
  },
  cellTitle: {
    flex: '1',
    lineHeight: 'normal',
  },
});

const TableHeaderCell = ({
  className,
  classes,
  column,
  children,
  ...otherProps
}) => {
  const customClasses = useStyles({ classes });

  return (
    <TableCell
      className={classNames(className, customClasses.root)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    >
      <div className={customClasses.cellContainer}>
        <Typography
          className={customClasses.cellTitle}
          variant="subtitle2"
        >
          {column.name}
        </Typography>
        {children}
      </div>
    </TableCell>
  );
};

TableHeaderCell.propTypes = {
  column: PropTypes.object.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object,
  children: PropTypes.node,
};

TableHeaderCell.defaultProps = {
  className: undefined,
  classes: undefined,
  children: undefined,
};

export default TableHeaderCell;
