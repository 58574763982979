import { getFiltersInfo } from 'src/features/filters/utils/filterUtils';
import { capitalize } from 'src/utils/formatters';
import {
  QUERIES_ADD_SAVED_FILTER_SUCCESS,
} from '../../features/queries/redux/constants';
import {
  SAVED_FILTER_ADDED,
} from './constants';

const events = {
  [QUERIES_ADD_SAVED_FILTER_SUCCESS]: {
    getEvent: () => SAVED_FILTER_ADDED,
    getPayload: (state, { data, analyticsPayload }) => {
      const { name, type, predicates } = data;
      const { view } = analyticsPayload;
      const { properties, values, valueCount } = getFiltersInfo(JSON.parse(predicates));

      return {
        'Saved filter name': name,
        'Saved filter type': type,
        'Filter property count': properties.length,
        'Filter properties': properties.join(', '),
        'Filter value count': valueCount,
        'Filter values': values,
        'View added from': capitalize(view),
      };
    },
  },
};

export default events;
