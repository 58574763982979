import {
  TABLE_SET_TABLE_CONFIG,
} from './constants';
import { getValidatedLocalStorage } from '../../../utils/validators';

export function setTableConfig(config, columnStorageKey, columnOrderStorageKey) {
  const localVisibleColumns = getValidatedLocalStorage(columnStorageKey);
  const visibleColumns = getValidatedLocalStorage(columnOrderStorageKey);

  const newTableConfig = {
    defaultVisible: localVisibleColumns ? localVisibleColumns.visibleColumns : config.defaultVisible || [],
    neverVisible: config.neverVisible || [],
    alwaysVisible: config.alwaysVisible || [],
  };

  if (visibleColumns) {
    newTableConfig.visibleColumns = visibleColumns;
  }

  return {
    type: TABLE_SET_TABLE_CONFIG,
    data: newTableConfig,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case TABLE_SET_TABLE_CONFIG:
      return {
        ...state,
        ...action.data,
        visibleColumnNames: [...action.data.defaultVisible],
      };

    default:
      return state;
  }
}
