import { all, put, takeLatest } from 'redux-saga/effects';
import { MAX_SIZE, MIN_FROM } from '../../../common/constants';
import { doGetFilteredProjects } from '../../projects/redux/getFilteredProjects';
import { doGetExpandedAllocations } from '../../projects/redux/getExpandedAllocations';
import { doGetPeople } from '../../people/redux/getPeople';
import {
  REPORTS_COLLECT_PROJECT_GANTT_DATA_BEGIN,
  REPORTS_COLLECT_PROJECT_GANTT_DATA_DISMISS_ERROR,
  REPORTS_COLLECT_PROJECT_GANTT_DATA_FAILURE,
  REPORTS_COLLECT_PROJECT_GANTT_DATA_SUCCESS,
} from './constants';
import { getProjectListQuery } from '../../projects/queries/getProjectListQuery';

export function collectProjectGanttData(accountId, queries, data) {
  const { roleSortOption, roleFilters, permissions } = data;
  const { query, variables } = getProjectListQuery(accountId, queries, MIN_FROM, MAX_SIZE, roleSortOption, roleFilters, permissions);

  return {
    type: REPORTS_COLLECT_PROJECT_GANTT_DATA_BEGIN,
    accountId,
    query,
    variables,
  };
}

export function dismissCollectProjectGanttDataError() {
  return {
    type: REPORTS_COLLECT_PROJECT_GANTT_DATA_DISMISS_ERROR,
  };
}

export function* doCollectProjectGanttData(action) {
  const { accountId, query, variables } = action;

  try {
    yield all([
      doGetFilteredProjects({ query, variables }),
      doGetExpandedAllocations({ accountId }),
      doGetPeople({ accountId }),
    ]);
  } catch (err) {
    yield put({
      type: REPORTS_COLLECT_PROJECT_GANTT_DATA_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({ type: REPORTS_COLLECT_PROJECT_GANTT_DATA_SUCCESS });
}

export function* watchCollectProjectGanttData() {
  yield takeLatest(REPORTS_COLLECT_PROJECT_GANTT_DATA_BEGIN, doCollectProjectGanttData);
}

export function reducer(state, action) {
  switch (action.type) {
    case REPORTS_COLLECT_PROJECT_GANTT_DATA_BEGIN:
      return {
        ...state,
        collectProjectGanttDataPending: true,
        collectProjectGanttDataError: null,
      };

    case REPORTS_COLLECT_PROJECT_GANTT_DATA_SUCCESS:
      return {
        ...state,
        collectProjectGanttDataPending: false,
        collectProjectGanttDataError: null,
      };

    case REPORTS_COLLECT_PROJECT_GANTT_DATA_FAILURE:
      return {
        ...state,
        collectProjectGanttDataPending: false,
        collectProjectGanttDataError: action.data.error,
      };

    case REPORTS_COLLECT_PROJECT_GANTT_DATA_DISMISS_ERROR:
      return {
        ...state,
        collectProjectGanttDataError: null,
      };

    default:
      return state;
  }
}
