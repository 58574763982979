import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { DATE_INPUT_FORMAT, DATE_DISPLAY_FORMAT } from 'src/common/constants';
import { NumberInput } from '../wrapped-components';
import { BREAKDOWN_MONTHLY } from './redux/constants';

const PeriodicRequirementRow = React.memo(({
  startDate,
  endDate,
  allocatedPercent,
  onUpdate,
  breakdownType,
  name,
}) => {
  const initialRequirement = {
    startDate,
    endDate,
    allocatedPercent,
    name,
  };

  const updateRequirement = (updatedPercent) => {
    onUpdate(initialRequirement, { ...initialRequirement, allocatedPercent: updatedPercent });
  };

  const getFormattedDateString = () => {
    if (breakdownType === BREAKDOWN_MONTHLY) {
      return moment(startDate, DATE_INPUT_FORMAT).format('MMMM YYYY');
    }
    return `${moment(startDate, DATE_INPUT_FORMAT).format(DATE_DISPLAY_FORMAT)} 
            - ${moment(endDate, DATE_INPUT_FORMAT).format(DATE_DISPLAY_FORMAT)}`;
  };

  return (
    <div className="allocations-periodic-requirement-row">
      <div className="time">{getFormattedDateString()}</div>
      { name && (
        <div className={classNames('name', { none: name === 'None' })}>
          {name}
        </div>
      )}
      <div className="requirement-input">
        <NumberInput
          className="requirement-picker"
          value={allocatedPercent}
          onValueChanged={updateRequirement}
          minValue={0}
        />
      </div>
    </div>
  );
});

PeriodicRequirementRow.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  allocatedPercent: PropTypes.number.isRequired,
  onUpdate: PropTypes.func.isRequired,
  breakdownType: PropTypes.string.isRequired,
  name: PropTypes.string,
};

PeriodicRequirementRow.defaultProps = {
  name: '',
};

export default PeriodicRequirementRow;
