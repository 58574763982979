import {
  SELF_PERFORM_CLEAR_SELECTED_REQUEST,
} from './constants';

export function clearSelectedRequest() {
  return {
    type: SELF_PERFORM_CLEAR_SELECTED_REQUEST,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case SELF_PERFORM_CLEAR_SELECTED_REQUEST:
      return {
        ...state,
        selectedRequest: null,
      };

    default:
      return state;
  }
}
