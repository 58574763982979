import { COMMON_TOGGLE_MODAL } from './constants';

export function toggleModal(data) {
  return {
    type: COMMON_TOGGLE_MODAL,
    data,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_TOGGLE_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen,
        editingUser: action.data,
      };

    default:
      return state;
  }
}
