const AUTOMATION_ADD_FILTER_BUTTON = 'AUTOMATION_ADD_FILTER_BUTTON';
const AUTOMATION_FILTER_MENU_BUTTON = 'AUTOMATION_FILTER_MENU_BUTTON';
const AUTOMATION_FILTER_MENU_BUTTON_ADD_FILTER = 'AUTOMATION_FILTER_MENU_BUTTON_addFilter';
const AUTOMATION_FILTER_COUNTER_ID_PREFIX = 'AUTOMATION_FILTER_COUNTER_';
const AUTOMATION_PEOPLE_FILTER_MENU_BUTTON = 'AUTOMATION_PEOPLE_FILTER_MENU_BUTTON';
const AUTOMATION_PROJECT_FILTER_MENU_BUTTON = 'AUTOMATION_PROJECT_FILTER_MENU_BUTTON';

export {
  AUTOMATION_ADD_FILTER_BUTTON,
  AUTOMATION_FILTER_MENU_BUTTON_ADD_FILTER,
  AUTOMATION_FILTER_MENU_BUTTON,
  AUTOMATION_FILTER_COUNTER_ID_PREFIX,
  AUTOMATION_PEOPLE_FILTER_MENU_BUTTON,
  AUTOMATION_PROJECT_FILTER_MENU_BUTTON,
};
