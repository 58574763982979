import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import shortid from 'shortid';
import { getNonOverlapping } from 'src/utils/dateSegmentUtils';
import { quickFilterValues } from 'src/features/gantt/utils/peopleGanttUtils';
import {
  PeopleGanttAvatar,
  GanttBarRow,
  PeopleGanttPopper,
} from 'src/features/gantt';
import { setSelectedProjectId } from 'src/features/common/redux/actions';
import { openModal } from 'src/features/modal-manager/redux/actions';
import { MULTI_STATE_MODAL_ID } from 'src/features/common/redux/constants';
import {
  PEOPLE_VIEW_SETTINGS_UTILIZATION,
  PEOPLE_VIEW_SETTINGS_PROJECTS,
  PEOPLE_VIEW_SETTINGS_FULL_COLOR,
} from 'src/features/gantt/people-gantt/constants';
import { PEOPLE_GANTT_CONFIG_KEY } from 'src/features/gantt/redux/constants';
import { DATE_DISPLAY_FORMAT } from 'src/common/constants';
import { LOST, PURSUIT, CANCELED } from 'src/features/projects/constants';
import { GREY, BLACK, utils } from '@bridgit/foundation';
import { naturalSort } from 'src/utils/sortUtils';

const { convertHexToRGB } = utils;

function PeopleGanttRow({
  person,
  showModal,
  zoomConfig,
  ganttDisplay,
  colorOption,
  queries,
  setSelectedProjectId,
  openModal,
  dates,
  columnWidth,
}) {
  const filterValues = useMemo(() => quickFilterValues(queries.filter), [queries]);
  const isFiltered = filterValues.availableFilter || filterValues.issuesFilter;
  const projectDisplay = ganttDisplay === PEOPLE_VIEW_SETTINGS_PROJECTS;
  const utilizationDisplay = ganttDisplay === PEOPLE_VIEW_SETTINGS_UTILIZATION;

  const allocations = useMemo(() => {
    const { allocationGroups } = person;
    const { availableFilter, issuesFilter } = filterValues;

    if (projectDisplay) return [];

    return allocationGroups.filter(({ isAvailable, isOverAllocated }) => (!isFiltered
      || (availableFilter && isAvailable)
      || (issuesFilter && isOverAllocated)));
  }, [projectDisplay, person, isFiltered, filterValues]);

  const projectBarRows = useMemo(() => {
    const { projectAllocations, allocationGroups } = person;
    const parsedProjectBarRows = [];

    if (!utilizationDisplay) {
      let allocations = projectAllocations;

      if (projectDisplay) {
        const unavailableBars = allocationGroups.filter(({ isUnavailable }) => isUnavailable);
        // projectAllocations are sorted by graph. If we merge unavailable bars, we need to resort them
        // since getNonOverlapping assumes the data has already been sorted
        if (unavailableBars.length) {
          allocations = naturalSort([...unavailableBars, ...projectAllocations], 'startDate');
        }
      }

      while (allocations.length) {
        const { nonOverlapping, leftovers } = getNonOverlapping(allocations);

        // Ignore "Lost" or "Canceled" projects (Pursuits)
        const nonOverlappingFiltered = nonOverlapping.reduce((filteredNonOverlapping, currentOverlapping) => {
          if (![LOST, CANCELED].includes(currentOverlapping.projectState)) {
            filteredNonOverlapping.push(currentOverlapping);
          }
          return filteredNonOverlapping;
        }, []);

        // Ignore empty arrays, otherwise an empty row will be created
        if (nonOverlappingFiltered.length > 0) {
          parsedProjectBarRows.push(nonOverlappingFiltered);
        }

        allocations = leftovers;
      }
    }
    return parsedProjectBarRows;
  }, [person, utilizationDisplay, projectDisplay]);

  const customPopperContent = useCallback(barData => onClickAway => (
    <PeopleGanttPopper
      allocation={barData}
      person={person}
      onClickAway={onClickAway}
    />
  ), [person]);

  const allocationBarDataMapper = useCallback((barData) => {
    if (barData.isAvailable) return barData;
    return {
      ...barData,
      customPopperContent: customPopperContent(barData),
    };
  }, [customPopperContent]);

  const projectBarDataMapper = useCallback((barData) => {
    const {
      projectId,
      roleId,
      startDate,
      endDate,
      projectName,
      allocatedPercent,
      isUnavailable,
      projectState,
      projectColour,
    } = barData;

    const barStyles = () => {
      const isPursuit = projectState === PURSUIT;

      return {
        projectColor: isPursuit ? convertHexToRGB(projectColour, 0.2) : projectColour,
        borderColor: isPursuit ? BLACK : projectColour,
        borderType: isPursuit ? 'dashed' : 'solid',
        textColor: isPursuit ? BLACK : '',
      };
    };

    const { projectColor, borderColor, borderType, textColor } = barStyles();

    if (isUnavailable) {
      return {
        ...barData,
        customPopperContent: customPopperContent(barData),
      };
    }

    const allocationLabel = typeof allocatedPercent === 'string' && allocatedPercent.toLowerCase() === 'custom' ? 'Custom' : `${allocatedPercent}%`;
    const startDateLabel = moment(startDate).format(DATE_DISPLAY_FORMAT);
    const endDateLabel = moment(endDate).format(DATE_DISPLAY_FORMAT);

    return {
      id: `${projectId}-${roleId}-${startDate}`,
      startDate,
      endDate,
      barColor: colorOption === PEOPLE_VIEW_SETTINGS_FULL_COLOR ? projectColor : GREY,
      borderColor,
      label: `${projectName} | ${allocationLabel} | ${startDateLabel} - ${endDateLabel}`,
      textColor,
      borderType,
    };
  }, [colorOption, customPopperContent]);

  const onProjectBarClick = useCallback(barData => () => {
    // When project bars only setting is selected we will have unavailable bars mixed in with project bars
    if (!barData.isUnavailable) {
      const { projectId, projectName } = barData;
      window.mixpanel.track('Project Details Modal Displayed', {
        'Project Name': projectName,
        'Project ID': projectId,
        'Launched from': 'People Gantt - Project Bar',
      });
      setSelectedProjectId(projectId);
      openModal(MULTI_STATE_MODAL_ID);
    }
  }, [openModal, setSelectedProjectId]);

  return (
    <div className="gantt-people-gantt-row">
      <PeopleGanttAvatar person={person} showModal={showModal} />
      <div className="bar-container">
        {allocations.length > 0 && (
          <div className="row">
            <GanttBarRow
              barData={allocations}
              dates={dates}
              zoomConfig={zoomConfig}
              dataMapper={allocationBarDataMapper}
              columnWidth={columnWidth}
            />
          </div>
        )}
        {!utilizationDisplay && projectBarRows.map(barData => (
          <GanttBarRow
            key={shortid.generate()}
            barData={barData}
            dates={dates}
            zoomConfig={zoomConfig}
            dataMapper={projectBarDataMapper}
            onClick={onProjectBarClick}
            columnWidth={columnWidth}
          />
        ))}
      </div>
    </div>
  );
}

PeopleGanttRow.propTypes = {
  person: PropTypes.object.isRequired,
  showModal: PropTypes.func,
  zoomConfig: PropTypes.object.isRequired,
  ganttDisplay: PropTypes.string.isRequired,
  colorOption: PropTypes.string.isRequired,
  queries: PropTypes.object.isRequired,
  setSelectedProjectId: PropTypes.func,
  openModal: PropTypes.func,
  dates: PropTypes.object.isRequired,
  columnWidth: PropTypes.number,
};

PeopleGanttRow.defaultProps = {
  showModal: () => {},
  setSelectedProjectId: () => {},
  openModal: () => {},
  columnWidth: undefined,
};

/* istanbul ignore next */
function mapStateToProps({ gantt, queries }) {
  const { ganttDisplay, colorOption } = gantt.instances[PEOPLE_GANTT_CONFIG_KEY];
  const { filteredPeople } = queries;

  return {
    ganttDisplay,
    colorOption,
    queries: filteredPeople,
  };
}

const mapDispatchToProps = dispatch => ({
  setSelectedProjectId: bindActionCreators(setSelectedProjectId, dispatch),
  openModal: bindActionCreators(openModal, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PeopleGanttRow);
