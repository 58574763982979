import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNTS_INVITE_USER_BEGIN,
  ACCOUNTS_INVITE_USER_SUCCESS,
  ACCOUNTS_INVITE_USER_FAILURE,
  ACCOUNTS_INVITE_USER_DISMISS_ERROR,
  ACCOUNTS_REINVITE_USER_BEGIN,
  ACCOUNTS_REINVITE_USER_DISMISS_ERROR,
  ACCOUNTS_REINVITE_USER_FAILURE,
  ACCOUNTS_REINVITE_USER_SUCCESS,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';

export function inviteUser(accountId, email, group) {
  return {
    type: ACCOUNTS_INVITE_USER_BEGIN,
    accountId,
    email,
    group,
  };
}

export function reinviteUser(accountId, email, group) {
  return {
    type: ACCOUNTS_REINVITE_USER_BEGIN,
    accountId,
    email,
    group,
  };
}

export function dismissInviteUserError() {
  return {
    type: ACCOUNTS_INVITE_USER_DISMISS_ERROR,
  };
}

export function dismissReinviteUserError() {
  return {
    type: ACCOUNTS_REINVITE_USER_DISMISS_ERROR,
  };
}

export function* doInviteUser(action) {
  const { accountId, email, group } = action;
  let res;
  try {
    res = yield call(protectedJsonPost, `accounts/${accountId}/invitations`, { email, group });
  } catch (err) {
    yield put({
      type: ACCOUNTS_INVITE_USER_FAILURE,
      data: { error: err },
      accountId,
      email,
    });
    return;
  }
  yield put({
    type: ACCOUNTS_INVITE_USER_SUCCESS,
    data: res,
    accountId,
  });
}

export function* doReinviteUser(action) {
  const { accountId, email, group } = action;
  let res;
  try {
    res = yield call(protectedJsonPost, `accounts/${accountId}/invitations`, { email, group });
  } catch (err) {
    yield put({
      type: ACCOUNTS_REINVITE_USER_FAILURE,
      data: { error: err },
      accountId,
      email,
    });
    return;
  }
  yield put({
    type: ACCOUNTS_REINVITE_USER_SUCCESS,
    data: res,
    accountId,
  });
}

export function* watchInviteUser() {
  yield takeLatest(ACCOUNTS_INVITE_USER_BEGIN, doInviteUser);
}

export function* watchReinviteUser() {
  yield takeLatest(ACCOUNTS_REINVITE_USER_BEGIN, doReinviteUser);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNTS_INVITE_USER_BEGIN:
    case ACCOUNTS_REINVITE_USER_BEGIN:
      return {
        ...state,
        inviteUserPending: true,
        inviteUserError: null,
      };

    case ACCOUNTS_REINVITE_USER_SUCCESS:
    case ACCOUNTS_INVITE_USER_SUCCESS: {
      const updatedInvitations = state.invitations[action.accountId].filter(i => i.email !== action.data.email).concat(action.data);
      return {
        ...state,
        invitations: {
          ...state.invitations,
          [action.accountId]: updatedInvitations,
        },
        inviteUserPending: false,
        inviteUserError: null,
      };
    }

    case ACCOUNTS_REINVITE_USER_FAILURE:
    case ACCOUNTS_INVITE_USER_FAILURE: {
      const { email } = action;
      return {
        ...state,
        inviteUserPending: false,
        inviteUserError: {
          ...action.data.error,
          email,
        },
      };
    }

    case ACCOUNTS_REINVITE_USER_DISMISS_ERROR:
    case ACCOUNTS_INVITE_USER_DISMISS_ERROR:
      return {
        ...state,
        inviteUserError: null,
      };

    default:
      return state;
  }
}
