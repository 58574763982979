import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import pluralize from 'pluralize';
import { Obfuscator } from '@bridgit/foundation';
import { getGapDays, getDurationDays } from 'src/utils/dateUtils';
import {
  DATE_INPUT_FORMAT,
  DATE_DISPLAY_FORMAT,
  DEFAULT_POU_START,
  DEFAULT_POU_END,
  TIME_OFF,
  UNAVAILABILITY,
  PRIVATE_MODE_TOOLTIP_TEXT,
} from '../../common/constants';

const BlackoutInfo = ({
  startDate,
  endDate,
  description,
  rangeType,
  showTimeHelperText,
  isPrivate,
}) => {
  const { privateModeEnabled } = useSelector(({ common }) => common);

  const getFormattedDate = dateString => (
    dateString
    && dateString.length
    && dateString !== DEFAULT_POU_START
    && dateString !== DEFAULT_POU_END
      ? moment(dateString, DATE_INPUT_FORMAT).format(DATE_DISPLAY_FORMAT)
      : ''
  );

  const startFormatted = getFormattedDate(startDate);
  const endFormatted = getFormattedDate(endDate);

  const blackoutHelperText = useMemo(() => {
    if (!showTimeHelperText) return `${rangeType === TIME_OFF ? 'No backfill' : 'Backfill'} required`;
    const today = moment().startOf('day');
    if (moment(startDate).isAfter(today)) {
      return `Starts in ${pluralize('day', getGapDays(today, startDate), true)}`;
    }
    return `Ends in ${pluralize('day', getDurationDays(today, endDate), true)}`;
  }, [endDate, rangeType, showTimeHelperText, startDate]);

  const verifiedDescription = description?.length ? description : 'Unavailable';

  return (
    <div className="allocations-blackout-info">
      <div className="blackout-details">
        <Obfuscator
          isEnabled={isPrivate && privateModeEnabled}
          label="Hidden description"
          tooltip={PRIVATE_MODE_TOOLTIP_TEXT}
        >
          <span className="description">{verifiedDescription}</span>
        </Obfuscator>
        <span className="timeframe">{`${startFormatted} - ${endFormatted}`}</span>
      </div>
      <span className="blackout-helper-text">
        {blackoutHelperText}
      </span>
    </div>
  );
};

BlackoutInfo.propTypes = {
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]).isRequired,
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]).isRequired,
  description: PropTypes.string,
  rangeType: PropTypes.oneOf([UNAVAILABILITY, TIME_OFF]).isRequired,
  showTimeHelperText: PropTypes.bool,
  isPrivate: PropTypes.bool,
};

BlackoutInfo.defaultProps = {
  description: '',
  showTimeHelperText: false,
  isPrivate: false,
};

export default BlackoutInfo;
