import React from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener, Button } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { trackTimeOffClick } from 'src/common/analyticsHelper';
import { CustomPopper } from '../wrapped-components';
import { BlackoutInfo } from '.';

const AllocationTimeOffPopper = ({
  onPopperClose,
  open,
  target,
  allocation,
  runsInModal,
}) => {
  if (open) {
    const { personId, name } = allocation;
    trackTimeOffClick(
      name,
      personId,
      runsInModal ? 'Project modal' : 'Project profile',
      null,
      true,
    );
  }

  return (
    <div className="allocations-allocation-time-off-popper">
      <ClickAwayListener
        onClickAway={onPopperClose}
        mouseEvent={open ? 'onClick' : false}
      >
        <div>
          <CustomPopper
            anchorEl={target}
            popperOpen={open}
            placement="bottom-end"
            classes="time-off-popper"
            hideArrow
          >
            <div className="time-off-popper-content">
              <Button
                size="small"
                aria-label="Close"
                className="popper-close-button"
                color="inherit"
                disableRipple
                onClick={onPopperClose}
              >
                <Close className="icon-close" />
              </Button>
              {allocation?.upcomingTimeOff?.map(timeOff => (
                <BlackoutInfo
                  key={timeOff?.id}
                  startDate={timeOff?.startDate}
                  endDate={timeOff?.endDate}
                  description={timeOff?.description}
                  rangeType={timeOff?.rangeType}
                  isPrivate={timeOff?.isPrivate}
                  showTimeHelperText
                />
              ))}
            </div>
          </CustomPopper>
        </div>
      </ClickAwayListener>
    </div>
  );
};

AllocationTimeOffPopper.propTypes = {
  onPopperClose: PropTypes.func,
  open: PropTypes.bool,
  target: PropTypes.object,
  allocation: PropTypes.object,
  runsInModal: PropTypes.bool,
};

AllocationTimeOffPopper.defaultProps = {
  onPopperClose: () => {},
  open: false,
  target: null,
  allocation: {},
  runsInModal: false,
};

export default AllocationTimeOffPopper;
