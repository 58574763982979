import { LoadableAccounts } from './index';

export default {
  path: '/',
  name: 'Accounts',
  childRoutes: [
    {
      path: 'accounts',
      name: 'Accounts',
      component: LoadableAccounts,
      isIndex: true,
    },
  ],
};
