import React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, TextField } from '@material-ui/core';
import { Clear, Search } from '@material-ui/icons';
import classNames from 'classnames';

export default function MultiSearch({
  placeholder,
  searchString,
  onClearSearch,
  onSearch,
  isSelectListSearch,
}) {
  const onSearchChange = (e) => {
    const searchString = e.currentTarget.value;

    if (searchString) {
      onSearch(searchString);
    } else {
      onClearSearch();
    }
  };

  const searchWrapClass = classNames('multiselect-multi-search',
    { 'select-list-search': isSelectListSearch });

  return (
    <div className={searchWrapClass}>
      <TextField
        placeholder={placeholder}
        fullWidth
        onChange={onSearchChange}
        value={searchString}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search className="search-icon" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {searchString.length > 0 && (
              <Clear className="filter-clear" onClick={onClearSearch} />
              )}
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}

MultiSearch.propTypes = {
  placeholder: PropTypes.string,
  onClearSearch: PropTypes.func.isRequired,
  searchString: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  isSelectListSearch: PropTypes.bool.isRequired,
};

MultiSearch.defaultProps = {
  placeholder: '',
  searchString: '',
};
