import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PROJECTS_ADD_PROJECT_SUB_PHASE_BEGIN,
  PROJECTS_ADD_PROJECT_SUB_PHASE_SUCCESS,
  PROJECTS_ADD_PROJECT_SUB_PHASE_FAILURE,
  PROJECTS_ADD_PROJECT_SUB_PHASE_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';
import { addSubPhaseToProject } from '../utils/projectUtils';

export function addProjectSubPhase(accountId, projectId, phase, subPhase, analyticsPayload = { }) {
  return {
    type: PROJECTS_ADD_PROJECT_SUB_PHASE_BEGIN,
    accountId,
    projectId,
    phase,
    subPhase,
    analyticsPayload,
  };
}

export function dismissAddProjectSubPhaseError() {
  return {
    type: PROJECTS_ADD_PROJECT_SUB_PHASE_DISMISS_ERROR,
  };
}

export function* doAddProjectSubPhase(action) {
  const { accountId, projectId, phase, subPhase, analyticsPayload } = action;

  try {
    yield call(protectedJsonPost, `accounts/${accountId}/projects/${projectId}/phases/${phase.id}/subphases`, subPhase);
  } catch (err) {
    yield put({
      type: PROJECTS_ADD_PROJECT_SUB_PHASE_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PROJECTS_ADD_PROJECT_SUB_PHASE_SUCCESS,
    data: subPhase,
    projectId,
    phase,
    analyticsPayload,
  });
}

export function* watchAddProjectSubPhase() {
  yield takeLatest(PROJECTS_ADD_PROJECT_SUB_PHASE_BEGIN, doAddProjectSubPhase);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PROJECTS_ADD_PROJECT_SUB_PHASE_BEGIN:
      return {
        ...state,
        addProjectSubPhasePending: true,
        addProjectSubPhaseError: null,
      };

    case PROJECTS_ADD_PROJECT_SUB_PHASE_SUCCESS: {
      const { data, projectId, phase } = action;
      const { filteredProjects, projectSelections } = state;

      const newFilteredProjects = filteredProjects.map((project) => {
        if (project.id === projectId) {
          return addSubPhaseToProject(project, phase, data);
        }
        return project;
      });

      const newProjectSelections = { ...projectSelections };
      Object.keys(newProjectSelections).forEach((selectionId) => {
        const selectedProject = newProjectSelections[selectionId];

        if (selectedProject?.id === projectId) {
          const updatedProject = addSubPhaseToProject(selectedProject, phase, data);

          newProjectSelections[selectionId] = {
            ...updatedProject,
          };
        }
      });

      return {
        ...state,
        filteredProjects: newFilteredProjects,
        addProjectSubPhasePending: false,
        addProjectSubPhaseError: null,
        projectSelections: newProjectSelections,
      };
    }

    case PROJECTS_ADD_PROJECT_SUB_PHASE_FAILURE:
      return {
        ...state,
        addProjectSubPhasePending: false,
        addProjectSubPhaseError: action.data.error,
      };

    case PROJECTS_ADD_PROJECT_SUB_PHASE_DISMISS_ERROR:
      return {
        ...state,
        addProjectSubPhaseError: null,
      };

    default:
      return state;
  }
}
