import React from 'react';
import PropTypes from 'prop-types';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { AUTOMATION_FILTER_COUNTER_ID_PREFIX } from './ids';

const FilterCounter = (props) => {
  const { onClick, label, open, filter } = props;
  const filterCounterId = `${AUTOMATION_FILTER_COUNTER_ID_PREFIX}${label.replace(' ', '')}`;

  const handleClick = () => {
    onClick(filter, filterCounterId);
  };

  return (
    <button
      className="filters-filter-counter"
      onClick={handleClick}
      type="button"
      id={filterCounterId}
    >
      <div className="counter-label">{ label }</div>
      <div className="count-container">
        {open ? <ArrowDropUp className="arrow" /> : <ArrowDropDown className="arrow" />}
      </div>
    </button>
  );
};

FilterCounter.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired,
  open: PropTypes.bool,
  filter: PropTypes.object,
};

FilterCounter.defaultProps = {
  open: false,
  onClick: () => {},
  filter: null,
};

export default FilterCounter;
