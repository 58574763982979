import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Input,
  FormControl,
  FormControlLabel,
  InputLabel,
  Checkbox,
  FormHelperText,
} from '@material-ui/core';
import { EditControls } from '../wrapped-components';

export class AddPhaseForm extends PureComponent {
  static propTypes = {
    addPhase: PropTypes.func.isRequired,
    accountId: PropTypes.number.isRequired,
    addPhasePending: PropTypes.bool.isRequired,
    cancel: PropTypes.func.isRequired,
    phases: PropTypes.array.isRequired,
  };

  state = {
    name: '',
    includeOnNewProjects: true,
    error: null,
  }

  addPhase = () => {
    const { addPhase, accountId } = this.props;
    const { name, includeOnNewProjects } = this.state;
    addPhase(accountId, { name: name.trim(), includeOnNewProjects });
  }

  onChange = (e) => {
    const name = e.target.value;
    this.validate(name.trim().toLowerCase());
    this.setState({
      name,
    });
  }

  toggleIncludeOnNewProjects = () => {
    const { includeOnNewProjects } = this.state;
    this.setState({
      includeOnNewProjects: !includeOnNewProjects,
    });
  }

  checkEnter = (e) => {
    const disabled = this.isDisabled();
    if (e.keyCode === 13 && !disabled) {
      this.addPhase();
    }
  }

  validate = (name) => {
    const { phases } = this.props;
    const isNotUnique = phases.some((phase) => {
      const phaseMatch = phase.name.toLowerCase() === name;
      const subMatch = phase.subPhases ? phase.subPhases.find(sp => sp.name.toLowerCase() === name) : false;
      return phaseMatch || subMatch;
    });
    const isEmpty = !name.length;
    let error = null;

    if (isEmpty) {
      error = 'Please enter a name';
    } else if (isNotUnique) {
      error = 'Phase name must be unique';
    }
    this.setState({ error });
  }

  isDisabled = () => {
    const { error, name } = this.state;
    return error !== null || !name.length;
  }

  render() {
    const { addPhasePending, cancel } = this.props;
    const { name, includeOnNewProjects, error } = this.state;
    return (
      <div className="account-settings-add-phase-form">
        <FormControl fullWidth>
          <InputLabel shrink>Title</InputLabel>
          <Input
            name="phase-name"
            value={name}
            placeholder="Enter a title for this phase"
            onChange={this.onChange}
            onKeyUp={this.checkEnter}
            inputProps={{ maxLength: 100 }}
            fullWidth
            autoFocus
          />
          {error && (
            <FormHelperText className="form__warning">{error}</FormHelperText>
          )}
          <FormControlLabel
            label="Automatically add phase to new projects"
            control={(
              <Checkbox
                classes={{ root: 'checkbox' }}
                checked={includeOnNewProjects}
                onChange={this.toggleIncludeOnNewProjects}
                color="primary"
              />
            )}
          />
        </FormControl>
        <EditControls
          primaryText="Save Phase"
          primaryAction={this.addPhase}
          secondaryAction={cancel}
          disabled={this.isDisabled()}
          pending={addPhasePending}
        />
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps({ common }) {
  return {
    accountId: common.accountId,
  };
}

export default connect(
  mapStateToProps,
)(AddPhaseForm);
