import { compose } from 'redux';

const people = root => root.people;

const pageFrom = state => state.pageFrom;
const entities = state => state.entities;

const photoUrls = entities => entities.reduce((urls, { photoUrl }) => {
  if (photoUrl) urls.push(photoUrl);
  return urls;
}, []);

export const peoplePhotos = compose(photoUrls, entities, people);
export const peoplePageFrom = compose(pageFrom, people);
