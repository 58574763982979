import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ClickAwayListener } from '@material-ui/core';
import { FilterList as FilterListIcon } from '@material-ui/icons';
import { Button } from '@bridgit/foundation';
import { FilterContainer } from '.';
import { CustomPopper } from '../wrapped-components';
import { setFilterListAnchorElId, setFilterAnchorElId, setFilterMenuVisibility, setPopperIsOpen } from './redux/actions';

const modifiers = {
  offset: {
    offset: '0, 8px',
    enabled: true,
  },
};

const FilterMenu = ({
  columns,
  label,
  exclude,
  updateCurrentFilter,
  priorityColumns,
  buttonId,
  filterQueries,
}) => {
  const dispatch = useDispatch();
  const { open, popperIsOpen } = useSelector(({ filters }) => filters.filterMenu);

  const [localOpen, setLocalOpen] = useState(false);

  const onClick = () => {
    const { args: filterArguments } = filterQueries;

    if ((!filterArguments || !filterArguments.length) && !open) {
      dispatch(setFilterListAnchorElId(buttonId));
      dispatch(setFilterAnchorElId(buttonId));
      dispatch(setPopperIsOpen(false));
    }
    dispatch(setFilterMenuVisibility(true));
    setLocalOpen(true);
  };

  const dismissFilterMenu = () => {
    if (!popperIsOpen) {
      dispatch(setFilterMenuVisibility(false));
      setLocalOpen(false);
    }
  };

  return (
    <div className="filters-filter-menu">
      <Button
        id={buttonId}
        color="secondary"
        onClick={onClick}
        variant="outlined"
        endIcon={<FilterListIcon className={classNames({ 'button-active': filterQueries?.args?.length })} />}
      >
        {label}
      </Button>
      {localOpen && (
        <CustomPopper
          popperOpen={open}
          anchorEl={document.getElementById(buttonId)}
          classes="common-filter-popper filters-filter-menu-popper"
          placement="bottom-start"
          hideArrow
          preventBubbling
          modifiers={modifiers}
        >
          <div role="presentation" onClick={dismissFilterMenu} className="filter-menu-click-listener" />
          <ClickAwayListener onClickAway={dismissFilterMenu}>
            <div>
              <FilterContainer
                filterQueries={filterQueries}
                columns={columns}
                priorityColumns={priorityColumns}
                exclude={exclude}
                trackApplyFilter={updateCurrentFilter}
                addFilterButtonId={`${buttonId}_addFilter`}
              />
            </div>
          </ClickAwayListener>
        </CustomPopper>
      )}
    </div>
  );
};

FilterMenu.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  exclude: PropTypes.arrayOf(PropTypes.object),
  updateCurrentFilter: PropTypes.func,
  priorityColumns: PropTypes.array,
  buttonId: PropTypes.string.isRequired,
  filterQueries: PropTypes.object,
};

FilterMenu.defaultProps = {
  columns: [],
  label: 'Filters',
  exclude: [],
  updateCurrentFilter: null,
  priorityColumns: [],
  filterQueries: {},
};

export default FilterMenu;
