import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Form, Field } from 'react-final-form';
import { validateEmail } from 'src/utils/validators';
import {
  AUTOMATION_EMAIL,
  AUTOMATION_PASSWORD,
} from './ids';

export default class LoginForm extends PureComponent {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    userLogin: PropTypes.func.isRequired,
    back: PropTypes.func.isRequired,
    loginError: PropTypes.object,
    resetMessageCode: PropTypes.string,
  };

  static defaultProps = {
    loginError: null,
    resetMessageCode: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,
    };
  }

  validateUserEmail = email => (validateEmail(email) ? undefined : 'Please enter a valid email address.');

  validateUserPassword = password => (password?.length ? undefined : true);

  handleClickShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  };

  render() {
    const { showPassword } = this.state;
    const { userLogin, loading, back, loginError, resetMessageCode } = this.props;

    const showingPassword = (
      <InputAdornment position="end">
        <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowPassword}>
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    );

    const Password = () => (
      <div>
        <Field
          name="password"
          tooShort="Your password must contain 8 more more characters"
          validate={this.validateUserPassword}
        >
          {({ input, meta: { error, touched } }) => (
            <FormControl margin="normal" fullWidth>
              <Typography component="span" gutterBottom variant="caption">
                Password
              </Typography>
              <Input
                {...input}
                id={AUTOMATION_PASSWORD}
                type={showPassword ? 'text' : 'password'}
                placeholder="Enter a password"
                endAdornment={showingPassword}
                autoComplete="off"
              />
              {error && touched && <FormHelperText className="form__warning">{error}</FormHelperText>}
            </FormControl>
          )}
        </Field>
      </div>
    );

    const Email = () => (
      <div>
        <Field
          name="email"
          placeholder="Enter your email"
          validate={this.validateUserEmail}
          validateFields={[]}
        >
          {({ input, meta: { error, touched }, ...rest }) => (
            <FormControl margin="normal" fullWidth>
              <Typography component="span" gutterBottom variant="caption">
                Email
              </Typography>
              <Input {...input} {...rest} id={AUTOMATION_EMAIL} />
              {error && touched && <FormHelperText className="form__warning">{error}</FormHelperText>}
            </FormControl>
          )}
        </Field>
      </div>
    );

    return (
      <>
        <Typography
          className="headline"
          color="primary"
          variant="h6"
          component="h4"
          gutterBottom
        >
          Log in with your account details
        </Typography>

        {resetMessageCode === 'resetSuccess' &&
          <Typography variant="body1" gutterBottom>Your password has been reset.</Typography>}

        {resetMessageCode === 'emailSuccess' &&
          <Typography variant="body1" gutterBottom>If there&#39;s an account linked to this email address, we will send you instructions to reset your password.</Typography>}

        {loading ? (
          <LinearProgress variant="query" />
        ) : (
          <Form
            onSubmit={userLogin}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className="login-login-form">
                <Typography
                  className="typography--300"
                  color="primary"
                  variant="caption"
                  component="h4"
                  gutterBottom
                >
                  {loginError && 'The email or password you entered is incorrect.'}
                </Typography>

                <Email />
                <Password />
                <Link className="forgot-password" to="/forgot-password">Forgot password?</Link>

                <section className="button__actions">
                  <Button
                    onClick={back}
                    color="primary"
                    disableRipple
                  >
                    Back
                  </Button>
                  <Button
                    color="primary"
                    disableRipple
                    disableElevation
                    size="medium"
                    type="submit"
                    variant="contained"
                  >
                    Log In
                  </Button>
                </section>
              </form>
            )}
          />
        )}
      </>
    );
  }
}
