import { DEFAULT_DARK_MODE_SETTINGS, DEFAULT_DIM_MODE_SETTINGS, STANDARD_THEME } from '../constants';

const initialState = {
  theme: STANDARD_THEME,
  settings: {
    dark: DEFAULT_DARK_MODE_SETTINGS,
    dim: DEFAULT_DIM_MODE_SETTINGS,
  },
  isUsingSystemTheme: false,
};

export default initialState;
