import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import {
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { DATE_DISPLAY_FORMAT, LIST_TAB } from '../../common/constants';
import { formatDollarValue, formatPhoneNumber } from '../../utils/formatters';
import { getValidatedLocalStorage } from '../../utils/validators';

function formatTableColumn(field) {
  const { name, type, isPrivate } = field;
  const column = { name, type, isPrivate };
  switch (type) {
    case 'Date':
      column.options = {
        customBodyRender: value => (value ? moment(value).format(DATE_DISPLAY_FORMAT) : ''),
        filter: true,
        filterEmptyOption: true,
      };
      break;

    case 'PhoneNumber':
      column.options = {
        customBodyRender: value => (<div className="people-list-phone">{value ? formatPhoneNumber(value) : ''}</div>),
      };
      break;

    case 'Boolean':
      column.options = {
        customBodyRender: value => (
          <FormControlLabel
            className={classNames('formControlLabel', { checked: value === 'true' })}
            control={<Checkbox checked={value === 'true'} disabled disableRipple />}
          />
        ),
        filter: true,
        filterOptions: [
          {
            name: 'Checked',
            value: true,
          },
          {
            name: 'Unchecked',
            value: false,
          },
        ],
      };
      break;
    case 'Currency':
      column.options = {
        customBodyRender: value => (value ? formatDollarValue(value) : ''),
        filter: true,
      };
      break;
    case 'SingleSelect':
      column.options = {
        filter: true,
        filterEmptyOption: true,
      };
      break;
    case 'MultiSelect':
      column.options = {
        filter: true,
        filterEmptyOption: true,
      };
      break;
    case 'Address':
      column.options = {
        filter: true,
      };
      break;
    case 'Text':
      column.options = {
        filter: true,
      };
      break;

    default:
      return column;
  }

  return column;
}

function getStoredView(storageKey = '') {
  const view = Number(getValidatedLocalStorage(storageKey));
  return Number.isNaN(view) ? false : view;
}

function getStoredContentView(storageKey) {
  // See if the active view was persisted to localstorage
  const storedView = getStoredView(storageKey);
  if (storedView !== null) return storedView;

  return LIST_TAB;
}

export {
  formatTableColumn,
  getStoredContentView,
};
