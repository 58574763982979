import moment from 'moment';
import { DATE_INPUT_FORMAT } from 'src/common/constants';
import { MULTI_STATE_MODAL_ID } from 'src/features/common/redux/constants';
import {
  filterConnectedAllocations,
  filterSquashedAllocations,
  handleParentDateChange,
  handleZeroPercentBlock,
} from 'src/features/allocations/utils/allocationUtils';
import { PROJECT_LIST_SELECTION_ID } from '../features/projects/redux/constants';

/*
  This function takes the old filteredPeople and the action from updating a project
  and makes sure that the changes to project name/colour/dates is applied to relevant allocations in projectAllocations for a person

  returns new filteredPeople with the changes
*/
const updateProjectForPeople = (filteredPeople, action) => {
  const { data, expandRole } = action;
  const { id, startDate, endDate, name, colour, state } = data;
  return filteredPeople.map((person) => {
    const { projectAllocations } = person;

    // first we have to handle if dates are shifted for the project
    let filteredProjectAllocations = projectAllocations.map((allocation) => {
      const { projectId, projectStart, projectEnd } = allocation;
      if (projectId !== id) return allocation;

      const shiftDates = expandRole && !moment(startDate).isSame(projectStart) && !moment(endDate).isSame(projectEnd);
      const diff = moment(startDate).diff(projectStart, 'days');
      if (shiftDates) {
        return {
          ...allocation,
          projectStart: startDate,
          projectEnd: endDate,
          startDate: moment(allocation.startDate).add(diff, 'days').format(DATE_INPUT_FORMAT),
          endDate: moment(allocation.endDate).add(diff, 'days').format(DATE_INPUT_FORMAT),
        };
      }

      return allocation;
    });

    // filter out allocations that don't fit in new dates
    filteredProjectAllocations = filterSquashedAllocations(
      filteredProjectAllocations,
      startDate,
      endDate,
      id,
    );

    return {
      ...person,
      projectAllocations: filteredProjectAllocations.map((allocation) => {
        const { projectId, projectStart, projectEnd } = allocation;
        if (projectId !== id) return allocation;
        const { newStart, newEnd } = handleParentDateChange(
          startDate,
          endDate,
          allocation,
          projectStart,
          projectEnd,
          expandRole,
        );
        return {
          ...allocation,
          startDate: newStart,
          endDate: newEnd,
          projectName: name,
          projectColour: colour,
          projectStart: startDate,
          projectEnd: endDate,
          projectState: state,
        };
      }),
    };
  });
};

/*
  This function takes the old filteredPeople and the action from adding a person to an allocation
  and makes sure that allocation is added to that person's projectAllocations
  and updates any other allocations that may need to merge into one big one

  returns new filteredPeople with the changes
*/
const addAllocationForPeople = (filteredPeople, action, projectSelections) => {
  const { data, projectId } = action;
  const { roleId, startDate, endDate, personId } = data;

  /*
    Get project info from the modal state
    If no data, then the modal is not open and we are not in the peopleGantt
  */
  const project = projectSelections[MULTI_STATE_MODAL_ID];
  if (!project || project.id !== projectId) return filteredPeople;

  /*
    Try to get the role info from project
  */
  const role = project.roles.find(role => role.id === roleId);
  if (!role) return filteredPeople;

  const {
    name: projectName,
    colour: projectColour,
    startDate: projectStart,
    endDate: projectEnd,
    state: projectState,
  } = project;
  const {
    name: roleName,
    note: roleNote,
    requirements,
  } = role;

  return filteredPeople.map((person) => {
    if (person.id !== personId) return person;

    const { projectAllocations } = person;
    const {
      filteredProjectAllocations,
      newStartDate,
      newEndDate,
    } = filterConnectedAllocations(projectAllocations, roleId, startDate, endDate);

    return {
      ...person,
      projectAllocations: [
        ...filteredProjectAllocations,
        {
          projectId,
          projectName,
          projectColour,
          projectStart,
          projectState,
          projectEnd,
          roleId,
          roleName,
          roleNote,
          startDate: newStartDate,
          endDate: newEndDate,
          allocatedPercent: requirements.length > 1 ? 'custom' : `${requirements[0].allocatedPercent}`,
        },
      ],
    };
  });
};

const replaceAllocationForPeople = (filteredPeople, action, projectSelections) => {
  const { data, oldData, projectId } = action;
  const { personId, roleId, startDate, endDate } = data;

  /*
    Get project info from the modal state
    If no data, then the modal is not open and we are not in the peopleGantt
  */
  const project = projectSelections[MULTI_STATE_MODAL_ID];
  if (!project || project.id !== projectId) return filteredPeople;

  /*
    Try to get the role info from project
  */
  const role = project.roles.find(role => role.id === roleId);
  if (!role) return filteredPeople;

  const {
    name: projectName,
    colour: projectColour,
    startDate: projectStart,
    endDate: projectEnd,
    state: projectState,
  } = project;
  const {
    name: roleName,
    note: roleNote,
    requirements,
  } = role;

  return filteredPeople.map((person) => {
    const { id, projectAllocations } = person;

    if (id === personId) {
      const {
        filteredProjectAllocations,
        newStartDate,
        newEndDate,
      } = filterConnectedAllocations(projectAllocations, roleId, startDate, endDate);
      return {
        ...person,
        projectAllocations: [
          ...filteredProjectAllocations,
          {
            projectId,
            projectName,
            projectColour,
            projectStart,
            projectEnd,
            projectState,
            roleId,
            roleName,
            roleNote,
            startDate: newStartDate,
            endDate: newEndDate,
            allocatedPercent: requirements.length > 1 ? 'custom' : `${requirements[0].allocatedPercent}`,
          },
        ],
      };
    }

    if (id === oldData.personId) {
      return {
        ...person,
        projectAllocations: projectAllocations.filter(a => (
          a.roleId !== roleId ||
          (a.startDate !== startDate && a.endDate !== endDate)
        )),
      };
    }

    return person;
  });
};

/*
  This function takes the old filteredPeople and the action from removing a person from an allocation
  and makes sure that allocation is removed from the projectAllocations for that person

  returns new filteredPeople with the changes
*/
const removeAllocationForPeople = (filteredPeople, action) => {
  const { data } = action;
  const { roleId, startDate, endDate, personId } = data;

  return filteredPeople.map((person) => {
    if (person.id === personId) {
      return {
        ...person,
        projectAllocations: person.projectAllocations.filter(allocation => (
          allocation.roleId !== roleId ||
          (allocation.startDate !== startDate && allocation.endDate !== endDate)
        )),
      };
    }
    return person;
  });
};

/*
  This function takes the old filteredPeople and the action from updating an allocation
  and makes sure any changes to dates is reflected in the projectAllocations for people

  returns new filteredPeople with the changes
*/
const updateAllocationDatesForPeople = (filteredPeople, action) => {
  const { oldAllocation, changes, roleId } = action;
  const { startDate, endDate } = oldAllocation;

  return filteredPeople.map((person) => {
    const { projectAllocations } = person;

    /*
      Filter out allocations that are removed because the new start/end date extends past the entire allocation
      ex. A1 - (Oct 1 - Oct 10), A2 - (Oct 10 - Oct 20), change A1 endDate to be Oct 20, A2 is deleted.
    */
    const filteredAllocations = projectAllocations.filter((allocation) => {
      if (
        allocation.roleId === roleId &&
        startDate !== allocation.startDate && endDate !== allocation.endDate
      ) {
        if (changes?.startDate) {
          return changes.startDate.isAfter(allocation.startDate) || moment(endDate).isBefore(allocation.startDate);
        }
        return changes.endDate.isBefore(allocation.endDate) || moment(startDate).isAfter(allocation.endDate);
      }
      return allocation;
    });

    return {
      ...person,
      projectAllocations: filteredAllocations.map((allocation) => {
        if (allocation.roleId === roleId) {
          /*
            If allocation is the one that is being updated (roleId and dates match oldAllocation defined above)
            This needs to be an OR to handle the case where the allocation spans before or after the gantt boundaries
            Return the allocation with the change applied
          */
          if (startDate === allocation.startDate || endDate === allocation.endDate) {
            return {
              ...allocation,
              startDate: changes?.startDate ? changes.startDate.format(DATE_INPUT_FORMAT) : allocation.startDate,
              endDate: changes?.endDate ? changes.endDate.format(DATE_INPUT_FORMAT) : allocation.endDate,
            };
          }

          /*
            If it is not the allocation being modified but one exists on the same role
            Update the dates that might have been pushed back based on changes to another allocation
            Ex. A1 - (Oct 1 - Oct 10), A2 - (Oct 10 - Oct 20), change A1 endDate to be Oct 15, A2's new start date is Oct 16.
          */
          return {
            ...allocation,
            startDate: changes?.endDate &&
              changes.endDate.isAfter(allocation.startDate) &&
              changes.endDate.isBefore(allocation.endDate)
              ? moment(changes.endDate).add(1, 'day').format(DATE_INPUT_FORMAT) : allocation.startDate,
            endDate: changes?.startDate &&
              changes.startDate.isBefore(allocation.endDate) &&
              changes.startDate.isAfter(allocation.startDate)
              ? moment(changes.startDate).subtract(1, 'day').format(DATE_INPUT_FORMAT) : allocation.endDate,
          };
        }
        return allocation;
      }),
    };
  });
};

/*
  This function takes the old filteredPeople and the action from updating a role
  and makes sure those updates are applied to projectAllocations on that role
  ex. Changing a role end date might affect an allocation's dates

  returns new filteredPeople with the changes
*/
const updateRoleForPeople = (filteredPeople, action, projectSelections) => {
  const { data, roleId, projectId, expandAllocations } = action;
  const { allocations, name, note, startDate, endDate } = data;
  const isCustom = allocations.length > 1;
  const zeroBlocks = allocations.filter(allocation => allocation.allocatedPercent === 0);

  /*
    Get project info from the modal state
    If no data, then the modal is not open and we are not in the peopleGantt
  */
  const project = projectSelections[MULTI_STATE_MODAL_ID];
  if (!project || project.id !== projectId) return filteredPeople;

  /*
    Try to get the role info from project
  */
  const role = project.roles.find(role => role.id === roleId);
  if (!role) return filteredPeople;

  return filteredPeople.map((person) => {
    const { projectAllocations } = person;
    let filteredProjectAllocations = filterSquashedAllocations(
      projectAllocations,
      startDate,
      endDate,
      null,
      roleId,
    );

    if (zeroBlocks.length) {
      filteredProjectAllocations = handleZeroPercentBlock(filteredProjectAllocations, zeroBlocks, roleId);
    }

    return {
      ...person,
      projectAllocations: filteredProjectAllocations.map((allocation) => {
        if (allocation.roleId === roleId) {
          const { newStart, newEnd } = handleParentDateChange(
            startDate,
            endDate,
            allocation,
            role.startDate,
            role.endDate,
            expandAllocations,
          );

          return {
            ...allocation,
            roleName: name,
            roleNote: note,
            allocatedPercent: isCustom ? 'custom' : `${allocations[0].allocatedPercent}`,
            startDate: newStart,
            endDate: newEnd,
          };
        }
        return allocation;
      }),
    };
  });
};

/*
  This function removes all allocations that match the role id of the role that was removed
*/
const removeRoleForPeople = (filteredPeople, action) => {
  const { roleId } = action;
  return filteredPeople.map((person) => {
    const { projectAllocations } = person;
    return {
      ...person,
      projectAllocations: projectAllocations.filter(a => a.roleId !== roleId),
    };
  });
};

/*
  This function adds newly created request to the selectedProject, when created on Project List page in Self-perform tab
*/
const addRequestToTheSelectedProject = (projectSelections, action) => {
  const project = projectSelections[PROJECT_LIST_SELECTION_ID];

  return {
    ...projectSelections,
    PROJECT_LIST_SELECTION_ID: {
      ...project,
      requests: [...project.requests, ...action.data],
    },
  };
};

const decreaseTotalPeopleAttachmentBytes = (accountEntities, action) => {
  const { analyticsPayload: { accountId, size } } = action;

  return accountEntities.map(account => (account.id === accountId
    ? { ...account, totalPeopleAttachmentBytes: account.totalPeopleAttachmentBytes - size }
    : account));
};

export {
  updateProjectForPeople,
  addAllocationForPeople,
  replaceAllocationForPeople,
  removeAllocationForPeople,
  updateAllocationDatesForPeople,
  updateRoleForPeople,
  removeRoleForPeople,
  addRequestToTheSelectedProject,
  decreaseTotalPeopleAttachmentBytes,
};
