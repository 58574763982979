import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { Star, StarBorder } from '@material-ui/icons';

export class RequiredToggle extends PureComponent {
  static propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    type: PropTypes.string,
  };

  static defaultProps = {
    onClick: () => {},
    disabled: false,
    required: true,
    type: 'view',
  }

  render() {
    const { onClick, required, disabled, type } = this.props;
    const isViewOnly = type === 'view';
    const title = required ? 'Click to make field non-mandatory' : 'Click to make field mandatory';
    const star = required
      ? <Star aria-label="Required" className={classNames('icon--required', { 'star-disabled': disabled })} />
      : <StarBorder aria-label="Required" className={classNames('icon--required', { 'star-disabled': disabled })} />;

    const starButton = (
      <IconButton
        className={classNames('account-settings-required-toggle', { dotted: !required, 'icon-disabled': disabled, 'view-only': isViewOnly })}
        onClick={onClick}
        disableRipple
        variant="contained"
      >
        {star}
      </IconButton>
    );

    return isViewOnly ? starButton : (
      <Tooltip title={title} placement="top">
        {starButton}
      </Tooltip>
    );
  }
}
export default RequiredToggle;
