import { PeopleGanttReport } from './people-gantt-report';
import { ProjectGanttReport } from './project-gantt-report';

export default {
  path: '',
  name: '',
  childRoutes: [
    {
      path: 'accounts/:id/reports/people/gantt',
      name: 'PeopleGanttReport',
      component: PeopleGanttReport,
    },
    {
      path: 'accounts/:id/reports/project/gantt',
      name: 'ProjectGanttReport',
      component: ProjectGanttReport,
    },
  ],
};
