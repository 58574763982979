import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { AuditLogItem, ListWithLoader } from '@bridgit/foundation';
import classNames from 'classnames';

import { getPersonAudit } from './redux/actions';
import { MULTI_STATE_MODAL_ID } from '../common/redux/constants';
import { PEOPLE_LIST_SELECTION_ID } from './redux/constants';
import { getPersonLogEventName, renderEventDetails, trackPersonAuditItemExpanded } from './utils/personAuditUtils';
import { splitByUpperCase } from '../../utils/miscUtils';

export const PersonAudit = ({ headerLabel }) => {
  const dispatch = useDispatch();
  const accountId = useSelector(({ common }) => common.accountId);
  const getPersonAuditPending = useSelector(({ people }) => people.getPersonAuditPending);
  const hasMorePersonLogItems = useSelector(({ people }) => people.personAudit?.hasMore);
  const personLogItems = useSelector(({ people }) => people.personAudit?.items || []);
  const pageFrom = useSelector(({ people }) => people.personAudit?.cursor);
  const isOpenInModal = useSelector(({ modalManager }) => modalManager.activeModal === MULTI_STATE_MODAL_ID);
  const selectedPerson = useSelector(({ people }) => {
    const { personSelections } = people;
    return isOpenInModal ? personSelections?.[MULTI_STATE_MODAL_ID] : personSelections?.[PEOPLE_LIST_SELECTION_ID];
  });

  useEffect(() => {
    if (accountId && selectedPerson?.id) dispatch(getPersonAudit(accountId, selectedPerson.id));
  }, [accountId, dispatch, selectedPerson?.id]);

  const loadMoreItems = useCallback(
    () => hasMorePersonLogItems && selectedPerson?.id && dispatch(getPersonAudit(accountId, selectedPerson.id, pageFrom)),
    [hasMorePersonLogItems, selectedPerson?.id, dispatch, accountId, pageFrom],
  );

  const handlePersonAuditItemExpanded = (isExpanded, eventData) => {
    trackPersonAuditItemExpanded(isExpanded, eventData, selectedPerson, isOpenInModal);
  };

  const renderPersonLogItems = ({ userName, eventOn, actionType, resourceType, eventData }) => {
    const eventName = getPersonLogEventName(resourceType, actionType);

    return (
      <AuditLogItem
        key={`${userName || 'Unknown'} ${eventOn}`}
        userName={userName || 'Unknown'}
        actionType={eventName || splitByUpperCase(actionType)}
        eventOn={eventOn}
        details={renderEventDetails(eventName, eventData)}
        onClick={handlePersonAuditItemExpanded}
        resourceType={resourceType}
      />
    );
  };

  return (
    <div className={classNames('people-person-audit', { 'people-person-audit-in-modal': isOpenInModal })}>
      {headerLabel && <div className="people-person-audit-header">{headerLabel}</div>}

      <ListWithLoader
        items={personLogItems}
        renderItems={renderPersonLogItems}
        loadMore={loadMoreItems}
        isLoading={getPersonAuditPending}
        isLoadingPage={getPersonAuditPending}
        className={getPersonAuditPending ? 'people-person-audit-list-loading' : 'people-person-audit-list'}
      />
    </div>
  );
};

PersonAudit.propTypes = {
  headerLabel: PropTypes.string,
};

PersonAudit.defaultProps = {
  headerLabel: '',
};

export default PersonAudit;
