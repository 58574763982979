import {
  FILTERS_RESET_ON,
  VIEW_RESET_ON,
  CLEAR_FILTER_CHIP,
  VIEW_REMOVED_FROM,
  PROPERTY_CHIP_CLEARED_FROM,
  VALUE_OF_CHIP_CLEARED,
} from '../filters/constants';

const filterClearEvent = target => `${FILTERS_RESET_ON} ${target}`;
const filterClearPayload = view => ({ [VIEW_RESET_ON]: view });

const filterChipRemoveEvent = target => `${CLEAR_FILTER_CHIP} ${target}`;
const filterChipRemovePayload = view => ({ [VIEW_REMOVED_FROM]: view });

const projectAllocationFilterChipRemovePayload = (columnName, filterLabel) => ({
  [PROPERTY_CHIP_CLEARED_FROM]: columnName,
  [VALUE_OF_CHIP_CLEARED]: filterLabel,
});

export {
  projectAllocationFilterChipRemovePayload,
  filterClearEvent,
  filterClearPayload,
  filterChipRemoveEvent,
  filterChipRemovePayload,
};
