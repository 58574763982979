import {
  PEOPLE_MANAGE_CERTIFICATION_ATTACHMENTS,
  PEOPLE_HIDE_CERTIFICATION_ATTACHMENTS,
} from './constants';

// This action does not update state, but triggers analytic events to fire
export function toggleCertificationAttachments(isCollapsing) {
  return {
    type: isCollapsing ? PEOPLE_HIDE_CERTIFICATION_ATTACHMENTS : PEOPLE_MANAGE_CERTIFICATION_ATTACHMENTS,
  };
}

export function reducer(state) {
  return state;
}
