import { call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { collateFieldValues } from 'src/filters/graphqlUtils';
import { getPeopleListQuery } from 'src/features/people/queries/getPeopleListQuery';
import { NO_AVAILABILITY, FULL_AVAILABILITY } from 'src/filters/constants';
import {
  REPORTS_GET_REPORT_PEOPLE_BEGIN,
  REPORTS_GET_REPORT_PEOPLE_SUCCESS,
  REPORTS_GET_REPORT_PEOPLE_FAILURE,
  REPORTS_GET_REPORT_PEOPLE_DISMISS_ERROR,
  PEOPLE_LIST_PDF_REPORT,
  CSV_DATE_FORMAT,
} from './constants';
import graphApiClient from '../../../common/GraphAPIClient';
import { MIN_FROM, MAX_SIZE, DATE_DISPLAY_FORMAT } from '../../../common/constants';
import initialState from './initialState';

export function getReportPeople(accountId, queries) {
  const { query, variables } = getPeopleListQuery(accountId, queries, MIN_FROM, MAX_SIZE);
  return {
    type: REPORTS_GET_REPORT_PEOPLE_BEGIN,
    query,
    variables,
    accountId,
  };
}

export function dismissGetReportPeopleError() {
  return {
    type: REPORTS_GET_REPORT_PEOPLE_DISMISS_ERROR,
  };
}

export function* doGetReportPeople(action) {
  const { query, variables, settings } = action;
  const reportType = settings?.type;
  let peopleResponse;

  try {
    peopleResponse = yield call(graphApiClient.query, { query, variables });
    const { errors } = peopleResponse;
    if (errors) {
      throw errors;
    }
  } catch (err) {
    yield put({
      type: REPORTS_GET_REPORT_PEOPLE_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: REPORTS_GET_REPORT_PEOPLE_SUCCESS,
    people: peopleResponse.data.people,
    reportType,
  });
}

export function* watchGetReportPeople() {
  yield takeLatest(REPORTS_GET_REPORT_PEOPLE_BEGIN, doGetReportPeople);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case REPORTS_GET_REPORT_PEOPLE_BEGIN:
      return {
        ...state,
        getReportPeoplePending: true,
        getReportPeopleError: null,
      };

    case REPORTS_GET_REPORT_PEOPLE_SUCCESS: {
      const { people, reportType } = action;
      const collatedPeople = collateFieldValues(people.items) || [];

      const generateAvailabilityText = (availableUntilDate, person) => {
        const formattedDate = reportType === PEOPLE_LIST_PDF_REPORT
          ? availableUntilDate.format(DATE_DISPLAY_FORMAT)
          : availableUntilDate.format(CSV_DATE_FORMAT);
        return `${person.percentAvailableToday}%, ${formattedDate}`;
      };

      collatedPeople.forEach((person, index) => {
        if ('availableInDays' in person
          && 'availableFor' in person
          && 'percentAvailableToday' in person) {
          if (person.availableInDays === NO_AVAILABILITY) {
            collatedPeople[index].nextAvailability = 'No availability';
          } else if (person.availableFor === FULL_AVAILABILITY) {
            collatedPeople[index].nextAvailability = '100% -';
          } else if (person.availableInDays === 0) {
            const availableUntilDate = moment().add(person.availableFor, 'days');
            collatedPeople[index].nextAvailability = generateAvailabilityText(availableUntilDate, person);
          } else {
            const availableUntilDate = moment().add(person.availableInDays - 1, 'days');
            collatedPeople[index].nextAvailability = generateAvailabilityText(availableUntilDate, person);
          }
        }
      });

      return {
        ...state,
        people: collatedPeople,
        getReportPeoplePending: false,
        getReportPeopleError: null,
      };
    }

    case REPORTS_GET_REPORT_PEOPLE_FAILURE: {
      return {
        ...initialState,
        getReportPeoplePending: false,
        getReportPeopleError: action.data.error,
      };
    }

    case REPORTS_GET_REPORT_PEOPLE_DISMISS_ERROR: {
      return {
        ...initialState,
        getReportPeopleError: null,
        showReport: false,
      };
    }

    default:
      return state;
  }
}
