import { DEFAULT_POU_START, DEFAULT_POU_END } from 'src/common/constants';
import { getPossibleAllocations } from 'src/features/allocations/utils/allocationUtils';
import { sortSegmentsByStartDate, stitchSegmentsByRange } from 'src/utils/dateSegmentUtils';
import {
  dayBefore,
  dayAfter,
  getGapDays,
  isStringOrMoment,
} from 'src/utils/dateUtils';

/*
  Given a set of availabilities and a role/segment start and end date,
  returns a full timeline with empty blocks as required.
*/
const getBlocks = (availabilities, unavailabilities, startDate, endDate) => {
  const blocks = [];
  let block;
  let nextBlock;
  let timeGap = 0;

  if (!(
    Array.isArray(availabilities)
    && Array.isArray(unavailabilities)
    && isStringOrMoment(startDate)
    && isStringOrMoment(endDate)
  )) return [];

  const noCoverage = { fullCoverage: false, partialCoverage: false, unavailable: false };

  // Except for how they render, availabilities and unavailabilities can be handled in much the same way
  const combinedPeriods = [...availabilities, ...unavailabilities].map((period) => {
    const {
      startDate: periodStart,
      endDate: periodEnd,
      fullCoverage,
      partialCoverage,
      unavailable,
      rangeType,
      id: periodId = null,
    } = { ...noCoverage, ...period };

    return {
      startDate: periodStart === DEFAULT_POU_START ? startDate : periodStart,
      endDate: periodEnd === DEFAULT_POU_END ? endDate : periodEnd,
      fullCoverage,
      partialCoverage,
      unavailable,
      ...rangeType && { rangeType },
      ...periodId && { periodId },
    };
  });

  if (!combinedPeriods.length) {
    return [{ ...noCoverage, startDate, endDate }];
  }

  const sortedAvailabilities = sortSegmentsByStartDate(combinedPeriods);
  const parsedAvailabilities = stitchSegmentsByRange(sortedAvailabilities);

  for (let i = 0; i < parsedAvailabilities.length; i += 1) {
    block = parsedAvailabilities[i];
    nextBlock = parsedAvailabilities[i + 1];

    // Add preceding empty block if needed
    if (i === 0) {
      timeGap = getGapDays(startDate, block.startDate);

      if (timeGap > 0) {
        blocks.push({
          startDate,
          endDate: dayBefore(block.startDate),
          ...noCoverage,
        });
      }
    }

    if (i === parsedAvailabilities.length - 1) {
      // Push the active availability chunk
      blocks.push(block);

      // Add trailing empty block if needed
      timeGap = getGapDays(block.endDate, endDate);

      if (timeGap > 0) {
        blocks.push({
          startDate: dayAfter(block.endDate),
          endDate,
          ...noCoverage,
        });
      }
    } else if (nextBlock && getGapDays(block.endDate, nextBlock.startDate) > 1) {
      // Push the active availability chunk
      blocks.push(block);

      // Add intermediate blocks if needed
      blocks.push({
        startDate: dayAfter(block.endDate),
        endDate: dayBefore(nextBlock.startDate),
        ...noCoverage,
      });
    } else {
      blocks.push(block);
    }
  }

  return blocks;
};

/*
  Build on top of getBlocks(), this takes availabilities for a person as well
  as the specific requirements for the role, and compares the two. A block of
  availability will be considered 'full' if the available percentage is greater
  than the requirement of the role. This also has the effect of merging adjacent
  availabilities.
*/
const getRelativeAvailabilityBlocks = (roleRequirements, availabilities, unavailabilities = [], startDate, endDate) => {
  if (Array.isArray(roleRequirements) && roleRequirements.length) {
    const possibleAllocations = getPossibleAllocations(roleRequirements, availabilities);
    return getBlocks(possibleAllocations, unavailabilities, startDate, endDate);
  }

  return getBlocks(availabilities, unavailabilities, startDate, endDate);
};

export {
  getBlocks,
  getRelativeAvailabilityBlocks,
};
