import {
  stringToMoment,
  isStringOrMoment,
  momentToString,
  dayAfter,
  dayBefore,
  toRange,
  rangesOverlap,
  dateStringAdd,
  dateStringSubtract,
} from 'src/utils/dateUtils';
import { getBoundingRangeFromSegments, objectIsSegment } from 'src/utils/dateSegmentUtils';

import {
  EDIT_ACTION_EXTENDED,
  EDIT_ACTION_COLLAPSED,
  START_DATE,
  END_DATE,
  DATE_EDIT_ACTIONS,
} from 'src/common/constants';
import tinycolor from 'tinycolor2';
import initialState from '../redux/initialState';
import {
  EDITABLE_SEGMENT_TYPES,
  SEGMENT_TYPE_PHASE,
  SEGMENT_TYPE_PRIMARY_PHASE,
  SEGMENT_TYPE_PROJECT,
  SEGMENT_TYPE_ROLE,
} from '../common/constants';
import { pickObjectProps } from '../../../filters/graphqlUtils';

function extendingByEndDate(editAction, dateUnderEdit) {
  return editAction === EDIT_ACTION_EXTENDED && dateUnderEdit === END_DATE;
}

function isSecondaryProjectBar(segmentType, typeUnderEdit) {
  return segmentType === SEGMENT_TYPE_PROJECT && typeUnderEdit === SEGMENT_TYPE_PHASE;
}

function isUneditableProjectBar(segmentType, editAction, dateUnderEdit, typeUnderEdit, newEndDate, projectEnd) {
  if (segmentType !== SEGMENT_TYPE_PROJECT) return false;
  if (typeUnderEdit === SEGMENT_TYPE_PROJECT) return true;

  const projectExtending = extendingByEndDate(editAction, dateUnderEdit);
  const secondaryProjectBar = isSecondaryProjectBar(segmentType, typeUnderEdit);
  const endIsWithinProjectBounds = stringToMoment(projectEnd).isSameOrAfter(newEndDate);

  return ((secondaryProjectBar && !projectExtending) ||
  (secondaryProjectBar && projectExtending && !endIsWithinProjectBounds));
}

function isPrimary(segmentType, typeUnderEdit) {
  return (
    (segmentType === SEGMENT_TYPE_PROJECT && typeUnderEdit === SEGMENT_TYPE_PROJECT) ||
    (segmentType === SEGMENT_TYPE_PRIMARY_PHASE && typeUnderEdit === SEGMENT_TYPE_PHASE)
  );
}

function shouldShiftDates(typeUnderEdit, dateUnderEdit, segmentStartDate, newEndDate, offset, checked) {
  if (typeUnderEdit !== SEGMENT_TYPE_PHASE || dateUnderEdit === START_DATE) return false;
  const originalEndDate = dateStringSubtract(newEndDate, offset);
  return stringToMoment(segmentStartDate).isSameOrAfter(originalEndDate) && checked;
}

/*
  Set initial values for individual segments to indicate:
    * checked - Starting checked state indicating if the entity should be updated
    * showCheckbox - Should this segment be presented with the option to edit
    * showDeleteWarning - True if the changes to the parent will cause this entity to be deleted
*/
function setSegmentFlags(segments, editAction, typeUnderEdit, newStartDate, newEndDate, dateUnderEdit) {
  if (!(
    DATE_EDIT_ACTIONS.includes(editAction) &&
    EDITABLE_SEGMENT_TYPES.includes(typeUnderEdit) &&
    [START_DATE, END_DATE].includes(dateUnderEdit) &&
    isStringOrMoment(newStartDate) &&
    isStringOrMoment(newEndDate)
  )) return segments;

  const parentRange = toRange(newStartDate, newEndDate);

  const shouldShowCheckbox = (type) => {
    if (typeUnderEdit === SEGMENT_TYPE_PROJECT) {
      return !(editAction === EDIT_ACTION_COLLAPSED || isPrimary(type, typeUnderEdit));
    }
    // When editing a phase date
    return !(
      isPrimary(type, typeUnderEdit) ||
      isUneditableProjectBar(
        type,
        editAction,
        dateUnderEdit,
        typeUnderEdit,
        newEndDate,
        segments[SEGMENT_TYPE_PROJECT]?.[0]?.endDate,
      )
    );
  };

  const shouldShowDeleteWarning = (startDate, endDate) => {
    if (typeUnderEdit === SEGMENT_TYPE_PHASE) return false;
    const segmentRange = toRange(startDate, endDate);
    return !segmentRange.overlaps(parentRange, { adjacent: true });
  };

  const flaggedSegments = {};
  const checked = true;

  EDITABLE_SEGMENT_TYPES.forEach((type) => {
    flaggedSegments[type] = segments[type]?.map((segment) => {
      const { startDate, endDate } = segment;
      const showCheckbox = shouldShowCheckbox(type);
      const showDeleteWarning = shouldShowDeleteWarning(startDate, endDate);

      return { ...segment, checked, showCheckbox, showDeleteWarning };
    }) || [];
  });

  return flaggedSegments;
}

function getProjectEditorData(project, dateUnderEdit, newStartDate, newEndDate, editAction) {
  if (!(
    objectIsSegment(project) &&
    isStringOrMoment(newStartDate) &&
    isStringOrMoment(newEndDate) &&
    (dateUnderEdit === START_DATE || dateUnderEdit === END_DATE)
  )) return null;

  const includeAdjacent = editAction === EDIT_ACTION_EXTENDED;

  const { startDate, endDate, roles, phases } = project;

  const projectStartDate = momentToString(startDate);
  const projectEndDate = momentToString(endDate);
  const dates = [
    dateUnderEdit === START_DATE ? newStartDate : projectEndDate,
    dateUnderEdit === END_DATE ? newEndDate : projectStartDate,
  ];

  const [rangeStart, rangeEnd] = dates.sort((a, b) => stringToMoment(a) - stringToMoment(b));
  const affectedRange = { startDate: rangeStart, endDate: rangeEnd };

  const getEntitiesImpacted = (segments = []) => segments.filter(segment => rangesOverlap(affectedRange, segment, includeAdjacent));

  const rolesImpacted = getEntitiesImpacted(roles);
  const phasesImpacted = getEntitiesImpacted(phases);

  return {
    [SEGMENT_TYPE_PROJECT]: [project],
    [SEGMENT_TYPE_ROLE]: rolesImpacted,
    [SEGMENT_TYPE_PHASE]: phasesImpacted,
  };
}

// This assumes that either the start or end date will be edited, not both at the same time
function getDateEditOptions(currentStartDate, currentEndDate, newStartDate, newEndDate) {
  let {
    editAction,
    dateUnderEdit,
    offset,
  } = initialState;

  const defaultConfig = {
    editAction,
    dateUnderEdit,
    offset,
  };

  if (!(
    isStringOrMoment(currentStartDate) &&
    isStringOrMoment(currentEndDate) &&
    isStringOrMoment(newStartDate) &&
    isStringOrMoment(newEndDate)
  )) return defaultConfig;

  const currentStart = stringToMoment(currentStartDate);
  const currentEnd = stringToMoment(currentEndDate);
  const newStart = stringToMoment(newStartDate);
  const newEnd = stringToMoment(newEndDate);

  // Which date is being edited? Start or end?
  if (!currentStart.isSame(newStart)) {
    dateUnderEdit = START_DATE;
  } else if (!currentEnd.isSame(newEnd)) {
    dateUnderEdit = END_DATE;
  } else {
    return defaultConfig;
  }

  // Calculate the difference in days between the original and updated date
  // and set a flag indicating if this is an extend or collapse action
  if (dateUnderEdit === START_DATE) {
    offset = newStart.diff(currentStart, 'days');
    editAction = offset > 0 ? EDIT_ACTION_COLLAPSED : EDIT_ACTION_EXTENDED;
  } else {
    offset = newEnd.diff(currentEnd, 'days');
    editAction = offset > 0 ? EDIT_ACTION_EXTENDED : EDIT_ACTION_COLLAPSED;
  }

  return {
    editAction,
    dateUnderEdit,
    offset,
  };
}

function getDefaultEditorConfig() {
  const configKeys = [
    'editAction',
    'dateUnderEdit',
    'typeUnderEdit',
    'offset',
    'newStartDate',
    'newEndDate',
    'boundingStartDate',
    'boundingEndDate',
  ];

  return pickObjectProps(initialState, configKeys);
}

/*
  Precalculates some config values to be stored in Redux and consumed by the date editor. Given the
  original and new start and end dates to be applied to the entity in question, this function returns:

  editAction: One of 'Extended' or 'Collapsed' as defined in our constants
  dateUnderEdit: String constant indicating if the start or end date is being modified
  typeUnderEdit: String constant for Phase, Project or Role types
  offset: Positive or negative offset indicating the difference in days between the modified and original date
  boundingStartDate: Earliest of the given dates, used to define the modal display boundary
  boundingEndDate: Same as above, but for the end date

  If any of the given arguments are invalid, the returned config object will consist of the default
  values that are defined in initialState.

  Note: typeUnderEdit, newStartDate, and newEndDate are validated and also returned unmodified.
  If they do not pass validation, defaults will be used. This is to limit the impact of garbage values
  being passed in.
*/
function getProjectEditorConfig(typeUnderEdit, currentStartDate, currentEndDate, newStartDate, newEndDate) {
  const defaultConfig = getDefaultEditorConfig();

  if (!(
    EDITABLE_SEGMENT_TYPES.includes(typeUnderEdit) &&
    isStringOrMoment(currentStartDate) &&
    isStringOrMoment(currentEndDate) &&
    isStringOrMoment(newStartDate) &&
    isStringOrMoment(newEndDate)
  )) return defaultConfig;

  const { startDate, endDate } = getBoundingRangeFromSegments([
    { startDate: currentStartDate, endDate: currentEndDate },
    { startDate: newStartDate, endDate: newEndDate },
  ]);

  const boundingStartDate = momentToString(startDate);
  const boundingEndDate = momentToString(endDate);
  const options = getDateEditOptions(currentStartDate, currentEndDate, newStartDate, newEndDate);
  const { editAction, dateUnderEdit, offset } = options;

  return {
    editAction,
    dateUnderEdit,
    typeUnderEdit,
    offset,
    newStartDate: momentToString(newStartDate),
    newEndDate: momentToString(newEndDate),
    boundingStartDate,
    boundingEndDate,
  };
}

function getPhaseEditorConfig(
  typeUnderEdit,
  projectStart,
  projectEnd,
  newStartDate,
  newEndDate,
  phaseStart,
  phaseEnd,
) {
  const defaultConfig = getDefaultEditorConfig();

  if (!(
    EDITABLE_SEGMENT_TYPES.includes(typeUnderEdit) &&
    isStringOrMoment(projectStart) &&
    isStringOrMoment(projectEnd) &&
    isStringOrMoment(newStartDate) &&
    isStringOrMoment(newEndDate) &&
    isStringOrMoment(phaseStart) &&
    isStringOrMoment(phaseEnd)
  )) return defaultConfig;

  const projectConfig = getProjectEditorConfig(typeUnderEdit, phaseStart, phaseEnd, newStartDate, newEndDate);

  // Recalculate bounding range as required by phase logic
  const boundingDates = getBoundingRangeFromSegments([{ startDate: projectStart, endDate: projectEnd }]);

  const { startDate } = boundingDates;
  let { endDate } = boundingDates;

  const { dateUnderEdit, editAction, offset } = projectConfig;

  if (editAction === EDIT_ACTION_EXTENDED && dateUnderEdit === END_DATE) {
    endDate = dateStringAdd(endDate, offset);
  }

  return {
    ...projectConfig,
    boundingStartDate: momentToString(startDate),
    boundingEndDate: momentToString(endDate),
  };
}

const getAffectedProjectEntities = (project, newStartDate, newEndDate) => {
  const segments = {
    [SEGMENT_TYPE_PROJECT]: [],
    [SEGMENT_TYPE_PHASE]: [],
    [SEGMENT_TYPE_ROLE]: [],
  };

  if (!(
    objectIsSegment(project) &&
    isStringOrMoment(newStartDate) &&
    isStringOrMoment(newEndDate)
  )) return segments;

  const { startDate, endDate } = project;

  if (stringToMoment(startDate).isSame(newStartDate) &&
    stringToMoment(endDate).isSame(newEndDate)) {
    return segments;
  }

  const { editAction, dateUnderEdit } = getDateEditOptions(startDate, endDate, newStartDate, newEndDate);

  const editorData = getProjectEditorData(
    project,
    dateUnderEdit,
    momentToString(newStartDate),
    momentToString(newEndDate),
    editAction,
  );

  return {
    ...segments,
    ...editorData,
  };
};

const buildTimelineDates = (segment, updatedParentSegment, editAction, dateUnderEdit, typeUnderEdit, offset, options = {}) => {
  if (!(
    objectIsSegment(segment) &&
    objectIsSegment(updatedParentSegment) &&
    [EDIT_ACTION_COLLAPSED, EDIT_ACTION_EXTENDED].includes(editAction) &&
    [START_DATE, END_DATE].includes(dateUnderEdit) &&
    typeof offset === 'number' &&
    !Number.isNaN(offset)
  )) return null;

  /*
    Note that if includeOffset is false, combine segments will do nothing,
    as there will only ever be one segment
  */
  const defaultOptions = {
    includeOffset: true,
    combineSegments: false,
    extendProject: false,
    useOriginalDates: false,
    shiftDates: false,
  };

  const { combineSegments, includeOffset, extendProject, useOriginalDates, shiftDates } = { ...defaultOptions, ...options };

  const isCollapsed = editAction === EDIT_ACTION_COLLAPSED;
  const editingProject = typeUnderEdit === SEGMENT_TYPE_PROJECT;
  const { startDate, endDate } = segment;
  const { startDate: parentStart, endDate: parentEnd } = updatedParentSegment;

  if (offset === 0 || useOriginalDates) return [segment];
  if (extendProject) return [{ startDate, endDate: dateStringAdd(endDate, offset) }];
  if (shiftDates) {
    return [{
      startDate: dateStringAdd(startDate, offset),
      endDate: dateStringAdd(endDate, offset),
    }];
  }

  const absOffset = Math.abs(offset);

  const baseSegment = { startDate, endDate };
  let offsetStartSegment = null;
  let offsetEndSegment = null;

  const segmentIsOutsideParentRange = !rangesOverlap(
    { startDate: parentStart, endDate: parentEnd },
    { startDate, endDate },
    true,
  );

  if (isCollapsed) {
    /*
      If the modification to the parent will result in the entire segment being deleted,
      return the segment as-is. It will be rendered without modification, but will be
      shown with a warning.
    */
    if (segmentIsOutsideParentRange) return [segment];

    // Truncate segment start
    if (dateUnderEdit === START_DATE) {
      const newStart = editingProject ? momentToString(parentStart) : dateStringAdd(startDate, absOffset);
      offsetStartSegment = { startDate, endDate: dayBefore(newStart) };
      baseSegment.startDate = newStart;
    } else {
      // Truncate segment end
      const newEnd = editingProject ? momentToString(parentEnd) : dateStringSubtract(endDate, absOffset);
      offsetEndSegment = { startDate: dayAfter(newEnd), endDate };
      baseSegment.endDate = newEnd;
    }

    // Expanding
  } else if (dateUnderEdit === START_DATE) {
    // Add new block before original start date
    const offsetStart = momentToString(stringToMoment(startDate).subtract(absOffset, 'days'));
    const offsetEnd = dayBefore(startDate);
    offsetStartSegment = { startDate: offsetStart, endDate: offsetEnd };
  } else {
    // Add new block after original end date
    const offsetEnd = momentToString(stringToMoment(endDate).add(absOffset, 'days'));
    offsetEndSegment = { startDate: dayAfter(endDate), endDate: offsetEnd };
  }

  const includeStartOffset = includeOffset && offsetStartSegment;
  const includeEndOffset = includeOffset && offsetEndSegment;

  const allSegments = [
    ...(includeStartOffset ? [offsetStartSegment] : []),
    baseSegment,
    ...(includeEndOffset ? [offsetEndSegment] : []),
  ];

  if (combineSegments) {
    const { startDate: boundStart, endDate: boundEnd } = getBoundingRangeFromSegments(allSegments);
    return [{ startDate: momentToString(boundStart), endDate: momentToString(boundEnd) }];
  }

  return allSegments;
};

function getShiftedColor(baseColor) {
  const darkenValue = 14;
  const lightenValue = 30;
  const desaturateValue = 40;

  const color = tinycolor(baseColor);

  if (color.isLight()) return color.darken(darkenValue).toString();

  return color.lighten(lightenValue).desaturate(desaturateValue).toString();
}

/*
  Given invalid inputs, this will return an object populated with reasonable defaults.
  The assumption is that this function is for handling filtering for 'checked' items
  as well as basic counts for logging purposes. Data validation should happen elsewhere.
*/
const getGroupData = (segments = []) => {
  let deleteCount = 0;
  let updatedCount = 0;
  const totalCount = segments?.length ?? 0;
  let selectedSegments = [];

  if (Array.isArray(segments)) {
    selectedSegments = segments.reduce((acc, current) => {
      const { checked, showDeleteWarning } = current;

      if (checked) acc.push(current);

      if (checked && !showDeleteWarning) updatedCount += 1;
      if (checked && showDeleteWarning) deleteCount += 1;

      return acc;
    }, []);
  }

  return { totalCount, updatedCount, deleteCount, selectedSegments };
};

const extendSegmentDates = (segment, dateUnderEdit, offset) => {
  if (!(
    objectIsSegment(segment) &&
    [START_DATE, END_DATE].includes(dateUnderEdit) &&
    typeof offset === 'number'
  )) return segment;

  const { startDate, endDate } = segment;

  const absOffset = Math.abs(offset);

  if (dateUnderEdit === START_DATE) {
    return { ...segment, startDate: dateStringSubtract(startDate, absOffset), endDate };
  }

  return { ...segment, startDate, endDate: dateStringAdd(endDate, absOffset) };
};

const collapseSegmentDates = (segment, dateUnderEdit, offset) => {
  if (!(
    objectIsSegment(segment) &&
    [START_DATE, END_DATE].includes(dateUnderEdit) &&
    typeof offset === 'number'
  )) return segment;

  const { startDate, endDate } = segment;

  const absOffset = Math.abs(offset);

  if (dateUnderEdit === START_DATE) {
    return { ...segment, startDate: dateStringAdd(startDate, absOffset), endDate };
  }

  return { ...segment, startDate, endDate: dateStringSubtract(endDate, absOffset) };
};

const shiftSegmentDates = (segment, offset) => {
  if (!(
    objectIsSegment(segment) &&
    typeof offset === 'number'
  )) return segment;

  const { startDate, endDate } = segment;

  return {
    ...segment,
    startDate: dateStringAdd(startDate, offset),
    endDate: dateStringAdd(endDate, offset),
  };
};

const getSingleSegmentDates = (segment, dateUnderEdit, editAction, newEndDate, offset) => {
  if (!(
    objectIsSegment(segment) &&
    [EDIT_ACTION_COLLAPSED, EDIT_ACTION_EXTENDED].includes(editAction) &&
    [START_DATE, END_DATE].includes(dateUnderEdit) &&
    isStringOrMoment(newEndDate) &&
    typeof offset === 'number' &&
    !Number.isNaN(offset)
  )) return segment;

  const { startDate: segmentStart, checked } = segment;
  const shiftDates = shouldShiftDates(SEGMENT_TYPE_PHASE, dateUnderEdit, segmentStart, newEndDate, offset, checked);

  if (shiftDates) return shiftSegmentDates(segment, offset);

  return editAction === EDIT_ACTION_EXTENDED
    ? extendSegmentDates(segment, dateUnderEdit, offset)
    : collapseSegmentDates(segment, dateUnderEdit, offset);
};

// Returns segments to be displayed in the phase date modal
function getPhaseEditorData(project, dateUnderEdit, origPhase, newEndDate, newStartDate) {
  if (!(
    objectIsSegment(project) &&
    objectIsSegment(origPhase) &&
    isStringOrMoment(newEndDate) &&
    isStringOrMoment(newStartDate) &&
    (dateUnderEdit === START_DATE || dateUnderEdit === END_DATE)
  )) return null;

  const { phases, roles } = project;
  const { id, startDate: currentStart, endDate: currentEnd } = origPhase;

  const filteredPhases = phases?.filter(phase => phase.id !== id);
  let phasesImpacted = [];
  let rolesImpacted = [];

  // When a start date is edited, we want all roles/phases with start dates
  // that are the same as the original start date AND an end date that is
  // after the new start date
  if (dateUnderEdit === START_DATE) {
    const getEntitiesWithSameStart = (segments = []) => (
      segments.filter(segment => (
        stringToMoment(segment.startDate).isSame(currentStart) &&
        stringToMoment(segment.endDate).isAfter(newStartDate)
      ))
    );
    phasesImpacted = getEntitiesWithSameStart(filteredPhases);
    rolesImpacted = getEntitiesWithSameStart(roles);
  }

  // When an end date is edited, we want all roles/phases with an end date on or after the original end date
  // AND with a start date on or before the new end date OR with a start date the same or after the
  // original end date
  if (dateUnderEdit === END_DATE) {
    const getImpactedEntities = (segments = []) => (
      segments.filter(segment => (
        (stringToMoment(segment.endDate).isSameOrAfter(currentEnd) &&
        stringToMoment(segment.startDate).isSameOrBefore(newEndDate)) ||
        stringToMoment(segment.startDate).isSameOrAfter(currentEnd)
      ))
    );
    phasesImpacted = getImpactedEntities(filteredPhases);
    rolesImpacted = getImpactedEntities(roles);
  }

  return {
    [SEGMENT_TYPE_PROJECT]: [project],
    [SEGMENT_TYPE_PHASE]: phasesImpacted,
    [SEGMENT_TYPE_ROLE]: rolesImpacted,
  };
}

const getAffectedPhaseEntities = (project, updatedPhase) => {
  const segments = {
    [SEGMENT_TYPE_PRIMARY_PHASE]: [],
    [SEGMENT_TYPE_PROJECT]: [],
    [SEGMENT_TYPE_PHASE]: [],
    [SEGMENT_TYPE_ROLE]: [],
  };

  if (!(
    objectIsSegment(project) &&
    objectIsSegment(updatedPhase)
  )) return segments;

  const origPhase = project.phases.find(phase => phase.id === updatedPhase.id);
  const { startDate, endDate } = origPhase;
  const { startDate: newStartDate, endDate: newEndDate } = updatedPhase;

  const dateUnchanged = stringToMoment(startDate).isSame(newStartDate) &&
    stringToMoment(endDate).isSame(newEndDate);

  if (dateUnchanged) {
    return segments;
  }

  const { dateUnderEdit } = getDateEditOptions(startDate, endDate, newStartDate, newEndDate);

  const editorData = getPhaseEditorData(
    project,
    dateUnderEdit,
    origPhase,
    newEndDate,
    newStartDate,
  );

  return {
    ...segments,
    [SEGMENT_TYPE_PRIMARY_PHASE]: [origPhase],
    ...editorData,
  };
};


// Returns a boolean to decide whether or not to open the phase date modal
const phaseModalShouldOpen = (project, updatedPhase) => {
  if (!(
    objectIsSegment(project) &&
    objectIsSegment(updatedPhase)
  )) return false;

  const origPhase = project.phases.find(phase => phase.id === updatedPhase.id);
  const { startDate: currentStartDate, endDate: currentEndDate } = origPhase;
  const { startDate: newStartDate, endDate: newEndDate } = updatedPhase;
  const { phases, roles } = project;

  const dateUnchanged = stringToMoment(currentStartDate).isSame(newStartDate) &&
    stringToMoment(currentEndDate).isSame(newEndDate);

  if (dateUnchanged) {
    return false;
  }

  const { editAction, dateUnderEdit } = getDateEditOptions(currentStartDate, currentEndDate, newStartDate, newEndDate);

  // Always open the modal if extending via end date
  if (editAction === EDIT_ACTION_EXTENDED && dateUnderEdit === END_DATE) {
    return true;
  }

  const filteredPhases = phases.filter(phase => phase.id !== updatedPhase.id);
  const combinedSegments = [...filteredPhases, ...roles];

  // When a start date is edited, are there any other phases/roles
  // with a start date the same as the original start date and an
  // end date that is after the new start date?
  if (dateUnderEdit === START_DATE) {
    return combinedSegments.some(segment => (
      stringToMoment(segment.startDate).isSame(currentStartDate) &&
      stringToMoment(segment.endDate).isAfter(newStartDate)
    ));
  }

  // When an end date is edited, are there any other phases/roles
  // with end dates on or after the original end date AND with a start date on
  // or before the new end date OR with a start date the same or after the original end date
  if (dateUnderEdit === END_DATE) {
    return combinedSegments.some(segment => (
      (stringToMoment(segment.endDate).isSameOrAfter(currentEndDate) &&
        stringToMoment(segment.startDate).isSameOrBefore(newEndDate)) ||
        stringToMoment(segment.startDate).isSameOrAfter(currentEndDate)
    ));
  }

  return false;
};

function getUpdatedSegments(segments, segmentType, segmentId, typeUnderEdit, offset) {
  // Set selected segment
  const selectedSegmentGroup = segments[segmentType]?.reduce((segmentAcc, current) => {
    if (current.id === segmentId) {
      segmentAcc.push({ ...current, checked: !current.checked });
    } else {
      segmentAcc.push(current);
    }

    return segmentAcc;
  }, []) || [];

  if (typeUnderEdit === SEGMENT_TYPE_PROJECT) {
    return {
      [segmentType]: selectedSegmentGroup,
    };
  }

  if (segmentType === SEGMENT_TYPE_PROJECT) {
    const projectSegment = segments[SEGMENT_TYPE_PROJECT]?.[0];
    const { endDate: projectEnd } = projectSegment;

    const setSegmentCheckboxes = (segmentGroup = []) => (
      segmentGroup.reduce((segmentAcc, current) => {
        const offsetEndDate = dateStringAdd(current.endDate, offset);

        if (stringToMoment(projectEnd).isBefore(offsetEndDate)) {
          if (projectSegment.checked) {
            segmentAcc.push({ ...current, checked: false, disabled: true });
          } else {
            segmentAcc.push({ ...current, disabled: false });
          }
        } else {
          segmentAcc.push(current);
        }

        return segmentAcc;
      }, [])
    );

    const updatedPhaseSegments = setSegmentCheckboxes(segments[SEGMENT_TYPE_PHASE]);
    const updatedRoleSegments = setSegmentCheckboxes(segments[SEGMENT_TYPE_ROLE]);

    return {
      ...segments,
      [SEGMENT_TYPE_PROJECT]: [{ ...projectSegment, checked: !projectSegment.checked }],
      [SEGMENT_TYPE_PHASE]: updatedPhaseSegments,
      [SEGMENT_TYPE_ROLE]: updatedRoleSegments,
    };
  }

  return {
    [segmentType]: selectedSegmentGroup,
  };
}

export {
  getDefaultEditorConfig,
  getProjectEditorConfig,
  getPhaseEditorConfig,
  getAffectedProjectEntities,
  getAffectedPhaseEntities,
  getDateEditOptions,
  setSegmentFlags,
  getProjectEditorData,
  getPhaseEditorData,
  buildTimelineDates,
  getShiftedColor,
  getGroupData,
  extendSegmentDates,
  collapseSegmentDates,
  phaseModalShouldOpen,
  isPrimary,
  isUneditableProjectBar,
  extendingByEndDate,
  isSecondaryProjectBar,
  shouldShiftDates,
  getUpdatedSegments,
  shiftSegmentDates,
  getSingleSegmentDates,
};
