import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PEOPLE_DELETE_PERSON_NOTE_BEGIN,
  PEOPLE_DELETE_PERSON_NOTE_SUCCESS,
  PEOPLE_DELETE_PERSON_NOTE_FAILURE,
  PEOPLE_DELETE_PERSON_NOTE_DISMISS_ERROR,
} from './constants';
import { protectedJsonDelete } from '../../../utils/api';

export function deletePersonNote(accountId, personId, noteId, analyticsPayload) {
  return {
    type: PEOPLE_DELETE_PERSON_NOTE_BEGIN,
    accountId,
    personId,
    noteId,
    analyticsPayload,
  };
}

export function dismissDeletePersonNoteError() {
  return {
    type: PEOPLE_DELETE_PERSON_NOTE_DISMISS_ERROR,
  };
}

export function* doDeletePersonNote(action) {
  const { accountId, personId, noteId, analyticsPayload } = action;
  let res;
  try {
    res = yield call(protectedJsonDelete, `accounts/${accountId}/persons/${personId}/notes/${noteId}`);
  } catch (err) {
    yield put({
      type: PEOPLE_DELETE_PERSON_NOTE_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PEOPLE_DELETE_PERSON_NOTE_SUCCESS,
    data: res,
    personId,
    noteId,
    analyticsPayload,
  });
}

export function* watchDeletePersonNote() {
  yield takeLatest(PEOPLE_DELETE_PERSON_NOTE_BEGIN, doDeletePersonNote);
}

export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_DELETE_PERSON_NOTE_BEGIN:
      return {
        ...state,
        deletePersonNotePending: true,
        deletePersonNoteError: null,
      };

    case PEOPLE_DELETE_PERSON_NOTE_SUCCESS: {
      const { personId, noteId } = action;
      const { personSelections } = state;

      const newPersonSelections = { ...personSelections };
      Object.keys(personSelections).forEach((selectionId) => {
        const selectedPerson = personSelections[selectionId];
        if (selectedPerson && selectedPerson.id === personId) {
          const updatedNotes = selectedPerson?.notes
            ? selectedPerson.notes.filter(p => p.id !== noteId)
            : [];
          newPersonSelections[selectionId] = {
            ...selectedPerson,
            notes: updatedNotes,
          };
        }
      });

      return {
        ...state,
        deletePersonNotePending: false,
        deletePersonNoteError: null,
        personSelections: newPersonSelections,
      };
    }

    case PEOPLE_DELETE_PERSON_NOTE_FAILURE:
      return {
        ...state,
        deletePersonNotePending: false,
        deletePersonNoteError: action.data.error,
      };

    case PEOPLE_DELETE_PERSON_NOTE_DISMISS_ERROR:
      return {
        ...state,
        deletePersonNoteError: null,
      };

    default:
      return state;
  }
}
