import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setPeopleFilterData,
  setProjectFilterData,
  setHourlyPersonFilterData,
} from '../redux/actions';

const usePeopleFilter = () => {
  const dispatch = useDispatch();
  const { personFields, accountModules } = useSelector(state => state.accountSettings);
  const { projectNames } = useSelector(state => state.projects);
  const { accountId } = useSelector(state => state.common);
  const { permissions } = useSelector(({ login: { userInfo } }) => userInfo);

  useEffect(() => {
    if (personFields && accountModules) {
      dispatch(setPeopleFilterData(accountId, personFields, projectNames, accountModules, permissions));
    }
  }, [personFields, projectNames, accountModules, accountId, dispatch, permissions]);
};

const useProjectFilter = () => {
  const dispatch = useDispatch();

  const { projectFields, phases, roles, accountModules } = useSelector(state => state.accountSettings);
  const projectDescriptor = useSelector(state => state.table?.descriptors?.projects?.projectDescriptor);

  useEffect(() => {
    if (projectFields) {
      dispatch(setProjectFilterData(projectDescriptor, projectFields, accountModules, phases, roles));
    }
  }, [projectDescriptor, projectFields, phases, roles, dispatch, accountModules]);
};

const useHourlyPersonFilter = () => {
  const dispatch = useDispatch();
  const { hourlyPersonFields } = useSelector(state => state.accountSettings);

  useEffect(() => {
    if (hourlyPersonFields) {
      dispatch(setHourlyPersonFilterData(hourlyPersonFields));
    }
  }, [hourlyPersonFields, dispatch]);
};

export {
  usePeopleFilter,
  useProjectFilter,
  useHourlyPersonFilter,
};
