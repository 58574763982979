import {
  g500traineeGreen,
  b500bridgitBlue,
  p500bridgitPurple,
} from '@bridgit/foundation';

import {
  ACTIVE,
  UPCOMING,
  PURSUIT,
} from '../../projects/constants';
import {
  FILLED_ROLES,
  UNFILLED_ROLES,
} from '../../../filters/constants';

const DEACTIVATED = 'Deactivated';

const PROJECT_STATE_COLOR_MAP = {
  [ACTIVE]: g500traineeGreen,
  [UPCOMING]: b500bridgitBlue,
  [PURSUIT]: p500bridgitPurple,
};

const PROJECT_GANTT_SELECTION_ID = 'PROJECT_GANTT_SELECTION_ID_V2';

const VIEW_ROLES = 'VIEW_ROLES';
const DEFAULT_VIEW_ROLES = true;

const SORT_ROLES_BY_NAME = 'SORT_ROLES_BY_NAME';
const SORT_ROLES_BY_START_DATE = 'SORT_ROLES_BY_START_DATE';
const SORT_ROLES_BY_NAME_VALUE = 'sortOrder';
const SORT_ROLES_BY_START_DATE_VALUE = 'startDate';

const SORT_ROLES_BY_DISPLAY_MAP = {
  [SORT_ROLES_BY_NAME]: 'Name',
  [SORT_ROLES_BY_START_DATE]: 'Start Date',
  [SORT_ROLES_BY_NAME_VALUE]: 'Name',
  [SORT_ROLES_BY_START_DATE_VALUE]: 'Start Date',
};

const SORT_ROLES_BY_MAP = {
  [SORT_ROLES_BY_NAME]: SORT_ROLES_BY_NAME_VALUE,
  [SORT_ROLES_BY_START_DATE]: SORT_ROLES_BY_START_DATE_VALUE,
  [SORT_ROLES_BY_NAME_VALUE]: SORT_ROLES_BY_NAME,
  [SORT_ROLES_BY_START_DATE_VALUE]: SORT_ROLES_BY_START_DATE,
};

const DEFAULT_ROLE_SORT = SORT_ROLES_BY_START_DATE_VALUE;

const DEFAULT_ROLE_FILTER = [];

const QUICK_ROLE_FILTERS = [FILLED_ROLES, UNFILLED_ROLES];

const ROLE_LEGEND_CLASS = 'LegendRowRole';

export {
  DEACTIVATED,
  PROJECT_STATE_COLOR_MAP,
  PROJECT_GANTT_SELECTION_ID,
  VIEW_ROLES,
  DEFAULT_VIEW_ROLES,
  SORT_ROLES_BY_NAME,
  SORT_ROLES_BY_START_DATE,
  SORT_ROLES_BY_DISPLAY_MAP,
  SORT_ROLES_BY_MAP,
  DEFAULT_ROLE_SORT,
  DEFAULT_ROLE_FILTER,
  QUICK_ROLE_FILTERS,
  ROLE_LEGEND_CLASS,
};
