import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  ClickAwayListener,
  Select,
} from '@material-ui/core';
import { AttachMoney } from '@material-ui/icons';
import { RangeTextField } from '.';
import { CustomPopper, EditControls } from '../wrapped-components';
import { formatDollarValue } from '../../utils/formatters';
import {
  BLANK_FILTER,
  ABOVE_FILTER,
  BELOW_FILTER,
  BETWEEN_FILTER,
  EQUALS_FILTER,
} from '../../filters/constants';
import { setPopperIsOpen } from '../filters/redux/actions';

function CurrencyFilterPopper({
  open,
  anchorEl,
  title,
  onApply,
  onCancel,
  placeholder,
  origSelected,
  setPopperIsOpen,
}) {
  const origVerb = origSelected.length ? origSelected[0].verb : ABOVE_FILTER;
  const origValue = origSelected.length ? origSelected[0].selected[0].value : '';
  const origSecondary = origSelected.length && origSelected[0].verb === BETWEEN_FILTER ? origSelected[0].selected[0].secondaryValue : '';
  const [verb, setVerb] = useState(origVerb);
  const [value, setValue] = useState(origValue);
  const [secondaryValue, setSecondaryValue] = useState(origSecondary);
  const [disableApply, setDisableApply] = useState(true);

  const isInvalid = (newValue, newSecondary, newVerb = verb) => {
    if (newVerb === BLANK_FILTER && newVerb !== origVerb) {
      return false;
    }

    if (newVerb !== BETWEEN_FILTER) {
      return newValue === '' || (newVerb === origVerb && newValue === origValue);
    }

    return newValue === '' || newSecondary === '' || (newVerb === origVerb && newValue === origValue && newSecondary === origSecondary);
  };

  const onVerbChange = (evt) => {
    setVerb(evt.target.value);
    setDisableApply(isInvalid(value, secondaryValue, evt.target.value));
  };

  const onValueChange = isSecondary => (evt) => {
    if (isSecondary) {
      setSecondaryValue(evt.target.value);
      setDisableApply(isInvalid(value, evt.target.value));
    } else {
      setValue(evt.target.value);
      setDisableApply(isInvalid(evt.target.value, secondaryValue));
    }
  };

  const onClickAway = (evt) => {
    const { target: { className } } = evt;
    if (className && typeof className === 'string' && (className.indexOf('verb-select') !== -1 || className.indexOf('MuiBackdrop') !== -1)) {
      return;
    }
    onCancel();
    setPopperIsOpen(false);
  };

  const onFilterApply = () => {
    const selected = [];

    if (verb === BLANK_FILTER) {
      selected.push({ name: BLANK_FILTER, value: '' });
    } else if (verb === BETWEEN_FILTER) {
      const min = Math.min(value, secondaryValue);
      const max = Math.max(value, secondaryValue);
      selected.push({
        name: `${formatDollarValue(min)} - ${formatDollarValue(max)}`,
        value,
        secondaryValue,
      });
    } else {
      selected.push({
        name: formatDollarValue(value),
        value,
      });
    }

    onApply([{
      verb,
      selected,
    }]);
    setDisableApply(true);
  };

  return (
    <CustomPopper
      popperOpen={open}
      anchorEl={anchorEl}
      classes="common-currency-filter-popper"
      placement="top-start"
      hideArrow
      preventBubbling
    >
      <ClickAwayListener onClickAway={onClickAway}>
        <div className="content-wrap">
          <div className="header">
            <div className="title">{title}</div>
          </div>
          <div className="select-wrap">
            <Select
              MenuProps={{ className: 'currency-filter-popper-select' }}
              value={verb}
              onChange={onVerbChange}
              fullWidth
              native
            >
              <option className="verb-select" value={ABOVE_FILTER}>Above</option>
              <option className="verb-select" value={BELOW_FILTER}>Below</option>
              <option className="verb-select" value={BETWEEN_FILTER}>Between</option>
              <option className="verb-select" value={EQUALS_FILTER}>Equals</option>
              <option className="verb-select blank" value={BLANK_FILTER}>{BLANK_FILTER}</option>
            </Select>
          </div>
          <RangeTextField
            verb={verb}
            placeholder={placeholder}
            value={value}
            secondaryValue={secondaryValue}
            onValueChange={onValueChange}
            startAdornment={<AttachMoney />}
          />
          <div className="footer">
            <EditControls
              primaryAction={onFilterApply}
              secondaryAction={onCancel}
              disabled={disableApply}
              primaryText="Apply"
              secondaryText="Cancel"
            />
          </div>
        </div>
      </ClickAwayListener>
    </CustomPopper>
  );
}

CurrencyFilterPopper.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.object,
  title: PropTypes.string.isRequired,
  onApply: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  origSelected: PropTypes.array,
  setPopperIsOpen: PropTypes.func.isRequired,
};

CurrencyFilterPopper.defaultProps = {
  anchorEl: null,
  placeholder: '',
  origSelected: [],
};

function mapDispatchToProps(dispatch) {
  return {
    setPopperIsOpen: bindActionCreators(setPopperIsOpen, dispatch),
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(CurrencyFilterPopper);
