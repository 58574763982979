import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import pluralize from 'pluralize';
import { Tune } from '@material-ui/icons';
import { ChipBowl } from 'src/features/table';
import { SideSlideMenu, SideSlideMenuButton } from '@bridgit/foundation';
import { FILTER_STORAGE_KEY, SIDE_PANEL_OPEN_KEY, getStorageKey } from 'src/common/localStorageKeys';
import { PEOPLE_VIEW } from 'src/common/constants';
import { trackAnalytics } from 'src/features/common/redux/actions';
import { getValidatedLocalStorage } from '../../utils/validators';
import { GANTT_CONTROLS_DEFAULT_OPTIONS } from './redux/constants';
import {
  filterClearEvent,
  filterClearPayload,
  filterChipRemoveEvent,
  filterChipRemovePayload,
} from '../../analytics/mixpanel/filterChips';

function GanttControls({
  accountId,
  userId,
  activeView,
  options,
  useClearText,
  queryId,
  sliderContent,
  resultCount,
}) {
  const [displayOptions, setDisplayOptions] = useState({ ...GANTT_CONTROLS_DEFAULT_OPTIONS, ...options });
  const [sidePanelOpen, setSidePanelOpen] = useState(false);

  const dispatch = useDispatch();

  const view = activeView === PEOPLE_VIEW ? 'People' : 'Project';
  const { showChips } = displayOptions;

  useEffect(() => {
    setDisplayOptions({ ...GANTT_CONTROLS_DEFAULT_OPTIONS, ...options });
  }, [options]);

  const filterStorageKey = useMemo(() => (
    getStorageKey(activeView, accountId, FILTER_STORAGE_KEY, userId)
  ), [accountId, activeView, userId]);

  const sidePanelOpenKey = useMemo(() => (
    getStorageKey(activeView, accountId, SIDE_PANEL_OPEN_KEY, userId)
  ), [accountId, activeView, userId]);

  const defaultSidePanelOpen = useMemo(() => {
    const isOpened = getValidatedLocalStorage(sidePanelOpenKey);
    return isOpened ?? true;
  }, [sidePanelOpenKey]);

  // Set default side panel open state
  useEffect(() => {
    setSidePanelOpen(defaultSidePanelOpen);
  }, [defaultSidePanelOpen]);

  const trackSlideMenuState = (open) => {
    dispatch(trackAnalytics(`${view} Gantt - Gantt settings panel selected`, {
      Opened: open,
    }));
    localStorage.setItem(sidePanelOpenKey, open);
  };

  const onSlideMenuToggle = () => {
    trackSlideMenuState(!sidePanelOpen);
    setSidePanelOpen(!sidePanelOpen);
  };

  const renderSideButtons = () => (
    <SideSlideMenuButton
      tooltip="Gantt settings"
      icon={<Tune />}
      isActive={sidePanelOpen}
      onClick={onSlideMenuToggle}
    />
  );

  const filterChips = useMemo(() => {
    if (!showChips) return null;

    const onClear = () => dispatch(trackAnalytics(filterClearEvent(view), filterClearPayload(`${view} Gantt`)));
    const onUpdate = () => dispatch(trackAnalytics(filterChipRemoveEvent(view), filterChipRemovePayload(`${view} Gantt`)));

    return (
      <ChipBowl
        useClearText={useClearText}
        queryId={queryId}
        filterStorageKey={filterStorageKey}
        onClearFilters={onClear}
        onUpdateFilters={onUpdate}
        filterType={view}
      />
    );
  }, [showChips, filterStorageKey, queryId, useClearText, view, dispatch]);

  return (
    <>
      <div className="gantt-gantt-controls">
        <div className="pre-table">
          {filterChips}
          <div className="total-results">
            {pluralize(view, resultCount, true)}
          </div>
        </div>
      </div>
      <SideSlideMenu
        open={sidePanelOpen}
        hide={!sliderContent}
        onSlideMenuToggle={onSlideMenuToggle}
        top={88} // related to @app-header-height, @app-page-header-height
        renderSideButtons={renderSideButtons}
      >
        {sliderContent}
      </SideSlideMenu>
    </>
  );
}

GanttControls.propTypes = {
  options: PropTypes.object,
  accountId: PropTypes.number.isRequired,
  userId: PropTypes.string.isRequired,
  activeView: PropTypes.number.isRequired,
  useClearText: PropTypes.bool,
  queryId: PropTypes.string.isRequired,
  sliderContent: PropTypes.node,
  resultCount: PropTypes.number.isRequired,
};

GanttControls.defaultProps = {
  options: {},
  useClearText: true,
  sliderContent: null,
};

function mapStateToProps({ common, login }) {
  const { activeView, contentView, accountId } = common;
  const { sub: userId } = login.userInfo;

  return {
    accountId,
    userId,
    contentView,
    activeView,
  };
}

export default connect(mapStateToProps)(GanttControls);
