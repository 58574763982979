import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Switch } from '@material-ui/core';

import { toggleSystemThemeSwitch } from './redux/actions';
import { THEME_OS_SYSTEM_THEME_SELECTED } from '../../analytics/themes/constants';

const SystemThemeSwitch = () => {
  const dispatch = useDispatch();
  const userId = useSelector(state => state.login.userInfo.sub || null);
  const isUsingSystemTheme = useSelector(state => state.themes.isUsingSystemTheme);

  const handleToggleSwitch = () => {
    const analyticsPayload = { eventType: THEME_OS_SYSTEM_THEME_SELECTED };

    dispatch(toggleSystemThemeSwitch(!isUsingSystemTheme, userId, analyticsPayload));
  };

  return (
    <div className="theme-settings-system-theme-container">
      <Typography gutterBottom className="theme-settings-system-theme-switch-title">
        Use OS system theme
      </Typography>
      <Switch
        checked={isUsingSystemTheme}
        onChange={handleToggleSwitch}
        value={isUsingSystemTheme}
        color="primary"
      />
    </div>
  );
};

export default SystemThemeSwitch;
