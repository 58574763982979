import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNT_SETTINGS_GET_ROLE_NAMES_BEGIN,
  ACCOUNT_SETTINGS_GET_ROLE_NAMES_SUCCESS,
  ACCOUNT_SETTINGS_GET_ROLE_NAMES_FAILURE,
  ACCOUNT_SETTINGS_GET_ROLE_NAMES_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';

export function getRoleNames(accountId) {
  return {
    type: ACCOUNT_SETTINGS_GET_ROLE_NAMES_BEGIN,
    accountId,
  };
}

export function dismissGetRoleNamesError() {
  return {
    type: ACCOUNT_SETTINGS_GET_ROLE_NAMES_DISMISS_ERROR,
  };
}

export function* doGetRoleNames(action) {
  const { accountId } = action;
  let res;
  try {
    res = yield call(protectedJsonGet, `accounts/${accountId}/role-names`);
  } catch (err) {
    yield put({
      type: ACCOUNT_SETTINGS_GET_ROLE_NAMES_FAILURE,
      data: { error: err },
    });
    return;
  }
  yield put({
    type: ACCOUNT_SETTINGS_GET_ROLE_NAMES_SUCCESS,
    data: res,
  });
}

export function* watchGetRoleNames() {
  yield takeLatest(ACCOUNT_SETTINGS_GET_ROLE_NAMES_BEGIN, doGetRoleNames);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNT_SETTINGS_GET_ROLE_NAMES_BEGIN:
      return {
        ...state,
        getRoleNamesPending: true,
        getRoleNamesError: null,
      };

    case ACCOUNT_SETTINGS_GET_ROLE_NAMES_SUCCESS:
      return {
        ...state,
        roles: action.data || [],
        getRoleNamesPending: false,
        getRoleNamesError: null,
      };

    case ACCOUNT_SETTINGS_GET_ROLE_NAMES_FAILURE:
      return {
        ...state,
        getRoleNamesPending: false,
        getRoleNamesError: action.data.error,
      };

    case ACCOUNT_SETTINGS_GET_ROLE_NAMES_DISMISS_ERROR:
      return {
        ...state,
        getRoleNamesError: null,
      };

    default:
      return state;
  }
}
