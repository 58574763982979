import { PERM_READ, PERM_PERSON } from 'src/features/permissions/utils/constants';

import { LoadableDashboard } from '.';

export default {
  path: 'accounts/:id/dashboard/:view?',
  name: 'Dashboard',
  component: LoadableDashboard,
  action: PERM_READ,
  subject: PERM_PERSON,
};
