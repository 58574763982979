import { call, put, takeLatest, all } from 'redux-saga/effects';
import { protectedJsonGet } from '../../../utils/api';
import {
  LOGIN_GET_USER_INFO_BEGIN,
  LOGIN_GET_USER_INFO_SUCCESS,
  LOGIN_GET_USER_INFO_FAILURE,
  LOGIN_GET_USER_INFO_DISMISS_ERROR,
} from './constants';
import { setUserInfo } from '../../../common/analyticsHelper';
import { selectLastVisitedAccountId } from '../../accounts/utils/accountUtils';

export function getUserInfo() {
  // If need to pass args to saga, pass it with the begin action.
  return {
    type: LOGIN_GET_USER_INFO_BEGIN,
  };
}

export function dismissGetUserInfoError() {
  return {
    type: LOGIN_GET_USER_INFO_DISMISS_ERROR,
  };
}

// worker Saga: will be fired on LOGIN_GET_USER_INFO_BEGIN actions
export function* doGetUserInfo() {
  let userInfo;
  let permissions;
  try {
    [userInfo, permissions] = yield all([
      call(protectedJsonGet, 'me'),
      call(protectedJsonGet, 'me/permissions'),
    ]);

    const { pathname } = window.location;
    const { sub } = userInfo;

    selectLastVisitedAccountId(pathname, sub);
  } catch (err) {
    yield put({
      type: LOGIN_GET_USER_INFO_FAILURE,
      data: { error: err },
    });
    return;
  }

  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: LOGIN_GET_USER_INFO_SUCCESS,
    data: {
      ...userInfo,
      permissions,
    },
  });
}

export function* watchGetUserInfo() {
  yield takeLatest(LOGIN_GET_USER_INFO_BEGIN, doGetUserInfo);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case LOGIN_GET_USER_INFO_BEGIN:
      return {
        ...state,
        getUserInfoPending: true,
        getUserInfoError: null,
      };

    case LOGIN_GET_USER_INFO_SUCCESS: {
      setUserInfo(action.data);
      return {
        ...state,
        userInfo: action.data,
        getUserInfoPending: false,
        getUserInfoError: null,
      };
    }
    case LOGIN_GET_USER_INFO_FAILURE:
      return {
        ...state,
        getUserInfoPending: false,
        getUserInfoError: action.data.error,
      };

    case LOGIN_GET_USER_INFO_DISMISS_ERROR:
      return {
        ...state,
        getUserInfoError: null,
      };

    default:
      return state;
  }
}
