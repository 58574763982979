import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Field, FormSpy } from 'react-final-form';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { Button, HelpTooltip, EditControls } from '@bridgit/foundation';

import DeleteCertificationModal from './DeleteCertificationModal';
import { isEmptyObject } from '../../utils/miscUtils';
import { addAccountCertification, updateAccountCertification } from './redux/actions';
import { generateAbbreviation, trimField } from '../../utils/formatters';
import {
  composeFormValidators,
  isRequired,
  isNumberInRange,
  isLengthInRange,
  isValueInUse,
} from '../../utils/validators';
import { AUTOMATION_CERTIFICATION_NAME_TEXTFIELD_ID, AUTOMATION_CERTIFICATION_SHORTFORM_TEXTFIELD_ID } from './ids';

const CertificationForm = ({ certification, onClose, onEditClose }) => {
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [abbreviationDefault, setAbbreviationDefault] = useState('');
  const { accountId } = useSelector(({ common }) => common);
  const { certifications } = useSelector(({ accountSettings }) => accountSettings);

  const toggleModal = useCallback(open => () => setIsModalOpen(open), []);

  const isEditing = useMemo(() => !!certification, [certification]);

  // Filter out the current certification so its name/abbreviation
  // doesn't cause a unique form error
  const uniqueCertifications = useMemo(
    () => (
      isEditing
        ? certifications.filter(cert => cert.id !== certification.id)
        : certifications
    ),
    [isEditing, certifications, certification],
  );

  // Default to a disabled form if not editing
  const [disabled, setDisabled] = useState(!isEditing);

  // Reset abbreviation on certification change
  useEffect(() => {
    setAbbreviationDefault(certification?.abbreviation || '');
  }, [certification]);

  const onSubmit = useCallback((data) => {
    const daysWarnBeforeExpireInt = parseInt(data.daysWarnBeforeExpire, 10);
    const actionData = {
      ...data,
      daysWarnBeforeExpire: data.requireWarningRange && !Number.isNaN(daysWarnBeforeExpireInt)
        ? daysWarnBeforeExpireInt
        : null,
    };

    if (isEditing) {
      dispatch(updateAccountCertification(accountId, certification.id, actionData));
      return onEditClose();
    }

    dispatch(addAccountCertification(accountId, actionData));
    return onClose();
  }, [accountId, certification, dispatch, isEditing, onClose, onEditClose]);

  const onNameChange = useCallback(onChange => (event) => {
    const name = event.target.value.trim();
    const abbreviation = generateAbbreviation(name);

    onChange(event);
    setAbbreviationDefault(abbreviation);
    setDisabled(!name.length);
  }, []);

  const renderNameTextField = useCallback(({ input, meta }) => (
    <>
      <TextField
        {...input}
        autoFocus
        onChange={onNameChange(input.onChange)}
        placeholder="Enter a title for this certification"
        label="Certification name"
        fullWidth
        inputProps={{ maxLength: 250 }}
        InputLabelProps={{ shrink: true }}
        id={AUTOMATION_CERTIFICATION_NAME_TEXTFIELD_ID}
      />
      <FormHelperText error={meta.touched}>{meta.error}</FormHelperText>
    </>
  ), [onNameChange]);

  const renderAbbreviationTextField = useCallback(({ input, meta: { touched, error } }) => (
    <>
      <TextField
        {...input}
        placeholder="Enter a short form"
        label={(
          <div className="certification-form-input-center-align">
            <span className="certification-form-abbreviation-input-label">Abbreviation</span>
            <HelpTooltip
              text="This short-form will represent the certification in areas where we're tight on space"
            />
          </div>
        )}
        fullWidth
        disabled={disabled}
        inputProps={{ maxLength: 6 }}
        InputLabelProps={{ shrink: true }}
        id={AUTOMATION_CERTIFICATION_SHORTFORM_TEXTFIELD_ID}
      />
      <FormHelperText error={touched}>{error}</FormHelperText>
    </>
  ), [disabled]);

  const renderExpirationCheckbox = useCallback(({ input }) => (
    <>
      <InputLabel disabled={disabled}>Expiration</InputLabel>
      <FormControlLabel
        // eslint-disable-next-line react/jsx-props-no-spreading
        control={<Checkbox {...input} disabled={disabled} color="primary" />}
        label="Expiration date required"
      />
    </>
  ), [disabled]);

  const renderWarningRangeCheckbox = useCallback(({ input }) => (
    <FormControlLabel
      // eslint-disable-next-line react/jsx-props-no-spreading
      control={<Checkbox {...input} disabled={disabled} color="primary" />}
      label="Expiration date warning range"
    />
  ), [disabled]);

  const renderWarningRangeTextField = useCallback(({ input, meta: { touched, error } }) => (
    <>
      <div className="certification-form-input-center-align">
        <InputLabel disabled={disabled}>Warning</InputLabel>
        <TextField
          {...input}
          disabled={disabled}
          className="certification-form-warning-range-input"
          inputProps={{ type: 'number', min: 1, max: 365 }}
        />
        <InputLabel disabled={disabled}>days before expiration</InputLabel>
      </div>
      <FormHelperText error={touched}>{error}</FormHelperText>
    </>
  ), [disabled]);

  return (
    <div className="account-settings-certification-form">
      <Form
        onSubmit={onSubmit}
        render={({ values, handleSubmit }) => (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  name="name"
                  validate={composeFormValidators(isRequired, isLengthInRange(1, 250), isValueInUse(uniqueCertifications, 'name'))}
                  format={trimField}
                  formatOnBlur
                  defaultValue={certification?.name || ''}
                  render={renderNameTextField}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name="abbreviation"
                  validate={composeFormValidators(isRequired, isLengthInRange(2, 6), isValueInUse(uniqueCertifications, 'abbreviation'))}
                  format={trimField}
                  formatOnBlur
                  defaultValue={abbreviationDefault}
                  render={renderAbbreviationTextField}
                />
              </Grid>

              <Grid container direction="column" item xs={12}>
                <Field
                  name="requireExpiration"
                  type="checkbox"
                  defaultValue={certification?.requireExpiration || false}
                  render={renderExpirationCheckbox}
                />
                <Field
                  name="requireWarningRange"
                  type="checkbox"
                  defaultValue={!!certification?.daysWarnBeforeExpire}
                  render={renderWarningRangeCheckbox}
                />
                {values.requireWarningRange && (
                  <Field
                    name="daysWarnBeforeExpire"
                    defaultValue={certification?.daysWarnBeforeExpire || 30}
                    validate={isNumberInRange(1, 365)}
                    render={renderWarningRangeTextField}
                  />
                )}
              </Grid>
            </Grid>

            <div className="certification-form-controls">
              <Divider className="certification-form-controls-divider" />
              <div className="certification-form-controls-layout">
                <FormSpy
                  subscription={{ errors: true }}
                  render={({ errors }) => (
                    <EditControls
                      primaryAction={handleSubmit}
                      secondaryAction={isEditing ? onEditClose : onClose}
                      disabled={!isEmptyObject(errors)}
                    />
                  )}
                />
                {isEditing && (
                  <Button onClick={toggleModal(true)} color="warning" variant="plain">Delete Certification</Button>
                )}
              </div>
            </div>
          </>
        )}
      />

      {isEditing && (
        <DeleteCertificationModal
          open={isModalOpen}
          onClose={toggleModal(false)}
          certificationId={certification.id}
        />
      )}
    </div>
  );
};

CertificationForm.propTypes = {
  certification: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onEditClose: PropTypes.func.isRequired,
};

CertificationForm.defaultProps = {
  certification: null,
};

export default CertificationForm;
