function getMousePosition(event, verticalOffset = 0) {
  const { target, pageX } = event;
  const element = target.getBoundingClientRect();
  return {
    getBoundingClientRect: () => ({
      top: element.top,
      right: pageX,
      bottom: element.bottom + verticalOffset,
      left: pageX,
      width: 0,
      height: 0,
    }),
    clientWidth: 0,
    clientHeight: 0,
  };
}

function infiniteScrollWatcher(element, loadMore, loading, pagingError) {
  const { target } = element;

  const scrollAmount = target.scrollHeight - target.scrollTop;
  const bottomBuffer = 100;
  const isNearBottom = scrollAmount - bottomBuffer <= target.clientHeight;

  if (!loading && isNearBottom && !pagingError) {
    loadMore();
  }
}

function getDiagonalHashStyle(baseColor, hashColor) {
  return `repeating-linear-gradient(-45deg, ${hashColor}, ${hashColor} 5px, ${baseColor} 5px, ${baseColor} 12px)`;
}

export {
  getMousePosition,
  infiniteScrollWatcher,
  getDiagonalHashStyle,
};
