import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { ICON_MAP, OAUTH_BUTTONS, OAUTH_URL_MAP } from './constants';
import { capitalize } from '../../utils/formatters';

export default class LoginSignup extends PureComponent {
  static propTypes = {
    headline: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    viaEmail: PropTypes.func.isRequired,
    acceptInvitationOauth: PropTypes.func,
    oauthError: PropTypes.number,
  };

  static defaultProps = {
    oauthError: null,
    acceptInvitationOauth: () => {},
  }

  oAuthSignup = type => () => {
    const { acceptInvitationOauth } = this.props;
    acceptInvitationOauth(type);
  }

  onOAuthClick = type => () => {
    const authType = { 'Auth Type': `${type}` };
    window.mixpanel.track('Login', authType);
    if (window.Appcues) window.Appcues.track('Login', authType);
  }

  render() {
    const { headline, type, viaEmail, oauthError } = this.props;
    const isSignup = type === 'Sign up';

    return (
      <Grid container justify="center" className="login-login-signup">
        <div className="welcome-banner">
          <div className="welcome-text">Welcome to Bridgit Bench</div>
        </div>

        <div>
          <Paper component="section" variant="outlined" className="spacious-container">
            <Typography
              className="headline"
              variant="subtitle1"
              component="h4"
              gutterBottom
            >
              {headline}
            </Typography>

            {!!oauthError && (
              <Typography
                className="typography--300 oauth-error"
                color="secondary"
                variant="caption"
                component="h4"
                gutterBottom
              >
                We are unable to log you in. Please try again or contact support.
              </Typography>
            )}

            <Button
              onClick={viaEmail}
              className="button__default email-button"
              disableRipple
              color="inherit"
              size="large"
              type="submit"
              variant="contained"
              fullWidth
            >
              {isSignup ? 'Create an account using your email' : 'Log in with Email'}
            </Button>

            <p className="divider__container">
              <Typography className="login-divider" component="span">Or continue with</Typography>
            </p>

            { isSignup
              ? (
                <div className="button-container">
                  {OAUTH_BUTTONS.map(provider => (
                    <Button
                      key={provider}
                      onClick={this.oAuthSignup(provider)}
                      className={classNames('authenticate', provider)}
                      disableRipple
                      color="primary"
                      size="large"
                      variant="contained"
                      fullWidth
                    >
                      <img alt={`${provider} Logo`} src={ICON_MAP[provider]} />
                      {provider}
                    </Button>
                  ))}
                </div>
              )
              : (
                <div className="button-container">
                  {OAUTH_BUTTONS.map(provider => (
                    <a
                      key={provider}
                      href={OAUTH_URL_MAP[provider]}
                      className={classNames('authenticate', provider)}
                      onClick={this.onOAuthClick(capitalize(provider))}
                    >
                      <img alt={`${provider} Logo`} src={ICON_MAP[provider]} />
                      {provider}
                    </a>
                  ))}
                </div>
              )}

          </Paper>
        </div>
      </Grid>
    );
  }
}
