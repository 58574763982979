import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PEOPLE_SEARCH_ACTIVE_PEOPLE_BEGIN,
  PEOPLE_SEARCH_ACTIVE_PEOPLE_SUCCESS,
  PEOPLE_SEARCH_ACTIVE_PEOPLE_FAILURE,
  PEOPLE_SEARCH_ACTIVE_PEOPLE_DISMISS_ERROR,
} from './constants';
import graphApiClient from '../../../common/GraphAPIClient';
import { searchActivePeopleQuery } from '../queries/searchActivePeopleQuery';
import initialState from './initialState';

export function searchActivePeople(accountId, searchQuery) {
  const { query, variables } = searchActivePeopleQuery(accountId, searchQuery);

  return {
    type: PEOPLE_SEARCH_ACTIVE_PEOPLE_BEGIN,
    query,
    variables,
  };
}

export function dismissSearchActivePeopleError() {
  return {
    type: PEOPLE_SEARCH_ACTIVE_PEOPLE_DISMISS_ERROR,
  };
}

export function* doSearchActivePeople(action) {
  const { query, variables } = action;

  let res;
  try {
    res = yield call(graphApiClient.query, { query, variables });
    const { errors } = res;
    if (errors) {
      throw errors;
    }
  } catch (err) {
    yield put({
      type: PEOPLE_SEARCH_ACTIVE_PEOPLE_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PEOPLE_SEARCH_ACTIVE_PEOPLE_SUCCESS,
    data: res.data,
  });
}

export function* watchSearchActivePeople() {
  yield takeLatest(PEOPLE_SEARCH_ACTIVE_PEOPLE_BEGIN, doSearchActivePeople);
}

export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_SEARCH_ACTIVE_PEOPLE_BEGIN:
      return {
        ...state,
        searchActivePeoplePending: true,
        searchActivePeopleError: null,
      };

    case PEOPLE_SEARCH_ACTIVE_PEOPLE_SUCCESS: {
      const items = action.data.people?.items ?? [];

      return {
        ...state,
        searchedActivePeople: items,
        searchActivePeoplePending: false,
        searchActivePeopleError: null,
      };
    }

    case PEOPLE_SEARCH_ACTIVE_PEOPLE_FAILURE: {
      const { searchedActivePeople } = initialState;
      return {
        ...state,
        searchedActivePeople,
        searchActivePeoplePending: false,
        searchActivePeopleError: action.data.error,
      };
    }

    case PEOPLE_SEARCH_ACTIVE_PEOPLE_DISMISS_ERROR:
      return {
        ...state,
        searchActivePeopleError: null,
      };

    default:
      return state;
  }
}
