import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  SORT_STORAGE_KEY,
  COLUMN_ORDER_STORAGE_KEY,
  COLUMN_STORAGE_KEY,
  getStorageKey,
} from 'src/common/localStorageKeys';
import { getInitialSort } from '../table/filterUtils';
import { setTableConfig } from '../table/redux/actions';
import { setSortQuery, clearQueries } from '../queries/redux/actions';
import { setFilterMenuVisibility } from './redux/actions';

const FilterManager = ({
  setTableConfig,
  getFilteredItems,
  accountId,
  queries,
  children,
  setSortQuery,
  clearQueries,
  userId,
  setFilterMenuVisibility,
  view,
  defaultSort,
  tableConfig,
  queryId,
  getFilteredItemsData,
  getFilteredItemsError,
}) => {
  useEffect(() => {
    const columnStorageKey = getStorageKey(view, accountId, COLUMN_STORAGE_KEY, userId);
    const columnOrderStorageKey = getStorageKey(view, accountId, COLUMN_ORDER_STORAGE_KEY, userId);
    setTableConfig(tableConfig, columnStorageKey, columnOrderStorageKey);
  }, [setTableConfig, view, accountId, userId, tableConfig]);

  useEffect(() => {
    const sortStorageKey = getStorageKey(view, accountId, SORT_STORAGE_KEY, userId);
    const currentSort = getInitialSort(sortStorageKey, defaultSort);
    setSortQuery(queryId, currentSort, sortStorageKey);
  }, [setSortQuery, defaultSort, view, accountId, userId, queryId]);

  const isFirstRender = useRef(true);
  const filterQuery = queries?.filter?.args;
  const sortQuery = queries?.sort?.args;
  const searchQuery = queries?.search?.args;

  useEffect(() => {
    if (isFirstRender.current || getFilteredItemsError) {
      isFirstRender.current = false;
    } else {
      // Only fetch data when actual queries change, hence the rebuild here.
      const queries = { filter: { args: filterQuery }, sort: { args: sortQuery }, search: { args: searchQuery } };
      getFilteredItems(accountId, queries, 0, getFilteredItemsData);
    }
  }, [filterQuery, sortQuery, searchQuery, accountId, getFilteredItems, getFilteredItemsData, getFilteredItemsError]);

  // on component unmount, clear queries and hide filter menu
  useEffect(() => () => {
    clearQueries(queryId);
    setFilterMenuVisibility(false);
  }, [clearQueries, setFilterMenuVisibility, queryId]);

  return children;
};

FilterManager.propTypes = {
  setTableConfig: PropTypes.func.isRequired,
  getFilteredItems: PropTypes.func.isRequired,
  accountId: PropTypes.number.isRequired,
  queries: PropTypes.object,
  children: PropTypes.node,
  setSortQuery: PropTypes.func.isRequired,
  clearQueries: PropTypes.func.isRequired,
  userId: PropTypes.string,
  setFilterMenuVisibility: PropTypes.func.isRequired,
  view: PropTypes.number.isRequired,
  defaultSort: PropTypes.object.isRequired,
  tableConfig: PropTypes.object.isRequired,
  queryId: PropTypes.string.isRequired,
  getFilteredItemsData: PropTypes.object,
  getFilteredItemsError: PropTypes.bool,
};

FilterManager.defaultProps = {
  children: null,
  userId: '',
  queries: {},
  getFilteredItemsData: undefined,
  getFilteredItemsError: false,
};

/* istanbul ignore next */
function mapStateToProps({
  common: { accountId },
  login: { userInfo: { sub: userId } },
}) {
  return {
    accountId,
    userId,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    setTableConfig: bindActionCreators(setTableConfig, dispatch),
    setSortQuery: bindActionCreators(setSortQuery, dispatch),
    clearQueries: bindActionCreators(clearQueries, dispatch),
    setFilterMenuVisibility: bindActionCreators(setFilterMenuVisibility, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FilterManager);
