import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNT_SETTINGS_GET_CERTIFICATION_BY_ID_BEGIN,
  ACCOUNT_SETTINGS_GET_CERTIFICATION_BY_ID_SUCCESS,
  ACCOUNT_SETTINGS_GET_CERTIFICATION_BY_ID_FAILURE,
  ACCOUNT_SETTINGS_GET_CERTIFICATION_BY_ID_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';

export function getAccountCertificationById(accountId, certificationId) {
  return {
    type: ACCOUNT_SETTINGS_GET_CERTIFICATION_BY_ID_BEGIN,
    accountId,
    certificationId,
  };
}

export function dismissGetAccountCertificationByIdError() {
  return {
    type: ACCOUNT_SETTINGS_GET_CERTIFICATION_BY_ID_DISMISS_ERROR,
  };
}

export function* doGetAccountCertificationById(action) {
  const { accountId, certificationId } = action;
  let res;

  try {
    res = yield call(protectedJsonGet, `accounts/${accountId}/certifications/${certificationId}`);
  } catch (err) {
    yield put({
      type: ACCOUNT_SETTINGS_GET_CERTIFICATION_BY_ID_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: ACCOUNT_SETTINGS_GET_CERTIFICATION_BY_ID_SUCCESS,
    data: res,
  });
}

export function* watchGetAccountCertificationById() {
  yield takeLatest(ACCOUNT_SETTINGS_GET_CERTIFICATION_BY_ID_BEGIN, doGetAccountCertificationById);
}

export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNT_SETTINGS_GET_CERTIFICATION_BY_ID_BEGIN:
      return {
        ...state,
        getAccountCertificationByIdPending: true,
        getAccountCertificationByIdError: null,
      };

    case ACCOUNT_SETTINGS_GET_CERTIFICATION_BY_ID_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        selectedCertification: data,
        getAccountCertificationByIdPending: false,
        getAccountCertificationByIdError: null,
      };
    }

    case ACCOUNT_SETTINGS_GET_CERTIFICATION_BY_ID_FAILURE:
      return {
        ...state,
        getAccountCertificationByIdPending: false,
        getAccountCertificationByIdError: action.data.error,
      };

    case ACCOUNT_SETTINGS_GET_CERTIFICATION_BY_ID_DISMISS_ERROR:
      return {
        ...state,
        getAccountCertificationByIdError: null,
      };

    default:
      return state;
  }
}
