import { generateHourlyPersonFilters } from '../utils/generateFilters';
import {
  FILTERS_SET_HOURLY_PERSON_FILTER_DATA,
} from './constants';

export function setHourlyPersonFilterData(hourlyPersonFields) {
  return {
    type: FILTERS_SET_HOURLY_PERSON_FILTER_DATA,
    hourlyPersonFilterData: generateHourlyPersonFilters(hourlyPersonFields),
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FILTERS_SET_HOURLY_PERSON_FILTER_DATA:
      return {
        ...state,
        hourlyPersonFilterData: action.hourlyPersonFilterData,
      };

    default:
      return state;
  }
}
