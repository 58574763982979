import { put, takeLatest, all } from 'redux-saga/effects';
import {
  PEOPLE_COLLECT_PEOPLE_TABLE_DATA_BEGIN,
  PEOPLE_COLLECT_PEOPLE_TABLE_DATA_SUCCESS,
  PEOPLE_COLLECT_PEOPLE_TABLE_DATA_FAILURE,
  PEOPLE_COLLECT_PEOPLE_TABLE_DATA_DISMISS_ERROR,
} from './constants';

import { getProjectNamesQuery } from '../../projects/queries/getProjectNamesQuery';

import { doGetPeople } from './getPeople';
import { doGetPersonFieldValues } from './getPersonFieldValues';
import { doGetPersonAvailabilities } from './getPersonAvailabilities';
import { doGetPersonProjects } from './getPersonProjects';
import { doGetAccountAllocations } from './getAccountAllocations';
import { doGetPersonFields } from '../../account-settings/redux/getPersonFields';
import { doGetProjectFields } from '../../account-settings/redux/getProjectFields';
import { doGetPhases } from '../../account-settings/redux/getPhases';
import { doGetProjectNames } from '../../projects/redux/getProjectNames';

export function collectPeopleTableData(accountId, start, end) {
  return {
    type: PEOPLE_COLLECT_PEOPLE_TABLE_DATA_BEGIN,
    accountId,
    start,
    end,
  };
}

export function dismissCollectPeopleTableDataError() {
  return {
    type: PEOPLE_COLLECT_PEOPLE_TABLE_DATA_DISMISS_ERROR,
  };
}

export function* doCollectPeopleTableData(action) {
  const { accountId, start, end } = action;
  const accountParam = { accountId };
  const { query, variables } = getProjectNamesQuery(accountId);

  try {
    yield all([
      doGetPeople({ accountId, deactivated: false }),
      doGetPersonFieldValues({ accountId, state: 'active' }),
      doGetPersonAvailabilities(accountParam),
      doGetPersonProjects(accountParam),
      doGetAccountAllocations({ accountId, start, end }),
      doGetPersonFields(accountParam),
      doGetProjectNames({ query, variables }),
      doGetProjectFields(accountParam),
      doGetPhases(accountParam),
    ]);
  } catch (err) {
    yield put({
      type: PEOPLE_COLLECT_PEOPLE_TABLE_DATA_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PEOPLE_COLLECT_PEOPLE_TABLE_DATA_SUCCESS,
  });
}

export function* watchCollectPeopleTableData() {
  yield takeLatest(PEOPLE_COLLECT_PEOPLE_TABLE_DATA_BEGIN, doCollectPeopleTableData);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_COLLECT_PEOPLE_TABLE_DATA_BEGIN:
      return {
        ...state,
        collectPeopleTableDataPending: true,
        collectPeopleTableDataError: null,
      };

    case PEOPLE_COLLECT_PEOPLE_TABLE_DATA_SUCCESS:
      return {
        ...state,
        collectPeopleTableDataPending: false,
        collectPeopleTableDataError: null,
      };

    case PEOPLE_COLLECT_PEOPLE_TABLE_DATA_FAILURE:
      return {
        ...state,
        collectPeopleTableDataPending: false,
        collectPeopleTableDataError: action.data.error,
      };

    case PEOPLE_COLLECT_PEOPLE_TABLE_DATA_DISMISS_ERROR:
      return {
        ...state,
        collectPeopleTableDataError: null,
      };

    default:
      return state;
  }
}
