import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { Loader } from '@bridgit/foundation';
import moment from 'moment';
import { DATE_INPUT_FORMAT } from 'src/common/constants';
import {
  sortSegmentsByStartDate,
} from 'src/utils/dateSegmentUtils';
import { BREAKDOWN_CUSTOM, BREAKDOWN_MONTHLY, BREAKDOWN_WEEKLY, BREAKDOWN_PHASES } from './redux/constants';
import { Modal } from '../common';
import { EditControls } from '../wrapped-components';
import {
  getMonthlyAllocations,
  getWeeklyAllocations,
  getPhaseAllocations,
  updateRequirements,
  mergeRequirements,
  deleteRequirement,
} from './utils/roleRequirementUtils';
import {
  RequirementModalContentHeader,
  CustomRequirements,
  PeriodicRequirements,
  RequirementBulkInput,
} from '.';

export default class CustomRoleRequirementModal extends Component {
  static propTypes = {
    selectedProject: PropTypes.object.isRequired,
    onHideModal: PropTypes.func.isRequired,
    role: PropTypes.object.isRequired,
    onSave: PropTypes.func,
    selectedPhases: PropTypes.array,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    onSave: () => {},
    selectedPhases: [],
    loading: false,
  };

  constructor(props) {
    super(props);

    const { role, selectedPhases, selectedProject } = props;
    const requirements = sortSegmentsByStartDate(this.sanitizeRequirements(role?.requirements || []));
    const percentage = requirements?.[0]?.allocatedPercent || 100;

    const workingRole = {
      ...role,
      allocations: [
        {
          startDate: role.startDate,
          endDate: role.endDate,
          allocatedPercent: percentage,
        },
      ],
    };

    let phaseRequirements = [];
    if (selectedPhases.length) {
      phaseRequirements = getPhaseAllocations(selectedProject.phases, selectedPhases, workingRole);
    }

    this.state = {
      valid: true,
      breakdownType: selectedPhases.length ? BREAKDOWN_PHASES : BREAKDOWN_CUSTOM,
      requirementBreakdowns: {
        [BREAKDOWN_CUSTOM]: [...requirements],
        [BREAKDOWN_PHASES]: phaseRequirements,
        [BREAKDOWN_MONTHLY]: [],
        [BREAKDOWN_WEEKLY]: [],
      },
      workingRole,
    };
  }

  breakdownLabels = {
    [BREAKDOWN_CUSTOM]: 'Custom',
    [BREAKDOWN_PHASES]: 'Range',
    [BREAKDOWN_MONTHLY]: 'Monthly',
    [BREAKDOWN_WEEKLY]: 'Weekly',
  };

  listRef = React.createRef();

  toggledOptions = [];

  sanitizeRequirements = requirements => requirements.map((req) => {
    const { startDate, endDate, allocatedPercent } = req;

    return {
      startDate: moment(startDate, DATE_INPUT_FORMAT).format(DATE_INPUT_FORMAT),
      endDate: moment(endDate, DATE_INPUT_FORMAT).format(DATE_INPUT_FORMAT),
      allocatedPercent,
    };
  })

  validateRequirements = (requirements = []) => {
    // Check if any requirements are missing a start or end date
    const allPopulated = requirements.every(req => req.startDate !== null && req.endDate !== null);

    // Check that not all requirements have an allocatedPercent of 0
    const allZero = requirements.every(r => r.allocatedPercent === 0);

    this.setState({
      valid: allPopulated && !allZero,
      allZero,
    });
  }

  getActiveRequirements = () => {
    const { requirementBreakdowns, breakdownType } = this.state;
    return requirementBreakdowns[breakdownType] || [];
  }

  setActiveRequirements = (updatedRequirements, cb) => {
    const { requirementBreakdowns, breakdownType } = this.state;
    this.validateRequirements(updatedRequirements);

    this.setState({
      requirementBreakdowns: {
        ...requirementBreakdowns,
        [breakdownType]: updatedRequirements,
      },
    }, cb);
  }

  onError = () => {
    this.setState({ valid: false });
  }

  onSave = () => {
    const { role, onSave, selectedProject } = this.props;
    const { breakdownType } = this.state;

    // Merge adjacent segments with the same allocation percent value before saving
    const mergedRequirements = mergeRequirements(this.getActiveRequirements());

    const hasZeroPercent = mergedRequirements.some(req => req.allocatedPercent === 0);
    const breakdownTypeMixPanel = {
      [BREAKDOWN_CUSTOM]: 'Custom Range',
      [BREAKDOWN_PHASES]: 'Phases',
      [BREAKDOWN_MONTHLY]: 'Monthly',
      [BREAKDOWN_WEEKLY]: 'Weekly',
    };

    // Mixpanel - Track custom allocation set up
    window.mixpanel.track('Custom Allocation Setup', {
      'Project Name': selectedProject.name,
      'Project ID': selectedProject.id,
      'Project status': selectedProject.state,
      'Project type': selectedProject.type,
      'Allocation Breakdown Type': breakdownTypeMixPanel[breakdownType],
      'Includes periods at 0%': hasZeroPercent,
    });

    onSave(role.rowId, mergedRequirements);
  }

  handleTypeChange = (evt) => {
    const { selectedProject, selectedPhases } = this.props;
    const { requirementBreakdowns, workingRole } = this.state;
    const { value: breakdownType } = evt.target;
    let requirements;

    this.toggledOptions.push(breakdownType);

    // Mixpanel - Track what selections are made by the user
    window.mixpanel.track('Navigation within the Custom Allocation modal', {
      'Project Name': selectedProject.name,
      'Project ID': selectedProject.id,
      'Project status': selectedProject.state,
      'Project type': selectedProject.type,
      'Toggled Allocation Breakdown Types': this.toggledOptions,
      'Number of times a user toggles between options within a session': this.toggledOptions.length,
    });

    // If there is already a cached value for this particular breakdown, do not recalculate
    if (requirementBreakdowns[breakdownType].length) {
      this.setState({ breakdownType });
      this.validateRequirements(requirementBreakdowns[breakdownType]);
      return;
    }

    if (breakdownType === BREAKDOWN_MONTHLY) {
      requirements = getMonthlyAllocations(workingRole);
    }

    if (breakdownType === BREAKDOWN_WEEKLY) {
      requirements = getWeeklyAllocations(workingRole);
    }

    if (breakdownType === BREAKDOWN_PHASES) {
      requirements = getPhaseAllocations(selectedProject.phases, selectedPhases, workingRole);
    }

    this.setState({
      breakdownType,
      requirementBreakdowns: {
        ...requirementBreakdowns,
        [breakdownType]: requirements,
      },
    });

    this.validateRequirements(requirements);
  }

  bulkUpdatePercentages = (newPercentValue) => {
    if (Number(newPercentValue).isNaN) return;

    const updatedRequirements = this.getActiveRequirements().map(r => ({ ...r, allocatedPercent: Number(newPercentValue) }));
    this.setActiveRequirements(updatedRequirements);
  }

  updateRequirements = (originalRequirement, updatedRequirement) => {
    const requirements = this.getActiveRequirements();
    let updated;

    if (updatedRequirement === null) {
      updated = deleteRequirement(originalRequirement, requirements);
    } else {
      updated = updateRequirements(originalRequirement, updatedRequirement, requirements);
    }

    this.setActiveRequirements(updated);
  }

  addRequirement = () => {
    const requirements = this.getActiveRequirements();
    const newRequirement = { startDate: null, endDate: null, allocatedPercent: 100 };

    const scrollToBottom = () => {
      if (!this.listRef?.current) return;

      this.listRef.current.scrollTo({
        top: this.listRef.current.offsetHeight,
        behavior: 'smooth',
      });
    };

    this.setActiveRequirements([...requirements, newRequirement], scrollToBottom);
  }

  render() {
    const { onHideModal, role, selectedProject, loading } = this.props;
    const { breakdownType, valid, allZero } = this.state;
    const allocations = this.getActiveRequirements();
    const reqPercentages = allocations.map(a => a.allocatedPercent);

    return (
      <div className="allocations-custom-role-requirement-modal">
        <Modal
          maxWidth="md"
          classes={{
            root: 'custom-role-requirement-modal',
            paperScrollPaper: 'modal-scroll-container',
          }}
          headline="Customize allocation"
          closeModal={onHideModal}
          showClose={!loading}
          buttons={[
            <div key="button-wrap" className="button-wrap">
              <EditControls
                primaryAction={this.onSave}
                secondaryAction={onHideModal}
                disabled={!valid}
              />
            </div>,
          ]}
        >
          {loading && <Loader />}
          <RequirementModalContentHeader role={role} />
          <div className="modal-content-body">
            <div className="breakdown-selection">
              <div className="header breakdown">Allocation breakdown</div>
              <div className="breakdown">
                <RadioGroup
                  name="breakdownType"
                  value={breakdownType}
                  onChange={this.handleTypeChange}
                >
                  <FormControlLabel value={BREAKDOWN_CUSTOM} control={<Radio color="primary" />} label="Custom range" />
                  <FormControlLabel value={BREAKDOWN_PHASES} control={<Radio color="primary" />} label="Phases" />
                  <FormControlLabel value={BREAKDOWN_MONTHLY} control={<Radio color="primary" />} label="Monthly" />
                  <FormControlLabel value={BREAKDOWN_WEEKLY} control={<Radio color="primary" />} label="Weekly" />
                </RadioGroup>
              </div>
            </div>

            <div className="breakdown-content">
              { (breakdownType === BREAKDOWN_PHASES && !selectedProject.phases.length)
                ? <p className="empty-text">There are currently no phases on this project.</p>
                : (
                  <>
                    <div className="headers">
                      <div className="header">{this.breakdownLabels[breakdownType]}</div>
                      { breakdownType === BREAKDOWN_PHASES && (
                        <div className="header">Phase(s)</div>
                      )}
                      <RequirementBulkInput
                        className="bulk-input"
                        onUpdate={this.bulkUpdatePercentages}
                        percentages={reqPercentages}
                      />
                    </div>

                    <div className="list-wrap" ref={this.listRef}>
                      { breakdownType === BREAKDOWN_CUSTOM ? (
                        <CustomRequirements
                          role={role}
                          requirements={allocations}
                          onRequirementChange={this.updateRequirements}
                          onError={this.onError}
                        />
                      ) : (
                        <PeriodicRequirements
                          requirements={allocations}
                          onRequirementChange={this.updateRequirements}
                          breakdownType={breakdownType}
                        />
                      )}
                    </div>

                    <div className="breakdown-footer">
                      { breakdownType === BREAKDOWN_CUSTOM && (
                        <Button
                          onClick={this.addRequirement}
                          disabled={!valid}
                          color="primary"
                          className="add-range-button"
                          disableRipple
                        >
                          <AddCircle />
                          Custom Range
                        </Button>
                      )}

                      { allZero && (
                        <span className="validation-warning">
                          A role must have at least one day with an allocation % greater than 0%
                        </span>
                      )}
                    </div>
                  </>
                )}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
