import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { DATE_DISPLAY_FORMAT } from '../../common/constants';

// Asynchronously loads moment-timezone and formats a date string
// after it's been loaded.
class LoadableMomentDate extends PureComponent {
  static propTypes = {
    date: PropTypes.string.isRequired,
  }

  state = {
    moment: null,
  }

  async componentDidMount() {
    const moment = await import(/* webpackChunkName: "moment-timezone" */ 'moment-timezone');
    this.setState({ moment });
  }

  render() {
    const { date } = this.props;
    const { moment } = this.state;
    return moment && date
      ? <>{moment.default(date).format(DATE_DISPLAY_FORMAT)}</>
      : null;
  }
}

export default LoadableMomentDate;
