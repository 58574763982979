import { gql } from 'apollo-boost';

const PEOPLE_GANTT_SHARE_QUERY = gql`
  query peopleGanttConfig($accountId: Int!, $key: String!) {
    peopleGanttConfig(accountId: $accountId, key: $key) {
      value
    }
  }
 `;

export const getPeopleGanttShareQuery = (accountId, key) => {
  const variables = { accountId, key };

  return {
    query: PEOPLE_GANTT_SHARE_QUERY,
    variables,
  };
};
