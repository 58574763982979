import { auto as followSystemColorScheme } from 'darkreader';
import { THEMES_TOGGLE_SYSTEM_THEME_SWITCH } from './constants';
import { setStoredSystemThemeStatus } from '../utils';

export function toggleSystemThemeSwitch(newToggleState, userId, analyticsPayload) {
  return {
    type: THEMES_TOGGLE_SYSTEM_THEME_SWITCH,
    userId,
    newToggleState,
    analyticsPayload,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case THEMES_TOGGLE_SYSTEM_THEME_SWITCH: {
      const { newToggleState, userId } = action;

      if (!newToggleState) followSystemColorScheme(false);

      setStoredSystemThemeStatus(newToggleState, userId);

      return {
        ...state,
        isUsingSystemTheme: newToggleState,
      };
    }

    default:
      return state;
  }
}
