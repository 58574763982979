import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import pluralize from 'pluralize';
import { Tag } from '@bridgit/foundation';
import { getCertificationStatus } from './utils/certificationUtils';
import { CERTIFICATION_STATUSES, CERTIFICATION_VARIANTS } from './constants';

export const CertificationTags = ({ certifications }) => {
  if (!certifications?.length) return null;

  return (
    <div className="people-certification-tags">
      {certifications.map(({ id, abbreviation, expiryDate, daysWarnBeforeExpire }) => {
        const state = getCertificationStatus(expiryDate, daysWarnBeforeExpire);

        let subLabel = '';

        if (state === CERTIFICATION_STATUSES.EXPIRING_SOON) {
          // Since expiryDate is at 0 hours get today at 0 hours to get a valid diff
          const today = moment(0, 'HH');
          const daysBeforeExpiry = moment(expiryDate).diff(today, 'days');
          subLabel = pluralize('day', daysBeforeExpiry, true);
        }

        return (
          <Tag
            className="certification-tag"
            key={id}
            label={abbreviation}
            subLabel={subLabel}
            variant={CERTIFICATION_VARIANTS[state]}
          >
            {abbreviation}
          </Tag>
        );
      })}
    </div>
  );
};

CertificationTags.propTypes = {
  certifications: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    abbreviation: PropTypes.string,
    expiryDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
    daysWarnBeforeExpire: PropTypes.number,
  })),
};

CertificationTags.defaultProps = {
  certifications: null,
};

export default CertificationTags;
