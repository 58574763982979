import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IconButton } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import { CustomTooltip } from 'src/common/components/DataDisplay';
import { CheckboxForm } from '../common';
import { Can } from '../wrapped-components';
import { updateAccountModule } from './redux/actions';

function ModuleManagement({
  accountId,
  accountModules,
  module,
  title,
  infoText,
  updateAccountModule,
  updateAccountModulePending,
}) {
  const [infoOpen, setInfoOpen] = useState(false);
  const selectedModule = useMemo(() => accountModules.find(mod => mod.name === module), [accountModules, module]);

  const controls = useMemo(() => {
    const components = selectedModule?.components || {};
    return Object.keys(components).map(comp => ({
      name: comp,
      label: comp,
    }));
  }, [selectedModule]);

  const onChange = useCallback((name, checked) => {
    const { id } = selectedModule;
    const data = { [name]: checked };
    updateAccountModule(accountId, id, data);
  }, [accountId, selectedModule, updateAccountModule]);

  const onClickAway = useCallback(() => setInfoOpen(false), []);
  const onClick = useCallback(() => setInfoOpen(true), []);

  if (!selectedModule) return null;

  return (
    <Can
      module={module}
      yes={(
        <div className="account-settings-module-management">
          <CheckboxForm
            title={title || module}
            controls={controls}
            onChange={onChange}
            checkedList={selectedModule.components}
            pending={updateAccountModulePending}
          />
          {infoText && (
            <CustomTooltip
              title={infoText}
              placement="top"
              color="primary"
              open={infoOpen}
              onClickAway={onClickAway}
            >
              <IconButton classes={{ root: 'help' }} aria-label="Help" onClick={onClick}>
                <Help />
              </IconButton>
            </CustomTooltip>
          )}
        </div>
      )}
    />
  );
}

ModuleManagement.propTypes = {
  accountId: PropTypes.number.isRequired,
  accountModules: PropTypes.arrayOf(PropTypes.object).isRequired,
  module: PropTypes.string.isRequired,
  title: PropTypes.string,
  infoText: PropTypes.string,
  updateAccountModule: PropTypes.func.isRequired,
  updateAccountModulePending: PropTypes.bool.isRequired,
};

ModuleManagement.defaultProps = {
  title: '',
  infoText: '',
};

/* istanbul ignore next */
function mapStateToProps({ common, accountSettings }) {
  const { accountId } = common;
  const { accountModules, updateAccountModulePending } = accountSettings;
  return {
    accountId,
    accountModules,
    updateAccountModulePending,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    updateAccountModule: bindActionCreators(updateAccountModule, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModuleManagement);
