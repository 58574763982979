import React, { useState, useEffect, useCallback, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, CircularProgress } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { PERM_WRITE, PERM_PROJECT } from 'src/features/permissions/utils/constants';
import { stringToMoment } from 'src/utils/dateUtils';
import { naturalSort } from 'src/utils/sortUtils';
import {
  deleteProjectPhase,
  deleteProjectSubPhase,
  updateProjectPhase,
  updateProjectSubPhase,
} from './redux/actions';
import { Confirm } from '../common';
import { SelectPhaseMenu } from '../phases';
import { ProjectPhaseRow, AddProjectPhaseForm } from '.';
import { Can } from '../wrapped-components';
import { getDateEditOptions } from '../edit-dates/utils/dateEditorUtils';

export const ProjectPhases = ({
  project,
  accountId,
  deleteProjectPhase,
  deleteProjectSubPhase,
  updateProjectPhase,
  updateProjectSubPhase,
  parentName,
  loading,
}) => {
  const [openPhases, setOpenPhases] = useState([]);
  const [confirming, setConfirming] = useState(false);
  const [activePhase, setActivePhase] = useState(null);
  const [showAddPhase, setShowAddPhase] = useState(false);
  const [addPhaseButtonNode, setAddPhaseButtonNode] = useState(null);
  const [phaseToAdd, setPhaseToAdd] = useState(null);
  const [subPhasesToAdd, setSubPhasesToAdd] = useState([]);
  const [phaseAlreadyAdded, setPhaseAlreadyAdded] = useState(false);
  const formRef = useRef(null);

  const sortedPhases = naturalSort(project.phases, ['startDate', 'endDate', 'name']).map(phase => (
    {
      ...phase,
      subPhases: naturalSort(phase.subPhases, ['startDate', 'endDate', 'name']),
    }
  ));

  const removePhase = useCallback(() => {
    const analyticsPayload = {
      activePhase,
      project,
      parentName,
    };
    deleteProjectPhase(accountId, project.id, activePhase, analyticsPayload);
    setConfirming(false);
    setActivePhase(null);
  }, [activePhase, project, parentName, deleteProjectPhase, accountId]);

  useEffect(() => {
    if (activePhase) {
      if (activePhase.subPhases.length) {
        setConfirming(true);
      } else {
        removePhase();
      }
    }
  }, [activePhase, removePhase]);

  useEffect(() => {
    setPhaseToAdd(null);
  }, [project]);

  useEffect(() => {
    if (!showAddPhase && formRef.current) {
      formRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [showAddPhase]);

  const cancelConfirm = () => {
    setConfirming(false);
    setActivePhase(null);
  };

  const handleDeleteClick = (e) => {
    const id = parseInt(e.currentTarget.dataset.id, 10);
    const phase = sortedPhases.find(phase => phase.id === id);
    setActivePhase(phase);
  };

  const handleSubDeleteClick = (e) => {
    const id = parseInt(e.currentTarget.dataset.id, 10);
    const parentId = parseInt(e.currentTarget.dataset.parent, 10);
    const phase = sortedPhases.find(phase => phase.id === parentId);
    const subPhase = phase.subPhases.find(sp => sp.id === id);
    const analyticsPayload = {
      project,
      parentName,
      subPhase,
    };
    deleteProjectSubPhase(accountId, project.id, phase, subPhase, analyticsPayload);
  };

  const togglePhase = (id) => {
    const newOpenPhases = openPhases.includes(id) ? openPhases.filter(phaseId => phaseId !== id) : [...openPhases, id];
    setOpenPhases(newOpenPhases);
  };

  const handlePhaseToggle = (e) => {
    const id = parseInt(e.currentTarget.dataset.id, 10);
    togglePhase(id);
  };

  const toggleAddPhase = (evt) => {
    setShowAddPhase(!showAddPhase);
    setAddPhaseButtonNode(evt.currentTarget);
  };

  const closeAddPhase = () => setShowAddPhase(false);

  const onSelect = (phase, subPhase = null) => {
    const phaseAlreadyAdded = sortedPhases.find(p => p.id === phase.id);

    if (!!phaseAlreadyAdded && !openPhases.includes(phase.id)) {
      togglePhase(phase.id);
    }
    setPhaseToAdd(phaseAlreadyAdded || phase);
    setSubPhasesToAdd(subPhase ? [subPhase] : phase.subPhases);
    setPhaseAlreadyAdded(!!phaseAlreadyAdded);
    setShowAddPhase(false);
  };

  const removePhaseToAdd = () => {
    setPhaseToAdd(null);
    setSubPhasesToAdd([]);
  };

  const removeSubPhaseToAdd = id => () => {
    setSubPhasesToAdd(subPhasesToAdd.filter(sub => sub.id !== id));
  };

  const handleUpdateProjectPhase = (phase, data, fieldEdited, subPhase) => {
    const { id: phaseId, startDate, endDate } = phase;
    const { startDate: newStartDate, endDate: newEndDate } = data;

    const originalStart = stringToMoment(startDate);
    const originalEnd = stringToMoment(endDate);
    const newStart = stringToMoment(newStartDate);
    const newEnd = stringToMoment(newEndDate);

    const { editAction, offset } = getDateEditOptions(originalStart, originalEnd, newStart, newEnd);
    const analyticsPayload = {
      project,
      parentName,
      fieldEdited,
      editAction,
      offset,
    };

    if (subPhase?.id) {
      analyticsPayload.updateType = 'Sub-phase';
      updateProjectSubPhase(accountId, project, phaseId, subPhase, data, analyticsPayload);
    } else {
      analyticsPayload.updateType = 'Phase';
      updateProjectPhase(accountId, project, phaseId, true, data, analyticsPayload);
    }
  };

  return (
    <div className="projects-project-phases">
      <div className="table-header">
        <div className="table-row">
          <div>Project phases</div>
          <div>Start date</div>
          <div>End date</div>
        </div>
      </div>
      { loading
        ? (
          <div className="progress-indicator">
            <CircularProgress size={35} />
          </div>
        )
        : (
          <div className="table-body">
            {sortedPhases.map(phase => (
              <Fragment key={phase.id}>
                <ProjectPhaseRow
                  project={project}
                  phase={phase}
                  handleDeleteClick={handleDeleteClick}
                  disabled={phaseToAdd !== null}
                  openPhases={openPhases}
                  handlePhaseToggle={handlePhaseToggle}
                  updatePhase={handleUpdateProjectPhase}
                />
                {openPhases.includes(phase.id) && phase.subPhases && phase.subPhases.map(sp => (
                  <ProjectPhaseRow
                    key={sp.id}
                    project={project}
                    phase={phase}
                    subPhase={sp}
                    handleDeleteClick={handleSubDeleteClick}
                    disabled={phaseToAdd !== null}
                    openPhases={openPhases}
                    handlePhaseToggle={handlePhaseToggle}
                    updatePhase={handleUpdateProjectPhase}
                  />
                ))}
                {phaseAlreadyAdded && phaseToAdd && phase.id === phaseToAdd.id && (
                  <AddProjectPhaseForm
                    refProp={formRef}
                    project={project}
                    phase={phaseToAdd}
                    subPhases={subPhasesToAdd}
                    removePhaseToAdd={removePhaseToAdd}
                    removeSubPhaseToAdd={removePhaseToAdd}
                    phaseAlreadyAdded={phaseAlreadyAdded}
                    parentName={parentName}
                  />
                )}
              </Fragment>
            ))}
            {phaseToAdd && !phaseAlreadyAdded && (
              <AddProjectPhaseForm
                refProp={formRef}
                project={project}
                phase={phaseToAdd}
                subPhases={subPhasesToAdd}
                removePhaseToAdd={removePhaseToAdd}
                removeSubPhaseToAdd={removeSubPhaseToAdd}
                phaseAlreadyAdded={phaseAlreadyAdded}
                parentName={parentName}
              />
            )}
          </div>
        )}
      {sortedPhases.length < 1 && !phaseToAdd && !loading && (
        <Can
          action={PERM_WRITE}
          subject={PERM_PROJECT}
          yes={<p className="no-phases">This project has no phases, click to add one.</p>}
          no={<p className="no-phases">This project has no phases.</p>}
        />
      )}
      <Can
        action={PERM_WRITE}
        subject={PERM_PROJECT}
        yes={(
          <Button
            className="add-phase-button"
            onClick={toggleAddPhase}
            disabled={!!phaseToAdd || subPhasesToAdd.length > 0}
            disableRipple
          >
            <AddCircle />
            Add phase
          </Button>
        )}
      />
      <SelectPhaseMenu
        anchorEl={addPhaseButtonNode}
        open={showAddPhase}
        onClose={closeAddPhase}
        selectedPhases={sortedPhases}
        onSelect={onSelect}
        placement={parentName === 'Project Gantt' ? 'left' : 'top-start'}
      />
      { confirming && (
        <Confirm
          headline="Remove Phase"
          acceptButtonText="Remove Phase"
          cancelButtonText="Cancel"
          onCancel={cancelConfirm}
          onAccept={removePhase}
        >
          <div>
            <p>{`Are you sure you want to remove the phase "${activePhase.name}" and any sub-phases associated with it from this project?`}</p>
          </div>
        </Confirm>
      )}
    </div>
  );
};

ProjectPhases.propTypes = {
  project: PropTypes.object.isRequired,
  accountId: PropTypes.number.isRequired,
  deleteProjectPhase: PropTypes.func.isRequired,
  deleteProjectSubPhase: PropTypes.func.isRequired,
  updateProjectPhase: PropTypes.func.isRequired,
  updateProjectSubPhase: PropTypes.func.isRequired,
  parentName: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

ProjectPhases.defaultProps = {
  loading: false,
};

/* istanbul ignore next */
function mapStateToProps({ common, projects }) {
  const { accountId } = common;
  const loading =
    projects.addProjectPhasesPending ||
    projects.deleteProjectPhasePending ||
    projects.addProjectSubPhasePending ||
    projects.deleteProjectSubPhasePending;
  return {
    accountId,
    loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deleteProjectPhase: bindActionCreators(deleteProjectPhase, dispatch),
    deleteProjectSubPhase: bindActionCreators(deleteProjectSubPhase, dispatch),
    updateProjectPhase: bindActionCreators(updateProjectPhase, dispatch),
    updateProjectSubPhase: bindActionCreators(updateProjectSubPhase, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectPhases);
