import moment from 'moment';
import pluralize from 'pluralize';
import { detect } from 'detect-browser';
import { stitchSegmentsByRange } from 'src/utils/dateSegmentUtils';
import { LOST, CANCELED } from 'src/features/projects/constants';
import {
  PEOPLE_DISPLAY_NEXT_PROJECT,
  PEOPLE_DISPLAY_NEXT_PROJECT_START,
  PEOPLE_DISPLAY_CURRENT_PROJECT_END,
  PEOPLE_DISPLAY_PURSUIT_PROJECTS,
  PEOPLE_DISPLAY_AVAILABILITY_UNTIL,
  PEOPLE_DISPLAY_CURRENT_PROJECT,
  PEOPLE_DISPLAY_NAME,
  PEOPLE_DISPLAY_TITLE,
  PEOPLE_DISPLAY_EMAIL,
  PEOPLE_DISPLAY_ISSUES,
  PEOPLE_DISPLAY_HIRE_DATE,
  PEOPLE_DISPLAY_TERMINATION_DATE,
  PEOPLE_DISPLAY_CERTIFICATIONS,
} from '../../people/constants';
import {
  EMPTY_FIELD_CSV,
  CSV_DATE_FORMAT,
  PROJECT_ROLES_COLUMN,
  TABLOID_PAPER,
  LANDSCAPE_TABLOID_PAPER_WIDTH,
  LANDSCAPE_LETTER_PAPER_WIDTH,
} from '../redux/constants';
import { DATE_DISPLAY_FORMAT, EMPTY_FIELD_VALUE } from '../../../common/constants';
import { formatDollarValue, formatPhoneNumber } from '../../../utils/formatters';
import deactivatedAvatar from '../../../images/deactivated_avatar.svg?url';
import emptyAvatar from '../../../images/empty_avatar.svg?url';
import { FILLED_ROLES, UNFILLED_ROLES } from '../../../filters/constants';
import { getEntityState, isPast, getFormattedDate } from '../../../utils/dateUtils';
import { sortAlphabetically } from '../../../utils/sortUtils';
import { ROLE_SCHEMA } from '../../../common/descriptors/role';

function sanitizeCSVString(string) {
  return string.replace(/"/g, '');
}

export function getPersonValue(column, person) {
  switch (column.name) {
    case PEOPLE_DISPLAY_EMAIL:
      return person.email || EMPTY_FIELD_VALUE;

    case PEOPLE_DISPLAY_ISSUES:
      return person.hasConflict ? 'Yes' : 'No';

    case PEOPLE_DISPLAY_CURRENT_PROJECT:
      return person.currentProjects?.length
        ? person.currentProjects.map(project => project.name).join(', ')
        : EMPTY_FIELD_VALUE;

    case PEOPLE_DISPLAY_CURRENT_PROJECT_END:
      return person.currentAllocations?.length
        ? person.currentAllocations.map(allocation => getFormattedDate(allocation.endDate)).join(', ')
        : EMPTY_FIELD_VALUE;

    case PEOPLE_DISPLAY_NEXT_PROJECT:
      return person.nextAllocations?.length
        ? person.nextAllocations.map(allocation => allocation.projectName).join(', ')
        : EMPTY_FIELD_VALUE;

    case PEOPLE_DISPLAY_NEXT_PROJECT_START:
      return person.nextAllocations?.length
        ? person.nextAllocations.map(allocation => getFormattedDate(allocation.startDate)).join(', ')
        : EMPTY_FIELD_VALUE;

    case PEOPLE_DISPLAY_PURSUIT_PROJECTS:
      return person.pursuitProjects?.length
        ? person.pursuitProjects.map(project => project.projectName).join(', ')
        : EMPTY_FIELD_VALUE;

    case PEOPLE_DISPLAY_AVAILABILITY_UNTIL:
      return person.nextAvailability || EMPTY_FIELD_VALUE;

    case PEOPLE_DISPLAY_CERTIFICATIONS: {
      if (!person.certifications?.length) return EMPTY_FIELD_VALUE;
      const sortedPersonCertifications = sortAlphabetically(person.certifications, true, 'name');

      return sortedPersonCertifications.map(({ expiryDate, name }) => {
        if (!expiryDate) return name;
        return isPast(expiryDate)
          ? `${name} • expired`
          : `${name} • expiring ${getFormattedDate(expiryDate)}`;
      }).join(', ');
    }
    case PEOPLE_DISPLAY_HIRE_DATE:
      return person?.employmentDates?.startDate
        ? getFormattedDate(person.employmentDates.startDate)
        : EMPTY_FIELD_VALUE;

    case PEOPLE_DISPLAY_TERMINATION_DATE:
      return person?.employmentDates?.endDate
        ? getFormattedDate(person.employmentDates.endDate)
        : EMPTY_FIELD_VALUE;

    default:
      return null;
  }
}

export function getProjectValue(column, project) {
  switch (column.name) {
    case 'Start Date':
      return project.startDate ? getFormattedDate(project.startDate) : EMPTY_FIELD_VALUE;

    case 'End Date':
      return project.endDate ? getFormattedDate(project.endDate) : EMPTY_FIELD_VALUE;

    case 'Issues':
      return project.hasIssues ? 'Yes' : 'No';

    case 'Active Phase':
      // No active phases shown for Lost or Canceled projects (Pursuits)
      return [LOST, CANCELED].includes(project.state) ?
        EMPTY_FIELD_CSV
        : project.currentPhases.map((phase) => {
          const { name, subPhases } = phase;
          return subPhases.length
            ? subPhases.map(subPhase => `(${name} / ${subPhase}) `)
            : `(${name}) `;
        });

    case 'Roles':
      return project.totalRoles === 0 ? 'None defined' : `${pluralize('Roles', project.unfilledRoles, true)} unfilled`;

    case 'Type':
      return project.type || EMPTY_FIELD_VALUE;

    default:
      return null;
  }
}

export function getFieldValue(column, person) {
  const field = person.fields.find(field => field.name === column.name);
  const hasValue = field && field.values && field.values.length > 0;

  switch (column.type) {
    case 'Date':
      return hasValue ? getFormattedDate(field.values[0]) : EMPTY_FIELD_VALUE;

    case 'PhoneNumber':
      return hasValue ? formatPhoneNumber(field.values[0]) : EMPTY_FIELD_VALUE;

    case 'Currency':
      return hasValue ? formatDollarValue(field.values[0]) : EMPTY_FIELD_VALUE;

    case 'Boolean':
      return hasValue && field.values[0] === 'true' ? 'True' : 'False';

    case 'MultiSelect':
      return hasValue ? field.values.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).join(', ') : EMPTY_FIELD_VALUE;

    default:
      return hasValue ? field.values[0] : EMPTY_FIELD_VALUE;
  }
}

export function printReport() {
  const browser = detect();
  try {
    if (browser && browser.name.toLowerCase() === 'safari') {
      setTimeout(() => document.execCommand('print', false, null), 0);
    } else {
      setTimeout(() => window.print(), 0);
    }
  } catch (e) {
    setTimeout(() => window.print(), 0);
  }
}

export function preloadImage(image, callback) {
  const logoImg = new Image();
  logoImg.src = image;

  if (logoImg.complete) {
    callback();
  } else {
    logoImg.addEventListener('load', callback);
  }
}

export const resolveImage = path => new Promise((resolve) => {
  const img = new Image();
  img.onload = () => resolve();
  img.onerror = () => resolve();

  img.src = path;
});


function getCSVPersonValue(column, person) {
  switch (column.name) {
    case PEOPLE_DISPLAY_NAME:
      return sanitizeCSVString(person.name) || EMPTY_FIELD_CSV;

    case PEOPLE_DISPLAY_TITLE:
      return sanitizeCSVString(person.title) || EMPTY_FIELD_CSV;

    case PEOPLE_DISPLAY_EMAIL:
      return sanitizeCSVString(person.email) || EMPTY_FIELD_CSV;

    case PEOPLE_DISPLAY_ISSUES:
      return person.hasConflict ? 'TRUE' : 'FALSE';

    case PEOPLE_DISPLAY_CURRENT_PROJECT:
      return person.currentProjects?.length
        ? sanitizeCSVString(person.currentProjects.map(project => project.name).join(', '))
        : EMPTY_FIELD_CSV;

    case PEOPLE_DISPLAY_CURRENT_PROJECT_END:
      return person.currentAllocations?.length
        ? sanitizeCSVString(person.currentAllocations.map(allocation => allocation.endDate).join(', '))
        : EMPTY_FIELD_CSV;

    case PEOPLE_DISPLAY_NEXT_PROJECT:
      return person.nextAllocations?.length
        ? sanitizeCSVString(person.nextAllocations.map(allocation => allocation.projectName).join(', '))
        : EMPTY_FIELD_CSV;

    case PEOPLE_DISPLAY_NEXT_PROJECT_START:
      return person.nextAllocations?.length
        ? sanitizeCSVString(person.nextAllocations.map(allocation => allocation.startDate).join(', '))
        : EMPTY_FIELD_CSV;

    case PEOPLE_DISPLAY_PURSUIT_PROJECTS:
      return person.pursuitProjects?.length
        ? sanitizeCSVString(person.pursuitProjects.map(project => project.projectName).join(', '))
        : EMPTY_FIELD_CSV;

    case PEOPLE_DISPLAY_AVAILABILITY_UNTIL:
      return person.nextAvailability || EMPTY_FIELD_VALUE;

    case PEOPLE_DISPLAY_HIRE_DATE:
      return person?.employmentDates?.startDate
        ? moment(person.employmentDates.startDate).format(CSV_DATE_FORMAT)
        : EMPTY_FIELD_CSV;

    case PEOPLE_DISPLAY_TERMINATION_DATE:
      return person?.employmentDates?.endDate
        ? moment(person.employmentDates.endDate).format(CSV_DATE_FORMAT)
        : EMPTY_FIELD_CSV;

    default:
      return null;
  }
}

function getCSVFieldValue(column, person) {
  const field = person.fields.find(field => field.name === column.name);
  const hasValue = field && field.values && field.values.length > 0;

  switch (column.type) {
    case 'Date':
      return hasValue ? moment(field.values[0]).format(CSV_DATE_FORMAT) : EMPTY_FIELD_CSV;

    case 'PhoneNumber':
      return hasValue ? formatPhoneNumber(field.values[0]) : EMPTY_FIELD_CSV;

    case 'Currency':
      return hasValue ? formatDollarValue(field.values[0], { includeSymbol: false }) : EMPTY_FIELD_CSV;

    case 'Boolean': {
      const isValueTrue = field?.values?.[0]?.toLowerCase() === 'true';
      return hasValue && isValueTrue ? 'TRUE' : 'FALSE';
    }

    case 'MultiSelect':
      return hasValue ? sanitizeCSVString(field.values.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).join(', ')) : EMPTY_FIELD_CSV;

    default:
      return hasValue ? sanitizeCSVString(field.values[0]) : EMPTY_FIELD_CSV;
  }
}

function getCSVCertificationValue(certificationColumn, person) {
  const { id } = certificationColumn;
  const certification = person.certifications?.find(cert => cert.id === id);

  if (!certification) return null;
  return certification.expiryDate ?? 'Certified, no expiration';
}

export function generatePeopleCSV(columns, people, certificationColumns) {
  return people.map((person) => {
    const row = columns.map((column) => {
      let fieldValue = getCSVPersonValue(column, person);

      // check for null in case the value is empty or false
      if (fieldValue === null) {
        fieldValue = getCSVFieldValue(column, person);
      }

      return fieldValue;
    });
    row.push(...certificationColumns.map(column => getCSVCertificationValue(column, person)));
    return row;
  });
}

function getCSVProjectValue(column, project) {
  switch (column.name) {
    case 'Project Name':
      return project.name || EMPTY_FIELD_CSV;

    case 'Status':
      return project.state || EMPTY_FIELD_CSV;

    case 'Start Date':
      return project.startDate ? moment(project.startDate).format(CSV_DATE_FORMAT) : EMPTY_FIELD_CSV;

    case 'End Date':
      return project.endDate ? moment(project.endDate).format(CSV_DATE_FORMAT) : EMPTY_FIELD_CSV;

    case 'Issues':
      return project.hasIssues ? 'TRUE' : 'FALSE';

    case 'Active Phase':
      // No active phases shown for Lost or Canceled projects (Pursuits)
      return [LOST, CANCELED].includes(project.state) ?
        EMPTY_FIELD_CSV
        : project.currentPhases.map((phase) => {
          const { name, subPhases } = phase;
          return subPhases.length
            ? subPhases.map(subPhase => `${name} / ${subPhase}`).join(', ')
            : name;
        }).join(', ');

    case 'Roles':
      return project.totalRoles === 0 ? 'None defined' : `${pluralize('Roles', project.unfilledRoles, true)} unfilled`;

    case 'Type':
      return project.type || EMPTY_FIELD_CSV;

    default:
      return null;
  }
}

const getCSVRoleSegmentValue = (column, roleSegment) => {
  switch (column) {
    case ROLE_SCHEMA.startDate:
    case ROLE_SCHEMA.endDate:
      return roleSegment[column] ? moment(roleSegment[column]).format(CSV_DATE_FORMAT) : EMPTY_FIELD_CSV;

    default:
      return roleSegment[column];
  }
};

export const generateRoleSegmentCSV = (columns, roleSegments) => (
  roleSegments.map(roleSegment => (
    columns.map(({ schemaName }) => (
      getCSVRoleSegmentValue(schemaName, roleSegment)
    ))
  ))
);

const getFieldValues = (columns, project, includeAllocations) => (
  columns.reduce((valueList, column) => {
    if (includeAllocations && column.name === PROJECT_ROLES_COLUMN) return valueList;

    const fieldValue = getCSVProjectValue(column, project) ?? getCSVFieldValue(column, project);

    valueList.push(fieldValue);
    return valueList;
  }, [])
);

export function generateProjectCSV(columns, projects) {
  return projects.map(project => getFieldValues(columns, project));
}

export function generateProjectAllocationsCSV(columns, projects, allProjectAllocations) {
  const rows = [];

  projects.forEach((project) => {
    const rowValues = getFieldValues(columns, project, true);
    const projectAllocations = allProjectAllocations.find(({ projectId }) => projectId === project.id);

    project?.roles?.forEach((role) => {
      const isCustom = role.requirements?.length > 1;
      const percentText = isCustom ? 'Custom' : role.requirements[0].allocatedPercent;

      const projectRole = projectAllocations?.roles?.find(({ roleId }) => roleId === role.id);
      const zeroPercent = role.requirements?.filter(req => req?.allocatedPercent === 0) || [];

      const combinedRanges = stitchSegmentsByRange([
        { startDate: role.startDate, endDate: role.endDate },
        ...role.allocations,
        ...zeroPercent,
      ]);

      combinedRanges.forEach((range) => {
        const { personId, startDate, endDate, allocatedPercent } = range;

        if (allocatedPercent !== 0) {
          const formattedStartDate = getFormattedDate(startDate);
          const formattedEndDate = getFormattedDate(endDate);
          let personName;

          if (personId) {
            const personAllocation = projectRole?.allocations?.find(alloc => alloc.personId === personId);
            personName = personAllocation?.name || '';
          } else {
            personName = 'Unfilled';
          }

          rows.push([...rowValues, role.name, role.note, personName, formattedStartDate, formattedEndDate, percentText]);
        }
      });
    });
  });

  return rows;
}

function getPercentageLabel(allocations) {
  const isCustom = allocations.some(alloc => alloc.allocatedPercent !== allocations[0].allocatedPercent);
  return isCustom ? 'Custom' : `${allocations[0].allocatedPercent}%`;
}

function populateSegmentByRole(role, segments, people) {
  const filteredSegments = segments.filter(segment => segment.allocatedPercent !== 0);

  return filteredSegments.map((segment, idx) => {
    const startDate = moment.utc(segment.startDate);
    const endDate = moment.utc(segment.endDate);

    const populatedSegment = {
      id: `${role.id}-${idx}`,
      startDate,
      endDate,
      startDateFormatted: startDate.format(DATE_DISPLAY_FORMAT),
      endDateFormatted: endDate.format(DATE_DISPLAY_FORMAT),
      roleName: role.name,
      roleNote: role.note,
      state: segment.allocationState || getEntityState(startDate, endDate),
    };

    if (segment.personId) {
      if (segment.state === 'Deactivated') {
        populatedSegment.personName = segment.name;
        populatedSegment.photoUrl = deactivatedAvatar;
      } else {
        const person = people.find(p => p.id === segment.personId) || {};
        populatedSegment.personName = person.name;
        populatedSegment.photoUrl = person.photoUrl || emptyAvatar;
      }

      populatedSegment.hasConflict = segment.hasConflict;
      populatedSegment.isUnfilled = false;
      populatedSegment.allocatedPercent = getPercentageLabel(role.requirements);
    } else {
      populatedSegment.personName = 'Unfilled';
      populatedSegment.isUnfilled = true;
    }

    return populatedSegment;
  });
}

export function parseRoleFilters(roleFilters) {
  if (!roleFilters) return {};

  const nameFilters = [];
  let hasFilledFilter = false;
  let hasUnfilledFilter = false;

  roleFilters.forEach((filter) => {
    const { id, name, value } = filter;

    if (id) {
      nameFilters.push(name);
    } else if (value === FILLED_ROLES) {
      hasFilledFilter = true;
    } else if (value === UNFILLED_ROLES) {
      hasUnfilledFilter = true;
    }
  });

  return {
    nameFilters,
    hasFilledFilter,
    hasUnfilledFilter,
  };
}

export function parseRoles(roles, roleAllocations, people) {
  if (!roles) {
    return [];
  }

  const parsedRoles = roles.map((role) => {
    const zeroPercent = role.requirements?.filter(req => req?.allocatedPercent === 0) || [];
    const allocations = roleAllocations.find(r => r.roleId === role.id)?.allocations || [];

    const segments = stitchSegmentsByRange([
      { startDate: role.startDate, endDate: role.endDate },
      ...allocations,
      ...zeroPercent,
    ]);

    return populateSegmentByRole(role, segments, people);
  });

  return [].concat(...parsedRoles);
}

const printHandler = () => {
  const browser = detect();
  try {
    if (browser && browser.name.toLowerCase() === 'safari') {
      setTimeout(() => document.execCommand('print', false, null), 0);
    } else {
      setTimeout(() => window.print(), 0);
    }
  } catch (e) {
    setTimeout(() => window.print(), 0);
  }
};

const getPaperWidth = (paperSize) => {
  if (paperSize === TABLOID_PAPER) {
    return LANDSCAPE_TABLOID_PAPER_WIDTH;
  }
  return LANDSCAPE_LETTER_PAPER_WIDTH;
};

export { printHandler, getPaperWidth };
