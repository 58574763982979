import moment from 'moment';
import { CERTIFICATION_STATUSES } from '../constants';

const { ACTIVE, EXPIRING_SOON, EXPIRED } = CERTIFICATION_STATUSES;

export const getCertificationStatus = (expiryDate, daysWarnBeforeExpire) => {
  const todayMoment = moment().startOf('day');
  const expiryDateMoment = moment(expiryDate);
  const difference = moment(expiryDateMoment).diff(todayMoment, 'days');

  if (difference <= 0) return EXPIRED;

  if (difference <= daysWarnBeforeExpire) return EXPIRING_SOON;

  return ACTIVE;
};
