import { getActiveViewLabel } from 'src/analytics/utils';
import {
  TABLE_SET_COLUMN_ORDER,
  TABLE_SET_VISIBLE_COLUMNS,
} from '../../features/table/redux/constants';

import {
  getChangeColumnOrderEvent,
  getChangeColumnPayload,
} from './utils';

const events = {
  [TABLE_SET_COLUMN_ORDER]: {
    getEvent: (state) => {
      const currentView = getActiveViewLabel(state);
      return getChangeColumnOrderEvent(currentView);
    },
    getPayload: (state, action) => {
      const { updatedColumn } = action;
      return {
        'Column order is updated via drag and drop': `${updatedColumn}`,
      };
    },
  },
  [TABLE_SET_VISIBLE_COLUMNS]: {
    getEvent: (state) => {
      const currentView = getActiveViewLabel(state);
      return getChangeColumnOrderEvent(currentView);
    },
    getPayload: (state, action) => {
      const { table } = state;
      const { selectedColumnNames = [] } = action;
      const oldColumns = table.visibleColumns.filter(col => !table.alwaysVisible?.includes(col.name));

      return getChangeColumnPayload(selectedColumnNames, oldColumns);
    },
  },
};

export default events;
