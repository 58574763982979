import { call, put, takeLatest } from 'redux-saga/effects';
import {
  REPORTS_GET_PEOPLE_GANTT_ALLOCATIONS_BEGIN,
  REPORTS_GET_PEOPLE_GANTT_ALLOCATIONS_SUCCESS,
  REPORTS_GET_PEOPLE_GANTT_ALLOCATIONS_FAILURE,
  REPORTS_GET_PEOPLE_GANTT_ALLOCATIONS_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';
import { DEFAULT_PROJECT_ALLOCATION_STATES } from '../../projects/constants';

export function getPeopleGanttAllocations(accountId, start, end) {
  return {
    type: REPORTS_GET_PEOPLE_GANTT_ALLOCATIONS_BEGIN,
    accountId,
    start,
    end,
  };
}

export function dismissGetPeopleGanttAllocationsError() {
  return {
    type: REPORTS_GET_PEOPLE_GANTT_ALLOCATIONS_DISMISS_ERROR,
  };
}

export function* doGetPeopleGanttAllocations(action) {
  const { accountId, start, end } = action;
  let res;
  try {
    res = yield call(protectedJsonGet, `accounts/${accountId}/allocations`, { start, end, projectState: DEFAULT_PROJECT_ALLOCATION_STATES });
  } catch (err) {
    yield put({
      type: REPORTS_GET_PEOPLE_GANTT_ALLOCATIONS_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: REPORTS_GET_PEOPLE_GANTT_ALLOCATIONS_SUCCESS,
    data: res,
  });
}

export function* watchGetPeopleGanttAllocations() {
  yield takeLatest(REPORTS_GET_PEOPLE_GANTT_ALLOCATIONS_BEGIN, doGetPeopleGanttAllocations);
}

export function reducer(state, action) {
  switch (action.type) {
    case REPORTS_GET_PEOPLE_GANTT_ALLOCATIONS_BEGIN:
      return {
        ...state,
        getPeopleGanttAllocationsPending: true,
        getPeopleGanttAllocationsError: null,
      };

    case REPORTS_GET_PEOPLE_GANTT_ALLOCATIONS_SUCCESS:
      return {
        ...state,
        peopleGanttAllocations: action.data,
        getPeopleGanttAllocationsPending: false,
        getPeopleGanttAllocationsError: null,
      };

    case REPORTS_GET_PEOPLE_GANTT_ALLOCATIONS_FAILURE:
      return {
        ...state,
        getPeopleGanttAllocationsPending: false,
        getPeopleGanttAllocationsError: action.data.error,
      };

    case REPORTS_GET_PEOPLE_GANTT_ALLOCATIONS_DISMISS_ERROR:
      return {
        ...state,
        getPeopleGanttAllocationsError: null,
      };

    default:
      return state;
  }
}
