import { call, put, takeLatest } from 'redux-saga/effects';
import {
  LOGIN_ACCEPT_INVITATION_OAUTH_BEGIN,
  LOGIN_ACCEPT_INVITATION_OAUTH_SUCCESS,
  LOGIN_ACCEPT_INVITATION_OAUTH_FAILURE,
  LOGIN_ACCEPT_INVITATION_OAUTH_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';

export function acceptInvitationOauth(data, authType) {
  return {
    type: LOGIN_ACCEPT_INVITATION_OAUTH_BEGIN,
    data,
    authType,
  };
}

export function dismissAcceptInvitationOauthError() {
  return {
    type: LOGIN_ACCEPT_INVITATION_OAUTH_DISMISS_ERROR,
  };
}

export function* doAcceptInvitationOauth(action) {
  const { data, authType } = action;
  let res;
  try {
    res = yield call(protectedJsonPost, 'signup/oauth', data);
  } catch (err) {
    yield put({
      type: LOGIN_ACCEPT_INVITATION_OAUTH_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: LOGIN_ACCEPT_INVITATION_OAUTH_SUCCESS,
    data: res,
    authType,
  });
}

export function* watchAcceptInvitationOauth() {
  yield takeLatest(LOGIN_ACCEPT_INVITATION_OAUTH_BEGIN, doAcceptInvitationOauth);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case LOGIN_ACCEPT_INVITATION_OAUTH_BEGIN:
      return {
        ...state,
        acceptInvitationOauthPending: true,
        acceptInvitationOauthError: null,
      };

    case LOGIN_ACCEPT_INVITATION_OAUTH_SUCCESS:
      return {
        ...state,
        acceptInvitationOauthPending: false,
        acceptInvitationOauthError: null,
      };

    case LOGIN_ACCEPT_INVITATION_OAUTH_FAILURE:
      return {
        ...state,
        acceptInvitationOauthPending: false,
        acceptInvitationOauthError: action.data.error,
      };

    case LOGIN_ACCEPT_INVITATION_OAUTH_DISMISS_ERROR:
      return {
        ...state,
        acceptInvitationOauthError: null,
      };

    default:
      return state;
  }
}
