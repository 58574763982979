import moment from 'moment';
import { DEFAULT_PROJECT_COLOUR } from '../../common/redux/constants';
import { getFieldInput } from '../../../utils/miscUtils';
import {
  MIN_API_CONFIGURABLE_DATE,
  MIN_API_CONFIGURABLE_DATE_MESSAGE,
  ACCOUNT_MODULE_PURSUIT_TRACKING,
} from '../../../common/constants';
import { OPPORTUNITY, AWARDED } from '../constants';

const generateInputs = (projectFields, phases) => {
  if ((projectFields && projectFields.length === 0) || !projectFields || !phases) {
    return {
      requiredInputs: [],
      optionalInputs: [],
      dateInputs: [],
    };
  }

  const optionalInputs = [];

  let requiredInputs = [
    {
      required: true,
      placeholder: 'Enter name',
      width: 'large',
      primary: true,
      name: 'Name',
      type: 'text',
      maxLength: 200,
    },
    {
      width: 'small',
      name: 'Color',
      type: 'color',
      value: DEFAULT_PROJECT_COLOUR,
    },
    {
      label: 'Type',
      required: true,
      placeholder: 'Enter type',
      name: 'Type',
      type: 'select',
      options: [
        { label: OPPORTUNITY, value: OPPORTUNITY },
        { label: AWARDED, value: AWARDED },
      ],
      module: ACCOUNT_MODULE_PURSUIT_TRACKING,
    },
  ];

  const dateInputs = [
    {
      required: true,
      label: 'Project start date',
      name: 'Start date',
      type: 'date',
      minDate: moment(MIN_API_CONFIGURABLE_DATE),
      minDateMessage: MIN_API_CONFIGURABLE_DATE_MESSAGE,
    },
    {
      required: true,
      label: 'Project end date',
      name: 'End date',
      type: 'date',
      minDate: moment(MIN_API_CONFIGURABLE_DATE),
      minDateMessage: MIN_API_CONFIGURABLE_DATE_MESSAGE,
    },
  ];

  if (phases.length === 0) {
    requiredInputs = [...requiredInputs, ...dateInputs];
  }

  projectFields.forEach((field) => {
    if (field.isRequired) {
      requiredInputs.push(getFieldInput(field));
    } else {
      optionalInputs.push(getFieldInput(field));
    }
  });

  // Move Other to the end of the list
  optionalInputs.push(...optionalInputs.splice(optionalInputs.findIndex(input => input.label === 'Other'), 1));

  return {
    requiredInputs,
    optionalInputs,
    dateInputs,
  };
};

export {
  generateInputs,
};
