import { call, put, takeLatest } from 'redux-saga/effects';
import { protectedJsonPut } from 'src/utils/api';
import {
  ACCOUNT_SETTINGS_UPDATE_PHASES_BEGIN,
  ACCOUNT_SETTINGS_UPDATE_PHASES_SUCCESS,
  ACCOUNT_SETTINGS_UPDATE_PHASES_FAILURE,
  ACCOUNT_SETTINGS_UPDATE_PHASES_DISMISS_ERROR,
} from './constants';

export function updatePhases(accountId, newPhases) {
  return {
    type: ACCOUNT_SETTINGS_UPDATE_PHASES_BEGIN,
    accountId,
    data: newPhases,
  };
}

export function dismissUpdatePhasesError() {
  return {
    type: ACCOUNT_SETTINGS_UPDATE_PHASES_DISMISS_ERROR,
  };
}

export function* doUpdatePhases(action) {
  const { accountId, data } = action;
  try {
    yield call(protectedJsonPut, `accounts/${accountId}/phases`, data);
  } catch (err) {
    yield put({
      type: ACCOUNT_SETTINGS_UPDATE_PHASES_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: ACCOUNT_SETTINGS_UPDATE_PHASES_SUCCESS,
    accountId,
    data,
  });
}

export function* watchUpdatePhases() {
  yield takeLatest(ACCOUNT_SETTINGS_UPDATE_PHASES_BEGIN, doUpdatePhases);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNT_SETTINGS_UPDATE_PHASES_BEGIN:
      return {
        ...state,
        updatePhasesPending: true,
        updatePhasesError: null,
      };

    case ACCOUNT_SETTINGS_UPDATE_PHASES_SUCCESS:
      return {
        ...state,
        phases: action.data,
        updatePhasesPending: false,
        updatePhasesError: null,
      };

    case ACCOUNT_SETTINGS_UPDATE_PHASES_FAILURE:
      return {
        ...state,
        updatePhasesPending: false,
        updatePhasesError: action.data.error,
      };

    case ACCOUNT_SETTINGS_UPDATE_PHASES_DISMISS_ERROR:
      return {
        ...state,
        updatePhasesError: null,
      };

    default:
      return state;
  }
}
