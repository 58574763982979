import { call, put, takeLatest, all } from 'redux-saga/effects';
import {
  ACCOUNT_SETTINGS_GET_ACCOUNT_PROPERTIES_BEGIN,
  ACCOUNT_SETTINGS_GET_ACCOUNT_PROPERTIES_SUCCESS,
  ACCOUNT_SETTINGS_GET_ACCOUNT_PROPERTIES_FAILURE,
  ACCOUNT_SETTINGS_GET_ACCOUNT_PROPERTIES_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet, getBaseRequest } from '../../../utils/api';

export function getAccountProperties(accountId) {
  return {
    type: ACCOUNT_SETTINGS_GET_ACCOUNT_PROPERTIES_BEGIN,
    accountId,
  };
}

export function dismissGetAccountPropertiesError() {
  return {
    type: ACCOUNT_SETTINGS_GET_ACCOUNT_PROPERTIES_DISMISS_ERROR,
  };
}

export function* doGetAccountProperties(action) {
  const { accountId } = action;
  let props;
  let options;
  try {
    [props, options] = yield all([
      call(protectedJsonGet, `accounts/${accountId}/properties`),
      call(getBaseRequest, 'bench-defaults/countries'),
    ]);
  } catch (err) {
    yield put({
      type: ACCOUNT_SETTINGS_GET_ACCOUNT_PROPERTIES_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: ACCOUNT_SETTINGS_GET_ACCOUNT_PROPERTIES_SUCCESS,
    data: props,
    options,
  });
}

export function* watchGetAccountProperties() {
  yield takeLatest(ACCOUNT_SETTINGS_GET_ACCOUNT_PROPERTIES_BEGIN, doGetAccountProperties);
}

export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNT_SETTINGS_GET_ACCOUNT_PROPERTIES_BEGIN:
      return {
        ...state,
        getAccountPropertiesPending: true,
        getAccountPropertiesError: null,
      };

    case ACCOUNT_SETTINGS_GET_ACCOUNT_PROPERTIES_SUCCESS:
      return {
        ...state,
        accountProperties: action.data,
        accountPropertyOptions: action.options,
        getAccountPropertiesPending: false,
        getAccountPropertiesError: null,
      };

    case ACCOUNT_SETTINGS_GET_ACCOUNT_PROPERTIES_FAILURE:
      return {
        ...state,
        getAccountPropertiesPending: false,
        getAccountPropertiesError: action.data.error,
      };

    case ACCOUNT_SETTINGS_GET_ACCOUNT_PROPERTIES_DISMISS_ERROR:
      return {
        ...state,
        getAccountPropertiesError: null,
      };

    default:
      return state;
  }
}
