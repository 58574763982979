import { call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import {
  FORECAST_GET_BENCH_COST_OVERVIEW_BEGIN,
  FORECAST_GET_BENCH_COST_OVERVIEW_SUCCESS,
  FORECAST_GET_BENCH_COST_OVERVIEW_FAILURE,
  FORECAST_GET_BENCH_COST_OVERVIEW_DISMISS_ERROR,
} from './constants';
import graphApiClient from '../../../common/GraphAPIClient';
import { getBenchCostOverviewQuery } from '../queries/getBenchCostOverviewQuery';
import { MONTH_YEAR_FORMAT } from '../../../common/constants';

export function getBenchCostOverview(accountId, queries, startDate, endDate, pursuitFilter = []) {
  const includePursuitIds = pursuitFilter.map(pursuit => pursuit.id);
  const { query, variables } = getBenchCostOverviewQuery(accountId, queries, startDate, endDate, includePursuitIds);

  return {
    type: FORECAST_GET_BENCH_COST_OVERVIEW_BEGIN,
    query,
    variables,
  };
}

export function dismissGetBenchCostOverviewError() {
  return {
    type: FORECAST_GET_BENCH_COST_OVERVIEW_DISMISS_ERROR,
  };
}

export function* doGetBenchCostOverview(action) {
  const { query, variables } = action;
  let res;
  try {
    res = yield call(graphApiClient.query, { query, variables });
  } catch (err) {
    yield put({
      type: FORECAST_GET_BENCH_COST_OVERVIEW_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: FORECAST_GET_BENCH_COST_OVERVIEW_SUCCESS,
    data: res.data,
  });
}

export function* watchGetBenchCostOverview() {
  yield takeLatest(FORECAST_GET_BENCH_COST_OVERVIEW_BEGIN, doGetBenchCostOverview);
}

export function reducer(state, action) {
  switch (action.type) {
    case FORECAST_GET_BENCH_COST_OVERVIEW_BEGIN:
      return {
        ...state,
        getBenchCostOverviewPending: true,
        getBenchCostOverviewError: null,
      };

    case FORECAST_GET_BENCH_COST_OVERVIEW_SUCCESS:
      return {
        ...state,
        getBenchCostOverviewPending: false,
        getBenchCostOverviewError: null,
        benchCostOverview: {
          ...action.data?.benchCostOverview,
          items: action.data?.benchCostOverview?.items.map(item => ({ ...item, x: moment(item.x, MONTH_YEAR_FORMAT) })),
        },
      };

    case FORECAST_GET_BENCH_COST_OVERVIEW_FAILURE:
      return {
        ...state,
        getBenchCostOverviewPending: false,
        getBenchCostOverviewError: action.data.error,
      };

    case FORECAST_GET_BENCH_COST_OVERVIEW_DISMISS_ERROR:
      return {
        ...state,
        getBenchCostOverviewError: null,
      };

    default:
      return state;
  }
}
