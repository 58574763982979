import { call, put, takeLatest } from 'redux-saga/effects';
import { protectedJsonPatch } from '../../../utils/api';
import {
  PEOPLE_UPDATE_PERSON_FIELD_VALUE_BEGIN,
  PEOPLE_UPDATE_PERSON_FIELD_VALUE_SUCCESS,
  PEOPLE_UPDATE_PERSON_FIELD_VALUE_FAILURE,
  PEOPLE_UPDATE_PERSON_FIELD_VALUE_DISMISS_ERROR,
} from './constants';
import { updatePersonField } from '../utils/peopleUtils';

export function updatePersonFieldValue(accountId, personId, field, analyticsPayload) {
  return {
    type: PEOPLE_UPDATE_PERSON_FIELD_VALUE_BEGIN,
    accountId,
    personId,
    field,
    analyticsPayload,
  };
}

export function dismissUpdatePersonFieldValueError() {
  return {
    type: PEOPLE_UPDATE_PERSON_FIELD_VALUE_DISMISS_ERROR,
  };
}

export function* doUpdatePersonFieldValue(action) {
  const { accountId, personId, field, analyticsPayload } = action;
  try {
    yield call(protectedJsonPatch, `accounts/${accountId}/persons/${personId}/person-field-values/${field.id}`, { values: field.values });
  } catch (err) {
    yield put({
      type: PEOPLE_UPDATE_PERSON_FIELD_VALUE_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PEOPLE_UPDATE_PERSON_FIELD_VALUE_SUCCESS,
    personId,
    data: field,
    analyticsPayload,
  });
}

export function* watchUpdatePersonFieldValue() {
  yield takeLatest(PEOPLE_UPDATE_PERSON_FIELD_VALUE_BEGIN, doUpdatePersonFieldValue);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_UPDATE_PERSON_FIELD_VALUE_BEGIN:
      return {
        ...state,
        updatePersonFieldValuePending: true,
        updatePersonFieldValueError: null,
      };

    case PEOPLE_UPDATE_PERSON_FIELD_VALUE_SUCCESS: {
      const { personId, data } = action;
      const { personFieldValues, filteredPeople, personSelections } = state;

      const newPersonFieldValues = personFieldValues.map((person) => {
        if (person.personId === personId) {
          return updatePersonField(person, data);
        }
        return person;
      });

      const newFilteredPeople = filteredPeople.map((person) => {
        if (person.id === personId) {
          const updatedPersonFields = newPersonFieldValues.find(p => p.personId === personId);
          const newFields = updatedPersonFields ? updatedPersonFields.fields : person.fields;
          return {
            ...person,
            fields: newFields,
          };
        }
        return person;
      });

      const newPersonSelections = { ...personSelections };
      Object.keys(newPersonSelections).forEach((selectionId) => {
        const selectedPerson = newPersonSelections[selectionId];
        if (selectedPerson && selectedPerson.id === personId) {
          const updatedPerson = updatePersonField(selectedPerson, data);
          newPersonSelections[selectionId] = {
            ...updatedPerson,
          };
        }
      });

      return {
        ...state,
        personFieldValues: newPersonFieldValues,
        updatePersonFieldValuePending: false,
        updatePersonFieldValueError: null,
        filteredPeople: newFilteredPeople,
        personSelections: newPersonSelections,
      };
    }

    case PEOPLE_UPDATE_PERSON_FIELD_VALUE_FAILURE:
      return {
        ...state,
        updatePersonFieldValuePending: false,
        updatePersonFieldValueError: action.data.error,
      };

    case PEOPLE_UPDATE_PERSON_FIELD_VALUE_DISMISS_ERROR:
      return {
        ...state,
        updatePersonFieldValueError: null,
      };

    default:
      return state;
  }
}
