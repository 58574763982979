import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';

export default function TimelineSegment({
  blockData,
  percentWidth,
  topClass,
  bottomClass,
  onSegmentClick,
  placeholder,
}) {
  const onClick = (evt) => {
    evt.stopPropagation();
    onSegmentClick(evt.currentTarget, blockData);
  };

  const style = { width: `${percentWidth}%` };

  const renderSplitSegment = [topClass, bottomClass].reduce((splitSegment, barClass, index) => {
    const barType = index === 0 ? 'top-bar' : 'bottom-bar';
    const splitBar = (
      <div key={barType} className={classNames('split-time-bar', barType, barClass)} />
    );

    if (barClass.includes('tooltip-hover')) {
      splitSegment.push(
        <Tooltip key={barType} disableFocusListener disableTouchListener title={placeholder}>
          {splitBar}
        </Tooltip>,
      );
    } else {
      splitSegment.push(splitBar);
    }

    return splitSegment;
  }, []);

  return (
    <div
      role="presentation"
      className="common-timeline-segment"
      style={style}
      onClick={onClick}
    >
      {renderSplitSegment}
    </div>
  );
}

TimelineSegment.propTypes = {
  blockData: PropTypes.object,
  percentWidth: PropTypes.number.isRequired,
  topClass: PropTypes.string,
  bottomClass: PropTypes.string,
  onSegmentClick: PropTypes.func,
  placeholder: PropTypes.string,
};

TimelineSegment.defaultProps = {
  blockData: {},
  topClass: '',
  bottomClass: '',
  onSegmentClick: () => {},
  placeholder: '',
};
