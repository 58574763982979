import {
  LOCALE_SET_LOCALE,
} from './constants';

export function setLocale(locale) {
  return {
    type: LOCALE_SET_LOCALE,
    locale,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case LOCALE_SET_LOCALE: {
      const { locale } = action;
      return {
        ...state,
        locale,
      };
    }

    default:
      return state;
  }
}
