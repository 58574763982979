import {
  FAILURE,
  PERFORM_AN_ACTION,
  ERROR_SUBSTITUTIONS,
  REQUIRED_PRIVATE_FIELD,
  REQUIRED_FIELDS_MISSING,
  DEFAULT,
  NOT_ALL_VALUES_AVAILABLE,
  INVALID_PROJECT_DATE,
  UNABLE_TO_ADD_PROJECTS,
  UNABLE_TO_UPDATE_PROJECTS,
  PROJECT_DATES_UPDATE_FAILED,
  PROJECT_PHASES_UPDATE_FAILED,
  PROJECT_ROLES_UPDATE_FAILED,
  PROJECT_OPPORTUNITIES_PRESENT,
  UNABLE_TO_DISABLE_PURSUIT_TRACKING,
  PROFILE_ATTACHMENT_NAME_IN_USE,
  PROFILE_ATTACHMENT_ACCOUNT_LIMIT_REACHED,
  PROFILE_ATTACHMENT_VALIDATION_ERROR,
  PROFILE_ATTACHMENT_DOWNLOAD_ERROR,
  PROFILE_CERTIFICATION_DELETE_INSUFFICIENT_PERMISSIONS,
  PROFILE_ATTACHMENT_CERT_LINK_ERROR,
  SHARE_PEOPLE_GANTT_DATA_INVALID,
  SET_SHARE_PEOPLE_GANTT_DATA_FAILED,
} from './redux/constants';
import {
  PROJECTS_ADD_PROJECT_FAILURE,
  PROJECTS_UPDATE_PROJECT_FAILURE,
  PROJECTS_UPDATE_PROJECT_DURATION_FAILURE,
} from '../projects/redux/constants';
import {
  PEOPLE_UPLOAD_PERSON_ATTACHMENT_FAILURE,
  PEOPLE_UPLOAD_PERSON_ATTACHMENT_S3_FAILURE,
  PEOPLE_UPLOAD_PERSON_ATTACHMENT_CERT_LINK_FAILURE,
  PEOPLE_DOWNLOAD_PERSON_ATTACHMENT_FAILURE,
  PEOPLE_DELETE_PERSON_CERTIFICATION_FAILURE,
} from '../people/redux/constants';
import {
  ACCOUNT_SETTINGS_REMOVE_ACCOUNT_MODULE_FAILURE,
} from '../account-settings/redux/constants';
import {
  GANTT_GET_PEOPLE_GANTT_SHARE_DATA_FAILURE,
  GANTT_SET_PEOPLE_GANTT_SHARE_DATA_FAILURE,
} from '../gantt/redux/constants';

const parseGraphQLError = (error) => {
  const { graphQLErrors } = error;
  const messages = graphQLErrors.map(gqlError => gqlError.message);
  return messages[0];
};

const parseAPIError = (error) => {
  const { data: { errors } } = error;
  const messages = errors.map(apiError => apiError.description);
  return messages[0];
};

const parseActionType = (actionType) => {
  // First, check if there is an action type substitution.
  const substitution = ERROR_SUBSTITUTIONS[actionType];
  if (substitution) {
    return substitution;
  }

  // actionType has the format <FEATURE>_<ACTION>_<SUBJECT>_FAILURE
  // For example PROJECTS_GET_FILTERED_PROJECTS_FAILURE
  try {
    const items = actionType.split('_').splice(1);

    // Remove 'FAILURE'
    const failure = items.pop();
    if (failure !== FAILURE) {
      return PERFORM_AN_ACTION;
    }

    // Get the action
    const action = items[0].toLowerCase();
    const parsedAction = action === 'get' ? 'load' : action;

    // Extract the subject
    const subject = items.splice(1).join(' ').toLowerCase();

    const message = `${parsedAction} ${subject}`;
    return message;
  } catch {
    return PERFORM_AN_ACTION;
  }
};

const generateCustomMessage = (actionType, errorMessage, data, status) => {
  if (actionType === PROJECTS_ADD_PROJECT_FAILURE) {
    if (errorMessage.match(REQUIRED_FIELDS_MISSING) || errorMessage.match(NOT_ALL_VALUES_AVAILABLE)) {
      return {
        messageId: REQUIRED_PRIVATE_FIELD,
        message: null,
      };
    }
  }

  if (
    actionType === PROJECTS_ADD_PROJECT_FAILURE ||
    actionType === PROJECTS_UPDATE_PROJECT_FAILURE
  ) {
    if (errorMessage.match(INVALID_PROJECT_DATE)) {
      return {
        messageId:
          actionType === PROJECTS_ADD_PROJECT_FAILURE
            ? UNABLE_TO_ADD_PROJECTS
            : UNABLE_TO_UPDATE_PROJECTS,
        message: null,
      };
    }
  }

  if (actionType === PROJECTS_UPDATE_PROJECT_DURATION_FAILURE) {
    if ([
      PROJECT_DATES_UPDATE_FAILED,
      PROJECT_PHASES_UPDATE_FAILED,
      PROJECT_ROLES_UPDATE_FAILED,
    ].includes(errorMessage)) {
      return {
        messageId: errorMessage,
        message: null,
      };
    }
  }

  if (actionType === GANTT_GET_PEOPLE_GANTT_SHARE_DATA_FAILURE) {
    return {
      messageId: SHARE_PEOPLE_GANTT_DATA_INVALID,
      message: null,
    };
  }

  if (actionType === GANTT_SET_PEOPLE_GANTT_SHARE_DATA_FAILURE) {
    return {
      messageId: SET_SHARE_PEOPLE_GANTT_DATA_FAILED,
      message: null,
    };
  }


  if (actionType === ACCOUNT_SETTINGS_REMOVE_ACCOUNT_MODULE_FAILURE) {
    if (errorMessage.match(PROJECT_OPPORTUNITIES_PRESENT)) {
      return {
        messageId: UNABLE_TO_DISABLE_PURSUIT_TRACKING,
        message: null,
      };
    }
  }

  if (actionType === PEOPLE_UPLOAD_PERSON_ATTACHMENT_FAILURE) {
    const { errors } = data;

    if (errors.some(err => err.errorType === 'Duplicate')) {
      return {
        messageId: PROFILE_ATTACHMENT_NAME_IN_USE,
        message: null,
      };
    }

    if (errors.some(err => err.errorType === 'LimitExceeded')) {
      return {
        messageId: PROFILE_ATTACHMENT_ACCOUNT_LIMIT_REACHED,
        message: null,
      };
    }

    if (errors.some(err => err.errorType === 'ValidationError')) {
      return {
        messageId: PROFILE_ATTACHMENT_VALIDATION_ERROR,
        message: null,
      };
    }
  }

  if (actionType === PEOPLE_UPLOAD_PERSON_ATTACHMENT_S3_FAILURE) {
    return {
      messageId: PROFILE_ATTACHMENT_CERT_LINK_ERROR,
      message: null,
    };
  }

  if (actionType === PEOPLE_UPLOAD_PERSON_ATTACHMENT_CERT_LINK_FAILURE) {
    return {
      messageId: PROFILE_ATTACHMENT_CERT_LINK_ERROR,
      message: null,
    };
  }

  if (actionType === PEOPLE_DOWNLOAD_PERSON_ATTACHMENT_FAILURE) {
    return {
      messageId: PROFILE_ATTACHMENT_DOWNLOAD_ERROR,
      message: null,
    };
  }

  if (actionType === PEOPLE_DELETE_PERSON_CERTIFICATION_FAILURE) {
    if (status === 403) {
      return {
        messageId: PROFILE_CERTIFICATION_DELETE_INSUFFICIENT_PERMISSIONS,
        message: null,
      };
    }
  }

  return null;
};

const parseError = (error, actionType) => {
  const { graphQLErrors, message, data, status } = error;
  let errorMessage = 'An unexpected error occurred.';

  if (graphQLErrors?.length > 0) {
    errorMessage = parseGraphQLError(error);
  } else if (data) {
    if (data?.errors?.length > 0) {
      errorMessage = parseAPIError(error);
    } else if (typeof data === 'string' && data.length > 0) {
      errorMessage = data;
    }
  } else if (typeof message === 'string' && message.length > 0) {
    errorMessage = message;
  }

  return generateCustomMessage(actionType, errorMessage, data, status) || {
    messageId: DEFAULT,
    message: parseActionType(actionType),
  };
};

export { parseError };
