import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import deepEqual from 'react-fast-compare';
import { bindActionCreators } from 'redux';
import { isAuthorized, hasModuleEnabled } from 'src/features/permissions/utils/permissionUtils';
import {
  PERM_WRITE,
  PERM_PROJECT,
  PERM_READ,
  PERM_ROLE,
  PERM_ASSIGNMENT,
} from 'src/features/permissions/utils/constants';
import { Can, PermissionTabs } from 'src/features/wrapped-components';
import { NavigateBack, w000pureWhite } from '@bridgit/foundation';
import {
  ROLES_TAB_VALUE,
  SELF_PERFORM_TAB_VALUE,
  DETAILS_TAB_VALUE,
  DATE_DISPLAY_FORMAT,
  PROJECT_DETAIL_TABS,
  ACCOUNT_MODULE_STANDARD,
} from 'src/common/constants';
import {
  ProjectDateTab,
  ProjectDetailTab,
  ProjectDetailsSideMenu,
  ProjectResourceTab,
  ProjectResourceTabDetail,
  ProjectStatus,
  ProjectSelfPerformTab,
  ProjectMoreActions,
  ProjectTypeEditor,
} from '.';
import {
  addProjectRoles,
  updateProject,
  getProjectById,
  getProjectAllocations,
} from './redux/actions';
import { trackAnalytics } from '../common/redux/actions';
import { FieldEditor } from '../common';
import { getRoleNames } from '../account-settings/redux/actions';
import { PROJECT_LIST_SELECTION_ID } from './redux/constants';
import {
  PROJECT_DATES_TAB_CLICKED,
  PROJECT_DETAILS_TAB_CLICKED,
  PROJECT_HOURLY_ASSIGNMENTS_TAB_CLICKED,
  PROJECT_NAME_UPDATED,
  PROJECT_ROLES_TAB_CLICKED,
} from '../../analytics/projects/constants';

export class ProjectDetails extends Component {
  static propTypes = {
    className: PropTypes.string,
    accountId: PropTypes.number.isRequired,
    onBackClick: PropTypes.func,
    selectedProject: PropTypes.object.isRequired,
    addProjectRoles: PropTypes.func.isRequired,
    updateProject: PropTypes.func.isRequired,
    projectFields: PropTypes.array.isRequired,
    updateProjectPending: PropTypes.bool.isRequired,
    projects: PropTypes.arrayOf(PropTypes.object).isRequired,
    getFilteredProjectsPending: PropTypes.bool.isRequired,
    showBackButton: PropTypes.bool.isRequired,
    getRoleNames: PropTypes.func.isRequired,
    getProjectById: PropTypes.func.isRequired,
    runsInModal: PropTypes.bool,
    permissions: PropTypes.object.isRequired,
    getProjectAllocations: PropTypes.func.isRequired,
    accountModules: PropTypes.arrayOf(PropTypes.object).isRequired,
    projectSidePanelOpen: PropTypes.bool.isRequired,
    trackAnalytics: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: '',
    onBackClick: () => {},
    runsInModal: false,
  };

  constructor(props) {
    super(props);
    const {
      selectedProject,
      accountId,
      runsInModal,
      permissions,
      accountModules,
    } = props;

    this.scrollRef = createRef();
    this.shrinkRef = createRef();

    const rootMargin = runsInModal ? '-100px 0px 0px 0px' : '-285px 0px 0px 0px';

    this.observer = new IntersectionObserver(
      this.handleScroll,
      {
        root: this.scrollRef.current,
        rootMargin,
        threshold: 1,
      },
    );

    const hasRolesPerms = isAuthorized(accountId, permissions, PERM_READ, PERM_ROLE);
    const hasAssignmentPerms = isAuthorized(accountId, permissions, PERM_READ, PERM_ASSIGNMENT);
    const hasStandardModule = hasModuleEnabled(accountModules, ACCOUNT_MODULE_STANDARD);
    let selectedTab;

    if (hasRolesPerms && hasStandardModule) {
      selectedTab = ROLES_TAB_VALUE;
    } else if (hasAssignmentPerms) {
      selectedTab = SELF_PERFORM_TAB_VALUE;
    } else {
      selectedTab = DETAILS_TAB_VALUE;
    }

    this.state = {
      selectedTab,
      editing: null,
      projectColor: selectedProject ? selectedProject.colour : w000pureWhite,
      scrolled: false,
    };
  }

  componentDidMount() {
    const { accountId, getRoleNames } = this.props;

    this.observer.observe(this.shrinkRef.current);

    getRoleNames(accountId);
  }

  componentDidUpdate = (prevProps) => {
    const {
      accountId,
      permissions,
      getProjectById,
      selectedProject,
      updateProjectPending,
      getFilteredProjectsPending,
      getProjectAllocations,
    } = this.props;

    const projectChanged = !deepEqual(prevProps.selectedProject, selectedProject);
    const fieldsChange = (prevProps.getFilteredProjectsPending && !getFilteredProjectsPending);

    if (fieldsChange || projectChanged) {
      this.updateProjectState({
        projectColor: selectedProject ? selectedProject.colour : w000pureWhite,
      });
    }

    // Reload role data when project is updated
    if (prevProps.updateProjectPending && !updateProjectPending) {
      getProjectById(accountId, selectedProject.id, PROJECT_LIST_SELECTION_ID, false, permissions);
      getProjectAllocations(accountId, selectedProject.id);
    }
  }

  componentWillUnmount() {
    this.observer.unobserve(this.shrinkRef.current);
  }

  updateProjectState = newState => this.setState(newState);

  handleScroll = (entries) => {
    const { editing } = this.state;
    const scrolled = !entries[0].isIntersecting;
    this.setState({
      scrolled,
      editing: scrolled ? false : editing,
    });
  };

  fieldToEdit = field => this.setState({ editing: field });

  onNameUpdate = (name) => {
    this.updateProject({ name: name.values[0] });
    this.setState({
      editing: null,
    });
  }

  onValidate = (name) => {
    const { projects, selectedProject } = this.props;
    const foundProject = projects.find(project => project.id !== selectedProject.id && project.name.toLowerCase() === name.toLowerCase());
    return foundProject ? { name: 'Project name must be unique' } : {};
  }

  updateProject = (values) => {
    const { accountId, updateProject, selectedProject, runsInModal, trackAnalytics } = this.props;
    const origin = `Project Details${runsInModal ? ' Modal' : ''}`;
    updateProject(accountId, selectedProject.id, values);

    const analyticsPayload = {
      'Project name updated to': values.name,
      'Project name updated from': selectedProject.name,
      'Project ID': selectedProject.id,
      'Project is updated from': origin,
      'Project status': selectedProject.state,
      'Project type': selectedProject.type,
    };

    trackAnalytics(PROJECT_NAME_UPDATED, analyticsPayload);
  }

  handleChange = (event, value) => {
    const { selectedProject, runsInModal, trackAnalytics } = this.props;
    const clickedFrom = runsInModal ? 'Project Details Modal' : 'Project List';

    const trackInfo = {
      'Project Name': selectedProject.name,
      'Project ID': selectedProject.id,
      'Project status': selectedProject.state,
      'Project type': selectedProject.type,
      'Clicked from': clickedFrom,
    };

    const trackMessage = {
      0: PROJECT_ROLES_TAB_CLICKED,
      1: PROJECT_HOURLY_ASSIGNMENTS_TAB_CLICKED,
      2: PROJECT_DETAILS_TAB_CLICKED,
      3: PROJECT_DATES_TAB_CLICKED,
    };

    trackAnalytics(trackMessage[value], trackInfo);

    this.scrollRef.current.scrollTo(0, 0);

    this.setState({
      selectedTab: value,
    });
  };

  Content = () => {
    const { selectedProject, projectFields, addProjectRoles, runsInModal } = this.props;
    const { selectedTab, editing, projectColor } = this.state;
    const parentName = `Project Details${runsInModal ? ' Modal' : ''}`;
    switch (selectedTab) {
      case 0:
        return (
          <div>
            <ProjectResourceTab
              selectedProject={selectedProject}
            />
            <ProjectResourceTabDetail
              selectedProject={selectedProject}
              addProjectRoles={addProjectRoles}
              runsInModal={runsInModal}
            />
          </div>
        );
      case 1:
        return (
          <ProjectSelfPerformTab
            selectedProject={selectedProject}
          />
        );
      case 2:
        return (
          <ProjectDetailTab
            project={selectedProject}
            projectFields={projectFields}
            disabled={!!editing}
            selectedProjectFields={selectedProject.fields}
            projectColor={projectColor}
            parentName={parentName}
          />
        );
      case 3:
        return (
          <ProjectDateTab
            selectedProject={selectedProject}
            runsInModal={runsInModal}
          />
        );
      default:
        return null;
    }
  }

  TitleField = () => {
    const { selectedProject, updateProjectPending } = this.props;
    const { editing } = this.state;
    const nameInput = {
      id: 'name',
      name: 'name',
      type: 'text',
      required: true,
      value: selectedProject.name,
      maxLength: 200,
    };

    return (
      <FieldEditor
        item={nameInput}
        input={nameInput}
        onSave={this.onNameUpdate}
        onValidate={this.onValidate}
        disabled={updateProjectPending}
        permission={{ action: PERM_WRITE, subject: PERM_PROJECT }}
        pending={updateProjectPending}
        fieldToEdit={this.fieldToEdit}
        editing={editing === 'name'}
        showName={false}
      />
    );
  }

  render() {
    const {
      className,
      onBackClick,
      selectedProject,
      showBackButton,
      runsInModal,
      projectSidePanelOpen,
    } = this.props;
    const { selectedTab, editing, scrolled } = this.state;

    const startDate = moment(selectedProject.startDate);
    const endDate = moment(selectedProject.endDate);
    const dateRange = `${startDate.format(DATE_DISPLAY_FORMAT)} - ${endDate.format(DATE_DISPLAY_FORMAT)}`;

    let statusText;
    let statusClass;
    if (selectedProject.totalRoles === 0) {
      statusClass = 'not-defined';
      statusText = 'Roles not defined';
    } else if (selectedProject.unfilledRoles === 0) {
      statusText = '0 Roles unfilled';
      statusClass = 'filled';
    } else {
      statusText = `${selectedProject.unfilledRoles} Role${selectedProject.unfilledRoles > 1 ? 's' : ''} unfilled`;
      statusClass = 'unfilled';
    }

    const showBack = runsInModal ? showBackButton : true;
    const navLabel = runsInModal ? 'Back to allocation modal' : 'Back to list of projects';

    return (
      <Paper
        elevation={0}
        className={classNames(
          'projects-project-details',
          className,
          { 'runs-in-modal': runsInModal, 'panel-open': projectSidePanelOpen, 'is-scrolled': scrolled },
        )}
        ref={this.scrollRef}
      >
        { showBack && (
          <div className={classNames('nav-wrapper', { 'back-to-papm': runsInModal })}>
            <NavigateBack
              onClick={onBackClick}
              label={navLabel}
              showLabel={runsInModal}
              edge="start"
            />
          </div>
        )}
        <div className="detail">
          <div className="detail-wrapper">
            <div className={classNames('header-content', { scrolled })}>
              <div className="header-wrapper">
                <div className="header-left">
                  <div className="title-wrapper">
                    <div className="title-field">
                      <this.TitleField />
                    </div>
                    <div className="title-header-right">
                      <Can
                        action={PERM_READ}
                        subject={PERM_ROLE}
                        yes={(
                          <div className={classNames('resource-status', statusClass)}>
                            {statusText}
                          </div>
                        )}
                      />
                      <ProjectTypeEditor
                        selectedProject={selectedProject}
                        runsInModal={runsInModal}
                      />
                      <Can
                        action={PERM_WRITE}
                        subject={PERM_PROJECT}
                        yes={(
                          <ProjectMoreActions
                            selectedProject={selectedProject}
                            onDelete={onBackClick}
                            runsInModal={runsInModal}
                          />
                        )}
                      />
                    </div>
                  </div>
                  { !editing &&
                    (
                      <div className="date-status-container">
                        <p className="title" variant="subtitle1">{dateRange}</p>
                        <ProjectStatus project={selectedProject} />
                      </div>
                    )}
                </div>
              </div>
              <div className="tabs-wrapper">
                <PermissionTabs
                  tabs={PROJECT_DETAIL_TABS}
                  value={selectedTab}
                  onChange={this.handleChange}
                />
                <hr />
              </div>
            </div>
            <div className="pixel-to-watch" ref={this.shrinkRef} />
            <div className="content-wrapper">
              <this.Content />
            </div>
          </div>
        </div>
        { !runsInModal && <ProjectDetailsSideMenu selectedProject={selectedProject} /> }
      </Paper>
    );
  }
}

const mapStateToProps = ({ projects, accountSettings, common, login }) => {
  const {
    getFilteredProjectsPending,
    updateProjectPending,
    projects: projectsProjects,
  } = projects;

  const { projectFields, accountModules } = accountSettings;
  const { accountId, multiStateModal, projectSidePanelOpen } = common;
  const { showBackButton } = multiStateModal;
  const { permissions } = login.userInfo;

  return {
    getFilteredProjectsPending,
    updateProjectPending,
    projects: projectsProjects,
    projectFields,
    accountId,
    showBackButton,
    permissions,
    accountModules,
    projectSidePanelOpen,
  };
};

const mapDispatchToProps = dispatch => ({
  addProjectRoles: bindActionCreators(addProjectRoles, dispatch),
  updateProject: bindActionCreators(updateProject, dispatch),
  getRoleNames: bindActionCreators(getRoleNames, dispatch),
  getProjectById: bindActionCreators(getProjectById, dispatch),
  getProjectAllocations: bindActionCreators(getProjectAllocations, dispatch),
  trackAnalytics: bindActionCreators(trackAnalytics, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetails);
