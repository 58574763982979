import { call, put, takeLatest } from 'redux-saga/effects';
import { protectedJsonPut } from 'src/utils/api';
import { SEGMENT_TYPE_PHASE } from 'src/features/edit-dates/common/constants';
import {
  PROJECTS_UPDATE_PROJECT_PHASE_BEGIN,
  PROJECTS_UPDATE_PROJECT_PHASE_SUCCESS,
  PROJECTS_UPDATE_PROJECT_PHASE_FAILURE,
  PROJECTS_UPDATE_PROJECT_PHASE_DISMISS_ERROR,
} from './constants';
import { updateProjectPhaseById } from '../utils/projectUtils';

export function updateProjectPhase(accountId, project, phaseId, expandSubPhases, data, analyticsPayload = { }) {
  return {
    type: PROJECTS_UPDATE_PROJECT_PHASE_BEGIN,
    accountId,
    project,
    phaseId,
    expandSubPhases,
    data,
    analyticsPayload,
  };
}

export function dismissUpdateProjectPhaseError() {
  return {
    type: PROJECTS_UPDATE_PROJECT_PHASE_DISMISS_ERROR,
  };
}

export function* doUpdateProjectPhase(action) {
  const { accountId, project, phaseId, expandSubPhases, data, analyticsPayload } = action;
  let res;
  try {
    res = yield call(protectedJsonPut, `accounts/${accountId}/projects/${project.id}/phases/${phaseId}?expandSubPhases=${expandSubPhases}`, data);
  } catch (err) {
    yield put({
      type: PROJECTS_UPDATE_PROJECT_PHASE_FAILURE,
      data: { error: err },
    });
    return;
  }
  yield put({
    type: PROJECTS_UPDATE_PROJECT_PHASE_SUCCESS,
    accountId,
    project,
    phaseId,
    expandSubPhases,
    data: res,
    analyticsPayload,
  });
}

export function* watchUpdateProjectPhase() {
  yield takeLatest(PROJECTS_UPDATE_PROJECT_PHASE_BEGIN, doUpdateProjectPhase);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PROJECTS_UPDATE_PROJECT_PHASE_BEGIN:
      return {
        ...state,
        updateProjectPhasePending: true,
        updateProjectPhaseError: null,
      };

    case PROJECTS_UPDATE_PROJECT_PHASE_SUCCESS: {
      const { project, phaseId, data } = action;
      const { filteredProjects, projectSelections } = state;
      const formattedData = { ...data, __typename: SEGMENT_TYPE_PHASE };

      const newFilteredProjects = filteredProjects.map((proj) => {
        if (proj.id === project.id) {
          return updateProjectPhaseById(proj, phaseId, formattedData);
        }
        return proj;
      });

      const newProjectSelections = { ...projectSelections };
      Object.keys(newProjectSelections).forEach((selectionId) => {
        const selectedProject = newProjectSelections[selectionId];
        if (selectedProject?.id === project.id) {
          const updatedProject = updateProjectPhaseById(selectedProject, phaseId, formattedData);
          newProjectSelections[selectionId] = {
            ...updatedProject,
          };
        }
      });

      return {
        ...state,
        filteredProjects: newFilteredProjects,
        updateProjectPhasePending: false,
        updateProjectPhaseError: null,
        projectSelections: newProjectSelections,
      };
    }

    case PROJECTS_UPDATE_PROJECT_PHASE_FAILURE:
      return {
        ...state,
        updateProjectPhasePending: false,
        updateProjectPhaseError: action.data.error,
      };

    case PROJECTS_UPDATE_PROJECT_PHASE_DISMISS_ERROR:
      return {
        ...state,
        updateProjectPhaseError: null,
      };

    default:
      return state;
  }
}
