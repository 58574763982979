import { call, put, takeLatest } from 'redux-saga/effects';
import {
  SELF_PERFORM_GET_FILTERED_REQUESTS_BEGIN,
  SELF_PERFORM_GET_FILTERED_REQUESTS_SUCCESS,
  SELF_PERFORM_GET_FILTERED_REQUESTS_FAILURE,
  SELF_PERFORM_GET_FILTERED_REQUESTS_DISMISS_ERROR,
} from './constants';
import graphApiClient from '../../../common/GraphAPIClient';
import { collateFieldValues } from '../../../filters/graphqlUtils';
import { getRequestListQuery, getAssignmentListQuery } from '../queries/getRequestListQuery';

export function getFilteredRequests(accountId, queries, pageFrom, data) {
  const { isAssignmentTable } = data;
  const { query, variables } = isAssignmentTable
    ? getAssignmentListQuery(accountId, queries, pageFrom)
    : getRequestListQuery(accountId, queries, pageFrom);

  return {
    type: SELF_PERFORM_GET_FILTERED_REQUESTS_BEGIN,
    query,
    variables,
  };
}

export function dismissGetFilteredRequestsError() {
  return {
    type: SELF_PERFORM_GET_FILTERED_REQUESTS_DISMISS_ERROR,
  };
}

export function* doGetFilteredRequests(action) {
  const { query, variables } = action;

  let res;
  try {
    res = yield call(graphApiClient.query, { query, variables });
  } catch (err) {
    yield put({
      type: SELF_PERFORM_GET_FILTERED_REQUESTS_FAILURE,
      data: { error: err },
      isPaging: variables.from !== 0,
    });
    return;
  }

  yield put({
    type: SELF_PERFORM_GET_FILTERED_REQUESTS_SUCCESS,
    data: res.data,
    clearData: variables.from === 0,
  });
}

export function* watchGetFilteredRequests() {
  yield takeLatest(SELF_PERFORM_GET_FILTERED_REQUESTS_BEGIN, doGetFilteredRequests);
}

export function reducer(state, action) {
  switch (action.type) {
    case SELF_PERFORM_GET_FILTERED_REQUESTS_BEGIN: {
      const { variables } = action;
      const isPaging = variables.from > 0;
      return {
        ...state,
        getFilteredRequestsPagePending: isPaging,
        getFilteredRequestsPending: !isPaging,
        getFilteredRequestsError: null,
      };
    }
    case SELF_PERFORM_GET_FILTERED_REQUESTS_SUCCESS: {
      const {
        data: { requests: { items, count, cursor, hasMore } },
        clearData,
      } = action;

      const collatedData = collateFieldValues(items);

      return {
        ...state,
        filteredRequests: clearData ? collatedData : [...state.filteredRequests, ...collatedData],
        requestCount: count,
        pageFrom: cursor,
        hasMore,
        getFilteredRequestsPagePending: false,
        getFilteredRequestsPending: false,
        getFilteredRequestsError: null,
      };
    }
    case SELF_PERFORM_GET_FILTERED_REQUESTS_FAILURE: {
      const { data, isPaging } = action;
      return {
        ...state,
        getFilteredRequestsPending: false,
        getFilteredRequestsError: isPaging ? null : data.error,
        getFilteredRequestPageError: isPaging ? data.error : null,
      };
    }
    case SELF_PERFORM_GET_FILTERED_REQUESTS_DISMISS_ERROR:
      return {
        ...state,
        getFilteredRequestsError: null,
        getFilteredRequestPageError: null,
      };

    default:
      return state;
  }
}
