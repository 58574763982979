import { getTableConfigFromSchema } from 'src/features/table/filterUtils';
import { SKILL_SET_FIELD } from 'src/features/self-perform/constants';

export const HOURLY_PERSON_FIELDS = [
  {
    schemaName: 'id',
    displayName: 'Hourly person ID',
    visible: false,
  },
  {
    schemaName: 'accountId',
    displayName: 'Account id',
    visible: false,
  },
  {
    schemaName: 'name',
    displayName: 'Name',
    alwaysVisible: true,
    sort: true,
  },
  {
    schemaName: 'skillSet',
    displayName: SKILL_SET_FIELD,
    defaultVisible: true,
    sort: true,
  },
  {
    schemaName: 'currentAssignments',
    displayName: 'Current assignment end',
    defaultVisible: true,
    sort: true,
  },
  {
    schemaName: 'upcomingAssignments',
    displayName: 'Next assignment start',
    defaultVisible: true,
    sort: true,
  },
];

export const HOURLY_PERSON_FIELDS_DEFAULTS = {
  displayName: '',
  visible: true,
  defaultVisible: false,
  alwaysVisible: false,
  filter: false,
  sort: false,
};

export const hourlyPersonDescriptor = HOURLY_PERSON_FIELDS.map(field => ({
  ...HOURLY_PERSON_FIELDS_DEFAULTS,
  ...field,
}));

export const HOURLY_PERSON_DISPLAY_NAMES = hourlyPersonDescriptor.reduce((displayNames, field) => {
  if (field.displayName.length) displayNames.push(field.displayName);
  return displayNames;
}, []);

export const HOURLY_PERSONS_SCHEMAS = HOURLY_PERSON_FIELDS.map(field => field.schemaName);

export const HourlyPersonsTableConfig = getTableConfigFromSchema(hourlyPersonDescriptor);
