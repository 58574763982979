import moment from 'moment';
import {
  DATE_DISPLAY_FORMAT,
  DEFAULT_POU_START,
  DEFAULT_POU_END,
  PRE_EMPLOYMENT,
  POST_EMPLOYMENT,
  MAX_CONFIGURABLE_DATE,
} from 'src/common/constants';
import { naturalSort } from 'src/utils/sortUtils';

export const trimUnavailabilities = (employmentDates, pous) => {
  if (!pous || !pous.length) return [];
  const filteredPous = pous.filter(pou => pou.rangeType !== PRE_EMPLOYMENT && pou.rangeType !== POST_EMPLOYMENT);
  return naturalSort(filteredPous, 'startDate');
};

const overlapsHireDate = (startDate, momentHireDate, data, pouEnd) => (startDate && !momentHireDate.isSame(data.startDate)
    && (momentHireDate.isBetween(data.startDate, pouEnd, null, '[]')
    || momentHireDate.isAfter(pouEnd)));

const overlapsTerminationDate = (endDate, momentTerminationDate, data, pouEnd) => (endDate && !momentTerminationDate.isSame(data.endDate)
    && (momentTerminationDate.isBetween(data.startDate, pouEnd, null, '[]')
    || momentTerminationDate.isBefore(data.startDate)));

const overlapsPou = (pou, data, pouEnd) => {
  const momentDataStart = moment(data.startDate);
  return ((moment(pou.startDate).isBetween(data.startDate, pouEnd, null, '[]')
    || moment(pou.endDate).isBetween(data.startDate, pouEnd, null, '[]')
    || momentDataStart.isBetween(pou.startDate, pou.endDate, null, '[]')
    || moment(data.endDate).isBetween(pou.startDate, pou.endDate, null, '[]'))
    && pou.id !== data.id);
};

export const validateAllocationUpdate = (employmentDates, pous = [], data) => {
  if (!data || !data.startDate || !data.endDate) return null;
  const inferredEndDate = employmentDates && employmentDates.endDate ? employmentDates.endDate : moment(MAX_CONFIGURABLE_DATE);
  const pouEnd = data.endDate || inferredEndDate;
  const errors = [];
  if (employmentDates) {
    const { startDate, endDate } = employmentDates;
    const momentHireDate = moment(startDate || undefined);
    const momentTerminationDate = moment(endDate || undefined);
    if (overlapsHireDate(startDate, momentHireDate, data, pouEnd)) {
      errors.push({ message: `Before ${momentHireDate.format(DATE_DISPLAY_FORMAT)}`, sortDate: startDate });
    }
    if (overlapsTerminationDate(endDate, momentTerminationDate, data, pouEnd)) {
      errors.push({ message: `After ${momentTerminationDate.format(DATE_DISPLAY_FORMAT)}`, sortDate: endDate });
    }
  }
  naturalSort(trimUnavailabilities(employmentDates, pous), 'startDate').forEach((pou) => {
    if (overlapsPou(pou, data, pouEnd)) {
      if (pou.startDate === DEFAULT_POU_START) {
        errors.push({ message: `Before ${moment(pou.endDate).format(DATE_DISPLAY_FORMAT)}`, sortDate: pou.endDate });
      } else if (pou.endDate === DEFAULT_POU_END) {
        errors.push({ message: `After ${moment(pou.startDate).format(DATE_DISPLAY_FORMAT)}`, sortDate: pou.startDate });
      } else {
        errors.push({ message: `${moment(pou.startDate).format(DATE_DISPLAY_FORMAT)} - ${moment(pou.endDate).format(DATE_DISPLAY_FORMAT)}`, sortDate: pou.startDate });
      }
    }
  });
  return errors.length ? naturalSort(errors, 'sortDate').map(error => error.message) : null;
};

export const validateUnavailability = (employmentDates, pous, data) => {
  if (!data.startDate) return 'Start date is required';
  let error = null;
  const momentDataStart = moment(data.startDate);
  const inferredEndDate = employmentDates && employmentDates.endDate ? employmentDates.endDate : moment(MAX_CONFIGURABLE_DATE);
  const pouEnd = data.endDate || inferredEndDate;
  if (employmentDates) {
    const { startDate, endDate } = employmentDates;
    const momentHireDate = moment(startDate || undefined);
    const momentTerminationDate = moment(endDate || undefined);
    if (overlapsHireDate(startDate, momentHireDate, data, pouEnd)) {
      return 'Time off cannot overlap with a person’s hire date.';
    }
    if (overlapsTerminationDate(endDate, momentTerminationDate, data, pouEnd)) {
      return 'Time off cannot overlap with a person’s termination date.';
    }
  }
  pous.forEach((pou) => {
    if (overlapsPou(pou, data, pouEnd)) {
      error = 'Time off cannot overlap with other time off entries.';
    }
  });
  if (momentDataStart.isAfter(pouEnd)) return 'End date must be later than start date';
  return error;
};

export const validateEmploymentPouOverlap = (editField, value, employmentDates, pous) => {
  let valid = true;
  const momentValue = moment(value);
  const filteredPous = trimUnavailabilities(employmentDates, pous || []);
  filteredPous.forEach((pou) => {
    if ((editField === 'start' && momentValue.isAfter(pou.startDate))
    || (editField === 'end' && momentValue.isBefore(pou.endDate))) {
      valid = false;
    }
  });
  return valid;
};
