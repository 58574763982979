import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DoneIcon from '@material-ui/icons/Done';
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Chip,
} from '@material-ui/core';

export default function RadioChipForm({
  title,
  controls,
  onChange,
  defaultValue,
  isMultiSelect,
  styleType,
}) {
  const defaultValues = [defaultValue].flat();
  const [value, setValue] = useState(defaultValues);

  const handleChange = name => (evt) => {
    evt.stopPropagation();
    if (isMultiSelect) {
      if (value.includes(name)) {
        const updatedValues = value.filter(item => item !== name);
        setValue(updatedValues);
      } else {
        setValue([...value, name]);
      }
    } else {
      setValue([name]);
    }
    return isMultiSelect ? onChange({ name, value: name }) : onChange(name);
  };

  const renderIcon = (controlName) => {
    if (isMultiSelect && value.includes(controlName)) {
      return <DoneIcon className="radio-chip-selected-icon" />;
    }
    return null;
  };

  return (
    <FormControl className="common-radio-chip-form">
      {!!title && <FormLabel className="radio-chip-form-title">{title}</FormLabel>}
      <FormGroup className={classnames('radio-chip-group', {
        bubble: styleType === 'bubble',
      })}
      >
        {controls.map(({ name, label }) => (
          <FormControlLabel
            className="radio-chip-label"
            key={name}
            control={(
              <Chip
                className={classnames('radio-chip', {
                  selected: value.includes(name),
                })}
                label={label}
                onClick={handleChange(name)}
                disableRipple
                icon={renderIcon(name)}
              />
            )}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}

RadioChipForm.propTypes = {
  title: PropTypes.string,
  controls: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  isMultiSelect: PropTypes.bool,
  styleType: PropTypes.string,
};

RadioChipForm.defaultProps = {
  title: '',
  controls: [],
  onChange: () => {},
  defaultValue: null,
  isMultiSelect: false,
  styleType: '',
};
