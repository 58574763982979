import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Loader } from '@bridgit/foundation';
import { Paper } from '@material-ui/core';
import { Can, PermissionTabs } from 'src/features/wrapped-components';
import { capitalize } from 'src/utils/formatters';
import { AccessDenied } from 'src/features/permissions';
import { PERM_WRITE, PERM_PERSON } from 'src/features/permissions/utils/constants';
import { isAuthorized, hasModuleEnabled } from 'src/features/permissions/utils/permissionUtils';
import { ACCOUNT_MODULE_STANDARD } from 'src/common/constants';
import {
  collectFields,
  getAccountModules,
} from './redux/actions';
import { TabBar } from '../common';
import { ErrorBoundary } from '../errors';
import { AccountSettings, ContentSettings } from '.';
import {
  tabs,
  sideTabs,
  SETTINGS_SIDE_TABS,
  SETTINGS_TABS,
} from './common/constants';

const { PHASES, DEACTIVATED_PEOPLE, PERMISSION_GROUPS, SERVICE_ACCOUNTS, CERTIFICATIONS } = SETTINGS_SIDE_TABS;
const { ACCOUNT, PEOPLE, PROJECTS, SELF_PERFORM } = SETTINGS_TABS;
const TAB_PROPS = { style: { display: 'none' } };

function SettingsNav({
  match,
  history,
}) {
  const dispatch = useDispatch();
  const { accountId, collapsedNav } = useSelector(({ common }) => common);
  const { accountModules } = useSelector(({ accountSettings }) => accountSettings);
  const { permissions } = useSelector(({ login: { userInfo } }) => userInfo);

  const defaultTab = useMemo(() => {
    const hasPeoplePerms = isAuthorized(accountId, permissions, PERM_WRITE, PERM_PERSON);
    const hasStandardModule = hasModuleEnabled(accountModules, ACCOUNT_MODULE_STANDARD);
    return hasPeoplePerms && hasStandardModule ? PEOPLE : PROJECTS;
  }, [accountId, accountModules, permissions]);

  const [activeTab, setActiveTab] = useState(match?.params?.type
    ? capitalize(match.params.type)
    : defaultTab);
  const [activeSideTab, setActiveSideTab] = useState(match?.params?.subType
    ? match.params.subType
    : sideTabs[activeTab]?.[0].value);
  const [action, setAction] = useState(null);
  const [subject, setSubject] = useState(null);
  const [module, setModule] = useState(null);
  const [checkBridgitPerms, setCheckBridgitPerms] = useState(false);

  // Validate that URL params are correct, if not redirect to default settings tab (PEOPLE)
  useEffect(() => {
    const { type, subType } = match.params;
    const tabMatch = Object.values(SETTINGS_TABS).find(tab => tab === capitalize(type ?? ''));
    const subTabMatch = Object.values(SETTINGS_SIDE_TABS).find(tab => tab === subType);

    if ((type && !tabMatch) || (subType && !subTabMatch)) {
      setActiveTab(defaultTab);
      setActiveSideTab(sideTabs[defaultTab][0].value);
      history.replace(`/accounts/${accountId}/settings/${defaultTab.toLowerCase()}/${sideTabs[defaultTab][0].value}`);
    }
  }, [accountId, history, match.params, defaultTab]);

  // Make sure that URL isn't missing tabs
  useEffect(() => {
    const { type, subType } = match.params;
    if (!type || !subType) {
      history.replace(`/accounts/${accountId}/settings/${activeTab.toLowerCase()}/${activeSideTab}`);
    }
  }, [match, activeTab, accountId, activeSideTab, history]);

  useEffect(() => {
    dispatch(getAccountModules(accountId));
  }, [dispatch, accountId]);

  useEffect(() => {
    dispatch(collectFields(accountId, permissions));
  }, [accountId, permissions, dispatch]);

  useEffect(() => {
    const tabMatch = sideTabs[activeTab]
      ? sideTabs[activeTab].find(tab => tab.value === activeSideTab)
      : null;
    if (tabMatch) {
      setAction(tabMatch.action);
      setSubject(tabMatch.subject);
      setModule(tabMatch.module);
      setCheckBridgitPerms(!!tabMatch.checkBridgitPerms);
    }
  }, [activeTab, activeSideTab]);

  const handleTabsChange = useCallback((event, newActiveTab) => {
    setActiveTab(newActiveTab);
    setActiveSideTab(sideTabs[newActiveTab][0].value);
    history.replace(`/accounts/${match.params.id}/settings/${newActiveTab.toLowerCase()}/${sideTabs[newActiveTab][0].value}`);

    if (newActiveTab === SELF_PERFORM) {
      window.mixpanel.track('Navigate to Hourly People Settings');
    }
  }, [history, match.params.id]);

  const handleSideTabChange = useCallback((e, newActiveSideTab) => {
    if (newActiveSideTab === PHASES) {
      window.mixpanel.track('Navigate to Phase Setup');
    } else if (newActiveSideTab === DEACTIVATED_PEOPLE) {
      window.mixpanel.track('View Deactivated people');
    } else if (newActiveSideTab === PERMISSION_GROUPS) {
      window.mixpanel.track('Navigate to Settings - Permission Groups');
    } else if (newActiveSideTab === SERVICE_ACCOUNTS) {
      window.mixpanel.track('Navigate to Settings - Service Accounts');
    } else if (newActiveSideTab === CERTIFICATIONS) {
      window.mixpanel.track('Navigate to Certification Setup');
    }

    setActiveSideTab(newActiveSideTab);
    history.replace(`/accounts/${match.params.id}/settings/${activeTab.toLowerCase()}/${newActiveSideTab}`);
  }, [activeTab, history, match.params.id]);

  const settingsContent = useMemo(() => {
    const renderBody = () => {
      switch (activeTab) {
        case ACCOUNT:
          return <AccountSettings activeSideTab={activeSideTab} />;
        case PEOPLE:
        case PROJECTS:
        case SELF_PERFORM:
          return <ContentSettings activeTab={activeTab} activeSideTab={activeSideTab} />;
        default:
          return null;
      }
    };

    if (!activeTab || !action || !subject) {
      return <Loader />;
    }

    return (
      <div className="settings-container app-page-main content-container">
        <Paper className="side-tabs">
          <PermissionTabs
            tabs={sideTabs[activeTab]}
            value={activeSideTab}
            onChange={handleSideTabChange}
            TabIndicatorProps={TAB_PROPS}
          />
        </Paper>
        {renderBody()}
      </div>
    );
  }, [action, activeSideTab, activeTab, handleSideTabChange, subject]);

  const accessDenied = useMemo(() => <AccessDenied />, []);

  return (
    <ErrorBoundary>
      <div className="account-settings-settings-nav">
        <TabBar
          handleChange={handleTabsChange}
          labels={tabs}
          value={activeTab}
          collapse={collapsedNav}
          inclusive
        />
        <Can
          action={action}
          subject={subject}
          module={module}
          checkBridgitPerms={checkBridgitPerms}
          yes={settingsContent}
          no={accessDenied}
          inclusive
        />
      </div>
    </ErrorBoundary>
  );
}

SettingsNav.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default SettingsNav;
