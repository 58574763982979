import { ROLE_SCHEMA } from '../../../common/descriptors/role';

export function parseRoleSegment(segment) {
  const { roleId, roleName, startDate, endDate, project, requirements } = segment || {};
  const { id, name, state, colour } = project || {};

  return {
    meta: {
      projectColor: colour,
      projectId: id,
    },
    id: `${roleId}-${startDate}`,
    [ROLE_SCHEMA.roleName]: roleName,
    [ROLE_SCHEMA.projectName]: name,
    [ROLE_SCHEMA.projectStatus]: state,
    [ROLE_SCHEMA.startDate]: startDate,
    [ROLE_SCHEMA.endDate]: endDate,
    [ROLE_SCHEMA.allocation]: requirements.length === 1 ? requirements[0].percent : 'Custom',
  };
}
