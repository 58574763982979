import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PEOPLE_REACTIVATE_PERSON_BEGIN,
  PEOPLE_REACTIVATE_PERSON_SUCCESS,
  PEOPLE_REACTIVATE_PERSON_FAILURE,
  PEOPLE_REACTIVATE_PERSON_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';

export function reactivatePerson(accountId, personId) {
  return {
    type: PEOPLE_REACTIVATE_PERSON_BEGIN,
    accountId,
    personId,
  };
}

export function dismissReactivatePersonError() {
  return {
    type: PEOPLE_REACTIVATE_PERSON_DISMISS_ERROR,
  };
}

export function* doReactivatePerson(action) {
  const { accountId, personId } = action;

  try {
    yield call(protectedJsonPost, `accounts/${accountId}/persons/${personId}/restore`);
  } catch (err) {
    yield put({
      type: PEOPLE_REACTIVATE_PERSON_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PEOPLE_REACTIVATE_PERSON_SUCCESS,
    personId,
  });
}

export function* watchReactivatePerson() {
  yield takeLatest(PEOPLE_REACTIVATE_PERSON_BEGIN, doReactivatePerson);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_REACTIVATE_PERSON_BEGIN:
      return {
        ...state,
        reactivatePersonPending: true,
        reactivatePersonError: null,
      };

    case PEOPLE_REACTIVATE_PERSON_SUCCESS: {
      const { entities, deactivatedPeople } = state;
      return {
        ...state,
        entities: entities.filter(person => person.id !== action.personId),
        deactivatedPeople: deactivatedPeople.filter(person => person.id !== action.personId),
        reactivatePersonPending: false,
        reactivatePersonError: null,
      };
    }

    case PEOPLE_REACTIVATE_PERSON_FAILURE:
      return {
        ...state,
        reactivatePersonPending: false,
        reactivatePersonError: action.data.error,
      };

    case PEOPLE_REACTIVATE_PERSON_DISMISS_ERROR:
      return {
        ...state,
        reactivatePersonError: null,
      };

    default:
      return state;
  }
}
