import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
import { IconButton } from '@bridgit/foundation';
import { SORT_STORAGE_KEY, getStorageKey } from 'src/common/localStorageKeys';
import { setSortQuery } from '../queries/redux/actions';

function SortDirectionToggle({
  queries,
  trackSortChange,
  sortStorageKey,
  setSortQuery,
  queryId,
}) {
  const queryObj = queries[queryId];
  const sortQuery = queryObj && queryObj.sort ? queryObj.sort : {};
  const { args: sortArgs = [{}] } = sortQuery;
  const sortProps = sortArgs[0];
  const { ascending } = sortProps;
  const directionLabel = ascending ? 'Ascending' : 'Descending';

  const toggleSortDirection = () => {
    const updatedQuery = {
      name: 'sort',
      args: [
        {
          ...sortProps,
          ascending: !ascending,
        },
      ],
    };

    trackSortChange(sortProps.label);

    setSortQuery(queryId, updatedQuery, sortStorageKey);
  };

  const icon = ascending ? <ArrowUpward /> : <ArrowDownward />;

  return (
    <Tooltip className="filters-sort-direction-toggle" title={directionLabel} placement="bottom">
      <IconButton
        onClick={toggleSortDirection}
        ariaLabel="Toggle sort order"
      >
        {icon}
      </IconButton>
    </Tooltip>
  );
}

/* istanbul ignore next */
function mapStateToProps({ common, queries, login }) {
  const { activeView, accountId } = common;
  const { sub: userId } = login.userInfo;
  const sortStorageKey = getStorageKey(activeView, accountId, SORT_STORAGE_KEY, userId);

  return {
    sortStorageKey,
    queries,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    setSortQuery: bindActionCreators(setSortQuery, dispatch),
  };
}

SortDirectionToggle.propTypes = {
  trackSortChange: PropTypes.func,
  queries: PropTypes.object.isRequired,
  sortStorageKey: PropTypes.string.isRequired,
  setSortQuery: PropTypes.func.isRequired,
  queryId: PropTypes.string.isRequired,
};

SortDirectionToggle.defaultProps = {
  trackSortChange: () => {},
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SortDirectionToggle);
