import { PERM_READ, PERM_PROJECT } from 'src/features/permissions/utils/constants';
import { LoadableProject } from '.';

export default {
  path: 'accounts/:id/projects/:contentView?/:projectId?',
  name: 'Projects',
  component: LoadableProject,
  action: PERM_READ,
  subject: PERM_PROJECT,
};
