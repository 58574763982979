import moment from 'moment';
import { call, put, takeLatest } from 'redux-saga/effects';
import { SEGMENT_TYPE_PHASE } from 'src/features/edit-dates/common/constants';
import { naturalSort } from 'src/utils/sortUtils';
import {
  PROJECTS_ADD_PROJECT_PHASES_BEGIN,
  PROJECTS_ADD_PROJECT_PHASES_SUCCESS,
  PROJECTS_ADD_PROJECT_PHASES_FAILURE,
  PROJECTS_ADD_PROJECT_PHASES_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';

export function addProjectPhases(accountId, projectId, data, analyticsPayload = { }) {
  return {
    type: PROJECTS_ADD_PROJECT_PHASES_BEGIN,
    accountId,
    projectId,
    data,
    analyticsPayload,
  };
}

export function dismissAddProjectPhasesError() {
  return {
    type: PROJECTS_ADD_PROJECT_PHASES_DISMISS_ERROR,
  };
}

export function* doAddProjectPhases(action) {
  const { accountId, projectId, data, analyticsPayload } = action;

  try {
    yield call(protectedJsonPost, `accounts/${accountId}/projects/${projectId}/phases/bulk`, data);
  } catch (err) {
    yield put({
      type: PROJECTS_ADD_PROJECT_PHASES_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PROJECTS_ADD_PROJECT_PHASES_SUCCESS,
    data,
    projectId,
    analyticsPayload,
  });
}

export function* watchAddProjectPhases() {
  yield takeLatest(PROJECTS_ADD_PROJECT_PHASES_BEGIN, doAddProjectPhases);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PROJECTS_ADD_PROJECT_PHASES_BEGIN:
      return {
        ...state,
        addProjectPhasesPending: true,
        addProjectPhasesError: null,
      };

    case PROJECTS_ADD_PROJECT_PHASES_SUCCESS: {
      const { data, projectId } = action;
      const { filteredProjects, projectSelections } = state;
      const formattedData = data.map(phase => ({ ...phase, __typename: SEGMENT_TYPE_PHASE }));

      const activePhases = data.reduce((acc, phase) => {
        const { startDate, endDate } = phase;

        if (moment().isBetween(startDate, endDate, null, '[]')) {
          acc.push({
            ...phase,
            subPhases: phase.subPhases
              .filter(({ startDate, endDate }) => moment().isBetween(startDate, endDate, null, '[]'))
              .map(({ name }) => name),
          });
        }

        return acc;
      }, []);

      const newFilteredProjects = filteredProjects.map((project) => {
        const { id, phases, currentPhases } = project;

        if (id === projectId) {
          const newPhases = [
            ...phases,
            ...formattedData,
          ];

          const newCurrentPhases = [
            ...currentPhases,
            ...activePhases,
          ];

          return {
            ...project,
            phases: naturalSort(newPhases, ['startDate', 'endDate', 'name']),
            currentPhases: naturalSort(newCurrentPhases, ['startDate', 'endDate', 'name']),
          };
        }
        return project;
      });

      const newProjectSelections = { ...projectSelections };
      Object.keys(newProjectSelections).forEach((selectionId) => {
        const selectedProject = newProjectSelections[selectionId];
        if (selectedProject?.id === projectId) {
          const updatedPhases = [
            ...selectedProject.phases,
            ...formattedData,
          ];

          const newCurrentPhases = [
            ...selectedProject.currentPhases,
            ...activePhases,
          ];

          newProjectSelections[selectionId] = {
            ...selectedProject,
            phases: updatedPhases,
            currentPhases: naturalSort(newCurrentPhases, ['startDate', 'endDate', 'name']),
          };
        }
      });

      return {
        ...state,
        filteredProjects: newFilteredProjects,
        addProjectPhasesPending: false,
        addProjectPhasesError: null,
        projectSelections: newProjectSelections,
      };
    }

    case PROJECTS_ADD_PROJECT_PHASES_FAILURE:
      return {
        ...state,
        addProjectPhasesPending: false,
        addProjectPhasesError: action.data.error,
      };

    case PROJECTS_ADD_PROJECT_PHASES_DISMISS_ERROR:
      return {
        ...state,
        addProjectPhasesError: null,
      };

    default:
      return state;
  }
}
