import React from 'react';
import PropTypes from 'prop-types';
import { Clear } from '@material-ui/icons';
import { Chip } from '@material-ui/core';

export default function FilterMismatchWarning({
  onDelete,
}) {
  return (
    <Chip
      size="small"
      label="No titles match the project role name. Try filtering on a title to refine results."
      color="primary"
      onDelete={onDelete}
      className="allocations-filter-mismatch-warning"
      classes={{ deleteIcon: 'icon-delete', label: 'label' }}
      deleteIcon={<Clear />}
    />
  );
}

FilterMismatchWarning.propTypes = {
  onDelete: PropTypes.func.isRequired,
};
