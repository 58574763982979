import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SWITCH_TYPE } from '../../common/constants';
import { MODULE_NAME_TRANSLATION } from './common/constants';
import {
  getModules,
  addAccountModule,
  removeAccountModule,
} from './redux/actions';
import { CheckboxForm } from '../common';
import { hasModuleEnabled } from '../permissions/utils/permissionUtils';

const AccountModule = () => {
  const dispatch = useDispatch();

  const { accountId } = useSelector(({ common }) => common);
  const { modules, accountModules } = useSelector(({ accountSettings }) => accountSettings);

  useEffect(() => {
    dispatch(getModules());
  }, [dispatch]);

  const controls = useMemo(() => modules.map(mod => ({
    name: mod.name,
    label: MODULE_NAME_TRANSLATION[mod.name] || mod.name,
  })), [modules]);

  const checkedList = useMemo(() => {
    const tempList = {};
    modules.forEach((mod) => {
      tempList[mod.name] = hasModuleEnabled(accountModules, mod.name);
    });
    return tempList;
  }, [accountModules, modules]);

  const onChange = (name, checked) => {
    const relativeModule = modules.find(mod => mod.name === name);
    if (!relativeModule) return;

    const { id, components } = relativeModule;
    if (checked) {
      const componentObj = {};

      // Add component call expects components in object with bool values
      components.forEach((comp) => {
        componentObj[comp] = true;
      });

      dispatch(addAccountModule(accountId, { id, components: componentObj }, name));
    } else {
      dispatch(removeAccountModule(accountId, id, name));
    }
  };

  return (
    <div className="account-settings-account-module">
      <CheckboxForm
        title="Account modules"
        controls={controls}
        onChange={onChange}
        checkedList={checkedList}
        type={SWITCH_TYPE}
      />
    </div>
  );
};

export default AccountModule;
