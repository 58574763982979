import React from 'react';
import moment from 'moment';
import { ProjectLink } from '@bridgit/foundation';
import { REQUEST_DESCRIPTOR } from 'src/common/descriptors/request';
import { ASSIGNMENT_DESCRIPTOR } from 'src/common/descriptors/assignment';
import { LoadableMomentDate } from 'src/features/common';
import {
  DAY_MAP,
  WEEK_LENGTH,
} from 'src/common/constants';
import { SKILL_SET_FIELD } from 'src/features/self-perform/constants';
import { getFormattedDateAndTime } from 'src/utils/dateUtils';

/*
  Format the sorted working days integer array into a string representing
  the days of the week. More than 2 consecutive days will be grouped together
  and display like M-F, only 2 consecutive days will not be grouped. Ungrouped
  days will be joined with a comma

  example input => output
  [4, 5] => 'Th,F'
  [0, 1, 2] => 'Su-T'
  [0, 2, 4, 6] => 'Su,T,Th,S'
  [0, 1, 2, 4, 5, 6] => 'Su-T,Th-S'
*/
export const formatWorkingDays = (workingDays) => {
  if (!workingDays || !workingDays.length) return '';

  const consecutiveDays = [];
  let chunkCount = -1;
  let lastDay = -1;

  // create a 2D array with consecutive days grouped together
  workingDays.forEach((dayString) => {
    const day = Number(dayString);
    const currentChunk = consecutiveDays[chunkCount];

    // is either the first item in the workingDays array or
    // is not consecutive to the previous item in the array
    if (!currentChunk || day !== lastDay + 1) {
      // create a new consecutive day array
      consecutiveDays.push([DAY_MAP[day]]);
      chunkCount += 1;
    } else {
      // the current item in the array is the next day from the
      // previous item so add it to the current array
      consecutiveDays[chunkCount].push(DAY_MAP[day]);
    }
    lastDay = day;
  });

  const daysString = consecutiveDays.map((daysChunk) => {
    if (daysChunk.length === 1) {
      return daysChunk[0];
    }

    if (daysChunk.length === 2) {
      return `${daysChunk[0]}, ${daysChunk[1]}`;
    }

    return `${daysChunk[0]}-${daysChunk[daysChunk.length - 1]}`;
  }).join(', ');

  return daysString;
};

const generateColumnsFor = (table, columnOptions, desc) => {
  // Generate columns from descriptor
  const columns = desc.reduce((visibleColumns, current) => {
    if (current.visible) {
      const additionalOptions = current.schemaName in columnOptions ? columnOptions[current.schemaName] : {};
      visibleColumns.push({
        name: current.displayName,
        schemaName: current.schemaName,
        type: current.type,
        filterType: current.filterType,
        options: {
          sort: current.sort,
          filter: current.filter,
          ...(current.filterOptions && { filterOptions: current.filterOptions }),
          ...additionalOptions,
        },
      });
    }

    return visibleColumns;
  }, []);

  const { visibleColumnNames, alwaysVisible } = table;

  // Validate visible columns only includes columns that exist
  const newVisibleColumns = columns.filter(column => visibleColumnNames.includes(column.name) || alwaysVisible.includes(column.name));
  const newVisibleColumnNames = newVisibleColumns.map(column => column.name);

  return { newVisibleColumns, newVisibleColumnNames };
};

export const renderCommunicatedStatus = value => (value ? (
  <span>
    {`Sent ${getFormattedDateAndTime(value)}`}
  </span>
) : 'Not communicated');

export const generateRequestColumns = (table, isAssignmentTable = false) => {
  const requestTableOptions = {
    requestedOn: { customBodyRender: value => <LoadableMomentDate date={value} /> },
  };

  const assignmentTableOptions = {
    lastNotifiedOn: {
      customBodyRender: renderCommunicatedStatus,
    },
  };

  // Custom options for columns
  const columnOptions = {
    requiredSkills: {
      customBodyRender: (skills) => {
        if (!skills) return null;
        return <span>{skills.join(', ')}</span>;
      },
    },
    startDate: { customBodyRender: value => <LoadableMomentDate date={value} /> },
    endDate: { customBodyRender: value => <LoadableMomentDate date={value} /> },
    projectName: { customBodyRender: value => <ProjectLink projectName={value.name} projectId={value.id} backgroundColor={value.colour} /> },
    ...(isAssignmentTable ? assignmentTableOptions : requestTableOptions),
  };

  return generateColumnsFor(table, columnOptions, isAssignmentTable ? ASSIGNMENT_DESCRIPTOR : REQUEST_DESCRIPTOR);
};

export const getRequestRowData = (filteredRequests, visibleColumns, isAssignmentTable = false) => filteredRequests.map((request) => {
  const {
    id,
    description,
    project,
    requiredSkills,
    startDate,
    endDate,
    requester,
    requestedOn,
    state,
    assignee,
    lastNotifiedOn,
    status,
  } = request;

  const requestObj = {
    Requester: requester?.name || '',
    'Date Requested': requestedOn,
    Status: state,
  };

  const assignmentObj = {
    Assignee: assignee?.name || '',
    'Communication Status': lastNotifiedOn,
    Status: status,
  };

  const dataObj = {
    Description: description,
    Project: project,
    [SKILL_SET_FIELD]: requiredSkills,
    'Start Date': startDate,
    'End Date': endDate,
    ...(isAssignmentTable ? assignmentObj : requestObj),
  };

  const rowData = !visibleColumns ? [] : visibleColumns.map(({ name }) => dataObj[name] ?? '');

  return {
    rowMeta: {
      id,
      rawData: request,
    },
    rowData,
    rowId: id,
  };
});

export const filterBestMatches = (sortedPeople, bestMatchesData) => {
  const otherMatches = [];
  const bestMatches = [];

  sortedPeople.forEach((person) => {
    const matchData = bestMatchesData.find(match => match.personId === person.id);

    if (matchData) {
      const { skillsMatchedPercent } = matchData;
      const personData = { ...person, skillsMatchedPercent };

      if (skillsMatchedPercent === 100) {
        bestMatches.push(personData);
      } else {
        otherMatches.push(personData);
      }
    }
  });

  return {
    otherMatches,
    bestMatches,
  };
};

export const getSelectedWorkDayValues = workDays => workDays.reduce((selected, { checked, value }) => {
  if (checked) selected.push(value.toString());

  return selected;
}, []);

const getSelectedRangeDays = (startDate, endDate, diff) => {
  const days = [];
  const startClone = startDate.clone();

  for (let i = 0; i <= diff; i += 1) {
    days.push((startClone.day().toString()));
    startClone.add(1, 'day');
  }

  return days;
};

const getSelectedCheckboxDays = selectedWorkDays => (selectedWorkDays[0]?.label ? getSelectedWorkDayValues(selectedWorkDays) : selectedWorkDays);

export const isNoWorkDaysWithinRange = (selectedWorkDays, start, end) => {
  const startDate = moment(start).startOf('day');
  const endDate = moment(end).startOf('day');
  const diff = endDate.diff(startDate, 'days');

  if (diff >= WEEK_LENGTH) return false;

  const selectedWorkingDays = getSelectedCheckboxDays(selectedWorkDays);

  const daysInSelectedRange = getSelectedRangeDays(startDate, endDate, diff); // these days are between start and end date

  return selectedWorkingDays.every(day => !daysInSelectedRange.includes(day));
};

export const isDateInSelectedWorkingDays = (date, selectedWorkDays) => {
  const day = date.day();

  return selectedWorkDays[day].checked;
};

export const isSomeWorkingDaysOutsideOfRange = (selectedWorkDays, start, end) => {
  const startDate = moment(start).startOf('day');
  const endDate = moment(end).startOf('day');
  const diff = endDate.diff(startDate, 'days');

  if (diff >= WEEK_LENGTH) return false;

  const selectedWorkingDays = getSelectedCheckboxDays(selectedWorkDays); // these are checkboxes

  const daysInSelectedRange = getSelectedRangeDays(startDate, endDate, diff); // these days are between start and end date

  return selectedWorkingDays.some(day => !daysInSelectedRange.includes(day));
};
