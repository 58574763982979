import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { Divider, InputAdornment } from '@material-ui/core';
import { OutlinedInput } from '@bridgit/foundation';
import { AttachMoney } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { sortAlphabetically } from 'src/utils/sortUtils';
import { useDispatch } from 'react-redux';
import { setTitleCosts } from '../accounts/redux/setTitleCosts';
import { validateAPICurrencyString } from '../../utils/validators';
import { MAX_TITLE_COST } from './common/constants';

const DefaultCostRateMenu = ({ titleCosts, accountId }) => {
  const dispatch = useDispatch();
  const [focusedCostRate, setFocusedCostRate] = useState(null);
  const [valueChanged, setValueChanged] = useState(false);
  const [hasErrorState, setHasErrorState] = useState(false);
  const inputsRef = useRef([]);

  useEffect(() => {
    inputsRef.current = inputsRef.current.slice(0, titleCosts.length);
  }, [titleCosts]);

  const onInputFocus = useCallback(id => setFocusedCostRate(id), []);

  const sortedTitleCosts = useMemo(() => sortAlphabetically(titleCosts, true, 'name'), [titleCosts]);
  const onChange = useCallback((updated, original) => {
    if (updated === '' || updated == null) {
      setValueChanged(updated !== original);
      return;
    }
    if (validateAPICurrencyString(updated)) {
      const parsedNumber = Number.parseFloat(updated);
      setHasErrorState(parsedNumber < 0 || parsedNumber > MAX_TITLE_COST);
      setValueChanged(updated !== original);
    } else {
      setHasErrorState(true);
    }
  }, []);

  const onEnter = useCallback((event, id, title, index) => {
    const nextInputRef = inputsRef?.current?.[index + 1];
    if (nextInputRef && !hasErrorState) {
      nextInputRef.focus();
    } else {
      inputsRef.current?.[index]?.blur();
    }
    if (hasErrorState || !valueChanged) {
      return;
    }
    dispatch(setTitleCosts(accountId, id, Number.parseFloat(event?.target?.value), { title }));
    setValueChanged(false);
  }, [accountId, dispatch, hasErrorState, valueChanged]);

  return (
    <div className="account-settings-default-cost-rate-menu">
      <p className="title">Cost rate defaults</p>
      <p className="description">People will inherit these values based on their titles. This field can still be updated individually on a person’s profile. The cost rate will be used to calculate the Bench cost for a person.</p>
      <div className="header-labels">
        <span>Title</span>
        <span>Value</span>
      </div>
      <Divider />
      {sortedTitleCosts?.map(({ id, name, hourlyCost }, index) => (
        <div key={id}>
          <div className="cost-rate-default">
            <span className="title">{name}</span>
            <OutlinedInput
              className="default-value-input"
              showEnter={focusedCostRate === id && valueChanged && !hasErrorState}
              defaultValue={hourlyCost}
              onEnter={event => onEnter(event, id, name, index)}
              onChange={({ target: { value } }) => onChange(value, hourlyCost)}
              materialProps={({
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoney className="dollar-icon" />
                  </InputAdornment>
                ),
                onFocus: () => onInputFocus(id),
                error: focusedCostRate === id && hasErrorState,
                inputRef: (element) => {
                  inputsRef.current[index] = element;
                },
              })}
            />
          </div>
          <Divider />
        </div>
      ))}
    </div>
  );
};

DefaultCostRateMenu.propTypes = {
  accountId: PropTypes.number.isRequired,
  titleCosts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    hourlyCost: PropTypes.number,
  })),
};

DefaultCostRateMenu.defaultProps = {
  titleCosts: [],
};

export default DefaultCostRateMenu;
