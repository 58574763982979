import React from 'react';

export default function NoFilterMessage() {
  return (
    <div className="allocations-no-filter-message">
      <span>No filters applied.</span>
      <span>Try resetting or adding filters to refine results.</span>
    </div>
  );
}
