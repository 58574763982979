export const QUERIES_SET_FILTER_QUERY = 'QUERIES_SET_FILTER_QUERY';
export const QUERIES_SET_SORT_QUERY = 'QUERIES_SET_SORT_QUERY';
export const QUERIES_SET_SEARCH_QUERY = 'QUERIES_SET_SEARCH_QUERY';
export const QUERIES_CLEAR_QUERIES = 'QUERIES_CLEAR_QUERIES';

export const FILTERED_PROJECTS_QUERY_ID = 'filteredProjects';
export const FILTERED_PEOPLE_QUERY_ID = 'filteredPeople';
export const DEACTIVATED_PEOPLE_QUERY_ID = 'deactivatedPeople';
export const ACCOUNTS_QUERY_ID = 'accounts';
export const DASHBOARD_UNFILLED_ROLES_QUERY_ID = 'dashboardUnfilledRoles';
export const ROLE_AVAILABILITIES_QUERY_ID = 'roleAvailabilities';
export const INVITED_USERS_QUERY_ID = 'invitedUsers';
export const ACTIVE_USERS_QUERY_ID = 'activeUsers';
export const FILTERED_PROJECT_FORECAST_QUERY_ID = 'filteredProjectForecast';
export const FILTERED_PEOPLE_FORECAST_QUERY_ID = 'filteredPeopleForecast';
export const FILTERED_PROJECT_DASHBOARD_QUERY_ID = 'filteredProjectDashboard';
export const FILTERED_HOURLY_PERSONS_QUERY_ID = 'filteredHourlyPersons';
export const FILTERED_REQUESTS_QUERY_ID = 'filteredRequests';
export const FILTERED_ASSIGNMENTS_QUERY_ID = 'filteredAssignments';
export const FILTERED_MATCHES_QUERY_ID = 'bestMatches';
export const FILTERED_UNFILLED_ROLES_QUERY_ID = 'filteredUnfilledRoles';
export const QUERIES_GET_SAVED_FILTERS_BEGIN = 'QUERIES_GET_SAVED_FILTERS_BEGIN';
export const QUERIES_GET_SAVED_FILTERS_SUCCESS = 'QUERIES_GET_SAVED_FILTERS_SUCCESS';
export const QUERIES_GET_SAVED_FILTERS_FAILURE = 'QUERIES_GET_SAVED_FILTERS_FAILURE';
export const QUERIES_GET_SAVED_FILTERS_DISMISS_ERROR = 'QUERIES_GET_SAVED_FILTERS_DISMISS_ERROR';
export const QUERIES_ADD_SAVED_FILTER_BEGIN = 'QUERIES_ADD_SAVED_FILTER_BEGIN';
export const QUERIES_ADD_SAVED_FILTER_SUCCESS = 'QUERIES_ADD_SAVED_FILTER_SUCCESS';
export const QUERIES_ADD_SAVED_FILTER_FAILURE = 'QUERIES_ADD_SAVED_FILTER_FAILURE';
export const QUERIES_ADD_SAVED_FILTER_DISMISS_ERROR = 'QUERIES_ADD_SAVED_FILTER_DISMISS_ERROR';
export const QUERIES_DELETE_SAVED_FILTER_BEGIN = 'QUERIES_DELETE_SAVED_FILTER_BEGIN';
export const QUERIES_DELETE_SAVED_FILTER_SUCCESS = 'QUERIES_DELETE_SAVED_FILTER_SUCCESS';
export const QUERIES_DELETE_SAVED_FILTER_FAILURE = 'QUERIES_DELETE_SAVED_FILTER_FAILURE';
export const QUERIES_DELETE_SAVED_FILTER_DISMISS_ERROR = 'QUERIES_DELETE_SAVED_FILTER_DISMISS_ERROR';
