import {
  PROJECTS_CLEAR_ALL_PROJECT_SELECTIONS,
} from './constants';
import initialState from './initialState';

export function clearAllProjectSelections() {
  return {
    type: PROJECTS_CLEAR_ALL_PROJECT_SELECTIONS,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case PROJECTS_CLEAR_ALL_PROJECT_SELECTIONS: {
      const { projectSelections } = initialState;
      return {
        ...state,
        projectSelections,
      };
    }

    default:
      return state;
  }
}
