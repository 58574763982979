import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import deepEqual from 'react-fast-compare';
import classnames from 'classnames';
import { IconButton } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import { PEOPLE_VIEW } from 'src/common/constants';
import { FILTER_STORAGE_KEY, getStorageKey } from 'src/common/localStorageKeys';
import { FilterPopper } from '../common';
import { getTitles } from './utils/peopleGanttUtils';
import { getPeopleFilterSummary } from '../filters/utils/filterUtils';
import { setFilterQuery } from '../queries/redux/actions';
import { trackAnalytics } from '../common/redux/actions';
import { FILTERED_PEOPLE_QUERY_ID } from '../queries/redux/constants';
import { PEOPLE_GANTT_CONFIG_KEY } from './redux/constants';
import { FILTER_ON_PEOPLE } from '../../analytics/filters/constants';

export class PeopleGanttTitleHeader extends Component {
  static propTypes = {
    accountId: PropTypes.number.isRequired,
    userId: PropTypes.string.isRequired,
    queries: PropTypes.object,
    accountSettings: PropTypes.object.isRequired,
    displayOptions: PropTypes.object.isRequired,
    setFilterQuery: PropTypes.func.isRequired,
    trackAnalytics: PropTypes.func.isRequired,
    isReport: PropTypes.bool,
  };

  static defaultProps = {
    queries: {},
    isReport: false,
  };

  constructor(props) {
    super(props);
    const { queries, accountSettings } = props;
    const filters = queries.filter;
    const filterArgs = filters && filters.args ? filters.args : [];
    const titleFilters = filterArgs && filterArgs.length ? filterArgs.find(filter => filter.column === 'title') : null;
    const filteredTitleValues = titleFilters ? titleFilters.activeFilters.map(filter => filter.name) : [];
    const titles = getTitles(accountSettings.personFields);
    this.state = {
      filteredTitleValues,
      filterOpen: false,
      accountSettings,
      queries,
      titles,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { queries, accountSettings } = nextProps;
    const queriesChanged = !deepEqual(prevState.queries, queries);
    const fieldsChanged = !deepEqual(prevState.accountSettings.personFields, accountSettings.personFields);
    const stateChanges = { queries, accountSettings };

    if (queriesChanged) {
      const filters = queries.filter;
      const filterArgs = filters && filters.args ? filters.args : [];
      const titleFilters = filterArgs && filterArgs.length ? filterArgs.find(filter => filter.column === 'title') : null;
      const filteredTitleValues = titleFilters ? titleFilters.activeFilters.map(filter => filter.name) : [];
      stateChanges.filteredTitleValues = filteredTitleValues;
    }

    if (fieldsChanged) {
      stateChanges.titles = getTitles(accountSettings.personFields);
    }

    return stateChanges;
  }

  openFilter = () => this.setState({ filterOpen: true });

  closeFilter = () => this.setState({ filterOpen: false });

  filterIcon = (el) => {
    this.filterRef = el;
  }

  applyTitleFilter = (activeFilters) => {
    const { queries, setFilterQuery, accountId, userId, trackAnalytics } = this.props;
    const filterStorageKey = getStorageKey(PEOPLE_VIEW, accountId, FILTER_STORAGE_KEY, userId);
    const filteredTitleValues = activeFilters.map(title => title.name);
    const filterQuery = queries.filter;
    const newArgs = filterQuery && filterQuery.args && filterQuery.args.length
      ? filterQuery.args.filter(arg => arg.column !== 'title')
      : [];
    const newQuery = {
      name: 'filter',
      args: newArgs,
    };

    if (activeFilters.length) {
      newQuery.args.push({
        activeFilters,
        column: 'title',
        label: 'Title',
      });
    }

    setFilterQuery(FILTERED_PEOPLE_QUERY_ID, newQuery, filterStorageKey);

    trackAnalytics(FILTER_ON_PEOPLE, {
      'View filtered on': 'People Gantt',
      'Property filtered by': 'Title',
      'Filter type used': 'Gantt quick option',
      ...getPeopleFilterSummary(newQuery.args),
    });

    this.setState({
      filteredTitleValues,
      filterOpen: false,
    });
  }

  render() {
    const { displayOptions, isReport } = this.props;
    const { filteredTitleValues, filterOpen, titles } = this.state;
    return (
      <span className="gantt-people-gantt-title-header" ref={this.filterIcon}>
        {displayOptions.photo && (
          <div className="photo-header">Photo</div>
        )}

        <div className={classnames('name-header', { 'no-photo': !displayOptions.photo })}>
          <div className="name-header-text">{displayOptions.title ? 'Name and Title' : 'Name'}</div>
          {!isReport && (
            <>
              <IconButton
                className="filter-icon"
                disableRipple
                variant="contained"
                color={filteredTitleValues.length ? 'primary' : 'default'}
                onClick={this.openFilter}
              >
                <FilterList className="icon-edit" aria-label="Required" />
              </IconButton>
              <FilterPopper
                anchorEl={this.filterRef}
                open={filterOpen}
                title="Title"
                items={titles}
                selected={filteredTitleValues}
                filterKey="name"
                onApply={this.applyTitleFilter}
                onCancel={this.closeFilter}
                showSearch
                placeholder="Search Title"
              />
            </>
          )}
        </div>
      </span>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps({ common, queries, accountSettings, login, gantt }) {
  const { accountId } = common;
  const { filteredPeople: filteredPeopleQueries } = queries;
  const { displayOptions } = gantt.instances[PEOPLE_GANTT_CONFIG_KEY];
  const { sub: userId } = login.userInfo;

  return {
    accountId,
    userId,
    queries: filteredPeopleQueries,
    accountSettings,
    displayOptions,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    setFilterQuery: bindActionCreators(setFilterQuery, dispatch),
    trackAnalytics: bindActionCreators(trackAnalytics, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PeopleGanttTitleHeader);
