import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { formatAvailabilityText } from 'src/features/people/utils/peopleUtils';
import { NO_AVAILABILITY } from 'src/filters/constants';

export const PersonAvailability = ({
  className,
  availableInDays,
}) => (
  <div
    className={classNames('people-person-availability', className, {
      soon: availableInDays < 31 && availableInDays !== NO_AVAILABILITY,
    })}
  >
    {formatAvailabilityText(availableInDays)}
  </div>
);

PersonAvailability.propTypes = {
  className: PropTypes.string,
  availableInDays: PropTypes.number.isRequired,
};

PersonAvailability.defaultProps = {
  className: '',
};

export default PersonAvailability;
