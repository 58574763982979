import axios from 'axios';
import { DEFAULT_BACKOFF_TIME } from 'src/common/constants';
import { logRollbar } from 'src/common/analyticsHelper';
import { MAX_PROJECTS } from 'src/features/projects/redux/constants';
import config from '../common/envConfig';
import { toUrlEncoded } from './miscUtils';
import { getAuthHeader } from './cookieUtils';

const baseApi = `https://${config.apiDomain}/rp/api/v1/`;
const baseAuth = `https://${config.authDomain}`;

const isAuthError = err => err?.response?.status === 401;

const handleRequestError = (err, customMessage = 'API request error') => {
  const { response } = err;

  if (isAuthError(err)) {
    throw response;
  } else {
    logRollbar(customMessage, err.response || err);
    throw response || err;
  }
};

export function login(credentials) {
  return axios({
    method: 'post',
    url: `${baseAuth}/signin`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: '*/*',
    },
    // data: multipart ? params : JSON.stringify(body),
    data: toUrlEncoded({
      username: credentials.email,
      password: credentials.password,
    }),
  })
    .then(response => response.data)
    .catch((err) => {
      logRollbar('Login API request error', err.response || err);
      throw err.response || err;
    });
}

export function logout() {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: '*/*',
  };

  headers.Authorization = getAuthHeader();

  return axios({
    method: 'post',
    url: `${baseAuth}/signout`,
    headers,
  })
    .then(response => response.data)
    .catch((err) => {
      logRollbar('Logout API request error', err.response || err);
      throw err.response || err;
    });
}

export function uploadImage(presignedUrl, file) {
  return axios({
    method: 'put',
    url: presignedUrl,
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
    },
    data: file,
  })
    .then(response => response.data)
    .catch((err) => {
      handleRequestError(err, 'Upload image API request error');
    });
}

export function uploadLogo(path, file) {
  const headers = {
    'Content-Type': 'multipart/form-data',
    Accept: '*/*',
  };

  headers.Authorization = getAuthHeader();

  const data = new FormData();
  data.append('file', file);

  return axios({
    method: 'put',
    url: `${baseApi}${path}`,
    headers,
    data,
  })
    .then(response => response.data)
    .catch((err) => {
      handleRequestError(err, 'Upload logo API request error');
    });
}

export async function uploadFile(url, file, errorMessage) {
  try {
    const config = { headers: { 'Content-Type': 'application/octet-stream' } };
    await axios.put(url, file, config);
  } catch (err) {
    handleRequestError(err, errorMessage);
  }
}

export async function downloadFile(url) {
  try {
    const config = { headers: { Authorization: getAuthHeader() } };

    // Get pre-signed URL
    const res = await axios.get(url, config);

    // Make sure we received a binaryUrl
    if (!res?.data?.binaryUrl) throw new Error('Invalid pre-signed URL');

    // Force browser download
    window.location.href = res.data.binaryUrl;
  } catch (err) {
    handleRequestError(err, 'File download error');
  }
}

export function getBaseRequest(path) {
  const { baseRequestOverride, apiDomain } = config;
  const domain = baseRequestOverride || apiDomain;
  return axios({
    method: 'get',
    url: `https://${domain}/${path}`,
  })
    .then(response => response.data)
    .catch((err) => {
      handleRequestError(err);
    });
}

export function getQueryString(params) {
  const query = Object.keys(params)
    .filter(k => params[k] !== undefined && params[k] !== null)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');
  return query.length ? `?${query}` : '';
}

/*
  Given a callback function, allow retry attempts to be made
  repeatedly while increasing the attempt interval.
 */
export function backoffRetry(callback) {
  let backoffTimeout = null;
  let backoffTime = DEFAULT_BACKOFF_TIME;

  const retry = () => {
    if (backoffTimeout) {
      clearTimeout(backoffTimeout);
    }

    backoffTimeout = setTimeout(() => {
      backoffTime *= 2;

      callback();
    }, backoffTime);
  };

  const resetTries = () => {
    backoffTime = DEFAULT_BACKOFF_TIME;
    clearTimeout(backoffTimeout);
  };

  const stopTrying = () => {
    clearTimeout(backoffTimeout);
  };

  return {
    retry,
    resetTries,
    stopTrying,
  };
}


export function doApiRequest(method, path, data = {}, includeHeaders = false) {
  const body = method !== 'get' ? data : undefined;
  const query = !body && data ? getQueryString(data) : undefined;
  const headers = { 'Content-Type': 'application/json' };

  headers.Authorization = getAuthHeader();

  return axios({
    method,
    headers,
    url: `${baseApi}${path}${query || ''}`,
    data: body,
  })
    .then(response => (includeHeaders ? { data: response.data, headers: response.headers } : response.data))
    .catch((err) => {
      handleRequestError(err);
    });
}

export function protectedJsonGet(path, params, includeHeaders = false) {
  const { searchParams, pathname } = new URL(path, 'https://dummyurl.com');

  const newParams = {
    limit: MAX_PROJECTS,
    ...params,
  };

  const entries = searchParams.entries();

  Array.from(entries).forEach(([key, value]) => { newParams[key] = value; });

  return doApiRequest('get', pathname.slice(1), newParams, includeHeaders);
}

export function protectedJsonPut(path, body) {
  return doApiRequest('put', path, body);
}

export function protectedJsonPost(path, body) {
  return doApiRequest('post', path, body);
}

export function protectedJsonPatch(path, body) {
  return doApiRequest('patch', path, body);
}

export function protectedJsonDelete(path, body) {
  return doApiRequest('delete', path, body);
}
