import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IconButton, Tooltip } from '@material-ui/core';
import { VisibilityOutlined, VisibilityOff } from '@material-ui/icons';

function PrivateToggle({
  onClick,
  disabled,
  isPrivate,
  type,
  tooltipMessage,
}) {
  const message = isPrivate ? 'Click to make field non-private' : 'Click to make field private';
  const tooltip = tooltipMessage || message;
  const classes = classNames('icon--private', { 'icon-disabled': disabled });
  const icon = isPrivate
    ? <VisibilityOff aria-label="Private" className={classes} />
    : <VisibilityOutlined aria-label="Private" className={classes} />;
  const button = (
    <IconButton
      className={classNames('account-settings-private-toggle', { 'button-disabled': disabled, dotted: !isPrivate })}
      onClick={onClick}
      disableRipple
      variant="contained"
    >
      {icon}
    </IconButton>
  );

  return type === 'view' ? button : <Tooltip title={tooltip} placement="top">{button}</Tooltip>;
}

PrivateToggle.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isPrivate: PropTypes.bool,
  type: PropTypes.string,
  tooltipMessage: PropTypes.string,
};

PrivateToggle.defaultProps = {
  onClick: () => {},
  disabled: false,
  isPrivate: true,
  type: 'view',
  tooltipMessage: null,
};

export default PrivateToggle;
