import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { ClickAwayListener } from '@material-ui/core';
import { Button } from '@bridgit/foundation';

import { FilterList } from '../filters';
import { CustomPopper } from '../wrapped-components';
import { trackAnalytics } from '../common/redux/actions';
import {
  PROJECT_SINGLE_ROLE_BUTTON_CLICKED,
  PROJECT_SINGLE_ROLE_SELECTED,
} from '../../analytics/projects/constants';

export const AddRoleSelector = ({
  onSelectRole,
  project,
}) => {
  const dispatch = useDispatch();

  const { roles = [] } = useSelector(({ accountSettings }) => accountSettings);

  const [open, setOpen] = useState(false);

  const buttonRef = useRef(null);

  const toggleOpen = () => {
    if (!open) {
      const analyticsPayload = {
        'Project Name': project.name,
        'Project ID': project.id,
      };
      dispatch(trackAnalytics(PROJECT_SINGLE_ROLE_BUTTON_CLICKED, analyticsPayload));
    }
    setOpen(!open);
  };

  const onSelect = (role) => {
    setOpen(false);
    onSelectRole([role]);

    const analyticsPayload = {
      'Project Name': project.name,
      'Project ID': project.id,
      'Role Name': role.name,
    };
    dispatch(trackAnalytics(PROJECT_SINGLE_ROLE_SELECTED, analyticsPayload));
  };

  return (
    <div className="projects-add-role-selector">
      <Button
        ref={buttonRef}
        onClick={toggleOpen}
        variant="contained"
        color="primary"
      >
        Add
      </Button>
      {open && (
        <CustomPopper
          popperOpen={open}
          anchorEl={buttonRef.current}
          classes="add-role-selector-popper"
          placement="bottom-start"
          hideArrow
          preventBubbling
        >
          <ClickAwayListener onClickAway={toggleOpen}>
            <div>
              <FilterList
                columns={roles}
                onFilterSelect={onSelect}
                alphabetize={false}
                placeholder="Search roles"
              />
            </div>
          </ClickAwayListener>
        </CustomPopper>
      )}
    </div>
  );
};

AddRoleSelector.propTypes = {
  onSelectRole: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
};

export default AddRoleSelector;
