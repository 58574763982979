import { gql } from 'apollo-boost';
import moment from 'moment';
import { generateRequestFilter, generateRequestSort, pickObjectProps } from 'src/filters/graphqlUtils';
import { dateStringSubtract, dateStringAdd } from 'src/utils/dateUtils';
import { PEOPLE_SCHEMAS } from 'src/common/descriptors/person';
import {
  PAGE_SIZE,
  DATE_INPUT_FORMAT,
  MIN_DATA_RANGE_IN_MONTHS,
  MAX_DATA_RANGE_IN_YEARS,
} from 'src/common/constants';

const PEOPLE_LIST_QUERY = gql`
    query peopleListQuery($accountId: Int!, $structuredSort: [PersonSort], $from: Int, $size: Int, $filters: [ESQuery], $state: PersonState, $relativeDate: String, $startDate: String, $endDate: String){
      people(accountId: $accountId, structuredSort: $structuredSort, from: $from, size: $size, filters: $filters, state: $state, relativeDate: $relativeDate, startDate: $startDate, endDate: $endDate) {
        hasMore
        cursor
        count
        items {
          id
          name
          title
          email
          photoUrl
          hasConflict
          isActive
          hourlyCostRate
          availableInDays
          availableFor
          percentAvailableToday
          employmentDates {
            startDate
            endDate
          }
          unavailabilities {
            id
            description
            startDate
            endDate
            isPrivate
            rangeType
          }
          projects {
            name
          }
          pursuitProjects {
            projectName
          }
          projectAllocations {
            projectId
            projectName
            projectColour
            projectStart
            projectEnd
            projectState
            roleId
            roleName
            roleNote
            startDate
            endDate
            allocatedPercent
          }
          currentProjects {
            name
          }
          currentAllocations {
            endDate
          }
          nextAllocations {
            projectName
            startDate
          }
          fields {
            dataType
            name
            values
            isPrivate
          }
          allocations {
            startDate
            endDate
            percent
          }
          availabilities {
            startDate
            endDate
            percent
          }
          certifications {
            id
            name
            abbreviation
            expiryDate
            daysWarnBeforeExpire
            hasAttachment
          }
        }
      }
    }
  `;

const getNewPeopleListEntity = (personData, fieldData = []) => {
  const personProps = [
    'id',
    'name',
    'title',
    'email',
    'photoUrl',
    'hasConflict',
    'employmentDates',
    'availabilities',
    'unavailabilities',
    'availableInDays',
    'availableFor',
    'percentAvailableToday',
  ];

  const fieldProps = [
    'type',
    'name',
    'values',
    'isPrivate',
  ];

  const person = pickObjectProps(personData, personProps);
  const fields = fieldData.map(field => ({
    ...pickObjectProps(field, fieldProps),
    __typename: 'Field',
  }));

  const projects = [];
  const projectAllocations = [];
  const currentProjects = [];

  const defaultProps = {
    isActive: true,
  };

  return {
    ...person,
    ...defaultProps,
    fields,
    projects,
    projectAllocations,
    currentProjects,
    __typename: 'Person',
  };
};

const getPeopleListQuery = (accountId, queries = {}, pageFrom, pageSize, state = 'Active', start, end) => {
  const startDate = start || dateStringSubtract(moment(), MIN_DATA_RANGE_IN_MONTHS, 'months');
  const endDate = end || dateStringAdd(moment(), MAX_DATA_RANGE_IN_YEARS, 'years');
  const defaults = {
    size: pageSize || PAGE_SIZE,
    state,
    relativeDate: moment().local().format(DATE_INPUT_FORMAT),
    startDate,
    endDate,
  };

  const defaultSort = [{
    name: { order: 'asc' },
  }];

  const { filter, sort, search } = queries;
  const searchFilter = generateRequestFilter(search, PEOPLE_SCHEMAS);
  const filters = generateRequestFilter(filter, PEOPLE_SCHEMAS).concat(searchFilter);
  const structuredSort = generateRequestSort(sort, PEOPLE_SCHEMAS, defaultSort);
  const variables = { accountId, filters, structuredSort, from: pageFrom, ...defaults };

  return {
    query: PEOPLE_LIST_QUERY,
    variables,
  };
};

export {
  getNewPeopleListEntity,
  getPeopleListQuery,
};
