import moment from 'moment';
import { GANTT_COLUMN_WIDTH, GANTT_MINIMUM_COLUMNS } from '../redux/constants';
import {
  MIN_DATA_RANGE_IN_MONTHS,
  MAX_DATA_RANGE_IN_YEARS,
} from '../../../common/constants';
import { momentToString } from '../../../utils/dateUtils';

export const PeopleZoomConfig = {
  1: {
    key: '1-week',
    label: '1 Week',
    zoom: 1,
    format: 'll',
    unit: 'weeks',
    dragUnit: 'days',
    snapToStart: false,
  },
  2: {
    key: '1-month',
    label: '1 Month',
    zoom: 1,
    format: 'MMM YYYY',
    unit: 'months',
    dragUnit: 'days',
    snapToStart: false,
  },
  3: {
    key: '3-months',
    label: '3 Months',
    zoom: 3,
    format: 'MMM YYYY',
    unit: 'months',
    dragUnit: 'weeks',
    snapToStart: false,
  },
  4: {
    key: '6-months',
    label: '6 Months',
    zoom: 6,
    format: 'MMM YYYY',
    unit: 'months',
    dragUnit: 'months',
    snapToStart: true,
  },
};

export const GanttReportZoomConfigLetter = [
  {
    key: '3-months',
    label: '3 Months',
    zoom: 14,
    format: 'MMM DD YYYY',
    unit: 'days',
  },
  {
    key: '6-months',
    label: '6 Months',
    zoom: 1,
    format: 'MMM DD YYYY',
    unit: 'months',
  },
  {
    key: '1-year',
    label: '1 Year',
    zoom: 2,
    format: 'MMM DD YYYY',
    unit: 'months',
  },
  {
    key: '2-years',
    label: '2 Years',
    zoom: 4,
    format: 'MMM DD YYYY',
    unit: 'months',
  },
  {
    key: '3-years',
    label: '3 Years',
    zoom: 6,
    format: 'MMM DD YYYY',
    unit: 'months',
  },
  {
    key: '4-years',
    label: '4 Years',
    zoom: 12,
    format: 'MMM DD YYYY',
    unit: 'months',
  },
  {
    key: '5-years',
    label: '5 Years',
    zoom: 12,
    format: 'MMM DD YYYY',
    unit: 'months',
  },
];

export const GanttReportZoomConfigTabloid = [
  {
    key: '3-months',
    label: '3 Months',
    zoom: 7,
    format: 'MMM DD YYYY',
    unit: 'days',
  },
  {
    key: '6-months',
    label: '6 Months',
    zoom: 14,
    format: 'MMM DD YYYY',
    unit: 'days',
  },
  {
    key: '1-year',
    label: '1 Year',
    zoom: 1,
    format: 'MMM DD YYYY',
    unit: 'months',
  },
  {
    key: '2-years',
    label: '2 Years',
    zoom: 2,
    format: 'MMM DD YYYY',
    unit: 'months',
  },
  {
    key: '3-years',
    label: '3 Years',
    zoom: 3,
    format: 'MMM DD YYYY',
    unit: 'months',
  },
  {
    key: '4-years',
    label: '4 Years',
    zoom: 6,
    format: 'MMM DD YYYY',
    unit: 'months',
  },
  {
    key: '5-years',
    label: '5 Years',
    zoom: 6,
    format: 'MMM DD YYYY',
    unit: 'months',
  },
];

export const timelineIntervals = [
  {
    length: 3,
    units: 'months',
    columns: {
      letter: 6,
      tabloid: 12,
    },
  },
  {
    length: 6,
    units: 'months',
    columns: {
      letter: 6,
      tabloid: 12,
    },
  },
  {
    length: 1,
    units: 'year',
    columns: {
      letter: 6,
      tabloid: 12,
    },
  },
  {
    length: 2,
    units: 'years',
    columns: {
      letter: 6,
      tabloid: 12,
    },
  },
  {
    length: 3,
    units: 'years',
    columns: {
      letter: 6,
      tabloid: 12,
    },
  },
  {
    length: 4,
    units: 'years',
    columns: {
      letter: 4,
      tabloid: 8,
    },
  },
  {
    length: 5,
    units: 'years',
    columns: {
      letter: 5,
      tabloid: 10,
    },
  },
];

export function getColumnHeaders(min, max, zoomConfig) {
  if (!min || !max) return [];

  const start = moment.utc(min);
  const columnHeaders = [];
  let counter = 0;
  const maxDate = zoomConfig.unit !== 'months' ? max.add(1, zoomConfig.unit) : max;

  while (start < maxDate || counter < GANTT_MINIMUM_COLUMNS) {
    let header = '';
    if (zoomConfig.unit === 'weeks') {
      const weekOf = moment(start).day('Monday').week(start.format('w'));
      header = weekOf.format(zoomConfig.format);
    } else {
      header = start.format(zoomConfig.format);
    }
    start.add(zoomConfig.zoom, zoomConfig.unit);
    columnHeaders.push(header);
    counter += 1;
  }

  return columnHeaders;
}

export function getStartPx(minDate, startDate, zoomConfig, columnWidth = GANTT_COLUMN_WIDTH) {
  if (!minDate) return null;

  let startPx;
  if (zoomConfig.unit === 'days') {
    startPx = startDate.diff(minDate, 'days') * columnWidth;
  } else if (zoomConfig.unit === 'weeks') {
    const minWeekStart = minDate.day('Monday');
    startPx = (startDate.diff(minWeekStart, 'weeks', true) * columnWidth);
  } else {
    const monthWidth = columnWidth / zoomConfig.zoom;
    const monthDays = startDate.daysInMonth();
    const allocationDay = startDate.date() - 1;
    const monthPx = (allocationDay / monthDays) * monthWidth;
    const diff = Math.floor(startDate.diff(minDate, 'months', true));
    startPx = (diff * monthWidth) + monthPx;
  }
  return startPx;
}

const monthDiff = (d1, d2) => {
  const nextMonth = moment(d1).add(1, 'months').startOf('month');
  const lastMonth = moment(d2).endOf('month');
  return lastMonth.diff(nextMonth, 'month');
};

export function getTodayPosition(minDate, zoomConfig, customDate = null) {
  if (!minDate) return null;

  if (customDate) {
    const date = moment(customDate).startOf('day');
    return getStartPx(minDate, date, zoomConfig);
  }

  const now = moment.utc().startOf('day');
  return getStartPx(minDate, now, zoomConfig);
}

export function getMinMonth(date, zoomLevel) {
  const month = date.month();
  return Math.floor(month / zoomLevel) * zoomLevel;
}

export function getMaxMonth(date, zoomLevel) {
  const month = date.month() + 1;
  return (Math.ceil(month / zoomLevel) * zoomLevel) + 1;
}

// If you don't want to include the last day, pass inclusive = false
export function getBarWidth(startDate, endDate, zoomConfig, inclusive = true, columnWidth = GANTT_COLUMN_WIDTH) {
  const startMonthDays = startDate.daysInMonth();
  const dayOffset = inclusive ? 1 : 0;
  const dayOfMonth = startDate.date() - dayOffset; // Integer value of the day number (e.g. Jan 7 = 7)
  const monthWidth = columnWidth / zoomConfig.zoom;
  const totalMonths = monthDiff(startDate, endDate);

  if (zoomConfig.unit === 'days') {
    const daysInRange = endDate.diff(startDate, 'days');
    return (daysInRange * columnWidth) + columnWidth;
  }

  if (zoomConfig.unit === 'weeks') {
    const weeksBetween = endDate.diff(startDate, 'weeks', true);
    const offset = columnWidth / 7;
    return weeksBetween * columnWidth + offset;
  }

  let totalWidth = totalMonths > 0 ? totalMonths * monthWidth : 0;

  const startMonthPx = ((startMonthDays - dayOfMonth) / startMonthDays) * monthWidth;
  totalWidth += startMonthPx;

  const endMonthDays = endDate.daysInMonth();
  const endDay = endDate.date();
  if (startDate.isSame(endDate, 'month')) {
    const endMonthPx = ((endMonthDays - endDay) / endMonthDays) * monthWidth;
    totalWidth -= endMonthPx;
  } else {
    const endMonthPx = (endDay / endMonthDays) * monthWidth;
    totalWidth += endMonthPx;
  }

  return totalWidth;
}

export function getPeopleDateRanges(zoomConfig) {
  const min = moment.utc().subtract(MIN_DATA_RANGE_IN_MONTHS, 'months');
  const max = moment.utc().add(MAX_DATA_RANGE_IN_YEARS, 'years');

  if (zoomConfig.unit === 'days') {
    return {
      min: min.startOf('day'),
      max,
    };
  }

  if (zoomConfig.unit === 'weeks') {
    return {
      min: min.startOf('week'),
      max: max.subtract(1, 'week'),
    };
  }

  return {
    min: min.month(getMinMonth(min, zoomConfig.zoom)).startOf('month'),
    max,
  };
}

export function getPercentageLabel(allocations) {
  const isCustom = allocations.some(alloc => alloc.allocatedPercent !== allocations[0].allocatedPercent);
  return isCustom ? 'Custom' : `${allocations[0].allocatedPercent}%`;
}

export function getRoleProgress(now, startDate, endDate) {
  if (now < startDate) {
    return '0';
  }
  if (now > endDate) {
    return '100%';
  }

  const total = endDate - startDate;
  const progress = now - startDate;

  return `${Math.round((progress / total) * 100)}%`;
}

export function getPhaseStartPx(minDate, startDate, zoomConfig) {
  return zoomConfig.unit !== 'months'
    ? startDate.diff(minDate, zoomConfig.unit, true) * GANTT_COLUMN_WIDTH
    : getBarWidth(minDate, startDate, zoomConfig, false);
}

const dateIsInRange = (date, minStartDate, maxEndDate) => (
  moment(date).isSameOrBefore(maxEndDate) && moment(date).isSameOrAfter(minStartDate)
);

export const trimTimeBar = (timeBar, minStartDate, maxEndDate) => {
  const { startDate, endDate } = timeBar;

  const momentStartDate = moment(startDate);
  const momentEndDate = moment(endDate).add(1, 'day');

  if (momentEndDate.isSameOrBefore(minStartDate)) return null;
  if (momentStartDate.isSameOrAfter(maxEndDate)) return null;

  let trimmedStartDate = startDate;
  let trimmedEndDate = endDate;

  if (momentStartDate.isBefore(minStartDate) && dateIsInRange(endDate, minStartDate, maxEndDate)) {
    trimmedStartDate = momentToString(minStartDate);
  }

  if (momentEndDate.isAfter(maxEndDate) && dateIsInRange(startDate, minStartDate, maxEndDate)) {
    trimmedEndDate = momentToString(maxEndDate);
  }

  if (momentStartDate.isBefore(minStartDate) && momentEndDate.isAfter(maxEndDate)) {
    trimmedStartDate = momentToString(minStartDate);
    trimmedEndDate = momentToString(maxEndDate);
  }

  return {
    ...timeBar,
    startDate: trimmedStartDate,
    endDate: trimmedEndDate,
  };
};
