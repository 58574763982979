import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FILTERED_PEOPLE_QUERY_ID } from '../queries/redux/constants';
import {
  getColumnHeaders,
  GanttReportZoomConfigLetter,
  GanttReportZoomConfigTabloid,
  timelineIntervals,
} from '../gantt/utils/ganttUtils';
import { TABLOID_PAPER } from './redux/constants';
import { TimelineHeader, PeopleGanttTitleHeader } from '../gantt';
import { PeopleGanttReportContent, ReportTemplate, CoverPage } from '.';
import { getAccountById } from '../../utils/miscUtils';
import { SETTINGS_TABS } from '../account-settings/common/constants';
import { trackAnalytics } from '../common/redux/actions';
import { REPORTS_EXPORT_PEOPLE_GANTT } from '../../analytics/reports/constants';
import {
  getBasicGanttReportAnalyticsPayload,
  getPeopleGanttReportAnalyticsPayload,
} from '../../analytics/reports/utils';

const { PEOPLE, PROJECTS } = SETTINGS_TABS;

const GanttReport = ({ type }) => {
  const dispatch = useDispatch();

  const { settings, peopleCount } = useSelector(({ reports }) => {
    const { people, settings } = reports;

    return {
      settings,
      peopleCount: people?.length,
    };
  });
  const { accountId } = useSelector(({ common }) => common);
  const { entities: accounts } = useSelector(({ accounts }) => accounts);
  const { filteredPeople } = useSelector(({ queries }) => queries);
  const { people: peopleGanttSettings } = useSelector(({ gantt }) => gantt.instances);

  useEffect(() => {
    const filteredEntity = filteredPeople;
    const basicAnalyticsPayload = getBasicGanttReportAnalyticsPayload(settings, filteredEntity);
    const eventName = REPORTS_EXPORT_PEOPLE_GANTT;

    const extraAnalyticsPayload = getPeopleGanttReportAnalyticsPayload(peopleGanttSettings);

    dispatch(trackAnalytics(eventName, { ...basicAnalyticsPayload, ...extraAnalyticsPayload }));
  }, [dispatch, filteredPeople, peopleGanttSettings, settings]);

  const count = peopleCount;
  const queryId = FILTERED_PEOPLE_QUERY_ID;
  const ReportContent = PeopleGanttReportContent;
  const account = getAccountById(accounts, accountId);

  const { title, showProfilePictures } = settings;

  const coverPage = (
    <CoverPage
      title={title}
      subTitle={`${count} ${pluralize(type, count)} included`}
      showFields={false}
      queryId={queryId}
    />
  );

  const { earliestDate, timelineLength, paperSize, columnWidth } = settings;

  const { length, units, columns } = timelineIntervals[timelineLength];
  const maxDate = moment.utc(earliestDate).add(length, units);
  const minDate = moment.utc(earliestDate).startOf('month');

  const columnCount = paperSize === TABLOID_PAPER
    ? columns.tabloid
    : columns.letter;

  const zoomConfig =
    paperSize === TABLOID_PAPER
      ? GanttReportZoomConfigTabloid[timelineLength]
      : GanttReportZoomConfigLetter[timelineLength];

  const columnHeaders = getColumnHeaders(minDate, maxDate, zoomConfig);

  // Depending on the report config settings `getColumnHeaders` might return
  // more columns than what we actually need so we have to remove them.
  if (columnHeaders.length > columnCount) {
    const diff = columnHeaders.length - columnCount;
    columnHeaders.splice(columnHeaders.length - diff, diff);
  }

  const ganttWidth = columnHeaders.length * columnWidth;
  const headerTitle = <PeopleGanttTitleHeader isReport />;

  const pageHeader = (
    <div className="page-header">
      {headerTitle}
      <TimelineHeader columnHeaders={columnHeaders} monthWidth={ganttWidth} showToday={false} />
    </div>
  );

  const report = (
    <ReportContent
      minDate={minDate}
      zoomConfig={zoomConfig}
      showProfilePictures={showProfilePictures}
    />
  );

  return (
    <div className="reports-gantt-report">
      <ReportTemplate
        coverPage={coverPage}
        report={report}
        header={account?.name || ''}
        title={title}
        showReportInfo={false}
        pageHeader={pageHeader}
      />
    </div>
  );
};

GanttReport.propTypes = {
  type: PropTypes.oneOf([PEOPLE, PROJECTS]).isRequired,
};

export default GanttReport;
