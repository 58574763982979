import {
  PERMISSIONS_CREATE_PERMISSION_GROUP_SUCCESS,
  PERMISSIONS_UPDATE_PERMISSION_GROUP_SUCCESS,
  PERMISSIONS_DELETE_PERMISSION_GROUP_SUCCESS,
} from '../../features/permissions/redux/constants';
import {
  CUSTOM_PERMISSION_GROUP_ADDED,
  CUSTOM_PERMISSION_GROUP_EDITED,
  CUSTOM_PERMISSION_GROUP_DELETED,
} from './constants';
import { createPermissionGroupPayload } from './utils';

const events = {
  [PERMISSIONS_CREATE_PERMISSION_GROUP_SUCCESS]: {
    getEvent: () => CUSTOM_PERMISSION_GROUP_ADDED,
    getPayload: createPermissionGroupPayload,
  },
  [PERMISSIONS_UPDATE_PERMISSION_GROUP_SUCCESS]: {
    getEvent: () => CUSTOM_PERMISSION_GROUP_EDITED,
    getPayload: createPermissionGroupPayload,
  },
  [PERMISSIONS_DELETE_PERMISSION_GROUP_SUCCESS]: {
    getEvent: () => CUSTOM_PERMISSION_GROUP_DELETED,
    getPayload: createPermissionGroupPayload,
  },
};

export default events;
