import { call, put, takeLatest } from 'redux-saga/effects';

import {
  ACCOUNTS_UPDATE_ACCOUNT_BEGIN,
  ACCOUNTS_UPDATE_ACCOUNT_DISMISS_ERROR,
  ACCOUNTS_UPDATE_ACCOUNT_FAILURE,
  ACCOUNTS_UPDATE_ACCOUNT_SUCCESS,
} from './constants';
import { protectedJsonPatch } from '../../../utils/api';

export function updateAccount(data) {
  // If need to pass args to saga, pass it with the begin action.
  return {
    type: ACCOUNTS_UPDATE_ACCOUNT_BEGIN,
    data,
  };
}

export function dismissUpdateAccountError() {
  return {
    type: ACCOUNTS_UPDATE_ACCOUNT_DISMISS_ERROR,
  };
}

// worker Saga: will be fired on ACCOUNTS_UPDATE_ACCOUNT_BEGIN actions
export function* doUpdateAccount(action) {
  // If necessary, use argument to receive the begin action with parameters.
  let res;
  try {
    res = yield call(protectedJsonPatch, `accounts/${action.data.id}`, action.data);
  } catch (err) {
    yield put({
      type: ACCOUNTS_UPDATE_ACCOUNT_FAILURE,
      data: { error: err },
    });
    return;
  }
  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: ACCOUNTS_UPDATE_ACCOUNT_SUCCESS,
    data: res,
  });
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchUpdateAccount() {
  yield takeLatest(ACCOUNTS_UPDATE_ACCOUNT_BEGIN, doUpdateAccount);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNTS_UPDATE_ACCOUNT_BEGIN:
      return {
        ...state,
        updateAccountPending: true,
        updateAccountError: null,
      };

    case ACCOUNTS_UPDATE_ACCOUNT_SUCCESS: {
      const updateAccountList = state.entities.map((account) => {
        if (account.id === action.data.id) {
          const newAcct = {
            ...account,
            ...action.data,
          };
          return newAcct;
        }
        return account;
      });

      return {
        ...state,
        entities: updateAccountList,
        updateAccountPending: false,
        updateAccountError: null,
      };
    }

    case ACCOUNTS_UPDATE_ACCOUNT_FAILURE:
      return {
        ...state,
        updateAccountPending: false,
        updateAccountError: action.data.error,
      };

    case ACCOUNTS_UPDATE_ACCOUNT_DISMISS_ERROR:
      return {
        ...state,
        updateAccountError: null,
      };

    default:
      return state;
  }
}
