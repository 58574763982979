import initialState from './initialState';
import { reducer as userLoginReducer } from './userLogin';
import { reducer as acceptInvitationReducer } from './acceptInvitation';
import { reducer as getLoginInvitationReducer } from './getLoginInvitation';
import { reducer as userLogoutReducer } from './userLogout';
import { reducer as getUserInfoReducer } from './getUserInfo';
import { reducer as acceptInvitationOauthReducer } from './acceptInvitationOauth';
import { reducer as updateUserInfoReducer } from './updateUserInfo';
import { reducer as updatePasswordReducer } from './updatePassword';
import { reducer as requestForgotPasswordReducer } from './requestForgotPassword';
import { reducer as resetPasswordReducer } from './resetPassword';
import { reducer as getDefaultTeamsReducer } from './getDefaultTeams';

const reducers = [
  userLoginReducer,
  acceptInvitationReducer,
  getLoginInvitationReducer,
  userLogoutReducer,
  getUserInfoReducer,
  acceptInvitationOauthReducer,
  updateUserInfoReducer,
  updatePasswordReducer,
  requestForgotPasswordReducer,
  resetPasswordReducer,
  getDefaultTeamsReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
