import React, { useMemo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { PERM_WRITE, PERM_ROLE } from 'src/features/permissions/utils/constants';
import { naturalSort } from 'src/utils/sortUtils';
import { EditProjectRoles, AssignProjectRoles } from '.';
import { Can } from '../wrapped-components';
import {
  getProjectAllocations,
  getProjectRoles,
  getProjects,
} from './redux/actions';
import { getRoleTemplates } from '../accounts/redux/getRoleTemplates';

export const ProjectResourceTabDetail = ({
  projectEntities,
  getProjectRoles,
  getProjectAllocations,
  getProjects,
  accountId,
  selectedProject,
  addProjectRoles,
  runsInModal,
  projectChanged,
  getProjectAllocationsPending,
  getPersonFieldValuesPending,
  getPeoplePending,
  addProjectRolesPending,
  getRoleTemplates,
  getRoleTemplatesPending,
}) => {
  const [loading, setLoading] = useState(getPeoplePending || getPersonFieldValuesPending || getProjectAllocationsPending || getRoleTemplatesPending);
  const [editing, setEditing] = useState(false);
  const [initialRoles, setInitialRoles] = useState([]);

  useEffect(() => {
    // Load existing allocations for this project
    getProjectAllocations(accountId, selectedProject.id);
    getProjects(accountId);
    getRoleTemplates(accountId);
  }, [accountId, selectedProject.id, getProjectAllocations, getProjects, getRoleTemplates]);

  useEffect(() => {
    if (!addProjectRolesPending) {
      setEditing(false);
      setInitialRoles([]);
    }
  }, [addProjectRolesPending]);

  useEffect(() => {
    const nextLoading =
    getPeoplePending ||
    getPersonFieldValuesPending ||
    getProjectAllocationsPending;

    // Use saga flags to set loading to false, but only set loading to true when specifically required
    // Loading state is necessary for changing projects, but not for refreshing the current project allocations
    if (loading && loading !== nextLoading) {
      setLoading(nextLoading);
      setEditing(false);
      setInitialRoles([]);
    }
  }, [getPeoplePending, getPersonFieldValuesPending, getProjectAllocationsPending, loading]);

  // If something changes with the allocation, reload all allocations for the selected project
  // This will ensure that newly created issues on other roles will be displayed correctly
  useEffect(() => {
    if (!projectChanged) {
      getProjectAllocations(accountId, selectedProject.id);
    } else {
      setLoading(true);
      setEditing(false);
      setInitialRoles([]);
      getProjectAllocations(accountId, selectedProject.id);
    }
  }, [accountId, getProjectAllocations, projectChanged, selectedProject.id]);

  const onCancel = () => {
    setEditing(false);
    setInitialRoles([]);
  };

  const onAddNewRole = useCallback((roles) => {
    setEditing(!editing);
    setInitialRoles(initialRoles.length ? [...initialRoles, ...roles] : roles);
  }, [editing, initialRoles]);

  const onSaveProjectRoles = useCallback((roleData) => {
    const parentName = runsInModal ? 'Project Details Modal' : 'Project List';
    addProjectRoles(accountId, selectedProject.id, roleData, parentName);
  }, [accountId, addProjectRoles, runsInModal, selectedProject.id]);

  const onSelectItem = useCallback((project) => {
    getProjectRoles(accountId, project.id);
  }, [accountId, getProjectRoles]);

  // Filter out projects with no roles - for copying roles from project
  const projectsData = useMemo(() => naturalSort(projectEntities.reduce((acc, { id, name, totalRoles }) => {
    if (totalRoles !== 0) {
      acc.push({ id, name, value: name });
    }
    return acc;
  }, []), 'name'), [projectEntities]);

  const renderEditOrAssignRoles = useMemo(() => {
    if (editing) {
      const parentName = runsInModal ? 'Project Details Modal' : 'Project List';

      return (
        <Can
          action={PERM_WRITE}
          subject={PERM_ROLE}
          yes={(
            <EditProjectRoles
              onSaveProjectRoles={onSaveProjectRoles}
              selectedProject={selectedProject}
              onCancel={onCancel}
              initialRoles={initialRoles}
              parentName={parentName}
              onSelectItem={onSelectItem}
              projectsData={projectsData}
            />
      )}
        />
      );
    }
    return (
      <AssignProjectRoles
        selectedProject={selectedProject}
        toggleEdit={onAddNewRole}
        loading={loading}
        runsInModal={runsInModal}
        onSelectItem={onSelectItem}
        projectsData={projectsData}
        accountId={accountId}
      />
    );
  }, [
    editing,
    initialRoles,
    loading,
    onAddNewRole,
    onSaveProjectRoles,
    onSelectItem,
    projectsData,
    runsInModal,
    selectedProject,
    accountId,
  ]);

  return (
    <div className={classNames('projects-project-resource-tab-detail', { 'runs-in-modal': runsInModal })}>
      {renderEditOrAssignRoles}
    </div>
  );
};

ProjectResourceTabDetail.propTypes = {
  projectEntities: PropTypes.arrayOf(PropTypes.object).isRequired,
  getProjectRoles: PropTypes.func.isRequired,
  getProjectAllocations: PropTypes.func.isRequired,
  getProjects: PropTypes.func.isRequired,
  accountId: PropTypes.number.isRequired,
  selectedProject: PropTypes.object.isRequired,
  addProjectRoles: PropTypes.func.isRequired,
  runsInModal: PropTypes.bool,
  projectChanged: PropTypes.bool.isRequired,
  getProjectAllocationsPending: PropTypes.bool.isRequired,
  getPersonFieldValuesPending: PropTypes.bool.isRequired,
  getPeoplePending: PropTypes.bool.isRequired,
  addProjectRolesPending: PropTypes.bool.isRequired,
  getRoleTemplates: PropTypes.func.isRequired,
  getRoleTemplatesPending: PropTypes.bool.isRequired,
};

ProjectResourceTabDetail.defaultProps = {
  runsInModal: false,
};

/* istanbul ignore next */
function mapStateToProps({ projects, people, common, accounts }) {
  const {
    addProjectRoleAllocationPending,
    deleteProjectAllocationPending,
    replaceProjectRoleAllocationPending,
    updateProjectRoleAllocationPending,
    deleteProjectRolePending,
    updateProjectRolePending,
    projects: projectEntities,
    getProjectAllocationsPending,
    addProjectRolesPending,
  } = projects;
  const {
    addUnavailabilityPending,
    deleteUnavailabilityPending,
    updateUnavailabilityPending,
    getPersonFieldValuesPending,
    getPeoplePending,
  } = people;

  const {
    getRoleTemplatesPending,
  } = accounts;

  const projectChanged =
    addProjectRoleAllocationPending ||
    deleteProjectAllocationPending ||
    replaceProjectRoleAllocationPending ||
    updateProjectRoleAllocationPending ||
    deleteProjectRolePending ||
    updateProjectRolePending ||
    addUnavailabilityPending ||
    deleteUnavailabilityPending ||
    updateUnavailabilityPending;

  return {
    accountId: common.accountId,
    projectChanged,
    getProjectAllocationsPending,
    projectEntities,
    getPersonFieldValuesPending,
    getPeoplePending,
    addProjectRolesPending,
    getRoleTemplatesPending,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    getProjectRoles: bindActionCreators(getProjectRoles, dispatch),
    getProjectAllocations: bindActionCreators(getProjectAllocations, dispatch),
    getProjects: bindActionCreators(getProjects, dispatch),
    getRoleTemplates: bindActionCreators(getRoleTemplates, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectResourceTabDetail);
