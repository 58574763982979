import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { BACKGROUND_GREY, MAIN_GREY } from '@bridgit/foundation';
import { EDIT_ACTION_COLLAPSED, EDIT_ACTION_EXTENDED, END_DATE, START_DATE, UNFILLED } from 'src/common/constants';
import { trimSegmentsToRange } from 'src/utils/dateSegmentUtils';
import {
  EDITABLE_SEGMENT_TYPES,
  PHASE_WILL_BE_DELETED,
  ROLE_WILL_BE_DELETED,
  SEGMENT_TYPE_PHASE,
  SEGMENT_TYPE_PRIMARY_PHASE,
  SEGMENT_TYPE_PROJECT,
  SEGMENT_TYPE_ROLE,
} from './common/constants';
import { SingleSegment } from './index';
import {
  buildTimelineDates,
  isPrimary,
  isUneditableProjectBar,
  extendingByEndDate,
  isSecondaryProjectBar,
  shouldShiftDates,
} from './utils/dateEditorUtils';
import { setSegmentSelected } from './redux/setSegmentSelected';
import { customPropCheck } from '../../utils/propCheckUtils';
import { isStringOrMoment } from '../../utils/dateUtils';

function SegmentFactory({
  segmentType,
  segment,
  offset,
  editAction,
  dateUnderEdit,
  setSegmentSelected,
  typeUnderEdit,
  newStartDate,
  newEndDate,
  projectEnd,
}) {
  const { id, name, startDate, endDate, checked, showCheckbox, showDeleteWarning, colour, allocations, note, disabled } = segment;

  const extendProject = isUneditableProjectBar(
    segmentType, editAction, dateUnderEdit, typeUnderEdit, newEndDate, projectEnd,
  );

  const useOriginalDates = isSecondaryProjectBar(segmentType, typeUnderEdit) &&
    !extendingByEndDate(editAction, dateUnderEdit);

  const shiftDates = shouldShiftDates(typeUnderEdit, dateUnderEdit, startDate, newEndDate, offset, checked);

  const includeOffset = (editAction === EDIT_ACTION_EXTENDED && checked) ||
    (typeUnderEdit === SEGMENT_TYPE_PHASE && editAction === EDIT_ACTION_COLLAPSED && !checked);

  const options = isPrimary(segmentType, typeUnderEdit) ? {} : {
    combineSegments: true,
    includeOffset,
    extendProject,
    useOriginalDates,
    shiftDates,
  };

  const isCollapsed = editAction === EDIT_ACTION_COLLAPSED;
  const updatedParent = { startDate: newStartDate, endDate: newEndDate };
  const timelineDates = buildTimelineDates(
    { startDate, endDate }, updatedParent, editAction, dateUnderEdit, typeUnderEdit, offset, options,
  );

  const updateSegmentEditState = useCallback((segmentType, segmentId) => () => {
    setSegmentSelected(segmentType, segmentId);
  }, [setSegmentSelected]);

  const commonProps = {
    name,
    checked,
    showCheckbox,
    showDeleteWarning,
    timelineDates,
    onToggleEdit: updateSegmentEditState(segmentType, id),
    compact: segmentType !== typeUnderEdit,
    disabled,
    endContentTooltip: disabled
      ? `This ${segmentType === SEGMENT_TYPE_ROLE ? 'role' : 'phase'} is restricted by the project end date`
      : null,
  };

  const renderOffsetText = () => (isPrimary(segmentType, typeUnderEdit) ?
    (
      <p className="offset-text">
        {`${isCollapsed ? '-' : '+'}${pluralize('day', Math.abs(offset), true)}`}
      </p>
    )
    : null
  );

  const getPrimaryPhaseSegment = () => (
    <SingleSegment
      {...commonProps} // eslint-disable-line react/jsx-props-no-spreading
      capsulePrimaryColor={BACKGROUND_GREY}
      capsuleBorderColor={MAIN_GREY}
      endContent={renderOffsetText()}
    />
  );

  const getProjectSegment = () => (
    <SingleSegment
      {...commonProps} // eslint-disable-line react/jsx-props-no-spreading
      capsulePrimaryColor={colour}
      capsuleBorderColor="transparent"
      endContent={renderOffsetText()}
    />
  );

  const getPhaseSegment = () => (
    <SingleSegment
      {...commonProps} // eslint-disable-line react/jsx-props-no-spreading
      warningText={PHASE_WILL_BE_DELETED}
    />
  );

  const getRoleSegment = () => {
    const getImpactedNames = (allocationSubset) => {
      const ids = [];
      const names = [];

      // Show each name only once, maintaining the order in which each first appears
      allocationSubset.forEach((allocation) => {
        const { name, personId } = allocation;
        if (!ids.includes(personId)) {
          ids.push(personId);
          names.push(name);
        }
      });

      return names.join(', ') || UNFILLED;
    };

    let impactedPersons;

    // If this role will be deleted, show all allocated people. Otherwise, show remaining people
    if (showDeleteWarning) {
      impactedPersons = getImpactedNames(allocations);
    } else {
      const end = typeUnderEdit === SEGMENT_TYPE_PHASE ? projectEnd : newEndDate;
      const trimmedAllocations = trimSegmentsToRange(allocations, newStartDate, end);
      impactedPersons = getImpactedNames(trimmedAllocations);
    }

    return (
      <SingleSegment
        {...commonProps} // eslint-disable-line react/jsx-props-no-spreading
        secondaryText={note}
        capsuleLeftText={impactedPersons}
        warningText={ROLE_WILL_BE_DELETED}
        tooltip={impactedPersons}
      />
    );
  };

  switch (segmentType) {
    case SEGMENT_TYPE_PRIMARY_PHASE:
      return getPrimaryPhaseSegment();
    case SEGMENT_TYPE_PROJECT:
      return getProjectSegment();
    case SEGMENT_TYPE_ROLE:
      return getRoleSegment();
    case SEGMENT_TYPE_PHASE:
      return getPhaseSegment();
    default:
      return null;
  }
}

SegmentFactory.propTypes = {
  segmentType: PropTypes.oneOf(EDITABLE_SEGMENT_TYPES).isRequired,
  segment: PropTypes.object.isRequired,
  offset: PropTypes.number.isRequired,
  editAction: PropTypes.oneOf([EDIT_ACTION_EXTENDED, EDIT_ACTION_COLLAPSED]).isRequired,
  dateUnderEdit: PropTypes.oneOf([START_DATE, END_DATE]).isRequired,
  setSegmentSelected: PropTypes.func.isRequired,
  typeUnderEdit: PropTypes.oneOf(EDITABLE_SEGMENT_TYPES).isRequired,
  newStartDate: customPropCheck(isStringOrMoment).isRequired,
  newEndDate: customPropCheck(isStringOrMoment).isRequired,
  projectEnd: customPropCheck(isStringOrMoment).isRequired,
};

/* istanbul ignore next */
function mapStateToProps({ editDates }) {
  const { offset, editAction, dateUnderEdit, typeUnderEdit, newStartDate, newEndDate, segments } = editDates;
  const projectEnd = segments[SEGMENT_TYPE_PROJECT]?.[0]?.endDate;

  return {
    offset,
    editAction,
    dateUnderEdit,
    typeUnderEdit,
    newStartDate,
    newEndDate,
    projectEnd,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    setSegmentSelected: bindActionCreators(setSegmentSelected, dispatch),
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SegmentFactory);
