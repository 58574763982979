import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { EditorModalManager } from 'src/features/edit-dates';
import { resetEditorState } from 'src/features/edit-dates/redux/actions';
import { PURSUIT } from 'src/features/projects/constants';
import { ManagedModal } from '../modal-manager';
import { closeModal } from '../modal-manager/redux/actions';
import {
  MULTI_STATE_MODAL_ID,
  COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PERSON,
  COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PROJECT,
  COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_ALLOCATE_PERSON,
  COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_EDIT_PROJECT_DATE,
  COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_EDIT_PHASE_DATE,
  COMMON_MODAL_POSITION_TOP,
} from './redux/constants';
import { ROLE_AVAILABILITIES_QUERY_ID } from '../queries/redux/constants';
import { clearQueries } from '../queries/redux/actions';
import { PeopleProfile, PersonModalSidePanel } from '../people';
import {
  getPersonById,
  clearPersonSelection,
  removePerson,
  getPersonCurrentAllocations,
  getPersonPastAllocations,
  getPersonAllocations,
  getPersonUpcomingAllocations,
} from '../people/redux/actions';
import { getProjectById } from '../projects/redux/getProjectById';
import { ProjectDetails, ProjectModalSidePanel } from '../projects';
import { clearProjectSelection } from '../projects/redux/clearProjectSelection';
import { setModalActiveView, clearMultiStateModalState, setSelectedProjectId } from './redux/actions';
import { AllocatePerson } from '../allocations';
import {
  PEOPLE_VIEW,
  PROJECT_VIEW,
} from '../../common/constants';
import { PEOPLE_LIST_SELECTION_ID } from '../people/redux/constants';
import { PROJECT_LIST_SELECTION_ID, PROJECTS_PROJECT_SELECTION_BACKUP } from '../projects/redux/constants';
import { getProjectAllocations } from '../projects/redux/actions';
import { PROFILE_DETAILS_MODAL_CONTEXT } from '../people/constants';

const MultiStateModal = ({
  accountId,
  modalActiveView,
  appActiveView,
  closeModal,
  removePerson,
  getPersonById,
  getProjectById,
  selectedPerson,
  selectedProject,
  selectedPersonId,
  selectedProjectId,
  roleDetails,
  getPersonByIdPending,
  getProjectByIdPending,
  clearPersonSelection,
  clearProjectSelection,
  listSelectedPerson,
  listSelectedProject,
  getPersonCurrentAllocations,
  getPersonPastAllocations,
  getPersonAllocations,
  getPersonUpcomingAllocations,
  getProjectAllocations,
  setModalActiveView,
  clearMultiStateModalState,
  setSelectedProjectId,
  projectSelectionBackup,
  clearQueries,
  resetEditorState,
  permissions,
}) => {
  useEffect(() => {
    if (accountId && modalActiveView) {
      if (modalActiveView === COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PERSON && selectedPersonId) {
        getPersonById(accountId, selectedPersonId, MULTI_STATE_MODAL_ID);
      } else if (
        (
          modalActiveView === COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_ALLOCATE_PERSON ||
          modalActiveView === COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_EDIT_PROJECT_DATE ||
          modalActiveView === COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PROJECT ||
          modalActiveView === COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_EDIT_PHASE_DATE
        ) &&
        selectedProjectId
      ) {
        getProjectById(accountId, selectedProjectId, MULTI_STATE_MODAL_ID, false, permissions);
      }
    }
  }, [
    getPersonById,
    getProjectById,
    accountId,
    selectedPersonId,
    modalActiveView,
    selectedProjectId,
    permissions,
  ]);

  const toggleActivation = () => {
    removePerson(accountId, selectedPersonId, selectedPerson.name, PROFILE_DETAILS_MODAL_CONTEXT);
    clearPersonSelection(MULTI_STATE_MODAL_ID);
    closeModal(MULTI_STATE_MODAL_ID);
  };

  const onPeopleProfileBackClick = () => {
    setModalActiveView(COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_ALLOCATE_PERSON);
  };

  const onProjectDetailsBackClick = () => {
    if (projectSelectionBackup?.id) {
      setSelectedProjectId(projectSelectionBackup.id, COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PROJECT, null, true);
      setModalActiveView(COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_ALLOCATE_PERSON);
    }
  };

  const onClose = () => {
    if (appActiveView === PEOPLE_VIEW && listSelectedPerson?.id) {
      getPersonCurrentAllocations(accountId, listSelectedPerson.id, 0);
      getPersonPastAllocations(accountId, listSelectedPerson.id, 0);
      getPersonAllocations(accountId, listSelectedPerson.id, 0, PURSUIT);
      getPersonUpcomingAllocations(accountId, listSelectedPerson.id, 0);
    } else if (appActiveView === PROJECT_VIEW && listSelectedProject?.id) {
      getProjectAllocations(accountId, listSelectedProject.id);
    }

    const analyticsPayload = { };

    switch (modalActiveView) {
      case COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PERSON:
        analyticsPayload.closedFrom = PROFILE_DETAILS_MODAL_CONTEXT;
        break;
      case COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PROJECT:
        analyticsPayload.closedFrom = 'Project Details Modal';
        break;
      case COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_ALLOCATE_PERSON:
        analyticsPayload.closedFrom = 'Place a Person Modal';
        break;
      case COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_EDIT_PROJECT_DATE:
        analyticsPayload.closedFrom = 'Edit Project Modal';
        break;
      case COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_EDIT_PHASE_DATE:
        analyticsPayload.closedFrom = 'Edit Phase Modal';
        break;
      default:
        analyticsPayload.closedFrom = 'Other';
        break;
    }

    clearMultiStateModalState(analyticsPayload);
    clearPersonSelection(MULTI_STATE_MODAL_ID);
    clearProjectSelection(MULTI_STATE_MODAL_ID);
    clearProjectSelection(PROJECTS_PROJECT_SELECTION_BACKUP);
    clearQueries(ROLE_AVAILABILITIES_QUERY_ID);
    resetEditorState();
  };

  const renderPeopleProfile = () => {
    if (!selectedPerson) return null;
    const { availableInDays } = selectedPerson;
    return (
      <div className="person-in-modal">
        <PeopleProfile
          selectedPerson={selectedPerson}
          selectedPersonAvailability={availableInDays}
          toggleActivation={toggleActivation}
          onBackClick={onPeopleProfileBackClick}
          runsInModal
        />
        <PersonModalSidePanel selectedPerson={selectedPerson} />
      </div>
    );
  };

  const renderProjectDetails = () => {
    if (!selectedProject) return null;
    return (
      <div className="project-in-modal">
        <ProjectDetails
          className="detail-compact"
          selectedProject={selectedProject}
          onBackClick={onProjectDetailsBackClick}
          runsInModal
        />
        <ProjectModalSidePanel selectedProject={selectedProject} />
      </div>
    );
  };

  const renderAllocatePerson = () => {
    if (!selectedProject || !roleDetails) return null;

    const { roles } = selectedProject;
    const { id: selectedRoleId, segmentStart, segmentEnd, currentlyAllocatedPerson } = roleDetails;
    const selectedResource = roles.find(role => role.id === selectedRoleId);

    if (!selectedResource) return null;

    return (
      <AllocatePerson
        selectedResource={selectedResource}
        selectedProject={selectedProject}
        startDate={segmentStart}
        endDate={segmentEnd}
        currentlyAllocatedPerson={currentlyAllocatedPerson}
        runsInModal
      />
    );
  };

  const renderDateEditor = () => (<EditorModalManager onModalClose={onClose} />);

  const renderContent = () => {
    switch (modalActiveView) {
      case COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PERSON:
        return renderPeopleProfile();
      case COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PROJECT:
        return renderProjectDetails();
      case COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_ALLOCATE_PERSON:
        return renderAllocatePerson();
      case COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_EDIT_PROJECT_DATE:
      case COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_EDIT_PHASE_DATE:
        return renderDateEditor();
      default:
        return null;
    }
  };

  const pending = getPersonByIdPending || getProjectByIdPending;
  const projectName = selectedProject?.name;

  const modalConfig = useMemo(() => {
    const className =
      modalActiveView === COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_EDIT_PROJECT_DATE ||
      modalActiveView === COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_EDIT_PHASE_DATE
        ? 'edit-dates-project-editor-modal'
        : '';

    const showAppBar = [
      COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_ALLOCATE_PERSON,
      COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_EDIT_PROJECT_DATE,
      COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_EDIT_PHASE_DATE,
    ].includes(modalActiveView);

    const maxWidth =
      modalActiveView === COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PROJECT ||
      modalActiveView === COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PERSON
        ? 'lg'
        : 'sm';

    const headline = (() => {
      switch (modalActiveView) {
        case COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_ALLOCATE_PERSON:
          return 'Place a person';
        case COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_EDIT_PROJECT_DATE:
          return `Confirm date changes to ${projectName || 'project'}`;
        case COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_EDIT_PHASE_DATE:
          return `Confirm date changes to ${projectName || 'phase'}`;
        default:
          return '';
      }
    })();

    const showClose = [
      COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_ALLOCATE_PERSON,
      COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_EDIT_PROJECT_DATE,
      COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_EDIT_PHASE_DATE,
      COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PROJECT,
      COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PERSON,
    ].includes(modalActiveView);

    return { className, showAppBar, headline, showClose, maxWidth };
  }, [modalActiveView, projectName]);

  const { className, showAppBar, headline, showClose, maxWidth } = modalConfig;

  return (
    <ManagedModal
      className={className}
      modalId={MULTI_STATE_MODAL_ID}
      pending={pending}
      onClose={onClose}
      showAppBar={showAppBar}
      headline={headline}
      showClose={showClose}
      position={COMMON_MODAL_POSITION_TOP}
      maxWidth={maxWidth}
    >
      <div className="common-multi-state-modal">{renderContent()}</div>
    </ManagedModal>
  );
};

MultiStateModal.propTypes = {
  accountId: PropTypes.number,
  appActiveView: PropTypes.number,
  modalActiveView: PropTypes.string,
  selectedPerson: PropTypes.object,
  selectedProject: PropTypes.object,
  selectedPersonId: PropTypes.number,
  selectedProjectId: PropTypes.number,
  roleDetails: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
  removePerson: PropTypes.func.isRequired,
  getPersonById: PropTypes.func.isRequired,
  getProjectById: PropTypes.func.isRequired,
  getPersonByIdPending: PropTypes.bool.isRequired,
  getProjectByIdPending: PropTypes.bool.isRequired,
  clearPersonSelection: PropTypes.func.isRequired,
  clearProjectSelection: PropTypes.func.isRequired,
  listSelectedPerson: PropTypes.object,
  listSelectedProject: PropTypes.object,
  projectSelectionBackup: PropTypes.object,
  getPersonCurrentAllocations: PropTypes.func.isRequired,
  getPersonPastAllocations: PropTypes.func.isRequired,
  getPersonAllocations: PropTypes.func.isRequired,
  getPersonUpcomingAllocations: PropTypes.func.isRequired,
  getProjectAllocations: PropTypes.func.isRequired,
  setModalActiveView: PropTypes.func.isRequired,
  clearMultiStateModalState: PropTypes.func.isRequired,
  setSelectedProjectId: PropTypes.func.isRequired,
  clearQueries: PropTypes.func.isRequired,
  resetEditorState: PropTypes.func.isRequired,
  permissions: PropTypes.object,
};

MultiStateModal.defaultProps = {
  accountId: null,
  selectedPerson: null,
  selectedProject: null,
  selectedPersonId: null,
  selectedProjectId: null,
  roleDetails: null,
  modalActiveView: COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PERSON,
  appActiveView: PEOPLE_VIEW,
  listSelectedPerson: null,
  listSelectedProject: null,
  projectSelectionBackup: null,
  permissions: null,
};

const mapStateToProps = ({ common, people, projects, login }) => {
  const { accountId, multiStateModal, activeView: appActiveView } = common;
  const { personSelections, getPersonByIdPending } = people;
  const { projectSelections, getProjectByIdPending } = projects;
  const { permissions } = login.userInfo;
  const { selectedPersonId, selectedProjectId, roleDetails, activeView: modalActiveView } = multiStateModal;

  return {
    accountId,
    appActiveView,
    modalActiveView,
    selectedPerson: personSelections[MULTI_STATE_MODAL_ID],
    listSelectedPerson: personSelections[PEOPLE_LIST_SELECTION_ID],
    listSelectedProject: projectSelections[PROJECT_LIST_SELECTION_ID],
    selectedProject: projectSelections[MULTI_STATE_MODAL_ID],
    projectSelectionBackup: projectSelections[PROJECTS_PROJECT_SELECTION_BACKUP],
    selectedPersonId,
    selectedProjectId,
    roleDetails,
    getPersonByIdPending,
    getProjectByIdPending,
    permissions,
  };
};

const mapDispatchToProps = dispatch => ({
  getPersonById: bindActionCreators(getPersonById, dispatch),
  getProjectById: bindActionCreators(getProjectById, dispatch),
  clearPersonSelection: bindActionCreators(clearPersonSelection, dispatch),
  clearProjectSelection: bindActionCreators(clearProjectSelection, dispatch),
  removePerson: bindActionCreators(removePerson, dispatch),
  closeModal: bindActionCreators(closeModal, dispatch),
  getPersonCurrentAllocations: bindActionCreators(getPersonCurrentAllocations, dispatch),
  getPersonPastAllocations: bindActionCreators(getPersonPastAllocations, dispatch),
  getPersonAllocations: bindActionCreators(getPersonAllocations, dispatch),
  getPersonUpcomingAllocations: bindActionCreators(getPersonUpcomingAllocations, dispatch),
  getProjectAllocations: bindActionCreators(getProjectAllocations, dispatch),
  setModalActiveView: bindActionCreators(setModalActiveView, dispatch),
  clearMultiStateModalState: bindActionCreators(clearMultiStateModalState, dispatch),
  setSelectedProjectId: bindActionCreators(setSelectedProjectId, dispatch),
  clearQueries: bindActionCreators(clearQueries, dispatch),
  resetEditorState: bindActionCreators(resetEditorState, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MultiStateModal);
