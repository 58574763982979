import {
  COMMON_SET_ACTIVE_ACCOUNT_ID,
} from './constants';

export function setActiveAccountId(accountId, analyticsPayload) {
  return {
    type: COMMON_SET_ACTIVE_ACCOUNT_ID,
    accountId,
    analyticsPayload,
  };
}

export function reducer(state, action) {
  const { accountId } = action;
  switch (action.type) {
    case COMMON_SET_ACTIVE_ACCOUNT_ID:
      return {
        ...state,
        accountId,
      };

    default:
      return state;
  }
}
