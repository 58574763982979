import moment from 'moment';
import { naturalSort } from 'src/utils/sortUtils';
import { cloneDeep } from '../../utils/miscUtils';
import { getValidatedLocalStorage, validateStoredSort } from '../../utils/validators';
import { MAPPED_PEOPLE_FILTER_NAMES } from '../../filters/constants';

function searchDataset(argument, data) {
  // If the search string is empty, shortcut the whole process and return the original data
  if (!argument || !argument.length) return data;

  const searchString = argument.toLowerCase();

  // At the moment, treat every field like a string.
  const testFilters = fieldValue => String(fieldValue).toLowerCase().indexOf(searchString) !== -1;

  return data.reduce((matchedRows, row) => {
    if (row.rowData.some(testFilters)) {
      matchedRows.push(row);
    }

    return matchedRows;
  }, []);
}

function sortRoles(item) {
  if (item.rowMeta) {
    return item.rowMeta.sortOrder;
  }
  return false;
}

function sortDataset(argument, data) {
  const sortOrder = argument.ascending ? 'asc' : 'desc';

  if (argument.column === 'Role') {
    return naturalSort(data, sortRoles, sortOrder);
  }

  return naturalSort(data, (item) => {
    const value = item.rowMeta.rawData[argument.column];
    const isDuration = moment.isDuration(value);
    return isDuration ? value.as('milliseconds') : value;
  }, sortOrder);
}

function sortTable(sortQuery, data) {
  if (!sortQuery || !sortQuery.args || !sortQuery.args.length) return data;
  return sortDataset(sortQuery.args[0], data);
}

function filterTable(searchQuery, data) {
  if (!searchQuery || !searchQuery.args || !searchQuery.args.length) return data;
  return searchDataset(searchQuery.args[0], data);
}

function getFilterOptions(fieldName, allFields) {
  const field = allFields.find(field => field.name === fieldName);

  if (!field || !field.definedValues || !field.definedValues.length) return [];

  return field.definedValues.map(value => ({ name: value.definedValue, value: value.definedValue }));
}

function getTableConfigFromSchema(schema) {
  return schema.reduce((acc, cur) => {
    if (cur.defaultVisible) acc.defaultVisible.push(cur.displayName);
    if (cur.alwaysVisible) acc.alwaysVisible.push(cur.displayName);
    if (cur.neverVisible) acc.neverVisible.push(cur.displayName);
    return acc;
  }, {
    defaultVisible: [],
    alwaysVisible: [],
    neverVisible: [],
  });
}

const generateVisibleColumns = (columns, visibleColumnNames, alwaysVisible, visibleColumns) => {
  const validatedColumns = columns.map(column => ({
    name: column.name,
    options: {},
    ...column,
  }));

  const validatedVisibleColumns = validatedColumns.filter(column => visibleColumnNames.includes(column.name) || alwaysVisible.includes(column.name));
  const newVisibleColumnNames = validatedVisibleColumns.map(column => column.name);

  let newVisibleColumns = [...validatedVisibleColumns];

  if (visibleColumns && visibleColumns.length) {
    newVisibleColumns = [];
    visibleColumns.forEach((visibleColumn) => {
      const found = validatedVisibleColumns.find(validatedVisibleColumn => validatedVisibleColumn.name === visibleColumn.name);
      if (found) newVisibleColumns.push(found);
    });
  }

  if (newVisibleColumns.length !== validatedVisibleColumns.length) {
    newVisibleColumns = validatedVisibleColumns;
  }

  return { newVisibleColumns, newVisibleColumnNames };
};

const updateColumnFilter = (column, updatedFilters, filterQueryObj) => {
  const { schemaName, name, type, filterType } = column;
  const uniqueColumnName = schemaName || name; // Custom fields have no schema name, and can only be identified by their label
  const prevQuery = filterQueryObj?.args || [];
  const nextQuery = prevQuery.filter(a => a.column !== uniqueColumnName);

  if (updatedFilters.length) {
    const index = prevQuery.findIndex(argument => argument.column === uniqueColumnName);
    const newArgument = {
      activeFilters: updatedFilters,
      column: uniqueColumnName,
      label: MAPPED_PEOPLE_FILTER_NAMES[name] || name,
      type,
      filterType,
    };

    if (index === -1) {
      nextQuery.push(newArgument);
    } else {
      // keep the argument order
      nextQuery.splice(index, 0, newArgument);
    }
  }

  return { name: filterQueryObj?.name || 'filter', args: nextQuery };
};

const updateMultiFilter = (columnLabel, filterList, filterQueryObj, idx) => {
  const chipData = filterQueryObj.args.find(c => c.label === columnLabel.name);
  const newFilter = cloneDeep(chipData.activeFilters);

  if (filterList.length) {
    newFilter[idx].selected = filterList;
  } else {
    newFilter.splice(idx, 1);
  }
  return updateColumnFilter(columnLabel, newFilter, filterQueryObj);
};

const getInitialSort = (storageKey, defaultSortQuery) => {
  const storedSort = getValidatedLocalStorage(storageKey);
  const validSort = validateStoredSort(storedSort);
  return validSort ? storedSort : defaultSortQuery;
};

export {
  filterTable,
  sortTable,
  getFilterOptions,
  getTableConfigFromSchema,
  generateVisibleColumns,
  updateColumnFilter,
  updateMultiFilter,
  getInitialSort,
};
