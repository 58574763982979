import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import deepEqual from 'react-fast-compare';
import { Error, Close, EventBusy } from '@material-ui/icons';
import { Avatar, IconButton, Tooltip, ClickAwayListener } from '@material-ui/core';
import { PERM_WRITE, PERM_ALLOCATION } from 'src/features/permissions/utils/constants';
import { AllocationTimeOffPopper } from 'src/features/allocations';
import { DateEditor } from '../common';
import { DATE_INPUT_FORMAT, DATE_DISPLAY_FORMAT } from '../../common/constants';
import emptyAvatar from '../../images/empty_avatar_blue.svg?url';
import deactivatedAvatar from '../../images/deactivated_avatar.svg?url';
import { Can, EditControls } from '../wrapped-components';
import { MULTI_STATE_MODAL_ID } from '../common/redux/constants';
import { setSelectedPersonId } from '../common/redux/actions';
import { openModal } from '../modal-manager/redux/actions';
import { PROFILE_DETAILS_MODAL_CONTEXT } from '../people/constants';
import { ALLOCATION_END_DATE_UPDATED, ALLOCATION_START_DATE_UPDATED } from '../../analytics/projects/constants';

export class AllocationSinglePopulated extends PureComponent {
  static propTypes = {
    accountId: PropTypes.number.isRequired,
    selectedProject: PropTypes.object.isRequired,
    role: PropTypes.object.isRequired,
    allocation: PropTypes.object.isRequired,
    personId: PropTypes.number.isRequired,
    personName: PropTypes.string.isRequired,
    personTitle: PropTypes.string.isRequired,
    personPhoto: PropTypes.string,
    openPopper: PropTypes.func.isRequired,
    onRowClick: PropTypes.func.isRequired,
    onDeleteAllocationConfirm: PropTypes.func.isRequired,
    onDeleteAllocation: PropTypes.func.isRequired,
    onEditAllocation: PropTypes.func.isRequired,
    editingAllocation: PropTypes.bool.isRequired,
    onAllocationChange: PropTypes.func.isRequired,
    setSelectedPersonId: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    activeModal: PropTypes.string,
    onClickAway: PropTypes.func,
    updateProjectRoleAllocationPending: PropTypes.bool.isRequired,
    getProjectAllocationsPending: PropTypes.bool.isRequired,
    confirmUpdateAllocation: PropTypes.func.isRequired,
    onEditAllocationCancel: PropTypes.func.isRequired,
    runsInModal: PropTypes.bool,
    modalOrigin: PropTypes.string,
    onDeleteAllocationWithoutConfirm: PropTypes.func.isRequired,
  };

  static defaultProps = {
    personPhoto: '',
    activeModal: null,
    onClickAway: () => {},
    runsInModal: false,
    modalOrigin: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      startDate: moment(props.allocation.startDate, DATE_INPUT_FORMAT),
      endDate: moment(props.allocation.endDate, DATE_INPUT_FORMAT),
      fieldEditing: null,
      valid: false,
      touched: false,
      timeOffPopperOpen: false,
      mixpanelPayload: {
        'Project Name': props.selectedProject.name,
        'Project ID': props.selectedProject.id,
        'Project status': props.selectedProject.state,
        'Project type': props.selectedProject.type,
        'Role Name': props.role.name,
        'Role ID': props.role.id,
        'Person Name': props.personName,
        'Person ID': props.allocation.personId,
      },
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { allocation, editingAllocation } = this.props;
    const allocationsChanged = !deepEqual(allocation, nextProps.allocation);
    if (allocationsChanged) {
      this.setState({
        startDate: moment(nextProps.allocation.startDate, DATE_INPUT_FORMAT),
        endDate: moment(nextProps.allocation.endDate, DATE_INPUT_FORMAT),
      });
    }

    if (editingAllocation && !nextProps.editingAllocation) {
      this.setState({
        fieldEditing: null,
        startDate: moment(nextProps.allocation.startDate, DATE_INPUT_FORMAT),
        endDate: moment(nextProps.allocation.endDate, DATE_INPUT_FORMAT),
      });
    }
  }

  timeOffPopperTarget = null;

  onDateError = (error = '') => {
    const { valid, touched } = this.state;
    const isValid = !error.length && touched;
    if (isValid !== valid) {
      this.setState({
        valid: isValid,
      });
    }
  }

  onEditAllocation = override => () => {
    const { onEditAllocation, allocation } = this.props;
    this.setState({
      fieldEditing: override,
    });
    onEditAllocation(allocation);
  }

  onStartDateChange = (date) => {
    const { onAllocationChange, modalOrigin, runsInModal } = this.props;
    const { mixpanelPayload } = this.state;
    this.setState({
      startDate: moment(date),
      touched: true,
    });

    const updatedFrom = modalOrigin || `Project Details${runsInModal ? ' Modal' : ''}`;

    // Mixpanel - Track Deleting Allocations
    window.mixpanel.track('Allocation Updated', {
      ...mixpanelPayload,
      [ALLOCATION_START_DATE_UPDATED]: true,
      [ALLOCATION_END_DATE_UPDATED]: false,
      'Allocated Person Updated': false,
      'Allocation updated from': updatedFrom,
    });

    onAllocationChange({ startDate: moment(date) });
  }

  onEndDateChange = (date) => {
    const { onAllocationChange, modalOrigin, runsInModal } = this.props;
    const { mixpanelPayload } = this.state;
    this.setState({
      endDate: moment(date),
      touched: true,
    });

    const updatedFrom = modalOrigin || `Project Details${runsInModal ? ' Modal' : ''}`;

    // Mixpanel - Track Deleting Allocations
    window.mixpanel.track('Allocation Updated', {
      ...mixpanelPayload,
      [ALLOCATION_START_DATE_UPDATED]: false,
      [ALLOCATION_END_DATE_UPDATED]: true,
      'Allocated Person Updated': false,
      'Allocation updated from': updatedFrom,
    });

    onAllocationChange({ endDate: moment(date) });
  }

  handleRowClick = () => {
    const { allocation, onRowClick, personId, personName, personTitle, personPhoto } = this.props;
    const selectedPerson = {
      id: personId,
      name: personName,
      title: personTitle,
      photoUrl: personPhoto,
    };
    onRowClick(allocation.startDate, allocation.endDate, selectedPerson);
  }

  handleDeleteAllocation = () => {
    const {
      accountId,
      selectedProject,
      role,
      allocation,
      personName,
      onDeleteAllocationConfirm,
      onDeleteAllocation,
      runsInModal,
      onDeleteAllocationWithoutConfirm,
      onEditAllocation,
    } = this.props;
    const { mixpanelPayload } = this.state;

    // set selected allocation as the one being changed in ProjectRole.js
    onEditAllocation(allocation);

    const { personId, startDate, endDate } = allocation;
    const { isCommunicated } = role;

    const data = {
      roleId: role.id,
      personId,
      startDate,
      endDate,
    };

    const deleteAllocation = () => {
      const deletedFrom = `Project Details${runsInModal ? ' Modal' : ''}`;
      window.mixpanel.track('Allocation Deleted', {
        ...mixpanelPayload,
        'Allocation deleted from': deletedFrom,
      });
      onDeleteAllocation(accountId, selectedProject.id, data);
    };

    // role has NOT been communicated and allocation starts in the future -> remove without Confirmation Dialog and NotifyModal
    if (!isCommunicated && moment(startDate).isAfter()) {
      return deleteAllocation();
    }

    // role has been communicated and allocation starts in the future -> show NotifyModal
    if (isCommunicated && moment(startDate).isAfter()) {
      return onDeleteAllocationWithoutConfirm(deleteAllocation);
    }

    // allocation started in the past (role status is irrelevant) -> show Confirmation Dialog
    return onDeleteAllocationConfirm(personName, deleteAllocation);
  }

  onCancel = () => {
    const { onEditAllocationCancel, allocation } = this.props;
    const { startDate, endDate } = allocation;
    this.setState({
      startDate: moment(startDate, DATE_INPUT_FORMAT),
      endDate: moment(endDate, DATE_INPUT_FORMAT),
    });
    onEditAllocationCancel();
  }

  onClickAway = () => {
    const { onClickAway } = this.props;
    const { touched } = this.state;
    if (!touched) {
      onClickAway();
    }
  }

  handleTimeOffClick = ({ currentTarget }) => {
    this.timeOffPopperTarget = currentTarget;
    this.setState({
      timeOffPopperOpen: true,
    });
  }

  onPopperClose = () => {
    this.timeOffPopperTarget = null;

    this.setState({
      timeOffPopperOpen: false,
    });
  };

  onVisitProfileClick = (event) => {
    const {
      personName,
      personId,
      setSelectedPersonId,
      activeModal,
      openModal,
      runsInModal,
    } = this.props;

    event.preventDefault();
    const analyticsPayload = {
      runsInModal,
      data: {
        personName,
        personId,
        to: PROFILE_DETAILS_MODAL_CONTEXT,
        from: 'Project Details Modal',
      },
    };
    setSelectedPersonId(personId, false, analyticsPayload);

    if (!activeModal || activeModal !== MULTI_STATE_MODAL_ID) {
      openModal(MULTI_STATE_MODAL_ID);
      window.mixpanel.track('Profile Details Modal Displayed', {
        'Person Name': personName,
        'Person ID': personId,
        'Launched from': 'Project Details',
      });
    }
  }

  RenderIdentity = () => {
    const { allocation, personName, personTitle } = this.props;

    if (allocation.state && allocation.state === 'Deactivated') {
      return (
        <div className="person-name-container">
          <div className="person-identity">
            <span className="person-name" title={allocation.name}>{allocation.name}</span>
            <span className="person-title deactivated-title" title="Deactivated">Deactivated</span>
          </div>
        </div>
      );
    }

    return (
      <Can
        action={PERM_WRITE}
        subject={PERM_ALLOCATION}
        yes={(
          <button
            type="button"
            className="person-name-container"
            onClick={this.handleRowClick}
          >
            <div className="person-identity">
              <span className="person-name" title={personName}>{personName}</span>
              <span className="person-title" title={personTitle}>{personTitle}</span>
            </div>
          </button>
        )}
        no={(
          <div className="person-name-container">
            <div className="person-identity">
              <span className="person-name" title={personName}>{personName}</span>
              <span className="person-title" title={personTitle}>{personTitle}</span>
            </div>
          </div>
        )}
      />
    );
  }

  RenderAvatar = () => {
    const { allocation, personPhoto, accountId } = this.props;

    if (allocation.state && allocation.state === 'Deactivated') {
      return (
        <div className="person-photo">
          <Avatar className="avatar" src={deactivatedAvatar} />
        </div>
      );
    }

    return (
      <div className="person-photo">
        <Tooltip title="Visit Profile" placement="top">
          <Link to={`/accounts/${accountId}/people/${allocation.personId}`} onClick={this.onVisitProfileClick}>
            <Avatar className="avatar" src={!personPhoto ? emptyAvatar : personPhoto} />
          </Link>
        </Tooltip>
      </div>
    );
  }

  renderEditControls = () => {
    const {
      updateProjectRoleAllocationPending,
      getProjectAllocationsPending,
      confirmUpdateAllocation,
    } = this.props;
    const { valid } = this.state;

    return (
      <EditControls
        primaryAction={confirmUpdateAllocation}
        secondaryAction={this.onCancel}
        disabled={!valid}
        primaryText="Save"
        pending={updateProjectRoleAllocationPending || getProjectAllocationsPending}
      />
    );
  }

  render() {
    const { role, allocation, openPopper, editingAllocation, runsInModal } = this.props;
    const { startDate, endDate, fieldEditing, timeOffPopperOpen } = this.state;
    const startDateFormatted = moment(allocation.startDate, DATE_INPUT_FORMAT).format(DATE_DISPLAY_FORMAT);
    const endDateFormatted = moment(allocation.endDate, DATE_INPUT_FORMAT).format(DATE_DISPLAY_FORMAT);

    return (
      <div className="allocations-allocation-single-populated single-row">
        <div className="person-info populated">
          <this.RenderAvatar />
          <this.RenderIdentity />
          <Can
            action={PERM_WRITE}
            subject={PERM_ALLOCATION}
            yes={(
              <Tooltip title="Remove person" placement="top">
                <IconButton className="person-name-delete" data-person-id={allocation.personId} onClick={this.handleDeleteAllocation}>
                  <Close fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )}
          />
        </div>
        <div className="start-date" title={startDateFormatted}>
          <Can
            action={PERM_WRITE}
            subject={PERM_ALLOCATION}
            yes={(
              <ClickAwayListener onClickAway={this.onClickAway} mouseEvent={fieldEditing === 'startDate' ? 'onClick' : false}>
                <div className="field-container">
                  <DateEditor
                    date={startDate}
                    minDate={moment(role.startDate, DATE_INPUT_FORMAT)}
                    minDateMessage="Start date cannot be earlier than the role start date"
                    maxDate={endDate}
                    maxDateMessage="Start date cannot be later than the allocation end date"
                    onChange={this.onStartDateChange}
                    editMode={editingAllocation}
                    onEditToggle={this.onEditAllocation('startDate')}
                    editIconTooltip="Edit allocation start date"
                    onError={this.onDateError}
                  />
                  {fieldEditing === 'startDate' && this.renderEditControls()}
                </div>
              </ClickAwayListener>
            )}
            no={<span>{startDateFormatted}</span>}
          />
        </div>
        <div className="allocation-visualization" />
        <div className="end-date" title={endDateFormatted}>
          <Can
            action={PERM_WRITE}
            subject={PERM_ALLOCATION}
            yes={(
              <ClickAwayListener onClickAway={this.onClickAway} mouseEvent={fieldEditing === 'endDate' ? 'onClick' : false}>
                <div className="field-container">
                  <DateEditor
                    date={endDate}
                    minDate={startDate}
                    minDateMessage="End date cannot be earlier than the allocation start date"
                    maxDate={moment(role.endDate, DATE_INPUT_FORMAT)}
                    maxDateMessage="End date cannot be later than the role end date"
                    onChange={this.onEndDateChange}
                    editMode={editingAllocation}
                    onEditToggle={this.onEditAllocation('endDate')}
                    editIconTooltip="Edit allocation end date"
                    onError={this.onDateError}
                  />
                  {fieldEditing === 'endDate' && this.renderEditControls()}
                </div>
              </ClickAwayListener>
            )}
            no={<span>{endDateFormatted}</span>}
          />
        </div>
        <div className="allocation-percentage">
          { allocation?.upcomingTimeOff?.length > 0 && (
            <Tooltip title="View time off" placement="top">
              <IconButton
                className="time-off-button"
                onClick={this.handleTimeOffClick}
                disableRipple
              >
                <EventBusy />
              </IconButton>
            </Tooltip>
          )}
          { allocation.hasConflict && (
            <Tooltip title="View issue details" placement="top">
              <button
                className="issues"
                type="button"
                data-person-id={allocation.personId}
                data-start-date={allocation.startDate}
                data-end-date={allocation.endDate}
                onClick={openPopper}
              >
                <Error className="issues-icon" />
              </button>
            </Tooltip>
          )}
        </div>
        { timeOffPopperOpen && (
          <AllocationTimeOffPopper
            onPopperClose={this.onPopperClose}
            open={timeOffPopperOpen}
            target={this.timeOffPopperTarget}
            allocation={allocation}
            runsInModal={runsInModal}
          />
        )}
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps({ common, modalManager }) {
  const { accountId, multiStateModal } = common;
  const { activeModal } = modalManager;
  const { modalOrigin } = multiStateModal;
  return {
    accountId,
    activeModal,
    modalOrigin,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSelectedPersonId: bindActionCreators(setSelectedPersonId, dispatch),
    openModal: bindActionCreators(openModal, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AllocationSinglePopulated);
