import { gql } from 'apollo-boost';
import { MAX_SIZE, MIN_FROM } from '../../../common/constants';
import { PURSUIT_QUERY_FILTER, PURSUIT_QUERY_SORT } from '../constants';

const PURSUIT_PROJECTS_QUERY = gql`
  query pursuitProjectsQuery($accountId: Int!, $structuredSort: [ProjectSort], $from: Int, $size: Int, $filters: [ESQuery]){
    projects(accountId: $accountId, structuredSort: $structuredSort, from: $from, size: $size, filters: $filters ) {
      count
      items {
        id
        name
        colour
        startDate
        endDate
      }
    }
  }
`;

const getPursuitProjectsQuery = (accountId) => {
  const variables = {
    accountId,
    filters: PURSUIT_QUERY_FILTER,
    structuredSort: PURSUIT_QUERY_SORT,
    size: MAX_SIZE,
    from: MIN_FROM,
  };

  return {
    query: PURSUIT_PROJECTS_QUERY,
    variables,
  };
};

export {
  getPursuitProjectsQuery,
};
