import {
  WRAPPED_COMPONENTS_CLEAR_PLACE_SUGGESTIONS,
} from './constants';

export function clearPlaceSuggestions() {
  return {
    type: WRAPPED_COMPONENTS_CLEAR_PLACE_SUGGESTIONS,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case WRAPPED_COMPONENTS_CLEAR_PLACE_SUGGESTIONS:
      return {
        ...state,
        placeSuggestions: {
          suggestions: [],
          status: null,
          isAddressSuggestionSelected: false,
        },
      };

    default:
      return state;
  }
}
