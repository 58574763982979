import initialState from './initialState';
import { reducer as setFilterListAnchorElIdReducer } from './setFilterListAnchorElId';
import { reducer as setFilterAnchorElIdReducer } from './setFilterAnchorElId';
import { reducer as setFilterMenuVisibilityReducer } from './setFilterMenuVisibility';
import { reducer as setPopperIsOpenReducer } from './setPopperIsOpen';
import { reducer as setProjectFilterDataReducer } from './setProjectFilterData';
import { reducer as setPeopleFilterDataReducer } from './setPeopleFilterData';
import { reducer as setHourlyPersonFilterDataReducer } from './setHourlyPersonFilterData';

const reducers = [
  setFilterListAnchorElIdReducer,
  setFilterAnchorElIdReducer,
  setFilterMenuVisibilityReducer,
  setPopperIsOpenReducer,
  setProjectFilterDataReducer,
  setPeopleFilterDataReducer,
  setHourlyPersonFilterDataReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((state, reducer) => reducer(state, action), newState);
}
