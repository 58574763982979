import pluralize from 'pluralize';
import {
  FORECAST_UPDATE_ANALYTICS_PAYLOAD,
} from './constants';

export function updateForecastAnalyticsPayload(overviewRange, trueValue, breakdownField, dataTypeSelected, queries) {
  const filteredBy = queries?.filter?.args
    ? queries.filter.args.map(filter => filter.label)
    : [];

  return {
    type: FORECAST_UPDATE_ANALYTICS_PAYLOAD,
    data: {
      'Timeframe selected': pluralize('year', overviewRange, true),
      'Calculation selected': trueValue ? 'True' : 'Average',
      'Breakdown property selected': breakdownField?.name ? breakdownField.name : 'Allocation Overview',
      'Fields filtered by': filteredBy.join(', '),
      'Data type selected': dataTypeSelected,
    },
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FORECAST_UPDATE_ANALYTICS_PAYLOAD:
      return {
        ...state,
        analyticsPayload: action.data,
      };

    default:
      return state;
  }
}
