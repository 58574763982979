import { gql } from 'apollo-boost';
import { MIN_FROM, AUDIT_PAGE_SIZE } from '../../../common/constants';

const PROJECT_ENTRY = gql`
  fragment ProjectEntry on ProjectEntry {
    id
    name
    colour
    startDate
    endDate
    state
    type
    fields {
      fieldId
      fieldName
      values
    }
  }
`;

const PHASE_ENTRY = gql`
  fragment PhaseEntry on PhaseEntry {
    data {
      id
      name
      startDate
      endDate
      subPhases {
        id
        name
        startDate
        endDate
      }
    }
  }
`;

const SUBPHASE_ENTRY = gql`
  fragment SubPhaseEntry on SubPhaseEntry {
    data{
      id
      name
      startDate
      endDate
    }
  }
`;

const FIELD_ENTRY = gql`
  fragment ProjectFieldEntry on FieldEntry {
    data {
      fieldId
      fieldName
      fieldType
      values
    }
  }
`;

const ROLE_ENTRY = gql`
  fragment RoleEntry on RoleEntry {
    id
    nameId
    name
    startDate
    endDate
    isFilled
    allocations {
      startDate
      endDate
      allocatedPercent
    }
    unfilledRanges {
      startDate
      endDate
    }
    note
    notification {
      notifiedOn
      recipientIds
    }
    isCommunicated
  }
`;

const BULK_ROLE_ENTRY = gql`
  fragment BulkRoleEntry on BulkRoleEntry {
    data {
      ...RoleEntry
    }
  }
`;

const ALLOCATION_ENTRY = gql`
  fragment AllocationEntry on AllocationEntry {
    personId
    person {
      id
      email
      name
      title
      photoUrl
      state
    }
    role {
      ...RoleEntry
    }
    allocations {
      startDate
      endDate
      allocatedPercent
    }
  }
`;

const PROJECT_NOTE_ENTRY = gql`
  fragment ProjectNoteEntry on ProjectNoteEntry {
    id
    message
    isPrivate
    creatorId
    creatorName
    createdOn
    lastModifiedOn
  }
`;

const PROJECT_AUDIT_QUERY = gql`
  ${PROJECT_ENTRY}
  ${PHASE_ENTRY}
  ${SUBPHASE_ENTRY}
  ${FIELD_ENTRY}
  ${ROLE_ENTRY}
  ${BULK_ROLE_ENTRY}
  ${ALLOCATION_ENTRY}
  ${PROJECT_NOTE_ENTRY}
  query projectAuditQuery($accountId: Int!, $projectId: Int!, $from: Int, $size: Int) {
    projectAudit(
      accountId: $accountId
      projectId: $projectId
      from: $from
      size: $size
    ) {
      hasMore
      cursor
      items {
        eventOn
        userId
        userName
        resourceId
        resourceType
        actionType
        eventData {
          ... on ProjectEntry { ...ProjectEntry }
          ... on PhaseEntry { ...PhaseEntry }
          ... on SubPhaseEntry { ...SubPhaseEntry }
          ... on FieldEntry { ...ProjectFieldEntry }
          ... on RoleEntry { ...RoleEntry }
          ... on BulkRoleEntry { ...BulkRoleEntry }
          ... on AllocationEntry { ...AllocationEntry }
          ... on ProjectNoteEntry { ...ProjectNoteEntry }
        }
      }
    }
  }
`;

export const getProjectAuditQuery = (accountId, projectId, from = MIN_FROM) => {
  const variables = {
    accountId,
    projectId,
    size: AUDIT_PAGE_SIZE,
    from,
  };

  return {
    query: PROJECT_AUDIT_QUERY,
    variables,
  };
};
