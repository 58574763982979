import { call, put, takeLatest } from 'redux-saga/effects';
import { getNewPeopleListEntity } from 'src/features/people/queries/getPeopleListQuery';
import moment from 'moment';
import {
  DATE_INPUT_FORMAT,
  DEFAULT_POU_START,
  DEFAULT_POU_END,
  PRE_EMPLOYMENT,
  POST_EMPLOYMENT,
} from 'src/common/constants';
import { NO_AVAILABILITY, FULL_AVAILABILITY } from 'src/filters/constants';
import {
  PEOPLE_ADD_PERSON_BEGIN,
  PEOPLE_ADD_PERSON_SUCCESS,
  PEOPLE_ADD_PERSON_FAILURE,
  PEOPLE_ADD_PERSON_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost, protectedJsonGet } from '../../../utils/api';
import { normalizeFields } from '../../../utils/normalizers';
import { AVAILABILITY, UNAVAILABILITY } from '../../gantt/people-gantt-v2/constants';

export function addPerson(accountId, data, modalOrigin, cb) {
  return {
    type: PEOPLE_ADD_PERSON_BEGIN,
    accountId,
    data,
    modalOrigin,
    cb,
  };
}

export function dismissAddPersonError() {
  return {
    type: PEOPLE_ADD_PERSON_DISMISS_ERROR,
  };
}

export function* doAddPerson(action) {
  const { accountId, data, modalOrigin, cb } = action;

  let person;
  try {
    person = yield call(protectedJsonPost, `accounts/${accountId}/persons`, data);
  } catch (err) {
    yield put({
      type: PEOPLE_ADD_PERSON_FAILURE,
      data: { error: err },
    });
    return;
  }

  let availabilities;
  try {
    availabilities = yield call(protectedJsonGet, `accounts/${accountId}/persons/availabilities`);
  } catch (err) {
    yield put({
      type: PEOPLE_ADD_PERSON_FAILURE,
      data: { error: err },
    });
    return;
  }

  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: PEOPLE_ADD_PERSON_SUCCESS,
    person,
    availabilities,
    fields: data.fields,
    modalOrigin,
  });

  if (cb) {
    cb(person);
  }
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchAddPerson() {
  yield takeLatest(PEOPLE_ADD_PERSON_BEGIN, doAddPerson);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_ADD_PERSON_BEGIN:
      return {
        ...state,
        addPersonPending: true,
        addPersonError: null,
      };

    case PEOPLE_ADD_PERSON_SUCCESS: {
      const { person, availabilities, fields } = action;
      const { employmentDates } = person;
      const newPeopleList = [...state.entities].concat(person);
      const newPersonAvailability = availabilities.find(availability => availability.personId === person.id);
      const { availableInDays } = newPersonAvailability || { availableInDays: NO_AVAILABILITY };
      const availableFor = FULL_AVAILABILITY;
      const percentAvailableToday = 100;
      const unavailabilities = [];
      const normalizedFields = normalizeFields(fields);

      if (employmentDates) {
        const { startDate, endDate } = employmentDates;
        if (startDate) {
          unavailabilities.push({
            description: null,
            startDate: DEFAULT_POU_START,
            endDate: moment(startDate).subtract(1, 'day').format(DATE_INPUT_FORMAT),
            isPrivate: false,
            rangeType: PRE_EMPLOYMENT,
            __typename: UNAVAILABILITY,
          });
        }
        if (endDate) {
          unavailabilities.push({
            description: null,
            startDate: moment(endDate).add(1, 'day').format(DATE_INPUT_FORMAT),
            endDate: DEFAULT_POU_END,
            isPrivate: false,
            rangeType: POST_EMPLOYMENT,
            __typename: UNAVAILABILITY,
          });
        }
      }

      const newPeopleListEntity = getNewPeopleListEntity({
        ...person,
        availableInDays,
        availableFor,
        percentAvailableToday,
        unavailabilities,
        availabilities: [{
          startDate: DEFAULT_POU_START,
          endDate: DEFAULT_POU_END,
          percent: 100,
          __typename: AVAILABILITY,
        }],
      }, normalizedFields);

      return {
        ...state,
        entities: newPeopleList,
        personFieldValues: [
          ...state.personFieldValues,
          {
            ...person,
            personId: person.id,
            fields: normalizedFields,
          },
        ],
        availabilities,
        addPersonPending: false,
        addPersonError: null,
        filteredPeople: [
          ...state.filteredPeople,
          newPeopleListEntity,
        ],
      };
    }

    case PEOPLE_ADD_PERSON_FAILURE:
      return {
        ...state,
        addPersonPending: false,
        addPersonError: action.data.error,
      };

    case PEOPLE_ADD_PERSON_DISMISS_ERROR:
      return {
        ...state,
        addPersonError: null,
      };

    default:
      return state;
  }
}
