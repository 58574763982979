const initialState = {
  entities: [],
  deactivatedPeople: [],
  allocations: [],
  availabilities: [],
  unavailabilities: [],
  currentProjects: [],
  currentAllocations: {},
  personAllocations: {},
  upcomingAllocations: {},
  issues: {},
  moreCurrentAllocations: false,
  morePastAllocations: false,
  moreAnyAllocations: false,
  pastAllocations: {},
  personFieldValues: [],
  personAllocationsByDate: {},
  personAttachments: {},
  personCertifications: {},
  filteredPeople: [],
  searchedActivePeople: [],
  pageFrom: 0,
  hasMore: true,
  personSelections: {},
  getPeoplePending: false,
  getPeopleError: null,
  removePersonPending: false,
  removePersonError: null,
  addPersonPending: false,
  addPersonError: null,
  getPersonFieldValuesPending: false,
  getPersonFieldValuesError: null,
  getPersonAvailabilitiesPending: false,
  getPersonAvailabilitiesError: null,
  getPersonProjectsPending: false,
  getPersonProjectsError: null,
  getPersonAllocationsByDatePending: false,
  getPersonAllocationsByDateError: null,
  getPersonCurrentAllocationsPending: false,
  getPersonCurrentAllocationsError: null,
  getPersonPastAllocationsPending: false,
  getPersonPastAllocationsError: null,
  getPersonIssuesPending: false,
  getPersonIssuesError: null,
  getAccountAllocationsPending: false,
  getAccountAllocationsError: null,
  getUploadUrlPending: false,
  getUploadUrlError: null,
  setProfilePicturePending: false,
  setProfilePictureError: null,
  removeProfilePicturePending: false,
  removeProfilePictureError: null,
  updatePersonPending: false,
  updatePersonError: null,
  getFilteredPeoplePagePending: false,
  getFilteredPeoplePending: false,
  getFilteredPeopleError: null,
  getFilteredPeoplePageError: null,
  reactivatePersonPending: false,
  reactivatePersonError: null,
  collectPeopleTableDataPending: false,
  collectPeopleTableDataError: null,
  updatePersonFieldValuePending: false,
  updatePersonFieldValueError: null,
  addUnavailabilityPending: false,
  addUnavailabilityError: null,
  updateUnavailabilityPending: false,
  updateUnavailabilityError: null,
  deleteUnavailabilityPending: false,
  deleteUnavailabilityError: null,
  getPersonUnavailabilitiesPending: false,
  getPersonUnavailabilitiesError: null,
  getPersonByIdPending: false,
  getPersonByIdError: null,
  getDeactivatedPeoplePending: false,
  getDeactivatedPeopleError: null,
  searchActivePeoplePending: false,
  searchActivePeopleError: null,
  getPersonAllocationsPending: false,
  getPersonAllocationsError: null,
  getPersonUpcomingAllocationsPending: false,
  getPersonUpcomingAllocationsError: null,
  addPersonNotePending: false,
  addPersonNoteError: null,
  deletePersonNotePending: false,
  deletePersonNoteError: null,
  updatePersonNotePending: false,
  updatePersonNoteError: null,
  getPersonAuditPending: false,
  getPersonAuditError: null,
  uploadPersonAttachmentPending: false,
  uploadPersonAttachmentError: null,
  getPersonAttachmentsPending: false,
  getPersonAttachmentsError: null,
  getPersonAttachmentByIdPending: false,
  getPersonAttachmentByIdError: null,
  downloadPersonAttachmentPending: false,
  downloadPersonAttachmentError: null,
  deletePersonAttachmentPending: false,
  deletePersonAttachmentError: null,
  getPersonCertificationsPending: false,
  getPersonCertificationsError: null,
  getPersonCertificationAttachmentsPending: false,
  getPersonCertificationAttachmentsError: null,
  addPersonCertificationPending: false,
  addPersonCertificationError: null,
  updatePersonCertificationPending: false,
  updatePersonCertificationError: null,
  deletePersonCertificationPending: false,
  deletePersonCertificationError: null,
};

export default initialState;
