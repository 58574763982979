import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogActions,
  AppBar,
  Typography,
  Toolbar,
} from '@material-ui/core';
import { COMMON_MODAL_POSITION_MIDDLE, COMMON_MODAL_POSITION_TOP } from './redux/constants';
import CloseButton from './CloseButton';

export default class Modal extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    headline: PropTypes.string.isRequired,
    closeModal: PropTypes.func,
    buttons: PropTypes.arrayOf(PropTypes.node),
    classes: PropTypes.object,
    className: PropTypes.string,
    showClose: PropTypes.bool,
    maxWidth: PropTypes.string,
    open: PropTypes.bool,
    disableEnforceFocus: PropTypes.bool,
    showAppBar: PropTypes.bool,
    position: PropTypes.string,
    fullHeight: PropTypes.bool,
    closeTooltipTitle: PropTypes.string,
    closeTooltipDelay: PropTypes.number,
  };

  static defaultProps = {
    children: null,
    showClose: true,
    buttons: [],
    classes: {},
    className: '',
    maxWidth: 'sm',
    closeModal: () => {},
    open: true,
    disableEnforceFocus: false,
    showAppBar: true,
    position: COMMON_MODAL_POSITION_MIDDLE,
    fullHeight: false,
    closeTooltipTitle: '',
    closeTooltipDelay: 100,
  };

  renderCloseButton = () => {
    const { closeModal, showAppBar, showClose, closeTooltipTitle, closeTooltipDelay } = this.props;
    const color = !showAppBar && showClose ? 'default' : 'inherit';
    return (
      <CloseButton
        onClick={closeModal}
        color={color}
        closeTooltipTitle={closeTooltipTitle}
        closeTooltipDelay={closeTooltipDelay}
        className="modal-close-button"
      />
    );
  }

  renderAppBar = () => {
    const { headline, showClose, showAppBar } = this.props;

    if (!showAppBar) {
      if (showClose) {
        return this.renderCloseButton();
      }
      return null;
    }

    return (
      <AppBar position="static" color="primary" className="header">
        <Toolbar className="dialog__header">
          <Typography color="inherit" component="h4" variant="h6">
            {headline}
          </Typography>
          {showClose && this.renderCloseButton()}
        </Toolbar>
      </AppBar>
    );
  }

  render() {
    const { children, buttons, classes, maxWidth, className, open, disableEnforceFocus, position, fullHeight } = this.props;
    const positionClass = position === COMMON_MODAL_POSITION_TOP ? { scrollPaper: 'position-top' } : {};
    const sizeClass = fullHeight ? { paperScrollPaper: 'full-height' } : {};

    return (
      <Dialog
        classes={{ root: 'common-modal', paperWidthSm: 'dialog__container', ...positionClass, ...sizeClass, ...classes }}
        className={className}
        open={open}
        fullWidth
        maxWidth={maxWidth}
        disableEnforceFocus={disableEnforceFocus}
      >
        { this.renderAppBar() }

        <DialogContent className="common-content">{children}</DialogContent>

        { buttons && buttons.length > 0 && (
          <DialogActions classes={{ root: 'dialog__actions' }} disableSpacing>
            {buttons}
          </DialogActions>
        )}

      </Dialog>
    );
  }
}
