import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { HOME_ADDRESS_FIELD } from 'src/common/constants';

const BestMatchListItem = ({
  person,
  isSelected,
  onSelectPerson,
  type,
}) => {
  const { name, skillSet } = person;

  const onSelect = () => {
    onSelectPerson(person);
  };

  const renderContent = () => (
    <>
      <div className="name">{name}</div>
      <div className="skillset">{skillSet?.join(', ')}</div>
    </>
  );

  const renderSelectedContent = () => {
    const address = person.fields?.find(({ name }) => name === HOME_ADDRESS_FIELD);

    return (
      <>
        <div className="name">{name}</div>
        <div className="selected-title">Skill set</div>
        <div className="skillset">{skillSet?.join(', ')}</div>
        <div className="selected-title">Home address</div>
        <div>{address?.values?.[0] || ''}</div>
      </>
    );
  };

  return (
    <div
      className={classNames('self-perform-best-match-list-item', type, { selected: isSelected })}
      onClick={onSelect}
      role="presentation"
    >
      {isSelected
        ? renderSelectedContent()
        : renderContent()}
    </div>
  );
};

BestMatchListItem.propTypes = {
  person: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelectPerson: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default BestMatchListItem;
