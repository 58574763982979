const initialState = {
  awardedOnlyOverview: {},
  awardedOnlyBreakdown: {},
  scenarioOverview: {},
  scenarioBreakdown: {},
  workforceDemand: [],
  workforceSupply: [],
  workforcePursuits: [],
  analyticsPayload: {},
  pursuitProjects: [],
  benchCostOverview: {},
  benchCostIndividuals: [],
  getUtilizationDataPending: false,
  getUtilizationDataError: null,
  getBreakdownPending: false,
  getBreakdownError: null,
  getWorkforcePlanPending: false,
  getWorkforcePlanError: null,
  getPursuitProjectsPending: false,
  getPursuitProjectsError: null,
  getBenchCostOverviewPending: false,
  getBenchCostOverviewError: null,
  getBenchCostIndividualsPending: false,
  getBenchCostIndividualsError: null,
};

export default initialState;
