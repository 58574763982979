import { call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { protectedJsonDelete } from '../../../utils/api';
import {
  PROJECTS_DELETE_PROJECT_ALLOCATION_BEGIN,
  PROJECTS_DELETE_PROJECT_ALLOCATION_SUCCESS,
  PROJECTS_DELETE_PROJECT_ALLOCATION_FAILURE,
  PROJECTS_DELETE_PROJECT_ALLOCATION_DISMISS_ERROR,
} from './constants';
import { DATE_INPUT_FORMAT } from '../../../common/constants';

export function deleteProjectAllocation(accountId, projectId, data, analyticsPayload = null) {
  // If need to pass args to saga, pass it with the begin action.
  return {
    type: PROJECTS_DELETE_PROJECT_ALLOCATION_BEGIN,
    accountId,
    projectId,
    data,
    analyticsPayload,
  };
}

export function dismissDeleteProjectAllocationError() {
  return {
    type: PROJECTS_DELETE_PROJECT_ALLOCATION_DISMISS_ERROR,
  };
}

export function* doDeleteProjectAllocation(action) {
  const { accountId, projectId, data } = action;
  try {
    yield call(protectedJsonDelete, `accounts/${accountId}/projects/${projectId}/allocations`, data);
  } catch (err) {
    yield put({
      type: PROJECTS_DELETE_PROJECT_ALLOCATION_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PROJECTS_DELETE_PROJECT_ALLOCATION_SUCCESS,
    projectId,
    data,
  });
}

export function* watchDeleteProjectAllocation() {
  yield takeLatest(PROJECTS_DELETE_PROJECT_ALLOCATION_BEGIN, doDeleteProjectAllocation);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PROJECTS_DELETE_PROJECT_ALLOCATION_BEGIN:
      return {
        ...state,
        deleteProjectAllocationPending: true,
        deleteProjectAllocationError: null,
      };

    case PROJECTS_DELETE_PROJECT_ALLOCATION_SUCCESS: {
      const { projectId, data } = action;
      let { projectAllocations, expandedAllocations } = state;

      if (projectAllocations[projectId]) {
        const relevantRole = projectAllocations[projectId].find(a => a.roleId === data.roleId);
        if (relevantRole) {
          const allocations = relevantRole.allocations.filter(a => !((a.startDate === data.startDate) && (a.endDate === data.endDate)));
          const newRole = {
            ...relevantRole,
            allocations,
          };
          projectAllocations = {
            ...projectAllocations,
            [projectId]: [
              // a person could be allocated to the role more than once,
              // so need to filter out by roleId and starDate to make sure the right person is removed
              ...projectAllocations[projectId].filter(r => r.roleId !== data.roleId && r.startDate === data.startDate),
              newRole,
            ],
          };
        }
      }

      const relevantAllocation = expandedAllocations.find(al => al.projectId === projectId);
      if (relevantAllocation) {
        const relevantRole = relevantAllocation.roles.find(role => role.roleId === data.roleId);
        if (relevantRole) {
          const allocations = relevantRole.allocations.filter(a => !(
            (a.startDate === moment(data.startDate).format(DATE_INPUT_FORMAT)) &&
            (a.endDate === moment(data.endDate).format(DATE_INPUT_FORMAT))
          ));
          const newRole = {
            ...relevantRole,
            allocations,
          };
          expandedAllocations = [
            ...expandedAllocations.filter(al => al.projectId !== projectId),
            {
              ...relevantAllocation,
              roles: [
                ...relevantAllocation.roles.filter(role => role.roleId !== data.roleId),
                newRole,
              ],
            },
          ];
        }
      }

      const { projectSelections } = state;
      const newProjectSelections = { ...projectSelections };
      Object.keys(newProjectSelections).forEach((selectionId) => {
        const selectedProject = newProjectSelections[selectionId];
        if (selectedProject && selectedProject.id === projectId) {
          const { unfilledRoles, roles } = selectedProject;
          let newUnfilledRoles = unfilledRoles;

          // Update roles and allocations
          const newRoles = roles.map((role) => {
            const newRole = { ...role };
            if (role.id === data.roleId) {
              newUnfilledRoles = unfilledRoles >= 0 && role?.isFilled ? unfilledRoles + 1 : unfilledRoles;
              const updatedRole = projectAllocations[projectId].find(role => role.roleId === data.roleId);

              newRole.allocations = [...updatedRole.allocations];

              // If the communicated role becomes completely unfilled, reset the communication/notification status
              if (newRole.isCommunicated && !newRole.allocations.length) {
                newRole.isCommunicated = false;
                newRole.notification = null;
              }
            }
            return newRole;
          });

          newProjectSelections[selectionId] = {
            ...selectedProject,
            roles: newRoles,
            unfilledRoles: newUnfilledRoles,
          };
        }
      });

      return {
        ...state,
        projectAllocations,
        expandedAllocations,
        deleteProjectAllocationPending: false,
        deleteProjectAllocationError: null,
        projectSelections: newProjectSelections,
        triggerRefreshAllocationMeta: true,
      };
    }

    case PROJECTS_DELETE_PROJECT_ALLOCATION_FAILURE:
      return {
        ...state,
        deleteProjectAllocationPending: false,
        deleteProjectAllocationError: action.data.error,
      };

    case PROJECTS_DELETE_PROJECT_ALLOCATION_DISMISS_ERROR:
      return {
        ...state,
        deleteProjectAllocationError: null,
      };

    default:
      return state;
  }
}
