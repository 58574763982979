import { withStyles } from '@material-ui/core/styles';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Paper } from '@material-ui/core';
import { Loader } from '@bridgit/foundation';
import queryString from 'query-string';
import { SignupForm, ExpiredInvite } from '.';
import LoginSignup from './LoginSignup';
import { PERM_BRIDGIT_ADMIN } from '../../common/constants';
import {
  acceptInvitationOauth,
  acceptInvitation,
  getLoginInvitation,
} from './redux/actions';
import { SELF_PERFORM_REQUESTER } from '../permissions/utils/constants';

const styles = theme => ({
  contained: {
    minWidth: 160,
    margin: '0 40px',
    backgroundColor: theme.palette.common.white,
  },
});

export class SignupFromEmailInvite extends PureComponent {
  static propTypes = {
    acceptInvitationOauthPending: PropTypes.bool.isRequired,
    getLoginInvitationError: PropTypes.bool,
    userLoginError: PropTypes.object,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    acceptInvitationPending: PropTypes.bool.isRequired,
    isNewUser: PropTypes.bool.isRequired,
    isInvitationExpired: PropTypes.bool.isRequired,
    acceptInvitationOauthError: PropTypes.object,
    userInvitationGroup: PropTypes.string.isRequired,
    getLoginInvitation: PropTypes.func.isRequired,
    acceptInvitation: PropTypes.func.isRequired,
    acceptInvitationOauth: PropTypes.func.isRequired,
    isCommonlyUsedPassword: PropTypes.bool.isRequired,
    isInvalidPassword: PropTypes.bool.isRequired,
    isBridgitAdminSignup: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    userLoginError: null,
    getLoginInvitationError: null,
    acceptInvitationOauthError: null,
  }

  constructor(props) {
    super(props);

    const { location, getLoginInvitation, acceptInvitation } = props;

    const parsed = queryString.parse(location.search);
    const urlToken = parsed.token;

    getLoginInvitation(urlToken);

    // Check if user exists (returns 'MissingRequiredField' for new users)
    acceptInvitation({ token: urlToken });

    this.state = {
      urlToken,
      viaEmail: false,
      oauthType: '',
    };
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { acceptInvitationOauthPending, acceptInvitationPending, history } = this.props;
    const { oauthType } = this.state;

    const acceptInvitationLoaded = acceptInvitationPending && !nextProps.acceptInvitationPending;

    // Only redirect to login if they signed up with a non-common password, the invitation is not expired and the password meets validation criteria
    if (acceptInvitationLoaded
      && !nextProps.isNewUser
      && !nextProps.isCommonlyUsedPassword
      && !nextProps.isInvitationExpired
      && !nextProps.isInvalidPassword
      && !nextProps.isBridgitAdminSignup
    ) {
      history.push('/login');
    }

    if (acceptInvitationOauthPending && !nextProps.acceptInvitationOauthPending) {
      if (nextProps.acceptInvitationOauthError) {
        return;
      }

      if (oauthType === 'google') {
        window.location.replace(`${window.location.origin}/auth/google/clientsignin`);
      } else if (oauthType === 'microsoft') {
        window.location.replace(`${window.location.origin}/auth/azure/signin`);
      } else if (oauthType === 'autodesk') {
        window.location.replace(`${window.location.origin}/auth/autodesk/signin`);
      } else if (oauthType === 'procore') {
        window.location.replace(`${window.location.origin}/auth/procore/signin`);
      }
    }
  }

  // Handles re-invitation of a deactivated Bridgit Admin Account
  componentDidUpdate = (prevProps) => {
    const { userInvitationGroup } = this.props;
    const { urlToken, oauthType } = this.state;

    if (prevProps.userInvitationGroup !== userInvitationGroup && userInvitationGroup === PERM_BRIDGIT_ADMIN) {
      acceptInvitationOauth({ token: urlToken }, oauthType);
    }
  }

  handleEmailClick = () => {
    this.setState(state => ({ viaEmail: !state.viaEmail }));
  };

  acceptInvitationOauth = (oauthType) => {
    const { acceptInvitationOauth } = this.props;
    const { urlToken } = this.state;

    this.setState({
      oauthType,
    });

    acceptInvitationOauth({ token: urlToken }, oauthType);
  }

  renderContent() {
    const { getLoginInvitationError, history, userLoginError, userInvitationGroup, isInvitationExpired } = this.props;
    const { urlToken, viaEmail } = this.state;
    const showInviteError = getLoginInvitationError || isInvitationExpired;
    const showEmailSignup = !showInviteError && viaEmail;
    const showSignupSelection = !showInviteError && !showEmailSignup;

    if (userInvitationGroup === SELF_PERFORM_REQUESTER) {
      return 'You do not have permission to access Bench on the web. Please log in to the Bench app on your mobile device.';
    }

    return (
      <>
        {showInviteError && (
          <div className="login-signup-form-box">
            <ExpiredInvite />
          </div>
        )}

        {showEmailSignup && (
          <div className="login-signup-form-box">
            <Paper component="section" variant="outlined" className="signup-form spacious-container">
              <SignupForm
                back={this.handleEmailClick}
                urlToken={urlToken}
                history={history}
              />
            </Paper>
          </div>
        )}

        {showSignupSelection && (
          <LoginSignup
            viaEmail={this.handleEmailClick}
            headline="Sign up to use Bridgit Bench"
            type="Sign up"
            loginError={userLoginError}
            acceptInvitationOauth={this.acceptInvitationOauth}
          />
        )}
      </>
    );
  }

  render() {
    const { loading } = this.props;

    if (loading) return <Loader />;

    return (
      <Grid className="login-signup-from-email-invite" container justify="center">
        <Grid className="form-container" container alignItems="center" justify="center">
          {this.renderContent()}
        </Grid>
      </Grid>
    );
  }
}

const pageWithStyles = withStyles(styles)(SignupFromEmailInvite);

/* istanbul ignore next */
function mapStateToProps({
  login: {
    acceptInvitationOauthPending,
    getLoginInvitationError,
    userLoginError,
    acceptInvitationError,
    getLoginInvitationPending,
    acceptInvitationPending,
    userInvitationData,
  },
}) {
  const isNewUser =
    acceptInvitationError?.status === 400
    && acceptInvitationError.data?.errors?.[0]?.errorType === 'MissingRequiredField';
  const isCommonlyUsedPassword = acceptInvitationError?.status === 406;
  const isInvitationExpired = acceptInvitationError?.status === 403;
  const isInvalidPassword = acceptInvitationError?.status === 400
    && acceptInvitationError.data?.errors?.[0]?.errorType === 'ValidationError'
    && acceptInvitationError.data?.errors?.[0]?.field === 'Password';
  const isBridgitAdminSignup = acceptInvitationError?.status === 422
    && acceptInvitationError.data?.errors?.[0]?.errorType === 'InvalidSignup';

  return {
    acceptInvitationOauthPending,
    getLoginInvitationError,
    userLoginError,
    isNewUser,
    acceptInvitationPending,
    isCommonlyUsedPassword,
    isInvitationExpired,
    loading: getLoginInvitationPending || acceptInvitationPending,
    userInvitationGroup: userInvitationData?.group || '',
    isInvalidPassword,
    isBridgitAdminSignup,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    getLoginInvitation: bindActionCreators(getLoginInvitation, dispatch),
    acceptInvitation: bindActionCreators(acceptInvitation, dispatch),
    acceptInvitationOauth: bindActionCreators(acceptInvitationOauth, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(pageWithStyles);
