import {
  COMMON_CLEAR_SELECTED_PERSON_ID, COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PERSON,
} from './constants';

export function clearSelectedPersonId() {
  return {
    type: COMMON_CLEAR_SELECTED_PERSON_ID,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_CLEAR_SELECTED_PERSON_ID: {
      const { multiStateModal } = state;
      const { activeView } = multiStateModal;
      return {
        ...state,
        multiStateModal: {
          ...multiStateModal,
          selectedPersonId: null,
          activeView: activeView === COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_PERSON ? null : activeView,
        },
      };
    }

    default:
      return state;
  }
}
