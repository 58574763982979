import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PEOPLE_GET_PEOPLE_BEGIN,
  PEOPLE_GET_PEOPLE_SUCCESS,
  PEOPLE_GET_PEOPLE_FAILURE,
  PEOPLE_GET_PEOPLE_DISMISS_ERROR,
  MAX_PEOPLE,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';

export function getPeople(accountId, deactivated = false) {
  // If need to pass args to saga, pass it with the begin action.
  return {
    type: PEOPLE_GET_PEOPLE_BEGIN,
    accountId,
    deactivated,
  };
}

export function dismissGetPeopleError() {
  return {
    type: PEOPLE_GET_PEOPLE_DISMISS_ERROR,
  };
}

// worker Saga: will be fired on PEOPLE_GET_PEOPLE_BEGIN actions
export function* doGetPeople(action) {
  const { accountId, deactivated } = action;
  let res;
  try {
    res = yield call(protectedJsonGet, `accounts/${accountId}/persons?state=${deactivated ? 'Deactivated' : 'Active'}&limit=${MAX_PEOPLE}`);
  } catch (err) {
    yield put({
      type: PEOPLE_GET_PEOPLE_FAILURE,
      data: { error: err },
    });
    return;
  }
  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: PEOPLE_GET_PEOPLE_SUCCESS,
    data: res,
  });
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchGetPeople() {
  yield takeLatest(PEOPLE_GET_PEOPLE_BEGIN, doGetPeople);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_GET_PEOPLE_BEGIN:
      return {
        ...state,
        getPeoplePending: true,
        getPeopleError: null,
      };

    case PEOPLE_GET_PEOPLE_SUCCESS:
      return {
        ...state,
        entities: action.data || [],
        getPeoplePending: false,
        getPeopleError: null,
      };

    case PEOPLE_GET_PEOPLE_FAILURE:
      return {
        ...state,
        getPeoplePending: false,
        getPeopleError: action.data.error,
      };

    case PEOPLE_GET_PEOPLE_DISMISS_ERROR:
      return {
        ...state,
        getPeopleError: null,
      };

    default:
      return state;
  }
}
