import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNT_SETTINGS_UPDATE_ACCOUNT_PROPERTIES_BEGIN,
  ACCOUNT_SETTINGS_UPDATE_ACCOUNT_PROPERTIES_SUCCESS,
  ACCOUNT_SETTINGS_UPDATE_ACCOUNT_PROPERTIES_FAILURE,
  ACCOUNT_SETTINGS_UPDATE_ACCOUNT_PROPERTIES_DISMISS_ERROR,
} from './constants';
import { protectedJsonPut } from '../../../utils/api';

export function updateAccountProperties(accountId, data) {
  return {
    type: ACCOUNT_SETTINGS_UPDATE_ACCOUNT_PROPERTIES_BEGIN,
    accountId,
    data,
  };
}

export function dismissUpdateAccountPropertiesError() {
  return {
    type: ACCOUNT_SETTINGS_UPDATE_ACCOUNT_PROPERTIES_DISMISS_ERROR,
  };
}

export function* doUpdateAccountProperties(action) {
  const { accountId, data } = action;
  let res;
  try {
    res = yield call(protectedJsonPut, `accounts/${accountId}/properties`, data);
  } catch (err) {
    yield put({
      type: ACCOUNT_SETTINGS_UPDATE_ACCOUNT_PROPERTIES_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: ACCOUNT_SETTINGS_UPDATE_ACCOUNT_PROPERTIES_SUCCESS,
    data: res,
  });
}

export function* watchUpdateAccountProperties() {
  yield takeLatest(ACCOUNT_SETTINGS_UPDATE_ACCOUNT_PROPERTIES_BEGIN, doUpdateAccountProperties);
}

export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNT_SETTINGS_UPDATE_ACCOUNT_PROPERTIES_BEGIN:
      return {
        ...state,
        updateAccountPropertiesPending: true,
        updateAccountPropertiesError: null,
      };

    case ACCOUNT_SETTINGS_UPDATE_ACCOUNT_PROPERTIES_SUCCESS:
      return {
        ...state,
        accountProperties: action.data,
        updateAccountPropertiesPending: false,
        updateAccountPropertiesError: null,
      };

    case ACCOUNT_SETTINGS_UPDATE_ACCOUNT_PROPERTIES_FAILURE:
      return {
        ...state,
        updateAccountPropertiesPending: false,
        updateAccountPropertiesError: action.data.error,
      };

    case ACCOUNT_SETTINGS_UPDATE_ACCOUNT_PROPERTIES_DISMISS_ERROR:
      return {
        ...state,
        updateAccountPropertiesError: null,
      };

    default:
      return state;
  }
}
