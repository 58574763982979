import { isAuthorized, hasModuleEnabled } from 'src/features/permissions/utils/permissionUtils';
import { getTableConfigFromSchema } from 'src/features/table/filterUtils';
import { PROJECT_FIELDS, PROJECT_FIELD_DEFAULTS } from 'src/common/descriptors/project';
import { ACCOUNT_MODULE_PURSUIT_TRACKING } from 'src/common/constants';
import { PURSUIT_MODULE_STATUS_TO_REMOVE, WIN_PERCENT } from 'src/features/projects/constants';

import {
  TABLE_SET_DESCRIPTORS,
} from './constants';

export function setDescriptors(accountId, permissions, accountModules, winPercentageFlag = false) {
  const authorizedFields = PROJECT_FIELDS.filter((field) => {
    let hasPerms = true;
    if (field.action && field.subject) {
      hasPerms = isAuthorized(accountId, permissions, field.action, field.subject);
    }
    return hasPerms;
  });

  const projectDescriptor = authorizedFields.reduce((projectDescriptors, field) => {
    let newField = field;
    if (!winPercentageFlag && field.schemaName === WIN_PERCENT) {
      return projectDescriptors;
    }
    // If Pursuit Tracking off...
    if (!hasModuleEnabled(accountModules, ACCOUNT_MODULE_PURSUIT_TRACKING)) {
      // Remove "type" and "winPercent" descriptors entirely
      if (field.schemaName === 'type' || field.schemaName === WIN_PERCENT) {
        return projectDescriptors;
      }
      // And filter out unused "status" options ("Lost", "Pursuit")
      if (field.schemaName === 'state') {
        const filterOptions = field.filterOptions.reduce((newFilterOptions, filter) => {
          if (PURSUIT_MODULE_STATUS_TO_REMOVE.includes(filter.name)) {
            return newFilterOptions;
          }
          newFilterOptions.push(filter);
          return newFilterOptions;
        }, []);

        newField = {
          ...field,
          filterOptions,
        };
      }
    }

    projectDescriptors.push({
      ...PROJECT_FIELD_DEFAULTS,
      ...newField,
    });

    return projectDescriptors;
  }, []);

  const projectTableConfig = getTableConfigFromSchema(projectDescriptor);

  return {
    type: TABLE_SET_DESCRIPTORS,
    projects: {
      projectDescriptor,
      projectTableConfig,
    },
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case TABLE_SET_DESCRIPTORS: {
      const { projects } = action;
      return {
        ...state,
        descriptors: {
          projects: {
            ...projects,
          },
        },
      };
    }
    default:
      return state;
  }
}
