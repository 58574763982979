import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SimpleTable } from '../common';
import { ASSIGNMENTS_COLUMNS } from './constants';
import { generateHourlyPersonAssignmentsData } from './utils/hourlyPersonUtils';
import { capitalize } from '../../utils/formatters';

export const HourlyPersonProfileAssignments = ({
  currentAssignments,
  upcomingAssignments,
  pastAssignments,
}) => {
  const tablesData = useMemo(
    () => generateHourlyPersonAssignmentsData(currentAssignments, upcomingAssignments, pastAssignments),
    [currentAssignments, upcomingAssignments, pastAssignments],
  );

  return (
    <>
      {tablesData.map(({ rows, assignmentType }) => (
        <SimpleTable
          key={assignmentType}
          rows={rows}
          cols={ASSIGNMENTS_COLUMNS}
          noDataText={`No ${assignmentType} assignments`}
          className="self-perform-person-assignments-table"
          tableTitle={assignmentType === 'completed' ? 'Assignment history' : `${capitalize(assignmentType)} assignments`}
        />
      ))}
    </>
  );
};

HourlyPersonProfileAssignments.propTypes = {
  currentAssignments: PropTypes.array.isRequired,
  upcomingAssignments: PropTypes.array.isRequired,
  pastAssignments: PropTypes.array.isRequired,
};

HourlyPersonProfileAssignments.defaultProps = {};

const mapStateToProps = ({ selfPerform: { selectedHourlyPerson } }) => {
  const { currentAssignments, upcomingAssignments, pastAssignments } = selectedHourlyPerson;
  return {
    currentAssignments,
    upcomingAssignments,
    pastAssignments,
  };
};

export default connect(
  mapStateToProps,
)(HourlyPersonProfileAssignments);
