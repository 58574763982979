/* This is the Root component mainly initializes Redux and React Router. */
import './common/wdyr';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Router } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { hot } from 'react-hot-loader/root';
import { renderRouteConfigV3 } from 'src/common/routeUtils';
import history from './common/history';
import { ReportFactory } from './features/reports';

function Root({
  routeConfig,
  accountId,
  permissions,
  accountModules,
}) {
  const children = useMemo(() => {
    let { routes } = routeConfig;

    if (!accountId || !permissions) {
      routes = routeConfig.defaultRoutes;
    }

    return (
      <>
        <ReportFactory />
        <Router history={history}>
          {renderRouteConfigV3(routes, '/', accountId, permissions, accountModules)}
        </Router>
      </>
    );
  }, [accountId, accountModules, permissions, routeConfig]);

  return (
    <ConnectedRouter history={history}>
      {children}
    </ConnectedRouter>
  );
}

Root.propTypes = {
  routeConfig: PropTypes.object.isRequired,
  accountId: PropTypes.number,
  permissions: PropTypes.object,
  accountModules: PropTypes.arrayOf(PropTypes.object),
};

Root.defaultProps = {
  accountId: null,
  permissions: null,
  accountModules: null,
};

/* istanbul ignore next */
function mapStateToProps({ common, login, accountSettings }) {
  const { accountId } = common;
  const { permissions } = login.userInfo;
  const { accountModules } = accountSettings;

  return {
    accountId,
    permissions,
    accountModules,
  };
}

export default hot(connect(
  mapStateToProps,
  null,
)(Root));
