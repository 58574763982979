import { call, put, takeLatest } from 'redux-saga/effects';
import {
  SELF_PERFORM_ASSIGN_REQUEST_BEGIN,
  SELF_PERFORM_ASSIGN_REQUEST_SUCCESS,
  SELF_PERFORM_NOTIFICATION_SENT_SUCCESS,
  SELF_PERFORM_ASSIGN_REQUEST_FAILURE,
  SELF_PERFORM_ASSIGN_REQUEST_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';

export function assignRequest(accountId, projectId, requestId, data, mixpanelData) {
  return {
    type: SELF_PERFORM_ASSIGN_REQUEST_BEGIN,
    accountId,
    projectId,
    requestId,
    data,
    mixpanelData,
  };
}

export function dismissAssignRequestError() {
  return {
    type: SELF_PERFORM_ASSIGN_REQUEST_DISMISS_ERROR,
  };
}

export function* doAssignRequest(action) {
  const { accountId, projectId, requestId, data, mixpanelData } = action;
  const { message } = data;

  try {
    yield call(protectedJsonPost, `accounts/${accountId}/projects/${projectId}/hourly-requests/${requestId}/assignment`, data);
  } catch (err) {
    yield put({
      type: SELF_PERFORM_ASSIGN_REQUEST_FAILURE,
      data: { error: err },
    });
    return;
  }

  // dispatch for mixpanel middleware
  if (message !== null) {
    yield put({
      type: SELF_PERFORM_NOTIFICATION_SENT_SUCCESS,
      requestId,
      projectId,
      personId: data?.assigneeId,
      message,
      mixpanelData,
    });
  }

  yield put({
    type: SELF_PERFORM_ASSIGN_REQUEST_SUCCESS,
    requestId,
    projectId,
    personId: data?.assigneeId,
    message,
    mixpanelData,
  });
}

export function* watchAssignRequest() {
  yield takeLatest(SELF_PERFORM_ASSIGN_REQUEST_BEGIN, doAssignRequest);
}

export function reducer(state, action) {
  switch (action.type) {
    case SELF_PERFORM_ASSIGN_REQUEST_BEGIN:
      return {
        ...state,
        assignRequestPending: true,
        assignRequestError: null,
      };

    case SELF_PERFORM_ASSIGN_REQUEST_SUCCESS:
      return {
        ...state,
        filteredRequests: state.filteredRequests?.filter(({ id }) => id !== action.requestId) || [],
        assignRequestPending: false,
        assignRequestError: null,
      };

    case SELF_PERFORM_ASSIGN_REQUEST_FAILURE:
      return {
        ...state,
        assignRequestPending: false,
        assignRequestError: action.data.error,
      };

    case SELF_PERFORM_ASSIGN_REQUEST_DISMISS_ERROR:
      return {
        ...state,
        assignRequestError: null,
      };

    default:
      return state;
  }
}
