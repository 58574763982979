const SAVED_FILTER_ADDED = 'Saved Filter Added';
const FILTERS_RESET_ON = 'Filters are reset on';
const VIEW_RESET_ON = 'View reset on';
const CLEAR_FILTER_CHIP = 'Clear Filter Chip for';
const VIEW_REMOVED_FROM = 'View removed from';
const PAPM_CLEAR_FILTER_CHIP = 'Place a Person - Clear Filter Chip';
const PAPM_FILTERS_RESET = 'Place a Person - Filters are reset';
const PROPERTY_CHIP_CLEARED_FROM = 'Property chip is cleared from';
const VALUE_OF_CHIP_CLEARED = 'Value of chip cleared';
const PROPERTY_SORTED_BY = 'Property sorted by';
const VIEW_SORTED_ON = 'View sorted on';
const SORT_ON = 'Sort on';
const FILTER_ON_PEOPLE = 'Filter on People';

export {
  SAVED_FILTER_ADDED,
  FILTERS_RESET_ON,
  VIEW_RESET_ON,
  CLEAR_FILTER_CHIP,
  VIEW_REMOVED_FROM,
  PAPM_CLEAR_FILTER_CHIP,
  PAPM_FILTERS_RESET,
  PROPERTY_CHIP_CLEARED_FROM,
  VALUE_OF_CHIP_CLEARED,
  PROPERTY_SORTED_BY,
  VIEW_SORTED_ON,
  SORT_ON,
  FILTER_ON_PEOPLE,
};
