import { call, put, takeLatest } from 'redux-saga/effects';
import { protectedJsonPut } from 'src/utils/api';
import {
  ACCOUNT_SETTINGS_UPDATE_SUB_PHASE_BEGIN,
  ACCOUNT_SETTINGS_UPDATE_SUB_PHASE_SUCCESS,
  ACCOUNT_SETTINGS_UPDATE_SUB_PHASE_FAILURE,
  ACCOUNT_SETTINGS_UPDATE_SUB_PHASE_DISMISS_ERROR,
} from './constants';

export function updateSubPhase(accountId, phaseId, subPhaseId, data) {
  // If need to pass args to saga, pass it with the begin action.
  return {
    type: ACCOUNT_SETTINGS_UPDATE_SUB_PHASE_BEGIN,
    accountId,
    phaseId,
    subPhaseId,
    data,
  };
}

export function dismissUpdateSubPhaseError() {
  return {
    type: ACCOUNT_SETTINGS_UPDATE_SUB_PHASE_DISMISS_ERROR,
  };
}

export function* doUpdateSubPhase(action) {
  const { accountId, phaseId, subPhaseId, data } = action;
  try {
    yield call(protectedJsonPut, `accounts/${accountId}/phases/${phaseId}/subphases/${subPhaseId}`, data);
  } catch (err) {
    yield put({
      type: ACCOUNT_SETTINGS_UPDATE_SUB_PHASE_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: ACCOUNT_SETTINGS_UPDATE_SUB_PHASE_SUCCESS,
    accountId,
    phaseId,
    subPhaseId,
    data,
  });
}

export function* watchUpdateSubPhase() {
  yield takeLatest(ACCOUNT_SETTINGS_UPDATE_SUB_PHASE_BEGIN, doUpdateSubPhase);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNT_SETTINGS_UPDATE_SUB_PHASE_BEGIN:
      return {
        ...state,
        updateSubPhasePending: true,
        updateSubPhaseError: null,
      };

    case ACCOUNT_SETTINGS_UPDATE_SUB_PHASE_SUCCESS: {
      const { phases } = state;
      const { phaseId, subPhaseId, data } = action;

      const newPhases = phases.map((phase) => {
        if (phaseId === phase.id) {
          const subPhases = phase.subPhases || [];
          const newSubPhases = subPhases.map((sp) => {
            if (sp.id === subPhaseId) {
              return {
                id: subPhaseId,
                ...data,
                parentId: action.phaseId,
              };
            }
            return sp;
          });
          return {
            ...phase,
            subPhases: newSubPhases,
          };
        }
        return phase;
      });

      return {
        ...state,
        phases: newPhases,
        updateSubPhasePending: false,
        updateSubPhaseError: null,
      };
    }

    case ACCOUNT_SETTINGS_UPDATE_SUB_PHASE_FAILURE:
      return {
        ...state,
        updateSubPhasePending: false,
        updateSubPhaseError: action.data.error,
      };

    case ACCOUNT_SETTINGS_UPDATE_SUB_PHASE_DISMISS_ERROR:
      return {
        ...state,
        updateSubPhaseError: null,
      };

    default:
      return state;
  }
}
