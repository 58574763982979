import moment from 'moment';
import { DATE_INPUT_FORMAT } from 'src/common/constants';
import {
  MUST,
  FIELDS,
  FIELDS_VALUE_SELECTION,
  FIELDS_VALUE_TEXT,
  FIELDS_VALUE_DATE,
  PHASES,
  PHASES_START_DATE,
  PHASES_END_DATE,
  PHASES_NAME,
  SUB_PHASES,
  SUB_PHASES_START_DATE,
  SUB_PHASES_END_DATE,
  SUB_PHASES_NAME,
  EMPLOYMENT_DATES,
  EMPLOYMENT_DATES_START_DATE,
  EMPLOYMENT_DATES_END_DATE,
  CERTIFICATIONS_ABBREVIATION,
  CERTIFICATIONS_NAME,
  CERTIFICATIONS,
} from './constants';
import { getDateFormat, removeReservedCharacters } from '../utils/filters';

const generateGenericProjectSearchFilter = (searchString) => {
  if (!searchString.length) {
    return null;
  }

  const dateFormat = getDateFormat(searchString);
  const searchTerm = removeReservedCharacters(searchString);
  const today = moment().format(DATE_INPUT_FORMAT);

  const should = [
    {
      nested: {
        path: FIELDS,
        query: {
          query_string: {
            query: `*${searchTerm}*`,
            fields: [FIELDS_VALUE_TEXT],
          },
        },
      },
    },
    {
      nested: {
        path: FIELDS,
        query: {
          query_string: {
            query: `*${searchTerm}*`,
            fields: [FIELDS_VALUE_SELECTION],
          },
        },
      },
    },
    {
      nested: {
        path: PHASES,
        query: {
          bool: {
            [MUST]: [
              {
                range: {
                  [PHASES_START_DATE]: {
                    lte: today,
                  },
                },
              },
              {
                range: {
                  [PHASES_END_DATE]: {
                    gte: today,
                  },
                },
              },
              {
                query_string: {
                  query: `*${searchTerm}*`,
                  fields: [PHASES_NAME],
                },
              },
            ],
          },
        },
      },
    },
    {
      nested: {
        path: SUB_PHASES,
        query: {
          bool: {
            [MUST]: [
              {
                range: {
                  [SUB_PHASES_START_DATE]: {
                    lte: today,
                  },
                },
              },
              {
                range: {
                  [SUB_PHASES_END_DATE]: {
                    gte: today,
                  },
                },
              },
              {
                query_string: {
                  query: `*${searchTerm}*`,
                  fields: [SUB_PHASES_NAME],
                },
              },
            ],
          },
        },
      },
    },
    {
      query_string: {
        query: `*${searchTerm}*`,
        fields: ['name'],
      },
    },
  ];

  if (dateFormat) {
    const parsedString = searchString.replace(/-/g, '/');
    const dateString = moment.utc(parsedString, dateFormat).format(DATE_INPUT_FORMAT);

    should.push({
      query_string: {
        query: dateString,
        fields: ['startDate', 'endDate'],
      },
    });
    should.push({
      nested: {
        path: FIELDS,
        query: {
          query_string: {
            query: dateString,
            fields: [FIELDS_VALUE_DATE],
          },
        },
      },
    });
  }

  return {
    bool: {
      should,
    },
  };
};

const generateGenericPeopleSearchFilter = (searchString) => {
  if (!searchString.length) {
    return null;
  }

  const dateFormat = getDateFormat(searchString);
  const searchTerm = removeReservedCharacters(searchString);

  const should = [
    {
      nested: {
        path: FIELDS,
        query: {
          query_string: {
            query: `*${searchTerm}*`,
            fields: [FIELDS_VALUE_TEXT, FIELDS_VALUE_SELECTION],
          },
        },
      },
    },
    {
      nested: {
        path: CERTIFICATIONS,
        query: {
          query_string: {
            query: `*${searchTerm}*`,
            fields: [CERTIFICATIONS_ABBREVIATION, CERTIFICATIONS_NAME],
          },
        },
      },
    },
    {
      query_string: {
        query: `*${searchTerm}*`,
        fields: ['name', 'email', 'title'],
      },
    },
  ];

  if (dateFormat) {
    const parsedString = searchString.replace(/-/g, '/');
    const dateString = moment.utc(parsedString, dateFormat).format(DATE_INPUT_FORMAT);

    should.push({
      nested: {
        path: FIELDS,
        query: {
          query_string: {
            query: dateString,
            fields: [FIELDS_VALUE_DATE],
          },
        },
      },
    },
    {
      nested: {
        path: EMPLOYMENT_DATES,
        query: {
          query_string: {
            query: dateString,
            fields: [EMPLOYMENT_DATES_START_DATE, EMPLOYMENT_DATES_END_DATE],
          },
        },
      },
    });
  }

  return {
    bool: {
      should,
    },
  };
};

const generateGenericHourlyPersonsSearchFilter = (searchString) => {
  if (!searchString.length) {
    return null;
  }

  const searchTerm = removeReservedCharacters(searchString);

  const should = [
    {
      query_string: {
        query: `*${searchTerm}*`,
        fields: ['name'],
      },
    },
  ];

  return {
    bool: {
      should,
    },
  };
};

export {
  generateGenericProjectSearchFilter,
  generateGenericPeopleSearchFilter,
  generateGenericHourlyPersonsSearchFilter,
};
