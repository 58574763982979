import { getTableConfigFromSchema } from 'src/features/table/filterUtils';

export const ROLE_SCHEMA = {
  roleName: 'roleName',
  projectName: 'projectName',
  projectStatus: 'projectStatus',
  startDate: 'startDate',
  endDate: 'endDate',
  allocation: 'allocation',
};

export const ROLE_FIELDS = [
  {
    schemaName: ROLE_SCHEMA.roleName,
    name: 'Role',
  },
  {
    schemaName: ROLE_SCHEMA.projectName,
    name: 'Project',
  },
  {
    schemaName: ROLE_SCHEMA.projectStatus,
    name: 'Project Status',
  },
  {
    schemaName: ROLE_SCHEMA.startDate,
    name: 'Start Date',
  },
  {
    schemaName: ROLE_SCHEMA.endDate,
    name: 'End Date',
  },
  {
    schemaName: ROLE_SCHEMA.allocation,
    name: 'Allocation %',
    sort: false,
  },
];

export const ROLE_FIELDS_DEFAULTS = {
  visible: true,
  defaultVisible: true,
  alwaysVisible: false,
  filter: false,
  sort: true,
};

export const ROLE_DESCRIPTOR = ROLE_FIELDS.map(field => ({
  ...ROLE_FIELDS_DEFAULTS,
  ...field,
}));

export const ROLE_TABLE_CONFIG = getTableConfigFromSchema(ROLE_DESCRIPTOR);
