import React, { useMemo, useState, useCallback, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import pluralize from 'pluralize';
import shortid from 'shortid';
import classNames from 'classnames';
import { ClearFiltersChip } from '@bridgit/foundation';
import { ACCOUNT_MODULE_PURSUIT_TRACKING } from 'src/common/constants';
import { FilterChipGroup } from '.';
import { isDefaultFilter, getDefaultFilter } from '../filters/utils/filterUtils';
import { updateColumnFilter, updateMultiFilter } from './filterUtils';
import { dismissAddSavedFilterError, addSavedFilter, setFilterQuery } from '../queries/redux/actions';
import { hasModuleEnabled } from '../permissions/utils/permissionUtils';
import { SaveFilterButton, SaveFilterModal } from '../filters';
import { toAPISavedFilter } from '../queries/utils/queryUtils';

export const ChipBowl = ({
  match,
  accountId,
  className,
  classes,
  noFilterMessage,
  queries,
  useClearText,
  showFilters,
  showReset,
  filterStorageKey,
  setFilterQuery,
  queryId,
  onUpdateFilters,
  onClearFilters,
  isPursuitModuleOn,
  showSaveFilterButton,
  bowlLabel,
  filterType,
  addSavedFilterPending,
  addSavedFilterError,
  dismissAddSavedFilterError,
  addSavedFilter,
}) => {
  const queryObj = queries[queryId];
  const queryArgs = queryObj?.filter?.args;
  const filterName = queryObj?.filter?.filterName;
  const isSelfPerform = match?.url.includes('self-perform');

  const defaultFilters = useMemo(() => getDefaultFilter(queryId, isPursuitModuleOn), [queryId, isPursuitModuleOn]);

  const chipData = useMemo(() => (
    queryArgs?.filter(a => 'activeFilters' in a)
  ), [queryArgs]);

  const notDefaultChips = useMemo(() => (
    !!chipData && !isDefaultFilter(defaultFilters, chipData)
  ), [defaultFilters, chipData]);

  const clearFilters = useCallback(() => {
    if (onClearFilters) onClearFilters();
    setFilterQuery(
      queryId,
      { name: 'filter', args: defaultFilters, filterType },
      filterStorageKey,
    );
  }, [defaultFilters, queryId, filterStorageKey, setFilterQuery, onClearFilters, filterType]);

  const updateFilters = useCallback((column, updatedFilters, idx, filterLabel, isMulti = false) => {
    const filterQueryObj = queryObj ? queryObj.filter : {};
    const filter = isMulti
      ? updateMultiFilter(column, updatedFilters, filterQueryObj, idx)
      : updateColumnFilter(column, updatedFilters, filterQueryObj);

    onUpdateFilters(column.name, filterLabel);

    setFilterQuery(queryId, { ...filter, filterType }, filterStorageKey);
  }, [filterStorageKey, onUpdateFilters, queryId, queryObj, setFilterQuery, filterType]);

  const updateMultiFilters = useCallback((column, updatedFilters, idx, filterLabel) => {
    updateFilters(column, updatedFilters, idx, filterLabel, true);
  }, [updateFilters]);

  const renderChipGroup = (chipGroup) => {
    const filters = chipGroup.activeFilters;

    if (filters.length && filters[0].verb) {
      return filters.map((f, idx) => (
        <FilterChipGroup
          className={classes.chipGroup}
          classes={classes}
          key={shortid.generate()}
          column={chipGroup}
          filters={f.selected || [f]}
          updateFilters={updateMultiFilters}
          verb={f.verb}
          id={idx}
          filterStorageKey={filterStorageKey}
        />
      ));
    }

    return (
      <FilterChipGroup
        className={classes.chipGroup}
        classes={classes}
        key={shortid.generate()}
        column={chipGroup}
        filters={chipGroup.activeFilters}
        verb={chipGroup.verb}
        updateFilters={updateFilters}
        filterStorageKey={filterStorageKey}
      />
    );
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const onModalCancel = useCallback(() => {
    setIsModalOpen(false);
    dismissAddSavedFilterError();
  }, [dismissAddSavedFilterError]);

  const onModalSave = useCallback((filterName) => {
    const savedFilter = toAPISavedFilter(filterName, filterType, queryArgs);
    const view = match?.params?.contentView || match?.params?.view;

    const analyticsPayload = {
      view: view === 'snapshot' ? 'dashboard' : view,
    };

    addSavedFilter(accountId, savedFilter, queryId, analyticsPayload);
  }, [addSavedFilter, accountId, queryArgs, filterType, queryId, match]);

  const isSavingRef = useRef(addSavedFilterPending);
  useEffect(() => {
    if (isSavingRef.current && !addSavedFilterPending && !addSavedFilterError) {
      setIsModalOpen(false);
      localStorage.setItem(filterStorageKey, JSON.stringify(queryObj?.filter));
    }
    isSavingRef.current = addSavedFilterPending;
  }, [addSavedFilterPending, addSavedFilterError, queryObj, filterStorageKey]);

  if (!queryArgs) return false;

  const isCollapsed = !showFilters && !!chipData.length;
  const isFilterSaved = !!queryObj?.filter?.filterId;
  const showSavedFilterDisplay = notDefaultChips && showSaveFilterButton && !isSelfPerform;
  const bowlLabelSaved = !!bowlLabel && <div className="bowl-label">{`${bowlLabel} - `}</div>;
  const bowlLabelUnsaved = !!bowlLabel && <div className="bowl-label unsaved">{bowlLabel}</div>;

  return (
    <>
      <div className="bowl-wrap">
        <div className="display-prefix">
          {showSavedFilterDisplay && <SaveFilterButton onClick={handleOpenModal} isFilterSaved={isFilterSaved} />}
          {isFilterSaved ? bowlLabelSaved : bowlLabelUnsaved}
          {showSavedFilterDisplay && !!filterName && !isCollapsed && <span className="filter-name">{filterName}</span>}
        </div>
        <div className={classNames('table-chip-bowl', className)}>
          {isCollapsed
            ? <div className="table-total-chips">{`${pluralize('Filter', chipData.length)} applied`}</div>
            : chipData.map(chipGroup => renderChipGroup(chipGroup))}

          {showFilters && !chipData.length && noFilterMessage && (
            <div className="no-filters">{noFilterMessage}</div>
          )}

          {showReset && !isDefaultFilter(defaultFilters, chipData) && (
            <div id="filter-chip" className="table-clear-bowl">
              <ClearFiltersChip
                clearFilters={useClearText}
                onClick={clearFilters}
              />
            </div>
          )}
        </div>
      </div>
      {isModalOpen && (
        <SaveFilterModal
          onCancel={onModalCancel}
          onSave={onModalSave}
          filterData={chipData}
        />
      )}
    </>
  );
};

ChipBowl.propTypes = {
  match: PropTypes.object.isRequired,
  accountId: PropTypes.number.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object,
  noFilterMessage: PropTypes.node,
  queries: PropTypes.object.isRequired,
  useClearText: PropTypes.bool,
  showFilters: PropTypes.bool,
  showReset: PropTypes.bool,
  filterStorageKey: PropTypes.string,
  setFilterQuery: PropTypes.func.isRequired,
  queryId: PropTypes.string.isRequired,
  onUpdateFilters: PropTypes.func,
  onClearFilters: PropTypes.func,
  isPursuitModuleOn: PropTypes.bool,
  showSaveFilterButton: PropTypes.bool,
  bowlLabel: PropTypes.string,
  filterType: PropTypes.string,
  addSavedFilterPending: PropTypes.bool,
  addSavedFilterError: PropTypes.bool,
  dismissAddSavedFilterError: PropTypes.func.isRequired,
  addSavedFilter: PropTypes.func.isRequired,
};

ChipBowl.defaultProps = {
  className: '',
  classes: {},
  noFilterMessage: null,
  useClearText: true,
  showFilters: true,
  showReset: true,
  filterStorageKey: '',
  onUpdateFilters: () => {},
  onClearFilters: undefined,
  isPursuitModuleOn: false,
  showSaveFilterButton: true,
  bowlLabel: null,
  filterType: '',
  addSavedFilterPending: false,
  addSavedFilterError: false,
};

/* istanbul ignore next */
function mapStateToProps({ common, queries, accountSettings }) {
  const { accountId } = common;
  const { accountModules } = accountSettings;
  const isPursuitModuleOn = hasModuleEnabled(accountModules, ACCOUNT_MODULE_PURSUIT_TRACKING);
  const { addSavedFilterPending, addSavedFilterError } = queries;

  return {
    accountId,
    queries,
    isPursuitModuleOn,
    addSavedFilterPending,
    addSavedFilterError: !!addSavedFilterError,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    setFilterQuery: bindActionCreators(setFilterQuery, dispatch),
    dismissAddSavedFilterError: bindActionCreators(dismissAddSavedFilterError, dispatch),
    addSavedFilter: bindActionCreators(addSavedFilter, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ChipBowl));
