import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import pluralize from 'pluralize';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItemText,
  ListItemSecondaryAction,
  ListSubheader,
  Switch,
  Tooltip,
} from '@material-ui/core';
import { Close, DragHandle } from '@material-ui/icons';
import { naturalSort } from 'src/utils/sortUtils';
import { OutlinedInput } from '../wrapped-components';
import { reorderList } from '../../utils/miscUtils';
import { validateListItem } from '../../utils/validators';
import { Confirm, Modal } from '../common';
import { MAX_LIST_ITEM_LENGTH, MAX_LIST_LENGTH } from './redux/constants';

export class SortList extends PureComponent {
  static propTypes = {
    list: PropTypes.object.isRequired,
    updateList: PropTypes.func.isRequired,
    removeItem: PropTypes.func.isRequired,
    pendingUpdate: PropTypes.bool.isRequired,
    maxItemLength: PropTypes.number,
    inUseModalText: PropTypes.string,
  };

  static defaultProps = {
    maxItemLength: MAX_LIST_ITEM_LENGTH,
    inUseModalText: '',
  }

  constructor(props) {
    super(props);

    this.classes = {
      ListSubheader: {
        root: 'subheader',
      },
      ListItemText: {
        root: 'form__li--small',
      },
    };

    this.inputProps = { maxLength: props.maxItemLength || MAX_LIST_ITEM_LENGTH };

    this.state = {
      values: props.list.definedValues || [],
      tempValue: '',
      showError: false,
      showEditError: false,
      showConfirm: false,
      showInUseModal: false,
      editingIndex: -1,
      tempEditValue: '',
      draggingIndex: null,
      alphabetize: !props.list.definedValues || props.list.definedValues.length === 0 || !!props.list.definedValues[0].alphabetize,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { list, pendingUpdate } = this.props;
    const nameChange = list.name !== nextProps.list.name;
    const newList = !list.definedValues && nextProps.list.definedValues && nextProps.list.definedValues.length;
    const lengthChange = list.definedValues && nextProps.list.definedValues && list.definedValues.length !== nextProps.list.definedValues.length;
    const updated = pendingUpdate && !nextProps.pendingUpdate;

    if (nameChange || newList || lengthChange || updated) {
      this.setState({
        values: nextProps.list.definedValues,
      });
    }
  }

  setValue = (event) => {
    this.setState({
      tempValue: event.currentTarget.value,
    });
  }

  setConfirm = (event) => {
    const { values } = this.state;
    const alphabetize = event.target.checked;

    if (alphabetize) {
      const sortedValues = naturalSort(values, 'name');
      let isSameOrder = true;

      for (let i = 0; i < values.length; i += 1) {
        if (values[i].id !== sortedValues[i].id) {
          isSameOrder = false;
          break;
        }
      }

      if (isSameOrder) {
        this.setAlphabetize(alphabetize, values);
      } else {
        this.setState({
          showConfirm: true,
        });
      }
    } else {
      this.setAlphabetize(alphabetize, values);
    }
  }

  confirmAlphabetize = () => {
    const { values } = this.state;

    const sortedValues = naturalSort(values, 'name');
    this.setAlphabetize(true, sortedValues);
  }

  setAlphabetize = (alphabetize, values) => {
    const { updateList, list: { name } } = this.props;
    this.setState({
      alphabetize,
      values,
      showConfirm: false,
    });

    updateList(values, alphabetize, false);

    window.mixpanel.track(`${name} order toggle used`, {
      'Toggle Selected': alphabetize ? 'Alphabetize' : 'Custom order',
    });
  }

  hideConfirm = () => {
    this.setState({
      showConfirm: false,
    });
  }

  toggleInUseModal = () => {
    const { showInUseModal } = this.state;
    this.setState({
      showInUseModal: !showInUseModal,
    });
  }

  onDragStart = (result) => {
    const { source } = result;
    this.setState({
      draggingIndex: source.index,
    });
  }

  onDragEnd = (result) => {
    const { values } = this.state;
    const { updateList, list: { name } } = this.props;
    const { source, destination } = result;

    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
      this.setState({
        draggingIndex: null,
      });
      return;
    }
    const newValues = reorderList(values, source.index, destination.index);
    this.setState({
      values: newValues,
      draggingIndex: null,
    });

    updateList(newValues, false, false);
    window.mixpanel.track(`${name} Reordered`);
  }

  setEditValue = (e) => {
    this.setState({
      tempEditValue: e.currentTarget.value,
    });
  }

  removeItem = (id, inUse) => (event) => {
    const { removeItem } = this.props;
    event.stopPropagation();

    if (inUse) {
      this.toggleInUseModal();
      return;
    }

    removeItem(id);
  }

  checkEnter = (e) => {
    const { updateList } = this.props;
    const { showError, alphabetize, values } = this.state;
    const value = e.currentTarget.value.trim();

    if (e.keyCode === 13) {
      if (value.length === 0) {
        return;
      }

      if (validateListItem(values, 'name', value)) {
        let newValues;
        if (alphabetize) {
          newValues = naturalSort([...values, { name: value }], 'name');
        } else {
          newValues = [{ name: value }, ...values];
        }
        this.setState({
          tempValue: '',
        });

        updateList(newValues, alphabetize);
      } else {
        this.setState({
          showError: true,
        });
      }
    } else if (showError) {
      this.setState({
        showError: false,
      });
    }
  }

  checkEditEnter = (e) => {
    const { showEditError, editingIndex, values, alphabetize } = this.state;
    const { updateList } = this.props;
    const value = e.currentTarget.value.trim();

    if (e.keyCode === 13) {
      if (value.length === 0) {
        const existingValue = values[editingIndex];
        this.removeItem(existingValue.id, existingValue.inUse);
        this.setState({
          tempEditValue: '',
          editingIndex: -1,
        });
      } else if (validateListItem(values, 'name', value, editingIndex)) {
        const newValues = [...values];
        newValues[editingIndex].name = value;
        const sortedValues = alphabetize ? naturalSort(newValues, 'name') : newValues;

        updateList(sortedValues, alphabetize);
        this.setState({
          values: newValues,
          tempEditValue: '',
          editingIndex: -1,
        });
      } else {
        this.setState({
          showEditError: true,
        });
      }
    } else if (showEditError) {
      this.setState({
        showEditError: false,
      });
    }
  }

  editItem = (e) => {
    const { values, editingIndex } = this.state;
    const index = parseInt(e.currentTarget.dataset.id, 10);
    if (editingIndex !== index) {
      this.setState({
        editingIndex: index,
        tempEditValue: values[index].name,
      });
    }
  }

  exitEdit = () => {
    this.setState({
      editingIndex: -1,
      tempEditValue: '',
    });
  }

  render() {
    const {
      alphabetize,
      draggingIndex,
      values,
      tempValue,
      showError,
      showEditError,
      showConfirm,
      showInUseModal,
      editingIndex,
      tempEditValue,
    } = this.state;
    const { inUseModalText, list } = this.props;
    const disableDragging = editingIndex !== -1;

    return (
      <Grid className="account-settings-sort-list" item xs={6}>
        <List component="nav">
          <ListSubheader classes={this.classes.ListSubheader} disableGutters>
            <span>List Content</span>
            <span>{pluralize('item', values.length, true)}</span>
            <FormControlLabel
              className="alphabetize-wrap"
              labelPlacement="start"
              control={(
                <Switch
                  checked={alphabetize}
                  onChange={this.setConfirm}
                  value="alphabetize"
                  color="primary"
                />
              )}
              label="Alphabetize"
            />
          </ListSubheader>
          {values.length >= MAX_LIST_LENGTH
            ? (
              <div className="limit-reached">
                {`The limit of ${MAX_LIST_LENGTH} list items has been reached`}
              </div>
            )
            : (
              <OutlinedInput
                className={classNames('show-enter', 'bottom-space', { 'error item-unique': showError })}
                inputProps={this.inputProps}
                value={tempValue}
                placeholder="Add a list item"
                onChange={this.setValue}
                onKeyUp={this.checkEnter}
                slim
                fullWidth
                autoFocus
              />
            )}
          <Divider />
          <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
            <Droppable droppableId="items">
              {provided => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {values.map((value, index) => (
                    <Draggable draggableId={String(value.id)} isDragDisabled={disableDragging} index={index} key={value.id}>
                      {provided => (
                        <div
                          key={value.name}
                          role="presentation"
                          className={classNames('list-item', { dragging: draggingIndex === index })}
                          data-id={index}
                          onClick={this.editItem}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <div className={classNames('drag-handle', { hidden: alphabetize })} {...provided.dragHandleProps}>
                            <DragHandle color={disableDragging ? 'disabled' : 'inherit'} />
                          </div>
                          {editingIndex === index
                            ? (
                              <OutlinedInput
                                className={`show-enter${showEditError ? ' error item-unique' : ''}`}
                                inputProps={this.inputProps}
                                value={tempEditValue}
                                placeholder="Edit list item"
                                onChange={this.setEditValue}
                                onKeyUp={this.checkEditEnter}
                                onBlur={this.exitEdit}
                                slim
                                fullWidth
                                autoFocus
                              />
                            )
                            : <ListItemText classes={this.classes.ListItemText} primary={value.name} />}
                          {values.length > 1 && (
                            <ListItemSecondaryAction className="delete-list-item">
                              <Tooltip title="Delete item" placement="top">
                                <IconButton onClick={this.removeItem(value.id, value.inUse)}>
                                  <Close />
                                </IconButton>
                              </Tooltip>
                            </ListItemSecondaryAction>
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </List>
        {showConfirm && (
          <Confirm
            headline="Alphabetize List"
            acceptButtonText="Ok"
            cancelButtonText="Cancel"
            onCancel={this.hideConfirm}
            onAccept={this.confirmAlphabetize}
          >
            Are you sure you would like to alphabetize this list? The current order will not be saved and will need to be manually reset.
          </Confirm>
        )}
        {showInUseModal && (
          <Modal
            headline={list.name}
            showClose={false}
            buttons={[(
              <Button
                key="ok"
                className="button__default"
                disableRipple
                variant="contained"
                size="medium"
                onClick={this.toggleInUseModal}
              >
                Ok
              </Button>
            )]}
          >
            {inUseModalText}
          </Modal>
        )}
      </Grid>
    );
  }
}

export default SortList;
