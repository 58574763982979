import React, { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { MailOutline, CheckRounded } from '@material-ui/icons';
import { MenuItem, Button, Tooltip, Badge } from '@material-ui/core';
import { NotifyModal, MoreActionsButton } from '@bridgit/foundation';
import { PERM_WRITE, PERM_ROLE, PERM_ALLOCATION } from 'src/features/permissions/utils/constants';
import {
  ROLE_NOTIFY_EMAIL,
  ROLE_NOTIFY_TYPE_SET,
  INCLUDE_PROJECT_ADDRESS,
  INCLUDE_ROLE_NAME,
} from './constants';
import { getRoleRecipients, getRoleRecipientNames } from './utils/projectRolesUtils';
import { Confirm } from '../common';
import { Can } from '../wrapped-components';
import { ProjectNotifyHeader } from '.';
import { sendRoleNotification } from './redux/actions';
import { trackAnalytics } from '../common/redux/actions';
import {
  FIELD_TYPE_ADDRESS,
  NOTIFY_MODAL_TIMESTAMP_FORMAT,
  ACCOUNT_MODULE_ASSIGNMENT_COMMUNICATION,
  COMPONENT_ASSIGNMENT_COMMUNICATION_EMAIL,
} from '../../common/constants';
import { PROJECT_CANCEL_ASSIGNMENT_COMMUNICATION_MODAL } from '../../analytics/projects/constants';

export const FloatingActionsProjectRole = ({
  onDeleteRole,
  onDuplicateRole,
  selectedProject,
  role,
  allocations,
  runsInModal,
}) => {
  const dispatch = useDispatch();

  const { accountId } = useSelector(({ common }) => common);
  const { sendRoleNotificationPending } = useSelector(({ projects }) => projects);
  const { people = [] } = useSelector(({ people }) => {
    const { entities } = people;

    return { people: entities };
  });

  const [showNotifyModal, setShowNotifyModal] = useState(false);

  const notifyHeader = useMemo(() => (
    <ProjectNotifyHeader
      label="Role"
      value={role.name}
      project={selectedProject}
    />
  ), [role.name, selectedProject]);

  const standardText = useMemo(() => {
    const { name } = selectedProject;
    return `[Person's name], you are needed on ${name} from [Dates]`;
  }, [selectedProject]);

  const recipients = useMemo(() => getRoleRecipients(people, allocations), [allocations, people]);

  const shouldShowNotifyHistory = useMemo(() => {
    const { endDate, isCommunicated } = role;
    const roleIsPast = moment().isAfter(endDate, 'date');
    const hasNoActiveOrUpcomingAllocations = !recipients.length; // "recipients" excludes past allocations

    // Display historical communication details if role is in the past but was communicated
    // Or if the role is ongoing but there was at least one allocation communicated in the past now
    return (
      (roleIsPast && isCommunicated) ||
      (!roleIsPast && hasNoActiveOrUpcomingAllocations && isCommunicated)
    );
  }, [recipients, role]);

  const communicationStatus = useMemo(() => {
    const { notification, isCommunicated } = role;

    if (!isCommunicated) return null;

    const { notifiedOn, recipientIds } = notification;
    const recipientNames = getRoleRecipientNames(people, recipientIds);
    const date = moment(notifiedOn).format(NOTIFY_MODAL_TIMESTAMP_FORMAT);

    return `Last communication sent on ${date} to ${recipientNames}`;
  }, [role, people]);

  const notifyOptions = useMemo(() => {
    const address = selectedProject.fields.find(({ name }) => name === FIELD_TYPE_ADDRESS);
    const hasAddress = !!address?.values[0];
    return {
      options: [
        ...(hasAddress ? [{
          label: `Include Project Address: ${address?.values[0]}`,
          value: INCLUDE_PROJECT_ADDRESS,
        }] : []),
        {
          label: `Include Role Name: ${role.name}`,
          value: INCLUDE_ROLE_NAME,
        },
      ],
      defaultOptions: {
        [INCLUDE_PROJECT_ADDRESS]: hasAddress,
        [INCLUDE_ROLE_NAME]: true,
      },
    };
  }, [role.name, selectedProject.fields]);

  const onNotifyOpen = () => setShowNotifyModal(true);

  useEffect(() => {
    if (!sendRoleNotificationPending) setShowNotifyModal(false);
  }, [sendRoleNotificationPending]);

  const onNotifyCancel = useCallback(() => {
    dispatch(trackAnalytics(PROJECT_CANCEL_ASSIGNMENT_COMMUNICATION_MODAL));
    setShowNotifyModal(false);
  }, [dispatch]);

  const onNotify = useCallback((message, standardMessage, options) => {
    const { id: projectId } = selectedProject;
    const { id: roleId } = role;

    const data = recipients.map(({ id }) => ({
      personId: id,
      method: ROLE_NOTIFY_EMAIL,
      type: ROLE_NOTIFY_TYPE_SET,
      includeProjectAddress: options[INCLUDE_PROJECT_ADDRESS],
      includeRoleName: options[INCLUDE_ROLE_NAME],
      ...(message ? { message } : {}),
    }));

    const analyticsPayload = {
      project: selectedProject,
      role,
      allocations,
      options,
    };

    dispatch(sendRoleNotification(accountId, projectId, roleId, data, analyticsPayload));
  }, [accountId, allocations, recipients, role, selectedProject, dispatch]);

  const onDeleteRoleCallback = useCallback(() => {
    onDeleteRole();
  }, [onDeleteRole]);

  const onDuplicateRoleCallback = useCallback(() => {
    onDuplicateRole();
  }, [onDuplicateRole]);

  const moreActions = useMemo(() => (
    <MoreActionsButton
      popperPlacement="top-end"
      buttonSize="small"
    >
      <MenuItem onClick={onDuplicateRoleCallback}>Duplicate role</MenuItem>
      <MenuItem onClick={onDeleteRoleCallback}>Delete role</MenuItem>
    </MoreActionsButton>
  ), [onDeleteRoleCallback, onDuplicateRoleCallback]);

  const notify = useMemo(() => {
    const { isCommunicated } = role;
    const tooltipTitle = isCommunicated ? communicationStatus : 'Communicate assignment';
    const hasNoActiveOrUpcomingAllocations = !recipients.length; // "recipients" excludes past allocations

    if ((!shouldShowNotifyHistory && hasNoActiveOrUpcomingAllocations) || runsInModal) return null;

    return (
      <Tooltip title={tooltipTitle} placement="top">
        <Button
          className="project-role-more-actions"
          disableRipple
          variant="outlined"
          aria-haspopup="true"
          onClick={onNotifyOpen}
        >
          <Badge badgeContent={isCommunicated && <CheckRounded className="project-role-communicate-assignment-badge" />}>
            <MailOutline className="project-role-communicate-assignment-icon" />
          </Badge>
        </Button>
      </Tooltip>
    );
  }, [role, communicationStatus, shouldShowNotifyHistory, recipients, runsInModal]);

  const { options, defaultOptions } = notifyOptions;

  return (
    <div className="projects-floating-actions-project-role">
      <Can
        action={PERM_WRITE}
        subject={PERM_ROLE}
        yes={moreActions}
      />

      <Can
        action={PERM_WRITE}
        subject={PERM_ALLOCATION}
        module={ACCOUNT_MODULE_ASSIGNMENT_COMMUNICATION}
        component={COMPONENT_ASSIGNMENT_COMMUNICATION_EMAIL}
        yes={notify}
      />

      <NotifyModal
        headline="Communicate assignment"
        open={!shouldShowNotifyHistory && showNotifyModal}
        standardText={standardText}
        recipients={recipients}
        notifyHeader={notifyHeader}
        primaryAction={onNotify}
        secondaryAction={onNotifyCancel}
        pending={sendRoleNotificationPending}
        includeAvatar
        showCommunicationMethods
        subtitle={communicationStatus}
        options={options}
        defaultOptions={defaultOptions}
      />

      {shouldShowNotifyHistory && showNotifyModal && (
        <Confirm
          headline="Communicate assignment"
          acceptButtonText="Ok"
          onAccept={onNotifyCancel}
          showSecondary={false}
        >
          <div className="notify-past-role">
            <div>This assignment is in the past, no new communications can be sent.</div>
            <div className="communication-status">{communicationStatus}</div>
          </div>
        </Confirm>
      )}
    </div>
  );
};

FloatingActionsProjectRole.propTypes = {
  onDeleteRole: PropTypes.func,
  onDuplicateRole: PropTypes.func,
  selectedProject: PropTypes.object.isRequired,
  role: PropTypes.object.isRequired,
  allocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  runsInModal: PropTypes.bool,
};

FloatingActionsProjectRole.defaultProps = {
  onDeleteRole: () => {},
  onDuplicateRole: () => {},
  runsInModal: false,
};

export default FloatingActionsProjectRole;
