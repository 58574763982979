import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNTS_SET_LOGO_BEGIN,
  ACCOUNTS_SET_LOGO_SUCCESS,
  ACCOUNTS_SET_LOGO_FAILURE,
  ACCOUNTS_SET_LOGO_DISMISS_ERROR,
} from './constants';
import { uploadLogo } from '../../../utils/api';

export function setLogo(accountId, image) {
  return {
    type: ACCOUNTS_SET_LOGO_BEGIN,
    accountId,
    image,
  };
}

export function dismissSetLogoError() {
  return {
    type: ACCOUNTS_SET_LOGO_DISMISS_ERROR,
  };
}

export function* doSetLogo(action) {
  const { accountId, image } = action;
  let res;
  try {
    res = yield call(uploadLogo, `accounts/${accountId}/logos`, image);
  } catch (err) {
    yield put({
      type: ACCOUNTS_SET_LOGO_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: ACCOUNTS_SET_LOGO_SUCCESS,
    data: res,
    accountId,
  });
}

export function* watchSetLogo() {
  yield takeLatest(ACCOUNTS_SET_LOGO_BEGIN, doSetLogo);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNTS_SET_LOGO_BEGIN:
      return {
        ...state,
        setLogoPending: true,
        setLogoError: null,
      };

    case ACCOUNTS_SET_LOGO_SUCCESS: {
      const { accountId, data } = action;
      const foundAccount = state.entities.find(account => account.id === accountId);
      const updatedAccount = { ...foundAccount, logoUrl: data };

      return {
        ...state,
        entities: [
          ...state.entities.filter(account => account.id !== accountId),
          updatedAccount,
        ],
        setLogoPending: false,
        setLogoError: null,
      };
    }

    case ACCOUNTS_SET_LOGO_FAILURE:
      return {
        ...state,
        setLogoPending: false,
        setLogoError: action.data.error,
      };

    case ACCOUNTS_SET_LOGO_DISMISS_ERROR:
      return {
        ...state,
        setLogoError: null,
      };

    default:
      return state;
  }
}
