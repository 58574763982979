import { call, put, takeLatest } from 'redux-saga/effects';
import { protectedJsonDelete } from 'src/utils/api';
import {
  PEOPLE_DELETE_UNAVAILABILITY_BEGIN,
  PEOPLE_DELETE_UNAVAILABILITY_SUCCESS,
  PEOPLE_DELETE_UNAVAILABILITY_FAILURE,
  PEOPLE_DELETE_UNAVAILABILITY_DISMISS_ERROR,
} from './constants';

export function deleteUnavailability(accountId, personId, data, analyticsPayload) {
  return {
    type: PEOPLE_DELETE_UNAVAILABILITY_BEGIN,
    accountId,
    personId,
    data,
    analyticsPayload,
  };
}

export function dismissDeleteUnavailabilityError() {
  return {
    type: PEOPLE_DELETE_UNAVAILABILITY_DISMISS_ERROR,
  };
}

export function* doDeleteUnavailability(action) {
  const { accountId, personId, data, analyticsPayload } = action;
  try {
    yield call(protectedJsonDelete, `accounts/${accountId}/persons/${personId}/unavailabilities`, data);
  } catch (err) {
    yield put({
      type: PEOPLE_DELETE_UNAVAILABILITY_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PEOPLE_DELETE_UNAVAILABILITY_SUCCESS,
    personId,
    data,
    analyticsPayload,
  });
}

export function* watchDeleteUnavailability() {
  yield takeLatest(PEOPLE_DELETE_UNAVAILABILITY_BEGIN, doDeleteUnavailability);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_DELETE_UNAVAILABILITY_BEGIN:
      return {
        ...state,
        deleteUnavailabilityPending: true,
        deleteUnavailabilityError: null,
      };

    case PEOPLE_DELETE_UNAVAILABILITY_SUCCESS: {
      const { personId, data } = action;
      const { filteredPeople, unavailabilities, personSelections } = state;

      const newFilteredPeople = filteredPeople.map((person) => {
        if (person.id === personId) {
          const pous = person.unavailabilities.filter(pou => !data.includes(pou.id));
          return {
            ...person,
            unavailabilities: pous,
          };
        }
        return person;
      });

      const newPous = unavailabilities.map((person) => {
        if (personId === person.personId) {
          const updatedPous = person.unavailabilities.filter(pou => !data.includes(pou.id));
          return {
            ...person,
            unavailabilities: updatedPous,
          };
        }
        return person;
      });

      const newPersonSelections = { ...personSelections };
      Object.keys(newPersonSelections).forEach((selectionId) => {
        const selectedPerson = { ...newPersonSelections[selectionId] };
        if (selectedPerson && selectedPerson.id === personId) {
          selectedPerson.unavailabilities = selectedPerson.unavailabilities.filter(pou => !data.includes(pou.id));
        }

        newPersonSelections[selectionId] = selectedPerson;
      });

      return {
        ...state,
        unavailabilities: newPous,
        deleteUnavailabilityPending: false,
        deleteUnavailabilityError: null,
        filteredPeople: newFilteredPeople,
        personSelections: newPersonSelections,
      };
    }

    case PEOPLE_DELETE_UNAVAILABILITY_FAILURE:
      return {
        ...state,
        deleteUnavailabilityPending: false,
        deleteUnavailabilityError: action.data.error,
      };

    case PEOPLE_DELETE_UNAVAILABILITY_DISMISS_ERROR:
      return {
        ...state,
        deleteUnavailabilityError: null,
      };

    default:
      return state;
  }
}
