import { defaultFilters, PEOPLE_FILTER_TYPE, PROJECT_FILTER_TYPE } from '../features/filters/common/constants';
import {
  PERM_ACCOUNT,
  PERM_ASSIGNMENT,
  PERM_HOURLY_PERSON,
  PERM_PERSON,
  PERM_PROJECT,
  PERM_READ,
  PERM_REQUEST,
  PERM_ROLE,
} from '../features/permissions/utils/constants';
import {
  FILTERED_ASSIGNMENTS_QUERY_ID,
  FILTERED_HOURLY_PERSONS_QUERY_ID,
  FILTERED_PEOPLE_FORECAST_QUERY_ID,
  FILTERED_PEOPLE_QUERY_ID,
  FILTERED_PROJECTS_QUERY_ID,
  FILTERED_PROJECT_DASHBOARD_QUERY_ID,
  FILTERED_PROJECT_FORECAST_QUERY_ID,
  FILTERED_UNFILLED_ROLES_QUERY_ID,
} from '../features/queries/redux/constants';
import { SKILL_SET_FIELD } from '../features/self-perform/constants';
import { PEOPLE_DISPLAY_COST_RATE } from '../features/people/constants';

export const FIELD_TYPE_SINGLE_SELECT = 'SingleSelect';
export const FIELD_TYPE_MULTI_SELECT = 'MultiSelect';
export const FIELD_TYPE_TEXT = 'Text';
export const FIELD_TYPE_LONG_TEXT = 'LongText';
export const FIELD_TYPE_CURRENCY = 'Currency';
export const FIELD_TYPE_ADDRESS = 'Address';
export const FIELD_TYPE_DATE = 'Date';
export const FIELD_TYPE_PHONE = 'PhoneNumber';
export const FIELD_TYPE_PHONE_E164 = 'Phone';
export const FIELD_TYPE_BOOLEAN = 'Boolean';

export const NOTES_FIELD = 'Notes';
export const HOME_ADDRESS_FIELD = 'Home Address';
export const PHONE_NUMBER_FIELD = 'Phone Number';
export const CELL_NUMBER_FIELD = 'Cell number';
export const PURSUIT_TRACKING_FIELD = 'Type';
export const EMAIL_FIELD = 'Email';

export const FIELD_TYPE_CUSTOM_ORDER = [SKILL_SET_FIELD];

export const EMPTY_FIELD_VALUE = '-';

export const BASE_COLUMN_COUNT = 6;

export const COMMON_FIELD_TYPES = [
  { display: 'Text', value: 'Text', name: 'text' },
  { display: 'Single list selection', value: FIELD_TYPE_SINGLE_SELECT, name: 'single-list-selection' },
  { display: 'Multi list selection', value: FIELD_TYPE_MULTI_SELECT, name: 'multi-list-selection' },
  { display: 'Date selector', value: FIELD_TYPE_DATE, name: 'date-selector' },
  { display: 'Checkbox', value: 'Boolean', name: 'checkbox' },
  { display: 'Phone', value: FIELD_TYPE_PHONE_E164, name: 'phone-number' },
  { display: '$ Value', value: FIELD_TYPE_CURRENCY, name: 'currency' },
  { display: 'Address', value: 'Address', name: 'address' },
];

export const FIELD_INPUT_TYPE = {
  [FIELD_TYPE_SINGLE_SELECT]: 'select',
  [FIELD_TYPE_MULTI_SELECT]: 'select',
  [FIELD_TYPE_TEXT]: 'text',
  [FIELD_TYPE_LONG_TEXT]: 'text',
  [FIELD_TYPE_CURRENCY]: 'currency',
  [FIELD_TYPE_ADDRESS]: 'address',
  [FIELD_TYPE_DATE]: 'date',
  Boolean: 'checkbox',
};

export const FieldTypeMap = {
  Text: 'Text',
  SingleSelect: 'Single list selection',
  MultiSelect: 'Multi list selection',
  DateSelector: 'Date selector',
  Boolean: 'Checkbox',
  PhoneNumber: 'Phone number',
  LongText: 'Long text',
  Date: 'Date selector',
  Email: 'Email',
  Image: 'Image',
  Currency: '$ Value',
  Address: 'Address',
  Phone: 'Phone',
};

export const FIELDS_WITH_TOOLTIPS = {
  [PEOPLE_DISPLAY_COST_RATE]: 'This field may inherit a default cost rate when it\'s left blank',
};

export const DATE_INPUT_FORMAT = 'YYYY-MM-DD';
export const DATE_DISPLAY_FORMAT = 'MMM D, YYYY';
export const MONTH_YEAR_FORMAT = 'MMM YYYY';
export const MONTH_DAY_RANGE_FORMAT = 'MMM DD';
export const MONTH_YEAR_SHORT_FORMAT = 'MMM \'YY';
export const TIME_DISPLAY_FORMAT = 'LT';
export const NOTIFY_MODAL_TIMESTAMP_FORMAT = 'MMM DD, YYYY [at] h:mm A';
export const SHARED_GANTT_EXPIRATION_TIMESTAMP_FORMAT = 'dddd MMMM DD, h:mma';

export const DAY_MAP = ['Su', 'M', 'T', 'W', 'Th', 'F', 'S'];

// Max number that can be stored in 52 bits without losing precision (assuming two decimal places)
// Output of Math.pow(2, 52) / 100
export const MAX_DOLLAR_VALUE = 45035996273704.96;

export const PERM_SUPER_ADMIN = 'Super Admin';
export const PERM_BRIDGIT_ADMIN = 'Bridgit Admin';
export const PERM_BRIDGIT_ADMIN_VIEW_ONLY = 'Bridgit Admin - View Only';

// Modules
export const ACCOUNT_MODULE_STANDARD = 'Standard';
export const ACCOUNT_MODULE_HOURLY = 'Hourly';
export const ACCOUNT_MODULE_PURSUIT_TRACKING = 'Pursuit Tracking';
export const ACCOUNT_MODULE_ASSIGNMENT_COMMUNICATION = 'Assignment Communication';
export const ACCOUNT_MODULE_ATTACHMENTS = 'Attachments';
export const ACCOUNT_MODULE_CERTIFICATIONS = 'Certifications';
export const ACCOUNT_MODULE_FINANCIAL_TRACKING = 'Financial Tracking';
export const ACCOUNT_MODULE_LOCATION = 'Location';
export const ACCOUNT_MODULE_MAPS = 'Maps';

// Components
export const COMPONENT_ASSIGNMENT_COMMUNICATION_EMAIL = 'Email';
export const PEOPLE_ACCOUNT_MODULE_COMPONENT = 'People';

export const LIST_TAB = 0;
export const GANTT_TAB = 1;
export const SELF_PERFORM_TAB = 2;
export const MAP_TAB = 3;

export const PROJECT_TABS = [
  {
    value: LIST_TAB,
    label: 'List',
    action: PERM_READ,
    subject: PERM_PROJECT,
  },
  {
    value: GANTT_TAB,
    label: 'Gantt',
    action: PERM_READ,
    subject: PERM_ROLE,
  },
  {
    value: MAP_TAB,
    label: 'Map',
    action: PERM_READ,
    subject: PERM_PROJECT,
    module: ACCOUNT_MODULE_MAPS,
  },
];

export const PEOPLE_TABS = [
  {
    value: LIST_TAB,
    label: 'List',
    action: PERM_READ,
    subject: PERM_PERSON,
    module: ACCOUNT_MODULE_STANDARD,
  },
  {
    value: GANTT_TAB,
    label: 'Gantt',
    action: PERM_READ,
    subject: PERM_PERSON,
    module: ACCOUNT_MODULE_STANDARD,
  },
  {
    value: SELF_PERFORM_TAB,
    label: 'Self-Perform',
    action: PERM_READ,
    subject: PERM_HOURLY_PERSON,
    module: ACCOUNT_MODULE_HOURLY,
  },
];

export const ROLES_TAB_VALUE = 0;
export const SELF_PERFORM_TAB_VALUE = 1;
export const DETAILS_TAB_VALUE = 2;
export const DATES_TAB_VALUE = 3;

export const PROJECT_DETAIL_TABS = [
  {
    value: ROLES_TAB_VALUE,
    label: 'roles',
    action: PERM_READ,
    subject: PERM_ROLE,
    module: ACCOUNT_MODULE_STANDARD,
  },
  {
    value: SELF_PERFORM_TAB_VALUE,
    label: 'self-perform',
    action: PERM_READ,
    subject: PERM_ASSIGNMENT,
    module: ACCOUNT_MODULE_HOURLY,
  },
  {
    value: DETAILS_TAB_VALUE,
    label: 'details',
    action: PERM_READ,
    subject: PERM_PROJECT,
  },
  {
    value: DATES_TAB_VALUE,
    label: 'dates',
    action: PERM_READ,
    subject: PERM_PROJECT,
  },
];

export const CONTENT_VIEW_MAP = {
  [LIST_TAB]: 'list',
  [GANTT_TAB]: 'gantt',
  [SELF_PERFORM_TAB]: 'self-perform',
  [MAP_TAB]: 'map',
  list: LIST_TAB,
  gantt: GANTT_TAB,
  'self-perform': SELF_PERFORM_TAB,
  map: MAP_TAB,
};

export const DASHBOARD_OVERVIEW_TAB = 0;
export const FORECAST_TAB = 1;

export const DASHBOARD_TABS = [
  {
    value: DASHBOARD_OVERVIEW_TAB,
    label: 'Dashboard',
    action: PERM_READ,
    subject: PERM_ACCOUNT,
  },
  {
    value: FORECAST_TAB,
    label: 'Forecasting',
    action: PERM_READ,
    subject: PERM_ACCOUNT,
  },
];

export const DASHBOARD_TAB_MAP = {
  [FORECAST_TAB]: 'forecasting',
  [DASHBOARD_OVERVIEW_TAB]: 'snapshot',
  forecasting: FORECAST_TAB,
  snapshot: DASHBOARD_OVERVIEW_TAB,
};

export const REQUESTS_TAB = 0;
export const ASSIGNMENTS_TAB = 1;

export const SELF_PERFORM_TAB_MAP = {
  [REQUESTS_TAB]: 'requests',
  [ASSIGNMENTS_TAB]: 'assignments',
  requests: REQUESTS_TAB,
  assignments: ASSIGNMENTS_TAB,
};

export const SELF_PERFORM_TABS = [
  {
    value: REQUESTS_TAB,
    label: SELF_PERFORM_TAB_MAP[REQUESTS_TAB],
    action: PERM_READ,
    subject: PERM_REQUEST,
  },
  {
    value: ASSIGNMENTS_TAB,
    label: SELF_PERFORM_TAB_MAP[ASSIGNMENTS_TAB],
    action: PERM_READ,
    subject: PERM_ASSIGNMENT,
  },
];

export const HOURLY_PERSON_PROFILE_ASSIGNMENTS_TAB = 0;
export const HOURLY_PERSON_PROFILE_DETAILS_TAB = 1;

export const PEOPLE_SELF_PERFORM_TAB_MAP = {
  [HOURLY_PERSON_PROFILE_ASSIGNMENTS_TAB]: 'Assignments',
  [HOURLY_PERSON_PROFILE_DETAILS_TAB]: 'Details',
};

export const PEOPLE_SELF_PERFORM_TABS = [
  {
    value: HOURLY_PERSON_PROFILE_ASSIGNMENTS_TAB,
    label: PEOPLE_SELF_PERFORM_TAB_MAP[HOURLY_PERSON_PROFILE_ASSIGNMENTS_TAB],
    action: PERM_READ,
    subject: PERM_HOURLY_PERSON,
  },
  {
    value: HOURLY_PERSON_PROFILE_DETAILS_TAB,
    label: PEOPLE_SELF_PERFORM_TAB_MAP[HOURLY_PERSON_PROFILE_DETAILS_TAB],
    action: PERM_READ,
    subject: PERM_HOURLY_PERSON,
  },
];

export const PEOPLE_VIEW = 0;
export const PROJECT_VIEW = 1;
export const DEACTIVATED_VIEW = 2;
export const ROLES_VIEW = 3;
export const ACTIVE_USERS = 4;
export const FORECAST_VIEW = 5;
export const DASHBOARD_VIEW = 6;
export const PAPM_VIEW = 7;
export const SELF_PERFORM_REQUESTS_VIEW = 8;
export const PEOPLE_HOURLY_PERSONS_VIEW = 9;
export const SELF_PERFORM_ASSIGNMENTS_VIEW = 10;
export const ROLE_SEGMENT_VIEW = 11;

export const VIEWS = {
  [PEOPLE_VIEW]: 'People',
  [PROJECT_VIEW]: 'Project',
  [DEACTIVATED_VIEW]: 'DeactivatedPeople',
  [ROLES_VIEW]: 'Roles',
  [ACTIVE_USERS]: 'ActiveUsers',
  [DASHBOARD_VIEW]: 'Dashboard',
  [FORECAST_VIEW]: 'Forecast',
  [PAPM_VIEW]: 'Place a Person',
  [SELF_PERFORM_REQUESTS_VIEW]: 'SelfPerformRequests',
  [SELF_PERFORM_ASSIGNMENTS_VIEW]: 'SelfPerformAssignments',
  [PEOPLE_HOURLY_PERSONS_VIEW]: 'PeopleHourlyPersons',
  [ROLE_SEGMENT_VIEW]: 'RoleSegments',
};

export const PAGE_NAMES = {
  [PEOPLE_VIEW]: 'People',
  [PROJECT_VIEW]: 'Project',
  [DEACTIVATED_VIEW]: 'Deactivated People',
  [ROLES_VIEW]: 'Roles',
  [ACTIVE_USERS]: 'Active Users',
  [DASHBOARD_VIEW]: 'Dashboard',
  [FORECAST_VIEW]: 'Forecasting',
  [PAPM_VIEW]: 'Place a Person',
  [SELF_PERFORM_REQUESTS_VIEW]: 'Self Perform Requests',
  [SELF_PERFORM_ASSIGNMENTS_VIEW]: 'Self Perform Assignments',
  [PEOPLE_HOURLY_PERSONS_VIEW]: 'People Hourly Persons',
  [ROLE_SEGMENT_VIEW]: 'Roles View',
};

export const QUERY_ID_BY_VIEW = {
  [PEOPLE_VIEW]: {
    [PEOPLE_FILTER_TYPE]: FILTERED_PEOPLE_QUERY_ID,
  },
  [PEOPLE_HOURLY_PERSONS_VIEW]: {
    [PEOPLE_FILTER_TYPE]: FILTERED_HOURLY_PERSONS_QUERY_ID,
  },
  [PROJECT_VIEW]: {
    [PROJECT_FILTER_TYPE]: FILTERED_PROJECTS_QUERY_ID,
  },
  [DASHBOARD_VIEW]: {
    [PROJECT_FILTER_TYPE]: FILTERED_PROJECT_DASHBOARD_QUERY_ID,
  },
  [FORECAST_VIEW]: {
    [PEOPLE_FILTER_TYPE]: FILTERED_PEOPLE_FORECAST_QUERY_ID,
    [PROJECT_FILTER_TYPE]: FILTERED_PROJECT_FORECAST_QUERY_ID,
  },
  [SELF_PERFORM_ASSIGNMENTS_VIEW]: {
    [PROJECT_FILTER_TYPE]: FILTERED_ASSIGNMENTS_QUERY_ID,
  },
  [ROLE_SEGMENT_VIEW]: {
    [PROJECT_FILTER_TYPE]: FILTERED_UNFILLED_ROLES_QUERY_ID,
  },
};

export const RELATED_VIEWS = {
  [PEOPLE_FILTER_TYPE]: [PEOPLE_VIEW, FORECAST_VIEW],
  [PROJECT_FILTER_TYPE]: [PROJECT_VIEW, DASHBOARD_VIEW, FORECAST_VIEW],
};

export const DEFAULT_FILTER_BY_VIEW = {
  [PEOPLE_VIEW]: {
    [PEOPLE_FILTER_TYPE]: defaultFilters.people,
  },
  [PEOPLE_HOURLY_PERSONS_VIEW]: {
    [PEOPLE_FILTER_TYPE]: defaultFilters.hourlyPersons,
  },
  [PROJECT_VIEW]: {
    [PROJECT_FILTER_TYPE]: defaultFilters.projects,
  },
  [DASHBOARD_VIEW]: {
    [PROJECT_FILTER_TYPE]: defaultFilters.dashboard,
  },
  [FORECAST_VIEW]: {
    [PEOPLE_FILTER_TYPE]: defaultFilters.forecastPeople,
    [PROJECT_FILTER_TYPE]: defaultFilters.forecastProject,
  },
  [SELF_PERFORM_ASSIGNMENTS_VIEW]: {
    [PROJECT_FILTER_TYPE]: defaultFilters.requests,
  },
  [ROLE_SEGMENT_VIEW]: {
    [PROJECT_FILTER_TYPE]: defaultFilters.projects,
  },
};

export const STORAGE_KEYS_BY_VIEW = {
  [PEOPLE_VIEW]: {
    [PEOPLE_FILTER_TYPE]: 'filterPeopleColumns',
  },
  [PEOPLE_HOURLY_PERSONS_VIEW]: {
    [PEOPLE_FILTER_TYPE]: 'filterPeopleHourlyPersonsColumns',
  },
  [PROJECT_VIEW]: {
    [PROJECT_FILTER_TYPE]: 'filterProjectColumns',
  },
  [DASHBOARD_VIEW]: {
    [PROJECT_FILTER_TYPE]: 'filterDashboardColumns',
  },
  [FORECAST_VIEW]: {
    [PEOPLE_FILTER_TYPE]: 'Forecast-people-filter',
    [PROJECT_FILTER_TYPE]: 'Forecast-project-filter',
  },
  [SELF_PERFORM_ASSIGNMENTS_VIEW]: {
    [PROJECT_FILTER_TYPE]: 'filterSelfPerformAssignmentsColumns',
  },
  [ROLE_SEGMENT_VIEW]: {
    [PROJECT_FILTER_TYPE]: 'filterRoleSegments',
  },
};

export const PEOPLE_HOURLY_PERSONS_LABEL = 'People';

export const SIMPLE_PROJECT_GANTT = 0;
export const EXPANDED_PROJECT_GANTT = 1;

export const PHASES_LIMIT = 20;
export const SUB_PHASES_LIMIT = 20;

export const PEOPLE_LIST_STATIC_COLUMNS = ['photo', 'name'];
export const PROJECT_LIST_STATIC_COLUMNS = ['project name'];
export const HOURLY_PERSONS_LIST_STATIC_COLUMNS = ['name'];

export const DEFAULT_BACKOFF_TIME = 1000;
export const PAGE_SIZE = 50;
export const ALLOCATION_MODAL_PAGE_SIZE = 25;
export const AUDIT_PAGE_SIZE = 15;

export const MAX_SIZE = 99999;
export const MIN_FROM = 0;

export const MAX_DATA_RANGE_IN_YEARS = 5;
export const MIN_DATA_RANGE_IN_MONTHS = 6;

export const DOT = '\u25CF';

export const DAILY_BREAKDOWN_DESC = {
  underAllocated: {
    label: 'Under Allocated',
  },
  fullyAllocated: {
    label: 'Fully Allocated',
  },
  overAllocated: {
    label: 'Over Allocated',
  },
  unavailable: {
    label: 'Unavailable',
  },
};

export const LABEL_TIME_OFF = 'Time Off';
export const LABEL_FULL_AVAILABLE = '100% Available';

export const DEFAULT_POU_START = '0001-01-01';
export const DEFAULT_POU_END = '9999-12-31';

export const PRE_EMPLOYMENT = 'PreEmployment';
export const POST_EMPLOYMENT = 'PostEmployment';
export const UNAVAILABILITY = 'Unavailability';
export const TIME_OFF = 'TimeOff';

export const TIME_OFF_LABEL_MAPPING = {
  [PRE_EMPLOYMENT]: 'Future hire',
  [UNAVAILABILITY]: 'Time off',
};

export const MIN_ALLOCATION_PERCENT = 1;
export const MAX_ALLOCATION_PERCENT = 100;

export const MAX_CONFIGURABLE_DATE = '2100-01-01';
export const MIN_CONFIGURABLE_DATE = '1900-01-01';
export const MIN_API_CONFIGURABLE_DATE = '2000-01-01';

export const MIN_CONFIGURABLE_DATE_MESSAGE = 'Date cannot be before Jan 1, 1900';
export const MIN_API_CONFIGURABLE_DATE_MESSAGE = 'Date cannot be before Jan 1, 2000';
export const MAX_CONFIGURABLE_DATE_MESSAGE = 'Date cannot be after Jan 1, 2100';

export const EDIT_ACTION_EXTENDED = 'Extended';
export const EDIT_ACTION_COLLAPSED = 'Collapsed';
export const EDIT_ACTION_SHIFTED = 'Shifted';
export const DATE_EDIT_ACTIONS = [EDIT_ACTION_COLLAPSED, EDIT_ACTION_EXTENDED, EDIT_ACTION_SHIFTED];

export const START_DATE = 'start';
export const END_DATE = 'end';

export const SAVE = 'Save';
export const CANCEL = 'Cancel';

export const CHECKBOX_TYPE = 'checkbox';
export const SWITCH_TYPE = 'switch';

export const BRIDGIT_SUPPORT_EMAIL = 'support@gobridgit.com';

export const DASHBOARD_NO_ROLES_PLACEHOLDER = 'No roles defined';

export const UNFILLED = 'Unfilled';

// App location names for logging purposes
export const PROJECT_DETAILS_MODAL = 'Project Details Modal';
export const PROJECT_DATES = 'Project Dates';
export const PROJECT_GANTT = 'Project Gantt';

export const INVALID_STATUS_FILTER = 'invalidStatusFilter';

export const REQUEST_ADDED_FROM_MAP = {
  [SELF_PERFORM_REQUESTS_VIEW]: 'Request List',
  [PROJECT_VIEW]: 'Project',
};

export const SORT_TYPES = {
  REQUEST_LIST: 'REQUEST_LIST',
  ASSIGNMENT_LIST: 'ASSIGNMENT_LIST',
};

export const WEEK_LENGTH = 7;

export const MAX_NOTE_LENGTH = 5000;
export const MAX_NOTES = 1000;

export const LIST = 'List';
export const GANTT = 'Gantt';

export const PRIVATE_MODE_TOOLTIP_TEXT = 'This information has been hidden for privacy. Click “view confidential fields” in the top right to display this information.';

export const APP_HEADER_HEIGHT = 89;

export const GEO_JSON_FEATURE = 'Feature';
export const GEO_JSON_POINT = 'Point';

export const MIN_PERCENTAGE_VALUE = 0;
export const MAX_PERCENTAGE_VALUE = 100;

export const COLOR = 'Color';
export const PERCENTAGE = 'Percentage';
