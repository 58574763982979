import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { BRIDGIT_LOGO } from 'src/features/common/constants';
import { printReport, preloadImage } from './utils/reportUtils';
import { setImagePreloadPending } from './redux/actions';

const ReportTemplate = ({
  coverPage,
  report,
  header,
  title,
  setImagePreloadPending,
  showReportInfo,
  pageHeader,
}) => {
  const today = moment.utc().format('MMMM D, YYYY');

  // Preload Bridgit logo and print report.
  useEffect(() => {
    const imagePreloadCallback = () => {
      setImagePreloadPending(false);
      printReport();
    };

    setImagePreloadPending(true);
    preloadImage(BRIDGIT_LOGO, imagePreloadCallback);
  }, [setImagePreloadPending]);

  return (
    <div className="reports-report-template">
      {coverPage}

      <div className="footer">
        <img className="footer-bridgit-logo" src={BRIDGIT_LOGO} alt="bridgit logo" />
        <div className="bridgit-info">
          <div className="bridgit-name">Bridgit Bench</div>
          <div className="bridgit-url">www.gobridgit.com</div>
        </div>
      </div>

      {pageHeader}

      <table>
        {pageHeader && (
          <thead>
            <tr>
              <td>
                <div className="header-space" />
              </td>
            </tr>
          </thead>
        )}

        <tbody>
          <tr>
            <td>
              {showReportInfo && (
                <div className="report-info">
                  <h2>{header}</h2>
                  <div className="report-title">{title}</div>
                  <div className="date">{today}</div>
                </div>
              )}
              {report}
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td>
              <div className="footer-space" />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

ReportTemplate.propTypes = {
  coverPage: PropTypes.element,
  report: PropTypes.element,
  pageHeader: PropTypes.element,
  header: PropTypes.string,
  title: PropTypes.string,
  setImagePreloadPending: PropTypes.func,
  showReportInfo: PropTypes.bool,
};

ReportTemplate.defaultProps = {
  coverPage: null,
  report: null,
  pageHeader: null,
  header: '',
  title: '',
  setImagePreloadPending: () => {},
  showReportInfo: true,
};

function mapDispatchToProps(dispatch) {
  return {
    setImagePreloadPending: bindActionCreators(setImagePreloadPending, dispatch),
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(ReportTemplate);
