export const GANTT_COLUMN_WIDTH = 200;
export const GANTT_MINIMUM_COLUMNS = 6;
export const GANTT_SET_GANTT_CONFIG = 'GANTT_SET_GANTT_CONFIG';
export const PEOPLE_GANTT_CONFIG_KEY = 'people';
export const PEOPLE_GANTT_CONFIG_KEY_V2 = 'people-gantt-v2';
export const PROJECT_GANTT_CONFIG_KEY = 'project-gantt';
export const DEFAULT_GANTT_SCALE = 2;
export const DEFAULT_PROJECT_ROLE_SORT_BY_OPTION = 'startDate';

export const GANTT_CONTROLS_DEFAULT_OPTIONS = {
  showZoom: true,
  showChips: true,
  showProjectView: false,
  showPeopleFilters: false,
};

export const GANTT_GET_GANTT_PEOPLE_BEGIN = 'GANTT_GET_GANTT_PEOPLE_BEGIN';
export const GANTT_GET_GANTT_PEOPLE_SUCCESS = 'GANTT_GET_GANTT_PEOPLE_SUCCESS';
export const GANTT_GET_GANTT_PEOPLE_FAILURE = 'GANTT_GET_GANTT_PEOPLE_FAILURE';
export const GANTT_GET_GANTT_PEOPLE_DISMISS_ERROR = 'GANTT_GET_GANTT_PEOPLE_DISMISS_ERROR';
export const GANTT_SET_GANTT_CONFIG_SETTINGS = 'GANTT_SET_GANTT_CONFIG_SETTINGS';
export const GANTT_GET_PEOPLE_GANTT_SHARE_DATA_BEGIN = 'GANTT_GET_PEOPLE_GANTT_SHARE_DATA_BEGIN';
export const GANTT_GET_PEOPLE_GANTT_SHARE_DATA_SUCCESS = 'GANTT_GET_PEOPLE_GANTT_SHARE_DATA_SUCCESS';
export const GANTT_GET_PEOPLE_GANTT_SHARE_DATA_FAILURE = 'GANTT_GET_PEOPLE_GANTT_SHARE_DATA_FAILURE';
export const GANTT_GET_PEOPLE_GANTT_SHARE_DATA_DISMISS_ERROR = 'GANTT_GET_PEOPLE_GANTT_SHARE_DATA_DISMISS_ERROR';
export const GANTT_SET_PEOPLE_GANTT_SHARE_DATA_BEGIN = 'GANTT_SET_PEOPLE_GANTT_SHARE_DATA_BEGIN';
export const GANTT_SET_PEOPLE_GANTT_SHARE_DATA_SUCCESS = 'GANTT_SET_PEOPLE_GANTT_SHARE_DATA_SUCCESS';
export const GANTT_SET_PEOPLE_GANTT_SHARE_DATA_FAILURE = 'GANTT_SET_PEOPLE_GANTT_SHARE_DATA_FAILURE';
export const GANTT_SET_PEOPLE_GANTT_SHARE_DATA_DISMISS_ERROR = 'GANTT_SET_PEOPLE_GANTT_SHARE_DATA_DISMISS_ERROR';
export const GANTT_SET_PEOPLE_GANTT_CONFIG_SETTINGS = 'GANTT_SET_PEOPLE_GANTT_CONFIG_SETTINGS';
export const GANTT_CLEAR_PEOPLE_GANTT_SHARE_EXPIRY_DATE = 'GANTT_CLEAR_PEOPLE_GANTT_SHARE_EXPIRY_DATE';
