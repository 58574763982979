import {
  MUST,
  MUST_NOT,
  BLANK_FILTER,
} from './constants';

const buildQuery = (activeFilter) => {
  const should = [];
  const mainShould = [];

  const { inclusive, selected } = activeFilter;
  const boolStatement = inclusive ? MUST : MUST_NOT;

  const nonBlankFilters = selected.filter(item => item.value !== BLANK_FILTER);

  nonBlankFilters.forEach((nonBlankFilter) => {
    should.push({
      match: {
        projects__id: nonBlankFilter.value,
      },
    });
  });

  if (should.length) {
    mainShould.push({
      nested: {
        path: 'projects',
        query: {
          bool: {
            must: [
              {
                bool: {
                  should,
                },
              },
            ],
          },
        },
      },
    });
  }


  if (selected.find(item => item.value === BLANK_FILTER)) {
    mainShould.push({
      bool: {
        must_not: [
          {
            nested: {
              path: 'projects',
              query: {
                exists: {
                  field: 'projects',
                },
              },
            },
          },
        ],
      },
    });
  }

  return {
    bool: {
      [boolStatement]: [
        {
          bool: {
            should: mainShould,
          },
        },
      ],
    },
  };
};

const generateProjectAllocationFilter = (activeFilters) => {
  const must = activeFilters.map(activeFilter => buildQuery(activeFilter));
  return {
    bool: {
      must,
    },
  };
};

export default generateProjectAllocationFilter;
