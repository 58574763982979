import { THEMES_LOAD_THEME_SETTINGS, THEMES_ADJUST_THEME_SETTINGS } from './constants';
import { setStoredThemeSettings } from '../utils';

export function triggerAdjustThemeSettings(type, themeName, settings, userId, analyticsPayload) {
  return {
    type,
    userId,
    settings,
    themeName,
    analyticsPayload,
  };
}

export function loadThemeSettings(themeName, settings, userId) {
  return triggerAdjustThemeSettings(THEMES_LOAD_THEME_SETTINGS, themeName, settings, userId);
}

export function adjustThemeSettings(themeName, settings, userId, analyticsPayload) {
  return triggerAdjustThemeSettings(THEMES_ADJUST_THEME_SETTINGS, themeName, settings, userId, analyticsPayload);
}

export function reducer(state, action) {
  switch (action.type) {
    case THEMES_LOAD_THEME_SETTINGS:
    case THEMES_ADJUST_THEME_SETTINGS: {
      const { settings, userId, themeName } = action;
      const updatedSettings = {
        ...state.settings,
        [themeName]: { ...settings },
      };

      setStoredThemeSettings(themeName, settings, userId);

      return {
        ...state,
        settings: updatedSettings,
      };
    }

    default:
      return state;
  }
}
