import { put, takeLatest, select } from 'redux-saga/effects';
import {
  DASHBOARD_GET_PROJECT_BREAKDOWN_AND_ROLES_BEGIN,
  DASHBOARD_GET_PROJECT_BREAKDOWN_AND_ROLES_SUCCESS,
  DASHBOARD_GET_PROJECT_BREAKDOWN_AND_ROLES_FAILURE,
  DASHBOARD_GET_PROJECT_BREAKDOWN_AND_ROLES_DISMISS_ERROR,
} from './constants';
import { getProjectBreakdown } from './getProjectBreakdown';
import { filteredDashboardSelector } from './selectors';
import { getUnfilledRoles } from './getUnfilledRoles';
import { accountIdSelector } from '../../common/redux/selectors';

export function getProjectBreakdownAndRoles() {
  return {
    type: DASHBOARD_GET_PROJECT_BREAKDOWN_AND_ROLES_BEGIN,
  };
}

export function dismissGetProjectBreakdownAndRolesError() {
  return {
    type: DASHBOARD_GET_PROJECT_BREAKDOWN_AND_ROLES_DISMISS_ERROR,
  };
}

export function* doGetProjectBreakdownAndRoles() {
  try {
    const accountId = yield select(accountIdSelector);
    const queries = yield select(filteredDashboardSelector);

    yield put(getProjectBreakdown(accountId, queries));
    yield put(getUnfilledRoles(accountId, queries));
  } catch (err) {
    yield put({
      type: DASHBOARD_GET_PROJECT_BREAKDOWN_AND_ROLES_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: DASHBOARD_GET_PROJECT_BREAKDOWN_AND_ROLES_SUCCESS,
  });
}

export function* watchGetProjectBreakdownAndRoles() {
  yield takeLatest(DASHBOARD_GET_PROJECT_BREAKDOWN_AND_ROLES_BEGIN, doGetProjectBreakdownAndRoles);
}

export function reducer(state, action) {
  switch (action.type) {
    case DASHBOARD_GET_PROJECT_BREAKDOWN_AND_ROLES_BEGIN:
      return {
        ...state,
        getProjectBreakdownAndRolesPending: true,
        getProjectBreakdownAndRolesError: null,
      };

    case DASHBOARD_GET_PROJECT_BREAKDOWN_AND_ROLES_SUCCESS:
      return {
        ...state,
        getProjectBreakdownAndRolesPending: false,
        getProjectBreakdownAndRolesError: null,
      };

    case DASHBOARD_GET_PROJECT_BREAKDOWN_AND_ROLES_FAILURE:
      return {
        ...state,
        getProjectBreakdownAndRolesPending: false,
        getProjectBreakdownAndRolesError: action.data.error,
      };

    case DASHBOARD_GET_PROJECT_BREAKDOWN_AND_ROLES_DISMISS_ERROR:
      return {
        ...state,
        getProjectBreakdownAndRolesError: null,
      };

    default:
      return state;
  }
}
