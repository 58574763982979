import { call, put, takeLatest } from 'redux-saga/effects';
import { protectedJsonPatch } from 'src/utils/api';
import {
  DEFAULT_POU_END,
  UNAVAILABILITY,
  TIME_OFF,
} from 'src/common/constants';
import {
  PEOPLE_UPDATE_UNAVAILABILITY_BEGIN,
  PEOPLE_UPDATE_UNAVAILABILITY_SUCCESS,
  PEOPLE_UPDATE_UNAVAILABILITY_FAILURE,
  PEOPLE_UPDATE_UNAVAILABILITY_DISMISS_ERROR,
} from './constants';
import { handleZeroPercentBlock } from '../../allocations/utils/allocationUtils';

export function updateUnavailability(accountId, personId, data, analyticsPayload) {
  return {
    type: PEOPLE_UPDATE_UNAVAILABILITY_BEGIN,
    accountId,
    personId,
    data,
    analyticsPayload,
  };
}

export function dismissUpdateUnavailabilityError() {
  return {
    type: PEOPLE_UPDATE_UNAVAILABILITY_DISMISS_ERROR,
  };
}

export function* doUpdateUnavailability(action) {
  const { accountId, personId, data, analyticsPayload } = action;
  const formattedData = data.map(pou => (
    {
      ...pou,
      endDate: pou.endDate || DEFAULT_POU_END,
    }
  ));
  let res;
  try {
    res = yield call(protectedJsonPatch, `accounts/${accountId}/persons/${personId}/unavailabilities`, formattedData);
  } catch (err) {
    yield put({
      type: PEOPLE_UPDATE_UNAVAILABILITY_FAILURE,
      data: { error: err },
    });
    return;
  }
  yield put({
    type: PEOPLE_UPDATE_UNAVAILABILITY_SUCCESS,
    accountId,
    personId,
    data: res,
    analyticsPayload,
  });
}

export function* watchUpdateUnavailability() {
  yield takeLatest(PEOPLE_UPDATE_UNAVAILABILITY_BEGIN, doUpdateUnavailability);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_UPDATE_UNAVAILABILITY_BEGIN:
      return {
        ...state,
        updateUnavailabilityPending: true,
        updateUnavailabilityError: null,
      };

    case PEOPLE_UPDATE_UNAVAILABILITY_SUCCESS: {
      const { personId, data } = action;
      const { filteredPeople, personSelections, unavailabilities } = state;
      const { id } = data[0];
      const newUnavailability = {
        ...data[0],
        __typename: UNAVAILABILITY,
      };

      const newFilteredPeople = filteredPeople.map((person) => {
        if (person.id === personId) {
          const { projectAllocations } = person;
          const newUnavailabilities = person.unavailabilities.map((pou) => {
            if (pou.id === id) {
              return newUnavailability;
            }
            return pou;
          });
          return {
            ...person,
            ...(newUnavailability.rangeType !== TIME_OFF && {
              projectAllocations: handleZeroPercentBlock(projectAllocations, data),
            }),
            unavailabilities: newUnavailabilities,
          };
        }
        return person;
      });

      const newPous = unavailabilities.map((person) => {
        if (personId === person.personId) {
          const updatedPous = person.unavailabilities.map((pou) => {
            if (pou.id === id) {
              return newUnavailability;
            }
            return pou;
          });
          return {
            ...person,
            unavailabilities: updatedPous,
          };
        }
        return person;
      });

      const newPersonSelections = { ...personSelections };
      Object.keys(newPersonSelections).forEach((selectionId) => {
        const selectedPerson = personSelections[selectionId];
        if (selectedPerson && selectedPerson.id === personId) {
          const { unavailabilities } = selectedPerson;
          const newPous = unavailabilities.map((pou) => {
            if (pou.id === id) {
              return newUnavailability;
            }
            return pou;
          });

          newPersonSelections[selectionId] = {
            ...selectedPerson,
            unavailabilities: newPous,
          };
        }
      });

      return {
        ...state,
        unavailabilities: newPous,
        updateUnavailabilityPending: false,
        updateUnavailabilityError: null,
        filteredPeople: newFilteredPeople,
        personSelections: newPersonSelections,
      };
    }

    case PEOPLE_UPDATE_UNAVAILABILITY_FAILURE:
      return {
        ...state,
        updateUnavailabilityPending: false,
        updateUnavailabilityError: action.data.error,
      };

    case PEOPLE_UPDATE_UNAVAILABILITY_DISMISS_ERROR:
      return {
        ...state,
        updateUnavailabilityError: null,
      };

    default:
      return state;
  }
}
