import {
  FILTERS_SET_POPPER_IS_OPEN,
} from './constants';

export function setPopperIsOpen(popperIsOpen) {
  return {
    type: FILTERS_SET_POPPER_IS_OPEN,
    popperIsOpen,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FILTERS_SET_POPPER_IS_OPEN: {
      const { popperIsOpen } = action;
      const { filterMenu } = state;
      return {
        ...state,
        filterMenu: {
          ...filterMenu,
          popperIsOpen,
        },
      };
    }

    default:
      return state;
  }
}
