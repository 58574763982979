import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNT_SETTINGS_REMOVE_ROLE_NAMES_BEGIN,
  ACCOUNT_SETTINGS_REMOVE_ROLE_NAMES_SUCCESS,
  ACCOUNT_SETTINGS_REMOVE_ROLE_NAMES_FAILURE,
  ACCOUNT_SETTINGS_REMOVE_ROLE_NAMES_DISMISS_ERROR,
} from './constants';
import { protectedJsonDelete } from '../../../utils/api';

export function removeRoleNames(accountId, roleIds) {
  return {
    type: ACCOUNT_SETTINGS_REMOVE_ROLE_NAMES_BEGIN,
    accountId,
    roleIds,
  };
}

export function dismissRemoveRoleNamesError() {
  return {
    type: ACCOUNT_SETTINGS_REMOVE_ROLE_NAMES_DISMISS_ERROR,
  };
}

export function* doRemoveRoleNames(action) {
  const { accountId, roleIds } = action;
  try {
    yield call(protectedJsonDelete, `accounts/${accountId}/role-names`, roleIds);
  } catch (err) {
    yield put({
      type: ACCOUNT_SETTINGS_REMOVE_ROLE_NAMES_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: ACCOUNT_SETTINGS_REMOVE_ROLE_NAMES_SUCCESS,
    roleIds,
  });
}

export function* watchRemoveRoleNames() {
  yield takeLatest(ACCOUNT_SETTINGS_REMOVE_ROLE_NAMES_BEGIN, doRemoveRoleNames);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNT_SETTINGS_REMOVE_ROLE_NAMES_BEGIN:
      return {
        ...state,
        removeRoleNamesPending: true,
        removeRoleNamesError: null,
      };

    case ACCOUNT_SETTINGS_REMOVE_ROLE_NAMES_SUCCESS:
      return {
        ...state,
        roles: state.roles.filter(role => action.roleIds.indexOf(role.id) === -1),
        removeRoleNamesPending: false,
        removeRoleNamesError: null,
      };

    case ACCOUNT_SETTINGS_REMOVE_ROLE_NAMES_FAILURE:
      return {
        ...state,
        removeRoleNamesPending: false,
        removeRoleNamesError: action.data.error,
      };

    case ACCOUNT_SETTINGS_REMOVE_ROLE_NAMES_DISMISS_ERROR:
      return {
        ...state,
        removeRoleNamesError: null,
      };

    default:
      return state;
  }
}
