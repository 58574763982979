import { call, put, takeLatest } from 'redux-saga/effects';
import {
  FORECAST_GET_PURSUIT_PROJECTS_BEGIN,
  FORECAST_GET_PURSUIT_PROJECTS_SUCCESS,
  FORECAST_GET_PURSUIT_PROJECTS_FAILURE,
  FORECAST_GET_PURSUIT_PROJECTS_DISMISS_ERROR,
} from './constants';
import { getPursuitProjectsQuery } from '../queries/getPursuitProjectsQuery';
import graphApiClient from '../../../common/GraphAPIClient';

export function getPursuitProjects(accountId) {
  const { query, variables } = getPursuitProjectsQuery(accountId);
  return {
    type: FORECAST_GET_PURSUIT_PROJECTS_BEGIN,
    query,
    variables,
  };
}

export function dismissGetPursuitProjectsError() {
  return {
    type: FORECAST_GET_PURSUIT_PROJECTS_DISMISS_ERROR,
  };
}

export function* doGetPursuitProjects(action) {
  const { query, variables } = action;
  let res;
  try {
    res = yield call(graphApiClient.query, { query, variables });
  } catch (err) {
    yield put({
      type: FORECAST_GET_PURSUIT_PROJECTS_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: FORECAST_GET_PURSUIT_PROJECTS_SUCCESS,
    data: res.data,
  });
}

export function* watchGetPursuitProjects() {
  yield takeLatest(FORECAST_GET_PURSUIT_PROJECTS_BEGIN, doGetPursuitProjects);
}

export function reducer(state, action) {
  switch (action.type) {
    case FORECAST_GET_PURSUIT_PROJECTS_BEGIN:
      return {
        ...state,
        getPursuitProjectsPending: true,
        getPursuitProjectsError: null,
      };

    case FORECAST_GET_PURSUIT_PROJECTS_SUCCESS:
      return {
        ...state,
        pursuitProjects: action?.data?.projects?.items,
        getPursuitProjectsPending: false,
        getPursuitProjectsError: null,
      };

    case FORECAST_GET_PURSUIT_PROJECTS_FAILURE:
      return {
        ...state,
        getPursuitProjectsPending: false,
        getPursuitProjectsError: action.data.error,
      };

    case FORECAST_GET_PURSUIT_PROJECTS_DISMISS_ERROR:
      return {
        ...state,
        getPursuitProjectsError: null,
      };

    default:
      return state;
  }
}
