import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import deepEqual from 'react-fast-compare';

import { useDebouncedEffect } from './useDebouncedEffect';
import { adjustThemeSettings } from './redux/actions';
import { DARK_THEME, DIM_THEME } from './constants';
import { ThemeConfigurator, SystemThemeSwitch, DimmedModeSwitch } from '.';
import { getStoredThemeSettings } from './utils';
import { DEBOUNCE_DURATION } from '../filters/redux/constants';

const ThemeSettings = () => {
  const dispatch = useDispatch();
  const userId = useSelector(state => state.login.userInfo.sub || null);
  const darkModeSettings = useSelector(state => state.themes.settings[DARK_THEME]);
  const dimModeSettings = useSelector(state => state.themes.settings[DIM_THEME]);
  const themeName = useSelector(state => state.themes.theme);
  const isUsingSystemTheme = useSelector(state => state.themes.isUsingSystemTheme || false);

  const [uncommittedDarkModeSettings, setUncommittedDarkModeSettings] = useState(darkModeSettings);
  const [uncommittedDimModeSettings, setUncommittedDimModeSettings] = useState(dimModeSettings);
  const [updatedThemeSetting, setUpdatedThemeSetting] = useState();

  useDebouncedEffect(() => {
    if (!deepEqual(uncommittedDarkModeSettings, darkModeSettings)) {
      dispatch(adjustThemeSettings(DARK_THEME, uncommittedDarkModeSettings, userId, { updatedThemeSetting }));
    }
  }, [dispatch, userId, uncommittedDarkModeSettings, updatedThemeSetting], DEBOUNCE_DURATION);

  useDebouncedEffect(() => {
    if (!deepEqual(uncommittedDimModeSettings, dimModeSettings)) {
      dispatch(adjustThemeSettings(DIM_THEME, uncommittedDimModeSettings, userId, { updatedThemeSetting }));
    }
  }, [dispatch, userId, uncommittedDimModeSettings, updatedThemeSetting], DEBOUNCE_DURATION);

  useEffect(() => {
    const storedDarkModeSettings = getStoredThemeSettings(userId, DARK_THEME);

    if (storedDarkModeSettings) setUncommittedDarkModeSettings(storedDarkModeSettings);
  }, [userId]);

  useEffect(() => {
    const storedDimModeSettings = getStoredThemeSettings(userId, DIM_THEME);

    if (storedDimModeSettings) setUncommittedDimModeSettings(storedDimModeSettings);
  }, [userId]);

  const handleChange = (themeName, settingName) => (evt, val) => {
    if (themeName === DARK_THEME) {
      setUncommittedDarkModeSettings({ ...uncommittedDarkModeSettings, [settingName]: val });
      setUpdatedThemeSetting(settingName);
    }

    if (themeName === DIM_THEME) {
      setUncommittedDimModeSettings({ ...uncommittedDimModeSettings, [settingName]: val });
      setUpdatedThemeSetting(settingName);
    }
  };

  const isDarkModeOn = useMemo(() => themeName === DARK_THEME, [themeName]);
  const isDimModeOn = useMemo(() => themeName === DIM_THEME, [themeName]);

  return (
    <div className="theme-settings-container">
      <SystemThemeSwitch />

      <DimmedModeSwitch />

      <ThemeConfigurator
        themeName={DARK_THEME}
        disabled={!isDarkModeOn || isUsingSystemTheme}
        onChange={handleChange}
        config={uncommittedDarkModeSettings}
      />

      <ThemeConfigurator
        themeName={DIM_THEME}
        disabled={!isDimModeOn || isUsingSystemTheme}
        onChange={handleChange}
        config={uncommittedDimModeSettings}
      />
    </div>
  );
};

ThemeSettings.propTypes = {

};

export default ThemeSettings;
