const AUTOMATION_SELECT_ALL_CHECKBOX = 'AUTOMATION_SELECT_ALL_CHECKBOX';
const AUTOMATION_SIDENAV_ICON_PREFIX = 'AUTOMATION_SIDENAV_ICON_';
const AUTOMATION_POPPER_MENU = 'AUTOMATION_POPPER_MENU';
const AUTOMATION_EMPTY_REQUESTER_TEXT = 'AUTOMATION_EMPTY_REQUESTER_TEXT';
const AUTOMATION_TOP_BAR_MENU_ICON = 'AUTOMATION_TOP_BAR_MENU_ICON';
const AUTOMATION_CONTENT_ITEM = 'AUTOMATION_CONTENT_ITEM';
const AUTOMATION_CONTENT_TITLE = 'AUTOMATION_CONTENT_TITLE';

export {
  AUTOMATION_SELECT_ALL_CHECKBOX,
  AUTOMATION_SIDENAV_ICON_PREFIX,
  AUTOMATION_POPPER_MENU,
  AUTOMATION_EMPTY_REQUESTER_TEXT,
  AUTOMATION_TOP_BAR_MENU_ICON,
  AUTOMATION_CONTENT_ITEM,
  AUTOMATION_CONTENT_TITLE,
};
