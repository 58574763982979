import React from 'react';
import classnames from 'classnames';
import { naturalSort } from 'src/utils/sortUtils';

import {
  PROJECT_LOG_RESOURCE_TYPES,
  PROJECT_CREATED,
  PROJECT_UPDATED,
  PROJECT_FILED_SET,
  PROJECT_FIELD_CLEARED,
  PHASE_ADDED,
  PHASE_UPDATED,
  PHASE_DELETED,
  SUB_PHASE_ADDED,
  SUB_PHASE_UPDATED,
  SUB_PHASE_DELETED,
  PROJECT_NOTE_ADDED,
  PROJECT_NOTE_UPDATED,
  PROJECT_NOTE_DELETED,
  ROLE_ADDED,
  ROLE_UPDATED,
  ROLE_DELETED,
  ALLOCATION_SET,
  ALLOCATION_DELETED,
} from '../constants';
import { capitalize, getFormattedFieldValue } from '../../../utils/formatters';
import { getFormattedDate, getFormattedDateAndTime } from '../../../utils/dateUtils';

export const getProjectAuditItemExpandedAnalytics = (isExpanded, eventData, selectedProject, isOpenInModal) => {
  if (!isExpanded) {
    const { id, name, state } = selectedProject;
    const viewedFrom = isOpenInModal ? 'Project details modal' : 'Project profile';
    const { userName, actionType, eventOn } = eventData;

    return {
      'Project name': name,
      'Project id': id,
      'Project status': state,
      'Viewed from': viewedFrom,
      'Expanded entry type': actionType,
      'Expanded entry date': getFormattedDate(eventOn),
      'Expanded entry user': userName,
    };
  }
  return null;
};

export const getProjectLogEventName = (resourceType, actionType) => PROJECT_LOG_RESOURCE_TYPES[resourceType][actionType];

const getProjectFields = (name, colour, startDate, endDate, state, type) => [
  { name: 'Project Name', value: name },
  { name: 'Colour', value: colour },
  { name: 'Project Start Date', value: getFormattedDate(startDate) },
  { name: 'Project End Date', value: getFormattedDate(endDate) },
  { name: 'State', value: state },
  { name: 'Type', value: type },
];

const getSubPhaseFields = (name, startDate, endDate, isNestedField) => [
  { name: `${isNestedField ? '○' : ''} Sub-phase Name`, value: name },
  { name: 'Sub-phase Start Date', value: getFormattedDate(startDate) },
  { name: 'Sub-phase End Date', value: getFormattedDate(endDate) },
];

const getProjectNotesFields = (message, isPrivate, creatorName, createdOn, lastModifiedOn) => [
  { name: 'Message', value: message },
  { name: 'isPrivate', value: capitalize(`${isPrivate}`) },
  { name: 'Creator Name', value: creatorName },
  { name: 'Created on', value: getFormattedDate(createdOn) },
  { name: 'Last modified on', value: getFormattedDate(lastModifiedOn) },
];

const getPhaseFields = (name, startDate, endDate) => [
  { name: 'Phase Name', value: name },
  { name: 'Phase Start Date', value: getFormattedDate(startDate) },
  { name: 'Phase End Date', value: getFormattedDate(endDate) },
];

const getBaseRolesFields = (startDate, endDate, isFilled) => [
  { name: 'Role Start Date', value: getFormattedDate(startDate) },
  { name: 'Role End Date', value: getFormattedDate(endDate) },
  { name: 'isFilled', value: capitalize(`${isFilled}`) },
];

const getFieldValues = fields => fields.map(({ name, value, isNestedField, isNestedValue }) => (
  <div key={name} className="projects-project-audit-details-row">
    {isNestedField ? (
      <span className="projects-project-audit-details-nested">{`○ ${name}: `}</span>
    ) : (
      <span className="projects-project-audit-details-label">{`${name}: `}</span>
    )}

    {isNestedValue ? (
      <div className="projects-project-audit-details-nested">
        <div className="projects-project-audit-details-row">{value ?? ''}</div>
      </div>
    ) : (
      <span>{value ?? ''}</span>
    )}
  </div>
));

const getCustomAllocationBreakdown = allocations => allocations.map(allocation => (
  <div key={allocation.startDate}>
    <span className="projects-project-audit-details-percentage">{`${allocation.allocatedPercent}%`}</span>
    <span>{`${getFormattedDate(allocation.startDate)} to ${getFormattedDate(allocation.endDate)}`}</span>
  </div>
));

const getCommonAllocationFields = (allocations, personName, roleName) => {
  const sortedAllocations = naturalSort(allocations, ['startDate', 'endDate']);
  const lastAllocationIdx = sortedAllocations.length - 1;
  const startDate = sortedAllocations?.[0]?.startDate;
  const endDate = sortedAllocations?.[lastAllocationIdx].endDate;

  let allocationBreakdown = getFieldValues([
    { name: 'Name', value: personName },
    { name: 'Role Name', value: roleName },
    { name: 'Allocation Start Date', value: getFormattedDate(startDate) },
    { name: 'Allocation End Date', value: getFormattedDate(endDate) },
  ]);

  if (sortedAllocations.length > 1) {
    const customAllocationSetValues = getCustomAllocationBreakdown(sortedAllocations);
    allocationBreakdown = [
      ...allocationBreakdown,
      ...getFieldValues([{ name: 'Custom allocation set', value: customAllocationSetValues }]),
    ];
  } else {
    allocationBreakdown = [
      ...allocationBreakdown,
      ...getFieldValues([{ name: 'Allocation %', value: sortedAllocations?.[0]?.allocatedPercent }]),
    ];
  }

  return allocationBreakdown;
};

const getRoleContent = ({ allocations, note, isCommunicated, startDate, endDate, isFilled }) => {
  const baseRoleFieldsConfig = getBaseRolesFields(startDate, endDate, isFilled);
  const baseRoleFields = getFieldValues(baseRoleFieldsConfig);
  const hasSplitAllocations = allocations.length > 1;
  const customAllocationSetValues = getCustomAllocationBreakdown(allocations);
  const splitAllocationFields = getFieldValues([{ name: 'Custom allocation set', value: customAllocationSetValues }]);

  const nonSplitAllocationsDatesMarkup = (
    <>
      <p>{`Start Date: ${getFormattedDate(allocations?.[0].startDate)}`}</p>
      <p>{`End Date: ${getFormattedDate(allocations?.[0].endDate)}`}</p>
    </>
  );

  const nonSplitAllocationFields = getFieldValues([
    { name: 'Allocations', value: nonSplitAllocationsDatesMarkup },
    { name: 'Allocation percentage', value: `${allocations?.[0].allocatedPercent}%` },
  ]);

  const allocationFields = hasSplitAllocations ? splitAllocationFields : nonSplitAllocationFields;

  const restRoleFields = getFieldValues([
    { name: 'Note', value: note },
    { name: 'Communicated', value: capitalize(`${isCommunicated}`) },
  ]);

  return [baseRoleFields, allocationFields, restRoleFields];
};

export const renderEventDetails = (eventName, eventData) => {
  const {
    name,
    colour,
    startDate,
    endDate,
    state,
    type,
    data,
    message,
    isPrivate,
    creatorName,
    createdOn,
    lastModifiedOn,
    allocations,
    person,
    role,
    __typename,
  } = eventData || {};

  switch (eventName) {
    case PROJECT_CREATED:
    case PROJECT_UPDATED: {
      const projectFields = getProjectFields(name, colour, startDate, endDate, state, type);

      return getFieldValues(projectFields);
    }

    case PROJECT_FILED_SET:
    case PROJECT_FIELD_CLEARED: {
      return (
        <div className="projects-project-audit-details">
          {data.map(field => (
            <div key={field.fieldId}>
              <p className="projects-project-audit-details-row">
                <span className={classnames('projects-project-audit-details-label', { 'deleted-field': !field.fieldName })}>
                  {`${field.fieldName || 'Deleted field'}: `}
                </span>
                <span>{getFormattedFieldValue(field.values, field.fieldType)}</span>
              </p>
            </div>
          ))}
        </div>
      );
    }

    case PHASE_ADDED:
    case PHASE_UPDATED:
    case PHASE_DELETED: {
      return (
        <div className="projects-project-audit-details">
          {data.map((phase) => {
            const phaseFieldsConfig = getPhaseFields(phase.name, phase.startDate, phase.endDate);
            const phaseFields = getFieldValues(phaseFieldsConfig);
            const subPhaseFields = phase.subPhases.map((subPhase) => {
              const subPhaseFieldsConfig = getSubPhaseFields(subPhase.name, subPhase.startDate, subPhase.endDate, true);

              return (
                <div key={subPhase.id} className="projects-project-audit-details-nested">
                  {getFieldValues(subPhaseFieldsConfig)}
                </div>
              );
            });

            return [phaseFields, subPhaseFields];
          })}
        </div>
      );
    }

    case SUB_PHASE_ADDED:
    case SUB_PHASE_UPDATED:
    case SUB_PHASE_DELETED: {
      return (
        <div className="projects-project-audit-details">
          {data.map((subPhase) => {
            const subPhaseFieldsConfig = getSubPhaseFields(subPhase.name, subPhase.startDate, subPhase.endDate);

            return getFieldValues(subPhaseFieldsConfig);
          })}
        </div>
      );
    }

    case PROJECT_NOTE_ADDED:
    case PROJECT_NOTE_UPDATED:
    case PROJECT_NOTE_DELETED: {
      const notesFields = getProjectNotesFields(message, isPrivate, creatorName, createdOn, lastModifiedOn);

      return getFieldValues(notesFields);
    }

    case ROLE_ADDED:
    case ROLE_UPDATED:
    case ROLE_DELETED: {
      const isBulkData = __typename.includes('Bulk');
      const normalizedEventData = isBulkData ? data : [eventData];

      return normalizedEventData.length > 1 // show roles as collapsible/expandable
        ? normalizedEventData.map(event => (
          <details key={event.id}>
            <summary>
              {getFieldValues([{ name: 'Role name', value: event.name }])}
            </summary>
            {getRoleContent(event)}
          </details>
        ))
        : (
          <div className="projects-project-audit-details">
            {getFieldValues([{ name: 'Role name', value: normalizedEventData[0].name }])}

            {getRoleContent(normalizedEventData[0])}
          </div>
        );
    }

    case ALLOCATION_SET: {
      const allocationBreakdown = getCommonAllocationFields(allocations, person?.name, role?.name);

      return [allocationBreakdown];
    }

    case ALLOCATION_DELETED: {
      const allocationBreakdown = getCommonAllocationFields(allocations, person?.name, role?.name);
      const isCommunicated = role && role.isCommunicated !== null ? capitalize(`${role.isCommunicated}`) : null;

      const additionalAllocationFields = getFieldValues([
        { name: 'Last notified on', value: role?.notification?.notifiedOn ? getFormattedDateAndTime(role.notification.notifiedOn) : null },
        { name: 'Communicated', value: isCommunicated },
      ]);

      return [allocationBreakdown, additionalAllocationFields];
    }

    default:
      return null;
  }
};
