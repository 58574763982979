import React, { useCallback, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Slider } from '@material-ui/core';

import { DISTANCE_FILTER_RANGE, EMPTY_RADIUS } from './common/constants';
import { DISTANCE_UNITS } from '../accounts/common/constants';
import { PERM_PRIVATE, PERM_READ } from '../permissions/utils/constants';
import { ACCOUNT_MODULE_LOCATION, HOME_ADDRESS_FIELD } from '../../common/constants';
import { Can } from '../wrapped-components';
import { setRadius } from './redux/actions';
import { convertDistanceToMeters } from '../../utils/formatters';

const { MIN, MAX } = DISTANCE_FILTER_RANGE;
const { KM, MI } = DISTANCE_UNITS;

const DistanceFromProjectCandidateFilter = ({ onChangeCommitted }) => {
  const [value, setValue] = useState(null);
  const [committedValue, setCommittedValue] = useState(null);

  const dispatch = useDispatch();

  const { radius } = useSelector(({ allocations }) => allocations);
  const { accountId } = useSelector(({ common }) => common);
  const isAddressPrivate = useSelector(({ accountSettings: { personFields } }) => (
    personFields.find(({ name }) => name === HOME_ADDRESS_FIELD)?.isPrivate ?? true
  ));
  const selectedUnit = useSelector(({ accounts: { entities } }) => {
    const { useMetricUnits } = entities.find(({ id }) => id === accountId);

    return useMetricUnits ? KM : MI;
  });

  // Reset the slider when the radius is reset
  useEffect(() => {
    if (radius === EMPTY_RADIUS) {
      setValue(MAX);
      setCommittedValue(MAX);
    }
  }, [radius]);

  const renderRadius = useCallback(() => {
    const radiusDisplay = committedValue === MAX ? `${MAX}+` : committedValue;

    return `${radiusDisplay} ${selectedUnit.value}`;
  }, [selectedUnit, committedValue]);

  const changeHandler = useCallback((_, newValue) => {
    setValue(newValue);
  }, []);

  const changeCommittedHandler = useCallback((_, newValue) => {
    const newRadius = newValue === MAX
      ? EMPTY_RADIUS
      // Round up in case we compute fractional meters (e.g. when converting miles to meters)
      : Math.ceil(convertDistanceToMeters(newValue, selectedUnit.conversionFactorMetric));

    dispatch(setRadius(newRadius));
    setCommittedValue(newValue);
    onChangeCommitted(newValue);
  }, [selectedUnit, dispatch, onChangeCommitted]);

  const distanceFilter = useMemo(() => (
    <div className="distance-from-project-candidate-filter">
      <div className="distance-from-project-candidate-filter-label">Distance from project:</div>
      <div className="current-radius-value">{renderRadius()}</div>
      <div className="distance-slider-container">
        <div className="distance-slider-label-start">{MIN}</div>
        <Slider
          min={MIN}
          max={MAX}
          value={value}
          onChange={changeHandler}
          onChangeCommitted={changeCommittedHandler}
          valueLabelDisplay="auto"
        />
        <div className="distance-slider-label-end">{`${MAX}+`}</div>
      </div>
    </div>
  ), [changeCommittedHandler, changeHandler, renderRadius, value]);

  return (
    <Can
      module={ACCOUNT_MODULE_LOCATION}
      action={PERM_READ}
      subject={isAddressPrivate ? PERM_PRIVATE : null}
      yes={distanceFilter}
    />
  );
};

DistanceFromProjectCandidateFilter.propTypes = {
  onChangeCommitted: PropTypes.func,
};

DistanceFromProjectCandidateFilter.defaultProps = {
  onChangeCommitted: () => null,
};

export default DistanceFromProjectCandidateFilter;
