import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNT_SETTINGS_GET_PERSON_FIELDS_BEGIN,
  ACCOUNT_SETTINGS_GET_PERSON_FIELDS_SUCCESS,
  ACCOUNT_SETTINGS_GET_PERSON_FIELDS_FAILURE,
  ACCOUNT_SETTINGS_GET_PERSON_FIELDS_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';

export function getPersonFields(accountId) {
  return {
    type: ACCOUNT_SETTINGS_GET_PERSON_FIELDS_BEGIN,
    accountId,
  };
}

export function dismissGetPersonFieldsError() {
  return {
    type: ACCOUNT_SETTINGS_GET_PERSON_FIELDS_DISMISS_ERROR,
  };
}

// worker Saga: will be fired on PEOPLE_GET_PERSON_FIELDS_BEGIN actions
export function* doGetPersonFields(action) {
  const { accountId } = action;
  let res;
  try {
    res = yield call(protectedJsonGet, `accounts/${accountId}/person-fields`);
  } catch (err) {
    yield put({
      type: ACCOUNT_SETTINGS_GET_PERSON_FIELDS_FAILURE,
      data: { error: err },
    });
    return;
  }
  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: ACCOUNT_SETTINGS_GET_PERSON_FIELDS_SUCCESS,
    data: res,
  });
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchGetPersonFields() {
  yield takeLatest(ACCOUNT_SETTINGS_GET_PERSON_FIELDS_BEGIN, doGetPersonFields);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNT_SETTINGS_GET_PERSON_FIELDS_BEGIN:
      return {
        ...state,
        getPersonFieldsPending: true,
        getPersonFieldsError: null,
      };

    case ACCOUNT_SETTINGS_GET_PERSON_FIELDS_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        personFields: data || [],
        getPersonFieldsPending: false,
        getPersonFieldsError: null,
      };
    }

    case ACCOUNT_SETTINGS_GET_PERSON_FIELDS_FAILURE:
      return {
        ...state,
        getPersonFieldsPending: false,
        getPersonFieldsError: action.data.error,
      };

    case ACCOUNT_SETTINGS_GET_PERSON_FIELDS_DISMISS_ERROR:
      return {
        ...state,
        getPersonFieldsError: null,
      };

    default:
      return state;
  }
}
