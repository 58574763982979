const initialState = {
  personFields: [],
  hourlyPersonFields: [],
  skillSet: [],
  projectFields: [],
  roles: [],
  phases: [],
  certifications: [],
  selectedCertification: {},
  deactivatedPeople: [],
  accountProperties: {
    enr400: false,
    country: null,
    provinceState: null,
    demo: false,
  },
  modules: [],
  accountModules: [],
  accountLimits: {},
  deactivatedPeopleCount: 0,
  getPersonFieldsPending: false,
  getPersonFieldsError: null,
  removePersonFieldsPending: false,
  removePersonFieldsError: null,
  addPersonFieldsPending: false,
  addPersonFieldsError: null,
  updatePersonFieldsPending: false,
  updatePersonFieldsError: null,
  getProjectFieldsPending: false,
  getProjectFieldsError: null,
  updateProjectFieldsPending: false,
  updateProjectFieldsError: null,
  addProjectFieldsPending: false,
  addProjectFieldsError: null,
  removeProjectFieldsPending: false,
  removeProjectFieldsError: null,
  getRoleNamesPending: false,
  getRoleNamesError: null,
  updateRoleNamesPending: false,
  updateRoleNamesError: null,
  removeRoleNamesPending: false,
  removeRoleNamesError: null,
  getPhasesPending: false,
  getPhasesError: null,
  addPhasePending: false,
  addPhaseError: null,
  deletePhasePending: false,
  deletePhaseError: null,
  updatePhasePending: false,
  updatePhaseError: null,
  addSubPhasePending: false,
  addSubPhaseError: null,
  deleteSubPhasePending: false,
  deleteSubPhaseError: null,
  updateSubPhasePending: false,
  updateSubPhaseError: null,
  updatePhasesPending: false,
  updatePhasesError: null,
  updateSubPhasesPending: false,
  updateSubPhasesError: null,
  collectFieldsPending: false,
  collectFieldsError: null,
  getAccountPropertiesPending: false,
  getAccountPropertiesError: null,
  updateAccountPropertiesPending: false,
  updateAccountPropertiesError: null,
  addAccountModulePending: false,
  addAccountModuleError: null,
  getAccountModulesPending: false,
  getAccountModulesError: null,
  removeAccountModulePending: false,
  removeAccountModuleError: null,
  getModulesPending: false,
  getModulesError: null,
  getHourlyPersonFieldsPending: false,
  getHourlyPersonFieldsError: null,
  updateHourlyPersonFieldsPending: false,
  updateHourlyPersonFieldsError: null,
  getSkillSetPending: false,
  getSkillSetError: null,
  updateSkillSetPending: false,
  updateSkillSetError: null,
  removeSkillPending: false,
  removeSkillError: null,
  addHourlyPersonFieldsPending: false,
  addHourlyPersonFieldsError: null,
  removeHourlyPersonFieldsPending: false,
  removeHourlyPersonFieldsError: null,
  updateAccountModulePending: false,
  updateAccountModuleError: null,
  getAccountLimitsPending: false,
  getAccountLimitsError: null,
  getAccountCertificationsPending: false,
  getAccountCertificationsError: null,
  addAccountCertificationPending: false,
  addAccountCertificationError: null,
  updateAccountCertificationPending: false,
  updateAccountCertificationError: null,
  deleteAccountCertificationPending: false,
  deleteAccountCertificationError: null,
  getAccountCertificationByIdPending: false,
  getAccountCertificationByIdError: null,
};

export default initialState;
