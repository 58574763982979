import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { AppBar } from '@material-ui/core';
import { PermissionTabs } from '../wrapped-components';

export default class TabBar extends PureComponent {
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    labels: PropTypes.array.isRequired,
    value: PropTypes.string.isRequired,
    collapse: PropTypes.bool.isRequired,
    inclusive: PropTypes.bool,
  };

  static defaultProps = {
    inclusive: false,
  }

  render() {
    const { handleChange, labels, value, collapse, inclusive } = this.props;

    return (
      <AppBar classes={{ root: `common-tab-bar app-page-offset ${collapse ? 'collapse' : ''}` }} color="inherit" position="fixed">
        <PermissionTabs
          tabs={labels}
          value={value}
          onChange={handleChange}
          inclusive={inclusive}
        />
      </AppBar>
    );
  }
}
