import {
  GANTT_SET_GANTT_CONFIG,
  GANTT_GET_PEOPLE_GANTT_SHARE_DATA_SUCCESS,
  GANTT_GET_PEOPLE_GANTT_SHARE_DATA_FAILURE,
  GANTT_SET_PEOPLE_GANTT_SHARE_DATA_SUCCESS,
} from '../../features/gantt/redux/constants';

import {
  PEOPLE_GANTT_SHARE_DATA_LOADED,
  PEOPLE_GANTT_SHARE_LINK_GENERATED,
} from './constants';

const events = {
  [GANTT_SET_GANTT_CONFIG]: {
    getEvent: (_, { analyticsPayload }) => analyticsPayload?.configChanged,
    getPayload: (_, { analyticsPayload }) => analyticsPayload?.payload,
  },
  [GANTT_GET_PEOPLE_GANTT_SHARE_DATA_SUCCESS]: {
    getEvent: () => PEOPLE_GANTT_SHARE_DATA_LOADED,
    getPayload: (_, { key }) => ({
      Link: key,
      Active: true,
    }),
  },
  [GANTT_GET_PEOPLE_GANTT_SHARE_DATA_FAILURE]: {
    getEvent: () => PEOPLE_GANTT_SHARE_DATA_LOADED,
    getPayload: (_, { analyticsPayload }) => ({
      Link: analyticsPayload?.key,
      Active: false,
    }),
  },
  [GANTT_SET_PEOPLE_GANTT_SHARE_DATA_SUCCESS]: {
    getEvent: () => PEOPLE_GANTT_SHARE_LINK_GENERATED,
    getPayload: (_, { analyticsPayload }) => ({
      Link: analyticsPayload?.key,
    }),
  },
};

export default events;
