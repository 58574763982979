import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Select, MenuItem } from '@material-ui/core';

const ModalSidePanel = ({
  className,
  options,
  activePanel,
  onChange,
  children,
}) => {
  const renderValue = (option) => {
    const { value, display } = option;
    return <MenuItem key={value} value={option}>{display}</MenuItem>;
  };

  if (!options.length || !activePanel) return null;

  return (
    <div className={classNames('common-modal-side-panel', className)}>
      <div className="common-modal-side-panel-header">
        {activePanel.icon}
        {options.length > 1
          ? (
            <Select
              className="common-modal-panel-select"
              value={activePanel}
              onChange={onChange}
            >
              {options.map(option => renderValue(option))}
            </Select>
          )
          : <div className="common-modal-panel-select">{activePanel.display}</div>}
      </div>
      {children}
    </div>
  );
};

ModalSidePanel.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    display: PropTypes.string,
    eventName: PropTypes.string,
    icon: PropTypes.node,
  })),
  activePanel: PropTypes.shape({
    value: PropTypes.number,
    display: PropTypes.string,
    eventName: PropTypes.string,
    icon: PropTypes.node,
  }).isRequired,
  onChange: PropTypes.func,
  children: PropTypes.node,
};

ModalSidePanel.defaultProps = {
  className: '',
  options: [],
  onChange: () => {},
  children: null,
};

export default ModalSidePanel;
