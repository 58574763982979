import {
  ACCOUNTS_QUERY_ID,
  ACTIVE_USERS_QUERY_ID,
  DASHBOARD_UNFILLED_ROLES_QUERY_ID,
  DEACTIVATED_PEOPLE_QUERY_ID,
  INVITED_USERS_QUERY_ID,
  FILTERED_UNFILLED_ROLES_QUERY_ID,
} from './constants';

const initialState = {
  [ACCOUNTS_QUERY_ID]: {},
  [ACTIVE_USERS_QUERY_ID]: {},
  [INVITED_USERS_QUERY_ID]: {},
  [DEACTIVATED_PEOPLE_QUERY_ID]: {},
  [DASHBOARD_UNFILLED_ROLES_QUERY_ID]: {},
  [FILTERED_UNFILLED_ROLES_QUERY_ID]: {},
  filteredPeople: {},
  savedFilters: [],
  getSavedFiltersPending: false,
  getSavedFiltersError: null,
  addSavedFilterPending: false,
  addSavedFilterError: null,
  deleteSavedFilterPending: false,
  deleteSavedFilterError: null,
};

export default initialState;
