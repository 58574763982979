import moment from 'moment';
import humanizeDuration from 'humanize-duration';
import { NO_AVAILABILITY } from 'src/filters/constants';

import {
  DATE_INPUT_FORMAT,
  DEFAULT_POU_START,
  DEFAULT_POU_END,
  PRE_EMPLOYMENT,
  POST_EMPLOYMENT,
  DOT,
} from 'src/common/constants';

const updatePersonField = (person, data) => {
  const existingField = person.fields.find(f => f.fieldId === data.id);
  let newFieldValues;
  if (existingField) {
    newFieldValues = person.fields.map((field) => {
      if (field.fieldId === data.id) {
        return {
          ...field,
          values: data.values,
          __typename: 'Field',
        };
      }
      return field;
    });
  } else {
    newFieldValues = [...person.fields, data];
  }
  return {
    ...person,
    fields: newFieldValues,
  };
};

const updatePousByEmploymentDates = (person, employmentDates) => {
  if (employmentDates) {
    let pous = person.unavailabilities ? [...person.unavailabilities] : [];
    const { startDate, endDate } = employmentDates;
    if ('startDate' in employmentDates) {
      if (startDate) {
        const startPou = pous.find(pou => pou.rangeType === PRE_EMPLOYMENT);
        if (startPou) {
          startPou.endDate = moment(startDate).subtract(1, 'day').format(DATE_INPUT_FORMAT);
          pous = pous.map((pou) => {
            if (pou.rangeType === PRE_EMPLOYMENT) return startPou;
            return pou;
          });
        } else {
          pous.push({
            description: '',
            startDate: DEFAULT_POU_START,
            endDate: moment(startDate).subtract(1, 'day').format(DATE_INPUT_FORMAT),
            isPrivate: false,
            rangeType: PRE_EMPLOYMENT,
            __typename: 'Unavailability',
          });
        }
      } else {
        pous = pous.filter(pou => pou.rangeType !== PRE_EMPLOYMENT);
      }
    }
    if ('endDate' in employmentDates) {
      if (endDate) {
        const endPou = pous.find(pou => pou.rangeType === POST_EMPLOYMENT);
        if (endPou) {
          endPou.startDate = moment(endDate).add(1, 'day').format(DATE_INPUT_FORMAT);
          pous = pous.map((pou) => {
            if (pou.rangeType === POST_EMPLOYMENT) return endPou;
            return pou;
          });
        } else {
          pous.push({
            description: '',
            startDate: moment(endDate).add(1, 'day').format(DATE_INPUT_FORMAT),
            endDate: DEFAULT_POU_END,
            isPrivate: false,
            rangeType: POST_EMPLOYMENT,
            __typename: 'Unavailability',
          });
        }
      } else {
        pous = pous.filter(pou => pou.rangeType !== POST_EMPLOYMENT);
      }
    }
    return {
      ...person,
      unavailabilities: pous,
    };
  }
  return { ...person };
};

const formatAvailabilityText = (availableInDays) => {
  if (availableInDays === 0) return `${DOT} Available now`;
  const duration = humanizeDuration(
    moment.duration(moment().diff(moment().add(availableInDays, 'days'))),
    { largest: 2, round: true, units: ['mo', 'd'] },
  );
  return availableInDays === NO_AVAILABILITY ? 'No availability' : `Available in ${duration}`;
};

const updatePersonNoteById = (person, noteId, data) => {
  const newNotes = person?.notes?.map((note) => {
    if (note.id === noteId) {
      return data;
    }
    return note;
  });
  return {
    ...person,
    notes: newNotes,
  };
};

export {
  updatePersonField,
  updatePousByEmploymentDates,
  formatAvailabilityText,
  updatePersonNoteById,
};
