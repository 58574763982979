import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { FieldEditor } from 'src/features/common';


export default function HourlyPersonCard({
  hourlyPerson,
  fields,
  expandedFields,
}) {
  const [expanded, setExpanded] = useState(false);

  const onClick = () => setExpanded(!expanded);

  const renderFields = fields => fields.map(item => (
    <div className="hourly-person-field" key={item.id}>
      <FieldEditor
        item={item}
        displayOnly
      />
    </div>
  ));

  if (!hourlyPerson) return null;

  const { name } = hourlyPerson;

  return (
    <div className="self-perform-hourly-person-card">
      <div>{name}</div>
      {renderFields(fields)}
      {expanded && renderFields(expandedFields)}
      <div className="hourly-person-expand" role="presentation" onClick={onClick}>
        {expanded
          ? (
            <>
              <div>Hide details</div>
              <ExpandLess />
            </>
          ) : (
            <>
              <div>See details</div>
              <ExpandMore />
            </>
          )}
      </div>
    </div>
  );
}

HourlyPersonCard.propTypes = {
  hourlyPerson: PropTypes.object,
  fields: PropTypes.arrayOf(PropTypes.object),
  expandedFields: PropTypes.arrayOf(PropTypes.object),
};

HourlyPersonCard.defaultProps = {
  hourlyPerson: null,
  fields: [],
  expandedFields: [],
};
