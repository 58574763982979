import {
  SELF_PERFORM_CLEAR_SELECTED_HOURLY_PERSON,
} from './constants';

export function clearSelectedHourlyPerson() {
  return {
    type: SELF_PERFORM_CLEAR_SELECTED_HOURLY_PERSON,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case SELF_PERFORM_CLEAR_SELECTED_HOURLY_PERSON:
      return {
        ...state,
        selectedHourlyPerson: null,
      };

    default:
      return state;
  }
}
