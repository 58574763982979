import {
  FILTER_PROJECT_DATE,
  FILTER_PHASE,
  FILTER_ISSUES,
  FILTER_ROLES,
  FILTER_TYPE,
  FILTER_STATUS,
  FILTER_ALLOCATED_PEOPLE,
  ROLE_CONTROL_OPTIONS,
} from 'src/filters/constants';
import {
  PERM_READ,
  PERM_ROLE,
} from 'src/features/permissions/utils/constants';
import {
  ACTIVE,
  UPCOMING,
  COMPLETE,
  LOST,
  PURSUIT,
  CANCELED,
  AWARDED,
  OPPORTUNITY,
  WIN_PERCENT,
  WIN_PERCENT_LABEL,
} from 'src/features/projects/constants';
import { ACCOUNT_MODULE_PURSUIT_TRACKING, COLOR } from 'src/common/constants';

export const PROJECT_FIELDS = [
  {
    schemaName: 'id',
    displayName: 'Project ID',
    visible: false,
  },
  {
    schemaName: 'name',
    displayName: 'Project Name',
    alwaysVisible: true,
    sortGantt: true,
  },
  {
    schemaName: 'colour',
    displayName: COLOR,
    visible: false,
  },
  {
    schemaName: 'startDate',
    displayName: 'Start Date',
    defaultVisible: true,
    filter: true,
    sortGantt: true,
    filterType: FILTER_PROJECT_DATE,
  },
  {
    schemaName: 'endDate',
    displayName: 'End Date',
    defaultVisible: true,
    filter: true,
    sortGantt: true,
    filterType: FILTER_PROJECT_DATE,
  },
  {
    schemaName: 'totalRoles',
    displayName: 'Roles',
    visible: false,
    action: PERM_READ,
    subject: PERM_ROLE,
  },
  {
    schemaName: 'roles',
    displayName: 'Roles',
    defaultVisible: true,
    filter: true,
    filterType: FILTER_ROLES,
    controlOptions: ROLE_CONTROL_OPTIONS,
    isCustomSort: true,
    action: PERM_READ,
    subject: PERM_ROLE,
  },
  {
    schemaName: 'state',
    displayName: 'Status',
    defaultVisible: true,
    filter: true,
    filterType: FILTER_STATUS,
    filterOptions: [
      { name: ACTIVE, value: ACTIVE },
      { name: UPCOMING, value: UPCOMING },
      { name: COMPLETE, value: COMPLETE },
      { name: PURSUIT, value: PURSUIT },
      { name: LOST, value: LOST },
      { name: CANCELED, value: CANCELED },
    ],
  },
  {
    schemaName: 'currentPhases',
    displayName: 'Active Phase',
    defaultVisible: true,
    filter: true,
    filterType: FILTER_PHASE,
  },
  {
    schemaName: 'hasIssues',
    displayName: 'Issues',
    defaultVisible: true,
    filter: true,
    filterType: FILTER_ISSUES,
    filterOptions: [
      { name: 'Has allocation issue', value: true },
      { name: 'Does not have allocation issue', value: false },
    ],
    action: PERM_READ,
    subject: PERM_ROLE,
  },
  {
    schemaName: 'lastModifiedOn',
    visible: false,
  },
  {
    schemaName: 'allocatedPeople',
    displayName: 'Allocated People',
    visible: true,
    neverVisible: true,
    filter: true,
    filterType: FILTER_ALLOCATED_PEOPLE,
    action: PERM_READ,
    subject: PERM_ROLE,
  },
  {
    schemaName: 'type',
    displayName: 'Type',
    filter: true,
    filterType: FILTER_TYPE,
    filterOptions: [
      { name: AWARDED, value: AWARDED },
      { name: OPPORTUNITY, value: OPPORTUNITY },
    ],
  },
  {
    schemaName: WIN_PERCENT,
    displayName: WIN_PERCENT_LABEL,
    module: ACCOUNT_MODULE_PURSUIT_TRACKING,
  },
];

export const PROJECT_FIELD_DEFAULTS = {
  displayName: '',
  visible: true,
  defaultVisible: false,
  alwaysVisible: false,
  neverVisible: false,
  filter: false,
  sort: true,
  sortGantt: false,
  controlOptions: [],
  isCustomSort: false,
};

export const PROJECT_SCHEMAS = PROJECT_FIELDS.map(field => field.schemaName);

const projectDescriptor = PROJECT_FIELDS.map(field => ({
  ...PROJECT_FIELD_DEFAULTS,
  ...field,
}));

export const PROJECT_DISPLAY_NAMES = projectDescriptor.reduce((displayNames, field) => {
  if (field.displayName.length) displayNames.push(field.displayName);
  return displayNames;
}, []);

export const PROJECT_GANTT_FILTERS = projectDescriptor.reduce((allowedFilters, field) => {
  if (field.sortGantt) {
    allowedFilters.push({
      label: field.displayName,
      column: field.schemaName,
    });
  }
  return allowedFilters;
}, []);
