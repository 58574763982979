import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { Button, SplitMenu } from '@bridgit/foundation';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import pluralize from 'pluralize';
import { Divider } from '@material-ui/core';
import classNames from 'classnames';
import { ACCOUNT_MODULE_FINANCIAL_TRACKING } from 'src/common/constants';
import { getTitleCosts } from '../accounts/redux/getTitleCosts';
import { PEOPLE_DISPLAY_COST_RATE } from '../people/constants';
import { DefaultCostRateMenu } from '.';
import { hasModuleEnabled } from '../permissions/utils/permissionUtils';

const SystemDefaults = ({
  accountId,
  refProp,
}) => {
  const dispatch = useDispatch();
  const [activeMenu] = useState(PEOPLE_DISPLAY_COST_RATE);
  const { titleCosts, getTitleCostsPending } = useSelector(({ accounts: { titleCosts, getTitleCostsPending } }) => ({ titleCosts, getTitleCostsPending }));
  const accountModules = useSelector(({ accountSettings: { accountModules } }) => accountModules);
  const defaultCostRateFlag = useSelector(({ launchDarkly: { defaultCostRate } }) => defaultCostRate);
  const financialModuleEnabled = useMemo(() => hasModuleEnabled(accountModules, ACCOUNT_MODULE_FINANCIAL_TRACKING), [accountModules]);
  const defaultMenus = useMemo(() => {
    const menus = [];
    if (defaultCostRateFlag && financialModuleEnabled) {
      menus.push({
        menuName: PEOPLE_DISPLAY_COST_RATE,
        count: titleCosts?.length ?? 0,
      });
    }
    return menus;
  }, [defaultCostRateFlag, financialModuleEnabled, titleCosts]);

  useEffect(() => {
    if (defaultCostRateFlag && financialModuleEnabled) {
      dispatch(getTitleCosts(accountId));
    }
  }, [dispatch, accountId, financialModuleEnabled, defaultCostRateFlag]);

  const renderItem = useCallback(({ menuName, count }) => (
    <div key={menuName}>
      <Button
        className={classNames('default-list-item', { 'active-row': activeMenu === menuName })}
      >
        <div className="description-wrapper">
          <p className="menu-name">{menuName}</p>
          <p className="subtitle">{pluralize('Default', count, true)}</p>
        </div>
      </Button>
      <Divider />
    </div>
  ), [activeMenu]);

  const rightSplit = useMemo(() => {
    switch (activeMenu) {
      case PEOPLE_DISPLAY_COST_RATE:
        return (<DefaultCostRateMenu accountId={accountId} titleCosts={titleCosts} />);
      default:
        return null;
    }
  }, [accountId, activeMenu, titleCosts]);

  return defaultMenus?.length > 0 && (
    <div ref={refProp} className="account-settings-system-defaults">
      <SplitMenu
        title="Default Values"
        items={defaultMenus}
        renderItems={renderItem}
        isLoading={getTitleCostsPending}
        isMenuActive
        activeNode={rightSplit}
      />
    </div>
  );
};

SystemDefaults.propTypes = {
  accountId: PropTypes.number.isRequired,
  refProp: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

SystemDefaults.defaultProps = {
  refProp: null,
};

export default SystemDefaults;
