import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import deepEqual from 'react-fast-compare';
import { Button } from '@material-ui/core';
import moment from 'moment';
import { stitchSegmentsByRange } from 'src/utils/dateSegmentUtils';
import { ConfirmOverlay } from 'src/features/common';
import { AllocationSinglePopulated, AllocationSingleEmpty } from '.';
import deactivatedAvatar from '../../images/deactivated_avatar.svg';
import { openModal } from '../modal-manager/redux/actions';
import { MULTI_STATE_MODAL_ID, COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_ALLOCATE_PERSON } from '../common/redux/constants';
import { setSelectedProjectId } from '../common/redux/actions';
import { backupProjectSelection } from '../projects/redux/actions';
import { PROJECT_LIST_SELECTION_ID } from '../projects/redux/constants';

class AllocationDetailRow extends PureComponent {
  static propTypes = {
    people: PropTypes.array.isRequired,
    role: PropTypes.object.isRequired,
    selectedProject: PropTypes.object.isRequired,
    allocations: PropTypes.array.isRequired,
    openPopper: PropTypes.func.isRequired,
    onDeleteAllocationConfirm: PropTypes.func.isRequired,
    onDeleteAllocation: PropTypes.func.isRequired,
    onEditAllocation: PropTypes.func.isRequired,
    editingAllocation: PropTypes.bool.isRequired,
    onAllocationChange: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    setSelectedProjectId: PropTypes.func.isRequired,
    activeModal: PropTypes.string,
    onClickAway: PropTypes.func,
    updateProjectRoleAllocationPending: PropTypes.bool.isRequired,
    getProjectAllocationsPending: PropTypes.bool.isRequired,
    confirmUpdateAllocation: PropTypes.func.isRequired,
    onEditAllocationCancel: PropTypes.func.isRequired,
    runsInModal: PropTypes.bool,
    backupProjectSelection: PropTypes.func.isRequired,
    onDeleteAllocationWithoutConfirm: PropTypes.func.isRequired,
  };

  static defaultProps = {
    activeModal: null,
    onClickAway: () => {},
    runsInModal: false,
  };

  constructor(props) {
    super(props);
    const { role, allocations, people } = props;
    this.state = {
      allocationRows: this.mapRows(role, allocations, people),
      showReplaceConfirm: false,
      roleDetails: null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { allocations, role, people } = this.props;
    const roleChanged = !deepEqual(role, nextProps.role);
    const allocationsChanged = !deepEqual(allocations, nextProps.allocations);
    const peopleChanged = !deepEqual(people, nextProps.people);

    if (roleChanged || allocationsChanged || peopleChanged) {
      this.setState({
        allocationRows: this.mapRows(nextProps.role, nextProps.allocations, nextProps.people),
      });
    }
  }

  mapRows = (role, allocations, people) => {
    const zeroPercent = role.requirements?.filter(req => req?.allocatedPercent === 0) || [];

    /*
      Overlay allocations and zero-percent ranges, then filter
      the zero-percent requirement blocks back out since they can't
      receive allocations.
    */
    const viableTimeline = stitchSegmentsByRange([
      { startDate: role.startDate, endDate: role.endDate },
      ...allocations,
      ...zeroPercent,
    ]).filter(block => block.allocatedPercent !== 0);

    const existingPeopleIds = people.map(person => person.id);

    return viableTimeline.map((allocation) => {
      if (allocation.state === 'Deactivated') {
        return {
          allocation,
          name: allocation.name,
          title: 'Deactivated',
          photoUrl: deactivatedAvatar,
          populated: true,
        };
      }

      if ('personId' in allocation && existingPeopleIds.includes(allocation.personId)) {
        const person = people.find(p => p.id === allocation.personId);

        return {
          allocation,
          name: person.name,
          title: person.title,
          photoUrl: person.photoUrl ? person.photoUrl : '',
          populated: true,
        };
      }
      return {
        populated: false,
        allocation,
      };
    });
  }

  handleClick = (startDate, endDate, selectedPerson = null) => {
    const {
      openModal,
      setSelectedProjectId,
      selectedProject,
      role,
      activeModal,
      runsInModal,
      backupProjectSelection,
    } = this.props;

    const today = moment().startOf('day');

    const showReplaceConfirm = (selectedPerson && (moment(startDate) <= today));
    const showModal = !showReplaceConfirm;

    const roleDetails = {
      id: role.id,
      segmentStart: startDate || role.startDate,
      segmentEnd: endDate || role.endDate,
      currentlyAllocatedPerson: selectedPerson,
    };

    if (showModal) {
      const multiStateModalIsActive = activeModal === MULTI_STATE_MODAL_ID;
      const modalOrigin = `Project Details${runsInModal ? ' Modal' : ''}`;
      const analyticsPayload = {
        runsInModal,
        data: {
          projectName: selectedProject.name,
          projectId: selectedProject.id,
          from: 'Project Details Modal',
          to: 'Place a Person Modal',
          projectStatus: selectedProject.state,
          projectType: selectedProject.type,
        },
      };
      setSelectedProjectId(
        selectedProject.id,
        COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_ALLOCATE_PERSON,
        roleDetails,
        multiStateModalIsActive,
        false,
        modalOrigin,
        analyticsPayload,
      );
      backupProjectSelection(multiStateModalIsActive ? MULTI_STATE_MODAL_ID : PROJECT_LIST_SELECTION_ID);
      if (!activeModal) openModal(MULTI_STATE_MODAL_ID);
    }

    this.setState({
      showReplaceConfirm,
      roleDetails,
    });
  }

  hideConfirm = () => {
    this.setState({
      showReplaceConfirm: false,
    });
  }

  showAllocationModal = () => {
    const {
      selectedProject,
      setSelectedProjectId,
      openModal,
      activeModal,
      runsInModal,
      backupProjectSelection,
    } = this.props;

    const { roleDetails } = this.state;
    const showProjectOnCancel = activeModal === MULTI_STATE_MODAL_ID;
    const modalOrigin = `Project Details${runsInModal ? ' Modal' : ''}`;
    const analyticsPayload = {
      runsInModal,
      data: {
        projectName: selectedProject.name,
        projectId: selectedProject.id,
        from: 'Project Details Modal',
        to: 'Place a Person Modal',
        projectStatus: selectedProject.state,
        projectType: selectedProject.type,
      },
    };
    setSelectedProjectId(
      selectedProject.id,
      COMMON_MULTI_STATE_MODAL_ACTIVE_VIEW_ALLOCATE_PERSON,
      roleDetails,
      showProjectOnCancel,
      false,
      modalOrigin,
      analyticsPayload,
    );

    const multiStateModalIsActive = activeModal === MULTI_STATE_MODAL_ID;
    backupProjectSelection(multiStateModalIsActive ? MULTI_STATE_MODAL_ID : PROJECT_LIST_SELECTION_ID);

    if (!activeModal) openModal(MULTI_STATE_MODAL_ID);

    this.setState({
      showReplaceConfirm: false,
    });
  }

  AllocationRows = () => {
    const {
      openPopper,
      role,
      onDeleteAllocationConfirm,
      onDeleteAllocation,
      selectedProject,
      onEditAllocation,
      editingAllocation,
      onAllocationChange,
      onClickAway,
      updateProjectRoleAllocationPending,
      getProjectAllocationsPending,
      confirmUpdateAllocation,
      onEditAllocationCancel,
      runsInModal,
      onDeleteAllocationWithoutConfirm,
    } = this.props;
    const { allocationRows } = this.state;

    return allocationRows.map((a) => {
      if (a.populated) {
        return (
          <AllocationSinglePopulated
            key={`${a.allocation.startDate}-${a.allocation.endDate}`}
            selectedProject={selectedProject}
            role={role}
            allocation={a.allocation}
            personId={a.allocation.personId}
            personName={a.name}
            personTitle={a.title}
            personPhoto={a.photoUrl}
            openPopper={openPopper}
            onRowClick={this.handleClick}
            onDeleteAllocationConfirm={onDeleteAllocationConfirm}
            onDeleteAllocation={onDeleteAllocation}
            onEditAllocation={onEditAllocation}
            editingAllocation={editingAllocation}
            onAllocationChange={onAllocationChange}
            onClickAway={onClickAway}
            updateProjectRoleAllocationPending={updateProjectRoleAllocationPending}
            getProjectAllocationsPending={getProjectAllocationsPending}
            confirmUpdateAllocation={confirmUpdateAllocation}
            onEditAllocationCancel={onEditAllocationCancel}
            runsInModal={runsInModal}
            onDeleteAllocationWithoutConfirm={onDeleteAllocationWithoutConfirm}
          />
        );
      }

      return (
        <AllocationSingleEmpty
          key={`${a.allocation.startDate}-${a.allocation.endDate}`}
          startDate={a.allocation.startDate}
          endDate={a.allocation.endDate}
          onRowClick={this.handleClick}
        />
      );
    });
  }

  render() {
    const { showReplaceConfirm } = this.state;
    const deleteConfirmText = 'This person has been active in this role, are you sure you want to replace them?';

    return (
      <div className="allocations-allocation-detail-row">
        <this.AllocationRows />

        {showReplaceConfirm && (
          <ConfirmOverlay
            onAccept={this.showAllocationModal}
            onCancel={this.hideConfirm}
            message={deleteConfirmText}
            displayCompact
            actionButtons={(
              <Button className="button__default" size="medium" variant="contained" onClick={this.showAllocationModal}>Replace</Button>
            )}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ modalManager }) => {
  const { activeModal } = modalManager;
  return {
    activeModal,
  };
};

const mapDispatchToProps = dispatch => ({
  openModal: bindActionCreators(openModal, dispatch),
  setSelectedProjectId: bindActionCreators(setSelectedProjectId, dispatch),
  backupProjectSelection: bindActionCreators(backupProjectSelection, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AllocationDetailRow);
