import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { MoreHoriz } from '@material-ui/icons';
import {
  Input,
  IconButton,
  Tooltip,
  TextField,
} from '@material-ui/core';
import classNames from 'classnames';
import { fitSegmentsToRange } from '../../utils/dateSegmentUtils';
import { validateRoleUpdate } from './utils/projectRolesUtils';
import { addProjectRoles } from './redux/actions';
import { ProjectRoleEditor, ProjectStatus } from '.';
import { EditControls, NumberInput } from '../wrapped-components';
import { CancelButton, DatePicker, ProjectLinkToModalContainer, Modal } from '../common';
import { CustomRoleRequirementModal } from '../allocations';
import { RolePhaseSelector } from '../phases';
import { DATE_DISPLAY_FORMAT, DATE_INPUT_FORMAT, MAX_ALLOCATION_PERCENT } from '../../common/constants';
import { AUTOMATION_ROLE_START_DATE_PREFIX, AUTOMATION_ROLE_END_DATE_PREFIX } from './ids';
import { momentToString } from '../../utils/dateUtils';

export const AddProjectRole = ({
  project,
  cancel,
}) => {
  const dispatch = useDispatch();

  const { accountId } = useSelector(({ common }) => common);
  const { roles } = useSelector(({ accountSettings }) => accountSettings);
  const { addProjectRolesPending } = useSelector(({ projects }) => projects);

  const [selectedRole, setSelectedRole] = useState(null);
  const [note, setNote] = useState('');
  const [startDate, setStartDate] = useState(project.startDate);
  const [endDate, setEndDate] = useState(project.endDate);
  const [workingStartDate, setWorkingStartDate] = useState(project.startDate);
  const [workingEndDate, setWorkingEndDate] = useState(project.endDate);
  const [allocatedPercent, setAllocatedPercent] = useState(MAX_ALLOCATION_PERCENT);
  const [showModal, setShowModal] = useState(false);
  const [valid, setValid] = useState(false);
  const [manualChange, setManualChange] = useState(false);
  const [selectedPhases, setSelectedPhases] = useState([]);
  const [requirements, setRequirements] = useState([
    {
      startDate,
      endDate,
      allocatedPercent,
    },
  ]);

  // Required to support old and new project gantts
  // When we remove the old project gantt in RP-7497 this can be cleaned up
  const projectColor = project.color || project.colour;

  const workingRole = useMemo(() => (
    {
      name: selectedRole?.name,
      startDate,
      endDate,
      rowId: -1,
    }
  ), [selectedRole, startDate, endDate]);

  const defaultRequirements = [{
    startDate: project.startDate,
    endDate: project.endDate,
    allocatedPercent: MAX_ALLOCATION_PERCENT,
  }];

  const validate = useCallback(() => {
    const existingProject = { startDate: project.startDate, endDate: project.endDate };
    const updatedRole = { startDate, endDate, requirements };
    let valid = true;

    if (!selectedRole) valid = false;

    if (!validateRoleUpdate(existingProject, updatedRole)) valid = false;

    setValid(valid);
  }, [requirements, endDate, project.endDate, project.startDate, selectedRole, startDate]);

  useEffect(() => {
    validate();
  }, [startDate, endDate, requirements, selectedRole, validate]);

  const clearRequirements = () => {
    setRequirements([
      {
        startDate,
        endDate,
        allocatedPercent,
      },
    ]);
    setManualChange(true);
  };

  const onChangeRole = (value) => {
    setSelectedRole(roles.find(role => role.id === value));
  };

  const onNoteChange = (evt) => {
    setNote(evt.target.value);
  };

  const onSave = () => {
    const data = [
      {
        name: selectedRole.name,
        note: note === '' ? null : note,
        startDate,
        endDate,
        requirements,
        selectedPhases,
      },
    ];
    dispatch(addProjectRoles(accountId, project.id, data, 'Project Gantt'));
  };

  const handleAllocatedPercentChange = (percent) => {
    setAllocatedPercent(percent);
    setRequirements(requirements.map(alloc => ({ ...alloc, allocatedPercent: percent })));
  };

  const onShowModal = () => setShowModal(true);

  const onHideModal = () => setShowModal(false);

  const updateRoleRequirements = (rowId, requirements) => {
    setRequirements(requirements);
    setAllocatedPercent(requirements[0].allocatedPercent);
    setShowModal(false);
    setManualChange(true);
  };

  const updateStartDate = (date) => {
    const workingRequirements = requirements.length ? requirements : defaultRequirements;
    const newRequirements = fitSegmentsToRange(workingRequirements, date, endDate);

    setStartDate(date);
    setRequirements(newRequirements);
    setAllocatedPercent(newRequirements[0]?.allocatedPercent);
  };

  const updateEndDate = (date) => {
    const workingRequirements = requirements.length ? requirements : defaultRequirements;
    const newRequirements = fitSegmentsToRange(workingRequirements, startDate, date);

    setEndDate(date);
    setRequirements(newRequirements);
    setAllocatedPercent(newRequirements[0]?.allocatedPercent);
  };

  const updateWorkingStartDate = (date) => {
    setWorkingStartDate(date);
    const newStart = moment(date);
    if (newStart.isValid()) updateStartDate(momentToString(newStart));
  };

  const updateWorkingEndDate = (date) => {
    setWorkingEndDate(date);
    const newEnd = moment(date);
    if (newEnd.isValid()) updateEndDate(momentToString(newEnd));
  };

  const manuallyUpdateWorkingStartDate = (date) => {
    setManualChange(true);
    updateWorkingStartDate(date);
  };

  const manuallyUpdateWorkingEndDate = (date) => {
    setManualChange(true);
    updateWorkingEndDate(date);
  };

  const clearStart = () => {
    setWorkingStartDate(null);
    setStartDate(null);
  };

  const clearEnd = () => {
    setWorkingEndDate(null);
    setEndDate(null);
  };

  const onPhaseSelect = () => {
    setManualChange(false);
  };

  const updateRole = (rowId, values) => {
    if ('startDate' in values) {
      setWorkingStartDate(values.startDate);
      setStartDate(values.startDate);
    }

    if ('endDate' in values) {
      setWorkingEndDate(values.endDate);
      setEndDate(values.endDate);
    }

    if ('requirements' in values) {
      setRequirements(values.requirements);
    }

    if ('selectedPhases' in values) {
      setSelectedPhases(values.selectedPhases);
    } else {
      setSelectedPhases([]);
    }
  };

  const preventDrag = evt => evt.stopPropagation();

  const requirementsDisabled = moment(workingStartDate).isAfter(workingEndDate)
  || moment(workingEndDate).isBefore(workingStartDate)
  || !(workingStartDate && workingEndDate);

  const validationMessages = {
    dateOutOfRange: 'Date out of range',
    dateFormatInvalid: 'Invalid Date Format',
  };

  const hasPhases = project?.phases?.length > 0;

  return (
    <div className="projects-add-project-role">
      <Modal
        className="add-project-role-modal"
        maxWidth="md"
        headline="Add a role"
        showClose={false}
        buttons={[(
          <div key="footer-controls" className="footer-controls" onMouseDown={preventDrag} role="presentation">
            <EditControls
              primaryAction={onSave}
              secondaryAction={cancel}
              disabled={!valid}
              primaryText="Save"
              pending={addProjectRolesPending}
            />
          </div>
        )]}
      >
        <div className="modal-content" onMouseDown={preventDrag} role="presentation">
          <div className="project-details">
            <div className="project-link-and-info">
              <ProjectLinkToModalContainer
                backgroundColor={projectColor}
                projectName={project.name}
                projectId={project.id}
                launchedFrom="Project Gantt - Add Role"
              />
              <div className="info">
                <ProjectStatus project={project} />
              </div>
            </div>
            <div className="project-dates">
              <div className="add-role-column">
                <span>Start Date</span>
                <p>{moment(project.startDate).format(DATE_DISPLAY_FORMAT)}</p>
              </div>
              <div className="add-role-column">
                <span>End Date</span>
                <p>{moment(project.endDate).format(DATE_DISPLAY_FORMAT)}</p>
              </div>
            </div>
          </div>
          <div className="add-role-form">
            <div className="add-role-row">
              <div className="add-role-column">
                <p>Role</p>
                <ProjectRoleEditor
                  selectedRole={selectedRole}
                  options={roles}
                  onChange={onChangeRole}
                />
                <span className="required-label">* Required</span>
              </div>
              <div className="add-role-column">
                <p>Note</p>
                <TextField
                  className="role-note"
                  placeholder="Enter a note about this role"
                  onChange={onNoteChange}
                  value={note || ''}
                  inputProps={{ maxLength: 50 }}
                />
              </div>
            </div>
            <div className="add-role-row">
              { hasPhases && (
                <div className="add-role-column">
                  <p>Quick options</p>
                  <RolePhaseSelector
                    className="gantt-role-phase-selector"
                    selectedProject={project}
                    updateRole={updateRole}
                    selectedRole={workingRole}
                    manualChange={manualChange}
                    onPhaseSelect={onPhaseSelect}
                    allocatedPercent={allocatedPercent}
                    parentName="Project Gantt"
                  />
                </div>
              )}
              <div className="add-role-column date-column">
                <p>Start date</p>
                <div className="date-wrapper">
                  <DatePicker
                    minDate={moment(project.startDate, DATE_INPUT_FORMAT)}
                    minDateMessage={validationMessages.dateOutOfRange}
                    maxDate={moment(endDate, DATE_INPUT_FORMAT)}
                    maxDateMessage={validationMessages.dateOutOfRange}
                    invalidDateMessage={validationMessages.dateFormatInvalid}
                    date={workingStartDate}
                    onChange={manuallyUpdateWorkingStartDate}
                    variant="inline"
                    id={`${AUTOMATION_ROLE_START_DATE_PREFIX}0`}
                  />
                  {startDate && <CancelButton className="date-cancel" onClick={clearStart} />}
                </div>
              </div>
              <div className="add-role-column date-column">
                <p>End date</p>
                <div className="date-wrapper">
                  <DatePicker
                    minDate={moment(startDate, DATE_INPUT_FORMAT)}
                    minDateMessage={validationMessages.dateOutOfRange}
                    maxDate={moment(project.endDate, DATE_INPUT_FORMAT)}
                    maxDateMessage={validationMessages.dateOutOfRange}
                    invalidDateMessage={validationMessages.dateFormatInvalid}
                    date={workingEndDate}
                    onChange={manuallyUpdateWorkingEndDate}
                    variant="inline"
                    id={`${AUTOMATION_ROLE_END_DATE_PREFIX}0`}
                  />
                  {endDate && <CancelButton className="date-cancel" onClick={clearEnd} />}
                </div>
              </div>
              <div className="add-role-column">
                <p>Allocation %</p>
                <div className="allocation-wrap">
                  { requirements.length > 1 && (
                    <div className="custom-wrap">
                      <Input
                        className="allocation-picker"
                        type="text"
                        value="Custom"
                        disabled
                      />
                      <CancelButton
                        className="clear"
                        onClick={clearRequirements}
                      />
                    </div>
                  )}
                  { requirements.length === 1 && (
                    <NumberInput
                      className="allocation-picker"
                      value={allocatedPercent}
                      onValueChanged={handleAllocatedPercentChange}
                    />
                  )}
                  <IconButton
                    onClick={onShowModal}
                    className={classNames({ disabled: requirementsDisabled })}
                    disabled={requirementsDisabled}
                  >
                    <Tooltip title="Customize" placement="top">
                      <MoreHoriz />
                    </Tooltip>
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {showModal && (
        <CustomRoleRequirementModal
          selectedProject={project}
          onHideModal={onHideModal}
          role={{ ...selectedRole, startDate, endDate, requirements }}
          onSave={updateRoleRequirements}
          selectedPhases={selectedPhases}
        />
      )}
    </div>
  );
};

AddProjectRole.propTypes = {
  project: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default AddProjectRole;
