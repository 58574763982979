const AUTOMATION_DELETE_PROJECT_ACTION = 'AUTOMATION_DELETE_PROJECT_ACTION';
const AUTOMATION_PROJECTS_GANTT_EDIT_ROLE_START_DATE = 'AUTOMATION_PROJECTS_GANTT_EDIT_ROLE_START_DATE';
const AUTOMATION_PROJECTS_GANTT_EDIT_ROLE_END_DATE = 'AUTOMATION_PROJECTS_GANTT_EDIT_ROLE_END_DATE';
const AUTOMATION_PROJECTS_GANTT_EDIT_ROLE_START_DATE_WRAPPER = 'AUTOMATION_PROJECTS_GANTT_EDIT_ROLE_START_DATE_WRAPPER';
const AUTOMATION_PROJECTS_GANTT_EDIT_ROLE_END_DATE_WRAPPER = 'AUTOMATION_PROJECTS_GANTT_EDIT_ROLE_END_DATE_WRAPPER';
const AUTOMATION_PROJECT_REQUESTERS_INPUT_ID = 'AUTOMATION_PROJECT_REQUESTERS_INPUT_ID';
const AUTOMATION_PROJECTS_ROLE_NOTE_FIELD = 'AUTOMATION_PROJECTS_ROLE_NOTE_FIELD';
const AUTOMATION_REQUESTER_NAME_PREFIX = 'AUTOMATION_REQUESTER_NAME_';
const AUTOMATION_REQUESTER_EMAIL_PREFIX = 'AUTOMATION_REQUESTER_EMAIL_';
const AUTOMATION_ROLE_END_DATE_PREFIX = 'AUTOMATION_ROLE_END_DATE_';
const AUTOMATION_ROLE_START_DATE_PREFIX = 'AUTOMATION_ROLE_START_DATE_';
const AUTOMATION_NOTIFY_HEADER_VALUE = 'AUTOMATION_NOTIFY_HEADER_VALUE';

export {
  AUTOMATION_DELETE_PROJECT_ACTION,
  AUTOMATION_PROJECTS_GANTT_EDIT_ROLE_START_DATE,
  AUTOMATION_PROJECTS_GANTT_EDIT_ROLE_END_DATE,
  AUTOMATION_PROJECTS_GANTT_EDIT_ROLE_START_DATE_WRAPPER,
  AUTOMATION_PROJECTS_GANTT_EDIT_ROLE_END_DATE_WRAPPER,
  AUTOMATION_PROJECT_REQUESTERS_INPUT_ID,
  AUTOMATION_PROJECTS_ROLE_NOTE_FIELD,
  AUTOMATION_REQUESTER_NAME_PREFIX,
  AUTOMATION_REQUESTER_EMAIL_PREFIX,
  AUTOMATION_ROLE_END_DATE_PREFIX,
  AUTOMATION_ROLE_START_DATE_PREFIX,
  AUTOMATION_NOTIFY_HEADER_VALUE,
};
