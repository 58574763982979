import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Timeline } from 'src/features/common';
import { calculateSpacerDimensions } from 'src/utils/dateUtils';
import { stitchSegmentsByRange } from 'src/utils/dateSegmentUtils';

export default function RoleCoverageIndicator({
  roleStartDate,
  roleEndDate,
  projectStartDate,
  projectEndDate,
  existingAllocations,
  roleRequirements,
}) {
  const { startPadPercentage, endPadPercentage } = useMemo(
    () => calculateSpacerDimensions(
      { startDate: roleStartDate, endDate: roleEndDate },
      { startDate: projectStartDate, endDate: projectEndDate },
    ),
    [roleStartDate, roleEndDate, projectStartDate, projectEndDate],
  );

  const getSegmentClasses = (segment) => {
    if (segment.allocated) return { topClass: '', bottomClass: '' };
    if (segment.zeroPercent) return { topClass: 'divider-only', bottomClass: 'hidden' };
    return { topClass: 'greyed-out', bottomClass: 'greyed-out' };
  };

  const segments = useMemo(() => {
    const zeroPercent = roleRequirements.filter(req => req?.allocatedPercent === 0);

    if (!existingAllocations.length && !zeroPercent.length) {
      const defaultSegment = { startDate: roleStartDate, endDate: roleEndDate };

      return [{
        ...defaultSegment,
        ...getSegmentClasses(defaultSegment),
      }];
    }

    // Start with the whole role, overlay existing allocations, then overlay zero-percent ranges
    const combinedBlocks = [
      { startDate: roleStartDate, endDate: roleEndDate },
      ...existingAllocations.map(allocation => ({ ...allocation, allocated: true })),
      ...zeroPercent.map(req => ({ ...req, zeroPercent: true })),
    ];

    return stitchSegmentsByRange(combinedBlocks).map(block => ({ ...block, ...getSegmentClasses(block) }));
  }, [existingAllocations, roleEndDate, roleRequirements, roleStartDate]);

  return (
    <div className="projects-role-coverage-indicator">
      { startPadPercentage !== 0 && (
        <div style={{ width: `${startPadPercentage}%` }} />
      )}

      <div className="timeline-wrapper">
        <Timeline
          startDate={roleStartDate}
          endDate={roleEndDate}
          segments={segments}
        />
      </div>

      { endPadPercentage !== 0 && (
        <div style={{ width: `${endPadPercentage}%` }} />
      )}
    </div>
  );
}

RoleCoverageIndicator.propTypes = {
  roleStartDate: PropTypes.string.isRequired,
  roleEndDate: PropTypes.string.isRequired,
  projectStartDate: PropTypes.string.isRequired,
  projectEndDate: PropTypes.string.isRequired,
  existingAllocations: PropTypes.arrayOf(PropTypes.object),
  roleRequirements: PropTypes.arrayOf(PropTypes.object),
};

RoleCoverageIndicator.defaultProps = {
  existingAllocations: [],
  roleRequirements: [],
};
