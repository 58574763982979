import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener, Paper } from '@material-ui/core';
import { CustomPopper, Can, EditButton } from 'src/features/wrapped-components';
import { PERM_WRITE, PERM_ROLE } from 'src/features/permissions/utils/constants';
import { RoleRequirementList } from '.';

const RoleRequirementPopper = ({
  onHidePopper,
  onEditRequirements,
  roleRequirements,
  targetRef,
  project,
}) => {
  const modifiers = {
    offset: {
      offset: '12px, 9px',
    },
    preventOverflow: {
      enabled: true,
      boundariesElement: 'scrollParent',
    },
  };

  useEffect(() => {
    const payload = {
      'Project name': project.name,
      'Project id': project.id,
      'Project status': project.state,
      'Project type': project.type,
    };
    window.mixpanel.track('View allocation % breakdown for a role', payload);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Close the popper if the parent element scrolls out of sight
  const onPopperUpdate = (popperData) => {
    const { hide } = popperData;
    if (hide) onHidePopper();
  };

  return (
    <CustomPopper
      popperOpen
      hideArrow
      anchorEl={targetRef}
      classes="allocations-role-requirement-popper"
      placement="bottom-end"
      modifiers={modifiers}
      onUpdate={onPopperUpdate}
    >
      <ClickAwayListener onClickAway={onHidePopper}>
        <Paper className="content-wrapper">
          <div className="requirement-header">
            Custom allocation breakdown
            { onEditRequirements && (
              <Can
                action={PERM_WRITE}
                subject={PERM_ROLE}
                yes={(
                  <EditButton
                    className="edit-allocations"
                    onClick={onEditRequirements}
                    tooltipTitle="Edit allocations"
                  />
                )}
              />
            )}
          </div>
          <RoleRequirementList roleRequirements={roleRequirements} />
        </Paper>
      </ClickAwayListener>
    </CustomPopper>
  );
};

RoleRequirementPopper.propTypes = {
  onHidePopper: PropTypes.func.isRequired,
  onEditRequirements: PropTypes.func,
  roleRequirements: PropTypes.array.isRequired,
  targetRef: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
};

RoleRequirementPopper.defaultProps = {
  onEditRequirements: null,
};

export default RoleRequirementPopper;
