import { getFieldInput } from 'src/utils/miscUtils';
import {
  FIELD_TYPE_PHONE,
  EMAIL_FIELD,
  CELL_NUMBER_FIELD,
  FIELD_TYPE_PHONE_E164,
} from 'src/common/constants';

const generateDataset = (selectedPersonFields, personFields) => {
  const contactFields = personFields.filter(({ type }) => [FIELD_TYPE_PHONE, FIELD_TYPE_PHONE_E164].includes(type));

  return contactFields.map((defaultField) => {
    const populatedField = selectedPersonFields.find(f => f.fieldId === defaultField.id) || {};

    return {
      ...defaultField,
      ...populatedField,
      value: populatedField.values ? populatedField.values[0] : undefined,
    };
  });
};

const generateInputs = (inputFields) => {
  const inputs = [];
  const exceptedFields = [EMAIL_FIELD, FIELD_TYPE_PHONE_E164, CELL_NUMBER_FIELD];

  if (!inputFields || inputFields.length === 0) return [];
  const fields = Array.isArray(inputFields) ? inputFields : [inputFields];

  fields.filter(f => exceptedFields.includes(f.name) || !f.isSystem).forEach((field) => {
    inputs.push(getFieldInput(field));
  });

  return inputs;
};

export { generateDataset, generateInputs };
