import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useDebounce } from '@bridgit/foundation';

import { sortFilterOptions } from '../../filters/utils/filterUtils';
import { searchActivePeople } from '../../people/redux/searchActivePeople';
import { clearSearchedActivePeople } from '../../people/redux/clearSearchedActivePeople';
import { DEBOUNCE_DURATION } from '../../filters/redux/constants';
import { MultiSelect } from '..';

export const SelectListSearch = ({
  verbs,
  filterKey,
  expanded,
  onExpanded,
  onDelete,
  onVerbChanged,
  onSelected,
  selectedItems,
  placeholder,
  disableDelete,
  verbText,
}) => {
  const dispatch = useDispatch();

  const { accountId } = useSelector(({ common }) => common);
  const { searchedActivePeople, searchActivePeoplePending } = useSelector(({ people }) => people);

  const [selectedVerb, setSelectedVerb] = useState(selectedItems.verb);
  const [visibleItems, setVisibleItems] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [searching, setSearching] = useState(false);

  const debouncedSearchString = useDebounce(searchString, DEBOUNCE_DURATION);

  useEffect(() => {
    const visibleSelected = searchedActivePeople
      // Filter out people seen in both `searchedActivePeople` and `selectedPeople` to avoid duplicated list entries
      .filter(item => selectedItems.selected.findIndex(selected => selected[filterKey] === item[filterKey]) === -1)
      // Then combine with the currently selected people
      .concat(selectedItems.selected);

    setVisibleItems(sortFilterOptions(visibleSelected, 'name'));
  }, [filterKey, searchedActivePeople, selectedItems.selected]);

  const onVerbChange = (evt) => {
    const { value } = evt.target;

    onVerbChanged(selectedItems.id, value);
    setSelectedVerb(value);
  };

  const onSelect = (item) => {
    const parentId = item[filterKey];

    const selectedIdx =
      selectedItems
        .selected
        .findIndex(selected => selected[filterKey] === parentId);

    const newSelected = [...selectedItems.selected];

    if (selectedIdx === -1) {
      newSelected.push(item);
    } else {
      newSelected.splice(selectedIdx, 1);
    }

    onSelected(selectedItems.id, newSelected);
  };

  // Perform people search when the debounced search string changes
  useEffect(() => {
    const searchQueries = debouncedSearchString
      .split(' ')
      // Escapes characters that cause errors
      .map(word => encodeURIComponent(word));

    dispatch(searchActivePeople(accountId, searchQueries));
    setSearching(false);
  }, [debouncedSearchString, accountId, dispatch]);

  const onSearch = (newSearchString = '') => {
    setSearchString(newSearchString);
    if (newSearchString.length > 1) {
      setSearching(true);
    }
  };

  const onClearSearch = () => {
    setVisibleItems(selectedItems.selected);
    dispatch(clearSearchedActivePeople());
    setSearchString('');
  };

  const onExpand = () => {
    setSearchString('');
    setVisibleItems(sortFilterOptions(selectedItems.selected, 'name'));
    onExpanded(selectedItems.id);
  };

  const noResultsFound = useMemo(() => (
    searchString?.length > 1 &&
    searchedActivePeople?.length === 0 &&
    !searching &&
    !searchActivePeoplePending
  ), [searchActivePeoplePending, searchString?.length, searchedActivePeople?.length, searching]);

  return (
    <MultiSelect
      selectedVerb={selectedVerb}
      verbs={verbs}
      verbText={verbText}
      expanded={expanded}
      disableDelete={disableDelete}
      selectedItems={selectedItems}
      visibleItems={visibleItems}
      filterKey={filterKey}
      noResultsFound={noResultsFound}
      searchString={searchString}
      placeholder={placeholder}
      onVerbChange={onVerbChange}
      onDelete={onDelete}
      onSelect={onSelect}
      onExpand={onExpand}
      onSearch={onSearch}
      onClearSearch={onClearSearch}
    />
  );
};

SelectListSearch.propTypes = {
  verbs: PropTypes.array.isRequired,
  filterKey: PropTypes.string.isRequired,
  expanded: PropTypes.string,
  onSelected: PropTypes.func.isRequired,
  onExpanded: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onVerbChanged: PropTypes.func.isRequired,
  selectedItems: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  disableDelete: PropTypes.bool,
  verbText: PropTypes.string,
};

SelectListSearch.defaultProps = {
  expanded: '',
  placeholder: '',
  disableDelete: false,
  verbText: '',
};

export default SelectListSearch;
