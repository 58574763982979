import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { DATE_DISPLAY_FORMAT } from 'src/common/constants';
import {
  PROJECT_CSV_ALLOCATION_HEADERS,
  PROJECT_HEADER_MAP,
  PROJECT_ROLES_COLUMN,
} from './redux/constants';
import { clearReportData, setReport } from './redux/actions';
import { generateProjectCSV, generateProjectAllocationsCSV } from './utils/reportUtils';
import { getAccountById } from '../../utils/miscUtils';

const ProjectCSVReport = ({
  clearReportData,
  setReport,
  projects,
  accountId,
  accounts,
  visibleColumns,
  includeAllocations,
  allocations,
}) => {
  const csvInstance = useRef();
  const hasProjects = projects && projects.length > 0;

  useEffect(() => {
    if (hasProjects && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        clearReportData();

        window.mixpanel.track('Export Project List', {
          'Report type': 'CSV',
          'Allocations included': includeAllocations,
        });

        setReport(false);
      });
    }
  }, [hasProjects, clearReportData, setReport, includeAllocations]);

  if (hasProjects) {
    const filteredColumns = visibleColumns.filter(({ name }) => name !== 'Photo');
    const headers = filteredColumns.reduce((headerNames, column) => {
      const { name } = column;

      if (!includeAllocations || (includeAllocations && name !== PROJECT_ROLES_COLUMN)) {
        headerNames.push(PROJECT_HEADER_MAP[name] || name);
      }
      return headerNames;
    }, []);

    const today = moment().format(DATE_DISPLAY_FORMAT);
    const currentAccount = getAccountById(accounts, accountId) || { name: '' };
    const data = includeAllocations
      ? generateProjectAllocationsCSV(filteredColumns, projects, allocations)
      : generateProjectCSV(filteredColumns, projects);

    return (
      <CSVLink
        className="reports-projects-csv"
        headers={includeAllocations ? [...headers, ...PROJECT_CSV_ALLOCATION_HEADERS] : headers}
        data={data}
        filename={`${currentAccount.name} - Project List - ${today}.csv`}
        ref={csvInstance}
      />
    );
  }

  return null;
};

ProjectCSVReport.propTypes = {
  clearReportData: PropTypes.func.isRequired,
  setReport: PropTypes.func.isRequired,
  projects: PropTypes.array.isRequired,
  accounts: PropTypes.array.isRequired,
  accountId: PropTypes.number.isRequired,
  visibleColumns: PropTypes.array.isRequired,
  includeAllocations: PropTypes.bool,
  allocations: PropTypes.array,
};

ProjectCSVReport.defaultProps = {
  includeAllocations: false,
  allocations: [],
};

const mapStateToProps = ({ accounts, reports, common, table }) => ({
  projects: reports.projects,
  accountId: common.accountId,
  accounts: accounts.entities,
  visibleColumns: table.visibleColumns,
  includeAllocations: reports.settings.includeAllocations,
  allocations: reports.projectAllocations,
});

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    clearReportData: bindActionCreators(clearReportData, dispatch),
    setReport: bindActionCreators(setReport, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectCSVReport);
