import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, ClickAwayListener } from '@material-ui/core';
import { BLANK_FILTER, AFTER_FILTER } from 'src/filters/constants';
import { DateFilter } from '.';
import { CustomPopper } from '../wrapped-components';
import { DATE_DISPLAY_FORMAT } from '../../common/constants';
import { shouldDisableApply } from './dateFilterUtils';
import { setPopperIsOpen } from '../filters/redux/actions';

class DateFilterPopper extends PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    anchorEl: PropTypes.object,
    title: PropTypes.string.isRequired,
    onApply: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    selected: PropTypes.object,
    includeBlank: PropTypes.bool,
    setPopperIsOpen: PropTypes.func.isRequired,
  };

  static defaultProps = {
    anchorEl: null,
    selected: null,
    includeBlank: true,
  }

  constructor(props) {
    super(props);

    const { selected } = props;
    const dateInfo = selected && selected.selected.length > 0 ? selected.selected[0] : null;

    this.state = {
      applyDisabled: true,
      verb: selected?.verb || AFTER_FILTER,
      date: dateInfo?.date || null,
      endDate: dateInfo?.endDate || null,
      preventCancel: false,
    };
  }

  onChange = (verb, date, endDate) => {
    const { selected } = this.props;
    this.setState({
      verb,
      date,
      endDate,
      applyDisabled: shouldDisableApply(selected, verb, date, endDate),
    });
  }

  onPreventCancel = () => {
    this.setState({
      preventCancel: true,
    });
  }

  onAllowCancel = () => {
    this.setState({
      preventCancel: false,
    });
  }

  onCancel = (evt) => {
    const { onCancel, selected, setPopperIsOpen } = this.props;
    const { preventCancel } = this.state;

    const { target: { className } } = evt;
    const dateInfo = selected && selected.selected.length > 0 ? selected.selected[0] : null;

    // prevent cancelling if the date picker or dropdown menu is open
    if (preventCancel || (className && typeof className === 'string' && (className.indexOf('verb-select') !== -1 || className.indexOf('MuiBackdrop') !== -1))) {
      return;
    }

    onCancel();
    setPopperIsOpen(false);
    this.setState({
      applyDisabled: true,
      verb: selected ? selected.verb : AFTER_FILTER,
      date: dateInfo ? dateInfo.date : null,
      endDate: dateInfo ? dateInfo.endDate : null,
    });
  }

  onApply = () => {
    const { onApply } = this.props;
    const { verb, date, endDate } = this.state;
    let name;

    switch (verb) {
      case 'between':
        name = `${moment.utc(date).format(DATE_DISPLAY_FORMAT)} - ${moment.utc(endDate).format(DATE_DISPLAY_FORMAT)}`;
        break;
      case BLANK_FILTER:
        name = BLANK_FILTER;
        break;
      default:
        name = moment.utc(date).format(DATE_DISPLAY_FORMAT);
    }

    const selected = [{
      verb,
      selected: [
        {
          name,
          date,
          endDate: verb === 'between' ? endDate : null,
        },
      ],
    }];
    onApply(selected);
  }

  onError = (error = '') => {
    const { applyDisabled: currApplyDisabled } = this.state;
    const applyDisabled = !!error.length;

    if (applyDisabled && !currApplyDisabled) {
      this.setState({ applyDisabled });
    }
  }

  renderFooter = () => {
    const { applyDisabled } = this.state;
    return (
      <div className="footer">
        <Button
          disableRipple
          size="medium"
          onClick={this.onCancel}
        >
          Cancel
        </Button>
        <Button
          disableRipple
          size="medium"
          onClick={this.onApply}
          color="primary"
          disabled={applyDisabled}
        >
          Apply
        </Button>
      </div>
    );
  }

  render() {
    const {
      anchorEl,
      open,
      title,
      includeBlank,
    } = this.props;
    const { verb, date, endDate } = this.state;

    return (
      <CustomPopper
        popperOpen={open}
        anchorEl={anchorEl}
        classes="common-date-filter-popper"
        placement="top-start"
        hideArrow
        preventBubbling
      >
        <ClickAwayListener onClickAway={this.onCancel}>
          <div className="content-wrap">
            <div role="presentation" onClick={this.onCancel} className="click-listener" />
            <div className="header">
              <div className="title">{title}</div>
            </div>
            <DateFilter
              className="content"
              includeBlank={includeBlank}
              initialVerb={verb}
              initialDate={date}
              initialEndDate={endDate}
              onChange={this.onChange}
              onOpenDatePicker={this.onPreventCancel}
              onCloseDatePicker={this.onAllowCancel}
              onError={this.onError}
            />
            {this.renderFooter()}
          </div>
        </ClickAwayListener>
      </CustomPopper>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setPopperIsOpen: bindActionCreators(setPopperIsOpen, dispatch),
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(DateFilterPopper);
