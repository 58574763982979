import parsePhoneNumber from 'libphonenumber-js';
import { DEFAULT_COUNTRY_CODE } from './constants';

/*
  Returns true if the phone number is a valid phone number
  assuming DEFAULT country code if missing explicitly
*/
const validatePhoneNumber = (phone) => {
  if (!phone) return false;
  const phoneNumber = parsePhoneNumber(phone, DEFAULT_COUNTRY_CODE);
  return phoneNumber ? phoneNumber.isValid() : false;
};

/*
  Returns true if the phone number is a valid phone number
  or a valid entry to a form where optional/empty inputs are accepted
*/
const validatePhoneNumberFormField = (phone) => {
  if (!phone) return true;
  return validatePhoneNumber(phone);
};

const formatPhoneNumberInput = (phone) => {
  if (!phone) return '';
  const phoneNumber = parsePhoneNumber(phone, DEFAULT_COUNTRY_CODE);
  return phoneNumber ? phoneNumber.number : '';
};

const formatPhoneNumberDisplay = (phone) => {
  if (!phone) return '';
  const phoneNumber = parsePhoneNumber(phone, DEFAULT_COUNTRY_CODE);
  return phoneNumber ? phoneNumber.formatInternational() : '';
};

export {
  validatePhoneNumber,
  validatePhoneNumberFormField,
  formatPhoneNumberInput,
  formatPhoneNumberDisplay,
};
