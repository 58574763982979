import moment from 'moment';
import {
  toRange,
  validateDate,
  subtractMomentRanges,
  isStringOrMoment,
  stringToMoment,
  momentToString,
} from 'src/utils/dateUtils';
import deepEqual from 'react-fast-compare';
import { DATE_INPUT_FORMAT, MAX_ALLOCATION_PERCENT } from 'src/common/constants';
import {
  convertSegmentFromRange,
  convertSegmentToRange,
  sortSegmentsByStartDate,
  getUnfilledSegments,
  isValidSegmentArray,
  stitchSegmentsByRange,
  splitOverlappingSegments,
  trimSegmentsToRange,
} from 'src/utils/dateSegmentUtils';
import { naturalSort } from 'src/utils/sortUtils';
import { BREAKDOWN_MONTHLY, BREAKDOWN_WEEKLY } from '../redux/constants';

function isValidRequirement(requirement) {
  if (!requirement) return false;

  const { startDate, endDate, allocatedPercent } = requirement;

  // For this specific purpose, a requirement can have a null start or end date
  return (startDate === null || validateDate(startDate)) &&
    (endDate === null || validateDate(endDate)) &&
    typeof allocatedPercent === 'number';
}

function requirementsMapToPeriod(requirements = [], period) {
  if (![BREAKDOWN_WEEKLY, BREAKDOWN_MONTHLY].includes(period)
    || requirements?.some(req => !isValidRequirement(req))) return false;

  const sortedReqs = sortSegmentsByStartDate(requirements);

  if (period === BREAKDOWN_WEEKLY) {
    const sunday = 0;
    const monday = 1;

    for (let i = 0; i < sortedReqs.length; i += 1) {
      const req = sortedReqs[i];
      const first = i === 0;
      const last = i === sortedReqs.length - 1;

      const start = moment(req.startDate);
      const end = moment(req.endDate);

      // Every range except the first should start on a Monday
      if (!first && start.day() !== monday) return false;

      // Every range except the last should end on a Sunday
      if (!last && end.day() !== sunday) return false;
    }

    return true;
  } if (period === BREAKDOWN_MONTHLY) {
    for (let i = 0; i < sortedReqs.length; i += 1) {
      const req = sortedReqs[i];

      const start = moment(req.startDate);
      const end = moment(req.endDate);
      const endOfMonth = end.clone().endOf('month');

      // Every range except the first should start on the 1st day of the month
      if (start.date() !== 1 && i !== 0) return false;

      // Every range except the last one should end on the last of the month
      if (end.date() !== endOfMonth.date() && i !== requirements.length - 1) return false;
    }

    return true;
  }

  return false;
}

function getMonthlyAllocations(role) {
  const startDate = moment(role.startDate);
  const endDate = moment(role.endDate);
  const allocations = [];
  const cleanMap = requirementsMapToPeriod(role?.requirements || [], BREAKDOWN_MONTHLY);
  const hasRequirements = role && role.requirements;
  const existingAllocations = hasRequirements && role.requirements.length > 1 ? [...role.requirements].sort((a, b) => (moment(a.startDate) - moment(b.startDate))) : undefined;
  let currentAllocation = 0;

  // From start date to end of month
  let endOfFirstMonth = startDate.clone().endOf('month');
  if (endOfFirstMonth > endDate) endOfFirstMonth = endDate;
  allocations.push({
    startDate: startDate.clone().format(DATE_INPUT_FORMAT),
    endDate: endOfFirstMonth.format(DATE_INPUT_FORMAT),
    allocatedPercent: cleanMap && existingAllocations ? existingAllocations[currentAllocation].allocatedPercent : 100,
  });
  startDate.add(1, 'month').startOf('month');

  while (endDate > startDate || endDate.isSame(startDate, 'day')) {
    if (existingAllocations && startDate > moment(existingAllocations[currentAllocation].endDate)) {
      currentAllocation += 1;
    }

    let endOfMonth = startDate.clone().endOf('month');
    if (endOfMonth > endDate) endOfMonth = endDate;
    allocations.push({
      startDate: startDate.clone().format(DATE_INPUT_FORMAT),
      endDate: endOfMonth.format(DATE_INPUT_FORMAT),
      allocatedPercent: cleanMap && existingAllocations ? existingAllocations[currentAllocation].allocatedPercent : 100,
    });
    startDate.add(1, 'month');
  }

  return allocations;
}

function getWeeklyAllocations(role) {
  let startDate = moment(role.startDate);
  const endDate = moment(role.endDate);
  const allocations = [];
  const cleanMap = requirementsMapToPeriod(role?.requirements || [], BREAKDOWN_WEEKLY);
  const existingAllocations = role.requirements.length > 1 ? [...role.requirements].sort((a, b) => (moment(a.startDate) - moment(b.startDate))) : undefined;
  let currentAllocation = 0;

  // We want our weeks to start with Monday
  // So if that's not already the case, we need to end the first chunk with Sunday
  if (startDate.day() !== 1) {
    let nextSunday = startDate.day() === 0 ? startDate.clone() : startDate.clone().add(1, 'week').day(0);
    if (nextSunday > endDate) nextSunday = endDate;
    allocations.push({
      startDate: startDate.clone().format(DATE_INPUT_FORMAT),
      endDate: nextSunday.format(DATE_INPUT_FORMAT),
      allocatedPercent: cleanMap && existingAllocations ? existingAllocations[currentAllocation].allocatedPercent : 100,
    });
    startDate = nextSunday.clone().add(1, 'day');
  }

  while (endDate > startDate || endDate.isSame(startDate, 'day')) {
    if (existingAllocations && startDate > moment(existingAllocations[currentAllocation].endDate)) {
      currentAllocation += 1;
    }

    let endOfWeek = startDate.clone().add(6, 'days');
    if (endOfWeek > endDate) endOfWeek = endDate;
    allocations.push({
      startDate: startDate.clone().format(DATE_INPUT_FORMAT),
      endDate: endOfWeek.format(DATE_INPUT_FORMAT),
      allocatedPercent: cleanMap && existingAllocations ? existingAllocations[currentAllocation].allocatedPercent : 100,
    });
    startDate.add(1, 'week');
  }

  return allocations;
}

/*
  Compares original and updated requirement to see if the updated range is 'shrinking'
  on either end.
    * If the start of the new range is after the start of the old range, shrinkStart will be true
    * If the end of the new range is before the end of the old range, shrinkEnd will be true
*/
function checkRangeChanges(oldReq, newReq) {
  let shrinkStart = false;
  let shrinkEnd = false;

  if (oldReq?.startDate === null || oldReq?.endDate === null) return { shrinkStart, shrinkEnd };

  try {
    const originalRange = toRange(oldReq.startDate, oldReq.endDate);
    const newRange = toRange(newReq.startDate, newReq.endDate);

    if (
      !originalRange.start.isValid() ||
      !originalRange.end.isValid() ||
      !newRange.start.isValid() ||
      !newRange.end.isValid()
    ) return {};

    shrinkStart = originalRange.start.isBefore(newRange.start);
    shrinkEnd = originalRange.end.isAfter(newRange.end);
  } catch (err) {
    return {};
  }

  return { shrinkStart, shrinkEnd };
}

function updateRequirements(originalReq, updatedReq, existingReqs) {
  const toRanges = requirements => requirements.map(r => convertSegmentToRange(r));
  const fromRanges = requirements => requirements.map(r => convertSegmentFromRange(r));

  // Basic sanity check on inputs. If anything is wrong, just return the original requirements
  if (!isValidRequirement(originalReq) ||
    !isValidRequirement(updatedReq) ||
    existingReqs.some(req => !isValidRequirement(req))
  ) return existingReqs;

  // If the updated requirement is incomplete, just replace the previous value
  if (updatedReq.startDate === null || updatedReq.endDate === null) {
    return [
      ...existingReqs.filter(r => !deepEqual(r, originalReq)),
      updatedReq,
    ];
  }

  const requirements = toRanges(existingReqs.filter(r => r.startDate !== null && r.endDate !== null));
  const updated = convertSegmentToRange(updatedReq);

  const { shrinkStart, shrinkEnd } = checkRangeChanges(originalReq, updatedReq);

  if (typeof shrinkStart === 'undefined' || typeof shrinkEnd === 'undefined') return originalReq;

  /*
    Blocks that are entirely contained within the updated range should be deleted
    If the range shrinks, there should be gaps that are replaced with blocks of 100%.
    If the range grows, there should be no empty places
  */
  const filtered = requirements.reduce((acc, curr) => {
    if (updated.range.overlaps(curr.range, { adjacent: true })) {
      // One or more ranges may be left over after subtracting the updated range
      const leftoverRanges = subtractMomentRanges(curr.range, updated.range);

      leftoverRanges.forEach((range) => {
        if ((range.end.isBefore(updated.range.start) && shrinkStart)
          || (range.start.isAfter(updated.range.end) && shrinkEnd)) {
          acc.push({ range, allocatedPercent: 100 });
        } else {
          acc.push({ range, allocatedPercent: curr.allocatedPercent });
        }
      });
    } else {
      acc.push(curr);
    }

    return acc;
  }, []);

  const merged = [...fromRanges(filtered), updatedReq];

  return sortSegmentsByStartDate(merged);
}

// Merges adjacent segments with the same allocation percent value
function mergeRequirements(requirements = []) {
  const mergedRequirements = [];
  let currentReq = requirements[0];
  requirements.forEach((req, index) => {
    if (index === 0) return;
    if (req?.allocatedPercent === currentReq?.allocatedPercent) {
      currentReq.endDate = req?.endDate;
    } else {
      mergedRequirements.push(currentReq);
      currentReq = req;
    }
  });
  mergedRequirements.push(currentReq);

  return mergedRequirements;
}

/*
  At the moment, supported usage only requires this to be a 'dumb' delete to get
  rid of incomplete ranges that have no impact on other roles yet. In the event
  that we want to delete an existing segment that will leave a gap, additional
  logic will have to be added to fill the empty space in the timeline.
*/
function deleteRequirement(targetReq, requirements = []) {
  return requirements.filter(req => !(deepEqual(targetReq, req)));
}

// Returns the earliest and latest dates in a list of selected phases
function getPhaseMinMaxDates(selectedPhases) {
  const phaseStartDates = selectedPhases.map(phase => stringToMoment(phase.startDate));
  const phaseEndDates = selectedPhases.map(phase => stringToMoment(phase.endDate));

  return {
    startDate: momentToString(moment.min(phaseStartDates)),
    endDate: momentToString(moment.max(phaseEndDates)),
  };
}

function getPercentageFromDateRange(requirements, range) {
  let workingAllocatedPercent = MAX_ALLOCATION_PERCENT;
  requirements.forEach((req) => {
    if (stringToMoment(range.startDate).isSameOrAfter(req.startDate)
      && stringToMoment(range.endDate).isSameOrBefore(req.endDate)) {
      workingAllocatedPercent = req.allocatedPercent;
    }
  });
  return workingAllocatedPercent;
}

function generateSplitRequirements(sortedPhases, currentReqs = [], includeName) {
  const splitPhases = splitOverlappingSegments(sortedPhases, currentReqs);
  const converted = sortedPhases.map(range => convertSegmentToRange(range));

  return splitPhases.map((segment) => {
    const allocatedPercent = getPercentageFromDateRange(currentReqs, segment);
    const segmentRange = convertSegmentToRange(segment);
    const names = [];
    converted.forEach((phase) => {
      if (segmentRange.range.overlaps(phase.range, { adjacent: true })) {
        names.push(phase.name);
      }
    });
    return {
      startDate: segment.startDate,
      endDate: segment.endDate,
      allocatedPercent,
      ...includeName && { name: names.join(', ') },
    };
  });
}

function buildPhaseRequirements(selectedPhases, currentReqs, includeName = false) {
  if (!selectedPhases.length) return [];
  const { startDate, endDate } = getPhaseMinMaxDates(selectedPhases);
  const sortedPhases = sortSegmentsByStartDate(selectedPhases);
  const requirements = generateSplitRequirements(sortedPhases, currentReqs, includeName);

  if (selectedPhases.length > 1) {
    const unfilledSegments = getUnfilledSegments(requirements, startDate, endDate);

    unfilledSegments.forEach((segment) => {
      const unfilledSegment = {
        startDate: segment.startDate,
        endDate: segment.endDate,
        allocatedPercent: 0,
        ...includeName && { name: 'None' },
      };
      requirements.push(unfilledSegment);
    });
  }

  return naturalSort(stitchSegmentsByRange(requirements), ['startDate', 'endDate', 'name']);
}

function buildRolePhaseData(selectedPhases, role) {
  if (!isStringOrMoment(role.startDate)
    || !isStringOrMoment(role.endDate)
    || (!isValidSegmentArray(selectedPhases) && selectedPhases.length > 0)) {
    return null;
  }

  if (!selectedPhases.length) {
    return role;
  }

  const { startDate, endDate } = getPhaseMinMaxDates(selectedPhases);

  const newRequirements = mergeRequirements(buildPhaseRequirements(selectedPhases, role.requirements));

  return {
    startDate,
    endDate,
    ...newRequirements.length && { requirements: newRequirements },
    selectedPhases,
  };
}

function getPhaseAllocations(phases, selectedPhases, role) {
  if (!phases.length) return role.requirements || [];
  let requirements = [];
  if (selectedPhases.length) {
    requirements = buildPhaseRequirements(selectedPhases, role.requirements, true);
  } else {
    const sortedPhases = naturalSort(phases, ['startDate', 'endDate', 'name']);
    requirements = generateSplitRequirements(sortedPhases, role.requirements, true);

    const unfilledSegments = getUnfilledSegments(requirements, role.startDate, role.endDate);
    unfilledSegments.forEach((segment) => {
      const allocatedPercent = getPercentageFromDateRange(role.requirements, segment);
      const unfilledSegment = {
        startDate: segment.startDate,
        endDate: segment.endDate,
        allocatedPercent,
        name: 'None',
      };
      requirements.push(unfilledSegment);
    });
  }
  const sortedRequirements = naturalSort(requirements, 'startDate', 'endDate', 'name');
  return trimSegmentsToRange(sortedRequirements, role.startDate, role.endDate);
}

export {
  requirementsMapToPeriod,
  getMonthlyAllocations,
  getWeeklyAllocations,
  getPhaseAllocations,
  checkRangeChanges,
  isValidRequirement,
  updateRequirements,
  mergeRequirements,
  deleteRequirement,
  buildRolePhaseData,
  getPercentageFromDateRange,
};
