import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@material-ui/core';
import { Edit, AttachMoney } from '@material-ui/icons';
import { Button } from '@bridgit/foundation';
import { connect } from 'react-redux';
import { RequiredToggle, PrivateToggle } from '.';
import { OutlinedInput } from '../wrapped-components';
import { FieldTypeMap } from '../../common/constants';
import { PEOPLE_DISPLAY_COST_RATE } from '../people/constants';

export class CustomField extends PureComponent {
  static propTypes = {
    field: PropTypes.object.isRequired,
    onSetEdit: PropTypes.func,
    disabled: PropTypes.bool,
    editing: PropTypes.bool,
    readOnly: PropTypes.bool,
    showEdit: PropTypes.bool,
    onButtonClick: PropTypes.func,
    defaultCostRateFlag: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    disabled: false,
    editing: false,
    readOnly: false,
    showEdit: false,
    onSetEdit: null,
    onButtonClick: null,
  }

  setEdit = () => {
    const { field, onSetEdit } = this.props;
    onSetEdit(field);
  }

  handleButtonClick = () => {
    const { onButtonClick } = this.props;
    onButtonClick();
  }

  render() {
    const { field, editing, disabled, readOnly, showEdit, defaultCostRateFlag } = this.props;

    return (
      <div className="account-settings-custom-field row">
        <OutlinedInput
          className="visible no-wrap"
          disabled={disabled}
          value={field.name.toString()}
          readOnly={readOnly}
        />

        <OutlinedInput disabled={disabled} value={FieldTypeMap[field.type]} className="form__select visible no-wrap" readOnly />

        {(field.type === 'SingleSelect' || field.type === 'MultiSelect') && (
          <OutlinedInput disabled={disabled} value={`"${field.name}"`} className="form__select visible no-wrap" readOnly />
        )}

        {!editing && field.isRequired && (
          <RequiredToggle disabled={disabled} type="view" />
        )}

        {!editing && field.isPrivate && (
          <PrivateToggle disabled={disabled} type="view" />
        )}

        {!editing && field?.isFinancials && (
          <>
            {field.name === PEOPLE_DISPLAY_COST_RATE && defaultCostRateFlag && (
            <Button
              color="primary"
              className="manage-defaults-button"
              onClick={this.handleButtonClick}
              disabled={disabled}
            >
              Manage Defaults
            </Button>
            )}
            <Tooltip title="Financial permission required" placement="top">
              <div className="financials-icon">
                <AttachMoney />
              </div>
            </Tooltip>
          </>
        )}

        {showEdit && (
          <Tooltip title="Edit" placement="top">
            <IconButton disabled={disabled} className={`button-edit${disabled ? ' icon-disabled' : ''}`} disableRipple variant="contained" onClick={this.setEdit}>
              <Edit className="icon-edit" aria-label="Required" />
            </IconButton>
          </Tooltip>
        )}

      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps({ launchDarkly }) {
  const { defaultCostRate: defaultCostRateFlag } = launchDarkly;

  return {
    defaultCostRateFlag,
  };
}

export default connect(
  mapStateToProps,
)(CustomField);
