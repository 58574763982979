import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNT_SETTINGS_UPDATE_ACCOUNT_MODULE_BEGIN,
  ACCOUNT_SETTINGS_UPDATE_ACCOUNT_MODULE_SUCCESS,
  ACCOUNT_SETTINGS_UPDATE_ACCOUNT_MODULE_FAILURE,
  ACCOUNT_SETTINGS_UPDATE_ACCOUNT_MODULE_DISMISS_ERROR,
} from './constants';
import { protectedJsonPatch } from '../../../utils/api';

export function updateAccountModule(accountId, moduleId, data) {
  return {
    type: ACCOUNT_SETTINGS_UPDATE_ACCOUNT_MODULE_BEGIN,
    accountId,
    moduleId,
    data,
  };
}

export function dismissUpdateAccountModuleError() {
  return {
    type: ACCOUNT_SETTINGS_UPDATE_ACCOUNT_MODULE_DISMISS_ERROR,
  };
}

export function* doUpdateAccountModule(action) {
  const { accountId, moduleId, data } = action;
  let response;
  try {
    response = yield call(protectedJsonPatch, `accounts/${accountId}/modules/${moduleId}`, data);
  } catch (err) {
    yield put({
      type: ACCOUNT_SETTINGS_UPDATE_ACCOUNT_MODULE_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: ACCOUNT_SETTINGS_UPDATE_ACCOUNT_MODULE_SUCCESS,
    data, // Mixpanel
    response,
  });
}

export function* watchUpdateAccountModule() {
  yield takeLatest(ACCOUNT_SETTINGS_UPDATE_ACCOUNT_MODULE_BEGIN, doUpdateAccountModule);
}

export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNT_SETTINGS_UPDATE_ACCOUNT_MODULE_BEGIN:
      return {
        ...state,
        updateAccountModulePending: true,
        updateAccountModuleError: null,
      };

    case ACCOUNT_SETTINGS_UPDATE_ACCOUNT_MODULE_SUCCESS: {
      const { response } = action;
      return {
        ...state,
        accountModules: [
          ...state.accountModules.filter(mod => mod.id !== response.id),
          response,
        ],
        updateAccountModulePending: false,
        updateAccountModuleError: null,
      };
    }

    case ACCOUNT_SETTINGS_UPDATE_ACCOUNT_MODULE_FAILURE:
      return {
        ...state,
        updateAccountModulePending: false,
        updateAccountModuleError: action.data.error,
      };

    case ACCOUNT_SETTINGS_UPDATE_ACCOUNT_MODULE_DISMISS_ERROR:
      return {
        ...state,
        updateAccountModuleError: null,
      };

    default:
      return state;
  }
}
