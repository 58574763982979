import moment from 'moment';
import { BLANK_FILTER } from 'src/filters/constants';

export const shouldDisableApply = (selected, verb, date, endDate) => {
  if (verb !== BLANK_FILTER && !date) {
    return true;
  }
  if (verb === 'between' && !endDate) {
    return true;
  }
  if (selected) {
    const dateInfo = selected.selected[0];
    if (verb === BLANK_FILTER && selected.verb === BLANK_FILTER) {
      return true;
    }
    return selected.verb === verb && (!date || moment.utc(dateInfo.date).isSame(date, 'day')) && (!endDate || moment.utc(dateInfo.endDate).isSame(endDate, 'day'));
  }
  if (verb === BLANK_FILTER) {
    return false;
  }
  return !date;
};

export const shouldDisableDate = (endDate, verb) => dateInput => (
  verb === 'between' ? moment(dateInput).isAfter(endDate) : false
);

export const shouldDisableEndDate = date => dateInput => (
  moment(dateInput).isBefore(date)
);
