import moment from 'moment';
import { timelineIntervals } from '../../features/gantt/utils/ganttUtils';
import { capitalize } from '../../utils/formatters';
import { LETTER_PAPER } from '../../features/reports/redux/constants';
import { parseRoleFilters } from '../../features/reports/utils/reportUtils';
import { SORT_ROLES_BY_DISPLAY_MAP } from '../../features/gantt/project-gantt/constants';
import { FILLED_ROLES, UNFILLED_ROLES } from '../../filters/constants';
import { PEOPLE_VIEW_SETTINGS_GANTT_DISPLAY_MAP } from '../../features/gantt/people-gantt/constants';

export const getBasicGanttReportAnalyticsPayload = (settings, filteredEntity) => {
  const {
    paperSize,
    timelineLength,
    pageOrientation,
    title,
    earliestDate,
  } = settings;

  const { filter, sort, search } = filteredEntity;

  const { length, units } = timelineIntervals[timelineLength];
  const capitalizedUnits = capitalize(units);
  const selectedInterval = `${length} ${capitalizedUnits}`;

  return {
    'Report title': title,
    'Earliest month displayed': moment(earliestDate).format('MMM, YYYY'),
    'Interval selected': selectedInterval,
    'Page orientation selected': capitalize(pageOrientation),
    'Page size selected': paperSize === LETTER_PAPER ? 'letter' : 'large',
    'Filters applied': filter.args.map(({ label }) => label).join(', '),
    'Sort applied': sort.args?.[0]?.label,
    'Search applied': search?.args?.[0]?.activeFilters?.[0] || '',
  };
};

export const getProjectGanttReportAnalyticsPayload = ({ viewRoles, roleSortOption, roleFilters, includeAllocPerc }) => {
  const analyticsPayload = {
    'Allocations included': includeAllocPerc,
    'View Roles': viewRoles,
  };

  if (viewRoles) {
    const { nameFilters, hasFilledFilter, hasUnfilledFilter } = parseRoleFilters(roleFilters);
    const isSingleFilter = (hasFilledFilter && !hasUnfilledFilter) || (!hasFilledFilter && hasUnfilledFilter);

    analyticsPayload['Role Sort applied'] = SORT_ROLES_BY_DISPLAY_MAP[roleSortOption];

    if (isSingleFilter) {
      analyticsPayload['Unfilled/Filled Role Filters applied'] = hasFilledFilter ? FILLED_ROLES : UNFILLED_ROLES;
    }

    if (nameFilters.length) {
      analyticsPayload['Role name filter applied'] = nameFilters.join(', ');
      analyticsPayload['Number of roles selected'] = nameFilters.length;
    }
  }

  return analyticsPayload;
};

export const getPeopleGanttReportAnalyticsPayload = (peopleGanttSettings) => {
  const { displayOptions, ganttDisplay, colorOption } = peopleGanttSettings;
  const { photo, title: personTitle } = displayOptions;

  return {
    'Gantt display option applied': PEOPLE_VIEW_SETTINGS_GANTT_DISPLAY_MAP[ganttDisplay],
    'Project color option applied': colorOption,
    'Titles displayed': !!personTitle,
    'Photos displayed': !!photo,
  };
};
