import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { LinearProgress } from '@material-ui/core';
import { DragHandle } from '@material-ui/icons';
import classNames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { SubPhaseList } from '.';
import { reorderList } from '../../utils/miscUtils';

function PhaseList({
  phases,
  getPhasesPending,
  activePhase,
  editing,
  activeSubPhase,
  editingSubPhase,
  setActivePhase,
  setActiveSubPhase,
  updatePhases,
  updateSubPhases,
}) {
  const [orderedPhases, setOrderedPhases] = useState(phases);
  const [draggingIndex, setDraggingIndex] = useState(null);

  useEffect(() => {
    if (!activePhase) setOrderedPhases(phases);
  }, [activePhase, phases]);

  const onDragStart = (result) => {
    const { source } = result;
    setDraggingIndex(source.index);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
      setDraggingIndex(null);
      return;
    }
    const newPhases = reorderList(orderedPhases, source.index, destination.index);
    setOrderedPhases(newPhases);
    setDraggingIndex(null);
    updatePhases(newPhases);
  };

  const phaseItems = activePhase ? phases : orderedPhases;

  const renderPhaseList = () => (
    <Droppable droppableId="phases">
      {provided => (
        <div
          className={classNames('phase-list', { scrollable: phases.length > 5 })}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {phaseItems.map((phase, index) => (
            <Draggable draggableId={String(phase.id)} index={index} key={phase.id}>
              {provided => (
                <div
                  onClick={setActivePhase}
                  className={classNames('phase-item', { active: activePhase && phase.id === activePhase.id, dragging: draggingIndex === index })}
                  data-id={phase.id}
                  role="presentation"
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                >
                  <div className="phase-item-start">
                    <div className={classNames('drag-handle', { hide: activePhase || editing })} {...provided.dragHandleProps}>
                      <DragHandle />
                    </div>
                    <p className="phase-name">{phase.name}</p>
                  </div>
                  <SubPhaseList
                    phase={phase}
                    phases={phases}
                    activePhase={activePhase}
                    activeSubPhase={activeSubPhase}
                    editingSubPhase={editingSubPhase}
                    setActiveSubPhase={setActiveSubPhase}
                    updateSubPhases={updateSubPhases}
                  />
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );

  return (
    <div className="account-settings-phase-list">
      {getPhasesPending ? <LinearProgress variant="query" />
        : (
          <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
            {renderPhaseList()}
            {editing && !activePhase && (
              <div className="phase-item active">
                <p className="phase-name">Phase</p>
              </div>
            )}
          </DragDropContext>
        )}
    </div>
  );
}

PhaseList.propTypes = {
  phases: PropTypes.array.isRequired,
  getPhasesPending: PropTypes.bool.isRequired,
  activePhase: PropTypes.object,
  editing: PropTypes.bool.isRequired,
  activeSubPhase: PropTypes.object,
  editingSubPhase: PropTypes.bool.isRequired,
  setActivePhase: PropTypes.func.isRequired,
  setActiveSubPhase: PropTypes.func.isRequired,
  updatePhases: PropTypes.func.isRequired,
  updateSubPhases: PropTypes.func.isRequired,
};

PhaseList.defaultProps = {
  activePhase: null,
  activeSubPhase: null,
};

export default PhaseList;
