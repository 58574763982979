import { call, put, takeLatest } from 'redux-saga/effects';
import {
  LOGIN_GET_DEFAULT_TEAMS_BEGIN,
  LOGIN_GET_DEFAULT_TEAMS_SUCCESS,
  LOGIN_GET_DEFAULT_TEAMS_FAILURE,
  LOGIN_GET_DEFAULT_TEAMS_DISMISS_ERROR,
} from './constants';
import { getBaseRequest } from '../../../utils/api';

export function getDefaultTeams() {
  return {
    type: LOGIN_GET_DEFAULT_TEAMS_BEGIN,
  };
}

export function dismissGetDefaultTeamsError() {
  return {
    type: LOGIN_GET_DEFAULT_TEAMS_DISMISS_ERROR,
  };
}

export function* doGetDefaultTeams() {
  let res;
  try {
    res = yield call(getBaseRequest, 'bench-defaults/teams');
  } catch (err) {
    yield put({
      type: LOGIN_GET_DEFAULT_TEAMS_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: LOGIN_GET_DEFAULT_TEAMS_SUCCESS,
    data: res,
  });
}

export function* watchGetDefaultTeams() {
  yield takeLatest(LOGIN_GET_DEFAULT_TEAMS_BEGIN, doGetDefaultTeams);
}

export function reducer(state, action) {
  switch (action.type) {
    case LOGIN_GET_DEFAULT_TEAMS_BEGIN:
      return {
        ...state,
        getDefaultTeamsPending: true,
        getDefaultTeamsError: null,
      };

    case LOGIN_GET_DEFAULT_TEAMS_SUCCESS:
      return {
        ...state,
        defaultTeams: action.data?.teams,
        getDefaultTeamsPending: false,
        getDefaultTeamsError: null,
      };

    case LOGIN_GET_DEFAULT_TEAMS_FAILURE:
      return {
        ...state,
        getDefaultTeamsPending: false,
        getDefaultTeamsError: action.data.error,
      };

    case LOGIN_GET_DEFAULT_TEAMS_DISMISS_ERROR:
      return {
        ...state,
        getDefaultTeamsError: null,
      };

    default:
      return state;
  }
}
