import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Dialog,
  DialogContent,
  DialogActions,
  AppBar,
  Typography,
  Toolbar,
} from '@material-ui/core';
import { EditControls } from '../wrapped-components';

export default class Confirm extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    headline: PropTypes.string.isRequired,
    cancelButtonText: PropTypes.string,
    acceptButtonText: PropTypes.string.isRequired,
    onCancel: PropTypes.func,
    onAccept: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    className: PropTypes.string,
    showSecondary: PropTypes.bool,
  };

  static defaultProps = {
    children: '',
    cancelButtonText: '',
    onCancel: () => {},
    loading: false,
    className: '',
    showSecondary: true,
  };

  render() {
    const {
      children,
      headline,
      cancelButtonText,
      acceptButtonText,
      onAccept,
      onCancel,
      loading,
      className,
      showSecondary,
    } = this.props;

    return (
      <Dialog
        classes={{ root: classNames('common-confirm', className), paperWidthXs: 'mid-size' }}
        open
        fullWidth
        maxWidth="xs"
      >
        <AppBar position="static" color="primary" className="header">
          <Toolbar className="dialog__header">
            <Typography color="inherit" component="h4" variant="h6">{headline}</Typography>
          </Toolbar>
        </AppBar>

        <DialogContent>{children}</DialogContent>

        <DialogActions classes={{ root: 'footer' }} disableSpacing>
          <EditControls
            primaryAction={onAccept}
            secondaryAction={onCancel}
            pending={loading}
            primaryText={acceptButtonText}
            secondaryText={cancelButtonText}
            showSecondary={showSecondary}
          />
        </DialogActions>
      </Dialog>
    );
  }
}
