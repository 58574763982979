import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  DATE_EDIT_ACTIONS,
  START_DATE,
  END_DATE,
  EDIT_ACTION_EXTENDED,
} from 'src/common/constants';
import { momentToString } from 'src/utils/dateUtils';
import { fitSegmentsToRange } from 'src/utils/dateSegmentUtils';
import { updateProject } from 'src/features/projects/redux/actions';
import { PERM_WRITE, PERM_ROLE } from 'src/features/permissions/utils/constants';
import { isAuthorized } from 'src/features/permissions/utils/permissionUtils';
import { getGroupData, extendSegmentDates, getAffectedProjectEntities } from './utils/dateEditorUtils';
import { DateModalControls, SegmentGroup } from '.';
import { SEGMENT_TYPE_PHASE, SEGMENT_TYPE_PROJECT, SEGMENT_TYPE_ROLE } from './common/constants';
import { setEditorSegments } from './redux/actions';
import { updateProjectDuration } from '../projects/redux/updateProjectDuration';

const ProjectEditor = ({
  segments,
  editAction,
  dateUnderEdit,
  project,
  newStartDate,
  newEndDate,
  boundingStartDate,
  boundingEndDate,
  accountId,
  updateProjectDuration,
  offset,
  onClose,
  trackDateEdit,
  isSaving,
  permissions,
  setEditorSegments,
}) => {
  const isSavingRef = useRef(isSaving);

  useEffect(() => {
    const editorSegments = getAffectedProjectEntities(project, newStartDate, newEndDate);
    setEditorSegments(editorSegments);
  }, [newEndDate, newStartDate, project, setEditorSegments]);

  const onSave = () => {
    const {
      selectedSegments: selectedPhases,
      totalCount: phaseCount,
      updatedCount: updatedPhaseCount,
      deleteCount: deletedPhaseCount,
    } = getGroupData(segments?.[SEGMENT_TYPE_PHASE]);

    const {
      selectedSegments: selectedRoles,
      totalCount: roleCount,
      updatedCount: updatedRoleCount,
      deleteCount: deletedRoleCount,
    } = getGroupData(segments?.[SEGMENT_TYPE_ROLE]);

    const hasRolePerms = isAuthorized(accountId, permissions, PERM_WRITE, PERM_ROLE);

    let phaseData = [];
    let roleData = [];
    if (editAction === EDIT_ACTION_EXTENDED) {
      phaseData = selectedPhases.map((segment) => {
        const { startDate, endDate } = extendSegmentDates(segment, dateUnderEdit, offset);
        const { id } = segment;

        return {
          id,
          startDate,
          endDate,
          expandSubphases: true,
        };
      });

      if (hasRolePerms) {
        roleData = selectedRoles.map((role) => {
          const { id, name } = role;
          const { startDate, endDate } = extendSegmentDates(role, dateUnderEdit, offset);
          const allocations = fitSegmentsToRange(role.requirements, startDate, endDate);

          const formattedAllocations = allocations.map(({ allocatedPercent, startDate, endDate }) => (
            { allocatedPercent, startDate, endDate }
          ));

          return {
            id,
            name,
            startDate,
            endDate,
            expandAllocations: true,
            allocations: formattedAllocations,
          };
        });
      }
    }

    trackDateEdit(
      phaseCount,
      updatedPhaseCount,
      deletedPhaseCount,
      roleCount,
      updatedRoleCount,
      deletedRoleCount,
    );

    updateProjectDuration(accountId, project.id, momentToString(newStartDate), momentToString(newEndDate), phaseData, roleData);
  };

  // Close the modal after saving the changes.
  useEffect(() => {
    if (isSavingRef.current && !isSaving) {
      onClose();
    }

    isSavingRef.current = isSaving;
  }, [isSaving, onClose]);


  if (!project || !segments) return null;

  const renderGroups = () => {
    if (!boundingStartDate || !boundingEndDate) return null;
    const groups = [
      SEGMENT_TYPE_PROJECT,
      SEGMENT_TYPE_PHASE,
      SEGMENT_TYPE_ROLE,
    ].map(type => (<SegmentGroup key={type} groupType={type} />));

    return (
      <>{groups}</>
    );
  };

  return (
    <div className="edit-dates-project-editor">
      {renderGroups()}
      <DateModalControls
        pending={isSaving}
        primaryAction={onSave}
        secondaryAction={onClose}
      />
    </div>
  );
};

ProjectEditor.propTypes = {
  editAction: PropTypes.oneOf(DATE_EDIT_ACTIONS),
  dateUnderEdit: PropTypes.oneOf([START_DATE, END_DATE]),
  onClose: PropTypes.func.isRequired,
  project: PropTypes.object,
  newStartDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
  newEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
  boundingStartDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
  boundingEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
  accountId: PropTypes.number.isRequired,
  updateProjectDuration: PropTypes.func.isRequired,
  segments: PropTypes.object,
  offset: PropTypes.number.isRequired,
  trackDateEdit: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  permissions: PropTypes.object.isRequired,
  setEditorSegments: PropTypes.func.isRequired,
};

ProjectEditor.defaultProps = {
  project: null,
  editAction: null,
  dateUnderEdit: null,
  newStartDate: null,
  newEndDate: null,
  boundingStartDate: null,
  boundingEndDate: null,
  segments: {},
};

/* istanbul ignore next */
function mapStateToProps({ editDates, projects, common, login }) {
  const {
    editAction,
    dateUnderEdit,
    newStartDate,
    newEndDate,
    boundingStartDate,
    boundingEndDate,
    segments,
    offset,
  } = editDates;
  const { updateProjectDurationPending } = projects;
  const { accountId, contentView } = common;
  const { userInfo: { permissions } } = login;

  return {
    editAction,
    dateUnderEdit,
    newStartDate,
    newEndDate,
    boundingStartDate,
    boundingEndDate,
    accountId,
    contentView,
    segments,
    offset,
    isSaving: updateProjectDurationPending,
    permissions,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    setEditorSegments: bindActionCreators(setEditorSegments, dispatch),
    updateProject: bindActionCreators(updateProject, dispatch),
    updateProjectDuration: bindActionCreators(updateProjectDuration, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectEditor);
