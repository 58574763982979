import {
  SERVICE_ACCOUNTS_RESET_SERVICE_ACCOUNT,
} from './constants';

export function resetServiceAccount() {
  return {
    type: SERVICE_ACCOUNTS_RESET_SERVICE_ACCOUNT,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case SERVICE_ACCOUNTS_RESET_SERVICE_ACCOUNT:
      return {
        ...state,
        account: null,
      };

    default:
      return state;
  }
}
