import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  FormControl,
  Input,
  Select,
  MenuItem,
  InputLabel,
  InputAdornment,
  FormHelperText,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import moment from 'moment';
import { Autocomplete } from '@material-ui/lab';
import { HelpTooltip } from '@bridgit/foundation';
import { ColorPicker, CancelButton, DatePicker } from '../common';
import { AUTOMATION_PROJECT_REQUESTERS_INPUT_ID } from '../projects/ids';
import { getSortedSelectedRequesterNames } from '../projects/utils/projectRequestersUtils';
import { AddressAutocomplete } from '.';
import { MIN_PERCENTAGE_VALUE, MAX_PERCENTAGE_VALUE } from '../../common/constants';

class FormField extends PureComponent {
  static propTypes = {
    type: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.object,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.arrayOf(PropTypes.object),
    ]),
    placeholder: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onDateInputFocus: PropTypes.func,
    onBlur: PropTypes.func,
    multi: PropTypes.bool,
    required: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.object),
    width: PropTypes.string,
    primary: PropTypes.bool,
    maxLength: PropTypes.number,
    initDate: PropTypes.object,
    minDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    maxDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    minDateMessage: PropTypes.string,
    alwaysShowErrors: PropTypes.bool,
    onKeyUp: PropTypes.func,
    autoFocus: PropTypes.bool,
    onSelectOpen: PropTypes.func,
    onSelectClose: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    defaultTouched: PropTypes.bool,
    isNoneOptionHidden: PropTypes.bool,
    tooltipText: PropTypes.string,
    defaultCostRateFlag: PropTypes.bool,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    numStep: PropTypes.number,
  };

  static defaultProps = {
    type: 'text',
    label: null,
    value: '',
    placeholder: null,
    error: null,
    multi: false,
    required: false,
    options: [],
    width: '',
    primary: false,
    maxLength: 250,
    initDate: null,
    minDate: undefined,
    maxDate: undefined,
    minDateMessage: '',
    alwaysShowErrors: false,
    onKeyUp: () => {},
    autoFocus: false,
    onDateInputFocus: () => {},
    onBlur: () => {},
    onSelectOpen: () => {},
    onSelectClose: () => {},
    disabled: false,
    className: '',
    id: '',
    defaultTouched: false,
    isNoneOptionHidden: false,
    tooltipText: null,
    defaultCostRateFlag: false,
    minValue: MIN_PERCENTAGE_VALUE,
    maxValue: MAX_PERCENTAGE_VALUE,
    numStep: 1,
  };

  constructor(props) {
    super(props);
    const { type, value, defaultTouched } = props;
    const workingDate = type === 'date' ? value : '';

    this.state = {
      touched: defaultTouched,
      dateFocused: false,
      workingDate,
    };
  }

  componentDidUpdate(prevProps) {
    const { type, value } = this.props;

    if (type === 'date' && value !== prevProps.value) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ workingDate: value });
    }
  }

  onDateChange = (date) => {
    const { name, onChange } = this.props;
    this.touch();
    onChange({ target: { name, value: date } });
  }

  onWorkingDateChange = (workingDate) => {
    if (moment(workingDate).isValid()) this.onDateChange(workingDate);
    this.setState({ workingDate });
  }

  touch = () => {
    const { onBlur } = this.props;
    this.setState({
      touched: true,
      dateFocused: false,
    });
    onBlur();
  }

  onClearField = () => {
    const { name, onChange } = this.props;
    onChange({ target: { name, value: null } });
  }

  onDateFocus = () => {
    const { onDateInputFocus } = this.props;
    this.setState({ dateFocused: true });
    onDateInputFocus();
  }

  onCheck = (e, value) => {
    const { onChange } = this.props;

    this.touch();
    onChange(e, value);
  };

  renderInput = () => {
    const {
      type,
      label,
      name,
      placeholder,
      value,
      required,
      onChange,
      multi,
      options,
      maxLength,
      initDate,
      minDate,
      minDateMessage,
      maxDate,
      onKeyUp,
      autoFocus,
      onSelectOpen,
      onSelectClose,
      disabled,
      className,
      id,
      isNoneOptionHidden,
      minValue,
      maxValue,
      numStep,
    } = this.props;

    const { dateFocused, workingDate } = this.state;

    switch (type) {
      case 'text':
        return (
          <Input
            multiline={multi}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={this.touch}
            inputProps={{ maxLength }}
            fullWidth
            autoFocus={autoFocus}
            onKeyUp={onKeyUp}
            disabled={disabled}
            id={label}
          />
        );
      case 'currency':
        return (
          <Input
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={this.touch}
            inputProps={{ maxLength }}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            autoFocus={autoFocus}
            onKeyUp={onKeyUp}
            id={label}
          />
        );
      case 'address': {
        return (
          <AddressAutocomplete
            name={name}
            onChange={onChange}
            renderInput={params => (
              <TextField
                {...params}
                  // "Hide" browser auto fill suggestions for "address" fields
                  // https://mui.com/components/autocomplete/#autocomplete-autofill
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
                placeholder={placeholder}
              />
            )}
            className={className}
            onOpen={this.touch}
            id="search-address"
            value={value || null}
            classes={{ inputRoot: 'address-autocomplete-input' }}
          />
        );
      }

      case 'select':
        return (
          <Select
            multiple={multi}
            value={value}
            name={name}
            onChange={onChange}
            onBlur={this.touch}
            onOpen={onSelectOpen}
            onClose={onSelectClose}
            MenuProps={{ classes: { paper: className } }}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <span className="placeholder">{placeholder}</span>;
              }

              if (id === AUTOMATION_PROJECT_REQUESTERS_INPUT_ID) {
                return getSortedSelectedRequesterNames(options, selected);
              }

              if (multi) {
                return selected.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).join(', ');
              }

              return options.find(o => o.value === selected)?.label || placeholder;
            }}
            displayEmpty
            fullWidth
            disabled={disabled}
          >
            {!multi && !required && !isNoneOptionHidden && (
              <MenuItem className="placeholder" key="empty" value="">None</MenuItem>
            )}
            {options.map(option => (
              <MenuItem key={option.value?.id || option.value} className="select-item" value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        );

      case 'date': {
        return (
          <div className="date-wrapper">
            <DatePicker
              placeholder={!dateFocused && placeholder ? placeholder : 'MM/DD/YYYY'}
              date={workingDate}
              initialFocusedDate={initDate}
              minDate={minDate}
              minDateMessage={minDateMessage}
              maxDate={maxDate}
              onChange={this.onWorkingDateChange}
              onClose={this.touch}
              onBlur={this.touch}
              onFocus={this.onDateFocus}
              variant="inline"
              id={label}
              disabled={disabled}
            />
            {value && <CancelButton className="date-cancel" onClick={this.onClearField} />}
          </div>
        );
      }

      case 'checkbox': {
        if (multi) {
          return (
            <FormGroup row className="checkbox-group">
              {value.map(({ label, checked, value, name }) => (
                <FormControlLabel
                  className="checkbox-label"
                  label={label}
                  labelPlacement="top"
                  key={label}
                  control={(
                    <Checkbox
                      className="checkbox"
                      checked={checked}
                      onChange={this.onCheck}
                      color="primary"
                      name={name}
                      value={value}
                      disableRipple
                    />
                  )}
                />
              ))}
            </FormGroup>
          );
        }

        return (
          <FormGroup row className="checkbox-group">
            <FormControlLabel
              className="checkbox-label"
              control={(
                <Checkbox
                  className="checkbox"
                  checked={!!value}
                  onChange={onChange}
                  color="primary"
                  name={name}
                  disableRipple
                />
              )}
            />
          </FormGroup>
        );
      }

      case 'image':
        return (
          <div className="image-upload">
            <div className="add-icon">
              <Add fontSize="large" />
            </div>
            <div>Click to upload a photo or drag and drop</div>
          </div>
        );

      case 'color':
        return (
          <ColorPicker
            onChange={onChange}
            value={value || undefined}
            name={name}
          />
        );

      case 'search': {
        return (
          <Autocomplete
            name={name}
            options={options}
            getOptionLabel={option => option.name || ''}
            onChange={(e, value) => onChange(e, value, name)}
            renderInput={params => <TextField placeholder={placeholder} {...params} />}
            noOptionsText="No results found"
            className={className}
            getOptionSelected={(option, selected) => option.value === selected.value}
            onOpen={this.touch}
            id={label}
            value={value || null}
          />
        );
      }

      case 'percentage': {
        return (
          <Input
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={this.touch}
            inputProps={{ min: minValue, max: maxValue, step: numStep }}
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
            autoFocus={autoFocus}
            onKeyUp={onKeyUp}
            id={label}
            type="number"
          />
        );
      }
      default:
        return null;
    }
  }

  render() {
    const {
      placeholder,
      label,
      error,
      required,
      width,
      primary,
      value,
      type,
      alwaysShowErrors,
      className,
      tooltipText,
      defaultCostRateFlag,
    } = this.props;
    const { touched, dateFocused } = this.state;

    const showErrors = (touched || alwaysShowErrors) && error;

    let widthClass;

    switch (width) {
      case 'full':
        widthClass = ' full-width';
        break;
      case 'large':
        widthClass = ' large-width';
        break;
      case 'medium':
        widthClass = ' medium-width';
        break;
      case 'quarter':
        widthClass = ' quarter-width';
        break;
      case 'small':
        widthClass = ' small-width';
        break;
      default:
        widthClass = '';
    }

    return (
      <div className={classNames(`wrapped-components-form-field${widthClass}`, { primary: !!primary }, className)}>
        <FormControl fullWidth>
          {label && (
            <InputLabel
              className={classNames({
                'form-field-with-tooltip': tooltipText && defaultCostRateFlag,
              })}
              shrink
              disableAnimation
            >
              {label}
              {tooltipText && defaultCostRateFlag && <HelpTooltip text={tooltipText} />}
            </InputLabel>
          )}
          {(dateFocused || (value && type === 'date')) && label === '' && (
            <InputLabel className="date-label" shrink disableAnimation>
              {placeholder}
            </InputLabel>
          )}
          {this.renderInput()}
          {showErrors && <FormHelperText className="field-error">{error}</FormHelperText>}
          {!showErrors && required && <FormHelperText className="required-icon">Required *</FormHelperText>}
        </FormControl>
      </div>
    );
  }
}

// TODO: remove redux stuff when flags are removed
const mapStateToProps = ({ launchDarkly: { defaultCostRate } }) => ({
  defaultCostRateFlag: defaultCostRate,
});

export default connect(mapStateToProps)(FormField);
