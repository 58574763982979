import { call, put, takeLatest } from 'redux-saga/effects';
import { LOGIN_USERLOGOUT_BEGIN, LOGIN_USERLOGOUT_FAILURE, LOGIN_USERLOGOUT_SUCCESS, LOGIN_USERLOGOUT_DISMISS_ERROR } from './constants';
import { logout } from '../../../utils/api';
import { clearUserInfo } from '../../../common/analyticsHelper';


export function userLogout() {
  return {
    type: LOGIN_USERLOGOUT_BEGIN,
  };
}

export function dismissUserLogoutError() {
  return {
    type: LOGIN_USERLOGOUT_DISMISS_ERROR,
  };
}

export function* doPostLogout() {
  let res;
  try {
    res = yield call(logout);
  } catch (err) {
    yield put({
      type: LOGIN_USERLOGOUT_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: LOGIN_USERLOGOUT_SUCCESS,
    data: res,
  });
}

export function* watchPostLogOut() {
  yield takeLatest(LOGIN_USERLOGOUT_BEGIN, doPostLogout);
}

export function reducer(state, action) {
  switch (action.type) {
    case LOGIN_USERLOGOUT_BEGIN:
      return {
        ...state,
        postLogoutPending: true,
      };
    case LOGIN_USERLOGOUT_SUCCESS: {
      clearUserInfo();
      return {
        ...state,
        postLogoutPending: false,
      };
    }
    case LOGIN_USERLOGOUT_FAILURE:
    case LOGIN_USERLOGOUT_DISMISS_ERROR:
      return {
        ...state,
        postLogoutPending: false,
      };
    default:
      return state;
  }
}
