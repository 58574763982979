const initialState = {
  peopleFilterData: [],
  projectFilterData: [],
  hourlyPersonFilterData: [],
  filterMenu: {
    filterListAnchorElId: null,
    filterAnchorElId: null,
    open: false,
    popperIsOpen: false,
  },
};

export default initialState;
