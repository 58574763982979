const initialState = {
  roleSegments: [],
  getFilteredRolesPending: false,
  getFilteredRolesError: null,
  count: 0,
  pageFrom: 0,
  hasMore: false,
  getFilteredRolesPagePending: false,
  getFilteredRolesPageError: null,
};

export default initialState;
