import { call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import {
  PROJECTS_SEND_ROLE_NOTIFICATION_BEGIN,
  PROJECTS_SEND_ROLE_NOTIFICATION_SUCCESS,
  PROJECTS_SEND_ROLE_NOTIFICATION_FAILURE,
  PROJECTS_SEND_ROLE_NOTIFICATION_DISMISS_ERROR,
  PROJECT_LIST_SELECTION_ID,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';
import { ASSIGNMENT_REMOVE_REPLACE_COMMUNICATION_SENT } from '../../../analytics/projects/constants';
import { ALLOCATION_REMOVED, ALLOCATION_REPLACED } from '../constants';
import { MULTI_STATE_MODAL_ID } from '../../common/redux/constants';

export function sendRoleNotification(accountId, projectId, roleId, data, analyticsPayload, callback) {
  return {
    type: PROJECTS_SEND_ROLE_NOTIFICATION_BEGIN,
    accountId,
    projectId,
    roleId,
    data,
    analyticsPayload,
    callback,
  };
}

export function dismissSendRoleNotificationError() {
  return {
    type: PROJECTS_SEND_ROLE_NOTIFICATION_DISMISS_ERROR,
  };
}

export function* doSendRoleNotification(action) {
  const { accountId, projectId, roleId, data, analyticsPayload, callback } = action;
  const { allocationChangeType } = analyticsPayload;
  const isAllocationRemovedReplaced = [ALLOCATION_REMOVED, ALLOCATION_REPLACED].includes(allocationChangeType);

  try {
    yield call(protectedJsonPost, `/accounts/${accountId}/projects/${projectId}/roles/${roleId}/notifications`, data);
  } catch (err) {
    yield put({
      type: PROJECTS_SEND_ROLE_NOTIFICATION_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PROJECTS_SEND_ROLE_NOTIFICATION_SUCCESS,
    data,
    analyticsPayload,
    roleId,
    projectId,
    mixpanelEvent: isAllocationRemovedReplaced ? ASSIGNMENT_REMOVE_REPLACE_COMMUNICATION_SENT : null,
  });

  // callback examples: deleteProjectAllocation, replaceProjectRoleAllocation
  if (callback) yield call(callback);
}

export function* watchSendRoleNotification() {
  yield takeLatest(PROJECTS_SEND_ROLE_NOTIFICATION_BEGIN, doSendRoleNotification);
}

export function reducer(state, action) {
  switch (action.type) {
    case PROJECTS_SEND_ROLE_NOTIFICATION_BEGIN:
      return {
        ...state,
        sendRoleNotificationPending: true,
        sendRoleNotificationError: null,
      };

    case PROJECTS_SEND_ROLE_NOTIFICATION_SUCCESS: {
      const { projectSelections } = state;
      const { roleId, data, projectId } = action;

      const projectSelectionStateToUpdate = projectSelections[PROJECT_LIST_SELECTION_ID]?.id === projectId
        ? PROJECT_LIST_SELECTION_ID
        : MULTI_STATE_MODAL_ID;

      const selectedProject = projectSelections[projectSelectionStateToUpdate];

      const updatedRoles = selectedProject.roles.map(role => (role.id === roleId
        ? {
          ...role,
          isCommunicated: true,
          notification: {
            notifiedOn: moment(),
            recipientIds: data.map(({ personId }) => personId),
          },
        }
        : role));

      return {
        ...state,
        sendRoleNotificationPending: false,
        sendRoleNotificationError: null,
        projectSelections: {
          ...projectSelections,
          [projectSelectionStateToUpdate]: {
            ...selectedProject,
            roles: updatedRoles,
          },
        },
      };
    }

    case PROJECTS_SEND_ROLE_NOTIFICATION_FAILURE:
      return {
        ...state,
        sendRoleNotificationPending: false,
        sendRoleNotificationError: action.data.error,
      };

    case PROJECTS_SEND_ROLE_NOTIFICATION_DISMISS_ERROR:
      return {
        ...state,
        sendRoleNotificationError: null,
      };

    default:
      return state;
  }
}
