import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { logRollbar } from 'src/common/analyticsHelper';
import { ErrorPage } from '.';

export default class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node,
    errorDisplay: PropTypes.node,
  }

  static defaultProps = {
    children: null,
    errorDisplay: <ErrorPage />,
  }

  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logRollbar(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children, errorDisplay } = this.props;

    if (hasError) {
      return errorDisplay || children;
    }

    return children;
  }
}
