import initialState from './initialState';
import { reducer as addProjectReducer } from './addProject';
import { reducer as getProjectsReducer } from './getProjects';
import { reducer as getProjectFieldValuesReducer } from './getProjectFieldValues';
import { reducer as updateProjectReducer } from './updateProject';
import { reducer as addProjectRolesReducer } from './addProjectRoles';
import { reducer as getProjectRoleAvailabilitiesReducer } from './getProjectRoleAvailabilities';
import { reducer as addProjectRoleAllocationReducer } from './addProjectRoleAllocation';
import { reducer as getProjectAllocationsReducer } from './getProjectAllocations';
import { reducer as getProjectReducer } from './getProject';
import { reducer as replaceProjectRoleAllocationReducer } from './replaceProjectRoleAllocation';
import { reducer as deleteProjectReducer } from './deleteProject';
import { reducer as deleteProjectAllocationReducer } from './deleteProjectAllocation';
import { reducer as deleteProjectRoleReducer } from './deleteProjectRole';
import { reducer as updateProjectRoleReducer } from './updateProjectRole';
import { reducer as updateProjectRoleAllocationReducer } from './updateProjectRoleAllocation';
import { reducer as getExpandedAllocationsReducer } from './getExpandedAllocations';
import { reducer as getFilteredProjectsReducer } from './getFilteredProjects';
import { reducer as deleteProjectPhaseReducer } from './deleteProjectPhase';
import { reducer as deleteProjectSubPhaseReducer } from './deleteProjectSubPhase';
import { reducer as addProjectPhasesReducer } from './addProjectPhases';
import { reducer as addProjectSubPhaseReducer } from './addProjectSubPhase';

import { reducer as collectProjectTableDataReducer } from './collectProjectTableData';
import { reducer as getProjectNamesReducer } from './getProjectNames';
import { reducer as updateProjectPhaseReducer } from './updateProjectPhase';
import { reducer as updateProjectSubPhaseReducer } from './updateProjectSubPhase';
import { reducer as updateProjectFieldValueReducer } from './updateProjectFieldValue';
import { reducer as refreshAllocationMetaReducer } from './refreshAllocationMeta';
import { reducer as getProjectByIdReducer } from './getProjectById';
import { reducer as clearProjectSelectionReducer } from './clearProjectSelection';
import { reducer as clearAllProjectSelectionsReducer } from './clearAllProjectSelections';
import { reducer as refreshProjectAllocationsReducer } from './refreshProjectAllocations';
import { reducer as backupProjectSelectionReducer } from './backupProjectSelection';
import { reducer as updateProjectDurationReducer } from './updateProjectDuration';
import { reducer as getProjectDefaultsReducer } from './getProjectDefaults';
import { reducer as sendRoleNotificationReducer } from './sendRoleNotification';
import { reducer as addProjectNoteReducer } from './addProjectNote';
import { reducer as deleteProjectNoteReducer } from './deleteProjectNote';
import { reducer as updateProjectNoteReducer } from './updateProjectNote';
import { reducer as getProjectAuditReducer } from './getProjectAudit';
import { reducer as getProjectRoleReducer } from './getProjectRole';
import { reducer as getProjectRolesReducer } from './getProjectRoles';

const reducers = [addProjectReducer, getProjectsReducer, getProjectFieldValuesReducer, updateProjectReducer, addProjectRolesReducer, getProjectRoleAvailabilitiesReducer, addProjectRoleAllocationReducer, getProjectAllocationsReducer, replaceProjectRoleAllocationReducer, deleteProjectReducer, getProjectReducer, deleteProjectRoleReducer, deleteProjectAllocationReducer, updateProjectRoleReducer, updateProjectRoleAllocationReducer, getExpandedAllocationsReducer, getFilteredProjectsReducer, deleteProjectPhaseReducer, deleteProjectSubPhaseReducer, addProjectPhasesReducer, addProjectSubPhaseReducer, collectProjectTableDataReducer, getProjectNamesReducer, updateProjectPhaseReducer, updateProjectSubPhaseReducer, updateProjectFieldValueReducer, refreshAllocationMetaReducer, getProjectByIdReducer, clearProjectSelectionReducer, clearAllProjectSelectionsReducer, refreshProjectAllocationsReducer, backupProjectSelectionReducer, updateProjectDurationReducer, getProjectDefaultsReducer, sendRoleNotificationReducer, addProjectNoteReducer, deleteProjectNoteReducer, updateProjectNoteReducer, getProjectAuditReducer, getProjectRoleReducer, getProjectRolesReducer];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
