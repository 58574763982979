import React, { useState, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import {
  ClickAwayListener,
  CircularProgress,
} from '@material-ui/core';
import {
  ArrowUpward,
  ArrowDownward,
} from '@material-ui/icons';
import { SORT_ASC, SORT_DESC, ROLE_STATE_CONTROL_OPTIONS } from 'src/filters/constants';
import { LocalStorageKeys } from 'src/common/localStorageKeys';
import { AllocationIssuePopper } from 'src/features/allocations';
import { PERM_WRITE, PERM_ROLE } from 'src/features/permissions/utils/constants';
import { RadioChipForm } from '@bridgit/foundation';
import { isCurrent } from 'src/utils/dateUtils';
import {
  DEFAULT_PROJECT_ROLE_SORT,
  PROJECT_LIST_SELECTION_ID,
} from './redux/constants';
import { trackAnalytics } from '../common/redux/actions';
import { MULTI_STATE_MODAL_ID } from '../common/redux/constants';
import {
  getProjectById,
  updateProjectRoleAllocation,
  deleteProjectAllocation,
} from './redux/actions';
import {
  getRoleSorting,
  getRoleSortClause,
  getFilteredRoles,
} from './utils/projectRolesUtils';
import { getPersonAllocationsByDate } from '../people/redux/actions';
import { getValidatedLocalStorage } from '../../utils/validators';
import {
  ProjectRole,
  AddRoleSelector,
  QuickAddRoleSelector,
} from '.';
import { Can } from '../wrapped-components';
import emptyImage from '../../images/role_setup_empty.svg?url';
import { RoleTemplateSelector } from './RoleTemplateSelector';
import {
  PROJECT_VIEW_ROLES_SORTED,
  PROJECT_ROLE_STATUS_FILTER_REMOVED,
  PROJECT_ROLE_STATUS_FILTER_ADDED,
} from '../../analytics/projects/constants';

export const AssignProjectRoles = ({
  selectedProject,
  toggleEdit,
  loading,
  runsInModal,
  onSelectItem,
  projectsData,
}) => {
  const dispatch = useDispatch();

  const { accountId } = useSelector(({ common }) => common);
  const {
    getPersonAllocationsByDatePending,
    deleteProjectAllocationPending,
    addProjectRolesPending,
    personAllocationsByDate,
    deleteProjectRolePending,
  } = useSelector(({ people }) => people);
  const { getProjectRolesPending, projectRoles } = useSelector(({ projects }) => projects);

  const permissions = useSelector(({ login }) => {
    const { userInfo: { permissions } } = login;

    return permissions;
  });

  const modalId = useSelector(({ modalManager }) => {
    const { activeModal } = modalManager;

    return activeModal ? MULTI_STATE_MODAL_ID : PROJECT_LIST_SELECTION_ID;
  });

  const storageSort = getValidatedLocalStorage(LocalStorageKeys.projectRoleSort);
  const roleSort = storageSort || DEFAULT_PROJECT_ROLE_SORT;
  const { sortName: initialSortName, sortDirection: initialSortDirection } = getRoleSorting(roleSort);

  const [anchorEl, setAnchorEl] = useState(null);
  const [popperOpen, setPopperOpen] = useState(false);
  const [personIssueAllocations, setPersonIssueAllocations] = useState([]);
  const [personIssueId, setPersonIssueId] = useState(null);
  const [editingRole, setEditingRole] = useState(false);
  const [sortName, setSortName] = useState(initialSortName);
  const [sortDirection, setSortDirection] = useState(initialSortDirection);
  const [roleStateFilters, setRoleStateFilters] = useState(getValidatedLocalStorage(LocalStorageKeys.projectRoleStateFilters) || []);
  const [filteredRoles, setFilteredRoles] = useState(getFilteredRoles(selectedProject, roleStateFilters));

  useEffect(() => {
    if (!getPersonAllocationsByDatePending) {
      setPersonIssueAllocations(personAllocationsByDate[personIssueId]);
    }
  }, [getPersonAllocationsByDatePending, personAllocationsByDate, personIssueId]);

  useEffect(() => {
    if (!deleteProjectAllocationPending) {
      const storageSort = getValidatedLocalStorage(LocalStorageKeys.projectRoleSort);
      const roleSort = storageSort || DEFAULT_PROJECT_ROLE_SORT;
      const { sortName, sortDirection } = getRoleSorting(roleSort);

      setEditingRole(false);
      setSortName(sortName);
      setSortDirection(sortDirection);
    }
  }, [selectedProject.id, deleteProjectAllocationPending]);

  useEffect(() => {
    setFilteredRoles(getFilteredRoles(selectedProject, roleStateFilters));
  }, [selectedProject.roles, roleStateFilters, selectedProject]);

  const setSort = useCallback(column => () => {
    let sort;

    if (sortName === column) {
      const newSortDirection = sortDirection === SORT_ASC ? SORT_DESC : SORT_ASC;
      sort = getRoleSortClause(sortName, newSortDirection);

      setSortDirection(newSortDirection);
    } else {
      sort = getRoleSortClause(column, SORT_ASC);

      setSortName(column);
      setSortDirection(SORT_ASC);
    }

    localStorage.setItem(LocalStorageKeys.projectRoleSort, JSON.stringify(sort));
    dispatch(getProjectById(accountId, selectedProject.id, modalId, true, permissions));

    const analyticsPayload = {
      'Sorted by': column === 'startDate' ? 'Start date' : 'Role name',
      'Project status': selectedProject.state,
      'Project type': selectedProject.type,
    };
    dispatch(trackAnalytics(PROJECT_VIEW_ROLES_SORTED, analyticsPayload));
  }, [accountId, dispatch, modalId, permissions, selectedProject, sortDirection, sortName]);

  const openPopper = useCallback((event) => {
    const { currentTarget } = event;
    const { personId, startDate, endDate } = currentTarget.dataset;

    dispatch(getPersonAllocationsByDate(accountId, personId, startDate, endDate));

    setAnchorEl(currentTarget);
    setPopperOpen(true);
    setPersonIssueId(personId);
  }, [accountId, dispatch]);

  const closePopper = () => setPopperOpen(false);

  const onEditRoleToggle = useCallback((customEditState = null) => {
    if (customEditState === null) {
      setEditingRole(!editingRole);
    } else {
      setEditingRole(customEditState);
    }
  }, [editingRole]);

  const renderAddRolesButtons = useMemo(() => (
    <Can
      action={PERM_WRITE}
      subject={PERM_ROLE}
      yes={(
        <div className="roles-controls-container">
          <span className="roles-subtitle">Roles on Project</span>
          <div className="add-roles-container">
            <AddRoleSelector
              onSelectRole={toggleEdit}
              project={selectedProject}
            />
            <QuickAddRoleSelector
              projects={projectsData}
              onSelectItem={onSelectItem}
              projectRoles={projectRoles}
              getProjectRolesPending={getProjectRolesPending}
              onSave={toggleEdit}
              selectedProject={selectedProject}
            />
            <RoleTemplateSelector
              onSave={toggleEdit}
              selectedProject={selectedProject}
            />
          </div>
        </div>
      )}
    />
  ), [getProjectRolesPending, onSelectItem, projectRoles, projectsData, selectedProject, toggleEdit]);

  const onRoleFilterChange = useCallback(({ value }) => {
    const isRemovingFilter = roleStateFilters?.includes(value);
    const newRoleStateFilters = isRemovingFilter
      ? roleStateFilters.filter(item => item !== value)
      : [...roleStateFilters, value];
    const filteredRoles = getFilteredRoles(selectedProject, newRoleStateFilters);

    setRoleStateFilters(newRoleStateFilters);
    setFilteredRoles(filteredRoles);

    localStorage.setItem(LocalStorageKeys.projectRoleStateFilters, JSON.stringify(newRoleStateFilters));

    const event = isRemovingFilter ? PROJECT_ROLE_STATUS_FILTER_REMOVED : PROJECT_ROLE_STATUS_FILTER_ADDED;
    const analyticsPayload = {
      'Project ID': selectedProject?.id,
      'Project name': selectedProject?.name,
      'Role status': value,
      [`View ${isRemovingFilter ? 'removed' : 'added'} from`]: `Project ${runsInModal ? 'modal' : 'list'}`,
    };
    dispatch(trackAnalytics(event, analyticsPayload));
  }, [roleStateFilters, runsInModal, selectedProject, dispatch]);

  const renderRoleFilterChips = useMemo(() => {
    if (!isCurrent(selectedProject.startDate, selectedProject.endDate)
      || !selectedProject?.roles?.length) {
      return null;
    }

    return (
      <div className="role-status-filters-container">
        <RadioChipForm
          className="role-status-filters"
          title="Role Status"
          controls={ROLE_STATE_CONTROL_OPTIONS}
          onChange={onRoleFilterChange}
          defaultValue={roleStateFilters}
          isMultiSelect
        />
      </div>
    );
  }, [onRoleFilterChange, roleStateFilters, selectedProject]);

  const getExistingAllocations = useCallback((projectAllocations, role) => {
    let existingAllocations = [];

    if (selectedProject?.id?.toString() in projectAllocations && projectAllocations[selectedProject.id].length) {
      const roleAllocation = projectAllocations?.[selectedProject.id]?.find(a => a.roleId === role.id);

      if (roleAllocation) {
        existingAllocations = roleAllocation.allocations;
      }
    }
    return existingAllocations;
  }, [selectedProject.id]);

  const deleteAllocationHandler = useCallback((accountId, projectId, data) => {
    dispatch(deleteProjectAllocation(accountId, projectId, data));
  }, [dispatch]);

  const updateAllocationHandler = useCallback((accountId, projectId, roleId, oldAllocation, changes) => {
    dispatch(updateProjectRoleAllocation(accountId, projectId, roleId, oldAllocation, changes));
  }, [dispatch]);

  const renderProjectRoles = useMemo(() => {
    if (!filteredRoles.length) return null;

    return filteredRoles.map(r => (
      <ProjectRole
        key={r.id}
        role={r}
        selectedProject={selectedProject}
        getExistingAllocations={getExistingAllocations}
        openPopper={openPopper}
        editingRole={editingRole}
        onEditRoleToggle={onEditRoleToggle}
        deleteProjectRolePending={deleteProjectRolePending}
        onDeleteAllocation={deleteAllocationHandler}
        onUpdateAllocation={updateAllocationHandler}
        runsInModal={runsInModal}
      />
    ));
  }, [deleteAllocationHandler, deleteProjectRolePending, editingRole, filteredRoles, getExistingAllocations, onEditRoleToggle, openPopper, runsInModal, selectedProject, updateAllocationHandler]);

  const renderTableHeader = useMemo(() => (
    <div className="project-roles-list">
      <div className="project-roles-column-names">
        <div
          className={classNames('role-title', 'sort-column', { sorted: sortName === 'sortOrder' })}
          title="Role"
          role="presentation"
          onClick={setSort('sortOrder')}
        >
          Role
          {sortName !== 'sortOrder' || sortDirection === SORT_ASC
            ? <ArrowUpward className="sort-arrow" />
            : <ArrowDownward className="sort-arrow" />}
        </div>
        <div
          className={classNames('start-date', 'sort-column', { sorted: sortName === 'startDate' })}
          title="Start date"
          role="presentation"
          onClick={setSort('startDate')}
        >
          Start date
          {sortName !== 'startDate' || sortDirection === SORT_ASC
            ? <ArrowUpward className="sort-arrow" />
            : <ArrowDownward className="sort-arrow" />}
        </div>
        <div className="label-spacer" />
        <div className="start-date" title="End date">End date</div>
        <div className="allocation-percentage" title="%">%</div>
      </div>
      <div className="project-roles-list-spacer" />
    </div>
  ), [setSort, sortDirection, sortName]);

  const renderEmptyRoles = useMemo(() => (
    <div className={classNames('projects-assign-project-roles empty', { 'runs-in-modal': runsInModal })}>
      {renderAddRolesButtons}
      <p>No roles have been added to this project.</p>
      <img src={emptyImage} alt="Empty" />
    </div>
  ), [renderAddRolesButtons, runsInModal]);

  const renderAllocationIssuePopper = useMemo(() => (
    <ClickAwayListener onClickAway={closePopper} mouseEvent={popperOpen ? 'onClick' : false}>
      <div>
        <AllocationIssuePopper
          popperTargetRef={anchorEl}
          popperOpen={popperOpen}
          onPopperClose={closePopper}
          allocations={personIssueAllocations}
          loading={getPersonAllocationsByDatePending}
        />
      </div>
    </ClickAwayListener>
  ), [anchorEl, getPersonAllocationsByDatePending, personIssueAllocations, popperOpen]);

  return (
    <>
      {!selectedProject?.roles?.length && !addProjectRolesPending ? (
        renderEmptyRoles
      )
        : (
          <div className={classNames('projects-assign-project-roles', { 'runs-in-modal': runsInModal })}>
            <div className="project-roles-control-wrapper">
              {renderAddRolesButtons}
              {renderRoleFilterChips}
            </div>

            { filteredRoles?.length
              ? renderTableHeader
              : (
                <div className="project-roles-no-match">
                  <p>No roles match your selected role status filters.</p>
                  <p>Try changing your filters or adding a new role.</p>
                </div>
              )}

            { loading && <CircularProgress className="project-roles-loading" size={35} color="secondary" /> }

            {renderProjectRoles}
            {renderAllocationIssuePopper}
          </div>
        )}
    </>
  );
};

AssignProjectRoles.propTypes = {
  selectedProject: PropTypes.object.isRequired,
  toggleEdit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  runsInModal: PropTypes.bool,
  onSelectItem: PropTypes.func.isRequired,
  projectsData: PropTypes.arrayOf(PropTypes.object),
};

AssignProjectRoles.defaultProps = {
  runsInModal: false,
  projectsData: [],
};

export default AssignProjectRoles;
