import {
  COMMON_TOGGLE_PERSON_SIDE_PANEL,
} from './constants';

export function togglePersonSidePanel(open) {
  return {
    type: COMMON_TOGGLE_PERSON_SIDE_PANEL,
    open,
  };
}

export function reducer(state, { type, open }) {
  switch (type) {
    case COMMON_TOGGLE_PERSON_SIDE_PANEL:
      return {
        ...state,
        personSidePanelOpen: open,
      };

    default:
      return state;
  }
}
