import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ClickAwayListener } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { Button } from '@bridgit/foundation';
import { FilterList } from '.';
import { CustomPopper } from '../wrapped-components';
import FilterCounter from './FilterCounter';
import FilterPopperFactory from './FilterPopperFactory';
import {
  setFilterListAnchorElId,
  setFilterAnchorElId,
  setPopperIsOpen,
} from './redux/actions';

const FilterContainer = ({
  className,
  filterQueries,
  columns,
  priorityColumns,
  exclude,
  showEmptyMessage,
  trackApplyFilter,
  addFilterButtonId,
}) => {
  const dispatch = useDispatch();
  const { filterListAnchorElId, filterAnchorElId } = useSelector(({ filters }) => filters.filterMenu);

  const [showFilterList, setShowFilterList] = useState(false);
  const [showFilterPopper, setShowFilterPopper] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [filteredColumns, setFilteredColumns] = useState([]);

  useEffect(() => {
    setFilteredColumns(Array.isArray(filterQueries.args) ? filterQueries.args.map(f => f.label) : []);
  }, [filterQueries]);

  const onAddFilterClick = () => {
    dispatch(setFilterListAnchorElId(addFilterButtonId));
    dispatch(setFilterAnchorElId(addFilterButtonId));
    setShowFilterList(true);
    dispatch(setPopperIsOpen(true));
  };

  const onFilterSelect = (filter) => {
    setShowFilterList(false);
    setSelectedFilter(filter);
    setShowFilterPopper(true);
    dispatch(setPopperIsOpen(true));
  };

  const dismissFilterList = () => {
    setSelectedFilter(null);
    setShowFilterList(false);
    dispatch(setFilterListAnchorElId(null));
    dispatch(setPopperIsOpen(false));
  };

  const dismissFilterPopper = () => {
    setSelectedFilter(null);
    setShowFilterList(false);
    setShowFilterPopper(false);
    dispatch(setFilterListAnchorElId(null));
    dispatch(setPopperIsOpen(false));
  };

  const toggleFilterPopper = (filter, filterCounterId) => {
    if (filter) {
      setSelectedFilter(filter);
    }
    setShowFilterPopper(true);
    dispatch(setFilterAnchorElId(filterCounterId));
    dispatch(setPopperIsOpen(true));
  };

  const onApplyFilter = (updatedFilters) => {
    trackApplyFilter?.(selectedFilter, updatedFilters);
    setSelectedFilter(null);
    dispatch(setPopperIsOpen(false));
  };

  const renderFilterCounters = () => {
    const { args: filterArguments } = filterQueries;
    if (showEmptyMessage && !(Array.isArray(filterArguments) && filterArguments.length)) {
      return <div className="no-filters-applied">No filters applied</div>;
    }

    return filterArguments.map((filter) => {
      const { label } = filter;
      const selectedColumn = columns.find(({ name }) => name === label);

      return (
        <FilterCounter
          key={label}
          onClick={toggleFilterPopper}
          label={label}
          open={Boolean(selectedFilter && selectedFilter.name === label)}
          filter={selectedColumn}
        />
      );
    });
  };

  const modifiers = {
    offset: {
      offset: '0, 8px',
      enabled: true,
    },
  };

  const filterListAnchorEl = document.getElementById(filterListAnchorElId);
  const filterAnchorEl = document.getElementById(filterAnchorElId);

  return (
    <div className={classNames('filters-filter-container', className)}>
      <div className="body">
        {renderFilterCounters()}
      </div>
      <div className="footer">
        <Button
          id={addFilterButtonId}
          color="primary"
          variant="plain"
          onClick={onAddFilterClick}
          startIcon={<AddCircle />}
        >
          Add filter
        </Button>
      </div>
      <CustomPopper
        popperOpen={showFilterList}
        anchorEl={filterListAnchorEl}
        classes="common-filter-popper"
        placement="bottom-start"
        hideArrow
        preventBubbling
        modifiers={modifiers}
      >
        <div role="presentation" onClick={dismissFilterList} className="filter-menu-click-listener" />
        <ClickAwayListener onClickAway={dismissFilterList} disableReactTree>
          <div>
            <FilterList
              columns={columns}
              priorityColumns={priorityColumns}
              onFilterSelect={onFilterSelect}
              exclude={exclude}
              filteredColumns={filteredColumns}
            />
          </div>
        </ClickAwayListener>
      </CustomPopper>
      {selectedFilter && filterAnchorEl && (
        <FilterPopperFactory
          filterQueries={filterQueries}
          selectedFilter={selectedFilter}
          anchorEl={filterAnchorEl}
          onCancel={dismissFilterPopper}
          onApply={onApplyFilter}
          open={showFilterPopper}
          placement={filterAnchorEl.className.includes('add-filter-button') ? 'top-start' : 'bottom-start'}
        />
      )}
    </div>
  );
};

FilterContainer.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object),
  priorityColumns: PropTypes.arrayOf(PropTypes.string),
  filterQueries: PropTypes.object,
  exclude: PropTypes.arrayOf(PropTypes.object),
  showEmptyMessage: PropTypes.bool,
  trackApplyFilter: PropTypes.func,
  addFilterButtonId: PropTypes.string.isRequired,
};

FilterContainer.defaultProps = {
  className: '',
  columns: [],
  priorityColumns: [],
  filterQueries: {},
  exclude: [],
  showEmptyMessage: true,
  trackApplyFilter: undefined,
};

export default FilterContainer;
