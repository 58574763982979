import {
  MUST,
  MUST_NOT,
  BLANK_FILTER,
  FIELDS_VALUE_SELECTION,
  FIELDS,
  FIELDS_NAME,
} from './constants';

const generateMultiSelectFilterQuery = (activeFilter, column) => {
  const should = [];
  const mainShould = [];

  const { inclusive, selected } = activeFilter;
  const boolStatement = inclusive ? MUST : MUST_NOT;

  const nonBlankFilters = selected.filter(item => item.value !== BLANK_FILTER);

  nonBlankFilters.forEach((nonBlankFilter) => {
    should.push({
      match: {
        [FIELDS_VALUE_SELECTION]: nonBlankFilter.value,
      },
    });
  });

  if (should.length) {
    mainShould.push({
      nested: {
        path: FIELDS,
        query: {
          bool: {
            must: [
              {
                bool: {
                  filter: [{ term: { [FIELDS_NAME]: column } }],
                },
              },
              {
                bool: {
                  should,
                },
              },
            ],
          },
        },
      },
    });
  }


  if (selected.find(item => item.value === BLANK_FILTER)) {
    mainShould.push({
      bool: {
        must_not: [
          {
            nested: {
              path: FIELDS,
              query: { match: { [FIELDS_NAME]: column } },
            },
          },
        ],
      },
    });
  }

  return {
    bool: {
      [boolStatement]: [
        {
          bool: {
            should: mainShould,
          },
        },
      ],
    },
  };
};

const generateMultiSelectFilter = (activeFilters, column) => {
  const must = activeFilters.map(activeFilter => generateMultiSelectFilterQuery(activeFilter, column));
  return {
    bool: {
      must,
    },
  };
};

export default generateMultiSelectFilter;
