import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PEOPLE_UPDATE_PERSON_CERTIFICATION_BEGIN,
  PEOPLE_UPDATE_PERSON_CERTIFICATION_SUCCESS,
  PEOPLE_UPDATE_PERSON_CERTIFICATION_FAILURE,
  PEOPLE_UPDATE_PERSON_CERTIFICATION_DISMISS_ERROR,
} from './constants';
import { protectedJsonPut } from '../../../utils/api';

export function updatePersonCertification(accountId, personId, certificationId, data, analyticsPayload) {
  return {
    type: PEOPLE_UPDATE_PERSON_CERTIFICATION_BEGIN,
    accountId,
    personId,
    certificationId,
    data,
    analyticsPayload,
  };
}

export function dismissUpdatePersonCertificationError() {
  return {
    type: PEOPLE_UPDATE_PERSON_CERTIFICATION_DISMISS_ERROR,
  };
}

export function* doUpdatePersonCertification(action) {
  const { accountId, personId, certificationId, data, analyticsPayload } = action;
  try {
    yield call(protectedJsonPut, `accounts/${accountId}/persons/${personId}/certifications/${certificationId}`, data);
  } catch (err) {
    yield put({
      type: PEOPLE_UPDATE_PERSON_CERTIFICATION_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PEOPLE_UPDATE_PERSON_CERTIFICATION_SUCCESS,
    personId,
    certificationId,
    data,
    analyticsPayload,
  });
}

export function* watchUpdatePersonCertification() {
  yield takeLatest(PEOPLE_UPDATE_PERSON_CERTIFICATION_BEGIN, doUpdatePersonCertification);
}

export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_UPDATE_PERSON_CERTIFICATION_BEGIN:
      return {
        ...state,
        updatePersonCertificationPending: true,
        updatePersonCertificationError: null,
      };

    case PEOPLE_UPDATE_PERSON_CERTIFICATION_SUCCESS: {
      const { certificationId, data, personId } = action;
      const { personCertifications, filteredPeople } = state;
      const newPersonCertifications = {
        ...personCertifications,
        items: personCertifications.items?.map(item => (
          item.id === certificationId ? { ...item, ...data } : item
        )),
      };
      const newFilteredPeople = filteredPeople.map(person => (
        person.id === personId
          ? ({
            ...person,
            certifications: person.certifications?.map(cert => (
              cert.id === certificationId ? { ...cert, ...data } : cert
            )),
          })
          : person
      ));

      return {
        ...state,
        personCertifications: newPersonCertifications,
        filteredPeople: newFilteredPeople,
        updatePersonCertificationPending: false,
        updatePersonCertificationError: null,
      };
    }

    case PEOPLE_UPDATE_PERSON_CERTIFICATION_FAILURE:
      return {
        ...state,
        updatePersonCertificationPending: false,
        updatePersonCertificationError: action.data.error,
      };

    case PEOPLE_UPDATE_PERSON_CERTIFICATION_DISMISS_ERROR:
      return {
        ...state,
        updatePersonCertificationError: null,
      };

    default:
      return state;
  }
}
