import moment from 'moment';
import { DATE_INPUT_FORMAT } from 'src/common/constants';
import {
  MUST,
  SHOULD,
  MUST_NOT,
  BLANK_FILTER,
  PHASES,
  PHASES_START_DATE,
  PHASES_END_DATE,
  PHASES_NAME,
  SUB_PHASES,
  SUB_PHASES_START_DATE,
  SUB_PHASES_END_DATE,
  SUB_PHASES_NAME,
} from './constants';

const buildParent = (parents, today) => {
  if (parents.length === 0) return false;

  const parentNames = parents.map(parent => ({
    match: {
      [PHASES_NAME]: parent.name,
    },
  }));

  return {
    nested: {
      path: PHASES,
      query: {
        bool: {
          [MUST]: [
            {
              range: {
                [PHASES_START_DATE]: {
                  lte: today,
                },
              },
            },
            {
              range: {
                [PHASES_END_DATE]: {
                  gte: today,
                },
              },
            },
            {
              bool: {
                [SHOULD]: parentNames,
              },
            },
            {
              bool: {
                [SHOULD]: [
                  {
                    bool: {
                      [MUST_NOT]: {
                        nested: {
                          path: SUB_PHASES,
                          query: {
                            exists: {
                              field: SUB_PHASES_NAME,
                            },
                          },
                        },
                      },
                    },
                  },
                  {
                    bool: {
                      [MUST_NOT]: {
                        nested: {
                          path: SUB_PHASES,
                          query: {
                            bool: {
                              [MUST]: [
                                {
                                  range: {
                                    [SUB_PHASES_START_DATE]: {
                                      lte: today,
                                    },
                                  },
                                },
                                {
                                  range: {
                                    [SUB_PHASES_END_DATE]: {
                                      gte: today,
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        },
                      },
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    },
  };
};

const buildInactive = today => (
  {
    bool: {
      [MUST_NOT]: {
        nested: {
          path: PHASES,
          query: {
            bool: {
              [MUST]: [
                {
                  range: {
                    [PHASES_START_DATE]: {
                      lte: today,
                    },
                  },
                },
                {
                  range: {
                    [PHASES_END_DATE]: {
                      gte: today,
                    },
                  },
                },
              ],
            },
          },
        },
      },
    },
  }
);

const buildEmptyPhase = () => (
  {
    bool: {
      [MUST_NOT]: {
        nested: {
          path: PHASES,
          query: {
            exists: {
              field: PHASES_NAME,
            },
          },
        },
      },
    },
  }
);

const buildSub = (subs, today) => {
  if (subs.length === 0) return false;

  const subNames = subs.map(sub => ({
    match: {
      [SUB_PHASES_NAME]: sub.name,
    },
  }));

  return {
    nested: {
      path: SUB_PHASES,
      query: {
        bool: {
          [MUST]: [
            {
              range: {
                [SUB_PHASES_START_DATE]: {
                  lte: today,
                },
              },
            },
            {
              range: {
                [SUB_PHASES_END_DATE]: {
                  gte: today,
                },
              },
            },
            {
              bool: {
                [SHOULD]: subNames,
              },
            },
          ],
        },
      },
    },
  };
};

const buildFilterQuery = (activeFilter) => {
  const today = moment().format(DATE_INPUT_FORMAT);

  const { inclusive, selected } = activeFilter;
  const boolStatement = inclusive ? SHOULD : MUST_NOT;

  const parents = selected.filter(item => !item.isSub && item.value !== BLANK_FILTER);
  const subs = selected.filter(item => item.isSub && item.value !== BLANK_FILTER);

  const parentShould = buildParent(parents, today);
  const subShould = buildSub(subs, today);

  const body = [];

  if (parentShould) {
    body.push(parentShould);
  }

  if (subShould) {
    body.push(subShould);
  }
  if (selected.find(item => item.value === BLANK_FILTER)) {
    body.push(buildInactive(today));
    body.push(buildEmptyPhase());
  }

  return {
    bool: {
      [boolStatement]: body,
    },
  };
};

const generatePhaseFilter = (activeFilters) => {
  const must = activeFilters.map(activeFilter => buildFilterQuery(activeFilter));

  return {
    bool: {
      must,
    },
  };
};

export default generatePhaseFilter;
