import moment from 'moment';

import {
  PROJECTS_ADD_PROJECT_ROLE_ALLOCATION_SUCCESS,
  PROJECTS_REPLACE_PROJECT_ROLE_ALLOCATION_SUCCESS,
  PROJECTS_UPDATE_PROJECT_FIELD_VALUE_SUCCESS,
  TRACK_PROJECT_UPDATE_CANCELED,
  TRACK_PROJECT_UPDATE_REACTIVATED,
  TRACK_PROJECT_UPDATE_LOST,
  TRACK_PROJECT_UPDATE_TYPE,
  PROJECTS_SEND_ROLE_NOTIFICATION_SUCCESS,
  PROJECTS_ADD_PROJECT_NOTE_SUCCESS,
  PROJECTS_UPDATE_PROJECT_NOTE_SUCCESS,
  PROJECTS_DELETE_PROJECT_NOTE_SUCCESS,
  PROJECTS_ADD_PROJECT_SUCCESS,
  PROJECTS_ADD_PROJECT_PHASES_SUCCESS,
  PROJECTS_ADD_PROJECT_SUB_PHASE_SUCCESS,
  PROJECTS_DELETE_PROJECT_PHASE_SUCCESS,
  PROJECTS_DELETE_PROJECT_SUB_PHASE_SUCCESS,
  PROJECTS_UPDATE_PROJECT_PHASE_SUCCESS,
  PROJECTS_UPDATE_PROJECT_SUB_PHASE_SUCCESS,
  PROJECTS_DELETE_PROJECT_ROLE_SUCCESS,
  PROJECTS_DELETE_PROJECT_ALLOCATION_BEGIN,
  PROJECTS_UPDATE_PROJECT_ROLE_ALLOCATION_BEGIN,
} from 'src/features/projects/redux/constants';

import {
  SELF_PERFORM_ASSIGN_REQUESTER_SUCCESS,
  SELF_PERFORM_DELETE_REQUESTER_SUCCESS,
} from 'src/features/self-perform/redux/constants';
import { FIELD_TYPE_ADDRESS } from 'src/common/constants';
import { getEntityState } from 'src/utils/dateUtils';
import { AWARDED, OPPORTUNITY } from 'src/features/projects/constants';
import {
  ALLOCATION_ADDED,
  ALLOCATION_UPDATED,
  PROJECT_REQUESTER_ADDED,
  PROJECT_REQUESTER_DELETED,
  PROJECT_DETAILS_UPDATED,
  PROJECT_CANCELED,
  PROJECT_REACTIVATED,
  PROJECT_LOST,
  PROJECT_TYPE_UPDATED,
  ASSIGNMENT_COMMUNICATION_SENT,
  ASSIGNMENT_REMOVE_REPLACE_COMMUNICATION_SENT,
  PROJECT_NOTE_ADDED,
  PROJECT_NOTE_EDITED,
  PROJECT_NOTE_DELETED,
  PROJECT_ADDED,
  PROJECT_PHASES_ADDED,
  PROJECT_PHASE_DELETED,
  PROJECT_ROLE_DELETED_FROM_PROJECT,
  PROJECT_ROLE_UPDATE_CAUSED_DELETION,
  PROJECT_VIEW_PANEL,
  ALLOCATION_DELETED,
  ALLOCATION_START_DATE_UPDATED,
  ALLOCATION_END_DATE_UPDATED,
} from './constants';
import {
  getProjectPhasePayload,
  getUpdateProjectPhasePayload,
  getDeleteProjectRolePayload,
  getUpdateProjectCausedRoleDeletePayload,
} from './utils';
import { COMMON_PHASE_EDITED_ON_PROJECT } from '../common/constants';

const events = {
  [PROJECTS_REPLACE_PROJECT_ROLE_ALLOCATION_SUCCESS]: {
    getEvent: () => ALLOCATION_UPDATED,
    getPayload: (state, action) => {
      const { analytics } = action;
      const {
        modalOrigin,
        ...analyticsPayload
      } = analytics;
      return {
        ...analyticsPayload,
        [ALLOCATION_START_DATE_UPDATED]: false,
        [ALLOCATION_END_DATE_UPDATED]: false,
        'Allocated Person Updated': true,
        'Allocation updated from': modalOrigin,
      };
    },
  },

  [PROJECTS_ADD_PROJECT_ROLE_ALLOCATION_SUCCESS]: {
    getEvent: () => ALLOCATION_ADDED,
    getPayload: (state, action) => {
      const { analytics } = action;
      const {
        allocationCausedConflicts,
        modalOrigin,
        ...analyticsPayload
      } = analytics;
      return {
        ...analyticsPayload,
        'Allocation Caused Conflicts': allocationCausedConflicts,
        'Allocation added from': modalOrigin,
      };
    },
  },

  [SELF_PERFORM_ASSIGN_REQUESTER_SUCCESS]: {
    getEvent: () => PROJECT_REQUESTER_ADDED,
    getPayload: (state, action) => {
      const { addedRequesters, projectName, projectId } = action;

      return addedRequesters.map(({ id, name }) => ({
        'Requester name': name,
        'Requester id': id,
        'Project name': projectName,
        'Project id': projectId,
      }));
    },
  },

  [SELF_PERFORM_DELETE_REQUESTER_SUCCESS]: {
    getEvent: () => PROJECT_REQUESTER_DELETED,
    getPayload: ({ selfPerform }, action) => {
      const { projectRequesters } = selfPerform;
      const { deletedRequesterIds, projectName, projectId } = action;

      const deletedRequesters = projectRequesters.filter(({ id }) => deletedRequesterIds.includes(id));

      return deletedRequesters.map(({ id, name }) => ({
        'Requester name': name,
        'Requester id': id,
        'Project name': projectName,
        'Project id': projectId,
      }));
    },
  },

  [PROJECTS_UPDATE_PROJECT_FIELD_VALUE_SUCCESS]: {
    getEvent: () => PROJECT_DETAILS_UPDATED,
    getPayload: (state, { analyticsPayload }) => analyticsPayload,
  },

  [TRACK_PROJECT_UPDATE_CANCELED]: {
    getEvent: () => PROJECT_CANCELED,
    getPayload: (state, { data, analyticsPayload }) => {
      const { name, id, state: status } = data;
      const { parentName } = analyticsPayload;
      return {
        'Project name': name,
        'Project ID': id,
        'Project is canceled from': parentName,
        'Project status': status,
      };
    },
  },

  [TRACK_PROJECT_UPDATE_REACTIVATED]: {
    getEvent: () => PROJECT_REACTIVATED,
    getPayload: (state, { data, analyticsPayload }) => {
      const { name, id, type, state: status } = data;
      const { initialProject, parentName } = analyticsPayload;
      const { roles, state: initialStatus, phases, startDate } = initialProject;
      const hasAllocations = initialProject?.roles?.some(role => role?.allocations?.length);
      return {
        'Project name': name,
        'Project ID': id,
        'Project is updated from': parentName,
        Type: type,
        'Status was': initialStatus,
        'Status is': status,
        'Has roles': roles?.length > 0,
        'Has allocations': hasAllocations,
        'Has phases': phases?.length > 0,
        'Start date in the future': moment(startDate).isAfter(moment()),
      };
    },
  },

  [TRACK_PROJECT_UPDATE_LOST]: {
    getEvent: () => PROJECT_LOST,
    getPayload: (state, { data, analyticsPayload }) => {
      const { name, id } = data;
      const { initialProject, parentName } = analyticsPayload;
      const { roles, phases, startDate } = initialProject;
      const hasAllocations = initialProject?.roles?.some(role => role?.allocations?.length);
      return {
        'Project name': name,
        'Project ID': id,
        'Project is updated from': parentName,
        'Has roles': roles?.length > 0,
        'Has allocations': hasAllocations,
        'Has phases': phases?.length > 0,
        'Start date in the future': moment(startDate).isAfter(moment()),
      };
    },
  },

  [TRACK_PROJECT_UPDATE_TYPE]: {
    getEvent: () => PROJECT_TYPE_UPDATED,
    getPayload: (state, { data, analyticsPayload }) => {
      const { initialProject, parentName } = analyticsPayload;
      const { name, id, type, state: status } = data;
      const { roles, state: initialStatus, phases, startDate } = initialProject;
      const hasAllocations = initialProject?.roles?.some(role => role?.allocations?.length);
      return {
        'Project name': name,
        'Project ID': id,
        'Project is updated from': parentName,
        'Type updated to': type,
        'Status was': initialStatus,
        'Status is': status,
        'Has roles': roles?.length > 0,
        'Has allocations': hasAllocations,
        'Has phases': phases?.length > 0,
        'Start date in the future': moment(startDate).isAfter(moment()),
      };
    },
  },

  [PROJECTS_SEND_ROLE_NOTIFICATION_SUCCESS]: {
    getEvent: () => ASSIGNMENT_COMMUNICATION_SENT,
    getPayload: (state, { data, analyticsPayload }) => {
      const { project, role, allocations, options } = analyticsPayload;
      const { id: projectId, name: projectName, fields } = project;
      const { id: roleId, name: roleName, startDate, endDate, isFilled, isCommunicated } = role;

      const methods = [];
      data.forEach((recipient) => {
        const { method } = recipient;
        if (!methods.includes(method)) methods.push(method);
      });

      // Past roles cannot be notified
      const roleStatus = moment().isBefore(startDate) ? 'Upcoming' : 'Active';

      const peopleOnRole = [];
      allocations.forEach(({ personId }) => {
        if (!peopleOnRole.includes(personId)) {
          peopleOnRole.push(personId);
        }
      });

      return {
        'Project name': projectName,
        'Project id': projectId,
        'Role name': roleName,
        'Role id': roleId,
        'Method of communication': methods.length > 1 ? 'Both' : methods[0],
        'Project address available': !!fields.find(({ name }) => name === FIELD_TYPE_ADDRESS),
        'Role start date': startDate,
        'Role end date': endDate,
        'Role status': roleStatus,
        '# of people in the role': peopleOnRole.length,
        '# of people communicated': data.length,
        'Role filled': isFilled,
        'Additional text added': !!data?.[0]?.message,
        'Additional text': data?.[0]?.message || '',
        'Project address included': !!options?.projectAddress,
        'Role name included': !!options?.roleName,
        Status: isCommunicated ? 'Communicated' : 'Not Communicated',
      };
    },
  },

  [ASSIGNMENT_REMOVE_REPLACE_COMMUNICATION_SENT]: {
    getEvent: () => ASSIGNMENT_REMOVE_REPLACE_COMMUNICATION_SENT,
    getPayload: (state, { data, analyticsPayload }) => {
      const { project, role, allocations, options, allocationChangeType, allocation } = analyticsPayload;
      const { id: projectId, name: projectName, fields } = project;
      const { id: roleId, name: roleName, startDate, endDate } = role;
      const { startDate: allocationStart, endDate: allocationEnd } = allocation;
      const { method, message } = data[0];

      const methods = method.split(',');

      const peopleOnRole = [];
      allocations.forEach(({ personId }) => {
        if (!peopleOnRole.includes(personId)) {
          peopleOnRole.push(personId);
        }
      });

      const allocationState = getEntityState(moment(allocationStart), moment(allocationEnd));

      return {
        'Project name': projectName,
        'Project id': projectId,
        'Role name': roleName,
        'Role id': roleId,
        'Method of communication': methods.length > 1 ? 'Both' : methods[0],
        'Project address available': !!fields.find(({ name }) => name === FIELD_TYPE_ADDRESS),
        'Project address included': !!options?.projectAddress,
        'Role name included': !!options?.roleName,
        'Role start date': startDate,
        'Role end date': endDate,
        'Sent to communicate': allocationChangeType,
        'Allocation status': allocationState,
        '# of people in the role at a time of action': peopleOnRole.length,
        'Additional text added': !!message,
        'Additional text': message || '',
      };
    },
  },

  [PROJECTS_ADD_PROJECT_NOTE_SUCCESS]: {
    getEvent: () => PROJECT_NOTE_ADDED,
    getPayload: (state, action) => {
      const { data: { isPrivate }, analyticsPayload } = action;
      const { projectId, projectName, projectType, projectStatus } = analyticsPayload;

      return {
        'Project Name': projectName,
        'Project ID': projectId,
        'Project type': projectType,
        'Project status': projectStatus,
        'Is Private': isPrivate,
      };
    },
  },

  [PROJECTS_UPDATE_PROJECT_NOTE_SUCCESS]: {
    getEvent: () => PROJECT_NOTE_EDITED,
    getPayload: (state, { analyticsPayload }) => {
      const { projectId, projectName, projectType, projectStatus } = analyticsPayload;

      return {
        'Project Name': projectName,
        'Project ID': projectId,
        'Project type': projectType,
        'Project status': projectStatus,
      };
    },
  },

  [PROJECTS_DELETE_PROJECT_NOTE_SUCCESS]: {
    getEvent: () => PROJECT_NOTE_DELETED,
    getPayload: (state, { analyticsPayload }) => {
      const { projectId, projectName, projectType, projectStatus, isOwn } = analyticsPayload;

      return {
        'Project name': projectName,
        'Project ID': projectId,
        'Project type': projectType,
        'Project status': projectStatus,
        'Is own': isOwn,
      };
    },
  },

  [PROJECTS_ADD_PROJECT_SUCCESS]: {
    getEvent: () => PROJECT_ADDED,
    getPayload: (state, { data: { id }, analyticsPayload }) => {
      const {
        name,
        phaseDataLength,
        color,
        colorType,
        type,
        modalOrigin,
        winPercent,
      } = analyticsPayload;

      return {
        'Project name': name,
        'Project id': id,
        'With Phases': phaseDataLength > 0,
        Colour: color,
        'Colour type used': colorType,
        Type: type || AWARDED,
        'Project added from': modalOrigin,
        ...(type === OPPORTUNITY && { 'Project win %': parseInt(winPercent, 10) || null }),
      };
    },
  },

  [PROJECTS_ADD_PROJECT_PHASES_SUCCESS]: {
    getEvent: () => PROJECT_PHASES_ADDED,
    getPayload: getProjectPhasePayload,
  },

  [PROJECTS_ADD_PROJECT_SUB_PHASE_SUCCESS]: {
    getEvent: () => PROJECT_PHASES_ADDED,
    getPayload: getProjectPhasePayload,
  },

  [PROJECTS_DELETE_PROJECT_PHASE_SUCCESS]: {
    getEvent: () => PROJECT_PHASE_DELETED,
    getPayload: (state, { analyticsPayload: { project, parentName, activePhase } }) => ({
      Type: 'Phase',
      'Phase name': activePhase.name,
      'Phase id': activePhase.id,
      'Project name': project.name,
      'Project id': project.id,
      'Project status': project.state,
      'Project type': project.type,
      Location: parentName === 'Project Details' ? 'Project Dates' : parentName,
    }),
  },

  [PROJECTS_DELETE_PROJECT_SUB_PHASE_SUCCESS]: {
    getEvent: () => PROJECT_PHASE_DELETED,
    getPayload: (state, { analyticsPayload: { project, parentName, subPhase } }) => ({
      Type: 'Sub-phase',
      'Phase name': subPhase.name,
      'Phase id': subPhase.id,
      'Project name': project.name,
      'Project id': project.id,
      'Project status': project.state,
      'Project type': project.type,
      Location: parentName === 'Project Details' ? 'Project Dates' : parentName,
    }),
  },

  [PROJECTS_UPDATE_PROJECT_PHASE_SUCCESS]: {
    getEvent: () => COMMON_PHASE_EDITED_ON_PROJECT,
    getPayload: getUpdateProjectPhasePayload,
  },

  [PROJECTS_UPDATE_PROJECT_SUB_PHASE_SUCCESS]: {
    getEvent: () => COMMON_PHASE_EDITED_ON_PROJECT,
    getPayload: getUpdateProjectPhasePayload,
  },

  [PROJECTS_DELETE_PROJECT_ROLE_SUCCESS]: {
    getEvent: (state, { analyticsPayload: { isRoleDeleted } }) => (isRoleDeleted ? PROJECT_ROLE_UPDATE_CAUSED_DELETION : PROJECT_ROLE_DELETED_FROM_PROJECT),
    getPayload: (state, { analyticsPayload }) => {
      const { isRoleDeleted } = analyticsPayload;

      return isRoleDeleted
        ? getUpdateProjectCausedRoleDeletePayload(analyticsPayload)
        : getDeleteProjectRolePayload(analyticsPayload);
    },
  },

  [PROJECT_VIEW_PANEL]: {
    getEvent: () => PROJECT_VIEW_PANEL,
    getPayload: (state, { analyticsPayload }) => analyticsPayload,
  },

  [PROJECTS_DELETE_PROJECT_ALLOCATION_BEGIN]: {
    getEvent: (state, { analyticsPayload }) => {
      if (analyticsPayload) {
        return ALLOCATION_DELETED;
      }
      return null;
    },
    getPayload: (state, { analyticsPayload }) => (analyticsPayload),
  },

  [PROJECTS_UPDATE_PROJECT_ROLE_ALLOCATION_BEGIN]: {
    getEvent: (state, { analyticsPayload }) => {
      if (analyticsPayload) {
        return ALLOCATION_UPDATED;
      }
      return null;
    },
    getPayload: (state, { analyticsPayload }) => (analyticsPayload),
  },
};

export default events;
