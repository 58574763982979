import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Paper, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

export default class ExpiredReset extends PureComponent {
  static propTypes = {
    dismissResetPasswordError: PropTypes.func.isRequired,
  };

  render() {
    const { dismissResetPasswordError } = this.props;
    return (
      <Paper component="section" variant="outlined" className="spacious-container">
        <Grid
          className="login-expired-invite"
          container
          item
          direction="column"
          spacing={3}
          justify="center"
          xs={12}
        >
          <Grid item>
            <Typography
              className="headline"
              variant="subtitle1"
              component="h4"
            >
              Oops! It looks like your password reset has expired.
            </Typography>
          </Grid>

          <Grid item>
            <Typography align="center" component="span">Already have an account?</Typography>
          </Grid>

          <Button
            className="login-redirect"
            color="primary"
            disableRipple
            disableElevation
            size="medium"
            variant="contained"
          >
            <Link to="/login" onClick={dismissResetPasswordError}>LOG IN</Link>
          </Button>

        </Grid>
      </Paper>
    );
  }
}
