import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { ConfirmOverlay } from 'src/features/common';

export const ConfirmDeleteRole = ({
  role,
  onCancel,
  onDelete,
  deleteProjectRolePending,
}) => {
  const confirmMessage = 'Are you sure you want to remove this role?';
  const showSubMessage = moment(role.startDate).isBefore();
  const confirmSubMessage = showSubMessage ? 'All history for this role and people on this role will be lost.' : undefined;

  return (
    <div className="allocations-confirm-delete-role">
      <ConfirmOverlay
        onAccept={onDelete}
        onCancel={onCancel}
        message={confirmMessage}
        subtext={confirmSubMessage}
        pending={deleteProjectRolePending}
        displayCompact
        actionButtons={(
          <Button
            className="button__default"
            variant="contained"
            size="medium"
            onClick={onDelete}
          >
            Remove
          </Button>
        )}
      />
    </div>
  );
};

ConfirmDeleteRole.propTypes = {
  role: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  deleteProjectRolePending: PropTypes.bool.isRequired,
};

function mapStateToProps({ projects }) {
  const { deleteProjectRolePending } = projects;
  return {
    deleteProjectRolePending,
  };
}

export default connect(
  mapStateToProps,
)(ConfirmDeleteRole);
