import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Popper } from '@material-ui/core';

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

function CustomPopper({
  popperOpen,
  anchorEl,
  classes,
  children,
  placement,
  hideArrow,
  preventBubbling,
  modifiers,
  disablePortal,
  onUpdate,
}) {
  const [arrowRef, setArrowRef] = useState(null);

  const handleArrowRef = (node) => { setArrowRef(node); };

  const popperModifiers = {
    preventOverflow: {
      enabled: true,
      boundariesElement: 'viewport',
      padding: 20,
    },
    flip: {
      enabled: true,
    },
    arrow: {
      enabled: !hideArrow,
      element: arrowRef,
    },
    ...modifiers,
  };

  const stopPropagation = preventBubbling ? evt => evt.stopPropagation() : null;

  const popperOptions = {
    onUpdate,
  };

  return (
    <div className="wrapped-components-custom-popper" onClick={stopPropagation}>
      <Popper
        className={classNames('custom-popper', classes)}
        open={popperOpen}
        anchorEl={anchorEl}
        placement={placement}
        disablePortal={disablePortal}
        modifiers={popperModifiers}
        popperOptions={popperOptions}
      >
        { !hideArrow && <span className="popper-arrow" ref={handleArrowRef} /> }
        {children}
      </Popper>
    </div>
  );
}

CustomPopper.propTypes = {
  popperOpen: PropTypes.bool.isRequired,
  anchorEl: PropTypes.object,
  classes: PropTypes.string,
  children: PropTypes.node,
  placement: PropTypes.string,
  hideArrow: PropTypes.bool,
  preventBubbling: PropTypes.bool,
  modifiers: PropTypes.object,
  disablePortal: PropTypes.bool,
  onUpdate: PropTypes.func,
};

CustomPopper.defaultProps = {
  anchorEl: null,
  classes: '',
  children: '',
  placement: 'bottom',
  hideArrow: false,
  preventBubbling: false,
  modifiers: {},
  disablePortal: false,
  onUpdate: () => {},
};

export default CustomPopper;
