import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { AUTOMATION_ADD_FILTER_BUTTON } from '../filters/ids';
import { getFilterOptions } from '../table/filterUtils';
import { formatTableColumn } from '../common/listUtils';
import { FILTER_AVAILABILITIES, BLANK_FILTER } from '../../filters/constants';
import { EXCLUDED_ALLOCATE_PERSON_MODAL_FILTERS, PRIORITY_PEOPLE_FILTERS } from '../filters/common/constants';
import { PersonDescriptor } from '../../common/descriptors/person';
import { ROLE_AVAILABILITIES_QUERY_ID } from '../queries/redux/constants';
import { FilterContainer } from '../filters';
import { hasModuleEnabled } from '../permissions/utils/permissionUtils';
import { getMappedFilters } from '../filters/utils/filterUtils';

export const EditCandidateFilters = ({
  onApplyFilter,
}) => {
  const { personFields, accountModules } = useSelector(({ accountSettings }) => accountSettings);
  const { projectNames } = useSelector(({ projects }) => projects);
  const filterQueries = useSelector(({ queries }) => {
    const allQueries = queries[ROLE_AVAILABILITIES_QUERY_ID] || {};

    return 'filter' in allQueries ? allQueries.filter : {};
  });

  const [columns, setColumns] = useState([]);

  /*
    Migrate to usePeopleFilter and getMappedFilters approach instead of this useEffect.
    See People.js for reference.
  */
  useEffect(() => {
    const generateColumns = () => {
      const columnOptions = {
        currentProjects: {
          filterOptions: projectNames,
          filterEmptyOption: true,
        },
      };

      const columns = PersonDescriptor.reduce((visibleColumns, current) => {
        if (current.module && !hasModuleEnabled(accountModules, current.module)) return visibleColumns;
        if (current.visible && current.filter) {
          const additionalOptions = current.schemaName in columnOptions ? columnOptions[current.schemaName] : {};

          // Allow run-time options to override column visibility
          if (('visible' in additionalOptions && !additionalOptions.visible)) return visibleColumns;

          visibleColumns.push({
            name: current.displayName,
            schemaName: current.schemaName,
            type: current.type,
            filterType: current.filterType,
            options: {
              sort: current.sort,
              filter: current.filter,
              singleFilter: current.filterType === FILTER_AVAILABILITIES,
              ...(current.filterOptions && { filterOptions: current.filterOptions }),
              ...additionalOptions,
            },
          });
        }

        return visibleColumns;
      }, []);

      personFields.forEach((field) => {
        if (!field.isSystem || field.type === 'Address') columns.push(formatTableColumn(field));
      });

      columns.forEach((column, index) => {
        if (column.options && column.options.filter) {
          const filterOptions = getFilterOptions(column.name, personFields);

          if (!('filterOptions' in column.options)) {
            columns[index].options.filterOptions = [];
          }

          if (column.options.filterEmptyOption && !column.options.filterOptions.find(option => option.name === BLANK_FILTER)) {
            filterOptions.push({ name: BLANK_FILTER, value: BLANK_FILTER });
          }

          columns[index].options.filterOptions.push(...filterOptions);
        }
      });

      return getMappedFilters(columns);
    };

    setColumns(generateColumns());
  }, [personFields, projectNames, accountModules]);

  return (
    <div className="allocations-edit-candidate-filters">
      <span className="section-heading">Filters</span>

      <FilterContainer
        className={classNames('filter-container', { compact: !(Array.isArray(filterQueries.args) && filterQueries.args.length) })}
        filterQueries={filterQueries}
        columns={columns}
        priorityColumns={PRIORITY_PEOPLE_FILTERS}
        exclude={EXCLUDED_ALLOCATE_PERSON_MODAL_FILTERS}
        showEmptyMessage={false}
        trackApplyFilter={onApplyFilter}
        addFilterButtonId={AUTOMATION_ADD_FILTER_BUTTON}
      />
    </div>
  );
};

EditCandidateFilters.propTypes = {
  onApplyFilter: PropTypes.func,
};

EditCandidateFilters.defaultProps = {
  onApplyFilter: () => {},
};

export default EditCandidateFilters;
