import {
  FIELDS,
  FIELDS_NAME,
  FIELDS_VALUE_TEXT,
} from './constants';

const shouldBeTrue = column => ({
  nested: {
    path: FIELDS,
    query: {
      bool: {
        must: [
          { match: { [FIELDS_NAME]: column } },
          { match: { [FIELDS_VALUE_TEXT]: 'true' } },
        ],
      },
    },
  },
});

const shouldBeFalse = column => ({
  bool: {
    should: [
      {
        bool: {
          must_not: [
            {
              nested: {
                path: FIELDS,
                query: { match: { [FIELDS_NAME]: column } },
              },
            },
          ],
        },
      },
      {
        nested: {
          path: FIELDS,
          query: {
            bool: {
              must: [
                { match: { [FIELDS_NAME]: column } },
                { match: { [FIELDS_VALUE_TEXT]: 'false' } },
              ],
            },
          },
        },
      },
    ],
  },
});

const generateBooleanFilter = (activeFilters, column) => {
  if (activeFilters.length === 1) {
    return activeFilters[0].value ? shouldBeTrue(column) : shouldBeFalse(column);
  }

  return null;
};

export default generateBooleanFilter;
