import {
  ERRORS_DISMISS_ERROR,
} from './constants';

export function dismissError() {
  return {
    type: ERRORS_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case ERRORS_DISMISS_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
}
