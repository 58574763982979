import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Paper } from '@material-ui/core';
import { NavigateBack } from '@bridgit/foundation';
import classNames from 'classnames';
import {
  HOURLY_PERSON_PROFILE_ASSIGNMENTS_TAB,
  HOURLY_PERSON_PROFILE_DETAILS_TAB,
  PEOPLE_SELF_PERFORM_TAB_MAP,
  PEOPLE_SELF_PERFORM_TABS,
} from 'src/common/constants';
import { FieldEditor } from '../common';
import { PERM_HOURLY_PERSON, PERM_WRITE } from '../permissions/utils/constants';
import { PermissionTabs } from '../wrapped-components';
import { updateHourlyPerson } from './redux/updateHourlyPerson';
import { HourlyPersonProfileDetail, HourlyPersonProfileAssignments } from '.';
import { getHourlyPersonFieldValues } from './redux/actions';

export const HourlyPersonProfile = ({
  onBackClick,
  name,
  skillSet,
  updateHourlyPersonPending,
  updateHourlyPerson,
  accountId,
  hourlyPersonId,
  getHourlyPersonFieldValues,
}) => {
  const [fieldToEdit, setFieldToEdit] = useState(null);
  const [selectedTab, setSelectedTab] = useState(HOURLY_PERSON_PROFILE_ASSIGNMENTS_TAB);

  useEffect(() => {
    getHourlyPersonFieldValues(accountId, hourlyPersonId);
  },
  [accountId, hourlyPersonId, getHourlyPersonFieldValues]);

  const onSave = ({ values }) => {
    updateHourlyPerson(accountId, hourlyPersonId, { name: values[0] }, name, 'List');

    setFieldToEdit(null);
  };

  const handleTabChange = useCallback((e, value) => {
    window.mixpanel.track(`Hourly Profile - ${PEOPLE_SELF_PERFORM_TAB_MAP[value]} Tab Clicked`);

    setSelectedTab(value);
  }, []);

  const handleCancelEditing = () => setFieldToEdit(null);

  const getTabContent = () => {
    switch (selectedTab) {
      case HOURLY_PERSON_PROFILE_ASSIGNMENTS_TAB:
        return <HourlyPersonProfileAssignments />;
      case HOURLY_PERSON_PROFILE_DETAILS_TAB:
        return <HourlyPersonProfileDetail />;
      default:
        return null;
    }
  };

  const renderSkills = () => {
    if (!skillSet) return null;

    return skillSet
      .map((skill, idx) => (idx === 0 ? skill : skill.toLowerCase()))
      .join(', ');
  };

  const nameInput = {
    id: 'name',
    name: 'name',
    type: 'text',
    required: true,
    value: name,
  };

  return (
    <Paper className="people-self-perform-profile">
      <div className="detail">
        <div className="detail-wrapper">
          <div className="header-content">
            <div className="nav-wrapper">
              <NavigateBack onClick={onBackClick} label="Back to list of people" />
            </div>
            <div className="headerLeft">
              <div className="title-wrapper">
                <FieldEditor
                  item={nameInput}
                  input={nameInput}
                  onSave={onSave}
                  onCancel={handleCancelEditing}
                  disabled={updateHourlyPersonPending}
                  permission={{ action: PERM_WRITE, subject: PERM_HOURLY_PERSON }}
                  pending={updateHourlyPersonPending}
                  fieldToEdit={setFieldToEdit}
                  editing={!!fieldToEdit}
                  showName={false}
                />
                <p className="skillSet" variant="subtitle1">{renderSkills()}</p>
              </div>
            </div>
            <div className={classNames('tabs-wrapper', { disabled: !!fieldToEdit })}>
              <PermissionTabs
                className="filter-tabs"
                tabs={PEOPLE_SELF_PERFORM_TABS}
                value={selectedTab}
                onChange={handleTabChange}
              />
              <hr />
            </div>
          </div>
        </div>
        <div className={classNames('content-wrapper', { disabled: !!fieldToEdit })}>
          {getTabContent()}
        </div>
      </div>
    </Paper>
  );
};

HourlyPersonProfile.propTypes = {
  onBackClick: PropTypes.func.isRequired,
  updateHourlyPersonPending: PropTypes.bool,
  skillSet: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  updateHourlyPerson: PropTypes.func.isRequired,
  accountId: PropTypes.number.isRequired,
  hourlyPersonId: PropTypes.number.isRequired,
  getHourlyPersonFieldValues: PropTypes.func.isRequired,
};

HourlyPersonProfile.defaultProps = {
  skillSet: [],
  updateHourlyPersonPending: false,
};

const mapStateToProps = ({
  common: { accountId },
  selfPerform: {
    updateHourlyPersonPending,
    selectedHourlyPerson: { id, name, skillSet },
  },
}) => ({
  accountId,
  updateHourlyPersonPending,
  hourlyPersonId: id,
  name,
  skillSet,
});

const mapDispatchToProps = dispatch => ({
  updateHourlyPerson: bindActionCreators(updateHourlyPerson, dispatch),
  getHourlyPersonFieldValues: bindActionCreators(getHourlyPersonFieldValues, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HourlyPersonProfile);
