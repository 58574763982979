import { call, put, takeLatest } from 'redux-saga/effects';
import {
  SELF_PERFORM_ASSIGN_REQUESTER_BEGIN,
  SELF_PERFORM_ASSIGN_REQUESTER_SUCCESS,
  SELF_PERFORM_ASSIGN_REQUESTER_FAILURE,
  SELF_PERFORM_ASSIGN_REQUESTER_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';

export function assignRequester(accountId, projectId, requesterIds, users, projectName) {
  return {
    type: SELF_PERFORM_ASSIGN_REQUESTER_BEGIN,
    accountId,
    projectId,
    requesterIds,
    users,
    projectName,
  };
}

export function dismissAssignRequesterError() {
  return {
    type: SELF_PERFORM_ASSIGN_REQUESTER_DISMISS_ERROR,
  };
}

export function* doAssignRequester(action) {
  const { accountId, projectId, requesterIds, users, projectName } = action;

  let res;
  try {
    res = yield call(protectedJsonPost, `accounts/${accountId}/projects/${projectId}/users`, requesterIds);
  } catch (err) {
    yield put({
      type: SELF_PERFORM_ASSIGN_REQUESTER_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: SELF_PERFORM_ASSIGN_REQUESTER_SUCCESS,
    data: res,
    addedRequesters: users[accountId].filter(({ id }) => requesterIds.includes(id)),
    projectId,
    projectName,
  });
}

export function* watchAssignRequester() {
  yield takeLatest(SELF_PERFORM_ASSIGN_REQUESTER_BEGIN, doAssignRequester);
}

export function reducer(state, action) {
  switch (action.type) {
    case SELF_PERFORM_ASSIGN_REQUESTER_BEGIN:
      return {
        ...state,
        assignRequesterPending: true,
        assignRequesterError: null,
      };

    case SELF_PERFORM_ASSIGN_REQUESTER_SUCCESS: {
      const { addedRequesters } = action;

      return {
        ...state,
        projectRequesters: [...state.projectRequesters, ...addedRequesters],
        assignRequesterPending: false,
        assignRequesterError: null,
      };
    }

    case SELF_PERFORM_ASSIGN_REQUESTER_FAILURE:
      return {
        ...state,
        assignRequesterPending: false,
        assignRequesterError: action.data.error,
      };

    case SELF_PERFORM_ASSIGN_REQUESTER_DISMISS_ERROR:
      return {
        ...state,
        assignRequesterError: null,
      };

    default:
      return state;
  }
}
