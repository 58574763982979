import moment from 'moment';
import {
  NO_AVAILABILITY_FILTER,
  AVAILABLE_NOW_FILTER,
  FUTURE_AVAILABILITY,
  AVAILABILITIES,
  AVAILABILITIES_PERCENT,
  AVAILABILITIES_START_DATE,
  AVAILABILITIES_END_DATE,
  ABOVE_FILTER,
  EQUALS_FILTER,
  BELOW_FILTER,
  BETWEEN_FILTER,
  AFTER_FILTER,
  BEFORE_FILTER,
  PERCENT_FILTER_TYPE,
  DATE_FILTER_TYPE,
} from 'src/filters/constants';
import { stringToMoment, momentToString } from 'src/utils/dateUtils';

const now = momentToString(moment());

const noAvailabilityAsOfDateQuery = (dateFilter) => {
  const date = dateFilter ? dateFilter?.value : now;

  return {
    bool: {
      must_not: [
        {
          bool: {
            must: {
              exists: {
                field: AVAILABILITIES,
              },
            },
          },
        },
        {
          nested: {
            path: AVAILABILITIES,
            query: {
              bool: {
                must:
                  [
                    { range: { [AVAILABILITIES_START_DATE]: { lte: date } } },
                    { range: { [AVAILABILITIES_END_DATE]: { gte: date } } },
                  ],
              },
            },
          },
        },
      ],
    },
  };
};

const generateMust = (activeFilter) => {
  const { type, verb, value, secondaryValue } = activeFilter;
  const date = type === DATE_FILTER_TYPE ? value : now;
  const must = [];

  if (type === PERCENT_FILTER_TYPE) {
    if (verb === ABOVE_FILTER) {
      must.push({ range: { [AVAILABILITIES_PERCENT]: { gt: value } } });
    } else if (verb === BELOW_FILTER) {
      must.push({ range: { [AVAILABILITIES_PERCENT]: { lt: value } } });
    } else if (verb === BETWEEN_FILTER) {
      const min = Math.min(value, secondaryValue);
      const max = Math.max(value, secondaryValue);
      must.push({ range: { [AVAILABILITIES_PERCENT]: { gte: min, lte: max } } });
    } else {
      must.push({ match: { [AVAILABILITIES_PERCENT]: value } });
    }
  }

  if (type === DATE_FILTER_TYPE) {
    if (verb === AFTER_FILTER) {
      must.push({ range: { [AVAILABILITIES_END_DATE]: { gt: date } } });
    } else if (verb === BEFORE_FILTER) {
      must.push({ range: { [AVAILABILITIES_START_DATE]: { lt: date } } });
    } else if (verb === BETWEEN_FILTER) {
      const momentDates = [stringToMoment(date), stringToMoment(secondaryValue)];
      const min = moment.min(momentDates);
      const max = moment.max(momentDates);

      must.push(
        { range: { [AVAILABILITIES_START_DATE]: { lt: momentToString(max) } } },
        { range: { [AVAILABILITIES_END_DATE]: { gt: momentToString(min) } } },
      );
    } else {
      must.push(
        { range: { [AVAILABILITIES_START_DATE]: { lte: date } } },
        { range: { [AVAILABILITIES_END_DATE]: { gte: date } } },
      );
    }
  }

  return must;
};

const generateShould = (must, activeFilters) => {
  const percentFilter = activeFilters.find(({ type }) => type === PERCENT_FILTER_TYPE);
  const dateFilter = activeFilters.find(({ type }) => type === DATE_FILTER_TYPE);
  const { verb, value, secondaryValue } = percentFilter || {};

  const should = [
    {
      nested: {
        path: AVAILABILITIES,
        query: {
          bool: {
            must,
          },
        },
      },
    },
  ];

  if (verb === BELOW_FILTER) {
    if (parseInt(value, 10) !== 0) {
      should.push(noAvailabilityAsOfDateQuery(dateFilter));
    }
  } else if (verb === BETWEEN_FILTER) {
    const min = Math.min(value, secondaryValue);
    if (min === 0) {
      should.push(noAvailabilityAsOfDateQuery(dateFilter));
    }
  } else if (verb === EQUALS_FILTER && parseInt(value, 10) === 0) {
    should.push(noAvailabilityAsOfDateQuery(dateFilter));
  }

  return should;
};

const generateAvailabilityFilter = (activeFilters) => {
  const filterValue = activeFilters?.[0]?.value;

  if (filterValue === NO_AVAILABILITY_FILTER) {
    return {
      bool: {
        must_not: {
          nested: {
            path: AVAILABILITIES,
            query: {
              bool: {
                must: [
                  { range: { [AVAILABILITIES_END_DATE]: { gte: now } } },
                ],
              },
            },
          },
        },
      },
    };
  }

  if (filterValue === AVAILABLE_NOW_FILTER) {
    return {
      bool: {
        must: {
          nested: {
            path: AVAILABILITIES,
            query: {
              bool: {
                must: [
                  { range: { [AVAILABILITIES_START_DATE]: { lte: now } } },
                  { range: { [AVAILABILITIES_END_DATE]: { gte: now } } },
                ],
              },
            },
          },
        },
      },
    };
  }

  if (filterValue === FUTURE_AVAILABILITY) {
    return {
      bool: {
        must: {
          nested: {
            path: AVAILABILITIES,
            query: {
              bool: {
                must: [
                  { range: { [AVAILABILITIES_END_DATE]: { gte: now } } },
                ],
              },
            },
          },
        },
      },
    };
  }

  const dateFilter = activeFilters.find(({ type }) => type === DATE_FILTER_TYPE);
  const must = activeFilters.map(activeFilter => generateMust(activeFilter));
  if (!dateFilter) {
    must.push(
      { range: { [AVAILABILITIES_START_DATE]: { lte: now } } },
      { range: { [AVAILABILITIES_END_DATE]: { gte: now } } },
    );
  }
  const should = generateShould(must.flat(), activeFilters);

  return {
    bool: {
      should,
    },
  };
};

export default generateAvailabilityFilter;
