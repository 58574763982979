import {
  EDIT_DATES_RESET_EDITOR_STATE,
} from './constants';
import initialState from './initialState';

export function resetEditorState() {
  return {
    type: EDIT_DATES_RESET_EDITOR_STATE,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case EDIT_DATES_RESET_EDITOR_STATE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
