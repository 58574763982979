export const MAX_FILE_SIZE = 5242880; // 5242880bytes is 5MB
export const MIN_IMAGE_DIMENSION = 180;
export const MAX_IMAGE_DIMENSION = 1500;
export const DEFAULT_PEOPLE_SORT = { name: 'sort', args: [{ column: 'name', label: 'Name', ascending: true, type: null }] };

export const MAX_PEOPLE = 999999;

export const PEOPLE_GET_PEOPLE_BEGIN = 'PEOPLE_GET_PEOPLE_BEGIN';
export const PEOPLE_GET_PEOPLE_SUCCESS = 'PEOPLE_GET_PEOPLE_SUCCESS';
export const PEOPLE_GET_PEOPLE_FAILURE = 'PEOPLE_GET_PEOPLE_FAILURE';
export const PEOPLE_GET_PEOPLE_DISMISS_ERROR = 'PEOPLE_GET_PEOPLE_DISMISS_ERROR';
export const PEOPLE_REMOVE_PERSON_BEGIN = 'PEOPLE_REMOVE_PERSON_BEGIN';
export const PEOPLE_REMOVE_PERSON_SUCCESS = 'PEOPLE_REMOVE_PERSON_SUCCESS';
export const PEOPLE_REMOVE_PERSON_FAILURE = 'PEOPLE_REMOVE_PERSON_FAILURE';
export const PEOPLE_REMOVE_PERSON_DISMISS_ERROR = 'PEOPLE_REMOVE_PERSON_DISMISS_ERROR';
export const PEOPLE_ADD_PERSON_BEGIN = 'PEOPLE_ADD_PERSON_BEGIN';
export const PEOPLE_ADD_PERSON_SUCCESS = 'PEOPLE_ADD_PERSON_SUCCESS';
export const PEOPLE_ADD_PERSON_FAILURE = 'PEOPLE_ADD_PERSON_FAILURE';
export const PEOPLE_ADD_PERSON_DISMISS_ERROR = 'PEOPLE_ADD_PERSON_DISMISS_ERROR';
export const PEOPLE_GET_PERSON_FIELD_VALUES_BEGIN = 'PEOPLE_GET_PERSON_FIELD_VALUES_BEGIN';
export const PEOPLE_GET_PERSON_FIELD_VALUES_SUCCESS = 'PEOPLE_GET_PERSON_FIELD_VALUES_SUCCESS';
export const PEOPLE_GET_PERSON_FIELD_VALUES_FAILURE = 'PEOPLE_GET_PERSON_FIELD_VALUES_FAILURE';
export const PEOPLE_GET_PERSON_FIELD_VALUES_DISMISS_ERROR = 'PEOPLE_GET_PERSON_FIELD_VALUES_DISMISS_ERROR';
export const PEOPLE_GET_PERSON_AVAILABILITIES_BEGIN = 'PEOPLE_GET_PERSON_AVAILABILITIES_BEGIN';
export const PEOPLE_GET_PERSON_AVAILABILITIES_SUCCESS = 'PEOPLE_GET_PERSON_AVAILABILITIES_SUCCESS';
export const PEOPLE_GET_PERSON_AVAILABILITIES_FAILURE = 'PEOPLE_GET_PERSON_AVAILABILITIES_FAILURE';
export const PEOPLE_GET_PERSON_AVAILABILITIES_DISMISS_ERROR = 'PEOPLE_GET_PERSON_AVAILABILITIES_DISMISS_ERROR';
export const PEOPLE_GET_PERSON_PROJECTS_BEGIN = 'PEOPLE_GET_PERSON_PROJECTS_BEGIN';
export const PEOPLE_GET_PERSON_PROJECTS_SUCCESS = 'PEOPLE_GET_PERSON_PROJECTS_SUCCESS';
export const PEOPLE_GET_PERSON_PROJECTS_FAILURE = 'PEOPLE_GET_PERSON_PROJECTS_FAILURE';
export const PEOPLE_GET_PERSON_PROJECTS_DISMISS_ERROR = 'PEOPLE_GET_PERSON_PROJECTS_DISMISS_ERROR';
export const PEOPLE_GET_PERSON_ALLOCATIONS_BY_DATE_BEGIN = 'PEOPLE_GET_PERSON_ALLOCATIONS_BY_DATE_BEGIN';
export const PEOPLE_GET_PERSON_ALLOCATIONS_BY_DATE_SUCCESS = 'PEOPLE_GET_PERSON_ALLOCATIONS_BY_DATE_SUCCESS';
export const PEOPLE_GET_PERSON_ALLOCATIONS_BY_DATE_FAILURE = 'PEOPLE_GET_PERSON_ALLOCATIONS_BY_DATE_FAILURE';
export const PEOPLE_GET_PERSON_ALLOCATIONS_BY_DATE_DISMISS_ERROR = 'PEOPLE_GET_PERSON_ALLOCATIONS_BY_DATE_DISMISS_ERROR';
export const PEOPLE_GET_PERSON_CURRENT_ALLOCATIONS_BEGIN = 'PEOPLE_GET_PERSON_CURRENT_ALLOCATIONS_BEGIN';
export const PEOPLE_GET_PERSON_CURRENT_ALLOCATIONS_SUCCESS = 'PEOPLE_GET_PERSON_CURRENT_ALLOCATIONS_SUCCESS';
export const PEOPLE_GET_PERSON_CURRENT_ALLOCATIONS_FAILURE = 'PEOPLE_GET_PERSON_CURRENT_ALLOCATIONS_FAILURE';
export const PEOPLE_GET_PERSON_CURRENT_ALLOCATIONS_DISMISS_ERROR = 'PEOPLE_GET_PERSON_CURRENT_ALLOCATIONS_DISMISS_ERROR';
export const PEOPLE_GET_PERSON_PAST_ALLOCATIONS_BEGIN = 'PEOPLE_GET_PERSON_PAST_ALLOCATIONS_BEGIN';
export const PEOPLE_GET_PERSON_PAST_ALLOCATIONS_SUCCESS = 'PEOPLE_GET_PERSON_PAST_ALLOCATIONS_SUCCESS';
export const PEOPLE_GET_PERSON_PAST_ALLOCATIONS_FAILURE = 'PEOPLE_GET_PERSON_PAST_ALLOCATIONS_FAILURE';
export const PEOPLE_GET_PERSON_PAST_ALLOCATIONS_DISMISS_ERROR = 'PEOPLE_GET_PERSON_PAST_ALLOCATIONS_DISMISS_ERROR';
export const PEOPLE_GET_PERSON_ISSUES_BEGIN = 'PEOPLE_GET_PERSON_ISSUES_BEGIN';
export const PEOPLE_GET_PERSON_ISSUES_SUCCESS = 'PEOPLE_GET_PERSON_ISSUES_SUCCESS';
export const PEOPLE_GET_PERSON_ISSUES_FAILURE = 'PEOPLE_GET_PERSON_ISSUES_FAILURE';
export const PEOPLE_GET_PERSON_ISSUES_DISMISS_ERROR = 'PEOPLE_GET_PERSON_ISSUES_DISMISS_ERROR';
export const PEOPLE_GET_ACCOUNT_ALLOCATIONS_BEGIN = 'PEOPLE_GET_ACCOUNT_ALLOCATIONS_BEGIN';
export const PEOPLE_GET_ACCOUNT_ALLOCATIONS_SUCCESS = 'PEOPLE_GET_ACCOUNT_ALLOCATIONS_SUCCESS';
export const PEOPLE_GET_ACCOUNT_ALLOCATIONS_FAILURE = 'PEOPLE_GET_ACCOUNT_ALLOCATIONS_FAILURE';
export const PEOPLE_GET_ACCOUNT_ALLOCATIONS_DISMISS_ERROR = 'PEOPLE_GET_ACCOUNT_ALLOCATIONS_DISMISS_ERROR';
export const PEOPLE_GET_UPLOAD_URL_BEGIN = 'PEOPLE_GET_UPLOAD_URL_BEGIN';
export const PEOPLE_GET_UPLOAD_URL_SUCCESS = 'PEOPLE_GET_UPLOAD_URL_SUCCESS';
export const PEOPLE_GET_UPLOAD_URL_FAILURE = 'PEOPLE_GET_UPLOAD_URL_FAILURE';
export const PEOPLE_GET_UPLOAD_URL_DISMISS_ERROR = 'PEOPLE_GET_UPLOAD_URL_DISMISS_ERROR';
export const PEOPLE_SET_PROFILE_PICTURE_BEGIN = 'PEOPLE_SET_PROFILE_PICTURE_BEGIN';
export const PEOPLE_SET_PROFILE_PICTURE_SUCCESS = 'PEOPLE_SET_PROFILE_PICTURE_SUCCESS';
export const PEOPLE_SET_PROFILE_PICTURE_FAILURE = 'PEOPLE_SET_PROFILE_PICTURE_FAILURE';
export const PEOPLE_SET_PROFILE_PICTURE_DISMISS_ERROR = 'PEOPLE_SET_PROFILE_PICTURE_DISMISS_ERROR';
export const PEOPLE_REMOVE_PROFILE_PICTURE_BEGIN = 'PEOPLE_REMOVE_PROFILE_PICTURE_BEGIN';
export const PEOPLE_REMOVE_PROFILE_PICTURE_SUCCESS = 'PEOPLE_REMOVE_PROFILE_PICTURE_SUCCESS';
export const PEOPLE_REMOVE_PROFILE_PICTURE_FAILURE = 'PEOPLE_REMOVE_PROFILE_PICTURE_FAILURE';
export const PEOPLE_REMOVE_PROFILE_PICTURE_DISMISS_ERROR = 'PEOPLE_REMOVE_PROFILE_PICTURE_DISMISS_ERROR';
export const PEOPLE_UPDATE_PERSON_BEGIN = 'PEOPLE_UPDATE_PERSON_BEGIN';
export const PEOPLE_UPDATE_PERSON_SUCCESS = 'PEOPLE_UPDATE_PERSON_SUCCESS';
export const PEOPLE_UPDATE_PERSON_FAILURE = 'PEOPLE_UPDATE_PERSON_FAILURE';
export const PEOPLE_UPDATE_PERSON_DISMISS_ERROR = 'PEOPLE_UPDATE_PERSON_DISMISS_ERROR';
export const PEOPLE_GET_FILTERED_PEOPLE_BEGIN = 'PEOPLE_GET_FILTERED_PEOPLE_BEGIN';
export const PEOPLE_GET_FILTERED_PEOPLE_SUCCESS = 'PEOPLE_GET_FILTERED_PEOPLE_SUCCESS';
export const PEOPLE_GET_FILTERED_PEOPLE_FAILURE = 'PEOPLE_GET_FILTERED_PEOPLE_FAILURE';
export const PEOPLE_GET_FILTERED_PEOPLE_DISMISS_ERROR = 'PEOPLE_GET_FILTERED_PEOPLE_DISMISS_ERROR';
export const PEOPLE_REACTIVATE_PERSON_BEGIN = 'PEOPLE_REACTIVATE_PERSON_BEGIN';
export const PEOPLE_REACTIVATE_PERSON_SUCCESS = 'PEOPLE_REACTIVATE_PERSON_SUCCESS';
export const PEOPLE_REACTIVATE_PERSON_FAILURE = 'PEOPLE_REACTIVATE_PERSON_FAILURE';
export const PEOPLE_REACTIVATE_PERSON_DISMISS_ERROR = 'PEOPLE_REACTIVATE_PERSON_DISMISS_ERROR';
export const PEOPLE_COLLECT_PEOPLE_TABLE_DATA_BEGIN = 'PEOPLE_COLLECT_PEOPLE_TABLE_DATA_BEGIN';
export const PEOPLE_COLLECT_PEOPLE_TABLE_DATA_SUCCESS = 'PEOPLE_COLLECT_PEOPLE_TABLE_DATA_SUCCESS';
export const PEOPLE_COLLECT_PEOPLE_TABLE_DATA_FAILURE = 'PEOPLE_COLLECT_PEOPLE_TABLE_DATA_FAILURE';
export const PEOPLE_COLLECT_PEOPLE_TABLE_DATA_DISMISS_ERROR = 'PEOPLE_COLLECT_PEOPLE_TABLE_DATA_DISMISS_ERROR';
export const PEOPLE_UPDATE_PERSON_FIELD_VALUE_BEGIN = 'PEOPLE_UPDATE_PERSON_FIELD_VALUE_BEGIN';
export const PEOPLE_UPDATE_PERSON_FIELD_VALUE_SUCCESS = 'PEOPLE_UPDATE_PERSON_FIELD_VALUE_SUCCESS';
export const PEOPLE_UPDATE_PERSON_FIELD_VALUE_FAILURE = 'PEOPLE_UPDATE_PERSON_FIELD_VALUE_FAILURE';
export const PEOPLE_UPDATE_PERSON_FIELD_VALUE_DISMISS_ERROR = 'PEOPLE_UPDATE_PERSON_FIELD_VALUE_DISMISS_ERROR';
export const PEOPLE_ADD_UNAVAILABILITY_BEGIN = 'PEOPLE_ADD_UNAVAILABILITY_BEGIN';
export const PEOPLE_ADD_UNAVAILABILITY_SUCCESS = 'PEOPLE_ADD_UNAVAILABILITY_SUCCESS';
export const PEOPLE_ADD_UNAVAILABILITY_FAILURE = 'PEOPLE_ADD_UNAVAILABILITY_FAILURE';
export const PEOPLE_ADD_UNAVAILABILITY_DISMISS_ERROR = 'PEOPLE_ADD_UNAVAILABILITY_DISMISS_ERROR';
export const PEOPLE_UPDATE_UNAVAILABILITY_BEGIN = 'PEOPLE_UPDATE_UNAVAILABILITY_BEGIN';
export const PEOPLE_UPDATE_UNAVAILABILITY_SUCCESS = 'PEOPLE_UPDATE_UNAVAILABILITY_SUCCESS';
export const PEOPLE_UPDATE_UNAVAILABILITY_FAILURE = 'PEOPLE_UPDATE_UNAVAILABILITY_FAILURE';
export const PEOPLE_UPDATE_UNAVAILABILITY_DISMISS_ERROR = 'PEOPLE_UPDATE_UNAVAILABILITY_DISMISS_ERROR';
export const PEOPLE_DELETE_UNAVAILABILITY_BEGIN = 'PEOPLE_DELETE_UNAVAILABILITY_BEGIN';
export const PEOPLE_DELETE_UNAVAILABILITY_SUCCESS = 'PEOPLE_DELETE_UNAVAILABILITY_SUCCESS';
export const PEOPLE_DELETE_UNAVAILABILITY_FAILURE = 'PEOPLE_DELETE_UNAVAILABILITY_FAILURE';
export const PEOPLE_DELETE_UNAVAILABILITY_DISMISS_ERROR = 'PEOPLE_DELETE_UNAVAILABILITY_DISMISS_ERROR';
export const PEOPLE_GET_PERSON_UNAVAILABILITIES_BEGIN = 'PEOPLE_GET_PERSON_UNAVAILABILITIES_BEGIN';
export const PEOPLE_GET_PERSON_UNAVAILABILITIES_SUCCESS = 'PEOPLE_GET_PERSON_UNAVAILABILITIES_SUCCESS';
export const PEOPLE_GET_PERSON_UNAVAILABILITIES_FAILURE = 'PEOPLE_GET_PERSON_UNAVAILABILITIES_FAILURE';
export const PEOPLE_GET_PERSON_UNAVAILABILITIES_DISMISS_ERROR = 'PEOPLE_GET_PERSON_UNAVAILABILITIES_DISMISS_ERROR';
export const PEOPLE_GET_PERSON_BY_ID_BEGIN = 'PEOPLE_GET_PERSON_BY_ID_BEGIN';
export const PEOPLE_GET_PERSON_BY_ID_SUCCESS = 'PEOPLE_GET_PERSON_BY_ID_SUCCESS';
export const PEOPLE_GET_PERSON_BY_ID_FAILURE = 'PEOPLE_GET_PERSON_BY_ID_FAILURE';
export const PEOPLE_GET_PERSON_BY_ID_DISMISS_ERROR = 'PEOPLE_GET_PERSON_BY_ID_DISMISS_ERROR';
export const PEOPLE_LIST_SELECTION_ID = 'PEOPLE_LIST_SELECTION_ID';
export const DEACTIVATED_PEOPLE_SELECTION_ID = 'DEACTIVATED_PEOPLE_SELECTION_ID';
export const PEOPLE_CLEAR_PERSON_SELECTION = 'PEOPLE_CLEAR_PERSON_SELECTION';
export const PEOPLE_CLEAR_ALL_PERSON_SELECTIONS = 'PEOPLE_CLEAR_ALL_PERSON_SELECTIONS';
export const PEOPLE_GET_DEACTIVATED_PEOPLE_BEGIN = 'PEOPLE_GET_DEACTIVATED_PEOPLE_BEGIN';
export const PEOPLE_GET_DEACTIVATED_PEOPLE_SUCCESS = 'PEOPLE_GET_DEACTIVATED_PEOPLE_SUCCESS';
export const PEOPLE_GET_DEACTIVATED_PEOPLE_FAILURE = 'PEOPLE_GET_DEACTIVATED_PEOPLE_FAILURE';
export const PEOPLE_GET_DEACTIVATED_PEOPLE_DISMISS_ERROR = 'PEOPLE_GET_DEACTIVATED_PEOPLE_DISMISS_ERROR';
export const PEOPLE_SEARCH_ACTIVE_PEOPLE_BEGIN = 'PEOPLE_SEARCH_ACTIVE_PEOPLE_BEGIN';
export const PEOPLE_SEARCH_ACTIVE_PEOPLE_SUCCESS = 'PEOPLE_SEARCH_ACTIVE_PEOPLE_SUCCESS';
export const PEOPLE_SEARCH_ACTIVE_PEOPLE_FAILURE = 'PEOPLE_SEARCH_ACTIVE_PEOPLE_FAILURE';
export const PEOPLE_SEARCH_ACTIVE_PEOPLE_DISMISS_ERROR = 'PEOPLE_SEARCH_ACTIVE_PEOPLE_DISMISS_ERROR';
export const PEOPLE_CLEAR_SEARCHED_ACTIVE_PEOPLE = 'PEOPLE_CLEAR_SEARCHED_ACTIVE_PEOPLE';
export const PEOPLE_GET_PERSON_ALLOCATIONS_BEGIN = 'PEOPLE_GET_PERSON_ALLOCATIONS_BEGIN';
export const PEOPLE_GET_PERSON_ALLOCATIONS_SUCCESS = 'PEOPLE_GET_PERSON_ALLOCATIONS_SUCCESS';
export const PEOPLE_GET_PERSON_ALLOCATIONS_FAILURE = 'PEOPLE_GET_PERSON_ALLOCATIONS_FAILURE';
export const PEOPLE_GET_PERSON_ALLOCATIONS_DISMISS_ERROR = 'PEOPLE_GET_PERSON_ALLOCATIONS_DISMISS_ERROR';
export const PEOPLE_GET_PERSON_UPCOMING_ALLOCATIONS_BEGIN = 'PEOPLE_GET_PERSON_UPCOMING_ALLOCATIONS_BEGIN';
export const PEOPLE_GET_PERSON_UPCOMING_ALLOCATIONS_SUCCESS = 'PEOPLE_GET_PERSON_UPCOMING_ALLOCATIONS_SUCCESS';
export const PEOPLE_GET_PERSON_UPCOMING_ALLOCATIONS_FAILURE = 'PEOPLE_GET_PERSON_UPCOMING_ALLOCATIONS_FAILURE';
export const PEOPLE_GET_PERSON_UPCOMING_ALLOCATIONS_DISMISS_ERROR = 'PEOPLE_GET_PERSON_UPCOMING_ALLOCATIONS_DISMISS_ERROR';
export const PEOPLE_ADD_PERSON_NOTE_BEGIN = 'PEOPLE_ADD_PERSON_NOTE_BEGIN';
export const PEOPLE_ADD_PERSON_NOTE_SUCCESS = 'PEOPLE_ADD_PERSON_NOTE_SUCCESS';
export const PEOPLE_ADD_PERSON_NOTE_FAILURE = 'PEOPLE_ADD_PERSON_NOTE_FAILURE';
export const PEOPLE_ADD_PERSON_NOTE_DISMISS_ERROR = 'PEOPLE_ADD_PERSON_NOTE_DISMISS_ERROR';
export const PEOPLE_DELETE_PERSON_NOTE_BEGIN = 'PEOPLE_DELETE_PERSON_NOTE_BEGIN';
export const PEOPLE_DELETE_PERSON_NOTE_SUCCESS = 'PEOPLE_DELETE_PERSON_NOTE_SUCCESS';
export const PEOPLE_DELETE_PERSON_NOTE_FAILURE = 'PEOPLE_DELETE_PERSON_NOTE_FAILURE';
export const PEOPLE_DELETE_PERSON_NOTE_DISMISS_ERROR = 'PEOPLE_DELETE_PERSON_NOTE_DISMISS_ERROR';
export const PEOPLE_UPDATE_PERSON_NOTE_BEGIN = 'PEOPLE_UPDATE_PERSON_NOTE_BEGIN';
export const PEOPLE_UPDATE_PERSON_NOTE_SUCCESS = 'PEOPLE_UPDATE_PERSON_NOTE_SUCCESS';
export const PEOPLE_UPDATE_PERSON_NOTE_FAILURE = 'PEOPLE_UPDATE_PERSON_NOTE_FAILURE';
export const PEOPLE_UPDATE_PERSON_NOTE_DISMISS_ERROR = 'PEOPLE_UPDATE_PERSON_NOTE_DISMISS_ERROR';
export const PEOPLE_GET_PERSON_AUDIT_BEGIN = 'PEOPLE_GET_PERSON_AUDIT_BEGIN';
export const PEOPLE_GET_PERSON_AUDIT_SUCCESS = 'PEOPLE_GET_PERSON_AUDIT_SUCCESS';
export const PEOPLE_GET_PERSON_AUDIT_FAILURE = 'PEOPLE_GET_PERSON_AUDIT_FAILURE';
export const PEOPLE_GET_PERSON_AUDIT_DISMISS_ERROR = 'PEOPLE_GET_PERSON_AUDIT_DISMISS_ERROR';
export const PEOPLE_UPLOAD_PERSON_ATTACHMENT_BEGIN = 'PEOPLE_UPLOAD_PERSON_ATTACHMENT_BEGIN';
export const PEOPLE_UPLOAD_PERSON_ATTACHMENT_SUCCESS = 'PEOPLE_UPLOAD_PERSON_ATTACHMENT_SUCCESS';
export const PEOPLE_UPLOAD_PERSON_ATTACHMENT_FAILURE = 'PEOPLE_UPLOAD_PERSON_ATTACHMENT_FAILURE';
export const PEOPLE_UPLOAD_PERSON_ATTACHMENT_S3_FAILURE = 'PEOPLE_UPLOAD_PERSON_ATTACHMENT_S3_FAILURE';
export const PEOPLE_UPLOAD_PERSON_ATTACHMENT_CERT_LINK_FAILURE = 'PEOPLE_UPLOAD_PERSON_ATTACHMENT_CERT_LINK_FAILURE';
export const PEOPLE_UPLOAD_PERSON_ATTACHMENT_DISMISS_ERROR = 'PEOPLE_UPLOAD_PERSON_ATTACHMENT_DISMISS_ERROR';
export const PEOPLE_GET_PERSON_ATTACHMENTS_BEGIN = 'PEOPLE_GET_PERSON_ATTACHMENTS_BEGIN';
export const PEOPLE_GET_PERSON_ATTACHMENTS_SUCCESS = 'PEOPLE_GET_PERSON_ATTACHMENTS_SUCCESS';
export const PEOPLE_GET_PERSON_ATTACHMENTS_FAILURE = 'PEOPLE_GET_PERSON_ATTACHMENTS_FAILURE';
export const PEOPLE_GET_PERSON_ATTACHMENTS_DISMISS_ERROR = 'PEOPLE_GET_PERSON_ATTACHMENTS_DISMISS_ERROR';
export const PEOPLE_GET_PERSON_ATTACHMENT_BY_ID_BEGIN = 'PEOPLE_GET_PERSON_ATTACHMENT_BY_ID_BEGIN';
export const PEOPLE_GET_PERSON_ATTACHMENT_BY_ID_SUCCESS = 'PEOPLE_GET_PERSON_ATTACHMENT_BY_ID_SUCCESS';
export const PEOPLE_GET_PERSON_ATTACHMENT_BY_ID_FAILURE = 'PEOPLE_GET_PERSON_ATTACHMENT_BY_ID_FAILURE';
export const PEOPLE_GET_PERSON_ATTACHMENT_BY_ID_DISMISS_ERROR = 'PEOPLE_GET_PERSON_ATTACHMENT_BY_ID_DISMISS_ERROR';
export const PEOPLE_DOWNLOAD_PERSON_ATTACHMENT_BEGIN = 'PEOPLE_DOWNLOAD_PERSON_ATTACHMENT_BEGIN';
export const PEOPLE_DOWNLOAD_PERSON_ATTACHMENT_SUCCESS = 'PEOPLE_DOWNLOAD_PERSON_ATTACHMENT_SUCCESS';
export const PEOPLE_DOWNLOAD_PERSON_ATTACHMENT_FAILURE = 'PEOPLE_DOWNLOAD_PERSON_ATTACHMENT_FAILURE';
export const PEOPLE_DOWNLOAD_PERSON_ATTACHMENT_DISMISS_ERROR = 'PEOPLE_DOWNLOAD_PERSON_ATTACHMENT_DISMISS_ERROR';
export const PEOPLE_DELETE_PERSON_ATTACHMENT_BEGIN = 'PEOPLE_DELETE_PERSON_ATTACHMENT_BEGIN';
export const PEOPLE_DELETE_PERSON_ATTACHMENT_SUCCESS = 'PEOPLE_DELETE_PERSON_ATTACHMENT_SUCCESS';
export const PEOPLE_DELETE_PERSON_ATTACHMENT_FAILURE = 'PEOPLE_DELETE_PERSON_ATTACHMENT_FAILURE';
export const PEOPLE_DELETE_PERSON_ATTACHMENT_DISMISS_ERROR = 'PEOPLE_DELETE_PERSON_ATTACHMENT_DISMISS_ERROR';
export const PEOPLE_GET_PERSON_CERTIFICATIONS_BEGIN = 'PEOPLE_GET_PERSON_CERTIFICATIONS_BEGIN';
export const PEOPLE_GET_PERSON_CERTIFICATIONS_SUCCESS = 'PEOPLE_GET_PERSON_CERTIFICATIONS_SUCCESS';
export const PEOPLE_GET_PERSON_CERTIFICATIONS_FAILURE = 'PEOPLE_GET_PERSON_CERTIFICATIONS_FAILURE';
export const PEOPLE_GET_PERSON_CERTIFICATIONS_DISMISS_ERROR = 'PEOPLE_GET_PERSON_CERTIFICATIONS_DISMISS_ERROR';
export const PEOPLE_GET_PERSON_CERTIFICATION_ATTACHMENTS_BEGIN = 'PEOPLE_GET_PERSON_CERTIFICATION_ATTACHMENTS_BEGIN';
export const PEOPLE_GET_PERSON_CERTIFICATION_ATTACHMENTS_SUCCESS = 'PEOPLE_GET_PERSON_CERTIFICATION_ATTACHMENTS_SUCCESS';
export const PEOPLE_GET_PERSON_CERTIFICATION_ATTACHMENTS_FAILURE = 'PEOPLE_GET_PERSON_CERTIFICATION_ATTACHMENTS_FAILURE';
export const PEOPLE_GET_PERSON_CERTIFICATION_ATTACHMENTS_DISMISS_ERROR = 'PEOPLE_GET_PERSON_CERTIFICATION_ATTACHMENTS_DISMISS_ERROR';
export const PEOPLE_CLEAR_PERSON_CERTIFICATIONS = 'PEOPLE_CLEAR_PERSON_CERTIFICATIONS';
export const PEOPLE_ADD_PERSON_CERTIFICATION_BEGIN = 'PEOPLE_ADD_PERSON_CERTIFICATION_BEGIN';
export const PEOPLE_ADD_PERSON_CERTIFICATION_SUCCESS = 'PEOPLE_ADD_PERSON_CERTIFICATION_SUCCESS';
export const PEOPLE_ADD_PERSON_CERTIFICATION_FAILURE = 'PEOPLE_ADD_PERSON_CERTIFICATION_FAILURE';
export const PEOPLE_ADD_PERSON_CERTIFICATION_DISMISS_ERROR = 'PEOPLE_ADD_PERSON_CERTIFICATION_DISMISS_ERROR';
export const PEOPLE_UPDATE_PERSON_CERTIFICATION_BEGIN = 'PEOPLE_UPDATE_PERSON_CERTIFICATION_BEGIN';
export const PEOPLE_UPDATE_PERSON_CERTIFICATION_SUCCESS = 'PEOPLE_UPDATE_PERSON_CERTIFICATION_SUCCESS';
export const PEOPLE_UPDATE_PERSON_CERTIFICATION_FAILURE = 'PEOPLE_UPDATE_PERSON_CERTIFICATION_FAILURE';
export const PEOPLE_UPDATE_PERSON_CERTIFICATION_DISMISS_ERROR = 'PEOPLE_UPDATE_PERSON_CERTIFICATION_DISMISS_ERROR';
export const PEOPLE_DELETE_PERSON_CERTIFICATION_BEGIN = 'PEOPLE_DELETE_PERSON_CERTIFICATION_BEGIN';
export const PEOPLE_DELETE_PERSON_CERTIFICATION_SUCCESS = 'PEOPLE_DELETE_PERSON_CERTIFICATION_SUCCESS';
export const PEOPLE_DELETE_PERSON_CERTIFICATION_FAILURE = 'PEOPLE_DELETE_PERSON_CERTIFICATION_FAILURE';
export const PEOPLE_DELETE_PERSON_CERTIFICATION_DISMISS_ERROR = 'PEOPLE_DELETE_PERSON_CERTIFICATION_DISMISS_ERROR';
export const PEOPLE_MANAGE_CERTIFICATION_ATTACHMENTS = 'PEOPLE_MANAGE_CERTIFICATION_ATTACHMENTS';
export const PEOPLE_HIDE_CERTIFICATION_ATTACHMENTS = 'PEOPLE_HIDE_CERTIFICATION_ATTACHMENTS';
