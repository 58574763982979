import React, { useEffect, useCallback, useState, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ListWithLoader } from '@bridgit/foundation';
import classNames from 'classnames';
import pluralize from 'pluralize';

import {
  getPersonAttachments,
  uploadPersonAttachment,
} from './redux/actions';
import { PEOPLE_LIST_SELECTION_ID } from './redux/constants';
import { MULTI_STATE_MODAL_ID } from '../common/redux/constants';
import { ProfileAttachment, ProfileUploadAttachment } from '.';
import {
  DEFAULT_LIST_ITEMS_LIMIT,
  DEFAULT_LIST_OFFSET,
  PERSON_ATTACHMENTS_TAB_NAME,
  PROFILE_DETAILS_MODAL_CONTEXT,
  PROFILE_DETAILS_PROFILE_CONTEXT,
} from './constants';
import { isAuthorized, hasModuleEnabled } from '../permissions/utils/permissionUtils';
import { PERM_PERSON, PERM_WRITE } from '../permissions/utils/constants';
import {
  ACCOUNT_MODULE_CERTIFICATIONS,
  PEOPLE_ACCOUNT_MODULE_COMPONENT,
} from '../../common/constants';

const ProfileAttachments = () => {
  const dispatch = useDispatch();

  const { accountId } = useSelector(({ common }) => common);

  const isOpenInModal = useSelector(({ modalManager }) => modalManager.activeModal === MULTI_STATE_MODAL_ID);

  const {
    personAttachmentItems,
    uploadPersonAttachmentPending,
    selectedPerson,
    getPersonAttachmentsPending,
    hasMorePersonAttachments,
    deletePersonAttachmentPending,
  } = useSelector(({ people }) => {
    const {
      personAttachments,
      uploadPersonAttachmentPending,
      personSelections,
      getPersonAttachmentsPending,
      deletePersonAttachmentPending,
    } = people;

    return {
      personAttachmentItems: personAttachments.items || [],
      uploadPersonAttachmentPending,
      selectedPerson: isOpenInModal ? personSelections?.[MULTI_STATE_MODAL_ID] : personSelections?.[PEOPLE_LIST_SELECTION_ID],
      getPersonAttachmentsPending,
      hasMorePersonAttachments: personAttachments.hasMore,
      deletePersonAttachmentPending,
    };
  });

  const { permissions } = useSelector(({ login }) => login.userInfo);

  const { accountModules } = useSelector(({ accountSettings }) => accountSettings);

  const hasCertificationsModule = useMemo(
    () => hasModuleEnabled(accountModules, ACCOUNT_MODULE_CERTIFICATIONS, PEOPLE_ACCOUNT_MODULE_COMPONENT),
    [accountModules],
  );

  const isFileUploaderVisible = useMemo(
    () => isAuthorized(accountId, permissions, PERM_WRITE, PERM_PERSON),
    [accountId, permissions],
  );

  const [offset, setOffset] = useState(0);
  const prevGetPersonAttachmentsPending = useRef(getPersonAttachmentsPending);

  // increment offset before getting a new portion of attachments
  useEffect(() => {
    if (!prevGetPersonAttachmentsPending.current && getPersonAttachmentsPending) {
      setOffset(offset + DEFAULT_LIST_OFFSET);
    }
    prevGetPersonAttachmentsPending.current = getPersonAttachmentsPending;
  }, [getPersonAttachmentsPending, offset]);

  useEffect(() => {
    if (selectedPerson?.id) {
      dispatch(getPersonAttachments(accountId, selectedPerson.id, 0, DEFAULT_LIST_ITEMS_LIMIT, hasCertificationsModule));
    }
  }, [accountId, dispatch, selectedPerson?.id, hasCertificationsModule]);

  const onUpload = useCallback((file, binary, isPrivate) => {
    const { name } = file;
    const { id: personId, name: personName } = selectedPerson;

    const data = { name, isPrivate };

    const analyticsPayload = {
      personName,
      personId,
      uploadedFrom: isOpenInModal ? PROFILE_DETAILS_MODAL_CONTEXT : PROFILE_DETAILS_PROFILE_CONTEXT,
      file,
      isPrivate,
      tabUploadedFrom: 'Attachments',
    };

    dispatch(uploadPersonAttachment(accountId, personId, data, binary, analyticsPayload));
  }, [accountId, dispatch, isOpenInModal, selectedPerson]);

  const getAttachmentCaption = useCallback((certifications) => {
    if (!certifications) return null;

    const abbreviations = certifications.map(({ abbreviation }) => abbreviation).join(', ');

    return certifications.length
      ? `Linked to ${abbreviations} ${pluralize('certification', certifications.length)}`
      : null;
  }, []);

  const renderPersonAttachmentLogItem = useCallback(({ id, binaryUrl, createdOn, isPrivate, name, state, size, certifications }) => (
    <ProfileAttachment
      key={id}
      id={id}
      name={name}
      createdOn={createdOn}
      binaryUrl={binaryUrl}
      isPrivate={isPrivate}
      state={state}
      size={size}
      numOfLoadedAttachments={personAttachmentItems.length}
      hasMore={hasMorePersonAttachments}
      activeTab={PERSON_ATTACHMENTS_TAB_NAME}
      caption={getAttachmentCaption(certifications)}
    />
  ), [getAttachmentCaption, hasMorePersonAttachments, personAttachmentItems.length]);

  const loadMoreItems = useCallback(
    () => {
      if (hasMorePersonAttachments && selectedPerson?.id) {
        dispatch(getPersonAttachments(accountId, selectedPerson.id, offset, DEFAULT_LIST_ITEMS_LIMIT, hasCertificationsModule));
      }
    }, [hasMorePersonAttachments, selectedPerson?.id, offset, dispatch, accountId, hasCertificationsModule],
  );

  return (
    <div className={classNames('people-profile-attachments', { 'people-profile-attachments-in-modal': isOpenInModal })}>
      <ProfileUploadAttachment
        header="Add attachments"
        onUpload={onUpload}
        pending={uploadPersonAttachmentPending}
      />

      <p className="people-profile-attachments-list-title">Attachments</p>

      <ListWithLoader
        items={personAttachmentItems}
        renderItems={renderPersonAttachmentLogItem}
        isLoadingPage={getPersonAttachmentsPending || deletePersonAttachmentPending}
        emptyText={<p className="people-profile-attachments-empty">No attachments have been added</p>}
        loadMore={loadMoreItems}
        className={classNames({
          'people-profile-attachments-list-with-uploader': isFileUploaderVisible && !getPersonAttachmentsPending,
          'people-profile-attachments-list-with-uploader-loading': isFileUploaderVisible && getPersonAttachmentsPending,
          'people-profile-attachments-list': !isFileUploaderVisible && !getPersonAttachmentsPending,
          'people-profile-attachments-list-loading': !isFileUploaderVisible && getPersonAttachmentsPending,
        })}
      />
    </div>
  );
};

export default ProfileAttachments;
