import { call, put, takeLatest } from 'redux-saga/effects';
import {
  DASHBOARD_GET_IDLE_TIME_BREAKDOWN_BEGIN,
  DASHBOARD_GET_IDLE_TIME_BREAKDOWN_SUCCESS,
  DASHBOARD_GET_IDLE_TIME_BREAKDOWN_FAILURE,
  DASHBOARD_GET_IDLE_TIME_BREAKDOWN_DISMISS_ERROR,
} from './constants';
import graphApiClient from '../../../common/GraphAPIClient';
import { getIdleTimeBreakdownQuery } from '../queries/getIdleTimeBreakdownQuery';

export function getIdleTimeBreakdown(accountId, startDate, endDate, fieldId) {
  const { query, variables } = getIdleTimeBreakdownQuery(accountId, startDate, endDate, fieldId);
  return {
    type: DASHBOARD_GET_IDLE_TIME_BREAKDOWN_BEGIN,
    query,
    variables,
  };
}

export function dismissGetIdleTimeBreakdownError() {
  return {
    type: DASHBOARD_GET_IDLE_TIME_BREAKDOWN_DISMISS_ERROR,
  };
}

export function* doGetIdleTimeBreakdown(action) {
  const { query, variables } = action;
  let res;
  try {
    res = yield call(graphApiClient.query, { query, variables });
  } catch (err) {
    yield put({
      type: DASHBOARD_GET_IDLE_TIME_BREAKDOWN_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: DASHBOARD_GET_IDLE_TIME_BREAKDOWN_SUCCESS,
    data: res.data,
  });
}

export function* watchGetIdleTimeBreakdown() {
  yield takeLatest(DASHBOARD_GET_IDLE_TIME_BREAKDOWN_BEGIN, doGetIdleTimeBreakdown);
}

export function reducer(state, action) {
  switch (action.type) {
    case DASHBOARD_GET_IDLE_TIME_BREAKDOWN_BEGIN:
      return {
        ...state,
        getIdleTimeBreakdownPending: true,
        getIdleTimeBreakdownError: null,
      };

    case DASHBOARD_GET_IDLE_TIME_BREAKDOWN_SUCCESS:
      return {
        ...state,
        idleTimeBreakdown: action?.data?.idleTimeBreakdown?.items || [],
        getIdleTimeBreakdownPending: false,
        getIdleTimeBreakdownError: null,
      };

    case DASHBOARD_GET_IDLE_TIME_BREAKDOWN_FAILURE:
      return {
        ...state,
        getIdleTimeBreakdownPending: false,
        getIdleTimeBreakdownError: action.data.error,
      };

    case DASHBOARD_GET_IDLE_TIME_BREAKDOWN_DISMISS_ERROR:
      return {
        ...state,
        getIdleTimeBreakdownError: null,
      };

    default:
      return state;
  }
}
