import {
  EDIT_DATES_SET_EDITOR_SEGMENTS,
} from './constants';
import { setSegmentFlags } from '../utils/dateEditorUtils';

export function setEditorSegments(segments = {}) {
  return {
    type: EDIT_DATES_SET_EDITOR_SEGMENTS,
    segments,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case EDIT_DATES_SET_EDITOR_SEGMENTS: {
      const { editAction, typeUnderEdit, newStartDate, newEndDate, dateUnderEdit } = state;
      const { segments } = action;
      const flaggedSegments = setSegmentFlags(segments, editAction, typeUnderEdit, newStartDate, newEndDate, dateUnderEdit);

      return {
        ...state,
        segments: flaggedSegments,
      };
    }
    default:
      return state;
  }
}
