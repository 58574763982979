import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IconButton, Tooltip } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { validatePhases } from 'src/utils/validators';
import { isEmptyObject } from 'src/utils/miscUtils';
import { addProjectPhases, addProjectSubPhase } from './redux/actions';
import { ValidatedForm, EditControls } from '../wrapped-components';
import { DATE_INPUT_FORMAT } from '../../common/constants';
import { AddProjectSubPhaseForm } from '.';

function AddProjectPhaseForm({
  accountId,
  project,
  phase,
  subPhases,
  addProjectPhases,
  addProjectSubPhase,
  removePhaseToAdd,
  removeSubPhaseToAdd,
  phaseAlreadyAdded,
  parentName,
  refProp,
}) {
  const [phaseValues, setPhaseValues] = useState({
    [`Start date-${phase.id}`]: phase.startDate,
    [`End date-${phase.id}`]: phase.endDate,
  });
  const [subPhaseData, setSubPhaseData] = useState([]);
  const [subPhaseInputs, setSubPhaseInputs] = useState({});
  const [errors, setErrors] = useState({});

  const phaseStartDate = phaseValues[`Start date-${phase.id}`] || null;
  const phaseEndDate = phaseValues[`End date-${phase.id}`] || null;

  const minPhaseStart = project.startDate;
  const maxPhaseStart = project.endDate;
  const minPhaseEnd = phaseStartDate || project.startDate;
  const maxPhaseEnd = project.endDate;

  const phaseInputs = [
    {
      type: 'date',
      name: `Start date-${phase.id}`,
      required: true,
      minDate: minPhaseStart,
      maxDate: maxPhaseStart,
      label: 'Start date',
    },
    {
      type: 'date',
      name: `End date-${phase.id}`,
      required: true,
      minDate: minPhaseEnd,
      maxDate: maxPhaseEnd,
      label: 'End Date',
    },
  ];

  const addPhase = () => {
    let phaseCount;
    const analyticsPayload = {
      project,
      parentName,
    };
    if (phaseAlreadyAdded && subPhases.length) {
      const sub = {
        ...subPhases[0],
        startDate: phaseValues[`Start date-${subPhases[0].id}`],
        endDate: phaseValues[`End date-${subPhases[0].id}`],
      };

      phaseCount = 1;
      analyticsPayload.phaseCount = phaseCount;
      addProjectSubPhase(accountId, project.id, phase, sub, analyticsPayload);
    } else {
      const phases = [
        {
          id: phase.id,
          name: phase.name,
          startDate: phaseStartDate || phase.startDate,
          endDate: phaseEndDate || phase.endDate,
          subPhases: subPhaseData,
        },
      ];

      phaseCount = subPhaseData.length + 1;
      analyticsPayload.phaseCount = phaseCount;
      addProjectPhases(accountId, project.id, phases, analyticsPayload);
    }

    removePhaseToAdd();
  };

  const onValueChanged = (name, values) => {
    const value = values[name] ? moment(values[name]).format(DATE_INPUT_FORMAT) : null;

    setPhaseValues({
      ...phaseValues,
      [name]: value,
    });
  };

  const validateDates = useCallback(() => {
    const phases = [
      {
        ...phase,
        subPhases,
      },
    ];

    const projectDates = {
      'Start date': project.startDate,
      'End date': project.endDate,
    };

    const newErrors = validatePhases(phases, phaseValues, projectDates);
    setErrors(newErrors);
    return newErrors;
  }, [project, phase, subPhases, phaseValues]);

  useEffect(() => {
    validateDates(null);
  }, [project, phaseStartDate, phaseEndDate, validateDates]);

  useEffect(() => {
    const subPhaseData = [];

    subPhases.forEach((sp) => {
      const subStartDate = phaseValues[`Start date-${sp.id}`];
      const subEndDate = phaseValues[`End date-${sp.id}`];
      if (!subStartDate || !subEndDate) return;
      subPhaseData.push({
        ...sp,
        startDate: moment(subStartDate).format(DATE_INPUT_FORMAT),
        endDate: moment(subEndDate).format(DATE_INPUT_FORMAT),
      });
    });

    let inputs = {};
    subPhases.forEach((sub) => {
      const minSubPhaseStart = phaseStartDate || phase.startDate || project.startDate;
      const maxSubPhaseStart = phaseValues[`End date-${sub.id}`] || phase.endDate || phaseEndDate || project.endDate;
      const minSubPhaseEnd = phaseValues[`Start date-${sub.id}`] || phase.startDate || phaseStartDate || project.startDate;
      const maxSubPhaseEnd = phaseEndDate || phase.endDate || project.endDate;

      inputs = {
        ...inputs,
        [sub.id]: [
          {
            type: 'date',
            name: sub && `Start date-${sub.id}`,
            required: true,
            minDate: minSubPhaseStart,
            maxDate: maxSubPhaseStart,
            label: 'Start date',
          },
          {
            type: 'date',
            name: sub && `End date-${sub.id}`,
            required: true,
            minDate: minSubPhaseEnd,
            maxDate: maxSubPhaseEnd,
            label: 'End date',
          },
        ],
      };
    });
    setSubPhaseData(subPhaseData);
    setSubPhaseInputs(inputs);
  }, [phaseValues, subPhases, phase, phaseStartDate, phaseEndDate, project]);

  const isDisabled = () => {
    const phaseDatesUnfilled = !phaseStartDate || !phaseEndDate;
    const subPhaseDatesUnfilled = subPhases.some(sp => (
      !phaseValues[`Start date-${sp.id}`] || !phaseValues[`End date-${sp.id}`]
    ));
    const disabled = phaseAlreadyAdded
      ? subPhaseDatesUnfilled || !isEmptyObject(errors)
      : phaseDatesUnfilled || subPhaseDatesUnfilled || !isEmptyObject(errors);
    return disabled;
  };

  return (
    <>
      { !phaseAlreadyAdded && (
        <div ref={refProp} className="projects-add-project-phase-form phase-row">
          <div className="table-cell phase-name">{phase.name}</div>
          <div className="table-cell">
            <ValidatedForm
              inputs={phaseInputs}
              validate={validateDates}
              onValueChanged={onValueChanged}
              errors={errors}
            />
          </div>
          <div className="table-cell delete-phase">
            <Tooltip title="Remove phase" placement="top">
              <IconButton onClick={removePhaseToAdd}>
                <Close fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}
      {subPhases.map(sub => (
        <AddProjectSubPhaseForm
          key={sub.id}
          refProp={phaseAlreadyAdded ? refProp : null}
          subPhase={sub}
          subPhaseInputs={subPhaseInputs}
          validateDates={validateDates}
          onValueChanged={onValueChanged}
          errors={errors}
          phaseAlreadyAdded={phaseAlreadyAdded}
          removePhaseToAdd={removePhaseToAdd}
          removeSubPhaseToAdd={removeSubPhaseToAdd}
        />
      ))}
      <div className="projects-add-project-phase-form form-controls">
        <EditControls
          primaryText="Save"
          primaryAction={addPhase}
          secondaryAction={removePhaseToAdd}
          disabled={isDisabled()}
        />
      </div>
    </>
  );
}

AddProjectPhaseForm.propTypes = {
  accountId: PropTypes.number.isRequired,
  project: PropTypes.object.isRequired,
  phase: PropTypes.object.isRequired,
  subPhases: PropTypes.array,
  addProjectPhases: PropTypes.func.isRequired,
  addProjectSubPhase: PropTypes.func.isRequired,
  removePhaseToAdd: PropTypes.func.isRequired,
  removeSubPhaseToAdd: PropTypes.func.isRequired,
  phaseAlreadyAdded: PropTypes.bool.isRequired,
  parentName: PropTypes.string.isRequired,
  refProp: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

AddProjectPhaseForm.defaultProps = {
  subPhases: [],
  refProp: null,
};

function mapStateToProps({ common }) {
  return {
    accountId: common.accountId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addProjectPhases: bindActionCreators(addProjectPhases, dispatch),
    addProjectSubPhase: bindActionCreators(addProjectSubPhase, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddProjectPhaseForm);
