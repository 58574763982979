import {
  REPORTS_SET_REPORT,
} from './constants';

export function setReport(showReport) {
  return {
    type: REPORTS_SET_REPORT,
    showReport,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case REPORTS_SET_REPORT: {
      const { showReport } = action;
      return {
        ...state,
        showReport,
      };
    }

    default:
      return state;
  }
}
