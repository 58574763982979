import React, { useCallback, useMemo, useState } from 'react';
import { PopperButton, SearchList } from '@bridgit/foundation';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { PERM_ACCOUNT, PERM_WRITE } from '../permissions/utils/constants';
import { isAuthorized } from '../permissions/utils/permissionUtils';
import { naturalSort } from '../../utils/sortUtils';
import { aggregateRoleTemplateList } from './utils/projectUtils';
import { trackAnalytics } from '../common/redux/actions';
import {
  PROJECT_ROLE_TEMPLATE_BUTTON_CLICKED,
  PROJECT_ADD_ROLES_TEMPLATE_USED,
} from '../../analytics/projects/constants';

const popperModifiers = {
  preventOverflow: {
    enabled: false,
  },
  flip: {
    enabled: false,
  },
};

export const RoleTemplateSelector = ({
  onSave,
  selectedProject,
}) => {
  const dispatch = useDispatch();

  const { accountId } = useSelector(({ common }) => common);
  const { userInfo } = useSelector(({ login }) => login);
  const { roleTemplates } = useSelector(({ accounts }) => accounts);

  const [aggregatedRoleList, setAggregatedRoleList] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const mappedRoleTemplates = useMemo(
    () => roleTemplates.map(roleTemplate => ({ ...roleTemplate, value: roleTemplate.name })),
    [roleTemplates],
  );

  const isAuth = useMemo(() => isAuthorized(accountId, userInfo.permissions, PERM_WRITE, PERM_ACCOUNT), [accountId, userInfo]);
  const isDisabled = useMemo(() => !roleTemplates?.length && !isAuth, [isAuth, roleTemplates?.length]);

  const onSelectItem = useCallback(({ data }) => setAggregatedRoleList(aggregateRoleTemplateList(data)), []);
  const onCancel = useCallback(() => {
    setAggregatedRoleList('');
    setIsOpen(false);
  }, []);

  const onSaveHandler = useCallback((selectedRoleTemplates) => {
    const transformedRoles = [];
    selectedRoleTemplates.forEach(({ data }) => {
      data.forEach(({ quantity, roleName, percent }) => {
        for (let i = 0; i < quantity; i += 1) {
          transformedRoles.push({
            name: roleName,
            allocatedPercent: percent,
          });
        }
      });
    });

    // Use the first element for tracking since the SelectList component returns an array of selections
    const selectedTemplate = selectedRoleTemplates[0];

    const analyticsPayload = {
      'Project id': selectedProject.id,
      'Project name': selectedProject.name,
      'Role template id': selectedTemplate.id,
      'Role template name': selectedTemplate.name,
      'Count of roles added': transformedRoles.length,
    };

    dispatch(trackAnalytics(PROJECT_ADD_ROLES_TEMPLATE_USED, analyticsPayload));
    onSave(naturalSort(transformedRoles, 'name'));
    setIsOpen(false);
  }, [onSave, selectedProject, dispatch]);

  const onAddTemplateButtonClick = useCallback(() => {
    setIsOpen(true);

    const analyticsPayload = {
      'Templates exist': !!roleTemplates?.length,
    };

    dispatch(trackAnalytics(PROJECT_ROLE_TEMPLATE_BUTTON_CLICKED, analyticsPayload));
  }, [roleTemplates, dispatch]);

  const popperBody = useMemo(() => {
    if (!roleTemplates?.length && isAuth) {
      return (
        <div className="no-templates-popper">
          <p className="no-templates-text">No role templates have been set up for your account.</p>
          <a className="no-templates-link" href={`/accounts/${accountId}/settings/projects/roles`}>Create a role template</a>
        </div>
      );
    }

    return (
      <div className="search-list-popper">
        <SearchList
          filterKey="name"
          items={mappedRoleTemplates}
          showSelectAll={false}
          placeholder="Search"
          showSearch
          singleFilter
          primaryActionText="Save"
          onSelectItem={onSelectItem}
          secondaryListText={aggregatedRoleList}
          onCancel={onCancel}
          onApply={onSaveHandler}
        />
      </div>
    );
  }, [roleTemplates.length, isAuth, mappedRoleTemplates, onSelectItem, aggregatedRoleList, onCancel, onSaveHandler, accountId]);

  return (
    <div className="projects-role-template-selector">
      <PopperButton
        buttonText="Use template"
        buttonVariant="outlined"
        classes="role-template-selector-popper"
        onClick={onAddTemplateButtonClick}
        isDisabled={isDisabled}
        tooltipText="No role templates exist for your account. Administrators can create role templates in the Settings area."
        isOpen={isOpen}
        popperModifiers={popperModifiers}
      >
        {popperBody}
      </PopperButton>
    </div>
  );
};

RoleTemplateSelector.propTypes = {
  onSave: PropTypes.func.isRequired,
  selectedProject: PropTypes.object.isRequired,
};

export default RoleTemplateSelector;
