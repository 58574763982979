import React from 'react';
import PropTypes from 'prop-types';
import error from 'src/images/error-page/error.svg?url';
import { BENCH_SUPPORT_URL } from './redux/constants';

const ErrorPage = (props) => {
  const { mainText, subText, image } = props;

  let errorImage = image;
  if (!image) {
    errorImage = <img src={error} alt="Oops" />;
  }

  return (
    <div className="errors-error-page">
      <div className="image">
        {errorImage}
      </div>
      <div className="text">
        <div className="main-text">{mainText}</div>
        <div className="sub-text">{subText}</div>
      </div>
    </div>
  );
};

ErrorPage.propTypes = {
  mainText: PropTypes.node,
  subText: PropTypes.node,
  image: PropTypes.node,
};

ErrorPage.defaultProps = {
  mainText: <div>Sorry, an unexpected error occurred.</div>,
  subText: (
    <div>
      Please try refreshing the page, or
      {' '}
      <a href={BENCH_SUPPORT_URL} target="_blank" rel="noopener noreferrer">contact support</a>
      .
    </div>
  ),
  image: null,
};

export default ErrorPage;
