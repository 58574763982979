import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import moment from 'moment';
import { DATE_INPUT_FORMAT, DATE_DISPLAY_FORMAT } from '../../common/constants';
import { FullTimeline } from '.';

function ProjectResourceTab({ selectedProject }) {
  const startDate = moment(selectedProject.startDate, DATE_INPUT_FORMAT).format(DATE_DISPLAY_FORMAT);
  const endDate = moment(selectedProject.endDate, DATE_INPUT_FORMAT).format(DATE_DISPLAY_FORMAT);

  return (
    <div className="projects-project-resource-tab">
      <span className="section-heading">Project overview</span>
      <Table className="project-overview-table">
        <TableHead>
          <TableRow>
            <TableCell>Total roles</TableCell>
            <TableCell>Unfilled roles</TableCell>
            <TableCell>Project start date</TableCell>
            <TableCell />
            <TableCell>Project end date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell scope="row">{selectedProject.totalRoles}</TableCell>
            <TableCell>{selectedProject.unfilledRoles}</TableCell>
            <TableCell>{startDate}</TableCell>
            <TableCell><FullTimeline selectedProject={selectedProject} /></TableCell>
            <TableCell>{endDate}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}

ProjectResourceTab.propTypes = {
  selectedProject: PropTypes.object.isRequired,
};

export default ProjectResourceTab;
