import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PROJECTS_DELETE_PROJECT_SUB_PHASE_BEGIN,
  PROJECTS_DELETE_PROJECT_SUB_PHASE_SUCCESS,
  PROJECTS_DELETE_PROJECT_SUB_PHASE_FAILURE,
  PROJECTS_DELETE_PROJECT_SUB_PHASE_DISMISS_ERROR,
} from './constants';
import { protectedJsonDelete } from '../../../utils/api';
import { removeProjectSubPhase } from '../utils/projectUtils';

export function deleteProjectSubPhase(accountId, projectId, phase, subPhase, analyticsPayload = { }) {
  return {
    type: PROJECTS_DELETE_PROJECT_SUB_PHASE_BEGIN,
    accountId,
    projectId,
    phase,
    subPhase,
    analyticsPayload,
  };
}

export function dismissDeleteProjectSubPhaseError() {
  return {
    type: PROJECTS_DELETE_PROJECT_SUB_PHASE_DISMISS_ERROR,
  };
}

export function* doDeleteProjectSubPhase(action) {
  const { accountId, projectId, phase, subPhase, analyticsPayload } = action;
  let res;
  try {
    res = yield call(protectedJsonDelete, `accounts/${accountId}/projects/${projectId}/phases/${phase.id}/subphases/${subPhase.id}`);
  } catch (err) {
    yield put({
      type: PROJECTS_DELETE_PROJECT_SUB_PHASE_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PROJECTS_DELETE_PROJECT_SUB_PHASE_SUCCESS,
    data: res,
    projectId,
    phase,
    subPhase,
    analyticsPayload,
  });
}

export function* watchDeleteProjectSubPhase() {
  yield takeLatest(PROJECTS_DELETE_PROJECT_SUB_PHASE_BEGIN, doDeleteProjectSubPhase);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case PROJECTS_DELETE_PROJECT_SUB_PHASE_BEGIN:
      return {
        ...state,
        deleteProjectSubPhasePending: true,
        deleteProjectSubPhaseError: null,
      };

    case PROJECTS_DELETE_PROJECT_SUB_PHASE_SUCCESS: {
      const { projectId, phase, subPhase } = action;
      const { filteredProjects, projectSelections } = state;

      const newFilteredProjects = filteredProjects.map((project) => {
        if (project.id === projectId) {
          return removeProjectSubPhase(project, phase, subPhase);
        }
        return project;
      });

      const newProjectSelections = { ...projectSelections };
      Object.keys(newProjectSelections).forEach((selectionId) => {
        const selectedProject = newProjectSelections[selectionId];

        if (selectedProject?.id === projectId) {
          const updatedProject = removeProjectSubPhase(selectedProject, phase, subPhase);
          newProjectSelections[selectionId] = {
            ...updatedProject,
          };
        }
      });

      return {
        ...state,
        filteredProjects: newFilteredProjects,
        deleteProjectSubPhasePending: false,
        deleteProjectSubPhaseError: null,
        projectSelections: newProjectSelections,
      };
    }

    case PROJECTS_DELETE_PROJECT_SUB_PHASE_FAILURE:
      return {
        ...state,
        deleteProjectSubPhasePending: false,
        deleteProjectSubPhaseError: action.data.error,
      };

    case PROJECTS_DELETE_PROJECT_SUB_PHASE_DISMISS_ERROR:
      return {
        ...state,
        deleteProjectSubPhaseError: null,
      };

    default:
      return state;
  }
}
