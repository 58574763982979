import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  KeyboardDatePicker as MaterialKeyboardDatePicker,
  DatePicker as MaterialDatePicker,
} from '@material-ui/pickers';
import { MuiThemeProvider } from '@material-ui/core/styles';
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Event,
} from '@material-ui/icons';
import { b500bridgitBlue, g200cementGrey } from '@bridgit/foundation';
import { DATE_INPUT_FORMAT, MIN_CONFIGURABLE_DATE, MAX_CONFIGURABLE_DATE, MIN_CONFIGURABLE_DATE_MESSAGE } from '../../common/constants';
import { mergeDeep } from '../../utils/miscUtils';

export default class DatePicker extends Component {
  static propTypes = {
    className: PropTypes.string,
    date: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    disabled: PropTypes.bool,
    initialFocusedDate: PropTypes.object,
    keyboard: PropTypes.bool,
    minDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    maxDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    minDateMessage: PropTypes.string,
    maxDateMessage: PropTypes.string,
    placeholder: PropTypes.string,
    shouldDisableDate: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onError: PropTypes.func,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    openTo: PropTypes.string,
    outerTheme: PropTypes.object,
    editing: PropTypes.bool,
    format: PropTypes.string,
    variant: PropTypes.string,
    views: PropTypes.arrayOf(PropTypes.oneOf(['year', 'date', 'month'])),
    id: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    date: null,
    disabled: false,
    placeholder: 'MM/DD/YYYY',
    initialFocusedDate: null,
    keyboard: true,
    minDate: moment(MIN_CONFIGURABLE_DATE),
    maxDate: moment(MAX_CONFIGURABLE_DATE),
    minDateMessage: MIN_CONFIGURABLE_DATE_MESSAGE,
    maxDateMessage: '',
    shouldDisableDate: () => {},
    onError: () => {},
    onOpen: () => {},
    onClose: () => {},
    onBlur: () => {},
    onFocus: () => {},
    openTo: undefined,
    outerTheme: {},
    editing: false,
    format: 'MM/DD/YYYY',
    variant: 'dialog',
    views: undefined,
    id: '',
  };

  getMuiTheme = (outerTheme) => {
    const { editing } = this.props;
    return mergeDeep({
      overrides: {
        MuiInput: {
          root: {
            borderBottom: editing ? `1px solid ${b500bridgitBlue}` : `1px solid ${g200cementGrey}`,
          },
          error: {
            '&::after': {
              borderBottom: 'none',
            },
          },
          underline: {
            '&::after': {
              border: `1px solid ${b500bridgitBlue}`,
            },
          },
        },
        MuiDialog: {
          root: {
            zIndex: '1450 !important',
          },
        },
        MuiDialogActions: {
          root: {
            display: 'none',
          },
        },
      },
    }, outerTheme);
  }

  render() {
    const {
      className,
      date,
      disabled,
      initialFocusedDate,
      keyboard,
      minDate,
      maxDate,
      minDateMessage,
      maxDateMessage,
      placeholder,
      shouldDisableDate,
      onChange,
      onError,
      onOpen,
      onClose,
      onBlur,
      onFocus,
      openTo,
      outerTheme,
      format,
      variant,
      views,
      id,
    } = this.props;

    const commonProps = {
      value: date ? moment(date, DATE_INPUT_FORMAT) : null,
      FormHelperTextProps: {
        classes: {
          error: 'mui-form-helper-text',
        },
      },
      InputProps: {
        classes: {
          error: 'input-error',
        },
      },
      onChange,
      className: `common-date-picker ${className}`,
      format,
      placeholder,
      disabled,
      initialFocusedDate,
      minDate,
      minDateMessage,
      maxDate,
      maxDateMessage,
      invalidDateMessage: 'Invalid Date Format',
      shouldDisableDate,
      onError,
      onOpen,
      onClose,
      onBlur,
      onFocus,
      openTo,
      autoOk: true,
      leftArrowIcon: <KeyboardArrowLeft />,
      rightArrowIcon: <KeyboardArrowRight />,
      variant,
      views,
      id,
    };
    return (
      <MuiThemeProvider theme={this.getMuiTheme(outerTheme)}>
        { keyboard ? (
          <MaterialKeyboardDatePicker
            {...commonProps} // eslint-disable-line react/jsx-props-no-spreading
            keyboardIcon={<Event className="date-picker-icon" />}
          />
        ) : (
          <MaterialDatePicker
            {...commonProps} // eslint-disable-line react/jsx-props-no-spreading
          />
        )}
      </MuiThemeProvider>
    );
  }
}
