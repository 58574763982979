import { call, put, takeLatest } from 'redux-saga/effects';
import { protectedJsonPost, uploadLogo } from '../../../utils/api';
import {
  ACCOUNTS_CREATE_NEW_ACCOUNT_BEGIN,
  ACCOUNTS_CREATE_NEW_ACCOUNT_DISMISS_ERROR,
  ACCOUNTS_CREATE_NEW_ACCOUNT_FAILURE,
  ACCOUNTS_CREATE_NEW_ACCOUNT_SUCCESS,
} from './constants';

export function createNewAccount(account, logo) {
  return {
    type: ACCOUNTS_CREATE_NEW_ACCOUNT_BEGIN,
    account,
    logo,
  };
}

export function dismissCreateNewAccountError() {
  return {
    type: ACCOUNTS_CREATE_NEW_ACCOUNT_DISMISS_ERROR,
  };
}

export function* doCreateNewAccount(action) {
  const { account, logo } = action;
  let accountResult;
  let logoResult;

  try {
    accountResult = yield call(protectedJsonPost, 'accounts', account);
  } catch (err) {
    yield put({
      type: ACCOUNTS_CREATE_NEW_ACCOUNT_FAILURE,
      data: { error: err },
    });
    return;
  }

  if (logo) {
    try {
      logoResult = yield call(uploadLogo, `accounts/${accountResult.id}/logos`, logo);
      accountResult = { ...accountResult, logoUrl: logoResult };
    } catch (err) {
      yield put({
        type: ACCOUNTS_CREATE_NEW_ACCOUNT_FAILURE,
        data: { error: err },
      });
      return;
    }
  }

  yield put({
    type: ACCOUNTS_CREATE_NEW_ACCOUNT_SUCCESS,
    data: accountResult,
  });
}

export function* watchCreateNewAccount() {
  yield takeLatest(ACCOUNTS_CREATE_NEW_ACCOUNT_BEGIN, doCreateNewAccount);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNTS_CREATE_NEW_ACCOUNT_BEGIN:
      return {
        ...state,
        createNewAccountPending: true,
        createNewAccountError: null,
      };

    case ACCOUNTS_CREATE_NEW_ACCOUNT_SUCCESS: {
      const entities = [...state.entities, action.data];

      return {
        ...state,
        entities,
        createNewAccountPending: false,
        createNewAccountError: null,
      };
    }

    case ACCOUNTS_CREATE_NEW_ACCOUNT_FAILURE:
      return {
        ...state,
        createNewAccountPending: false,
        createNewAccountError: action.data.error,
      };

    case ACCOUNTS_CREATE_NEW_ACCOUNT_DISMISS_ERROR:
      return {
        ...state,
        createNewAccountError: null,
      };

    default:
      return state;
  }
}
