import 'url-polyfill';
import { MuiThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { theme } from '@bridgit/foundation';
import configStore from './common/configStore';
import routeConfig from './common/routeConfig';
import { initMixpanel, initRollbar } from './common/analyticsHelper';
import Root from './Root';
import { isSupportedBrowser, getMobileOS } from './utils/browser-utils';
import UnsupportedBrowser from './features/common/UnsupportedBrowser';
import UnsupportedMobileBrowser from './features/common/UnsupportedMobileBrowser';

const store = configStore();

window.mixpanel = require('mixpanel-browser');

initMixpanel();
initRollbar();

function renderApp(app) {
  render(
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          {app}
        </Provider>
      </MuiThemeProvider>
    </MuiPickersUtilsProvider>,
    document.getElementById('root'),
  );
}

const appRoot = (
  <div id="root-desktop">
    <Root routeConfig={routeConfig} />
  </div>
);

let app;
const onMobileBrowser = getMobileOS();
if (onMobileBrowser) {
  app = <UnsupportedMobileBrowser os={onMobileBrowser} />;
} else if (!isSupportedBrowser()) {
  app = <UnsupportedBrowser />;
} else {
  app = appRoot;
}

renderApp(app);

// Hot Module Replacement API
/* istanbul ignore if  */
if (module.hot) {
  module.hot.accept('./common/routeConfig', () => {
    const nextRouteConfig = require('./common/routeConfig').default; // eslint-disable-line
    renderApp(<Root routeConfig={nextRouteConfig} />);
  });
  module.hot.accept('./Root', () => {
    const nextRoot = require('./Root').default; // eslint-disable-line
    renderApp(<Root routeConfig={routeConfig} />);
  });
}
