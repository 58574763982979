import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNT_SETTINGS_ADD_PERSON_FIELDS_BEGIN,
  ACCOUNT_SETTINGS_ADD_PERSON_FIELDS_SUCCESS,
  ACCOUNT_SETTINGS_ADD_PERSON_FIELDS_FAILURE,
  ACCOUNT_SETTINGS_ADD_PERSON_FIELDS_DISMISS_ERROR,
} from './constants';
import { protectedJsonPost } from '../../../utils/api';

export function addPersonFields(accountId, data) {
  return {
    type: ACCOUNT_SETTINGS_ADD_PERSON_FIELDS_BEGIN,
    accountId,
    data,
  };
}

export function dismissAddPersonFieldsError() {
  return {
    type: ACCOUNT_SETTINGS_ADD_PERSON_FIELDS_DISMISS_ERROR,
  };
}

export function* doAddPersonFields(action) {
  const { accountId, data } = action;
  let res;
  try {
    res = yield call(protectedJsonPost, `accounts/${accountId}/person-fields`, data);
  } catch (err) {
    yield put({
      type: ACCOUNT_SETTINGS_ADD_PERSON_FIELDS_FAILURE,
      data: { error: err },
    });
    return;
  }
  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: ACCOUNT_SETTINGS_ADD_PERSON_FIELDS_SUCCESS,
    data: res,
  });
}

export function* watchAddPersonFields() {
  yield takeLatest(ACCOUNT_SETTINGS_ADD_PERSON_FIELDS_BEGIN, doAddPersonFields);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNT_SETTINGS_ADD_PERSON_FIELDS_BEGIN:
      return {
        ...state,
        addPersonFieldsPending: true,
        addPersonFieldsError: null,
      };

    case ACCOUNT_SETTINGS_ADD_PERSON_FIELDS_SUCCESS: {
      const newPersonFieldList = [...state.personFields, ...action.data];

      return {
        ...state,
        personFields: newPersonFieldList,
        addPersonFieldsPending: false,
        addPersonFieldsError: null,
      };
    }

    case ACCOUNT_SETTINGS_ADD_PERSON_FIELDS_FAILURE:
      return {
        ...state,
        addPersonFieldsPending: false,
        addPersonFieldsError: action.data.error,
      };

    case ACCOUNT_SETTINGS_ADD_PERSON_FIELDS_DISMISS_ERROR:
      return {
        ...state,
        addPersonFieldsError: null,
      };

    default:
      return state;
  }
}
