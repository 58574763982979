import moment from 'moment';
import { DATE_INPUT_FORMAT } from 'src/common/constants';

export function getDateRangeQuery(selected, verb) {
  const date = moment.utc(selected.date);
  const endDate = moment.utc(selected.endDate);

  switch (verb) {
    case 'after':
      return {
        gte: date.format(DATE_INPUT_FORMAT),
      };
    case 'before':
      return {
        lte: date.format(DATE_INPUT_FORMAT),
      };
    case 'between':
      return {
        gte: date.format(DATE_INPUT_FORMAT),
        lte: endDate.format(DATE_INPUT_FORMAT),
      };
    case 'on':
      return {
        gte: date.format(DATE_INPUT_FORMAT),
        lte: date.format(DATE_INPUT_FORMAT),
      };
    default:
      return {};
  }
}
