import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tab, Tabs } from '@material-ui/core';
import { isAuthorized, hasModuleEnabled } from '../permissions/utils/permissionUtils';

const classes = {
  tabs: {
    root: 'wrapped-components-permission-tabs',
  },
  tab: {
    root: 'tab',
  },
};

function PermissionTabs({
  accountId,
  className,
  tabs,
  value,
  onChange,
  permissions,
  accountModules,
  TabIndicatorProps,
  inclusive,
}) {
  const canTabs = useMemo(() => tabs.filter((tab) => {
    const accountPerm = tab.checkBridgitPerms ? '*' : accountId;
    const isAuthorizedTab = isAuthorized(accountPerm, permissions, tab.action, tab.subject, inclusive);
    const hasModuleForTab = hasModuleEnabled(accountModules, tab.module);
    return isAuthorizedTab && hasModuleForTab;
  }), [tabs, accountId, permissions, inclusive, accountModules]);

  const permissionedTabs = useMemo(() => canTabs.map((tab, index) => (
    <Tab
      key={index} // eslint-disable-line react/no-array-index-key
      value={tab.value}
      label={tab.label}
      disableRipple
      classes={classes.tab}
    />
  )), [canTabs]);

  // If no tabs to display return null
  if (!canTabs.length) return null;

  // Protect against value not being in the tabs list after permissions checks happen
  const tabExists = !!canTabs.find(({ value: tabValue }) => tabValue === value);

  return (
    <Tabs
      className={className}
      value={tabExists ? value : canTabs[0]?.value}
      onChange={onChange}
      component="nav"
      classes={classes.tabs}
      TabIndicatorProps={TabIndicatorProps}
      indicatorColor="primary"
      textColor="primary"
    >
      {permissionedTabs}
    </Tabs>
  );
}

PermissionTabs.propTypes = {
  accountId: PropTypes.number.isRequired,
  className: PropTypes.string,
  tabs: PropTypes.array.isRequired,
  permissions: PropTypes.object,
  accountModules: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  TabIndicatorProps: PropTypes.object,
  inclusive: PropTypes.bool,
};

PermissionTabs.defaultProps = {
  className: '',
  permissions: null,
  TabIndicatorProps: {},
  inclusive: false,
};


/* istanbul ignore next */
function mapStateToProps({ common, login, accountSettings }) {
  const { accountId } = common;
  const { permissions } = login.userInfo;
  const { accountModules } = accountSettings;
  return {
    accountId,
    permissions,
    accountModules,
  };
}

export default connect(
  mapStateToProps,
)(PermissionTabs);
