import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RadioButtonForm } from '@bridgit/foundation';

import { ACCOUNT_MODULE_LOCATION } from '../../common/constants';
import { DISTANCE_UNITS } from '../accounts/common/constants';
import { MODULE_NAME_TRANSLATION } from './common/constants';
import { Can } from '../wrapped-components';
import { updateAccount } from '../accounts/redux/actions';
import { capitalize } from '../../utils/formatters';

const { KM, MI } = DISTANCE_UNITS;

const controls = [KM, MI].map(({ value, label }) => ({ value, label: capitalize(label) }));

const LocationUnitSelect = () => {
  const dispatch = useDispatch();

  const { accountId } = useSelector(({ common }) => common);
  const {
    distanceUnit,
    updateAccountPending,
  } = useSelector(({ accounts }) => {
    const { entities, updateAccountPending } = accounts;

    return {
      distanceUnit: entities.find(({ id }) => id === accountId)?.useMetricUnits ? KM.value : MI.value,
      updateAccountPending,
    };
  });

  const onChangeHandler = useCallback((value) => {
    const data = {
      id: accountId,
      useMetricUnits: value === KM.value,
    };

    dispatch(updateAccount(data));
  }, [accountId, dispatch]);

  const content = useMemo(() => (
    <div className="account-settings-module-management">
      <RadioButtonForm
        title={MODULE_NAME_TRANSLATION[ACCOUNT_MODULE_LOCATION]}
        controls={controls}
        value={distanceUnit}
        onChange={onChangeHandler}
        isDisabled={updateAccountPending}
      />
    </div>
  ), [onChangeHandler, distanceUnit, updateAccountPending]);

  return (
    <Can
      module={ACCOUNT_MODULE_LOCATION}
      yes={content}
    />
  );
};

export default LocationUnitSelect;
