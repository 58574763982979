import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { w000pureWhite } from '@bridgit/foundation';

const BaseThemeSlider = withStyles({
  root: {
    height: 10,
    padding: '8px 0 2px',
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: w000pureWhite,
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 10,
    borderRadius: 4,
  },
  rail: {
    height: 10,
    borderRadius: 4,
    padding: '0 2px',
  },
  disabled: {
    '& .MuiSlider-thumb': {
      marginTop: -2,
      height: 14,
      width: 14,
    },
  },
  mark: {
    top: 12,
    marginRight: 2,
  },
})(Slider);

export default BaseThemeSlider;
