import React, { PureComponent } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export class EditControls extends PureComponent {
  static propTypes = {
    primaryAction: PropTypes.func,
    secondaryAction: PropTypes.func,
    disabled: PropTypes.bool,
    secondaryDisabled: PropTypes.bool,
    primaryVariant: PropTypes.string,
    secondaryVariant: PropTypes.string,
    primaryText: PropTypes.string,
    secondaryText: PropTypes.string,
    pending: PropTypes.bool,
    showSecondary: PropTypes.bool,
    addMarginTop: PropTypes.bool,
    className: PropTypes.string,
    pendingText: PropTypes.string,
  };

  static defaultProps = {
    primaryAction: undefined,
    secondaryAction: undefined,
    primaryVariant: '',
    secondaryVariant: '',
    disabled: false,
    secondaryDisabled: false,
    primaryText: 'Save',
    secondaryText: 'Cancel',
    pending: false,
    showSecondary: true,
    addMarginTop: false,
    className: '',
    pendingText: '',
  };

  getControlButtons = () => {
    const {
      primaryAction,
      secondaryAction,
      primaryVariant,
      secondaryVariant,
      disabled,
      secondaryDisabled,
      primaryText,
      secondaryText,
      pending,
      showSecondary,
      addMarginTop,
    } = this.props;

    const defaultSecondaryProps =
      secondaryVariant === 'contained'
        ? {
          disableRipple: true,
          variant: 'contained',
        }
        : {};

    const defaultPrimaryProps =
      primaryVariant === 'contained'
        ? {
          disableRipple: true,
          type: 'submit',
          variant: 'contained',
        }
        : {};

    const secondaryProps = {
      className: 'button__default',
      color: 'default',
      onClick: secondaryAction,
      disabled: secondaryDisabled || pending,
      ...defaultSecondaryProps,
    };

    const primaryProps = {
      color: 'primary',
      onClick: primaryAction,
      disabled: disabled || pending,
      ...defaultPrimaryProps,
    };

    const spinnerClass = pending ? 'spinner' : 'hidden';
    const buttonClass = pending ? 'button-hidden' : '';
    const secondaryButtonClass = showSecondary ? buttonClass : 'secondary-button-hidden';
    const buttonContainerClass = `button-container ${addMarginTop ? ' margin-top' : ''}`;

    /* eslint-disable react/jsx-props-no-spreading */
    return (
      <div className={buttonContainerClass}>
        <div className={secondaryButtonClass}><Button {...secondaryProps}>{secondaryText}</Button></div>
        <div className={buttonClass}><Button {...primaryProps}>{primaryText}</Button></div>
        <div className={spinnerClass}>
          <CircularProgress size={25} color="primary" />
        </div>
      </div>
    );
    /* eslint-enable react/jsx-props-no-spreading */
  };

  render() {
    const { className, pending, pendingText } = this.props;
    const showPending = pending && !!pendingText?.length;

    const buttons = this.getControlButtons();
    return (
      <div className={classNames('wrapped-components-edit-controls', className)}>
        { showPending && (<div className="pending-text">{pendingText}</div>) }
        {buttons}
      </div>
    );
  }
}

export default EditControls;
