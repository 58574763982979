import React from 'react';
import moment from 'moment';
import {
  Avatar,
  Typography,
  Chip,
} from '@material-ui/core';
import { Error } from '@material-ui/icons';
import classNames from 'classnames';
import { ProjectLink } from '@bridgit/foundation';
import { hourlyPersonDescriptor } from 'src/common/descriptors/hourlyPerson';
import emptyAvatar from 'src/images/empty_avatar.svg?url';
import { naturalSort } from 'src/utils/sortUtils';
import { DATE_DISPLAY_FORMAT } from 'src/common/constants';
import {
  FILTER_AVAILABILITIES,
  NO_AVAILABILITY,
  BLANK_FILTER,
  NEXT_ALLOCATIONS,
  NEXT_ALLOCATIONS_START_DATE,
  CERTIFICATIONS,
} from 'src/filters/constants';
import { CANCELED } from 'src/features/projects/constants';
import { hasModuleEnabled, isAuthorized } from 'src/features/permissions/utils/permissionUtils';
import { formatTableColumn } from 'src/features/common/listUtils';
import { getFilterOptions } from 'src/features/table/filterUtils';
import { ASSIGNMENT_TYPE_TO_DATE_TYPE_MAP, ASSIGNMENT_TYPES } from 'src/features/self-perform/constants';
import { PersonDescriptor } from 'src/common/descriptors/person';
import { CertificationTags } from 'src/features/people';
import { LoadableMomentDate } from '../../common';
import { PEOPLE_DISPLAY_EMPLOYEE_ID } from '../../people/constants';

/*
  Generates a list of columns and their properties around filtering among other things
  This could return columns that have the filter: false property
*/
const generatePeopleFilters = (personFields, projectList, accountModules, accountId, permissions) => {
  const fields = naturalSort(personFields.filter(f => !f.isSystem || f.type === 'Address'), 'name');

  const plusMoreCustomBodyRender = (value) => {
    const valueArray = value ? value.split(';') : [];
    const numMore = valueArray.length - 1;

    return (
      <div>
        <div>{valueArray[0]}</div>
        {numMore > 0 && <div className="subtext">{`+${numMore} more`}</div>}
      </div>
    );
  };

  const columnOptions = {
    photoUrl: {
      customBodyRender: (value) => {
        if (value) {
          return <Avatar className="avatar compact" src={value} />;
        }
        return <Avatar className="avatar compact" src={emptyAvatar} />;
      },
      columnClass: 'photo-compact',
    },
    nextAvailability: {
      customBodyRender: (value) => {
        const { availableInDays, percentAvailable, timeOffLabel, availabilityUntil } = value;

        if (availableInDays === NO_AVAILABILITY) {
          return <span>No availability</span>;
        }

        return (
          <div className="next-availability">
            <div className="percentage-container">
              <p>{timeOffLabel || `${percentAvailable}%`}</p>
              { (availableInDays === 0 || !!timeOffLabel) && (
                <div className={classNames('availability-indicator', { 'time-off': !!timeOffLabel })}>
                  <div className="percentage-used" style={{ width: `${percentAvailable}%` }} />
                </div>
              )}
            </div>
            <div className="available-until">
              { availabilityUntil }
            </div>
          </div>
        );
      },
    },
    currentProjects: {
      customBodyRender: plusMoreCustomBodyRender,
      filterOptions: projectList?.filter(project => project.state !== CANCELED),
      filterEmptyOption: true,
    },
    currentAllocations: {
      customBodyRender: plusMoreCustomBodyRender,
    },
    [NEXT_ALLOCATIONS]: {
      customBodyRender: plusMoreCustomBodyRender,
    },
    [NEXT_ALLOCATIONS_START_DATE]: {
      customBodyRender: plusMoreCustomBodyRender,
    },
    pursuitProjects: {
      customBodyRender: plusMoreCustomBodyRender,
    },
    hasConflict: {
      customBodyRender: value => (value ? <div className="issues"><Error className="issues-icon" /></div> : <span />),
    },
    [CERTIFICATIONS]: {
      customBodyRender: value => value && <CertificationTags certifications={value} />,
    },
  };

  const columns = PersonDescriptor.reduce((visibleColumns, current) => {
    const {
      visible,
      schemaName,
      module,
      displayName,
      type,
      filterType,
      isFinancials,
      hideColumnFilter,
      sort,
      filter,
      filterOptions,
      action,
      subject,
    } = current;

    if (visible) {
      const additionalOptions = schemaName in columnOptions ? columnOptions[schemaName] : {};

      let hasPerms = true;
      if (action && subject) {
        hasPerms = isAuthorized(accountId, permissions, action, subject);
      }

      // Allow run-time options to override column visibility
      if (('visible' in additionalOptions && !additionalOptions.visible) ||
        ('module' in current && !hasModuleEnabled(accountModules, module)) ||
        !hasPerms) return visibleColumns;

      visibleColumns.push({
        name: displayName,
        schemaName,
        type,
        filterType,
        isFinancials,
        options: {
          hideColumnFilter,
          sort,
          filter,
          singleFilter: filterType === FILTER_AVAILABILITIES,
          ...(filterOptions && { filterOptions }),
          ...additionalOptions,
        },
      });
    }

    return visibleColumns;
  }, []);

  const other = personFields.find(f => f.name === 'Other');
  const employeeId = personFields.find(f => f.name === PEOPLE_DISPLAY_EMPLOYEE_ID);

  if (other) {
    columns.push({ name: other.name, isPrivate: other?.isPrivate, options: { sort: false } });
  }

  if (employeeId) {
    columns.push({ ...employeeId, options: { filter: true } });
  }

  fields.forEach((field) => {
    columns.push(formatTableColumn(field));
  });

  columns.forEach((column, index) => {
    const { options, name } = column;

    if (options && options.filter) {
      const filterOptions = getFilterOptions(name, personFields);

      if (!('filterOptions' in options)) {
        columns[index].options.filterOptions = [];
      }

      if (options.filterEmptyOption && !options.filterOptions.find(option => option.name === BLANK_FILTER)) {
        filterOptions.push({ name: BLANK_FILTER, value: BLANK_FILTER });
      }

      columns[index].options.filterOptions.push(...filterOptions);
    }
  });

  return columns;
};

const generateProjectFilters = (projectDescriptor, projectFields, accountModules, phases = [], roles = []) => {
  const fields = naturalSort(projectFields.filter(element => !(['Other'].includes(element.name))), 'name');
  const columnOptions = {
    name: {
      customBodyRender: (value, rowMeta) => value && (
        <ProjectLink
          backgroundColor={rowMeta.colour}
          projectName={value}
          projectId={rowMeta.id}
          hasHoverDelay={!rowMeta.isMap}
        />
      ),
    },
    startDate: {
      customBodyRender: value => <LoadableMomentDate date={value} />,
    },
    endDate: {
      customBodyRender: value => <LoadableMomentDate date={value} />,
    },
    currentPhases: {
      visible: phases?.length || 0,
      filterOptions: phases.map(phase => ({
        name: phase.name,
        value: phase.id,
        subOptions: phase.subPhases.map(sub => ({
          name: sub.name,
          value: sub.id,
          isSub: true,
          parentId: phase.name,
        })),
        hasSubOptions: phase.subPhases.length > 0,
      })),
      filterEmptyOption: true,
    },
    roles: {
      customBodyRender: value => (
        value?.length > 0
          ? (
            <Typography className={classNames('wrapped-text-item', {
              unfilled: value[0] !== '0',
            })}
            >
              {value}
            </Typography>
          )
          : <Chip size="small" label="None defined" component="span" className="no-roles" />
      ),
      visible: roles?.length || 0,
      filterOptions: roles.map(({ name, id }) => ({ name, value: id })),
    },
    hasIssues: {
      customBodyRender: value => (value ? <div className="issues"><Error className="issues-icon" /></div> : <span />),
    },
  };

  const columns = projectDescriptor.reduce((visibleColumns, current) => {
    if (current.visible) {
      const additionalOptions = current.schemaName in columnOptions ? columnOptions[current.schemaName] : {};

      // Allow run-time options to override column visibility
      if ('visible' in additionalOptions && !additionalOptions.visible) return visibleColumns;

      visibleColumns.push({
        name: current.displayName,
        schemaName: current.schemaName,
        type: current.type,
        filterType: current.filterType,
        options: {
          sort: current.sort,
          filter: current.filter,
          ...(current.filterOptions && { filterOptions: current.filterOptions }),
          ...additionalOptions,
          ...current.controlOptions && { controlOptions: current.controlOptions },
          ...current.isCustomSort && { isCustomSort: current.isCustomSort },
        },
      });
    }

    return visibleColumns;
  }, []);

  const other = projectFields.find(({ name }) => name === 'Other');

  if (other) {
    columns.push({ name: other.name, isPrivate: other?.isPrivate, options: { sort: false } });
  }

  fields.forEach((field) => {
    columns.push(formatTableColumn(field));
  });

  columns.forEach((column, index) => {
    const { options, name } = column;

    if (options && options.filter) {
      const filterOptions = getFilterOptions(name, projectFields);

      if (!('filterOptions' in options)) {
        columns[index].options.filterOptions = [];
      }

      if (options.filterEmptyOption && !options.filterOptions.find(option => option.name === BLANK_FILTER)) {
        filterOptions.push({ name: BLANK_FILTER, value: BLANK_FILTER });
      }

      columns[index].options.filterOptions.push(...filterOptions);
    }
  });

  return columns;
};

const getAssignmentDatesComponent = (assignments, assignmentType) => {
  if (!assignments.length) return null;

  const dateType = ASSIGNMENT_TYPE_TO_DATE_TYPE_MAP[assignmentType];
  const assignmentDate = assignments[0]?.[dateType] && moment(assignments[0]?.[dateType]).format(DATE_DISPLAY_FORMAT);
  const moreAssignmentsText = assignments.length > 1 && `+${assignments.length - 1} more`;

  return assignmentDate && (
    <>
      <span>{moment(assignments[0]?.[dateType]).format(DATE_DISPLAY_FORMAT)}</span>
      <span>{` | ${assignments[0]?.project?.name}`}</span>
      {moreAssignmentsText && <span className="more-assignments-text">{moreAssignmentsText}</span>}
    </>
  );
};

const generateHourlyPersonFilters = (hourlyPersonFields) => {
  const fields = naturalSort(hourlyPersonFields.filter(f => !f.isSystem || f.type === 'Address'), 'name');

  const columnOptions = {
    skillSet: {
      customBodyRender: (skills) => {
        if (!skills) return null;

        return <span>{skills.join(', ')}</span>;
      },
    },
    currentAssignments: {
      customBodyRender: currentAssignments => getAssignmentDatesComponent(currentAssignments, ASSIGNMENT_TYPES.CURRENT),
    },
    upcomingAssignments: {
      customBodyRender: nextAssignments => getAssignmentDatesComponent(nextAssignments, ASSIGNMENT_TYPES.NEXT),
    },
  };

  const columns = hourlyPersonDescriptor.reduce((visibleColumns, current) => {
    if (current.visible) {
      const additionalOptions = current.schemaName in columnOptions ? columnOptions[current.schemaName] : {};

      // Allow run-time options to override column visibility
      if ('visible' in additionalOptions && !additionalOptions.visible) return visibleColumns;
      visibleColumns.push({
        name: current.displayName,
        schemaName: current.schemaName,
        type: current.type,
        filterType: current.filterType,
        options: {
          sort: current.sort,
          filter: current.filter,
          ...(current.filterOptions && { filterOptions: current.filterOptions }),
          ...additionalOptions,
        },
      });
    }

    return visibleColumns;
  }, []);

  const notes = hourlyPersonFields.find(f => f.name === 'Notes');

  if (notes) {
    columns.push({ name: 'Notes', isPrivate: notes?.isPrivate, options: { sort: false } });
  }

  fields.forEach((field) => {
    // TODO: change to just columns.push(formatTableColumn(field)) when custom field filtering is set up;
    const formattedField = formatTableColumn(field);
    formattedField.options.filter = false;

    columns.push(formattedField);
  });

  columns.forEach((column, index) => {
    const { options, name } = column;

    if (options && options.filter) {
      const filterOptions = getFilterOptions(name, hourlyPersonFields);

      if (!('filterOptions' in options)) {
        columns[index].options.filterOptions = [];
      }

      if (options.filterEmptyOption && !options.filterOptions.find(option => option.name === BLANK_FILTER)) {
        filterOptions.push({ name: BLANK_FILTER, value: BLANK_FILTER });
      }

      columns[index].options.filterOptions.push(...filterOptions);
    }
  });

  return columns;
};

export {
  generatePeopleFilters,
  generateProjectFilters,
  generateHourlyPersonFilters,
};
