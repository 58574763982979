import React, { useCallback, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ConfirmationModal } from '@bridgit/foundation';
import { Info } from '@material-ui/icons';
import { FormField } from '../wrapped-components';

const SaveFilterModal = ({
  filterData,
  onCancel,
  onSave,
}) => {
  const addSavedFilterError = useSelector(({ queries: { addSavedFilterError } }) => addSavedFilterError);

  const [filterName, setFilterName] = useState('');
  const isFilterNameUnique = addSavedFilterError?.data?.errors[0]?.values[0] !== filterName;

  const handleInputChange = useCallback(evt => setFilterName(evt.currentTarget.value), []);

  const saveFilter = useCallback(() => {
    onSave(filterName);
  }, [filterName, onSave]);

  const error = useMemo(() => {
    if (filterName.length > 100) return '100 character limit reached';
    if (addSavedFilterError?.data?.status === 409 && !isFilterNameUnique) return 'Name must be unique';
    return ' ';
  }, [filterName.length, addSavedFilterError?.data?.status, isFilterNameUnique]);

  const isDisabled = error !== ' ' || !filterName.length || (addSavedFilterError && !isFilterNameUnique);

  const filterList = useMemo(() => filterData?.map((chipSet) => {
    let { activeFilters } = chipSet;
    const filterVerb = activeFilters?.[0]?.verb;
    const verbText = filterVerb ? filterVerb.toLowerCase() : 'includes';

    if (activeFilters.length && filterVerb) {
      activeFilters = activeFilters.map(filter => (filter.selected || [filter])).flat(3);
    }

    activeFilters = activeFilters.map(filter => filter.name);

    return (
      <li key={chipSet.label} className="filter">{`${chipSet.label} ${verbText}: ${activeFilters?.join(', ')}`}</li>
    );
  }), [filterData]);

  return (
    <ConfirmationModal
      open
      disabled={isDisabled}
      headline="Create saved filter"
      onCancel={onCancel}
      onConfirm={saveFilter}
      className="save-filter-modal"
      cancelText="Cancel"
      confirmText="Save"
      onKeyboardEnter={saveFilter}
      onKeyboardEscape={onCancel}
      primaryButtonClassName="save-filter-button"
    >
      <div className="save-filter-modal-contents">
        <FormField
          name="filterName"
          type="text"
          error={error}
          label="Saved filter name"
          value={filterName}
          width="full"
          onChange={handleInputChange}
          autoFocus
          maxLength={101}
          className="save-filter-input"
          placeholder="Saved filter"
          alwaysShowErrors
        />
        <div className="input-info">
          <Info color="primary" fontSize="inherit" />
          <span className="input-info-text">Tip: Give your filter a descriptive name to easily find it in the future.</span>
        </div>
        <div className="filters">
          {filterList}
        </div>
      </div>
    </ConfirmationModal>
  );
};

SaveFilterModal.propTypes = {
  filterData: PropTypes.array,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

SaveFilterModal.defaultProps = {
  filterData: [],
};

export default SaveFilterModal;
