import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import deepEqual from 'react-fast-compare';
import { Grid } from '@material-ui/core';
import { getFieldInput } from 'src/utils/miscUtils';
import { PERM_WRITE, PERM_PROJECT } from 'src/features/permissions/utils/constants';
import { getColorType } from './utils/projectUtils';
import { updateProjectFieldValue, updateProject } from './redux/actions';
import { trackAnalytics } from '../common/redux/actions';
import { FieldEditor } from '../common';
import {
  PROJECT_ADDRESS_UPDATED,
  PROJECT_COLOUR_UPDATED,
  PROJECT_VIEW_ADDRESS_ON_MAP,
  PROJECT_WIN_PERCENT_UPDATED,
} from '../../analytics/projects/constants';
import { ACCOUNT_MODULE_PURSUIT_TRACKING, FIELD_TYPE_ADDRESS, COLOR, PERCENTAGE } from '../../common/constants';
import { hasModuleEnabled } from '../permissions/utils/permissionUtils';
import { LOST, OPPORTUNITY, WIN_PERCENT_STEP, WIN_PERCENT_LABEL } from './constants';

export class ProjectDetailTab extends PureComponent {
  static propTypes = {
    project: PropTypes.object.isRequired,
    projectFields: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    selectedProjectFields: PropTypes.array.isRequired,
    updateProject: PropTypes.func.isRequired,
    updateProjectPending: PropTypes.bool.isRequired,
    projectColor: PropTypes.string.isRequired,
    accountId: PropTypes.number.isRequired,
    updateProjectFieldValuePending: PropTypes.bool.isRequired,
    updateProjectFieldValue: PropTypes.func.isRequired,
    parentName: PropTypes.string,
    accountColors: PropTypes.array,
    defaultProjectColors: PropTypes.array,
    trackAnalytics: PropTypes.func.isRequired,
    isAddressSuggestionSelected: PropTypes.bool,
    hasPursuitsModuleEnabled: PropTypes.bool.isRequired,
    winPercentageFlag: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    disabled: false,
    parentName: 'Project Details',
    accountColors: [],
    defaultProjectColors: [],
    isAddressSuggestionSelected: false,
  };

  constructor(props) {
    super(props);

    const fields = this.generateFields(
      props.selectedProjectFields,
      props.projectFields,
      props.projectColor,
      props.project.winPercent,
      props.project.type,
      props.project.state,
    );
    const inputs = this.generateInputs(fields);

    this.state = {
      fields,
      inputs,
      fieldToEdit: null,
    };
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { selectedProjectFields, updateProjectFieldValuePending, projectColor, project } = this.props;
    if ((updateProjectFieldValuePending && !nextProps.updateProjectFieldValuePending)
      || (!deepEqual(selectedProjectFields, nextProps.selectedProjectFields))
      || projectColor !== nextProps.projectColor
      || project.winPercent !== nextProps.project.winPercent
      || project.type !== nextProps.project.type
      || project.state !== nextProps.project.state
    ) {
      const fields = this.generateFields(
        nextProps.selectedProjectFields,
        nextProps.projectFields,
        nextProps.projectColor,
        nextProps.project.winPercent,
        nextProps.project.type,
        nextProps.project.state,
      );
      const inputs = this.generateInputs(fields);
      this.setState({
        fields,
        inputs,
        fieldToEdit: null,
      });
    }
  }

  generateFields = (
    selectedProjectFields,
    projectFields,
    nextProjectColor,
    nextWinPercent,
    nextProjectType,
    nextProjectState,
  ) => {
    const { projectColor, project, hasPursuitsModuleEnabled, winPercentageFlag } = this.props;
    const detailFields = projectFields.filter(({ name }) => name !== 'Other');
    const fields = detailFields.map((defaultField) => {
      const populatedField = selectedProjectFields.find(f => f.fieldId === defaultField.id) || {};
      let value;
      if (populatedField.values) {
        if (defaultField.type === 'MultiSelect') {
          value = populatedField.values;
        } else {
          [value] = populatedField.values;
        }
      }
      return {
        ...defaultField,
        ...populatedField,
        value,
      };
    });

    if (hasPursuitsModuleEnabled
      && winPercentageFlag
      && nextProjectType === OPPORTUNITY
      && nextProjectState !== LOST) {
      fields.push({
        projectField: true,
        id: -2,
        type: PERCENTAGE,
        name: WIN_PERCENT_LABEL,
        value: nextWinPercent || project.winPercent,
        numStep: WIN_PERCENT_STEP,
      });
    }

    fields.push({
      projectField: true,
      id: -1,
      type: COLOR,
      name: COLOR,
      value: nextProjectColor || projectColor,
    });

    const otherField = projectFields.find(({ name }) => name === 'Other');
    if (otherField) {
      const populatedOther = selectedProjectFields.find(f => f.name === otherField.name) || {};
      fields.push({
        ...otherField,
        ...populatedOther,
        value: populatedOther.values ? populatedOther.values[0] : undefined,
      });
    }

    return fields;
  }

  generateInputs = (inputFields) => {
    const inputs = [];

    if (!inputFields || inputFields.length === 0) {
      return [];
    }

    inputFields.forEach((field) => {
      inputs.push(getFieldInput(field));
    });

    return inputs;
  }

  onSave = (data) => {
    const {
      updateProject,
      accountId,
      updateProjectFieldValue,
      project,
      parentName,
      accountColors,
      defaultProjectColors,
      trackAnalytics,
      isAddressSuggestionSelected,
    } = this.props;
    const { values, value: origValue, type, fieldId, name } = data;
    const fieldValue = values.length > 1 ? values.join(', ') : values[0];
    const originalValue = Array.isArray(origValue) ? origValue?.join(', ') : origValue;

    // If the value wasn't changed, cancel editing
    if (fieldValue === originalValue) {
      this.setState({
        fieldToEdit: null,
      });
      return;
    }

    const baseAnalyticsPayload = {
      'Project Name': project.name,
      'Project ID': project.id,
      'Project status': project.state,
    };

    if (name === COLOR) {
      updateProject(accountId, project.id, { colour: fieldValue });

      const colorType = getColorType(defaultProjectColors, accountColors, fieldValue);
      const colorUpdateAnalyticsPayload = {
        ...baseAnalyticsPayload,
        'Project type': project.type,
        'Colour updated to': fieldValue,
        'Colour type used': colorType,
      };

      trackAnalytics(PROJECT_COLOUR_UPDATED, colorUpdateAnalyticsPayload);
    } else if (name === WIN_PERCENT_LABEL) {
      updateProject(accountId, project.id, { winPercent: fieldValue ?? null });
      const winPercentAnalyticsPayload = {
        ...baseAnalyticsPayload,
        'Project win %': parseInt(fieldValue, 10) || null,
      };
      trackAnalytics(PROJECT_WIN_PERCENT_UPDATED, winPercentAnalyticsPayload);
    } else {
      const updateAddressPayload = {
        ...baseAnalyticsPayload,
        'Project is updated from': parentName,
        Type: project.type,
      };

      const analyticsPayload = {
        ...updateAddressPayload,
        'Field ID': fieldId,
        'Field name': name,
        'Field value updated from': originalValue,
        'Field value updated to': Array.isArray(values) ? values?.join(', ') : values,
      };

      if (type === FIELD_TYPE_ADDRESS) {
        trackAnalytics(
          PROJECT_ADDRESS_UPDATED,
          { ...updateAddressPayload, 'Autofill address selected': isAddressSuggestionSelected },
        );
      }

      updateProjectFieldValue(accountId, project.id, data, analyticsPayload);
    }
  }

  fieldToEdit = (id) => {
    this.setState({ fieldToEdit: id });
  }

  onAddressView = (evt) => {
    evt.stopPropagation();

    const { parentName, trackAnalytics } = this.props;

    const analyticsPayload = {
      'Address Type': 'Project Address',
      Location: parentName,
    };
    trackAnalytics(PROJECT_VIEW_ADDRESS_ON_MAP, analyticsPayload);
  };

  render() {
    const { disabled, updateProjectFieldValuePending, updateProjectPending } = this.props;
    const { fields, inputs, fieldToEdit } = this.state;

    return (
      <div className={`projects-project-detail-tab${disabled ? ' disabled' : ''}`}>
        <Grid
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          container
          spacing={1}
        >
          {fields.map((field) => {
            const checked = field.type === 'Boolean' && field.value && field.value.toString().toLowerCase() === 'true';
            const input = inputs.find(i => parseInt(i.name, 10) === field.id);

            return (
              <div key={field.id} className={field.type === 'LongText' && field.name === 'Other' ? 'other-wrapper' : 'item-wrap'}>
                <FieldEditor
                  item={field}
                  checked={checked}
                  input={input}
                  onSave={this.onSave}
                  disabled={fieldToEdit !== null && fieldToEdit !== field.id}
                  permission={{ action: PERM_WRITE, subject: PERM_PROJECT }}
                  pending={[COLOR, PERCENTAGE].includes(field.type)
                    ? updateProjectPending : updateProjectFieldValuePending}
                  fieldToEdit={this.fieldToEdit}
                  editing={fieldToEdit === field.id}
                  onAddressView={this.onAddressView}
                />
              </div>
            );
          })}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = ({ projects, common, accounts, wrappedComponents, accountSettings, launchDarkly }) => {
  const { updateProjectFieldValuePending, updateProjectPending, projectDefaults } = projects;
  const { accountId } = common;
  const { accountColors } = accounts;
  const { placeSuggestions: { isAddressSuggestionSelected } } = wrappedComponents;
  const { accountModules } = accountSettings;
  const { pursuitsWinPercentage } = launchDarkly;

  const hasPursuitsModuleEnabled = hasModuleEnabled(accountModules, ACCOUNT_MODULE_PURSUIT_TRACKING);

  return {
    updateProjectFieldValuePending,
    updateProjectPending,
    accountId,
    accountColors,
    defaultProjectColors: projectDefaults?.colors || [],
    isAddressSuggestionSelected,
    hasPursuitsModuleEnabled,
    winPercentageFlag: pursuitsWinPercentage,
  };
};

const mapDispatchToProps = dispatch => ({
  updateProjectFieldValue: bindActionCreators(updateProjectFieldValue, dispatch),
  updateProject: bindActionCreators(updateProject, dispatch),
  trackAnalytics: bindActionCreators(trackAnalytics, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectDetailTab);
