import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { TextField, InputAdornment } from '@material-ui/core';
import { Search, Clear } from '@material-ui/icons';
import { useDebounce, usePrevious } from '@bridgit/foundation';

import { getSearchStringFromQuery } from './utils/filterUtils';
import { DEBOUNCE_DURATION } from './redux/constants';
import { setSearchQuery } from '../queries/redux/actions';

const SearchBox = ({
  placeholder,
  onFocus,
  onSearch,
  queryId,
  type,
}) => {
  const dispatch = useDispatch();

  const queries = useSelector(({ queries }) => queries);

  const [searchString, setSearchString] = useState(getSearchStringFromQuery(queries[queryId]));
  const [isTouched, setIsTouched] = useState(false);

  const debouncedSearchString = useDebounce(searchString, DEBOUNCE_DURATION);

  const prevQueryId = usePrevious(queryId);

  // clear search input when changing tabs from List/Gantt -> Self-perform
  useEffect(() => {
    if (prevQueryId !== queryId) {
      setSearchString('');
    }
  }, [prevQueryId, queryId]);

  useEffect(() => {
    // don't search if in-between query changes or if the user is still typing or if user has not interacted with the search field
    if (prevQueryId !== queryId || searchString !== debouncedSearchString || !isTouched) return;

    dispatch(setSearchQuery(queryId, {
      name: 'search',
      args: [{ filterType: type, activeFilters: [debouncedSearchString] }],
    }));
    onSearch(debouncedSearchString);
  }, [debouncedSearchString, dispatch, onSearch, queryId, type, prevQueryId, searchString, isTouched]);

  const onChange = useCallback((event) => {
    const { value } = event.target;

    setSearchString(value);
    setIsTouched(true);
  }, []);

  const onClear = useCallback(() => {
    setSearchString('');
  }, []);

  return (
    <div className="filters-search-box">
      <TextField
        placeholder={placeholder}
        value={searchString}
        onChange={onChange}
        className="search-box-root"
        onFocus={onFocus}
        id="search-textfield"
        autoFocus
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <Search className="filter-icon" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {searchString.length > 0 && <Clear className="filter-clear" onClick={onClear} />}
            </InputAdornment>
          ),
          classes: {
            root: 'filter-text-field',
            focused: 'focused',
          },
        }}
      />
    </div>
  );
};

SearchBox.propTypes = {
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  onSearch: PropTypes.func,
  queryId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

SearchBox.defaultProps = {
  placeholder: '',
  onFocus: () => {},
  onSearch: () => {},
};

export default SearchBox;
