import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PROJECT_GET_PROJECT_FIELD_VALUES_BEGIN,
  PROJECT_GET_PROJECT_FIELD_VALUES_SUCCESS,
  PROJECT_GET_PROJECT_FIELD_VALUES_FAILURE,
  PROJECT_GET_PROJECT_FIELD_VALUES_DISMISS_ERROR,
  MAX_PROJECTS,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';

export function getProjectFieldValues(accountId) {
  return {
    type: PROJECT_GET_PROJECT_FIELD_VALUES_BEGIN,
    accountId,
  };
}

export function dismissGetProjectFieldValuesError() {
  return {
    type: PROJECT_GET_PROJECT_FIELD_VALUES_DISMISS_ERROR,
  };
}

export function* doGetProjectFieldValues(action) {
  const { accountId } = action;
  let res;
  try {
    res = yield call(protectedJsonGet, `accounts/${accountId}/project-field-values?limit=${MAX_PROJECTS}`);
  } catch (err) {
    yield put({
      type: PROJECT_GET_PROJECT_FIELD_VALUES_FAILURE,
      data: { error: err },
    });
    return;
  }
  yield put({
    type: PROJECT_GET_PROJECT_FIELD_VALUES_SUCCESS,
    data: res,
  });
}

export function* watchGetProjectFieldValues() {
  yield takeLatest(PROJECT_GET_PROJECT_FIELD_VALUES_BEGIN, doGetProjectFieldValues);
}

export function reducer(state, action) {
  switch (action.type) {
    case PROJECT_GET_PROJECT_FIELD_VALUES_BEGIN:
      return {
        ...state,
        getProjectFieldValuesPending: true,
        getProjectFieldValuesError: null,
      };

    case PROJECT_GET_PROJECT_FIELD_VALUES_SUCCESS:
      return {
        ...state,
        projectFieldValues: action.data || [],
        getProjectFieldValuesPending: false,
        getProjectFieldValuesError: null,
      };

    case PROJECT_GET_PROJECT_FIELD_VALUES_FAILURE:
      return {
        ...state,
        getProjectFieldValuesPending: false,
        getProjectFieldValuesError: action.data.error,
      };

    case PROJECT_GET_PROJECT_FIELD_VALUES_DISMISS_ERROR:
      return {
        ...state,
        getProjectFieldValuesError: null,
      };

    default:
      return state;
  }
}
