import {
  ACCOUNT_SETTINGS_CLEAR_SELECTED_CERTIFICATION,
} from './constants';

export function clearSelectedAccountCertification() {
  return {
    type: ACCOUNT_SETTINGS_CLEAR_SELECTED_CERTIFICATION,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNT_SETTINGS_CLEAR_SELECTED_CERTIFICATION:
      return {
        ...state,
        selectedCertification: {},
      };

    default:
      return state;
  }
}
