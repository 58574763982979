import React from 'react';
import PropTypes from 'prop-types';
import { DATE_INPUT_FORMAT } from 'src/common/constants';
import moment from 'moment';
import { PeriodicRequirementRow } from '.';

const PeriodicRequirements = ({
  requirements,
  onRequirementChange,
  breakdownType,
}) => (
  <div className="allocations-periodic-requirements">
    {requirements.map((req) => {
      const { startDate, endDate, allocatedPercent, name } = req;

      return (
        <PeriodicRequirementRow
          key={`${startDate}-${endDate}`}
          breakdownType={breakdownType}
          startDate={moment(startDate, DATE_INPUT_FORMAT).format(DATE_INPUT_FORMAT)}
          endDate={moment(endDate, DATE_INPUT_FORMAT).format(DATE_INPUT_FORMAT)}
          allocatedPercent={allocatedPercent}
          onUpdate={onRequirementChange}
          name={name}
        />
      );
    })}
  </div>
);

PeriodicRequirements.propTypes = {
  requirements: PropTypes.array.isRequired,
  onRequirementChange: PropTypes.func.isRequired,
  breakdownType: PropTypes.string.isRequired,
};

export default PeriodicRequirements;
