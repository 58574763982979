import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { trackAnalytics } from 'src/features/common/redux/actions';
import { useDispatch } from 'react-redux';
import { ChipBowl } from '../table';
import { NoFilterMessage, DistanceFromProjectCandidateFilter } from '.';
import { ROLE_AVAILABILITIES_QUERY_ID } from '../queries/redux/constants';
import { projectAllocationFilterChipRemovePayload } from '../../analytics/mixpanel/filterChips';
import { PAPM_FILTERS_RESET, PAPM_CLEAR_FILTER_CHIP } from '../../analytics/filters/constants';

const CandidateFilters = ({
  toggleFilterEdit,
  onResetFilters,
  editing,
  onApplyDistanceFilter,
}) => {
  const dispatch = useDispatch();

  const resetFilters = useCallback(() => {
    dispatch(trackAnalytics(PAPM_FILTERS_RESET));
    onResetFilters();
  }, [onResetFilters, dispatch]);

  const onUpdateFilters = useCallback((columnName, filterLabel) => {
    dispatch(trackAnalytics(PAPM_CLEAR_FILTER_CHIP, projectAllocationFilterChipRemovePayload(columnName, filterLabel)));
  }, [dispatch]);

  return (
    <div className="allocations-candidate-filters">
      <Button
        disableRipple
        className="reset"
        color="primary"
        onClick={resetFilters}
      >
        Reset filters
      </Button>

      <DistanceFromProjectCandidateFilter
        onChangeCommitted={onApplyDistanceFilter}
      />

      <ChipBowl
        className="filter-chips"
        classes={{
          chipGroup: 'chip-group',
          groupLabel: 'group-label',
          operator: 'operator',
          chip: 'chip',
        }}
        showReset={false}
        noFilterMessage={<NoFilterMessage />}
        queryId={ROLE_AVAILABILITIES_QUERY_ID}
        onUpdateFilters={onUpdateFilters}
        showSaveFilterButton={false}
      />

      { !editing ?
        (
          <Button
            disableRipple
            className="more"
            onClick={toggleFilterEdit}
          >
            Add filters
            <ChevronRight />
          </Button>
        )
        :
        (
          <Button
            disableRipple
            className="more"
            onClick={toggleFilterEdit}
          >
            Back to results
            <ChevronRight />
          </Button>
        )}
    </div>
  );
};

CandidateFilters.propTypes = {
  toggleFilterEdit: PropTypes.func.isRequired,
  onResetFilters: PropTypes.func.isRequired,
  onApplyDistanceFilter: PropTypes.func.isRequired,
  editing: PropTypes.bool,
};

CandidateFilters.defaultProps = {
  editing: false,
};

export default CandidateFilters;
