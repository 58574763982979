import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { IconButton } from '@bridgit/foundation';
import { InsertDriveFile } from '@material-ui/icons';
import { CustomPopper } from '../wrapped-components';
import { ListFileExportMenu } from '.';
import { LIST_TAB, PEOPLE_VIEW, PROJECT_VIEW } from '../../common/constants';
import GanttFileExportMenu from './GanttFileExportMenu';
import { isFirefox } from '../../utils/browser-utils';
import { PeopleGanttFileExportMenu } from './people-gantt-report';
import { ProjectGanttFileExportMenu } from './project-gantt-report';

export const FileExportMenu = ({
  loading,
  title,
  queryId,
}) => {
  const { contentView, activeView } = useSelector(({ common }) => common);
  const {
    newPeopleGantt: newPeopleGanttFlag,
  } = useSelector(({ launchDarkly }) => launchDarkly);
  const [anchorEl, setAnchorEl] = useState(null);

  // Clear popup when content tab changes
  useEffect(() => {
    setAnchorEl(null);
  }, [contentView]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isListView = contentView === LIST_TAB;

  const renderExportMenu = useCallback(() => {
    if (isListView) {
      return <ListFileExportMenu handleClose={handleClose} menuTitle={title} queryId={queryId} />;
    }

    if (activeView === PEOPLE_VIEW && newPeopleGanttFlag) {
      return <PeopleGanttFileExportMenu handleClose={handleClose} menuTitle={title} />;
    }

    if (activeView === PROJECT_VIEW) {
      return <ProjectGanttFileExportMenu handleClose={handleClose} menuTitle={title} />;
    }

    return <GanttFileExportMenu handleClose={handleClose} menuTitle={title} />;
  }, [activeView, isListView, newPeopleGanttFlag, queryId, title]);

  // Hide the Gantt report button for Firefox
  // until all issues are sorted out.
  if (isFirefox() && !isListView) {
    return null;
  }

  return (
    <div>
      <Tooltip title="Export report" placement="bottom">
        <IconButton
          color="secondary"
          variant="outlined"
          onClick={handleClick}
          isLoading={loading}
          ariaLabel="Export report"
          aria-haspopup
          aria-controls="export-menu"
        >
          <InsertDriveFile />
        </IconButton>
      </Tooltip>

      <CustomPopper
        popperOpen={Boolean(anchorEl)}
        anchorEl={anchorEl}
        classes="reports-file-export-menu"
        placement="bottom-start"
        hideArrow
        preventBubbling
        disablePortal
      >
        { renderExportMenu() }
      </CustomPopper>
    </div>
  );
};

FileExportMenu.propTypes = {
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  queryId: PropTypes.string.isRequired,
};

export default FileExportMenu;
