import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Widgit,
  InsightfulStatement,
  BarGraph,
  b500bridgitBlue,
  b300clearSkyBlue,
  DeltaWidgit,
  g600stoneGrey,
} from '@bridgit/foundation';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import histogram from '../../images/horizontal-histogram.png';
import { stringToMoment } from '../../utils/dateUtils';
import {
  IDLE_TIME_CONTROLS,
  IDLE_TIME_TOOLTIPS,
  IDLE_TIME,
  UTILIZATION,
  TRUE_UTILIZATION_RATE,
  POSITIVE,
  NEGATIVE,
  IDLE_TIME_GRAPH_Y_TICKS,
  IDLE_TIME_VALUE,
} from './constants';
import { generateInsightfulStatement, generateXLabels } from './utils/dashboardUtils';
import { RadioChipForm } from '../common';
import { getIdleTimeOverview } from './redux/getIdleTimeOverview';
import { MONTH_DAY_RANGE_FORMAT, FORECAST_TAB } from '../../common/constants';
import { trackAnalytics } from '../common/redux/trackAnalytics';
import {
  IDLE_TIME_CTA_CLICK,
  IDLE_TIME_GRAPH_CLICK,
  IDLE_TIME_METRIC_CLICK,
} from '../../analytics/dashboard/constants';


export const IdleTimeWidgit = ({ onTabsChange }) => {
  const [currentChip, setCurrentChip] = useState(IDLE_TIME_VALUE);
  const [selectedBar, setSelectedBar] = useState(null);

  const { accountId } = useSelector(({ common }) => common);
  const {
    idleTimeOverview,
    getIdleTimeOverviewPending,
    monthlyIdleTimeOverview,
    getMonthlyIdleTimeOverviewPending,
    idleTimeSentiment,
  } = useSelector(({ dashboard }) => dashboard);

  const hasEmptyUtilizationData = useMemo(() => (idleTimeOverview.length === 0 && !getIdleTimeOverviewPending) && (monthlyIdleTimeOverview.length === 0 && !getMonthlyIdleTimeOverviewPending), [getIdleTimeOverviewPending, idleTimeOverview, monthlyIdleTimeOverview, getMonthlyIdleTimeOverviewPending]);
  const graphStartDate = useMemo(() => stringToMoment(idleTimeOverview?.[0]?.x), [idleTimeOverview]);

  const dispatch = useDispatch();

  const isIdleTimeSelected = useMemo(() => currentChip === IDLE_TIME_VALUE, [currentChip]);

  useEffect(() => {
    dispatch(getIdleTimeOverview(accountId, moment(), moment().add(12, 'weeks')));
  }, [accountId, dispatch]);

  const transformUtilizationData = useCallback(data => (isIdleTimeSelected ? data.map(week => ({ ...week, y: 100 - week.y })) : data), [isIdleTimeSelected]);

  const barData = useMemo(() => transformUtilizationData(idleTimeOverview), [transformUtilizationData, idleTimeOverview]);
  const deltaData = useMemo(() => transformUtilizationData(monthlyIdleTimeOverview), [transformUtilizationData, monthlyIdleTimeOverview]);

  const setNonIntegerValueToFixed = value => (Number.isInteger(value) ? Number(value) : Number(value.toFixed(1)));

  const { currentPercent, firstDelta, secondDelta, secondDeltaText } = useMemo(() => {
    if (getMonthlyIdleTimeOverviewPending || deltaData.length < 3) return {};

    const firstMonth = deltaData[0];
    const secondMonth = deltaData[1];
    const thirdMonth = deltaData[2];

    const firstDelta = (secondMonth?.y - firstMonth?.y);
    const secondDelta = (thirdMonth?.y - firstMonth?.y);

    const secondDeltaText = `In ${moment(thirdMonth?.x).format('MMMM')}`;

    return {
      currentPercent: setNonIntegerValueToFixed(firstMonth?.y),
      firstDelta: setNonIntegerValueToFixed(firstDelta),
      secondDelta: setNonIntegerValueToFixed(secondDelta),
      secondDeltaText,
    };
  }, [deltaData, getMonthlyIdleTimeOverviewPending]);

  const selectedBarFormattedDateRange = useCallback(() => {
    if (selectedBar) {
      const selectedBarStartDate = stringToMoment(selectedBar.x).format(MONTH_DAY_RANGE_FORMAT);
      const selectedBarEndDate = stringToMoment(selectedBar.x).add(6, 'days').format(MONTH_DAY_RANGE_FORMAT);
      return `${selectedBarStartDate} - ${selectedBarEndDate}`;
    }
    return null;
  }, [selectedBar]);

  const xLabels = useMemo(() => (generateXLabels(monthlyIdleTimeOverview, graphStartDate)), [graphStartDate, monthlyIdleTimeOverview]);

  const graphTypeLabel = useMemo(() => IDLE_TIME_CONTROLS[currentChip].secondaryLabel, [currentChip]);

  const handleChipChange = useCallback((value) => {
    setCurrentChip(value);

    const analyticsPayload = {
      'Metric Clicked': IDLE_TIME_CONTROLS[value].secondaryLabel,
    };

    dispatch(trackAnalytics(IDLE_TIME_METRIC_CLICK, analyticsPayload));
  }, [dispatch]);

  const onInsightfulStatementClick = useCallback(() => {
    onTabsChange(null, FORECAST_TAB);

    dispatch(trackAnalytics(IDLE_TIME_CTA_CLICK));
  }, [onTabsChange, dispatch]);

  const onBarClick = useCallback((bar) => {
    setSelectedBar(bar);

    const barClicked = barData.findIndex(({ x, y }) => bar.x === x && bar.y === y) + 1;

    const analyticsPayload = {
      'Metric Clicked': graphTypeLabel,
      'Time Period': barClicked,
    };

    dispatch(trackAnalytics(IDLE_TIME_GRAPH_CLICK, analyticsPayload));
  }, [dispatch, barData, graphTypeLabel]);

  const handleClearSelection = useCallback(() => {
    setSelectedBar(null);
  }, []);

  const widgitCurrentPercent = selectedBar?.y ? setNonIntegerValueToFixed(selectedBar.y) : currentPercent;

  const mainContent = useMemo(() => (
    <div className="main-content">
      <div className="delta-widgit-container">
        <div className="delta-widgit">
          <DeltaWidgit
            currentPercent={widgitCurrentPercent}
            firstDelta={firstDelta}
            secondDelta={secondDelta}
            secondDeltaText={secondDeltaText}
            desirableDelta={isIdleTimeSelected ? NEGATIVE : POSITIVE}
            selectedText={selectedBarFormattedDateRange()}
          />
        </div>
      </div>
      <div className="bargraph">
        <BarGraph
          barColor={b300clearSkyBlue}
          barBorderColor={b500bridgitBlue}
          selectedBarColor={b500bridgitBlue}
          useOpacity={false}
          onBarClick={onBarClick}
          barSpacing={30}
          barWidth={27}
          height={188}
          width={450}
          hideYTickLine
          barBorderRadius={2}
          hasOuterPadding={false}
          hasBackgroundBars
          hideXAxis
          xTickStep={2}
          yTicks={IDLE_TIME_GRAPH_Y_TICKS}
          barData={barData}
          yTickFormat={tick => `${tick}%`}
          axisColor={g600stoneGrey}
          onClearSelection={handleClearSelection}
        />
        <div className="x-labels">
          {xLabels.map(label => (<span key={label}>{label}</span>))}
        </div>
        <div />
      </div>
    </div>
  ), [barData, widgitCurrentPercent, firstDelta, isIdleTimeSelected, secondDelta, secondDeltaText, selectedBarFormattedDateRange, xLabels, onBarClick, handleClearSelection]);

  const tooltipText = useMemo(() => (
    <div className="tooltip-text">
      <div>
        <b>{`${isIdleTimeSelected ? IDLE_TIME : TRUE_UTILIZATION_RATE}`}</b>
        {IDLE_TIME_TOOLTIPS[currentChip]}
      </div>
      <br />
      <div>
        {isIdleTimeSelected ? IDLE_TIME : TRUE_UTILIZATION_RATE}
        {' '}
        is the exact inverse of
        {' '}
        {isIdleTimeSelected ? TRUE_UTILIZATION_RATE : IDLE_TIME}
        .
      </div>
    </div>
  ), [currentChip, isIdleTimeSelected]);

  const emptyState = useMemo(() => (
    <div className="empty-state-container">
      <div className="empty-state-text">
        <div>See how your idle time or utilization rate is trending.</div>
        <div>
          <RouterLink className="add-projects-link" to={`/accounts/${accountId}/projects`} type="button">Add some projects</RouterLink>
          {' '}
          with roles assigned to them to get started.
        </div>
      </div>
      <img className="histogram" alt="Horizontal Histogram" src={histogram} />
    </div>
  ), [accountId]);

  const widgitFooter = useMemo(() => {
    if (!idleTimeSentiment) {
      return null;
    }
    const insightfulStatement = generateInsightfulStatement(isIdleTimeSelected ? IDLE_TIME : UTILIZATION)[idleTimeSentiment];

    return (
      <InsightfulStatement
        onClick={onInsightfulStatementClick}
        insightfulStatement={insightfulStatement}
      />
    );
  }, [idleTimeSentiment, isIdleTimeSelected, onInsightfulStatementClick]);

  return (
    <div className="dashboard-idle-time-widgit">
      <Widgit
        headerText={graphTypeLabel}
        headerTooltipContent={tooltipText}
        footerContent={widgitFooter}
        isLoading={getIdleTimeOverviewPending || getMonthlyIdleTimeOverviewPending}
        mainContent={mainContent}
        isEmpty={hasEmptyUtilizationData}
        emptyState={emptyState}
        rightHeaderContent={(
          <RadioChipForm
            controls={IDLE_TIME_CONTROLS}
            defaultValue={IDLE_TIME_VALUE}
            onChange={handleChipChange}
          />
      )}
      />
    </div>
  );
};

IdleTimeWidgit.propTypes = {
  onTabsChange: PropTypes.func.isRequired,
};

export default IdleTimeWidgit;
