import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider } from '@material-ui/core/styles';
import moment from 'moment';
import deepEqual from 'react-fast-compare';
import { b500bridgitBlue, Loader } from '@bridgit/foundation';
import { FormField } from '.';
import { cloneDeep } from '../../utils/miscUtils';

export default class ValidatedForm extends Component {
  static propTypes = {
    className: PropTypes.string,
    inputs: PropTypes.arrayOf(PropTypes.object),
    validate: PropTypes.func,
    onValueChanged: PropTypes.func,
    onDateInputFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onSelectOpen: PropTypes.func,
    onSelectClose: PropTypes.func,
    errors: PropTypes.object,
    alwaysShowErrors: PropTypes.bool,
    onKeyUp: PropTypes.func,
    forceUpdate: PropTypes.bool,
    loading: PropTypes.bool,
    defaultValues: PropTypes.object,
  };

  static defaultProps = {
    className: '',
    inputs: [],
    validate: () => {},
    onValueChanged: () => {},
    onDateInputFocus: () => {},
    onBlur: () => {},
    onSelectOpen: () => {},
    onSelectClose: () => {},
    errors: {},
    alwaysShowErrors: false,
    onKeyUp: () => {},
    forceUpdate: false,
    loading: false,
    defaultValues: {},
  };

  constructor(props) {
    super(props);

    const values = { ...this.parseValues(props.inputs), ...props.defaultValues };

    this.state = {
      values,
      errors: {},
    };
  }

  componentDidMount() {
    const { values } = this.state;
    const { validate, errors } = this.props;

    this.setState({
      errors: {
        ...validate(values),
        ...errors,
      },
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { errors, inputs, defaultValues } = this.props;
    const { values } = this.state;
    const updatedState = {};

    if (!deepEqual(errors, nextProps.errors)) {
      updatedState.errors = nextProps.errors;
    }

    if (nextProps.forceUpdate && !deepEqual(inputs, nextProps.inputs)) {
      const newValues = this.parseValues(nextProps.inputs);
      updatedState.values = newValues;
      updatedState.errors = nextProps.validate(newValues);
    }

    if (!deepEqual(defaultValues, nextProps.defaultValues)) {
      const newValues = { ...values, ...nextProps.defaultValues };
      this.setState({ values: newValues });
    }

    this.setState(updatedState);
  }

  getMuiTheme = outerTheme => ({
    ...outerTheme,
    overrides: {
      ...outerTheme.overrides,
      MuiInputLabel: {
        shrink: {
          transform: 'translate(0, 24px) scale(1)',
          width: '100%',
          fontSize: '0.75rem',
          top: '-23px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
      MuiInput: {
        underline: {
          '&$error': {
            '&:after': {
              borderBottomColor: 'inherit',
            },
          },
        },
      },
      MuiFormHelperText: {
        root: {
          '&$error': {
            color: b500bridgitBlue,
          },
        },
      },
    },
  });

  parseValues = inputs => inputs.reduce((values, input) => {
    const obj = cloneDeep(values);

    switch (input.type) {
      case 'checkbox': {
        if (input.multi) {
          obj[input.name] = input.options || [];
        } else if ('value' in input) {
          obj[input.name] = input.value && input.value.toString().toLowerCase() === 'true';
        } else {
          obj[input.name] = false;
        }
        break;
      }
      case 'date':
        obj[input.name] = input.value && moment(input.value).isValid() ? moment(input.value).toISOString() : null;
        break;
      case 'select': {
        let { value } = input;
        if (!input.value) {
          value = input.multi ? [] : '';
        }
        obj[input.name] = value;
        break;
      }
      case 'color':
        obj[input.name] = input.value;
        break;
      default:
        obj[input.name] = input.value || '';
    }
    return obj;
  }, {});

  onInputChange = (e, passedValue, customFieldName) => {
    const { validate, onValueChanged } = this.props;
    const { values } = this.state;
    const newValues = cloneDeep(values);

    const { type, name, value, checked } = e.target;

    if (type === 'checkbox') {
      if (name === 'workDays') {
        newValues[name][value].checked = checked;
      } else {
        newValues[name] = !newValues[name];
      }
    } else if (type === 'select') {
      if (newValues[name].includes(value)) {
        newValues[name].splice(newValues[name].indexOf(value), 1);
      } else {
        newValues[name].push(value);
      }
    } else if (customFieldName) {
      newValues[customFieldName] = passedValue;
    } else {
      newValues[name] = value;
    }

    onValueChanged(name || customFieldName, newValues);

    this.setState({
      values: newValues,
      errors: validate(newValues),
    });
  };

  onDateInputFocus = () => {
    const { onDateInputFocus } = this.props;
    onDateInputFocus();
  }

  renderInputs = () => {
    const { inputs, alwaysShowErrors, onKeyUp, onBlur, onSelectOpen, onSelectClose } = this.props;
    const { values, errors } = this.state;

    return inputs.map((input, index) => (
      <FormField
        key={input.name}
        type={input.type}
        label={input.label}
        name={input.name}
        value={values[input.name]}
        placeholder={input.placeholder}
        error={errors && errors[input.name]}
        onChange={this.onInputChange}
        onDateInputFocus={this.onDateInputFocus}
        onBlur={onBlur}
        onSelectOpen={onSelectOpen}
        onSelectClose={onSelectClose}
        multi={input.multi}
        required={input.required}
        options={input.options}
        width={input.width}
        primary={input.primary}
        maxLength={input.maxLength}
        initDate={input.initDate}
        minDate={input.minDate}
        minDateMessage={input.minDateMessage}
        maxDate={input.maxDate}
        alwaysShowErrors={alwaysShowErrors}
        onKeyUp={onKeyUp}
        autoFocus={index === 0}
        className={input.className}
        id={input.id}
        disabled={input.disabled}
        defaultTouched={input.defaultTouched}
        tooltipText={input.tooltipText}
        minValue={input.minValue}
        maxValue={input.maxValue}
        numStep={input.numStep}
      />
    ));
  };

  render() {
    const { className, loading } = this.props;

    return (
      <div className={`wrapped-components-validated-form ${className}`}>
        <MuiThemeProvider theme={this.getMuiTheme}>
          {loading && <Loader />}
          {this.renderInputs()}
        </MuiThemeProvider>
      </div>
    );
  }
}
