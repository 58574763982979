import { call, put, takeLatest } from 'redux-saga/effects';
import {
  SETTINGS_UPDATE_USER_MEMBERSHIP_BEGIN,
  SETTINGS_UPDATE_USER_MEMBERSHIP_SUCCESS,
  SETTINGS_UPDATE_USER_MEMBERSHIP_FAILURE,
  SETTINGS_UPDATE_USER_MEMBERSHIP_DISMISS_ERROR,
} from './constants';
import { protectedJsonPut } from '../../../utils/api';

export function updateUserMembership(userId, newGroup) {
  return {
    type: SETTINGS_UPDATE_USER_MEMBERSHIP_BEGIN,
    userId,
    newGroup,
  };
}

export function dismissUpdateUserMembershipError() {
  return {
    type: SETTINGS_UPDATE_USER_MEMBERSHIP_DISMISS_ERROR,
  };
}

export function* doUpdateUserMembership(action) {
  const { userId, newGroup } = action;
  try {
    yield call(protectedJsonPut, `/Users/${userId}/membership`, {
      group: newGroup,
    });
  } catch (err) {
    yield put({
      type: SETTINGS_UPDATE_USER_MEMBERSHIP_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: SETTINGS_UPDATE_USER_MEMBERSHIP_SUCCESS,
    data: {
      userId,
      newGroup,
    },
  });
}

export function* watchUpdateUserMembership() {
  yield takeLatest(SETTINGS_UPDATE_USER_MEMBERSHIP_BEGIN, doUpdateUserMembership);
}

export function reducer(state, action) {
  switch (action.type) {
    case SETTINGS_UPDATE_USER_MEMBERSHIP_BEGIN:
      return {
        ...state,
        updateUserMembershipPending: true,
        updateUserMembershipError: null,
      };

    case SETTINGS_UPDATE_USER_MEMBERSHIP_SUCCESS: {
      const { userId, newGroup } = action.data;
      const updatedAdmin = state.bridgitAdmins?.find(admin => admin.id === userId);
      if (updatedAdmin) {
        updatedAdmin.group = newGroup;
      }
      return {
        ...state,
        updateUserMembershipPending: false,
        updateUserMembershipError: null,
      };
    }
    case SETTINGS_UPDATE_USER_MEMBERSHIP_FAILURE:
      return {
        ...state,
        updateUserMembershipPending: false,
        updateUserMembershipError: action.data.error,
      };

    case SETTINGS_UPDATE_USER_MEMBERSHIP_DISMISS_ERROR:
      return {
        ...state,
        updateUserMembershipError: null,
      };

    default:
      return state;
  }
}
