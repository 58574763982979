import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DATE_DISPLAY_FORMAT } from '../../common/constants';

const RequirementModalContentHeader = ({
  role,
}) => {
  const roleStartFormatted = moment(role.startDate).format(DATE_DISPLAY_FORMAT);
  const roleEndFormatted = moment(role.endDate).format(DATE_DISPLAY_FORMAT);

  return (
    <div className="allocations-requirement-modal-content-header modal-content-header">
      <div className="header-item">
        <span className="section-heading">Role</span>
        <span className="section-value">{role.name}</span>
      </div>
      <div className="header-item">
        <span className="section-heading">Start date</span>
        <span className="section-value">{roleStartFormatted}</span>
      </div>
      <div className="header-item">
        <span className="section-heading">End date</span>
        <span className="section-value">{roleEndFormatted}</span>
      </div>
    </div>
  );
};

RequirementModalContentHeader.propTypes = {
  role: PropTypes.object.isRequired,
};

export default RequirementModalContentHeader;
