import React from 'react';
import moment from 'moment';
import { SORT_ASC, SORT_DESC, ROLE_STATE_CONTROL_OPTIONS } from 'src/filters/constants';
import { stringToMoment, isStringOrMoment, isPast, isCurrent, isUpcoming } from 'src/utils/dateUtils';
import { objectIsSegment, trimSegmentsToRange } from 'src/utils/dateSegmentUtils';
import { roleIsShrinking, getImpactedPersonId } from 'src/features/allocations/utils/allocationUtils';
import {
  DATE_DISPLAY_FORMAT,
  FIELD_TYPE_ADDRESS,
  NOTIFY_MODAL_TIMESTAMP_FORMAT,
  DATE_INPUT_FORMAT,
} from '../../../common/constants';
import { INCLUDE_PROJECT_ADDRESS, INCLUDE_ROLE_NAME } from '../constants';
import { ProjectNotifyHeader } from '../index';

const getRoleSorting = (sort) => {
  const primarySort = sort?.roles?.nested?.structuredSort?.[0];
  const sortKeys = primarySort ? Object.keys(primarySort) : null;
  const sortName = sortKeys?.[0];
  const sortDirection = primarySort?.[sortName]?.order;

  return { sortName, sortDirection };
};

const getRoleSortClause = (name, direction) => {
  let structuredSort;
  const primaryOrder = direction === SORT_DESC ? SORT_DESC : SORT_ASC;

  if (name === 'startDate') {
    structuredSort = [
      { startDate: { order: primaryOrder } },
      { endDate: { order: SORT_ASC } },
      { sortOrder: { order: SORT_ASC } },
    ];
  } else {
    structuredSort = [
      { sortOrder: { order: primaryOrder } },
      { startDate: { order: SORT_ASC } },
      { endDate: { order: SORT_ASC } },
    ];
  }

  return {
    roles: {
      nested: {
        path: 'roles',
        structuredSort,
      },
    },
  };
};

/*
  Verify that a given role is valid. The role start and end date must be in the
  correct order and fall within the boundaries of the parent project. The role
  must have requirements, and at least one requirement segment must have a non-zero
  allocatedPercent value.
*/
function validateRoleUpdate(project, updatedRole) {
  const roleStart = stringToMoment(updatedRole?.startDate);
  const roleEnd = stringToMoment(updatedRole?.endDate);
  const projectStart = stringToMoment(project?.startDate);
  const projectEnd = stringToMoment(project?.endDate);

  // Role and project must have valid start and end dates
  if (!(roleStart.isValid() && roleEnd.isValid() && projectStart.isValid() && projectEnd.isValid())) return false;

  // Role must have valid requirements
  if (!updatedRole?.requirements?.every(req => objectIsSegment(req))) return false;

  const { requirements } = updatedRole;

  // Role start must be between project start and role end date
  if (!moment(roleStart).isBetween(projectStart, roleEnd, null, '[]')) {
    return false;
  }

  // Role end date must be between role start and project end date
  if (!moment(roleEnd).isBetween(roleStart, projectEnd, null, '[]')) {
    return false;
  }

  // There must be non-zero requirements
  if (requirements.every(req => req.allocatedPercent === 0)) return false;

  // Requirement percentages must between 0 and 100
  if (requirements.some(req => req.allocatedPercent < 0 || req.allocatedPercent > 100)) return false;

  return true;
}

function confirmRoleUpdate(newStartDate, newEndDate, existingRole, allocations = [], callbacks) {
  const { saveChange, cancelChange, requestConfirmation } = callbacks;

  if (!(
    isStringOrMoment(newStartDate) &&
    isStringOrMoment(newEndDate) &&
    isStringOrMoment(existingRole?.startDate) &&
    isStringOrMoment(existingRole?.endDate) &&
    typeof saveChange === 'function' &&
    typeof cancelChange === 'function' &&
    typeof requestConfirmation === 'function'
  )) return;

  const newStart = stringToMoment(newStartDate);
  const newEnd = stringToMoment(newEndDate);
  const existingStart = stringToMoment(existingRole.startDate);
  const existingEnd = stringToMoment(existingRole.endDate);

  const roleShrinking = roleIsShrinking(newStart, newEnd, existingRole);
  const personImpactedId = allocations.length ? getImpactedPersonId(allocations, existingRole, newStart) : null;

  const trimmedSegments = trimSegmentsToRange(existingRole.requirements, newStart, newEnd);
  const allRequirementsZero = trimmedSegments.every(req => req.allocatedPercent === 0);

  if (newStart.isSame(existingStart) && newEnd.isSame(existingEnd)) {
    cancelChange();
  } else if (
    (allocations.length && !roleShrinking && personImpactedId) // Existing allocations need to be addressed
    || (roleShrinking && allRequirementsZero) // All requirements will be zero after the update
  ) {
    requestConfirmation();
  } else {
    saveChange();
  }
}

const getRoleRecipients = (people, allocations) => {
  const peopleOnRole = [];

  // collect unique person ids
  allocations.forEach(({ personId, endDate }) => {
    const personMatch = people.find(person => person.id === personId);
    const duplicatePersonId = peopleOnRole.find(person => person.id === personId);
    if (personMatch && !duplicatePersonId && moment().isSameOrBefore(endDate, 'date')) {
      peopleOnRole.push(personMatch);
    }
  });

  return peopleOnRole;
};

const getRoleRecipientNames = (people, recipientIds) => {
  const names = people.reduce((names, { id, name }) => {
    if (recipientIds.includes(id)) names.push(name);

    return names;
  }, []);

  return names.join(', ');
};

const getCommunicateAssignmentRemovalModalProps = (role, selectedProject, people, allocation) => {
  const { name: roleName, notification } = role;
  const { notifiedOn, recipientIds } = notification;
  const { name: projectName } = selectedProject;
  const { name, startDate, endDate, personId } = allocation;

  const recipientNames = getRoleRecipientNames(people, recipientIds);
  const date = moment(notifiedOn).format(NOTIFY_MODAL_TIMESTAMP_FORMAT);
  const address = selectedProject.fields.find(({ name }) => name === FIELD_TYPE_ADDRESS);
  const hasAddress = !!address?.values[0];
  const options = [
    ...(hasAddress ? [{
      label: `Include Project Address: ${address?.values[0]}`,
      value: INCLUDE_PROJECT_ADDRESS,
    }] : []),
    {
      label: `Include Role Name: ${roleName}`,
      value: INCLUDE_ROLE_NAME,
    },
  ];
  const defaultOptions = {
    [INCLUDE_PROJECT_ADDRESS]: hasAddress,
    [INCLUDE_ROLE_NAME]: true,
  };
  const recipient = people.find(({ id }) => id === personId);
  const allocationPeriod = `${moment(startDate).format(DATE_DISPLAY_FORMAT)} - ${moment(endDate).format(DATE_DISPLAY_FORMAT)}`;

  return {
    headline: 'Communicate assignment removal',
    standardText: `${name}, you are no longer required on ${projectName} from ${allocationPeriod}.`,
    recipients: [recipient],
    notifyHeader: <ProjectNotifyHeader label="Role" value={roleName} project={selectedProject} />,
    subtitle: `Last communication sent on ${date} to ${recipientNames}`,
    options,
    defaultOptions,
  };
};

// Accepts array or object
const formatProjectRoles = projectRoles => (Array.isArray(projectRoles) ? projectRoles : [projectRoles]).map(({ name, note, startDate, endDate, requirements, selectedPhases }) => (
  {
    name,
    note,
    startDate: moment(startDate).format(DATE_INPUT_FORMAT),
    endDate: moment(endDate).format(DATE_INPUT_FORMAT),
    requirements: requirements
      ? requirements.map(a => (
        {
          startDate: moment(a.startDate).format(DATE_INPUT_FORMAT),
          endDate: moment(a.endDate).format(DATE_INPUT_FORMAT),
          allocatedPercent: a.allocatedPercent,
        }
      ))
      : [],
    selectedPhases,
  }
));

const roleIsVisible = (role, filters) => {
  const { startDate, endDate } = role;
  if (filters.length === 0 || filters.length === ROLE_STATE_CONTROL_OPTIONS.length) return true;
  if (
    (filters.includes('Past') && isPast(endDate)) ||
    (filters.includes('Current') && isCurrent(startDate, endDate)) ||
    (filters.includes('Upcoming') && isUpcoming(startDate))
  ) {
    return true;
  }
  return false;
};

const getFilteredRoles = (selectedProject, roleStateFilters) => (isCurrent(selectedProject.startDate, selectedProject.endDate)
|| !selectedProject?.roles?.length
  ? selectedProject?.roles?.filter(role => roleIsVisible(role, roleStateFilters))
  : selectedProject?.roles);

export {
  getRoleSorting,
  getRoleSortClause,
  validateRoleUpdate,
  confirmRoleUpdate,
  getRoleRecipients,
  getRoleRecipientNames,
  getCommunicateAssignmentRemovalModalProps,
  formatProjectRoles,
  roleIsVisible,
  getFilteredRoles,
};
