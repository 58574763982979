import initialState from './initialState';
import { reducer as changeThemeReducer } from './changeTheme';
import { reducer as adjustThemeSettingsReducer } from './adjustThemeSettings';
import { reducer as toggleSystemThemeSwitchReducer } from './toggleSystemThemeSwitch';

const reducers = [
  changeThemeReducer,
  adjustThemeSettingsReducer,
  toggleSystemThemeSwitchReducer,
];

export default function reducer(state = initialState, action) {
  return reducers.reduce((s, r) => r(s, action), state);
}
