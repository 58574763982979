import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import { LinearProgress, Grid, ClickAwayListener } from '@material-ui/core';
import { ProjectRoleTitle } from '@bridgit/foundation';
import { getPercentageLabel } from '../gantt/utils/ganttUtils';
import { DATE_DISPLAY_FORMAT } from '../../common/constants';
import { CustomPopper } from '../wrapped-components';
import { ProjectLinkToModalContainer } from '../common';
import { getMousePosition } from '../../utils/uiUtils';
import { ProjectStatus } from '../projects';

export class ProjectBreakdownBar extends PureComponent {
  static propTypes = {
    project: PropTypes.object.isRequired,
    people: PropTypes.array.isRequired,
    projectAllocations: PropTypes.array.isRequired,
    accountId: PropTypes.number,
    bar: PropTypes.object.isRequired,
    verticalOffset: PropTypes.number,
    getProjectAllocations: PropTypes.func.isRequired,
    width: PropTypes.number.isRequired,
    classes: PropTypes.string,
    loadingAllocations: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    accountId: null,
    verticalOffset: 0,
    classes: '',
  }

  constructor(props) {
    super(props);
    this.state = {
      popperOpen: false,
      anchorEl: null,
    };
  }

  handleClick = (event) => {
    const { verticalOffset, project, getProjectAllocations, accountId } = this.props;
    const mouseLoc = getMousePosition(event, verticalOffset);
    this.setState({
      popperOpen: true,
      anchorEl: mouseLoc,
    });
    getProjectAllocations(accountId, project.id);
  }

  handleClickAway = () => {
    this.setState({
      popperOpen: false,
      anchorEl: null,
    });
  }

  handlePopperClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  }

  renderPerson = person => (
    <Grid
      key={`${person.id}-${person.startDate}-${person.endDate}`}
      container
      direction="row"
      justify="space-between"
      alignItems="center"
    >
      <p className="person-name">{person.name}</p>
      <p className="person-dates">{`${moment(person.startDate).format(DATE_DISPLAY_FORMAT)} - ${moment(person.endDate).format(DATE_DISPLAY_FORMAT)}`}</p>
    </Grid>
  );

  filterPeople = (allocations) => {
    const { people } = this.props;
    return allocations.reduce((result, alloc) => {
      const person = people.find(entity => entity.id === alloc.personId);
      if (person) result.push({ ...person, ...alloc });
      return result;
    }, []);
  }

  renderAllocations = (allocations) => {
    const filteredPeople = this.filterPeople(allocations);
    return filteredPeople.map(person => this.renderPerson(person));
  }

  renderUnfilledRoles = (role) => {
    const { projectAllocations } = this.props;
    const allocations = projectAllocations.filter(allocation => role.id === allocation.roleId);
    const partiallyAllocated = allocations.map(({ allocations }) => this.filterPeople(allocations));
    const unfilled = (!role || !role.unfilledRanges) ? [] : role.unfilledRanges.map(gap => ({
      id: 0,
      name: 'Unfilled',
      startDate: gap.startDate,
      endDate: gap.endDate,
    }));

    const combinedRoles = [...partiallyAllocated, ...unfilled].flat();
    return (
      <div className="unfilled-roles">
        {combinedRoles.map(role => this.renderPerson(role))}
      </div>
    );
  }

  renderRoleTitle = role => (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="flex-start"
    >
      <ProjectRoleTitle
        className="dashboard-role-title"
        roleName={role.name}
        roleNote={role.note}
      />
      <p>{getPercentageLabel(role.requirements)}</p>
    </Grid>
  )

  renderRoles = () => {
    const { project, projectAllocations, bar } = this.props;

    if (bar.filled) {
      const filledRoles = project.roles ? project.roles.filter(role => role.isFilled) : [];

      return filledRoles.map((role) => {
        const allocations = projectAllocations.filter(allocation => role.id === allocation.roleId);
        return (
          <div key={role.id} className="role-section">
            {this.renderRoleTitle(role)}
            {allocations.map(allocation => this.renderAllocations(allocation.allocations))}
          </div>
        );
      });
    }

    const unfilledRoles = project.roles ? project.roles.filter(role => !role.isFilled) : [];
    return unfilledRoles.map(role => (
      <div key={role.id} className="role-section">
        {this.renderRoleTitle(role)}
        {this.renderUnfilledRoles(role)}
      </div>
    ));
  }

  onProjectClick = () => {
    this.setState({
      popperOpen: false,
      anchorEl: null,
    });
  }

  render() {
    const { project, bar, loadingAllocations, classes, width } = this.props;
    const { popperOpen, anchorEl } = this.state;

    return (
      <button
        className={classNames('dashboard-bar bar', classes)}
        style={{ width: `${width}%` }}
        onClick={this.handleClick}
        type="button"
      >
        <div className="dashboard-project-breakdown-bar">
          { popperOpen && (
            <CustomPopper
              popperOpen={popperOpen}
              anchorEl={anchorEl}
              classes="dashboard-popper"
              placement="bottom"
              hideArrow
            >
              <ClickAwayListener onClickAway={this.handleClickAway}>
                <div
                  className="hover-container"
                  onClick={this.handlePopperClick}
                  role="presentation"
                >
                  <div className="hover-box">
                    <ProjectLinkToModalContainer
                      backgroundColor={project.color}
                      projectId={project.id}
                      projectName={project.name}
                      onClick={this.onProjectClick}
                      launchedFrom="Dashboard - Project Breakdown Popper"
                    />
                    <div className="info">
                      <div className="dates">{project.dateRange}</div>
                      <ProjectStatus project={project} />
                    </div>
                    <p className="divider" />
                    <div className="bar-role-count">
                      {bar.roleCount}
                    </div>
                    <div className="role-availabilities">
                      {loadingAllocations
                        ? (
                          <div className="progress-container">
                            <LinearProgress variant="query" color="primary" />
                          </div>
                        )
                        : this.renderRoles()}
                    </div>
                  </div>
                </div>
              </ClickAwayListener>
            </CustomPopper>
          ) }
        </div>
      </button>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps({ common }) {
  const { accountId } = common;
  return {
    accountId,
  };
}

export default connect(
  mapStateToProps,
)(ProjectBreakdownBar);
