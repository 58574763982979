import {
  COMMON_TRACK_ANALYTICS,
} from './constants';

export function trackAnalytics(event, analyticsPayload) {
  return {
    type: COMMON_TRACK_ANALYTICS,
    event,
    analyticsPayload,
  };
}

export function reducer(state) {
  return state;
}
