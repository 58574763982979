import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Paper,
  Table as MaterialTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow as MaterialTableRow,
} from '@material-ui/core';
import { Obfuscator, Loader } from '@bridgit/foundation';
import { TableRow, PageLoading } from '.';
import { AUTOMATION_NO_RECORDS_FOUND } from './ids';
import { PRIVATE_MODE_TOOLTIP_TEXT } from '../../common/constants';
import { infiniteScrollWatcher } from '../../utils/uiUtils';

const Table = ({
  data,
  columns,
  renderColumn,
  rowHover,
  onRowClick,
  onRowMouseEnter,
  onRowMouseLeave,
  noMatch,
  loading,
  loadingPage,
  pagingError,
  selectedRowId,
  className,
  loadMore,
}) => {
  const privateModeEnabled = useSelector(({ common }) => common.privateModeEnabled);

  const getCustomRender = useCallback((value, columnIndex, rowMeta) => {
    const column = columns[columnIndex];
    const { options: { customBodyRender = null } = {} } = column;
    const isObfuscated = privateModeEnabled && (column?.isPrivate || column?.isFinancials);

    return (
      <TableCell key={columnIndex} className="table-cell">
        <Obfuscator
          isEnabled={isObfuscated}
          label="Hidden field"
          tooltip={PRIVATE_MODE_TOOLTIP_TEXT}
          isOpaque={column?.type === 'Boolean'}
        >
          {typeof customBodyRender === 'function' ? customBodyRender(value, rowMeta) : value?.toString()}
        </Obfuscator>
      </TableCell>
    );
  }, [columns, privateModeEnabled]);

  const onScroll = useCallback((element) => {
    if (loadMore) infiniteScrollWatcher(element, loadMore, loading, pagingError);
  }, [loadMore, loading, pagingError]);

  const hasData = data.length > 0;

  return (
    <Paper onScroll={loadMore && onScroll} className={`table-table ${className}`}>

      {loading && <Loader />}

      <MaterialTable className="table-root">
        {columns.length > 0 && !loading && (
          <TableHead className="table-head">
            <MaterialTableRow className="table-row">
              {columns.map(renderColumn)}
            </MaterialTableRow>
          </TableHead>
        )}

        <TableBody className="table-body">

          {!hasData && !loading && (
            <MaterialTableRow>
              <TableCell id={AUTOMATION_NO_RECORDS_FOUND} className="no-match" colSpan={columns.length}>
                <span>{noMatch}</span>
              </TableCell>
            </MaterialTableRow>
          )}

          {hasData && !loading && data.map(row => (
            <TableRow
              hover={rowHover}
              selected={String(row.rowId) === String(selectedRowId)}
              rowId={String(row.rowId)}
              rowMeta={row.rowMeta}
              key={row.rowId}
              onClick={onRowClick}
              onMouseEnter={onRowMouseEnter}
              onMouseLeave={onRowMouseLeave}
            >
              {row.rowData.slice(0, columns.length)
                .map((value, index) => getCustomRender(value, index, row.rowMeta))}
            </TableRow>
          ))}

          {(loadingPage || pagingError) && <PageLoading colSpan={columns.length} />}
        </TableBody>
      </MaterialTable>
    </Paper>
  );
};

Table.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  renderColumn: PropTypes.func.isRequired,
  rowHover: PropTypes.bool,
  onRowClick: PropTypes.func,
  onRowMouseEnter: PropTypes.func,
  onRowMouseLeave: PropTypes.func,
  noMatch: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  loading: PropTypes.bool,
  loadingPage: PropTypes.bool,
  pagingError: PropTypes.bool,
  selectedRowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  loadMore: PropTypes.func,
};

Table.defaultProps = {
  rowHover: true,
  onRowClick: () => {},
  onRowMouseEnter: () => {},
  onRowMouseLeave: () => {},
  noMatch: 'Sorry, no matching records found',
  loading: false,
  loadingPage: false,
  pagingError: false,
  selectedRowId: '',
  className: '',
  loadMore: undefined,
};

export default Table;
