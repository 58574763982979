import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import {
  Checkbox,
  Collapse,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

export default function RenderItem({
  item,
  expandedSubs,
  searchString,
  selectedItems,
  filterKey,
  subText,
  onSelect,
  onExpandSub,
  primaryContent,
  secondaryContent,
}) {
  const id = item[filterKey];
  const isSearching = !!searchString;

  const isExpandedSubs = useMemo(() => (
    item.hasSubOptions && expandedSubs.indexOf(id) !== -1
  ), [expandedSubs, id, item.hasSubOptions]);

  const isItemChecked = useMemo(() => (
    selectedItems.selected.findIndex(selectedItem => selectedItem[filterKey] === id) !== -1
  ), [filterKey, id, selectedItems.selected]);

  const onClick = item => (evt) => {
    evt.stopPropagation();
    onSelect(item);
  };

  return (
    <Fragment key={id}>
      <ListItem
        classes={{ root: 'multiselect-list-item' }}
        onClick={!item.hasSubOptions || isSearching ? onClick(item) : onExpandSub(id)}
        button
        divider
        disableRipple
      >
        <ListItemText classes={{ primary: 'primary-text' }} primary={item[primaryContent]} secondary={item[secondaryContent]} />
        {item.hasSubOptions && (
        <div className="sub-descriptor">
          {pluralize(subText, item.subOptions.length, true)}
          {!isSearching && isExpandedSubs && <KeyboardArrowDown />}
          {!isSearching && !isExpandedSubs && <KeyboardArrowUp />}
        </div>
        )}
        <Checkbox
          classes={{ root: 'checkbox' }}
          onClick={item.hasSubOptions ? onClick(item) : undefined}
          checked={isItemChecked}
          tabIndex={-1}
          disableRipple
          color="primary"
        />
      </ListItem>
      {!isSearching && item.hasSubOptions && (
      <Collapse in={isExpandedSubs} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {item.subOptions.map(subItem => (
            <ListItem
              key={subItem.value}
              classes={{ root: 'list-item sub' }}
              onClick={onClick(subItem)}
              button
              divider
              disableRipple
            >
              <ListItemText primary={subItem.name} />
              <Checkbox
                classes={{ root: 'checkbox' }}
                checked={selectedItems.selected.findIndex(selectedItem => selectedItem[filterKey] === subItem[filterKey]) !== -1}
                tabIndex={-1}
                disableRipple
                color="primary"
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
      )}
    </Fragment>
  );
}

RenderItem.propTypes = {
  item: PropTypes.object.isRequired,
  expandedSubs: PropTypes.array,
  selectedItems: PropTypes.object.isRequired,
  filterKey: PropTypes.string.isRequired,
  subText: PropTypes.string,
  searchString: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onExpandSub: PropTypes.func,
  primaryContent: PropTypes.string,
  secondaryContent: PropTypes.string,
};

RenderItem.defaultProps = {
  subText: '',
  searchString: '',
  expandedSubs: [],
  onExpandSub: () => {},
  primaryContent: 'name',
  secondaryContent: 'details',
};
