import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PEOPLE_DELETE_PERSON_ATTACHMENT_BEGIN,
  PEOPLE_DELETE_PERSON_ATTACHMENT_SUCCESS,
  PEOPLE_DELETE_PERSON_ATTACHMENT_FAILURE,
  PEOPLE_DELETE_PERSON_ATTACHMENT_DISMISS_ERROR,
} from './constants';
import { protectedJsonDelete, protectedJsonGet } from '../../../utils/api';
import { ATTACHMENTS_STATE_QUERY_PARAM } from '../constants';

export function deletePersonAttachment(accountId, personId, attachmentId, analyticsPayload, newOffset, hasMore, certificationId) {
  return {
    type: PEOPLE_DELETE_PERSON_ATTACHMENT_BEGIN,
    accountId,
    personId,
    attachmentId,
    analyticsPayload,
    newOffset,
    hasMore,
    certificationId,
  };
}

export function dismissDeletePersonAttachmentError() {
  return {
    type: PEOPLE_DELETE_PERSON_ATTACHMENT_DISMISS_ERROR,
  };
}

export function* doDeletePersonAttachment(action) {
  const { accountId, personId, attachmentId, analyticsPayload, newOffset, hasMore, certificationId } = action;

  let substituteAttachmentResponse;

  try {
    yield call(protectedJsonDelete, `/accounts/${accountId}/persons/${personId}/attachments/${attachmentId}`);

    if (hasMore) {
      substituteAttachmentResponse = yield call(
        protectedJsonGet,
        `/accounts/${accountId}/persons/${personId}/attachments`,
        {
          offset: newOffset,
          limit: 1,
          state: ATTACHMENTS_STATE_QUERY_PARAM,
        },
        true,
      );
    }
  } catch (err) {
    yield put({
      type: PEOPLE_DELETE_PERSON_ATTACHMENT_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PEOPLE_DELETE_PERSON_ATTACHMENT_SUCCESS,
    data: attachmentId,
    analyticsPayload,
    substituteAttachment: substituteAttachmentResponse?.data,
    hasMore: substituteAttachmentResponse?.headers?.['query-has-more'] === 'True',
    certificationId,
  });
}

export function* watchDeletePersonAttachment() {
  yield takeLatest(PEOPLE_DELETE_PERSON_ATTACHMENT_BEGIN, doDeletePersonAttachment);
}

export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_DELETE_PERSON_ATTACHMENT_BEGIN:
      return {
        ...state,
        deletePersonAttachmentPending: true,
        deletePersonAttachmentError: null,
      };

    case PEOPLE_DELETE_PERSON_ATTACHMENT_SUCCESS: {
      const { data: attachmentId, substituteAttachment, hasMore, certificationId } = action;

      const replacementAttachment = substituteAttachment?.length ? substituteAttachment : [];

      const oldPersonAttachmentItems = state.personAttachments.items || [];
      const oldPersonCertificationItems = state.personCertifications.items || [];

      // certificationId is only passed if attachment is deleted from certification view/tab
      const stateToUpdate = certificationId ? {
        personCertifications: {
          ...state.personCertifications,
          items: oldPersonCertificationItems.map(cert => (cert.id === certificationId
            ? { ...cert, attachments: cert.attachments.filter(({ id }) => id !== attachmentId) }
            : cert)),
        },
      } : {
        personAttachments: {
          ...state.personAttachments,
          hasMore,
          items: [...oldPersonAttachmentItems.filter(({ id }) => id !== attachmentId), ...replacementAttachment],
        },
      };

      return {
        ...state,
        ...stateToUpdate,
        deletePersonAttachmentPending: false,
        deletePersonAttachmentError: null,
      };
    }

    case PEOPLE_DELETE_PERSON_ATTACHMENT_FAILURE:
      return {
        ...state,
        deletePersonAttachmentPending: false,
        deletePersonAttachmentError: action.data.error,
      };

    case PEOPLE_DELETE_PERSON_ATTACHMENT_DISMISS_ERROR:
      return {
        ...state,
        deletePersonAttachmentError: null,
      };

    default:
      return state;
  }
}
