export const SERVICE_ACCOUNTS_LIST_COLUMNS = [
  {
    name: 'Name',
  },
  {
    name: 'Unique Identifier',
  },
  {
    name: 'Permission group',
  },
  {
    name: 'Account creation',
  },
];

export const MAX_ALLOWED_SERVICE_ACCOUNTS = 10;

export const addServiceAccountInputs = groups => [
  {
    id: 1,
    label: 'Name',
    name: 'name',
    type: 'text',
    placeholder: 'Enter a service account name',
    required: true,
    maxLength: 50,
    className: 'account-name',
  },
  {
    id: 2,
    label: 'Permission Group',
    name: 'permissionGroup',
    type: 'select',
    placeholder: 'Select a permission group',
    options: groups.map(group => ({ label: group.name, value: group.name })),
    required: true,
    className: 'permission-group',
  },
];

export const SERVICE_ACCOUNTS_STEPS = {
  ACCOUNTS_LIST: 'ACCOUNTS_LIST',
  ADD_ACCOUNT_FORM: 'ADD_ACCOUNT_FORM',
  ACCOUNT_DETAILS: 'ACCOUNT_DETAILS',
};

export const VALIDATION_ERRORS = {
  INVALID_NAME: 'Please enter a valid name',
  DUPLICATE_NAME: 'Please enter a unique name',
  REQUIRED: 'This field is required',
};

export const SERVICE_ACCOUNT_FIELDS = {
  ID: 'id',
  PASSWORD: 'password',
};

export const DELETE_SERVICE_ACCOUNT_MODAL = 'DELETE_SERVICE_ACCOUNT_MODAL';
