import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Paper } from '@material-ui/core';
import queryString from 'query-string';
import { getPermissionsForAccount } from 'src/features/permissions/utils/permissionUtils';
import {
  userLogin,
  dismissUserLoginError,
  dismissResetPasswordError,
} from './redux/actions';
import LoginForm from './LoginForm';
import LoginSignup from './LoginSignup';
import config from '../../common/envConfig';
import { getAccounts } from '../accounts/redux/actions';

export const Login = () => {
  const dispatch = useDispatch();

  const { accountId } = useSelector(({ common }) => common);
  const { getAccountsPending } = useSelector(({ accounts }) => accounts);
  const {
    userInfo,
    userLoginPending,
    userLoginError,
    /*
      Need to track pending logout to make sure when redirected back to /login,
      wait for redux to be cleared for hooks in this component (see rootReducer.js)
    */
    postLogoutPending,
  } = useSelector(({ login }) => login);

  const prevLoginPending = useRef(userLoginPending);
  const loginComplete = useRef(false);

  const history = useHistory();
  const location = useLocation();

  const [viaEmail, setViaEmail] = useState(false);
  const [oauthError, setOauthError] = useState(null);
  const [resetMessageCode, setResetMessageCode] = useState(null);

  useEffect(() => {
    const search = location.search && queryString.parse(location.search);
    setViaEmail(!!search?.msg);
    setOauthError(search?.statusCode ? parseInt(search.statusCode, 10) : null);
    setResetMessageCode(search?.msg || null);
  }, [location.search]);

  useEffect(() => {
    if (!userLoginPending && userLoginPending !== prevLoginPending.current) {
      loginComplete.current = true;
    }

    prevLoginPending.current = userLoginPending;
  }, [userLoginPending]);

  useEffect(() => {
    // If logout is in progress, or a login has not yet completed, do nothing
    if (postLogoutPending || !loginComplete.current) return;

    // If we have the userInfo and accountId, hand off to Redirector.js
    if (userInfo?.sub && accountId) {
      history.push('/', { ...history.location.state }); // persist router state on redirect
      return;
    }

    /*
      If we have the userInfo but no accountId
      redirect to accounts list for bridgit admins
      getAccounts for other users
    */
    if (userInfo?.sub && !accountId) {
      const { permissions } = userInfo;
      if (getPermissionsForAccount('*', permissions)) {
        history.push('/', { ...history.location.state }); // persist router state on redirect
      } else {
        dispatch(getAccounts());
      }
    }
  }, [accountId, dispatch, history, postLogoutPending, userInfo, userLoginPending]);

  const handleEmailClick = () => {
    setViaEmail(!viaEmail);
    setOauthError(null);
    setResetMessageCode(null);
    dispatch(dismissUserLoginError());
  };

  const handleLogin = (data) => {
    dispatch(dismissResetPasswordError());
    setResetMessageCode(null);
    dispatch(userLogin(data, config.useTokenAuth));
    const authType = { 'Auth Type': 'Bridgit Auth' };
    if (window.Appcues) window.Appcues.track('Login', authType);
  };

  const isLoginSignupShown = !viaEmail && !userLoginPending;

  return (
    <Grid container justify="center">
      {isLoginSignupShown ? (
        <LoginSignup
          className="login-login-signup"
          viaEmail={handleEmailClick}
          headline="Log in to get started"
          type="Log in"
          oauthError={oauthError}
          resetMessageCode={resetMessageCode}
        />
      ) : (
        <div className="login-login-signup">
          <Paper variant="outlined" component="section" className="spacious-container">
            <LoginForm
              back={handleEmailClick}
              userLogin={handleLogin}
              loading={userLoginPending || getAccountsPending}
              loginError={userLoginError}
              resetMessageCode={resetMessageCode}
            />
          </Paper>
        </div>
      )}
    </Grid>
  );
};

export default Login;
