export const EXCLUDE_PEOPLE_COLUMNS = ['Photo', 'Name', 'Title'];
export const EXCLUDE_PROJECTS_COLUMNS = ['Project Name', 'Status'];

export const PROJECT_TITLE = 'Project Report';
export const PEOPLE_TITLE = 'People Report';
export const REPORT_DEFAULT_PROJECT_TITLE = 'Project Gantt Report';
export const REPORT_DEFAULT_PEOPLE_TITLE = 'People Gantt Report';
export const REPORT_TITLE_MAX_LENGTH = 70;

export const TABLOID_PAPER = 'TABLOID_PAPER';
export const LETTER_PAPER = 'LETTER_PAPER';

export const LANDSCAPE = 'LANDSCAPE';
export const PORTRAIT = 'PORTRAIT';

export const MAX_LETTER_ROLE_COUNT = 10;
export const MAX_TABLOID_ROLE_COUNT = 40;

export const PEOPLE_LIST_PDF_REPORT = 'PEOPLE_LIST_PDF_REPORT';
export const PEOPLE_LIST_CSV_REPORT = 'PEOPLE_LIST_CSV_REPORT';
export const PROJECT_LIST_PDF_REPORT = 'PROJECT_LIST_PDF_REPORT';
export const PROJECT_LIST_CSV_REPORT = 'PROJECT_LIST_CSV_REPORT';
export const PROJECT_GANTT_PDF_REPORT = 'PROJECT_GANTT_PDF_REPORT';
export const PEOPLE_GANTT_PDF_REPORT = 'PEOPLE_GANTT_PDF_REPORT';
export const ROLE_SEGMENT_CSV_REPORT = 'ROLE_SEGMENT_CSV_REPORT';

export const LANDSCAPE_LETTER_PAPER_WIDTH = 1400;
export const PORTRAIT_LETTER_PAPER_WIDTH = 1110;

export const LANDSCAPE_TABLOID_PAPER_WIDTH = 1560;
export const PORTRAIT_TABLOID_PAPER_WIDTH = 1450;

export const STICKY_COLUMN_WIDTH = 200;

export const EMPTY_FIELD_CSV = '';
export const CSV_DATE_FORMAT = 'YYYY-MM-DD';

export const PROJECT_HEADER_MAP = {
  'Start Date': 'Project Start Date',
  'End Date': 'Project End Date',
};

export const PROJECT_ROLES_COLUMN = 'Roles';

export const PROJECT_CSV_ALLOCATION_HEADERS = [
  'Role',
  'Role Note',
  'Person Allocated',
  'Allocation Start Date',
  'Allocation End Date',
  'Allocation %',
];

export const REPORTS_GET_REPORT_PEOPLE_BEGIN = 'REPORTS_GET_REPORT_PEOPLE_BEGIN';
export const REPORTS_GET_REPORT_PEOPLE_SUCCESS = 'REPORTS_GET_REPORT_PEOPLE_SUCCESS';
export const REPORTS_GET_REPORT_PEOPLE_FAILURE = 'REPORTS_GET_REPORT_PEOPLE_FAILURE';
export const REPORTS_GET_REPORT_PEOPLE_DISMISS_ERROR = 'REPORTS_GET_REPORT_PEOPLE_DISMISS_ERROR';
export const REPORTS_CLEAR_REPORT_DATA = 'REPORTS_CLEAR_REPORT_DATA';
export const REPORTS_GET_REPORT_PROJECTS_BEGIN = 'REPORTS_GET_REPORT_PROJECTS_BEGIN';
export const REPORTS_GET_REPORT_PROJECTS_SUCCESS = 'REPORTS_GET_REPORT_PROJECTS_SUCCESS';
export const REPORTS_GET_REPORT_PROJECTS_FAILURE = 'REPORTS_GET_REPORT_PROJECTS_FAILURE';
export const REPORTS_GET_REPORT_PROJECTS_DISMISS_ERROR = 'REPORTS_GET_REPORT_PROJECTS_DISMISS_ERROR';
export const REPORTS_SET_REPORT = 'REPORTS_SET_REPORT';
export const REPORTS_GET_PEOPLE_ALLOCATIONS_BEGIN = 'REPORTS_GET_PEOPLE_ALLOCATIONS_BEGIN';
export const REPORTS_GET_PEOPLE_ALLOCATIONS_SUCCESS = 'REPORTS_GET_PEOPLE_ALLOCATIONS_SUCCESS';
export const REPORTS_GET_PEOPLE_ALLOCATIONS_FAILURE = 'REPORTS_GET_PEOPLE_ALLOCATIONS_FAILURE';
export const REPORTS_GET_PEOPLE_ALLOCATIONS_DISMISS_ERROR = 'REPORTS_GET_PEOPLE_ALLOCATIONS_DISMISS_ERROR';
export const REPORTS_SET_IMAGE_PRELOAD_PENDING = 'REPORTS_SET_IMAGE_PRELOAD_PENDING';
export const REPORTS_COLLECT_PEOPLE_DATA_BEGIN = 'REPORTS_COLLECT_PEOPLE_DATA_BEGIN';
export const REPORTS_COLLECT_PEOPLE_DATA_SUCCESS = 'REPORTS_COLLECT_PEOPLE_DATA_SUCCESS';
export const REPORTS_COLLECT_PEOPLE_DATA_FAILURE = 'REPORTS_COLLECT_PEOPLE_DATA_FAILURE';
export const REPORTS_COLLECT_PEOPLE_DATA_DISMISS_ERROR = 'REPORTS_COLLECT_PEOPLE_DATA_DISMISS_ERROR';
export const REPORTS_COLLECT_PROJECT_DATA_BEGIN = 'REPORTS_COLLECT_PROJECT_DATA_BEGIN';
export const REPORTS_COLLECT_PROJECT_DATA_SUCCESS = 'REPORTS_COLLECT_PROJECT_DATA_SUCCESS';
export const REPORTS_COLLECT_PROJECT_DATA_FAILURE = 'REPORTS_COLLECT_PROJECT_DATA_FAILURE';
export const REPORTS_COLLECT_PROJECT_DATA_DISMISS_ERROR = 'REPORTS_COLLECT_PROJECT_DATA_DISMISS_ERROR';
export const REPORTS_GET_PROJECT_ALLOCATIONS_BEGIN = 'REPORTS_GET_PROJECT_ALLOCATIONS_BEGIN';
export const REPORTS_GET_PROJECT_ALLOCATIONS_SUCCESS = 'REPORTS_GET_PROJECT_ALLOCATIONS_SUCCESS';
export const REPORTS_GET_PROJECT_ALLOCATIONS_FAILURE = 'REPORTS_GET_PROJECT_ALLOCATIONS_FAILURE';
export const REPORTS_GET_PROJECT_ALLOCATIONS_DISMISS_ERROR = 'REPORTS_GET_PROJECT_ALLOCATIONS_DISMISS_ERROR';
export const REPORTS_GET_PEOPLE_GANTT_ALLOCATIONS_BEGIN = 'REPORTS_GET_PEOPLE_GANTT_ALLOCATIONS_BEGIN';
export const REPORTS_GET_PEOPLE_GANTT_ALLOCATIONS_SUCCESS = 'REPORTS_GET_PEOPLE_GANTT_ALLOCATIONS_SUCCESS';
export const REPORTS_GET_PEOPLE_GANTT_ALLOCATIONS_FAILURE = 'REPORTS_GET_PEOPLE_GANTT_ALLOCATIONS_FAILURE';
export const REPORTS_GET_PEOPLE_GANTT_ALLOCATIONS_DISMISS_ERROR = 'REPORTS_GET_PEOPLE_GANTT_ALLOCATIONS_DISMISS_ERROR';
export const REPORTS_GET_GANTT_REPORT_PEOPLE_BEGIN = 'REPORTS_GET_GANTT_REPORT_PEOPLE_BEGIN';
export const REPORTS_GET_GANTT_REPORT_PEOPLE_SUCCESS = 'REPORTS_GET_GANTT_REPORT_PEOPLE_SUCCESS';
export const REPORTS_GET_GANTT_REPORT_PEOPLE_FAILURE = 'REPORTS_GET_GANTT_REPORT_PEOPLE_FAILURE';
export const REPORTS_GET_GANTT_REPORT_PEOPLE_DISMISS_ERROR = 'REPORTS_GET_GANTT_REPORT_PEOPLE_DISMISS_ERROR';
export const REPORTS_GET_REPORT_ROLE_SEGMENTS_BEGIN = 'REPORTS_GET_REPORT_ROLE_SEGMENTS_BEGIN';
export const REPORTS_GET_REPORT_ROLE_SEGMENTS_SUCCESS = 'REPORTS_GET_REPORT_ROLE_SEGMENTS_SUCCESS';
export const REPORTS_GET_REPORT_ROLE_SEGMENTS_FAILURE = 'REPORTS_GET_REPORT_ROLE_SEGMENTS_FAILURE';
export const REPORTS_GET_REPORT_ROLE_SEGMENTS_DISMISS_ERROR = 'REPORTS_GET_REPORT_ROLE_SEGMENTS_DISMISS_ERROR';
export const REPORTS_COLLECT_PROJECT_GANTT_DATA_BEGIN = 'REPORTS_COLLECT_PROJECT_GANTT_DATA_BEGIN';
export const REPORTS_COLLECT_PROJECT_GANTT_DATA_SUCCESS = 'REPORTS_COLLECT_PROJECT_GANTT_DATA_SUCCESS';
export const REPORTS_COLLECT_PROJECT_GANTT_DATA_FAILURE = 'REPORTS_COLLECT_PROJECT_GANTT_DATA_FAILURE';
export const REPORTS_COLLECT_PROJECT_GANTT_DATA_DISMISS_ERROR = 'REPORTS_COLLECT_PROJECT_GANTT_DATA_DISMISS_ERROR';
