import {
  MUST,
  MUST_NOT,
  ROLES,
  FILLED_ROLES,
  UNFILLED_ROLES,
  ROLES_NAME,
  ROLES_ISFILLED,
  ROLE_STATS_UNFILLED,
  ROLE_STATS_TOTAL,
} from './constants';

const generateRolesFilterQuery = ({ inclusive, selected }) => {
  const selectedRolesFilter = [];
  const filledOrUnfilledFilter = [];

  selected.forEach(({ name }) => {
    if ([FILLED_ROLES, UNFILLED_ROLES].includes(name)) {
      filledOrUnfilledFilter.push(name);
    } else {
      selectedRolesFilter.push({
        bool: {
          must: [
            {
              bool: {
                filter: { term: { [ROLES_NAME]: name } },
              },
            },
          ],
        },
      });
    }
  });

  const mainFilter = [];
  const innerFilter = [];

  const isFilledRoles = filledOrUnfilledFilter.includes(FILLED_ROLES);
  const isUnfilledRoles = filledOrUnfilledFilter.includes(UNFILLED_ROLES);
  const isOnlyOneFilterSelected =
    (isFilledRoles || isUnfilledRoles) && !(isUnfilledRoles && isFilledRoles);

  // If both "Filled" and "Unfilled" roles selected, do not include either (canceled out)
  if (isOnlyOneFilterSelected) {
    // If "Filled" selected, and no roles selected, query shape is different
    if (isFilledRoles && !selectedRolesFilter.length) {
      mainFilter.push({
        bool: {
          must: { match: { [ROLE_STATS_UNFILLED]: 0 } },
        },
      });
    } else {
      innerFilter.push({
        bool: {
          filter: {
            term: { [ROLES_ISFILLED]: !!isFilledRoles },
          },
        },
      });
    }
  }

  if (selectedRolesFilter.length) {
    innerFilter.push({
      bool: {
        should: selectedRolesFilter,
      },
    });
  }

  if (innerFilter.length) {
    mainFilter.push({
      nested: {
        path: ROLES,
        query: {
          bool: {
            must: innerFilter,
          },
        },
      },
    });
  }

  const boolStatement = inclusive ? MUST : MUST_NOT;

  return {
    bool: {
      [boolStatement]: mainFilter,
    },
  };
};

const generateRolesFilter = (activeFilters) => {
  const generatedFilters = activeFilters.map(activeFilter => generateRolesFilterQuery(activeFilter));

  // Filter out projects with no roles
  generatedFilters.push({
    bool: {
      must: { range: { [ROLE_STATS_TOTAL]: { gt: 0 } } },
    },
  });

  return {
    bool: {
      must: generatedFilters,
    },
  };
};

export default generateRolesFilter;
