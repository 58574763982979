import { call, put, takeLatest } from 'redux-saga/effects';
import {
  SETTINGS_GET_ADMINS_BEGIN,
  SETTINGS_GET_ADMINS_SUCCESS,
  SETTINGS_GET_ADMINS_FAILURE,
  SETTINGS_GET_ADMINS_DISMISS_ERROR,
} from './constants';
import { protectedJsonGet } from '../../../utils/api';

export function getAdmins() {
  // If need to pass args to saga, pass it with the begin action.
  return {
    type: SETTINGS_GET_ADMINS_BEGIN,
  };
}

export function dismissGetAdminsError() {
  return {
    type: SETTINGS_GET_ADMINS_DISMISS_ERROR,
  };
}

// worker Saga: will be fired on SETTINGS_GET_USERS_BEGIN actions
export function* doGetAdmins() {
  // If necessary, use argument to receive the begin action with parameters.
  let res;
  try {
    res = yield call(protectedJsonGet, 'users');
  } catch (err) {
    yield put({
      type: SETTINGS_GET_ADMINS_FAILURE,
      data: { error: err },
    });
    return;
  }
  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: SETTINGS_GET_ADMINS_SUCCESS,
    data: res,
  });
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchGetAdmins() {
  yield takeLatest(SETTINGS_GET_ADMINS_BEGIN, doGetAdmins);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case SETTINGS_GET_ADMINS_BEGIN:
      return {
        ...state,
        getAdminsPending: true,
        getAdminsError: null,
      };

    case SETTINGS_GET_ADMINS_SUCCESS:
      return {
        ...state,
        bridgitAdmins: action.data,
        getAdminsPending: false,
        getAdminsError: null,
      };

    case SETTINGS_GET_ADMINS_FAILURE:
      return {
        ...state,
        getAdminsPending: false,
        getAdminsError: action.data.error,
      };

    case SETTINGS_GET_ADMINS_DISMISS_ERROR:
      return {
        ...state,
        getAdminsError: null,
      };

    default:
      return state;
  }
}
