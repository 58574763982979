const initialState = {
  getPlaceSuggestionsPending: false,
  getPlaceSuggestionsError: null,
  placeSuggestions: {
    suggestions: [],
    status: null,
    isAddressSuggestionSelected: false,
  },
};

export default initialState;
