import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNT_SETTINGS_REMOVE_PERSON_FIELDS_BEGIN,
  ACCOUNT_SETTINGS_REMOVE_PERSON_FIELDS_SUCCESS,
  ACCOUNT_SETTINGS_REMOVE_PERSON_FIELDS_FAILURE,
  ACCOUNT_SETTINGS_REMOVE_PERSON_FIELDS_DISMISS_ERROR,
} from './constants';
import { protectedJsonDelete } from '../../../utils/api';

export function removePersonFields(accountId, fieldIds) {
  return {
    type: ACCOUNT_SETTINGS_REMOVE_PERSON_FIELDS_BEGIN,
    accountId,
    fieldIds,
  };
}

export function dismissRemovePersonFieldsError() {
  return {
    type: ACCOUNT_SETTINGS_REMOVE_PERSON_FIELDS_DISMISS_ERROR,
  };
}

export function* doRemovePersonFields(action) {
  const { accountId, fieldIds } = action;
  try {
    yield call(protectedJsonDelete, `accounts/${accountId}/person-fields`, fieldIds);
  } catch (err) {
    yield put({
      type: ACCOUNT_SETTINGS_REMOVE_PERSON_FIELDS_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: ACCOUNT_SETTINGS_REMOVE_PERSON_FIELDS_SUCCESS,
    data: fieldIds,
  });
}

export function* watchRemovePersonFields() {
  yield takeLatest(ACCOUNT_SETTINGS_REMOVE_PERSON_FIELDS_BEGIN, doRemovePersonFields);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNT_SETTINGS_REMOVE_PERSON_FIELDS_BEGIN:
      return {
        ...state,
        removePersonFieldsPending: true,
        removePersonFieldsError: null,
      };

    case ACCOUNT_SETTINGS_REMOVE_PERSON_FIELDS_SUCCESS:
      return {
        ...state,
        personFields: state.personFields.filter(field => action.data.indexOf(field.id) === -1),
        removePersonFieldsPending: false,
        removePersonFieldsError: null,
      };

    case ACCOUNT_SETTINGS_REMOVE_PERSON_FIELDS_FAILURE:
      return {
        ...state,
        removePersonFieldsPending: false,
        removePersonFieldsError: action.data.error,
      };

    case ACCOUNT_SETTINGS_REMOVE_PERSON_FIELDS_DISMISS_ERROR:
      return {
        ...state,
        removePersonFieldsError: null,
      };

    default:
      return state;
  }
}
