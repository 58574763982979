import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PEOPLE_DELETE_PERSON_CERTIFICATION_BEGIN,
  PEOPLE_DELETE_PERSON_CERTIFICATION_SUCCESS,
  PEOPLE_DELETE_PERSON_CERTIFICATION_FAILURE,
  PEOPLE_DELETE_PERSON_CERTIFICATION_DISMISS_ERROR,
} from './constants';
import { protectedJsonDelete } from '../../../utils/api';

export function deletePersonCertification(accountId, personId, certificationId, analyticsPayload) {
  return {
    type: PEOPLE_DELETE_PERSON_CERTIFICATION_BEGIN,
    accountId,
    personId,
    certificationId,
    analyticsPayload,
  };
}

export function dismissDeletePersonCertificationError() {
  return {
    type: PEOPLE_DELETE_PERSON_CERTIFICATION_DISMISS_ERROR,
  };
}

export function* doDeletePersonCertification(action) {
  const { accountId, personId, certificationId, analyticsPayload } = action;
  try {
    yield call(protectedJsonDelete, `accounts/${accountId}/persons/${personId}/certifications/${certificationId}`);
  } catch (err) {
    yield put({
      type: PEOPLE_DELETE_PERSON_CERTIFICATION_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: PEOPLE_DELETE_PERSON_CERTIFICATION_SUCCESS,
    certificationId,
    personId,
    analyticsPayload,
  });
}

export function* watchDeletePersonCertification() {
  yield takeLatest(PEOPLE_DELETE_PERSON_CERTIFICATION_BEGIN, doDeletePersonCertification);
}

export function reducer(state, action) {
  switch (action.type) {
    case PEOPLE_DELETE_PERSON_CERTIFICATION_BEGIN:
      return {
        ...state,
        deletePersonCertificationPending: true,
        deletePersonCertificationError: null,
      };

    case PEOPLE_DELETE_PERSON_CERTIFICATION_SUCCESS: {
      const { certificationId, personId } = action;
      const { personCertifications, filteredPeople } = state;
      const newPersonCertifications = {
        ...personCertifications,
        items: personCertifications.items?.filter(cert => cert.id !== certificationId),
      };
      const newFilteredPeople = filteredPeople.map(person => (
        person.id === personId
          ? ({
            ...person,
            certifications: person.certifications?.filter(cert => cert.id !== certificationId),
          })
          : person
      ));

      return {
        ...state,
        deletePersonCertificationPending: false,
        personCertifications: newPersonCertifications,
        filteredPeople: newFilteredPeople,
        deletePersonCertificationError: null,
      };
    }

    case PEOPLE_DELETE_PERSON_CERTIFICATION_FAILURE:
      return {
        ...state,
        deletePersonCertificationPending: false,
        deletePersonCertificationError: action.data.error,
      };

    case PEOPLE_DELETE_PERSON_CERTIFICATION_DISMISS_ERROR:
      return {
        ...state,
        deletePersonCertificationError: null,
      };

    default:
      return state;
  }
}
