import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  LinearProgress,
  Paper,
  Typography,
} from '@material-ui/core/';
import { Image } from '@material-ui/icons/';
import { Form } from 'react-final-form';
import { Field } from 'react-final-form-html5-validation';

import { isAuthorized, hasOneOfModules } from '../permissions/utils/permissionUtils';
import { PERM_ACCOUNT, PERM_WRITE } from '../permissions/utils/constants';
import * as actions from './redux/actions';
import { Confirm } from '../common';
import { EditButton, Can } from '../wrapped-components';
import { AddLogo } from '.';
import { ModuleManagement, LocationUnitSelect } from '../account-settings';
import { MANAGED_MODULES } from './common/constants';
import {
  ACCOUNT_MODULE_ASSIGNMENT_COMMUNICATION,
  ACCOUNT_MODULE_ATTACHMENTS,
} from '../../common/constants';

export class AccountDetails extends Component {
  static propTypes = {
    accounts: PropTypes.object.isRequired,
    accountId: PropTypes.number.isRequired,
    userInfo: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    accountModules: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  constructor(props) {
    super(props);

    const { accountId } = props;
    const retrievedAccount = props.accounts.entities.find(account => account.id === accountId);

    this.state = {
      editingName: false,
      currentAccount: { ...retrievedAccount },
      tempAccountName: retrievedAccount ? retrievedAccount.name : '',
      nameError: false,
      logoOpen: false,
    };
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { accounts, accountId } = this.props;
    const { currentAccount } = this.state;

    const accountsLoaded = !currentAccount.id && accounts.entities.length !== nextProps.accounts.entities.length;
    const updatedAccount = accounts.updateAccountPending && !nextProps.accounts.updateAccountPending && !nextProps.accounts.updateAccountError;
    const addedLogo = accounts.setLogoPending && !nextProps.accounts.setLogoPending;
    const deletedLogo = accounts.deleteLogoPending && !nextProps.accounts.deleteLogoPending;
    const isAccountChanged = nextProps.accountId !== accountId;

    if (isAccountChanged) {
      const retrievedAccount = accounts.entities.find(account => account.id === nextProps.accountId);

      this.setState({
        currentAccount: { ...retrievedAccount },
        tempAccountName: retrievedAccount.name,
      });
    }

    if (accountsLoaded || updatedAccount || addedLogo || deletedLogo) {
      const retrievedAccount = nextProps.accounts.entities.find(account => account.id === accountId);
      this.setState({
        currentAccount: { ...retrievedAccount },
        tempAccountName: retrievedAccount ? retrievedAccount.name : '',
        editingName: false,
        nameError: false,
      });
    } else if (!accounts.updateAccountError && nextProps.accounts.updateAccountError && nextProps.accounts.updateAccountError.status === 409) {
      this.setState({
        nameError: 'Please enter a unique account name',
      });
    }
  }

  updateAccountName = () => {
    const { actions } = this.props;
    const { tempAccountName, currentAccount } = this.state;

    if (tempAccountName !== currentAccount.name) {
      const updatedAccount = {
        id: currentAccount.id,
        name: tempAccountName,
      };

      actions.updateAccount(updatedAccount);
    } else {
      this.setState({
        editingName: false,
      });
    }
  }

  onAcountNameChange = (e) => {
    const { value } = e.currentTarget;
    let nameError = '';
    if (!value) nameError = 'Please enter an account name';
    if (this.checkAccountName(value)) nameError = 'Please enter a unique account name';
    this.setState({
      tempAccountName: value,
      nameError,
    });
  }

  onAccountNameCancel = () => {
    const { currentAccount } = this.state;
    this.setState({
      tempAccountName: currentAccount.name,
    });
    this.editForm();
  }

  checkAccountName = (name) => {
    const { accounts } = this.props;
    const { currentAccount } = this.state;
    return accounts.entities.filter(a => a.id !== currentAccount.id).find(a => a.name === name);
  }

  editForm = () => {
    this.setState(state => ({
      editingName: !state.editingName,
      nameError: false,
    }));
  }

  isDisabled = () => {
    const { accountId, userInfo } = this.props;
    return !isAuthorized(accountId, userInfo.permissions, PERM_WRITE, PERM_ACCOUNT);
  }

  onAddLogo = (image) => {
    const { actions, accountId } = this.props;

    this.setState({
      logoOpen: false,
    });

    actions.setLogo(accountId, image);
  }

  onDeleteLogo = () => {
    const { actions, accountId } = this.props;

    this.setState({
      deleteModalOpen: false,
    });

    actions.deleteLogo(accountId);
  }

  showLogo = () => {
    this.setState({
      logoOpen: true,
    });
  }

  hideLogo = () => {
    this.setState({
      logoOpen: false,
    });
  }

  showDeleteModal = () => {
    this.setState({
      deleteModalOpen: true,
    });
  }

  hideDeleteModal = () => {
    this.setState({
      deleteModalOpen: false,
    });
  }

  render() {
    const { accounts, accountModules } = this.props;
    const {
      tempAccountName,
      nameError,
      currentAccount,
      editingName,
      logoOpen,
      deleteModalOpen,
    } = this.state;

    const currentLogo = currentAccount?.logoUrl || '';
    const hasModuleToManage = hasOneOfModules(accountModules, MANAGED_MODULES);

    return (
      <Grid item xs={10} className="accounts-account-details">
        <Paper className="paper__container">
          <Form
            onSubmit={this.updateAccountName}
            initialValues={currentAccount}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <section className="form__input__container">
                  <Typography component="h1" classes={{ root: 'header' }}>Basic settings</Typography>
                  {!editingName
                    ? (
                      <div className="name-container">
                        <h4 className="name-container__title">{tempAccountName}</h4>
                        <Can
                          action={PERM_WRITE}
                          subject={PERM_ACCOUNT}
                          checkBridgitPerms
                          yes={<EditButton onClick={this.editForm} />}
                        />
                      </div>
                    )
                    : (
                      <Field
                        name="name"
                        component="input"
                      >
                        {({ input, ...rest }) => (
                          <FormControl fullWidth>
                            <Input
                              className={`input--headline${accounts.updateAccountPending ? ' no-underline' : ''}`}
                              id="name"
                              {...input}
                              {...rest}
                              value={tempAccountName}
                              inputProps={{ maxLength: 50 }}
                              onChange={this.onAcountNameChange}
                            />
                            {accounts.updateAccountPending &&
                              <LinearProgress className="small-progress" variant="query" />}
                            {nameError && (
                              <FormHelperText className="form__warning">{nameError}</FormHelperText>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    )}

                  {editingName && (
                    <div className="flex--right">
                      <Button color="default" onClick={this.onAccountNameCancel}>Cancel</Button>
                      <Button type="submit" color="primary" disabled={nameError.length > 0}>Save</Button>
                    </div>
                  )}
                </section>

                <Divider classes={{ root: 'divider divider--fullWidth' }} />

                <Grid container item xs={12}>
                  <Grid item xs={6}>
                    <FormControl classes={{ root: 'meta' }}>
                      <div className="label">Number of Projects</div>
                      <div>{currentAccount.numProjects || 0}</div>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl classes={{ root: 'meta' }}>
                      <div className="label">Number of People</div>
                      <div>{currentAccount.numPeople || 0}</div>
                    </FormControl>
                  </Grid>
                </Grid>

                <Divider classes={{ root: 'divider divider--pr32' }} />

                <Grid container item xs={12}>
                  <Grid container item alignItems="flex-start" justify="flex-start" xs={1}>
                    <Image classes={{ root: 'icon' }} color="primary" />
                  </Grid>

                  <Grid container item xs={11}>
                    <FormControl classes={{ root: 'meta--logo' }}>
                      <div>Logo</div>
                      <AddLogo
                        logo={currentLogo}
                        onSave={this.onAddLogo}
                        onDelete={this.showDeleteModal}
                        open={logoOpen}
                        showModal={this.showLogo}
                        hideModal={this.hideLogo}
                        loading={accounts.setLogoPending}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                {hasModuleToManage && (
                  <>
                    <Divider classes={{ root: 'divider divider--fullWidth' }} />
                    <Grid container item xs={11}>
                      <Typography component="h1" classes={{ root: 'header' }}>Module management</Typography>
                      <FormControl classes={{ root: 'account-module-management' }} fullWidth>
                        <ModuleManagement
                          module={ACCOUNT_MODULE_ASSIGNMENT_COMMUNICATION}
                          infoText="By selecting the checkbox, you will enable the functionality of communicating assignments via email"
                        />
                        <ModuleManagement module={ACCOUNT_MODULE_ATTACHMENTS} />
                        <LocationUnitSelect />
                      </FormControl>
                    </Grid>
                  </>
                )}
              </form>
            )}
          />
        </Paper>

        {deleteModalOpen && (
          <Confirm
            headline="Delete logo"
            acceptButtonText="Delete"
            cancelButtonText="Cancel"
            onCancel={this.hideDeleteModal}
            onAccept={this.onDeleteLogo}
          >
            <div>Are you sure you want to delete this logo?</div>
          </Confirm>
        )}
      </Grid>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps({ accounts, common, login, accountSettings }) {
  const { accountModules } = accountSettings;
  return {
    accounts,
    userInfo: login.userInfo,
    accountId: common.accountId,
    accountModules,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountDetails);
