import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNT_SETTINGS_UPDATE_CERTIFICATION_BEGIN,
  ACCOUNT_SETTINGS_UPDATE_CERTIFICATION_SUCCESS,
  ACCOUNT_SETTINGS_UPDATE_CERTIFICATION_FAILURE,
  ACCOUNT_SETTINGS_UPDATE_CERTIFICATION_DISMISS_ERROR,
} from './constants';
import { protectedJsonPatch } from '../../../utils/api';

export function updateAccountCertification(accountId, certificationId, data) {
  return {
    type: ACCOUNT_SETTINGS_UPDATE_CERTIFICATION_BEGIN,
    accountId,
    certificationId,
    data,
  };
}

export function dismissUpdateAccountCertificationError() {
  return {
    type: ACCOUNT_SETTINGS_UPDATE_CERTIFICATION_DISMISS_ERROR,
  };
}

export function* doUpdateAccountCertification(action) {
  const { accountId, certificationId, data } = action;
  try {
    yield call(protectedJsonPatch, `accounts/${accountId}/certifications/${certificationId}`, data);
  } catch (err) {
    yield put({
      type: ACCOUNT_SETTINGS_UPDATE_CERTIFICATION_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: ACCOUNT_SETTINGS_UPDATE_CERTIFICATION_SUCCESS,
    accountId,
    certificationId,
    data,
  });
}

export function* watchUpdateAccountCertification() {
  yield takeLatest(ACCOUNT_SETTINGS_UPDATE_CERTIFICATION_BEGIN, doUpdateAccountCertification);
}

export function reducer(state, action) {
  switch (action.type) {
    case ACCOUNT_SETTINGS_UPDATE_CERTIFICATION_BEGIN:
      return {
        ...state,
        updateCertificationPending: true,
        updateCertificationError: null,
      };

    case ACCOUNT_SETTINGS_UPDATE_CERTIFICATION_SUCCESS: {
      const newCertifications = state.certifications.map(certification => (
        action.certificationId === certification.id
          ? { ...certification, ...action.data }
          : certification
      ));

      return {
        ...state,
        certifications: newCertifications,
        updateCertificationPending: false,
        updateCertificationError: null,
      };
    }

    case ACCOUNT_SETTINGS_UPDATE_CERTIFICATION_FAILURE:
      return {
        ...state,
        updateCertificationPending: false,
        updateCertificationError: action.data.error,
      };

    case ACCOUNT_SETTINGS_UPDATE_CERTIFICATION_DISMISS_ERROR:
      return {
        ...state,
        updateCertificationError: null,
      };

    default:
      return state;
  }
}
