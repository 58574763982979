import initialState from './initialState';
import { reducer as toggleNavigationReducer } from './toggleNavigation';
import { reducer as toggleModalReducer } from './toggleModal';
import { reducer as graphApiQueryReducer } from './graphApiQuery';
import { reducer as setActiveAccountIdReducer } from './setActiveAccountId';
import { reducer as setActiveViewReducer } from './setActiveView';
import { reducer as setContentViewReducer } from './setContentView';
import { reducer as setSelectedPersonIdReducer } from './setSelectedPersonId';
import { reducer as setSelectedProjectIdReducer } from './setSelectedProjectId';
import { reducer as clearSelectedPersonIdReducer } from './clearSelectedPersonId';
import { reducer as clearSelectedProjectIdReducer } from './clearSelectedProjectId';
import { reducer as setModalActiveViewReducer } from './setModalActiveView';
import { reducer as clearMultiStateModalStateReducer } from './clearMultiStateModalState';
import { reducer as setBackButtonStateReducer } from './setBackButtonState';
import { reducer as togglePersonSidePanelReducer } from './togglePersonSidePanel';
import { reducer as toggleProjectSidePanelReducer } from './toggleProjectSidePanel';
import { reducer as collectAppPrerequisitesReducer } from './collectAppPrerequisites';
import { reducer as setPrivateModeReducer } from './setPrivateMode';
import { reducer as trackAnalyticsReducer } from './trackAnalytics';

const reducers = [toggleNavigationReducer, toggleModalReducer, graphApiQueryReducer, setActiveAccountIdReducer, setActiveViewReducer, setContentViewReducer, setSelectedPersonIdReducer, setSelectedProjectIdReducer, clearSelectedPersonIdReducer, clearSelectedProjectIdReducer, setModalActiveViewReducer, clearMultiStateModalStateReducer, setBackButtonStateReducer, togglePersonSidePanelReducer, toggleProjectSidePanelReducer, collectAppPrerequisitesReducer, setPrivateModeReducer, trackAnalyticsReducer];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  /* istanbul ignore next */
  return reducers.reduce((s, r) => r(s, action), newState);
}
