import React from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  Tooltip,
} from '@material-ui/core';
import {
  Cancel,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@material-ui/icons';

export default function VerbSelector({
  onExpand,
  expanded,
  selectedItems,
  selectedVerb,
  onVerbChange,
  verbs,
  verbText,
  disableDelete,
  onDelete,
}) {
  const onDeleteGroup = (evt) => {
    evt.stopPropagation();
    onDelete(selectedItems.id);
  };

  const onSelectClick = (evt) => {
    evt.stopPropagation();
  };

  return (
    <button type="button" className="multiselect-verbs" onClick={onExpand}>
      {expanded === selectedItems.id ? <KeyboardArrowDown className="multi-select-expand" /> : <KeyboardArrowUp className="multi-select-expand" />}
      <Select
        className="multi-select-container"
        classes={{ root: 'multi-select-select', select: 'multi-select-click' }}
        value={selectedVerb}
        onClick={onSelectClick}
        onChange={onVerbChange}
        MenuProps={{ className: 'multi-select-menu-container' }}
        native
      >
        {verbs.map(v => <option key={v.verb} className="verb-select" value={v.verb}>{`${v.verb}${verbText}`}</option>)}
      </Select>
      <div className="multi-select-trailer">
        {selectedItems.selected.length > 0 && <div>{`(${selectedItems.selected.length})`}</div>}
        {!disableDelete && (
        <Tooltip title="Delete filter" placement="top">
          <Cancel className="multi-select-delete" onClick={onDeleteGroup} />
        </Tooltip>
        )}
      </div>
    </button>
  );
}

VerbSelector.propTypes = {
  verbs: PropTypes.array.isRequired,
  selectedVerb: PropTypes.string.isRequired,
  expanded: PropTypes.string,
  onExpand: PropTypes.func.isRequired,
  onVerbChange: PropTypes.func.isRequired,
  selectedItems: PropTypes.object.isRequired,
  disableDelete: PropTypes.bool,
  verbText: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
};

VerbSelector.defaultProps = {
  expanded: '',
  disableDelete: false,
  verbText: '',
};
