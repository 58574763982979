import { call, put, takeLatest } from 'redux-saga/effects';
import {
  REPORTS_GET_GANTT_REPORT_PEOPLE_BEGIN,
  REPORTS_GET_GANTT_REPORT_PEOPLE_SUCCESS,
  REPORTS_GET_GANTT_REPORT_PEOPLE_FAILURE,
  REPORTS_GET_GANTT_REPORT_PEOPLE_DISMISS_ERROR,
} from './constants';
import { getPeopleListQuery } from '../../people/queries/getPeopleListQuery';
import { MAX_SIZE, MIN_FROM } from '../../../common/constants';
import GraphAPIClient from '../../../common/GraphAPIClient';

export function getGanttReportPeople(accountId, queries, startDate) {
  const { query, variables } = getPeopleListQuery(accountId, queries, MIN_FROM, MAX_SIZE, 'Active', startDate);

  return {
    type: REPORTS_GET_GANTT_REPORT_PEOPLE_BEGIN,
    query,
    variables,
  };
}

export function dismissGetGanttReportPeopleError() {
  return {
    type: REPORTS_GET_GANTT_REPORT_PEOPLE_DISMISS_ERROR,
  };
}

export function* doGetGanttReportPeople(action) {
  const { query, variables } = action;
  let res;
  try {
    res = yield call(GraphAPIClient.query, { query, variables });
    const { errors } = res;
    if (errors) {
      throw errors;
    }
  } catch (err) {
    yield put({
      type: REPORTS_GET_GANTT_REPORT_PEOPLE_FAILURE,
      data: { error: err },
    });
    return;
  }

  yield put({
    type: REPORTS_GET_GANTT_REPORT_PEOPLE_SUCCESS,
    data: res?.data?.people?.items,
  });
}

export function* watchGetGanttReportPeople() {
  yield takeLatest(REPORTS_GET_GANTT_REPORT_PEOPLE_BEGIN, doGetGanttReportPeople);
}

export function reducer(state, action) {
  switch (action.type) {
    case REPORTS_GET_GANTT_REPORT_PEOPLE_BEGIN:
      return {
        ...state,
        getGanttReportPeoplePending: true,
        getGanttReportPeopleError: null,
      };

    case REPORTS_GET_GANTT_REPORT_PEOPLE_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        ganttReportPeople: data,
        getGanttReportPeoplePending: false,
        getGanttReportPeopleError: null,
      };
    }

    case REPORTS_GET_GANTT_REPORT_PEOPLE_FAILURE:
      return {
        ...state,
        getGanttReportPeoplePending: false,
        getGanttReportPeopleError: action.data.error,
      };

    case REPORTS_GET_GANTT_REPORT_PEOPLE_DISMISS_ERROR:
      return {
        ...state,
        getGanttReportPeopleError: null,
      };

    default:
      return state;
  }
}
