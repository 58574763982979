import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from '../../utils/miscUtils';
import { OutlinedInput } from '../wrapped-components';
import { FieldTypeMap } from '../../common/constants';
import CancelButton from '../common/CancelButton';
import { RequiredToggle, PrivateToggle } from '.';

export class CustomFieldEdit extends PureComponent {
  static propTypes = {
    field: PropTypes.object.isRequired,
    onUpdateField: PropTypes.func.isRequired,
    onDeleteField: PropTypes.func.isRequired,
    allFields: PropTypes.array.isRequired,
    currentId: PropTypes.number,
    isSystemField: PropTypes.bool,
    showRequiredToggle: PropTypes.bool,
    showPrivateToggle: PropTypes.bool,
  };

  static defaultProps = {
    isSystemField: false,
    currentId: -1,
    showRequiredToggle: true,
    showPrivateToggle: true,
  }

  inputProps = {
    OutlinedInput: { maxLength: 50 },
  }

  onTitleChange = (e) => {
    const { field, onUpdateField } = this.props;
    const clonedField = cloneDeep(field);

    clonedField.name = e.currentTarget.value;
    clonedField.nameError = false;

    onUpdateField(clonedField);
  }

  onRequiredChange = () => {
    const { onUpdateField, field } = this.props;
    const clonedField = cloneDeep(field);

    clonedField.isRequired = !clonedField.isRequired;

    onUpdateField(clonedField);
  }

  onPrivateChange = () => {
    const { onUpdateField, field } = this.props;
    const clonedField = cloneDeep(field);

    clonedField.isPrivate = !clonedField.isPrivate;

    onUpdateField(clonedField);
  }

  validateTrimName = () => {
    const { field, onUpdateField } = this.props;
    const errorField = cloneDeep(field);

    errorField.name = errorField.name.trim();

    if (!errorField.name) {
      errorField.nameError = 'empty';
    } else if (!this.isUniqueName(errorField.name)) {
      errorField.nameError = 'unique';
    }

    onUpdateField(errorField);
  }

  trimName = () => {
    const { field, onUpdateField } = this.props;
    const clonedField = cloneDeep(field);
    clonedField.name = clonedField.name.trim();
    onUpdateField(clonedField);
  }

  isUniqueName = (name) => {
    const { allFields, currentId } = this.props;
    const reservedFieldNames = ['notes'];
    const lowerName = name.toLowerCase();

    for (let i = 0; i < allFields.length; i += 1) {
      const field = allFields[i];
      if ((field?.name?.toLowerCase() === lowerName && field.id !== currentId) || reservedFieldNames.includes(lowerName)) {
        return false;
      }
    }

    return true;
  }

  render() {
    const { field, onDeleteField, isSystemField, showRequiredToggle, showPrivateToggle } = this.props;
    const isList = field.type === 'SingleSelect' || field.type === 'MultiSelect';

    return (
      <div className="account-settings-custom-field-edit row">
        <OutlinedInput
          value={field.name.toString()}
          placeholder="Enter a field title"
          className={`no-wrap visible${field.nameError ? ` error ${field.nameError}` : ''}`}
          onChange={this.onTitleChange}
          onBlur={this.validateTrimName}
          inputProps={this.inputProps.OutlinedInput}
          disabled={isSystemField}
          readOnly={isSystemField}
        />

        <OutlinedInput disabled value={FieldTypeMap[field.type]} className="form__select visible" readOnly />

        {isList && field.definedValues && field.definedValues.length > 0 && (
          <OutlinedInput disabled value={`"${field.name}"`} className="form__select visible" />
        )}
        {showRequiredToggle && (
          <RequiredToggle
            onClick={this.onRequiredChange}
            required={field.isRequired}
            type="edit"
          />
        )}
        {showPrivateToggle && (
          <PrivateToggle
            onClick={this.onPrivateChange}
            isPrivate={field.isPrivate}
            type="edit"
          />
        )}
        {!isSystemField && <CancelButton className="button-delete" onClick={onDeleteField} tooltipText="Delete field" />}
      </div>
    );
  }
}
export default CustomFieldEdit;
